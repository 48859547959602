<h1 mat-dialog-title class='text-uppercase'>Add Stripe Account Balance</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <div class="border py-12">
    <form [formGroup]="subscriptionForm" *ngIf="subscriptionForm">
    <div fxLayout="row wrap" fxLayoutAlign="start center" class="pl-32 mt-20">
      <div fxFlex="30" class="mr-12">
        <div class="text-bold font-size-16">
          Amount ({{commonHelper.getCurrencySymbol(data.country_id)}})<span class="red-fg">*</span>
        </div>
        <div>
          <mat-form-field class="w-100-p my-8" appearance="outline">
            <input type="number" matInput formControlName="amount">
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="30">
        <div class="text-bold font-size-16">
          Transaction ID<span class="red-fg">*</span>
        </div>
        <div>
          <mat-form-field class="w-100-p my-8" appearance="outline">
            <input type="text" matInput formControlName="txn_id">
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="30">
        <div class="text-bold font-size-16 mb-10">
          Transaction Proof<span class="red-fg">*</span>
        </div>
        <div>
          <input type="file" accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls" id="file1"
            formControlName="bank_cheque_attchment" (change)="onSelectFile1($event)">
          <div class="text-center w-160 mx-auto mb-20" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="url1">
            <img [src]="url1" style="max-width:100%;height: auto;">
          </div>
        </div>
      </div>
      <div fxFlex='60'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Comments</mat-label>
          <textarea matInput rows="6" formControlName="comments"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="pl-32 mt-20" *ngIf="!haveStripeAccount">
      <div class="text-bold font-size-16">
        Billing Details<span class="red-fg">*</span>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
        <div fxFlex='30'>
          <mat-form-field class="w-100-p my-8" appearance="outline">
            <mat-label>Street<span class="red-fg">*</span> </mat-label>
            <input type="text" matInput formControlName="billing_street">
          </mat-form-field>
        </div>
        <div fxFlex='30'>
          <mat-form-field class="w-100-p my-8 ml-12" appearance="outline">
            <mat-label>City<span class="red-fg">*</span></mat-label>
            <input type="text" matInput formControlName="billing_city">
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
        <div fxFlex='30'>
          <mat-form-field class="w-100-p" appearance='outline'>
            <mat-label>Country<span class="red-fg">*</span></mat-label>
            <mat-select formControlName="billing_country" (selectionChange)="changeCountry($event.value)">
              <mat-option *ngFor="let item of countries" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex='30'>
          <mat-form-field class="w-100-p ml-12" appearance='outline'>
            <mat-label>State<span class="red-fg">*</span></mat-label>
            <mat-select formControlName="billing_state">
              <mat-option *ngFor="let item of states" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
        <div fxFlex='30'>
          <mat-form-field class="w-100-p my-8" appearance="outline">
            <mat-label>Zip<span class="red-fg">*</span> </mat-label>
            <input type="text" matInput formControlName="billing_zip">
          </mat-form-field>
        </div>
      </div>
    </div>

    

  </form>
  </div>
</mat-dialog-content>

<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-button class="primary" (click)="save()">Save</button>
</mat-dialog-actions>