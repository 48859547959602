import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  displayedColumns: string[] = ['sno', 'reportname'];
  dataSource;
  length: any;
  pageSize: any;
  getTableData: any;
  index: any;
  params: { limit: number; page: number; };

  constructor(private commonservice: CommonService, public dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    this.getData();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getData();
  }
  getData() {
    this.commonservice.getReportsListing(this.params).subscribe(result => {
      if (result) {
        this.getTableData = result.data;
        console.log(this.getTableData);
        
        this.length = this.getTableData.total;
        this.pageSize = this.getTableData.per_page;
        this.index = this.getTableData.current_page;
        this.dataSource = new MatTableDataSource(this.getTableData.data);
      }
    })
  }
  
  navigate(element) {
    if (element.enable_powerbi == 0) {
      this.router.navigate([]).then(result => { window.open("#/reports/listing/"+element.action_url, '_blank'); });
    } else {
      this.router.navigate([]).then(result => { window.open("#/reports/" + element.action_url, '_blank'); });
    }
  }
}
