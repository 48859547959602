import { Component, OnInit } from '@angular/core';
import { AddUserDialogComponent } from 'app/main/configuration/add-user-dialog/add-user-dialog.component';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { ConfigurationService } from 'app/services/configuration.service';
import { ActivatedRoute } from '@angular/router';
import { AddFromMailComponent } from './add-from-mail/add-from-mail.component';


@Component({
  selector: 'app-from-emails',
  templateUrl: './from-emails.component.html',
  styleUrls: ['./from-emails.component.scss']
})
export class FromEmailsComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email','actions'];
  dataSource;
  searchValue = '';
  getlist: any;
  getTableData: any;
  length: any;
  params = { limit: 15, page: 1, search: '',company_id:0 }
  currentPage: number;
  limit: number;
  currentUser: any;
  company_id : number;

  constructor(private commonService: CommonService,
    private configService: ConfigurationService,
    private activatedroute: ActivatedRoute,
    public dialog: MatDialog) { 
      this.company_id = this.activatedroute.parent.snapshot.params['id'];
      
    }

  ngOnInit() {
    // this.getCurrentUser();
    
    this.getUserData();
  }

  // getCurrentUser() {
  //   this.configService.getCurrentUser().subscribe(res => {
  //     if (res.status == 1) {
  //       this.currentUser = res.data;
  //     }
  //   })
  // }

  applyFilter(val) {
    this.params['search'] = val;

    this.params.page = 1;
    this.getUserData();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getUserData();
  }
  getUserData() {
    this.params['company_id'] = this.company_id;
    
    this.configService.getFromMailList(this.params).subscribe(result => {
      if (result.status === 1) {
        this.currentPage = result.cpanel_users.current_page;
        this.limit = result.cpanel_users.per_page;
        this.length = result.cpanel_users.total
        this.dataSource = new MatTableDataSource(result.cpanel_users.data);
      }
    })
  }
  addUser(): void {
    var element = {};
    element['company_id'] = this.company_id;
    const dialogRef = this.dialog.open(AddFromMailComponent, {
      width: '50%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.getUserData();
      }
    });
  }
  edit(element): void {
    element['company_id'] = this.company_id;
    const dialogRef = this.dialog.open(AddFromMailComponent, {
      width: '50%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.getUserData();
      }
    });
  }
  
  deleteUser(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to delete Email';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        var param = {};
        param['company_id'] = this.company_id;
        param['user_id'] = record_id;
        this.configService.deleteFromMailList(param).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
          this.getUserData();
        });
      } else {

      }
    })
  }

  showActivities() {
    let activityData = {
      'company_id': this.company_id,
      'module_slug': 'from_emails',
      'show_company_name': false,
      'module_name': 'From Emails'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
