<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">Offline Payments</h2>
  </mat-toolbar-row>
  <div class="center px-20">
    <mat-toolbar-row class="secondary-headers w-100-p px-20">
      <ul class="nav m-0 p-0" fxFlex="auto">
        <li><a class="text-bold" (click)="getViewList(0)" [ngClass]="{'active': viewList == 0}">Open Balance
            Customers</a>
        </li>
        <li><a class="text-bold" (click)="getViewList(1)" [ngClass]="{'active': viewList == 1}">Zero Balance
            Customers</a>
        </li>
      </ul>
    </mat-toolbar-row>
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">

        <div class="search-wrapper" fxFlex="25">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [(ngModel)]="searchValue" placeholder="Customer">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>

        <div fxFlex="50" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="end center">

            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="customer">
                <mat-header-cell *matHeaderCellDef> Customer </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a class='cursor-pointer' (click)='navigate(element)'>{{element.name}} </a>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="balance">
                <mat-header-cell *matHeaderCellDef> Credit Balance </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{common_hepler.getCurrencySymbol(element.country_id)}}{{element.amount}} </mat-cell>
              </ng-container>

              <ng-container *ngIf="viewList == 1" matColumnDef="invoice_ref">
                <mat-header-cell *matHeaderCellDef> Invoice Ref # </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.invoice_id}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="ref">
                <mat-header-cell *matHeaderCellDef> Ref # </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.balance_transaction_id}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> {{viewList ==0?'Transactioin ID':'Debited For'}} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{viewList ==0? element.offline_transaction_id:element.payment_type}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="created_by">
                <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="created_on">
                <mat-header-cell *matHeaderCellDef> Created On </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at | date:'MM/dd/yyyy'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="proof">
                <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button mat-icon-button class="primary-fg" matTooltip="Comments: {{element.comments}}">
                    <mat-icon class="s-18">comment</mat-icon>
                  </button>

                  <button mat-icon-button class="primary-fg" matTooltip="Transaction Attachment" *ngIf="element.offline_payment_attachment"
                    (click)="downloadOfflineDoc(element, element.offline_payment_attachment)">
                    <mat-icon class="s-18">cloud_download</mat-icon>
                  </button>

                  <button mat-icon-button class="accent-fg" matTooltip="Invoice Attachment" *ngIf="viewList == 1"
                    (click)="downloadInvocieDoc(element, element.invoice_attachment, 'invoice_attachment')">
                    <mat-icon class="s-18">insert_drive_file</mat-icon>
                  </button>
                  
                  <span *ngIf="!element.offline_payment_attachment">-</span>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>