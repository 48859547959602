import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';


@Component({
  selector: 'app-data-backup-request',
  templateUrl: './data-backup-request.component.html',
  styleUrls: ['./data-backup-request.component.scss']
})

export class DataBackupRequestComponent implements OnInit {

  displayedColumns: string[] = ['customer_name', 's3_status', 'data_status', 'created_at', 'updated_at'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  params: {page: number; limit?: number, searchKey?: any, company_id?: any };
  
  constructor(
    private commonService: CommonService,
    public router: Router,) {
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    this.getList();
  }
  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }
  
  getList() {
    this.commonService.dataBackupRequests(this.params).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data.data);
        this.length = res.data.total;
        this.pageSize = res.data.per_page;
        this.index = res.data.current_page;
      }
    })
  }
  navigate(element, blank) {
    // this.router.navigate(['company_details', element.company_id]);
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }

}
