<div class="loader content" *ngIf="showLoader">
  <mat-spinner *ngIf="showLoader" [diameter]="50"></mat-spinner>
</div>
<h1 mat-dialog-title class='text-uppercase'>Add signup</h1>
<mat-divider></mat-divider>
<form [formGroup]="addSignupRequest" (ngSubmit)="addSignup()">
  <mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>First Name</mat-label>
          <input type="text" formControlName="first_name" matInput>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Last Name</mat-label>
          <input type="text" formControlName="last_name" matInput>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Email</mat-label>
          <input type="email" formControlName="email" matInput>
        </mat-form-field>
      </div>

      <div fxFlex='48' *ngIf="showPassword">
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Password</mat-label>
          <input type="text" formControlName="password" matInput>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Country of Operations</mat-label>
          <mat-select formControlName="country_id" (selectionChange)="changeCountry()">
            <mat-option *ngFor="let item of comapany_locations" [value]="item.country_table_id">
              {{item.country_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <intl-input-phone appearance="outline" class="mt-12" formControlName="contact_number"
                [ConfigurationOption]="configOption3" (OnIsRequiredChange)="requiredFlagChange($event)"
                [NumberTextValue]="NumberModel2" [SelectedCountryISOCode]="SelectedCountryISOCode"></intl-input-phone>
      </div>

       <!-- <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Company Name</mat-label>
          <input matInput #CompleteAuto aria-label="State" (input)="autoComplete(CompleteAuto.value);"
            [matAutocomplete]="auto" formControlName="company_name">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedAuto($event)">
            <mat-option *ngFor="let state of myBooks" [value]="state.name">
              <img class="img-res" aria-hidden [src]="state.logo">
              <span>{{state.name}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div> -->

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Company Name</mat-label>
          <input type="text" matInput formControlName="company_name">
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Subdomain Name</mat-label>
          <input type="text" matInput formControlName="sub_domain_name">
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Website</mat-label>
          <input type="text" matInput formControlName="website">
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <div class="sub-heading">Interested In</div>
        <mat-checkbox formControlName="interest_crm" value="Sales CRM" color="primary" class="mr-4 font-size-14">Sales CRM</mat-checkbox>
        <mat-checkbox formControlName="interest_hire" value="Recruitment" color="primary" class="mr-4 font-size-14">Recruitment</mat-checkbox>
        <mat-checkbox formControlName="interest_hr" value="HR Management" color="primary" class="font-size-14">HR Management</mat-checkbox>
      </div>

      <!-- <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Role</mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let item of role_types" [value]="item.value">
              {{item.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Buying Stage</mat-label>
          <mat-select formControlName="buying_stage">
            <mat-option *ngFor="let item of buying_stages" [value]="item.value">
              {{item.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

     

    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button class='primary mr-4'>Save</button>
  </mat-dialog-actions>
</form>