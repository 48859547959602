import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopCardsComponent } from './top-cards/top-cards.component';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/material.module';
import { RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ShortNumberPipe } from './short-number.pipe';
import { CommentsComponent } from './comments/comments.component';
import { CommonModuleActivityComponent } from './common-module-activity/common-module-activity.component';
import { CommonModuleDialogActivityComponent } from './common-module-dialog-activity/common-module-dialog-activity.component';
import { CommonSubscriptionPlanComponent } from './common-subscription-plan/common-subscription-plan.component';
import { CompanyPricePlanConfigurationComponent } from './company-price-plan-configuration/company-price-plan-configuration.component';
import { DoccumentsListingComponent } from './doccuments-listing/doccuments-listing.component';
import { ActiveUserChangePasswordComponent } from './active-user-change-password/active-user-change-password.component';
import { SendDoctoClientDialogComponent } from './send-docto-client-dialog/send-docto-client-dialog.component';
import { PlansDialogComponent } from './plans-dialog/plans-dialog.component';

@NgModule({
    declarations: [
        TopCardsComponent,
        ConfirmDialogComponent,
        ShortNumberPipe,
        CommentsComponent,
        // ModuleActivitiesComponent replaces commonmoduleactivitycomponent,
        CommonModuleActivityComponent,
        // ModuleActivityDialogueComponent replaces CommonModuleDialogActivityComponent,
        CommonModuleDialogActivityComponent,
        CommonSubscriptionPlanComponent,
        CompanyPricePlanConfigurationComponent,
        DoccumentsListingComponent,
        ActiveUserChangePasswordComponent,
        SendDoctoClientDialogComponent,
        PlansDialogComponent
    ],
    imports: [
        CommonModule,
        FuseSidebarModule,
        FuseSharedModule,
        MaterialModule,
        RouterModule
    ],
    exports: [
        TopCardsComponent,
        ShortNumberPipe,
        CommentsComponent,
        CommonModuleActivityComponent,
        CommonModuleDialogActivityComponent,
        CommonSubscriptionPlanComponent,
        CompanyPricePlanConfigurationComponent,
        DoccumentsListingComponent,
        SendDoctoClientDialogComponent
    ]
})
export class CommonAllModule { }
