<div class="page-layout carded fullwidth inner-scroll" *ngIf="client_info_permissions">
    <nav mat-tab-nav-bar class="bg-white">
        <a mat-tab-link routerLink="billingdetails" routerLinkActive="active" *ngIf="client_info_permissions['clients.billing_details']">Billing Details</a>
        <a mat-tab-link routerLink="subscription" routerLinkActive="active" *ngIf="client_info_permissions['clients.subsriptions']">Subscription Plans</a>
        <a mat-tab-link routerLink="invoices" routerLinkActive="active" *ngIf="client_info_permissions['clients.invoice']">Invoices</a>
        <a mat-tab-link routerLink="users" routerLinkActive="active" *ngIf="client_info_permissions['clients.users']">Users</a>
        <a mat-tab-link routerLink="textrecruit" routerLinkActive="active" *ngIf="client_info_permissions['clients.text_recruit']">Text Recruit</a>
        <a mat-tab-link routerLink="mxrecords" routerLinkActive="active" *ngIf="client_info_permissions['clients.mxrecords']">MX Records</a>
        <a mat-tab-link routerLink="integrationControls" routerLinkActive="active" *ngIf="client_info_permissions['clients.integration_controls']">Integration Controls</a>
        <a mat-tab-link routerLink="data_backup" routerLinkActive="active">Data Backup</a>
        <a mat-tab-link routerLink="partner_search" routerLinkActive="active" *ngIf="client_info_permissions['clients.partner_search']">PartnerSearch</a>
        <a mat-tab-link routerLink="implementationActivities" routerLinkActive="active">Implementation</a>
        <a mat-tab-link routerLink="trainings" routerLinkActive="active">Trainings</a>
        <a mat-tab-link routerLink="data-migration" routerLinkActive="active">Data Migration</a>
        <a mat-tab-link routerLink="from-emails" routerLinkActive="active">From Emails</a>
      </nav>
    <!-- <mat-toolbar-row class="secondary-headers w-100-p">
        <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
            <li><a class="text-bold text-capitalize" routerLink="billingdetails" routerLinkActive="active"
                    *ngIf="client_info_permissions['clients.billing_details']">Billing Details</a></li>
            <li><a class="text-bold text-capitalize" routerLink="subscription" routerLinkActive="active"
                    *ngIf="client_info_permissions['clients.subsriptions']">Subscription Plans</a></li>
            <li><a class="text-bold text-capitalize" routerLink="invoices" routerLinkActive="active"
                    *ngIf="client_info_permissions['clients.invoice']">Invoices</a></li>
            <li><a class="text-bold text-capitalize" routerLink="users" routerLinkActive="active"
                    *ngIf="client_info_permissions['clients.users']">Users</a></li>
            
            <li><a class="text-bold text-capitalize" routerLink="textrecruit" routerLinkActive="active"
                    *ngIf="client_info_permissions['clients.text_recruit']">Text Recruit</a></li>
            <li><a class="text-bold text-capitalize" routerLink="mxrecords" routerLinkActive="active"
                    *ngIf="client_info_permissions['clients.mxrecords']">MX Records</a></li>
            <li><a class="text-bold text-capitalize" routerLink="integrationControls" routerLinkActive="active"
                    *ngIf="client_info_permissions['clients.integration_controls']">Integration Controls</a></li>

            <li><a class="text-bold text-capitalize" routerLink="data_backup" routerLinkActive="active">Data Backup</a>
            </li>

            <li><a class="text-bold text-capitalize" routerLink="partner_search" routerLinkActive="active"
                    *ngIf="client_info_permissions['clients.partner_search']">PartnerSearch</a></li>
            <li><a class="text-bold text-capitalize" routerLink="implementationActivities"
                    routerLinkActive="active">Implementation</a></li>
            <li>
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </li>
            <mat-menu #menu="matMenu">
                <button class="text-bold text-capitalize" routerLink="trainings" routerLinkActive="active"
                    mat-menu-item>
                    <span>Trainings</span>
                </button>
                <button class="text-bold text-capitalize" routerLink="data-migration" routerLinkActive="active"
                    mat-menu-item>
                    <span>Data Migration</span>
                </button>
            </mat-menu>
        </ul>
    </mat-toolbar-row> -->
    <div class="center company-info-max-h px-20">
        <div class="content-card mt-12 mb-56">
            <div class="content" fusePerfectScrollbar>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>