<h1 mat-dialog-title class='text-uppercase'> {{(gst_number != '') ? 'Edit' : 'Add'}} GST Number </h1>
<mat-divider></mat-divider>
<mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-12" [formGroup]="gstform">
        <div fxFlex>
          GST Number
        </div>
        <div fxFlex='70'>
            <mat-form-field appearance="outline" class="w-100-p">
                <input type="text" matInput formControlName="gstnumber" >
                <mat-error *ngIf="gstform.get('gstnumber').hasError('pattern')">Not a valid GST Number</mat-error>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button class='primary' (click)="update()" >Update</button>
</mat-dialog-actions> 