<h1 mat-dialog-title class='text-uppercase'>Add Reciepient</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
      
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Email ID</mat-label>
          <input type="email" formControlName="email" matInput>
        </mat-form-field>
      </div>
      
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-flat-button class='primary' (click)="save()">Save</button>
</mat-dialog-actions>