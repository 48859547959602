<h1 mat-dialog-title class='text-uppercase'>Change Password</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
<form [formGroup]="ChangePasswordForm" class="px-20 py-16">
        <div fxLayout="row" fxLayoutAlign="center start" class="px-20 py-16 text-center">
          <div fxFlex="40" fxLayoutGap="12px grid">
            
           <div class="form-group">
            <mat-form-field appearance="outline" class="w-100-p input-group">
              <mat-label>Enter New Password</mat-label>
              <input matInput type="password" formControlName="password"  [type]="hide ? 'password' : 'text'">
              <button mat-button type="button" >
                  <mat-icon class="font-size-14" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                <!-- <mat-icon class="font-size-14">remove_red_eye</mat-icon> -->
              </button>
             
            </mat-form-field>
           
          </div>
          <div >
            <mat-form-field appearance="outline" class="w-100-p input-group">
              <mat-label>Confirm New Password</mat-label>
              <input matInput type="password"    formControlName="password_confirmation" [type]="hidden ? 'password' : 'text'">
              <button mat-button  type="button"> 
                  <mat-icon class="font-size-14" matSuffix (click)="hidden = !hidden">{{hidden ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              
            </mat-form-field>
          
            </div>
            <div>
              <mat-checkbox color="primary" formControlName="mark_as_default"> Enforce user to change password when login </mat-checkbox>
            </div>
            <ul class="list-unstyled password-strength pb-40 w-100-p">
              <li>
                <mat-icon class="font-size-16" [color]="ChangePasswordForm.controls['password'].hasError('required') || ChangePasswordForm.controls['password'].hasError('minlength')  ? 'white' : 'primary'">{{ ChangePasswordForm.controls['password'].hasError('required') ||
                    ChangePasswordForm.controls['password'].hasError('minlength') ? 'check_circle' :
                    'check_circle'  }}</mat-icon>At least 8 characters long
              </li>
              <li>
                <mat-icon class="font-size-16"  [color]="ChangePasswordForm.controls['password'].hasError('required') || ChangePasswordForm.controls['password'].hasError('hasSmallCase')  ? 'white' : 'primary'" >{{ ChangePasswordForm.controls['password'].hasError('required') ||
                    ChangePasswordForm.controls['password'].hasError('hasSmallCase') ? 'check_circle' :
                    'check_circle' }}</mat-icon>One lowercase character
              </li>
              <li>
                <mat-icon class="font-size-16"   [color]="ChangePasswordForm.controls['password'].hasError('required') || ChangePasswordForm.controls['password'].hasError('hasCapitalCase')  ? 'white' : 'primary'">{{ ChangePasswordForm.controls['password'].hasError('required') ||
                    ChangePasswordForm.controls['password'].hasError('hasCapitalCase') ? 'check_circle' :
                    'check_circle' }}</mat-icon>One uppercase character
              </li>
              <li>
                <mat-icon class="font-size-16"   [color]="ChangePasswordForm.controls['password'].hasError('required') || ChangePasswordForm.controls['password'].hasError('hasSpecialCharacters')  || ChangePasswordForm.controls['password'].hasError('hasNumber')  ? 'white' : 'primary'">{{ ChangePasswordForm.controls['password'].hasError('required') ||
                    ChangePasswordForm.controls['password'].hasError('hasSpecialCharacters') ? 'check_circle' :
                    'check_circle' }}</mat-icon>Atleast one Number and Special character
              </li>
            </ul>
            
          </div>
        </div>
    </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button class='primary' (click)="save()">Save</button>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
</mat-dialog-actions>