import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { Location } from '@angular/common';
import { isEmpty, result } from 'lodash';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import masterdata from 'app/services/masterdata';
import { CustomConfirmDialogComponent } from '../../custom-confirm-dialog/custom-confirm-dialog.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

export interface Cycle {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-add-addons',
  templateUrl: './add-addons.component.html',
  styleUrls: ['./add-addons.component.scss']
})
export class AddAddonsComponent implements OnInit {

  subCycle: Cycle[] = [
    { value: 1, viewValue: 'Monthly' },
    { value: 3, viewValue: 'Quarterly' },
    { value: 6, viewValue: 'Half-Yearly' },
    { value: 12, viewValue: 'Yearly' }
  ];
  plans = [
    { value: 'limited', viewValue: 'Limited' },
    { value: 'unlimited_excl_addons', viewValue: 'Unlimited (Excluding Addons)' },
    { value: 'unlimited_inc_addons', viewValue: 'Unlimited (Including Addons)' },
  ];
  paymentmodes = [
    // { name: 'Bank', value: 'bank', isActive:1, slug:'bank', isDisable:false },
    // { name: 'Cheque', value: 'cheque', isActive:1, slug:'cheque', isDisable:false },
    { name: 'Generate Invoice', value: 'invoice_url', isActive: 1, slug: 'invoice_url', isDisable: false },
    { name: 'Stripe Credit Balance', value: 'invoice_url', isActive: 1, slug: 'stripe_balance', isDisable: false },
    // { name: 'Credit Card', value: 'card', isActive:1, slug:'card', isDisable:false },

  ]
  number = Number;
  addonPrices: any;
  masterAddons: any;
  addonProRates: any;
  totalMasterAddonCost: number;
  subscriptionForm: FormGroup;
  subscriptionApps: any;
  subscriptionAddons: any = {};
  company_id: any;
  total_price: number;
  subscription_apps: any = {};
  branding_addon_data: any;
  type: string;
  purchased_addons: any;
  company_subscription_details: any;
  subscription_cycle: any;
  subscription_plan: any;
  planaddons: any;
  company_details: any;
  country_id: any;
  currency: string;
  total: number = 0;
  tax: number;
  subtotal: any = 0;
  payment: boolean = false;
  showtxn: boolean;
  currency_symbol: string;
  payment_mode: any = '';
  selected: any;
  showcc: boolean = false;
  cc_details: any;
  selected_ccdetails: any;
  hire_users: any;
  hr_users: any;
  crm_users: any;
  contacts_search_credits: any;
  mass_mails_credits: any;
  i9_forms: any;
  sms_credits: any;
  linkedin_credits: any;
  massmailcost: number;
  contactcost: number;
  i9_forms_cost: number;
  sms_credits_cost: number;
  linkedin_credits_cost: number;
  hirecost: number;
  hrcost: number;
  crmcost: number;
  brandingcost: number;
  is_mass_disabled: boolean = false;
  is_contact_disabled: boolean = false;
  is_i9_forms_disabled: boolean = false;
  is_sms_credits_disabled: boolean = false;
  is_linkedin_credits_disabled: boolean = false;
  is_hire_disabled: boolean;
  is_hr_disabled: boolean;
  is_crm_disabled: boolean;
  billing: any;
  ccid: any;
  params: any;
  pay_billing_shipping: any;
  addonsByPlan: any = { 'limited': {}, 'unlimited_excl_addons': {}, 'unlimited_inc_addons': {} };
  addonsByCycle: any = {};
  is_unlmtd_excl: boolean;
  default_card: string;
  selectedul = 'unlimited';
  hireused: any;
  crmused: any;
  hrused: any;
  masterData = masterdata;
  countries: { id: number; name: string; }[];
  states: { id: number; country_id: number; code: string; name: string; standard_timezone: string; timezone: string; }[];
  wallet_amount: any;
  final: number;
  tds: number;
  tdsvalue: number = 0;
  gstvalue: number = 0;
  gst_number: any;
  hire_users_per_day_cost: number;
  hr_users_per_day_cost: number;
  crm_users_per_day_cost: number;
  remaining_balance: any = 0;
  licenses_remaining_balance: any;
  unlimited_addon_access: any;
  hire_addons: any = ['resume_job_indox', 'chatbot', 'job_grabber', 'resume_harvesting'];
  hr_addons: any = ['quick_books'];
  hire_hr_addons: any = ['digital_onboarding_templates', 'report_builder'];
  showTXNID: boolean = true;
  user_data: any;
  unit_prices = { 'hire': 0, 'hr': 0, 'crm': 0 };
  updateCheckout: Promise<any>;
  formData: FormData = new FormData();
  url1: any;
  accountBalance: any;
  isAccountBalPayment: boolean = false;
  turnOffPaymentMode: boolean = false;

  constructor(private commonservice: CommonService,
    private companyDetailService: CompanyDetailsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    public dialog: MatDialog,
    private router: Router) {
    this.subscriptionForm = this.fb.group({
      subscription_cycle: [''],
      hire_qty: [''],
      hr_qty: [''],
      crm_qty: [''],
      mass_qty: [''],
      mass_slab_index: [''],
      massmail_is_subscribed: [''],
      contact_qty: [''],
      contact_slab_index: [''],
      contact_is_subscribed: [''],
      sms_credits_qty: [''],
      sms_credits_slab_index: [''],
      sms_credits_is_subscribed: [''],
      i9_forms_qty: [''],
      i9_forms_slab_index: [''],
      i9_forms_is_subscribed: [''],
      linkedin_credits_qty: [''],
      linkedin_credits_slab_index: [''],
      linkedin_credits_is_subscribed: [''],
      hirecheck: [''],
      hrcheck: [''],
      crmcheck: [''],
      branding: ['false'],
      opt_hire_unlimited: [false],
      opt_hr_unlimited: [false],
      opt_crm_unlimited: [false],
      opt_massmails_unlimited: [false],
      opt_sms_credits_unlimited: [false],
      opt_contact_unlimited: [false],
      opt_i9_forms_unlimited: [false],
      opt_linkedin_credits_unlimited: [false],
      selected_card: [''],
      subscription_plan: [''],
      billing_street: [''],
      billing_city: [''],
      billing_country: [''],
      billing_state: [''],
      billing_zip: [''],
      txn_id: [''],
      bank_cheque_attchment: ['']
    })
    this.company_id = this.route.parent.snapshot.params['id'];
  }

  ngOnInit() {
    this.getPlanDetails();
    this.calculateSum();
    this.countries = this.masterData.countriesList;
  }

  isEmpty(check) {
    return isEmpty(check);
  }

  changeCountry(country) {
    this.states = this.masterData.statesList.filter(state => state.country_id === country);
  }

  getPlanDetails() {
    let params = { 'company_id': this.company_id, 'is_cpanel': 1 }
    this.companyDetailService.add_addons(params).subscribe(res => {
      if (res.status != 1) {
        this.commonservice.openSnackBar('warn', res.message);
        this.location.back();
      }
      this.accountBalance = res.data.accountBalance;
      this.subscriptionApps = res.data.subscription_apps;
      this.addonPrices = res.data.addons_pricing;
      this.masterAddons = res.data.masterAddons;
      this.addonProRates = res.data.addonProRates;
      this.subscriptionAddons = res.data.subscription_addons ? res.data.subscription_addons : {};
      this.purchased_addons = res.data.purchased_addons ? res.data.purchased_addons : {};
      this.branding_addon_data = res.data.branding_addon_data;
      this.company_subscription_details = res.data.activeSubscription ? res.data.activeSubscription : {};
      this.subscription_cycle = res.data.subscription_cycle ? res.data.subscription_cycle : 1;
      this.subscription_plan = res.data.subscription_plan ? res.data.subscription_plan : 'limited';
      this.company_details = res.data.companyDetails;
      this.country_id = this.company_details.country_id;
      this.cc_details = res.data.cc_details ? res.data.cc_details : '';
      this.pay_billing_shipping = res.data.pay_billing_shipping ? res.data.pay_billing_shipping : '';
      this.default_card = res.data.default_card ? res.data.default_card : '';
      this.wallet_amount = this.company_details.wallet_amount != null ? this.company_details.wallet_amount : 0;
      this.gst_number = !isEmpty(this.company_details.gst_number) ? this.company_details.gst_number : '';
      this.hire_users_per_day_cost = this.unit_prices['hire'] = res.data.hire_users_per_day_cost;
      this.hr_users_per_day_cost = this.unit_prices['hr'] = res.data.hr_users_per_day_cost;
      this.crm_users_per_day_cost = this.unit_prices['crm'] = res.data.crm_users_per_day_cost;
      this.licenses_remaining_balance = res.data.licenses_remaining_balance;
      this.unlimited_addon_access = res.data.unlimited_addon_access;
      this.user_data = !isEmpty(res.data.user_data) ? res.data.user_data : '';
      if (!isEmpty(this.masterAddons)) {
        Object.values(this.masterAddons).filter(addon => {
          this.subscriptionForm.addControl(addon['addon_slug'], new FormControl(''));
          this.subscriptionForm.addControl(addon['addon_slug'] + '_is_subscribe', new FormControl(addon['is_subscribed']));
        });
      }
      this.hireused = !isEmpty(this.subscriptionAddons.hire_users) ? Number(this.subscriptionAddons.hire_users.qty) : '0';
      this.hrused = !isEmpty(this.subscriptionAddons.hire_users) ? Number(this.subscriptionAddons.hire_users.qty) : '0';
      this.crmused = !isEmpty(this.subscriptionAddons.hire_users) ? Number(this.subscriptionAddons.hire_users.qty) : '0';

      this.addonsPlan();
      this.addonsCycle();

      this.planaddons = this.addonPrices[this.subscription_cycle][this.subscription_plan];
      this.hire_users = this.planaddons.hire_users;
      this.hr_users = this.planaddons.hr_users;
      this.crm_users = this.planaddons.crm_users;

      // if (this.subscription_plan != 'unlimited_excl_addons') {
      //   this.contacts_search_credits = this.planaddons.contacts_search_credits;
      //   this.linkedin_credits = this.planaddons.linkedin_credits;
      //   this.mass_mails_credits = this.planaddons.mass_mails_credits;
      //   this.i9_forms = this.planaddons.i9_forms;
      //   if (this.getAddonAccess('sms_credits')) {
      //     this.sms_credits = this.planaddons.sms_credits;
      //   }
      // } else {
      this.contacts_search_credits = this.addonPrices[this.subscription_cycle]['limited'].contacts_search_credits;
      this.linkedin_credits = this.addonPrices[this.subscription_cycle]['limited'].linkedin_credits;
      this.mass_mails_credits = this.addonPrices[this.subscription_cycle]['limited'].mass_mails_credits;
      this.i9_forms = this.addonPrices[this.subscription_cycle]['limited'].i9_forms;
      if (this.getAddonAccess('sms_credits')) {
        this.sms_credits = this.addonPrices[this.subscription_cycle]['limited'].sms_credits;
      }
      // }

      this.subscriptionForm.get('hirecheck').setValue(this.subscriptionApps ? Boolean(this.subscriptionApps.hire) : false);
      this.subscriptionForm.get('hrcheck').setValue(this.subscriptionApps ? Boolean(this.subscriptionApps.hr) : false);
      this.subscriptionForm.get('crmcheck').setValue(this.subscriptionApps ? Boolean(this.subscriptionApps.crm) : false);
      this.subscriptionForm.get('selected_card').setValue(this.default_card ? this.default_card.toString() : '');

      this.selectedCc();
      this.checkCurrency();
      this.calTotal();
      this.appendBillingAddress();
    });
  }

  appendBillingAddress() {
    let lastRec;
    Object.keys(this.pay_billing_shipping).forEach(key => {
      lastRec = this.pay_billing_shipping[key];
    })

    if (!isEmpty(lastRec)) {
      this.changeCountry(lastRec.country);
      this.subscriptionForm.get('billing_street').setValue(lastRec.street);
      this.subscriptionForm.get('billing_city').setValue(lastRec.city);
      this.subscriptionForm.get('billing_country').setValue(lastRec.country);
      this.subscriptionForm.get('billing_state').setValue(lastRec.state);
      this.subscriptionForm.get('billing_zip').setValue(lastRec.zip);
    }
  }

  addonsPlan() {
    Object.keys(this.addonPrices).forEach(key => {
      if (isEmpty(this.addonPrices[key].limited)) {
        this.addonsByPlan['limited'][key] = false
      } else {
        this.addonsByPlan['limited'][key] = true
      }
    })
  }

  addonsCycle() {
    Object.keys(this.addonPrices).forEach(key => {
      this.addonsByCycle[key] = {};
      if (isEmpty(this.addonPrices[key].limited)) {
        this.addonsByCycle[key]['limited'] = false
      } else {
        this.addonsByCycle[key]['limited'] = true
      }
    })
  }

  optUnlimited(user?) {
    if (user == 'hire_users' || user == '') {
      if (this.subscriptionForm.value['opt_hire_unlimited']) {
        this.addLicesesBalanceToWallet('hire_users', true);
        if (!isEmpty(this.addonPrices[this.subscription_cycle].unlimited_options.hire_users)) {
          this.hire_users = this.addonPrices[this.subscription_cycle].unlimited_options.hire_users;
          this.is_hire_disabled = true;
        } else {
          this.hire_users = this.addonPrices[this.subscription_cycle].limited.hire_users;
          this.is_hire_disabled = false;
        }
      } else {
        this.addLicesesBalanceToWallet('hire_users', false);
        this.hire_users = this.addonPrices[this.subscription_cycle].limited.hire_users;
        this.is_hire_disabled = false;
      }
    }

    if (user == 'hr_users' || user == '') {
      if (this.subscriptionForm.value['opt_hr_unlimited']) {
        this.addLicesesBalanceToWallet('hr_users', true);
        if (!isEmpty(this.addonPrices[this.subscription_cycle].unlimited_options.hr_users)) {
          this.hr_users = this.addonPrices[this.subscription_cycle].unlimited_options.hr_users;
          this.is_hr_disabled = true;
        } else {
          this.hr_users = this.addonPrices[this.subscription_cycle].limited.hr_users;
          this.is_hr_disabled = false;
        }
      } else {
        this.addLicesesBalanceToWallet('hr_users', false);
        this.hr_users = this.addonPrices[this.subscription_cycle].limited.hr_users;
        this.is_hr_disabled = false;
      }
    }

    if (user == 'crm_users' || user == '') {
      if (this.subscriptionForm.value['opt_crm_unlimited']) {
        this.addLicesesBalanceToWallet('crm_users', true);
        if (!isEmpty(this.addonPrices[this.subscription_cycle].unlimited_options.crm_users)) {
          this.crm_users = this.addonPrices[this.subscription_cycle].unlimited_options.crm_users;
          this.is_crm_disabled = true;
        } else {
          this.crm_users = this.addonPrices[this.subscription_cycle].limited.crm_users;
          this.is_crm_disabled = false;
        }
      } else {
        this.addLicesesBalanceToWallet('crm_users', false);
        this.crm_users = this.addonPrices[this.subscription_cycle].limited.crm_users;
        this.is_crm_disabled = false;
      }
    }
    if (user == 'mass_mails_credits' || user == '') {
      if (this.subscriptionForm.value['opt_massmails_unlimited']) {
        if (!isEmpty(this.addonPrices[this.subscription_cycle].unlimited_options.mass_mails_credits)) {
          this.subscriptionForm.get('opt_massmails_unlimited').setValue(true);
          this.is_mass_disabled = true;
        } else {
          this.subscriptionForm.get('opt_massmails_unlimited').setValue(false);
          this.is_mass_disabled = false;
        }
      } else {
        this.mass_mails_credits = this.addonPrices[this.subscription_cycle].limited.mass_mails_credits;
        this.is_mass_disabled = false;

      }
    }

    if ((user == 'sms_credits' || user == '') && this.getAddonAccess('sms_credits')) {
      if (this.subscriptionForm.value['opt_sms_credits_unlimited']) {
        if (!isEmpty(this.addonPrices[this.subscription_cycle].unlimited_options.sms_credits)) {
          this.subscriptionForm.get('opt_sms_credits_unlimited').setValue(true);
          this.is_sms_credits_disabled = true;
        } else {
          this.subscriptionForm.get('opt_sms_credits_unlimited').setValue(false);
          this.is_sms_credits_disabled = false;
        }
      } else {
        this.sms_credits = this.addonPrices[this.subscription_cycle].limited.sms_credits;
        this.is_sms_credits_disabled = false;
      }
    }

    if (user == 'contacts_search_credits' || user == '') {
      if (this.subscriptionForm.value['opt_contact_unlimited']) {
        if (!isEmpty(this.addonPrices[this.subscription_cycle].unlimited_options.contacts_search_credits)) {
          this.subscriptionForm.get('opt_contact_unlimited').setValue(true);
          this.is_contact_disabled = true;
        } else {
          this.subscriptionForm.get('opt_contact_unlimited').setValue(false);
          this.is_contact_disabled = false;
        }
      } else {
        this.contacts_search_credits = this.addonPrices[this.subscription_cycle].limited.contacts_search_credits;
        this.is_contact_disabled = false;

      }
    }

    if (user == 'linkedin_credits' || user == '') {
      if (this.subscriptionForm.value['opt_linkedin_credits_unlimited']) {
        if (!isEmpty(this.addonPrices[this.subscription_cycle].unlimited_options.linkedin_credits)) {
          this.subscriptionForm.get('opt_linkedin_credits_unlimited').setValue(true);
          this.is_linkedin_credits_disabled = true;
        } else {
          this.subscriptionForm.get('opt_linkedin_credits_unlimited').setValue(false);
          this.is_linkedin_credits_disabled = false;
        }
      } else {
        this.linkedin_credits = this.addonPrices[this.subscription_cycle].limited.linkedin_credits;
        this.is_linkedin_credits_disabled = false;

      }
    }

    if (user == 'i9_forms' || user == '') {
      if (this.subscriptionForm.value['opt_i9_forms_unlimited']) {
        if (!isEmpty(this.addonPrices[this.subscription_cycle].unlimited_options.i9_forms)) {
          this.subscriptionForm.get('opt_i9_forms_unlimited').setValue(true);
          this.is_i9_forms_disabled = true;
        } else {
          this.subscriptionForm.get('opt_i9_forms_unlimited').setValue(false);
          this.is_i9_forms_disabled = false;
        }
      } else {
        this.i9_forms = this.addonPrices[this.subscription_cycle].limited.i9_forms;
        this.is_i9_forms_disabled = false;

      }
    }
  }
  selectedCc() {
    let key = this.subscriptionForm.value["selected_card"];
    this.selected_ccdetails = this.cc_details ? this.cc_details[key] : '';
  }
  checkCurrency() {
    if (this.country_id == 2) {
      this.currency = 'INR';
      this.currency_symbol = '₹';
      this.tdsvalue = 0.10;
      this.gstvalue = 0.18;
    } else {
      this.currency = 'USD';
      this.currency_symbol = '$';
    }
  }

  paymentChange(item) {
    this.selected = item;
    this.payment_mode = item.value;

    if (this.payment_mode == 'invoice_url') {
      this.showTXNID = false;
    } else {
      this.showTXNID = true;
    }

    if (this.payment_mode != '') {
      if (this.payment_mode != 'card') {
        this.showtxn = true;
        this.showcc = false;

        if (this.payment_mode == 'invoice_url') {
          this.subscriptionForm.get('txn_id').clearValidators();
          this.subscriptionForm.get('bank_cheque_attchment').clearValidators();
        } else {
          this.subscriptionForm.get('txn_id').setValidators([Validators.required]);
          this.subscriptionForm.get('bank_cheque_attchment').setValidators([Validators.required]);
        }
        this.subscriptionForm.get('txn_id').updateValueAndValidity();
        this.subscriptionForm.get('bank_cheque_attchment').updateValueAndValidity();

        this.subscriptionForm.get('billing_street').setValidators([Validators.required]);
        this.subscriptionForm.get('billing_city').setValidators([Validators.required]);
        this.subscriptionForm.get('billing_country').setValidators([Validators.required]);
        this.subscriptionForm.get('billing_state').setValidators([Validators.required]);
        this.subscriptionForm.get('billing_zip').setValidators([Validators.required]);
      }
      else {
        this.showtxn = false;
        this.showcc = true;
        // this.subscriptionForm.get('txn_id').clearValidators();
      }
    } else {
      this.showtxn = false;
      this.showcc = false;
    }
  }

  isActive(item) { return this.selected === item; };

  calTotal() {
    if (this.subscriptionForm.value['hirecheck']) {
      if (this.subscriptionForm.value['opt_hire_unlimited']) {
        this.hirecost = this.subscription_cycle * Number(this.hire_users.cost);
        this.unit_prices['hire'] = Number(this.hire_users.cost);
      } else {
        this.hirecost = this.subscriptionForm.value['hire_qty'] * Number(this.hire_users_per_day_cost);
        this.unit_prices['hire'] = Number(this.hire_users_per_day_cost);
      }
    } else {
      this.hirecost = 0;
    }

    if (this.subscriptionForm.value['hrcheck']) {
      if (this.subscriptionForm.value['opt_hr_unlimited']) {
        this.hrcost = this.subscription_cycle * Number(this.hr_users.cost);
        this.unit_prices['hr'] = Number(this.hr_users.cost);
      } else {
        this.hrcost = this.subscriptionForm.value['hr_qty'] * Number(this.hr_users_per_day_cost);
        this.unit_prices['hr'] = Number(this.hr_users_per_day_cost);
      }
    } else {
      this.hrcost = 0;
    }

    if (this.subscriptionForm.value['crmcheck']) {
      if (this.subscriptionForm.value['opt_crm_unlimited']) {
        this.crmcost = this.subscription_cycle * Number(this.crm_users.cost);
        this.unit_prices['crm'] = Number(this.crm_users.cost);
      } else {
        this.crmcost = this.subscriptionForm.value['crm_qty'] * Number(this.crm_users_per_day_cost);
        this.unit_prices['crm'] = Number(this.crm_users_per_day_cost);
      }
    } else {
      this.crmcost = 0;
    }

    if (this.subscriptionForm.value['opt_massmails_unlimited']) {
      var unlimitedmasscost = Number(this.addonPrices[this.subscription_cycle]['unlimited_options']['mass_mails_credits'].cost);
      if (this.subscriptionForm.value['massmail_is_subscribed']) {
        this.massmailcost = unlimitedmasscost * this.subscription_cycle;
      } else {
        this.massmailcost = unlimitedmasscost;
      }
    } else {
      if (this.subscriptionForm.value['mass_slab_index'] != '' && this.subscriptionForm.value['mass_qty'] != '' && this.subscriptionForm.value['mass_qty'] != 0) {
        var unlimitedmasscost = Number(this.addonPrices[this.subscription_cycle].limited.mass_mails_credits[this.subscriptionForm.value['mass_slab_index']].cost);
      } else {
        var unlimitedmasscost = 0;
      }
      this.massmailcost = unlimitedmasscost * Number(this.subscriptionForm.value['mass_qty']);
    }

    if (this.subscriptionForm.value['opt_sms_credits_unlimited']) {
      var unlimited_sms_credits_cost = Number(this.addonPrices[this.subscription_cycle]['unlimited_options']['sms_credits'].cost);
      if (this.subscriptionForm.value['sms_credits_is_subscribed']) {
        this.sms_credits_cost = unlimited_sms_credits_cost * 1;
      } else {
        this.sms_credits_cost = unlimited_sms_credits_cost;
      }
    } else {
      if (this.subscriptionForm.value['sms_credits_slab_index'] != '' && this.subscriptionForm.value['sms_credits_qty'] != '' && this.subscriptionForm.value['sms_credits_qty'] != 0) {
        var unlimited_sms_credits_cost = Number(this.addonPrices[this.subscription_cycle].limited.sms_credits[this.subscriptionForm.value['sms_credits_slab_index']].cost);
      } else {
        var unlimited_sms_credits_cost = 0;
      }
      this.sms_credits_cost = unlimited_sms_credits_cost * Number(this.subscriptionForm.value['sms_credits_qty']);
    }


    if (this.subscriptionForm.value['opt_linkedin_credits_unlimited']) {
      var unlimitedlinkedin_creditscost = Number(this.addonPrices[this.subscription_cycle]['unlimited_options']['linkedin_credits'].cost);
      if (this.subscriptionForm.value['linkedin_credits_is_subscribed']) {
        this.linkedin_credits_cost = unlimitedlinkedin_creditscost * this.subscription_cycle;
      } else {
        this.linkedin_credits_cost = unlimitedlinkedin_creditscost;
      }
    } else {
      if (this.subscriptionForm.value['linkedin_credits_slab_index'] != '' && this.subscriptionForm.value['linkedin_credits_qty'] != 0 && this.subscriptionForm.value['linkedin_credits_qty'] != '') {
        var unlimitedlinkedin_creditscost = Number(this.addonPrices[this.subscription_cycle].limited.linkedin_credits[this.subscriptionForm.value['linkedin_credits_slab_index']].cost);
      } else {
        var unlimitedlinkedin_creditscost = 0;
      }
      this.linkedin_credits_cost = unlimitedlinkedin_creditscost * Number(this.subscriptionForm.value['linkedin_credits_qty']);
    }

    if (this.subscriptionForm.value['opt_contact_unlimited']) {
      var unlimitedcontactcost = Number(this.addonPrices[this.subscription_cycle]['unlimited_options']['contacts_search_credits'].cost);
      if (this.subscriptionForm.value['contact_is_subscribed']) {
        this.contactcost = unlimitedcontactcost * this.subscription_cycle;
      } else {
        this.contactcost = unlimitedcontactcost;
      }
    } else {
      if (this.subscriptionForm.value['contact_slab_index'] != '' && this.subscriptionForm.value['contact_qty'] != 0 && this.subscriptionForm.value['contact_qty'] != '') {
        var unlimitedcontactcost = Number(this.addonPrices[this.subscription_cycle].limited.contacts_search_credits[this.subscriptionForm.value['contact_slab_index']].cost);
      } else {
        var unlimitedcontactcost = 0;
      }
      this.contactcost = unlimitedcontactcost * Number(this.subscriptionForm.value['contact_qty']);
    }

    if (this.subscriptionForm.value['opt_i9_forms_unlimited']) {
      var unlimitedi9formcost = Number(this.addonPrices[this.subscription_cycle]['unlimited_options']['i9_forms'].cost);
      this.i9_forms_cost = unlimitedi9formcost;
    } else {
      if (this.subscriptionForm.value['i9_forms_slab_index'] != '' && this.subscriptionForm.value['i9_forms_qty'] != 0 && this.subscriptionForm.value['i9_forms_qty'] != '') {
        var unlimitedi9formcost = Number(this.addonPrices[this.subscription_cycle].limited.i9_forms[this.subscriptionForm.value['i9_forms_slab_index']].cost);
      } else {
        var unlimitedi9formcost = 0;
      }
      this.i9_forms_cost = unlimitedi9formcost * Number(this.subscriptionForm.value['i9_forms_qty']);
    }

    if (this.subscriptionForm.value['branding'] == 'true') {
      if (this.branding_addon_data.oorwin_branding[this.subscription_cycle].name == 'remove_monthly') {
        this.brandingcost = Number(this.branding_addon_data.oorwin_branding[this.subscription_cycle].cost) * this.subscription_cycle
      } else {
        this.brandingcost = Number(this.branding_addon_data.oorwin_branding[this.subscription_cycle].cost);
      }
    } else {
      this.brandingcost = 0;
    }

    let addonCost = 0;
    if (!isEmpty(this.masterAddons)) {
      Object.values(this.masterAddons).filter(addon => {
        if ((this.subscriptionForm.controls['hirecheck'].value ? this.hire_addons.includes(addon['addon_slug']) : false) || (this.subscriptionForm.controls['hrcheck'].value ? this.hr_addons.includes(addon['addon_slug']) : false) || ((this.subscriptionForm.controls['hrcheck'].value || this.subscriptionForm.controls['hirecheck'].value) ? this.hire_hr_addons.includes(addon['addon_slug']) : false)) {
          if (this.subscriptionForm.value[addon['addon_slug']]) {
            addonCost += Number(this.addonProRates[addon['addon_slug']]);
          }
        }
      });
    }
    this.totalMasterAddonCost = addonCost;
    console.log(this.totalMasterAddonCost, 'totalMasterAddonCost');

    this.total = this.hirecost + this.hrcost + this.crmcost + this.massmailcost + this.contactcost + this.brandingcost + this.linkedin_credits_cost + this.totalMasterAddonCost;

    if (this.getAddonAccess('sms_credits')) {
      this.total += this.sms_credits_cost;
    }

    if (this.getAddonAccess('i9')) {
      this.total += this.i9_forms_cost;
    }

    this.cartSubTotalAmount();
  }

  addLicesesBalanceToWallet(addon_type, is_checked) {
    if (this.licenses_remaining_balance[addon_type] && is_checked) {
      this.wallet_amount = parseFloat(this.wallet_amount) + parseFloat(this.licenses_remaining_balance[addon_type]);
      this.remaining_balance = parseFloat(this.remaining_balance) + parseFloat(this.licenses_remaining_balance[addon_type]);
    }
    else if (this.licenses_remaining_balance[addon_type] && !is_checked) {
      this.wallet_amount = parseFloat(this.wallet_amount) - parseFloat(this.licenses_remaining_balance[addon_type]);
      this.remaining_balance = parseFloat(this.remaining_balance) - parseFloat(this.licenses_remaining_balance[addon_type]);
    }
  }

  cartSubTotalAmount() {
    let sub_total = this.total;
    if (this.total > 0 && this.wallet_amount > 0) {
      let used_wallet_amount = Math.min(this.total, this.wallet_amount);
      sub_total = this.total - used_wallet_amount;
    }
    let taxData = this.calculateTax(sub_total, this.gst_number);
    this.tax = taxData.tax_amount;
    this.tds = taxData.tds_amount;
    this.subtotal = taxData.sub_total;
  }

  usedWalletAmount() {
    let used_wallet_amount = 0;
    if (this.total > 0 && this.wallet_amount > 0) {
      used_wallet_amount = Math.min(this.total, this.wallet_amount);
    }
    return used_wallet_amount;
  }

  calculateTax(sub_total, is_gstin = false) {
    let arrResult = { 'sub_total': sub_total, 'tax_amount': 0, 'tds_amount': 0 };
    if (this.country_id == 2 && sub_total > 0) {
      let tax_amount = sub_total * 0.18;
      let final_amunt = tax_amount + sub_total;

      if (is_gstin) {
        let tds_amount = sub_total * 0.10;
        let total_with_tds = sub_total - tds_amount;
        final_amunt = total_with_tds + tax_amount;
        arrResult['tds_amount'] = tds_amount;
      }

      arrResult['sub_total'] = final_amunt;
      arrResult['tax_amount'] = tax_amount;
      return arrResult;
    }
    return arrResult;
  }

  calculateSum() {
    this.subscriptionForm.valueChanges.subscribe(() => {
      setTimeout(() => {
        this.calTotal();
        this.validate();
      }, 200);
    })
  }

  originalOrder = (): number => {
    return 0;
  }

  massClear() {
    this.subscriptionForm.get('mass_qty').setValue('');
    this.subscriptionForm.get('mass_slab_index').setValue('');
    this.subscriptionForm.get('massmail_is_subscribed').setValue(false);
    this.subscriptionForm.get('opt_massmails_unlimited').setValue(false);
    this.is_mass_disabled = false;
  }

  smsCreditsClear() {
    this.subscriptionForm.get('sms_credits_qty').setValue('');
    this.subscriptionForm.get('sms_credits_slab_index').setValue('');
    this.subscriptionForm.get('sms_credits_is_subscribed').setValue(false);
    this.subscriptionForm.get('opt_sms_credits_unlimited').setValue(false);
    this.is_mass_disabled = false;
  }

  contactClear() {
    this.subscriptionForm.get('contact_qty').setValue('');
    this.subscriptionForm.get('contact_slab_index').setValue('');
    this.subscriptionForm.get('contact_is_subscribed').setValue(false);
    this.subscriptionForm.get('opt_contact_unlimited').setValue(false);
    this.is_contact_disabled = false;
  }

  linkedinCreditsClear() {
    this.subscriptionForm.get('linkedin_credits_qty').setValue('');
    this.subscriptionForm.get('linkedin_credits_slab_index').setValue('');
    this.subscriptionForm.get('linkedin_credits_is_subscribed').setValue(false);
    this.subscriptionForm.get('opt_linkedin_credits_unlimited').setValue(false);
    this.is_linkedin_credits_disabled = false;
  }

  i9_formsClear() {
    this.subscriptionForm.get('i9_forms_qty').setValue('');
    this.subscriptionForm.get('i9_forms_slab_index').setValue('');
    this.subscriptionForm.get('i9_forms_is_subscribed').setValue(false);
    this.subscriptionForm.get('opt_i9_forms_unlimited').setValue(false);
    this.is_i9_forms_disabled = false;
  }

  masterAddonClear(val) {
    this.subscriptionForm.get(val).setValue(false);
    this.subscriptionForm.get(val + '_is_subscribe').setValue(false);
  }

  validate() {
    if (this.subscriptionForm.controls['hirecheck'].value) {
      if (this.subscriptionForm.controls['opt_hire_unlimited'].value) {
        this.subscriptionForm.get('hire_qty').clearValidators();
      } else {
        this.subscriptionForm.controls['hire_qty'].setValidators([Validators.min(Number(this.hireused))]);
      }
    } else {
      this.subscriptionForm.get('hire_qty').clearValidators();
    }

    if (this.subscriptionForm.controls['hrcheck'].value) {
      if (this.subscriptionForm.controls['opt_hr_unlimited'].value) {
        this.subscriptionForm.get('hr_qty').clearValidators();
      } else {
        this.subscriptionForm.controls['hr_qty'].setValidators([Validators.min(Number(this.hrused))]);
      }
    } else {
      this.subscriptionForm.get('hr_qty').clearValidators();
    }

    if (this.subscriptionForm.controls['crmcheck'].value) {
      if (this.subscriptionForm.controls['opt_crm_unlimited'].value) {
        this.subscriptionForm.get('crm_qty').clearValidators();
      } else {
        this.subscriptionForm.controls['crm_qty'].setValidators([Validators.min(Number(this.crmused))]);
      }
    } else {
      this.subscriptionForm.get('crm_qty').clearValidators();
    }

    this.subscriptionForm.get('hire_qty') ? this.subscriptionForm.get('hire_qty').updateValueAndValidity({ onlySelf: true }) : '';
    this.subscriptionForm.get('hr_qty') ? this.subscriptionForm.get('hr_qty').updateValueAndValidity({ onlySelf: true }) : '';
    this.subscriptionForm.get('crm_qty') ? this.subscriptionForm.get('crm_qty').updateValueAndValidity({ onlySelf: true }) : '';
  }

  back() {
    this.turnOffPaymentMode = false;
    this.location.back();
  }
  matTooltipContent(addon) {
    switch (addon) {
      case 'chatbot':
        return "By selecting this checkbox the chatbot credits will be auto renewed once the chatbot credits's expiry date is reached.";
        break;
      case 'digital_onboarding_templates':
        return "By selecting this checkbox the Digital Onboarding & Templates credits will be auto renewed once the Digital Onboarding & Templates credits's expiry date is reached.";
        break;
      case 'job_grabber':
        return "By selecting this checkbox the Job Grabber credits will be auto renewed once the Job Grabber credits's expiry date is reached.";
        break;
      case 'quick_books':
        return "By selecting this checkbox the QuickBooks Integration credits will be auto renewed once the QuickBooks Integration credits's expiry date is reached.";
        break;
      case 'report_builder':
        return "By selecting this checkbox the Custom Report Builder will be auto renewed once the Custom Report Builder's expiry date is reached.";
        break;
      case 'resume_harvesting':
        return "By selecting this checkbox the Resume Harvester will be auto renewed once the Resume Harvester's expiry date is reached.";
        break;
      case 'resume_job_indox':
        return "By selecting this checkbox the Resume & Job Inbox will be auto renewed once the Resume & Job Inbox's expiry date is reached.";
        break;
      case 'video_interviews':
        return "By selecting this checkbox the Video Interviews will be auto renewed once the Video Interviews's expiry date is reached.";
        break;
    }
  }

  showInvoiceUrlPayment() {
    let isActive = 1;
    this.isAccountBalPayment = false;
    this.turnOffPaymentMode = false;
    let totalAmount = this.subtotal.toFixed(2);
    let allowCreditBalance = false;

    if (this.accountBalance > 0 && (parseFloat(this.accountBalance) >= parseFloat(totalAmount))) {
      isActive = 1;
      this.isAccountBalPayment = true;
    }
    else if (this.accountBalance > 0 && (parseFloat(this.accountBalance) < parseFloat(totalAmount))) {
      allowCreditBalance = true;
    }
    
    // else if (this.accountBalance > 0 && totalAmount != this.accountBalance) {
    //   isActive = 0;
    //   this.turnOffPaymentMode = true;
    // }
    // else if (this.accountBalance == 0) {
    //   isActive = 0;
    // }

    this.paymentmodes.forEach(ele => {

      if (this.isAccountBalPayment && ele.slug == 'stripe_balance') {
        ele.isDisable = false;
        ele.isActive = 1;
        ele.name = 'Stripe Credit Balance ' + this.currency_symbol + this.accountBalance;
      }

      if (this.isAccountBalPayment && ele.slug != 'stripe_balance') {
        ele.isDisable = true;
      }

      if (allowCreditBalance && ele.slug != 'stripe_balance') {
        ele.isDisable = false;
      }

      if (!this.isAccountBalPayment && ele.slug == 'stripe_balance') {
        ele.isActive = 0;
      }
      
    })
  }

  checkout() {
    this.showInvoiceUrlPayment();
    if (this.subscriptionForm.get('hire_qty').valid && this.subscriptionForm.get('hr_qty').valid && this.subscriptionForm.get('crm_qty').valid) {
      this.payment = true;
    } else {
      this.commonservice.openSnackBar('warn', 'Please select one addon');
    }
  }

  downloadProforma()
  {

    let postData = {};
    postData['check_data1'] = '';
    postData['customer_email'] = '';
    postData['comments'] = '';
    postData['status'] = true;
    this.setPaymentInfoParam(postData);
    console.log(this.params);

  }

  setPaymentInfoParam(result)
  {
    // if (this.subscription_plan != 'unlimited_inc_addons') {
      if (!isEmpty(this.masterAddons)) {
        Object.values(this.masterAddons).filter(addon => {
          if (!isEmpty(addon)) {
            this.subscriptionAddons[addon['addon_slug']] = {};
            if (this.subscriptionForm.value[addon['addon_slug']] && ((this.subscriptionForm.controls['hirecheck'].value ? this.hire_addons.includes(addon['addon_slug']) : false) || (this.subscriptionForm.controls['hrcheck'].value ? this.hr_addons.includes(addon['addon_slug']) : false) || ((this.subscriptionForm.controls['hrcheck'].value || this.subscriptionForm.controls['hirecheck'].value) ? this.hire_hr_addons.includes(addon['addon_slug']) : false))) {
              this.subscriptionAddons[addon['addon_slug']]['is_unlimited'] = 1;
              this.subscriptionAddons[addon['addon_slug']]['cost'] = this.addonProRates[addon['addon_slug']];
              this.subscriptionAddons[addon['addon_slug']]['credits'] = addon['quantity'];
              this.subscriptionAddons[addon['addon_slug']]['per_pack'] = 1;
              this.subscriptionAddons[addon['addon_slug']]['qty'] = addon['quantity'];
              this.subscriptionAddons[addon['addon_slug']]['is_subscribed'] = this.subscriptionForm.value[addon['addon_slug'] + '_is_subscribe'] ? 1 : 0;
              // this.subscriptionAddons[addon['addon_slug']]['is_enable'] = this.subscriptionForm.value[addon['addon_slug']];
              this.subscriptionAddons[addon['addon_slug']]['total'] = this.addonProRates[addon['addon_slug']];
            }
          }
        });
      }
      // }
      // console.log(this.subscriptionAddons,this.subscription_plan);return;
      this.subscriptionAddons['contacts_search_credits'] = {};
      if (this.subscriptionForm.value['opt_contact_unlimited']) {
        this.subscriptionAddons['contacts_search_credits']['is_unlimited'] = 1
        this.subscriptionAddons['contacts_search_credits']['cost'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].contacts_search_credits.cost;
        this.subscriptionAddons['contacts_search_credits']['credits'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].contacts_search_credits.quantity;
        this.subscriptionAddons['contacts_search_credits']['is_subscribed'] = this.subscriptionForm.value.contact_is_subscribed ? 1 : 0;
        this.subscriptionAddons['contacts_search_credits']['per_pack'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].contacts_search_credits.quantity;
        this.subscriptionAddons['contacts_search_credits']['qty'] = 1;
        this.subscriptionAddons['contacts_search_credits']['total'] = this.contactcost;

      } else {
        this.subscriptionAddons['contacts_search_credits']['is_unlimited'] = 0;

        if (this.subscriptionForm.value['contact_slab_index'] != '' && (this.subscriptionForm.value['contact_qty'] != '' || this.subscriptionForm.value['contact_qty'] != 0)) {
          this.subscriptionAddons['contacts_search_credits']['cost'] = this.contacts_search_credits[this.subscriptionForm.value['contact_slab_index']].cost;
          this.subscriptionAddons['contacts_search_credits']['credits'] = Number(this.subscriptionForm.value['contact_slab_index']) * this.subscriptionForm.value['contact_qty'];
          this.subscriptionAddons['contacts_search_credits']['is_subscribed'] = this.subscriptionForm.value.contact_is_subscribed ? 1 : 0;
          this.subscriptionAddons['contacts_search_credits']['per_pack'] = Number(this.subscriptionForm.value.contact_slab_index);
          this.subscriptionAddons['contacts_search_credits']['qty'] = this.subscriptionForm.value.contact_qty;
          this.subscriptionAddons['contacts_search_credits']['slab_index'] = Number(this.subscriptionForm.value.contact_slab_index);
          this.subscriptionAddons['contacts_search_credits']['total'] = this.contactcost;

        }
        else {
          this.subscriptionAddons['contacts_search_credits'] = {};
        }
      }


      this.subscriptionAddons['linkedin_credits'] = {};
      if (this.subscriptionForm.value['opt_linkedin_credits_unlimited']) {
        this.subscriptionAddons['linkedin_credits']['is_unlimited'] = 1
        this.subscriptionAddons['linkedin_credits']['cost'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].linkedin_credits.cost;
        this.subscriptionAddons['linkedin_credits']['credits'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].linkedin_credits.quantity;
        this.subscriptionAddons['linkedin_credits']['is_subscribed'] = this.subscriptionForm.value.linkedin_credits_is_subscribed ? 1 : 0;
        this.subscriptionAddons['linkedin_credits']['per_pack'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].linkedin_credits.quantity;
        this.subscriptionAddons['linkedin_credits']['qty'] = 1;
        this.subscriptionAddons['linkedin_credits']['total'] = this.linkedin_credits_cost;

      } else {
        this.subscriptionAddons['linkedin_credits']['is_unlimited'] = 0;

        if (this.subscriptionForm.value['linkedin_credits_slab_index'] != '' && (this.subscriptionForm.value['linkedin_credits_qty'] != '' || this.subscriptionForm.value['linkedin_credits_qty'] != 0)) {
          this.subscriptionAddons['linkedin_credits']['cost'] = this.linkedin_credits[this.subscriptionForm.value['linkedin_credits_slab_index']].cost;
          this.subscriptionAddons['linkedin_credits']['credits'] = Number(this.subscriptionForm.value['linkedin_credits_slab_index']) * this.subscriptionForm.value['linkedin_credits_qty'];
          this.subscriptionAddons['linkedin_credits']['is_subscribed'] = this.subscriptionForm.value.linkedin_credits_is_subscribed ? 1 : 0;
          this.subscriptionAddons['linkedin_credits']['per_pack'] = Number(this.subscriptionForm.value.linkedin_credits_slab_index);
          this.subscriptionAddons['linkedin_credits']['qty'] = this.subscriptionForm.value.linkedin_credits_qty;
          this.subscriptionAddons['linkedin_credits']['slab_index'] = Number(this.subscriptionForm.value.linkedin_credits_slab_index);
          this.subscriptionAddons['linkedin_credits']['total'] = this.linkedin_credits_cost;

        }
        else {
          this.subscriptionAddons['linkedin_credits'] = {};
        }
      }

      this.subscriptionAddons['mass_mails_credits'] = {};

      if (this.subscriptionForm.value['opt_massmails_unlimited']) {
        this.subscriptionAddons['mass_mails_credits']['is_unlimited'] = 1
        this.subscriptionAddons['mass_mails_credits']['cost'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].mass_mails_credits.cost;
        this.subscriptionAddons['mass_mails_credits']['credits'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].mass_mails_credits.quantity;
        this.subscriptionAddons['mass_mails_credits']['is_subscribed'] = this.subscriptionForm.value.massmail_is_subscribed ? 1 : 0;
        this.subscriptionAddons['mass_mails_credits']['per_pack'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].mass_mails_credits.quantity;
        this.subscriptionAddons['mass_mails_credits']['qty'] = 1;
        this.subscriptionAddons['mass_mails_credits']['total'] = this.massmailcost;

      } else {
        this.subscriptionAddons['mass_mails_credits']['is_unlimited'] = 0;

        if (this.subscriptionForm.value['mass_slab_index'] != '' && (this.subscriptionForm.value['mass_qty'] != '' || this.subscriptionForm.value['mass_qty'] != 0)) {
          this.subscriptionAddons['mass_mails_credits'] = {};
          this.subscriptionAddons['mass_mails_credits']['cost'] = this.mass_mails_credits[Number(this.subscriptionForm.value['mass_slab_index'])].cost;
          this.subscriptionAddons['mass_mails_credits']['credits'] = Number(this.subscriptionForm.value['mass_slab_index']) * this.subscriptionForm.value['mass_qty'];
          this.subscriptionAddons['mass_mails_credits']['is_subscribed'] = this.subscriptionForm.value.massmail_is_subscribed ? 1 : 0;
          this.subscriptionAddons['mass_mails_credits']['per_pack'] = Number(this.subscriptionForm.value.mass_slab_index);
          this.subscriptionAddons['mass_mails_credits']['qty'] = this.subscriptionForm.value.mass_qty;
          this.subscriptionAddons['mass_mails_credits']['slab_index'] = Number(this.subscriptionForm.value.mass_slab_index);
          this.subscriptionAddons['mass_mails_credits']['total'] = this.massmailcost;
        }
        else {
          this.subscriptionAddons['mass_mails_credits'] = {};
        }
      }


      this.subscriptionAddons['sms_credits'] = {};

      if (this.subscriptionForm.value['opt_sms_credits_unlimited']) {
        this.subscriptionAddons['sms_credits']['is_unlimited'] = 1
        this.subscriptionAddons['sms_credits']['cost'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].sms_credits.cost;
        this.subscriptionAddons['sms_credits']['credits'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].sms_credits.quantity;
        this.subscriptionAddons['sms_credits']['is_subscribed'] = this.subscriptionForm.value.sms_credits_is_subscribed ? 1 : 0;
        this.subscriptionAddons['sms_credits']['per_pack'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].sms_credits.quantity;
        this.subscriptionAddons['sms_credits']['qty'] = 1;
        this.subscriptionAddons['sms_credits']['total'] = this.sms_credits_cost;

      } else {
        this.subscriptionAddons['sms_credits']['is_unlimited'] = 0;

        if (this.subscriptionForm.value['sms_credits_slab_index'] != '' && (this.subscriptionForm.value['sms_credits_qty'] != '' || this.subscriptionForm.value['sms_credits_qty'] != 0)) {
          this.subscriptionAddons['sms_credits'] = {};
          this.subscriptionAddons['sms_credits']['cost'] = this.sms_credits[Number(this.subscriptionForm.value['sms_credits_slab_index'])].cost;
          this.subscriptionAddons['sms_credits']['credits'] = Number(this.subscriptionForm.value['sms_credits_slab_index']) * this.subscriptionForm.value['sms_credits_qty'];
          this.subscriptionAddons['sms_credits']['is_subscribed'] = this.subscriptionForm.value.sms_credits_is_subscribed ? 1 : 0;
          this.subscriptionAddons['sms_credits']['per_pack'] = Number(this.subscriptionForm.value.sms_credits_slab_index);
          this.subscriptionAddons['sms_credits']['qty'] = this.subscriptionForm.value.sms_credits_qty;
          this.subscriptionAddons['sms_credits']['slab_index'] = Number(this.subscriptionForm.value.sms_credits_slab_index);
          this.subscriptionAddons['sms_credits']['total'] = this.sms_credits_cost;
        }
        else {
          this.subscriptionAddons['sms_credits'] = {};
        }
      }


      this.subscriptionAddons['i9_forms'] = {};

      if (this.subscriptionForm.value['opt_i9_forms_unlimited']) {
        this.subscriptionAddons['i9_forms']['is_unlimited'] = 1
        this.subscriptionAddons['i9_forms']['cost'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].i9_forms.cost;
        this.subscriptionAddons['i9_forms']['credits'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].i9_forms.quantity;
        this.subscriptionAddons['i9_forms']['is_subscribed'] = this.subscriptionForm.value.i9_forms_is_subscribed ? 1 : 0;
        this.subscriptionAddons['i9_forms']['per_pack'] = this.addonPrices[this.subscription_cycle]['unlimited_options'].i9_forms.quantity;
        this.subscriptionAddons['i9_forms']['qty'] = 1;
        this.subscriptionAddons['i9_forms']['total'] = this.i9_forms_cost;

      } else {
        this.subscriptionAddons['i9_forms']['is_unlimited'] = 0;

        if (this.subscriptionForm.value['i9_forms_slab_index'] != '' && (this.subscriptionForm.value['i9_forms_qty'] != '' || this.subscriptionForm.value['i9_forms_qty'] != 0)) {
          this.subscriptionAddons['i9_forms'] = {};
          this.subscriptionAddons['i9_forms']['cost'] = this.i9_forms[Number(this.subscriptionForm.value['i9_forms_slab_index'])].cost;
          this.subscriptionAddons['i9_forms']['credits'] = Number(this.subscriptionForm.value['i9_forms_slab_index']) * this.subscriptionForm.value['i9_forms_qty'];
          this.subscriptionAddons['i9_forms']['is_subscribed'] = this.subscriptionForm.value.i9_forms_is_subscribed ? 1 : 0;
          this.subscriptionAddons['i9_forms']['per_pack'] = Number(this.subscriptionForm.value.i9_forms_slab_index);
          this.subscriptionAddons['i9_forms']['qty'] = this.subscriptionForm.value.i9_forms_qty;
          this.subscriptionAddons['i9_forms']['slab_index'] = Number(this.subscriptionForm.value.i9_forms_slab_index);
          this.subscriptionAddons['i9_forms']['total'] = this.i9_forms_cost;
        }
        else {
          this.subscriptionAddons['i9_forms'] = {};
        }
      }

      this.subscriptionAddons['crm_users'] = {};
      this.subscriptionAddons['hire_users'] = {};
      this.subscriptionAddons['hr_users'] = {};
      this.subscriptionAddons['oorwin_branding'] = {};

      if (this.subscriptionForm.value['crmcheck']) {
        this.subscriptionAddons['crm_users']['cost'] = this.crm_users.cost;
        this.subscriptionAddons['crm_users']['error'] = 0;
        this.subscriptionAddons['crm_users']['per_pack'] = 1;
        this.subscriptionAddons['crm_users']['total'] = this.crmcost;
        if (this.subscriptionForm.value['opt_crm_unlimited']) {
          this.subscriptionAddons['crm_users']['credits'] = this.crm_users.quantity;
          this.subscriptionAddons['crm_users']['is_unlimited'] = 1
          this.subscriptionAddons['crm_users']['qty'] = 1;
        } else {
          this.subscriptionAddons['crm_users']['qty'] = this.subscriptionForm.value['crm_qty'];
          this.subscriptionAddons['crm_users']['credits'] = this.subscriptionForm.value['crm_qty'];
          this.subscriptionAddons['crm_users']['is_unlimited'] = 0
        }
      }

      if (this.subscriptionForm.value['hrcheck']) {
        this.subscriptionAddons['hr_users']['cost'] = this.hr_users.cost;
        this.subscriptionAddons['hr_users']['error'] = 0;
        this.subscriptionAddons['hr_users']['per_pack'] = 1;
        this.subscriptionAddons['hr_users']['total'] = this.hrcost;
        if (this.subscriptionForm.value['opt_hr_unlimited']) {
          this.subscriptionAddons['hr_users']['credits'] = this.hr_users.quantity;
          this.subscriptionAddons['hr_users']['is_unlimited'] = 1
          this.subscriptionAddons['hr_users']['qty'] = 1;
        } else {
          this.subscriptionAddons['hr_users']['qty'] = this.subscriptionForm.value['hr_qty'];
          this.subscriptionAddons['hr_users']['credits'] = this.subscriptionForm.value['hr_qty'];
          this.subscriptionAddons['hr_users']['is_unlimited'] = 0
        }
      }

      if (this.subscriptionForm.value['hirecheck']) {
        this.subscriptionAddons['hire_users']['cost'] = this.hire_users.cost;
        this.subscriptionAddons['hire_users']['error'] = 0;
        this.subscriptionAddons['hire_users']['per_pack'] = 1;
        this.subscriptionAddons['hire_users']['total'] = this.hirecost;
        if (this.subscriptionForm.value['opt_hire_unlimited']) {
          this.subscriptionAddons['hire_users']['credits'] = this.hire_users.quantity;
          this.subscriptionAddons['hire_users']['is_unlimited'] = 1
          this.subscriptionAddons['hire_users']['qty'] = 1;
        } else {
          this.subscriptionAddons['hire_users']['qty'] = this.subscriptionForm.value['hire_qty'];
          this.subscriptionAddons['hire_users']['credits'] = this.subscriptionForm.value['hire_qty'];
          this.subscriptionAddons['hire_users']['is_unlimited'] = 0
        }
      }

      if (this.subscriptionForm.value['branding'] === 'true') {
        this.subscriptionAddons['oorwin_branding']['qty'] = 1;
        this.subscriptionAddons['oorwin_branding']['cost'] = this.branding_addon_data.oorwin_branding[this.subscription_cycle].cost;
        this.subscriptionAddons['oorwin_branding']['per_pack'] = 1;
        this.subscriptionAddons['oorwin_branding']['credits'] = 1;
        this.subscriptionAddons['oorwin_branding']['total'] = this.brandingcost;
        this.subscriptionAddons['oorwin_branding']['remove_type'] = this.branding_addon_data.oorwin_branding[this.subscription_cycle].name;
      } else {
        // delete this.subscriptionAddons['oorwin_branding'];
        this.subscriptionAddons['oorwin_branding'] = {};
      }

      this.subscription_apps = {};
      this.subscription_apps['hire'] = this.subscription_plan == 'limited' ? (this.subscriptionForm.value['hirecheck'] ? 1 : 0) : 1;
      this.subscription_apps['hr'] = this.subscription_plan == 'limited' ? (this.subscriptionForm.value['hrcheck'] ? 1 : 0) : 1;
      this.subscription_apps['crm'] = this.subscription_plan == 'limited' ? (this.subscriptionForm.value['crmcheck'] ? 1 : 0) : 1;

      this.params = {
        cart: this.subscriptionAddons,
        subscription_apps: this.subscription_apps,
        subscription_cycle: this.subscription_cycle,
        subscription_plan: this.subscription_plan,
        payment_mode: this.payment_mode,
        company_id: this.company_id,
        remaining_balance: this.remaining_balance,
        is_cpanel: 1
      }

      if (this.payment_mode == 'card') {
        let selected_billing = this.pay_billing_shipping[this.subscriptionForm.value['selected_card']];
        this.params['billing'] = selected_billing;
        this.params['billing']['stripe_card_ref'] = this.subscriptionForm.value['selected_card'];
        this.params['billing']['selected_country_id'] = selected_billing.country;
        this.params['master'] = {};
        this.params['master']['existing_cc_selection'] = this.subscriptionForm.value['selected_card'];
      } else {
        this.params['billing'] = {};
        this.params['billing']['street'] = this.subscriptionForm.value['billing_street'];
        this.params['billing']['city'] = this.subscriptionForm.value['billing_city'];
        this.params['billing']['selected_country_id'] = this.subscriptionForm.value['billing_country'];
        this.params['billing']['state'] = this.subscriptionForm.value['billing_state'];
        this.params['billing']['zip'] = this.subscriptionForm.value['billing_zip'];

        this.params['master'] = '';
        this.params['txn_id'] = this.subscriptionForm.value['txn_id'];
      }

      this.params['comments'] = isEmpty(result.comments)?'':result.comments;
      if (result.check_data1) {
        this.params['mail_toadmin'] = result.check_data1;
        this.params['customer_email'] = result.customer_email;
      }
      this.params['isAccountBalancePayment'] = this.isAccountBalPayment;
      
  }

  save(type = false) {

    // if (type && this.subtotal <= 0 && this.total <= 0) {
    //   this.commonservice.openSnackBar('warn', 'Please add one addon');
    //   return false;
    // }

    if ((!this.subscriptionForm.valid || !this.payment_mode) && this.subtotal > 0) {
      this.commonservice.openSnackBar('warn', 'Please submit required details');
      this.subscriptionForm.get('txn_id').markAsTouched();
      return false;
    }

    let msg; let emailInfo;
    if (this.payment_mode == 'invoice_url' && this.isAccountBalPayment) {
      msg = 'Are you sure, Do you want to process payment using stripe credit balance?';
      emailInfo = '';
    }
    else if (this.payment_mode == 'invoice_url' && !this.isAccountBalPayment) {
      msg = 'Are you sure, Do you want to send invoice payment link to customer?';
      emailInfo = this.user_data.email;
    }
    else {
      msg = 'Are you sure. Do you want to pay?';
      emailInfo = '';
    }
    // console.log(this.subscriptionForm);
    const data = [];
    data['title'] = 'Alert';
    data['message'] = msg;
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    data['check_data1'] = 'Send invoice email to super admin';
    data['email'] = emailInfo;
    const dialogRef = this.dialog.open(CustomConfirmDialogComponent, { width: '35%', data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (!result.status) {
        return false;
      }
      this.setPaymentInfoParam(result);
      
      if (this.payment_mode == 'invoice_url') {
        this.params['payment_type'] = 'cart';
        this.updateCheckout = this.companyDetailService.invoiceURLCheckout(this.params).toPromise();
      } else {
        this.formData.append('requestData', JSON.stringify(this.params));
        this.formData.append('is_cpanel', '1');
        (this.subtotal == 0) ? this.formData.append('is_zero_payment', '1') : this.formData.append('is_zero_payment', '0');
        this.updateCheckout = this.companyDetailService.addonPaymentCheckout(this.formData).toPromise();
      }

      this.updateCheckout.then(res => {
        if (res.status == 1) {
          if (this.isAccountBalPayment) {
            this.commonservice.getLoader();
            setTimeout(() => {
              this.commonservice.openSnackBar('primary', res.data.message);
              this.commonservice.getLoader();
              this.router.navigate(['company_details/' + this.company_id + '/billingdetails']);
            }, 8000);
          } 
          else {
            this.commonservice.openSnackBar('primary', res.data.message);
            this.router.navigate(['company_details/' + this.company_id + '/billingdetails']);
          }
        }
        else {
          this.commonservice.openSnackBar('warn', res.data.message);
        }
      });
    })
  }

  getAddonAccess(addon) {
    if (addon == 'sms_credits' && this.country_id == 2) {
      return true;
    }
    else if (addon == 'i9' && this.country_id == 1) {
      return true;
    }
    return false;
  }

  onSelectFile1(event) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url1 = event.target['result'];
      }
      //set uploaded file object to 'social_media_logo'
      this.formData.append('bank_cheque_attchment', event.target.files[0]);
    }
  }

}

