import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';

@Component({
  selector: 'app-confirm-exit-request-approve',
  templateUrl: './confirm-exit-request-approve.component.html',
  styleUrls: ['./confirm-exit-request-approve.component.scss']
})
export class ConfirmExitRequestApproveComponent implements OnInit {

  addRefund: FormGroup;
  moreAmount = false;
  title = [];
  otherText:any = '';
  showOtherText:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ConfirmExitRequestApproveComponent>
  ) {
    this.addRefund = this.fb.group({
      reason : ['', Validators.required],
      free_access_type: ['current_subscription', Validators.required]
    });
  }

  ngOnInit() {
    this.title[0] = this.data.message.split('#')[0];
    this.title[1] = this.data.message.split('#')[1];
    if (this.data.otherText) {
      this.otherText = this.data.otherText;
    }
    if (this.data.showOtherText) {
      this.showOtherText = this.data.showOtherText;
    }
  }

  closeWithComments() {
    if (this.addRefund.valid) {
      let data = {};
      data['commets'] = this.addRefund.value.reason;
      data['free_access_type'] = this.addRefund.value.free_access_type;
      data['approved'] = true;
      this.dialogRef.close(data);
    }
  }

  closeDialog() {
    let data = {};
    data['approved'] = false; 
    this.dialogRef.close(data);
  }

}
