<div class="page-layout carded fullwidth inner-scroll">
    <mat-toolbar-row class="secondary-headers w-100-p px-20">
        <h2 class="sub-heading">Due Customers</h2>
    </mat-toolbar-row>
    <div class="center px-20">
        <div class="content-card mt-12 mb-52">
            <div class="content" fusePerfectScrollbar>
                <div fxLayout='row' fxLayoutAlign="start center" class="fuse-white-bg px-12 py-4">
                    <div fxFlex="25" fxLayout='row wrap' fxLayoutAlign="start center">
                        <mat-button-toggle-group name="country" (change)="changeCounry($event)" [value]="listSelectedCountry">
                            <mat-button-toggle value="1">USA</mat-button-toggle>
                            <mat-button-toggle value="2">India</mat-button-toggle>
                            <mat-button-toggle value="702">Singapore</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                    <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="start baseline" *ngIf="params.country_id">
                        <div  class="sub-heading">
                            Due Amount :&nbsp;
                        </div>
                        <div fxFlex="35" class="sub-heading ">
                            {{currencySymbol}}{{revenewSummary.due_amount | number : '1.2-2'}}
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="dataSource">
                    <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12 py-12">
                        <div class="search-wrapper" fxFlex="30">
                            <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
                                [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue"
                                placeholder="Search by Customer">
                            <mat-icon class="search_icon">search</mat-icon>
                            <mat-icon class="close s-20 danger-fg" *ngIf="searchValue"
                                (click)="searchValue=''; applyFilter('');">
                                close
                            </mat-icon>
                        </div>
                        <div *ngIf="totalRecords>0">
                            <button mat-flat-button class="primary" (click)="exportExcel()">Export</button>
                        </div>
                    </div>
                    <div class="">
                        <div class="px-12 pb-12">
                            <mat-table [dataSource]="dataSource">

                                <ng-container matColumnDef="sno">
                                    <mat-header-cell *matHeaderCellDef fxFlex="60px"> S.No </mat-header-cell>
                                    <mat-cell *matCellDef="let element" fxFlex="60px" > {{element.s_no}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="customer_name">
                                    <mat-header-cell *matHeaderCellDef fxFlex="200px"> customer name </mat-header-cell>
                                    <mat-cell *matCellDef="let element" fxFlex="200px" > {{element.name}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="region">
                                    <mat-header-cell *matHeaderCellDef> region </mat-header-cell>
                                    <mat-cell *matCellDef="let element" > {{element.region}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="bill_amount">
                                    <mat-header-cell *matHeaderCellDef> bill amount </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.billing_amount}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="billing_cycle">
                                    <mat-header-cell *matHeaderCellDef> billing cycle </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.billing_cycle}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="billing_date">
                                    <mat-header-cell *matHeaderCellDef> billing date </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.billing_date}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="end_date">
                                    <mat-header-cell *matHeaderCellDef> subscription end date </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.subscription_end_date}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="license_cost">
                                    <mat-header-cell *matHeaderCellDef> license cost </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.licenses_amount}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="addon_cost">
                                    <mat-header-cell *matHeaderCellDef> addon cost </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.addons_amount}} </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns">
                                </mat-row>
                            </mat-table>

                            <div *ngIf="totalRecords == 0" class="text-center">
                                <h2 class="sub-heading">No Records Found</h2>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>