<div class="text-center my-12">
    <img class="logo-icon" src="assets/images/logo/logo.svg">
</div>
<mat-divider cdkFocusRegionEnd></mat-divider>
<div fxLayout="column" fxFlex fusePerfectScrollbar>

    <mat-nav-list role="list">

        <mat-list-item *ngFor="let navigationItem of sidebarItems" routerLink="{{navigationItem.slug}}"
            routerLinkActive="primary" (click)="close()">
            <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">

                <mat-icon mat-list-icon class="mr-8 secondary-text s-16">
                    {{navigationItem.icon}}
                </mat-icon>

                <span class="secondary-text text-bold" fxLayout="row" fxLayoutAlign="center center">
                    {{navigationItem.name}}
                </span>

            </div>

        </mat-list-item>

    </mat-nav-list>

</div>