import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { CommonService } from 'app/services/common.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormArray, Validators, FormArrayName, FormControl } from '@angular/forms';
import { ValidateService } from 'app/services/validate.service';
import { ClientsService } from 'app/services/clients.service';

@Component({
  selector: 'app-mxrecords-validation-remainder',
  templateUrl: './mxrecords-validation-remainder.component.html',
  styleUrls: ['./mxrecords-validation-remainder.component.scss']
})
export class MxrecordsValidationRemainderComponent implements OnInit, AfterViewInit {

  @ViewChild('ToEmailchipList', { static: false }) ToEmailchipList: MatChipList;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  MXRecordsValidation: FormGroup;
  cc: boolean;
  bcc: boolean;
  shotochips: boolean = true;
  ckeditor: any;

  constructor(
    private commonService: CommonService,
    private clientService: ClientsService,
    public dialog: MatDialog,
    private validateservice: ValidateService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MxrecordsValidationRemainderComponent>
  ) {

  }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit(): void {}

  validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }
  onReady(e) {
    this.ckeditor = e.editor;
  }

  createForm() {
    this.MXRecordsValidation = this.fb.group({
      cc: new FormArray([]),
      bcc: new FormArray([]),
      subject: ['', Validators.required],
      mail_body: ['', Validators.required]
    })
  }

  // showCc(e) {
  //   if (this.cc) {
  //     this.cc = false;
  //     let ccmails = <FormArray>this.MXRecordsValidation.get('cc');
  //     while (ccmails.length > 0) {
  //       ccmails.removeAt(0);
  //     }
  //   } else {
  //     this.cc = true;
  //   }
  // }

  // showBcc(e) {
  //   if (this.bcc) {
  //     this.bcc = false;
  //     let bccmails = <FormArray>this.MXRecordsValidation.get('bcc');
  //     while (bccmails.length > 0) {
  //       bccmails.removeAt(0);
  //     }
  //   } else {
  //     this.bcc = true;
  //   }
  // }

  // addEmail(event: MatChipInputEvent, field): void {
  //   const input = event.input;
  //   const value = event.value;

  //   if ((value || '').trim()) {
  //     const control = <FormArray>this.MXRecordsValidation.get(field);
  //     if (!this.checkDuplicateEmail(value.trim(), control)) {
  //       control.push(this.fb.control(value.trim(), [Validators.email]));
  //     }
  //   }

  //   if (input) {
  //     input.value = '';
  //   }
  // }

  // removeEmail(index: any, field: any): void {
  //   const control = <FormArray>this.MXRecordsValidation.get(field);
  //   if (index >= 0) {
  //     control.controls.splice(index, 1);
  //   }
  // }

  // checkDuplicateEmail(value: any, control: any) {
  //   let checkEmail = control.controls.filter(item => item.value === value);
  //   if (checkEmail.length === 0) {
  //     return false
  //   }
  //   return true;
  // }

  send() {
    if (this.MXRecordsValidation.valid) {
      let params = {
        cc: this.MXRecordsValidation.controls.cc.value,
        bcc: this.MXRecordsValidation.controls.bcc.value,
        mail_body: this.MXRecordsValidation.controls.mail_body.value,
        subject: this.MXRecordsValidation.controls.subject.value,
      }
      this.clientService.sendDomainVerifyRemainder(params).subscribe(res => {
        if (res.status) {
          this.commonService.openSnackBar('primary', res.message);
          this.createForm();
        } else {
          this.commonService.openSnackBar('warn', res.message);
        }
      })
    } else {
      this.validateservice.validateAllFormFields(this.MXRecordsValidation);
    }
  }

}
