import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  userForm: FormGroup;
  constructor(private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FilterDialogComponent>
    ) {
    this.userForm = this.fb.group({
      users: [''],
      // modules: [''],
      from_date: [''],
      to_date: ['']
    })
  }

  ngOnInit() {
  }
  
  reset() {
    this.userForm.reset();
  }
  save() {
      this.dialogRef.close(this.userForm.value);
  }
}
