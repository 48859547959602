<h1 mat-dialog-title class='text-uppercase' *ngIf="data.signup_request_id">
    {{data.module_name}} Comments

</h1>
<h1 mat-dialog-title class='text-uppercase' *ngIf="!data.signup_request_id">
    
    {{data.module_name}} Activities

</h1>
<mat-divider></mat-divider>
<mat-dialog-content *ngIf="!isModuleComments">
    <!-- <app-module-activities [moduleSlug]='module_slug' [companyId]='company_id' [showCompanyName]='show_company_name'
        [isModel]="true"></app-module-activities> -->
    <app-common-module-activity [moduleSlug]='module_slug' [signupRequestId]='signup_request_id' [companyId]='company_id' [showCompanyName]='show_company_name'
        [isModel]="true"></app-common-module-activity>
</mat-dialog-content>

<mat-dialog-content *ngIf="isModuleComments">
    <app-comments [moduleSlug]='module_slug' [companyId]='company_id' [recordId]="record_id"></app-comments>
</mat-dialog-content>

<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
</mat-dialog-actions>