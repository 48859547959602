<h1 mat-dialog-title class='text-uppercase'>Purchased Addon List</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <div class="page-layout carded fullwidth inner-scroll">
    <div class="center px-20">
      <div class="">
        <div class="content" fusePerfectScrollbar>
          <ng-container *ngIf="dataSource">
            <div class="px-12 pb-12">
              <mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="addon">
                    <mat-header-cell *matHeaderCellDef> Addon </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="billing_type">
                    <mat-header-cell *matHeaderCellDef> Billing Type </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.billing_type}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="start_date">
                <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.start_date | date:"MM/dd/yyyy" }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="end_date">
                <mat-header-cell *matHeaderCellDef>End Date </mat-header-cell>
                <mat-cell *matCellDef="let element" > {{element.end_date | date:"MM/dd/yyyy" }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="credits">
                  <mat-header-cell *matHeaderCellDef> Credits </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.credits}} </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="used">
                  <mat-header-cell *matHeaderCellDef> Used </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.used}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef fxFlex="24"> Action </mat-header-cell>
                    <mat-cell *matCellDef="let element"  fxFlex="24"> 
                        <mat-form-field appearance='outline' class="w-100 mr-8" style="margin-top: 5px;"
                            matTooltipPosition="above">
                            <mat-label>Extend End Date</mat-label>
                            <input matInput [matDatepicker]="picker1" (click)="picker1.open()" (dateChange)="onDateChange($event, element)"
                                value="{{element.end_date}}">
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>

                        <button mat-button class="primary" *ngIf="element.billing_type == 'paid' && element.is_subscribed == 0" (click)="subscribeAddon(element, 1)">Subscribe</button>
                        <button mat-button class="warn" *ngIf="element.billing_type == 'paid' && element.is_subscribed == 1" (click)="subscribeAddon(element, 0)">UnSubscribe</button>
                    </mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
  
              <div *ngIf="dataSource.length == 0" class="text-center">
                <h2 class="sub-heading">No Records Found</h2>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
</mat-dialog-actions>
