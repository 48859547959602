import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { CommonService } from 'app/services/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
// import { element } from '@angular/core/src/render3';
import { Router } from '@angular/router';
import { ExcelService } from 'app/services/excel.service';
import { DatePipe } from '@angular/common';
import { ReportsService } from 'app/services/reports.service';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-client-subscriptions',
  templateUrl: './client-subscriptions.component.html',
  styleUrls: ['./client-subscriptions.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ClientSubscriptionsComponent implements OnInit {

  displayedColumns: string[] = ['name', 'start_date','end_date', 'renewal_date', 'subsription_cycle', 'amount', 'status','sales_user_name','support_user_name'];
  dataSource:any;
  searchValue = '';
  length: number = 10;
  pageSize: number = 15;
  index: number = 1;
  clientSubscriptionForm: FormGroup;
  selectedValue: boolean = false;
  toppingList: any;
  getTableData: any;
  countries = [
    { value: '1', viewValue: 'USA' },{ value: '2', viewValue: 'India' }, { value: '702', viewValue: 'Singapore'}
  ];
  status_list = [
    { value: 'renewed', viewValue: 'Renewed' }, { value: 'due', viewValue: 'Due' },{ value: 'upcoming', viewValue: 'Upcoming' }
  ];
  country_id: any = '1';
  params = {page:1, limit:15, country_id:this.country_id, status_type:'upcoming'};
  cpanelusers: any;

  constructor(private fb: FormBuilder,
    private commonservice:CommonService, 
    private reportService: ReportsService,
    public router: Router,private excelService: ExcelService,
    private datepipe: DatePipe,) {
    this.clientSubscriptionForm = this.fb.group({
      Year_Month: [moment()],
      client_ids:[''],
      status_type:['upcoming']
      // monthWiseSelection: [''],
      // selected_date:['']
    });
  }

  ngOnInit() {
    this.commonservice.companyList().subscribe(res => {
      if (res.status === 1) {this.toppingList = res.data;}
    });
    this.params['is_month'] =  1;
    this.params['is_date'] = 0;
    this.getSubscriptionHistory();
  }

  getSubscriptionHistory() {
    this.reportService.subscriptionHistory(this.params).subscribe(result => {
      if(result.status == 1) {
        this.getTableData = result.data.data;
        this.length = this.getTableData.total;
        this.pageSize = result.data.data.per_page;
        this.index = result.data.data.current_page;
        this.dataSource = new MatTableDataSource(result.data.data.data);
        this.cpanelusers = result.data.cpanelusers;
      }
    }); 
  }

  pageEvent(event) {
    this.params['limit'] = event.pageSize;
    this.params['page'] = event.pageIndex + 1;
    this.getSubscriptionHistory();
  }
  
  applyFilter(filterValue: string) {
    this.params['search_key'] = filterValue;
    this.params['page'] = 1;
    // console.log(this.params);
    this.getSubscriptionHistory();
  }

  getUserName(id){
    return this.cpanelusers[id];
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.clientSubscriptionForm.get('Year_Month').value;
    ctrlValue.year(normalizedYear.year());
    this.clientSubscriptionForm.get('Year_Month').setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.clientSubscriptionForm.get('Year_Month').value;
    ctrlValue.month(normalizedMonth.month());
    this.clientSubscriptionForm.get('Year_Month').setValue(ctrlValue);
    let val = moment(ctrlValue).format('YYYY-MM');
    this.params['year_month'] = val;
    this.params['page'] = 1;
    // this.params['selected_date'] = '';
    // if (this.clientSubscriptionForm.value.monthWiseSelection) {
    //   this.params['is_month'] = 1;
    //   this.params['is_date'] = 0;
    // } else{
    //   this.params['is_month'] = 0;
    //   this.params['is_date'] = 1;
    // }
    datepicker.close();
    this.getSubscriptionHistory();
    // console.log(this.params);
  }
  clientChange() {
    this.params['client_ids'] = this.clientSubscriptionForm.value.client_ids ? this.clientSubscriptionForm.value.client_ids.map(x => x).join(",") : '';
    this.params['page'] = 1; 
    // console.log(this.params);
    this.getSubscriptionHistory();
  }
  appTypeChange() {
    this.params['status_type'] = this.clientSubscriptionForm.value.status_type;
    this.params['page'] = 1; 
    this.getSubscriptionHistory();
  }
  onDateChange() {
    this.params['selected_date'] = moment(this.clientSubscriptionForm.value.selected_date).format("YYYY-MM-DD");
    this.params['year_month'] = '';
    this.params['page'] = 1; 

    if (this.clientSubscriptionForm.value.monthWiseSelection) {
      this.params['is_month'] = 1;
      this.params['is_date'] = 0;
    } else{
      this.params['is_month'] = 0;
      this.params['is_date'] = 1;
    }
    // console.log(this.params);
    this.getSubscriptionHistory();
  }

  subCycle(element) {
    if (element === 1) {
      return 'Monthly';
    }
    else if (element === 3) {
      return 'Quarterly';
    }
    else if (element === 6) {
      return 'Half yearly';
    }
    else if (element === 12) {
      return 'Yearly';
    }
    else {
      return '-'
    }
  }

  renewCompany(record) {
    this.commonservice.subscriptionType = 'renew';
    this.router.navigate(['company_details/'+record.company_id+'/renew']);
  }

  renewalDate(record) {
    return  moment(record.end_date).add(1,'day').format("MM/DD/YYYY");
  }
  renewalStatus(record, type='') {
    let EndDate = moment(record.end_date).add(1,'day').format("YYYY-MM-DD");
    let to_day = moment().format("YYYY-MM-DD");

    if(EndDate > to_day) {
      let remaining =  moment(EndDate).diff(to_day, 'days');
      if(type == 'action') {
        return remaining;
      } else if(type == 'status') {
        return remaining+' day(s) to left';
      }
    } else if( EndDate <= to_day ) {
      return 'Due'
    }

  }

  navigate(element, blank) {
    // this.router.navigate(['company_details', element.company_id]);
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }

  exportExcel() {
    this.params.limit = this.length;
    this.reportService.subscriptionHistory(this.params).subscribe(result => {
      if (result.status === 1) {
        let prepared_data = this.prepareExcelData(result.data.data.data);
        let sheet_name = 'Customers Renewal List ';
        this.excelService.exportAsExcelFile(prepared_data, sheet_name);
      }
    })
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Company Name'] = element.company_name;
      eachRow['Start Date'] = this.datepipe.transform(element.start_date, 'MM/dd/yyyy');
      eachRow['End Date'] = this.datepipe.transform(element.end_date, 'MM/dd/yyyy');
      // eachRow['Subscription End Date'] = this.datepipe.transform(element.end_date, 'MM/dd/yyyy');
      eachRow['Next Renewal Date'] = this.renewalDate(element);
      eachRow['Billinng Cycle'] = this.subCycle(element.billing_cycle);
      eachRow['Amount'] = this.getCurrencySymbol(this.country_id) + ' ' +parseFloat(element.amount);
      eachRow['Remaining Days to renewal'] = element.is_active==0 ? 'Renewed' : (element.is_active == 1?this.renewalStatus(element, 'status') : '');
      eachRow['Sales Rep'] = element.sales_user_id ? this.cpanelusers[element.sales_user_id] : '-';
      eachRow['Support Executive'] = element.support_user_id ? this.cpanelusers[element.support_user_id] : '-';
      
      ArrData.push(eachRow);
    });
    // console.log('prepared', ArrData);
    return ArrData;
  }

  getCurrencySymbol(country_id) {
    if (country_id == 2) {
      return '₹';
    } else {
      return '$';
    }
  }

  countryChanged() {
    this.searchValue = '';
    this.params['limit'] = 15;
    this.params['page'] = 1;
    this.params['country_id'] = this.country_id;
    this.params['status_type'] = this.clientSubscriptionForm.value.status_type;
    this.getSubscriptionHistory();
  }

}
