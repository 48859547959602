<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">Data Migration Settings</h2>
  </mat-toolbar-row>
  <div class="center px-20 company-info-max-h">
    <div class="content-card mt-12 mb-52">
      <!-- <div class="content"> -->
      <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between stretch" class="m-12">

        <div fxFlex="25" class="mat-elevation-z5 border-radius-4" *ngIf="stageData">
          <ul class="left-nav">
            <li><a class="active">Data Migration</a></li>
          </ul>
        </div>

        <div fxFlex="72" *ngIf="stageData">
          <mat-card class="p-0" fxFlex="100">
            <mat-card-header class="px-12 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
              fxLayoutAlign="space-between center">
              <mat-card-title class="m-0 muli-bold font-size-14">Activities</mat-card-title>
            </mat-card-header>
            <mat-card-content class="px-16 py-12 scroll-content-height" fusePerfectScrollbar>
              <!-- <cdk-virtual-scroll-viewport itemSize="50" style="height: calc(100vh - 260px)" fusePerfectScrollbar> -->
                <div>
                  <div class="drag-list">
                    <div class="drag-box" *ngFor="let stage of allocatedPocData;let i = index">
                      <!-- <div class="drag-custom-placeholder" *cdkDragPlaceholder></div> -->
                      <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <!-- <mat-icon class="">drag_indicator</mat-icon>  -->
                        {{stage.stage_name}}
                      </div>
                      <div fxFlex="230px">
                        <mat-form-field appearance="outline" class="w-100-p">
                          <mat-label>Support POC</mat-label>
                          <mat-select [(ngModel)]="allocatedPocData[i]['poc']" name="poc">
                            <mat-option *ngFor="let user of usersList" [value]='user.name'>{{user.name}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              <!-- </cdk-virtual-scroll-viewport> -->
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <!-- </div> -->
    </div>
  </div>
</div>

<div class='footer'>
  <button mat-button (click)="saveData()">Save</button>
</div>