import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-partner-search',
  templateUrl: './partner-search.component.html',
  styleUrls: ['./partner-search.component.scss']
})
export class PartnerSearchComponent implements OnInit {
  id: any;
  partnerForm: FormGroup;
  compData = [];
  constructor(private route: ActivatedRoute,
    private commonservice: CommonService,
    private companyDetailService: CompanyDetailsService,
    private fb: FormBuilder) {
    this.id = this.route.parent.snapshot.params['id'];
    this.partnerForm = this.fb.group({
      enablepartner: ['']
    })
  }

  ngOnInit() {
    this.companyDetailService.partnerSearch(this.id).subscribe(res => {
      if (res.status === 1) {
        this.compData = res.data;
        console.log(this.compData);
      }
    });
    this.companyDetailService.enablepartnerSearch({ company_id: Number(this.id), is_get: 1 }).subscribe(res => {
      console.log(res, 'enable');
      this.partnerForm.get('enablepartner').setValue(String(res));
    });
  }
  enablePartnerSearch(val) {
    console.log(val);
    this.companyDetailService.enablepartnerSearch({ company_id: Number(this.id), is_get: 0, enable_search: Number(val), partner: 0 }).subscribe(res => {
      if (res.status === 1) {
        this.commonservice.openSnackBar('primary', res.message);
      }
      else {
        this.commonservice.openSnackBar('warn', res.message);
      }
    });
  }
}
