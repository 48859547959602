<h1 mat-dialog-title class='text-uppercase'>Add Additional Charges</h1>
<mat-divider></mat-divider>
<form [formGroup]="lineItemForm" (ngSubmit)="save()" *ngIf="responseLoaded">
<mat-dialog-content>
    <div class="border py-12" >
            <div fxLayout="row wrap" fxLayoutAlign="start center" class="pl-32 mt-20">
                <div fxFlex="50" class="mr-20">
                    <div class="text-bold font-size-16">
                        Service Description<span class="red-fg">*</span>
                    </div>
                </div>
                <div fxFlex="25">
                    <div class="text-bold font-size-16">
                        Price<span class="red-fg">*</span>
                    </div>
                </div>
            </div>
            <ng-container formArrayName="itemArr">
                <div fxLayout="row wrap" fxLayoutAlign="start center" class="pl-32" *ngFor="let item of itemForms.controls; let i=index" [formGroupName]="i">
                    <div fxFlex="50" class="mr-20">
                        <mat-form-field class="w-100-p" appearance="outline">
                            <input type="text" matInput formControlName='title' [ngClass]="{ 'is-invalid': submitted && item.get('title').errors }">
                        </mat-form-field>
                    </div>
                    <div fxFlex="25">
                        <mat-form-field class="w-100-p" appearance="outline">
                            <input type="number" matInput formControlName='price' [ngClass]="{ 'is-invalid': submitted && item.get('price').errors }">
                        </mat-form-field>
                    </div>
                    <div fxFlex="15" fxLayout="row wrap" fxLayoutAlign="center center">
                        <button mat-icon-button class="" (click)="addItem()">
                            <mat-icon class="s-24">add</mat-icon>
                        </button>
                        <button mat-icon-button class="" (click)="removeItem(i)" *ngIf="i != 0">
                            <mat-icon class="s-24">remove</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-dialog-content>
    
    <mat-divider></mat-divider>
    <mat-dialog-actions align='end'>
        <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
        <button mat-button class="primary"  type="submit">Save</button>
    </mat-dialog-actions>
</form>