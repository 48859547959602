<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">Subscription Invoices</h2>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">

        <div class="search-wrapper" fxFlex="30" fxFlex.lt-xl="20">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [(ngModel)]="searchValue" placeholder="Company Name">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>

        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="end center">

            <mat-form-field appearance="outline">
              <mat-select [(ngModel)]="invoiceStatus" (selectionChange)='selectChange($event.value)'>
                  <mat-option *ngFor="let item of selection" [value]='item.value'>
                      {{item.viewValue}}
                  </mat-option>
              </mat-select>
           </mat-form-field>

            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="customer_name">
                <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a class='cursor-pointer'(click)='navigate(element)'>{{element.name}} </a>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="sales_rep">
                <mat-header-cell *matHeaderCellDef> Sales Representative</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.sales_user_id? usersList[element.sales_user_id]:'-'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="invoice_id">
                <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.invoice_id}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="invoice_url">
                <mat-header-cell *matHeaderCellDef> URL </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.invoice_url}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="payment_for">
                <mat-header-cell *matHeaderCellDef> Payment  </mat-header-cell>
                <mat-cell *matCellDef="let element" >  {{element.payment_type}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{getPaymentStatus(element.status)}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="created_on">
                <mat-header-cell *matHeaderCellDef>Created On </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at |date:"MM/dd/yyyy"}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef fxFlex='150px'> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="164px">
                  <button *ngIf="params.status == 0" mat-icon-button class="red-fg" matTooltip="Delete" (click)="deleteRecord(element)" >
                    <mat-icon class="s-18">delete</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
          
              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>