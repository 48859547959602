<h1 mat-dialog-title class='text-uppercase'>{{show_title}}</h1>
<mat-divider></mat-divider>
<form [formGroup] = "addCredits" (ngSubmit)="saveCredits()">
<mat-dialog-content>
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill fxLayoutGap="12px grid">

    <!-- <div fxFlex='48' fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="masterAddons.includes(data.addonSlug)">
      <mat-form-field class="w-100-p" appearance='outline'>
        <mat-label>Credits</mat-label>
        <input type="number" matInput formControlName="selected_pack" (keyup)= "calAmount()">
      </mat-form-field>
    </div>
    <div fxFlex='24' fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="masterAddons.includes(data.addonSlug)">
      <mat-form-field class="w-100-p" appearance='outline'>
        <mat-label>Per Pack</mat-label>
        <input type="number" matInput formControlName="selected_qty" readonly>
      </mat-form-field>
    </div> -->

    <div fxFlex='48' fxLayout="row wrap" fxLayoutAlign="start center">
      <mat-form-field class="w-100-p" appearance='outline'>
        <mat-label>Select</mat-label>
        <mat-select formControlName="selected_pack">
          <mat-option *ngFor="let option of options_list | keyvalue" [value]="option.value.slab_index" >
            {{option.value.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex='24'>
      <mat-form-field class="w-100-p" appearance='outline'>
        <mat-label>Per Pack</mat-label>
        <input type="number" matInput formControlName="selected_qty">
      </mat-form-field>
    </div>
    <!-- <div fxFlex='24'>
      <mat-form-field class="w-100-p" appearance='outline'>
        <mat-label>Amount</mat-label>
        <input type="text" matInput  formControlName="total_cost" value="{{opt_cost}}" readonly style="cursor:no-drop;"> 
      </mat-form-field>
    </div>

    <div fxFlex='48' *ngIf="subscription_type == 'paid'">
      <mat-label class="sub-heading">Usage</mat-label> <br />
      <mat-radio-group aria-label="Usage" formControlName="charge_type">
        <mat-radio-button value="0" class="font-size-14 mr-8" color="primary" [checked]="true">Free</mat-radio-button>
       <mat-radio-button value="1" class="font-size-14" color="primary">Charge</mat-radio-button> 
      </mat-radio-group>
    </div>

    <div fxFlex='48' *ngIf="subscription_type == 'trial'">
      <mat-label class="sub-heading">Usage</mat-label> <br />
      <mat-radio-group aria-label="Usage" formControlName="charge_type">
        <mat-radio-button value="0" class="font-size-14 mr-8" color="primary" [checked]="true">Free</mat-radio-button>
      </mat-radio-group>
    </div> -->

  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button class='primary'>Add</button>
</mat-dialog-actions>
</form>