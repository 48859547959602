import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/services/common.service';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-add-text-recruit-dialog',
  templateUrl: './add-text-recruit-dialog.component.html',
  styleUrls: ['./add-text-recruit-dialog.component.scss']
})
export class AddTextRecruitDialogComponent implements OnInit {
  addTextRecruit: FormGroup;
  saveData: any;
  editData: any;
  provider_name = [{id:1,name:'Oorwin'},{id:7,name:'Vox'}];
  constructor(private fb: FormBuilder,
    private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    public dialogRef: MatDialogRef<AddTextRecruitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.addTextRecruit = this.fb.group({
      // phone_number: ['', [Validators.required, Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]],
      phone_number: ['', Validators.required],
      account_sid: ['', Validators.required],
      auth_token: ['', Validators.required],
      credits: ['', [Validators.required, Validators.pattern('^(0|[1-9][0-9]*)$')]],
      expire_at: [''],
      provider_type:['']
    })
  }

  ngOnInit() {
    console.log(this.data['perform'], 'edit');
    if (this.data['perform'] == 'edit') {
      this.companyDetailService.editTextRecruiter(this.data['id']).subscribe(res => {
        if (res) {
          this.editData = res;
          this.addTextRecruit.patchValue({
            phone_number: res.phone_number,
            account_sid: res.account_sid,
            auth_token: res.auth_token,
            credits: res.credits,
            expire_at: res.expire_at === null ? '' : res.expire_at,
            provider_type : res.provider_type
          })
        }
      })
    }
  }
  addSignup() {
    if (this.addTextRecruit.valid && this.data['perform'] == 'add') {
      this.saveData = {
        phone_number: this.addTextRecruit.value.phone_number,
        account_sid: this.addTextRecruit.value.account_sid,
        auth_token: this.addTextRecruit.value.auth_token,
        credits: this.addTextRecruit.value.credits,
        company_id: this.data['id'],
        provider_type: this.addTextRecruit.value.provider_type
      };
      if (this.addTextRecruit.value.expire_at) {
        this.saveData['expire_at'] = moment(this.addTextRecruit.value.expire_at).format("YYYY-MM-DD")
      }
      console.log(this.saveData);
      this.companyDetailService.addTextRecruiter(this.saveData).subscribe(res => {
        if (res.status === 1) {
          this.dialogRef.close('true');
          this.commonService.openSnackBar('primary', res.message)
        }
        else {
          this.commonService.openSnackBar('warn', 'duplicate number');
        }
      })
    }
    else if (this.addTextRecruit.valid && this.data['perform'] == 'edit') {
      console.log(this.addTextRecruit.value);
      this.editData.phone_number = this.addTextRecruit.value.phone_number;
      this.editData.account_sid = this.addTextRecruit.value.account_sid;
      this.editData.auth_token = this.addTextRecruit.value.auth_token;
      this.editData.credits = this.addTextRecruit.value.credits;
      this.editData.provider_type = this.addTextRecruit.value.provider_type;
      if (this.addTextRecruit.value.expire_at) {
        this.editData['expire_at'] = moment(this.addTextRecruit.value.expire_at).format("YYYY-MM-DD")
      }
      this.companyDetailService.addTextRecruiter(this.editData).subscribe(res => {
        if (res.status === 1) {
          this.dialogRef.close('true');
          this.commonService.openSnackBar('primary', res.message)
        }
        else {
          this.commonService.openSnackBar('warn', 'duplicate number');
        }
      })
    }
  }
}
