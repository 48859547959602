import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from './apiurls';
import { SharedServiceService } from './shared-service.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private sharedService: SharedServiceService
  ) { }

  killAllActiveSessions(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.killAllActiveSessions, params);
  }

  discardReportCustomers(params): Observable<any> {
    return this.sharedService.get(ApiUrls.clients.discardReportCustomers, params);
  }

  updateSalesSupportUser(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.updateSalesSupportUser, params);
  }

  SendActivationEmail(company_id): Observable<any> {
    const url = ApiUrls.clients.sendActivationEmail + '/' + company_id;
    return this.sharedService.get(url, {});
  }

  deleteTrialCompany(company_id): Observable<any> {
    const url = ApiUrls.clients.deleteTrial + '/' + company_id;
    return this.sharedService.get(url, {});
  }

  getActiveCustomers(params): Observable<any> {
    return this.sharedService.get(ApiUrls.clients.activeCustomers, params);
  }

  sendDomainVerifyRemainder(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.sendMXRecordsValidationEmail, params);
  }

  resetUserPassword(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.resetUserPassword, params);
  }

  revenueReport(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.revenueReport, params);
  }

  totalActiveUsers(params) {
    return this.sharedService.post(ApiUrls.topcards.totalactiveusers, params);
  }

  getCustomerActiveUsers(params): Observable<any> {
    return this.sharedService.get(ApiUrls.clients.customerActiveUsers, params);
  }

  revokeUser(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.revokeUser, params);
  }
}
