import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserLicencesDialogComponent } from '../user-licences-dialog/user-licences-dialog.component';
import { SearchCreditDialogComponent } from '../search-credit-dialog/search-credit-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { Location } from '@angular/common';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ConvertFreeLicensesToPaidComponent } from '../convert-free-licenses-to-paid/convert-free-licenses-to-paid.component';
import { RemoveFreeLicensesComponent } from '../remove-free-licenses/remove-free-licenses.component';
import { DecimalPipe, formatNumber } from '@angular/common';
import { add, isEmpty } from 'lodash';
import { AddGstDialogComponent } from './add-gst-dialog/add-gst-dialog.component';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { ConfirmExitRequestApproveComponent } from '../confirm-exit-request-approve/confirm-exit-request-approve.component';
import { MassMailsSplitComponent } from '../mass-mails-split/mass-mails-split.component';
import { AddCustomerBalanceComponent } from '../add-customer-balance/add-customer-balance.component';
import { RenewalRemainderDialogComponent } from '../renewal-remainder-dialog/renewal-remainder-dialog.component';
import { UpdateCompanyinfoDialogComponent } from '../update-companyinfo-dialog/update-companyinfo-dialog.component';
import { CompanyDetailsService } from 'app/services/company-details.service';
import { PurchasedAddonTypeListComponent } from '../purchased-addon-type-list/purchased-addon-type-list.component';
import { InvoiceLineItemsComponent } from '../invoice-line-items/invoice-line-items.component';
import { AddFreeCreditsDialogComponent } from '../add-free-credits-dialog/add-free-credits-dialog.component';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent implements OnInit {

  company_id: any;
  showLoader: boolean;
  layoutData: any;
  CompanyDetails: any;
  BillingAddress: any;
  licenseType: any;
  per_any_license_cost: number = 0;
  license_title: any;
  activeSubscription: any;
  addonId: number;
  mass_mail_opts: any;
  contact_search_opts: any;
  linkedin_credits_opts: any;
  i9_forms_opts: any;
  sms_credits_opts: any;
  dataSource: any;
  displayedColumns = ['name', 'subscriptiontype', 'quantity', 'used', 'cost', 'startdate', 'enddate', 'billable']
  addonParam: { company_id: number; status: string; subscription_id: number; };
  billingDetails: FormGroup;
  is_active: string = 'due';
  addons_arr: any;
  subscription_type: any;
  allow_app_type: any;
  permissions: any = {};
  subscriptionData: any;
  // hire_licenses_data: any;
  // hr_licenses_data: any;
  // crm_licenses_data: any;
  // mass_mails_data: any;
  // contact_search_data: any;
  linkedin_credits: any;
  i9_forms: any;
  sms_credits: any;
  addons_data: any;
  // remaining_hire_licenses: any;
  // remaining_hr_licenses: any;
  // remaining_crm_licenses: any;
  proRatesArr: any;
  addonSlug: string;
  payment_gateway: any;
  country_id: any;
  subscr_start_date: any;
  subscr_end_date: any;
  diffInDays: number;
  hire_license: any;
  hr_license: any;
  crm_license: any;
  licences: any = {};
  pro_rate: number;
  subscription_cycle: any;
  mass_mails_credits: any;
  contacts_search_credits: any;
  grace_period: any;
  actual_subscr_end_date: any;
  is_unlimited: any;
  is_subscr_end: boolean;
  unlimitedobj: any = {};
  itemsarr: any;
  is_grace_subscr_end: boolean;
  gst_number: any;
  company_type:any;
  splittedAddons:any = [];
  splittedAddonsInfo:any = [];
  isLostAccount:any;
  accountBalance:any = 0;

  subscriptions: any;
  isClassicPlanUsingCustomer: any;
  isCustomerCompletlyMigratedFromClassicPlan: any;
  subscribedAddons: any;
  subscribedPlans: any;
  showInTableAddons: any;
  allPaidAddons: any;
  usedLicenses: any;
  reaminPlanAddon: any;
  // reaminPlanAddonArr: any;
  getPlanTtitle:any;
  addonPriceTypes:any;
  package: string;
  toDayDate: Date = new Date();
  isSubscriptionExpired:boolean;
  isExtraChargesAdded:boolean;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    private location: Location,
    private fb: FormBuilder,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.company_id = this.route.parent.snapshot.params['id'];

  }

  setFromData() {
    this.billingDetails = this.fb.group({
      end_date: ['',Validators.required],
      discardReports: ['', Validators.required],
      accountLost: ['', Validators.required],
      bulkImport: ['', Validators.required],
      companyType: ['', Validators.required],
      auto_renewal: ['', Validators.required],
      disableMassMail: ['', Validators.required],
      next_renewal_start_date:['']
    })
  }

  ngOnInit() {
    this.setFromData();
    this.getBillingDetails();
  }

  isSubscriptionEnd() {
    var today = new Date();
    var sub_end_date =  new Date(this.actual_subscr_end_date);

    var diffInDays = Math.floor((Date.UTC(sub_end_date.getFullYear(), sub_end_date.getMonth(), sub_end_date.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) ) /(1000 * 60 * 60 * 24));
    if (diffInDays < 0) {
      this.is_subscr_end = true;

    } else {
      this.is_subscr_end = false
    }
    if(this.layoutData.remaining_days < 0) {
      this.is_grace_subscr_end = true
    } else {
      this.is_grace_subscr_end = false
    }
  }

  returnZero() {
    return 0;
  }

  isEmpty(check) {
    return isEmpty(check);
  }
  isSubscribedApp(app_name:string)
  {
     let avilable_apps = this.layoutData.enabledApps.toString();
     if(avilable_apps.includes(app_name))
     {
       return true;
     }
     return false;
  }
  enabledApps() {
    return this.layoutData.enabledApps.map(app => app.charAt(0).toUpperCase() + app.slice(1))?.toString(); 
  }

  getBillingDetails() {
    this.companyDetailService.getBillingData(this.company_id).subscribe(res => {
      if (res.status == 1) {
        this.layoutData = res.data;
        this.subscriptionData = this.layoutData.subscriptionData;
        this.splittedAddons = this.layoutData.splittedAddons;
        this.splittedAddonsInfo = this.layoutData.splittedAddonsInfo;
        this.company_type = this.layoutData.company_type;
        this.subscribedAddons = this.layoutData.subscribedAddons;
        this.subscribedPlans = this.layoutData.subscribedPlans;
        this.showInTableAddons = this.layoutData.showInTableAddons;
        this.allPaidAddons = this.layoutData.allPaidAddons;
        this.usedLicenses = this.layoutData.usedLicenses;
        this.reaminPlanAddon = this.layoutData.reaminPlanAddonArr;
        this.getPlanTtitle = this.layoutData.getPlanTtitle;
        this.addonPriceTypes = this.layoutData.addonPriceTypes;
        this.isSubscriptionExpired = this.layoutData.isSubscriptionExpired;
        this.isExtraChargesAdded = this.layoutData.isExtraChargesAdded;
        this.CompanyDetails = this.layoutData.companyDetails;
        this.accountBalance = this.layoutData.accountBalance;
        this.isLostAccount = this.CompanyDetails.lost_account;
        this.payment_gateway = this.CompanyDetails.payment_gateway;
        this.country_id = this.CompanyDetails.country_id;
        this.BillingAddress = this.layoutData.address;
        this.subscription_type = this.CompanyDetails.subscription_type;
        this.gst_number = this.CompanyDetails.gst_number;

        this.permissions = this.layoutData.permissions;
        this.activeSubscription = this.layoutData.activeSubscription;
        this.subscription_cycle = this.activeSubscription.subscription_cycle;
        this.subscr_start_date = new Date(this.activeSubscription.start_date);
        this.subscr_end_date = new Date(this.activeSubscription.end_date);
        this.grace_period = this.activeSubscription.grace_period;
        this.actual_subscr_end_date = new Date(this.subscr_end_date.getFullYear(), this.subscr_end_date.getMonth(), (this.subscr_end_date.getDate() - this.grace_period));
        this.getlicences();
        this.isSubscriptionEnd();

        if (this.subscription_type == 'trial') {
          this.getAddonList('free');
        } else if (this.subscription_type == 'paid') {
          this.getAddonList('paid');
        }
        this.billingDetails.patchValue({
          discardReports: String(this.CompanyDetails.discard_reports),
          accountLost: String(this.CompanyDetails.lost_account),
          bulkImport: String(this.CompanyDetails.bulk_import_access),
          companyType: String(this.company_type),
          auto_renewal: String(this.CompanyDetails.auto_renewal),
          disableMassMail: String(this.CompanyDetails.disable_mass_mails),
          end_date:this.activeSubscription.end_date,
          next_renewal_start_date:this.layoutData.nextRenewalStartDate
        })
      }
      else {
        this.commonService.openSnackBar('warn', res.message);
      }
    });
  }

  getlicences() {
    if (this.activeSubscription.subscription_plan == 'limited') {
      if (this.unlimitedobj.hire_users == 1) {
        this.hire_license = this.subscriptionData.unlimited_options.hire_users
      } else {
        this.hire_license = this.subscriptionData.limited.hire_users
      }
      if (this.unlimitedobj.hr_users == 1) {
        this.hr_license = this.subscriptionData.unlimited_options.hr_users
      } else {
        this.hr_license = this.subscriptionData.limited.hr_users
      }
      if (this.unlimitedobj.crm_users == 1) {
        this.crm_license = this.subscriptionData.unlimited_options.crm_users
      } else {
        this.crm_license = this.subscriptionData.limited.crm_users
      }
    } else if (this.activeSubscription.subscription_plan == 'unlimited_inc_addons') {
      this.hire_license = this.subscriptionData.unlimited_inc_addons.hire_users;
      this.hr_license = this.subscriptionData.unlimited_inc_addons.hr_users;
      this.crm_license = this.subscriptionData.unlimited_inc_addons.crm_users;
    } else if (this.activeSubscription.subscription_plan == 'unlimited_excl_addons') {
      this.hire_license = this.subscriptionData.unlimited_excl_addons.hire_users;
      this.hr_license = this.subscriptionData.unlimited_excl_addons.hr_users;
      this.crm_license = this.subscriptionData.unlimited_excl_addons.crm_users;
    }
    this.licences = {
      hire_users: this.hire_license ? this.hire_license : '',
      hr_users: this.hr_license ? this.hr_license : '',
      crm_users: this.crm_license ? this.crm_license : '',
      mass_mails_credits: this.mass_mails_credits ? this.mass_mails_credits : '',
      contacts_search_credits: this.contacts_search_credits ? this.contacts_search_credits : '',
      linkedin_credits: this.linkedin_credits ? this.linkedin_credits : '',
      sms_credits: this.sms_credits ? this.sms_credits : '',
      i9_forms: this.i9_forms ? this.i9_forms : '',
    }
  }

  addGstNumber() {
    const dialogRef = this.dialog.open(AddGstDialogComponent, {
      width: '500px', 
      data: {
        gst_number: this.gst_number,
        company_id: this.company_id,
        company_name:this.CompanyDetails.display_name
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getBillingDetails();
      }
    });
  }

  addRemainderEmails() {
    const dialogRef = this.dialog.open(RenewalRemainderDialogComponent, {
      width: '750px', 
      data: {
        company_id: this.company_id,
        to_email: this.layoutData.to_email,
        cc_email: this.layoutData.cc_email
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getBillingDetails();
      }
    });
  }

  updateCompanyInfo( Fieldtitle, inputFieldName, inputFieldValue ) {
    const data = [];
    data['title'] = Fieldtitle;
    data['inputFieldName'] = inputFieldName;
    data['inputFieldValue'] = inputFieldValue;
    data['company_id'] = this.company_id;
    data['successText'] = 'Update';
    data['closeText'] = 'Close';
    const dialogRef = this.dialog.open(UpdateCompanyinfoDialogComponent, {
      width:'500px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getBillingDetails();
      }
    });
  }

  EnalbeFreeAppAccess(addon_slug) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to Enable Free App Access';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        let saveData: any;
        saveData = {
          'company_id': this.company_id,
          'addon_slug': addon_slug,
          'subscription_id': this.activeSubscription.id,
          'company_name':this.CompanyDetails.display_name
        };
        switch (addon_slug) {
          case 'hire_users':
            saveData['addon_type'] = 'hire';
          break;
          case 'hr_users':
            saveData['addon_type'] = 'hr';
          break;
          case 'crm_users':
            saveData['addon_type'] = 'crm';
          break;
        }
        this.companyDetailService.enableFreeAppAccess(saveData).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.getBillingDetails();
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        });
      }
    })
  }

  calculateProRates() {
    var sub_start_date = new Date(this.subscr_start_date);
    var sub_end_date =  new Date(this.actual_subscr_end_date);

    this.diffInDays = Math.floor((Date.UTC(sub_end_date.getFullYear(), sub_end_date.getMonth(), sub_end_date.getDate()) - Date.UTC(sub_start_date.getFullYear(), sub_start_date.getMonth(), sub_start_date.getDate()) ) /(1000 * 60 * 60 * 24));
    console.log(this.diffInDays);

    console.log(this.diffInDays);
    Object.keys(this.licences).forEach((key) => {
      if (key == 'hire_users' || key == 'hr_users' || key == 'crm_users') {
        var perday = (this.subscription_cycle * this.licences[key].cost) / this.diffInDays;
        var today = new Date();
        var remaining_days = this.actual_subscr_end_date.getDate() - today.getDate();
        // var remaining_days = (from_today_diff / (1000 * 3600 * 24));
        var pro_rate = formatNumber((perday * remaining_days), this.locale, '1.2-2');
        this.proRatesArr[key] = pro_rate;
        console.log(remaining_days);
      }
    })
    console.log(this.proRatesArr);
  }

  getLicenseData() {
    if (this.licenseType == 'hire_users') {
      this.per_any_license_cost = this.licences.hire_users.cost;
      this.license_title = 'HIRE User Licenses';
      this.addonSlug = 'hire_users';
      this.package = 'hire';
    }
    if (this.licenseType == 'hr_users') {
      this.per_any_license_cost = this.licences.hr_users.cost;
      this.license_title = 'HR User Licenses';
      this.addonSlug = 'hr_users';
      this.package = 'hr';
    }
    if (this.licenseType == 'crm_users') {
      this.per_any_license_cost = this.licences.crm_users.cost;
      this.license_title = 'CRM User Licenses';
      this.addonSlug = 'crm_users';
      this.package = 'crm';
    }
  }

  UserLicences(license_type) {
    
    if (!this.is_grace_subscr_end) {
      this.licenseType = license_type;
      this.getLicenseData();
      let prepareData: any;
      let otherData: any;
      otherData = {
        'subscription_type': this.CompanyDetails.subscription_type
      }
      prepareData = {
        'per_license_cost': this.per_any_license_cost,
        'license_title': this.trimString(this.licenseType),
        'company_id': this.company_id,
        'subscription_id': this.activeSubscription.id,
        'company': otherData,
        'addonSlug': this.licenseType,
        'is_unlimited': this.is_unlimited,
        'company_name': this.CompanyDetails.display_name
      }
      
      const dialogRef = this.dialog.open(UserLicencesDialogComponent, {
        width: '50%',
        data: prepareData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getBillingDetails();
        }
      });
    } else {
      this.commonService.openSnackBar('warn', 'Company Subscription has been expired');
    }
  }

  addUnit(slug, plan_slug) {
    this.licenseType = slug;
      let prepareData: any;
      let otherData: any;
      otherData = {
        'subscription_type': this.CompanyDetails.subscription_type
      }
      prepareData = {
        'license_title': this.trimString(this.licenseType),
        'company_id': this.company_id,
        'subscription_id': this.activeSubscription.id,
        'company': otherData,
        'addonSlug': this.licenseType,
        'planSlug': plan_slug,
        'company_name': this.CompanyDetails.display_name,
        'price_type': 'per_unit'
      }
      const dialogRef = this.dialog.open(UserLicencesDialogComponent, {
        width: '50%',
        data: prepareData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getBillingDetails();
        }
      });
  }

  addFreeAddonCredits(slug, plan_slug) {
    let addonType = slug;
    let prepareData: any;
    let otherData: any;
    otherData = {
      'subscription_type': this.CompanyDetails.subscription_type
    }
    prepareData = {
      'addon_title': this.trimString(addonType),
      'company_id': this.company_id,
      'subscription_id': this.activeSubscription.id,
      'company': otherData,
      'addonSlug': addonType,
      'planSlug': plan_slug,
      'company_name': this.CompanyDetails.display_name,
      'price_type': 'per_unit'
    }
    const dialogRef = this.dialog.open(AddFreeCreditsDialogComponent, {
      width: '50%',
      data: prepareData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getBillingDetails();
      }
    });
  }

  addFlat(slug, plan_slug) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to give this addon as free addon';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    data['price_type'] = 'flat_fee';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        let otherData = {
          'subscription_type': this.CompanyDetails.subscription_type
        }
        let saveData = {
          'company_id': this.company_id,
          'subscription_id': this.activeSubscription.id,
          'company': otherData,
          'addonSlug': slug,
          'plan_slug': plan_slug,
          'company_name': this.CompanyDetails.display_name,
          'comments': result.commets,
          'free_access_type': result.free_access_type,
          'price_type': this.allPaidAddons[plan_slug][slug]['price_type']
        };
        console.log(saveData);
        this.companyDetailService.saveAddonsAsFree(saveData).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.getBillingDetails();
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        });

      }
    })
  }
 
  trimString(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  AddCredits(credit_type) {
    if (!this.is_grace_subscr_end) {
      let prepareData: any;
      let otherData: any;
      otherData = {
        'subscription_type': this.CompanyDetails.subscription_type
      }
      prepareData = {
        'company_id': this.company_id,
        'subscription_id': this.activeSubscription.id,
        'company': otherData,
        'is_unlimited': 0,
        'company_name':this.CompanyDetails.display_name
      }
      switch (credit_type) {
        case 'mass_mails_credits':
          prepareData['options_list'] = this.mass_mail_opts;
          prepareData['addonSlug'] = 'mass_mails_credits';
          prepareData['credit_title'] = 'MassMail Credits';
        break;
        case 'contacts_search_credits':
          prepareData['options_list'] = this.contact_search_opts;
          prepareData['addonSlug'] = 'contacts_search_credits';
          prepareData['credit_title'] = 'Contact Search (Accounts)';
        break;
        case 'linkedin_credits':
          prepareData['options_list'] = this.linkedin_credits_opts;
          prepareData['addonSlug'] = 'linkedin_credits';
          prepareData['credit_title'] = 'Contact Search (Linkedin)';
        break;
        case 'sms_credits':
          prepareData['options_list'] = this.sms_credits_opts;
          prepareData['addonSlug'] = 'sms_credits';
          prepareData['credit_title'] = 'SMS Text Credits';
        break;
        case 'i9_forms':
          prepareData['options_list'] = this.i9_forms_opts;
          prepareData['addonSlug'] = 'i9_forms';
          prepareData['credit_title'] = 'I-9 Forms';
        break;
        case 'chatbot':
          prepareData['options_list'] = this.subscriptionData.unlimited_options.chatbot;
          prepareData['addonSlug'] = 'chatbot';
          prepareData['credit_title'] = 'Chatbot';
        break;
        case 'digital_onboarding_templates':
          prepareData['options_list'] = this.subscriptionData.unlimited_options.digital_onboarding_templates;
          prepareData['addonSlug'] = 'digital_onboarding_templates';
          prepareData['credit_title'] = 'Digital Onboarding & templates';
        break;
        case 'job_grabber':
          prepareData['options_list'] = this.subscriptionData.unlimited_options.job_grabber;
          prepareData['addonSlug'] = 'job_grabber';
          prepareData['credit_title'] = 'Job Grabber';
        break;
        case 'quick_books':
          prepareData['options_list'] = this.subscriptionData.limited.quick_books;
          prepareData['addonSlug'] = 'quick_books';
          prepareData['credit_title'] = 'Quick Books';
        break;
        case 'report_builder':
          prepareData['options_list'] = this.subscriptionData.unlimited_options.report_builder;
          prepareData['addonSlug'] = 'report_builder';
          prepareData['credit_title'] = 'Custom Report Builder';
        break;
        case 'resume_harvesting':
          prepareData['options_list'] = this.subscriptionData.unlimited_options.resume_harvesting;
          prepareData['addonSlug'] = 'resume_harvesting';
          prepareData['credit_title'] = 'Resume Harvester';
        break;
        case 'resume_job_indox':
          prepareData['options_list'] = this.subscriptionData.unlimited_options.resume_job_indox;
          prepareData['addonSlug'] = 'resume_job_indox';
          prepareData['credit_title'] = 'Resume & Job Inbox';
        break;
        case 'video_interviews':
          prepareData['options_list'] = this.subscriptionData.unlimited_options.video_interviews;
          prepareData['addonSlug'] = 'video_interviews';
          prepareData['credit_title'] = 'Video Interviews';
        break;
      }

      const dialogRef = this.dialog.open(SearchCreditDialogComponent, {
        width: '50%',
        data: prepareData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getBillingDetails();
        }
      });
    } else {
      this.commonService.openSnackBar('warn', 'Company Subscription has been expired');
    }
  }

  addSlub(data: any) {
      let prepareData: any;
      let otherData: any;
      otherData = {
        'subscription_type': this.CompanyDetails.subscription_type
      }
      prepareData = {
        'company_id': this.company_id,
        'subscription_id': this.activeSubscription.id,
        'company': otherData,
        'is_unlimited': 0,
        'company_name':this.CompanyDetails.display_name
      }
      prepareData['options_list'] = data.other_details.slab_data;
      prepareData['addonSlug'] = data.slug;
      prepareData['credit_title'] = data.name;
      prepareData['slug'] = data.slug;
      prepareData['plan_slug'] = data.sb_plan_slug;
      prepareData['price_type'] = data.price_type;

      const dialogRef = this.dialog.open(SearchCreditDialogComponent, {
        width: '50%',
        data: prepareData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getBillingDetails();
        }
      });
  }

  cartRoute(type, id) {
    // this.commonService.subscriptionType = type;
    this.router.navigate(['company_details/' + id + '/manageSubscription/'+type]);
  }

  back() {
    this.location.back();
  }

  getRouteForSubscription (type='') {
    
    if (this.subscription_type == 'trial') {
      if (type == 'title')
        return 'Upgrade';

      return 'upgrade';
    }
    else if (this.isSubscriptionExpired) {
      if (type == 'title')
        return 'Renew';

      return 'renew';
    }
    else if (!this.isSubscriptionExpired) {
      if (type == 'title')
        return 'Top-up';

      return 'top_up';
    }
  }

  getAddonList(status_type) {
    this.is_active = status_type;
    // console.log(this.addonParam);
    this.addonParam = { company_id: this.company_id, status: status_type, subscription_id: this.activeSubscription.id };
    this.companyDetailService.getAddonList(this.addonParam).subscribe(res => {
      if (res.status === 1) {
        this.dataSource = new MatTableDataSource(res.data);
      }
    })
  }

  onDateChange(event, col:any=null) {
    const data = [];
    data['title'] = 'Alert';

    if (col == 'next_renewal_start_date') {
      data['message'] = 'Are you sure? # You want to extend next renewal start date';
    }
    else {
      data['message'] = 'Are you sure? # You want to extend end date';
    }

    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        let extended_date: any;
        let saveData: any;
        if (col == 'next_renewal_start_date') {
          extended_date = moment(this.billingDetails.value.next_renewal_start_date).format("YYYY-MM-DD");
        }else {
          extended_date = moment(this.billingDetails.value.end_date).format("YYYY-MM-DD");
        }
        saveData = {
          'company': this.CompanyDetails,
          'column': col?col:'end_date',
          'extended_date': extended_date,
          'comments': result.commets
        };
        this.commonService.udpateCompanyInfo(saveData).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
            // this.getBillingDetails();
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        });

      } else {


      }
    })
  }

  reportDiscard(event, message, feature, column_name) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to change '+message;
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        this.companyDetailService.discardCompanyReports({ company_id: Number(this.company_id), status: event.value, column: column_name,company_name:this.CompanyDetails.display_name, comments: result.commets }).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
          }
          else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      } else {
        this.billingDetails.get(feature).setValue(String(this.CompanyDetails[column_name]));
      }
    })
  }

  reportPayment(event) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to Exit Company';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        this.companyDetailService.discardCompanyPayments({ company_id: Number(this.company_id), status: event.value, company_name:this.CompanyDetails.display_name, comments: result.commets }).subscribe(res => {
          if (res.status === 1) {
            this.getBillingDetails();
            this.commonService.openSnackBar('primary', res.message);
          }
          else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      } else {
        this.billingDetails.get('accountLost').setValue(String(this.CompanyDetails.lost_account));
      }
    })
  }

  setCompanyType(event) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to change company type';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        this.companyDetailService.companyType({ company_id: Number(this.company_id), company_type: event.value, company_name:this.CompanyDetails.display_name, comments: result.commets }).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
          }
          else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      } else {
        this.billingDetails.get('companyType').setValue(String(this.company_type));
      }
    })
  }

  updateCompanyRenewal (event) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to change company auto renewal';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        this.companyDetailService.companyAutoRenewal({ company_id: Number(this.company_id), auto_renewal: event.value, company_name:this.CompanyDetails.display_name, comments: result.commets }).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
          }
          else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      } else {
        this.billingDetails.get('auto_renewal').setValue(String(this.CompanyDetails.auto_renewal));
      }
    })
  }

  bulkImportAccess(event) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to change Bulk Access Import';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width:'50%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        this.companyDetailService.bulkImportAccess({ company_id: Number(this.company_id), status: event.value, company_name:this.CompanyDetails.display_name, comments: result.commets }).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
          }
          else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      } else {
        this.billingDetails.get('bulkImport').setValue(String(this.CompanyDetails.bulk_import_access));
      }
    })
  }

  convertFreeLicensesToPaid(addonSlug) {
    if (this.is_subscr_end) {
      this.commonService.openSnackBar('warn', 'Company subscription has been expired, Please renew the subscription');
    } else {
      this.licenseType = addonSlug.addon_slug;
      let prepareData: any;
      this.getLicenseData();
      prepareData = {
        'license_title': this.license_title,
        'company_id': this.company_id,
        'free_licenses': addonSlug.credits,
        'addonSlug': this.addonSlug,
        'sb_plan_slug': addonSlug.sb_plan_slug,
        'package': this.package,
        'billing_cycle': this.activeSubscription.billing_cycle,
        'payment_gateway': this.CompanyDetails.payment_gateway
      }
      console.log(prepareData);
      const dialogRef = this.dialog.open(ConvertFreeLicensesToPaidComponent, {
        width: '50%',
        data: prepareData
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.getBillingDetails();
        }
      });
    }
  }

  exitFreeLicenses(license_type, free_licenses, available_licenses) {
    let prepareData: any;
    this.licenseType = license_type;
    this.getLicenseData();
    prepareData = {
      'license_title': this.license_title,
      'company_id': this.company_id,
      'addonSlug': this.addonSlug,
      'free_licenses': free_licenses,
      'available_licenses': available_licenses,
      'subscription_id': this.activeSubscription.id,
      'is_unlimited': this.unlimitedobj[license_type],
      'company_name':this.CompanyDetails.display_name
    }

    const dialogRef = this.dialog.open(RemoveFreeLicensesComponent, {
      width: '50%',
      data: prepareData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getBillingDetails();
      }
    });
  }

  getCurrencySymbol() {
    if (this.payment_gateway == 1) {
      return '$';
    }

    if (this.country_id == 2) {
      return '₹';
    } else {
      return '$';
    }
  }

  getAddonAccess(addon_slug) {
    if (addon_slug == 'i9_forms') {
      if (this.country_id == 1) {
        return true;
      }
      return false;
    }
    else if (addon_slug == 'sms_credits') {
      if (this.country_id == 2) {
        return true;
      }
      return false;
    }
    return true;
  }

  SMSAccess() {
      if (this.country_id == 2) {
        return true;
      }
      return false;
  }

  showActivities() {
    let activityData ={
      'company_id':this.company_id,
      'module_slug':'billingDetials',
      'show_company_name':false,
      'module_name':'Billing Details'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  selectMassMailRecord() {
    let activityData ={
      'addonRecords':this.splittedAddons,
      'splittedAddonsInfo':this.splittedAddonsInfo,
      'company_id': this.company_id,
      'company_name':this.CompanyDetails.name
    }
    const dialogRef = this.dialog.open(MassMailsSplitComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getBillingDetails();
      }
    });
  }

  selectAddonType(addonSlug) {
    let paramsData ={
      'addonSlug':addonSlug,
      'company_id': this.company_id,
      'company_name':this.CompanyDetails.display_name
    }
    const dialogRef = this.dialog.open(PurchasedAddonTypeListComponent, {
      width: '100%',
      data: paramsData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getBillingDetails();
      }
    });
  }

  disableFreeAddonOpt(addon_key) {
    let allowApp = this.allow_app_type[addon_key] == 1 ? false : true;
    let isInactive = this.isLostAccount == 0 ? false : true;

    if (!allowApp || !isInactive){
      return true;
    }
    return false;
  }

  isAccountActive() {
    let isInactive = this.isLostAccount == 1 ? true : false;
    
    if (isInactive){
      return false;
    }
    return true;
  }

  addCustomerBalance() {
    let activityData ={
      'company_id': this.company_id,
      'country_id':this.country_id,
      'haveStripeAccount': isEmpty(this.CompanyDetails.stripe_customer_id)?false:true
    }
    const dialogRef = this.dialog.open(AddCustomerBalanceComponent, {
      width: '60%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getBillingDetails();
      }
    });
  }

  addInvoiceLineItems() {
    let dataObj ={
      'company_id': this.company_id,
    }
    const dialogRef = this.dialog.open(InvoiceLineItemsComponent, {
      width: '60%',
      data: dataObj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getBillingDetails();
      }
    });
  }
}
