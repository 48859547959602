<h1 mat-dialog-title class='text-uppercase'>Purchased Mass Mails</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <div class="page-layout carded fullwidth inner-scroll">
    <div class="center px-20">
      <div class="">
        <div class="content" fusePerfectScrollbar>
          <ng-container *ngIf="dataSource">
            <div class="px-12 pb-12">
              <mat-table [dataSource]="dataSource">
                
                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef> Select Record </mat-header-cell>
                  <mat-cell *matCellDef="let element"> 
                    <mat-checkbox name="{{element.id}}" (change)="massMailsIdArr[element.id] = $event.checked"></mat-checkbox>  
                    <span>
                      <button mat-icon-button class="accent-fg ml-12" [matMenuTriggerFor]="menu" (click)="getSplittedInfo(element.id)">
                        <mat-icon matTooltip="Mass Mails Splitting Information">info</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu" class="w-700">
                          <div class="px-12 pb-12">
                              <mat-table [dataSource]="dataSourceSet">
                                <!-- Position Column -->
                                <ng-container matColumnDef="sno">
                                  <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
                                  <mat-cell *matCellDef="let element"> {{element.sno}} </mat-cell>
                                </ng-container>
                              
                                <!-- Name Column -->
                                <ng-container matColumnDef="credits">
                                  <mat-header-cell *matHeaderCellDef> Credits/Month </mat-header-cell>
                                  <mat-cell *matCellDef="let element"> {{element.available}} </mat-cell>
                                </ng-container>
                              
                                <!-- Weight Column -->
                                <ng-container matColumnDef="used">
                                  <mat-header-cell *matHeaderCellDef> Used </mat-header-cell>
                                  <mat-cell *matCellDef="let element"> {{element.used}} </mat-cell>
                                </ng-container>
                              
                                <!-- Symbol Column -->
                                <ng-container matColumnDef="startDate">
                                  <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
                                  <mat-cell *matCellDef="let element"> {{element.start_date | date:"MM/dd/yyyy"}} </mat-cell>
                                </ng-container>
                              
                                <!-- Symbol Column -->
                                <ng-container matColumnDef="endDate">
                                  <mat-header-cell *matHeaderCellDef> End Date </mat-header-cell>
                                  <mat-cell *matCellDef="let element"> {{element.end_date | date:"MM/dd/yyyy"}} </mat-cell>
                                </ng-container>
                              
                                <mat-header-row *matHeaderRowDef="splittedMassMailsCols"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: splittedMassMailsCols;"></mat-row>
                              </mat-table>
                          </div>
                      </mat-menu>
                  </span> 
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="available">
                  <mat-header-cell *matHeaderCellDef> Available </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.available}} </mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="used">
                  <mat-header-cell *matHeaderCellDef> Used </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.used}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="paid_on">
                  <mat-header-cell *matHeaderCellDef> Paid On </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.start_date | date:"MM/dd/yyyy" }} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="end_date">
                  <mat-header-cell *matHeaderCellDef>End Date </mat-header-cell>
                  <mat-cell *matCellDef="let element" > {{element.end_date | date:"MM/dd/yyyy" }}</mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
  
              <div *ngIf="dataSource.length == 0" class="text-center">
                <h2 class="sub-heading">No Records Found</h2>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-flat-button class='primary' (click)="splitMassMails()">Split Mass Mails</button>
</mat-dialog-actions>