<!-- <div fxLayout="row wrap" fxLayoutAlign="end center" class="mt-20 px-20">
    <button mat-flat-button class="primary" (click)="showAddForm = 2;createRecordForm(); editRecordId='';" *ngIf="showAddForm == 1">Add Migration</button>
    <button mat-flat-button class="primary" (click)="showAddForm = 1" *ngIf="showAddForm != 1">Ceipal/Zoniac</button>
    <button mat-flat-button class="accent ml-4" (click)="getMigrationRecords()" *ngIf="showAddForm != 3">List</button>
</div> -->
<div>
    <div class="mt-20 px-20">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
            <div fxFlex='120px' class="text-bold">Client Name</div>
            <div fxFlex>
                <mat-form-field class="w-240" appearance='outline'>
                    <mat-select [(value)]="client_name">
                        <mat-option>Select</mat-option>
                        <mat-option *ngFor="let option of companies" [value]='option.value'>{{option.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start start" class="mt-20">
            <div fxFlex='120px' class="text-bold">File Path</div>
            <div fxFlex>
                <div class="file-choose">
                    <label>
                        <mat-icon class="s-20 mr-12">get_app</mat-icon>
                        <span class="muli-bold">Choose a File</span>
                    </label>
                    <input #myFile type="file" (change)="handleFileInput($event.target.files)"
                        accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                    <div class="font-size-12 mt-4">( <span class="text-bold warn-fg"> Note:</span> File size should not
                        exceeds 5MB)</div>
                </div>
                <div class="my-16">OR</div>
                <div>
                    <mat-form-field class="w-50-p" appearance='outline'>
                        <mat-label>s3 import document path</mat-label>
                        <input type="text" matInput [(ngModel)]="folderpath" (keypress)="resetuploadfile()">
                    </mat-form-field>
                </div>
            </div>
        </div>

    </div>
    <div class="mt-40 px-20" *ngIf="fileToUpload">
        <div class="mb-20 font-size-16 text-bold">File Details</div>
        <div class="file-details ">
            <div fxLayout="row wrap" fxLayoutAlign="space-around center" class='mb-12'>
                <div fxFlex='200px' class="file-label">Selected Client :</div>
                <div fxFlex class="file-value">{{client_name}}</div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-around center" class='mb-12'>
                <div fxFlex='200px' class="file-label">File name :</div>
                <div fxFlex class="file-value">{{fileToUpload.name}}</div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-around center" class='mb-12'>
                <div fxFlex='200px' class="file-label">File Size :</div>
                <div fxFlex class="file-value">{{fileToUpload.size | fileSize}}</div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-around center" class='mb-12'>
                <div fxFlex='200px' class="file-label">File Type :</div>
                <div fxFlex class="file-value">{{fileToUpload.type}}</div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-around center" class='mb-12'>
                <div fxFlex='200px' class="file-label">Uploaded at :</div>
                <div fxFlex class="file-value">{{fileToUpload.lastModified | date:'medium'}}</div>
            </div>

        </div>
    </div>

    <div class="footer">
        <button mat-button (click)="uploadFile()" [disabled]="isLostAccount">Upload</button>
    </div>
</div>

<!-- <div *ngIf="showAddForm == 2">
    <form [formGroup]="userForm">
        <div class="mt-20 px-20">
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="12px" class="pb-12">
                <div fxFlex="25">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>Initiated Date</mat-label>
                        <input matInput [matDatepicker]="picker" (click)="picker.open()" formControlName="initiatedOn">
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div fxFlex="25">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>Sales/support rep</mat-label>
                        <mat-select formControlName="cpanelUser">
                            <mat-option *ngFor="let user of usersList" [value]='user.name'>{{user.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex="25">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="status">
                            <mat-option *ngFor="let item of statusList" [value]='item.status'>{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="12px" class="pb-12">
                <div fxFlex="25">
                    <mat-form-field appearance='outline' class="w-100-p">
                        <mat-label>Total Files</mat-label>
                        <input type="number" matInput formControlName="total_files">
                    </mat-form-field>
                </div>
                <div fxFlex="25">
                    <mat-form-field appearance='outline' class="w-100-p">
                        <mat-label>Migrated Files</mat-label>
                        <input type="number" matInput formControlName="migrated_files">
                    </mat-form-field>
                </div>
                <div fxFlex="25">
                    <mat-form-field appearance='outline' class="w-100-p">
                        <mat-label>Failed Files</mat-label>
                        <input type="number" matInput formControlName="failed_files">
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="12px" class="pb-12 mt-20">
                <mat-label class="text-bold">Migration Type:</mat-label>
                <mat-radio-group formControlName="migrationType" aria-label="Select an option" fxLayout="row wrap"
                    fxLayoutAlign="space-between center" (selectionChange)="showFolderInput()">
                    <mat-radio-button value="ftp" color="primary" class="sub-heading mr-20">FTP
                    </mat-radio-button>
                    <mat-radio-button value="excel" color="primary" class="sub-heading mr-20">Excel
                    </mat-radio-button>
                    <mat-radio-button value="sql" color="primary" class="sub-heading mr-20">SQL
                    </mat-radio-button>
                    <mat-radio-button value="json" color="primary" class="sub-heading mr-20">JSON
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="12px" class="pb-12 mt-20"
                *ngIf="userForm.value.migrationType == 'ftp'">
                <div *ngFor="let text_ctl of getFormControls(); let i = index" fxLayout="row wrap"
                    fxLayoutAlign="start center" fxFlex="70">
                    <div fxFlex="45">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>DIR Name(.zip/.rar)</mat-label>
                            <input matInput [formControl]="text_ctl">
                        </mat-form-field>
                    </div>
                    <div fxFlex="5"></div>
                    <div fxFlex="40">
                        <button type="button" mat-icon-button class="primary-fg" matTooltip="Add" (click)="add()" *ngIf="i<=8">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button type="button" mat-icon-button class="red-fg" matTooltip="Delete" (click)="remove(i)"
                            *ngIf="i>=1 && i<=9">
                            <mat-icon>remove</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class='footer'>
            <button (click)="onSubmit()" mat-button [disabled]="isLostAccount">Save</button>
            <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
        </div>
    </form>
</div> -->

<!-- <div *ngIf="showAddForm == 3" class="page-layout carded fullwidth inner-scroll mt-12">
    <div class="center px-20">
        <div class="">
            <div class="content" fusePerfectScrollbar>
                <ng-container>
                    <div class="px-12 pb-12">
                        <mat-table [dataSource]="dataSource">

                            <ng-container matColumnDef="initiated_on">
                                <mat-header-cell *matHeaderCellDef> Initiated On </mat-header-cell>
                                <mat-cell *matCellDef="let element">{{element.initiated_on | date:"longDate"}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="user">
                                <mat-header-cell *matHeaderCellDef> Sales/Support </mat-header-cell>
                                <mat-cell *matCellDef="let element">{{element.cpanel_user}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="migration_type">
                                <mat-header-cell *matHeaderCellDef> Migration Type </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="text-transform: uppercase;">{{element.migration_type}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="folders">
                                <mat-header-cell *matHeaderCellDef> Folders </mat-header-cell>
                                <mat-cell *matCellDef="let element;let i = index" style="text-transform: none;">
                                    <div>
                                        <span [innerHTML]="getFolderNames(element) | slice:0:more[i] ? getFolderNames(element).length : 50"> </span>
                                        <a *ngIf="getFolderNames(element).length > 100" style="cursor:pointer;" (click)="more[i] = !more[i];">
                                            {{!more[i] ? ' More ' : ' Less '}}</a>
                                    </div> 
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="total">
                                <mat-header-cell *matHeaderCellDef> Total Files </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.total_files}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="migrated">
                                <mat-header-cell *matHeaderCellDef> Migrated Files </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.migrated_files}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="failed">
                                <mat-header-cell *matHeaderCellDef> Failed Files </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.failed_files}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="remaining">
                                <mat-header-cell *matHeaderCellDef> Remaining </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.total_files - (element.migrated_files + element.failed_files )}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="percentage">
                                <mat-header-cell *matHeaderCellDef> Completed </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{((element.migrated_files + element.failed_files )/element.total_files) * 100 | number:'1.2-2'}}%</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center"> Actions
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" fxLayoutAlign="center center">
                                    <button mat-icon-button class="accent-fg" matTooltip="Edit" (click)="editRecord(element)" [disabled]="isLostAccount">
                                        <mat-icon class="s-18">edit</mat-icon>
                                    </button>
                                    <button mat-icon-button class="red-fg" matTooltip="Delete"
                                        (click)="deleteMigrationRecord(element.id)" [disabled]="isLostAccount">
                                        <mat-icon class="s-18">delete</mat-icon>
                                    </button>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>

                        <div *ngIf="dataSource.data.length == 0" class="text-center">
                            <h2 class="sub-heading">No Records Found</h2>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div> -->