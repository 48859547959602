import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonHelpersService } from 'app/services/common-helpers.service';
import { CommonService } from 'app/services/common.service';
import { CompanyDetailsService } from 'app/services/company-details.service';
import masterdata from 'app/services/masterdata';

@Component({
  selector: 'app-add-customer-balance',
  templateUrl: './add-customer-balance.component.html',
  styleUrls: ['./add-customer-balance.component.scss']
})
export class AddCustomerBalanceComponent implements OnInit {

  subscriptionForm: FormGroup;
  countries: { id: number; name: string; }[];
  states: { id: number; country_id: number; code: string; name: string; standard_timezone: string; timezone: string; }[];
  masterData = masterdata;
  formData: FormData = new FormData();
  url1: any;
  payLoadData: any = {};
  haveStripeAccount: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddCustomerBalanceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private commonService: CommonService,
    private commonHelper: CommonHelpersService,
    private companyDetailService: CompanyDetailsService) {
    this.haveStripeAccount = this.data.haveStripeAccount;
    this.subscriptionForm = this.fb.group({
      billing_street: ['', Validators.required],
      billing_city: ['', Validators.required],
      billing_country: ['', Validators.required],
      billing_state: ['', Validators.required],
      billing_zip: ['', Validators.required],
      txn_id: ['', Validators.required],
      bank_cheque_attchment: ['', Validators.required],
      amount: ['', Validators.required],
      comments: ['', Validators.required]
    })

    if (this.haveStripeAccount) {
      this.subscriptionForm.get('billing_street').clearValidators();
      this.subscriptionForm.get('billing_city').clearValidators();
      this.subscriptionForm.get('billing_country').clearValidators();
      this.subscriptionForm.get('billing_state').clearValidators();
      this.subscriptionForm.get('billing_zip').clearValidators();
    }
  }

  ngOnInit() {
    this.countries = this.masterData.countriesList;
  }

  changeCountry(country) {
    this.states = this.masterData.statesList.filter(state => state.country_id === country);
  }

  onSelectFile1(event) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url1 = event.target['result'];
      }
      //set uploaded file object to 'social_media_logo'
      this.formData.append('bank_cheque_attchment', event.target.files[0]);
    }
  }

  save() {
    if (this.subscriptionForm.valid) {
      this.payLoadData['amount'] = this.subscriptionForm.value['amount'];
      this.payLoadData['txn_id'] = this.subscriptionForm.value['txn_id'];
      this.payLoadData['comments'] = this.subscriptionForm.value['comments'];
      this.payLoadData['company_id'] = this.data.company_id;
      if (!this.haveStripeAccount) {
        this.payLoadData['billing'] = {};
        this.payLoadData['billing']['street'] = this.subscriptionForm.value['billing_street'];
        this.payLoadData['billing']['city'] = this.subscriptionForm.value['billing_city'];
        this.payLoadData['billing']['selected_country_id'] = this.subscriptionForm.value['billing_country'];
        this.payLoadData['billing']['state'] = this.subscriptionForm.value['billing_state'];
        this.payLoadData['billing']['zip'] = this.subscriptionForm.value['billing_zip'];
      }
      this.formData.append('requestData', JSON.stringify(this.payLoadData));
      this.companyDetailService.addCustomerAccountBalance(this.formData).subscribe(res => {
        if (res.status == 1) {
          this.commonService.openSnackBar('primary', res.message);
          this.dialogRef.close(1);
        } else {
          this.commonService.openSnackBar('warn', res.message);
        }
      })
    } else {
      this.commonService.openSnackBar('warn', 'Please submit required details');
    }
  }

}
