<h1 mat-dialog-title class='text-uppercase'>
  {{this.data?.invoice_id?'TDS Remainder Email' : 'Send Mail'}}</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <div class="w-100-p p-20 mb-52" [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start">
      <div fxFlex="100">
        <mat-form-field class="w-100-p country-p" appearance='outline'>
          <mat-label>To: Cpanel Users</mat-label>
          <mat-select appMatSearchSelector formControlName="sales_emails" multiple (selectionChange)="salesUserChange()">
            <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
            <ng-container *ngFor="let saleUser of users">
              <mat-option *appMatSearchSelectorItems [value]="saleUser.email">{{saleUser.name}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100-p country-p" appearance='outline'>
          <mat-label>Additional Recipients</mat-label>
          <mat-chip-list #cc_emails aria-label="Additional emails" formArrayName="cc_emails">
            <mat-chip *ngFor="let email of form?.get('cc_emails')?.value" [selectable]="selectable"
              [removable]="removable" (removed)="remove(email,'cc_emails')">
              {{email}}
              <button matChipRemove *ngIf="removable">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input #additionalEmail placeholder="Enter Email IDs" [matChipInputFor]="cc_emails"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="add($event,'cc_emails')">
          </mat-chip-list>
        </mat-form-field>

        <div class="py-12">
          <mat-checkbox  color="primary" formControlName='check_data1' class="mr-20">Send this email to Customer</mat-checkbox>
        </div>
        <div class="">
          <mat-form-field appearance="outline" class="w-100-p">
            <mat-label>Subject</mat-label>
            <input matInput formControlName="subject">
          </mat-form-field>
        </div>
        <div class="mt-12">
          <div class="font-size-12 text-bold my-4">Message Body</div>
          <ckeditor formControlName="mail_body" [config]="config" (ready)="onReady($event)"></ckeditor>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-button class="primary-fg" (click)="sendEmail()">Send</button>
</mat-dialog-actions>