<div>
  <form>
    <div fxLayout="row wrap" class="border-b-1">
      <ul fxFlex class="top-nav m-0 p-0">
        <li><a class="text-bold" [routerLink]="['../']">All</a></li>
        <li><a class="text-bold" class="active">Discard Reports</a></li>
      </ul>
    </div>
  </form>
  <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
    <div class="search-wrapper" fxFlex="30">
      <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
        [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Company Name, Contact Name">
      <mat-icon class="search_icon">search</mat-icon>
      <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
        close
      </mat-icon>
    </div>
    <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
      <div>
        <button mat-stroked-button (click)="exportExcel()" *ngIf="logginUser?.role_name == 'admin'" >Export as Excel</button>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
          [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<cdk-virtual-scroll-viewport itemSize="50" class="scroll-content-height" fusePerfectScrollbar>
  <div class="content" *ngIf="dataSource">
    <div class="px-12 pb-12">
      <mat-table [dataSource]="dataSource">
        <!-- customerName Column -->
        <ng-container matColumnDef="customerName">
          <mat-header-cell *matHeaderCellDef> customer Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> <a class='cursor-pointer'
              (click)='navigate(element)'>{{element.name}}</a>
          </mat-cell>
        </ng-container>
        <!-- name Column -->
        <ng-container matColumnDef="contactName">
          <mat-header-cell *matHeaderCellDef> Contact Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{(element.client_info)?element.client_info.first_name:'-'}}
            {{(element.client_info)?element.client_info.last_name:'-'}} </mat-cell>
        </ng-container>

        <!-- email Column -->
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef fxFlex="200px">contact Email </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="200px" class="lowercase"> {{(element.client_info)?element.client_info.email:'-'}}
          </mat-cell>
        </ng-container>

        <!-- subdomain Column -->
        <ng-container matColumnDef="subdomain">
          <mat-header-cell *matHeaderCellDef> sub domain name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.sub_domain_name}} </mat-cell>
        </ng-container>

        <!-- region Column -->
        <ng-container matColumnDef="region">
          <mat-header-cell *matHeaderCellDef> region </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.region | uppercase}} </mat-cell>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> status </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.status === 1 ? 'active' : 'inactive'}} </mat-cell>
        </ng-container>

        <!-- subscriptionenddate Column -->
        <ng-container matColumnDef="subscriptionenddate">
          <mat-header-cell *matHeaderCellDef> subscription end date </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.subscr_end_date | date:"MM/dd/yyyy"}} </mat-cell>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button class="accent-fg" matTooltip="View" (click)="navigate(element)">
              <mat-icon class="s-18">remove_red_eye</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <div *ngIf="dataSource.data.length == 0" class="text-center">
        <h2 class="heading m-0 py-16 border">No Records Found</h2>
      </div>
    </div>
  </div>
</cdk-virtual-scroll-viewport>