<div fxFlex="100">
    <form *ngIf="addondetails" #subscribeForm="ngForm" (ngSubmit)="saveData()" novalidate>
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="1%" class="my-20 px-12">
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100" class="pb-16">
                <div fxFlex='24' *ngIf="!hideCountry">
                    <mat-form-field class="w-100-p" appearance='outline'>
                        <mat-label>Country</mat-label>
                        <mat-select (selectionChange)="countryChanged()" [(value)]="selected_country">
                            <mat-option *ngFor="let item of coutries" [value]="item.value">
                                {{item.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- <div fxFlex='24' class="pl-20">
                    <mat-form-field class="w-100-p" appearance='outline'>
                        <mat-label>product</mat-label>
                        <mat-select (selectionChange)="productChanged()" [(value)]="selected_package">
                            <mat-option *ngFor="let item of packages" [value]="item.value">
                                {{item.viewValue}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100" class="pb-16">
                <label class="form-label">Discounts</label>
                <div fxFlex='15' class="pl-20">
                    <mat-form-field class="w-100-p" appearance='outline'>
                        <mat-label>Quarterly Discount</mat-label>
                        <input type="number" matInput [(ngModel)]="billingCycleDiscounts[3]" name="quarterly_discount">
                    </mat-form-field>
                </div>

                <div fxFlex='15' class="pl-20">
                    <mat-form-field class="w-100-p" appearance='outline'>
                        <mat-label>Half-Yearly Discount</mat-label>
                        <input type="number" matInput [(ngModel)]="billingCycleDiscounts[6]"
                            name="half_yearly_discount">
                    </mat-form-field>
                </div>

                <div fxFlex='15' class="pl-20">
                    <mat-form-field class="w-100-p" appearance='outline'>
                        <mat-label>Yearly Discount</mat-label>
                        <input type="number" matInput [(ngModel)]="billingCycleDiscounts[12]" name="yearly_discount">
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="100" class="pb-16">
                <label class="form-label">Billing Cycles</label>
                <div fxFlex='15' class="pl-20" *ngFor="let item of billingCycles | keyvalue: unsorted">
                    <mat-checkbox color="primary" class="font-size-14" [(ngModel)]="billingCycles[item.key]"
                        [disabled]="(item.key == 12 && company_id == 0) || (item.key == current_billng_cycle && company_id != 0)"
                        [name]="item.key">
                        <span *ngIf="item.key ==1">Monthly</span>
                        <span *ngIf="item.key ==3">Quarterly</span>
                        <span *ngIf="item.key ==6">Half-Yearly</span>
                        <span *ngIf="item.key ==12">Yearly</span>
                    </mat-checkbox>
                </div>
            </div>

            <div [fxFlex]='(addonDetailLength == 4) ? 24 : 32' *ngFor="let item of addondetails | keyvalue">
                <mat-card class="p-0">
                    <mat-card-header class="px-16 border-top-radius-4 grey-200 py-8 sticky">
                        <mat-card-title class="m-0 muli-bold">
                            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                                <div class="font-size-14">

                                    <ng-container>{{planTitleArr[item.key]}}</ng-container>

                                    <!-- <div class="accent-fg" matTooltip="Free Addons" (click)="restrictTo(item.key)">
                                        <mat-icon class="s-18">info</mat-icon>
                                    </div> -->
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="center">
                                    <span *ngIf="company_id" class="accent-fg mr-4" matTooltip="View Plan" (click)="showPlanDetails(item.key, allPlans[item.key])">
                                        <mat-icon class="s-18">info</mat-icon>
                                    </span>
                                    <mat-checkbox color="primary" class="font-size-14"
                                        [checked]="activePlanData[allPlans[item.key]]"
                                        (change)="checkPlanEnable($event, item.key)"
                                        [disabled]="(planTitleArr[item.key] == 'Classic')" [name]="item.key">Activate
                                        Plan
                                    </mat-checkbox>
                                </div>
                            </div>
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content class="p-1">
                        <ng-container *ngIf="addondetails[item.key]">
                            <ng-container *ngFor="let plan_package of addondetails[item.key]| keyvalue;let i = index;">
                                <mat-accordion>
                                    <mat-expansion-panel class="mb-16" [expanded]="i">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                {{packages[plan_package.key]['viewValue']}}
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div
                                            *ngFor="let field of addondetails[item.key][plan_package.key]; let i = index">
                                            <ng-container *ngIf="field.type == 'licenses'">
                                                <div class="group_header">
                                                    {{field.name}} {{ (field.credits_type == 'un_limited') ? '(Un
                                                    Limited)' : '' }}
                                                </div>
                                                <mat-form-field class="w-100-p mb-8" appearance="outline">
                                                    <mat-label>Price / Month </mat-label>
                                                    <input type="number" matInput required min="0"
                                                        [(ngModel)]="field.price"
                                                        [name]="field.slug+'_'+i+'_'+item.key">
                                                    <!-- <ng-container *ngIf=" (planTitleArr[item.key] == 'Enterprise') ">
                                                <input type="number" matInput required [(ngModel)]="field.price"
                                                    [name]="field.slug+'_'+i+'_'+item.key">
                                            </ng-container>
                                            <ng-container *ngIf=" (planTitleArr[item.key] != 'Enterprise') ">
                                                <input type="number" customMin="1" matInput required
                                                    [(ngModel)]="field.price" [name]="field.slug+'_'+i+'_'+item.key">
                                            </ng-container> -->

                                                </mat-form-field>
                                                <mat-form-field class="w-100-p mb-8" appearance="outline"
                                                    *ngIf="field.price_type == 'flat_fee' || field.price_type == 'per_unit' ">
                                                    <mat-label>{{ (field.price_type == 'per_unit') ? 'Minimum Licenses
                                                        Credits' : 'Credits' }}
                                                    </mat-label>
                                                    <!-- customMin="1" -->
                                                    <input type="number" min="0" matInput required
                                                        [(ngModel)]="field.quantity"
                                                        [name]="field.slug+'-'+i+'-'+item.key">
                                                </mat-form-field>
                                            </ng-container>
                                            <!-- addons -->
                                            <ng-container *ngIf="field.type == 'addons'">
                                                <div
                                                    *ngIf="field.price_type =='slab' || field.price_type =='slab_flat_fee'">
                                                    <div class="group_header"> {{field.name}} {{ (field.credits_type ==
                                                        'un_limited') ?
                                                        '(Un Limited)' : '' }}</div>
                                                    <ng-container
                                                        *ngFor="let val of field?.other_details['slab_data'] | keyvalue:unsorted">
                                                        <mat-form-field class="w-100-p mb-8" appearance="outline">
                                                            <mat-label>{{ (val.value.name == 'Unlimited') ? 'Max Limit'
                                                                :
                                                                val.value.name+' / Month' }} </mat-label>
                                                            <input type="number" min="0" matInput required
                                                                [(ngModel)]="val.value.price"
                                                                [name]="val.value.slab_index+'_'+i+'_'+item.key">
                                                            <!-- <ng-container *ngIf=" (planTitleArr[item.key] == 'Enterprise') ">
                                                        <input type="number" matInput required [(ngModel)]="val.value.price"
                                                            [name]="val.value.slab_index+'_'+i+'_'+item.key">
                                                    </ng-container>
                                                    <ng-container *ngIf="(planTitleArr[item.key] != 'Enterprise') ">
                                                        <input type="number" customMin="1" matInput required
                                                            [(ngModel)]="val.value.price"
                                                            [name]="val.value.slab_index+'_'+i+'_'+item.key">
                                                    </ng-container> -->

                                                        </mat-form-field>
                                                    </ng-container>
                                                </div>

                                                <div
                                                    *ngIf="field.price_type !='slab' && field.price_type !='slab_flat_fee'">
                                                    <div class="group_header">
                                                        {{field.name}} {{ (field.credits_type == 'un_limited') ? '(Un
                                                        Limited)' : ''
                                                        }}
                                                    </div>
                                                    <mat-form-field class="w-100-p mb-8" appearance="outline">
                                                        <mat-label>{{field.name}} / Month </mat-label>
                                                        <input type="number" min="0" matInput required
                                                            [(ngModel)]="field.price"
                                                            [name]="field.slug+'_'+i+'_'+item.key">
                                                        <!-- <ng-container *ngIf=" (planTitleArr[item.key] == 'Enterprise') ">
                                                    <input type="number" matInput required [(ngModel)]="field.price"
                                                        [name]="field.slug+'_'+i+'_'+item.key">
                                                </ng-container>
                                                <ng-container *ngIf="(planTitleArr[item.key] != 'Enterprise') ">
                                                    <input type="number" customMin="1" matInput required
                                                        [(ngModel)]="field.price" [name]="field.slug+'_'+i+'_'+item.key">
                                                </ng-container> -->


                                                    </mat-form-field>
                                                    <mat-form-field class="w-100-p mb-8" appearance="outline"
                                                        *ngIf="field.price_type == 'flat_fee' || field.price_type == 'per_unit' ">
                                                        <mat-label>{{ (field.credits_type == 'un_limited') ? 'Max Limit'
                                                            :
                                                            'Quantity' }}
                                                        </mat-label>
                                                        <!-- customMin="1" -->
                                                        <input type="number" matInput required min="0"
                                                            [(ngModel)]="field.quantity"
                                                            [name]="field.slug+'-'+i+'-'+item.key">
                                                    </mat-form-field>
                                                </div>
                                            </ng-container>
                                            <!-- features -->
                                            <ng-container *ngIf="field.type == 'features'">
                                                <div class="group_header">
                                                    {{field.name}} {{ (field.credits_type == 'un_limited') ? '(Un
                                                    Limited)' : ''
                                                    }}
                                                </div>
                                                <mat-form-field class="w-100-p mb-8" appearance="outline">
                                                    <mat-label>{{field.name}} / Month </mat-label>

                                                    <input type="number" matInput required min="0"
                                                        [(ngModel)]="field.price"
                                                        [name]="field.slug+'_'+i+'_'+item.key">
                                                    <!-- <ng-container *ngIf=" (planTitleArr[item.key] == 'Enterprise')">
                                                <input type="number" matInput required [(ngModel)]="field.price"
                                                    [name]="field.slug+'_'+i+'_'+item.key">
                                            </ng-container>
                                            <ng-container *ngIf="(planTitleArr[item.key] != 'Enterprise') ">
                                                <input type="number" customMin="1" matInput required
                                                    [(ngModel)]="field.price" [name]="field.slug+'_'+i+'_'+item.key">
                                            </ng-container> -->

                                                </mat-form-field>
                                            </ng-container>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </ng-container>
                        </ng-container>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </form>
</div>
<div class='footer'>
    <button mat-button (click)="saveData()">Save</button>
    <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
    <button mat-button (click)="showActivities()">Activities</button>
</div>