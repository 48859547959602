<div class="center px-20">
  <div class="content-card mt-12 mb-52">
    <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">

      <div class="search-wrapper" fxFlex="30" fxFlex.lt-xl="20">
        <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
          [(ngModel)]="searchValue" placeholder="Zip Name...">
        <mat-icon class="search_icon">search</mat-icon>
        <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
          close
        </mat-icon>
      </div>

      <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
        <div fxLayout="row" fxLayoutAlign="end center">
          <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
            [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
        </div>
      </div>
    </div>
    <div class="content" fusePerfectScrollbar>
      <ng-container *ngIf="dataSource">
        <div class="px-12 pb-12">
          <mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="zip_name">
              <mat-header-cell *matHeaderCellDef> Zip File Name</mat-header-cell>
              <mat-cell *matCellDef="let element" style="text-transform: none;">{{element.file_name}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="uploaded_date">
              <mat-header-cell *matHeaderCellDef>Uploaded Date</mat-header-cell>
              <mat-cell *matCellDef="let element" class="lowercase"> {{element.uploaded_at}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef> Current Status </mat-header-cell>
              <mat-cell *matCellDef="let element" class="lowercase"> {{element.status}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="percentage">
              <mat-header-cell *matHeaderCellDef> Percentage(%) </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.percentage}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="migrated">
              <mat-header-cell *matHeaderCellDef> Migrated </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{(element.migrated_count != null)?element.migrated_count:0}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="failed">
              <mat-header-cell *matHeaderCellDef> Failed(Duplicate+Empty) </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{(element.failed_count != null)?element.failed_count:0}}
                <button mat-icon-button class="primary-fg" matTooltip="Download Failed Data"
                  *ngIf="element.failed_count != null && element.failed_data_attachment && element.status == 'Completed'" (click)="downloadFailedData(element.migration_file_id, element.failed_data_attachment)">
                  <mat-icon class="s-18">cloud_download</mat-icon>
                </button>
                <button mat-icon-button class="accent-fg" matTooltip="Export Failed Data"
                *ngIf="element.failed_count != null && element.status == 'Completed'" (click)="exportFailedData(element.migration_file_id)">
                <mat-icon class="s-18">cloud_upload</mat-icon>
              </button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>

          <div *ngIf="dataSource.data.length == 0" class="text-center">
            <h2 class="sub-heading">No Records Found</h2>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>