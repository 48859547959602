<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">

    <ul class="nav m-0 p-0" fxFlex="auto">
      <li><a class="text-bold" (click)="getDataList('lead_owners')"
          [ngClass]="{'active': getlist == 'lead_owners'}">Lead Owners</a>
      </li>
      <li><a class="text-bold" (click)="getDataList('signup')"
          [ngClass]="{'active': getlist == 'signup'}">Signup Assignments</a></li>
      <li><a class="text-bold" (click)="getDataList('demo_request')"
          [ngClass]="{'active': getlist == 'demo_request'}">Demo Request Assignments</a>
      </li>
    </ul>

  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">

        <div class="search-wrapper" fxFlex="30" fxFlex.lt-xl="20">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [(ngModel)]="searchValue" placeholder="Name, Email, Region">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>

        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="end center">
            <div *ngIf="getlist == 'lead_owners'">
              <button mat-flat-button class="primary mr-4" (click)="addLeadOwner()">Add</button>
            </div>
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource && getlist == 'lead_owners' ">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.first_name}} {{element.last_name}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.email}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="contact_number">
                <mat-header-cell *matHeaderCellDef> Mobile </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.contact_number}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="region">
                <mat-header-cell *matHeaderCellDef> Region </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.country_id == 1">USA</span>
                  <span *ngIf="element.country_id == 2">India</span>
                  <span *ngIf="element.country_id == 702">Singapore</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="sort_order">
                <mat-header-cell *matHeaderCellDef> Sort Order </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.sort_order}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{element.status? 'Active': 'Inactive'}}
                  <ng-container *ngIf="checkLastActivity(element.id)"><mat-icon class="s-18 green-fg">check_circle</mat-icon>
                  </ng-container>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element" >

                  <button mat-icon-button class="accent-fg" matTooltip="Edit" (click)="addLeadOwner(element)">
                    <mat-icon class="s-18">edit</mat-icon>
                  </button>

                  <button mat-icon-button class="red-fg" matTooltip="Delete" (click)="deleteRecord(element.id)" *ngIf="currentUser && currentUser.role_id == 1">
                    <mat-icon class="s-18">delete</mat-icon>
                  </button>

                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="dataSource && getlist != 'lead_owners' ">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Client Name </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.first_name}} {{element.last_name}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef> Client Email </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.email}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="region">
                <mat-header-cell *matHeaderCellDef> Region </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.country_id == 1">USA</span>
                  <span *ngIf="element.country_id == 2">India</span>
                  <span *ngIf="element.country_id == 702">Singapore</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="lead_owner">
                <mat-header-cell *matHeaderCellDef> Lead Owner </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.lfirst_name}} {{element.llast_name}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="lead_owner_email">
                <mat-header-cell *matHeaderCellDef> Lead Owner Email </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.lemail}} </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </div>
</div>