import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { isEmpty } from 'lodash';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-add-gst-dialog',
  templateUrl: './add-gst-dialog.component.html',
  styleUrls: ['./add-gst-dialog.component.scss']
})
export class AddGstDialogComponent implements OnInit {
  gst_number: any;
  gstform: FormGroup;
  company_id: any;
  private gstpattern = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1})+$/;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private commonserv: CommonService,
    private companyDetailService: CompanyDetailsService,
    public dialogRef: MatDialogRef<AddGstDialogComponent>,

  ) {
    this.gst_number = !isEmpty(data.gst_number) ? data.gst_number.toUpperCase() : '';
    this.company_id = data.company_id;
        this.gstform = this.fb.group({
          'gstnumber': ['', [Validators.required, Validators.pattern(this.gstpattern)]]
        })
    if (!isEmpty(this.gst_number)) {
      this.gstform.get('gstnumber').setValue(this.gst_number);
    }

  }
  isEmpty(check) {
    return isEmpty(check);
  }
  update() {
    if (this.gstform.valid) {
      const params = {
        'company_id': this.company_id,
        'gst_number': this.gstform.controls['gstnumber'].value.toUpperCase(),
        'company_name':this.data.company_name,
        'is_cpanel':1
      }
      this.companyDetailService.saveGst(params).subscribe(res => {
        if (res.status) {
          this.commonserv.openSnackBar('primary', res.message);
          this.dialogRef.close(1);
        } else {
          this.commonserv.openSnackBar('warn', res.message);

        }
      })
    }
  }

  ngOnInit() {
  }

}
