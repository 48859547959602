<div class="page-layout carded fullwidth inner-scroll" *ngIf="dataSource">
  <mat-toolbar-row class="secondary-headers w-100-p" *ngIf="!company_id" >
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a (click)="getDoccumentsData('main')" class="text-bold" [ngClass]="{'active': getlist == 'main'}">Global Documents</a></li>
      <li><a (click)="getDoccumentsData('all')" class="text-bold" [ngClass]="{'active': getlist == 'all'}" >Company Documents</a></li>

    </ul>
  </mat-toolbar-row>

  <div class="center px-20">
    <div class="content-card mt-12 mb-56">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
        <div class="search-wrapper" fxFlex="30">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Search...">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>
        <div fxFlex="250px" fxLayout="row wrap" fxLayoutAlign="end center" class="ml-60">
            <mat-form-field class="w-100-p country-p" appearance='outline'>
                <mat-label>Status</mat-label>
                <mat-select value="all" (selectionChange)=changeStatus($event)>
                    <mat-option value = "all" >All</mat-option>
                    <mat-option value = "submitted" >Submitted</mat-option>
                    <mat-option value = "initiated" >Initiated</mat-option>
                    <mat-option value = "notinitiated" >Not Initiated</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="100" class="actions_list">
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50, 100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="limit" [pageIndex]="currentPage-1"></mat-paginator>
          </div>
        </div>
        
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">
              
              <!-- <ng-container matColumnDef="sno">
                <mat-header-cell *matHeaderCellDef fxFlex="100px"> S.no </mat-header-cell>
                <mat-cell *matCellDef="let element;index as i" fxFlex="100px">
                  {{i + 1}}
                </mat-cell>
              </ng-container> -->
             
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element">  {{element.name}} {{element.name ? '/' : ''}} {{element.title}} </mat-cell>
              </ng-container>

              <!-- email Column -->
              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef > Description </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.description}} </mat-cell>
              </ng-container>

               <!-- email Column -->
               <ng-container matColumnDef="signdate" >
                <mat-header-cell *matHeaderCellDef > Sign date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.sign_date ? element.sign_date : 'N/A'}}  </mat-cell>
              </ng-container>

              <!-- role Column -->
              <ng-container matColumnDef="status" >
                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.save_type ? element.save_type : 'Not Initiated'}} </mat-cell>
                <!-- <mat-cell *matCellDef="let element"> {{element.status === 0 ? 'Inactive' : 'Active'}} </mat-cell> -->
              </ng-container>

              <!-- action Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef fxFlex='280px'> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="280px">
                  
                  <button mat-icon-button
                    [ngClass]="{'orange-fg': element.status === 1 , 'primary-fg':element.status === 0}"
                    [matTooltip] = "element.status === 1 ? 'Inactive' : 'Active'" *ngIf="!company_id && element.save_type != 'submitted'" (click)="revokeDoccumentsData(element)">
                    <mat-icon class="s-18">{{element.status === 1 ? 'voice_over_off' : 'person'}}</mat-icon>
                  </button>
                  <button mat-icon-button class="accent-fg" matTooltip="Edit"  *ngIf="element.save_type != 'submitted'" (click)="edit(element)">
                    <mat-icon class="s-18">edit</mat-icon>
                  </button>
                  <button mat-icon-button class="accent-fg" matTooltip="Design" *ngIf="element.unique_id && element.save_type != 'submitted'" (click)="design(element.unique_id)">
                    <mat-icon class="s-18">color_lens</mat-icon> 
                  </button>
                  <button mat-icon-button class="accent-fg" matTooltip="Preview" *ngIf="element.unique_id" (click)="preview(element.unique_id)">
                    <mat-icon class="s-18">visibility</mat-icon> 
                  </button>
                  <button mat-icon-button class="accent-fg" matTooltip="Send-Email" *ngIf="company_id && element.unique_id && element.save_type != 'submitted'" (click)="sendEmail(element.id)">
                    <mat-icon class="s-18">mail</mat-icon> 
                  </button>
                  <button mat-icon-button class="accent-fg" matTooltip="Download" *ngIf="element.save_type == 'submitted'" (click)="downnload(element.unique_id)">
                    <mat-icon class="s-18">cloud_download</mat-icon> 
                  </button>
                  <button mat-icon-button class="red-fg" matTooltip="Delete" *ngIf="!company_id && element.save_type != 'submitted' && element.save_type != 'initiated'" (click)="deleteDoccumentsData(element)" >
                    <mat-icon class="s-18">delete</mat-icon>
                  </button>

                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>
        <!-- <div class="footer">
          <button mat-button (click)="addUser()">Add</button>
          <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
          <button mat-button (click)="showActivities()">Activities</button>
        </div> -->
      </div>
    </div>
  </div>
</div>