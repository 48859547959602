import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { ConfirmExitRequestApproveComponent } from '../confirm-exit-request-approve/confirm-exit-request-approve.component';

@Component({
  selector: 'app-approve-exit-request',
  templateUrl: './approve-exit-request.component.html',
  styleUrls: ['./approve-exit-request.component.scss']
})
export class ApproveExitRequestComponent implements OnInit {

  addonsData: any = {};
  payment_type: any;
  total_amount: any;
  requested_on: any;
  subscription_end_date: any;
  show_wallet_amount:boolean;
  wallet_amount :any =  0;
  add_wallet = false;
  no_refund = 0;

  approveExitRequest: FormGroup;

  constructor(
    private fb: FormBuilder,
    private commonservice: CommonService,
    public dialogRef: MatDialogRef<ApproveExitRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    public dialog: MatDialog,
  ) {
    this.approveExitRequest = this.fb.group({
      total_amount: ['', Validators.required],
      refund_wallet_amount: [false],
    });
  }

  ngOnInit() {
    this.getAddonsData(this.data);

  }

  getAddonsData(payload) {
    this.commonservice.getExitRequestsAddons(payload).subscribe(res => {
      if (res && res.status) {
        let responseData = res.data;
        this.payment_type = responseData.payment_type;
        this.requested_on = responseData.requested_on;
        this.subscription_end_date = responseData.subscription_end_date;
        this.show_wallet_amount = responseData.show_wallet_amount;
        this.wallet_amount = responseData.wallet_amount;

        if (this.payment_type != 'no_due') {
          this.total_amount = responseData.total_amount;
          this.addonsData = responseData.arrData;
          this.approveExitRequest.patchValue({
            total_amount: this.total_amount,
          })
        }
      } else {
        this.commonservice.openSnackBar('warn', 'Invalid Request');
      }
    });
  }

  refundToCustomer() {
    if (this.approveExitRequest.valid) {
      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? # You want to approve this request';
      data['successText'] = 'Approve';
      if (this.payment_type == 'refund_to_customer') {
        if (this.no_refund == 1) {
          data['successText'] = 'Approve';
        } else {
          data['successText'] = 'Approve & Refund';
        }
        data['message'] = 'Are you sure? # You want to approve this refund';
      }
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
        width: '50%',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.approved) {
          let payloadData = {};
          payloadData['company_id'] = this.data.company_id;
          payloadData['request_id'] = this.data.req_id;
          payloadData['comments'] = result.commets;
          payloadData['payment_type'] = this.payment_type;
          payloadData['requested_on'] = this.requested_on;
          payloadData['subscription_end_date'] = this.subscription_end_date;
          payloadData['refund_wallet_amount'] = this.approveExitRequest.value.refund_wallet_amount;
          payloadData['wallet_amount'] = this.wallet_amount;
          payloadData['no_refund'] = this.no_refund;

          if (this.payment_type == 'refund_to_customer') {
            payloadData['approved_amount'] = this.approveExitRequest.value.total_amount;
            payloadData['total_amount'] = this.total_amount;
          }

          if (this.payment_type == 'customer_payment') { 
            if (this.approveExitRequest.value.refund_wallet_amount) {
              payloadData['approved_amount'] = this.wallet_amount;
              payloadData['total_amount'] = this.wallet_amount;
            } else {
              payloadData['approved_amount'] = 0;
              payloadData['total_amount'] = 0;
              payloadData['wallet_amount'] = 0;
            }
          }

          this.commonservice.approveExitRequest(payloadData).subscribe(res => {
            if (res.status) {
              this.commonservice.openSnackBar('primary', res.data.message);
              this.dialogRef.close(true);
            } else {
              this.commonservice.openSnackBar('warn', res.data.message);
              this.dialogRef.close(false);
            }
          });
        }
      })
    }
  }

  approveNoDueRequest() {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to approve this request';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        let payloadData = {};
        payloadData['company_id'] = this.data.company_id;
        payloadData['request_id'] = this.data.req_id;
        payloadData['comments'] = result.commets;
        payloadData['payment_type'] = this.payment_type;
        payloadData['requested_on'] = this.requested_on;
        payloadData['subscription_end_date'] = this.subscription_end_date;
        payloadData['refund_wallet_amount'] = this.add_wallet;
        payloadData['no_refund'] = 1;

        if (this.add_wallet) {
          payloadData['wallet_amount'] = this.wallet_amount;
          payloadData['approved_amount'] = this.wallet_amount;
          payloadData['total_amount'] = this.wallet_amount;
        } else {
          payloadData['wallet_amount'] = 0;
          payloadData['approved_amount'] = 0;
          payloadData['total_amount'] = 0;
        }

        this.commonservice.approveExitRequest(payloadData).subscribe(res => {
          if (res.status) {
            this.commonservice.openSnackBar('primary', res.data.message);
            this.dialogRef.close(true);
          } else {
            this.commonservice.openSnackBar('warn', res.data.message);
            this.dialogRef.close(false);
          }
        });
      }
    })
  }

  // amountChanged() {
  //   let amount = this.approveExitRequest.value.total_amount;
  //   if (amount > this.total_amount) {
  //     this.approveExitRequest.controls['total_amount'].setErrors({ 'incorrect': true });
  //     this.commonservice.openSnackBar('warn', 'Entered amount is more than total refund amount');
  //   }
  // }

  addWalletAmount() {
    if (this.payment_type != 'refund_to_customer') {
      return true;
    }
    let total;
    if (this.approveExitRequest.value.refund_wallet_amount) {
      total = parseFloat(this.approveExitRequest.value.total_amount) + parseFloat(this.wallet_amount);
      total = total.toFixed(2);
      this.total_amount = parseFloat(this.total_amount) + parseFloat(this.wallet_amount);
    } else{
      total = parseFloat(this.approveExitRequest.value.total_amount) - parseFloat(this.wallet_amount);
      total = total.toFixed(2);
      this.total_amount = parseFloat(this.total_amount) - parseFloat(this.wallet_amount);
    }
    this.approveExitRequest.controls['total_amount'].setValue(total);
    // this.amountChanged();
  }

  refundWalletAmount() {
    // console.log(this.add_wallet);
  }

}
