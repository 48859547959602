<div>
  
  <div fxLayout="row wrap" class="border-b-1">
    <ul fxFlex class="top-nav m-0 p-0">
      <li><a class="text-bold" class="active">All</a></li>
      <li><a class="text-bold" [routerLink]="['discard_reports']">Discard Reports</a></li>
      <!-- <li><a class="text-bold" (click)="getClientsData('today')" [ngClass]="{'active': getlist == 'today'}">Expiring
        today</a>
      </li>
      <li><a class="text-bold" (click)="getClientsData('week')" [ngClass]="{'active': getlist == 'week'}">Expiring
        this
        week</a></li>
        <li><a class="text-bold" (click)="getClientsData('2days')" [ngClass]="{'active': getlist == '2days'}">Expiring
          in
          next 2days</a>
        </li> -->
      </ul>
      
      <div fxFlex="23" fxLayout="row wrap" fxLayoutAlign="end center">
        <mat-form-field class="w-100-p country-p" appearance='outline'>
          <mat-select [(ngModel)] ="country_id" (selectionChange)="countryChanged()">
            <mat-option *ngFor="let item of countries" [value]="item.value">
            {{item.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      
        <div fxFlex="23" fxLayout="row wrap" fxLayoutAlign="end center">
          <mat-form-field class="w-90-p mr-20" appearance="outline" [matMenuTriggerFor]="menu">
            <mat-label>Select subscription end date</mat-label>
            <mat-date-range-input [rangePicker]="rangePicker" [formGroup]="dateRangeForm">
                <input matStartDate placeholder="Start date" readonly
                    formControlName="start_date">
                <input matEndDate placeholder="End date" readonly formControlName="end_date">
            </mat-date-range-input>
        </mat-form-field>

        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="setDate('this_week')"
                [ngClass]="{'primary': (dateActive == 'this_week')}">This Week</button>
            <button mat-menu-item (click)="setDate('this_month')"
                [ngClass]="{'primary': (dateActive == 'this_month')}">This Month</button>
            <button mat-menu-item (click)="setDate('last_month')"
                [ngClass]="{'primary': (dateActive == 'last_month')}">Last Month</button>
            <button mat-menu-item (click)="rangePicker.open();dateActive = 'custom';"
                [ngClass]="{'primary': dateActive == 'custom'}">Custom Range</button>
            <button mat-menu-item (click)="setDate('clear')"
                [ngClass]="{'primary': dateActive == 'clear'}">Clear</button>
            <!-- <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle> -->
            <mat-date-range-picker #rangePicker>
                <mat-date-range-picker-actions>
                    <button mat-button matDateRangePickerCancel>Cancel</button>
                    <button mat-raised-button color="primary"
                        matDateRangePickerApply>Apply</button>
                </mat-date-range-picker-actions>
            </mat-date-range-picker>
        </mat-menu>
        </div>
    </div>
  <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
    <div class="search-wrapper" fxFlex="30">
      <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
        [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Company Name">
      <mat-icon class="search_icon">search</mat-icon>
      <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
        close
      </mat-icon>
    </div>
    <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">

     

      <div>
        <button class="warn mr-12" mat-stroked-button *ngIf="getlist == 'all' && logginUser?.role_name == 'admin'" (click)="forceLogout()">Force Logout</button>
        <button class="primary" mat-stroked-button *ngIf="getlist == 'all' && logginUser?.role_name == 'admin'" (click)="exportExcel()">Export as Excel</button>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
          [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<cdk-virtual-scroll-viewport itemSize="50" class="scroll-content-height" fusePerfectScrollbar>
  <div class="content">
    <ng-container *ngIf="dataSource">
      <div class="px-12 pb-12">
        <mat-table [dataSource]="dataSource">
          <!-- customerName Column -->
          <ng-container matColumnDef="customerName">
            <mat-header-cell *matHeaderCellDef> customer Name
              <span>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="sortColumnData('c.name','asc')">
                    <mat-icon>sort</mat-icon>
                    <span>Sort Ascending</span>
                  </button>
                  <button mat-menu-item (click)="sortColumnData('c.name','desc')">
                    <mat-icon>sort</mat-icon>
                    <span>Sort Descending</span>
                  </button>
                </mat-menu>
            </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> 
              <a *ngIf="element.is_db_deleted == 0" (click)="navigate(element)" class="cursor-pointer">{{element.name}}</a>
              <span *ngIf="element.is_db_deleted == 1">{{element.name}}</span>
            </mat-cell>
          </ng-container>
          <!-- name Column -->
          <ng-container matColumnDef="contactName">
            <mat-header-cell *matHeaderCellDef> Contact Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.client_info?.first_name}}
              {{element.client_info?.last_name}} </mat-cell>
          </ng-container>

          <!-- email Column -->
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef fxFlex="200px">contact Email </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="200px" class="lowercase"> {{element.client_info?.email}}
            </mat-cell>
          </ng-container>

          <!-- subdomain Column -->
          <ng-container matColumnDef="subdomain">
            <mat-header-cell *matHeaderCellDef> sub domain name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.sub_domain_name}} </mat-cell>
          </ng-container>

          <!-- region Column -->
          <ng-container matColumnDef="region">
            <mat-header-cell *matHeaderCellDef> region
              <span>
                <button mat-icon-button [matMenuTriggerFor]="menu1">
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu1="matMenu">
                  <button mat-menu-item (click)="sortColumnData('c.region','asc')">
                    <mat-icon>sort</mat-icon>
                    <span>Sort Ascending</span>
                  </button>
                  <button mat-menu-item (click)="sortColumnData('c.region','desc')">
                    <mat-icon>sort</mat-icon>
                    <span>Sort Descending</span>
                  </button>
                </mat-menu>
            </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element" > 
              <span *ngIf="element.region != 'usa'">{{element.region | titlecase}}</span>
              <span *ngIf="element.region == 'usa'">{{element.region | uppercase }}</span>
            </mat-cell>
          </ng-container>

          <!-- status Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> status </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.status === 1 ? 'active' : 'inactive'}} </mat-cell>
          </ng-container>

           <!-- MSA and SOW Column -->
           <ng-container matColumnDef="msaandsow">
            <mat-header-cell *matHeaderCellDef> msa and sow </mat-header-cell>
            <mat-cell *matCellDef="let element"> 

              <span *ngIf="!(element.msa_unique_name)">Not Avilable</span>
                    
                <div>
                    <button  mat-icon-button class="accent-fg" matTooltip="MSA Document" 
                    *ngIf="element.msa_unique_name"
                        (click)="downloadMSaDoc(element.id,element.msa_original_name, 'msa_unique_name')">
                        <mat-icon class="s-18">insert_drive_file</mat-icon>
                    </button>
                    
                    
                    <button mat-icon-button class="primary-fg" matTooltip="Upload MSA Document" (click)="hiddenMsacertificate.click()">
                        <mat-icon class="s-18">cloud_upload</mat-icon>
                    </button>

                    <input style="display: none" name="file" type="file" onclick="this.value = null"
                    (change)="uploadMsaSow($event,element.id)" #hiddenMsacertificate />
                </div>

                  
            </mat-cell>
          </ng-container>

          <!-- Demo Recording URL -->
          <ng-container matColumnDef="demo_record_url">
            <mat-header-cell *matHeaderCellDef> Demo Recording URL </mat-header-cell>
            <mat-cell *matCellDef="let element"> 
              <!-- matBadge="{{element?.demo_record_url_cnt}}" matBadgeColor="warn" 
              (click)="viewComment(element)"
                -->
                <button *ngIf="element?.demo_record_url_cnt" (click)="viewDemoUrl(element)" 
                mat-icon-button  matTooltip="View Demo Recording URL">
                <mat-icon class="s-18">remove_red_eye</mat-icon>
                </button>

                <button mat-icon-button  class="teal-fg" matTooltip="Add Demo Recording URL">
              <mat-icon (click)="$event.stopPropagation();addDemoUrl(element.id)"  class="s-18">videocam</mat-icon>
             </button>

            </mat-cell>
          </ng-container>

          <!-- Sales rep Column -->
          <ng-container matColumnDef="sales_user">
            <mat-header-cell *matHeaderCellDef> Sales Rep </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="userslist" >{{ (element.sales_user_id) ? (userslist | getById:element.sales_user_id:'first_name' ) +" "+ (userslist | getById:element.sales_user_id:'last_name' )  : '-'}} </span> 
            </mat-cell>
          </ng-container>

          <!-- Support executive Column -->
          <ng-container matColumnDef="support_user">
            <mat-header-cell *matHeaderCellDef> Support Executive </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="userslist">{{ (element.support_user_id) ? (userslist | getById:element.support_user_id:'first_name' ) +" "+ (userslist | getById:element.support_user_id:'last_name' )  : '-'}}</span>
            </mat-cell>
          </ng-container>

          <!-- subscriptionenddate Column -->
          <ng-container matColumnDef="subscriptionenddate">
            <mat-header-cell *matHeaderCellDef fxFlex="150px"> subscription end date
              <span>
                <button mat-icon-button [matMenuTriggerFor]="menu2">
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu2="matMenu">
                  <button mat-menu-item (click)="sortColumnData('csd.subscr_end_date','asc')">
                    <mat-icon>sort</mat-icon>
                    <span>Sort Ascending</span>
                  </button>
                  <button mat-menu-item (click)="sortColumnData('csd.subscr_end_date','desc')">
                    <mat-icon>sort</mat-icon>
                    <span>Sort Descending</span>
                  </button>
                </mat-menu>
            </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="150px"
            > {{element.subscr_end_date | date:"MM/dd/yyyy"}} </mat-cell>
          </ng-container>

          <!-- action Column -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button class="accent-fg" matTooltip="View" (click)="navigate(element)" [disabled]="element.status == 0?true:false">
                <mat-icon class="s-18">remove_red_eye</mat-icon>
              </button>
              <button mat-icon-button class="accent-fg" matTooltip="Update Sales and Support Exucutive" (click)="edit(element)"  [disabled]="element.status == 0?true:false">
                <mat-icon class="s-18">group_add</mat-icon>
              </button>

              <button matBadge="{{element?.comments_cnt}}" matBadgeColor="warn" 
              (click)="viewComment(element)" mat-icon-button  class="teal-fg" matTooltip="Comments"
                    >
                    <mat-icon   (click)="$event.stopPropagation();addComment(element.id)"  class="s-18">comments</mat-icon>
             </button>
              <!-- <button mat-icon-button class="accent-fg" matTooltip="Renew" *ngIf="subscr_end[element.company_id] && element.status" (click)="renewRoute(element.company_id)" [disabled]="element.status == 0?true:false">
                <mat-icon class="s-18">monetization_on</mat-icon>
              </button> -->
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div *ngIf="dataSource.data.length == 0" class="text-center">
          <h2 class="heading m-0 py-16 border">No Records Found</h2>
        </div>
      </div>
    </ng-container>
  </div>
</cdk-virtual-scroll-viewport>

<div class='footer'>
  <button mat-button (click)="showActivities()">Activities</button>
</div>