<div [formGroup]="form" class="w-100-p p-20 mb-52">
  <div fxLayout="row wrap" fxLayoutAlign="space-between start">
    <div fxFlex="70">
      <div class="">
        <mat-form-field appearance="outline" class="w-100-p">
          <mat-label>Subject</mat-label>
          <input matInput formControlName="subject">
        </mat-form-field>
      </div>
      <div class="mt-12">
        <div class="font-size-12 text-bold my-4">Message Body</div>
        <ckeditor formControlName="mail_body" [config]="config" (ready)="onReady($event)"></ckeditor>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <button mat-button (click)="back()">Back</button>
  <button mat-button class="" (click)="save()">Save</button>
</div>