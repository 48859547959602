<div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
  <div class="search-wrapper" fxFlex="30">
    <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
      [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Template name,subject ..">
    <mat-icon class="search_icon">search</mat-icon>
    <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
      close
    </mat-icon>
  </div>
  <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
    <div fxLayout="row" fxLayoutAlign="end center">
      <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
        [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
    </div>
  </div>
</div>
<div class="content" fusePerfectScrollbar>
  <ng-container *ngIf="dataSource">
    <div class="p-12">
      <mat-table [dataSource]="dataSource">
        <!-- template Column -->
        <ng-container matColumnDef="template">
          <mat-header-cell *matHeaderCellDef> Template Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.name}}
          </mat-cell>
        </ng-container>
        <!-- Subject Column -->
        <ng-container matColumnDef="subject">
          <mat-header-cell *matHeaderCellDef> Subject </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.subject}} </mat-cell>
        </ng-container>

        <!-- action Column -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef fxFlex="150px"> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="150px">
            <button mat-icon-button class="accent-fg" matTooltip="View" (click)="view(element)">
              <mat-icon class="s-18">remove_red_eye</mat-icon>
            </button>
            <button mat-icon-button class="accent-fg" matTooltip="Edit" (click)="edit(element)">
              <mat-icon class="s-18">edit</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <div *ngIf="dataSource.data.length == 0" class="text-center">
        <h2 class="heading m-0 py-16 border">No Records Found</h2>
      </div>
    </div>
  </ng-container>
</div>