<h1 mat-dialog-title class='text-uppercase'>Account Details</h1>
<mat-divider></mat-divider>
<form [formGroup]="addTextRecruit" (ngSubmit)="addSignup()">
  <mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Phone/Sender Id</mat-label>
          <input type="text" formControlName="phone_number" matInput>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Account Sid/Project Id</mat-label>
          <input type="text" formControlName="account_sid" matInput>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Auth Token</mat-label>
          <input type="text" formControlName="auth_token" matInput>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Total Credits</mat-label>
          <input type="text" formControlName="credits" matInput>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Credit Expiry</mat-label>
          <input matInput [matDatepicker]="picker" (click)="picker.open()" formControlName="expire_at">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field appearance="outline" class="w-100-p">
          <mat-label>Provider Name</mat-label>
          <mat-select formControlName="provider_type">
            <mat-option *ngFor="let val of provider_name" [value]="val.id">
              {{val.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button class='primary mr-4'>Save</button>
  </mat-dialog-actions>
</form>