import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-cron-email-cleanup',
  templateUrl: './cron-email-cleanup.component.html',
  styleUrls: ['./cron-email-cleanup.component.scss']
})
export class CronEmailCleanupComponent implements OnInit {
  params: { companies_data: {}; company_id: number; limit: number; page: number; view_status: string; };
  id: any;
  displayedColumns: string[] = ['sno', 'company', 'listname', 'description', 'createdon', 'actions'];
  dataSource;
  searchValue = '';
  getTableData: any;
  length: any;
  pageSize: any;
  index: any;

  constructor(private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    private route: ActivatedRoute,
    public dialog: MatDialog) {
    this.id = this.route.parent.snapshot.params['id'];
  }

  ngOnInit() {
    this.params = { companies_data: {}, company_id: Number(this.id), limit: 15, page: 1, view_status: "pending" };
    this.getList();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }
  getList() {
    this.companyDetailService.cronEmails(this.params).subscribe(res => {
      if (res['status'] === 1) {
        this.dataSource = new MatTableDataSource(res['cron_emails'].data);
        this.getTableData = res['cron_emails'];
        this.pageSize = this.getTableData.per_page;
        this.length = this.getTableData.total;
        this.index = this.getTableData.current_page;
      }
    })
  }
  cleanMail(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to Clean email';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.companyDetailService.cronEmailClean({ company_id: Number(this.id), contact_id: element.contact_id }).subscribe(res => {
          if (res['status'] === 1) {
            this.commonService.openSnackBar('primary', res['message']);
          }
          else {
            this.commonService.openSnackBar('warn', res['message']);
          }
        })
      } else {

      }
    })
  }
}
