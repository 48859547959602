<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">Reveneu Forecast</h2>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12 py-12" *ngIf="dataSource">
       
        <div fxFlex="10" class="p-12">
          <mat-form-field class="" appearance='outline'>
            <mat-label>USD in INR</mat-label>
            <input matInput type="number" [(ngModel)]="searchValue" (keyup.enter)="applyFilter($event.target.value)">
            <button *ngIf="searchValue" (click)="searchValue=''; applyFilter('');" matSuffix mat-icon-button
              aria-label="Clear">
              <mat-icon class="close s-20 danger-fg">
                close
              </mat-icon>
            </button>
          </mat-form-field>
        </div>

        <div fxFlex="80" class="actions_list" fxLayout="row wrap" fxLayoutAlign="end center">
          <div>
            <button mat-stroked-button (click)="exportRevenueForecost()">Export</button>
          </div>
        </div>

      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="yearmonth">
                <mat-header-cell *matHeaderCellDef> Year-Month </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.year_month}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef> Billing </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> $ {{element.revenue| number:'1.2-2'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="mmr">
                <mat-header-cell *matHeaderCellDef> MRR </mat-header-cell>
                <mat-cell *matCellDef="let element"> $ {{element.mrr | number:'1.2-2'}} </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>