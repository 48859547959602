<h1 mat-dialog-title class='text-uppercase'>MX Records Validation Remainder</h1>
<mat-divider></mat-divider>

<mat-dialog-content>
  <!-- <div class="page-layout carded fullwidth inner-scroll"> -->
  <div class="center px-20">
    <div class="content-card mt-12 mb-56">
      <div class="content p-20" fusePerfectScrollbar>
        <div [formGroup]="MXRecordsValidation" class="w-100-p">
          <div fxLayout="row" fxLayoutAlign="space-between start">
            <div fxFlex='48'>
              <ng-container>
                <!-- <div class="mt-12" fxLayout='row' fxLayoutAlign="start center">
                  <div fxFlex>
                  </div>
                  <div fxFlex='100px' fxLayout='row' fxLayoutAlign="space-evenly center" class="ml-20">
                    <div>
                      <span class="font-size-12 text-bold cursor-pointer" [ngClass]="{'accent-fg': cc}"
                        (click)="showCc($event)">CC</span>
                    </div>
                    <div>
                      <span class="font-size-12 mr-0 text-bold cursor-pointer" [ngClass]="{'accent-fg': bcc}"
                        (click)="showBcc($event)">BCC</span>
                    </div>
                  </div>
                </div> -->

                <div *ngIf="cc">
                  <mat-form-field class="w-100-p" appearance="outline">
                    <span matPrefix class="font-size-12">CC &nbsp;</span>
                    <mat-chip-list #CCEmailchipList formArrayName="cc">
                      <mat-chip [color]="(email.invalid)?'warn':''" selected
                        *ngFor="let email of MXRecordsValidation.get('cc').controls; let i=index;"
                        [selectable]="selectable" [removable]="removable" (removed)="removeEmail(i,'cc')" name="chips"
                        class="text-lowercase">
                        {{email.value}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip>

                      <input [matChipInputFor]="CCEmailchipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addEmail($event,'cc')">

                    </mat-chip-list>
                  </mat-form-field>
                </div>

                <div *ngIf="bcc">
                  <mat-form-field class="w-100-p" appearance="outline">
                    <span matPrefix class="font-size-12">BCC &nbsp;</span>
                    <mat-chip-list #BCCEmailchipList formArrayName="bcc">
                      <mat-chip [color]="(email.invalid)?'warn':''" selected
                        *ngFor="let email of MXRecordsValidation.get('bcc').controls; let i=index;"
                        [selectable]="selectable" [removable]="removable" (removed)="removeEmail(i,'bcc')"
                        class="text-lowercase">
                        {{email.value}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip>

                      <input [matChipInputFor]="BCCEmailchipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addEmail($event,'bcc')">

                    </mat-chip-list>
                  </mat-form-field>

                </div>

              </ng-container>
              <div class="mt-20">
                <mat-form-field appearance="outline" class="w-70-p">
                  <mat-label>Subject</mat-label>
                  <input matInput formControlName="subject">
                </mat-form-field>
              </div>
            </div>
            <div fxFlex='48'>
              <div class="mt-20">
                <div class="font-size-12 text-bold my-4">Message Body</div>
                <ckeditor formControlName="mail_body" (ready)="onReady($event)"></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</mat-dialog-content>

<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-button class="primary" (click)="send()">Send</button>
</mat-dialog-actions>