import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ExcelService } from 'app/services/excel.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ReportsService } from 'app/services/reports.service';


@Component({
  selector: 'app-payment-reports',
  templateUrl: './payment-reports.component.html',
  styleUrls: ['./payment-reports.component.scss']
})
export class PaymentReportsComponent implements OnInit {

  // ejs datepicker
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  // public lastEnd: Date = this.today;
  public lastEnd: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  // public yearStart = moment().startOf('year');
  public yearEnd: Date = this.today;
  displayedColumns: string[] = ['customer_name', 'sales_rep', 'invocie_ref', 'transaction_id', 'invoice_type', 'amount', 'payment_mode', 'paid_on', 'actions'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  params: { page: number; limit?: number, searchKey?: any, company_id?: any, status_type?: any, date_range?: any, country_id?: any, paymentType?: any };
  paymentReportForm: FormGroup;
  selectedDateRange = {};
  country_id: any = '1';
  paymentType: any = '0';
  dateActive = '';

  status_list = [
    { value: '1', viewValue: 'Paid' },
    { value: '2', viewValue: 'Failed' },
  ];

  countries = [
    { value: '1', viewValue: 'USA' }, { value: '2', viewValue: 'India' }, { value: '702', viewValue: 'Singapore' }
  ];

  paymentTypes = [
    { value: '0', viewValue: 'All' }, { value: 'card', viewValue: 'Card' }, { value: 'bank', viewValue: 'Bank' }, { value: 'cheque', viewValue: 'Cheque' }, { value: 'stripe_offline', viewValue: 'Offline' }
  ];

  constructor(
    private commonService: CommonService,
    private reportService:ReportsService,
    private excelService: ExcelService,
    private datepipe: DatePipe, private router: Router, private fb: FormBuilder) {

    this.paymentReportForm = this.fb.group({
      status: ['1'],
      start_date: [''],
      end_date: ['']
    });

    this.paymentReportForm.valueChanges.pipe(debounceTime(0), distinctUntilChanged()).subscribe(res => {
      this.params.status_type = res.status;
      this.params.date_range = '';
      if (res.start_date && res.end_date && this.params.status_type) {
        this.selectedDateRange['startDate'] = moment(res.start_date).format("YYYY-MM-DD");
        this.selectedDateRange['endDate'] = moment(res.end_date).format("YYYY-MM-DD");
        this.params.date_range = this.selectedDateRange ? JSON.stringify(this.selectedDateRange) : '';
      }
      if (this.params.status_type != null) {
        this.getList();
      }
    });
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1, status_type: '1' };
    this.getList();
  }

  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.params.country_id = this.country_id;
    this.reportService.getPaymentReportData(this.params).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data.data);
        this.length = res.data.total;
        this.pageSize = res.data.per_page;
        this.index = res.data.current_page;
      }
    })
  }

  navigate(element, blank) {
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }

  clearFilters() {
    this.selectedDateRange = {};
    this.paymentReportForm.reset();
    this.params = { limit: 15, page: 1 };
    this.paymentReportForm.patchValue({ 'status': '1' });
  }

  downloadInvocieDoc(company_id, invoice_id, fileName, columnName) {
    this.commonService.downloadInvoice(company_id, invoice_id, columnName).subscribe(res => {
      this.showPDFFile(res, fileName);
    });
  }

  showPDFFile(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const dataURL = URL.createObjectURL(blob);
    window.open(dataURL, '_blank');
  }

  trimAmount(txt) {
    let amount;
    amount = txt.replace(/-/g, "");
    return parseFloat(amount);
  }

  exportExcel() {
    let exportParams = {};
    exportParams['limit'] = this.length;
    exportParams['page'] = 1;
    exportParams['status_type'] = this.params.status_type;
    exportParams['date_range'] = this.params.date_range;
    exportParams['searchKey'] = this.params.searchKey;
    exportParams['country_id'] = this.country_id;
    this.reportService.getPaymentReportData(exportParams).subscribe(result => {
      if (result.status === 1) {
        let prepared_data = this.prepareExcelData(result.data.data);
        let report_title;
        if (this.selectedDateRange['startDate']) {
          report_title = 'Payments Report - ' + this.selectedDateRange['startDate'] + ' - ' + this.selectedDateRange['endDate'];
        } else {
          report_title = 'Payments Report';
        }
        // console.log('pre', report_title);return;
        this.excelService.exportAsExcelFile(prepared_data, report_title);
      }
    })
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name'] = element.company_name ? element.company_name : 'Company Deleted';
      eachRow['Sales Representative'] = element.sales_executive_name;
      eachRow['Invocie Ref#'] = element.ref_id;
      eachRow['Trasaction ID'] = element.trasaction_id ? element.trasaction_id : '-';
      eachRow['Amount($)'] = this.trimAmount(element.amount);
      eachRow['Status'] = element.status;
      eachRow['Invoice Type'] = element.item_details;
      eachRow['Paid On'] = element.updated_at;
      ArrData.push(eachRow);
    });
    return ArrData;
  }

  countryChanged() {
    this.searchValue = '';
    this.params.limit = 15;
    this.params.page = 1;
    this.params.status_type = 1;
    this.params.country_id = this.country_id;
    this.params.searchKey = '';
    this.getList();
  }

  paymentTypeChanged() {
    this.searchValue = '';
    this.params.limit = 15;
    this.params.page = 1;
    this.params.status_type = 1;
    this.params.country_id = this.country_id;
    this.params.searchKey = '';
    this.params.paymentType = this.paymentType;
    this.getList();
  }

  getCurrencySymbol() {
    if (this.country_id == 2) {
      return '₹';
    } else {
      return '$';
    }
  }

  setDate(date_type: string) {
    this.dateActive = date_type;
    switch (date_type) {
      case 'this_week':
        this.paymentReportForm.patchValue({
          start_date: this.datepipe.transform(this.weekStart, 'yyyy-MM-dd'),
          end_date: this.datepipe.transform(this.weekEnd, 'yyyy-MM-dd')
        });
        break;
      case 'this_month':
        this.paymentReportForm.patchValue({
          start_date: this.datepipe.transform(this.monthStart, 'yyyy-MM-dd'),
          end_date: this.datepipe.transform(this.monthEnd, 'yyyy-MM-dd')
        });
        break;
      case 'last_month':
        this.paymentReportForm.patchValue({
          start_date: this.datepipe.transform(this.lastStart, 'yyyy-MM-dd'),
          end_date: this.datepipe.transform(this.lastEnd, 'yyyy-MM-dd')
        });
        break;
      default:
        this.paymentReportForm.patchValue({
          start_date: '',
          end_date: ''
        });
        break;
    }
  }
}
