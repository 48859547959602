// import 'rxjs/add/operator/do';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { tap } from 'rxjs/operators';
import { SharedServiceService } from './shared-service.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router, 
        private commonservice: CommonService, 
        private _location: Location,
        private sharedService : SharedServiceService
        ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.commonservice.getLoader();
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
                // console.log(HttpResponse, event.status);
                this.commonservice.getLoader();
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                // console.log('err', err);
                
                if (err.status === 401) {
                    this.commonservice.openSnackBar('warn', err.error.message ? err.error.message : 'Something went wrong please try again');
                    this.router.navigate(['login']);
                    this.commonservice.getLoader();
                }
                else if (err.status === 500) {
                    this.commonservice.openSnackBar('warn', err.error.message ? err.error.message : 'Something went wrong please try again');
                    this.commonservice.getLoader();
                }
                else if (err.status === 403) {
                    this.commonservice.openSnackBar('warn', err.error.message ? err.error.message : 'Something went wrong please try again');
                    if(this.sharedService.getToken()) {
                        this.router.navigate(['dashboard']);
                    } else {
                        this.router.navigate(['login']);
                    }                    
                    this.commonservice.getLoader();
                }
                else {
                    this.commonservice.openSnackBar('warn', err.error.message ? err.error.message : 'Something went wrong please try again');
                    // this.router.navigate(['login']);
                    this.commonservice.getLoader();
                }
            }
        }));
    }
}