<ng-container *ngIf="dataSource">
  <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12">
    <div class="search-wrapper" fxFlex="30">
      <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
        [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Phone Number">
      <mat-icon class="search_icon">search</mat-icon>
      <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
        close
      </mat-icon>
    </div>
    <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
      <button mat-flat-button class="primary mr-4" (click)="add()" [disabled]="isLostAccount">Add</button>
      <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
        [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
    </div>
  </div>
  <div class="px-12 pb-12">
    <mat-table [dataSource]="dataSource">
      <!-- phone Column -->
      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.phone_number}}
        </mat-cell>
      </ng-container>
      <!-- Account SID Column -->
      <ng-container matColumnDef="AccountSID">
        <mat-header-cell *matHeaderCellDef> Account SID </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.account_sid}} </mat-cell>
      </ng-container>

      <!-- 	Auth Token Column -->
      <ng-container matColumnDef="AuthToken">
        <mat-header-cell *matHeaderCellDef fxFlex="200px"> Auth Token </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="200px"> {{element.auth_token}} </mat-cell>
      </ng-container>

      <!-- Total Credits Column -->
      <ng-container matColumnDef="TotalCredits">
        <mat-header-cell *matHeaderCellDef> Total Credits </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.credits}} </mat-cell>
      </ng-container>

      <!-- 	Credits Expiry Column -->
      <ng-container matColumnDef="CreditsExpiry">
        <mat-header-cell *matHeaderCellDef> Credits Expiry </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.expire_at === null ? 'N/A' : (element.expire_at | date:'MM/dd/yyyy')}} </mat-cell>
      </ng-container>

      <!-- Availbel Column -->
      <ng-container matColumnDef="Available">
        <mat-header-cell *matHeaderCellDef> Available </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.credits - element.used_credits}} </mat-cell>
      </ng-container>

      <!-- action Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button class="accent-fg" matTooltip="Edit" (click)="edit(element)" [disabled]="isLostAccount">
            <mat-icon class="s-18">edit</mat-icon>
          </button>
          <button mat-icon-button class="red-fg" matTooltip="Delete" (click)="delete(element)" [disabled]="isLostAccount">
            <mat-icon class="s-18">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div *ngIf="dataSource.data.length == 0" class="text-center">
      <h2 class="heading m-0 py-16 border">No Records Found</h2>
    </div>
  </div>
</ng-container>