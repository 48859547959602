import { Injectable } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ValidateService {

    constructor() { }
    validateAllFormFields(formGroup: any) {
        // This code also works in IE 11
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);

            if (control instanceof FormControl) {
                control.markAsTouched();
            } else if (control instanceof FormGroup) {
                control.markAsTouched();
                this.validateAllFormFields(control);
            } else if (control instanceof FormArray) {
                control.markAsTouched();
                this.validateAllFormFields(control);
            }
        });
    }
}