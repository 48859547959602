import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { Router } from '@angular/router';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { ConfigurationService } from 'app/services/configuration.service';


@Component({
  selector: 'app-cron-jobs',
  templateUrl: './cron-jobs.component.html',
  styleUrls: ['./cron-jobs.component.scss']
})
export class CronJobsComponent implements OnInit {
  displayedColumns: string[] = ['cronjobname', 'time', 'status', 'actions'];
  dataSource;
  length: any;
  pageSize: any;
  getTableData: any;
  index: any;
  params: { limit: number; page: number; };

  constructor(
    private commonservice: CommonService,
    private configService: ConfigurationService,
    public dialog: MatDialog,
    private router: Router) { }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    this.getData();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getData();
  }
  getData() {
    this.configService.cronjobslist(this.params).subscribe(result => {
      if (result) {
        this.getTableData = result.cron_jobs;
        this.length = this.getTableData.total;
        this.pageSize = this.getTableData.per_page;
        this.index = this.getTableData.current_page;
        this.dataSource = new MatTableDataSource(this.getTableData.data);
      }
    })
  }
  updateStatus(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = element.status == 1 ? 'Are you sure? # You want to switch off CronJob' : 'Are you sure? # You want to switch on CronJob';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.configService.cronjobstatusupdate({ status: element.status == 1 ? 0 : 1, id: element.id, cron_name: element.cron_job_name }).subscribe(res => {
          if (res.status === 1) {
            this.getData();
            this.commonservice.openSnackBar('primary', res.message);
          }
          else {
            this.commonservice.openSnackBar('primary', res.message);
          }
        })
      } else {

      }
    })
  }
  navigate(element) {
    this.router.navigate(['configuration/cronjobs/log_activities', element.id])
  }
  showActivities() {
    let activityData = {
      'company_id': 0,
      'module_slug': 'cronJobs',
      'show_company_name': false,
      'module_name': 'Cron Jobs'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
