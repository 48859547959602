import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/services/common.service';
import { ExcelService } from 'app/services/excel.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { EditFieldsComponent } from './edit-fields/edit-fields.component';
import { debounceTime, distinctUntilChanged, last, take } from 'rxjs/operators';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { ClientsService } from 'app/services/clients.service';
import { ConfirmExitRequestApproveComponent } from 'app/main/company-details/confirm-exit-request-approve/confirm-exit-request-approve.component';
@Component({
  selector: 'app-client-all',
  templateUrl: './client-all.component.html',
  styleUrls: ['./client-all.component.scss']
})
export class ClientAllComponent implements OnInit {
  displayedColumns: string[] = ['customerName', 'contactName', 'email', 'subdomain', 'region', 'status', 'msaandsow','demo_record_url', 'sales_user', 'support_user', 'subscriptionenddate', 'actions'];
  dataSource;
  searchValue = '';
  getlist: any;
  getTableData: any;
  length: any;
  pageSize: any;
  index: any;
  getDateRange = {};
  dateActive = 'last_30days';

  countries = [
    { value: 'all', viewValue: 'All' }, { value: '1', viewValue: 'USA ($)' }, { value: '2', viewValue: 'India (₹)' }, { value: '702', viewValue: 'Singapore (S$)' }
  ];
  country_id: any = 'all';

  // ejs datepicker
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  // public lastEnd: Date = this.today;
  public lastEnd: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  // public yearStart = moment().startOf('year');
  public yearEnd: Date = this.today;
  // public value: Date[] = [this.yearStart, this.yearEnd];
  // ejs datepicker completed
  dateRangeForm: FormGroup;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  params: { all_users: number; limit: number; page: number; searchKey?: string; search_filter?: string; type: any; dateRange?: any; country_id?: any; sort_column?: any; sort_type?: any };
  userslist: any = [];
  permissions: any;
  subscr_end: any = {};
  logginUser: any;
  constructor(private commonservice: CommonService,
    private clientService: ClientsService,
    private excelService: ExcelService,
    public dialog: MatDialog,
    public router: Router,
    private datepipe: DatePipe,
    private fb: FormBuilder,
    public datePipe: DatePipe) {

    this.commonservice.currentUser.subscribe(res => {
        if(res) {
          this.logginUser = res;
        }
    });
    this.dateRangeForm = this.fb.group({
      start_date: [''],
      end_date: ['']
    });
    this.dateRangeForm.valueChanges.pipe(debounceTime(0), distinctUntilChanged()).subscribe(res => {
      console.log(res);
      if (res) {
        if (res.start_date && res.end_date) {
          this.getDateRange['startDate'] = this.datepipe.transform(res.start_date, 'yyyy-MM-dd');
          this.getDateRange['endDate'] = this.datepipe.transform(res.end_date, 'yyyy-MM-dd');
          this.params.dateRange = JSON.stringify(this.getDateRange);
        }
        else {
          this.params.dateRange = '';
        }
        this.getClientsData('');
      }
    });
  }

  ngOnInit() {
    this.getClientsData('all');
    this.commonservice.permissions.subscribe(res => {
      this.permissions = res;
    })


    // this.paginator.pageIndex = this.index;
    // this.permissions = this.commonservice.masterhomepermissions;
    // console.log(this.commonservice.masterhomepermissions);
    // if(this.permissions['users']) {
    // }

  }
  navigate(element, blank) {
    // this.router.navigate(['company_details', element.company_id]);
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }
  navigatetoImport(element) {
    this.router.navigate(['import', element.company_id])
  }
  applyFilter(filterValue: string) {
    this.params.searchKey = filterValue;
    this.params.page = 1;
    this.getClientsData('');
  }
  pageEvent(event) {
    // console.log(event, this.getlist);
    this.params.all_users = 1;
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.params.type = 'paid';
    this.getClientsData('');
  }
  getClientsData(getnav) {
    switch (getnav) {
      case 'all':
        this.getlist = getnav;
        this.params = { all_users: 1, limit: 15, page: 1, type: 'paid' }
        break;
      case 'today':
        this.getlist = getnav;
        this.params = { all_users: 1, limit: 15, page: 1, searchKey: '', search_filter: 'search_filter_today', type: 'paid' }
        break;
      case 'week':
        this.getlist = getnav;
        this.params = { all_users: 1, limit: 15, page: 1, searchKey: '', search_filter: 'search_filter_week', type: 'paid' }
        break;
      case '2days':
        this.getlist = getnav;
        this.params = { all_users: 1, limit: 15, page: 1, searchKey: '', search_filter: 'search_filter_intwodys', type: 'paid' }
        break;
      default:
        break;
    }
    this.params.country_id = this.country_id;
    this.commonservice.getList(this.params).subscribe(result => {
      if (result.status === 1) {
        // console.log(result);
        this.getTableData = result.data.data;
        this.length = this.getTableData.total;
        this.pageSize = this.getTableData.per_page;
        this.index = this.getTableData.current_page;
        this.dataSource = new MatTableDataSource(this.getTableData.data);
        this.userslist = result.data.users;
        this.isSubscriptionEnd(this.getTableData.data);
      }
    })
  }

  sortColumnData(column_name, order_type) {
    this.params.sort_column = column_name;
    this.params.sort_type = order_type;
    this.getClientsData('');
  }

  exportExcel() {
    let Params = { all_users: 1, limit: this.length, page: 1, type: 'paid', country_id: "all" };
    this.commonservice.getList(Params).subscribe(result => {
      if (result.status === 1) {
        let prepared_data = this.prepareExcelData(result.data.data.data);
        this.excelService.exportAsExcelFile(prepared_data, 'clients');
      }
    })
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer'] = element.name;
      eachRow['Customer Name'] = (element?.client_info?.first_name ? element.client_info.first_name : '') + ' ' + (element?.client_info?.last_name ? element.client_info.last_name : '');
      eachRow['Customer Email'] = (element?.client_info?.email ? element.client_info.email : '');
      eachRow['Subdomain Name'] = element.sub_domain_name;
      eachRow['Region'] = element.region;
      eachRow['Status'] = element.status == 1 ? 'Active' : 'In-Active';
      eachRow['Subscription End Date'] = moment(element.subscr_end_date).format('MM/DD/YYYY');
      eachRow['Sales Rep'] = element.sales_user_id ? this.userName(element.sales_user_id) : '-';
      eachRow['Support Executive'] = element.support_user_id ? this.userName(element.support_user_id) : '-';
      ArrData.push(eachRow);
    });
    console.log('prepared', ArrData);
    return ArrData;
  }
  userName(id) {
    let name;
    this.userslist.forEach(val => {
      if (val.id == id) {
        name = val.first_name;
      }
    });
    return name;
  }
  refresh() {
    let data = [];
    data['title'] = 'Are you sure?';
    data['message'] = 'Do you want to logout all the active customers from Oorwin Portal ?';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data,
      minWidth: '30%',
      minHeight: '40%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = {

        }
        this.clientService.killAllActiveSessions(params).subscribe(res => {
          if (res.status) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);

          }
        })
      }
    });
  }

  // getCpanelUsers() {
  //   let params = {
  //     limit: 'all',
  //     page: 1,
  //     search: ''

  //   }
  //   this.commonservice.getUsersList(params).subscribe(res => {
  //     this.userslist = res.cpanel_users;
  //     console.log(this.userslist);

  //   })
  // }

  edit(element) {
    const dialogRef = this.dialog.open(EditFieldsComponent, {
      minWidth: '40%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.getClientsData('all');
      }
    });
  }
  // updatePayment(element) {
  //   const dialogRef = this.dialog.open(UpdatePaymentDetailsComponent, {
  //     width: '40%',
  //     data: element
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log('The dialog was closed');
  //     if (result) {
  //       this.getClientsData('all');
  //     }
  //   });
  // }
  renewRoute(id) {
    this.commonservice.subscriptionType = 'renew';
    this.router.navigate(['company_details/' + id + '/renew']);
  }
  isSubscriptionEnd(data) {
    var today = new Date().getTime();
    data.forEach(e => {
      // console.log(e.subscr_end_date);
      var enddate = new Date(e.subscr_end_date);
      var sub_end_date = enddate.getTime();
      var Difference_In_Time = sub_end_date - today;
      var diffInDays = (Difference_In_Time / (1000 * 3600 * 24));
      if (diffInDays < 0) {
        this.subscr_end[e.company_id] = true;
      } else {
        this.subscr_end[e.company_id] = false;
      }

    });
    // console.log(this.subscr_end);

  }

  countryChanged() {
    this.params = { all_users: 1, limit: 15, page: 1, type: 'paid', country_id: this.country_id };
    this.getClientsData('');
  }

  showActivities() {
    let activityData = {
      'company_id': 0,
      'module_slug': 'clients',
      'show_company_name': true,
      'module_name': 'Clients'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  setDate(date_type: string) {
    this.dateActive = date_type;
    switch (date_type) {
      case 'this_week':
        this.dateRangeForm?.setValue({
          start_date: this.datePipe.transform(this.weekStart, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(this.weekEnd, 'yyyy-MM-dd')
        });
        break;
      case 'this_month':
        this.dateRangeForm.setValue({
          start_date: this.datePipe.transform(this.monthStart, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(this.monthEnd, 'yyyy-MM-dd')
        });
        break;
      case 'last_month':
        this.dateRangeForm.setValue({
          start_date: this.datePipe.transform(this.lastStart, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(this.lastEnd, 'yyyy-MM-dd')
        });
        break;
      default:
        this.dateRangeForm.setValue({
          start_date: '',
          end_date: ''
        });
        break;
    }
  }

  forceLogout() {
    let data = [];
    data['title'] = 'Are you sure?';
    data['message'] = 'Do you want to logout users ?';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data,
      minWidth: '30%',
      minHeight: '40%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = {};
        this.commonservice.cpanelEndSessions({}).subscribe(res => {
          if (res.status) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
        })
      }
    });
  }

  

viewComment(items)
{
  let record_id = items.id;
  let cnt = items.comments_cnt;
  if(cnt)
  {



    let activityData ={
      'company_id':record_id,
      'show_company_name':true,
      'addon_slug':'client_notes',
      'module_slug':'client_note',
      'module_name':'client_note',
}
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
    

  }

    // alert(record_id);

}

addComment(record_id) {
  
 const data = [];
 data['title'] = 'Comment';
 data['message'] = 'Comment';
 data['successText'] = 'Yes';
 data['closeText'] = 'No';
 const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
   minWidth: '30%',
   minHeight: '40%',
   data: data
 });
 let saveData: any;
 
 dialogRef.afterClosed().subscribe(result => {
   console.log(result);
   if (result.approved) {

     saveData = {
       'id': record_id,
       'status': 'comment',
       'comments' : result.commets
     };
     this.commonservice.saveClientNotes(saveData).subscribe(res => {
       if (res.status == 1) {
         this.commonservice.openSnackBar('primary', res.message);
       } else {
         this.commonservice.openSnackBar('warn', res.message);
       }
       this.getClientsData('all');
      //  this.getSignUpRequestData(this.getTableData.data[0].status);
     });
   } else {

   }
 })
 
}

downloadMSaDoc(id:any, fileName:any ,doc_type:any) {
  this.commonservice.downloadInvoice(id, 0, 'msa_unique_name').subscribe(res => {
    this.downloadFile(res, fileName);
  });
}

showPDFFile(data: any, fileName: string) {
  const blob = new Blob([data], { type: 'application/pdf' });
  const dataURL = URL.createObjectURL(blob);
  window.open(dataURL, '_blank');
}

downloadFile(data: any, fileName: string) {
  const blob = new Blob([data], { type: 'application/octet-stream' });
  const dataURL = window.URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.href = dataURL;
  a.download = fileName;
  a.click();
}

uploadMsaSow(event: any, id:any): void {

  if (event.target.files && event.target.files[0]) {
    var file = event.target.files[0]['name'];
    var FormMsaSow = new FormData();
    var extension = file.split('.').pop().toLowerCase();
    var valid_extensions = [];
    valid_extensions = [
          'pdf'
      ];

  if(!(valid_extensions.indexOf(extension) > -1))
  {
    this.commonservice.openSnackBar('warn', 'Please upload valid file.');
  }
  else{
    FormMsaSow.append('document_file', event.target.files[0]);

  let payLoadData = {};
  payLoadData['company_id'] = id;

  FormMsaSow.append('requestData', JSON.stringify(payLoadData));
  this.commonservice.uploadMsaDocument(FormMsaSow).subscribe(res => {
    if (res.status == 1) {
      this.commonservice.openSnackBar('primary', res.message);
      this.getClientsData('all');
    } else {
      this.commonservice.openSnackBar('warn', res.message);
    }
  })
  }
   
  }

}

viewDemoUrl(items)
{
  let record_id = items.id;
  let cnt = items.demo_record_url_cnt;
  if(cnt)
  {
    let activityData ={
          'company_id':record_id,
          'show_company_name':true,
          'addon_slug':'demo_record_url',
          'module_slug':'demo_record_url',
          'module_name':'demo_record_url',
    }

    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
    

  }

    // alert(record_id);

}

addDemoUrl(record_id) {
  
 const data = [];
 data['title'] = 'Demo Recording URL';
 // 
 data['message'] = 'Demo Recording URL';
 data['successText'] = 'Yes';
 data['closeText'] = 'No';
 const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
   minWidth: '30%',
   minHeight: '40%',
   data: data
 });
 let saveData: any;
 
 dialogRef.afterClosed().subscribe(result => {
   console.log(result);
   if (result.approved) {

     saveData = {
       'id': record_id,
       'status': 'demo_record_url',
       'url' : result.commets
     };
     this.commonservice.saveClientNotes(saveData).subscribe(res => {
       if (res.status == 1) {
         this.commonservice.openSnackBar('primary', res.message);
       } else {
         this.commonservice.openSnackBar('warn', res.message);
       }
       this.getClientsData('all');
      //  this.getSignUpRequestData(this.getTableData.data[0].status);
     });
   } else {

   }
 })
 
}

}
