<div class="p-12">
    <form [formGroup]="subscriptionForm" *ngIf="subscriptionForm">
        <ng-container *ngIf="!payment">

            <div fxLayout="row wrap" fxLayoutAlign="space-between center" *ngIf="subscriptionApps">
                <div fxLayoutAlign="space-between center" fxLayout="row" *ngIf="subscription_plan == 'limited'">
                    <!-- (change)="checkboxOnChange('hire')" -->
                    <mat-checkbox color="primary" formControlName='hirecheck'
                        [disabled]="type == 'renew' && (subscriptionApps.hire == 1)"
                        class="mr-20">HIRE</mat-checkbox>
                    <mat-checkbox color="primary" formControlName='hrcheck'
                        [disabled]="type == 'renew' && (subscriptionApps.hr == 1)"
                        class="mr-20">HR</mat-checkbox>
                    <mat-checkbox color="primary" formControlName='crmcheck'
                        [disabled]="type == 'renew' && (subscriptionApps.crm == 1)">
                        CRM</mat-checkbox>
                </div>
                <div fxLayoutAlign="space-between center" fxLayout="row" *ngIf="subscription_plan != 'limited'">
                    <mat-checkbox color="primary" class="mr-20" checked="checked" disabled>HIRE</mat-checkbox>
                    <mat-checkbox color="primary" class="mr-20" checked="checked" disabled>HR</mat-checkbox>
                    <mat-checkbox color="primary" checked="checked" disabled>CRM</mat-checkbox>
                </div>
                <div fxFlex fxLayout="row wrap" fxLayoutAlign="end center">
                    <mat-form-field appearance="outline" class="w-252 mr-8">
                        <mat-select formControlName="subscription_plan" (selectionChange)='planChange()'>
                            <ng-container *ngFor="let plan of plans">
                                <!-- isUnlmtd(plan.value)  -->
                                <mat-option [value]="plan.value"
                                    *ngIf="addonsByCycle[subscriptionForm.controls['subscription_cycle'].value][plan.value]">
                                    {{plan.viewValue}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="w-252">
                        <mat-select formControlName="subscription_cycle" (selectionChange)='cycleChange()'>
                            <ng-container *ngFor="let cycle of subCycle">
                                <mat-option [value]="cycle.value"
                                    *ngIf="addonsByPlan[subscriptionForm.controls['subscription_plan'].value][cycle.value]">
                                    {{cycle.viewValue}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div>
                <table *ngIf="addonPrices">
                    <thead>
                        <tr>
                            <th>Subscription Item</th>
                            <th>Per Pack</th>
                            <th>Qty</th>
                            <th>Credits</th>
                            <th>Unit Price ({{currency_symbol}})</th>
                            <th class="text-right pr-16">Price ({{currency_symbol}})</th>
                        </tr>
                    </thead>
                    <tbody>

                        <!-- unlimited including/excluding addons -->
                        <tr *ngIf="subscription_plan != 'limited'">
                            <td class="py-16">
                                {{subscription_plan == 'unlimited_excl_addons' ? 'Unlimited (Excluding Addons)' : 'Unlimited (Including Addons)'}}
                            </td>
                            <td>
                                1
                            </td>
                            <td>
                                Unlimited
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                {{addonPrices[subscription_cycle][subscription_plan].cost}}
                            </td>
                            <td class="text-right pr-16">
                                {{hirecost  | number : '1.2-2'}}
                            </td>
                        </tr>
                        <!-- <tr  *ngIf="extra_hire_purchased > 0">
                            <td colspan="6" class="no-border"> HIRE USER LICENSES</td>
                        </tr> -->

                        <!-- HIRE USER LICENSES -->
                        <tr *ngIf="subscriptionForm.controls['hirecheck'].value && subscription_plan == 'limited'">
                            <td>
                                <div> HIRE USER LICENSES</div>
                                <div *ngIf="extra_hire_purchased > 0 && subscriptionForm.controls['opt_hire_unlimited'].value"
                                    class="pl-20 pt-16">Unlimited</div>
                            </td>
                            <td>1</td>
                            <td>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                    <div>
                                        <div class="w-200">
                                            <mat-form-field appearance="outline" class="w-200"
                                                *ngIf="!is_hire_disabled">
                                                <input numeric type="number" matInput formControlName="hire_qty">
                                            </mat-form-field>
                                        </div>

                                        <ng-container *ngIf="subscriptionForm.controls['hire_qty'].errors">
                                            <mat-error *ngIf="subscriptionForm.controls['hire_qty'].errors.required">
                                                Quantity is required
                                            </mat-error>
                                            <mat-error *ngIf="subscriptionForm.controls['hire_qty'].errors.min">
                                                Min Users Count should be
                                                {{subscriptionForm.controls['hire_qty'].errors.min.min}}
                                            </mat-error>
                                        </ng-container>
                                    </div>
                                    <div class="ml-4 mt-8"
                                        *ngIf="!isEmpty(addonPrices[subscription_cycle].unlimited_options.hire_users)">
                                        <mat-checkbox color="primary" formControlName='opt_hire_unlimited'
                                            (change)="optUnlimited('hire_users')">Opt for Unlimited
                                        </mat-checkbox>
                                    </div>
                                </div>

                            </td>
                            <td>
                                <ng-container *ngIf="!subscriptionForm.controls['opt_hire_unlimited'].value">
                                    {{subscriptionForm.value['hire_qty']}}</ng-container>
                                <ng-container *ngIf="subscriptionForm.controls['opt_hire_unlimited'].value">Unlimited
                                </ng-container>

                            </td>
                            <td>
                                {{hire_users.cost}}
                            </td>
                            <td class="text-right pr-16">
                                {{hirecost  | number : '1.2-2'}}
                            </td>
                        </tr>


                        <!-- extra hire users -->
                        <tr
                            *ngIf="extra_hire_purchased > 0 && (subscriptionForm.controls['opt_hire_unlimited'].value || subscription_plan != 'limited' )">
                            <td>
                                <div [ngClass]="{'pl-20': subscription_plan == 'limited'}">HIRE Extra licenses</div>
                            </td>
                            <td>1</td>
                            <td>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                    <div>
                                        <div class="w-200">
                                            <mat-form-field appearance="outline" class="w-200">
                                                <input numeric type="number" matInput formControlName="extra_hire_qty">
                                            </mat-form-field>
                                        </div>

                                        <ng-container *ngIf="subscriptionForm.controls['extra_hire_qty'].errors">
                                            <mat-error
                                                *ngIf="subscriptionForm.controls['extra_hire_qty'].errors.required">
                                                Quantity is required
                                            </mat-error>
                                            <mat-error *ngIf="subscriptionForm.controls['extra_hire_qty'].errors.min">
                                                Min Users Count should be
                                                {{subscriptionForm.controls['extra_hire_qty'].errors.min.min}}
                                            </mat-error>
                                        </ng-container>
                                    </div>
                                </div>

                            </td>
                            <td>
                                <ng-container>{{subscriptionForm.value['extra_hire_qty']}}</ng-container>

                            </td>
                            <td>
                                {{extra_hire_users.cost}}
                            </td>
                            <td class="text-right pr-16">
                                {{extrahirecost  | number : '1.2-2'}}
                            </td>
                        </tr>
                        <!-- HR USER LICENSES -->
                        <tr *ngIf="subscriptionForm.controls['hrcheck'].value  && subscription_plan == 'limited'">
                            <td>HR USER LICENSES
                                <div *ngIf="extra_hr_purchased > 0 && subscriptionForm.controls['opt_hr_unlimited'].value"
                                    class="pl-20 pt-16">Unlimited</div>
                            </td>
                            <td>1</td>
                            <td>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                    <div>
                                        <div class="w-200">
                                            <mat-form-field appearance="outline" class="w-200" *ngIf="!is_hr_disabled">
                                                <input numeric type="number" matInput formControlName="hr_qty">
                                            </mat-form-field>
                                        </div>

                                        <ng-container *ngIf="subscriptionForm.controls['hr_qty'].errors">
                                            <mat-error *ngIf="subscriptionForm.controls['hr_qty'].errors.required">
                                                Quantity is required
                                            </mat-error>
                                            <mat-error *ngIf="subscriptionForm.controls['hr_qty'].errors.min">
                                                Min Users count should be
                                                {{subscriptionForm.controls['hr_qty'].errors.min.min}}
                                            </mat-error>

                                        </ng-container>
                                    </div>
                                    <div class="ml-4 mt-8"
                                        *ngIf="!isEmpty(addonPrices[subscription_cycle].unlimited_options.hr_users)">
                                        <mat-checkbox color="primary" formControlName='opt_hr_unlimited'
                                            (change)="optUnlimited('hr_users')">Opt for
                                            Unlimited</mat-checkbox>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <ng-container *ngIf="!subscriptionForm.controls['opt_hr_unlimited'].value">
                                    {{subscriptionForm.value['hr_qty']}}
                                </ng-container>
                                <ng-container *ngIf="subscriptionForm.controls['opt_hr_unlimited'].value">
                                    Unlimited
                                </ng-container>
                            </td>
                            <td>{{hr_users.cost}}</td>
                            <td class="text-right pr-16">
                                {{hrcost  | number : '1.2-2'}}
                            </td>
                        </tr>


                        <!-- extra hr users -->
                        <tr
                            *ngIf="extra_hr_purchased > 0 && (subscriptionForm.controls['opt_hr_unlimited'].value || subscription_plan != 'limited')">
                            <td>
                                <div [ngClass]="{'pl-20': subscription_plan == 'limited'}">HR Extra licenses</div>
                            </td>
                            <td>1</td>
                            <td>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                    <div>
                                        <div class="w-200">
                                            <mat-form-field appearance="outline" class="w-200">
                                                <input numeric type="number" matInput formControlName="extra_hr_qty">
                                            </mat-form-field>
                                        </div>

                                        <ng-container *ngIf="subscriptionForm.controls['extra_hr_qty'].errors">
                                            <mat-error
                                                *ngIf="subscriptionForm.controls['extra_hr_qty'].errors.required">
                                                Quantity is required
                                            </mat-error>
                                            <mat-error *ngIf="subscriptionForm.controls['extra_hr_qty'].errors.min">
                                                Min Users Count should be
                                                {{subscriptionForm.controls['extra_hr_qty'].errors.min.min}}
                                            </mat-error>
                                        </ng-container>
                                    </div>
                                </div>

                            </td>
                            <td>
                                <ng-container>{{subscriptionForm.value['extra_hr_qty']}}</ng-container>

                            </td>
                            <td>
                                {{extra_hr_users.cost}}
                            </td>
                            <td class="text-right pr-16">
                                {{extrahrcost  | number : '1.2-2'}}
                            </td>
                        </tr>


                        <!-- CRM USER LICENSES -->
                        <tr *ngIf="subscriptionForm.controls['crmcheck'].value  && subscription_plan == 'limited'">
                            <td>CRM USER LICENSES
                                <div *ngIf="extra_crm_purchased > 0 && subscriptionForm.controls['opt_crm_unlimited'].value"
                                    class="pl-20 pt-8">Unlimited</div>
                            </td>
                            <td>1</td>
                            <td>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                    <div>
                                        <div class="w-200">
                                            <mat-form-field appearance="outline" class="w-200" *ngIf="!is_crm_disabled">
                                                <input numeric type="number" matInput formControlName="crm_qty">
                                            </mat-form-field>
                                        </div>

                                        <ng-container *ngIf="subscriptionForm.controls['crm_qty'].errors">
                                            <mat-error *ngIf="subscriptionForm.controls['crm_qty'].errors.required">
                                                Quantity is required
                                            </mat-error>
                                            <mat-error *ngIf="subscriptionForm.controls['crm_qty'].errors.min">
                                                Min Users Count should be
                                                {{subscriptionForm.controls['crm_qty'].errors.min.min}}
                                            </mat-error>

                                        </ng-container>
                                    </div>
                                    <div class="ml-4 mt-8"
                                        *ngIf="!isEmpty(addonPrices[subscription_cycle].unlimited_options.crm_users)">
                                        <mat-checkbox color="primary" formControlName='opt_crm_unlimited'
                                            (change)="optUnlimited('crm_users')">Opt for
                                            Unlimited</mat-checkbox>
                                    </div>
                                </div>

                            </td>
                            <td>
                                <ng-container *ngIf="!subscriptionForm.controls['opt_crm_unlimited'].value">
                                    {{subscriptionForm.value['crm_qty']}}
                                </ng-container>
                                <ng-container *ngIf="subscriptionForm.controls['opt_crm_unlimited'].value">
                                    Unlimited
                                </ng-container>
                            </td>
                            <td>{{crm_users.cost}}</td>
                            <td class="text-right pr-16">
                                {{crmcost | number : '1.2-2'}}
                            </td>
                        </tr>


                        <!-- extra crm users -->
                        <tr
                            *ngIf="extra_crm_purchased > 0 && (subscriptionForm.controls['opt_crm_unlimited'].value  || subscription_plan != 'limited')">
                            <td>
                                <div [ngClass]="{'pl-20': subscription_plan == 'limited'}">CRM Extra licenses</div>
                            </td>
                            <td>1</td>
                            <td>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                    <div>
                                        <div class="w-200">
                                            <mat-form-field appearance="outline" class="w-200">
                                                <input numeric type="number" matInput formControlName="extra_crm_qty">
                                            </mat-form-field>
                                        </div>

                                        <ng-container *ngIf="subscriptionForm.controls['extra_crm_qty'].errors">
                                            <mat-error
                                                *ngIf="subscriptionForm.controls['extra_crm_qty'].errors.required">
                                                Quantity is required
                                            </mat-error>
                                            <mat-error *ngIf="subscriptionForm.controls['extra_crm_qty'].errors.min">
                                                Min Users Count should be
                                                {{subscriptionForm.controls['extra_crm_qty'].errors.min.min}}
                                            </mat-error>
                                        </ng-container>
                                    </div>
                                </div>

                            </td>
                            <td>
                                <ng-container>{{subscriptionForm.value['extra_crm_qty']}}</ng-container>

                            </td>
                            <td>
                                {{extra_crm_users.cost}}
                            </td>
                            <td class="text-right pr-16">
                                {{extracrmcost  | number : '1.2-2'}}
                            </td>
                        </tr>
                        <!-- Mass Mail -->
                        <tr *ngIf="subscription_plan != 'unlimited_inc_addons'">
                            <td>Mass Mail</td>
                            <td>
                                <div>
                                    <mat-form-field appearance="outline" class="w-200" *ngIf="!is_mass_disabled">
                                        <mat-select formControlName="mass_slab_index" [disabled]="is_mass_disabled">
                                            <mat-option value=''>Select</mat-option>
                                            <mat-option *ngFor="let item of mass_mails_credits | keyvalue:originalOrder"
                                                [value]="item.key">
                                                {{item.value.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-200" *ngIf="is_mass_disabled">
                                        <mat-select [disabled]="is_mass_disabled" [(value)]="selectedul">
                                            <mat-option value='unlimited' selected>Unlimited</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="subscriptionForm.controls['mass_slab_index'].value || subscriptionForm.controls['opt_massmails_unlimited'].value " fxLayout="row wrap"
                                    fxLayoutAlign="start center">
                                    <mat-checkbox color="primary" formControlName="massmail_is_subscribed"
                                        class="font-size-12 text-bold">Auto Renew</mat-checkbox>
                                    <mat-icon class="s-14 accent-fg ml-4"
                                        matTooltip="By selecting this checkbox the mass mails credits will be auto renewed once the mass mails credits's expiry date is reached.">
                                        info</mat-icon>
                                </div>
                            </td>
                            <td>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                    <div>
                                        <div class="w-200">
                                            <mat-form-field appearance="outline" class="w-200"
                                                *ngIf="!is_mass_disabled">
                                                <input numeric type="number" matInput formControlName="mass_qty">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="ml-4 mt-8"
                                        *ngIf="!isEmpty(addonPrices[subscription_cycle].unlimited_options.mass_mails_credits)">
                                        <mat-checkbox color="primary" formControlName='opt_massmails_unlimited'
                                            (change)="optUnlimited('mass_mails_credits')">Opt for
                                            Unlimited</mat-checkbox>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span *ngIf="!subscriptionForm.controls['opt_massmails_unlimited'].value">
                                    {{subscriptionForm.value['mass_slab_index'] * subscriptionForm.value['mass_qty']}}
                                </span>
                                <span *ngIf="subscriptionForm.value['opt_massmails_unlimited']">
                                    Unlimited
                                </span>
                            </td>
                            <td>
                                <span
                                    *ngIf="!subscriptionForm.value['opt_massmails_unlimited'] && subscriptionForm.value['mass_slab_index']">
                                    {{mass_mails_credits[subscriptionForm.value['mass_slab_index']].cost}}
                                </span>
                                <span *ngIf="subscriptionForm.value['opt_massmails_unlimited']">
                                    {{addonPrices[subscription_cycle].unlimited_options.mass_mails_credits.cost}}
                                </span>
                                <span
                                    *ngIf="!subscriptionForm.value['opt_massmails_unlimited'] && subscriptionForm.value['mass_slab_index'] == ''">0</span>

                            </td>
                            <td class="pr-16">
                                <div fxLayout="row wrap" fxLayoutAlign="end center">
                                    <span>{{massmailcost | number : '1.2-2'}} </span>
                                    <mat-icon class="s-18 warn-fg cursor-pointer ml-4" (click)="massClear();">cancel
                                    </mat-icon>
                                </div>
                            </td>
                        </tr>

                        <!-- SMS Text Credits -->
                        <tr *ngIf="subscription_plan != 'unlimited_inc_addons' && getAddonAccess('sms_credits')">
                            <td>SMS Text</td>
                            <td>
                                <div>
                                    <mat-form-field appearance="outline" class="w-200" *ngIf="!is_sms_credits_disabled">
                                        <mat-select formControlName="sms_credits_slab_index" [disabled]="is_sms_credits_disabled">
                                            <mat-option value=''>Select</mat-option>
                                            <mat-option *ngFor="let item of sms_credits | keyvalue:originalOrder"
                                                [value]="item.key">
                                                {{item.value.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-200" *ngIf="is_sms_credits_disabled">
                                        <mat-select [disabled]="is_sms_credits_disabled" [(value)]="selectedul">
                                            <mat-option value='unlimited' selected>Unlimited</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="subscriptionForm.controls['sms_credits_slab_index'].value || subscriptionForm.controls['opt_sms_credits_unlimited'].value " fxLayout="row wrap"
                                    fxLayoutAlign="start center">
                                    <mat-checkbox color="primary" formControlName="sms_credits_is_subscribed"
                                        class="font-size-12 text-bold">Auto Renew</mat-checkbox>
                                    <mat-icon class="s-14 accent-fg ml-4"
                                        matTooltip="By selecting this checkbox the SMS text credits will be auto renewed once the SMS text credits's expiry date is reached.">
                                        info</mat-icon>
                                </div>
                            </td>
                            <td>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                    <div>
                                        <div class="w-200">
                                            <mat-form-field appearance="outline" class="w-200"
                                                *ngIf="!is_sms_credits_disabled">
                                                <input numeric type="number" matInput formControlName="sms_credits_qty">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="ml-4 mt-8"
                                        *ngIf="!isEmpty(addonPrices[subscription_cycle].unlimited_options.sms_credits)">
                                        <mat-checkbox color="primary" formControlName='opt_sms_credits_unlimited'
                                            (change)="optUnlimited('sms_credits')">Opt for
                                            Unlimited</mat-checkbox>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span *ngIf="!subscriptionForm.controls['opt_sms_credits_unlimited'].value">
                                    {{subscriptionForm.value['sms_credits_slab_index'] * subscriptionForm.value['sms_credits_qty']}}
                                </span>
                                <span *ngIf="subscriptionForm.value['opt_sms_credits_unlimited']">
                                    Unlimited
                                </span>
                            </td>
                            <td>
                                <span
                                    *ngIf="!subscriptionForm.value['opt_sms_credits_unlimited'] && subscriptionForm.value['sms_credits_slab_index']">
                                    {{sms_credits[subscriptionForm.value['sms_credits_slab_index']].cost}}
                                </span>
                                <span *ngIf="subscriptionForm.value['opt_sms_credits_unlimited']">
                                    {{addonPrices[subscription_cycle].unlimited_options.sms_credits.cost}}
                                </span>
                                <span
                                    *ngIf="!subscriptionForm.value['opt_sms_credits_unlimited'] && subscriptionForm.value['sms_credits_slab_index'] == ''">0</span>

                            </td>
                            <td class="pr-16">
                                <div fxLayout="row wrap" fxLayoutAlign="end center">
                                    <span>{{sms_credits_cost | number : '1.2-2'}} </span>
                                    <mat-icon class="s-18 warn-fg cursor-pointer ml-4" (click)="smsCreditsClear();">cancel
                                    </mat-icon>
                                </div>
                            </td>
                        </tr>

                        <!-- Contact Search -->
                        <tr *ngIf="subscription_plan != 'unlimited_inc_addons'">
                            <td> Contact Search (Accounts) </td>
                            <td>
                                <div>
                                    <mat-form-field appearance="outline" class="w-200" *ngIf="!is_contact_disabled">
                                        <mat-select formControlName="contact_slab_index">
                                            <mat-option value=''>Select</mat-option>
                                            <mat-option
                                                *ngFor="let item of contacts_search_credits | keyvalue:originalOrder"
                                                [value]="item.key">
                                                {{item.value.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-200" *ngIf="is_contact_disabled">
                                        <mat-select [disabled]="is_contact_disabled" [(value)]="selectedul">
                                            <mat-option value='unlimited' selected>Unlimited</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="subscriptionForm.value['contact_slab_index']  || subscriptionForm.controls['opt_contact_unlimited'].value" fxLayout="row wrap"
                                    fxLayoutAlign="start center">
                                    <mat-checkbox color="primary" formControlName="contact_is_subscribed"
                                        class="font-size-12 text-bold">Auto Renew</mat-checkbox>
                                    <mat-icon class="s-14 accent-fg ml-4"
                                        matTooltip="By selecting this checkbox the contact search credits will be auto renewed once the contact search credits's expiry date is reached.">
                                        info</mat-icon>
                                </div>
                            </td>
                            <td>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                    <div>
                                        <div class="w-200">
                                            <mat-form-field appearance="outline" class="w-200"
                                                *ngIf="!is_contact_disabled">
                                                <input numeric type="number" matInput formControlName="contact_qty">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="ml-4 mt-8"
                                        *ngIf="!isEmpty(addonPrices[subscription_cycle].unlimited_options.contacts_search_credits)">
                                        <mat-checkbox color="primary" formControlName='opt_contact_unlimited'
                                            (change)="optUnlimited('contacts_search_credits')">Opt for
                                            Unlimited</mat-checkbox>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span *ngIf="!subscriptionForm.value['opt_contact_unlimited']">
                                    {{subscriptionForm.value['contact_slab_index'] * subscriptionForm.value['contact_qty']}}
                                </span>
                                <span *ngIf="subscriptionForm.value['opt_contact_unlimited']">
                                    Unlimited
                                </span>
                            </td>
                            <td>
                                <span
                                    *ngIf="!subscriptionForm.value['opt_contact_unlimited'] && subscriptionForm.value['contact_slab_index']">
                                    {{contacts_search_credits[subscriptionForm.value['contact_slab_index']].cost}}

                                </span>
                                <span *ngIf="subscriptionForm.value['opt_contact_unlimited']">
                                    {{addonPrices[subscription_cycle].unlimited_options.contacts_search_credits.cost}}
                                </span>
                                <span
                                    *ngIf="!subscriptionForm.value['opt_contact_unlimited'] && subscriptionForm.value['contact_slab_index'] == ''">0</span>
                            </td>
                            <td class="pr-16">
                                <div fxLayout="row wrap" fxLayoutAlign="end center">
                                    {{contactcost | number : '1.2-2'}}
                                    <mat-icon class="s-18 warn-fg cursor-pointer ml-4" (click)="contactClear();">cancel
                                    </mat-icon>
                                </div>
                            </td>
                        </tr>

                        <!-- Linkedin -->
                        <tr *ngIf="subscription_plan != 'unlimited_inc_addons'">
                            <td> Contact Search (Linkedin) </td>
                            <td>
                                <div>
                                    <mat-form-field appearance="outline" class="w-200" *ngIf="!is_linkedin_credits_disabled">
                                        <mat-select formControlName="linkedin_credits_slab_index">
                                            <mat-option value=''>Select</mat-option>
                                            <mat-option
                                                *ngFor="let item of linkedin_credits | keyvalue:originalOrder"
                                                [value]="item.key">
                                                {{item.value.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-200" *ngIf="is_linkedin_credits_disabled">
                                        <mat-select [disabled]="is_linkedin_credits_disabled" [(value)]="selectedul">
                                            <mat-option value='unlimited' selected>Unlimited</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="subscriptionForm.value['linkedin_credits_slab_index']  || subscriptionForm.controls['opt_linkedin_credits_unlimited'].value" fxLayout="row wrap"
                                    fxLayoutAlign="start center">
                                    <mat-checkbox color="primary" formControlName="linkedin_credits_is_subscribed"
                                        class="font-size-12 text-bold">Auto Renew</mat-checkbox>
                                    <mat-icon class="s-14 accent-fg ml-4"
                                        matTooltip="By selecting this checkbox the linkedin credits will be auto renewed once the linkedin credits's expiry date is reached.">
                                        info</mat-icon>
                                </div>
                            </td>
                            <td>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                    <div>
                                        <div class="w-200">
                                            <mat-form-field appearance="outline" class="w-200"
                                                *ngIf="!is_linkedin_credits_disabled">
                                                <input numeric type="number" matInput formControlName="linkedin_credits_qty">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="ml-4 mt-8"
                                        *ngIf="!isEmpty(addonPrices[subscription_cycle].unlimited_options.linkedin_credits)">
                                        <mat-checkbox color="primary" formControlName='opt_linkedin_credits_unlimited'
                                            (change)="optUnlimited('linkedin_credits')">Opt for
                                            Unlimited</mat-checkbox>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span *ngIf="!subscriptionForm.value['opt_linkedin_credits_unlimited']">
                                    {{subscriptionForm.value['linkedin_credits_slab_index'] * subscriptionForm.value['linkedin_credits_qty']}}
                                </span>
                                <span *ngIf="subscriptionForm.value['opt_linkedin_credits_unlimited']">
                                    Unlimited
                                </span>
                            </td>
                            <td>
                                <span
                                    *ngIf="!subscriptionForm.value['opt_linkedin_credits_unlimited'] && subscriptionForm.value['linkedin_credits_slab_index']">
                                    {{linkedin_credits[subscriptionForm.value['linkedin_credits_slab_index']].cost}}

                                </span>
                                <span *ngIf="subscriptionForm.value['opt_linkedin_credits_unlimited']">
                                    {{addonPrices[subscription_cycle].unlimited_options.linkedin_credits.cost}}
                                </span>
                                <span
                                    *ngIf="!subscriptionForm.value['opt_linkedin_credits_unlimited'] && subscriptionForm.value['linkedin_credits_slab_index'] == ''">0</span>
                            </td>
                            <td class="pr-16">
                                <div fxLayout="row wrap" fxLayoutAlign="end center">
                                    {{linkedin_credits_cost | number : '1.2-2'}}
                                    <mat-icon class="s-18 warn-fg cursor-pointer ml-4" (click)="linkedinCreditsClear();">cancel
                                    </mat-icon>
                                </div>
                            </td>
                        </tr>

                        <!-- I-9 Forms -->
                        <tr *ngIf="subscriptionForm.controls['hrcheck'].value && subscription_plan != 'unlimited_inc_addons' && getAddonAccess('i9')">
                            <td>I-9 Forms</td>
                            <td>
                                <div>
                                    <mat-form-field appearance="outline" class="w-200" *ngIf="!is_i9_forms_disabled">
                                        <mat-select formControlName="i9_forms_slab_index" [disabled]="is_i9_forms_disabled">
                                            <mat-option value=''>Select</mat-option>
                                            <mat-option *ngFor="let item of i9_forms | keyvalue:originalOrder"
                                                [value]="item.key">
                                                {{item.value.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline" class="w-200" *ngIf="is_i9_forms_disabled">
                                        <mat-select [disabled]="is_i9_forms_disabled" [(value)]="selectedul">
                                            <mat-option value='unlimited' selected>Unlimited</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="subscriptionForm.controls['i9_forms_slab_index'].value || subscriptionForm.controls['opt_i9_forms_unlimited'].value " fxLayout="row wrap"
                                    fxLayoutAlign="start center">
                                    <mat-checkbox color="primary" formControlName="i9_forms_is_subscribed"
                                        class="font-size-12 text-bold">Auto Renew</mat-checkbox>
                                    <mat-icon class="s-14 accent-fg ml-4"
                                        matTooltip="By selecting this checkbox the I-9 Forms credits will be auto renewed once the I-9 Forms credits's expiry date is reached.">
                                        info</mat-icon>
                                </div>
                            </td>
                            <td>
                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                    <div>
                                        <div class="w-200">
                                            <mat-form-field appearance="outline" class="w-200"
                                                *ngIf="!is_i9_forms_disabled">
                                                <input numeric type="number" matInput formControlName="i9_forms_qty">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="ml-4 mt-8"
                                        *ngIf="!isEmpty(addonPrices[subscription_cycle].unlimited_options.i9_forms)">
                                        <mat-checkbox color="primary" formControlName='opt_i9_forms_unlimited'
                                            (change)="optUnlimited('i9_forms')">Opt for
                                            Unlimited</mat-checkbox>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span *ngIf="!subscriptionForm.controls['opt_i9_forms_unlimited'].value">
                                    {{subscriptionForm.value['i9_forms_slab_index'] * subscriptionForm.value['i9_forms_qty']}}
                                </span>
                                <span *ngIf="subscriptionForm.value['opt_i9_forms_unlimited']">
                                    Unlimited
                                </span>
                            </td>
                            <td>
                                <span
                                    *ngIf="!subscriptionForm.value['opt_i9_forms_unlimited'] && subscriptionForm.value['i9_forms_slab_index']">
                                    {{i9_forms[subscriptionForm.value['i9_forms_slab_index']].cost}}
                                </span>
                                <span *ngIf="subscriptionForm.value['opt_i9_forms_unlimited']">
                                    {{addonPrices[subscription_cycle].unlimited_options.i9_forms.cost}}
                                </span>
                                <span
                                    *ngIf="!subscriptionForm.value['opt_i9_forms_unlimited'] && subscriptionForm.value['i9_forms_slab_index'] == ''">0</span>

                            </td>
                            <td class="pr-16">
                                <div fxLayout="row wrap" fxLayoutAlign="end center">
                                    <span>{{i9_forms_cost | number : '1.2-2'}} </span>
                                    <mat-icon class="s-18 warn-fg cursor-pointer ml-4" (click)="i9_formsClear();">cancel
                                    </mat-icon>
                                </div>
                            </td>
                        </tr>

                         <!-- Master Addons -->
                         <ng-container *ngIf="subscription_plan != 'unlimited_inc_addons' && submasterAddons">
                            <tr *ngFor="let addon of submasterAddons | keyvalue">
                                <ng-container *ngIf="(subscriptionForm.controls['hirecheck'].value ? hire_addons.includes(addon.key) : false) || (subscriptionForm.controls['hrcheck'].value ? hr_addons.includes(addon.key) : false) || ((subscriptionForm.controls['hrcheck'].value || subscriptionForm.controls['hirecheck'].value) ? hire_hr_addons.includes(addon.key) : false)">
                                    <td> <mat-checkbox color="primary" class="font-size-12 text-bold" [formControlName] = "addon.key" ></mat-checkbox> <span class="pl-12"> {{addon['value']['name']}} </span></td>
                                    <td>
                                        <div fxLayout="row wrap" fxLayoutAlign="start center">
                                            <div class="pr-12">
                                                1
                                            </div>
                                            <mat-checkbox color="primary" class="font-size-12 text-bold" [formControlName] = "addon.key + '_is_subscribe'">Auto Renew</mat-checkbox>
                                            <mat-icon class="s-14 accent-fg ml-4" [matTooltip]="matTooltipContent(addon.key)">info</mat-icon>
                                        </div>
                                    </td>
                                    <td>
                                    -
                                    </td>
                                    <td>
                                        -
                                    </td>
                                    <td>
                                        {{addon['value']['cost']}}
                                    </td>
                                    <td class="pr-16">
                                        <div fxLayout="row wrap" fxLayoutAlign="end center">
                                            {{subscriptionForm.value[addon['key']] ? (addon['value']['cost'] * subscription_cycle | number : '1.2-2') : 0}}
                                            <mat-icon class="s-18 warn-fg cursor-pointer ml-4" (click)="masterAddonClear(addon['key']);">cancel
                                            </mat-icon>
                                        </div>
                                    </td>
                                </ng-container>
                            </tr>
                         </ng-container>

                        <tr *ngIf="branding_addon_data.add_branding_addon">
                            <td>
                                Remove Oorwin Branding <br>
                                ({{branding_addon_data.oorwin_branding[subscription_cycle].name == 'remove_monthly' ? 'Subscription Based' : 'Life Time'}})
                            </td>
                            <td>
                                <mat-radio-group formControlName="branding">
                                    <mat-radio-button value='true' color="primary">Yes</mat-radio-button>
                                    <mat-radio-button value='false' color="primary" class="ml-12">No
                                    </mat-radio-button>
                                </mat-radio-group>
                            </td>
                            <td colspan="2"></td>
                            <td>
                                {{branding_addon_data.oorwin_branding[subscription_cycle].cost}}
                            </td>
                            <td class="text-right pr-16">
                                {{brandingcost | number : '1.2-2'}}
                            </td>
                        </tr>

                        <!-- Total -->
                        <tr class="no-border">
                            <td colspan="4" class="text-right">
                              <h2 class="sub-heading">Total</h2>
                            </td>
                            <td colspan="2" class="text-right pr-16" *ngIf="subscriptionForm.value">
                              <h2 class="text-bold font-size-14">
                                (+) {{total | currency:currency}}
                              </h2>
                            </td>
                        </tr>

                        <tr class="no-border" *ngIf="wallet_amount > 0 && total > 0">
                            <td colspan="4" class="text-right">
                              <h2 class="font-size-12">Available Balance <span>{{wallet_amount | currency:currency}}</span></h2>
                            </td>
                            <td colspan="2" class="text-right pr-16" *ngIf="subscriptionForm.value">
                              <h2 class="text-bold font-size-12">
                                (-) {{usedWalletAmount() | currency:currency}}
                              </h2>
                            </td>
                          </tr>

                        <tr class="no-border" *ngIf="wallet_amount > 0 && subtotal > 0">
                        <td colspan="4" class="text-right">
                            <h2 class="sub-heading">Sub Total</h2>
                        </td>
                        <td colspan="2" class="text-right pr-16" *ngIf="subscriptionForm.value">
                            <h2 class="text-bold font-size-14">
                            {{ total - usedWalletAmount() | currency:currency}}
                            </h2>
                        </td>
                        </tr>
                        <!-- tax -->
                        <tr *ngIf="country_id == 2 && tax > 0" class="no-border">
                            <td colspan="4" class="text-right">
                                <h2 class="font-size-12">GST of {{gstvalue * 100}}% </h2>
                            </td>
                            <td colspan="2" class="text-right pr-16" *ngIf="subscriptionForm.value">
                                <h2 class="text-bold font-size-12">
                                    (+) {{tax | currency:currency}}
                                </h2>
                            </td>
                        </tr>
                        <!-- tax -->
                        <tr *ngIf="country_id == 2 && !isEmpty(gst_number) && tds > 0" class="no-border">
                            <td colspan="4" class="text-right">
                                <h2 class="font-size-12">TDS of {{tdsvalue * 100}} %</h2>
                            </td>
                            <td colspan="2" class="text-right pr-16" *ngIf="subscriptionForm.value">
                                <h2 class="text-bold font-size-14">
                                    (-) {{tds | currency:currency}}
                                </h2>
                            </td>
                        </tr>
                        <!-- subtotal -->
                        <tr>
                            <td colspan="4" class="text-right">
                                <h2 class="text-bold">Grand Total</h2>
                            </td>
                            <td colspan="2" class="text-right pr-16" *ngIf="subscriptionForm.value">
                                <h2 class="text-bold font-size-20">
                                    {{subtotal | currency:currency}}
                                </h2>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <ng-container *ngIf="payment">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill fxLayoutGap="40px" class="mb-12">
                <div fxFlex='330px' class="text-center font-size-32 text-bold">Payment Method</div>
                <div fxFlex class="text-right font-size-32 text-bold">
                    Total Amount: <span class="accent-fg"> {{subtotal  | currency:currency}} </span>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="40px" class="mb-12">
                <div fxFlex='330px'>
                    <div *ngIf="!turnOffPaymentMode">
                        <ng-container *ngFor="let item of paymentmodes">
                            <ng-container *ngIf="(type == 'renew' || (type != 'renew' && item.value != 'card')) && item.isActive == 1">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill
                                    class="pay_modes cursor-pointer" (click)="paymentChange(item)"
                                    [ngClass]="{active: isActive(item), 'disableDiv': item.isDisable}">
                                    <div fxFlex='75px'>
                                        <ng-container *ngIf="item.value == 'bank'">
                                            <mat-icon class="s-40 ml-4 pay_icon">account_balance</mat-icon>
                                        </ng-container>
                                        <ng-container *ngIf="item.slug == 'stripe_balance'">
                                            <mat-icon class="s-40 ml-4 pay_icon">account_balance_wallet</mat-icon>
                                          </ng-container>
                                        <ng-container *ngIf="item.value == 'cheque'">
                                            <mat-icon class="s-40 ml-4 pay_icon">monetization_on</mat-icon>
                                        </ng-container>
                                        <ng-container *ngIf="item.value == 'card'">
                                            <mat-icon class="s-40 ml-4 pay_icon">credit_card</mat-icon>
                                        </ng-container>
                                        <ng-container *ngIf="item.slug == 'invoice_url'">
                                            <mat-icon class="s-40 ml-4 pay_icon">mail</mat-icon>
                                        </ng-container>
                                    </div>
                                    <div fxFlex class="font-size-16 text-bold"> {{item.name}} </div>
                                    <div fxFlexAlign="center" class="select">
                                        <mat-icon class="s-24 primary-fg ml-4">check_circle_outline</mat-icon>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="ml-32 p-8 warn-fg font-size-16 border" *ngIf="turnOffPaymentMode">
                        <ng-container>
                          Please use exact stripe credit balance to processed for payment
                        </ng-container>
                    </div>
                </div>
                <div fxFlex class="pl-32 mt-20">
                    <ng-container *ngIf="showtxn && showTXNID">
                        <div class="text-bold font-size-16">
                            Transaction ID<span class = "red-fg">*</span>
                        </div>
                        <div>
                            <mat-form-field class="w-40-p my-8" appearance="outline">
                                <input type="text" matInput formControlName="txn_id">
                            </mat-form-field>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="showtxn && showTXNID">
                        <div class="text-bold font-size-16 mb-10">
                          Transaction Proof<span class = "red-fg">*</span>
                        </div>
                        <div>
                            <input type="file" accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls" id="file1" formControlName="bank_cheque_attchment"
                            (change)="onSelectFile1($event)">
                            <div class="text-center w-160 mx-auto mb-20" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="url1">
                              <img [src]="url1" style="max-width:100%;height: auto;">
                            </div>
                        </div>
                      </ng-container>

                    <ng-container *ngIf="showtxn">
                        <div class="text-bold font-size-16 mt-20">
                            Billing Details<span class = "red-fg">*</span>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
                            <div fxFlex='30'>
                                <mat-form-field class="w-100-p my-8" appearance="outline">
                                    <mat-label>Street<span class = "red-fg">*</span> </mat-label>
                                    <input type="text" matInput formControlName="billing_street">
                                </mat-form-field>
                            </div>
                            <div fxFlex='30'>
                                <mat-form-field class="w-100-p my-8 ml-12" appearance="outline">
                                    <mat-label>City<span class = "red-fg">*</span></mat-label>
                                    <input type="text" matInput formControlName="billing_city">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
                            <div fxFlex='30'>
                                <mat-form-field class="w-100-p" appearance='outline'>
                                    <mat-label>Country<span class = "red-fg">*</span></mat-label>
                                    <mat-select formControlName="billing_country"
                                        (selectionChange)="changeCountry($event.value)">
                                        <mat-option *ngFor="let item of countries" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex='30'>
                                <mat-form-field class="w-100-p ml-12" appearance='outline'>
                                    <mat-label>State<span class = "red-fg">*</span></mat-label>
                                    <mat-select formControlName="billing_state">
                                        <mat-option *ngFor="let item of states" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
                            <div fxFlex='30'>
                                <mat-form-field class="w-100-p my-8" appearance="outline">
                                    <mat-label>Zip<span class = "red-fg">*</span> </mat-label>
                                    <input type="text" matInput formControlName="billing_zip">
                                </mat-form-field>
                            </div>
                        </div>

                    </ng-container>

                    <!-- <ng-container *ngIf="showcc">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill class="my-12">
                            <div fxFlex='150px' class="text-bold">
                                Credit Cards
                            </div>
                            <div fxFlex>
                                <mat-form-field class="w-50-p" appearance='outline' *ngIf="!isEmpty(cc_details)">
                                    <mat-select formControlName="selected_card" (selectionChange)='selectedCc()'>
                                        <ng-container>
                                            <mat-option [value]='cc.key' *ngFor="let cc of cc_details | keyvalue"
                                                class="text-capitalize">
                                                {{cc.value.cc_type | titlecase}} ending with {{cc.value.cc_number}}
                                                {{cc.key == default_card ? '(Default)' : ''}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="w-40-p my-8" appearance="outline" *ngIf="isEmpty(cc_details)">
                                    <input type="text" matInput disabled value="No card found">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlexFill class="mt-40"
                            *ngIf="selected_ccdetails">
                            <div fxFlex>
                                <div class="font-size-18 my-12 text-bold">Billing Details</div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill class="pl-8 py-20">
                                    <div fxFlex='100px' class="text-bold">Street</div>
                                    <div fxFlex>{{selected_ccdetails.street}} </div>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill class="pl-8 py-20">
                                    <div fxFlex='100px' class="text-bold">City</div>
                                    <div fxFlex>{{selected_ccdetails.city}}</div>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill class="pl-8 py-20">
                                    <div fxFlex='100px' class="text-bold">State</div>
                                    <div fxFlex>{{selected_ccdetails.state_name}}</div>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill class="pl-8 py-20">
                                    <div fxFlex='100px' class="text-bold">Country</div>
                                    <div fxFlex>{{selected_ccdetails.country}}</div>
                                </div>
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill class="pl-8 py-20">
                                    <div fxFlex='100px' class="text-bold">Zip</div>
                                    <div fxFlex>{{selected_ccdetails.zip}}</div>
                                </div>
                            </div>
                            <div fxFlex='430px' class="card-panel">
                                <div class="card card--front">
                                    <div class="card__owner font-size-18 text-bold">{{company_details.display_name}}
                                    </div>
                                    <div class="card__number">xxxx xxxx xxxx {{selected_ccdetails.cc_number}}</div>
                                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="pt-20">
                                        <div class="card__expiry-date">{{selected_ccdetails.city}} </div>
                                        <img class="card__logo" src="assets/images/visa.png"
                                            *ngIf="selected_ccdetails.cc_type == 'visa'" />
                                        <img class="card__logo" src="assets/images/mastercard.png"
                                            *ngIf="selected_ccdetails.cc_type == 'mastercard'" />
                                        <div class="card__logotext"
                                            *ngIf="selected_ccdetails.cc_type != 'mastercard' && selected_ccdetails.cc_type != 'visa'">
                                            {{selected_ccdetails.cc_type}} </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </ng-container> -->
                </div>

            </div>
        </ng-container>
    </form>
</div>

<div class='footer' *ngIf="!turnOffPaymentMode">
    <button mat-button (click)="back()" *ngIf="!payment">Back</button>
    <button mat-button (click)="checkout()" class="primary" *ngIf="!payment && subtotal > 0">Checkout</button>
    <button mat-button (click)="save(true)" class="primary" *ngIf="!payment && subtotal<=0 ">Checkout</button>
    <button mat-button (click)="payment = false" *ngIf="payment">Back</button>
    <button mat-button (click)="save()" class="primary" *ngIf="payment&&payment_mode != 'invoice_url'">Pay</button>
    <button mat-button (click)="save()" class="primary" *ngIf="payment&&payment_mode == 'invoice_url'">{{isAccountBalPayment?'Pay':'Send Invoice Payment Link'}}</button>
    <button mat-button (click)="downloadProforma()" class="primary" *ngIf="payment&&payment_mode == 'invoice_url'">
        Download Proforma</button>
</div>

<div class='footer' *ngIf="turnOffPaymentMode">
  <button mat-button (click)="back()" *ngIf="!payment">Back</button>
  <button mat-button (click)="payment = false;turnOffPaymentMode=false" *ngIf="payment">Back</button>
</div>