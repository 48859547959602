import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { isEmpty } from 'lodash';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-update-companyinfo-dialog',
  templateUrl: './update-companyinfo-dialog.component.html',
  styleUrls: ['./update-companyinfo-dialog.component.scss']
})
export class UpdateCompanyinfoDialogComponent implements OnInit {

  updateInfoForm: FormGroup;
  showOtherText: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UpdateCompanyinfoDialogComponent>
  ) {
    this.updateInfoForm = this.fb.group({
      fieldData: [this.data.inputFieldValue, Validators.required]
    });
  }

  ngOnInit() {
  }

  isEmpty(check) {
    return isEmpty(check);
  }

  closeDialog() {
    let data = {};
    data['approved'] = false;
    this.dialogRef.close(data);
  }

  saveInfo() {
    if (this.updateInfoForm.valid) {
      const params = {
        'company_id': this.data.company_id,
        'inputFieldData': this.updateInfoForm.controls['fieldData'].value,
        'inputFieldName': this.data.inputFieldName
      }
      this.companyDetailService.saveCompanyInfo(params).subscribe(res => {
        if (res.status) {
          this.commonService.openSnackBar('primary', res.message);
          this.dialogRef.close(1);
        } else {
          this.commonService.openSnackBar('warn', res.message);
        }
      })
    }
  }

}
