import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from 'app/services/common.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ConfigurationOptions, ContentOptionsEnum, SortOrderEnum, OutputOptionsEnum } from 'intl-input-phone';
import { isEmpty } from 'lodash';
import { element } from 'protractor';

@Component({
  selector: 'app-add-signup-dialog',
  templateUrl: './add-signup-dialog.component.html',
  styleUrls: ['./add-signup-dialog.component.scss']
})
export class AddSignupDialogComponent implements OnInit {

  filteredStates: any;
  saveData: any = {};
  showLoader: boolean;
  validation_msg: any;
  myBooks = <any>[];
  role_types = [
    { value: 'C-Level', viewValue: 'C-Level' },
    { value: 'EVP/SVP', viewValue: 'EVP/SVP' },
    { value: 'VP/Director', viewValue: 'VP/Director' },
    { value: 'Manager', viewValue: 'Manager' },
    { value: 'Business Partner', viewValue: 'Business Partner' },
    { value: 'Other', viewValue: 'Other' },
  ];
  buying_stages = [
    { value: 'Immediate Purchase', viewValue: 'Immediate Purchase' },
    { value: 'Purchase in 3 months', viewValue: 'Purchase in 3 months' },
    { value: 'Just Exploring', viewValue: 'Just Exploring' },
  ];
  comapany_locations = [
    { country_table_id: 1, country_name: 'USA', ext: '+1', iso: 'US' },
    { country_table_id: 2, country_name: 'India', ext: '+91', iso: 'IN' },
    { country_table_id: 702, country_name: 'Singapore', ext: '+65', iso: 'SG' },
  ];

  app_list = [
    { formCtrlName: 'interest_crm', name: 'Sales CRM' },
    { formCtrlName: 'interest_hire', name: 'Recruitment' },
    { formCtrlName: 'interest_hr', name: 'HR Management' },
  ];

  showPassword: boolean = true;

  configOption3: ConfigurationOptions;
  NumberModel2: string = "+1";
  SelectedCountryISOCode: string = "US";
  IsRequired: boolean = false;
  phoneNumber: any;
  phoneNumberExt: any;
  CountryISOCode: any;
  addSignupRequest: FormGroup;

  constructor(
    private fb: FormBuilder,
    private commonservice: CommonService,
    public dialogRef: MatDialogRef<AddSignupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient

  ) {
    this.addSignupRequest = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      company_name: ['', Validators.required],
      sub_domain_name: ['', Validators.required],
      website: ['', Validators.required],
      country_id: [1, Validators.required],
      interest_crm: [''],
      interest_hire: [''],
      interest_hr: [''],
      contact_number: [''],
    });

    if (!this.data.id) {
      console.log('herere');

      this.addSignupRequest.addControl('password', new FormControl('', Validators.required));
    }

    this.configOption3 = new ConfigurationOptions();
    this.configOption3.SelectorClass = "OptionType3";
    this.configOption3.OptionTextTypes = [];
    this.configOption3.OptionTextTypes.push(ContentOptionsEnum.Flag);
    this.configOption3.OptionTextTypes.push(ContentOptionsEnum.CountryName);
    this.configOption3.OptionTextTypes.push(ContentOptionsEnum.CountryPhoneCode);
    this.configOption3.SortBy = SortOrderEnum.CountryPhoneCode;
    this.configOption3.OutputFormat = OutputOptionsEnum.Number;
  }

  ngOnInit() {
    if (this.data.id) {
      this.IsRequired = true;
      this.showPassword = false;
      this.addSignupRequest.patchValue({
        first_name: this.data.first_name,
        last_name: this.data.last_name,
        email: this.data.email,
        country_id: this.data.country_id,
        company_name: this.data.company_name,
        sub_domain_name: this.data.sub_domain_name,
        website: this.data.website,
      })
      // this.addSignupRequest.patchValue(this.data);
      this.addCountryData(this.data.contact_number);
      let user_contact_number = this.data.contact_number.replace(this.NumberModel2, '');
      this.NumberModel2 = this.NumberModel2 + ' ' + user_contact_number;
      let instrested_in = this.data.interested_app;
      if (this.data.interested_app) {
        let res = instrested_in.split(",");
        res.forEach((v, k) => {
          this.app_list.forEach(ele => {
            if (ele.name == v) {
              this.addSignupRequest.controls[ele.formCtrlName].setValue(true);
            }
          })
        })
      }
    }
  }

  // autoComplete(val) {
  //   if (val != '') {
  //     let param = { query: val };
  //     this.http.get(('https://autocomplete.clearbit.com/v1/companies/suggest'), { params: param }).subscribe(res => {
  //       this.myBooks = res as any[];


  //     })
  //   }
  // }
  // selectedAuto(e: MatAutocompleteSelectedEvent) {
  //   // console.log(e.option.value);
  //   this.myBooks.filter(val => {
  //     if (val.name === e.option.value) {
  //       this.addSignupRequest.patchValue({
  //         sub_domain_name: val.name.split(' ')[0],
  //         website: val.domain
  //       })
  //     }
  //   })
  // }

  addSignup() {

    if (this.addSignupRequest.valid && this.showPassword) {

      if (!this.IsRequired) {
        this.commonservice.openSnackBar('warn', 'Please enter valid contact number');
        return;
      }

      let interested_app = [];

      if (this.addSignupRequest.value.interest_crm)
        interested_app.push('Sales CRM');

      if (this.addSignupRequest.value.interest_hire)
        interested_app.push('Recruitment');

      if (this.addSignupRequest.value.interest_hr)
        interested_app.push('HR Management');

      this.saveData = {
        'first_name': this.addSignupRequest.value.first_name,
        'last_name': this.addSignupRequest.value.last_name,
        'email': this.addSignupRequest.value.email,
        'password': this.addSignupRequest.value.password,
        'password_confirmation': this.addSignupRequest.value.password,
        'country_id': this.addSignupRequest.value.country_id,
        'contact_number_ext': this.addSignupRequest.value.contact_number.CountryModel.CountryPhoneCode,
        'contact_number': this.addSignupRequest.value.contact_number.Number,
        'iso_code': this.addSignupRequest.value.contact_number.CountryModel.ISOCode,
        'company_name': this.addSignupRequest.value.company_name,
        'sub_domain_name': this.addSignupRequest.value.sub_domain_name,
        'website': this.addSignupRequest.value.website,
        'interested_app': interested_app,
      };

      this.showLoader = true;
      this.commonservice.saveSignupRequest(this.saveData).subscribe(res => {
        if (res.success == 1) {
          this.commonservice.openSnackBar('primary', res.message);
          this.dialogRef.close(1);
        } else {
          this.validation_msg = res.message;
          if (typeof res.validations != 'undefined') {
            for (let field_name in res.validations) {
              this.validation_msg += res.validations[field_name];
            }
          }
          this.commonservice.openSnackBar('warn', this.validation_msg);
        }
        this.showLoader = false;
      });

    }
    else if (this.addSignupRequest.valid && !this.showPassword) {

      let interested_app = [];

      if (this.addSignupRequest.value.interest_crm)
        interested_app.push('Sales CRM');

      if (this.addSignupRequest.value.interest_hire)
        interested_app.push('Recruitment');

      if (this.addSignupRequest.value.interest_hr)
        interested_app.push('HR Management');

      // console.log(this.data);
      if (this.data.id) {
        this.saveData['id'] = this.data.id;
      }

      if (this.IsRequired && !this.addSignupRequest.value.contact_number) {
        this.saveData['contact_number'] = this.data.contact_number;
      } else {
        if (!this.IsRequired) {
          this.commonservice.openSnackBar('warn', 'Please enter valid mobile number');
          return;
        }
        this.saveData['contact_number'] = this.addSignupRequest.value.contact_number.Number;
        this.saveData['contact_number_ext'] = this.addSignupRequest.value.contact_number.CountryModel.CountryPhoneCode;
        this.saveData['iso_code'] = this.addSignupRequest.value.contact_number.CountryModel.ISOCode;
      }

      this.saveData['first_name'] = this.addSignupRequest.value.first_name;
      this.saveData['last_name'] = this.addSignupRequest.value.last_name;
      this.saveData['email'] = this.addSignupRequest.value.email;
      this.saveData['country_id'] = this.addSignupRequest.value.country_id;
      this.saveData['company_name'] = this.addSignupRequest.value.company_name;
      this.saveData['sub_domain_name'] = this.addSignupRequest.value.sub_domain_name;
      this.saveData['website'] = this.addSignupRequest.value.website;
      this.saveData['interested_app'] = interested_app;

      this.showLoader = true;
      this.commonservice.saveSignupRequest(this.saveData).subscribe(res => {
        if (res.status == 1) {
          this.commonservice.openSnackBar('primary', res.message);
          this.dialogRef.close(1);
        } else {
          this.validation_msg = res.message;
          if (typeof res.validations != 'undefined') {
            for (let field_name in res.validations) {
              this.validation_msg += res.validations[field_name];
            }
          }
          this.commonservice.openSnackBar('warn', this.validation_msg);
        }
        this.showLoader = false;
      });
    }
  }

  requiredFlagChange(isRequire: boolean) {
    this.IsRequired = isRequire;
  }

  changeCountry(check_id = null) {

    if (!this.showPassword) {
      return;
    }
    let country_id: any;
    if (check_id) {
      country_id = check_id
    } else {
      country_id = this.addSignupRequest.value.country_id;
    }

    if (this.showPassword && this.addSignupRequest.value.contact_number) {
      return;
    }
    this.comapany_locations.forEach(element => {
      if (element.country_table_id == country_id) {
        this.SelectedCountryISOCode = element.iso;
        this.NumberModel2 = element.ext;
      }
    })
  }

  addCountryData(phone_number) {
    this.comapany_locations.forEach(element => {
      if (phone_number.indexOf(element.ext) == 0) {
        this.SelectedCountryISOCode = element.iso;
        this.NumberModel2 = element.ext;
      }
    })
  }

}
