<form [formGroup]="billingDetails" *ngIf="billingDetails">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="px-20 py-8 bb" *ngIf="CompanyDetails">
        <div fxFlex="40" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex>
                <p class="sub-heading"> Stripe Customer Id : &nbsp;
                    <a>{{!isEmpty(CompanyDetails.stripe_customer_id) ? CompanyDetails.stripe_customer_id : '-'}}</a>
                    &nbsp;
                    <button
                        *ngIf="permissions['clients.billing_details.add_account_balance'] && layoutData.addAccountBalance"
                        mat-button class="primary" (click)="addCustomerBalance()" matTooltip="Add Stripe Balance">
                        Add Stripe Balance
                    </button>
                </p>
                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                    <p class="sub-heading"> Stripe Credit Balance : &nbsp; <a>{{getCurrencySymbol()}}
                            {{accountBalance>0? accountBalance : 0 }}</a>
                    </p>
                    <p class="sub-heading"> Wallet Amount : &nbsp; <a>{{getCurrencySymbol()}}
                            {{(isEmpty(CompanyDetails.wallet_amount) ) ? '0.00' : CompanyDetails.wallet_amount }}</a>
                    </p>
                </div>
            </div>
        </div>
        <div fxFlex="56" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div [fxFlex]="subscription_type == 'trial' ? 48 : 24" class="mr-4">
                <mat-form-field appearance='outline' class="w-100-p" matTooltip="Subscription Start Date"
                    matTooltipPosition="above">
                    <mat-label>Subscription Start Date</mat-label>
                    <input type="text" matInput value="{{activeSubscription.start_date | date:'MM/dd/yyyy'}}" readonly
                        style="cursor:no-drop;">
                </mat-form-field>
            </div>
            
            <ng-container *ngIf="subscription_type != 'trial'">
                <div fxFlex="24" class="mr-4">
                    <mat-form-field appearance='outline' class="w-100-p" matTooltip="Subscription End Date"
                        matTooltipPosition="above">
                        <mat-label>Subscription End Date</mat-label>
                        <input type="text" matInput value="{{actual_subscr_end_date | date:'MM/dd/yyyy'}}" readonly
                            style="cursor:no-drop;">
                    </mat-form-field>
                </div>
                <div fxFlex="24" class="mr-4">
                    <mat-form-field appearance='outline' class="w-100-p" matTooltip="End Date with Grace period"
                        matTooltipPosition="above">
                        <mat-label>End Date with Grace period</mat-label>
                        <input matInput [matDatepicker]="picker" (click)="picker.open()" (dateChange)="onDateChange($event)"
                            formControlName="end_date" [disabled]="isLostAccount == 0 ? false : true">
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div fxFlex="24" class="mr-4">
                    <mat-form-field appearance='outline' class="w-100-p" matTooltip="Next Renewal Start Date"
                        matTooltipPosition="above">
                        <mat-label>Next Renewal Start Date</mat-label>
                        <input matInput [matDatepicker]="picker1" (click)="picker1.open()" (dateChange)="onDateChange($event, 'next_renewal_start_date')"
                            formControlName="next_renewal_start_date" [disabled]="isLostAccount == 0 ? false : true">
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>

            </ng-container>
            <ng-container *ngIf="subscription_type == 'trial'">
                <div fxFlex="48" class="mr-4">
                    <mat-form-field appearance='outline' class="w-100-p" matTooltip="Trial End Date"
                        matTooltipPosition="above">
                        <mat-label>Trial End Date</mat-label>
                        <input matInput [matDatepicker]="picker" (click)="picker.open()" (dateChange)="onDateChange($event)"
                            formControlName="end_date" [min]="activeSubscription.start_date">
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </ng-container>
            <!-- <div fxFlex="24">
                <mat-form-field appearance='outline' class="w-100-p">
                    <mat-label>Expiries in</mat-label>
                    <input type="text" matInput value="{{layoutData.remaining_days}}" readonly style="cursor:no-drop;">
                </mat-form-field>
            </div> -->
        </div>


        <div fxFlex="100">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxLayout="row wrap" fxFlex="24" fxLayoutAlign="space-between center" class="pb-8">
                    <label id="example-radio-group-label" class="sub-heading">Discard Reports</label>
                    <div class="px-12">:</div>
                    <mat-radio-group fxFlex aria-labelledby="example-radio-group-label"
                        (change)="reportDiscard($event, 'Discard From Payment Reports', 'discardReports', 'discard_reports')"
                        formControlName="discardReports" [disabled]="isLostAccount == 0 ? false : true">
                        <mat-radio-button value='0' color="primary" class="sub-heading mr-4">No</mat-radio-button>
                        <mat-radio-button value='1' color="primary" class="sub-heading"> Yes</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="row wrap" fxFlex="24" fxLayoutAlign="space-between center" class="pb-8">
                    <label id="example-radio-group-label" class="sub-heading">Disable Mass Mails </label>
                    <div class="px-12">:</div>
                    <mat-radio-group fxFlex aria-labelledby="example-radio-group-label"
                        (change)="reportDiscard($event, 'Disable Mass Mails', 'disableMassMail', 'disable_mass_mails')"
                        formControlName="disableMassMail" [disabled]="isLostAccount == 0 ? false : true">
                        <mat-radio-button value='0' color="primary" class="sub-heading mr-4">No</mat-radio-button>
                        <mat-radio-button value='1' color="primary" class="sub-heading"> Yes</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="row wrap" fxFlex="24" fxLayoutAlign="space-between center" class="pb-8">
                    <label id="example-radio-group-label1" class="sub-heading">Bulk Import</label>
                    <div class="px-12">:</div>
                    <mat-radio-group fxFlex aria-labelledby="example-radio-group-label1"
                        (change)="bulkImportAccess($event)" formControlName="bulkImport"
                        [disabled]="isLostAccount == 0 ? false : true">
                        <mat-radio-button value='0' color="primary" class="sub-heading mr-4">No</mat-radio-button>
                        <mat-radio-button value='1' color="primary" class="sub-heading">Yes</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="row wrap" fxFlex="24" fxLayoutAlign="space-between center" class="pb-8">
                    <label id="example-radio-group-label1" class="sub-heading">Lost Account </label>
                    <div class="px-12">:</div>
                    <mat-radio-group fxFlex aria-labelledby="example-radio-group-label1"
                        (change)="reportPayment($event)" formControlName="accountLost"
                        [disabled]="isLostAccount == 0 ? false : true">
                        <mat-radio-button value='0' color="primary" class="sub-heading mr-4">No</mat-radio-button>
                        <mat-radio-button value='1' color="primary" class="sub-heading">Yes</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="row wrap" fxFlex="24" fxLayoutAlign="space-between center">
                    <label id="example-radio-group-label1" class="sub-heading">Company Type</label>
                    <div class="px-12">:</div>
                    <mat-radio-group fxFlex aria-labelledby="example-radio-group-label1"
                        (change)="setCompanyType($event)" formControlName="companyType"
                        [disabled]="isLostAccount == 0 ? false : true">
                        <mat-radio-button value='recruitment' color="primary" class="sub-heading mr-4">Recruitment
                        </mat-radio-button>
                        <mat-radio-button value='corporate' color="primary" class="sub-heading">Corporate
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="row wrap" fxFlex="24" fxLayoutAlign="space-between center">
                    <label id="example-radio-group-label1" class="sub-heading">Company Auto Renewal</label>
                    <div class="px-12">:</div>
                    <mat-radio-group fxFlex aria-labelledby="example-radio-group-label1"
                        (change)="updateCompanyRenewal($event)" formControlName="auto_renewal">
                        <mat-radio-button value='0' color="primary" class="sub-heading mr-4">No
                        </mat-radio-button>
                        <mat-radio-button value='1' color="primary" class="sub-heading">Yes
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div fxLayout="row wrap" fxFlex="24" fxLayoutAlign="space-between center">
                    
                </div>

                <div fxLayout="row wrap" fxFlex="24" fxLayoutAlign="space-between center">
                    
                </div>
            </div>
        </div>

    </div>
</form>

<div fxLayout="row wrap" fxLayoutAlign="space-between start" class='p-16' *ngIf="CompanyDetails">
    <div fxFlex='72' fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="16px grid">
        <div fxFlex='100'>
            <mat-card fxLayout="row wrap" class="line-height-26 p-12 mat-elevation-z3">
                <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                    <div fxFlex="48" class="text-right sub-heading ">
                        Company Name
                    </div>
                    <div fxFlex="48" class="sub-heading text-capitalize accent-fg">{{ CompanyDetails.display_name }}
                        <button mat-icon-button
                            (click)="updateCompanyInfo('Company Name', 'display_name', CompanyDetails.display_name)"
                            matTooltip="Edit Company Name">
                            <mat-icon class="s-18">edit</mat-icon>
                        </button>
                    </div>
                </div>
                <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                    <div fxFlex="48" class="text-right sub-heading">
                        Website
                    </div>
                    <div fxFlex="48" class="sub-heading accent-fg">
                        {{ CompanyDetails.website? CompanyDetails.website: '-'}}
                    </div>
                </div>
                <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                    <div fxFlex="48" class="text-right sub-heading">
                        Sub Domain
                    </div>
                    <div fxFlex="48" class="sub-heading accent-fg">{{CompanyDetails.sub_domain_name}}
                        <button mat-icon-button
                            (click)="updateCompanyInfo('Sub Domain', 'sub_domain_name', CompanyDetails.sub_domain_name)"
                            matTooltip="Edit Sub Domain">
                            <mat-icon class="s-18">edit</mat-icon>
                        </button>
                    </div>
                </div>
                <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                    <div fxFlex="48" class="text-right sub-heading">
                        Sign Up Date
                    </div>
                    <div fxFlex="48" class="sub-heading accent-fg">{{CompanyDetails.created_at | date: "MM/dd/yyyy"}}
                    </div>
                </div>

                <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                    <div fxFlex="48" class="text-right sub-heading">
                        Current Plan
                    </div>
                    <div fxFlex="48" class="sub-heading text-capitalize accent-fg" *ngIf="activeSubscription">
                        <ng-container *ngIf="activeSubscription.billing_cycle == 1">Monthly</ng-container>
                        <ng-container *ngIf="activeSubscription.billing_cycle == 3">Quarterly</ng-container>
                        <ng-container *ngIf="activeSubscription.billing_cycle == 6">Half-Yearly</ng-container>
                        <ng-container *ngIf="activeSubscription.billing_cycle == 12">Yearly</ng-container>
                        <ng-container *ngIf="activeSubscription.subscription_plan == 'limited'"> - Limited
                        </ng-container>
                        <ng-container *ngIf="activeSubscription.subscription_plan == 'unlimited_excl_addons'"> -
                            Unlimited (Excl. addons) </ng-container>
                        <ng-container *ngIf="activeSubscription.subscription_plan == 'unlimited_inc_addons'"> -
                            Unlimited (Inc. addons)</ng-container>

                    </div>
                </div>
                <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between baseline" *ngIf="country_id == 2">
                    <div fxFlex="48" class="text-right sub-heading">
                        GST Number
                    </div>
                    <div fxFlex="48" class="sub-heading accent-fg">
                        <button mat-button class="primary" (click)="addGstNumber()" matTooltip="Add GST number"
                            *ngIf="isEmpty(gst_number)" [disabled]="isLostAccount == 0 ? false : true">
                            Add GST Number
                        </button>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="gst_number" fxFlexFill>
                            <div>{{gst_number}}</div>
                            <div>
                                <button mat-icon-button (click)="addGstNumber()" matTooltip="Edit GST number"
                                    [disabled]="isLostAccount == 0 ? false : true">
                                    <mat-icon class="s-18">edit</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between baseline" *ngIf="country_id == 2">
                    <div fxFlex="48" class="text-right sub-heading">
                        Renewal Remainder Emails
                    </div>
                    <div fxFlex="48" class="sub-heading accent-fg">
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill>
                            <div>{{ CompanyDetails.renewal_remainder_emails ? '' : BillingAddress.email }}</div>
                            <div>
                                <button mat-icon-button (click)="addRemainderEmails()" matTooltip="Add Emails"
                                    [disabled]="isLostAccount == 0 ? false : true">
                                    <mat-icon class="s-18">edit</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                    <div fxFlex="48" class="text-right sub-heading">
                        Subscribed Plans
                    </div>
                    <div fxFlex="48" class="sub-heading accent-fg">
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill>
                            <div>{{ getPlanTtitle }} {{layoutData.enabledApps.length > 0 ? "( " +  enabledApps() + " )" : '' }}</div>
                        </div>
                    </div>
                </div>

            </mat-card>
        </div>

        <ng-container *ngFor="let addon of addons_data">
            <div fxFlex='50' *ngIf="getAddonAccess(addon.key)">
                <mat-card class="p-0">
                    <mat-card-header class="px-16 border-top-radius-4 grey-200" fxLayout="row wrap"
                        fxLayoutAlign="space-between center">
                        <mat-card-title class="m-0 muli-bold"> {{addon.title}} </mat-card-title>
                        <div *ngIf="permissions['clients.billing_details.addons']">
                            <button *ngIf="addon.key == 'mass_mails_credits' && splittedAddons.length > 0"
                                mat-icon-button class="accent-fg" matTooltip="Split Mass Mails Into Monthly Wise"
                                (click)="selectMassMailRecord()">
                                <mat-icon class="s-18">call_split</mat-icon>
                            </button>
                            <button mat-icon-button
                                *ngIf="addon.addon_slug == 'hire_users' || addon.addon_slug == 'hr_users' || addon.addon_slug == 'crm_users' "
                                (click)="(addon.addon_slug == 'hire_users' || addon.addon_slug == 'hr_users' || addon.addon_slug == 'crm_users' ) ? UserLicences(addon.addon_slug) : AddCredits(addon.key)">
                                <mat-icon class="s-18">add</mat-icon>
                            </button>
                            <button mat-icon-button class="primary-fg" matTooltip="Enable Free App Access"
                                (click)="EnalbeFreeAppAccess(addon.key)"
                                *ngIf=" (addon.key == 'hire_users' || addon.key == 'hr_users' || addon.key == 'crm_users' ) && allow_app_type[addon.key] == 0">
                                <mat-icon class="s-18">person</mat-icon>
                            </button>
                        </div>
                    </mat-card-header>
                    <mat-card-content class="px-16 py-8">
                        <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                            <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="start baseline">
                                <div class="sub-heading mr-4">Total: </div>
                                <div class="accent-fg sub-heading"> {{addon.credits}}</div>
                            </div>
                            <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="start baseline">
                                <div class="sub-heading mr-4">Used: </div>
                                <div class="accent-fg sub-heading"> {{addon.used}}</div>
                            </div>

                            <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="start baseline">
                                <div class="sub-heading mr-4">Available: </div>
                                <div class="accent-fg sub-heading">
                                    {{ addon.credits - addon.used }}</div>
                            </div>
                        </div>

                        <table class="table"
                            *ngIf=" addon.addon_slug == 'hire_users' || addon.addon_slug == 'hr_users' || addon.addon_slug == 'crm_users' ">
                            <thead>
                                <th>Type</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Paid</td>
                                </tr>

                                <tr>
                                    <td>Free</td>
                                </tr>

                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </ng-container>

    </div>
    <div fxFlex='26'>
        <mat-card class="p-0">
            <mat-card-header class="px-16 py-8 border-top-radius-4 primary">
                <mat-card-title class="m-0">Billing Details</mat-card-title>
            </mat-card-header>
            <mat-card-content class="px-16 py-8">
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" class='line-height-28'>
                    <div fxFlex='16'>
                        <mat-icon class="s-18">person</mat-icon>
                    </div>
                    <div fxFlex='84'>
                        <h2 class="heading m-0">{{BillingAddress.contact_name ? BillingAddress.contact_name : '-'}}</h2>
                    </div>
                    <div fxFlex='16'>
                        <mat-icon class="s-18">email</mat-icon>
                    </div>
                    <div fxFlex='84'>
                        <h2 class="heading m-0">{{BillingAddress.email ? BillingAddress.email : '-'}}</h2>
                    </div>
                    <div fxFlex='16'>
                        <mat-icon class="s-18">phone</mat-icon>
                    </div>
                    <div fxFlex='84'>
                        <h2 class="heading m-0">{{BillingAddress.contact_number ? BillingAddress.contact_number : '-'}}
                        </h2>
                    </div>
                    <ng-container *ngIf="BillingAddress.city">
                        <div fxFlex='16'>
                            <mat-icon class="s-18">contact_mail</mat-icon>
                        </div>
                        <div fxFlex='84'>
                            <h2 class="heading m-0">{{BillingAddress.street ? BillingAddress.street : '-'}},
                                {{BillingAddress.city ? BillingAddress.city : '-'}},<br />
                                {{BillingAddress.state_code ? BillingAddress.state_code : '-'}}, <br />
                                {{BillingAddress.country_code ? BillingAddress.country_code : ''}} -
                                {{BillingAddress.zip ? BillingAddress.zip : ''}}</h2>
                        </div>
                    </ng-container>
                </div>
            </mat-card-content>
        </mat-card>
    </div>

    <div fxFlex='100' fxLayout="row wrap" fxLayoutAlign="start start">
        <div fxFlex='100' class="mt-20" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px grid"
            *ngFor="let addon_plan of subscribedAddons | keyvalue">
            <ng-container *ngFor="let addonslug of subscribedAddons[addon_plan.key] | keyvalue">
                <div fxFlex='33' *ngIf="showInTableAddons.includes(addonslug.key)">
                    <mat-card class="p-0" style="min-height: 160px;" *ngIf="!isEmpty(addonslug.value)">
                        <mat-card-header class="px-16 border-top-radius-4 grey-200 h-40" fxLayout="row wrap"
                            fxLayoutAlign="space-between center">
                            <mat-card-title class="m-0 muli-bold"> {{(addonslug.value?.['free']?.name) ??
                                (addonslug.value?.['paid']?.name)}} </mat-card-title>

                               
                            <div
                                *ngIf="permissions['clients.billing_details.addons']">
                                <button mat-icon-button
                                    *ngIf="addonPriceTypes[addonslug.key]=='per_unit' && ( activeSubscription?.subscription_type == 'trial'
                                     || isSubscribedApp(allPaidAddons[addon_plan.key][addonslug.key]['package']))"
                                    (click)="addUnit(addonslug.key, addon_plan.key)">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                                <button mat-icon-button
                                    *ngIf="activeSubscription.subscription_type == 'trial' && (addonslug.key == 'partner_login' || addonslug.key == 'mass_mails_credits' || (addonslug.key == 'contacts_search_credits') || (addonslug.key == 'linkedin_credits') || addonslug.key == 'sms_credits' || addonslug.key == 'job_grabber' || addonslug.key == 'i9_forms' || (addonslug.key == 'email_cleanup') || addonslug.key == 'digital_templates' || addonslug.key =='oorwin_assessments')"
                                    (click)="addFreeAddonCredits(addonslug.key, addon_plan.key)">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                                <button mat-icon-button
                                    *ngIf="allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['price_type']=='slab' || allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['price_type']=='slab_flat_fee'"
                                    (click)="addSlub(allPaidAddons?.[addon_plan.key][addonslug.key])">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                                <button mat-icon-button
                                    *ngIf="allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['price_type']=='flat_fee_per_unit'"
                                    (click)="addUnit(addonslug.key, addon_plan.key)">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                                
                                <button mat-icon-button
                                    *ngIf="allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['price_type']=='flat_fee' || allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['price_type']=='flat_fee_pro_rate' "
                                    (click)="addFlat(addonslug.key, addon_plan.key)">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                                <!--  -->
                                <button mat-icon-button *ngIf=
                                "allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['slug']== 'mass_mails_credits' || 
                                (allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['slug']== 'contacts_search_credits') ||
                                (allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['slug']== 'linkedin_credits') || 
                                allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['slug']== 'sms_credits' ||
                                allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['slug']== 'job_grabber' ||
                                (allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['slug']== 'email_cleanup') ||
                                 addonslug.key == ''"
                                    (click)="selectAddonType(allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['slug'])"  matTooltip="Extend credits end date">
                                    <mat-icon class="s-18">format_list_bulleted</mat-icon>
                                </button>
                                
                                <button mat-icon-button matTooltip="Convert free licences to paid licences"
                                    *ngIf="(subscr_end_date >= toDayDate) && (CompanyDetails.country_id != 2) && (CompanyDetails.stripe_payment_method_id) && (subscription_type != trial) && (addonslug.key == 'crm_users' || addonslug.key == 'hire_users' || addonslug.key == 'hr_users') && addonslug.value?.['free']"
                                    (click)="convertFreeLicensesToPaid(addonslug.value?.['free'], addon_plan.key)">
                                    <mat-icon class="s-18">crop_free</mat-icon>
                                </button>
                            </div>
                        </mat-card-header>
                        <mat-card-content class="px-16 py-8">
                            <div fxLayout="row wrap" fxLayoutAlign="space-between center"
                                *ngIf="addonslug.value?.['free']">

                                <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="start baseline">
                                    <div class="sub-heading mr-4">Total: </div>
                                    <div class="accent-fg sub-heading"> {{addonslug.value['paid']?.credits +
                                        addonslug.value?.['free']?.credits}}</div>
                                </div>
                                <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="start baseline">
                                    <div class="sub-heading mr-4">Used: </div>
                                    <div class="accent-fg sub-heading">
                                        {{addonslug.value['paid']?.used + addonslug.value['free']?.used}}</div>
                                </div>

                                <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="start baseline">
                                    <div class="sub-heading mr-4">Available: </div>
                                    <div class="accent-fg sub-heading">
                                        {{ (addonslug.value['paid']?.credits - addonslug.value['paid']?.used) +
                                        (addonslug.value['free']?.credits - addonslug.value['free']?.used)}}
                                    </div>
                                </div>
                            </div>

                            <table class="table">
                                <thead>
                                    <th>Type</th>
                                    <th>Credits</th>
                                    <th>Used</th>
                                    <th>Available</th>
                                </thead>
                                <tbody>
                                    <tr *ngIf="addonslug.value?.['paid']">
                                        <td>Paid</td>
                                        <td>{{addonslug.value['paid']?.credits}}</td>
                                        <td>{{addonslug.value['paid']?.used}}</td>
                                        <td>{{addonslug.value['paid']?.credits - addonslug.value['paid']?.used}}</td>
                                    </tr>

                                    <tr *ngIf="addonslug.value?.['free']">
                                        <td>Free</td>
                                        <td>{{addonslug.value['free']?.credits}}</td>
                                        <td>{{addonslug.value['free']?.used}}</td>
                                        <td>{{addonslug.value['free']?.credits - addonslug.value['free']?.used}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="p-0" style="min-height: 160px;" *ngIf="isEmpty(addonslug.value)">
                        <mat-card-header class="px-16 border-top-radius-4 grey-200 h-40" fxLayout="row wrap"
                            fxLayoutAlign="space-between center">
                            <mat-card-title class="m-0 muli-bold">
                                {{allPaidAddons?.[addon_plan.key][addonslug.key]['name']}} </mat-card-title>
                            <div *ngIf="permissions['clients.billing_details.addons']">
                                <!-- {{abcd(allPaidAddons?.[addon_plan.key][addonslug.key])}} -->

                                <button mat-icon-button
                                    *ngIf="
                                    (allPaidAddons?.[addon_plan.key][addonslug.key]?.['price_type']=='per_unit')  && (activeSubscription.subscription_type == 'trial' || isSubscribedApp(allPaidAddons[addon_plan.key][addonslug.key]['package']))"
                                    (click)="addUnit(addonslug.key, addon_plan.key)">
                                    <mat-icon class="s-18">add</mat-icon>
                                    
                                </button>
                                <button mat-icon-button
                                    *ngIf="(allPaidAddons?.[addon_plan.key][addonslug.key]?.['price_type']=='slab' || allPaidAddons?.[addon_plan.key][addonslug.key]?.['price_type']=='slab_flat_fee')"
                                    (click)="addSlub(allPaidAddons?.[addon_plan.key][addonslug.key])">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                                <button mat-icon-button
                                    *ngIf="allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['price_type']=='flat_fee_per_unit'"
                                    (click)="addUnit(addonslug.key, addon_plan.key)">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                                <button mat-icon-button
                                    *ngIf="allPaidAddons?.[addon_plan.key][addonslug.key]?.['price_type']=='flat_fee' || allPaidAddons?.[addon_plan.key][addonslug.key]?.['price_type']=='flat_fee_pro_rate'"
                                    (click)="addFlat(addonslug.key, addon_plan.key)">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                            </div>
                        </mat-card-header>
                        <mat-card-content class="px-16 py-8">
                            <table class="table"
                                *ngIf="allPaidAddons?.[addon_plan.key][addonslug.key]?.['price_type'] != 'flat_fee'">
                                <thead>
                                    <th>Type</th>
                                    <th>Credits</th>
                                    <th>Used</th>
                                    <th>Available</th>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>Free</td>
                                        <td>{{addonslug.value?.['free']?.credits ?? 0}}</td>
                                        <td>{{addonslug.value?.['free']?.used ?? 0}}</td>
                                        <td>{{(addonslug.value?.['free']?.credits - addonslug?.value['free']?.used) ??
                                            0}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </div>
            </ng-container>
        </div>

        <div fxFlex='100' fxLayout="row wrap"
            fxLayoutAlign="start start" fxLayoutGap="16px grid" *ngFor="let addon_plan of subscribedAddons | keyvalue">
            <ng-container *ngFor="let addonslug of subscribedAddons[addon_plan.key] | keyvalue">
                <div fxFlex='33' class="mt-20" *ngIf="!(showInTableAddons.includes(addonslug.key))">
                    <mat-card class="p-0">
                        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-8 h-40" fxLayout="row wrap"
                            fxLayoutAlign="space-between center">
                            <mat-card-title class="m-0 muli-bold">
                                {{isEmpty(addonslug.value) ? allPaidAddons?.[addon_plan.key][addonslug.key]['name'] :
                                (addonslug.value?.['free']?.name) ??
                                (addonslug.value?.['paid']?.name)}}
                            </mat-card-title>
                            <div *ngIf="permissions['clients.billing_details.addons']">
                                <button mat-icon-button
                                    *ngIf="allPaidAddons?.[addon_plan.key][addonslug.key]?.['price_type']=='per_unit'"
                                    (click)="addUnit(addonslug.key, addon_plan.key)">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                                <button mat-icon-button
                                    *ngIf="(allPaidAddons?.[addon_plan.key][addonslug.key]?.['price_type']=='slab' || allPaidAddons?.[addon_plan.key][addonslug.key]?.['price_type']=='slab_flat_fee') && isEmpty(addonslug.value)"
                                    (click)="addSlub(allPaidAddons?.[addon_plan.key][addonslug.key])">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                                <button mat-icon-button
                                    *ngIf="allPaidAddons?.[addon_plan.key]?.[addonslug.key]?.['price_type']=='flat_fee_per_unit'"
                                    (click)="addUnit(addonslug.key, addon_plan.key)">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                                <button mat-icon-button
                                    *ngIf="(allPaidAddons?.[addon_plan.key][addonslug.key]?.['price_type']=='flat_fee' && isEmpty(addonslug.value)) || (allPaidAddons?.[addon_plan.key][addonslug.key]?.['price_type']=='flat_fee_pro_rate' && isEmpty(addonslug.value))"
                                    (click)="addFlat(addonslug.key, addon_plan.key)">
                                    <mat-icon class="s-18">add</mat-icon>
                                </button>
                            </div>
                        </mat-card-header>
                        <mat-card-content class="px-16 py-8"></mat-card-content>
                    </mat-card>
                </div>
            </ng-container>
        </div>
    </div>

    <div fxFlex='100'>
        <ul fxLayout="row" class='nav'>
            <!-- <li *ngIf="subscription_type == 'paid'"><a (click)="getAddonList('due')"
                    [ngClass]="{ active : is_active == 'due' }">Due</a></li> -->
            <li *ngIf="subscription_type == 'paid'"><a (click)="getAddonList('paid')"
                    [ngClass]="{ active : is_active == 'paid' }">Paid</a></li>
            <li><a (click)="getAddonList('free')" [ngClass]="{ active : is_active == 'free' }">Free</a></li>
            <li><a (click)="getAddonList('top_up')" [ngClass]="{ active : is_active == 'top_up' }">Top Up</a></li>
        </ul>
        <mat-divider></mat-divider>
        <cdk-virtual-scroll-viewport itemSize="50" class="scroll-content-height">
            <div class="my-12" *ngIf="dataSource">
                <mat-table [dataSource]="dataSource">
                    <!-- customername Column -->
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef fxFlex="200px"> name </mat-header-cell>
                        <mat-cell *matCellDef="let element" fxFlex="200px">
                            <a>{{trimString(element.addon_slug)}}</a>
                        </mat-cell>
                    </ng-container>
                    <!-- subscriptiontype Column -->
                    <ng-container matColumnDef="subscriptiontype">
                        <mat-header-cell *matHeaderCellDef fxFlex="200px"> subscription type</mat-header-cell>
                        <mat-cell *matCellDef="let element" fxFlex="200px">
                            {{element.purchase_type}}
                        </mat-cell>
                    </ng-container>

                    <!-- quantity Column -->
                    <ng-container matColumnDef="quantity">
                        <mat-header-cell *matHeaderCellDef> quantity</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.units}} </mat-cell>
                    </ng-container>

                    <!-- used Column -->
                    <ng-container matColumnDef="used">
                        <mat-header-cell *matHeaderCellDef> used</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{element.used}} </mat-cell>
                    </ng-container>

                    <!-- cost Column -->
                    <ng-container matColumnDef="cost">
                        <mat-header-cell *matHeaderCellDef> cost </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span *ngIf="element.total != 0"> {{element.total}}</span>
                            <span *ngIf="element.total == 0">-</span>
                        </mat-cell>
                    </ng-container>

                    <!-- addedon Column -->
                    <!-- <ng-container matColumnDef="added_on">
                        <mat-header-cell *matHeaderCellDef> added on </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{ element.created_at | date:"MM/dd/yyyy"}}</mat-cell>
                    </ng-container> -->

                    <!-- startdate Column -->
                    <ng-container matColumnDef="startdate">
                        <mat-header-cell *matHeaderCellDef> start date </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span *ngIf="element.addon_id != 1 && element.addon_id != 5 && element.addon_id != 6 ">{{
                                element.start_date | date:"MM/dd/yyyy"}}
                            </span>
                            <span *ngIf="element.addon_id == 1 || element.addon_id == 5 || element.addon_id == 6 ">-
                            </span>
                        </mat-cell>
                    </ng-container>

                    <!-- enddate Column -->
                    <ng-container matColumnDef="enddate">
                        <mat-header-cell *matHeaderCellDef> enddate </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <span *ngIf="element.addon_id != 1 && element.addon_id != 5 && element.addon_id != 6 ">{{
                                element.end_date | date:"MM/dd/yyyy"}}
                            </span>
                            <span *ngIf="element.addon_id == 1 || element.addon_id == 5 || element.addon_id == 6 ">-
                            </span>
                        </mat-cell>
                    </ng-container>

                    <!-- billable Column -->
                    <ng-container matColumnDef="billable">
                        <mat-header-cell *matHeaderCellDef> billable </mat-header-cell>
                        <mat-cell *matCellDef="let element">{{element.billing_type == 'paid' ? 'Yes' : 'No'}}</mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
                <div *ngIf="dataSource.data.length == 0" class="text-center">
                    <h2 class="heading m-0 py-16 border">No Records Found</h2>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</div>

<div class='footer'>
    <button mat-button (click)="addInvoiceLineItems()" matTooltip="Add invoice line items to next subscription renewal">
        {{isExtraChargesAdded?'Edit Additional Charges':'Add Additional Charges'}}
    </button>
    <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
    <button *ngIf="subscription_type != 'paid'" mat-button (click)="cartRoute('upgrade', company_id)">Upgrade</button>
    <button *ngIf="subscription_type == 'paid' && actual_subscr_end_date >= toDayDate" mat-button (click)="cartRoute('top_up', company_id)">Top-up</button>
    <button *ngIf="subscription_type == 'paid' && actual_subscr_end_date < toDayDate" mat-button (click)="cartRoute('renew', company_id)">Renew</button>
    <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
    <button mat-button (click)="showActivities()" [disabled]="isLostAccount == 0 ? false : true" >Activities</button>
    <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
    <button *ngIf="subscription_type == 'paid' && actual_subscr_end_date >= toDayDate" mat-button (click)="cartRoute('upgrade_subscription', company_id)">Upgrade Existing Plan</button>
</div>