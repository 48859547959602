import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/services/common.service';
import * as moment from 'moment';
import { ConfirmExitRequestApproveComponent } from '../confirm-exit-request-approve/confirm-exit-request-approve.component';

@Component({
  selector: 'app-purchased-addon-type-list',
  templateUrl: './purchased-addon-type-list.component.html',
  styleUrls: ['./purchased-addon-type-list.component.scss']
})
export class PurchasedAddonTypeListComponent implements OnInit {

  displayedColumns: string[] = ['addon','billing_type','start_date','end_date','credits','used', 'action'];
  dataSource:any;

  constructor( public dialogRef: MatDialogRef<PurchasedAddonTypeListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private commonService: CommonService, 
    public dialog: MatDialog,) { }
    
  ngOnInit() {
    this.getList();
  }

  getList() {
    this.commonService.getPaidAddonTypeList({addonSlug:this.data.addonSlug, company_id:this.data.company_id}).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data);
      }
    })
  }

  onDateChange(event, record) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to extend end date';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const confirmAlert = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });

    confirmAlert.afterClosed().subscribe(result => {
      if (result.approved) {
        let extended_date: any;
        let saveData: any;
        extended_date = moment(event.target.value).format("YYYY-MM-DD");
        
        saveData = {
          'company_id': this.data.company_id,
          'company_name': this.data.company_name,
          'record':record,
          'extended_date': extended_date,
          'comments': result.commets
        };
        this.commonService.updateAddonTypeData(saveData).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.dialogRef.close(true);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        });

      } else {
      }
    })
  }

  subscribeAddon(record, subscribed) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to ' + (subscribed ? 'subscribe' : 'unsubscribe') + ' addon';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const confirmAlert = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });

    confirmAlert.afterClosed().subscribe(result => {
      if (result.approved) {
        let saveData: any;
        saveData = {
          'company_id': this.data.company_id,
          'company_name': this.data.company_name,
          'addon_id':record.id,
          'addon_name':record.name,
          'addon_credits': record.credits,
          'comments': result.commets,
          'is_subscribed': subscribed
        };
        this.commonService.updateAddonSubscription(saveData).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.dialogRef.close(true);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        });
      }
    })
  }

}
