import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { EditDoccumentlistingDialogComponent } from '../../main/signup-request/edit-doccumentlisting-dialog/edit-doccumentlisting-dialog.component';
import { environment } from 'environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SendDoctoClientDialogComponent } from '../send-docto-client-dialog/send-docto-client-dialog.component';

@Component({
  selector: 'app-doccuments-listing',
  templateUrl: './doccuments-listing.component.html',
  styleUrls: ['./doccuments-listing.component.scss']
})
export class DoccumentsListingComponent implements OnInit {
  MxRecordsFrom: FormGroup;
  displayedColumns: string[] = ['title', 'description', 'signdate', 'status', 'actions'];
  dataSource;
  searchValue = '';
  save_type = '';
  getlist: any;
  getTableData: any;
  length: any;
  company_id: any;
  params: { company_id: number; limit: number; page: number, search: string; }
  currentPage: number;
  limit: number;
  currentUser: any;
  companydomain: any;
  
  constructor(private commonService: CommonService, public dialog: MatDialog , private activatedroute: ActivatedRoute) {
    this.company_id = this.activatedroute.parent.snapshot.params['id']; }

  ngOnInit() {
    this.params = { company_id: Number(this.company_id), limit: 15, page: 1, search: '',  }
    this.getDoccumentsData('main');
  }

  applyFilter(val) {
    this.params['search'] = val;
    this.params.page = 1;
    this.getDoccumentsData('search');
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getDoccumentsData('main');
  }
  changeStatus(event) {
    // console.log(event.value);
    this.params['save_type'] = event.value;
    this.commonService.getDoccumentsList(this.params).subscribe(result => {
      if (result.status === 1) {
        this.currentPage = result.doccumentslist.current_page;
        this.limit = result.doccumentslist.per_page;
        this.length = result.doccumentslist.total;
        this.dataSource = new MatTableDataSource(result.doccumentslist.data);
      }
    })
  }
  getDoccumentsData(getnav) {
    switch (getnav) {
      case 'main':
        this.getlist = getnav;
        this.params['main_doc'] = 1;
        break;
      case 'all':
        this.getlist = getnav;
        this.params['main_doc'] = 0;
        break;
      case 'search':
        this.getlist = getnav;
        this.params['main_doc'] = 2;
        break;
      case 'status':
        this.getlist = getnav;
        this.params['main_doc'] = 2;
        break;
      default:
        break;
    }

    if (this.company_id)
      this.displayedColumns = ['title', 'description', 'signdate', 'status', 'actions'];
    else if (this.params['main_doc'] == 1 ) 
        this.displayedColumns = ['title', 'description', 'actions'];
    else 
        this.displayedColumns = ['title', 'description', 'signdate', 'status', 'actions'];

    this.commonService.getDoccumentsList(this.params).subscribe(result => {
      if (result.status === 1) {
        this.currentPage = result.doccumentslist.current_page;
        this.limit = result.doccumentslist.per_page;
        this.length = result.doccumentslist.total;
        this.dataSource = new MatTableDataSource(result.doccumentslist.data);
      }
    })
  }

  edit(element): void {
    const dialogRef = this.dialog.open(EditDoccumentlistingDialogComponent, {
      width: '50%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.getDoccumentsData('main');
      }
    });
  }

  
  design(element){
    
    var DOC_URL = environment.docUrl;
    this.commonService.getCompanyData({ id: element }).subscribe(result => {
      if (result.status == 1) {
        this.companydomain = result.companydomain;
      }
    });
    if ( this.companydomain != 'undefined' ) {
      DOC_URL = DOC_URL.replace( "app", this.companydomain );
      window.open( DOC_URL + 'doc_design/index.html#/design_template/' + element );
    }
  }

  preview(element){
    
    var DOC_URL = environment.docUrl;
    this.commonService.getCompanyData({ id: element }).subscribe(result => {
      if (result.status == 1) {
        this.companydomain = result.companydomain ;
      }
    });
    if ( this.companydomain != 'undefined' ) {
      DOC_URL = DOC_URL.replace( "app", this.companydomain );
    }
    window.open( DOC_URL + 'doc_design/index.html#/preview_template/' + element );
  }

  downnload(element){
    var DOC_URL = environment.apiUrl;
    window.open( DOC_URL + 'download_onboarding_pdf/' + element );
  }

  sendEmail(element){
    
    const dialogRef = this.dialog.open(SendDoctoClientDialogComponent, {
      width: '50%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      if (result) {
        this.getDoccumentsData('main');
      }
    });
    // var DOC_URL = environment.apiUrl + 'send_document/' + element ;
    // window.open( DOC_URL );

    // var DOC_URL = 'http://consolidation.localhost/manoj_spa/';
    // window.open( DOC_URL + 'doc_design/index.html#/send_document/' + element );

  }

  revokeDoccumentsData(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = element.status == 1 ? 'Are you sure? # You want to In-Active Doccument' : 'Are you sure? # You want to Active Doccument';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonService.revokeDoccument({
          status: element.status,
          id: element.id
        }).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.getDoccumentsData('main');
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      }
    })
  }

  deleteDoccumentsData(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to Delete DoccumentsData';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonService.deleteDoccument({ id: element.id }).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.getDoccumentsData('main');
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      }
    })
  }

}
