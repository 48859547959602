import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupRequestComponent } from './signup-request/signup-request.component';
import { PassDueDateComponent } from './pass-due-date/pass-due-date.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ImportComponent } from './import/import.component';
import { MigrationRequestComponent } from './migration-request/migration-request.component';
import { DataBackupRequestComponent } from './data-backup-request/data-backup-request.component';
import { FTPDetailsComponent } from './ftpdetails/ftpdetails.component';
import { ReleaseMaintenanceComponent } from './release-maintenance/release-maintenance.component';
import { ExitRequestComponent } from './exit-request/exit-request.component';
import { LeadOwnersComponent } from './lead-owners/lead-owners.component';
import { DemoRequestComponent } from './demo-request/demo-request.component';
import { PowerbiReportsComponent } from './powerbi-reports/powerbi-reports.component';
import { SubscriptionInvoiceComponent } from './subscription-invoice/subscription-invoice.component';
import { CpanelActivitiesComponent } from './cpanel-activities/cpanel-activities.component';
import { FtpFilesComponent } from './ftp-files/ftp-files.component';
import { ImplementationDataListingComponent } from './implementation-data-listing/implementation-data-listing.component';
import { DataMigrationListingComponent } from './data-migration-listing/data-migration-listing.component';
import { CreditAccountBalanceTransactionsComponent } from './credit-account-balance-transactions/credit-account-balance-transactions.component';
import { CouponComponent } from './coupon/coupon.component';
import { CommonModuleActivityComponent } from 'app/common-all/common-module-activity/common-module-activity.component';
import { UsageMetricsComponent } from './usage-metrics/usage-metrics.component';

const routes: Routes = [
  {
    path: 'signup_requests',
    component: SignupRequestComponent
  },
  {
    path: 'migrationRequests',
    component: MigrationRequestComponent
  },
  {
    path: 'data_backup_requests',
    component: DataBackupRequestComponent
  },
  {
    path: 'file_storage_details',
    component: FTPDetailsComponent
  },
  {
    path: 'exit_requests',
    component: ExitRequestComponent
  },
  {
    path: 'client_payments',
    component: PowerbiReportsComponent
  },
  {
    path: 'subscriptionInvoices',
    component: SubscriptionInvoiceComponent
  },
  {
    path: 'cpanelActivities',
    component: CpanelActivitiesComponent
  },
  {
    path: 'company_ftp_files',
    component: FtpFilesComponent
  },
  {
    path: 'creditBalanceTransactions',
    component: CreditAccountBalanceTransactionsComponent
  },
  {
    path: 'activities/:module_slug',
    component: CommonModuleActivityComponent
  },
  {
    path: 'change_password',
    component: ChangePasswordComponent
  },
  {
    path: 'leadOwners',
    component: LeadOwnersComponent
  },
  {
    path: 'demoRequests',
    component: DemoRequestComponent
  },
  {
    path: 'pass_due_date',
    component: PassDueDateComponent
  },
  {
    path: 'import/:id',
    component: ImportComponent
  },
  {
    path: 'maintenance',
    component: ReleaseMaintenanceComponent,
  },
  {
    path: 'implemenationListing',
    component: ImplementationDataListingComponent,
  },
  {
    path: 'migrationsListing',
    component: DataMigrationListingComponent,
  },
  {
    path: 'couponsListing',
    component: CouponComponent,
  },
  {
    path: 'usage-metrics',
    component: UsageMetricsComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignupRequestRoutingModule { }
