<h1 mat-dialog-title class='text-uppercase'>Edit User</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="addUserForm">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>First Name</mat-label>
          <input type="text" matInput formControlName="first_name">
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Last Name</mat-label>
          <input type="text" matInput formControlName="last_name">
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Email</mat-label>
          <input type="email" matInput formControlName="email">
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Mobile Number</mat-label>
          <input type="text" matInput formControlName="contact_number">
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Country</mat-label>
          <mat-select formControlName="country_id" (selectionChange)="changeCountry($event.value)">
            <mat-option *ngFor="let item of countries" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>State</mat-label>
          <mat-select formControlName="state_id">
            <mat-option *ngFor="let item of states" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>City</mat-label>
          <input type="text" matInput formControlName="city">
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Office Number</mat-label>
          <input type="text" matInput formControlName="office_number">
        </mat-form-field>
      </div>
    </div>

    <div class="my-16">
      <mat-checkbox [checked]='checked' color="primary" class="sub-heading" (change)='check($event)' formControlName="is_super_admin">Super Admin
      </mat-checkbox >
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid" *ngIf="!checked">
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Hire Profile</mat-label>
          <mat-select formControlName="hire_role_id">
            <mat-option *ngFor="let item of hire_users_list" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>HR Profile</mat-label>
          <mat-select formControlName="hr_role_id">
            <mat-option *ngFor="let item of hr_users_list" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>CRM Profile</mat-label>
          <mat-select formControlName="crm_role_id">
            <mat-option *ngFor="let item of crm_users_list" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-flat-button class='primary' (click)="saveUser()">Save</button>
</mat-dialog-actions>