import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-ftpdetails',
  templateUrl: './ftpdetails.component.html',
  styleUrls: ['./ftpdetails.component.scss']
})
export class FTPDetailsComponent implements OnInit {

  displayedColumns: string[] = ['customer_name', 'user_name', 'password','host' ,'port', 'created_on', 'actions'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  currentUser = {};
  params: { page: number; limit?: number, searchKey?: any };

  constructor(
    private commonService: CommonService,
    public router: Router, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    this.getCurrentUser();
    this.getList();
  }
  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.commonService.getFTPdetails(this.params).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data.data);
        this.length = res.data.total;
        this.pageSize = res.data.per_page;
        this.index = res.data.current_page;
      }
    })
  }
  navigate(element, blank) {
    // this.router.navigate(['company_details', element.company_id]);
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }

  deleteRecord(record) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? You want to delete record';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = {};
        params['company_id'] = record.company_id;
        this.commonService.deleteFTPdetails(params).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
          this.getList();
        });
      } else { }
    })
  }

  getCurrentUser() {
    this.commonService.getCurrentUser().subscribe(res => {
      if (res.status == 1) {
        this.currentUser = res.data;
      }
    })
  }

}
