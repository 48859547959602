<ng-container *ngIf="dataSource">
  <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12">
    <!-- <div class="search-wrapper" fxFlex="30">
      <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
        [(ngModel)]="searchValue" placeholder="Search Here ...">
      <mat-icon class="search_icon">search</mat-icon>
      <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
        close
      </mat-icon>
    </div> -->
    <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
      <div>
        <button mat-flat-button class="primary mr-4" (click)="addRefund()">Add</button>
      </div>
      <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
        [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
    </div>
  </div>
  <div class="px-12 pb-12">
    <mat-table [dataSource]="dataSource">
      <!-- comment Column -->
      <ng-container matColumnDef="comment">
        <mat-header-cell *matHeaderCellDef> Comments</mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.item_details}}
        </mat-cell>
      </ng-container>
      <!-- amount Column -->
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef> amount </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.amount}} </mat-cell>
      </ng-container>

      <!-- 	refundtype Column -->
      <ng-container matColumnDef="refundtype">
        <mat-header-cell *matHeaderCellDef> refund type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.refund_type}}
        </mat-cell>
      </ng-container>

      <!-- 	date Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> date </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.created_at |date:'MM/dd/yyyy'}}
        </mat-cell>
      </ng-container>

       <!-- 	date Column -->
       <!-- <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef> action </mat-header-cell>
        <mat-cell *matCellDef="let element"> 
          <span *ngIf="element.refund_type == 'card'">
              <button mat-flat-button class="primary mr-4" (click)="processRefund(element.id)">Process Refund</button>
          </span>
          <span *ngIf="element.refund_type == 'wallet'">-</span>
        </mat-cell>
      </ng-container> -->

      <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div *ngIf="dataSource.data.length == 0" class="text-center">
      <h2 class="heading m-0 py-16 border">No Records Found</h2>
    </div>
  </div>
</ng-container>