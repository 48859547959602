import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
// import { SubscriptionConfigurationDialogComponent } from '../../subscriptions/subscription-configuration-dialog/subscription-configuration-dialog.component';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'app/services/common.service';

@Component({
  selector: 'app-update-payment-details',
  templateUrl: './update-payment-details.component.html',
  styleUrls: ['./update-payment-details.component.scss']
})
export class UpdatePaymentDetailsComponent implements OnInit {
  updateform: FormGroup;
  payment: { name: string; value: string; }[];
  showtxn: boolean = false;
  company_id: any;
  amount: any;

  constructor(private fb: FormBuilder,
    // public dialogRef: MatDialogRef<SubscriptionConfigurationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private commonserv: CommonService) {
    this.company_id = this.data.company_id;
    this.payment = [
      { name: 'Bank', value: 'bank' },
      { name: 'Cheque', value: 'cheque' },
      { name: 'Credit Card', value: 'credit-card' },

    ]
    this.updateform = this.fb.group({
      payment_mode: ['', Validators.required],
      txn_id: ['', Validators.required],
    })
  }

  ngOnInit() {
    console.log(this.data);
    this.getRenewAmount()
  }
  getRenewAmount() {
    let params = {
      company_id: this.company_id
    }
    // this.commonserv.getRenewDetails(params).subscribe(res => {
    //   if (res.status) {
    //     this.amount = res.data ? res.data['amount'] : 'undefined';
    //   } else {
    //     this.commonserv.openSnackBar('warn', 'You cannot renew this account');
    //     // this.dialogRef.close(1);
    //   }
    // })
  }

  paymentChange() {
    if (this.updateform.value['payment_mode'] != '') {
      this.showtxn = true;
    } else {
      this.showtxn = false;
    }
  }

  update() {
    if (this.updateform.valid) {
      let data = [];
      data['title'] = 'Are you sure?';
      data['message'] = 'Do you want to Update the payment information ?';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: data,
        minWidth: '30%',
        minHeight: '40%'
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let params = {
            'company_id': this.company_id,
            'payment_mode': this.updateform.value['payment_mode'],
            'txn_id': this.updateform.value['txn_id']
          }
          // this.commonserv.cpanelrenew(params).subscribe(res => {
          //   if (res.status) {
          //     this.commonserv.openSnackBar('primary', res.message);
          //   } else {
          //     this.commonserv.openSnackBar('warn', res.message);
          //   }
          // })

        }
      });
    } else {

    }
  }
}
