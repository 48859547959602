import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'app/services/common.service';
import { CompanyDetailsService } from 'app/services/company-details.service';
import * as moment from 'moment';

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss']
})
export class TrainingsComponent implements OnInit {

  statusList = [
    { value: 'in_progress', viewValue: 'In Progress' }, { value: 'completed', viewValue: 'Completed' }
  ];

  trainingPoc: any;
  estCompletioinDate: any
  completionDate: any;
  trainingStatus: any;
  company_id: any;
  usersList: any

  @ViewChild('subscribeForm', { static: false }) subscribeForm: FormGroupDirective;


  constructor(private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    public dialog: MatDialog,
    private route: ActivatedRoute,) {
    this.company_id = this.route.parent.snapshot.params['id'];
  }

  ngOnInit() {
    this.getCPanelUsers();
    this.getTrainingInfo();
  }

  getTrainingInfo() {
    this.companyDetailService.getTrainingData({company_id:this.company_id, module:'training'}).subscribe(res => {
      if (res.status == 1) {
        let responseData = res.data;
        this.trainingPoc = responseData.trainingPoc;
        this.estCompletioinDate = responseData.estCompletioinDate;
        this.completionDate = responseData.completionDate;
        this.trainingStatus = responseData.trainingStatus;
      } 
    })
  }

  getCPanelUsers() {
    let RoleIdArr = {};
    RoleIdArr[3] = 3;
    RoleIdArr[2] = 2;
    this.companyDetailService.getCpanelRoleUsers(RoleIdArr).subscribe(res => {
      if (res.status == 1) {
        this.usersList = res.data.users;
      }
    })
  }

  saveData() {

    if (this.subscribeForm.valid) {
      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? You want to update training data?';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35%',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let formData = {};
          formData['trainingPoc'] = this.trainingPoc;
          formData['estCompletioinDate'] = this.estCompletioinDate;
          formData['completionDate'] = this.completionDate;
          formData['trainingStatus'] = this.trainingStatus;
          let payLoadData = {
            company_id: this.company_id,
            module: 'training',
            data: JSON.stringify(formData)
          }
          this.companyDetailService.saveTrainingInfo(payLoadData).subscribe(res => {
            if (res.status == 1) {
              this.commonService.openSnackBar('primary', res.message);
            } else {
              this.commonService.openSnackBar('warn', res.message);
            }
          })
        }
      })
    }
    else {
      this.commonService.openSnackBar('warn', 'Please submit required details');
      return false;
    }
  }

  onDateChange(event, inputName) {
    if (inputName == 'estCompletioinDate') {
      this.estCompletioinDate = moment(this.estCompletioinDate).format("YYYY-MM-DD");
    }
    else if (inputName == 'completionDate') {
      this.completionDate = moment(this.completionDate).format("YYYY-MM-DD");
    }
  }

}
