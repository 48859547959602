<div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="customersResult">
  <div class="search-wrapper" fxFlex="30">
    <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value, 'customer')" type="text"
      [readOnly]="customersResult.length === 0" [(ngModel)]="customer_searchValue" placeholder="Company Name">
    <mat-icon class="search_icon">search</mat-icon>
    <mat-icon class="close s-20 danger-fg" *ngIf="customer_searchValue"
      (click)="customer_searchValue =''; applyFilter('','customer');">
      close
    </mat-icon>
  </div>
  <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
    <div fxLayout="row" fxLayoutAlign="end center">
      <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="customers_data_length"
        (page)="pageEvent($event, 'customer')" [pageSize]="customers_data_pageSize"
        [pageIndex]="customers_data_index-1"></mat-paginator>
    </div>
  </div>
</div>
<div class="content" fusePerfectScrollbar class="scroll-content-height">
  <div class="p-12">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of customersData">
        <mat-expansion-panel-header (click)="getCompany(item.id)">
          <mat-panel-title>
            {{item.name}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="dataSource">
          <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg">
            <div class="search-wrapper" fxFlex="30">
              <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value, 'user')" type="text"
                [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue"
                placeholder="User Name, Contact Number, Email">
              <mat-icon class="search_icon">search</mat-icon>
              <mat-icon class="close s-20 danger-fg" *ngIf="searchValue"
                (click)="searchValue=''; applyFilter('', 'user');">
                close
              </mat-icon>
            </div>
            <div fxFlex="20">
              <!-- <form [formGroup]="company">
                    <mat-form-field class="w-100-p ml-8" appearance="outline">
                      <mat-select class="w-100-p" placeholder="Select" formControlName="toppings" multiple>
                        <mat-option *ngFor="let topping of toppingList" [value]="topping.id">{{topping.display_name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </form> -->
            </div>
            <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
              <div>
                <button mat-stroked-button (click)="exportExcel(item.name)">Export as Excel</button>
              </div>

              <div fxLayout="row" fxLayoutAlign="end center">
                <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length"
                  (page)="pageEvent($event, 'user')" [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
              </div>
            </div>
          </div>
          <div class="pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="user_name">
                <mat-header-cell *matHeaderCellDef> user name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef> role </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.is_super_admin == 0">{{getUserRole(element.active_roles, 'role')}}</span>
                  <span *ngIf="element.is_super_admin == 1">Super Administrator</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="access_to">
                <mat-header-cell *matHeaderCellDef> access to </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{getUserRole(element.active_roles, 'access_to')}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef> email </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase">{{element.email}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef> contact number </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.contact_number}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="added_on">
                <mat-header-cell *matHeaderCellDef> added on </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at | date:'MM/dd/yyyy' }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> actions </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button mat-icon-button class="accent-fg" matTooltip="Change Password"
                    (click)="changePassword(element.id)">
                    <mat-icon class="s-18">vpn_key</mat-icon>
                  </button>

                  <button mat-icon-button
                    [ngClass]="{'orange-fg': element.status == 1 , 'primary-fg':element.status == 0}"
                    matTooltip="Active" (click)="revokeUser(element)">
                    <mat-icon class="s-18">{{element.status == 1 ? 'voice_over_off' : 'person'}}</mat-icon>
                  </button>

                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>