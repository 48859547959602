<div fxLayout="row" fxLayoutAlign="space-between" id="login">
  <div fxLayout="column" fxFlex="60" fxLayoutAlign="space-between start" style="height:100vh;overflow:hidden;">
    <div class="logo py-4 px-40">
      <img src="assets/images/logo/oorwin.png">
    </div>
    <div>
      <img src="assets/images/login.png" alt="image">
    </div>

  </div>

  <div id="login-form-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
    <div id="login-form">
      <div [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

        <div *ngIf="!showOtp">
          <div class="font-size-20 text-bold mb-16">Log In</div>
          <form [formGroup]="loginForm">

            <!-- <label class="text-bold pl-12">Mobile Number</label>
            <intl-input-phone appearance="outline" class="mt-12" formControlName="mobile"
              [ConfigurationOption]="configOption3" (OnIsRequiredChange)="requiredFlagChange($event)"
              [NumberTextValue]="NumberModel2" [SelectedCountryISOCode]="SelectedCountryISOCode"></intl-input-phone> -->
              
            <mat-form-field appearance="outline" class="mt-12" *ngIf="!showOtp">
              <mat-label>Email</mat-label>
              <mat-icon matPrefix class="s-18 mr-8">person</mat-icon>
              <input matInput formControlName="email">
            </mat-form-field>

            <!-- <mat-form-field appearance="outline" class="mt-12">
              <mat-label>Mobile Number</mat-label>
              <mat-icon matPrefix class="s-18 mr-8">phone</mat-icon>
              <input matInput formControlName="mobile">
            </mat-form-field> -->

            <mat-form-field class="mt-12" appearance="outline" *ngIf="!showOtp">
              <mat-label>Enter your password </mat-label>
              <mat-icon matPrefix class="s-18 mr-8">vpn_key</mat-icon>
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
              <button type='button' mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
            </mat-form-field>

            <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"  *ngIf="!showOtp">
              <!-- <mat-checkbox color="primary" class="remember-me" aria-label="Remember Me">
                Remember Me
              </mat-checkbox> -->
              <a class="forgot-password primary-fg cursor-pointer" (click)="forgotPassword()">
                Forgot Password?
              </a>
            </div>

            <button mat-raised-button color="primary" class="submit-button btn-rounded" aria-label="LOG IN"
              (click)='login()'>Submit</button>
          </form>
        </div>
        <div id="recaptcha-container"></div>

      </div>

      <!-- <div *ngIf="forgotpw">
      <div class="font-size-20 text-bold">Forgot Password</div>
      <p class="my-12">Go back to <span class="accent-fg cursor-pointer" (click)='forgotpw = false'> Sign In </span>
      </p>
      <form>
        <mat-form-field appearance="outline" class="mt-12 w-100-p">
          <mat-label>Email</mat-label>
          <mat-icon matPrefix class="s-18 mr-8">email</mat-icon>
          <input matInput>
        </mat-form-field>
        <button mat-raised-button color="primary" class="submit-button btn-rounded my-12" aria-label="Reset Password">
            Reset Password
          </button>
        </form>
      </div> -->

      <div *ngIf="showOtp">
        <div class="font-size-20 text-bold">Enter OTP Number</div>
        
        <form [formGroup]="OTPForm">  
          <mat-form-field appearance="outline" class="mt-12 w-100-p">
            <mat-label>OTP</mat-label>
            <mat-icon matPrefix class="s-18 mr-8">perm_phone_msg</mat-icon>
            <input matInput  type="text" formControlName="user_otp">
          </mat-form-field>

          <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"  *ngIf="showOtp">
              <a class="forgot-password primary-fg cursor-pointer" (click)="resendOtp()"> Resend OTP </a>
          </div>

          <button mat-raised-button color="primary" class="submit-button btn-rounded my-12" aria-label="Reset Password"
            (click)="login()" [disabled]="confirmOtp">Confirm OTP</button>
        </form>
      </div>

    </div>
  </div>
</div>