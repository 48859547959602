import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isEmpty } from 'lodash';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from './custom-validators';
import { CommonService } from 'app/services/common.service';
import { ClientsService } from 'app/services/clients.service';

@Component({
  selector: 'app-active-user-change-password',
  templateUrl: './active-user-change-password.component.html',
  styleUrls: ['./active-user-change-password.component.scss']
})

export class ActiveUserChangePasswordComponent implements OnInit {
   isActive: boolean;
   hide = true;
   hide1 = true;
   hidden =true;
   isClassVisible: true;
  constructor(
    public dialogRef: MatDialogRef<ActiveUserChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private commonService: CommonService,
    private clientService: ClientsService) { }

  ngOnInit() {
  }
  ChangePasswordForm: FormGroup = this.fb.group({
   
    password: [
      null,
      Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        CustomValidators.patternValidator(/\d/, {
          hasNumber: true
        }),
        // check whether the entered password has upper case letter
        CustomValidators.patternValidator(/[A-Z]/, {
          hasCapitalCase: true
        }),
        // check whether the entered password has a lower case letter
        CustomValidators.patternValidator(/[a-z]/, {
          hasSmallCase: true
        }),
        // check whether the entered password has a special character
        CustomValidators.patternValidator(
          /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
          {
            hasSpecialCharacters: true
          }
        ),
        Validators.minLength(8)
      ])
    ],
    password_confirmation: [null,Validators.compose([Validators.required])],
    mark_as_default:[]
    
  },
  {
    // check whether our password and confirm password match
    validator: CustomValidators.passwordMatchValidator
  }
  );

  save() {
    if (this.ChangePasswordForm.valid) {
      let saveData = {};
      saveData['password'] = this.ChangePasswordForm.value.password;
      if(this.ChangePasswordForm.value.mark_as_default) {
        saveData['enforce_user_login'] = 1;
      }
      saveData['id'] = this.data.id;
      saveData['company_id'] = this.data.company_id;
      this.clientService.resetUserPassword(saveData).subscribe(res => {
        if(res['status'] == 1) {
          this.commonService.openSnackBar('primary',res.message);
        }else{
          this.commonService.openSnackBar('warn',res.message);
        }
        this.dialogRef.close(1);
      });
    }
  }
}
