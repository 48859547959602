<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p">
    <ul class="nav m-0 p-0" fxFlex="auto">
      <li><a class="text-bold" (click)="getSignUpRequestData('inprogress')"
          [ngClass]="{'active': getlist == 'inprogress'}">In Pipeline</a>
      </li>
      <li><a class="text-bold" (click)="getSignUpRequestData('pending')"
          [ngClass]="{'active': getlist == 'pending'}">Pending</a>
      </li>
      <li><a class="text-bold" (click)="getSignUpRequestData('completed')"
          [ngClass]="{'active': getlist == 'completed'}">Completed</a></li>
      <li><a class="text-bold" (click)="getSignUpRequestData('rejected')"
          [ngClass]="{'active': getlist == 'rejected'}">Rejected</a>
      </li>
      <!-- <li><a class="text-bold" (click)="showActivities('activities')"
        [ngClass]="{'active': getlist == 'activities'}">Activities</a>
    </li> -->
    </ul>

    <mat-form-field appearance="outline" class="w-160 mr-8">
      <mat-label>Year</mat-label>
      <mat-select [(ngModel)]="year_id" (selectionChange)="yearChanged()">
          <mat-option *ngFor="let item of years" [value]="item.value">
              {{item.viewValue}}
          </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <div fxFlex="280px" fxLayout="row wrap" fxLayoutAlign="end center" > -->
      <mat-form-field appearance="outline" class="w-160 mr-8">
        <mat-label>Country</mat-label>
        <mat-select [(ngModel)]="country_id" (selectionChange)="countryChanged()">
          <mat-option *ngFor="let item of countries" [value]="item.value">
            {{item.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    <!-- </div> -->
  </mat-toolbar-row>
  
  <div class="center px-20" >
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
        <div class="search-wrapper" fxFlex="30">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Name,Email,Contact Number ..">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>
        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div>
            <button mat-flat-button class="primary mr-4" (click)="addSignUp()">Add Signup</button>
            <button mat-stroked-button (click)="exportExcel()" *ngIf="logginUser?.role_name == 'admin'">Export as Excel</button>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">

          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">
              <!-- client Column -->
              <ng-container matColumnDef="client">
                <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.company_name"> <a class='cursor-pointer'
                      (click)="requestDetails(element)">{{element.company_name}}</a></span>
                  <span *ngIf="!element.company_name">-</span>
                </mat-cell>
              </ng-container>
              <!-- name Column -->
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Contact Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.first_name}} {{element.last_name}} </mat-cell>
              </ng-container>

              <!-- email Column -->
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef fxFlex="200px"> Email </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="200px" class="lowercase"> {{element.email}} </mat-cell>
              </ng-container>

              <!-- subdomain Column -->
              <ng-container matColumnDef="subdomain">
                <mat-header-cell *matHeaderCellDef> sub domain </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.sub_domain_name?element.sub_domain_name:'-'}} </mat-cell>
              </ng-container>

              <!-- contactnumber Column -->
              <ng-container matColumnDef="contactnumber">
                <mat-header-cell *matHeaderCellDef> contact number </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.contact_number?element.contact_number:'-'}} </mat-cell>
              </ng-container>

              <!-- date Column -->
              <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef> Signup on </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at|date:"MM/dd/yyyy"}} </mat-cell>
              </ng-container>

              <!-- <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef> role </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.role"> {{element.role}} </span>
                  <span *ngIf="!element.role">-</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="buying_stage">
                <mat-header-cell *matHeaderCellDef> buying stage </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.buying_stage"> {{element.buying_stage}} </span>
                  <span *ngIf="!element.buying_stage">-</span>
                </mat-cell>
              </ng-container> -->

              <ng-container matColumnDef="region">
                <mat-header-cell *matHeaderCellDef> region </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.region != 'usa'">{{element.region | titlecase}}</span>
                  <span *ngIf="element.region == 'usa'">{{element.region | uppercase }}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="location">
                <mat-header-cell *matHeaderCellDef> location </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{locationInfo(element)}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef fxFlex="80px"> status </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="80px"> {{element.status}} </mat-cell>
              </ng-container>

              <!-- action Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef fxFlex="200px" fxLayoutAlign="center center"> Actions
                </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="200px">
                  <button mat-icon-button class="accent-fg" matTooltip="Edit" *ngIf="getlist == 'pending'"
                    (click)="editSignup(element)">
                    <mat-icon class="s-18">edit</mat-icon>
                  </button>

                  <button mat-icon-button class="green-fg" matTooltip="Send Verification Email"
                    *ngIf="getlist == 'pending' || getlist == 'inprogress'" (click)="verifyEmail(element.id)">
                    <mat-icon class="s-18">email</mat-icon>
                  </button>

                  <button mat-icon-button class="green-fg" matTooltip="Send Login Email" *ngIf="getlist == 'approved'"
                    (click)="sendLoginEmail(element.id)">
                    <mat-icon class="s-18">email</mat-icon>
                  </button>

                  <!-- <button mat-icon-button class="teal-fg" matTooltip="Accept"
                    *ngIf="getlist == 'pending' && (element.company_name && element.sub_domain_name)"
                    (click)="createCompany(element.id)">
                    <mat-icon class="s-18">done</mat-icon>
                  </button> -->
                  <!-- <button mat-icon-button class="teal-fg" matTooltip="Accept" *ngIf="getlist == 'approved'"
                    (click)="setCompany(element.id)">
                    <mat-icon class="s-18">done</mat-icon>
                  </button> -->

                  <button mat-icon-button class="warn-fg" matTooltip="Reject" *ngIf="getlist == 'pending' || getlist == 'inprogress'"
                    (click)="rejectPending(element.id)">
                    <mat-icon class="s-18">clear</mat-icon>
                  </button>
                  <button mat-icon-button class="red-fg" matTooltip="Delete" (click)="deleteSignup(element.id)"
                    *ngIf="getlist == 'pending' || getlist == 'rejected' || getlist == 'inprogress'">
                    <mat-icon class="s-18">delete</mat-icon>
                  </button>

                  <!-- <div class="demo-section">
                    <mat-icon matBadge="15" class="teal-fg" matBadgeColor="warn">comments</mat-icon>
                     
                  </div> -->

                  <button matBadge="{{element.comments_cnt}}" matBadgeColor="warn" (click)="viewComment(element)" mat-icon-button  class="teal-fg" matTooltip="Comments"
                    >
                    <mat-icon *ngIf="getlist == 'pending' || getlist == 'rejected' || getlist == 'inprogress'"  (click)="$event.stopPropagation();addComment(element.id)"  class="s-18">comments</mat-icon>
                    <mat-icon *ngIf="!(getlist == 'pending' || getlist == 'rejected' || getlist == 'inprogress')" 
                     class="s-18">comments</mat-icon>

                  </button>

                  <!-- <button matBadge="15" matBadgeColor="warn" (click)="viewComment(element.id)" mat-icon-button  class="teal-fg" matTooltip="Add Comments"
                    *ngIf="getlist == 'pending' || getlist == 'rejected' || getlist == 'inprogress'">
                    <mat-icon  (click)="addComment(element.id)"  class="s-18">comments</mat-icon>
                  </button> -->
                  

                  <!-- <button mat-raised-button color="primary"
    matBadge="8" matBadgePosition="before" matBadgeColor="accent">
    <mat-icon class="s-18">comments</mat-icon>
</button> -->


                  <!-- <span *ngIf="getlist == 'completed'">-</span> -->
                  <!-- <button mat-icon-button class="accent-fg" matTooltip="Activate Mail"
                    (click)="completedEmail(element.id)" *ngIf="getlist == 'completed'">
                    <mat-icon class="s-18">email</mat-icon>
                  </button> -->
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class='footer'>
  <button mat-button (click)="showActivities()">Activities</button>
</div>