<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold active">Bounced Search</a></li>
    </ul>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-56">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12">
        <div fxFlex="100">
          <form [formGroup]="bouncedsearchmails">

            <mat-form-field appearance="outline" class="w-200 mr-8">
              <mat-label>Email</mat-label>
              <input type="email" formControlName="email" matInput>
            </mat-form-field>

            <mat-form-field class="w-200 mr-8" appearance="outline">
              <mat-label>Select Company</mat-label>
              <mat-select appMatSearchSelector formControlName="company_ids" multiple
                (selectionChange)="companyselector()">
                <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                <ng-container *ngFor="let cmpny of companies">
                  <mat-option *appMatSearchSelectorItems [value]="cmpny.id">{{cmpny.mailgun_domain}}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>

            <button mat-raised-button class="mat-accent mr-16" (click)="bouncedsearch()">Search</button>
          </form>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="bouncedSearchData">
          <div class="px-12 pb-12">
            <ng-container *ngFor="let item of bouncedSearchData | keyvalue">
              <div>{{item.key}}:{{item.value}}</div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>