import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-mx-records',
  templateUrl: './mx-records.component.html',
  styleUrls: ['./mx-records.component.scss']
})
export class MxRecordsComponent implements OnInit {

  MxRecordsFrom: FormGroup;
  id: any;
  domainData: any = false;
  domainDisable = false;
  disableStyle = "";
  inboundDnsRecords: any;
  outboundDnsRecords: any;
  displayedColumns: string[] = ['type', 'host_name', 'enter_value'];
  displayedColumns1: string[] = ['type', 'priority', 'enter_value'];
  dataSource;
  dataSource1;
  any_domain: string = '';
  showUpdate: boolean = false;
  DNS_status: boolean = false;
  subsidaryList:any = [];
  isLostAccount:any = false;

  constructor(private fb: FormBuilder,
    private commonservice: CommonService,
    private companyDetailService: CompanyDetailsService,
    private route: ActivatedRoute,
    public dialog: MatDialog) {
    this.id = this.route.parent.snapshot.params['id'];
    this.MxRecordsFrom = this.fb.group({
      domain: ['', Validators.required],
      subsidary_id:[1],
      from_email: ['', Validators.email],
    })
  }

  ngOnInit() {
    this.getDomainDetails();
  }

  getDomainDetails() {
    let params = {};
    params['company_id'] = Number(this.id);
    params['subsidary_id'] = this.MxRecordsFrom.value['subsidary_id'];
    params['from_email'] = this.MxRecordsFrom.value['from_email'];
    this.companyDetailService.getDomainDetails(params).subscribe(res => {
      // console.log('res', res);return;
      if (res.status && res.data) {
        this.DomainResponse(res);
      } else {
        this.MxRecordsFrom.get('domain').setValue(null);
        this.domainData = false;
        this.domainDisable = false;
        this.disableStyle = '';
        this.dataSource = '';
        this.dataSource1 = '';
        this.any_domain = '';
      }
      this.isLostAccount = res.data.isAccountLost;
    })
  }

  DomainResponse(res) {
    this.domainDisable = true;
    this.inboundDnsRecords = res.data.inboundDnsRecords;
    this.outboundDnsRecords = res.data.outboundDnsRecords;
    this.disableStyle = "cursor:no-drop";
    this.any_domain = res.data.domain.name;
    this.MxRecordsFrom.get('domain').setValue(res.data.domain.name);
    this.MxRecordsFrom.get('from_email').setValue(res.data.from_email);
    // this.MxRecordsFrom.controls['domain'].disable();
    this.domainData = res.data.domain;
    this.dataSource = new MatTableDataSource(this.outboundDnsRecords);
    this.dataSource1 = new MatTableDataSource(this.inboundDnsRecords);
    this.subsidaryList = res.data.subsidaries_list;
    this.checkDNSRecordStatus(this.inboundDnsRecords, this.outboundDnsRecords);
  }

  checkDNSRecordStatus(inboundDnsRecords, outboundDnsRecords) {
    let checkInbouns: boolean;
    let checkOutbouns: boolean;
    checkInbouns = true;
    checkOutbouns = true;
    inboundDnsRecords.forEach(element => {
      if (element.valid != 'valid') {
        checkInbouns = false;
      }
    });
    outboundDnsRecords.forEach(element => {
      if (element.valid != 'valid') {
        checkOutbouns = false;
      }
    });

    if (!checkInbouns && !checkOutbouns) {
      this.DNS_status = false;
    } else {
      this.DNS_status = true;
    }

  }

  save() {
    if (this.MxRecordsFrom.valid) {
      let params = {};
      params['domain'] = this.MxRecordsFrom.value.domain;
      params['company_id'] = Number(this.id);
      params['is_cpanel'] = 1;
      params['subsidary_id'] = this.MxRecordsFrom.value['subsidary_id'];
      params['from_email'] = this.MxRecordsFrom.value['from_email'];
      this.companyDetailService.addDomain(params).subscribe(res => {
        if (res.status) {
          this.commonservice.openSnackBar('primary', 'Domain added successfully, Please verify the doamin');
          this.getDomainDetails();
        } else {
          this.commonservice.openSnackBar('warn', res.message);
        }
      });
    }
  }

  verifyDoamin() {
    let params = {};
    params['company_id'] = Number(this.id);
    params['is_cpanel'] = 1;
    params['subsidary_id'] = this.MxRecordsFrom.value['subsidary_id'];
    params['from_email'] = this.MxRecordsFrom.value['from_email'];
    this.companyDetailService.verifyDomain(params).subscribe(res => {
      if (res.status) {
        if (res.data.domain.state == 'active') {
          this.commonservice.openSnackBar('primary', 'Domain verified successfully');
          this.DomainResponse(res);
        } else {
          this.commonservice.openSnackBar('warn', 'Please update DNS records');
          this.DomainResponse(res);
        }
      } else {
        this.commonservice.openSnackBar('warn', res.message);
      }
    });
  }

  deleteDoamin() {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to delete this Domain';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    let params = {};
    params['company_id'] = Number(this.id);
    params['is_cpanel'] = 1;
    params['subsidary_id'] = this.MxRecordsFrom.value['subsidary_id'];
    params['from_email'] = this.MxRecordsFrom.value['from_email'];
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.companyDetailService.deleteDomain(params).subscribe(res => {
          if (res.status) {
            this.commonservice.openSnackBar('primary', 'Domain deleted successfully');
            this.MxRecordsFrom.get('domain').setValue(null);
            this.domainData = false;
            this.domainDisable = false;
            this.disableStyle = '';
            this.dataSource = '';
            this.dataSource1 = '';
            this.any_domain = '';
            this.getDomainDetails();
          }
        });
      } else { }
    })
  }

  domainChange() {
    if (this.any_domain) {
      if (this.any_domain != this.MxRecordsFrom.value.domain) {
        this.showUpdate = true;
      } else {
        this.showUpdate = false;
      }
    } else {
      this.showUpdate = false;
    }
  }

  udpateDoamin() {
    if (this.MxRecordsFrom.valid) {
      let msg;
      msg = 'if you update the domain, the previous domain (' + this.domainData.name + ') will be updated with the given domain (' + this.MxRecordsFrom.value.domain + ')';
      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? ' + msg;
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35%',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.save();
        } else { }
      })
    }
  }

  forceAddDomain() {
    if (this.MxRecordsFrom.valid) {
      let msg;
      msg = 'you want to update domain';
      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? ' + msg;
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35%',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.forceSave();
        } else { }
      })
    }
  }

  forceSave() {
    if (this.MxRecordsFrom.valid) {
      let params = {};
      params['domain'] = this.MxRecordsFrom.value.domain;
      params['company_id'] = Number(this.id);
      params['is_cpanel'] = 1;
      params['subsidary_id'] = this.MxRecordsFrom.value['subsidary_id'];
      params['from_email'] = this.MxRecordsFrom.value['from_email'];
      this.companyDetailService.mailDomainForceAdd(params).subscribe(res => {
        if (res.status) {
          this.commonservice.openSnackBar('primary', 'Domain updated successfully');
          this.getDomainDetails();
          this.showUpdate = false;
        } else {
          this.commonservice.openSnackBar('warn', res.message);
        }
      });
    }
  }

  showActivities() {
    let activityData ={
      'company_id':this.id,
      'module_slug':'mxRecords',
      'show_company_name':false,
      'module_name':'MX Records'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
