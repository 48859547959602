import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/services/common.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { ConfirmExitRequestApproveComponent } from '../confirm-exit-request-approve/confirm-exit-request-approve.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-search-credit-dialog',
  templateUrl: './search-credit-dialog.component.html',
  styleUrls: ['./search-credit-dialog.component.scss']
})

export class SearchCreditDialogComponent implements OnInit {

  options_list: any;
  addCredits: FormGroup;
  selected_opt: any;
  opt_cost: any;
  calCost: any;
  show_title: any;
  saveData: any;
  subscription_type: any;
  // masterAddons:string[] = ['chatbot','digital_onboarding_templates','job_grabber','quick_books','report_builder','resume_harvesting','resume_job_indox','video_interviews'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<SearchCreditDialogComponent>,
    public dialog: MatDialog
  ) {
    this.addCredits = this.fb.group({
      'selected_pack': ['', Validators.required],
      'selected_qty': ['', Validators.required],
      // 'total_cost': new FormControl({ value: '', disabled: true }),
      // 'charge_type': ['']
    });

    this.subscription_type = this.data.company.subscription_type;
  }

  ngOnInit() {
    this.options_list = this.data.options_list;
    this.show_title = this.data.credit_title;
    // console.log(this.data);
    // if(this.masterAddons.includes(this.data.addonSlug)) {
    //   this.addCredits.get('selected_qty').setValue(1);
    // }
  }

  // calAmount() {
  //   if (this.addCredits.value.selected_pack && this.addCredits.value.selected_qty) {
  //     this.selected_opt = this.addCredits.value.selected_pack;
  //     this.getPackCost();
  //     if (this.calCost) {
  //       if(this.masterAddons.includes(this.data.addonSlug)){
  //         this.opt_cost = (this.addCredits.value.selected_pack * this.calCost).toFixed(2);
  //       } else{
  //         this.opt_cost = (this.addCredits.value.selected_qty * this.calCost).toFixed(2);
  //       }
  //     } else {
  //       this.opt_cost = 0;
  //     }
  //   } else {
  //     this.opt_cost = 0;
  //   }
  // }

  // getPackCost() {
  //   console.log(this.masterAddons.includes(this.data.addonSlug));
  //   if(this.masterAddons.includes(this.data.addonSlug)){
  //     this.calCost = this.options_list.cost;
  //   } else {
  //      this.options_list.forEach(element => {
  //       if (element.quantity == this.selected_opt) {
  //         this.calCost = element.cost;
  //       }
  //     });
  //   }
  // }

  saveCredits() {
    if (this.addCredits.valid) {
      let start_date: any;
      let end_date: any;
      start_date = moment().format("YYYY-MM-DD");
      if (this.data.addon_slug == 'i9_forms') {
        end_date = moment().add(1, 'year').format('YYYY-MM-DD');
      } else{
        end_date = moment().add(30, 'days').format('YYYY-MM-DD');
      }

      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure?';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
        width: '35%',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.approved) {
          // let defaultCharge: any = 0;
          // if (this.addCredits.value.charge_type) {
          //   defaultCharge = this.addCredits.value.charge_type;
          // }
          let credits_data: any;
          credits_data = {
            // 'charge_type': defaultCharge,
            // 'cost': this.calCost,
            // 'is_subscribed': 0,
            'packs': this.addCredits.value.selected_qty,
            'per_pack': this.addCredits.value.selected_pack,
            'slug': this.data.slug,
            // 'quantity': this.addCredits.value.selected_pack * this.addCredits.value.selected_qty,
            // 'start_date': start_date,
            // 'end_date': end_date,
          };
          this.saveData = {
            'company_id': this.data.company_id,
            'subscription_id': this.data.subscription_id,
            'company': this.data.company,
            'data': credits_data,
            'addon_slug': this.data.addonSlug,
            'addon_operation_id': 0,
            'is_unlimited': 0,
            'company_name':this.data.company_name,
            'plan_slug': this.data.plan_slug,
            'comments': result.commets,
            'price_type':this.data.price_type
          };
          // this.saveData['addon_data'] = {};
          // this.saveData['addon_data'][this.data.addonSlug] = credits_data;
          
          // console.log(this.saveData);return;
          this.companyDetailService.saveAddonsAsFree(this.saveData).subscribe(res => {
            if (res.status == 1) {
              this.commonService.openSnackBar('primary', res.data.message);
            } else {
              this.commonService.openSnackBar('warn', res.data.message);
            }
            this.dialogRef.close(1);
          });
        } else { }
      })
    }
  }

}
