import { Component, OnInit } from '@angular/core';

import { CommonService } from 'app/services/common.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as _moment from 'moment';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-bounced-search',
  templateUrl: './bounced-search.component.html',
  styleUrls: ['./bounced-search.component.scss']
})
export class BouncedSearchComponent implements OnInit {
  companies = [];
  bouncedsearchmails: FormGroup;
  selectedCompanyIds = [];
  bouncedSearchData = {};
  constructor(
    private fb: FormBuilder,
    private commonservice: CommonService,
    private configService: ConfigurationService
  ) {
    this.bouncedsearchmails = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      company_ids: [''],
    });
  }

  ngOnInit() {
    this.companydata();
  }
  bouncedsearch() {
    if (this.bouncedsearchmails.valid) {
      let params = {};
      params['email'] = this.bouncedsearchmails.value.email;
      params['search_company'] = this.selectedCompanyIds ? this.selectedCompanyIds : '';
      this.configService.getbouncedsearchList(params).subscribe(res => {
        if (res.status == 1) {
          this.bouncedSearchData = res.data;
        } else {
          this.commonservice.openSnackBar('warn', res.message);
        }
      });
    }
  }
  companydata() {
    this.configService.getCompanyListData().subscribe(res => {
      if (res.status === 1) {
        this.companies = res.data;
      }
    });
  }
  companyselector() {
    this.selectedCompanyIds = this.bouncedsearchmails.value.company_ids ? this.bouncedsearchmails.value.company_ids.map(x => x).join(",") : '';
  }

}