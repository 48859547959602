<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold active">Failed Jobs</a></li>
    </ul>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-52">

      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div>
            <form [formGroup]="queueFilterForm">
              <mat-form-field appearance="outline" class="w-200 mr-8">
                <mat-label>Queue Filter</mat-label>
                <mat-select formControlName="queue_filter" (selectionChange)="appTypeChange()">
                  <mat-option *ngFor="let val of show_filter_values" [value]="val.value">
                    {{val.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="w-200" appearance="outline">
                <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="date_filter"
                  (dateChange)="onDateChange()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <button mat-raised-button class="mat-accent mr-16" (click)="searchFilter()">Search</button>
            </form>

          </div>
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <div fxFlex="45">
          <ng-container *ngIf="dataSource">
            <div class="px-12 pb-12">
              <mat-table [dataSource]="dataSource">

                <ng-container matColumnDef="select">
                  <mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event? masterToggle():null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                    </mat-checkbox>
                    <button mat-icon-button class="red-fg" matTooltip="Delete"
                      [disabled]="this.selection.selected.length==0" (click)="bulkDeleteQueues()">
                      <mat-icon class="s-18">delete</mat-icon>
                    </button>
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event? selection.toggle(row):null"
                      [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                    </mat-checkbox>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="id">
                  <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
                  <mat-cell *matCellDef="let element"> <a class='cursor-pointer'
                      (click)='showException(element.exception)'>{{element.id}} </a></mat-cell>
                </ng-container>

                <ng-container matColumnDef="queue">
                  <mat-header-cell *matHeaderCellDef>queue </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="lowercase"> {{element.queue}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="failed_at">
                  <mat-header-cell *matHeaderCellDef>Failed At </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="lowercase"> {{element.failed_at}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="runJob(element.id)" class="mr-4">
                      <mat-icon class="s-18">refresh</mat-icon>
                    </button>
                    <button mat-icon-button class="red-fg" matTooltip="Delete" (click)="DeleteQueue(element.id)">
                      <mat-icon class="s-18">delete</mat-icon>
                    </button>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>

              <div *ngIf="dataSource.data.length == 0" class="text-center">
                <h2 class="sub-heading">No Records Found</h2>
              </div>
            </div>
          </ng-container>
        </div>
        <div fxFlex="45">
          <mat-card class="p-0">
            <mat-card-content class="p-0">
              <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
                <pre>{{exception_msg }}
              </pre>
              </div>
            </mat-card-content>
          </mat-card>

        </div>

      </div>

    </div>
  </div>
</div>