<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">Client Subscriptions</h2>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row wrap' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">

        <div class="search-wrapper" fxFlex="30">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [(ngModel)]="searchValue" placeholder="Company Name">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>

        <div fxFlex="70" class="actions_list" fxLayout="row wrap" fxLayoutAlign="end center">
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-12">

            <button mat-flat-button class="primary mr-4" (click)="exportExcel()">Export</button>
            
            <mat-form-field appearance="outline" class="w-150 mr-8">
              <mat-label>Country</mat-label>
              <mat-select [(ngModel)]="country_id" (selectionChange)="countryChanged()">
                  <mat-option *ngFor="let item of countries" [value]="item.value">
                      {{item.viewValue}}
                  </mat-option>
              </mat-select>
            </mat-form-field>
            
            <form [formGroup]="clientSubscriptionForm">
              <mat-form-field appearance="outline" class="w-150 mr-8">
                <mat-label>Renewal Status</mat-label>
                  <mat-select formControlName="status_type" (selectionChange)="appTypeChange()">
                    <mat-option *ngFor="let cust of status_list" [value]="cust.value">
                      {{cust.viewValue}}
                    </mat-option>
                  </mat-select>
              </mat-form-field>

              <mat-form-field class="w-200 mr-8" appearance="outline">
                <mat-label>Select Company</mat-label>
                <mat-select appMatSearchSelector formControlName="client_ids" multiple (selectionChange)="clientChange()">
                  <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                  <ng-container *ngFor="let topping of toppingList">
                    <mat-option *appMatSearchSelectorItems [value]="topping.id">{{topping.display_name}}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>

              <!-- <mat-checkbox color="primary" class="font-size-12 px-12" formControlName="monthWiseSelection">MonthWise
              </mat-checkbox> -->

              <!-- <mat-form-field class="w-200" appearance="outline" *ngIf="!clientSubscriptionForm.get('monthWiseSelection').value">
                <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="selected_date" (dateChange)="onDateChange()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field> -->

              <!-- <mat-form-field class="w-200" appearance="outline" *ngIf="clientSubscriptionForm.get('monthWiseSelection').value"> -->
              <mat-form-field class="w-200" appearance="outline" >
                <input matInput [matDatepicker]="dp" placeholder="Month and Year" formControlName="Year_Month">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                  (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                </mat-datepicker>
              </mat-form-field>

            </form>

          </div>
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> <a class='cursor-pointer'
                  (click)='navigate(element)'>{{element.company_name}} </a></mat-cell>
              </ng-container>

              <ng-container matColumnDef="start_date">
                <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.start_date | date:"MM/dd/yyyy"}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="end_date">
                <mat-header-cell *matHeaderCellDef> End Date </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.end_date | date:"MM/dd/yyyy"}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="renewal_date">
                <mat-header-cell *matHeaderCellDef>Next Renewal Date </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{renewalDate(element)}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="subsription_cycle">
                <mat-header-cell *matHeaderCellDef> Billinng Cycle </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{subCycle(element.billing_cycle)}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{getCurrencySymbol(country_id)}} {{element.amount}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Remaining Days to renewal </mat-header-cell>
                <mat-cell *matCellDef="let element"> 
                 <span *ngIf = "element.is_active == 0">Renewed</span> 
                 <span *ngIf = "element.is_active == 1">{{renewalStatus(element, 'status')}}</span>
                 </mat-cell>
              </ng-container>

              <!-- Sales Rep Column -->
              <ng-container matColumnDef="sales_user_name">
                <mat-header-cell *matHeaderCellDef > Sales Rep </mat-header-cell>
                <mat-cell *matCellDef="let element" > {{element.sales_user_id ? cpanelusers[element.sales_user_id] : '-' }} </mat-cell>
              </ng-container>

              <!-- Support Executive Column -->
              <ng-container matColumnDef="support_user_name">
                <mat-header-cell *matHeaderCellDef > Support Executive </mat-header-cell>
                <mat-cell *matCellDef="let element" > {{element.support_user_id ? cpanelusers[element.support_user_id] : '-' }} </mat-cell>
              </ng-container>

              <!-- <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.is_active == 0">-</span>
                  <span *ngIf="element.is_active == 1 && renewalStatus(element, 'action') > 0 ">-</span>
                  <span *ngIf="element.is_active == 1 && renewalStatus(element) == 'Due' ">
                    <button mat-flat-button class="primary mr-4" (click)="renewCompany(element)">Renew</button>
                  </span>
                </mat-cell>
              </ng-container> -->

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>