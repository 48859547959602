<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">Implementation</h2>
  </mat-toolbar-row>
  <div class="center px-20">
    <mat-toolbar-row class="secondary-headers w-100-p px-20">
      <ul class="nav m-0 p-0" fxFlex="auto">
        <li><a class="text-bold" (click)="getViewList('in_progress')"
            [ngClass]="{'active': viewList == 'in_progress'}">List</a>
        </li>
        <li><a class="text-bold" (click)="getViewList('washed_away')"
            [ngClass]="{'active': viewList == 'washed_away'}">Washed Away</a>
        </li>
      </ul>
    </mat-toolbar-row>
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">

        <div class="search-wrapper" fxFlex="30" fxFlex.lt-xl="20">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [(ngModel)]="searchValue" placeholder="Customer">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>

        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-flat-button class="primary mr-4" (click)="exportExcel()">Export</button>
          </div>
        </div>

        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="customer_name">
                <mat-header-cell *matHeaderCellDef> Customer </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a class='cursor-pointer' (click)='navigate(element)'>{{element.name}} </a>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="intiated_by">
                <mat-header-cell *matHeaderCellDef> Initiated By </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.created}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="intiated_on">
                <mat-header-cell *matHeaderCellDef> Initiated On </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at | date:'MM/dd/yyyy'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="poc">
                <mat-header-cell *matHeaderCellDef> Support POC </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.first_name?element.first_name:'-'}}
                  {{element.last_name?element.last_name:'-'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="subtype">
                <mat-header-cell *matHeaderCellDef> Subscription Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.subscription_type}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef> Total Activities </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.totlaActivities}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="completed">
                <mat-header-cell *matHeaderCellDef> Activities Completed </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.completedCunt}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="pending">
                <mat-header-cell *matHeaderCellDef> Activities Pending </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.inProgressCunt}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="percentage">
                <mat-header-cell *matHeaderCellDef> Completed Percentage </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.completedPercentage}}% </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>