export const environment = {
    production: false,
    dev       : true,
    hmr       : false,
    apiUrl: 'https://api.beanhiredev.com/api/v2/cpanel/',
    firebaseConfig: {
        apiKey: "AIzaSyAQQfKggyEu9jkkaGys80wf2b9-msLo1PM",
        authDomain: "cpaneldev-d7b19.firebaseapp.com",
        databaseURL: "https://cpaneldev-d7b19.firebaseio.com",
        projectId: "cpaneldev-d7b19",
        storageBucket: "cpaneldev-d7b19.appspot.com",
        messagingSenderId: "962993663118",
        appId: "1:962993663118:web:28cd91645c7f962167addf",
        measurementId: "G-8V0YF83ET9"
    },
    docUrl: 'http://app.beanhiredev.com/'
};
