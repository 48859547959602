<div class="page-layout carded fullwidth inner-scroll">
    <mat-toolbar-row class="secondary-headers w-100-p py-4">
        <ul class="nav m-0 p-0" fxFlex="auto">
            <li class="text-bold active">Client Payments</li>
        </ul>
        <div class="mr-12">
            <!-- 23/2/2020 - 24/2/2020 -->
        </div>
        <div fxFlex="150px" fxLayout="row wrap" fxLayoutAlign="end center" class="mr-12">
            <mat-form-field class="w-100-p country-p" appearance='outline'>
                <mat-label>Country</mat-label>
                <mat-select [(ngModel)]="country_id" (selectionChange)="countryChanged()">
                    <mat-option *ngFor="let item of countries" [value]="item.value">
                        {{item.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlex="150px" fxLayout="row wrap" fxLayoutAlign="end center" class="mr-12">
            <mat-form-field class="w-100-p country-p" appearance='outline'>
                <mat-label>Invoice Status</mat-label>
                <mat-select [(ngModel)]="invoice_status" (selectionChange)="invoiceStatusChanged()">
                    <mat-option *ngFor="let item of invoiceStatus" [value]="item.value">
                        {{item.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlex="300px" fxLayout="row wrap" fxLayoutAlign="end center" [formGroup]="dateRangeForm">
            <mat-form-field appearance="outline" class="w-252 mr-20">
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date" formControlName="start_date">
                    <input matEndDate placeholder="End date" formControlName="end_date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </mat-toolbar-row>

    <div class="center px-20">
        <div class="content-card mt-12 mb-52">
            <div class="px-12">
                <div fxLayout='row' fxLayoutAlign="start center" *ngIf="revenueInfo">
                    <div>
                        <div class=""> Current Month Statistics</div>
                        <div class="mt-4 text-bold">({{monthStart | date:"MM/dd/yyyy"}} -
                            {{today | date:"MM/dd/yyyy" }})</div>
                    </div>
                    <div class="pl-12">
                        <div fxLayout='row' fxLayoutAlign="start center" fxLayoutGap="10px" class="py-12">

                            <div class="border-success px-12 py-8">New Customers:
                                {{revenueInfo.clientStats.upgrade_customers}}</div>
                            <div class="border-red px-12 py-8">Renewed Customers:
                                {{revenueInfo.clientStats.renewal_customers}}</div>
                            <div class="border-blue px-12 py-8">Overdue Customers:
                                {{revenueInfo.clientStats.due_customers}}</div>
                            <!-- <div class="border-orange px-12 py-8">Upcoming Renew Customers:
                  {{revenueInfo.clientStats.upcoming_renew_customers}}</div> -->
                        </div>
                        <div fxLayout='row' fxLayoutAlign="start center" class="" *ngIf="revenueInfo">
                            <div class="border px-12 py-8" fxLayout='row' fxLayoutGap="20px">
                                <div class="primary-fg">Received Amount:
                                    {{getCurrencySymbol()}}{{revenueInfo.paymentStats.received_amount}}
                                </div>
                                <div class="warn-fg">Due Amount:
                                    {{getCurrencySymbol()}}{{revenueInfo.paymentStats.due_amount}}</div>
                                <div class="deep-purple-fg">Refunded
                                    Amount: {{getCurrencySymbol()}}{{revenueInfo.paymentStats.refunded_amount}}</div>
                                <!-- <div class="orange-fg">Expected Amount: {{getCurrencySymbol()}}{{getTableData.paymentStats.expected_amount}}
                  </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <hr>

                <div class="" *ngIf="dataSource" fxLayout='row' fxLayoutAlign="space-between center">

                    <div class="search-wrapper" fxFlex="220px" fxLayoutAlign="start center">
                        <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
                            [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Customer">
                        <mat-icon class="search_icon">search</mat-icon>
                        <mat-icon class="close s-20 danger-fg" *ngIf="searchValue"
                            (click)="searchValue=''; applyFilter('');">
                            close
                        </mat-icon>
                    </div>
                    <div fxFlex class="actions_list" fxLayout="row" fxLayoutAlign="end start">
                        <div fxLayout="row" fxLayoutAlign="start center" class="mt-4">
                            <button mat-icon-button class="accent-fg" matTooltip="Export Data" (click)="exportExcel()">
                                <mat-icon class="s-24">screen_share</mat-icon>
                            </button>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center">
                            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length"
                                (page)="pageEvent($event)" [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content" fusePerfectScrollbar>
                <ng-container *ngIf="dataSource">

                    <div class="px-12 pb-12">
                        <mat-table [dataSource]="dataSource" class="table display-table">

                            <ng-container matColumnDef="customer">
                                <mat-header-cell *matHeaderCellDef class=""> Customer </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="">
                                    <span *ngIf="element.company_info.name" [matTooltip]="element.company_info.name"> <a
                                            class='cursor-pointer'
                                            (click)="companyData(element)">{{element.company_info.name}}</a></span>
                                    <span *ngIf="!element.company_info.name">-</span>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="subscription">
                                <mat-header-cell *matHeaderCellDef> Subscription
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    {{planType(element.company_info.subscription_plan)}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="cycle">
                                <mat-header-cell *matHeaderCellDef> Cycle </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase">
                                    {{subCycle(element.company_info.subscription_cycle)}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="start_date">
                                <mat-header-cell *matHeaderCellDef> Start Date
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase">
                                    {{element.company_info.subscr_start_date | date:"MM/dd/yyyy"}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="end_date">
                                <mat-header-cell *matHeaderCellDef> End Date
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase">
                                    {{element.company_info.end_date | date:"MM/dd/yyyy"}} </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="hire_data">
                                <mat-header-cell *matHeaderCellDef class="text-nowrap"> Hire Credits </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase text-nowrap">
                                    <div fxLayout='row'>
                                        <div *ngIf="element.hire_users.paid || element.hire_users.free">L:</div>
                                        <div *ngIf="element.hire_users.paid">
                                            {{element.hire_users.paid}} -
                                            {{getCurrencySymbol()}}{{element.hire_users.amount | number:'1.2-2'}}
                                        </div>
                                        <div *ngIf="element.hire_users.free"> &nbsp;| Free- {{element.hire_users.free}}
                                        </div>
                                    </div>
                                    <div fxLayout='row'>
                                        <div *ngIf="element.mass_mails_credits.paid || element.mass_mails_credits.free">
                                            MM:
                                        </div>
                                        <div *ngIf="element.mass_mails_credits.paid">
                                            {{element.mass_mails_credits.paid}} -
                                            {{getCurrencySymbol()}}{{element.mass_mails_credits.amount}}
                                        </div>
                                        <div *ngIf="element.mass_mails_credits.free">&nbsp;| Free -
                                            {{element.mass_mails_credits.free}}
                                        </div>
                                    </div>
                                    <div fxLayout='row'>
                                        <div
                                            *ngIf="element.contacts_search_credits.paid || element.contacts_search_credits.free">
                                            CS:
                                        </div>
                                        <div *ngIf="element.contacts_search_credits.paid">
                                            {{element.contacts_search_credits.paid}} -
                                            {{getCurrencySymbol()}}{{element.contacts_search_credits.amount}}
                                        </div>
                                        <div *ngIf="element.contacts_search_credits.free"> &nbsp;| Free -
                                            {{element.contacts_search_credits.free}} </div>
                                    </div>
                                    <div fxLayout='row'>
                                        <div *ngIf="element.linkedin_credits.paid || element.linkedin_credits.free">LC:
                                        </div>
                                        <div *ngIf="element.linkedin_credits.paid">
                                            {{element.linkedin_credits.paid}} -
                                            {{getCurrencySymbol()}}{{element.linkedin_credits.amount}}
                                        </div>
                                        <div *ngIf="element.linkedin_credits.free">&nbsp;| Free -
                                            {{element.linkedin_credits.free}}
                                        </div>
                                    </div>
                                    <div fxLayout='row'>
                                        <div *ngIf="element.sms_credits.paid || element.sms_credits.free">SMS:
                                        </div>
                                        <div *ngIf="element.sms_credits.paid">
                                            {{element.sms_credits.paid}} -
                                            {{getCurrencySymbol()}}{{element.sms_credits.amount}}
                                        </div>
                                        <div *ngIf="element.sms_credits.free">&nbsp;| Free -
                                            {{element.linkedin_credits.free}}
                                        </div>
                                    </div>
                                    <div fxLayout='row'>
                                        <div *ngIf="element.oorwin_branding.amount">
                                            Branding - {{getCurrencySymbol()}}{{element.oorwin_branding.amount}}
                                        </div>
                                    </div>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="hr_data">
                                <mat-header-cell *matHeaderCellDef> HR Credits
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase">
                                    <div fxLayout='row'>
                                        <div *ngIf="element.hr_users.paid || element.hr_users.free">L:</div>
                                        <div *ngIf="element.hr_users.paid">
                                            {{element.hr_users.paid}} -
                                            {{getCurrencySymbol()}}{{element.hr_users.amount}}
                                        </div>
                                        <div *ngIf="element.hr_users.free">&nbsp; | Free - {{element.hr_users.free}}
                                        </div>
                                    </div>
                                    <div fxLayout='row'>
                                        <div
                                            *ngIf="(element.i9_forms.paid || element.i9_forms.free) && (element.hr_users.paid || element.hr_users.free) && country_id == 1">
                                            I-9:</div>
                                        <div
                                            *ngIf="(element.hr_users.paid || element.hr_users.free) && element.i9_forms.paid && country_id == 1">
                                            {{element.i9_forms.paid}} -
                                            {{getCurrencySymbol()}}{{element.i9_forms.amount}}
                                        </div>
                                        <div
                                            *ngIf="(element.hr_users.paid || element.hr_users.free) && element.i9_forms.free && country_id == 1">
                                            &nbsp;| Free - {{element.i9_forms.free}}</div>
                                    </div>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="crm_data">
                                <mat-header-cell *matHeaderCellDef> CRM Credits
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase">

                                    <ng-container *ngIf="element.crm_users.paid || element.crm_users.free">L:
                                    </ng-container>
                                    <ng-container *ngIf="element.crm_users.paid">
                                        {{element.crm_users.paid}} - {{getCurrencySymbol()}}{{element.crm_users.amount}}
                                    </ng-container>
                                    <ng-container *ngIf="element.crm_users.free">&nbsp;| Free -
                                        {{element.crm_users.free}}</ng-container>
                                </mat-cell>
                            </ng-container>

                            <!-- <ng-container matColumnDef="other_data">
                <mat-header-cell *matHeaderCellDef > Others
                </mat-header-cell>
                <mat-cell *matCellDef="let element"  class="lowercase">
                  <ng-container *ngIf="element.oorwin_branding.amount">
                    Branding - {{getCurrencySymbol()}}{{element.oorwin_branding.amount}}
                  </ng-container>
                  <ng-container *ngIf="!element.oorwin_branding.amount">-</ng-container>
                </mat-cell>
              </ng-container> -->

                            <ng-container matColumnDef="cost">
                                <mat-header-cell *matHeaderCellDef> Cost
                                    ({{getCurrencySymbol()}}) </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase"
                                    matTooltip="Cost not includes the removed unused user licenses amount">
                                    {{element.payment_info.total_paid}}
                                    <ng-container *ngIf="country_id == 2 && element.payment_info.tax">
                                        <br> GST (+) {{element.payment_info.tax}}<br>
                                        TDS (-) {{element.payment_info.tds}}
                                    </ng-container>
                                </mat-cell>
                            </ng-container>

                            <!-- <ng-container matColumnDef="wallet">
                <mat-header-cell *matHeaderCellDef > Wallet
                  ({{getCurrencySymbol()}}) </mat-header-cell>
                <mat-cell *matCellDef="let element"  class="lowercase">
                  0
                </mat-cell>
              </ng-container> -->

                            <ng-container matColumnDef="refund">
                                <mat-header-cell *matHeaderCellDef> Refund
                                    ({{getCurrencySymbol()}}) </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase">
                                    <ng-container *ngIf="element.payment_info.refund">
                                        {{element.payment_info.total_refund |number:'1.2-2'}}
                                    </ng-container>
                                    <ng-container *ngIf="!element.payment_info.refund">0</ng-container>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="sub_total">
                                <mat-header-cell *matHeaderCellDef> Sub Total
                                    ({{getCurrencySymbol()}}) </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase">
                                    <ng-container *ngIf="element.payment_info.refund">
                                        {{(element.payment_info.total_paid - element.payment_info.refund) |
                                        number:'1.2-2' }}
                                    </ng-container>
                                    <ng-container *ngIf="!element.payment_info.refund">
                                        {{element.payment_info.total_paid | number:'1.2-2'}}
                                    </ng-container>
                                </mat-cell>
                            </ng-container>

                            <!-- <ng-container matColumnDef="invoice_status">
                <mat-header-cell *matHeaderCellDef> Status
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase">
                  {{getPaymentStatus(element.company_info.subscr_end_date)}}</mat-cell>
              </ng-container> -->

                            <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>

                        <div *ngIf="dataSource.data.length == 0" class="text-center">
                            <h2 class="sub-heading">No Records Found</h2>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>