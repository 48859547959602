import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { isEmpty } from 'lodash';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import _ from 'lodash';
import { ClientsService } from 'app/services/clients.service';

@Component({
  selector: 'app-edit-fields',
  templateUrl: './edit-fields.component.html',
  styleUrls: ['./edit-fields.component.scss']
})
export class EditFieldsComponent implements OnInit {
  editform: FormGroup;
  userslist: any;
  adminusers: any;
  supportusers: any;
  salesusers: any;
  clientdata: any;

  constructor(public dialogRef: MatDialogRef<EditFieldsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private commonService: CommonService,
    private clientService: ClientsService) {
    this.clientdata = data;
    console.log(this.clientdata);

    this.editform = this.fb.group({
      sales: [''],
      support: [''],
    })
  }
  ngOnInit() {
    this.getList();
    this.setdata();

  }

  setdata() {
    if (!isEmpty(this.clientdata)) {
      this.editform.patchValue({
        sales: this.clientdata.sales_user_id ? this.clientdata.sales_user_id : '' ,
        support: this.clientdata.support_user_id ? this.clientdata.support_user_id : ''
      })
    }
  }

  getList() {
    let params = {
      limit: 'all',
      page: 1,
      search: ''

    }
    this.commonService.getUsersList(params).subscribe(res => {
      this.userslist = res.cpanel_users;
      var grouped = _.mapValues(_.groupBy(this.userslist, 'role_id'));
      console.log(grouped);
      this.adminusers = grouped[1] ? grouped[1] : '';
      this.supportusers = grouped[2] ? grouped[2] : '';
      this.salesusers = grouped[3] ? grouped[3] : '';
    })
  }
  save() {
    
    if(this.editform.value.sales != '' || this.editform.value.support != '') {
    let company_id = this.clientdata.company_id ? this.clientdata.company_id : this.clientdata.id;
    console.log(company_id);
    let params = {
      sales_user_id: this.editform.value.sales,
      support_user_id: this.editform.value.support,
      company_id: company_id
    }
    this.clientService.updateSalesSupportUser(params).subscribe(res => {
      console.log(res);
      if(res.status) {
        this.commonService.openSnackBar('primary', res.message);
        this.dialogRef.close(true);
      } else {
        this.commonService.openSnackBar('warn', res.message);

      }

    })
    
  } else {
    this.commonService.openSnackBar('warn', 'Please fill atleast one field');
  }

  }
}
