import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  @Input()
  moduleSlug: any;
  @Input()
  companyId: any;
  @Input()
  recordId: any = 0;

  userComments: any;
  commentsData: any;
  currentUser: any;
  comment_id:any;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.getModuleComments({ company_id: this.companyId, module: this.moduleSlug, record_id:this.recordId });
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.commonService.getCurrentUser().subscribe(res => {
      if (res.status == 1) {
        this.currentUser = res.data;
      }
    })
  }

  getModuleComments(params) {
    this.commonService.getModuleCommentsData(params).subscribe(res => {
      if (res.status == 1) {
        this.commentsData = res.data.comments;
      }
    })
  }

  saveComment() {
    let payloadData = {};
    payloadData['company_id'] = this.companyId;
    payloadData['comments'] = this.userComments;
    payloadData['module'] = this.moduleSlug;
    payloadData['record_id'] = this.recordId;
    if (this.comment_id) {
      payloadData['id'] = this.comment_id;
    }
    this.commonService.saveModuleComments(payloadData).subscribe(res => {
      if (res.status == 1) {
        this.userComments = '';
        this.comment_id = null;
        this.getModuleComments({ company_id: this.companyId, module: this.moduleSlug, record_id:this.recordId });
        this.commonService.openSnackBar('primary', res.message);
      } else {
        this.commonService.openSnackBar('warn', res.message);
      }
    })
  }

  deleteComment(record_id) {
    let payloadData = {};
    payloadData['record_id'] = record_id;
    this.commonService.deleteModuleComments(payloadData).subscribe(res => {
      if (res.status == 1) {
        this.userComments = '';
        this.getModuleComments({ company_id: this.companyId, module: this.moduleSlug, record_id:this.recordId });
        this.commonService.openSnackBar('primary', res.message);
      } else {
        this.commonService.openSnackBar('warn', res.message);
      }
    })
  }

  cancelComments() {
    this.userComments = '';
    this.comment_id = '';
  }

  editComment(id,comments) {
    this.comment_id = id;
    this.userComments = comments;
  }

}
