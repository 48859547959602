import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-plans-dialog',
  templateUrl: './plans-dialog.component.html',
  styleUrls: ['./plans-dialog.component.scss']
})
export class PlansDialogComponent implements OnInit {
  selectedApps: any;
  plans: any;
  planTitleArr: any;
  plan_slug: any;
  plan_id: any;
  defaultApps: any;
  constructor(public dialogRef: MatDialogRef<PlansDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
        this.selectedApps = data.enabledApps;
        console.log(this.selectedApps)
        this.plans = data.plans;
        this.planTitleArr = data.planTitleArr;
        this.plan_slug = data.plan_slug
        this.plan_id = data.plan_id;
        this.defaultApps = {"hire":"ATS","hr":"HR","crm":"CRM"};
      
  }

  ngOnInit(): void {
  }

}
