<h1 mat-dialog-title class='text-uppercase'>Remove Free {{show_title}}</h1>
<mat-divider></mat-divider>
<form [formGroup]="AddLicense">
  <mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="grey-100-bg border-radius-4 p-8 mb-8">
      <p class="text-bold m-0" fxFlex="50">Total Free {{show_title}} : <span class="accent-fg">{{free_licenses}}</span></p>
      <p class="text-bold m-0" fxFlex>Total Available {{show_title}} : <span class="accent-fg">{{available_licenses}}</span></p>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill fxLayoutGap="12px grid">
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Exit Licenses</mat-label>
          <input type="number" min="1" matInput formControlName="total_licenses">
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button class='primary' (click)="convertLinceses()">Remove</button>
  </mat-dialog-actions>
</form>