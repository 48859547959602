import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/services/common.service';
import { CompanyDetailsService } from 'app/services/company-details.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-upload-invoice-pdf',
  templateUrl: './upload-invoice-pdf.component.html',
  styleUrls: ['./upload-invoice-pdf.component.scss']
})
export class UploadInvoicePdfComponent implements OnInit {
  formData: FormData = new FormData();
  url1: any;
  is_uploaded: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<UploadInvoicePdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private companyDetailService: CompanyDetailsService
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }


  onSelectFile1(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.url1 = event.target['result'];
      }
      //set uploaded file object to 'social_media_logo'
      this.formData.append('invoice_attachment', event.target.files[0]);
      this.is_uploaded = true;
    }
  }

  save() {
    console.log(this.url1, isEmpty(this.formData));
    if (this.is_uploaded) {
      let payLoadData = {};
      payLoadData['company_id'] = this.data.company_id;
      payLoadData['invoice_attachment_path'] = this.data.invoice_attachment;
      
      this.formData.append('requestData', JSON.stringify(payLoadData));
      this.companyDetailService.uploadInvoicePdf(this.formData).subscribe(res => {
        if (res.status == 1) {
          this.commonService.openSnackBar('primary', res.message);
          this.dialogRef.close(1);
        } else {
          this.commonService.openSnackBar('warn', res.message);
        }
      })
    } else {
      this.commonService.openSnackBar('warn', 'Please upload pdf format');
    }
  }
}
