<div class="page-layout carded fullwidth inner-scroll">
  <div class="center px-20">
    <div class="mt-12 mb-52">
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource && implementationFromData">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="position">
                <mat-header-cell *matHeaderCellDef> Implementation Activities </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.title}}&nbsp;<sub>{{element.sub_title}}</sub> </mat-cell>
              </ng-container>

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef fxFlex="15%"> Applicable? </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="15%">
                  <mat-checkbox color="primary" class="font-size-14" [(ngModel)]= "implementationFromData[element.slug].is_applicable">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="weight">
                <mat-header-cell *matHeaderCellDef fxFlex="16%"> Completion Date </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="16%">
                  <span *ngIf="element.is_date">
                    <mat-form-field appearance="outline" class="">
                      <mat-label>Select Date</mat-label>
                      <input matInput [matDatepicker]="picker" (click)="picker.open()" [(ngModel)]= "implementationFromData[element.slug].completion_date" (dateChange)="onDateChange($event, element.slug)">
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </span>
                  <span *ngIf="element.is_date==0">-</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="symbol">
                <mat-header-cell *matHeaderCellDef fxFlex="15%"> Status </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="15%">
                  <mat-form-field class="w-100-p country-p" appearance='outline'>
                    <!-- <mat-label>Select</mat-label> -->
                    <mat-select [(ngModel)]= "implementationFromData[element.slug].status" [disabled]="!implementationFromData[element.slug].is_applicable" (selectionChange)="getIsApplicable(element.slug)">
                      <mat-option *ngFor="let item of statusList" [value]="item.value">
                        {{item.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef fxFlex='150px'> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="150px">
                  <button mat-icon-button class="primary-fg" matTooltip="Comments" (click)="showComments(element.slug, element.title)">
                    <mat-icon class="s-18">chat</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class='footer'>
  <button mat-button (click)="sendImplementationUpdate()">Send Email</button>
  <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
  <button mat-button (click)="saveData()">Save</button>
  <mat-divider style="width:1px;background-color:#fff;"></mat-divider>

  <button mat-button (click)="downloadPdfData()">Download as PDF</button>
</div>