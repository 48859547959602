<form [formGroup]="partnerForm">
  <div fxLayout="row wrap" fxLayoutAlign="center start">
    <mat-card class="mt-40 mat-elevation-z3" fxFlex="40" *ngIf="compData.length > 0">
      <div fxLayout="row wrap" fxLayoutAlign="space-between start">
        <div fxFlex="50">
          <h2 class="heading">Partner Companies</h2>
        </div>
        <div fxFlex="50">
          <ul class="m-0 p-0 pl-12 list-style-none line-height-28 grey-200-bg">
            <li *ngFor="let item of compData">{{item.name}}</li>
          </ul>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div fxFlex="50">
          <h2 class="heading">Enable Partner Search</h2>
        </div>
        <div fxFlex="50">
          <mat-radio-group aria-label="Select an option" formControlName="enablepartner"
            (change)="enablePartnerSearch($event.value)">
            <mat-radio-button value="0" class="sub-heading mr-8" color="primary">No</mat-radio-button>
            <mat-radio-button value="1" class="sub-heading" color="primary">Yes</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-card>
    <h2 class="heading" *ngIf="compData.length == 0">No Data Available</h2>
  </div>
</form>