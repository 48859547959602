import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/services/common.service';
import { ExcelService } from 'app/services/excel.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { EditFieldsComponent } from '../client-all/edit-fields/edit-fields.component';
import { TrailSuscriptionendDateDialogComponent } from '../trail-suscriptionend-date-dialog/trail-suscriptionend-date-dialog.component';
import { ClientsService } from 'app/services/clients.service';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { ConfirmExitRequestApproveComponent } from 'app/main/signup-request/confirm-exit-request-approve/confirm-exit-request-approve.component';
@Component({
  selector: 'app-trail-comapnies',
  templateUrl: './trail-comapnies.component.html',
  styleUrls: ['./trail-comapnies.component.scss']
})
export class TrailComapniesComponent implements OnInit {
  displayedColumns: string[] = ['customername', 'contactname', 'contactnumber', 'email','lost_account', 'signupdate', 'region', 'remainingdays', 'trial_endon', 'sales_user', 'support_user', 'actions'];
  dataSource;
  searchValue = '';
  getlist: any;
  getTableData: any;
  length: any;
  pageSize: any;
  index: any;
  getDateRange = {};
  userslist: any = [];
  params: { limit: number; page: number; all_users: number; searchKey: string; search_filter: string; type: string; dateRange?: any; country_id?: any; sort_column?: any; sort_type?: any };
  currentTab: any;
  dateRangeForm: FormGroup;
  dateActive = '';

  countries = [
    { value: 'all', viewValue: 'All' }, { value: '1', viewValue: 'USA ($)' }, { value: '2', viewValue: 'India (₹)' }, { value: '702', viewValue: 'Singapore (S$)' }
  ];
  country_id: any = 'all';

  // ejs datepicker
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  // public lastEnd: Date = this.today;
  public lastEnd: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  // public yearStart = moment().startOf('year');
  public yearEnd: Date = this.today;
  // public value: Date[] = [this.yearStart, this.yearEnd];
  // ejs datepicker completed

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  activeRoute: any;
  permissions: any;
  logginUser: any;
  constructor(
    private commonservice: CommonService,
    private clientService: ClientsService,
    private excelService: ExcelService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    public datePipe: DatePipe) {

      this.commonservice.currentUser.subscribe(res => {
        if(res) {
          this.logginUser = res;
        }
    });
    
    this.route.url.subscribe(res => {
      if (res) {
        this.activeRoute = res[0].path;
      }
    });
    this.dateRangeForm = this.fb.group({
      start_date: [''],
      end_date: ['']
    });
    this.dateRangeForm.valueChanges.pipe(debounceTime(0), distinctUntilChanged()).subscribe(res => {
      if (res) {
        if (res.start_date && res.end_date) {
          this.getDateRange['startDate'] = this.datepipe.transform(res.start_date, 'yyyy-MM-dd');
          this.getDateRange['endDate'] = this.datepipe.transform(res.end_date, 'yyyy-MM-dd');
          this.params.dateRange = JSON.stringify(this.getDateRange);
          this.params.page = 1;
        }
        else {
          this.params.dateRange = '';
        }
        this.getTrailCompaniesData('');
      }
    });
  }

  
  ngOnInit() {
    this.getTrailCompaniesData('all');
    this.commonservice.permissions.subscribe(res => {
      this.permissions = res;
      console.log(this.permissions, 'permissions');

    })
  }

  applyFilter(filterValue: string) {
    this.params['searchKey'] = filterValue;
    this.params.page = 1;
    this.getTrailCompaniesData('');
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getTrailCompaniesData('');
  }
  getTrailCompaniesData(getnav) {
    this.currentTab = getnav;
    switch (getnav) {
      case 'all':
        this.getlist = getnav;
        if (this.activeRoute == 'trial') {
          this.params = { limit: 15, page: 1, all_users: 1, searchKey: '', search_filter: '', type: 'trial' };
        }
        else {
          this.params = { limit: 15, page: 1, all_users: 2, searchKey: '', search_filter: '', type: 'trial' };
        }
        break;
      case 'search_filter_today':
        this.getlist = getnav;
        if (this.activeRoute == 'trial') {
          this.params = { limit: 15, page: 1, all_users: 1, searchKey: '', search_filter: 'search_filter_today', type: 'trial' };
        }
        else {
          this.params = { limit: 15, page: 1, all_users: 2, searchKey: '', search_filter: 'search_filter_today', type: 'trial' };
        }
        break;
        case 'free_customers':
        this.getlist = getnav;
        if (this.activeRoute == 'trial') {
          this.params = { limit: 15, page: 1, all_users: 1, searchKey: '', search_filter: 'free_customers', type: 'trial' };
        }
        else {
          this.params = { limit: 15, page: 1, all_users: 2, searchKey: '', search_filter: 'free_customers', type: 'trial' };
        }
        break;
      case 'search_filter_week':
        this.getlist = getnav;
        if (this.activeRoute == 'trial') {
          this.params = { limit: 15, page: 1, all_users: 1, searchKey: '', search_filter: 'search_filter_week', type: 'trial' };
        }
        else {
          this.params = { limit: 15, page: 1, all_users: 2, searchKey: '', search_filter: 'search_filter_week', type: 'trial' };
        }
        break;
      case 'search_filter_intwodys':
        this.getlist = getnav;
        if (this.activeRoute == 'trial') {
          this.params = { limit: 15, page: 1, all_users: 1, searchKey: '', search_filter: 'search_filter_intwodys', type: 'trial' };
        }
        else {
          this.params = { limit: 15, page: 1, all_users: 2, searchKey: '', search_filter: 'search_filter_intwodys', type: 'trial' };
        }
        break;
      default:
        // console.log(this.params);
        break;
    }
    this.params.country_id = this.country_id;
    this.commonservice.getList(this.params).subscribe(result => {
      if (result) {
        this.getTableData = result.data.data;
        this.length = this.getTableData.total;
        this.pageSize = this.getTableData.per_page;
        this.index = this.getTableData.current_page;
        this.dataSource = new MatTableDataSource(this.getTableData.data);
        this.userslist = result.data.users;
      }
    })
  }

  sortColumnData(column_name, order_type) {
    this.params.sort_column = column_name;
    this.params.sort_type = order_type;
    this.getTrailCompaniesData('');
  }


  


viewComment(items)
{
  let record_id = items.id;
  let cnt = items.comments_cnt;
  if(cnt)
  {



    let activityData ={
      'company_id':record_id,
      'show_company_name':true,
      'addon_slug':'client_notes',
      'module_slug':'client_note',
      'module_name':'client_note',
}
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
    

  }

    // alert(record_id);

}

addComment(record_id) {
  
 const data = [];
 data['title'] = 'Comment';
 data['message'] = 'Comment';
 data['successText'] = 'Yes';
 data['closeText'] = 'No';
 const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
   minWidth: '30%',
   minHeight: '40%',
   data: data
 });
 let saveData: any;
 
 dialogRef.afterClosed().subscribe(result => {
   console.log(result);
   if (result.approved) {

     saveData = {
       'id': record_id,
       'status': 'comment',
       'comments' : result.commets
     };
     this.commonservice.saveClientNotes(saveData).subscribe(res => {
       if (res.status == 1) {
         this.commonservice.openSnackBar('primary', res.message);
       } else {
         this.commonservice.openSnackBar('warn', res.message);
       }
       this.getTrailCompaniesData(this.getlist);
     });
   } else {

   }
 })
 
}

  exportExcel() {
    let Params = { all_users: 2, limit: this.length, page: 1, type: 'trial' };
    this.commonservice.getList(Params).subscribe(result => {
      if (result.status === 1) {
        let prepared_data = this.prepareExcelData(result.data.data.data);
        this.excelService.exportAsExcelFile(prepared_data, 'Trail Companies');
      }
    })
  }

  navigate(element) {
    // this.router.navigate(['company_details', element.company_id]);
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }
  navigatetoImport(element) {
    this.router.navigate(['import', element.company_id])
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name'] = element.name;
      eachRow['Contact Name'] = element.client_info.first_name + ' ' + element.client_info.last_name;
      eachRow['Contact Number'] = element.client_info.contact_number;
      eachRow['Signup Date'] = moment(element.subscr_start_date).format('MM/DD/YYYY');
      eachRow['Customer Email'] = element.client_info.email;
      // eachRow['Subdomain Name'] = element.sub_domain_name;
      eachRow['Remaining Days'] = this.differDays(element.subscr_end_date, element.grace_period);
      eachRow['Trial End Date'] = this.showExtendedDate(element.subscr_end_date, element.grace_period);
      eachRow['Sales Rep'] = element.sales_user_id ? this.userName(element.sales_user_id) : '-';
      eachRow['Support Executive'] = element.support_user_id ? this.userName(element.support_user_id) : '-';
      ArrData.push(eachRow);
    });
    // console.log('prepared', ArrData);
    return ArrData;
  }
  upgradeRoute(id) {
    this.commonservice.subscriptionType = 'upgrade';
    this.router.navigate(['company_details/' + id + '/upgrade']);
  }
  userName(id) {
    let name;
    this.userslist.forEach(val => {
      if (val.id == id) {
        name = val.first_name;
      }
    });
    return name;
  }

  differDays(end_date, grace_period) {
    let grace_end_date = end_date;
    if (grace_period > 0) {
      grace_end_date = moment(end_date).add(grace_period, 'days').format("YYYY-MM-DD");
    }
    let start_date = moment(new Date()).format("YYYY-MM-DD");
    let diffDays = moment(grace_end_date).diff(start_date, 'days');
    return diffDays;
  }
  showExtendedDate(end_date, grace_period) {
    let grace_end_date = end_date;
    if (grace_period > 0) {
      grace_end_date = moment(end_date).add(grace_period, 'days').format("MM/DD/YYYY");
    } else {
      grace_end_date = moment(end_date).format("MM/DD/YYYY");
    }
    return grace_end_date;
  }

  extendTrial(element) {
    // console.log(element);
    const dialogRef = this.dialog.open(TrailSuscriptionendDateDialogComponent, {
      width: '35%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getTrailCompaniesData(this.currentTab);
      }
    });
  }

  SendActivationMail(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to send activation email';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService.SendActivationEmail(record_id).subscribe(res => {
          if (res.status == 1) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
          this.getTrailCompaniesData(this.currentTab);
        });
      } else {

      }
    })
  }

  endTrial(record) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to end subscription';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let SaveData: any;
        SaveData = {
          'company': record,
          'column': 'end_trial',
          'extended_date': moment(new Date()).format("YYYY-MM-DD"),
          'comments': ''
        }
        this.commonservice.udpateCompanyInfo(SaveData).subscribe(res => {
          if (res.status == 1) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
          this.getTrailCompaniesData(this.currentTab);
        });
      } else {

      }
    })

  }

  deleteTrial(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to delete';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService.deleteTrialCompany(record_id).subscribe(res => {
          if (res.status == 1) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
          this.getTrailCompaniesData(this.currentTab);
        });
      } else {

      }
    })
  }
  refresh() {
    this.searchValue = '';
    this.params = { limit: 15, page: 1, all_users: 2, searchKey: '', search_filter: '', type: 'trial' };
    this.dateRangeForm.reset();
  }

  edit(element) {
    const dialogRef = this.dialog.open(EditFieldsComponent, {
      minWidth: '40%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.getTrailCompaniesData('all');
      }
    });
  }

  countryChanged() {
    this.params.limit = 15;
    this.params.page = 1;
    this.params.country_id = this.country_id;
    this.getTrailCompaniesData('');
  }

  setDate(date_type: string) {
    this.dateActive = date_type;
    switch (date_type) {
      case 'this_week':
        this.dateRangeForm?.setValue({
          start_date: this.datePipe.transform(this.weekStart, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(this.weekEnd, 'yyyy-MM-dd')
        });
        break;
      case 'this_month':
        this.dateRangeForm.setValue({
          start_date: this.datePipe.transform(this.monthStart, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(this.monthEnd, 'yyyy-MM-dd')
        });
        break;
      case 'last_month':
        this.dateRangeForm.setValue({
          start_date: this.datePipe.transform(this.lastStart, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(this.lastEnd, 'yyyy-MM-dd')
        });
        break;
      default:
        this.dateRangeForm.setValue({
          start_date: '',
          end_date: ''
        });
        break;
    }
  }
}
