import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { AddRefundComponent } from './add-refund/add-refund.component';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-refunds',
  templateUrl: './refunds.component.html',
  styleUrls: ['./refunds.component.scss']
})
export class RefundsComponent implements OnInit {

  // displayedColumns: string[] = ['comment', 'amount', 'refundtype', 'date','action'];
  displayedColumns: string[] = ['comment', 'amount', 'refundtype', 'date'];
  dataSource:any;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  paramsData: any;
  limit: any = 15;
  page: any = 1;

  constructor(private commonService: CommonService,
    private CompanyDetailsService: CompanyDetailsService,
    public dialog: MatDialog,
    private route: ActivatedRoute) {
    this.id = this.route.parent.snapshot.params['id'];
  }

  ngOnInit() {
    this.getList();
  }
  applyFilter(value) {

  }
  pageEvent(event) {
    this.limit = event.pageSize;
    this.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.paramsData = {
      'company_id': this.id,
      'limit': this.limit,
      'page': this.page
    };
    this.CompanyDetailsService.getRefundRequests(this.paramsData).subscribe(res => {
      if (res.status == 1) {
        // console.log('res',res);
        this.dataSource = new MatTableDataSource(res.data.data);
        this.length = res.data.total;
        this.pageSize = res.data.per_page;
        this.index = res.data.current_page;
      }
    });
  }
  addRefund(): void {
    let prepareData: any;
    prepareData = {
      'company_id': this.id
    }
    const dialogRef = this.dialog.open(AddRefundComponent, {
      width: '50%',
      data: prepareData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getList();
      }
      // console.log('The dialog was closed');
    });
  }

  processRefund(record_id): void {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to process this refund';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonService.getLoader();
        this.CompanyDetailsService.processRefund(this.id,record_id).subscribe(res => {
         
          this.commonService.getLoader();
        });
      } else {

      }
    })
  }

}
