<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold active">Communication Center</a></li>
    </ul>
  </mat-toolbar-row>

  <div class="center px-20">
    <div class="content-card mt-12 mb-56">
      <div class="content p-20" fusePerfectScrollbar>
        <div [formGroup]="CommunicationForm" class="w-100-p">
          <div fxLayout="row wrap" fxLayoutAlign="space-between start">
            <div fxFlex='35'>
              <div>
                <mat-radio-group formControlName="customer_type" aria-label="Select an option"
                  (change)="onCtrlChange()">
                  <mat-radio-button value="1" color="primary" class="sub-heading mr-20">All Customers
                  </mat-radio-button>
                  <mat-radio-button value="2" color="primary" class="sub-heading">Individual Customer
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="mt-12 pl-24 line-height-28">
                <mat-radio-group formControlName="user_type" aria-label="Select an option" *ngIf="showusertype "
                  (change)="onTypeChange()">
                  <div>
                    <mat-radio-button value="admin" color="primary" class="sub-heading mr-20">Super Admins
                    </mat-radio-button>
                  </div>
                  <div>
                    <mat-radio-button value="all_users" color="primary" class="sub-heading">All Users
                    </mat-radio-button>
                  </div>
                </mat-radio-group>
              </div>
              <ng-container>
                <div class="mt-20" fxLayout='row wrap' fxLayoutAlign="start center">
                  <div fxFlex="70">
                    <ng-container *ngIf="showAll_options">

                      <mat-form-field class="w-100-p" appearance="outline">
                        <mat-label>To</mat-label>
                        <mat-select appMatSearchSelector class="w-100-p" formControlName="toids" multiple>
                          <app-mat-search-selector placeholder='Search..'>
                          </app-mat-search-selector>
                          <ng-container *ngFor="let company of companies">
                            <mat-option *appMatSearchSelectorItems [value]="company.id">
                              {{company.display_name}}
                            </mat-option>
                          </ng-container>
                        </mat-select>
                      </mat-form-field>

                    </ng-container>

                    <ng-container *ngIf="shotochips">
                      <mat-form-field class="w-100-p" appearance="outline">
                        <!-- <span matPrefix class="font-size-12">To &nbsp;</span> -->
                        <mat-label>To</mat-label>
                        <mat-chip-list #ToEmailchipList formArrayName="to">
                          <mat-chip [color]="(email.invalid)?'warn':''" selected
                            *ngFor="let email of CommunicationForm.get('to').controls; let i=index;"
                            [selectable]="selectable" [removable]="removable" (removed)="removeEmail(i,'to')"
                            name="tochips" class="text-lowercase">
                            {{email.value}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                          </mat-chip>

                          <input [matChipInputFor]="ToEmailchipList"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="addEmail($event,'to')">

                        </mat-chip-list>
                      </mat-form-field>
                      <!-- <span class="warn-fg font-size-10" *ngIf="CommunicationForm.get('to').errors">Atleast one email needed</span> -->
                    </ng-container>
                    
                  </div>
                  <div fxFlex='30' fxLayout='row wrap' fxLayoutAlign="space-evenly center">
                    <div>
                      <span class="font-size-12 text-bold cursor-pointer" [ngClass]="{'accent-fg': cc}"
                        (click)="showCc($event)">CC</span>
                    </div>
                    <div>
                      <span class="font-size-12 mr-0 text-bold cursor-pointer" [ngClass]="{'accent-fg': bcc}"
                        (click)="showBcc($event)">BCC</span>
                    </div>
                  </div>

                </div>
                <div *ngIf="cc">
                  <mat-form-field class="w-100-p mt-12" appearance="outline">
                    <span matPrefix class="font-size-12">CC &nbsp;</span>
                    <mat-chip-list #CCEmailchipList formArrayName="cc">
                      <mat-chip [color]="(email.invalid)?'warn':''" selected
                        *ngFor="let email of CommunicationForm.get('cc').controls; let i=index;"
                        [selectable]="selectable" [removable]="removable" (removed)="removeEmail(i,'cc')" name="chips"
                        class="text-lowercase">
                        {{email.value}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip>

                      <input [matChipInputFor]="CCEmailchipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addEmail($event,'cc')">

                    </mat-chip-list>
                  </mat-form-field>

                </div>
                <div *ngIf="bcc">
                  <mat-form-field class="w-100-p mt-12" appearance="outline">
                    <span matPrefix class="font-size-12">BCC &nbsp;</span>
                    <mat-chip-list #BCCEmailchipList formArrayName="bcc">
                      <mat-chip [color]="(email.invalid)?'warn':''" selected
                        *ngFor="let email of CommunicationForm.get('bcc').controls; let i=index;"
                        [selectable]="selectable" [removable]="removable" (removed)="removeEmail(i,'bcc')"
                        class="text-lowercase">
                        {{email.value}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip>

                      <input [matChipInputFor]="BCCEmailchipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addEmail($event,'bcc')">

                    </mat-chip-list>
                  </mat-form-field>

                </div>

              </ng-container>
            </div>
            <div fxFlex="60">
              <div class="">
                <mat-form-field appearance="outline" class="w-100-p">
                  <mat-label>Subject</mat-label>
                  <input matInput formControlName="subject">
                </mat-form-field>
              </div>
              <div class="mt-12">
                <div class="font-size-12 text-bold my-4">Message Body</div>
                <ckeditor formControlName="mail_body" (ready)="onReady($event)"></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer">
  <button mat-button class="primary" (click)="send()">Send</button>
</div>