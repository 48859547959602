import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isEmpty } from 'lodash';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'app/services/common.service';
import { ConfigurationOptions, ContentOptionsEnum, SortOrderEnum, OutputOptionsEnum } from 'intl-input-phone';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
const moment = _rollupMoment || _moment; 


@Component({
  selector: 'app-add-couponlisting-dialog',
  templateUrl: './add-couponlisting-dialog.component.html',
  styleUrls: ['./add-couponlisting-dialog.component.scss']
})
export class AddCouponlistingDialogComponent implements OnInit { 

  form: FormGroup; 
  importForm: FormGroup;
  addData:any = {};
  formData: FormData = new FormData();
  importFormData: FormData = new FormData();
  restrictToClients:any;
  restricttoAddon:any;
  IsRequired: boolean = false;
  isImport: boolean = false;
  expiry: boolean = false;
  configOption3: ConfigurationOptions;

  discountType = [
    { value: 'percentage', viewValue: 'Percentage' },
    { value: 'fixed_value', viewValue: 'Fixed Value' },
  ];

  billingCycle = [
    { value: '1', viewValue: 'Monthly' },
    { value: '3', viewValue: 'Quarterly' },
    { value: '6', viewValue: 'Half Yearly' },
    { value: '12', viewValue: 'Yearly' },
  ];


  validity = [
    // { value: 'one_time_use', viewValue: 'One Time Use' },
    // { value: 'period', viewValue: 'Period' },
    { value: 'valid_till_date', viewValue: 'Valid Till Date' },
    { value: 'forever', viewValue: 'Forever' },
  ];

  todayDate:Date = new Date();
  file: File;

  constructor(public dialogRef: MatDialogRef<AddCouponlistingDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private commonService: CommonService) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      discountType: ['', Validators.required],
      value: ['', Validators.required],
      validity: ['', Validators.required],      
      validityExpiry: [],
      maxRedemptions: ['', Validators.required],
      billingCycle: ['', Validators.required],
      restrictToAddon: [],
      restrictToClients: ['', Validators.required]
    })

    this.importForm = this.fb.group({
      couponsFile: [],
    });
  }

  ngOnInit() {
    
    if (!isEmpty(this.data)) {

      if (this.data == 'import'){
        this.isImport = true;
      } else {

        this.IsRequired = true;
        
        if ( this.data.type == 'percentage' ) {
          this.form.controls["value"].setValidators([Validators.min(1), Validators.max(100)]);
          this.form.controls["value"].setValidators([Validators.required]);
          this.form.controls["value"].updateValueAndValidity();
        }

        if ( this.data.validity_type == 'valid_till_date' ) {
          this.expiry = true;
        }
        
        this.commonService.getCouponCompanyId({ id: this.data.id }).subscribe(res => {
          if (res.status == 1) {
            this.form.patchValue({
              restrictToClients: res.data,
          });
          }
        });

        this.form.patchValue({
          name: this.data.title,
          discountType: this.data.type,
          value: this.data.discount,
          maxDiscountValue: this.data.max_discount_value,
          validity: this.data.validity_type,
          validityExpiry: this.data.expiry_date,
          maxRedemptions: this.data.max_redemptions,
          billingCycle: this.data.billing_cycle,
          restrictToAddon: this.data.items,
        });
        
      }
    }

    this.commonService.getPlanDetails().subscribe(res => {

      if (res.status == 1) {
        this.restrictToClients = res.data.companyData;
        this.restricttoAddon = res.data.planData;
      }
    });
  }

  selectChange(val) {
    if (val == 'percentage') {
      this.form.controls["value"].addValidators([Validators.min(1), Validators.max(100)]);
      this.form.controls["value"].addValidators([Validators.required]);
      this.form.controls["value"].updateValueAndValidity();
    } else {
      this.form.controls["value"].addValidators([Validators.min(1)]);
      this.form.controls["value"].addValidators([Validators.required]);
      this.form.controls["value"].updateValueAndValidity();
    }
  }

  onFileChange(files: FileList): void {

    if (files.length) {
      this.file = files[0];
      this.importFormData.append("couponsFile", this.file, this.file.name);
    }
  }
  
  validitySelectChange(val) {
    if (val == 'forever') {
      this.expiry = false;
      this.form.removeControl('validityExpiry');
    } else {
      this.expiry = true;
      this.form.addControl('validityExpiry', new FormControl('', Validators.required));
      this.form.controls["validityExpiry"].updateValueAndValidity();
    }
  }

  save() {

    if (this.data == 'import'){
      
      if(this.file){
        const data = [];
        data['title'] = 'Alert';
        data['message'] = 'Are you sure? You want to Import?';
        data['successText'] = 'Yes';
        data['closeText'] = 'No';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '35%',
          data: data
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.commonService.couponsImport(this.importFormData).subscribe(res => {
              if (res.status === 1) {
                this.dialogRef.close(true);
                this.commonService.openSnackBar('primary', res.message);
              } else {
                if (!isEmpty(res.validations.title))
                  this.commonService.openSnackBar('warn', res.validations.title);
                else if (!isEmpty(res.validations.billing_cycle))
                  this.commonService.openSnackBar('warn', res.validations.billing_cycle);
                else 
                  this.commonService.openSnackBar('warn', res.message);
              }
            });
          }
        });
      } else {
        this.commonService.openSnackBar('warn', 'Please Select File.');
      }

    } else {

      if (this.form.valid) {
        
        const data = [];
        data['title'] = 'Alert';
        
        if (isEmpty(this.form.value.restrictToAddon))
          data['message'] = "If your not selecting any Item, it'll applicable to Invoice Items.";
        else
          data['message'] = 'Are you sure? You want to proceed?';

        data['successText'] = 'Yes';
        data['closeText'] = 'No';
        
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '35%',
          data: data
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            if (!isEmpty(this.data)) {
              this.addData['id'] = this.data.id;
            }

            this.addData['title'] = this.form.value.name;
            this.addData['discountType'] = this.form.value.discountType;
            this.addData['value'] = this.form.value.value;
            this.addData['validity'] = this.form.value.validity;
            this.addData['validityExpiry'] = this.form.value.validityExpiry;
            this.addData['maxRedemptions'] = this.form.value.maxRedemptions;
            this.addData['billingCycle'] = this.form.value.billingCycle;
            this.addData['restrictToClients'] = this.form.value.restrictToClients;
            this.addData['restrictToAddon'] = this.form.value.restrictToAddon;
            // console.log(this.addData);
            // return false;
            this.commonService.saveCoupon(this.addData).subscribe(res => {
              if (res.status === 1) {
                this.dialogRef.close(true);
                this.commonService.openSnackBar('primary', res.message);
              } else {
                let msg = res.message;
                this.commonService.openSnackBar('warn', msg);
              }
            });
          }
        });

      }
    }

  }

}
