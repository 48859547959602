<h1 mat-dialog-title class='text-uppercase text-center'>{{data.title}}</h1>
<mat-divider></mat-divider>
<mat-dialog-content class="text-center line-height-32" >
  <p>{{data.message}}</p>
  <form [formGroup]="DialogFrom">

    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
      <div fxFlex='100'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Comments</mat-label>
          <textarea matInput rows="6" formControlName="comments"></textarea>
        </mat-form-field>
      </div>
    </div>

    <mat-checkbox *ngIf="!data.email" color="primary" formControlName='check_data1' class="mr-20">{{data.check_data1}}</mat-checkbox>
    <mat-form-field class="w-100-p" appearance='outline' *ngIf="data.email">
      <mat-label>Email</mat-label>
      <input type="email" formControlName="email" matInput>
    </mat-form-field>
  </form>
  </mat-dialog-content>
<mat-dialog-actions align='center'>
  <button mat-flat-button class='mr-16' (click)="dialogRef.close({'status':false})">{{data.closeText}}</button>
  <button mat-flat-button (click)="sendConfirmData()" class='primary'>{{data.successText}}</button>
</mat-dialog-actions>