<div class="page-layout carded fullwidth inner-scroll">
    <mat-toolbar-row class="secondary-headers w-100-p">
        <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
            <li><a class="text-bold active">Unsubscribe List</a></li>
        </ul>
    </mat-toolbar-row>

    <div class="center px-20">
        <div class="content-card mt-12 mb-56">
            <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
                <div class="search-wrapper" fxFlex="30">
                    <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
                        [(ngModel)]="searchValue" placeholder="Search Email...">
                    <mat-icon class="search_icon">search</mat-icon>
                    <mat-icon class="close s-20 danger-fg" *ngIf="searchValue"
                        (click)="searchValue=''; applyFilter('');">
                        close
                    </mat-icon>
                </div>
                <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
                    <div>
                        <button mat-flat-button class="primary mr-4" (click)="addUnsubscribeEmail()">Add</button>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50, 100]" [length]="length"
                            (page)="pageEvent($event)" [pageSize]="limit" [pageIndex]="currentPage-1"></mat-paginator>
                    </div>
                </div>

            </div>
            <div class="content" fusePerfectScrollbar>
                <ng-container *ngIf="dataSource">
                    <div class="px-12 pb-12">
                        <mat-table [dataSource]="dataSource">
                            <ng-container matColumnDef="email_id">
                                <mat-header-cell *matHeaderCellDef> Email ID </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase"> {{element.email}}</mat-cell>
                            </ng-container>

                            <!-- email Column -->
                            <ng-container matColumnDef="comment">
                                <mat-header-cell *matHeaderCellDef> Comment </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase"> {{element.comments}}
                                </mat-cell>
                            </ng-container>

                            <!-- email Column -->
                            <ng-container matColumnDef="created_on">
                                <mat-header-cell *matHeaderCellDef> Created on </mat-header-cell>
                                <mat-cell *matCellDef="let element"> {{element.created_at | date : 'medium'}}
                                </mat-cell>
                            </ng-container>

                            <!-- action Column -->
                            <ng-container matColumnDef="actions">
                                <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                                <mat-cell *matCellDef="let element">

                                    <button mat-icon-button class="red-fg" matTooltip="Delete"
                                        (click)="deleteUnsubscribeEmail(element)">
                                        <mat-icon class="s-18">delete</mat-icon>
                                    </button>

                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                        </mat-table>
                        <div *ngIf="dataSource.data.length == 0" class="text-center">
                            <h2 class="heading m-0 py-16 border">No Records Found</h2>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>