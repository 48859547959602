import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/services/common.service';
import { CompanyDetailsService } from 'app/services/company-details.service';
import { isEmpty } from 'lodash';
import { ConfirmExitRequestApproveComponent } from '../confirm-exit-request-approve/confirm-exit-request-approve.component';

@Component({
  selector: 'app-mass-mails-split',
  templateUrl: './mass-mails-split.component.html',
  styleUrls: ['./mass-mails-split.component.scss']
})
export class MassMailsSplitComponent implements OnInit {

  splittedMassMailsCols: string[] = ['sno', 'credits', 'used', 'startDate', 'endDate'];
  dataSourceSet;

  displayedColumns: string[] = ['id', 'available', 'used', 'paid_on', 'end_date'];
  dataSource;
  massMailsIdArr = {};
  splittedAddonsInfo: any = [];

  constructor(public dialogRef: MatDialogRef<MassMailsSplitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private commonservice: CommonService,
    private companyDetailService: CompanyDetailsService, public dialog: MatDialog,) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data.addonRecords);
    this.splittedAddonsInfo = this.data.splittedAddonsInfo;
  }

  splitMassMails() {

    if (isEmpty(this.massMailsIdArr)) {
      this.commonservice.openSnackBar('warn', 'Please select record');
      return;
    }
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to split mass mails into monthly wise';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        let formData = {};
        formData['companyAddonIdArr'] = this.massMailsIdArr;
        formData['company_id'] = this.data.company_id;
        formData['company_name'] = this.data.company_name;
        formData['comments'] = result.commets;

        this.companyDetailService.doMassMailsSplit(formData).subscribe(res => {
          if (res.data.status == 1) {
            this.commonservice.openSnackBar('primary', res.data.message);
            this.dialogRef.close(1);
          } else {
            this.commonservice.openSnackBar('warn', res.data.message);
          }
        });
      }
    })
  }

  getSplittedInfo(companyAddonId) {
    this.dataSourceSet = this.splittedAddonsInfo[companyAddonId];
  }

}
