<h1 mat-dialog-title class='text-uppercase'>{{show_title}} {{ data.is_unlimited == 1 ? ' (Unlimited)' : ''}} </h1>
<mat-divider></mat-divider>
<form [formGroup] = "addAddonCreditGrup" (ngSubmit) ="addCredits()">
<mat-dialog-content>
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill fxLayoutGap="12px grid">
    <div fxFlex='48' fxLayout="row wrap" fxLayoutAlign="start center">
      <mat-label class="w-100-p sub-heading">Credits</mat-label>
      <mat-form-field class="w-50-p" appearance='outline'>
        <input numeric type="number" matInput formControlName="total_credits" min="0">
      </mat-form-field>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button class='primary'>Add</button>
</mat-dialog-actions>
</form>