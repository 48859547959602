<div>
  <div fxLayout="row wrap" class="border-b-1">
    <ul class="top-nav m-0 p-0" fxFlex>
      <li><a class="text-bold" (click)="getActiveUsers('all')" [ngClass]="{'active': getlist == 'all'}">
          {{customerType == 'active'?'Active Customers':'Due Customers'}}</a>
      </li>
      <!-- <li><a class="text-bold" (click)="getActiveUsers('today')" [ngClass]="{'active': getlist == 'today'}">Expiring
              today</a>
            </li>
            <li><a class="text-bold" (click)="getActiveUsers('week')" [ngClass]="{'active': getlist == 'week'}">Expiring
              this
              week</a></li>
              <li><a class="text-bold" (click)="getActiveUsers('2days')" [ngClass]="{'active': getlist == '2days'}">Expiring
                in
                next 2 days</a>
              </li> -->
    </ul>

    <div fxFlex="280px" fxLayout="row wrap" fxLayoutAlign="end center">
      <mat-form-field class="w-100-p country-p" appearance='outline'>
        <mat-label>Country</mat-label>
        <mat-select [(ngModel)]="country_id" (selectionChange)="countryChanged()">
          <mat-option *ngFor="let item of countries" [value]="item.value">
            {{item.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
    <div class="search-wrapper" fxFlex="30">
      <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
        [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Company Name">
      <mat-icon class="search_icon">search</mat-icon>
      <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
        close
      </mat-icon>
    </div>
    <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
      <div>
        <button mat-stroked-button (click)="exportExcel()" *ngIf="logginUser?.role_name == 'admin'">Export as Excel</button>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
          [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<cdk-virtual-scroll-viewport itemSize="50" class="scroll-content-height">
<div class="mx-12 mb-12">
  <ng-container *ngIf="dataSource">
    <div>
      <div>
        <mat-table [dataSource]="dataSource" class="table">
          <!-- customername Column -->
          <ng-container matColumnDef="customername">
            <mat-header-cell *matHeaderCellDef fxFlex="230px"> customer name
              <span>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="sortColumnData('c.name','asc')">
                    <mat-icon>sort</mat-icon>
                    <span>Sort Ascending</span>
                  </button>
                  <button mat-menu-item (click)="sortColumnData('c.name','desc')">
                    <mat-icon>sort</mat-icon>
                    <span>Sort Descending</span>
                  </button>
                </mat-menu>
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="230px">
              <a (click)="navigate(element,'blank')" class="cursor-pointer">{{element.company_name}}</a>
            </mat-cell>
          </ng-container>
          <!-- contactname Column -->
          <ng-container matColumnDef="contactname">
            <mat-header-cell *matHeaderCellDef> contact name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.client_info.first_name}}
              {{element.client_info.last_name}} </mat-cell>
          </ng-container>

          <!-- contactemail Column -->
          <ng-container matColumnDef="contactemail">
            <mat-header-cell *matHeaderCellDef fxFlex="200px"> contact email </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="200px" class="lowercase"> {{element.client_info.email}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="region">
            <mat-header-cell *matHeaderCellDef> Region
              <span>
                <button mat-icon-button [matMenuTriggerFor]="menu1">
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu1="matMenu">
                  <button mat-menu-item (click)="sortColumnData('c.region','asc')">
                    <mat-icon>sort</mat-icon>
                    <span>Sort Ascending</span>
                  </button>
                  <button mat-menu-item (click)="sortColumnData('c.region','desc')">
                    <mat-icon>sort</mat-icon>
                    <span>Sort Descending</span>
                  </button>
                </mat-menu>
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.region != 'usa'">{{element.region | titlecase}}</span>
              <span *ngIf="element.region == 'usa'">{{element.region | uppercase }}</span>
            </mat-cell>
          </ng-container>

          <!-- MSA and SOW Column -->
          <ng-container matColumnDef="msaandsow">
            <mat-header-cell *matHeaderCellDef> msa and sow </mat-header-cell>
            <mat-cell *matCellDef="let element"> 

              <span *ngIf="!(element.msa_unique_name)">Not Avilable</span>
                    
                <div>
                    <button  mat-icon-button class="accent-fg" matTooltip="MSA Document" 
                    *ngIf="element.msa_unique_name"
                        (click)="downloadMSaDoc(element.id,element.msa_original_name, 'msa_unique_name')">
                        <mat-icon class="s-18">insert_drive_file</mat-icon>
                    </button>
                    
                    
                    <button mat-icon-button class="primary-fg" matTooltip="Upload MSA Document" (click)="hiddenMsacertificate.click()">
                        <mat-icon class="s-18">cloud_upload</mat-icon>
                    </button>

                    <input style="display: none" name="file" type="file" onclick="this.value = null"
                    (change)="uploadMsaSow($event,element.id)" #hiddenMsacertificate />
                </div>

                  
            </mat-cell>
          </ng-container>
          <!-- Demo Recording URL -->
          <ng-container matColumnDef="demo_record_url">
            <mat-header-cell *matHeaderCellDef> Demo Recording URL </mat-header-cell>
            <mat-cell *matCellDef="let element"> 
              <!-- matBadge="{{element?.demo_record_url_cnt}}" matBadgeColor="warn" 
              (click)="viewComment(element)"
                -->
                <button *ngIf="element?.demo_record_url_cnt" (click)="viewDemoUrl(element)" 
                mat-icon-button  matTooltip="View Demo Recording URL">
                <mat-icon class="s-18">remove_red_eye</mat-icon>
                </button>

                <button mat-icon-button  class="teal-fg" matTooltip="Add Demo Recording URL">
              <mat-icon (click)="$event.stopPropagation();addDemoUrl(element.id)"  class="s-18">videocam</mat-icon>
             </button>

            </mat-cell>
          </ng-container>



          <ng-container matColumnDef="onboarded_date">
            <mat-header-cell *matHeaderCellDef> Onboarded On</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.onboarded_date">{{ element.onboarded_date == '-' ? '-' : element.onboarded_date | date:"MM/dd/yyyy" }}</span>
              <span *ngIf="!element.onboarded_date">-</span>
            </mat-cell>
          </ng-container>



          <!-- subdomain Column -->
          <ng-container matColumnDef="hire">
            <mat-header-cell *matHeaderCellDef fxFlex="230px"> hire </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="230px"> U ({{element.hire_users}})
              | M ({{element.mass_mails}}) | C ({{element.contact_search}}) </mat-cell>
          </ng-container>

          <!-- region Column -->
          <ng-container matColumnDef="hr">
            <mat-header-cell *matHeaderCellDef> hr </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.hr_users}} </mat-cell>
          </ng-container>

          <!-- region Column -->
          <ng-container matColumnDef="crm">
            <mat-header-cell *matHeaderCellDef> crm </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.crm_users}} </mat-cell>
          </ng-container>

          <!-- region Column -->
          <ng-container matColumnDef="monthly_pay">
            <mat-header-cell *matHeaderCellDef> amount Paid </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.total_cost}} </mat-cell>
          </ng-container>

          <!-- subscriptionenddate Column -->
          <!-- <ng-container matColumnDef="last_paid">
                  <mat-header-cell *matHeaderCellDef> last paid </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.last_paid | date:"MM/dd/yyyy"}} </mat-cell>
                </ng-container> -->

          <!-- status Column -->
          <ng-container matColumnDef="due_payments">
            <mat-header-cell *matHeaderCellDef> Subscription End On </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.subscr_end_date | date:"MM/dd/yyyy"}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="subscription_plan">
            <mat-header-cell *matHeaderCellDef fxFlex="150px"> subscription plan </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="150px">
              <span *ngIf="element.subscription_plan == 'limited'">Limited</span>
              <span *ngIf="element.subscription_plan == 'unlimited_inc_addons'">Unlimited (Incl. Add-ons)</span>
              <span *ngIf="element.subscription_plan == 'unlimited_excl_addons'">Unlimited (Excl. Add-ons)</span>
            </mat-cell>
          </ng-container>

          <!-- status Column -->
          <ng-container matColumnDef="subscription_cycle">
            <mat-header-cell *matHeaderCellDef fxFlex="150px"> subscription cycle </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="150px">
              <span *ngIf="element.subscription_cycle == 1">Monthly</span>
              <span *ngIf="element.subscription_cycle == 3">Quarterly</span>
              <span *ngIf="element.subscription_cycle == 6">Half-Yearly</span>
              <span *ngIf="element.subscription_cycle == 12">Yearly</span>
            </mat-cell>
          </ng-container>

          <!-- Sales Rep Column -->
          <ng-container matColumnDef="sales_user_name">
            <mat-header-cell *matHeaderCellDef fxFlex="230px"> Sales Rep </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="230px"> {{element.sales_user_name ? element.sales_user_name :
              '-' }} </mat-cell>
          </ng-container>

          <!-- Support Executive Column -->
          <ng-container matColumnDef="support_user_name">
            <mat-header-cell *matHeaderCellDef fxFlex="230px"> Support Executive </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="230px"> {{element.support_user_name ? element.support_user_name
              : '-' }} </mat-cell>
          </ng-container>

          <!-- action Column -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-icon-button class="accent-fg" matTooltip="View" (click)="navigate(element,'')">
                <mat-icon class="s-18">remove_red_eye</mat-icon>
              </button>
              <button mat-icon-button class="accent-fg" matTooltip="Update Sales and Support Exucutive"
                (click)="edit(element)" *ngIf="permissions['users']">
                <mat-icon class="s-18">group_add</mat-icon>
              </button>
              <button mat-icon-button class="red-fg" matTooltip="" (click)="navigatetoImport(element)">
                <mat-icon class="s-18">arrow_right_alt</mat-icon>
              </button>

              <button matBadge="{{element?.comments_cnt}}" matBadgeColor="warn" 
              (click)="viewComment(element)" mat-icon-button  class="teal-fg" matTooltip="Comments"
                    >
                    <mat-icon   (click)="$event.stopPropagation();addComment(element.company_id)"  class="s-18">comments</mat-icon>
             </button>
             
              <button mat-icon-button class="accent-fg" *ngIf="subscr_end[element.company_id]" matTooltip="Renew"
                (click)="renewRoute(element.company_id, 'renew')">
                <mat-icon class="s-18">monetization_on</mat-icon>
              </button>
              <button mat-icon-button class="accent-fg" matTooltip="Cart" *ngIf="!subscr_end[element.company_id]"
                (click)="renewRoute(element.company_id, 'addAddons')">
                <mat-icon class="s-18">shopping_cart</mat-icon>
              </button>

            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
      <div *ngIf="dataSource.data.length == 0" class="text-center">
        <h2 class="heading m-0 py-16 border">No Records Found</h2>
      </div>
    </div>
  </ng-container>
</div>
</cdk-virtual-scroll-viewport>