import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { ConfirmExitRequestApproveComponent } from '../confirm-exit-request-approve/confirm-exit-request-approve.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-add-free-credits-dialog',
  templateUrl: './add-free-credits-dialog.component.html',
  styleUrls: ['./add-free-credits-dialog.component.scss']
})
export class AddFreeCreditsDialogComponent implements OnInit {

  show_title: any;
  addAddonCreditGrup: FormGroup;
  saveData: any;
  subscription_type: any;
  savepromise: Promise<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    public companyDetailService: CompanyDetailsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddFreeCreditsDialogComponent>,
    public dialog: MatDialog
  ) {
    this.addAddonCreditGrup = this.fb.group({
      'total_credits': ['', Validators.required],
    });
    this.subscription_type = this.data.company.subscription_type;
  }

  ngOnInit() {
    this.show_title = this.data.addon_title;
  }

  addCredits() {
    if (this.addAddonCreditGrup.valid) {
      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? you want to give free credits';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
        width: '35%',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.approved) {
          let defaultCharge: any = 0;
          let addon_data: any;
            addon_data = {
              'available': this.addAddonCreditGrup.value.total_credits,
              'is_subscribed': 0
            };
          this.saveData = {
            'company_id': this.data.company_id,
            'subscription_id': this.data.subscription_id,
            'company': this.data.company,
            'data': addon_data,
            'addon_id': this.data.addon_id,
            'addon_slug': this.data.addonSlug,
            'addon_operation_id': 0,
            'is_unlimited': 0,
            'company_name': this.data.company_name,
            'comments': result.commets,
            'plan_slug': this.data.planSlug,
            'price_type': this.data.price_type
          };
          this.savepromise = this.companyDetailService.saveAddonsAsFree(this.saveData).toPromise();
          this.savepromise.then(res => {
            if (res.status == 1) {
              this.commonService.openSnackBar('primary', res.message);
            } else {
              this.commonService.openSnackBar('warn', res.message);
            }
            this.dialogRef.close(1);
          });
        } else { }
      })
    }
  }

}
