<div class="page-layout carded fullwidth inner-scroll">
    <mat-toolbar-row class="secondary-headers w-100-p">
      <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
        <li><a class="text-bold active">Proforma Invoice Setup</a></li>
      </ul>
    </mat-toolbar-row>
  
    <div class="center px-20">
      <div class="content-card mt-12 mb-56">
        <div class="content p-20" fusePerfectScrollbar>
          <div [formGroup]="PeformaForm" class="w-100-p">
            <div fxLayout="row wrap" fxLayoutAlign="space-between start">
                <div fxFlex='45'>
                    <div class="mt-12">
                        <div class="font-size-12 text-bold my-4">Billed By</div>
                        <ckeditor formControlName="billed_by"></ckeditor>
                      </div>
                    </div>
              <div fxFlex="45">
                
                <div class="mt-12">
                  <div class="font-size-12 text-bold my-4">Bank Details</div>
                  <ckeditor formControlName="bank_details"></ckeditor>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="footer">
    <button mat-button class="primary" (click)="save()">Save</button>
  </div>