import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { ReportsService } from 'app/services/reports.service';


@Component({
  selector: 'app-revenue-forecost',
  templateUrl: './revenue-forecost.component.html',
  styleUrls: ['./revenue-forecost.component.scss']
})
export class RevenueForecostComponent implements OnInit {

  displayedColumns: string[] = ['yearmonth', 'total','mmr'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  currentUser = {};
  params: { page: number; limit?: number, searchKey?: any };
  downloadRevenueForecostURL:any;


  constructor(
    private commonService: CommonService,
    private reportService: ReportsService,
    public router: Router, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    this.getList();
    this.downloadRevenueForecostURL = environment.apiUrl+'revenueForecost/1';
    this.searchValue = '73';
  }
  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.reportService.getRevenueForeCost(this.params).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data);
        // this.length = res.data.total;
        // this.pageSize = res.data.per_page;
        // this.index = res.data.current_page;
      }
    })
  }

  exportRevenueForecost() {
    let dollarRate;
    if (this.searchValue) {
      dollarRate = this.searchValue;
    } else {
      dollarRate = 73;
    }
    let fileURL = this.downloadRevenueForecostURL+'/'+dollarRate;
    window.open(fileURL, '_blank');
  }
}
