<h1 mat-dialog-title class='text-uppercase'>Filter</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="userForm">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance="outline">
          <mat-label>Select Users</mat-label>
          <mat-select appMatSearchSelector class="w-100-p" formControlName="users" multiple>
            <app-mat-search-selector placeholder='Search..'></app-mat-search-selector>
            <ng-container *ngFor="let topping of data.users_list">
              <mat-option *appMatSearchSelectorItems [value]="topping">
                {{topping.name}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance="outline">
          <mat-label>Select Modules</mat-label>
          <mat-select appMatSearchSelector class="w-100-p" formControlName="modules" multiple>
            <app-mat-search-selector placeholder='Search..'></app-mat-search-selector>
            <ng-container *ngFor="let topping of data.modules_list">
              <mat-option *appMatSearchSelectorItems [value]="topping">
                {{topping.name}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div> -->
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>From Date</mat-label>
          <input matInput [matDatepicker]="picker" (click)="picker.open()" formControlName="from_date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>To Date</mat-label>
          <input matInput [matDatepicker]="picker1" (click)="picker1.open()" formControlName="to_date">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-button class="warn" class="mr-4" (click)="reset();">Reset</button>
  <button mat-flat-button class='primary' (click)="save();">Apply</button>
</mat-dialog-actions>