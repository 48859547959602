import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-integration-controls',
  templateUrl: './integration-controls.component.html',
  styleUrls: ['./integration-controls.component.scss']
})
export class IntegrationControlsComponent implements OnInit {

  form: FormGroup;
  id: any;
  imap_sync_enable: boolean = false;
  currentUser: any;
  country_id: any = 1;
  payment_gateway: any;
  isLostAccount: any;
  members = [];
  showOpt: boolean = false;
  showOptMarketPlace : boolean = false;
  showOptjob_grabber_limit: boolean;

  constructor(public dialog: MatDialog,
    private fb: FormBuilder, private route: ActivatedRoute,
    private commonService: CommonService,
    private companyDetailService: CompanyDetailsService) {
    this.id = this.route.parent.snapshot.params['id'];
    this.form = this.fb.group({
      resume_parser: [],
      solr_indexing_type: [],
      candidate_search_by_permission: [],
      enable_email_sync: [],
      is_subsidary_enabled: [],
      payment_gateway: [],
      is_job_campaign_enabled: [],
      is_jobgrabber_suggest_email: [],
      is_db_solr_search: [],
      can_account_auto_inactive: [],
      enable_partner_login: [],
      is_integrate_candidate_search : [],
      has_resume_builder :[],
      npa_worldwide_marketplace: [],
      member_id: [],
      oorwin_marketplace: [],
      job_grabber_limit: [],
      job_grabber_limit_enable: [],
      free_job_post_cnt:[]

      // enable_data_backup:[]
    })
  }

  ngOnInit() {
    this.getCurrentUser();
    this.getIntegrationControls();
  }

  getIntegrationControls() {
    this.companyDetailService.integrationControlsData(this.id).subscribe(result => {
      if (result['status'] == 1) {
        this.country_id = result['data'].country_id;
        this.isLostAccount = result['isAccountLost'];
        this.payment_gateway = result['data'].payment_gateway;
        if (result['data'].enable_email_sync) {
          this.imap_sync_enable = true;
        }

        this.form.patchValue({
          resume_parser: String(result['data'].resume_parser),
          solr_indexing_type: String(result['data'].solr_indexing_type),
          candidate_search_by_permission: String(result['data'].candidate_search_by_permission),
          enable_email_sync: this.imap_sync_enable,
          payment_gateway: String(result['data'].payment_gateway),
          is_subsidary_enabled: String(result['data'].is_subsidary_enabled),
          is_job_campaign_enabled: String(result['data'].is_job_campaign_enabled),
          is_jobgrabber_suggest_email: String(result['data'].is_jobgrabber_suggest_email),
          is_db_solr_search: String(result['data'].is_db_solr_search),
          can_account_auto_inactive: String(result['data'].can_account_auto_inactive),
          enable_partner_login: String(result['data'].enable_partner_login),
          is_integrate_candidate_search: String(result['data'].is_integrate_candidate_search),
          has_resume_builder: String(result['data'].has_resume_builder),
          
          npa_worldwide_marketplace: String(result['data'].is_npa),
          member_id: result['data'].npa_id,
          oorwin_marketplace: String(result['data'].oorwin_marketplace),
          job_grabber_limit_enable: String(result['data'].job_grabber_limit),
          free_job_post_cnt: result['data'].free_job_post_cnt,
          job_grabber_limit: result['data'].job_grabber_limit,
          
          
        });

        if (result['data'].is_npa) {
          this.showOpt = true;
        }

        if (result['data'].oorwin_marketplace) {
          this.showOptMarketPlace = true;
        }

        if (result['data'].job_grabber_limit) {
          this.showOptjob_grabber_limit = true;
        }

        

        this.members = result['npa_member_lists'];
      }
    })
  }

  onCtrlChange(column_name) {
    let params = {};
    params['column_name'] = column_name;
    params['company_id'] = this.id;

    
    if (column_name != 'npa_worldwide_marketplace' && column_name != 'oorwin_marketplace' && column_name != 'job_grabber_limit_enable') {

      if (column_name == 'enable_email_sync' && this.form.value[column_name]) {
        params['ctrl_value'] = 1;
      } else if (column_name == 'enable_email_sync' && !this.form.value[column_name]) {
        params['ctrl_value'] = 0;
      } else {
        params['ctrl_value'] = this.form.value[column_name];
      }
      this.companyDetailService.updateIntegrationCtrls(params).subscribe(res => {
        if (res.data.status == 1) {
          this.commonService.openSnackBar('primary', res.data.message);
        } else {
          this.commonService.openSnackBar('warn', res.data.message);
        }
      });
    } else {

      if (this.form.value[column_name] == 1) {
       

        if (column_name == 'npa_worldwide_marketplace') {
          this.showOpt = true;
          this.form.addControl('member_id', this.fb.control('', Validators.required));
          // this.form.controls.member_id.setErrors(Validators.required);
  
          if (this.form.value[column_name] == 1 && this.form.value.member_id) {
            var member_id = this.form.value.member_id;
            params['member_id'] = member_id;
            params['ctrl_value'] = 1;
  
          } else {
            this.commonService.openSnackBar('warn', 'Please Select Member');
            return false;
          }
        }
        else if(column_name == 'oorwin_marketplace')
        {
          this.showOptMarketPlace = true;
          this.form.addControl('free_job_post_cnt', this.fb.control('', Validators.required));
          if (this.form.value[column_name] == 1 && this.form.value.free_job_post_cnt) {
            var free_job_post_cnt = this.form.value.free_job_post_cnt;
            params['free_job_post_cnt'] = free_job_post_cnt;
            params['ctrl_value'] = 1;

          } else {
            this.commonService.openSnackBar('warn', 'Please specify the maximum number of credits for each free job posting.');
            return false;
          }

        }

        else if(column_name == 'job_grabber_limit_enable')
        {
          this.showOptjob_grabber_limit = true;
          this.form.addControl('job_grabber_limit', this.fb.control('', Validators.required));
          if (this.form.value[column_name] == 1 && this.form.value.job_grabber_limit) {
            var job_grabber_limit = this.form.value.job_grabber_limit;
            params['job_grabber_limit'] = job_grabber_limit;
            params['ctrl_value'] = 1;

          } else {
            this.commonService.openSnackBar('warn', 'Please specify the maximum number of credits for job grabber.');
            return false;
          }

        }

        

       
      } else {
        this.showOpt = false;
        this.showOptMarketPlace = false
        this.showOptjob_grabber_limit = false;
        params['ctrl_value'] = 0;
      }

      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? # You want to update the data';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        minWidth: '30%',
        minHeight: '40%',
        data: data
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          params['is_npa'] = this.form.value[column_name];

          this.companyDetailService.updateIntegrationCtrls(params).subscribe(res => {
            if (res.data.status == 1) {
              this.commonService.openSnackBar('primary', res.data.message);
            } else {
              this.commonService.openSnackBar('warn', res.data.message);
            }
          });
        }
      })

    }
  }

  getCurrentUser() {
    this.commonService.getCurrentUser().subscribe(res => {
      if (res.status == 1) {
        this.currentUser = res.data;
      }
    })
  }

  onGatewayChange(event) {

    if (this.currentUser.role_id != 1 || this.country_id == 2) {
      this.commonService.openSnackBar('warn', 'Invalid Request');
    }

    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to change payment gateway';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = {};
        params['company_id'] = this.id;
        params['payment_gateway'] = event.value;

        this.companyDetailService.updatePaymentGateway(params).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
          }
          else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      } else {
        this.form.get('payment_gateway').setValue(String(this.payment_gateway));
      }
    })
  }

  showActivities() {
    let activityData = {
      'company_id': this.id,
      'module_slug': 'integrationControls',
      'show_company_name': false,
      'module_name': 'Integrations'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
