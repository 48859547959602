import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { WindowService } from 'app/services/window.service';
import { environment } from 'environments/environment';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit { 

  hide = true;
  reHide = true;
  emailForm: FormGroup;
  resetForm: FormGroup;
  email: any;
  token: any;
  resetPassword: boolean = false;
  password: any;
  repassword: any;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder, 
    private commonService: CommonService,
    private win: WindowService
  ) {
    sessionStorage.clear();
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.token = this.route.snapshot.params['id'];

    this.emailForm = this.fb.group({
      email: ['', Validators.required],
    });

    this.resetForm = this.fb.group({
      password: ['', Validators.required],
      repassword: ['', Validators.required]
    });

  }

  ngOnInit() {
    
    if (!isEmpty(this.token)) {

      this.commonService.showResetForm(this.token).subscribe(res => {
        if (res.status == 1) {
          this.resetPassword = true;
          this.email = res.data.email;
          this.token = res.data.token;
        } else {
          this.router.navigate(['login']);
        }
      });
    }

  }

  isValidEmail() {

    if (this.emailForm.valid) {
      
      this.email = this.emailForm.value.email;

      let params = {};
      params['email'] = this.email;

      this.commonService.sendResetLinkEmail(params).subscribe(res => {
        if (res.status == 1) {
          this.commonService.openSnackBar('primary', res.message);
          this.emailForm.reset();
          this.emailForm.get('email').clearValidators();
          this.emailForm.get('email').updateValueAndValidity();
        } else {
          this.commonService.openSnackBar('warn', res.message);
        }
      });
    } else {
      this.commonService.openSnackBar('warn', 'Please Enter Valid Email.');
    }
  }

  savePassword() {

    if (this.resetForm.valid) {
      
      this.password = this.resetForm.value.password;
      this.repassword = this.resetForm.value.repassword;

      if (this.password == this.repassword) {
        
        let params = {};
        params['email'] = this.email;
        params['token'] = this.token;
        params['password'] = this.resetForm.value.password;

        this.commonService.resetPassword(params).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);          
            this.router.navigate(['login']);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        });
        
      } else {
        this.commonService.openSnackBar('warn', 'The Passwords you entered do not match. Please re-enter password. ');
      }
    } else {
      this.commonService.openSnackBar('warn', 'Please enter valid password.');
    }
    
  }

  backToLogin()
  {
    this.router.navigate(['login']);
  }
}
