import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModuleActivityComponent } from 'app/common-all/common-module-activity/common-module-activity.component';
import { CompanyPricePlanConfigurationComponent } from 'app/common-all/company-price-plan-configuration/company-price-plan-configuration.component';
import { BouncedSearchComponent } from './bounced-search/bounced-search.component';
import { CronJobLogActivitiesComponent } from './cron-jobs/cron-job-log-activities/cron-job-log-activities.component';
import { CronJobsComponent } from './cron-jobs/cron-jobs.component';
import { DefaultSettingsComponent } from './default-settings/default-settings.component';
import { DoccumentsListingComponent } from '../../common-all/doccuments-listing/doccuments-listing.component';
import { EmailCommunicationComponent } from './email-communication/email-communication.component';
import { MailTemplatesEditComponent } from './mail-templates/mail-templates-edit/mail-templates-edit.component';
import { MailTemplatesListComponent } from './mail-templates/mail-templates-list/mail-templates-list.component';
import { MailTemplatesComponent } from './mail-templates/mail-templates.component';
import { QueueLogsComponent } from './queue-logs/queue-logs.component';

import { AddonComponent } from './subscriptions/addon/addon.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { TrialPlanComponent } from './subscriptions/trial-plan/trial-plan.component';
import { UsersComponent } from './users/users.component';
import { UnsubscribeListComponent } from './unsubscribe-list/unsubscribe-list.component';
import { PerformaInvoiceComponent } from './performa-invoice/performa-invoice.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: ConfigurationComponent
  // },
  {
    path: 'addons',
    component: SubscriptionsComponent,
    children: [
      {
        path: 'trail_mode',
        // component: TrailModeComponent
        component: TrialPlanComponent

      },
      {
        path: 'paid',
        // component: CountrySubscriptionPlanDetailsComponent
        // component: PaidModeComponent
        component: CompanyPricePlanConfigurationComponent
      },
      {
        path: 'edit_addons',
        component: AddonComponent
      },
      {
        path: 'activities/:module_slug',
        component: CommonModuleActivityComponent
      },
      {
        path: '',
        redirectTo: 'trail_mode',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'cronjobs',
    component: CronJobsComponent,
  },
  {
    path: 'cronjobs/log_activities/:id',
    component: CronJobLogActivitiesComponent
  },
  {
    path: 'cpanel_users',
    component: UsersComponent
  },
  {
    path: 'email_communication',
    component: EmailCommunicationComponent,
  },
  {
    path: 'bounced_search',
    component: BouncedSearchComponent
  },
  {
    path: 'queue_logs',
    component: QueueLogsComponent
  },
  {
    path: 'mail_templates',
    component: MailTemplatesComponent,
    children: [
      {
        path: '',
        component: MailTemplatesListComponent
      },
      {
        path: 'edit/:id',
        component: MailTemplatesEditComponent
      }
    ]
  },
  {
    path: 'dataMigrationSettings',
    component: DefaultSettingsComponent,
  },
  {
    path: 'doccumentsListing',
    component: DoccumentsListingComponent,
  },
  {
    path: 'unsubscribeListing',
    component: UnsubscribeListComponent,
  },
  {
    path: 'proformaInvoice',
    component: PerformaInvoiceComponent,
  },
  
  {
    path: '',
    redirectTo: 'addons',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigurationRoutingModule { }
