<h1 mat-dialog-title class='text-uppercase'>Block Email</h1>
<mat-divider></mat-divider>
<form [formGroup]="addUnsubscribeForm" (ngSubmit)="addUnsubscribeEmail()">
    <mat-dialog-content>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
            <div fxFlex='96'>
                <mat-form-field class="w-100-p" appearance='outline'>
                    <mat-label>Mail or Domain</mat-label>
                    <input type="text" formControlName="email" matInput>
                </mat-form-field>
            </div>
            <div fxFlex='96'>
                <mat-form-field class="w-100-p" appearance='outline'>
                    <mat-label>Comments</mat-label>
                    <textarea matInput rows="4" class="textresize" formControlName="comments"></textarea>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-divider></mat-divider>
    <mat-dialog-actions align='end'>
        <button mat-flat-button mat-dialog-close>Cancel</button>
        <button mat-flat-button class='primary mr-4'>Save</button>
    </mat-dialog-actions>
</form>