import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentsComponent } from 'app/common-all/comments/comments.component';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'app/services/common.service';
import { CompanyDetailsService } from 'app/services/company-details.service';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { SendMailDialogueComponent } from '../send-mail-dialogue/send-mail-dialogue.component';


@Component({
  selector: 'app-data-migration',
  templateUrl: './data-migration.component.html',
  styleUrls: ['./data-migration.component.scss'],
})


export class DataMigrationComponent implements OnInit {
  currentStage: any = 'data_backup_responsibility';
  currentStageName: any = 'Data Backup Responsibility';
  stageArr: any = ['data_backup_responsibility', 'data_downloading', 'data_analysis', 'data_confirmation', 'migration_inprogress', 'migration_under_validation', 'migration_completed'];
  usersList: any;
  company_id: any;
  trainingPoc: any;
  estCompletioinDate: any
  completionDate: any;
  dataLocationInfo: any;
  cost: any;
  migrationType: any;
  isStageActive: any = 0;
  isCurrentStage: any = 0;
  stagesData: any;
  isFlowComplete: any = false;
  mainTab: any = 'dataMigration';
  currentActiveStage: any;
  stagePocArr: any;
  clientMigrationTypes = [
    { name: 'Ceipal', value: 'ceipal' },
    { name: 'Zoniac', value: 'zoniac' },
    { name: 'FTP', value: 'ftp' },
    { name: 'Excel', value: 'excel' },
    { name: 'SQL', value: 'sql' },
    { name: 'JSON', value: 'json' }
  ];
  clientMigration: any = "ceipal";
  displayedColumns1: string[] = ['user_name', 'password', 'host', 'port', 'created_on'];
  dataSource1: any;

  standardOpts: any = [
    { module: 'accounts', name: 'Accounts', isDone: false },
    { module: 'contacts', name: 'Contacts', isDone: false },
    { module: 'jobs', name: 'Jobs', isDone: false },
    { module: 'candidates', name: 'Candidates', isDone: false },
    { module: 'employees', name: 'Employees', isDone: false },
    { module: 'assignments', name: 'Assignments', isDone: false },
    { module: 'perm_placements', name: 'Perm Placements', isDone: false },
    { module: 'resource_pool', name: 'Resource Pool', isDone: false },
    { module: 'notes', name: 'Notes', isDone: false }
  ];

  comprehnsiveOpts: any = [
    { module: 'submissions', name: 'Submissions', isDone: false },
    { module: 'pool_submissions', name: 'Pool Submissions', isDone: false },
    { module: 'onboarding', name: 'Onboarding', isDone: false },
    { module: 'interviews', name: 'Interviews', isDone: false },
    { module: 'tasks', name: 'Tasks', isDone: false },
    { module: 'documents', name: 'Documents', isDone: false }
  ];

  @ViewChild('subscribeForm', { static: false }) subscribeForm: FormGroupDirective;
  @ViewChild('child', { static: false }) child: CommentsComponent;

  constructor(private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public router: Router,) {
    this.company_id = this.route.parent.snapshot.params['id'];
  }

  ngOnInit() {
    this.getCPanelUsers();
    this.getPresentStage();
    this.getFtpDetails();
  }

  getFtpDetails() {
    this.commonService.getFTPdetails({ company_id: this.company_id }).subscribe(res => {
      if (res.status == 1) {
        this.dataSource1 = new MatTableDataSource(res.data.data);
      }
    })
  }

  getPresentStage() {

    this.companyDetailService.getCurrentStage({ company_id: this.company_id }).subscribe(res => {
      if (res.data.status == 1) {
        let response = res.data.data;
        this.stagesData = res.data.stagesData;
        this.stagePocArr = res.data.stagePocArr;
        this.currentStage = this.currentActiveStage = response.stage_slug;
        this.currentStageName = response.stage_name;

        let decodedData = response.info;
        if (decodedData.trainingPoc) {
          this.trainingPoc = decodedData.trainingPoc;
        } else {
          this.trainingPoc = this.stagePocArr[this.currentStage];
        }
        this.estCompletioinDate = decodedData.estCompletioinDate;
        this.completionDate = decodedData.completionDate;
        this.dataLocationInfo = decodedData.dataLocationInfo;

        if (this.currentStage == 'data_backup_responsibility') {
          this.standardOpts = decodedData.standardOpts;
          this.comprehnsiveOpts = decodedData.comprehnsiveOpts;
          this.cost = decodedData.cost;
          this.migrationType = decodedData.migrationType;
        }
        else if (this.currentStage == 'migration_inprogress') {
          this.clientMigration = decodedData.clientMigration;
        }

        this.child.getModuleComments({ company_id: this.company_id, module: this.currentStage });
      }
      else if (res.data.status == 0) {
        this.stagePocArr = res.data.stagePocArr;
        this.stagesData = res.data.stagesData;
        this.currentActiveStage = 'data_backup_responsibility';
        this.trainingPoc = this.stagePocArr[this.currentStage];
      }
    })
  }

  getCPanelUsers() {
    let RoleIdArr = {};
    RoleIdArr[3] = 3;
    RoleIdArr[2] = 2;
    this.companyDetailService.getCpanelRoleUsers(RoleIdArr).subscribe(res => {
      if (res.status == 1) {
        this.usersList = res.data.users;
      }
    })
  }

  getStage(stage, stageIndex) {
    this.currentStage = stage;
    this.currentStageName = this.stagesData[stageIndex]['stage_name'];
    this.fetchStageData();
    this.child.getModuleComments({ company_id: this.company_id, module: this.currentStage });
  }

  fetchStageData() {
    this.companyDetailService.getStageInfo({ company_id: this.company_id, stage_slug: this.currentStage }).subscribe(res => {
      this.currentActiveStage = res.data.activeStage;
      if (res.data.status == 1) {
        let decodedData = res.data.data.info;
        if (decodedData.trainingPoc) {
          this.trainingPoc = decodedData.trainingPoc;
        } else {
          this.trainingPoc = this.stagePocArr[this.currentStage];
        }
        this.estCompletioinDate = decodedData.estCompletioinDate;
        this.completionDate = decodedData.completionDate;
        this.dataLocationInfo = decodedData.dataLocationInfo;

        if (this.currentStage == 'data_backup_responsibility') {
          this.standardOpts = decodedData.standardOpts;
          this.comprehnsiveOpts = decodedData.comprehnsiveOpts;
          this.cost = decodedData.cost;
          this.migrationType = decodedData.migrationType;
        }
        else if (this.currentStage == 'migration_inprogress') {
          this.clientMigration = decodedData.clientMigration;
        }
      } else if (res.data.status == 0) {
        this.trainingPoc = this.stagePocArr[this.currentStage];
        this.estCompletioinDate = '';
        this.completionDate = '';
        this.dataLocationInfo = '';
        this.currentActiveStage = 'data_backup_responsibility';
      }
    })
  }

  getNextStage(stage, isDone = false) {

    if (isEmpty(this.trainingPoc) || isEmpty(this.completionDate)) {
      this.commonService.openSnackBar('warn', 'Please submit required fields');
      return false;
    }

    if (isDone) {
      this.isStageActive = 1;
      this.isCurrentStage = 1;
    }
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? You want to update data?';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = this.prepareFormData();
        this.companyDetailService.saveDataMigrationStageInfo(params).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
            if (!this.isFlowComplete) {
              let i = this.stageArr.indexOf(stage);
              let nextStage = this.stageArr[i + 1];
              this.getStage(nextStage, i + 1);
              this.currentStage = nextStage;
              this.isStageActive = 0;
              this.isCurrentStage = 0;
            }
            this.isFlowComplete = false;
            this.fetchStageData();
            this.stagesData = res.data.stagesData;
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      }
    })
  }

  getPreviousStage(stage, isDone = false) {
    if (isDone) {
      this.isStageActive = 0;
      this.isCurrentStage = 0;
    }
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? You want to update data?';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = this.prepareFormData();
        let i = this.stageArr.indexOf(stage);
        let prevStage = this.stageArr[i - 1];
        params['prev_stage'] = prevStage;
        this.companyDetailService.saveDataMigrationStageInfo(params).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.getStage(prevStage, i - 1);
            this.currentStage = prevStage;
            this.isStageActive = 0;
            this.isCurrentStage = 0;
            this.fetchStageData();
            this.stagesData = res.data.stagesData;
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      }
    })
  }

  prepareFormData() {
    let formData = {};
    formData['trainingPoc'] = this.trainingPoc;
    formData['estCompletioinDate'] = this.estCompletioinDate;
    formData['completionDate'] = this.completionDate;
    formData['dataLocationInfo'] = this.dataLocationInfo;
    if (this.currentStage == 'migration_inprogress') {
      formData['clientMigration'] = this.clientMigration;
    }
    if (this.currentStage == 'data_backup_responsibility') {
      formData['standardOpts'] = this.standardOpts;
      formData['comprehnsiveOpts'] = this.comprehnsiveOpts;
      formData['migrationType'] = this.migrationType;
      formData['cost'] = this.cost;
    }

    let payLoadData = {
      company_id: this.company_id,
      stage_slug: this.currentStage,
      stage_name: this.currentStageName,
      is_active: this.isStageActive,
      is_current_stage: this.isCurrentStage,
      isSave: false,
      data: JSON.stringify(formData),
    }
    return payLoadData;
  }

  saveData(isSave = false) {

    if (isEmpty(this.trainingPoc)) {
      this.commonService.openSnackBar('warn', 'Please submit required fields');
      return false;
    }
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? You want to update data?';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = this.prepareFormData();
        params['isSave'] = isSave;
        this.companyDetailService.saveDataMigrationStageInfo(params).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      }
    })
  }

  onDateChange(event, inputName) {
    if (inputName == 'estCompletioinDate') {
      this.estCompletioinDate = moment(this.estCompletioinDate).format("YYYY-MM-DD");
    }
    else if (inputName == 'completionDate') {
      this.completionDate = moment(this.completionDate).format("YYYY-MM-DD");
    }
  }

  getTabData(tabName) {
    this.mainTab = tabName
  }

  processedMigration() {
    this.router.navigate([]).then(result => { window.open("#/company_details/" + this.company_id + "/candidatesmigration/" + this.clientMigration, '_blank'); });
  }

  sendUpdateEmailToCustomer() {
    let dialogueData = {};
    this.companyDetailService.getEmailTemplateData({ slug: 'DATA_MIGRATION_UPDATE_CUSTOMER', company_id: this.company_id }).subscribe(res => {
      if (res.status == 1) {
        dialogueData['emailData'] = res.data.emailData;
        dialogueData['users'] = res.data.users;
        dialogueData['emails'] = res.data.emails;
        dialogueData['company_id'] = this.company_id;
        dialogueData['module'] = 'data_migration_customer_email';
        dialogueData['stage'] = this.currentStage;
        dialogueData['stageName'] = this.currentStageName;

        const dialogRef = this.dialog.open(SendMailDialogueComponent, {
          width: '80%',
          data: dialogueData
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
      else {
        this.commonService.openSnackBar('warn', res.message);
      }
    })
  }

}
