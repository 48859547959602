// export let APP_ENV;
// // console.log(location, location.host.indexOf('oorwincrm.com'));
// if (location.host.indexOf('beanhiredev.com') > -1) {
//     APP_ENV = 'http://beanhiredev.com/cpanel';
// }
// else if (location.host.indexOf('oorwin.com') > -1) {
//     APP_ENV = 'http://oorwin.com/cpanel';
// }
// else {
//     APP_ENV = 'http://localhost/pranay_oorwinAPI/cpanel';
// }
export const ApiUrls = {
    dashboard: {
        revenueReport: 'monthly_revenue_report',
        signups: 'signups',
        closures: 'closures',
        exit_customers: 'exit_customers',
        payments_decliened: 'payments_decliened',
        total_monthly_revenue: 'total_monthly_revenue',
        total_signups_closures: 'total_signups_closures',
        monthlySubscriptionRevenue:'monthly_subscription_revenue',
        monthlySubscriptionChartData:'chart_monthly_subscription_revenue',
        MRRCharData:'chart_mrr_data',
        paymentModeWisePaymentsCharData:'getMonthlyPaymentModeWiseReport',
        refundsChartData:'refunds_chart_data',
        exitCustomersChartData:'chartMonthlyWiseExitCustomers',
        exitCustomersListData:'listMonthlyWiseExitCustomers',
        refundsMonthReport:'refunds_monthly_report',
        unlimitedSubscriptionMrr:'unlimited_mrr_chart',
        get_sales_executive_payments:'get_sales_executive_payments',
        getSalesUsers:'get_sales_users',
        getCpanelRoleUsers:'getCpanelRoleUsers',
        downloadMrrData:'downloadMrr',
        downloadMonthlyPaymentsData:'downloadMonthlyPaymentsData',
        revenueForecost:'revenueForecost',
        getNextSixMonths:'getNextSixMonths',
        revenueForecostChartData:'revenueForecostChartData',
    },
    powerbi: {
        reportsListing:'reportsListing',
        currentMonthRevenueInfo:'currentMonthRevenueInfo',
        getBiReport:'get_bireport',
    },

    commonUrls: {
        login: 'login',
        loginWithUsername: 'loginWithUsername',
        logout: 'logout',
        changePassword: 'profile/change_password',
        currentUserData: 'getCurrentUser',
        clientRefund:'client_refunds',
        modulePermissions:'module_permissions',
        verifyMobileNumber:'checkUserWithMobile',
        sendResetLinkEmail:'sendResetLinkEmail',
        showResetForm: 'password/reset',
        resetPassword: 'password/reset',
        FireBaseLogin:'loginWithFirebase',
        migrationRequests:'migration_requests',
        migrationManagement:'migration_management',
        dataBackupRequests:'data_backup_requests',
        ftpDetails:'ftp_details',
        deleteFTPrecord:'ftp_record_delete',
        deleteMigrationRequest:'migration_request_delete',
        paymentReport:'payment_report',
        exitRequests:'exit_requests',
        demo_requests:'demo_requests',
        client_payments:'client_payments',
        subscription_invoices:'subscription_invoices',
        delete_invoice:'delete_subscription_invoice',
        cpanel_activities:'cpanel_activities',
        ftp_files:'ftp_files',
        getImportMigrateStatusList:'getImportMigrateStatusList',
        dataMigrationStagePoc:'dataMigrationStagePoc',
        saveDataMigrationStagePoc:'saveDataMigrationStagePoc',
        emailTemplateData:'emailTemplateData',
        sendDialogueEmail:'sendDialogueEmail',
        implementationListing:'getImplementationData',
        dataMigrtionListing:'getDataMigrationStageData',
        stripeBalTransactions:'stripeBalanceTransactions',
        getDoccumentsList:'getDoccumentsList',
        saveDoccumentsList:'saveDoccumentsList',
        revokeDoccument:'revokeDoccument',
        deleteDoccument:'deleteDoccument',
        getCompanyData:'getCompanyData',
        sendDoccument: 'send_document',
        // getPlanDetailsUpgrade: 'subscription/get_plan_details_upgrade',
        // getTopupPlanDetails: 'subscription/get_plan_details_topup',
        // getRenewPlanDetails: 'subscription/get_plan_details_renew'
        getPlanDetailsUpgrade: 'subscription/getDetailsForUpgrade',
        getTopupPlanDetails: 'subscription/getDetailsForTopup',
        getRenewPlanDetails: 'subscription/getDetailsForRenew',
        getUpgradeSubscriptionDetails: 'subscription/getUpgradeSubscriptionDetails',
    },
    topcards: {
        labelsCount: 'labelsCunt',
        payments: 'payments/new_report',
        totalactiveusers: 'total_active_users'
    },
    signUpRequest: {
        getTableData: 'signup_request',
        exportAsExcel: 'signup_requests/listDownload',
        saveSignup: 'signup_cpanel',
        updateSignup: 'request_update_signup',
        deleteSignup: 'signup_request/update_status',
        createCompany: 'signup_requests/createCompany',
        setCompany: 'signup_requests/setCompanyData',
        activateMail: 'clients/sendActivationMail',
        // verifyEmail:'verify_user_email',
        verifyEmail:'send_signup_verify_email',
        loginEmail:'send_login_email_customer',
    },
    clients: {
        getListData: 'clients/getList',
        sendActivationEmail: 'clients/sendActivationMail',
        updateTrialCompanyInfo: 'update_company_info',
        deleteTrial: 'delete_trial_company',
        activeCustomers:'active_customers',
        customerActiveUsers:'customer_active_users',
        resetUserPassword:'reset_user_password',
        revokeUser:'revoke_user',
        deleteUser:'delete_user',
        employeeAccess:'employee_access',
        userActivationEmail:'send_user_activation_mail',
        discardReportCustomers:'get_discard_report_customers',
        subscriptionHistory:'client_subscriptions',
        upgradeLinkToCustomer:'upgrade_payment_link_to_customer',
        importExcelFile:'importExcelFile',
        uploadExcelData:'uploadExcelData',
        sendCommunication: 'sendCommunication',
        saveProforma : 'saveProforma',
        downloadProforma : 'downloadProforma',
        getProformaConfig : 'getProformaConfig',
        enableMaintenanceMode: 'enableMaintenanceMode',
        killAllActiveSessions: 'killAllActiveSessions',
        updateSalesSupportUser:'updateSalesSupportUser',
        sendMXRecordsValidationEmail:'doamin_verify_remainder',
        updateRenewalRemainderEmail: 'update_renewal_remainder_email',
        enableFreeAppAccess: 'enable_free_app_access',
        companyList:'clients/companyList',
        endSessions: 'endSessions',
        saveClientNotes: 'saveClientNotes'
    },
    users: {
        getListData: 'cpanel_users/getList',
        save: 'cpanel_user/save',
        updateStatus: 'cpanel_user/update_status',
        deleteUser:'delete_cpanel_user',
        updateMfaInfo:'cpanel_user/update_mfa'
    },
    lead_owners: {
        getListData: 'lead_owner/getList',
        save: 'lead_owner/save',
        deleteRecord: 'lead_owner/delete',
        lead_assignments:'lead_assignments'
    },
    cronjobs: {
        getlist: 'cronjobs/getList',
        cronjobstatus: 'cronjob-status/update',
        getCronEmails: 'get/cronEmails',
        logList: 'cronjob/log_list'
    },
    companyInfo: {
        textRecruitList: 'sms_clients/getList',
        addTextRecruiter: 'sms_clients/saveSmsClientAccount',
        deleteTextRecruiterList: 'sms_clients/delete',
        editTextRecruiter: 'sms_clients/edit',
        getInvoiceList: 'get_invoices',
        uploadInvoicePdf: 'upload_invoice_pdf',
        upload_tds_document: 'upload_tds_document',
        upload_msa_document: 'upload_msa_document',
        updateResumeParser: 'update_resume_parser',
        updateSoloarIndex: 'update_solr_indexing_type',
        cronEmails: 'get/cronEmails',
        cronEmailClean: 'cronjobs/getEmailCleanDataHubuco',
        billingDetails: 'client/billing_details',
        getAccountDetails: 'client/getAccountDetails',
        saveUserLicense: 'add/user_liceses',
        updateUserLicense: 'update/user_liceses',
        saveCredits: 'add/mails_contatcs_credits',
        getCompanySubscriptionDetails: 'get/company_subscription/details',
        saveCompanySubscription: 'update_company_subscription',
        getRenewSubscription: 'get_renew_subscription',
        getDefaultAddons: 'default_addons',
        saveTrialMode: 'saveDefaultAddons',
        getPaidMode: 'paid_mode_data',
        getPaidModeSubscription: 'paid_mode_data_subscription',
        saveDefaultAddonsSubscription: 'saveDefaultAddonsSubscription',
        getPaidmodeCompanySubscription: 'getPaidmodeCompanySubscription',
        getAddon: 'get_addons',
        getUserList: 'users',
        DiscardCompanyReports: 'discard_company_from_reports',
        DiscardCompanyPayments: 'discard_company_from_payments',
        setCompanyType: 'setCompanyType',
        updateCompanyAutoRenewal: 'updateCompanyAutoRenewal',
        mxRecords: 'update_mxrecords',
        editUser: 'users/getUserRoles',
        partnerSearch: 'clients/partner_search',
        enablePartnerSearch: 'clients/enable_partner_search',
        checkUserInPartnerCompanies: 'user/checkUserInPartnerCompanies',
        saveCompanyUser: 'storeUser',
        addRefund: 'addRefund',
        getRefundData: 'getRefundRequests',
        downloadInvoice: 'downloadInvoice',
        downloadOfflineAttchment: 'downloadOfflineAttchment',
        processRefund:'process_refund',
        usersActivities:'user_activity',
        bulkImport: 'bulk_import',
        convertLicenesToPaid:'convert_free_licenses_paid',
        downloadRefundInvoice:'download_refund_invoice',
        updateCandidateSearchbyPermission:'candidate_search_permission',
        integrationControls:'get_integration_controls',
        freeLicnesesRemove:'remove_free_licenses',
        updateIntegrationCtrls:'update_integration_controls',
        getDomainDetails:'mail_gun/get_domain',
        addDomain:'mail_gun/add_domain',
        verifyDomain:'mail_gun/verify_domain',
        deleteDomain:'mail_gun/delete_domain',
        forceAddDomain:'mail_gun/force_update_domain',
        updateNewSubscriptionStartDate:'add_new_start_date',
        upgrade : 'upgrade',
        renew : 'renew',
        add_addons : 'add_addons',
        addon_payment_checkout:'addon_payment_checkout',
        exit_request_addons_data:'exit_request_addons',
        approveExitRequest:'approve_exit_request',
        rejectExitRequest:'reject_exit_request',
        stripePaymentRefund:'stripe/refund',
        stripeConvertFreeLicenses:'stripe/convert_licenses',
        get_pro_rate: 'get_pro_rate',
        paymentGatewayUpdate:'update_payment_gateway',
        // getRenewDetails:'getrenewdetails',
        // updaterenew: 'cpanelrenew',
        renewcheckout: 'renew_checkout',
        upgradecheckout: 'subscription/upgrade_checkout',
        // upgradecheckout: 'upgrade_checkout',
        invoiceURLcheckout: 'create_invoice_url',
        zeroAmountInvoiceCheckoutUrl: 'cpanel_zero_amount_invoice_payment',
        upgradeSubscriptionUrl: 'subscription/upgradeSubscriptionCheckout',
        savegst: 'savegst',
        splitMassMails:'massMailsSplit',
        candidateMigrationRecord:'candidateMigrationRecord',
        getCandidateMigrationRecords:'getCandidateMigrationRecords',
        deleteCandidateMigrationRecords:'deleteCandidateMigrationRecord',
        getCpanelAllRoleUsers:'getAllCpanelUsers',
        exportFtpFailedData:'exportFtpFailedData',
        downloadFailedFTPData:'downloadFailedFTPData',
        isAccountLost:'isAccountLost',
        implemetationActivities:'implemetationActivities',
        saveimplemetationActivities:'saveimplemetationActivities',
        downloadImplementation:'downloadImplementation',
        saveTrainingInfo:'saveTrainingInfo',
        getTrainingInfo:'getTrainingInfo',
        getModuleComments:'getModuleComments',
        saveModuleComments:'saveModuleComments',
        deleteModuleComments:'deleteModuleComments',
        saveDataMigration:'saveDataMigration',
        getStageInfo:'getStageInfo',
        getCurrentStage:'getCurrentStage',
        addCustomerAccountBalance:'addCustomerAccountBalance',
        saveCompanyInfo: 'saveCompanyInfo',
        getCompanyubscriptionPlanDetails: 'get_company_subscription_plan_details',
        getCountrySubscriptionPlanDetails: 'get_country_subscription_plan_details',
        saveCountrySubscriptionPlanDetails:'save_country_subscription_plan_details',
        saveCompanySubscriptionPlanDetails: 'save_company_subscription_plan_details',
        //pranay
        getsubscriptionPlanDetails: 'getsubscriptionPlanDetails',
        saveSubscriptionDetails: 'saveSubscriptionDetails',
        saveAddonsAsFree: 'saveAddonsAsFree',
        //
        getCountryDetails: 'get_coutry_id',
        getPlanDetails:'get_plan_details',
        saveCoupon:'save_coupon',
        couponsImport:'couponsImport',
        getCouponsList: 'get_coupons_list', 
        getCouponsCompanyList: 'getCouponsCompanyList',
        getCouponCompanyId:'get_coupon_companyid',
        deleteCoupon:'delete_coupon',
        getTrialPlanAddons: 'getTrialPlanAddons',
        updateTrialData:'updateTrialData',
        getFreeSubscriptionPlanDetails: 'getFreeSubscriptionPlanDetails',
        getAddonsList: 'getAddonsList',
        saveAddon: 'saveAddon',
        getSubscriptionDetails: 'subscription/get_subscription_info',
        getPaidAddonTypeList:'getPaidAddonTypeList',
        updateAddonTypeData:'updateAddonTypeData',
        updateAddonSubscription: 'updateAddonSubscription',
        saveInvoiceLineItem:'saveInvoiceLineItem',
        getInvoiceLineItem:'getInvoiceLineItem'
    },
    bouncedSearch:{
        getlist: 'companies/getList',
        getbouncedsearchList: 'mail_gun/searchSuppression'

    },
    queues:{
        getQueuesList: 'get_queue_logs',
        runQueue: 'queue/run',
        deleteQueue: 'queue/delete',
        bulkdeleteQueue:'queue/bulkdelete'
    },
    mailTemplates:{
        getTemplates:'getTemplates',
        editTemplate:'editTemplate',
        saveTemplate:'saveTemplate'
    },
    reports:{
        allDueCustomers:'allDueCustomers',
        getCustomerUsageMetrics:'getCustomerUsageMetrics',
        allCustomerUsers : 'allCustomerUsers'
    },
    unsubsribeList: {
        getUnsubscribeList: 'getUnsubscribeList',
        saveUnsubscribeEmail: 'saveUnsubscribeEmail',
        deleteUnsubscribeEmail: 'deleteUnsubscribeEmail'
    },
    from_email: {
        getListData: 'cpanel_from_email/getList',
        save: 'cpanel_from_email/save',
        delete:'cpanel_from_email/delete',
    },
}