import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from 'app/services/excel.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { isEmpty } from 'lodash';
import { EditFieldsComponent } from '../client-all/edit-fields/edit-fields.component';
import { ClientsService } from 'app/services/clients.service';
import { ConfirmExitRequestApproveComponent } from 'app/main/signup-request/confirm-exit-request-approve/confirm-exit-request-approve.component';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';


@Component({
  selector: 'app-exit-customers',
  templateUrl: './exit-customers.component.html',
  styleUrls: ['./exit-customers.component.scss']
})
export class ExitCustomersComponent implements OnInit {
  params: { all_users: number; exit_users: number; limit: number; page: number; type: string; searchKey?: string;status?:string; dateRange?: any; country_id?: any;sort_column?:any; sort_type?:any };
  displayedColumns: string[] = ['customername', 'contactname', 'contactemail','region','existed_date','onboarded_date', 'hire', 'crm','hr','monthly_pay','last_paid','due_payments','subscription_cycle', 'sales_user_name','support_user_name','actions'];
  getTableData: any;
  length: any;
  pageSize: any;
  dataSource;
  index: any;
  searchValue = '';
  dateRangeForm: FormGroup;
  getDateRange = {};
  dateActive = '';
  
  countries = [
    {value:'all', viewValue: 'All'}, { value: '1', viewValue: 'USA ($)' },{ value: '2', viewValue: 'India (₹)' }, { value: '702', viewValue: 'Singapore (S$)'}
  ];
  country_id: any = 'all';

  // ejs datepicker
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  // public lastEnd: Date = this.today;
  public lastEnd: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  // public yearStart = moment().startOf('year');
  public yearEnd: Date = this.today;
  permissions: any;
  logginUser: any;
  // public value: Date[] = [this.yearStart, this.yearEnd];
  // ejs datepicker completed


  constructor(private commonService: CommonService,
    private clientService: ClientsService,
    private excelService: ExcelService,
    public router: Router,
    private fb: FormBuilder,
    public datepipe: DatePipe,
    public dialog:MatDialog
    ) {
      this.commonService.currentUser.subscribe(res => {
        if(res) {
          this.logginUser = res;
        }
    });
      this.dateRangeForm = this.fb.group({
        start_date: [''],
        end_date: ['']
      });
      this.dateRangeForm.valueChanges.subscribe(res => {
        if (res) {
          if (res.start_date && res.end_date) {
            this.getDateRange['startDate'] = this.datepipe.transform(res.start_date, 'yyyy-MM-dd');
            this.getDateRange['endDate'] = this.datepipe.transform(res.end_date, 'yyyy-MM-dd');
            this.params.dateRange = JSON.stringify(this.getDateRange);
            this.params.page = 1;
          }
          else {
            this.params.dateRange = '';
          }
          this.getList();
        }
      });
     }

  ngOnInit() {
    this.params = { all_users: 1, exit_users: 1, limit: 15, page: 1, type: 'paid', status:'exit' };
    this.getList();
    this.commonService.permissions.subscribe(res => {
      this.permissions = res;
    })
  }
  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }
  getList() {
    this.params.country_id = this.country_id;
    this.clientService.getActiveCustomers(this.params).subscribe(result => {
      if (result.status === 1) {
        this.getTableData = result.data;
        this.length = this.getTableData.total;
        this.pageSize = result.data.per_page;
        this.index = result.data.current_page;
        this.dataSource = new MatTableDataSource(result.data.data);
      }
    })
  }

  sortColumnData(column_name, order_type) {
    this.params.sort_column = column_name;
    this.params.sort_type = order_type;
    this.getList();
  }

  exportExcel() {
    this.params.limit = this.length ? this.length : 0;
    if(this.length > 0){
      this.clientService.getActiveCustomers(this.params).subscribe(result => {
        if (result.status === 1) {
          let prepared_data = this.prepareExcelData(result.data.data);
          let sheet_name = 'Exit Customers List ';
          this.excelService.exportAsExcelFile(prepared_data, sheet_name);
        }
      })
    }
    else{
      this.commonService.openSnackBar('warn', 'There is no records found.');
    }
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name'] = element.company_name;
      eachRow['Contact Name'] = element.client_info.first_name+' '+element.client_info.last_name;
      eachRow['Contact Email'] = element.client_info.email;
      eachRow['Region'] = element.region;
      eachRow['Exit Date'] = (!isEmpty(element.company_updated_date))?moment(element.company_updated_date).format('MM/DD/YYYY'):'-';
      eachRow['Onboarded On'] = (!isEmpty(element.onboarded_date))?moment(element.onboarded_date).format('MM/DD/YYYY'):'-';
      eachRow['Hire Users'] = element.hire_users;
      eachRow['Mass Mails'] =  element.mass_mails;
      eachRow['Contact Search'] =  element.contact_search;
      eachRow['CRM Users'] =  element.crm_users;
      eachRow['HR Users'] =  element.hr_users;
      eachRow['$ Total Paid'] = element.total_cost;
      eachRow['Last Paid'] = moment(element.last_paid).format('MM/DD/YYYY');
      eachRow['Due Payments'] = element.is_due;
      eachRow['Subscription Cycle'] = this.subCycle(element.subscription_cycle);
      eachRow['Sales Rep'] = element.sales_user_name ? element.sales_user_name : '-';
      eachRow['Support Executive'] = element.support_user_name ? element.support_user_name : '-';
      ArrData.push(eachRow);
    });
    // console.log('prepared', ArrData);
    return ArrData;
  }

  subCycle(element) {
    if (element === 1) {
      return 'Monthly';
    }
    else if (element === 3) {
      return 'Quarterly';
    }
    else if (element === 6) {
      return 'Half yearly';
    }
    else if (element === 12) {
      return 'Yearly';
    }
    else {
      return '-'
    }
  }

  navigate(element, blank) {
    if (blank) {
      // this.router.navigate(['company_details', element.company_id]);
      this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
    }
    else {
      this.router.navigate(['company_details', element.company_id]);
    }
  }

  refresh() {
    this.searchValue = '';
    this.params = { all_users: 1, exit_users: 1, limit: 15, page: 1, type: 'paid', status:'exit' };
    this.dateRangeForm.reset();
  }
  edit(element) {
    const dialogRef = this.dialog.open(EditFieldsComponent, {
      minWidth: '40%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      if (result) {
        this.params = { all_users: 1, exit_users: 1, limit: 15, page: 1, type: 'paid', status:'exit' };
        this.getList();
      }
    });
  }

  countryChanged() {
    this.params = { all_users: 1, exit_users: 1, limit: 15, page: 1, type: 'paid', status:'exit', country_id: this.country_id };
    this.getList();
  }

  setDate(date_type: string) {
    this.dateActive = date_type;
    switch (date_type) {
      case 'this_week':
        this.dateRangeForm?.setValue({
          start_date: this.datepipe.transform(this.weekStart, 'yyyy-MM-dd'),
          end_date: this.datepipe.transform(this.weekEnd, 'yyyy-MM-dd')
        });
        break;
      case 'this_month':
        this.dateRangeForm.setValue({
          start_date: this.datepipe.transform(this.monthStart, 'yyyy-MM-dd'),
          end_date: this.datepipe.transform(this.monthEnd, 'yyyy-MM-dd')
        });
        break;
      case 'last_month':
        this.dateRangeForm.setValue({
          start_date: this.datepipe.transform(this.lastStart, 'yyyy-MM-dd'),
          end_date: this.datepipe.transform(this.lastEnd, 'yyyy-MM-dd')
        });
        break;
      default:
        this.dateRangeForm.setValue({
          start_date: '',
          end_date: ''
        });
        break;
    }
  }


   

viewComment(items)
{
  let record_id = items.company_id;
  let cnt = items.comments_cnt;
  if(cnt)
  {



    let activityData ={
      'company_id':record_id,
      'show_company_name':true,
      'addon_slug':'client_notes',
      'module_slug':'client_note',
      'module_name':'client_note',
}
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });
    

    dialogRef.afterClosed().subscribe(result => {
    });
    

  }

    // alert(record_id);

}

addComment(record_id) {
  
 const data = [];
 data['title'] = 'Comment';
 data['message'] = 'Comment';
 data['successText'] = 'Yes';
 data['closeText'] = 'No';
 const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
   minWidth: '30%',
   minHeight: '40%',
   data: data
 });
 let saveData: any;
 
 dialogRef.afterClosed().subscribe(result => {
   console.log(result);
   if (result.approved) {

     saveData = {
       'id': record_id,
       'status': 'comment',
       'comments' : result.commets
     };
     this.commonService.saveClientNotes(saveData).subscribe(res => {
       if (res.status == 1) {
         this.commonService.openSnackBar('primary', res.message);
       } else {
         this.commonService.openSnackBar('warn', res.message);
       }
       this.getList();

     });
   } else {

   }
 })
 
}
}
