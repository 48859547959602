import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { isEmpty } from 'lodash';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ConfigurationOptions, ContentOptionsEnum, SortOrderEnum, OutputOptionsEnum } from 'intl-input-phone';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-edit-doccumentlisting-dialog',
  templateUrl: './edit-doccumentlisting-dialog.component.html',
  styleUrls: ['./edit-doccumentlisting-dialog.component.scss']
})
export class EditDoccumentlistingDialogComponent implements OnInit {

  form: FormGroup;
  addData:any = {};
  formData: FormData = new FormData();
  url1:any;
  IsRequired: boolean = false;
  configOption3: ConfigurationOptions;

  constructor(public dialogRef: MatDialogRef<EditDoccumentlistingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private commonService: CommonService) {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      // signdate: ['', Validators.required],
      bank_cheque_attchment: ['', Validators.required]
    })

  }

  ngOnInit() {
    this.setData();
  }
  setData() {
    if (!isEmpty(this.data)) {
      this.IsRequired = true;
      console.log(this.data.sendemail);
      this.form.patchValue({
        title: this.data.title,
        description: this.data.description,
        // signdate: moment(this.data.sign_date).format("YYYY-MM-DD")
        // signdate: this.data.signdate(formatDate(Date, 'yyyy-MM-dd','en')),
        // this.yourForm.controls.controlName.setValue(formatDate(date,'yyyy-MM-dd','en'));
      })

    }
  }


  onSelectFile1(event) {
   
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url1 = event.target['result'];
      }
      //set uploaded file object to 'social_media_logo'
      this.formData.append('doc_attchment', event.target.files[0]);
    }
  }
  
  save() {

    this.addData['id'] = this.data.id;
    this.addData['title'] = this.form.value.title;
    this.addData['description'] = this.form.value.description;
    // this.addData['signdate'] = this.form.value.signdate;
    // this.addData['cpanel_role_id'] = this.form.value.role;

    this.formData.append('requestData', JSON.stringify(this.addData));

    this.commonService.saveDoccumentsList(this.formData).subscribe(res => {
      if (res.status === 1) {
        this.dialogRef.close(true);
        this.commonService.openSnackBar('primary', res.message);
      }
      else {
        let msg = res.message;
        if (typeof res.validations != 'undefined') {
          for (let field_name in res.validations) {
            msg += res.validations[field_name];
          }
        }
        this.commonService.openSnackBar('warn', msg);
      }
    })
    
  }

}
