import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthModule } from './auth/auth.module';
import { SignupRequestModule } from './main/signup-request/signup-request.module';
import { ConfigurationModule } from './main/configuration/configuration.module';
import { ReportsModule } from './main/reports/reports.module';
import { ClientsModule } from './main/clients/clients.module';
import { CompanyDetailsModule } from './main/company-details/company-details.module';
import { DashboardModule } from './main/dashboard/dashboard.module';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => AuthModule
  },
  { 
    path: 'dashboard',
    loadChildren: () => DashboardModule 
  },
  {
    path: 'signup',
    loadChildren: () => SignupRequestModule
  },
  {
    path: 'configuration',
    loadChildren: () => ConfigurationModule
  },
  {
    path: 'reports',
    loadChildren: () => ReportsModule
  },
  {
    path: 'clients',
    loadChildren: () => ClientsModule
  },
  {
    path: 'company_details/:id',
    loadChildren: () => CompanyDetailsModule
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
];
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
