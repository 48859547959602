import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-mail-templates-view',
  templateUrl: './mail-templates-view.component.html',
  styleUrls: ['./mail-templates-view.component.scss']
})
export class MailTemplatesViewComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<MailTemplatesViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
