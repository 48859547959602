import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from 'app/services/excel.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-implementation-data-listing',
  templateUrl: './implementation-data-listing.component.html',
  styleUrls: ['./implementation-data-listing.component.scss']
})
export class ImplementationDataListingComponent implements OnInit {

  displayedColumns: string[] = ['customer_name', 'intiated_by', 'intiated_on','poc' , 'subtype', 'total', 'completed', 'pending','percentage'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  currentUser = {};
  params: { page: number; limit?: number, searchKey?: any, listType?:any };
  viewList:any='in_progress';


  constructor(
    private commonService: CommonService,
    public router: Router, public dialog: MatDialog ,private excelService: ExcelService,private datepipe: DatePipe ) {
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    this.getList();
  }

  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.commonService.getImplementationListing(this.params).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data.data);
        this.length = res.data.total;
        this.pageSize = res.data.per_page;
        this.index = res.data.current_page;
      }
    })
  }

  navigate(element, blank) {
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.id, '_blank'); });
  }

  getViewList(listType) {
    this.viewList = listType;
    this.params.listType = listType;
    this.params.page = 1;
    this.getList();
  }

  exportExcel() {
    this.params.limit = this.length;
    this.commonService.getImplementationListing(this.params).subscribe(result => {
      if (result.status == 1) {
        let prepared_data = this.prepareExcelData(result.data.data);
        let sheet_name = 'Implementatin Customers List ';
        this.excelService.exportAsExcelFile(prepared_data, sheet_name);
      }
    })
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name']        = element.name;
      eachRow['Initiated By']         = element.created;
      eachRow['Initiated On']         = this.datepipe.transform(element.created_at, 'MM/dd/yyyy');
      eachRow['Support POC']          = element.first_name + ' ' + element.last_name;
      eachRow['Subscription Type']    = element.subscription_type;
      eachRow['Total Activities']     = element.totlaActivities;
      eachRow['Activities Completed'] = element.completedCunt;
      eachRow['Activities Pending']   = element.inProgressCunt;
      eachRow['Completed Percentage'] = element.completedPercentage;
      
      ArrData.push(eachRow);
    });
    // console.log('prepared', ArrData);
    return ArrData;
  }

}
