<h1 mat-dialog-title class='text-uppercase text-center'>{{data.title}}</h1>
<mat-divider></mat-divider>
  <div *ngIf="data.title == 'Free Addons'" >
      <mat-dialog-content class=" line-height-32 text-capitalize" [innerHtml]='data.message'></mat-dialog-content>
  </div>
  <div *ngIf="data.title != 'Free Addons'" >
      <mat-dialog-content class=" text-center line-height-32 text-capitalize" [innerHtml]='data.message'></mat-dialog-content>
  </div>
<mat-dialog-actions align='center'>
  <button mat-flat-button class='mr-16' *ngIf="data.closeText" (click)="dialogRef.close(false)">{{data.closeText}}</button>
  <button mat-flat-button (click)="dialogRef.close(true)" class='primary'>{{data.successText}}</button>
</mat-dialog-actions>