<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-header">
    <div class="p-4" fxLayout="row" fxLayoutAlign="start space-between" fxLayoutGap="12px" *ngIf="topcardsCount">
      <div class="mat-elevation-z3 border-radius-8 h-fit" fxFlex="20" fxLayout="row wrap" fxLayoutAlign="center center">
        <h2
          class="font-size-14 text-bold py-8 primary white-space-wrap m-0 text-center text-uppercase border-radius-top-8"
          fxFlex='50'>
          Active
          Customers</h2>
        <p class="text-center accent-fg text-bold font-size-32 m-0" fxFlex="50">
          <a class='cursor-pointer text-decoration-none' [routerLink]="['../active_customers']"
            routerLinkActive="router-link-active">{{topcardsCount.tot_paid_cust}}</a>
        </p>
      </div>
      <div class="mat-elevation-z3  border-radius-8 h-fit" fxFlex="20" fxLayout="row wrap"
        fxLayoutAlign="center center">
        <h2
          class="font-size-14 text-bold py-8 primary white-space-wrap m-0 text-center text-uppercase border-radius-top-8"
          fxFlex='50'>
          TRIAL CUSTOMERS</h2>
        <p class="text-center accent-fg text-bold font-size-32 m-0" fxFlex='50'>
          <a class="cursor-pointer text-decoration-none" [routerLink]="['../trial_active_users']"
            routerLinkActive="router-link-active">{{topcardsCount.tot_trial_cust}}</a>
        </p>
      </div>
      <div class="mat-elevation-z3  border-radius-8 h-fit" fxFlex="20" fxLayout="row wrap"
        fxLayoutAlign="center center">
        <h2
          class="font-size-14 text-bold py-8 primary white-space-wrap m-0 text-center text-uppercase border-radius-top-8"
          fxFlex='50'>
          EXIT CUSTOMERS</h2>
        <p class="text-center accent-fg text-bold font-size-32 m-0" fxFlex='50'>
          <a class="cursor-pointer text-decoration-none" [routerLink]="['../exit_users']"
            routerLinkActive="router-link-active">{{topcardsCount.tot_exit_cust}}</a>
        </p>
      </div>
      <div class="mat-elevation-z3  border-radius-8 h-fit" fxFlex="20" fxLayout="row wrap"
        fxLayoutAlign="center center">
        <h2
          class="font-size-14 text-bold py-8  primary white-space-wrap m-0 text-center text-uppercase border-radius-top-8"
          fxFlex='50'>
          SIGNUP PENDING</h2>
        <p class="text-center accent-fg text-bold font-size-32 m-0" fxFlex='50'>
          <a class="cursor-pointer text-decoration-none" [routerLink]="['../signup_requests']"
            routerLinkActive="router-link-active"
            routerLinkActive="router-link-active">{{topcardsCount.signup_pending_cust}}</a>
        </p>
      </div>
      <div class="mat-elevation-z10  border-radius-8" fxFlex="15" *ngIf="false">
        <h2 class="font-size-14 text-bold py-8   m-0 text-center text-uppercase border-radius-top-8">PASS DUE
          DATE</h2>
        <p class="text-center accent-fg text-bold font-size-24">
          <a class="cursor-pointer text-decoration-none" [routerLink]="['../pass_due_date']"
            routerLinkActive="router-link-active">{{topcardsCount.passed_due_date}}</a>
        </p>
      </div>
      <div class="mat-elevation-z3  border-radius-8 h-fit" fxFlex="20" fxLayout="row wrap"
        fxLayoutAlign="center center">
        <h2
          class="font-size-14 text-bold primary white-space-wrap py-8  m-0 text-center text-uppercase border-radius-top-8"
          fxFlex='50'>
          Total
          Active Users</h2>
        <p class="text-center accent-fg text-bold font-size-32 m-0" fxFlex='50'>
          <a class="cursor-pointer text-decoration-none" [routerLink]="['../total_active_users']"
            routerLinkActive="router-link-active">{{topcardsCount.active_users}}</a>
        </p>
      </div>
      <div class="mat-elevation-z10  border-radius-8" fxFlex="15" *ngIf="false">
        <h2 class="font-size-14 text-bold py-8  m-0 text-center text-uppercase border-radius-top-8">Current
          Month</h2>
        <p class="text-center accent-fg text-bold font-size-24">
          <a class="cursor-pointer text-decoration-none" [routerLink]="['../payments']"
            routerLinkActive="router-link-active">{{topcardsCount.payments_listing}}</a>
        </p>
      </div>

    </div>
  </mat-toolbar-row>
  <router-outlet></router-outlet>
</div>