import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonHelpersService {

  constructor() { }

  getCurrencySymbol(country_id) {
    if (country_id == 1) {
      return '$';
    }
    if (country_id == 2) {
      return '₹';
    } else {
      return '$';
    }
  }
}
