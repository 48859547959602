import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cpanel-activities',
  templateUrl: './cpanel-activities.component.html',
  styleUrls: ['./cpanel-activities.component.scss']
})
export class CpanelActivitiesComponent implements OnInit {

  displayedColumns: string[] = ['customer_name', 'activity', 'details', 'created_by' ,'created_on'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  more = [];
  params: { page: number; limit?: number, searchKey?: any, status?: any, module_slug:any};

  constructor(
    private commonService: CommonService,
    public router: Router, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.params = { limit:15, page:1, status:0, module_slug: 'cpanel' };
    this.getList();
  }

  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.commonService.getCpanelActivities(this.params).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data.data);
        this.length = res.data.total;
        this.pageSize = res.data.per_page;
        this.index = res.data.current_page;
        // res.data.data.filter((val, index) => {
        //   this.more[index] = false;
        // })
      }
    })
  }

  navigate(element, blank) {
    // this.router.navigate(['company_details', element.company_id]);
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }
}
