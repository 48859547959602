import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/services/common.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { ApproveExitRequestComponent } from '../approve-exit-request/approve-exit-request.component';
import { ConfirmExitRequestApproveComponent } from '../confirm-exit-request-approve/confirm-exit-request-approve.component';

@Component({
  selector: 'app-exit-request',
  templateUrl: './exit-request.component.html',
  styleUrls: ['./exit-request.component.scss']
})
export class ExitRequestComponent implements OnInit {
  
  displayedColumns: string[] = ['client', 'email', 'name', 'contactnumber', 'date', 'subscr_end_date', 'closing_type','actions'];
  dataSource;
  searchValue = '';
  getlist: any;
  getTableData: any;
  params: { limit: number; page: number; searchKey?: string; status: number; };
  length: any;
  pageSize: any;
  index: any;
  exit_request_permissions:any= {};
  more = {};

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  constructor(private commonservice: CommonService,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    public router: Router,
    ) {
  }

  ngOnInit() {
    this.getModulePermissions();
    this.getExitRequests('pending');
  }

  applyFilter(filterValue: string) {
    this.params['searchKey'] = filterValue;
    this.params['page'] = 1;
    this.getExitRequests('');
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.params.status = this.getTableData.data[0].status;
    this.getExitRequests('');
  }
  getExitRequests(getnav) {
    switch (getnav) {
      case 'pending':
        this.getlist = getnav;
        this.params = { limit: 15, page: 1, status: 0 }
        break;
      case 'approved':
        this.getlist = getnav;
        this.params = { limit: 15, page: 1, status: 1 }
        break;
      case 'rejected':
        this.getlist = getnav;
        this.params = { limit: 15, page: 1, status: 2 }
        break;
      default:
        break;
    }
    this.commonservice.getExitRequests(this.params).subscribe(result => {
      if (result) {
        this.getTableData = result.data;
        this.length = this.getTableData.total;
        this.pageSize = result.data.per_page;
        this.index = result.data.current_page;
        this.dataSource = new MatTableDataSource(result.data.data);
        result.data.data.filter((val, index) => {
          this.more[index] = false;
        })
      }
    })
  }

  navigate(element, blank) {
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }

  approveExitRequest(company_id, req_id) {
    let payload = {};
    payload['company_id'] = company_id;
    payload['req_id'] = req_id;
    const dialogRef = this.dialog.open(ApproveExitRequestComponent, {
      width: '65%',
      data: payload
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getExitRequests('pending');
      }
    });
  }

  rejectExitRequest(record) {
      let req_id = record.id;
      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? # You want to reject';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      data['otherText'] = 'Upon reject, paid amount will be added to customer wallet';
      if (record.closing_type == 1) {
        data['showOtherText'] = true;
      }

      const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      width: '50%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        let payload = {};
        payload['request_id'] = req_id;
        payload['comments'] = result.commets;

        this.commonservice.rejectExitRequest(payload).subscribe(res => {
          if (res.status) {
            this.commonservice.openSnackBar('primary', res.data.message);
            this.getExitRequests('pending');
          } else {
            this.commonservice.openSnackBar('warn', res.data.message);
          }
        });
      } 
    })

  }

  getModulePermissions() {
    let permission_params = {};
    permission_params['module'] = 'exit_requests';
    this.commonservice.getModulePermissions(permission_params).subscribe(res => {
        if (res.status == 1) {
            this.exit_request_permissions = res.data.permissions;
            this.commonservice.setpermissions(this.exit_request_permissions);
        }
    })
}

}
