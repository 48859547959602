import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-custom-confirm-dialog',
  templateUrl: './custom-confirm-dialog.component.html',
  styleUrls: ['./custom-confirm-dialog.component.scss']
})
export class CustomConfirmDialogComponent implements OnInit {

  DialogFrom: FormGroup;

  constructor(public dialogRef: MatDialogRef<CustomConfirmDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.DialogFrom = this.fb.group({
      check_data1: ['true'],
      email: [this.data.email, Validators.required],
      comments: ['', Validators.required],
    });

    this.data.message = this.data.message.replace("#", "<br>");
  }

  ngOnInit() {
  }

  sendConfirmData() {

    if (isEmpty(this.DialogFrom.value['comments'])) {
      return false;
    }

    let postData = {};
    postData['check_data1'] = this.DialogFrom.value['check_data1'];
    postData['customer_email'] = this.DialogFrom.value['email'];
    postData['comments'] = this.DialogFrom.value['comments'];
    postData['status'] = true;
    this.dialogRef.close(postData);
  }

}
