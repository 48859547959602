import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { WindowService } from 'app/services/window.service';
import { environment } from 'environments/environment';
import * as firebase from "firebase/app";
// import "firebase/auth";
import { initializeApp } from "firebase/app";
import { ConfigurationOptions, ContentOptionsEnum, SortOrderEnum, OutputOptionsEnum } from 'intl-input-phone';

const app = initializeApp(environment.firebaseConfig);
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})

export class LoginComponent implements OnInit {

  hide = true;
  forgotpw = false;
  enterOTP = false;
  loginForm: FormGroup;
  OTPForm: FormGroup;
  confirmOtp: boolean = false;
  windowRef: any;
  phoneNumber: any;
  phoneNumberExt: any;
  verificationCode: string;
  user: any;
  showOtp: boolean = false;
  email: any;
  password: any;

  configOption3: ConfigurationOptions;
  NumberModel2: string = "+91";
  SelectedCountryISOCode: string = "IN";
  IsRequired: boolean = false;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private router: Router,
    private fb: FormBuilder,
    private commonService: CommonService,
    private win: WindowService
  ) {
    sessionStorage.clear();
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      // mobile: ['', Validators.required],
    });

    this.OTPForm = this.fb.group({
      user_otp: ['', Validators.required]
    });

    this.configOption3 = new ConfigurationOptions();
    this.configOption3.SelectorClass = "OptionType3";
    this.configOption3.OptionTextTypes = [];
    this.configOption3.OptionTextTypes.push(ContentOptionsEnum.Flag);
    this.configOption3.OptionTextTypes.push(ContentOptionsEnum.CountryName);
    this.configOption3.OptionTextTypes.push(ContentOptionsEnum.CountryPhoneCode);
    this.configOption3.SortBy = SortOrderEnum.CountryPhoneCode;
    this.configOption3.OutputFormat = OutputOptionsEnum.Number;

  }

  ngOnInit() {
    this.windowRef = this.win.windowRef;
    // if (!firebase.apps.length) {
    //   firebase.initializeApp(environment.firebaseConfig);
    // }
    // this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    //   'size': 'invisible',
    //   'callback': function (response) {
    //   },
    //   'expired-callback': function () {
    //   }
    // });

    // this.windowRef.recaptchaVerifier.render();
  }


  login() {

      let saveData = {};
      saveData['email'] = this.email = this.loginForm.value.email;
      saveData['password'] = this.password = this.loginForm.value.password;

      if (this.showOtp) {
        
        if (this.OTPForm.valid) {
          saveData['email'] = this.email;
          saveData['password'] = this.password;
          saveData['otp'] = this.OTPForm.value.user_otp;

          this.commonService.getLogin(saveData).subscribe(res => {
            if (res.status == 1) {
              sessionStorage.setItem('token', res.data.token);
              this.router.navigate(['dashboard'])
            } else {
              this.commonService.openSnackBar('warn', res.message);
            }
          });
        }
      } else {
        if (this.loginForm.valid) {
          this.commonService.getLoginWithUsername(saveData).subscribe(res => {
            if (res.status == 1) {
              this.commonService.openSnackBar('primary', 'OTP Sent To Your Registered Email Id.');
              this.showOtp = true;
            } else {
              this.commonService.openSnackBar('warn', res.message);
            }
          });
        }
      }
    
  }

  resendOtp() {
    let saveData = {};
    saveData['email'] = this.email;
    saveData['password'] = this.password;

    this.commonService.getLoginWithUsername(saveData).subscribe(res => {
      if (res.status == 1) {
        this.commonService.openSnackBar('primary', 'OTP Sent To Your Registered Email Id.');

        this.showOtp = true;
        this.loginForm.addControl('user_otp', this.fb.control('', Validators.required));
      } else {
        this.commonService.openSnackBar('warn', res.message);
      }
    });
  }

  forgotPassword() {
    this.router.navigate(['forgot_password']);
  }

  sendLoginCode() {
    if (this.loginForm.valid) {
      // console.log(this.loginForm.value.mobile.CountryModel.CountryPhoneCode); return;
      if (!this.IsRequired) {
        this.commonService.openSnackBar('warn', 'Please enter valid mobile number');
        return;
      }

      this.phoneNumber = this.loginForm.value.mobile.Number;
      this.phoneNumberExt = this.loginForm.value.mobile.CountryModel.CountryPhoneCode;
      let params = {};
      params['mobile'] = this.phoneNumber;
      params['mobile_ext'] = this.phoneNumberExt;
      this.commonService.verifyUserMobileNumber(params).subscribe(res => {
        if (res.status == 1) {
          this.enterOTP = true;
          const appVerifier = this.windowRef.recaptchaVerifier;
          const num = res.data.mobile;

          // firebase.auth().signInWithPhoneNumber(num, appVerifier).then(result => {
          //   this.windowRef.confirmationResult = result;
          // }).catch(error => console.log(error));

        } else {
          this.commonService.openSnackBar('warn', res.message);
        }
      });
    } else {
      this.commonService.openSnackBar('warn', 'Please enter valid mobile number');
    }
  }

  verifyLoginCode() {
    if (this.OTPForm.valid) {
      this.confirmOtp = true;
      let verifyToken: any;
      this.verificationCode = this.OTPForm.value.user_otp;
      this.windowRef.confirmationResult.confirm(this.verificationCode).then(result => {
        // this.user = result.user;
        if (result.user) {
          // firebase.auth().currentUser.getIdToken(true).then(res => {
          //   this.verifyFireBaseToken(res);
          // }).catch(error => {
          //   this.commonService.openSnackBar('warn', 'Something went wrong');
          //   this.backToLogin();
          // });
        }
      }).catch(error => {
        this.commonService.openSnackBar('warn', 'Incorrect code entered');
        this.backToLogin();
      });
    }
  }

  verifyFireBaseToken(verifyToken) {
    let params = {};
    params['token'] = verifyToken;
    params['mobile'] = this.phoneNumber;
    params['mobile_ext'] = this.phoneNumberExt;

    this.commonService.loginWithFireBase(params).subscribe(res => {
      if (res.status == 1) {
        sessionStorage.setItem('token', res.data.token);
        this.router.navigate(['dashboard'])
      } else {
        this.commonService.openSnackBar('warn', res.message);
      }
    });
  }

  backToLogin() {
    this.enterOTP = false;
    this.confirmOtp = false;
    this.loginForm.reset();
    this.loginForm.clearValidators();
    this.OTPForm.reset();
    this.OTPForm.clearValidators();
  }

  requiredFlagChange(isRequire: boolean) {
    this.IsRequired = isRequire;
    console.log(this.IsRequired);
  }

}
