<mat-toolbar-row class="secondary-headers w-100-p px-20">
    <ul class="nav m-0 p-0" fxFlex="auto">
        <li><a class="text-bold" (click)="getList('listing', true)"
                [ngClass]="{'active': listType == 'listing'}">Listing</a>
        </li>
        <li><a class="text-bold" (click)="getList('invoice_url', true)"
                [ngClass]="{'active': listType == 'invoice_url'}">Generated Invoices</a>
        </li>
    </ul>
</mat-toolbar-row>

<ng-container *ngIf="dataSource && listType == 'listing'">
    <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12">
        <div class="search-wrapper" fxFlex="30">
            <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
                [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Search Here ...">
            <mat-icon class="search_icon">search</mat-icon>
            <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
                close
            </mat-icon>
        </div>
        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
            <form [formGroup]="form">
                <mat-form-field appearance="outline">
                    <mat-select formControlName="selectVal" (selectionChange)='selectChange($event.value)'>
                        <mat-option *ngFor="let item of selection" [value]='item.value'>
                            {{item.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
                [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
        </div>
    </div>
    <div class="px-12 pb-12">
        <mat-table [dataSource]="dataSource">
            <!-- invoiceRef Column -->
            <ng-container matColumnDef="invoiceRef">
                <mat-header-cell *matHeaderCellDef> Invoice Ref# </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.reference_id}}
                </mat-cell>
            </ng-container>
            <!-- transactionId Column -->
            <ng-container matColumnDef="transactionId">
                <mat-header-cell *matHeaderCellDef fxFlex='200px'> Transaction ID </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="200px">
                    <span *ngIf="element.transaction_id">{{element.transaction_id}}</span>
                    <span *ngIf="!element.transaction_id">-</span>
                </mat-cell>
            </ng-container>

            <!-- 	Status Column -->
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.status === 1 ? 'paid' : 'not paid'}}
                </mat-cell>
            </ng-container>

            <!-- Total Credits Column -->
            <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef> Amount ({{getCurrencySymbol()}}) </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span *ngIf="element.sub_total >= 0">{{element.total}}</span>
                    <span *ngIf="element.sub_total < 0"> 0.00</span>
                </mat-cell>
            </ng-container>


            <!-- Total Credits Column -->
            <ng-container matColumnDef="incentive_amount">
                <mat-header-cell *matHeaderCellDef> Incentive Amount ({{getCurrencySymbol()}}) </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span *ngIf="element.refund_amount > 0 "> {{element.refund_amount}}</span>
                    <span *ngIf="(element.refund_amount == null || element.refund_amount == 0) && element.total >= 0 ">
                        0.00</span>
                    <span *ngIf="element.total < 0"> {{convertToPositive(element.total)}}</span>
                </mat-cell>
            </ng-container>

            <!-- Total Credits Column -->
            <ng-container matColumnDef="refund_amount">
                <mat-header-cell *matHeaderCellDef> Refund Amount ({{getCurrencySymbol()}}) </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span *ngIf="element.refund_amount > 0 "> {{element.refund_amount}}</span>
                    <span *ngIf="(element.refund_amount == null || element.refund_amount == 0) && element.total >= 0 ">
                        0.00</span>
                    <span *ngIf="element.total < 0"> {{convertToPositive(element.total)}}</span>
                </mat-cell>
            </ng-container>





            <!-- 	Credits Expiry Column -->
            <ng-container matColumnDef="paidon">
                <mat-header-cell *matHeaderCellDef>Paid on </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.updated_at |date:"MM/dd/yyyy"}}
                </mat-cell>
            </ng-container>
            <!-- 	Status Column -->
            <ng-container matColumnDef="payment_mode">
                <mat-header-cell *matHeaderCellDef> Payment Mode </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.payment_mode}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef fxFlex='200px'> description </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex='200px'> {{element.description}}
                </mat-cell>
            </ng-container>


            <!-- action Column -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-icon-button class="accent-fg" matTooltip="Invoice" *ngIf="element.invoice_attachment"
                        (click)="downloadInvocieDoc(element.id, element.invoice_attachment, 'invoice_attachment')">
                        <mat-icon class="s-18">insert_drive_file</mat-icon>
                    </button>
                    <span *ngIf="!(element.invoice_attachment)">-</span>

                    <button mat-icon-button class="primary-fg" matTooltip="Payment Proof" *ngIf="element.offline_payment_attachment"
                        (click)="downloadInvocieDoc(element.id, element.offline_payment_attachment, 'offline_payment_attachment')">
                        <mat-icon class="s-18">cloud_download</mat-icon>
                    </button>
                    <button mat-icon-button class="orange-fg" matTooltip="Intiate Refund"
                        *ngIf="(isActiveSubscriptionId(element.subscription_id) && availableRefundAmount(element) && checkSubscriptionActive() && element.total > 0 && element.payment_gateway_id == 2 && element.payment_mode == 'card' ) && (permissions && permissions['clients.invoice.refund_invoice'])"
                        (click)="refundInvoice(element)" [disabled]="isLostAccount">
                        <mat-icon class="s-18">account_balance_wallet</mat-icon>
                    </button>
                    <button mat-icon-button class="primary-fg" matTooltip="Upload invoice pdf" (click)="uploadInvoice(element)">
                        <mat-icon class="s-18">cloud_upload</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tds_certificate">
                <mat-header-cell *matHeaderCellDef> TDS Certificate </mat-header-cell>
                <mat-cell *matCellDef="let element">

                    <span *ngIf="!(element.is_tds_paid)">Not Avilable</span>
                    

                    <div *ngIf="(element.is_tds_paid)">
                    <button mat-icon-button class="accent-fg" matTooltip="TDS Certificate" *ngIf="element.tds_unique_name"
                        (click)="downloadInvocieDoc(element.id, element.tds_original_name, 'tds_unique_name')">
                        <mat-icon class="s-18">insert_drive_file</mat-icon>
                    </button>
                    
                    
                    <button mat-icon-button class="primary-fg" matTooltip="Upload TDS Certificate" (click)="hiddenTDScertificate.click()">
                        <mat-icon class="s-18">cloud_upload</mat-icon>

                    </button>

                    


                    <input style="display: none" name="file" type="file" onclick="this.value = null"
                    (change)="uploadTDSDocument($event,element.id)" #hiddenTDScertificate />
                </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div *ngIf="dataSource.data.length == 0" class="text-center">
            <h2 class="heading m-0 py-16 border">No Records Found</h2>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dataSource && listType == 'invoice_url'">
    <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12">
        <div class="search-wrapper" fxFlex="30">
            <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
                [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Search Here ...">
            <mat-icon class="search_icon">search</mat-icon>
            <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
                close
            </mat-icon>
        </div>
        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
            <form [formGroup]="form">
                <mat-form-field appearance="outline">
                    <mat-select formControlName="selectVal" (selectionChange)='selectChange($event.value)'>
                        <mat-option *ngFor="let item of selection" [value]='item.value'>
                            {{item.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
                [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
        </div>
    </div>
    <div class="px-12 pb-12">
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="invoice_id">
                <mat-header-cell *matHeaderCellDef> Invoice ID </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.invoice_id}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="invoice_url">
                <mat-header-cell *matHeaderCellDef>URL </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase">{{element.invoice_url}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="payment">
                <mat-header-cell *matHeaderCellDef> Payment </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.payment_type}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{getPaymentStatus(element.status)}} </mat-cell>
              </ng-container>

            <ng-container matColumnDef="created_on">
                <mat-header-cell *matHeaderCellDef>Created On </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at |date:"MM/dd/yyyy"}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="due_on">
                <mat-header-cell *matHeaderCellDef>{{params.status == 1?'Paid On':'Due Date'}} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ params.status == 1? element.updated_at :element.due_date |date:"MM/dd/yyyy" }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef fxFlex='150px'> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="164px">
                  <button *ngIf="params.status == 0" mat-icon-button class="red-fg" matTooltip="Delete" (click)="deleteRecord(element)" [disabled]="isLostAccount">
                    <mat-icon class="s-18">delete</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div *ngIf="dataSource.data.length == 0" class="text-center">
            <h2 class="heading m-0 py-16 border">No Records Found</h2>
        </div>
    </div>
</ng-container>

<div class='footer'>
    <button mat-button (click)="showActivities()">Activities</button>
</div>