
class MasterData {
    public countriesList = [  
        {  
           "id":1,
           "name":"USA"
        },
        {  
           "id":2,
           "name":"India"
        },
        {  
           "id":4,
           "name":"Afghanistan"
        },
        {  
           "id":8,
           "name":"Albania"
        },
        {  
           "id":12,
           "name":"Algeria"
        },
        {  
           "id":16,
           "name":"American Samoa"
        },
        {  
           "id":20,
           "name":"Andorra"
        },
        {  
           "id":24,
           "name":"Angola"
        },
        {  
           "id":28,
           "name":"Antigua and Barbuda"
        },
        {  
           "id":31,
           "name":"Azerbaijan"
        },
        {  
           "id":32,
           "name":"Argentina"
        },
        {  
           "id":36,
           "name":"Australia"
        },
        {  
           "id":40,
           "name":"Austria"
        },
        {  
           "id":44,
           "name":"Bahamas"
        },
        {  
           "id":48,
           "name":"Bahrain"
        },
        {  
           "id":50,
           "name":"Bangladesh"
        },
        {  
           "id":51,
           "name":"Armenia"
        },
        {  
           "id":52,
           "name":"Barbados"
        },
        {  
           "id":56,
           "name":"Belgium"
        },
        {  
           "id":60,
           "name":"Bermuda"
        },
        {  
           "id":64,
           "name":"Bhutan"
        },
        {  
           "id":68,
           "name":"Bolivia"
        },
        {  
           "id":70,
           "name":"Bosnia and Herzegovina"
        },
        {  
           "id":72,
           "name":"Botswana"
        },
        {  
           "id":74,
           "name":"Bouvet Island"
        },
        {  
           "id":76,
           "name":"Brazil"
        },
        {  
           "id":84,
           "name":"Belize"
        },
        {  
           "id":86,
           "name":"British Indian Ocean Territory"
        },
        {  
           "id":90,
           "name":"Solomon Islands"
        },
        {  
           "id":92,
           "name":"British Virgin Islands"
        },
        {  
           "id":96,
           "name":"Brunei"
        },
        {  
           "id":100,
           "name":"Bulgaria"
        },
        {  
           "id":104,
           "name":"Myanmar"
        },
        {  
           "id":108,
           "name":"Burundi"
        },
        {  
           "id":112,
           "name":"Belarus"
        },
        {  
           "id":116,
           "name":"Cambodia"
        },
        {  
           "id":120,
           "name":"Cameroon"
        },
        {  
           "id":124,
           "name":"Canada"
        },
        {  
           "id":132,
           "name":"Cape Verde"
        },
        {  
           "id":136,
           "name":"Cayman Islands"
        },
        {  
           "id":140,
           "name":"Central African Republic"
        },
        {  
           "id":144,
           "name":"Sri Lanka"
        },
        {  
           "id":148,
           "name":"Chad"
        },
        {  
           "id":152,
           "name":"Chile"
        },
        {  
           "id":156,
           "name":"China"
        },
        {  
           "id":158,
           "name":"Taiwan"
        },
        {  
           "id":162,
           "name":"Christmas Island"
        },
        {  
           "id":166,
           "name":"Cocos Islands"
        },
        {  
           "id":170,
           "name":"Colombia"
        },
        {  
           "id":174,
           "name":"Comoros"
        },
        {  
           "id":175,
           "name":"Mayotte"
        },
        {  
           "id":178,
           "name":"Republic of the Congo"
        },
        {  
           "id":180,
           "name":"Democratic Republic of the Congo"
        },
        {  
           "id":184,
           "name":"Cook Islands"
        },
        {  
           "id":188,
           "name":"Costa Rica"
        },
        {  
           "id":191,
           "name":"Croatia"
        },
        {  
           "id":192,
           "name":"Cuba"
        },
        {  
           "id":196,
           "name":"Cyprus"
        },
        {  
           "id":203,
           "name":"Czech Republic"
        },
        {  
           "id":204,
           "name":"Benin"
        },
        {  
           "id":208,
           "name":"Denmark"
        },
        {  
           "id":212,
           "name":"Dominica"
        },
        {  
           "id":214,
           "name":"Dominican Republic"
        },
        {  
           "id":218,
           "name":"Ecuador"
        },
        {  
           "id":222,
           "name":"El Salvador"
        },
        {  
           "id":226,
           "name":"Equatorial Guinea"
        },
        {  
           "id":231,
           "name":"Ethiopia"
        },
        {  
           "id":232,
           "name":"Eritrea"
        },
        {  
           "id":233,
           "name":"Estonia"
        },
        {  
           "id":234,
           "name":"Faroe Islands"
        },
        {  
           "id":238,
           "name":"Falkland Islands"
        },
        {  
           "id":239,
           "name":"South Georgia and the South Sandwich Islands"
        },
        {  
           "id":242,
           "name":"Fiji"
        },
        {  
           "id":246,
           "name":"Finland"
        },
        {  
           "id":248,
           "name":"Aland Islands"
        },
        {  
           "id":250,
           "name":"France"
        },
        {  
           "id":254,
           "name":"French Guiana"
        },
        {  
           "id":258,
           "name":"French Polynesia"
        },
        {  
           "id":260,
           "name":"French Southern Territories"
        },
        {  
           "id":262,
           "name":"Djibouti"
        },
        {  
           "id":266,
           "name":"Gabon"
        },
        {  
           "id":268,
           "name":"Georgia"
        },
        {  
           "id":270,
           "name":"Gambia"
        },
        {  
           "id":275,
           "name":"Palestinian Territory"
        },
        {  
           "id":276,
           "name":"Germany"
        },
        {  
           "id":288,
           "name":"Ghana"
        },
        {  
           "id":292,
           "name":"Gibraltar"
        },
        {  
           "id":296,
           "name":"Kiribati"
        },
        {  
           "id":300,
           "name":"Greece"
        },
        {  
           "id":304,
           "name":"Greenland"
        },
        {  
           "id":308,
           "name":"Grenada"
        },
        {  
           "id":312,
           "name":"Guadeloupe"
        },
        {  
           "id":316,
           "name":"Guam"
        },
        {  
           "id":320,
           "name":"Guatemala"
        },
        {  
           "id":324,
           "name":"Guinea"
        },
        {  
           "id":328,
           "name":"Guyana"
        },
        {  
           "id":332,
           "name":"Haiti"
        },
        {  
           "id":334,
           "name":"Heard Island and McDonald Islands"
        },
        {  
           "id":336,
           "name":"Vatican"
        },
        {  
           "id":340,
           "name":"Honduras"
        },
        {  
           "id":344,
           "name":"Hong Kong"
        },
        {  
           "id":348,
           "name":"Hungary"
        },
        {  
           "id":352,
           "name":"Iceland"
        },
        {  
           "id":360,
           "name":"Indonesia"
        },
        {  
           "id":364,
           "name":"Iran"
        },
        {  
           "id":368,
           "name":"Iraq"
        },
        {  
           "id":372,
           "name":"Ireland"
        },
        {  
           "id":376,
           "name":"Israel"
        },
        {  
           "id":380,
           "name":"Italy"
        },
        {  
           "id":384,
           "name":"Ivory Coast"
        },
        {  
           "id":388,
           "name":"Jamaica"
        },
        {  
           "id":392,
           "name":"Japan"
        },
        {  
           "id":398,
           "name":"Kazakhstan"
        },
        {  
           "id":400,
           "name":"Jordan"
        },
        {  
           "id":404,
           "name":"Kenya"
        },
        {  
           "id":408,
           "name":"North Korea"
        },
        {  
           "id":410,
           "name":"South Korea"
        },
        {  
           "id":414,
           "name":"Kuwait"
        },
        {  
           "id":417,
           "name":"Kyrgyzstan"
        },
        {  
           "id":418,
           "name":"Laos"
        },
        {  
           "id":422,
           "name":"Lebanon"
        },
        {  
           "id":426,
           "name":"Lesotho"
        },
        {  
           "id":428,
           "name":"Latvia"
        },
        {  
           "id":430,
           "name":"Liberia"
        },
        {  
           "id":434,
           "name":"Libya"
        },
        {  
           "id":438,
           "name":"Liechtenstein"
        },
        {  
           "id":440,
           "name":"Lithuania"
        },
        {  
           "id":442,
           "name":"Luxembourg"
        },
        {  
           "id":446,
           "name":"Macao"
        },
        {  
           "id":450,
           "name":"Madagascar"
        },
        {  
           "id":454,
           "name":"Malawi"
        },
        {  
           "id":458,
           "name":"Malaysia"
        },
        {  
           "id":462,
           "name":"Maldives"
        },
        {  
           "id":466,
           "name":"Mali"
        },
        {  
           "id":470,
           "name":"Malta"
        },
        {  
           "id":474,
           "name":"Martinique"
        },
        {  
           "id":478,
           "name":"Mauritania"
        },
        {  
           "id":480,
           "name":"Mauritius"
        },
        {  
           "id":484,
           "name":"Mexico"
        },
        {  
           "id":492,
           "name":"Monaco"
        },
        {  
           "id":496,
           "name":"Mongolia"
        },
        {  
           "id":498,
           "name":"Moldova"
        },
        {  
           "id":499,
           "name":"Montenegro"
        },
        {  
           "id":500,
           "name":"Montserrat"
        },
        {  
           "id":504,
           "name":"Morocco"
        },
        {  
           "id":508,
           "name":"Mozambique"
        },
        {  
           "id":512,
           "name":"Oman"
        },
        {  
           "id":516,
           "name":"Namibia"
        },
        {  
           "id":520,
           "name":"Nauru"
        },
        {  
           "id":524,
           "name":"Nepal"
        },
        {  
           "id":528,
           "name":"Netherlands"
        },
        {  
           "id":530,
           "name":"Netherlands Antilles"
        },
        {  
           "id":533,
           "name":"Aruba"
        },
        {  
           "id":540,
           "name":"New Caledonia"
        },
        {  
           "id":548,
           "name":"Vanuatu"
        },
        {  
           "id":554,
           "name":"New Zealand"
        },
        {  
           "id":558,
           "name":"Nicaragua"
        },
        {  
           "id":562,
           "name":"Niger"
        },
        {  
           "id":566,
           "name":"Nigeria"
        },
        {  
           "id":570,
           "name":"Niue"
        },
        {  
           "id":574,
           "name":"Norfolk Island"
        },
        {  
           "id":578,
           "name":"Norway"
        },
        {  
           "id":580,
           "name":"Northern Mariana Islands"
        },
        {  
           "id":581,
           "name":"United States Minor Outlying Islands"
        },
        {  
           "id":583,
           "name":"Micronesia"
        },
        {  
           "id":584,
           "name":"Marshall Islands"
        },
        {  
           "id":585,
           "name":"Palau"
        },
        {  
           "id":586,
           "name":"Pakistan"
        },
        {  
           "id":591,
           "name":"Panama"
        },
        {  
           "id":598,
           "name":"Papua New Guinea"
        },
        {  
           "id":600,
           "name":"Paraguay"
        },
        {  
           "id":604,
           "name":"Peru"
        },
        {  
           "id":608,
           "name":"Philippines"
        },
        {  
           "id":612,
           "name":"Pitcairn"
        },
        {  
           "id":616,
           "name":"Poland"
        },
        {  
           "id":620,
           "name":"Portugal"
        },
        {  
           "id":624,
           "name":"Guinea-Bissau"
        },
        {  
           "id":626,
           "name":"East Timor"
        },
        {  
           "id":630,
           "name":"Puerto Rico"
        },
        {  
           "id":634,
           "name":"Qatar"
        },
        {  
           "id":638,
           "name":"Reunion"
        },
        {  
           "id":642,
           "name":"Romania"
        },
        {  
           "id":643,
           "name":"Russia"
        },
        {  
           "id":646,
           "name":"Rwanda"
        },
        {  
           "id":652,
           "name":"Saint Barthélemy"
        },
        {  
           "id":654,
           "name":"Saint Helena"
        },
        {  
           "id":659,
           "name":"Saint Kitts and Nevis"
        },
        {  
           "id":660,
           "name":"Anguilla"
        },
        {  
           "id":662,
           "name":"Saint Lucia"
        },
        {  
           "id":663,
           "name":"Saint Martin"
        },
        {  
           "id":666,
           "name":"Saint Pierre and Miquelon"
        },
        {  
           "id":670,
           "name":"Saint Vincent and the Grenadines"
        },
        {  
           "id":674,
           "name":"San Marino"
        },
        {  
           "id":678,
           "name":"Sao Tome and Principe"
        },
        {  
           "id":682,
           "name":"Saudi Arabia"
        },
        {  
           "id":686,
           "name":"Senegal"
        },
        {  
           "id":688,
           "name":"Serbia"
        },
        {  
           "id":690,
           "name":"Seychelles"
        },
        {  
           "id":694,
           "name":"Sierra Leone"
        },
        {  
           "id":702,
           "name":"Singapore"
        },
        {  
           "id":703,
           "name":"Slovakia"
        },
        {  
           "id":704,
           "name":"Vietnam"
        },
        {  
           "id":705,
           "name":"Slovenia"
        },
        {  
           "id":706,
           "name":"Somalia"
        },
        {  
           "id":710,
           "name":"South Africa"
        },
        {  
           "id":716,
           "name":"Zimbabwe"
        },
        {  
           "id":724,
           "name":"Spain"
        },
        {  
           "id":732,
           "name":"Western Sahara"
        },
        {  
           "id":736,
           "name":"Sudan"
        },
        {  
           "id":740,
           "name":"Suriname"
        },
        {  
           "id":744,
           "name":"Svalbard and Jan Mayen"
        },
        {  
           "id":748,
           "name":"Swaziland"
        },
        {  
           "id":752,
           "name":"Sweden"
        },
        {  
           "id":756,
           "name":"Switzerland"
        },
        {  
           "id":760,
           "name":"Syria"
        },
        {  
           "id":762,
           "name":"Tajikistan"
        },
        {  
           "id":764,
           "name":"Thailand"
        },
        {  
           "id":768,
           "name":"Togo"
        },
        {  
           "id":772,
           "name":"Tokelau"
        },
        {  
           "id":776,
           "name":"Tonga"
        },
        {  
           "id":780,
           "name":"Trinidad and Tobago"
        },
        {  
           "id":784,
           "name":"United Arab Emirates"
        },
        {  
           "id":788,
           "name":"Tunisia"
        },
        {  
           "id":792,
           "name":"Turkey"
        },
        {  
           "id":795,
           "name":"Turkmenistan"
        },
        {  
           "id":796,
           "name":"Turks and Caicos Islands"
        },
        {  
           "id":798,
           "name":"Tuvalu"
        },
        {  
           "id":800,
           "name":"Uganda"
        },
        {  
           "id":804,
           "name":"Ukraine"
        },
        {  
           "id":807,
           "name":"Macedonia"
        },
        {  
           "id":818,
           "name":"Egypt"
        },
        {  
           "id":826,
           "name":"United Kingdom"
        },
        {  
           "id":831,
           "name":"Guernsey"
        },
        {  
           "id":832,
           "name":"Jersey"
        },
        {  
           "id":833,
           "name":"Isle of Man"
        },
        {  
           "id":834,
           "name":"Tanzania"
        },
        {  
           "id":850,
           "name":"U.S. Virgin Islands"
        },
        {  
           "id":854,
           "name":"Burkina Faso"
        },
        {  
           "id":855,
           "name":"Kosovo"
        },
        {  
           "id":858,
           "name":"Uruguay"
        },
        {  
           "id":860,
           "name":"Uzbekistan"
        },
        {  
           "id":862,
           "name":"Venezuela"
        },
        {  
           "id":876,
           "name":"Wallis and Futuna"
        },
        {  
           "id":882,
           "name":"Samoa"
        },
        {  
           "id":887,
           "name":"Yemen"
        },
        {  
           "id":891,
           "name":"Serbia and Montenegro"
        },
        {  
           "id":894,
           "name":"Zambia"
        }
     ];

    public statesList = [
        {
           "id":1,
           "country_id":1,
           "code":"AL",
           "name":"Alabama",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":2,
           "country_id":1,
           "code":"AK",
           "name":"Alaska",
           "standard_timezone":"(UTC-8.0) Anchorage",
           "timezone":"America/Anchorage"
        },
        {
           "id":3,
           "country_id":1,
           "code":"AZ",
           "name":"Arizona",
           "standard_timezone":"(UTC-08:00) Pacific Time (US & Canada)",
           "timezone":"America/Phoenix"
        },
        {
           "id":4,
           "country_id":1,
           "code":"AR",
           "name":"Arkansas",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":5,
           "country_id":1,
           "code":"CA",
           "name":"California",
           "standard_timezone":"(UTC-08:00) Pacific Time (US & Canada)",
           "timezone":"America/Los_Angeles"
        },
        {
           "id":6,
           "country_id":1,
           "code":"CO",
           "name":"Colorado",
           "standard_timezone":"(UTC-07:00) Mountain Time (US & Canada)",
           "timezone":"America/Denver"
        },
        {
           "id":7,
           "country_id":1,
           "code":"CT",
           "name":"Connecticut",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":8,
           "country_id":1,
           "code":"DE",
           "name":"Delaware",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":9,
           "country_id":1,
           "code":"FL",
           "name":"Florida",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":10,
           "country_id":1,
           "code":"GA",
           "name":"Georgia",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":11,
           "country_id":1,
           "code":"HI",
           "name":"Hawaii",
           "standard_timezone":"(UTC-10.0) Honolulu",
           "timezone":"Pacific/Honolulu"
        },
        {
           "id":12,
           "country_id":1,
           "code":"ID",
           "name":"Idaho",
           "standard_timezone":"(UTC-07:00) Mountain Time (US & Canada)",
           "timezone":"America/Denver"
        },
        {
           "id":13,
           "country_id":1,
           "code":"IL",
           "name":"Illinois",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":14,
           "country_id":1,
           "code":"IN",
           "name":"Indiana",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":15,
           "country_id":1,
           "code":"IA",
           "name":"Iowa",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":16,
           "country_id":1,
           "code":"KS",
           "name":"Kansas",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":17,
           "country_id":1,
           "code":"KY",
           "name":"Kentucky",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":18,
           "country_id":1,
           "code":"LA",
           "name":"Louisiana",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":19,
           "country_id":1,
           "code":"ME",
           "name":"Maine",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":20,
           "country_id":1,
           "code":"MD",
           "name":"Maryland",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":21,
           "country_id":1,
           "code":"MA",
           "name":"Massachusetts",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":22,
           "country_id":1,
           "code":"MI",
           "name":"Michigan",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":23,
           "country_id":1,
           "code":"MN",
           "name":"Minnesota",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":24,
           "country_id":1,
           "code":"MS",
           "name":"Mississippi",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":25,
           "country_id":1,
           "code":"MO",
           "name":"Missouri",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":26,
           "country_id":1,
           "code":"MT",
           "name":"Montana",
           "standard_timezone":"(UTC-07:00) Mountain Time (US & Canada)",
           "timezone":"America/Denver"
        },
        {
           "id":27,
           "country_id":1,
           "code":"NE",
           "name":"Nebraska",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":28,
           "country_id":1,
           "code":"NV",
           "name":"Nevada",
           "standard_timezone":"(UTC-08:00) Pacific Time (US & Canada)",
           "timezone":"America/Los_Angeles"
        },
        {
           "id":29,
           "country_id":1,
           "code":"NH",
           "name":"New Hampshire",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":30,
           "country_id":1,
           "code":"NJ",
           "name":"New Jersey",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":31,
           "country_id":1,
           "code":"NM",
           "name":"New Mexico",
           "standard_timezone":"(UTC-07:00) Mountain Time (US & Canada)",
           "timezone":"America/Denver"
        },
        {
           "id":32,
           "country_id":1,
           "code":"NY",
           "name":"New York",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":33,
           "country_id":1,
           "code":"NC",
           "name":"North Carolina",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":34,
           "country_id":1,
           "code":"ND",
           "name":"North Dakota",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":35,
           "country_id":1,
           "code":"OH",
           "name":"Ohio",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":36,
           "country_id":1,
           "code":"OK",
           "name":"Oklahoma",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":37,
           "country_id":1,
           "code":"OR",
           "name":"Oregon",
           "standard_timezone":"(UTC-08:00) Pacific Time (US & Canada)",
           "timezone":"America/Los_Angeles"
        },
        {
           "id":38,
           "country_id":1,
           "code":"PA",
           "name":"Pennsylvania",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":39,
           "country_id":1,
           "code":"RI",
           "name":"Rhode Island",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":40,
           "country_id":1,
           "code":"SC",
           "name":"South Carolina",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":41,
           "country_id":1,
           "code":"SD",
           "name":"South Dakota",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":42,
           "country_id":1,
           "code":"TN",
           "name":"Tennessee",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":43,
           "country_id":1,
           "code":"TX",
           "name":"Texas",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":44,
           "country_id":1,
           "code":"UT",
           "name":"Utah",
           "standard_timezone":"(UTC-07:00) Mountain Time (US & Canada)",
           "timezone":"America/Denver"
        },
        {
           "id":45,
           "country_id":1,
           "code":"VT",
           "name":"Vermont",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":46,
           "country_id":1,
           "code":"VA",
           "name":"Virginia",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":47,
           "country_id":1,
           "code":"WA",
           "name":"Washington",
           "standard_timezone":"(UTC-08:00) Pacific Time (US & Canada)",
           "timezone":"America/Los_Angeles"
        },
        {
           "id":3877,
           "country_id":1,
           "code":"DC",
           "name":"District of Columbia",
           "standard_timezone":"(UTC-05:00) Eastern Standard Time",
           "timezone":"America/Columbia"
        },
        {
           "id":48,
           "country_id":1,
           "code":"WV",
           "name":"West Virginia",
           "standard_timezone":"(UTC-05:00) Eastern Time (US & Canada)",
           "timezone":"America/New_York"
        },
        {
           "id":49,
           "country_id":1,
           "code":"WI",
           "name":"Wisconsin",
           "standard_timezone":"(UTC-06:00) Central Time (US & Canada)",
           "timezone":"America/Chicago"
        },
        {
           "id":50,
           "country_id":1,
           "code":"WY",
           "name":"Wyoming",
           "standard_timezone":"(UTC-07:00) Mountain Time (US & Canada)",
           "timezone":"America/Denver"
        },
        {
           "id":51,
           "country_id":2,
           "code":"AN",
           "name":"Andaman and Nicobar Islands",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":52,
           "country_id":2,
           "code":"AP",
           "name":"Andhra Pradesh",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":53,
           "country_id":2,
           "code":"AR",
           "name":"Arunachal Pradesh",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":54,
           "country_id":2,
           "code":"AS",
           "name":"Assam",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":55,
           "country_id":2,
           "code":"BR",
           "name":"Bihar",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":56,
           "country_id":2,
           "code":"CH",
           "name":"Chandigarh",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":57,
           "country_id":2,
           "code":"CT",
           "name":"Chhattisgarh",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":58,
           "country_id":2,
           "code":"DN",
           "name":"Dadra and Nagar Haveli",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":59,
           "country_id":2,
           "code":"DD",
           "name":"Daman and Diu",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":60,
           "country_id":2,
           "code":"GA",
           "name":"Goa",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":61,
           "country_id":2,
           "code":"GJ",
           "name":"Gujarat",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":62,
           "country_id":2,
           "code":"HR",
           "name":"Haryana",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":63,
           "country_id":2,
           "code":"HP",
           "name":"Himachal Pradesh",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":64,
           "country_id":2,
           "code":"JK",
           "name":"Jammu and Kashmir",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":65,
           "country_id":2,
           "code":"JH",
           "name":"Jharkhand",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":66,
           "country_id":2,
           "code":"KA",
           "name":"Karnataka",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":67,
           "country_id":2,
           "code":"KL",
           "name":"Kerala",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":68,
           "country_id":2,
           "code":"LD",
           "name":"Lakshadweep",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":69,
           "country_id":2,
           "code":"MP",
           "name":"Madhya Pradesh",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":70,
           "country_id":2,
           "code":"MH",
           "name":"Maharashtra",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":71,
           "country_id":2,
           "code":"MN",
           "name":"Manipur",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":72,
           "country_id":2,
           "code":"ML",
           "name":"Meghalaya",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":73,
           "country_id":2,
           "code":"MZ",
           "name":"Mizoram",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":74,
           "country_id":2,
           "code":"NL",
           "name":"Nagaland",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":75,
           "country_id":2,
           "code":"DL",
           "name":"National Capital Territory of Delhi",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":76,
           "country_id":2,
           "code":"OR",
           "name":"Odisha",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":77,
           "country_id":2,
           "code":"PY",
           "name":"Pondicherry",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":78,
           "country_id":2,
           "code":"PB",
           "name":"Punjab",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":79,
           "country_id":2,
           "code":"RJ",
           "name":"Rajasthan",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":80,
           "country_id":2,
           "code":"SK",
           "name":"Sikkim",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":81,
           "country_id":2,
           "code":"TN",
           "name":"Tamil Nadu",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":82,
           "country_id":2,
           "code":"TS",
           "name":"Telangana",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":83,
           "country_id":2,
           "code":"TR",
           "name":"Tripura",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":84,
           "country_id":2,
           "code":"UP",
           "name":"Uttar Pradesh",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":85,
           "country_id":2,
           "code":"UT",
           "name":"Uttarakhand",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":86,
           "country_id":2,
           "code":"WB",
           "name":"West Bengal",
           "standard_timezone":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
           "timezone":"Asia/Kolkata"
        },
        {
           "id":87,
           "country_id":4,
           "code":"",
           "name":"Badakhshan",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":88,
           "country_id":4,
           "code":"",
           "name":"Badghis Province",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":89,
           "country_id":4,
           "code":"",
           "name":"Baghlān",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":90,
           "country_id":4,
           "code":"",
           "name":"Bāmīān",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":91,
           "country_id":4,
           "code":"",
           "name":"Farah",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":92,
           "country_id":4,
           "code":"",
           "name":"Faryab Province",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":93,
           "country_id":4,
           "code":"",
           "name":"Ghaznī",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":94,
           "country_id":4,
           "code":"",
           "name":"Ghowr",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":95,
           "country_id":4,
           "code":"",
           "name":"Helmand Province",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":96,
           "country_id":4,
           "code":"",
           "name":"Herat Province",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":97,
           "country_id":4,
           "code":"",
           "name":"Kabul",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":98,
           "country_id":4,
           "code":"",
           "name":"Kāpīsā",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":99,
           "country_id":4,
           "code":"",
           "name":"Lowgar",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":100,
           "country_id":4,
           "code":"",
           "name":"Nangarhār",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":101,
           "country_id":4,
           "code":"",
           "name":"Nīmrūz",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":102,
           "country_id":4,
           "code":"",
           "name":"Orūzgān",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":103,
           "country_id":4,
           "code":"",
           "name":"Kandahār",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":104,
           "country_id":4,
           "code":"",
           "name":"Kunduz Province",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":105,
           "country_id":4,
           "code":"",
           "name":"Takhār",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":106,
           "country_id":4,
           "code":"",
           "name":"Vardak",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":107,
           "country_id":4,
           "code":"",
           "name":"Zabul Province",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":108,
           "country_id":4,
           "code":"",
           "name":"Paktīkā",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":109,
           "country_id":4,
           "code":"",
           "name":"Balkh",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":110,
           "country_id":4,
           "code":"",
           "name":"Jowzjān",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":111,
           "country_id":4,
           "code":"",
           "name":"Samangān",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":112,
           "country_id":4,
           "code":"",
           "name":"Sar-e Pol",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":113,
           "country_id":4,
           "code":"",
           "name":"Konar",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":114,
           "country_id":4,
           "code":"",
           "name":"Laghmān",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":115,
           "country_id":4,
           "code":"",
           "name":"Paktia Province",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":116,
           "country_id":4,
           "code":"",
           "name":"Khowst",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":117,
           "country_id":4,
           "code":"",
           "name":"Nūrestān",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":118,
           "country_id":4,
           "code":"",
           "name":"Parvān",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":119,
           "country_id":4,
           "code":"",
           "name":"Dāykondī",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":120,
           "country_id":4,
           "code":"",
           "name":"Panjshīr",
           "standard_timezone":"(UTC+4.30) Kabul",
           "timezone":"Asia/Kabul"
        },
        {
           "id":121,
           "country_id":8,
           "code":"",
           "name":"Berat",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":122,
           "country_id":8,
           "code":"",
           "name":"Dibër",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":123,
           "country_id":8,
           "code":"",
           "name":"Durrës",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":124,
           "country_id":8,
           "code":"",
           "name":"Elbasan",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":125,
           "country_id":8,
           "code":"",
           "name":"Fier",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":126,
           "country_id":8,
           "code":"",
           "name":"Gjirokastër",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":127,
           "country_id":8,
           "code":"",
           "name":"Korçë",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":128,
           "country_id":8,
           "code":"",
           "name":"Kukës",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":129,
           "country_id":8,
           "code":"",
           "name":"Lezhë",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":130,
           "country_id":8,
           "code":"",
           "name":"Shkodër",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":131,
           "country_id":8,
           "code":"",
           "name":"Tiranë",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":132,
           "country_id":8,
           "code":"",
           "name":"Vlorë",
           "standard_timezone":"(UTC+2.0) Tirane",
           "timezone":"Europe/Tirane"
        },
        {
           "id":133,
           "country_id":12,
           "code":"",
           "name":"Alger",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":134,
           "country_id":12,
           "code":"",
           "name":"Batna",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":135,
           "country_id":12,
           "code":"",
           "name":"Constantine",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":136,
           "country_id":12,
           "code":"",
           "name":"Médéa",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":137,
           "country_id":12,
           "code":"",
           "name":"Mostaganem",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":138,
           "country_id":12,
           "code":"",
           "name":"Oran",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":139,
           "country_id":12,
           "code":"",
           "name":"Saïda",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":140,
           "country_id":12,
           "code":"",
           "name":"Sétif",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":141,
           "country_id":12,
           "code":"",
           "name":"Tiaret",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":142,
           "country_id":12,
           "code":"",
           "name":"Tizi Ouzou",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":143,
           "country_id":12,
           "code":"",
           "name":"Tlemcen",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":144,
           "country_id":12,
           "code":"",
           "name":"Bejaïa",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":145,
           "country_id":12,
           "code":"",
           "name":"Biskra",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":146,
           "country_id":12,
           "code":"",
           "name":"Blida",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":147,
           "country_id":12,
           "code":"",
           "name":"Bouira",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":148,
           "country_id":12,
           "code":"",
           "name":"Djelfa",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":149,
           "country_id":12,
           "code":"",
           "name":"Guelma",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":150,
           "country_id":12,
           "code":"",
           "name":"Jijel",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":151,
           "country_id":12,
           "code":"",
           "name":"Laghouat",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":152,
           "country_id":12,
           "code":"",
           "name":"Mascara",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":153,
           "country_id":12,
           "code":"",
           "name":"Mʼsila",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":154,
           "country_id":12,
           "code":"",
           "name":"Oum el Bouaghi",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":155,
           "country_id":12,
           "code":"",
           "name":"Sidi Bel Abbès",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":156,
           "country_id":12,
           "code":"",
           "name":"Skikda",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":157,
           "country_id":12,
           "code":"",
           "name":"Tébessa",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":158,
           "country_id":12,
           "code":"",
           "name":"Adrar",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":159,
           "country_id":12,
           "code":"",
           "name":"Aïn Defla",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":160,
           "country_id":12,
           "code":"",
           "name":"Aïn Temouchent",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":161,
           "country_id":12,
           "code":"",
           "name":"Annaba",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":162,
           "country_id":12,
           "code":"",
           "name":"Béchar",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":163,
           "country_id":12,
           "code":"",
           "name":"Bordj Bou Arréridj",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":164,
           "country_id":12,
           "code":"",
           "name":"Boumerdes",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":165,
           "country_id":12,
           "code":"",
           "name":"Chlef",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":166,
           "country_id":12,
           "code":"",
           "name":"El Bayadh",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":167,
           "country_id":12,
           "code":"",
           "name":"El Oued",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":168,
           "country_id":12,
           "code":"",
           "name":"El Tarf",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":169,
           "country_id":12,
           "code":"",
           "name":"Ghardaïa",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":170,
           "country_id":12,
           "code":"",
           "name":"Illizi",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":171,
           "country_id":12,
           "code":"",
           "name":"Khenchela",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":172,
           "country_id":12,
           "code":"",
           "name":"Mila",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":173,
           "country_id":12,
           "code":"",
           "name":"Naama النعامة",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":174,
           "country_id":12,
           "code":"",
           "name":"Ouargla",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":175,
           "country_id":12,
           "code":"",
           "name":"Relizane",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":176,
           "country_id":12,
           "code":"",
           "name":"Souk Ahras",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":177,
           "country_id":12,
           "code":"",
           "name":"Tamanghasset",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":178,
           "country_id":12,
           "code":"",
           "name":"Tindouf",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":179,
           "country_id":12,
           "code":"",
           "name":"Tipaza",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":180,
           "country_id":12,
           "code":"",
           "name":"Tissemsilt",
           "standard_timezone":"(UTC+1.0) Algiers",
           "timezone":"Africa/Algiers"
        },
        {
           "id":181,
           "country_id":16,
           "code":"",
           "name":"American Samoa",
           "standard_timezone":"(UTC-11.0) Pago_Pago",
           "timezone":"Pacific/Pago_Pago"
        },
        {
           "id":182,
           "country_id":20,
           "code":"",
           "name":"Parròquia de Canillo",
           "standard_timezone":"(UTC+2.0) Andorra",
           "timezone":"Europe/Andorra"
        },
        {
           "id":183,
           "country_id":20,
           "code":"",
           "name":"Parròquia dʼEncamp",
           "standard_timezone":"(UTC+2.0) Andorra",
           "timezone":"Europe/Andorra"
        },
        {
           "id":184,
           "country_id":20,
           "code":"",
           "name":"Parròquia de la Massana",
           "standard_timezone":"(UTC+2.0) Andorra",
           "timezone":"Europe/Andorra"
        },
        {
           "id":185,
           "country_id":20,
           "code":"",
           "name":"Parròquia dʼOrdino",
           "standard_timezone":"(UTC+2.0) Andorra",
           "timezone":"Europe/Andorra"
        },
        {
           "id":186,
           "country_id":20,
           "code":"",
           "name":"Parròquia de Sant Julià de Lòria",
           "standard_timezone":"(UTC+2.0) Andorra",
           "timezone":"Europe/Andorra"
        },
        {
           "id":187,
           "country_id":20,
           "code":"",
           "name":"Parròquia dʼAndorra la Vella",
           "standard_timezone":"(UTC+2.0) Andorra",
           "timezone":"Europe/Andorra"
        },
        {
           "id":188,
           "country_id":20,
           "code":"",
           "name":"Parròquia dʼEscaldes-Engordany",
           "standard_timezone":"(UTC+2.0) Andorra",
           "timezone":"Europe/Andorra"
        },
        {
           "id":189,
           "country_id":24,
           "code":"",
           "name":"Benguela",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":190,
           "country_id":24,
           "code":"",
           "name":"Bié",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":191,
           "country_id":24,
           "code":"",
           "name":"Cabinda",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":192,
           "country_id":24,
           "code":"",
           "name":"Cuando Cubango",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":193,
           "country_id":24,
           "code":"",
           "name":"Cuanza Norte",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":194,
           "country_id":24,
           "code":"",
           "name":"Cuanza Sul",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":195,
           "country_id":24,
           "code":"",
           "name":"Cunene",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":196,
           "country_id":24,
           "code":"",
           "name":"Huambo",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":197,
           "country_id":24,
           "code":"",
           "name":"Huíla",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":198,
           "country_id":24,
           "code":"",
           "name":"Luanda",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":199,
           "country_id":24,
           "code":"",
           "name":"Malanje",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":200,
           "country_id":24,
           "code":"",
           "name":"Namibe",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":201,
           "country_id":24,
           "code":"",
           "name":"Moxico",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":202,
           "country_id":24,
           "code":"",
           "name":"Uíge",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":203,
           "country_id":24,
           "code":"",
           "name":"Zaire",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":204,
           "country_id":24,
           "code":"",
           "name":"Lunda Norte",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":205,
           "country_id":24,
           "code":"",
           "name":"Lunda Sul",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":206,
           "country_id":24,
           "code":"",
           "name":"Bengo",
           "standard_timezone":"(UTC+1.0) Luanda",
           "timezone":"Africa/Luanda"
        },
        {
           "id":207,
           "country_id":28,
           "code":"",
           "name":"Redonda",
           "standard_timezone":"(UTC+10.0) Antigua",
           "timezone":"America/Antigua"
        },
        {
           "id":208,
           "country_id":28,
           "code":"",
           "name":"Barbuda",
           "standard_timezone":"(UTC+10.0) Antigua",
           "timezone":"America/Antigua"
        },
        {
           "id":209,
           "country_id":28,
           "code":"",
           "name":"Saint George",
           "standard_timezone":"(UTC+10.0) Antigua",
           "timezone":"America/Antigua"
        },
        {
           "id":210,
           "country_id":28,
           "code":"",
           "name":"Saint John",
           "standard_timezone":"(UTC+10.0) Antigua",
           "timezone":"America/Antigua"
        },
        {
           "id":211,
           "country_id":28,
           "code":"",
           "name":"Saint Mary",
           "standard_timezone":"(UTC+10.0) Antigua",
           "timezone":"America/Antigua"
        },
        {
           "id":212,
           "country_id":28,
           "code":"",
           "name":"Saint Paul",
           "standard_timezone":"(UTC+10.0) Antigua",
           "timezone":"America/Antigua"
        },
        {
           "id":213,
           "country_id":28,
           "code":"",
           "name":"Saint Peter",
           "standard_timezone":"(UTC+10.0) Antigua",
           "timezone":"America/Antigua"
        },
        {
           "id":214,
           "country_id":28,
           "code":"",
           "name":"Saint Philip",
           "standard_timezone":"(UTC+10.0) Antigua",
           "timezone":"America/Antigua"
        },
        {
           "id":215,
           "country_id":31,
           "code":"",
           "name":"Abşeron",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":216,
           "country_id":31,
           "code":"",
           "name":"Ağcabǝdi",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":217,
           "country_id":31,
           "code":"",
           "name":"Ağdam",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":218,
           "country_id":31,
           "code":"",
           "name":"Ağdaş",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":219,
           "country_id":31,
           "code":"",
           "name":"Ağstafa",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":220,
           "country_id":31,
           "code":"",
           "name":"Ağsu",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":221,
           "country_id":31,
           "code":"",
           "name":"Əli Bayramli",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":222,
           "country_id":31,
           "code":"",
           "name":"Astara",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":223,
           "country_id":31,
           "code":"",
           "name":"Baki",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":224,
           "country_id":31,
           "code":"",
           "name":"Balakǝn",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":225,
           "country_id":31,
           "code":"",
           "name":"Bǝrdǝ",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":226,
           "country_id":31,
           "code":"",
           "name":"Beylǝqan",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":227,
           "country_id":31,
           "code":"",
           "name":"Bilǝsuvar",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":228,
           "country_id":31,
           "code":"",
           "name":"Cǝbrayıl",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":229,
           "country_id":31,
           "code":"",
           "name":"Cǝlilabad",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":230,
           "country_id":31,
           "code":"",
           "name":"Daşkǝsǝn",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":231,
           "country_id":31,
           "code":"",
           "name":"Dǝvǝçi",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":232,
           "country_id":31,
           "code":"",
           "name":"Füzuli",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":233,
           "country_id":31,
           "code":"",
           "name":"Gǝdǝbǝy",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":234,
           "country_id":31,
           "code":"",
           "name":"Gǝncǝ",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":235,
           "country_id":31,
           "code":"",
           "name":"Goranboy",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":236,
           "country_id":31,
           "code":"",
           "name":"Göyçay",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":237,
           "country_id":31,
           "code":"",
           "name":"Hacıqabul",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":238,
           "country_id":31,
           "code":"",
           "name":"İmişli",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":239,
           "country_id":31,
           "code":"",
           "name":"İsmayıllı",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":240,
           "country_id":31,
           "code":"",
           "name":"Kǝlbǝcǝr",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":241,
           "country_id":31,
           "code":"",
           "name":"Kürdǝmir",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":242,
           "country_id":31,
           "code":"",
           "name":"Laçın",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":243,
           "country_id":31,
           "code":"",
           "name":"Lǝnkǝran",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":244,
           "country_id":31,
           "code":"",
           "name":"Lǝnkǝran Şǝhǝri",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":245,
           "country_id":31,
           "code":"",
           "name":"Lerik",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":246,
           "country_id":31,
           "code":"",
           "name":"Masallı",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":247,
           "country_id":31,
           "code":"",
           "name":"Mingǝcevir",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":248,
           "country_id":31,
           "code":"",
           "name":"Naftalan",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":249,
           "country_id":31,
           "code":"",
           "name":"Nakhichevan",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":250,
           "country_id":31,
           "code":"",
           "name":"Neftçala",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":251,
           "country_id":31,
           "code":"",
           "name":"Oğuz",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":252,
           "country_id":31,
           "code":"",
           "name":"Qǝbǝlǝ",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":253,
           "country_id":31,
           "code":"",
           "name":"Qǝx",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":254,
           "country_id":31,
           "code":"",
           "name":"Qazax",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":255,
           "country_id":31,
           "code":"",
           "name":"Qobustan",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":256,
           "country_id":31,
           "code":"",
           "name":"Quba",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":257,
           "country_id":31,
           "code":"",
           "name":"Qubadlı",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":258,
           "country_id":31,
           "code":"",
           "name":"Qusar",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":259,
           "country_id":31,
           "code":"",
           "name":"Saatlı",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":260,
           "country_id":31,
           "code":"",
           "name":"Sabirabad",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":261,
           "country_id":31,
           "code":"",
           "name":"Şǝki",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":262,
           "country_id":31,
           "code":"",
           "name":"Şǝki",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":263,
           "country_id":31,
           "code":"",
           "name":"Salyan",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":264,
           "country_id":31,
           "code":"",
           "name":"Şamaxı",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":265,
           "country_id":31,
           "code":"",
           "name":"Şǝmkir",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":266,
           "country_id":31,
           "code":"",
           "name":"Samux",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":267,
           "country_id":31,
           "code":"",
           "name":"Siyǝzǝn",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":268,
           "country_id":31,
           "code":"",
           "name":"Sumqayit",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":269,
           "country_id":31,
           "code":"",
           "name":"Şuşa",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":270,
           "country_id":31,
           "code":"",
           "name":"Şuşa Şəhəri",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":271,
           "country_id":31,
           "code":"",
           "name":"Tǝrtǝr",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":272,
           "country_id":31,
           "code":"",
           "name":"Tovuz",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":273,
           "country_id":31,
           "code":"",
           "name":"Ucar",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":274,
           "country_id":31,
           "code":"",
           "name":"Xaçmaz",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":275,
           "country_id":31,
           "code":"",
           "name":"Xankǝndi",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":276,
           "country_id":31,
           "code":"",
           "name":"Xanlar",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":277,
           "country_id":31,
           "code":"",
           "name":"Xızı",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":278,
           "country_id":31,
           "code":"",
           "name":"Xocalı",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":279,
           "country_id":31,
           "code":"",
           "name":"Xocavǝnd",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":280,
           "country_id":31,
           "code":"",
           "name":"Yardımlı",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":281,
           "country_id":31,
           "code":"",
           "name":"Yevlax",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":282,
           "country_id":31,
           "code":"",
           "name":"Yevlax",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":283,
           "country_id":31,
           "code":"",
           "name":"Zǝngilan",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":284,
           "country_id":31,
           "code":"",
           "name":"Zaqatala",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":285,
           "country_id":31,
           "code":"",
           "name":"Zǝrdab",
           "standard_timezone":"(UTC+5.0) Baku",
           "timezone":"Asia/Baku"
        },
        {
           "id":286,
           "country_id":32,
           "code":"",
           "name":"Buenos Aires",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Buenos_Aires"
        },
        {
           "id":287,
           "country_id":32,
           "code":"",
           "name":"Catamarca",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Catamarca"
        },
        {
           "id":288,
           "country_id":32,
           "code":"",
           "name":"Chaco",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":289,
           "country_id":32,
           "code":"",
           "name":"Chubut",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Catamarca"
        },
        {
           "id":290,
           "country_id":32,
           "code":"",
           "name":"Córdoba",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":291,
           "country_id":32,
           "code":"",
           "name":"Corrientes",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":292,
           "country_id":32,
           "code":"",
           "name":"Distrito Federal",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Buenos_Aires"
        },
        {
           "id":293,
           "country_id":32,
           "code":"",
           "name":"Entre Ríos",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":294,
           "country_id":32,
           "code":"",
           "name":"Formosa",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":295,
           "country_id":32,
           "code":"",
           "name":"Jujuy",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Jujuy"
        },
        {
           "id":296,
           "country_id":32,
           "code":"",
           "name":"La Pampa",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":297,
           "country_id":32,
           "code":"",
           "name":"La Rioja",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/La_Rioja"
        },
        {
           "id":298,
           "country_id":32,
           "code":"",
           "name":"Mendoza",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Mendoza"
        },
        {
           "id":299,
           "country_id":32,
           "code":"",
           "name":"Misiones",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":300,
           "country_id":32,
           "code":"",
           "name":"Neuquén",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":301,
           "country_id":32,
           "code":"",
           "name":"Río Negro",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":302,
           "country_id":32,
           "code":"",
           "name":"Salta",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":303,
           "country_id":32,
           "code":"",
           "name":"San Juan",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/San_Juan"
        },
        {
           "id":304,
           "country_id":32,
           "code":"",
           "name":"San Luis",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":305,
           "country_id":32,
           "code":"",
           "name":"Santa Cruz",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Rio_Gallegos"
        },
        {
           "id":306,
           "country_id":32,
           "code":"",
           "name":"Santa Fe",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":307,
           "country_id":32,
           "code":"",
           "name":"Santiago del Estero",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Cordoba"
        },
        {
           "id":308,
           "country_id":32,
           "code":"",
           "name":"Tierra del Fuego, Antártida e Islas del Atlán",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Ushuaia"
        },
        {
           "id":309,
           "country_id":32,
           "code":"",
           "name":"Tucumán",
           "standard_timezone":"(UTC-3.0) Argentina",
           "timezone":"America/Argentina/Tucuman"
        },
        {
           "id":310,
           "country_id":36,
           "code":"",
           "name":"Australian Capital Territory",
           "standard_timezone":"(UTC+10.0) Sydney",
           "timezone":"Australia/Sydney"
        },
        {
           "id":311,
           "country_id":36,
           "code":"",
           "name":"New South Wales",
           "standard_timezone":"(UTC+10:00) Canberra, Melbourne, Sydney",
           "timezone":"Australia/Sydney"
        },
        {
           "id":312,
           "country_id":36,
           "code":"",
           "name":"Northern Territory",
           "standard_timezone":"(UTC+9.30) Darwin",
           "timezone":"Australia/Darwin"
        },
        {
           "id":313,
           "country_id":36,
           "code":"",
           "name":"Queensland",
           "standard_timezone":"(UTC+10.0) Brisbane",
           "timezone":"Australia/Brisbane"
        },
        {
           "id":314,
           "country_id":36,
           "code":"",
           "name":"South Australia",
           "standard_timezone":"(UTC+9.30) Adelaide",
           "timezone":"Australia/Adelaide"
        },
        {
           "id":315,
           "country_id":36,
           "code":"",
           "name":"Tasmania",
           "standard_timezone":"(UTC+10.0) Hobart",
           "timezone":"Australia/Hobart"
        },
        {
           "id":316,
           "country_id":36,
           "code":"",
           "name":"Victoria",
           "standard_timezone":"(UTC+10.0) Melbourne",
           "timezone":"Australia/Melbourne"
        },
        {
           "id":317,
           "country_id":36,
           "code":"",
           "name":"Western Australia",
           "standard_timezone":"(UTC+8.0) Perth",
           "timezone":"Australia/Perth"
        },
        {
           "id":318,
           "country_id":40,
           "code":"",
           "name":"Burgenland",
           "standard_timezone":"(UTC+2.0) Vienna",
           "timezone":"Europe/Vienna"
        },
        {
           "id":319,
           "country_id":40,
           "code":"",
           "name":"Carinthia",
           "standard_timezone":"(UTC+2.0) Vienna",
           "timezone":"Europe/Vienna"
        },
        {
           "id":320,
           "country_id":40,
           "code":"",
           "name":"Lower Austria",
           "standard_timezone":"(UTC+2.0) Vienna",
           "timezone":"Europe/Vienna"
        },
        {
           "id":321,
           "country_id":40,
           "code":"",
           "name":"Upper Austria",
           "standard_timezone":"(UTC+2.0) Vienna",
           "timezone":"Europe/Vienna"
        },
        {
           "id":322,
           "country_id":40,
           "code":"",
           "name":"Salzburg",
           "standard_timezone":"(UTC+2.0) Vienna",
           "timezone":"Europe/Vienna"
        },
        {
           "id":323,
           "country_id":40,
           "code":"",
           "name":"Styria",
           "standard_timezone":"(UTC+2.0) Vienna",
           "timezone":"Europe/Vienna"
        },
        {
           "id":324,
           "country_id":40,
           "code":"",
           "name":"Tyrol",
           "standard_timezone":"(UTC+2.0) Vienna",
           "timezone":"Europe/Vienna"
        },
        {
           "id":325,
           "country_id":40,
           "code":"",
           "name":"Vorarlberg",
           "standard_timezone":"(UTC+2.0) Vienna",
           "timezone":"Europe/Vienna"
        },
        {
           "id":326,
           "country_id":40,
           "code":"",
           "name":"Vienna",
           "standard_timezone":"(UTC+2.0) Vienna",
           "timezone":"Europe/Vienna"
        },
        {
           "id":327,
           "country_id":44,
           "code":"",
           "name":"Bimini",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":328,
           "country_id":44,
           "code":"",
           "name":"Cat Island",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":329,
           "country_id":44,
           "code":"",
           "name":"Inagua",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":330,
           "country_id":44,
           "code":"",
           "name":"Long Island",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":331,
           "country_id":44,
           "code":"",
           "name":"Mayaguana",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":332,
           "country_id":44,
           "code":"",
           "name":"Ragged Island",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":333,
           "country_id":44,
           "code":"",
           "name":"Harbour Island, Eleuthera",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":334,
           "country_id":44,
           "code":"",
           "name":"North Abaco",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":335,
           "country_id":44,
           "code":"",
           "name":"Acklins",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":336,
           "country_id":44,
           "code":"",
           "name":"City of Freeport, Grand Bahama",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":337,
           "country_id":44,
           "code":"",
           "name":"South Andros",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":338,
           "country_id":44,
           "code":"",
           "name":"Hope Town, Abaco",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":339,
           "country_id":44,
           "code":"",
           "name":"Mangrove Cay, Andros",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":340,
           "country_id":44,
           "code":"",
           "name":"Mooreʼs Island, Abaco",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":341,
           "country_id":44,
           "code":"",
           "name":"Rum Cay",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":342,
           "country_id":44,
           "code":"",
           "name":"North Andros",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":343,
           "country_id":44,
           "code":"",
           "name":"North Eleuthera",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":344,
           "country_id":44,
           "code":"",
           "name":"South Eleuthera",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":345,
           "country_id":44,
           "code":"",
           "name":"South Abaco",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":346,
           "country_id":44,
           "code":"",
           "name":"San Salvador",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":347,
           "country_id":44,
           "code":"",
           "name":"Berry Islands",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":348,
           "country_id":44,
           "code":"",
           "name":"Black Point, Exuma",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":349,
           "country_id":44,
           "code":"",
           "name":"Central Abaco",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":350,
           "country_id":44,
           "code":"",
           "name":"Central Andros",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":351,
           "country_id":44,
           "code":"",
           "name":"Central Eleuthera",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":352,
           "country_id":44,
           "code":"",
           "name":"Crooked Island",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":353,
           "country_id":44,
           "code":"",
           "name":"East Grand Bahama",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":354,
           "country_id":44,
           "code":"",
           "name":"Exuma",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":355,
           "country_id":44,
           "code":"",
           "name":"Grand Cay, Abaco",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":356,
           "country_id":44,
           "code":"",
           "name":"Spanish Wells, Eleuthera",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":357,
           "country_id":44,
           "code":"",
           "name":"West Grand Bahama",
           "standard_timezone":"(UTC-4.0) Nassau",
           "timezone":"America/Nassau"
        },
        {
           "id":358,
           "country_id":48,
           "code":"",
           "name":"Southern Governate",
           "standard_timezone":"(UTC+3.0) Bahrain",
           "timezone":"Asia/Bahrain"
        },
        {
           "id":359,
           "country_id":48,
           "code":"",
           "name":"Northern Governate",
           "standard_timezone":"(UTC+3.0) Bahrain",
           "timezone":"Asia/Bahrain"
        },
        {
           "id":360,
           "country_id":48,
           "code":"",
           "name":"Capital Governate",
           "standard_timezone":"(UTC+3.0) Bahrain",
           "timezone":"Asia/Bahrain"
        },
        {
           "id":361,
           "country_id":48,
           "code":"",
           "name":"Central Governate",
           "standard_timezone":"(UTC+3.0) Bahrain",
           "timezone":"Asia/Bahrain"
        },
        {
           "id":362,
           "country_id":48,
           "code":"",
           "name":"Muharraq Governate",
           "standard_timezone":"(UTC+3.0) Bahrain",
           "timezone":"Asia/Bahrain"
        },
        {
           "id":363,
           "country_id":50,
           "code":"",
           "name":"BG80",
           "standard_timezone":"(UTC+6.0) Dhaka",
           "timezone":"Asia/Dhaka"
        },
        {
           "id":364,
           "country_id":50,
           "code":"",
           "name":"Dhaka",
           "standard_timezone":"(UTC+6.0) Dhaka",
           "timezone":"Asia/Dhaka"
        },
        {
           "id":365,
           "country_id":50,
           "code":"",
           "name":"Khulna",
           "standard_timezone":"(UTC+6.0) Dhaka",
           "timezone":"Asia/Dhaka"
        },
        {
           "id":366,
           "country_id":50,
           "code":"",
           "name":"Rājshāhi",
           "standard_timezone":"(UTC+6.0) Dhaka",
           "timezone":"Asia/Dhaka"
        },
        {
           "id":367,
           "country_id":50,
           "code":"",
           "name":"Chittagong",
           "standard_timezone":"(UTC+6.0) Dhaka",
           "timezone":"Asia/Dhaka"
        },
        {
           "id":368,
           "country_id":50,
           "code":"",
           "name":"Barisāl",
           "standard_timezone":"(UTC+6.0) Dhaka",
           "timezone":"Asia/Dhaka"
        },
        {
           "id":369,
           "country_id":50,
           "code":"",
           "name":"Sylhet",
           "standard_timezone":"(UTC+6.0) Dhaka",
           "timezone":"Asia/Dhaka"
        },
        {
           "id":370,
           "country_id":51,
           "code":"",
           "name":"Aragatsotn",
           "standard_timezone":"(UTC+4.0) Yerevan",
           "timezone":"Asia/Yerevan"
        },
        {
           "id":371,
           "country_id":51,
           "code":"",
           "name":"Ararat",
           "standard_timezone":"(UTC+4.0) Yerevan",
           "timezone":"Asia/Yerevan"
        },
        {
           "id":372,
           "country_id":51,
           "code":"",
           "name":"Armavir",
           "standard_timezone":"(UTC+4.0) Yerevan",
           "timezone":"Asia/Yerevan"
        },
        {
           "id":373,
           "country_id":51,
           "code":"",
           "name":"Gegharkʼunikʼ",
           "standard_timezone":"(UTC+4.0) Yerevan",
           "timezone":"Asia/Yerevan"
        },
        {
           "id":374,
           "country_id":51,
           "code":"",
           "name":"Kotaykʼ",
           "standard_timezone":"(UTC+4.0) Yerevan",
           "timezone":"Asia/Yerevan"
        },
        {
           "id":375,
           "country_id":51,
           "code":"",
           "name":"Lorri",
           "standard_timezone":"(UTC+4.0) Yerevan",
           "timezone":"Asia/Yerevan"
        },
        {
           "id":376,
           "country_id":51,
           "code":"",
           "name":"Shirak",
           "standard_timezone":"(UTC+4.0) Yerevan",
           "timezone":"Asia/Yerevan"
        },
        {
           "id":377,
           "country_id":51,
           "code":"",
           "name":"Syunikʼ",
           "standard_timezone":"(UTC+4.0) Yerevan",
           "timezone":"Asia/Yerevan"
        },
        {
           "id":378,
           "country_id":51,
           "code":"",
           "name":"Tavush",
           "standard_timezone":"(UTC+4.0) Yerevan",
           "timezone":"Asia/Yerevan"
        },
        {
           "id":379,
           "country_id":51,
           "code":"",
           "name":"Vayotsʼ Dzor",
           "standard_timezone":"(UTC+4.0) Yerevan",
           "timezone":"Asia/Yerevan"
        },
        {
           "id":380,
           "country_id":51,
           "code":"",
           "name":"Yerevan",
           "standard_timezone":"(UTC+4.0) Yerevan",
           "timezone":"Asia/Yerevan"
        },
        {
           "id":381,
           "country_id":52,
           "code":"",
           "name":"Christ Church",
           "standard_timezone":"(UTC-4.0) Barbados",
           "timezone":"America/Barbados"
        },
        {
           "id":382,
           "country_id":52,
           "code":"",
           "name":"Saint Andrew",
           "standard_timezone":"(UTC-4.0) Barbados",
           "timezone":"America/Barbados"
        },
        {
           "id":383,
           "country_id":52,
           "code":"",
           "name":"Saint George",
           "standard_timezone":"(UTC-4.0) Barbados",
           "timezone":"America/Barbados"
        },
        {
           "id":384,
           "country_id":52,
           "code":"",
           "name":"Saint James",
           "standard_timezone":"(UTC-4.0) Barbados",
           "timezone":"America/Barbados"
        },
        {
           "id":385,
           "country_id":52,
           "code":"",
           "name":"Saint John",
           "standard_timezone":"(UTC-4.0) Barbados",
           "timezone":"America/Barbados"
        },
        {
           "id":386,
           "country_id":52,
           "code":"",
           "name":"Saint Joseph",
           "standard_timezone":"(UTC-4.0) Barbados",
           "timezone":"America/Barbados"
        },
        {
           "id":387,
           "country_id":52,
           "code":"",
           "name":"Saint Lucy",
           "standard_timezone":"(UTC-4.0) Barbados",
           "timezone":"America/Barbados"
        },
        {
           "id":388,
           "country_id":52,
           "code":"",
           "name":"Saint Michael",
           "standard_timezone":"(UTC-4.0) Barbados",
           "timezone":"America/Barbados"
        },
        {
           "id":389,
           "country_id":52,
           "code":"",
           "name":"Saint Peter",
           "standard_timezone":"(UTC-4.0) Barbados",
           "timezone":"America/Barbados"
        },
        {
           "id":390,
           "country_id":52,
           "code":"",
           "name":"Saint Philip",
           "standard_timezone":"(UTC-4.0) Barbados",
           "timezone":"America/Barbados"
        },
        {
           "id":391,
           "country_id":52,
           "code":"",
           "name":"Saint Thomas",
           "standard_timezone":"(UTC-4.0) Barbados",
           "timezone":"America/Barbados"
        },
        {
           "id":392,
           "country_id":56,
           "code":"",
           "name":"Bruxelles-Capitale",
           "standard_timezone":"(UTC+2.0) Brussels",
           "timezone":"Europe/Brussels"
        },
        {
           "id":393,
           "country_id":56,
           "code":"",
           "name":"Flanders",
           "standard_timezone":"(UTC+2.0) Brussels",
           "timezone":"Europe/Brussels"
        },
        {
           "id":394,
           "country_id":56,
           "code":"",
           "name":"Wallonia",
           "standard_timezone":"(UTC+2.0) Brussels",
           "timezone":"Europe/Brussels"
        },
        {
           "id":395,
           "country_id":60,
           "code":"",
           "name":"Devonshire",
           "standard_timezone":"(UTC-3.0) Bermuda",
           "timezone":"Atlantic/Bermuda"
        },
        {
           "id":396,
           "country_id":60,
           "code":"",
           "name":"Hamilton (parish)",
           "standard_timezone":"(UTC-3.0) Bermuda",
           "timezone":"Atlantic/Bermuda"
        },
        {
           "id":397,
           "country_id":60,
           "code":"",
           "name":"Hamilton (city)",
           "standard_timezone":"(UTC-3.0) Bermuda",
           "timezone":"Atlantic/Bermuda"
        },
        {
           "id":398,
           "country_id":60,
           "code":"",
           "name":"Paget",
           "standard_timezone":"(UTC-3.0) Bermuda",
           "timezone":"Atlantic/Bermuda"
        },
        {
           "id":399,
           "country_id":60,
           "code":"",
           "name":"Pembroke",
           "standard_timezone":"(UTC-3.0) Bermuda",
           "timezone":"Atlantic/Bermuda"
        },
        {
           "id":400,
           "country_id":60,
           "code":"",
           "name":"Saint Georgeʼs (parish)",
           "standard_timezone":"(UTC-3.0) Bermuda",
           "timezone":"Atlantic/Bermuda"
        },
        {
           "id":401,
           "country_id":60,
           "code":"",
           "name":"Saint Georgeʼs (city)",
           "standard_timezone":"(UTC-3.0) Bermuda",
           "timezone":"Atlantic/Bermuda"
        },
        {
           "id":402,
           "country_id":60,
           "code":"",
           "name":"Sandys",
           "standard_timezone":"(UTC-3.0) Bermuda",
           "timezone":"Atlantic/Bermuda"
        },
        {
           "id":403,
           "country_id":60,
           "code":"",
           "name":"Smithʼs",
           "standard_timezone":"(UTC-3.0) Bermuda",
           "timezone":"Atlantic/Bermuda"
        },
        {
           "id":404,
           "country_id":60,
           "code":"",
           "name":"Southampton",
           "standard_timezone":"(UTC-3.0) Bermuda",
           "timezone":"Atlantic/Bermuda"
        },
        {
           "id":405,
           "country_id":60,
           "code":"",
           "name":"Warwick",
           "standard_timezone":"(UTC-3.0) Bermuda",
           "timezone":"Atlantic/Bermuda"
        },
        {
           "id":406,
           "country_id":64,
           "code":"",
           "name":"Bumthang",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":407,
           "country_id":64,
           "code":"",
           "name":"Chhukha",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":408,
           "country_id":64,
           "code":"",
           "name":"Chirang",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":409,
           "country_id":64,
           "code":"",
           "name":"Daga",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":410,
           "country_id":64,
           "code":"",
           "name":"Geylegphug",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":411,
           "country_id":64,
           "code":"",
           "name":"Ha",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":412,
           "country_id":64,
           "code":"",
           "name":"Lhuntshi",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":413,
           "country_id":64,
           "code":"",
           "name":"Mongar",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":414,
           "country_id":64,
           "code":"",
           "name":"Paro District",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":415,
           "country_id":64,
           "code":"",
           "name":"Pemagatsel",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":416,
           "country_id":64,
           "code":"",
           "name":"Samchi",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":417,
           "country_id":64,
           "code":"",
           "name":"Samdrup Jongkhar District",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":418,
           "country_id":64,
           "code":"",
           "name":"Shemgang",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":419,
           "country_id":64,
           "code":"",
           "name":"Tashigang",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":420,
           "country_id":64,
           "code":"",
           "name":"Thimphu",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":421,
           "country_id":64,
           "code":"",
           "name":"Tongsa",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":422,
           "country_id":64,
           "code":"",
           "name":"Wangdi Phodrang",
           "standard_timezone":"(UTC+6.0) Thimphu",
           "timezone":"Asia/Thimphu"
        },
        {
           "id":423,
           "country_id":68,
           "code":"",
           "name":"Chuquisaca",
           "standard_timezone":"(UTC-4.0) La_Paz",
           "timezone":"America/La_Paz"
        },
        {
           "id":424,
           "country_id":68,
           "code":"",
           "name":"Cochabamba",
           "standard_timezone":"(UTC-4.0) La_Paz",
           "timezone":"America/La_Paz"
        },
        {
           "id":425,
           "country_id":68,
           "code":"",
           "name":"El Beni",
           "standard_timezone":"(UTC-4.0) La_Paz",
           "timezone":"America/La_Paz"
        },
        {
           "id":426,
           "country_id":68,
           "code":"",
           "name":"La Paz",
           "standard_timezone":"(UTC-4.0) La_Paz",
           "timezone":"America/La_Paz"
        },
        {
           "id":427,
           "country_id":68,
           "code":"",
           "name":"Oruro",
           "standard_timezone":"(UTC-4.0) La_Paz",
           "timezone":"America/La_Paz"
        },
        {
           "id":428,
           "country_id":68,
           "code":"",
           "name":"Pando",
           "standard_timezone":"(UTC-4.0) La_Paz",
           "timezone":"America/La_Paz"
        },
        {
           "id":429,
           "country_id":68,
           "code":"",
           "name":"Potosí",
           "standard_timezone":"(UTC-4.0) La_Paz",
           "timezone":"America/La_Paz"
        },
        {
           "id":430,
           "country_id":68,
           "code":"",
           "name":"Santa Cruz",
           "standard_timezone":"(UTC-4.0) La_Paz",
           "timezone":"America/La_Paz"
        },
        {
           "id":431,
           "country_id":68,
           "code":"",
           "name":"Tarija",
           "standard_timezone":"(UTC-4.0) La_Paz",
           "timezone":"America/La_Paz"
        },
        {
           "id":432,
           "country_id":70,
           "code":"",
           "name":"Federation of Bosnia and Herzegovina",
           "standard_timezone":"(UTC+2.0) Sarajevo",
           "timezone":"Europe/Sarajevo"
        },
        {
           "id":433,
           "country_id":70,
           "code":"",
           "name":"Republika Srpska",
           "standard_timezone":"(UTC+2.0) Sarajevo",
           "timezone":"Europe/Sarajevo"
        },
        {
           "id":434,
           "country_id":70,
           "code":"",
           "name":"Brčko",
           "standard_timezone":"(UTC+2.0) Sarajevo",
           "timezone":"Europe/Sarajevo"
        },
        {
           "id":435,
           "country_id":72,
           "code":"",
           "name":"Central",
           "standard_timezone":"(UTC+2.0) Gaborone",
           "timezone":"Africa/Gaborone"
        },
        {
           "id":436,
           "country_id":72,
           "code":"",
           "name":"Chobe",
           "standard_timezone":"(UTC+2.0) Gaborone",
           "timezone":"Africa/Gaborone"
        },
        {
           "id":437,
           "country_id":72,
           "code":"",
           "name":"Ghanzi",
           "standard_timezone":"(UTC+2.0) Gaborone",
           "timezone":"Africa/Gaborone"
        },
        {
           "id":438,
           "country_id":72,
           "code":"",
           "name":"Kgalagadi",
           "standard_timezone":"(UTC+2.0) Gaborone",
           "timezone":"Africa/Gaborone"
        },
        {
           "id":439,
           "country_id":72,
           "code":"",
           "name":"Kgatleng",
           "standard_timezone":"(UTC+2.0) Gaborone",
           "timezone":"Africa/Gaborone"
        },
        {
           "id":440,
           "country_id":72,
           "code":"",
           "name":"Kweneng",
           "standard_timezone":"(UTC+2.0) Gaborone",
           "timezone":"Africa/Gaborone"
        },
        {
           "id":441,
           "country_id":72,
           "code":"",
           "name":"Ngamiland",
           "standard_timezone":"(UTC+2.0) Gaborone",
           "timezone":"Africa/Gaborone"
        },
        {
           "id":442,
           "country_id":72,
           "code":"",
           "name":"North East",
           "standard_timezone":"(UTC+2.0) Gaborone",
           "timezone":"Africa/Gaborone"
        },
        {
           "id":443,
           "country_id":72,
           "code":"",
           "name":"South East",
           "standard_timezone":"(UTC+2.0) Gaborone",
           "timezone":"Africa/Gaborone"
        },
        {
           "id":444,
           "country_id":72,
           "code":"",
           "name":"Southern",
           "standard_timezone":"(UTC+2.0) Gaborone",
           "timezone":"Africa/Gaborone"
        },
        {
           "id":445,
           "country_id":72,
           "code":"",
           "name":"North West",
           "standard_timezone":"(UTC+2.0) Gaborone",
           "timezone":"Africa/Gaborone"
        },
        {
           "id":446,
           "country_id":76,
           "code":"",
           "name":"Acre",
           "standard_timezone":"(UTC-5.0) Rio_Branco",
           "timezone":"America/Rio_Branco"
        },
        {
           "id":447,
           "country_id":76,
           "code":"",
           "name":"Alagoas",
           "standard_timezone":"(UTC-3.0) Maceio",
           "timezone":"America/Maceio"
        },
        {
           "id":448,
           "country_id":76,
           "code":"",
           "name":"Amapá",
           "standard_timezone":"(UTC-3.0) Belem",
           "timezone":"America/Belem"
        },
        {
           "id":449,
           "country_id":76,
           "code":"",
           "name":"Estado do Amazonas",
           "standard_timezone":"(UTC-4.0) Manaus",
           "timezone":"America/Manaus"
        },
        {
           "id":450,
           "country_id":76,
           "code":"",
           "name":"Bahia",
           "standard_timezone":"(UTC-4.0) Bahia",
           "timezone":"America/Bahia"
        },
        {
           "id":451,
           "country_id":76,
           "code":"",
           "name":"Ceará",
           "standard_timezone":"(UTC-3.0) Fortaleza",
           "timezone":"America/Fortaleza"
        },
        {
           "id":452,
           "country_id":76,
           "code":"",
           "name":"Distrito Federal",
           "standard_timezone":"(UTC-3.0) Sao_Paulo",
           "timezone":"America/Sao_Paulo"
        },
        {
           "id":453,
           "country_id":76,
           "code":"",
           "name":"Espírito Santo",
           "standard_timezone":"(UTC-3.0) Sao_Paulo",
           "timezone":"America/Sao_Paulo"
        },
        {
           "id":454,
           "country_id":76,
           "code":"",
           "name":"Fernando de Noronha",
           "standard_timezone":"(UTC-3.0) Recife",
           "timezone":"America/Recife"
        },
        {
           "id":455,
           "country_id":76,
           "code":"",
           "name":"Goias",
           "standard_timezone":"(UTC-3.0) Sao_Paulo",
           "timezone":"America/Sao_Paulo"
        },
        {
           "id":456,
           "country_id":76,
           "code":"",
           "name":"Mato Grosso do Sul",
           "standard_timezone":"(UTC-4.0) Campo_Grande",
           "timezone":"America/Campo_Grande"
        },
        {
           "id":457,
           "country_id":76,
           "code":"",
           "name":"Maranhão",
           "standard_timezone":"(UTC-3.0) Fortaleza",
           "timezone":"America/Fortaleza"
        },
        {
           "id":458,
           "country_id":76,
           "code":"",
           "name":"Mato Grosso",
           "standard_timezone":"(UTC-4.0) Cuiaba",
           "timezone":"America/Cuiaba"
        },
        {
           "id":459,
           "country_id":76,
           "code":"",
           "name":"Minas Gerais",
           "standard_timezone":"(UTC-3.0) Sao_Paulo",
           "timezone":"America/Sao_Paulo"
        },
        {
           "id":460,
           "country_id":76,
           "code":"",
           "name":"Pará",
           "standard_timezone":"(UTC-3.0) Belem",
           "timezone":"America/Belem"
        },
        {
           "id":461,
           "country_id":76,
           "code":"",
           "name":"Paraíba",
           "standard_timezone":"(UTC-3.0) Fortaleza",
           "timezone":"America/Fortaleza"
        },
        {
           "id":462,
           "country_id":76,
           "code":"",
           "name":"Paraná",
           "standard_timezone":"(UTC-3.0) Sao_Paulo",
           "timezone":"America/Sao_Paulo"
        },
        {
           "id":463,
           "country_id":76,
           "code":"",
           "name":"Pernambuco",
           "standard_timezone":"(UTC -2:00 )Noronha",
           "timezone":"America/Noronha"
        },
        {
           "id":464,
           "country_id":76,
           "code":"",
           "name":"Piauí",
           "standard_timezone":"(UTC-3.0) Fortaleza",
           "timezone":"America/Fortaleza"
        },
        {
           "id":465,
           "country_id":76,
           "code":"",
           "name":"State of Rio de Janeiro",
           "standard_timezone":"(UTC-3.0) Sao_Paulo",
           "timezone":"America/Sao_Paulo"
        },
        {
           "id":466,
           "country_id":76,
           "code":"",
           "name":"Rio Grande do Norte",
           "standard_timezone":"(UTC-3.0) Fortaleza",
           "timezone":"America/Fortaleza"
        },
        {
           "id":467,
           "country_id":76,
           "code":"",
           "name":"Rio Grande do Sul",
           "standard_timezone":"(UTC-3.0) Sao_Paulo",
           "timezone":"America/Sao_Paulo"
        },
        {
           "id":468,
           "country_id":76,
           "code":"",
           "name":"Rondônia",
           "standard_timezone":"(UTC-4.0) Porto_Velho",
           "timezone":"America/Porto_Velho"
        },
        {
           "id":469,
           "country_id":76,
           "code":"",
           "name":"Roraima",
           "standard_timezone":"(UTC-4.0) Boa_Vista",
           "timezone":"America/Boa_Vista"
        },
        {
           "id":470,
           "country_id":76,
           "code":"",
           "name":"Santa Catarina",
           "standard_timezone":"(UTC-3.0) Sao_Paulo",
           "timezone":"America/Sao_Paulo"
        },
        {
           "id":471,
           "country_id":76,
           "code":"",
           "name":"São Paulo",
           "standard_timezone":"(UTC-3.0) Sao_Paulo",
           "timezone":"America/Sao_Paulo"
        },
        {
           "id":472,
           "country_id":76,
           "code":"",
           "name":"Sergipe",
           "standard_timezone":"(UTC-3.0) Maceio",
           "timezone":"America/Maceio"
        },
        {
           "id":473,
           "country_id":76,
           "code":"",
           "name":"Estado de Goiás",
           "standard_timezone":"(UTC-3.0) Sao_Paulo",
           "timezone":"America/Sao_Paulo"
        },
        {
           "id":474,
           "country_id":76,
           "code":"",
           "name":"Pernambuco",
           "standard_timezone":"(UTC -2:00 )Noronha",
           "timezone":"America/Noronha"
        },
        {
           "id":475,
           "country_id":76,
           "code":"",
           "name":"Tocantins",
           "standard_timezone":"(UTC-3.0) Araguaina",
           "timezone":"America/Araguaina"
        },
        {
           "id":476,
           "country_id":84,
           "code":"",
           "name":"Belize",
           "standard_timezone":"(UTC-6.0) Belize",
           "timezone":"America/Belize"
        },
        {
           "id":477,
           "country_id":84,
           "code":"",
           "name":"Cayo",
           "standard_timezone":"(UTC-6.0) Belize",
           "timezone":"America/Belize"
        },
        {
           "id":478,
           "country_id":84,
           "code":"",
           "name":"Corozal",
           "standard_timezone":"(UTC-6.0) Belize",
           "timezone":"America/Belize"
        },
        {
           "id":479,
           "country_id":84,
           "code":"",
           "name":"Orange Walk",
           "standard_timezone":"(UTC-6.0) Belize",
           "timezone":"America/Belize"
        },
        {
           "id":480,
           "country_id":84,
           "code":"",
           "name":"Stann Creek",
           "standard_timezone":"(UTC-6.0) Belize",
           "timezone":"America/Belize"
        },
        {
           "id":481,
           "country_id":84,
           "code":"",
           "name":"Toledo",
           "standard_timezone":"(UTC-6.0) Belize",
           "timezone":"America/Belize"
        },
        {
           "id":482,
           "country_id":86,
           "code":"",
           "name":"British Indian Ocean Territory",
           "standard_timezone":"(UTC +6:00)/Chagos",
           "timezone":"Indian/Chagos"
        },
        {
           "id":483,
           "country_id":90,
           "code":"",
           "name":"Malaita",
           "standard_timezone":"(UTC+2.0) Guadalcanal",
           "timezone":"Pacific/Guadalcanal"
        },
        {
           "id":484,
           "country_id":90,
           "code":"",
           "name":"Western",
           "standard_timezone":"(UTC+2.0) Guadalcanal",
           "timezone":"Pacific/Guadalcanal"
        },
        {
           "id":485,
           "country_id":90,
           "code":"",
           "name":"Central",
           "standard_timezone":"(UTC+2.0) Guadalcanal",
           "timezone":"Pacific/Guadalcanal"
        },
        {
           "id":486,
           "country_id":90,
           "code":"",
           "name":"Guadalcanal",
           "standard_timezone":"(UTC+2.0) Guadalcanal",
           "timezone":"Pacific/Guadalcanal"
        },
        {
           "id":487,
           "country_id":90,
           "code":"",
           "name":"Isabel",
           "standard_timezone":"(UTC+2.0) Guadalcanal",
           "timezone":"Pacific/Guadalcanal"
        },
        {
           "id":488,
           "country_id":90,
           "code":"",
           "name":"Makira",
           "standard_timezone":"(UTC+2.0) Guadalcanal",
           "timezone":"Pacific/Guadalcanal"
        },
        {
           "id":489,
           "country_id":90,
           "code":"",
           "name":"Temotu",
           "standard_timezone":"(UTC+2.0) Guadalcanal",
           "timezone":"Pacific/Guadalcanal"
        },
        {
           "id":490,
           "country_id":90,
           "code":"",
           "name":"Central Province",
           "standard_timezone":"(UTC+2.0) Guadalcanal",
           "timezone":"Pacific/Guadalcanal"
        },
        {
           "id":491,
           "country_id":90,
           "code":"",
           "name":"Choiseul",
           "standard_timezone":"(UTC+2.0) Guadalcanal",
           "timezone":"Pacific/Guadalcanal"
        },
        {
           "id":492,
           "country_id":90,
           "code":"",
           "name":"Rennell and Bellona",
           "standard_timezone":"(UTC+2.0) Guadalcanal",
           "timezone":"Pacific/Guadalcanal"
        },
        {
           "id":493,
           "country_id":90,
           "code":"",
           "name":"Rennell and Bellona",
           "standard_timezone":"(UTC+2.0) Guadalcanal",
           "timezone":"Pacific/Guadalcanal"
        },
        {
           "id":494,
           "country_id":92,
           "code":"",
           "name":"British Virgin Islands",
           "standard_timezone":"(UTC+2.0) Tortola",
           "timezone":"America/Tortola"
        },
        {
           "id":495,
           "country_id":96,
           "code":"",
           "name":"Belait",
           "standard_timezone":"(UTC+8.0) Brunei",
           "timezone":"Asia/Brunei"
        },
        {
           "id":496,
           "country_id":96,
           "code":"",
           "name":"Brunei and Muara",
           "standard_timezone":"(UTC+8.0) Brunei",
           "timezone":"Asia/Brunei"
        },
        {
           "id":497,
           "country_id":96,
           "code":"",
           "name":"Temburong",
           "standard_timezone":"(UTC+8.0) Brunei",
           "timezone":"Asia/Brunei"
        },
        {
           "id":498,
           "country_id":96,
           "code":"",
           "name":"Tutong",
           "standard_timezone":"(UTC+8.0) Brunei",
           "timezone":"Asia/Brunei"
        },
        {
           "id":499,
           "country_id":100,
           "code":"",
           "name":"Burgas",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":500,
           "country_id":100,
           "code":"",
           "name":"Grad",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":501,
           "country_id":100,
           "code":"",
           "name":"Khaskovo",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":502,
           "country_id":100,
           "code":"",
           "name":"Lovech",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":503,
           "country_id":100,
           "code":"",
           "name":"Mikhaylovgrad",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":504,
           "country_id":100,
           "code":"",
           "name":"Plovdiv",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":505,
           "country_id":100,
           "code":"",
           "name":"Razgrad",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":506,
           "country_id":100,
           "code":"",
           "name":"Sofiya",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":507,
           "country_id":100,
           "code":"",
           "name":"Varna",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":508,
           "country_id":100,
           "code":"",
           "name":"Blagoevgrad",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":509,
           "country_id":100,
           "code":"",
           "name":"Burgas",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":510,
           "country_id":100,
           "code":"",
           "name":"Dobrich",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":511,
           "country_id":100,
           "code":"",
           "name":"Gabrovo",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":512,
           "country_id":100,
           "code":"",
           "name":"Oblast Sofiya-Grad",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":513,
           "country_id":100,
           "code":"",
           "name":"Khaskovo",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":514,
           "country_id":100,
           "code":"",
           "name":"Kŭrdzhali",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":515,
           "country_id":100,
           "code":"",
           "name":"Kyustendil",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":516,
           "country_id":100,
           "code":"",
           "name":"Lovech",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":517,
           "country_id":100,
           "code":"",
           "name":"Montana",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":518,
           "country_id":100,
           "code":"",
           "name":"Pazardzhit",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":519,
           "country_id":100,
           "code":"",
           "name":"Pernik",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":520,
           "country_id":100,
           "code":"",
           "name":"Pleven",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":521,
           "country_id":100,
           "code":"",
           "name":"Plovdiv",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":522,
           "country_id":100,
           "code":"",
           "name":"Razgrad",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":523,
           "country_id":100,
           "code":"",
           "name":"Ruse",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":524,
           "country_id":100,
           "code":"",
           "name":"Shumen",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":525,
           "country_id":100,
           "code":"",
           "name":"Silistra",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":526,
           "country_id":100,
           "code":"",
           "name":"Sliven",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":527,
           "country_id":100,
           "code":"",
           "name":"Smolyan",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":528,
           "country_id":100,
           "code":"",
           "name":"Sofiya",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":529,
           "country_id":100,
           "code":"",
           "name":"Stara Zagora",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":530,
           "country_id":100,
           "code":"",
           "name":"Tŭrgovishte",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":531,
           "country_id":100,
           "code":"",
           "name":"Varna",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":532,
           "country_id":100,
           "code":"",
           "name":"Veliko Tŭrnovo",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":533,
           "country_id":100,
           "code":"",
           "name":"Vidin",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":534,
           "country_id":100,
           "code":"",
           "name":"Vratsa",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":535,
           "country_id":100,
           "code":"",
           "name":"Yambol",
           "standard_timezone":"(UTC+3.0) Sofia",
           "timezone":"Europe/Sofia"
        },
        {
           "id":536,
           "country_id":104,
           "code":"",
           "name":"Rakhine State",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":537,
           "country_id":104,
           "code":"",
           "name":"Chin State",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":538,
           "country_id":104,
           "code":"",
           "name":"Ayeyarwady",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":539,
           "country_id":104,
           "code":"",
           "name":"Kachin State",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":540,
           "country_id":104,
           "code":"",
           "name":"Kayin State",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":541,
           "country_id":104,
           "code":"",
           "name":"Kayah State",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":542,
           "country_id":104,
           "code":"",
           "name":"Magwe",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":543,
           "country_id":104,
           "code":"",
           "name":"Mandalay",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":544,
           "country_id":104,
           "code":"",
           "name":"Pegu",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":545,
           "country_id":104,
           "code":"",
           "name":"Sagain",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":546,
           "country_id":104,
           "code":"",
           "name":"Shan State",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":547,
           "country_id":104,
           "code":"",
           "name":"Tanintharyi",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":548,
           "country_id":104,
           "code":"",
           "name":"Mon State",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":549,
           "country_id":104,
           "code":"",
           "name":"Rangoon",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":550,
           "country_id":104,
           "code":"",
           "name":"Magway",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":551,
           "country_id":104,
           "code":"",
           "name":"Bago",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":552,
           "country_id":104,
           "code":"",
           "name":"Yangon",
           "standard_timezone":"(UTC+6.50) Rangoon",
           "timezone":"Asia/Rangoon"
        },
        {
           "id":553,
           "country_id":108,
           "code":"",
           "name":"Bujumbura",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":554,
           "country_id":108,
           "code":"",
           "name":"Bubanza",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":555,
           "country_id":108,
           "code":"",
           "name":"Bururi",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":556,
           "country_id":108,
           "code":"",
           "name":"Cankuzo",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":557,
           "country_id":108,
           "code":"",
           "name":"Cibitoke",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":558,
           "country_id":108,
           "code":"",
           "name":"Gitega",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":559,
           "country_id":108,
           "code":"",
           "name":"Karuzi",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":560,
           "country_id":108,
           "code":"",
           "name":"Kayanza",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":561,
           "country_id":108,
           "code":"",
           "name":"Kirundo",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":562,
           "country_id":108,
           "code":"",
           "name":"Makamba",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":563,
           "country_id":108,
           "code":"",
           "name":"Muyinga",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":564,
           "country_id":108,
           "code":"",
           "name":"Ngozi",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":565,
           "country_id":108,
           "code":"",
           "name":"Rutana",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":566,
           "country_id":108,
           "code":"",
           "name":"Ruyigi",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":567,
           "country_id":108,
           "code":"",
           "name":"Muramvya",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":568,
           "country_id":108,
           "code":"",
           "name":"Mwaro",
           "standard_timezone":"(UTC+2.0) Bujumbura",
           "timezone":"Africa/Bujumbura"
        },
        {
           "id":569,
           "country_id":112,
           "code":"",
           "name":"Brestskaya Voblastsʼ",
           "standard_timezone":"(UTC+3.0) Minsk",
           "timezone":"Europe/Minsk"
        },
        {
           "id":570,
           "country_id":112,
           "code":"",
           "name":"Homyelʼskaya Voblastsʼ",
           "standard_timezone":"(UTC+3.0) Minsk",
           "timezone":"Europe/Minsk"
        },
        {
           "id":571,
           "country_id":112,
           "code":"",
           "name":"Hrodzyenskaya Voblastsʼ",
           "standard_timezone":"(UTC+3.0) Minsk",
           "timezone":"Europe/Minsk"
        },
        {
           "id":572,
           "country_id":112,
           "code":"",
           "name":"Mahilyowskaya Voblastsʼ",
           "standard_timezone":"(UTC+3.0) Minsk",
           "timezone":"Europe/Minsk"
        },
        {
           "id":573,
           "country_id":112,
           "code":"",
           "name":"Horad Minsk",
           "standard_timezone":"(UTC+3.0) Minsk",
           "timezone":"Europe/Minsk"
        },
        {
           "id":574,
           "country_id":112,
           "code":"",
           "name":"Minskaya Voblastsʼ",
           "standard_timezone":"(UTC+3.0) Minsk",
           "timezone":"Europe/Minsk"
        },
        {
           "id":575,
           "country_id":112,
           "code":"",
           "name":"Vitsyebskaya Voblastsʼ",
           "standard_timezone":"(UTC+3.0) Minsk",
           "timezone":"Europe/Minsk"
        },
        {
           "id":576,
           "country_id":116,
           "code":"",
           "name":"Krŏng Preăh Seihânŭ",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":577,
           "country_id":116,
           "code":"",
           "name":"Kâmpóng Cham",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":578,
           "country_id":116,
           "code":"",
           "name":"Kâmpóng Chhnăng",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":579,
           "country_id":116,
           "code":"",
           "name":"Khétt Kâmpóng Spœ",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":580,
           "country_id":116,
           "code":"",
           "name":"Kâmpóng Thum",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":581,
           "country_id":116,
           "code":"",
           "name":"Kândal",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":582,
           "country_id":116,
           "code":"",
           "name":"Kaôh Kŏng",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":583,
           "country_id":116,
           "code":"",
           "name":"Krâchéh",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":584,
           "country_id":116,
           "code":"",
           "name":"Môndól Kiri",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":585,
           "country_id":116,
           "code":"",
           "name":"Phnum Penh",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":586,
           "country_id":116,
           "code":"",
           "name":"Poŭthĭsăt",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":587,
           "country_id":116,
           "code":"",
           "name":"Preăh Vihéar",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":588,
           "country_id":116,
           "code":"",
           "name":"Prey Vêng",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":589,
           "country_id":116,
           "code":"",
           "name":"Stœ̆ng Trêng",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":590,
           "country_id":116,
           "code":"",
           "name":"Svay Riĕng",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":591,
           "country_id":116,
           "code":"",
           "name":"Takêv",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":592,
           "country_id":116,
           "code":"",
           "name":"Kâmpôt",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":593,
           "country_id":116,
           "code":"",
           "name":"Phnum Pénh",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":594,
           "country_id":116,
           "code":"",
           "name":"Rôtânăh Kiri",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":595,
           "country_id":116,
           "code":"",
           "name":"Siĕm Réab",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":596,
           "country_id":116,
           "code":"",
           "name":"Bantéay Méan Cheăy",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":597,
           "country_id":116,
           "code":"",
           "name":"Kêb",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":598,
           "country_id":116,
           "code":"",
           "name":"Ŏtdâr Méan Cheăy",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":599,
           "country_id":116,
           "code":"",
           "name":"Preăh Seihânŭ",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":600,
           "country_id":116,
           "code":"",
           "name":"Bătdâmbâng",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":601,
           "country_id":116,
           "code":"",
           "name":"Palĭn",
           "standard_timezone":"(UTC+7.0) Phnom_Penh",
           "timezone":"Asia/Phnom_Penh"
        },
        {
           "id":602,
           "country_id":120,
           "code":"",
           "name":"Est",
           "standard_timezone":"(UTC+1.0) Douala",
           "timezone":"Africa/Douala"
        },
        {
           "id":603,
           "country_id":120,
           "code":"",
           "name":"Littoral",
           "standard_timezone":"(UTC+1.0) Douala",
           "timezone":"Africa/Douala"
        },
        {
           "id":604,
           "country_id":120,
           "code":"",
           "name":"North-West Province",
           "standard_timezone":"(UTC+1.0) Douala",
           "timezone":"Africa/Douala"
        },
        {
           "id":605,
           "country_id":120,
           "code":"",
           "name":"Ouest",
           "standard_timezone":"(UTC+1.0) Douala",
           "timezone":"Africa/Douala"
        },
        {
           "id":606,
           "country_id":120,
           "code":"",
           "name":"South-West Province",
           "standard_timezone":"(UTC+1.0) Douala",
           "timezone":"Africa/Douala"
        },
        {
           "id":607,
           "country_id":120,
           "code":"",
           "name":"Adamaoua",
           "standard_timezone":"(UTC+1.0) Douala",
           "timezone":"Africa/Douala"
        },
        {
           "id":608,
           "country_id":120,
           "code":"",
           "name":"Centre",
           "standard_timezone":"(UTC+1.0) Douala",
           "timezone":"Africa/Douala"
        },
        {
           "id":609,
           "country_id":120,
           "code":"",
           "name":"Extreme-Nord",
           "standard_timezone":"(UTC+1.0) Douala",
           "timezone":"Africa/Douala"
        },
        {
           "id":610,
           "country_id":120,
           "code":"",
           "name":"North Province",
           "standard_timezone":"(UTC+1.0) Douala",
           "timezone":"Africa/Douala"
        },
        {
           "id":611,
           "country_id":120,
           "code":"",
           "name":"South Province",
           "standard_timezone":"(UTC+1.0) Douala",
           "timezone":"Africa/Douala"
        },
        {
           "id":612,
           "country_id":124,
           "code":"",
           "name":"Alberta",
           "standard_timezone":"(UTC-6.0) Edmonton",
           "timezone":"America/Edmonton"
        },
        {
           "id":613,
           "country_id":124,
           "code":"",
           "name":"British Columbia",
           "standard_timezone":"(UTC-7.0) Vancouver",
           "timezone":"America/Vancouver"
        },
        {
           "id":614,
           "country_id":124,
           "code":"",
           "name":"Manitoba",
           "standard_timezone":"(UTC-5.0) Winnipeg",
           "timezone":"America/Winnipeg"
        },
        {
           "id":615,
           "country_id":124,
           "code":"",
           "name":"New Brunswick",
           "standard_timezone":"(UTC-3.0) Halifax",
           "timezone":"America/Halifax"
        },
        {
           "id":616,
           "country_id":124,
           "code":"",
           "name":"Newfoundland and Labrador",
           "standard_timezone":"(UTC-3.0) Goose_Bay",
           "timezone":"America/Goose_Bay"
        },
        {
           "id":617,
           "country_id":124,
           "code":"",
           "name":"Nova Scotia",
           "standard_timezone":"(UTC-3.0) Halifax",
           "timezone":"America/Halifax"
        },
        {
           "id":618,
           "country_id":124,
           "code":"",
           "name":"Ontario",
           "standard_timezone":"(UTC-4.0) Toronto",
           "timezone":"America/Toronto"
        },
        {
           "id":619,
           "country_id":124,
           "code":"",
           "name":"Prince Edward Island",
           "standard_timezone":"(UTC-3.0) Halifax",
           "timezone":"America/Halifax"
        },
        {
           "id":620,
           "country_id":124,
           "code":"",
           "name":"Quebec",
           "standard_timezone":"(UTC-4.0) Montreal",
           "timezone":"America/Montreal"
        },
        {
           "id":621,
           "country_id":124,
           "code":"",
           "name":"Saskatchewan",
           "standard_timezone":"(UTC-5.0) Winnipeg",
           "timezone":"America/Winnipeg"
        },
        {
           "id":622,
           "country_id":124,
           "code":"",
           "name":"Yukon",
           "standard_timezone":"(UTC-7.0) Whitehorse",
           "timezone":"America/Whitehorse"
        },
        {
           "id":623,
           "country_id":124,
           "code":"",
           "name":"Northwest Territories",
           "standard_timezone":"(UTC-6.0) Yellowknife",
           "timezone":"America/Yellowknife"
        },
        {
           "id":624,
           "country_id":124,
           "code":"",
           "name":"Nunavut",
           "standard_timezone":"(UTC-5.0) Rankin_Inlet",
           "timezone":"America/Rankin_Inlet"
        },
        {
           "id":625,
           "country_id":132,
           "code":"",
           "name":"Boa Vista",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":626,
           "country_id":132,
           "code":"",
           "name":"Brava",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":627,
           "country_id":132,
           "code":"",
           "name":"Maio",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":628,
           "country_id":132,
           "code":"",
           "name":"Paul",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":629,
           "country_id":132,
           "code":"",
           "name":"Praia",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":630,
           "country_id":132,
           "code":"",
           "name":"Ribeira Grande",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":631,
           "country_id":132,
           "code":"",
           "name":"Sal",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":632,
           "country_id":132,
           "code":"",
           "name":"Santa Catarina   ",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":633,
           "country_id":132,
           "code":"",
           "name":"São Nicolau",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":634,
           "country_id":132,
           "code":"",
           "name":"São Vicente",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":635,
           "country_id":132,
           "code":"",
           "name":"Tarrafal ",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":636,
           "country_id":132,
           "code":"",
           "name":"Mosteiros",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":637,
           "country_id":132,
           "code":"",
           "name":"Praia",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":638,
           "country_id":132,
           "code":"",
           "name":"Santa Catarina",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":639,
           "country_id":132,
           "code":"",
           "name":"Santa Cruz",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":640,
           "country_id":132,
           "code":"",
           "name":"São Domingos",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":641,
           "country_id":132,
           "code":"",
           "name":"São Filipe",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":642,
           "country_id":132,
           "code":"",
           "name":"São Miguel",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":643,
           "country_id":132,
           "code":"",
           "name":"Tarrafal",
           "standard_timezone":"(UTC+2.0) Cape_Verde",
           "timezone":"Atlantic/Cape_Verde"
        },
        {
           "id":644,
           "country_id":136,
           "code":"",
           "name":"Creek",
           "standard_timezone":"(UTC-5.0) Cayman",
           "timezone":"America/Cayman"
        },
        {
           "id":645,
           "country_id":136,
           "code":"",
           "name":"Eastern",
           "standard_timezone":"(UTC-5.0) Cayman",
           "timezone":"America/Cayman"
        },
        {
           "id":646,
           "country_id":136,
           "code":"",
           "name":"Midland",
           "standard_timezone":"(UTC-5.0) Cayman",
           "timezone":"America/Cayman"
        },
        {
           "id":647,
           "country_id":136,
           "code":"",
           "name":"South Town",
           "standard_timezone":"(UTC-5.0) Cayman",
           "timezone":"America/Cayman"
        },
        {
           "id":648,
           "country_id":136,
           "code":"",
           "name":"Spot Bay",
           "standard_timezone":"(UTC-5.0) Cayman",
           "timezone":"America/Cayman"
        },
        {
           "id":649,
           "country_id":136,
           "code":"",
           "name":"Stake Bay",
           "standard_timezone":"(UTC-5.0) Cayman",
           "timezone":"America/Cayman"
        },
        {
           "id":650,
           "country_id":136,
           "code":"",
           "name":"West End",
           "standard_timezone":"(UTC-5.0) Cayman",
           "timezone":"America/Cayman"
        },
        {
           "id":651,
           "country_id":136,
           "code":"",
           "name":"Western",
           "standard_timezone":"(UTC-5.0) Cayman",
           "timezone":"America/Cayman"
        },
        {
           "id":652,
           "country_id":140,
           "code":"",
           "name":"Bamingui-Bangoran",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":653,
           "country_id":140,
           "code":"",
           "name":"Basse-Kotto",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":654,
           "country_id":140,
           "code":"",
           "name":"Haute-Kotto",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":655,
           "country_id":140,
           "code":"",
           "name":"Mambéré-Kadéï",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":656,
           "country_id":140,
           "code":"",
           "name":"Haut-Mbomou",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":657,
           "country_id":140,
           "code":"",
           "name":"Kémo",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":658,
           "country_id":140,
           "code":"",
           "name":"Lobaye",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":659,
           "country_id":140,
           "code":"",
           "name":"Mbomou",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":660,
           "country_id":140,
           "code":"",
           "name":"Nana-Mambéré",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":661,
           "country_id":140,
           "code":"",
           "name":"Ouaka",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":662,
           "country_id":140,
           "code":"",
           "name":"Ouham",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":663,
           "country_id":140,
           "code":"",
           "name":"Ouham-Pendé",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":664,
           "country_id":140,
           "code":"",
           "name":"Vakaga",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":665,
           "country_id":140,
           "code":"",
           "name":"Nana-Grébizi",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":666,
           "country_id":140,
           "code":"",
           "name":"Sangha-Mbaéré",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":667,
           "country_id":140,
           "code":"",
           "name":"Ombella-Mpoko",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":668,
           "country_id":140,
           "code":"",
           "name":"Bangui",
           "standard_timezone":"(UTC+1.0) Bangui",
           "timezone":"Africa/Bangui"
        },
        {
           "id":669,
           "country_id":144,
           "code":"",
           "name":"Central",
           "standard_timezone":"(UTC+5.50) Colombo",
           "timezone":"Asia/Colombo"
        },
        {
           "id":670,
           "country_id":144,
           "code":"",
           "name":"North Central",
           "standard_timezone":"(UTC+5.50) Colombo",
           "timezone":"Asia/Colombo"
        },
        {
           "id":671,
           "country_id":144,
           "code":"",
           "name":"North Eastern",
           "standard_timezone":"(UTC+5.50) Colombo",
           "timezone":"Asia/Colombo"
        },
        {
           "id":672,
           "country_id":144,
           "code":"",
           "name":"North Western",
           "standard_timezone":"(UTC+5.50) Colombo",
           "timezone":"Asia/Colombo"
        },
        {
           "id":673,
           "country_id":144,
           "code":"",
           "name":"Sabaragamuwa",
           "standard_timezone":"(UTC+5.50) Colombo",
           "timezone":"Asia/Colombo"
        },
        {
           "id":674,
           "country_id":144,
           "code":"",
           "name":"Southern",
           "standard_timezone":"(UTC+5.50) Colombo",
           "timezone":"Asia/Colombo"
        },
        {
           "id":675,
           "country_id":144,
           "code":"",
           "name":"Uva",
           "standard_timezone":"(UTC+5.50) Colombo",
           "timezone":"Asia/Colombo"
        },
        {
           "id":676,
           "country_id":144,
           "code":"",
           "name":"Western",
           "standard_timezone":"(UTC+5.50) Colombo",
           "timezone":"Asia/Colombo"
        },
        {
           "id":677,
           "country_id":148,
           "code":"",
           "name":"Batha",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":678,
           "country_id":148,
           "code":"",
           "name":"Biltine",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":679,
           "country_id":148,
           "code":"",
           "name":"Borkou-Ennedi-Tibesti",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":680,
           "country_id":148,
           "code":"",
           "name":"Chari-Baguirmi",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":681,
           "country_id":148,
           "code":"",
           "name":"Guéra",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":682,
           "country_id":148,
           "code":"",
           "name":"Kanem",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":683,
           "country_id":148,
           "code":"",
           "name":"Lac",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":684,
           "country_id":148,
           "code":"",
           "name":"Logone Occidental",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":685,
           "country_id":148,
           "code":"",
           "name":"Logone Oriental",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":686,
           "country_id":148,
           "code":"",
           "name":"Mayo-Kébbi",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":687,
           "country_id":148,
           "code":"",
           "name":"Moyen-Chari",
           "standard_timezone":"(UTC+1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":688,
           "country_id":148,
           "code":"",
           "name":"Ouaddaï",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":689,
           "country_id":148,
           "code":"",
           "name":"Salamat",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":690,
           "country_id":148,
           "code":"",
           "name":"Tandjilé",
           "standard_timezone":"(UTC +1:00)Ndjamena",
           "timezone":"Africa/Ndjamena"
        },
        {
           "id":691,
           "country_id":152,
           "code":"",
           "name":"Valparaíso",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":692,
           "country_id":152,
           "code":"",
           "name":"Aisén del General Carlos Ibáñez del Campo",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":693,
           "country_id":152,
           "code":"",
           "name":"Antofagasta",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":694,
           "country_id":152,
           "code":"",
           "name":"Araucanía",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":695,
           "country_id":152,
           "code":"",
           "name":"Atacama",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":696,
           "country_id":152,
           "code":"",
           "name":"Bío-Bío",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":697,
           "country_id":152,
           "code":"",
           "name":"Coquimbo",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":698,
           "country_id":152,
           "code":"",
           "name":"Libertador General Bernardo OʼHiggins",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":699,
           "country_id":152,
           "code":"",
           "name":"Los Lagos",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":700,
           "country_id":152,
           "code":"",
           "name":"Magallanes y Antártica Chilena",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":701,
           "country_id":152,
           "code":"",
           "name":"Maule",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":702,
           "country_id":152,
           "code":"",
           "name":"Región Metropolitana",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":703,
           "country_id":152,
           "code":"",
           "name":"Tarapaca",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":704,
           "country_id":152,
           "code":"",
           "name":"Los Lagos",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":705,
           "country_id":152,
           "code":"",
           "name":"Tarapacá",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":706,
           "country_id":152,
           "code":"",
           "name":"Región de Arica y Parinacota",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":707,
           "country_id":152,
           "code":"",
           "name":"Región de Los Ríos",
           "standard_timezone":"(UTC-4.0) Santiago",
           "timezone":"America/Santiago"
        },
        {
           "id":708,
           "country_id":156,
           "code":"",
           "name":"Anhui",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":709,
           "country_id":156,
           "code":"",
           "name":"Zhejiang",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":710,
           "country_id":156,
           "code":"",
           "name":"Jiangxi",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":711,
           "country_id":156,
           "code":"",
           "name":"Jiangsu",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":712,
           "country_id":156,
           "code":"",
           "name":"Jilin",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":713,
           "country_id":156,
           "code":"",
           "name":"Qinghai",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":714,
           "country_id":156,
           "code":"",
           "name":"Fujian",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":715,
           "country_id":156,
           "code":"",
           "name":"Heilongjiang",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":716,
           "country_id":156,
           "code":"",
           "name":"Henan",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":717,
           "country_id":156,
           "code":"",
           "name":"disputed",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":718,
           "country_id":156,
           "code":"",
           "name":"Hebei",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":719,
           "country_id":156,
           "code":"",
           "name":"Hunan Province",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":720,
           "country_id":156,
           "code":"",
           "name":"Hubei",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":721,
           "country_id":156,
           "code":"",
           "name":"Xinjiang",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":722,
           "country_id":156,
           "code":"",
           "name":"Xizang",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":723,
           "country_id":156,
           "code":"",
           "name":"Gansu",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":724,
           "country_id":156,
           "code":"",
           "name":"Guangxi",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":725,
           "country_id":156,
           "code":"",
           "name":"Guizhou",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":726,
           "country_id":156,
           "code":"",
           "name":"Liaoning Province",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":727,
           "country_id":156,
           "code":"",
           "name":"Inner Mongolia",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":728,
           "country_id":156,
           "code":"",
           "name":"Ningxia",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":729,
           "country_id":156,
           "code":"",
           "name":"Beijing",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":730,
           "country_id":156,
           "code":"",
           "name":"Shanghai",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":731,
           "country_id":156,
           "code":"",
           "name":"Shanxi",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":732,
           "country_id":156,
           "code":"",
           "name":"Shandong",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":733,
           "country_id":156,
           "code":"",
           "name":"Shaanxi",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":734,
           "country_id":156,
           "code":"",
           "name":"Tianjin",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":735,
           "country_id":156,
           "code":"",
           "name":"Yunnan Province",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":736,
           "country_id":156,
           "code":"",
           "name":"Guangdong",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":737,
           "country_id":156,
           "code":"",
           "name":"Hainan Province",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":738,
           "country_id":156,
           "code":"",
           "name":"Sichuan",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":739,
           "country_id":156,
           "code":"",
           "name":"Chongqing",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":740,
           "country_id":156,
           "code":"",
           "name":"PF99",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":741,
           "country_id":158,
           "code":"",
           "name":"Fukien",
           "standard_timezone":"(UTC+8.0) Taipei",
           "timezone":"Asia/Taipei"
        },
        {
           "id":742,
           "country_id":158,
           "code":"",
           "name":"Kaohsiung",
           "standard_timezone":"(UTC+8.0) Taipei",
           "timezone":"Asia/Taipei"
        },
        {
           "id":743,
           "country_id":158,
           "code":"",
           "name":"Taipei",
           "standard_timezone":"(UTC+8.0) Taipei",
           "timezone":"Asia/Taipei"
        },
        {
           "id":744,
           "country_id":158,
           "code":"",
           "name":"Taiwan",
           "standard_timezone":"(UTC+8.0) Taipei",
           "timezone":"Asia/Taipei"
        },
        {
           "id":745,
           "country_id":162,
           "code":"",
           "name":"Christmas Island",
           "standard_timezone":"(UTC-7.0) Christmas",
           "timezone":"Indian/Christmas"
        },
        {
           "id":746,
           "country_id":166,
           "code":"",
           "name":"Cocos (Keeling) Islands",
           "standard_timezone":"(UTC-3.0) Cocos",
           "timezone":"Indian/Cocos"
        },
        {
           "id":747,
           "country_id":170,
           "code":"",
           "name":"Amazonas",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":748,
           "country_id":170,
           "code":"",
           "name":"Antioquia",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":749,
           "country_id":170,
           "code":"",
           "name":"Arauca",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":750,
           "country_id":170,
           "code":"",
           "name":"Atlántico",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":751,
           "country_id":170,
           "code":"",
           "name":"Bolívar",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":752,
           "country_id":170,
           "code":"",
           "name":"Boyacá",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":753,
           "country_id":170,
           "code":"",
           "name":"Caldas",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":754,
           "country_id":170,
           "code":"",
           "name":"Caquetá",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":755,
           "country_id":170,
           "code":"",
           "name":"Cauca",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":756,
           "country_id":170,
           "code":"",
           "name":"Cesar",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":757,
           "country_id":170,
           "code":"",
           "name":"Chocó",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":758,
           "country_id":170,
           "code":"",
           "name":"Córdoba",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":759,
           "country_id":170,
           "code":"",
           "name":"Guaviare",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":760,
           "country_id":170,
           "code":"",
           "name":"Guainía",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":761,
           "country_id":170,
           "code":"",
           "name":"Huila",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":762,
           "country_id":170,
           "code":"",
           "name":"La Guajira",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":763,
           "country_id":170,
           "code":"",
           "name":"Magdalena",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":764,
           "country_id":170,
           "code":"",
           "name":"Meta",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":765,
           "country_id":170,
           "code":"",
           "name":"Nariño",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":766,
           "country_id":170,
           "code":"",
           "name":"Norte de Santander",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":767,
           "country_id":170,
           "code":"",
           "name":"Putumayo",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":768,
           "country_id":170,
           "code":"",
           "name":"Quindío",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":769,
           "country_id":170,
           "code":"",
           "name":"Risaralda",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":770,
           "country_id":170,
           "code":"",
           "name":"Archipiélago de San Andrés, Providencia y San",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":771,
           "country_id":170,
           "code":"",
           "name":"Santander",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":772,
           "country_id":170,
           "code":"",
           "name":"Sucre",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":773,
           "country_id":170,
           "code":"",
           "name":"Tolima",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":774,
           "country_id":170,
           "code":"",
           "name":"Valle del Cauca",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":775,
           "country_id":170,
           "code":"",
           "name":"Vaupés",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":776,
           "country_id":170,
           "code":"",
           "name":"Vichada",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":777,
           "country_id":170,
           "code":"",
           "name":"Casanare",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":778,
           "country_id":170,
           "code":"",
           "name":"Cundinamarca",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":779,
           "country_id":170,
           "code":"",
           "name":"Bogota D.C.",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":780,
           "country_id":170,
           "code":"",
           "name":"Bolívar",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":781,
           "country_id":170,
           "code":"",
           "name":"Boyacá",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":782,
           "country_id":170,
           "code":"",
           "name":"Caldas",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":783,
           "country_id":170,
           "code":"",
           "name":"Magdalena",
           "standard_timezone":"(UTC-5.0) Bogota",
           "timezone":"America/Bogota"
        },
        {
           "id":784,
           "country_id":174,
           "code":"",
           "name":"Anjouan",
           "standard_timezone":"(UTC+1.0) Comoro",
           "timezone":"Indian/Comoro"
        },
        {
           "id":785,
           "country_id":174,
           "code":"",
           "name":"Grande Comore",
           "standard_timezone":"(UTC+1.0) Comoro",
           "timezone":"Indian/Comoro"
        },
        {
           "id":786,
           "country_id":174,
           "code":"",
           "name":"Mohéli",
           "standard_timezone":"(UTC+1.0) Comoro",
           "timezone":"Indian/Comoro"
        },
        {
           "id":787,
           "country_id":175,
           "code":"",
           "name":"Mayotte",
           "standard_timezone":"(UTC+3.0) Mayotte",
           "timezone":"Indian/Mayotte"
        },
        {
           "id":788,
           "country_id":178,
           "code":"",
           "name":"Bouenza",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":789,
           "country_id":178,
           "code":"",
           "name":"CF03",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":790,
           "country_id":178,
           "code":"",
           "name":"Kouilou",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":791,
           "country_id":178,
           "code":"",
           "name":"Lékoumou",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":792,
           "country_id":178,
           "code":"",
           "name":"Likouala",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":793,
           "country_id":178,
           "code":"",
           "name":"Niari",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":794,
           "country_id":178,
           "code":"",
           "name":"Plateaux",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":795,
           "country_id":178,
           "code":"",
           "name":"Sangha",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":796,
           "country_id":178,
           "code":"",
           "name":"Pool",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":797,
           "country_id":178,
           "code":"",
           "name":"Brazzaville",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":798,
           "country_id":178,
           "code":"",
           "name":"Cuvette",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":799,
           "country_id":178,
           "code":"",
           "name":"Cuvette-Ouest",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":800,
           "country_id":178,
           "code":"",
           "name":"Pointe-Noire",
           "standard_timezone":"(UTC+1.0) Brazzaville",
           "timezone":"Africa/Brazzaville"
        },
        {
           "id":801,
           "country_id":180,
           "code":"",
           "name":"Bandundu",
           "standard_timezone":"(UTC+1.0) Kinshasa",
           "timezone":"Africa/Kinshasa"
        },
        {
           "id":802,
           "country_id":180,
           "code":"",
           "name":"Équateur",
           "standard_timezone":"(UTC+1.0) Kinshasa",
           "timezone":"Africa/Kinshasa"
        },
        {
           "id":803,
           "country_id":180,
           "code":"",
           "name":"Kasaï-Occidental",
           "standard_timezone":"(UTC+2.0) Lubumbashi",
           "timezone":"Africa/Lubumbashi"
        },
        {
           "id":804,
           "country_id":180,
           "code":"",
           "name":"Kasaï-Oriental",
           "standard_timezone":"(UTC+2.0) Lubumbashi",
           "timezone":"Africa/Lubumbashi"
        },
        {
           "id":805,
           "country_id":180,
           "code":"",
           "name":"Katanga",
           "standard_timezone":"(UTC+2.0) Lubumbashi",
           "timezone":"Africa/Lubumbashi"
        },
        {
           "id":806,
           "country_id":180,
           "code":"",
           "name":"Kinshasa",
           "standard_timezone":"(UTC+1.0) Kinshasa",
           "timezone":"Africa/Kinshasa"
        },
        {
           "id":807,
           "country_id":180,
           "code":"",
           "name":"Bas-Congo",
           "standard_timezone":"(UTC+1.0) Kinshasa",
           "timezone":"Africa/Kinshasa"
        },
        {
           "id":808,
           "country_id":180,
           "code":"",
           "name":"Orientale",
           "standard_timezone":"(UTC+2.0) Lubumbashi",
           "timezone":"Africa/Lubumbashi"
        },
        {
           "id":809,
           "country_id":180,
           "code":"",
           "name":"Maniema",
           "standard_timezone":"(UTC+2.0) Lubumbashi",
           "timezone":"Africa/Lubumbashi"
        },
        {
           "id":810,
           "country_id":180,
           "code":"",
           "name":"Nord-Kivu",
           "standard_timezone":"(UTC+2.0) Lubumbashi",
           "timezone":"Africa/Lubumbashi"
        },
        {
           "id":811,
           "country_id":180,
           "code":"",
           "name":"Sud-Kivu",
           "standard_timezone":"(UTC+2.0) Lubumbashi",
           "timezone":"Africa/Lubumbashi"
        },
        {
           "id":812,
           "country_id":184,
           "code":"",
           "name":"Cook Islands",
           "standard_timezone":"(UTC -10:00)Rarotonga",
           "timezone":"Pacific/Rarotonga"
        },
        {
           "id":813,
           "country_id":188,
           "code":"",
           "name":"Alajuela",
           "standard_timezone":"(UTC-6.0) Costa_Rica",
           "timezone":"America/Costa_Rica"
        },
        {
           "id":814,
           "country_id":188,
           "code":"",
           "name":"Cartago",
           "standard_timezone":"(UTC-6.0) Costa_Rica",
           "timezone":"America/Costa_Rica"
        },
        {
           "id":815,
           "country_id":188,
           "code":"",
           "name":"Guanacaste",
           "standard_timezone":"(UTC-6.0) Costa_Rica",
           "timezone":"America/Costa_Rica"
        },
        {
           "id":816,
           "country_id":188,
           "code":"",
           "name":"Heredia",
           "standard_timezone":"(UTC-6.0) Costa_Rica",
           "timezone":"America/Costa_Rica"
        },
        {
           "id":817,
           "country_id":188,
           "code":"",
           "name":"Limón",
           "standard_timezone":"(UTC-6.0) Costa_Rica",
           "timezone":"America/Costa_Rica"
        },
        {
           "id":818,
           "country_id":188,
           "code":"",
           "name":"Puntarenas",
           "standard_timezone":"(UTC-6.0) Costa_Rica",
           "timezone":"America/Costa_Rica"
        },
        {
           "id":819,
           "country_id":188,
           "code":"",
           "name":"San José",
           "standard_timezone":"(UTC-6.0) Costa_Rica",
           "timezone":"America/Costa_Rica"
        },
        {
           "id":820,
           "country_id":191,
           "code":"",
           "name":"Bjelovarsko-Bilogorska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":821,
           "country_id":191,
           "code":"",
           "name":"Brodsko-Posavska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":822,
           "country_id":191,
           "code":"",
           "name":"Dubrovačko-Neretvanska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":823,
           "country_id":191,
           "code":"",
           "name":"Istarska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":824,
           "country_id":191,
           "code":"",
           "name":"Karlovačka",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":825,
           "country_id":191,
           "code":"",
           "name":"Koprivničko-Križevačka",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":826,
           "country_id":191,
           "code":"",
           "name":"Krapinsko-Zagorska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":827,
           "country_id":191,
           "code":"",
           "name":"Ličko-Senjska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":828,
           "country_id":191,
           "code":"",
           "name":"Međimurska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":829,
           "country_id":191,
           "code":"",
           "name":"Osječko-Baranjska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":830,
           "country_id":191,
           "code":"",
           "name":"Požeško-Slavonska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":831,
           "country_id":191,
           "code":"",
           "name":"Primorsko-Goranska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":832,
           "country_id":191,
           "code":"",
           "name":"Šibensko-Kniniska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":833,
           "country_id":191,
           "code":"",
           "name":"Sisačko-Moslavačka",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":834,
           "country_id":191,
           "code":"",
           "name":"Splitsko-Dalmatinska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":835,
           "country_id":191,
           "code":"",
           "name":"Varaždinska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":836,
           "country_id":191,
           "code":"",
           "name":"Virovitičk-Podravska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":837,
           "country_id":191,
           "code":"",
           "name":"Vukovarsko-Srijemska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":838,
           "country_id":191,
           "code":"",
           "name":"Zadarska",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":839,
           "country_id":191,
           "code":"",
           "name":"Zagrebačka",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":840,
           "country_id":191,
           "code":"",
           "name":"Grad Zagreb",
           "standard_timezone":"(UTC+2.0) Zagreb",
           "timezone":"Europe/Zagreb"
        },
        {
           "id":841,
           "country_id":192,
           "code":"",
           "name":"Pinar del Río",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":842,
           "country_id":192,
           "code":"",
           "name":"Ciudad de La Habana",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":843,
           "country_id":192,
           "code":"",
           "name":"Matanzas",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":844,
           "country_id":192,
           "code":"",
           "name":"Isla de la Juventud",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":845,
           "country_id":192,
           "code":"",
           "name":"Camagüey",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":846,
           "country_id":192,
           "code":"",
           "name":"Ciego de Ávila",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":847,
           "country_id":192,
           "code":"",
           "name":"Cienfuegos",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":848,
           "country_id":192,
           "code":"",
           "name":"Granma",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":849,
           "country_id":192,
           "code":"",
           "name":"Guantánamo",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":850,
           "country_id":192,
           "code":"",
           "name":"La Habana",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":851,
           "country_id":192,
           "code":"",
           "name":"Holguín",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":852,
           "country_id":192,
           "code":"",
           "name":"Las Tunas",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":853,
           "country_id":192,
           "code":"",
           "name":"Sancti Spíritus",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":854,
           "country_id":192,
           "code":"",
           "name":"Santiago de Cuba",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":855,
           "country_id":192,
           "code":"",
           "name":"Villa Clara",
           "standard_timezone":"(UTC-4.0) Havana",
           "timezone":"America/Havana"
        },
        {
           "id":856,
           "country_id":196,
           "code":"",
           "name":"Famagusta",
           "standard_timezone":"(UTC+3.0) Nicosia",
           "timezone":"Asia/Nicosia"
        },
        {
           "id":857,
           "country_id":196,
           "code":"",
           "name":"Kyrenia",
           "standard_timezone":"(UTC+3.0) Nicosia",
           "timezone":"Asia/Nicosia"
        },
        {
           "id":858,
           "country_id":196,
           "code":"",
           "name":"Larnaca",
           "standard_timezone":"(UTC+3.0) Nicosia",
           "timezone":"Asia/Nicosia"
        },
        {
           "id":859,
           "country_id":196,
           "code":"",
           "name":"Nicosia",
           "standard_timezone":"(UTC+3.0) Nicosia",
           "timezone":"Asia/Nicosia"
        },
        {
           "id":860,
           "country_id":196,
           "code":"",
           "name":"Limassol",
           "standard_timezone":"(UTC+3.0) Nicosia",
           "timezone":"Asia/Nicosia"
        },
        {
           "id":861,
           "country_id":196,
           "code":"",
           "name":"Paphos",
           "standard_timezone":"(UTC+3.0) Nicosia",
           "timezone":"Asia/Nicosia"
        },
        {
           "id":862,
           "country_id":203,
           "code":"",
           "name":"Hradec Kralove",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":863,
           "country_id":203,
           "code":"",
           "name":"Jablonec nad Nisou",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":864,
           "country_id":203,
           "code":"",
           "name":"Jiein",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":865,
           "country_id":203,
           "code":"",
           "name":"Jihlava",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":866,
           "country_id":203,
           "code":"",
           "name":"Kolin",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":867,
           "country_id":203,
           "code":"",
           "name":"Liberec",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":868,
           "country_id":203,
           "code":"",
           "name":"Melnik",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":869,
           "country_id":203,
           "code":"",
           "name":"Mlada Boleslav",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":870,
           "country_id":203,
           "code":"",
           "name":"Nachod",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":871,
           "country_id":203,
           "code":"",
           "name":"Nymburk",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":872,
           "country_id":203,
           "code":"",
           "name":"Pardubice",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":873,
           "country_id":203,
           "code":"",
           "name":"Hlavní Mesto Praha",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":874,
           "country_id":203,
           "code":"",
           "name":"Semily",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":875,
           "country_id":203,
           "code":"",
           "name":"Trutnov",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":876,
           "country_id":203,
           "code":"",
           "name":"South Moravian Region",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":877,
           "country_id":203,
           "code":"",
           "name":"Jihočeský Kraj",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":878,
           "country_id":203,
           "code":"",
           "name":"Vysočina",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":879,
           "country_id":203,
           "code":"",
           "name":"Karlovarský Kraj",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":880,
           "country_id":203,
           "code":"",
           "name":"Královéhradecký Kraj",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":881,
           "country_id":203,
           "code":"",
           "name":"Liberecký Kraj",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":882,
           "country_id":203,
           "code":"",
           "name":"Olomoucký Kraj",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":883,
           "country_id":203,
           "code":"",
           "name":"Moravskoslezský Kraj",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":884,
           "country_id":203,
           "code":"",
           "name":"Pardubický Kraj",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":885,
           "country_id":203,
           "code":"",
           "name":"Plzeňský Kraj",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":886,
           "country_id":203,
           "code":"",
           "name":"Středočeský Kraj",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":887,
           "country_id":203,
           "code":"",
           "name":"Ústecký Kraj",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":888,
           "country_id":203,
           "code":"",
           "name":"Zlínský Kraj",
           "standard_timezone":"(UTC+2.0) Prague",
           "timezone":"Europe/Prague"
        },
        {
           "id":889,
           "country_id":204,
           "code":"",
           "name":"Atakora",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":890,
           "country_id":204,
           "code":"",
           "name":"Atlantique",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":891,
           "country_id":204,
           "code":"",
           "name":"Alibori",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":892,
           "country_id":204,
           "code":"",
           "name":"Borgou",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":893,
           "country_id":204,
           "code":"",
           "name":"Collines",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":894,
           "country_id":204,
           "code":"",
           "name":"Kouffo",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":895,
           "country_id":204,
           "code":"",
           "name":"Donga",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":896,
           "country_id":204,
           "code":"",
           "name":"Littoral",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":897,
           "country_id":204,
           "code":"",
           "name":"Mono",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":898,
           "country_id":204,
           "code":"",
           "name":"Ouémé",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":899,
           "country_id":204,
           "code":"",
           "name":"Plateau",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":900,
           "country_id":204,
           "code":"",
           "name":"Zou",
           "standard_timezone":"(UTC+1.0) Porto-Novo",
           "timezone":"Africa/Porto-Novo"
        },
        {
           "id":901,
           "country_id":208,
           "code":"",
           "name":"Århus",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":902,
           "country_id":208,
           "code":"",
           "name":"Bornholm",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":903,
           "country_id":208,
           "code":"",
           "name":"Frederiksborg",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":904,
           "country_id":208,
           "code":"",
           "name":"Fyn",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":905,
           "country_id":208,
           "code":"",
           "name":"Copenhagen city",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":906,
           "country_id":208,
           "code":"",
           "name":"København",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":907,
           "country_id":208,
           "code":"",
           "name":"Nordjylland",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":908,
           "country_id":208,
           "code":"",
           "name":"Ribe",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":909,
           "country_id":208,
           "code":"",
           "name":"Ringkøbing",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":910,
           "country_id":208,
           "code":"",
           "name":"Roskilde",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":911,
           "country_id":208,
           "code":"",
           "name":"Sønderjylland",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":912,
           "country_id":208,
           "code":"",
           "name":"Storstrøm",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":913,
           "country_id":208,
           "code":"",
           "name":"Vejle",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":914,
           "country_id":208,
           "code":"",
           "name":"Vestsjælland",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":915,
           "country_id":208,
           "code":"",
           "name":"Viborg",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":916,
           "country_id":208,
           "code":"",
           "name":"Fredriksberg",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":917,
           "country_id":208,
           "code":"",
           "name":"Capital Region",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":918,
           "country_id":208,
           "code":"",
           "name":"Central Jutland",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":919,
           "country_id":208,
           "code":"",
           "name":"North Jutland",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":920,
           "country_id":208,
           "code":"",
           "name":"Region Zealand",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":921,
           "country_id":208,
           "code":"",
           "name":"Region South Denmark",
           "standard_timezone":"(UTC+2.0) Copenhagen",
           "timezone":"Europe/Copenhagen"
        },
        {
           "id":922,
           "country_id":212,
           "code":"",
           "name":"Saint Andrew",
           "standard_timezone":"(UTC-4.0) Dominica",
           "timezone":"America/Dominica"
        },
        {
           "id":923,
           "country_id":212,
           "code":"",
           "name":"Saint David",
           "standard_timezone":"(UTC-4.0) Dominica",
           "timezone":"America/Dominica"
        },
        {
           "id":924,
           "country_id":212,
           "code":"",
           "name":"Saint George",
           "standard_timezone":"(UTC-4.0) Dominica",
           "timezone":"America/Dominica"
        },
        {
           "id":925,
           "country_id":212,
           "code":"",
           "name":"Saint John",
           "standard_timezone":"(UTC-4.0) Dominica",
           "timezone":"America/Dominica"
        },
        {
           "id":926,
           "country_id":212,
           "code":"",
           "name":"Saint Joseph",
           "standard_timezone":"(UTC-4.0) Dominica",
           "timezone":"America/Dominica"
        },
        {
           "id":927,
           "country_id":212,
           "code":"",
           "name":"Saint Luke",
           "standard_timezone":"(UTC-4.0) Dominica",
           "timezone":"America/Dominica"
        },
        {
           "id":928,
           "country_id":212,
           "code":"",
           "name":"Saint Mark",
           "standard_timezone":"(UTC-4.0) Dominica",
           "timezone":"America/Dominica"
        },
        {
           "id":929,
           "country_id":212,
           "code":"",
           "name":"Saint Patrick",
           "standard_timezone":"(UTC-4.0) Dominica",
           "timezone":"America/Dominica"
        },
        {
           "id":930,
           "country_id":212,
           "code":"",
           "name":"Saint Paul",
           "standard_timezone":"(UTC-4.0) Dominica",
           "timezone":"America/Dominica"
        },
        {
           "id":931,
           "country_id":212,
           "code":"",
           "name":"Saint Peter",
           "standard_timezone":"(UTC-4.0) Dominica",
           "timezone":"America/Dominica"
        },
        {
           "id":932,
           "country_id":214,
           "code":"",
           "name":"Azua",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":933,
           "country_id":214,
           "code":"",
           "name":"Baoruco",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":934,
           "country_id":214,
           "code":"",
           "name":"Barahona",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":935,
           "country_id":214,
           "code":"",
           "name":"Dajabón",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":936,
           "country_id":214,
           "code":"",
           "name":"Duarte",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":937,
           "country_id":214,
           "code":"",
           "name":"Espaillat",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":938,
           "country_id":214,
           "code":"",
           "name":"Independencia",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":939,
           "country_id":214,
           "code":"",
           "name":"La Altagracia",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":940,
           "country_id":214,
           "code":"",
           "name":"Elías Piña",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":941,
           "country_id":214,
           "code":"",
           "name":"La Romana",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":942,
           "country_id":214,
           "code":"",
           "name":"María Trinidad Sánchez",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":943,
           "country_id":214,
           "code":"",
           "name":"Monte Cristi",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":944,
           "country_id":214,
           "code":"",
           "name":"Pedernales",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":945,
           "country_id":214,
           "code":"",
           "name":"Puerto Plata",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":946,
           "country_id":214,
           "code":"",
           "name":"Salcedo",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":947,
           "country_id":214,
           "code":"",
           "name":"Samaná",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":948,
           "country_id":214,
           "code":"",
           "name":"Sánchez Ramírez",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":949,
           "country_id":214,
           "code":"",
           "name":"San Juan",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":950,
           "country_id":214,
           "code":"",
           "name":"San Pedro de Macorís",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":951,
           "country_id":214,
           "code":"",
           "name":"Santiago",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":952,
           "country_id":214,
           "code":"",
           "name":"Santiago Rodríguez",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":953,
           "country_id":214,
           "code":"",
           "name":"Valverde",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":954,
           "country_id":214,
           "code":"",
           "name":"El Seíbo",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":955,
           "country_id":214,
           "code":"",
           "name":"Hato Mayor",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":956,
           "country_id":214,
           "code":"",
           "name":"La Vega",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":957,
           "country_id":214,
           "code":"",
           "name":"Monseñor Nouel",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":958,
           "country_id":214,
           "code":"",
           "name":"Monte Plata",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":959,
           "country_id":214,
           "code":"",
           "name":"San Cristóbal",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":960,
           "country_id":214,
           "code":"",
           "name":"Distrito Nacional",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":961,
           "country_id":214,
           "code":"",
           "name":"Peravia",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":962,
           "country_id":214,
           "code":"",
           "name":"San José de Ocoa",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":963,
           "country_id":214,
           "code":"",
           "name":"Santo Domingo",
           "standard_timezone":"(UTC-4.0) Santo_Domingo",
           "timezone":"America/Santo_Domingo"
        },
        {
           "id":964,
           "country_id":218,
           "code":"",
           "name":"Galápagos",
           "standard_timezone":"(UTC-5.0) Galapagos",
           "timezone":"Pacific/Galapagos"
        },
        {
           "id":965,
           "country_id":218,
           "code":"",
           "name":"Azuay",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":966,
           "country_id":218,
           "code":"",
           "name":"Bolívar",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":967,
           "country_id":218,
           "code":"",
           "name":"Cañar",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":968,
           "country_id":218,
           "code":"",
           "name":"Carchi",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":969,
           "country_id":218,
           "code":"",
           "name":"Chimborazo",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":970,
           "country_id":218,
           "code":"",
           "name":"Cotopaxi",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":971,
           "country_id":218,
           "code":"",
           "name":"El Oro",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":972,
           "country_id":218,
           "code":"",
           "name":"Esmeraldas",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":973,
           "country_id":218,
           "code":"",
           "name":"Guayas",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":974,
           "country_id":218,
           "code":"",
           "name":"Imbabura",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":975,
           "country_id":218,
           "code":"",
           "name":"Loja",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":976,
           "country_id":218,
           "code":"",
           "name":"Los Ríos",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":977,
           "country_id":218,
           "code":"",
           "name":"Manabí",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":978,
           "country_id":218,
           "code":"",
           "name":"Morona-Santiago",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":979,
           "country_id":218,
           "code":"",
           "name":"Napo",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":980,
           "country_id":218,
           "code":"",
           "name":"Pastaza",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":981,
           "country_id":218,
           "code":"",
           "name":"Pichincha",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":982,
           "country_id":218,
           "code":"",
           "name":"Tungurahua",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":983,
           "country_id":218,
           "code":"",
           "name":"Zamora-Chinchipe",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":984,
           "country_id":218,
           "code":"",
           "name":"Sucumbios",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":985,
           "country_id":218,
           "code":"",
           "name":"Napo",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":986,
           "country_id":218,
           "code":"",
           "name":"Orellana",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":987,
           "country_id":218,
           "code":"",
           "name":"Provincia de Santa Elena",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":988,
           "country_id":218,
           "code":"",
           "name":"Provincia de Santo Domingo de los Tsáchilas",
           "standard_timezone":"(UTC-5.0) Guayaquil",
           "timezone":"America/Guayaquil"
        },
        {
           "id":989,
           "country_id":222,
           "code":"",
           "name":"Ahuachapán",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":990,
           "country_id":222,
           "code":"",
           "name":"Cabañas",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":991,
           "country_id":222,
           "code":"",
           "name":"Chalatenango",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":992,
           "country_id":222,
           "code":"",
           "name":"Cuscatlán",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":993,
           "country_id":222,
           "code":"",
           "name":"La Libertad",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":994,
           "country_id":222,
           "code":"",
           "name":"La Paz",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":995,
           "country_id":222,
           "code":"",
           "name":"La Unión",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":996,
           "country_id":222,
           "code":"",
           "name":"Morazán",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":997,
           "country_id":222,
           "code":"",
           "name":"San Miguel",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":998,
           "country_id":222,
           "code":"",
           "name":"San Salvador",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":999,
           "country_id":222,
           "code":"",
           "name":"Santa Ana",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":1000,
           "country_id":222,
           "code":"",
           "name":"San Vicente",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":1001,
           "country_id":222,
           "code":"",
           "name":"Sonsonate",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":1002,
           "country_id":222,
           "code":"",
           "name":"Usulután",
           "standard_timezone":"(UTC-6.0) El_Salvador",
           "timezone":"America/El_Salvador"
        },
        {
           "id":1003,
           "country_id":226,
           "code":"",
           "name":"Annobón",
           "standard_timezone":"(UTC+1.0) Malabo",
           "timezone":"Africa/Malabo"
        },
        {
           "id":1004,
           "country_id":226,
           "code":"",
           "name":"Bioko Norte",
           "standard_timezone":"(UTC+1.0) Malabo",
           "timezone":"Africa/Malabo"
        },
        {
           "id":1005,
           "country_id":226,
           "code":"",
           "name":"Bioko Sur",
           "standard_timezone":"(UTC+1.0) Malabo",
           "timezone":"Africa/Malabo"
        },
        {
           "id":1006,
           "country_id":226,
           "code":"",
           "name":"Centro Sur",
           "standard_timezone":"(UTC+1.0) Malabo",
           "timezone":"Africa/Malabo"
        },
        {
           "id":1007,
           "country_id":226,
           "code":"",
           "name":"Kié-Ntem",
           "standard_timezone":"(UTC+1.0) Malabo",
           "timezone":"Africa/Malabo"
        },
        {
           "id":1008,
           "country_id":226,
           "code":"",
           "name":"Litoral",
           "standard_timezone":"(UTC+1.0) Malabo",
           "timezone":"Africa/Malabo"
        },
        {
           "id":1009,
           "country_id":226,
           "code":"",
           "name":"Wele-Nzas",
           "standard_timezone":"(UTC+1.0) Malabo",
           "timezone":"Africa/Malabo"
        },
        {
           "id":1010,
           "country_id":231,
           "code":"",
           "name":"Arsi",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1011,
           "country_id":231,
           "code":"",
           "name":"Gonder",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1012,
           "country_id":231,
           "code":"",
           "name":"Bale",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1013,
           "country_id":231,
           "code":"",
           "name":"Eritrea",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1014,
           "country_id":231,
           "code":"",
           "name":"Gamo Gofa",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1015,
           "country_id":231,
           "code":"",
           "name":"Gojam",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1016,
           "country_id":231,
           "code":"",
           "name":"Harerge",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1017,
           "country_id":231,
           "code":"",
           "name":"Ilubabor",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1018,
           "country_id":231,
           "code":"",
           "name":"Kefa",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1019,
           "country_id":231,
           "code":"",
           "name":"Addis Abeba",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1020,
           "country_id":231,
           "code":"",
           "name":"Sidamo",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1021,
           "country_id":231,
           "code":"",
           "name":"Tigray",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1022,
           "country_id":231,
           "code":"",
           "name":"Welega Kifle Hager",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1023,
           "country_id":231,
           "code":"",
           "name":"Welo",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1024,
           "country_id":231,
           "code":"",
           "name":"Adis Abeba",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1025,
           "country_id":231,
           "code":"",
           "name":"Asosa",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1026,
           "country_id":231,
           "code":"",
           "name":"Borena ",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1027,
           "country_id":231,
           "code":"",
           "name":"Debub Gonder",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1028,
           "country_id":231,
           "code":"",
           "name":"Debub Shewa",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1029,
           "country_id":231,
           "code":"",
           "name":"Debub Welo",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1030,
           "country_id":231,
           "code":"",
           "name":"Dire Dawa",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1031,
           "country_id":231,
           "code":"",
           "name":"Gambela",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1032,
           "country_id":231,
           "code":"",
           "name":"Metekel",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1033,
           "country_id":231,
           "code":"",
           "name":"Mirab Gojam",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1034,
           "country_id":231,
           "code":"",
           "name":"Mirab Harerge",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1035,
           "country_id":231,
           "code":"",
           "name":"Mirab Shewa",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1036,
           "country_id":231,
           "code":"",
           "name":"Misrak Gojam",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1037,
           "country_id":231,
           "code":"",
           "name":"Misrak Harerge",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1038,
           "country_id":231,
           "code":"",
           "name":"Nazret",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1039,
           "country_id":231,
           "code":"",
           "name":"Ogaden",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1040,
           "country_id":231,
           "code":"",
           "name":"Omo",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1041,
           "country_id":231,
           "code":"",
           "name":"Semen Gonder",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1042,
           "country_id":231,
           "code":"",
           "name":"Semen Shewa",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1043,
           "country_id":231,
           "code":"",
           "name":"Semen Welo",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1044,
           "country_id":231,
           "code":"",
           "name":"Tigray",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1045,
           "country_id":231,
           "code":"",
           "name":"Bale",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1046,
           "country_id":231,
           "code":"",
           "name":"Gamo Gofa",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1047,
           "country_id":231,
           "code":"",
           "name":"Ilubabor",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1048,
           "country_id":231,
           "code":"",
           "name":"Kefa",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1049,
           "country_id":231,
           "code":"",
           "name":"Sidamo",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1050,
           "country_id":231,
           "code":"",
           "name":"Welega",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1051,
           "country_id":231,
           "code":"",
           "name":"Ādīs Ābeba",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1052,
           "country_id":231,
           "code":"",
           "name":"Āfar",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1053,
           "country_id":231,
           "code":"",
           "name":"Āmara",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1054,
           "country_id":231,
           "code":"",
           "name":"Bīnshangul Gumuz",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1055,
           "country_id":231,
           "code":"",
           "name":"Dirē Dawa",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1056,
           "country_id":231,
           "code":"",
           "name":"Gambēla Hizboch",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1057,
           "country_id":231,
           "code":"",
           "name":"Hārerī Hizb",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1058,
           "country_id":231,
           "code":"",
           "name":"Oromīya",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1059,
           "country_id":231,
           "code":"",
           "name":"Sumalē",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1060,
           "country_id":231,
           "code":"",
           "name":"Tigray",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1061,
           "country_id":231,
           "code":"",
           "name":"YeDebub Bihēroch Bihēreseboch na Hizboch",
           "standard_timezone":"(UTC+3.0) Addis_Ababa",
           "timezone":"Africa/Addis_Ababa"
        },
        {
           "id":1062,
           "country_id":232,
           "code":"",
           "name":"Ānseba",
           "standard_timezone":"(UTC+3.0) Asmara",
           "timezone":"Africa/Asmara"
        },
        {
           "id":1063,
           "country_id":232,
           "code":"",
           "name":"Debub",
           "standard_timezone":"(UTC+3.0) Asmara",
           "timezone":"Africa/Asmara"
        },
        {
           "id":1064,
           "country_id":232,
           "code":"",
           "name":"Debubawī Kʼeyih Bahrī",
           "standard_timezone":"(UTC+3.0) Asmara",
           "timezone":"Africa/Asmara"
        },
        {
           "id":1065,
           "country_id":232,
           "code":"",
           "name":"Gash Barka",
           "standard_timezone":"(UTC+3.0) Asmara",
           "timezone":"Africa/Asmara"
        },
        {
           "id":1066,
           "country_id":232,
           "code":"",
           "name":"Maʼākel",
           "standard_timezone":"(UTC+3.0) Asmara",
           "timezone":"Africa/Asmara"
        },
        {
           "id":1067,
           "country_id":232,
           "code":"",
           "name":"Semēnawī Kʼeyih Bahrī",
           "standard_timezone":"(UTC+3.0) Asmara",
           "timezone":"Africa/Asmara"
        },
        {
           "id":1068,
           "country_id":233,
           "code":"",
           "name":"Harjumaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1069,
           "country_id":233,
           "code":"",
           "name":"Hiiumaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1070,
           "country_id":233,
           "code":"",
           "name":"Ida-Virumaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1071,
           "country_id":233,
           "code":"",
           "name":"Järvamaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1072,
           "country_id":233,
           "code":"",
           "name":"Jõgevamaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1073,
           "country_id":233,
           "code":"",
           "name":"Läänemaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1074,
           "country_id":233,
           "code":"",
           "name":"Lääne-Virumaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1075,
           "country_id":233,
           "code":"",
           "name":"Pärnumaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1076,
           "country_id":233,
           "code":"",
           "name":"Põlvamaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1077,
           "country_id":233,
           "code":"",
           "name":"Raplamaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1078,
           "country_id":233,
           "code":"",
           "name":"Saaremaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1079,
           "country_id":233,
           "code":"",
           "name":"Tartumaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1080,
           "country_id":233,
           "code":"",
           "name":"Valgamaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1081,
           "country_id":233,
           "code":"",
           "name":"Viljandimaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1082,
           "country_id":233,
           "code":"",
           "name":"Võrumaa",
           "standard_timezone":"(UTC+3.0) Tallinn",
           "timezone":"Europe/Tallinn"
        },
        {
           "id":1083,
           "country_id":234,
           "code":"",
           "name":"Norðoyar region",
           "standard_timezone":"(UTC +1:00)Faroe",
           "timezone":"Atlantic/Faroe"
        },
        {
           "id":1084,
           "country_id":234,
           "code":"",
           "name":"Eysturoy region",
           "standard_timezone":"(UTC +1:00)Faroe",
           "timezone":"Atlantic/Faroe"
        },
        {
           "id":1085,
           "country_id":234,
           "code":"",
           "name":"Sandoy region",
           "standard_timezone":"(UTC +1:00)Faroe",
           "timezone":"Atlantic/Faroe"
        },
        {
           "id":1086,
           "country_id":234,
           "code":"",
           "name":"Streymoy region",
           "standard_timezone":"(UTC +1:00)Faroe",
           "timezone":"Atlantic/Faroe"
        },
        {
           "id":1087,
           "country_id":234,
           "code":"",
           "name":"Suðuroy region",
           "standard_timezone":"(UTC +1:00)Faroe",
           "timezone":"Atlantic/Faroe"
        },
        {
           "id":1088,
           "country_id":234,
           "code":"",
           "name":"Vágar region",
           "standard_timezone":"(UTC +1:00)Faroe",
           "timezone":"Atlantic/Faroe"
        },
        {
           "id":1089,
           "country_id":238,
           "code":"",
           "name":"Falkland Islands (Islas Malvinas)",
           "standard_timezone":"(UTC+1.0) Stanley",
           "timezone":"Atlantic/Stanley"
        },
        {
           "id":1090,
           "country_id":239,
           "code":"",
           "name":"South Georgia and The South Sandwich Islands ",
           "standard_timezone":"(UTC-4.0) South_Georgia",
           "timezone":"Atlantic/South_Georgia"
        },
        {
           "id":1091,
           "country_id":242,
           "code":"",
           "name":"Central",
           "standard_timezone":"(UTC+3.0) Fiji",
           "timezone":"Pacific/Fiji"
        },
        {
           "id":1092,
           "country_id":242,
           "code":"",
           "name":"Eastern",
           "standard_timezone":"(UTC+3.0) Fiji",
           "timezone":"Pacific/Fiji"
        },
        {
           "id":1093,
           "country_id":242,
           "code":"",
           "name":"Northern",
           "standard_timezone":"(UTC+3.0) Fiji",
           "timezone":"Pacific/Fiji"
        },
        {
           "id":1094,
           "country_id":242,
           "code":"",
           "name":"Rotuma",
           "standard_timezone":"(UTC+3.0) Fiji",
           "timezone":"Pacific/Fiji"
        },
        {
           "id":1095,
           "country_id":242,
           "code":"",
           "name":"Western",
           "standard_timezone":"(UTC+3.0) Fiji",
           "timezone":"Pacific/Fiji"
        },
        {
           "id":1096,
           "country_id":246,
           "code":"",
           "name":"Åland",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1097,
           "country_id":246,
           "code":"",
           "name":"Hame",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1098,
           "country_id":246,
           "code":"",
           "name":"Keski-Suomi",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1099,
           "country_id":246,
           "code":"",
           "name":"Kuopio",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1100,
           "country_id":246,
           "code":"",
           "name":"Kymi",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1101,
           "country_id":246,
           "code":"",
           "name":"Lapponia",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1102,
           "country_id":246,
           "code":"",
           "name":"Mikkeli",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1103,
           "country_id":246,
           "code":"",
           "name":"Oulu",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1104,
           "country_id":246,
           "code":"",
           "name":"Pohjois-Karjala",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1105,
           "country_id":246,
           "code":"",
           "name":"Turku ja Pori",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1106,
           "country_id":246,
           "code":"",
           "name":"Uusimaa",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1107,
           "country_id":246,
           "code":"",
           "name":"Vaasa",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1108,
           "country_id":246,
           "code":"",
           "name":"Southern Finland",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1109,
           "country_id":246,
           "code":"",
           "name":"Eastern Finland",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1110,
           "country_id":246,
           "code":"",
           "name":"Western Finland",
           "standard_timezone":"(UTC+3.0) Helsinki",
           "timezone":"Europe/Helsinki"
        },
        {
           "id":1111,
           "country_id":250,
           "code":"",
           "name":"Aquitaine",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1112,
           "country_id":250,
           "code":"",
           "name":"Auvergne",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1113,
           "country_id":250,
           "code":"",
           "name":"Basse-Normandie",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1114,
           "country_id":250,
           "code":"",
           "name":"Bourgogne",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1115,
           "country_id":250,
           "code":"",
           "name":"Brittany",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1116,
           "country_id":250,
           "code":"",
           "name":"Centre",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1117,
           "country_id":250,
           "code":"",
           "name":"Champagne-Ardenne",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1118,
           "country_id":250,
           "code":"",
           "name":"Corsica",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1119,
           "country_id":250,
           "code":"",
           "name":"Franche-Comté",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1120,
           "country_id":250,
           "code":"",
           "name":"Haute-Normandie",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1121,
           "country_id":250,
           "code":"",
           "name":"Île-de-France",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1122,
           "country_id":250,
           "code":"",
           "name":"Languedoc-Roussillon",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1123,
           "country_id":250,
           "code":"",
           "name":"Limousin",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1124,
           "country_id":250,
           "code":"",
           "name":"Lorraine",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1125,
           "country_id":250,
           "code":"",
           "name":"Midi-Pyrénées",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1126,
           "country_id":250,
           "code":"",
           "name":"Nord-Pas-de-Calais",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1127,
           "country_id":250,
           "code":"",
           "name":"Pays de la Loire",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1128,
           "country_id":250,
           "code":"",
           "name":"Picardie",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1129,
           "country_id":250,
           "code":"",
           "name":"Poitou-Charentes",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1130,
           "country_id":250,
           "code":"",
           "name":"Provence-Alpes-Côte dʼAzur",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1131,
           "country_id":250,
           "code":"",
           "name":"Rhône-Alpes",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1132,
           "country_id":250,
           "code":"",
           "name":"Alsace",
           "standard_timezone":"(UTC+2.0) Paris",
           "timezone":"Europe/Paris"
        },
        {
           "id":1133,
           "country_id":254,
           "code":"",
           "name":"Guyane",
           "standard_timezone":"(UTC-4.0) Guyana",
           "timezone":"America/Guyana"
        },
        {
           "id":1134,
           "country_id":260,
           "code":"",
           "name":"Saint-Paul-et-Amsterdam",
           "standard_timezone":"(UTC+2.0) Kerguelen",
           "timezone":"Indian/Kerguelen"
        },
        {
           "id":1135,
           "country_id":260,
           "code":"",
           "name":"Crozet",
           "standard_timezone":"(UTC+2.0) Kerguelen",
           "timezone":"Indian/Kerguelen"
        },
        {
           "id":1136,
           "country_id":260,
           "code":"",
           "name":"Kerguelen",
           "standard_timezone":"(UTC+2.0) Kerguelen",
           "timezone":"Indian/Kerguelen"
        },
        {
           "id":1137,
           "country_id":260,
           "code":"",
           "name":"Terre Adélie",
           "standard_timezone":"(UTC+2.0) Kerguelen",
           "timezone":"Indian/Kerguelen"
        },
        {
           "id":1138,
           "country_id":260,
           "code":"",
           "name":"Îles Éparses",
           "standard_timezone":"(UTC+2.0) Kerguelen",
           "timezone":"Indian/Kerguelen"
        },
        {
           "id":1139,
           "country_id":262,
           "code":"",
           "name":"Ali Sabieh",
           "standard_timezone":"(UTC+3.0) Djibouti",
           "timezone":"Africa/Djibouti"
        },
        {
           "id":1140,
           "country_id":262,
           "code":"",
           "name":"Dikhil   ",
           "standard_timezone":"(UTC+3.0) Djibouti",
           "timezone":"Africa/Djibouti"
        },
        {
           "id":1141,
           "country_id":262,
           "code":"",
           "name":"Djibouti  ",
           "standard_timezone":"(UTC+3.0) Djibouti",
           "timezone":"Africa/Djibouti"
        },
        {
           "id":1142,
           "country_id":262,
           "code":"",
           "name":"Obock",
           "standard_timezone":"(UTC+3.0) Djibouti",
           "timezone":"Africa/Djibouti"
        },
        {
           "id":1143,
           "country_id":262,
           "code":"",
           "name":"Tadjourah",
           "standard_timezone":"(UTC+3.0) Djibouti",
           "timezone":"Africa/Djibouti"
        },
        {
           "id":1144,
           "country_id":262,
           "code":"",
           "name":"Dikhil",
           "standard_timezone":"(UTC+3.0) Djibouti",
           "timezone":"Africa/Djibouti"
        },
        {
           "id":1145,
           "country_id":262,
           "code":"",
           "name":"Djibouti",
           "standard_timezone":"(UTC+3.0) Djibouti",
           "timezone":"Africa/Djibouti"
        },
        {
           "id":1146,
           "country_id":262,
           "code":"",
           "name":"Arta",
           "standard_timezone":"(UTC+3.0) Djibouti",
           "timezone":"Africa/Djibouti"
        },
        {
           "id":1147,
           "country_id":266,
           "code":"",
           "name":"Estuaire",
           "standard_timezone":"(UTC+1.0) Libreville",
           "timezone":"Africa/Libreville"
        },
        {
           "id":1148,
           "country_id":266,
           "code":"",
           "name":"Haut-Ogooué",
           "standard_timezone":"(UTC+1.0) Libreville",
           "timezone":"Africa/Libreville"
        },
        {
           "id":1149,
           "country_id":266,
           "code":"",
           "name":"Moyen-Ogooué",
           "standard_timezone":"(UTC+1.0) Libreville",
           "timezone":"Africa/Libreville"
        },
        {
           "id":1150,
           "country_id":266,
           "code":"",
           "name":"Ngounié",
           "standard_timezone":"(UTC+1.0) Libreville",
           "timezone":"Africa/Libreville"
        },
        {
           "id":1151,
           "country_id":266,
           "code":"",
           "name":"Nyanga",
           "standard_timezone":"(UTC+1.0) Libreville",
           "timezone":"Africa/Libreville"
        },
        {
           "id":1152,
           "country_id":266,
           "code":"",
           "name":"Ogooué-Ivindo",
           "standard_timezone":"(UTC+1.0) Libreville",
           "timezone":"Africa/Libreville"
        },
        {
           "id":1153,
           "country_id":266,
           "code":"",
           "name":"Ogooué-Lolo",
           "standard_timezone":"(UTC+1.0) Libreville",
           "timezone":"Africa/Libreville"
        },
        {
           "id":1154,
           "country_id":266,
           "code":"",
           "name":"Ogooué-Maritime",
           "standard_timezone":"(UTC+1.0) Libreville",
           "timezone":"Africa/Libreville"
        },
        {
           "id":1155,
           "country_id":266,
           "code":"",
           "name":"Woleu-Ntem",
           "standard_timezone":"(UTC+1.0) Libreville",
           "timezone":"Africa/Libreville"
        },
        {
           "id":1156,
           "country_id":268,
           "code":"",
           "name":"Ajaria",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1157,
           "country_id":268,
           "code":"",
           "name":"Tʼbilisi",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1158,
           "country_id":268,
           "code":"",
           "name":"Abkhazia",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1159,
           "country_id":268,
           "code":"",
           "name":"Kvemo Kartli",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1160,
           "country_id":268,
           "code":"",
           "name":"Kakheti",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1161,
           "country_id":268,
           "code":"",
           "name":"Guria",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1162,
           "country_id":268,
           "code":"",
           "name":"Imereti",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1163,
           "country_id":268,
           "code":"",
           "name":"Shida Kartli",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1164,
           "country_id":268,
           "code":"",
           "name":"Mtskheta-Mtianeti",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1165,
           "country_id":268,
           "code":"",
           "name":"Racha-Lechkhumi and Kvemo Svaneti",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1166,
           "country_id":268,
           "code":"",
           "name":"Samegrelo and Zemo Svaneti",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1167,
           "country_id":268,
           "code":"",
           "name":"Samtskhe-Javakheti",
           "standard_timezone":"(UTC+4.0) Tbilisi",
           "timezone":"Asia/Tbilisi"
        },
        {
           "id":1168,
           "country_id":270,
           "code":"",
           "name":"Banjul",
           "standard_timezone":"(UTC+0.0) Banjul",
           "timezone":"Africa/Banjul"
        },
        {
           "id":1169,
           "country_id":270,
           "code":"",
           "name":"Lower River",
           "standard_timezone":"(UTC+0.0) Banjul",
           "timezone":"Africa/Banjul"
        },
        {
           "id":1170,
           "country_id":270,
           "code":"",
           "name":"Central River",
           "standard_timezone":"(UTC+0.0) Banjul",
           "timezone":"Africa/Banjul"
        },
        {
           "id":1171,
           "country_id":270,
           "code":"",
           "name":"Upper River",
           "standard_timezone":"(UTC+0.0) Banjul",
           "timezone":"Africa/Banjul"
        },
        {
           "id":1172,
           "country_id":270,
           "code":"",
           "name":"Western",
           "standard_timezone":"(UTC+0.0) Banjul",
           "timezone":"Africa/Banjul"
        },
        {
           "id":1173,
           "country_id":270,
           "code":"",
           "name":"North Bank",
           "standard_timezone":"(UTC+0.0) Banjul",
           "timezone":"Africa/Banjul"
        },
        {
           "id":1174,
           "country_id":275,
           "code":"",
           "name":"Gaza Strip",
           "standard_timezone":"(UTC+4.50) Gaza",
           "timezone":"Asia/Gaza"
        },
        {
           "id":1175,
           "country_id":275,
           "code":"",
           "name":"West Bank",
           "standard_timezone":"(UTC+4.50) Gaza",
           "timezone":"Asia/Gaza"
        },
        {
           "id":1176,
           "country_id":276,
           "code":"",
           "name":"Baden-Württemberg",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1177,
           "country_id":276,
           "code":"",
           "name":"Bavaria",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1178,
           "country_id":276,
           "code":"",
           "name":"Bremen",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1179,
           "country_id":276,
           "code":"",
           "name":"Hamburg",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1180,
           "country_id":276,
           "code":"",
           "name":"Hesse",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1181,
           "country_id":276,
           "code":"",
           "name":"Lower Saxony",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1182,
           "country_id":276,
           "code":"",
           "name":"North Rhine-Westphalia",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1183,
           "country_id":276,
           "code":"",
           "name":"Rhineland-Palatinate",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1184,
           "country_id":276,
           "code":"",
           "name":"Saarland",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1185,
           "country_id":276,
           "code":"",
           "name":"Schleswig-Holstein",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1186,
           "country_id":276,
           "code":"",
           "name":"Brandenburg",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1187,
           "country_id":276,
           "code":"",
           "name":"Mecklenburg-Vorpommern",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1188,
           "country_id":276,
           "code":"",
           "name":"Saxony",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1189,
           "country_id":276,
           "code":"",
           "name":"Saxony-Anhalt",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1190,
           "country_id":276,
           "code":"",
           "name":"Thuringia",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1191,
           "country_id":276,
           "code":"",
           "name":"Berlin",
           "standard_timezone":"(UTC+2.0) Berlin",
           "timezone":"Europe/Berlin"
        },
        {
           "id":1192,
           "country_id":288,
           "code":"",
           "name":"Greater Accra",
           "standard_timezone":"(UTC+0.0) Accra",
           "timezone":"Africa/Accra"
        },
        {
           "id":1193,
           "country_id":288,
           "code":"",
           "name":"Ashanti",
           "standard_timezone":"(UTC+0.0) Accra",
           "timezone":"Africa/Accra"
        },
        {
           "id":1194,
           "country_id":288,
           "code":"",
           "name":"Brong-Ahafo Region",
           "standard_timezone":"(UTC+0.0) Accra",
           "timezone":"Africa/Accra"
        },
        {
           "id":1195,
           "country_id":288,
           "code":"",
           "name":"Central",
           "standard_timezone":"(UTC+0.0) Accra",
           "timezone":"Africa/Accra"
        },
        {
           "id":1196,
           "country_id":288,
           "code":"",
           "name":"Eastern",
           "standard_timezone":"(UTC+0.0) Accra",
           "timezone":"Africa/Accra"
        },
        {
           "id":1197,
           "country_id":288,
           "code":"",
           "name":"Northern",
           "standard_timezone":"(UTC+0.0) Accra",
           "timezone":"Africa/Accra"
        },
        {
           "id":1198,
           "country_id":288,
           "code":"",
           "name":"Volta",
           "standard_timezone":"(UTC+0.0) Accra",
           "timezone":"Africa/Accra"
        },
        {
           "id":1199,
           "country_id":288,
           "code":"",
           "name":"Western",
           "standard_timezone":"(UTC+0.0) Accra",
           "timezone":"Africa/Accra"
        },
        {
           "id":1200,
           "country_id":288,
           "code":"",
           "name":"Upper East",
           "standard_timezone":"(UTC+0.0) Accra",
           "timezone":"Africa/Accra"
        },
        {
           "id":1201,
           "country_id":288,
           "code":"",
           "name":"Upper West",
           "standard_timezone":"(UTC+0.0) Accra",
           "timezone":"Africa/Accra"
        },
        {
           "id":1202,
           "country_id":292,
           "code":"",
           "name":"Gibraltar",
           "standard_timezone":"(UTC+2.0) Gibraltar",
           "timezone":"Europe/Gibraltar"
        },
        {
           "id":1203,
           "country_id":296,
           "code":"",
           "name":"Line Islands",
           "standard_timezone":"(UTC +14:00)Kiritimati",
           "timezone":"Pacific/Kiritimati"
        },
        {
           "id":1204,
           "country_id":296,
           "code":"",
           "name":"Gilbert Islands",
           "standard_timezone":"(UTC+12.0) Tarawa",
           "timezone":"Pacific/Tarawa"
        },
        {
           "id":1205,
           "country_id":296,
           "code":"",
           "name":"Phoenix Islands",
           "standard_timezone":"(UTC +13:00)Enderbury",
           "timezone":"Pacific/Enderbury"
        },
        {
           "id":1206,
           "country_id":300,
           "code":"",
           "name":"Mount Athos",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1207,
           "country_id":300,
           "code":"",
           "name":"East Macedonia and Thrace",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1208,
           "country_id":300,
           "code":"",
           "name":"Central Macedonia",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1209,
           "country_id":300,
           "code":"",
           "name":"West Macedonia",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1210,
           "country_id":300,
           "code":"",
           "name":"Thessaly",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1211,
           "country_id":300,
           "code":"",
           "name":"Epirus",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1212,
           "country_id":300,
           "code":"",
           "name":"Ionian Islands",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1213,
           "country_id":300,
           "code":"",
           "name":"West Greece",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1214,
           "country_id":300,
           "code":"",
           "name":"Central Greece",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1215,
           "country_id":300,
           "code":"",
           "name":"Peloponnese",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1216,
           "country_id":300,
           "code":"",
           "name":"Attica",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1217,
           "country_id":300,
           "code":"",
           "name":"North Aegean",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1218,
           "country_id":300,
           "code":"",
           "name":"South Aegean",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1219,
           "country_id":300,
           "code":"",
           "name":"Crete",
           "standard_timezone":"(UTC+3.0) Athens",
           "timezone":"Europe/Athens"
        },
        {
           "id":1220,
           "country_id":304,
           "code":"",
           "name":"Nordgrønland",
           "standard_timezone":"(UTC+2.0) Godthab",
           "timezone":"America/Godthab"
        },
        {
           "id":1221,
           "country_id":304,
           "code":"",
           "name":"Østgrønland",
           "standard_timezone":"(UTC+2.0) Godthab",
           "timezone":"America/Godthab"
        },
        {
           "id":1222,
           "country_id":304,
           "code":"",
           "name":"Vestgrønland",
           "standard_timezone":"(UTC+2.0) Godthab",
           "timezone":"America/Godthab"
        },
        {
           "id":1223,
           "country_id":308,
           "code":"",
           "name":"Saint Andrew",
           "standard_timezone":"(UTC-4.0) Grenada",
           "timezone":"America/Grenada"
        },
        {
           "id":1224,
           "country_id":308,
           "code":"",
           "name":"Saint David",
           "standard_timezone":"(UTC-4.0) Grenada",
           "timezone":"America/Grenada"
        },
        {
           "id":1225,
           "country_id":308,
           "code":"",
           "name":"Saint George",
           "standard_timezone":"(UTC-4.0) Grenada",
           "timezone":"America/Grenada"
        },
        {
           "id":1226,
           "country_id":308,
           "code":"",
           "name":"Saint John",
           "standard_timezone":"(UTC-4.0) Grenada",
           "timezone":"America/Grenada"
        },
        {
           "id":1227,
           "country_id":308,
           "code":"",
           "name":"Saint Mark",
           "standard_timezone":"(UTC-4.0) Grenada",
           "timezone":"America/Grenada"
        },
        {
           "id":1228,
           "country_id":308,
           "code":"",
           "name":"Saint Patrick",
           "standard_timezone":"(UTC-4.0) Grenada",
           "timezone":"America/Grenada"
        },
        {
           "id":1229,
           "country_id":312,
           "code":"",
           "name":"Guadeloupe",
           "standard_timezone":"(UTC-3.0) Guadeloupe",
           "timezone":"America/Guadeloupe"
        },
        {
           "id":1230,
           "country_id":316,
           "code":"",
           "name":"Guam",
           "standard_timezone":"(UTC-6.0) Guam",
           "timezone":"Pacific/Guam"
        },
        {
           "id":1231,
           "country_id":320,
           "code":"",
           "name":"Alta Verapaz",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1232,
           "country_id":320,
           "code":"",
           "name":"Baja Verapaz",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1233,
           "country_id":320,
           "code":"",
           "name":"Chimaltenango",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1234,
           "country_id":320,
           "code":"",
           "name":"Chiquimula",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1235,
           "country_id":320,
           "code":"",
           "name":"El Progreso",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1236,
           "country_id":320,
           "code":"",
           "name":"Escuintla",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1237,
           "country_id":320,
           "code":"",
           "name":"Guatemala",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1238,
           "country_id":320,
           "code":"",
           "name":"Huehuetenango",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1239,
           "country_id":320,
           "code":"",
           "name":"Izabal",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1240,
           "country_id":320,
           "code":"",
           "name":"Jalapa",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1241,
           "country_id":320,
           "code":"",
           "name":"Jutiapa",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1242,
           "country_id":320,
           "code":"",
           "name":"Petén",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1243,
           "country_id":320,
           "code":"",
           "name":"Quetzaltenango",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1244,
           "country_id":320,
           "code":"",
           "name":"Quiché",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1245,
           "country_id":320,
           "code":"",
           "name":"Retalhuleu",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1246,
           "country_id":320,
           "code":"",
           "name":"Sacatepéquez",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1247,
           "country_id":320,
           "code":"",
           "name":"San Marcos",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1248,
           "country_id":320,
           "code":"",
           "name":"Santa Rosa",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1249,
           "country_id":320,
           "code":"",
           "name":"Sololá",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1250,
           "country_id":320,
           "code":"",
           "name":"Suchitepéquez",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1251,
           "country_id":320,
           "code":"",
           "name":"Totonicapán",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1252,
           "country_id":320,
           "code":"",
           "name":"Zacapa",
           "standard_timezone":"(UTC-6.0) Guatemala",
           "timezone":"America/Guatemala"
        },
        {
           "id":1253,
           "country_id":324,
           "code":"",
           "name":"Beyla",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1254,
           "country_id":324,
           "code":"",
           "name":"Boffa",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1255,
           "country_id":324,
           "code":"",
           "name":"Boké",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1256,
           "country_id":324,
           "code":"",
           "name":"Conakry",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1257,
           "country_id":324,
           "code":"",
           "name":"Dabola",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1258,
           "country_id":324,
           "code":"",
           "name":"Dalaba",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1259,
           "country_id":324,
           "code":"",
           "name":"Dinguiraye",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1260,
           "country_id":324,
           "code":"",
           "name":"Faranah",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1261,
           "country_id":324,
           "code":"",
           "name":"Forécariah",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1262,
           "country_id":324,
           "code":"",
           "name":"Fria",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1263,
           "country_id":324,
           "code":"",
           "name":"Gaoual",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1264,
           "country_id":324,
           "code":"",
           "name":"Guéckédou",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1265,
           "country_id":324,
           "code":"",
           "name":"Kankan",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1266,
           "country_id":324,
           "code":"",
           "name":"Kérouané",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1267,
           "country_id":324,
           "code":"",
           "name":"Kindia",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1268,
           "country_id":324,
           "code":"",
           "name":"Kissidougou",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1269,
           "country_id":324,
           "code":"",
           "name":"Koundara",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1270,
           "country_id":324,
           "code":"",
           "name":"Kouroussa",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1271,
           "country_id":324,
           "code":"",
           "name":"Macenta",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1272,
           "country_id":324,
           "code":"",
           "name":"Mali",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1273,
           "country_id":324,
           "code":"",
           "name":"Mamou",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1274,
           "country_id":324,
           "code":"",
           "name":"Pita",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1275,
           "country_id":324,
           "code":"",
           "name":"Siguiri",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1276,
           "country_id":324,
           "code":"",
           "name":"Télimélé",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1277,
           "country_id":324,
           "code":"",
           "name":"Tougué",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1278,
           "country_id":324,
           "code":"",
           "name":"Yomou",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1279,
           "country_id":324,
           "code":"",
           "name":"Coyah",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1280,
           "country_id":324,
           "code":"",
           "name":"Dubréka",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1281,
           "country_id":324,
           "code":"",
           "name":"Kankan",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1282,
           "country_id":324,
           "code":"",
           "name":"Koubia",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1283,
           "country_id":324,
           "code":"",
           "name":"Labé",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1284,
           "country_id":324,
           "code":"",
           "name":"Lélouma",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1285,
           "country_id":324,
           "code":"",
           "name":"Lola",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1286,
           "country_id":324,
           "code":"",
           "name":"Mandiana",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1287,
           "country_id":324,
           "code":"",
           "name":"Nzérékoré",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1288,
           "country_id":324,
           "code":"",
           "name":"Siguiri",
           "standard_timezone":"(UTC+0.0) Conakry",
           "timezone":"Africa/Conakry"
        },
        {
           "id":1289,
           "country_id":328,
           "code":"",
           "name":"Barima-Waini",
           "standard_timezone":"(UTC-4.0) Guyana",
           "timezone":"America/Guyana"
        },
        {
           "id":1290,
           "country_id":328,
           "code":"",
           "name":"Cuyuni-Mazaruni",
           "standard_timezone":"(UTC-4.0) Guyana",
           "timezone":"America/Guyana"
        },
        {
           "id":1291,
           "country_id":328,
           "code":"",
           "name":"Demerara-Mahaica",
           "standard_timezone":"(UTC-4.0) Guyana",
           "timezone":"America/Guyana"
        },
        {
           "id":1292,
           "country_id":328,
           "code":"",
           "name":"East Berbice-Corentyne",
           "standard_timezone":"(UTC-4.0) Guyana",
           "timezone":"America/Guyana"
        },
        {
           "id":1293,
           "country_id":328,
           "code":"",
           "name":"Essequibo Islands-West Demerara",
           "standard_timezone":"(UTC-4.0) Guyana",
           "timezone":"America/Guyana"
        },
        {
           "id":1294,
           "country_id":328,
           "code":"",
           "name":"Mahaica-Berbice",
           "standard_timezone":"(UTC-4.0) Guyana",
           "timezone":"America/Guyana"
        },
        {
           "id":1295,
           "country_id":328,
           "code":"",
           "name":"Pomeroon-Supenaam",
           "standard_timezone":"(UTC-4.0) Guyana",
           "timezone":"America/Guyana"
        },
        {
           "id":1296,
           "country_id":328,
           "code":"",
           "name":"Potaro-Siparuni",
           "standard_timezone":"(UTC-4.0) Guyana",
           "timezone":"America/Guyana"
        },
        {
           "id":1297,
           "country_id":328,
           "code":"",
           "name":"Upper Demerara-Berbice",
           "standard_timezone":"(UTC-4.0) Guyana",
           "timezone":"America/Guyana"
        },
        {
           "id":1298,
           "country_id":328,
           "code":"",
           "name":"Upper Takutu-Upper Essequibo",
           "standard_timezone":"(UTC-4.0) Guyana",
           "timezone":"America/Guyana"
        },
        {
           "id":1299,
           "country_id":332,
           "code":"",
           "name":"Nord-Ouest",
           "standard_timezone":"(UTC-4.0) Port-au-Prince",
           "timezone":"America/Port-au-Prince"
        },
        {
           "id":1300,
           "country_id":332,
           "code":"",
           "name":"Artibonite",
           "standard_timezone":"(UTC-4.0) Port-au-Prince",
           "timezone":"America/Port-au-Prince"
        },
        {
           "id":1301,
           "country_id":332,
           "code":"",
           "name":"Centre",
           "standard_timezone":"(UTC-4.0) Port-au-Prince",
           "timezone":"America/Port-au-Prince"
        },
        {
           "id":1302,
           "country_id":332,
           "code":"",
           "name":"Nord",
           "standard_timezone":"(UTC-4.0) Port-au-Prince",
           "timezone":"America/Port-au-Prince"
        },
        {
           "id":1303,
           "country_id":332,
           "code":"",
           "name":"Nord-Est",
           "standard_timezone":"(UTC-4.0) Port-au-Prince",
           "timezone":"America/Port-au-Prince"
        },
        {
           "id":1304,
           "country_id":332,
           "code":"",
           "name":"Ouest",
           "standard_timezone":"(UTC-4.0) Port-au-Prince",
           "timezone":"America/Port-au-Prince"
        },
        {
           "id":1305,
           "country_id":332,
           "code":"",
           "name":"Sud",
           "standard_timezone":"(UTC-4.0) Port-au-Prince",
           "timezone":"America/Port-au-Prince"
        },
        {
           "id":1306,
           "country_id":332,
           "code":"",
           "name":"Sud-Est",
           "standard_timezone":"(UTC-4.0) Port-au-Prince",
           "timezone":"America/Port-au-Prince"
        },
        {
           "id":1307,
           "country_id":332,
           "code":"",
           "name":"GrandʼAnse",
           "standard_timezone":"(UTC-4.0) Port-au-Prince",
           "timezone":"America/Port-au-Prince"
        },
        {
           "id":1308,
           "country_id":332,
           "code":"",
           "name":"Nippes",
           "standard_timezone":"(UTC-4.0) Port-au-Prince",
           "timezone":"America/Port-au-Prince"
        },
        {
           "id":1309,
           "country_id":336,
           "code":"",
           "name":"Vatican City",
           "standard_timezone":"(UTC-5.0) Vatican",
           "timezone":"Europe/Vatican"
        },
        {
           "id":1310,
           "country_id":340,
           "code":"",
           "name":"Atlántida",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1311,
           "country_id":340,
           "code":"",
           "name":"Choluteca",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1312,
           "country_id":340,
           "code":"",
           "name":"Colón",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1313,
           "country_id":340,
           "code":"",
           "name":"Comayagua",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1314,
           "country_id":340,
           "code":"",
           "name":"Copán",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1315,
           "country_id":340,
           "code":"",
           "name":"Cortés",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1316,
           "country_id":340,
           "code":"",
           "name":"El Paraíso",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1317,
           "country_id":340,
           "code":"",
           "name":"Francisco Morazán",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1318,
           "country_id":340,
           "code":"",
           "name":"Gracias a Dios",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1319,
           "country_id":340,
           "code":"",
           "name":"Intibucá",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1320,
           "country_id":340,
           "code":"",
           "name":"Islas de la Bahía",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1321,
           "country_id":340,
           "code":"",
           "name":"La Paz",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1322,
           "country_id":340,
           "code":"",
           "name":"Lempira",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1323,
           "country_id":340,
           "code":"",
           "name":"Ocotepeque",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1324,
           "country_id":340,
           "code":"",
           "name":"Olancho",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1325,
           "country_id":340,
           "code":"",
           "name":"Santa Bárbara",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1326,
           "country_id":340,
           "code":"",
           "name":"Valle",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1327,
           "country_id":340,
           "code":"",
           "name":"Yoro",
           "standard_timezone":"(UTC-6.0) Tegucigalpa",
           "timezone":"America/Tegucigalpa"
        },
        {
           "id":1328,
           "country_id":344,
           "code":"",
           "name":"Hong Kong",
           "standard_timezone":"(UTC+8.0) Hong_Kong",
           "timezone":"Asia/Hong_Kong"
        },
        {
           "id":1329,
           "country_id":348,
           "code":"",
           "name":"Bács-Kiskun",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1330,
           "country_id":348,
           "code":"",
           "name":"Baranya",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1331,
           "country_id":348,
           "code":"",
           "name":"Békés",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1332,
           "country_id":348,
           "code":"",
           "name":"Borsod-Abaúj-Zemplén",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1333,
           "country_id":348,
           "code":"",
           "name":"Budapest",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1334,
           "country_id":348,
           "code":"",
           "name":"Csongrád",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1335,
           "country_id":348,
           "code":"",
           "name":"Fejér",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1336,
           "country_id":348,
           "code":"",
           "name":"Győr-Moson-Sopron",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1337,
           "country_id":348,
           "code":"",
           "name":"Hajdú-Bihar",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1338,
           "country_id":348,
           "code":"",
           "name":"Heves",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1339,
           "country_id":348,
           "code":"",
           "name":"Komárom-Esztergom",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1340,
           "country_id":348,
           "code":"",
           "name":"Nógrád",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1341,
           "country_id":348,
           "code":"",
           "name":"Pest",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1342,
           "country_id":348,
           "code":"",
           "name":"Somogy",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1343,
           "country_id":348,
           "code":"",
           "name":"Szabolcs-Szatmár-Bereg",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1344,
           "country_id":348,
           "code":"",
           "name":"Jász-Nagykun-Szolnok",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1345,
           "country_id":348,
           "code":"",
           "name":"Tolna",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1346,
           "country_id":348,
           "code":"",
           "name":"Vas",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1347,
           "country_id":348,
           "code":"",
           "name":"Veszprém",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1348,
           "country_id":348,
           "code":"",
           "name":"Zala",
           "standard_timezone":"(UTC+2.0) Budapest",
           "timezone":"Europe/Budapest"
        },
        {
           "id":1349,
           "country_id":352,
           "code":"",
           "name":"Borgarfjardarsysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1350,
           "country_id":352,
           "code":"",
           "name":"Dalasysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1351,
           "country_id":352,
           "code":"",
           "name":"Eyjafjardarsysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1352,
           "country_id":352,
           "code":"",
           "name":"Gullbringusysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1353,
           "country_id":352,
           "code":"",
           "name":"Hafnarfjördur",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1354,
           "country_id":352,
           "code":"",
           "name":"Husavik",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1355,
           "country_id":352,
           "code":"",
           "name":"Isafjördur",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1356,
           "country_id":352,
           "code":"",
           "name":"Keflavik",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1357,
           "country_id":352,
           "code":"",
           "name":"Kjosarsysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1358,
           "country_id":352,
           "code":"",
           "name":"Kopavogur",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1359,
           "country_id":352,
           "code":"",
           "name":"Myrasysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1360,
           "country_id":352,
           "code":"",
           "name":"Neskaupstadur",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1361,
           "country_id":352,
           "code":"",
           "name":"Nordur-Isafjardarsysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1362,
           "country_id":352,
           "code":"",
           "name":"Nordur-Mulasysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1363,
           "country_id":352,
           "code":"",
           "name":"Nordur-Tingeyjarsysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1364,
           "country_id":352,
           "code":"",
           "name":"Olafsfjördur",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1365,
           "country_id":352,
           "code":"",
           "name":"Rangarvallasysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1366,
           "country_id":352,
           "code":"",
           "name":"Reykjavík",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1367,
           "country_id":352,
           "code":"",
           "name":"Saudarkrokur",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1368,
           "country_id":352,
           "code":"",
           "name":"Seydisfjordur",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1369,
           "country_id":352,
           "code":"",
           "name":"Siglufjordur",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1370,
           "country_id":352,
           "code":"",
           "name":"Skagafjardarsysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1371,
           "country_id":352,
           "code":"",
           "name":"Snafellsnes- og Hnappadalssysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1372,
           "country_id":352,
           "code":"",
           "name":"Strandasysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1373,
           "country_id":352,
           "code":"",
           "name":"Sudur-Mulasysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1374,
           "country_id":352,
           "code":"",
           "name":"Sudur-Tingeyjarsysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1375,
           "country_id":352,
           "code":"",
           "name":"Vestmannaeyjar",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1376,
           "country_id":352,
           "code":"",
           "name":"Vestur-Bardastrandarsysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1377,
           "country_id":352,
           "code":"",
           "name":"Vestur-Hunavatnssysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1378,
           "country_id":352,
           "code":"",
           "name":"Vestur-Isafjardarsysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1379,
           "country_id":352,
           "code":"",
           "name":"Vestur-Skaftafellssysla",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1380,
           "country_id":352,
           "code":"",
           "name":"East",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1381,
           "country_id":352,
           "code":"",
           "name":"Capital Region",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1382,
           "country_id":352,
           "code":"",
           "name":"Northeast",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1383,
           "country_id":352,
           "code":"",
           "name":"Northwest",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1384,
           "country_id":352,
           "code":"",
           "name":"South",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1385,
           "country_id":352,
           "code":"",
           "name":"Southern Peninsula",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1386,
           "country_id":352,
           "code":"",
           "name":"Westfjords",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1387,
           "country_id":352,
           "code":"",
           "name":"West",
           "standard_timezone":"(UTC+0.0) Reykjavik",
           "timezone":"Atlantic/Reykjavik"
        },
        {
           "id":1388,
           "country_id":360,
           "code":"",
           "name":"Aceh",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1389,
           "country_id":360,
           "code":"",
           "name":"Bali",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1390,
           "country_id":360,
           "code":"",
           "name":"Bengkulu",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1391,
           "country_id":360,
           "code":"",
           "name":"Jakarta Raya",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1392,
           "country_id":360,
           "code":"",
           "name":"Jambi",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1393,
           "country_id":360,
           "code":"",
           "name":"Jawa Barat",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1394,
           "country_id":360,
           "code":"",
           "name":"Central Java",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1395,
           "country_id":360,
           "code":"",
           "name":"East Java",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1396,
           "country_id":360,
           "code":"",
           "name":"Yogyakarta ",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1397,
           "country_id":360,
           "code":"",
           "name":"West Kalimantan",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1398,
           "country_id":360,
           "code":"",
           "name":"South Kalimantan",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1399,
           "country_id":360,
           "code":"",
           "name":"Kalimantan Tengah",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1400,
           "country_id":360,
           "code":"",
           "name":"East Kalimantan",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1401,
           "country_id":360,
           "code":"",
           "name":"Lampung",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1402,
           "country_id":360,
           "code":"",
           "name":"Nusa Tenggara Barat",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1403,
           "country_id":360,
           "code":"",
           "name":"East Nusa Tenggara",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1404,
           "country_id":360,
           "code":"",
           "name":"Central Sulawesi",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1405,
           "country_id":360,
           "code":"",
           "name":"Sulawesi Tenggara",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1406,
           "country_id":360,
           "code":"",
           "name":"Sulawesi Utara",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1407,
           "country_id":360,
           "code":"",
           "name":"West Sumatra",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1408,
           "country_id":360,
           "code":"",
           "name":"Sumatera Selatan",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1409,
           "country_id":360,
           "code":"",
           "name":"North Sumatra",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1410,
           "country_id":360,
           "code":"",
           "name":"Timor Timur",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1411,
           "country_id":360,
           "code":"",
           "name":"Maluku ",
           "standard_timezone":"(UTC+9.0) Jayapura",
           "timezone":"Asia/Jayapura"
        },
        {
           "id":1412,
           "country_id":360,
           "code":"",
           "name":"Maluku Utara",
           "standard_timezone":"(UTC+9.0) Jayapura",
           "timezone":"Asia/Jayapura"
        },
        {
           "id":1413,
           "country_id":360,
           "code":"",
           "name":"West Java",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1414,
           "country_id":360,
           "code":"",
           "name":"North Sulawesi",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1415,
           "country_id":360,
           "code":"",
           "name":"South Sumatra",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1416,
           "country_id":360,
           "code":"",
           "name":"Banten",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1417,
           "country_id":360,
           "code":"",
           "name":"Gorontalo",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1418,
           "country_id":360,
           "code":"",
           "name":"Bangka-Belitung",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1419,
           "country_id":360,
           "code":"",
           "name":"Papua",
           "standard_timezone":"(UTC+9.0) Jayapura",
           "timezone":"Asia/Jayapura"
        },
        {
           "id":1420,
           "country_id":360,
           "code":"",
           "name":"Riau",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1421,
           "country_id":360,
           "code":"",
           "name":"South Sulawesi",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1422,
           "country_id":360,
           "code":"",
           "name":"Irian Jaya Barat",
           "standard_timezone":"(UTC+9.0) Jayapura",
           "timezone":"Asia/Jayapura"
        },
        {
           "id":1423,
           "country_id":360,
           "code":"",
           "name":"Riau Islands",
           "standard_timezone":"(UTC+7.0) Jakarta",
           "timezone":"Asia/Jakarta"
        },
        {
           "id":1424,
           "country_id":360,
           "code":"",
           "name":"Sulawesi Barat",
           "standard_timezone":"(UTC+7.0) Ujung_Pandang",
           "timezone":"Asia/Ujung_Pandang"
        },
        {
           "id":1425,
           "country_id":364,
           "code":"",
           "name":"Āz̄ārbāyjān-e Gharbī",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1426,
           "country_id":364,
           "code":"",
           "name":"Chahār Maḩāll va Bakhtīārī",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1427,
           "country_id":364,
           "code":"",
           "name":"Sīstān va Balūchestān",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1428,
           "country_id":364,
           "code":"",
           "name":"Kohgīlūyeh va Būyer Aḩmad",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1429,
           "country_id":364,
           "code":"",
           "name":"Fārs Province",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1430,
           "country_id":364,
           "code":"",
           "name":"Gīlān",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1431,
           "country_id":364,
           "code":"",
           "name":"Hamadān",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1432,
           "country_id":364,
           "code":"",
           "name":"Īlām",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1433,
           "country_id":364,
           "code":"",
           "name":"Hormozgān Province",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1434,
           "country_id":364,
           "code":"",
           "name":"Kerman",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1435,
           "country_id":364,
           "code":"",
           "name":"Kermānshāh",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1436,
           "country_id":364,
           "code":"",
           "name":"Khūzestān",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1437,
           "country_id":364,
           "code":"",
           "name":"Kordestān",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1438,
           "country_id":364,
           "code":"",
           "name":"Mazandaran",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1439,
           "country_id":364,
           "code":"",
           "name":"Markazi",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1440,
           "country_id":364,
           "code":"",
           "name":"Zanjan",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1441,
           "country_id":364,
           "code":"",
           "name":"Bushehr Province",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1442,
           "country_id":364,
           "code":"",
           "name":"Lorestān",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1443,
           "country_id":364,
           "code":"",
           "name":"Markazi",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1444,
           "country_id":364,
           "code":"",
           "name":"Semnān Province",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1445,
           "country_id":364,
           "code":"",
           "name":"Tehrān",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1446,
           "country_id":364,
           "code":"",
           "name":"Zanjan",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1447,
           "country_id":364,
           "code":"",
           "name":"Eşfahān",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1448,
           "country_id":364,
           "code":"",
           "name":"Kermān",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1449,
           "country_id":364,
           "code":"",
           "name":"Ostan-e Khorasan-e Razavi",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1450,
           "country_id":364,
           "code":"",
           "name":"Yazd",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1451,
           "country_id":364,
           "code":"",
           "name":"Ardabīl",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1452,
           "country_id":364,
           "code":"",
           "name":"Āz̄ārbāyjān-e Sharqī",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1453,
           "country_id":364,
           "code":"",
           "name":"Markazi Province",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1454,
           "country_id":364,
           "code":"",
           "name":"Māzandarān Province",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1455,
           "country_id":364,
           "code":"",
           "name":"Zanjan Province",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1456,
           "country_id":364,
           "code":"",
           "name":"Golestān",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1457,
           "country_id":364,
           "code":"",
           "name":"Qazvīn",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1458,
           "country_id":364,
           "code":"",
           "name":"Qom",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1459,
           "country_id":364,
           "code":"",
           "name":"Yazd",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1460,
           "country_id":364,
           "code":"",
           "name":"Khorāsān-e Jonūbī",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1461,
           "country_id":364,
           "code":"",
           "name":"Razavi Khorasan Province",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1462,
           "country_id":364,
           "code":"",
           "name":"Khorāsān-e Shomālī",
           "standard_timezone":"(UTC+4.50) Tehran",
           "timezone":"Asia/Tehran"
        },
        {
           "id":1463,
           "country_id":368,
           "code":"",
           "name":"Anbar",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1464,
           "country_id":368,
           "code":"",
           "name":"Al Başrah",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1465,
           "country_id":368,
           "code":"",
           "name":"Al Muthanná",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1466,
           "country_id":368,
           "code":"",
           "name":"Al Qādisīyah",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1467,
           "country_id":368,
           "code":"",
           "name":"As Sulaymānīyah",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1468,
           "country_id":368,
           "code":"",
           "name":"Bābil",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1469,
           "country_id":368,
           "code":"",
           "name":"Baghdād",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1470,
           "country_id":368,
           "code":"",
           "name":"Dahūk",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1471,
           "country_id":368,
           "code":"",
           "name":"Dhi Qar",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1472,
           "country_id":368,
           "code":"",
           "name":"Diyala",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1473,
           "country_id":368,
           "code":"",
           "name":"Arbīl",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1474,
           "country_id":368,
           "code":"",
           "name":"Karbalāʼ",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1475,
           "country_id":368,
           "code":"",
           "name":"At Taʼmīm",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1476,
           "country_id":368,
           "code":"",
           "name":"Maysan",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1477,
           "country_id":368,
           "code":"",
           "name":"Nīnawá",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1478,
           "country_id":368,
           "code":"",
           "name":"Wāsiţ",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1479,
           "country_id":368,
           "code":"",
           "name":"An Najaf",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1480,
           "country_id":368,
           "code":"",
           "name":"Şalāḩ ad Dīn",
           "standard_timezone":"(UTC+3.0) Baghdad",
           "timezone":"Asia/Baghdad"
        },
        {
           "id":1481,
           "country_id":372,
           "code":"",
           "name":"Carlow",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1482,
           "country_id":372,
           "code":"",
           "name":"Cavan",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1483,
           "country_id":372,
           "code":"",
           "name":"County Clare",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1484,
           "country_id":372,
           "code":"",
           "name":"Cork",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1485,
           "country_id":372,
           "code":"",
           "name":"Donegal",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1486,
           "country_id":372,
           "code":"",
           "name":"Galway",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1487,
           "country_id":372,
           "code":"",
           "name":"County Kerry",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1488,
           "country_id":372,
           "code":"",
           "name":"County Kildare",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1489,
           "country_id":372,
           "code":"",
           "name":"County Kilkenny",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1490,
           "country_id":372,
           "code":"",
           "name":"Leitrim",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1491,
           "country_id":372,
           "code":"",
           "name":"Laois",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1492,
           "country_id":372,
           "code":"",
           "name":"Limerick",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1493,
           "country_id":372,
           "code":"",
           "name":"County Longford",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1494,
           "country_id":372,
           "code":"",
           "name":"County Louth",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1495,
           "country_id":372,
           "code":"",
           "name":"County Mayo",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1496,
           "country_id":372,
           "code":"",
           "name":"County Meath",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1497,
           "country_id":372,
           "code":"",
           "name":"Monaghan",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1498,
           "country_id":372,
           "code":"",
           "name":"County Offaly",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1499,
           "country_id":372,
           "code":"",
           "name":"County Roscommon",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1500,
           "country_id":372,
           "code":"",
           "name":"County Sligo",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1501,
           "country_id":372,
           "code":"",
           "name":"County Waterford",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1502,
           "country_id":372,
           "code":"",
           "name":"County Westmeath",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1503,
           "country_id":372,
           "code":"",
           "name":"County Wexford",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1504,
           "country_id":372,
           "code":"",
           "name":"County Wicklow",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1505,
           "country_id":372,
           "code":"",
           "name":"Dún Laoghaire-Rathdown",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1506,
           "country_id":372,
           "code":"",
           "name":"Fingal County",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1507,
           "country_id":372,
           "code":"",
           "name":"Tipperary North Riding",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1508,
           "country_id":372,
           "code":"",
           "name":"South Dublin",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1509,
           "country_id":372,
           "code":"",
           "name":"Tipperary South Riding",
           "standard_timezone":"(UTC+1.0) Dublin",
           "timezone":"Europe/Dublin"
        },
        {
           "id":1510,
           "country_id":376,
           "code":"",
           "name":"HaDarom",
           "standard_timezone":"(UTC+3.0) Jerusalem",
           "timezone":"Asia/Jerusalem"
        },
        {
           "id":1511,
           "country_id":376,
           "code":"",
           "name":"HaMerkaz",
           "standard_timezone":"(UTC+3.0) Jerusalem",
           "timezone":"Asia/Jerusalem"
        },
        {
           "id":1512,
           "country_id":376,
           "code":"",
           "name":"Northern District",
           "standard_timezone":"(UTC+3.0) Jerusalem",
           "timezone":"Asia/Jerusalem"
        },
        {
           "id":1513,
           "country_id":376,
           "code":"",
           "name":"H̱efa",
           "standard_timezone":"(UTC+3.0) Jerusalem",
           "timezone":"Asia/Jerusalem"
        },
        {
           "id":1514,
           "country_id":376,
           "code":"",
           "name":"Tel Aviv",
           "standard_timezone":"(UTC+3.0) Jerusalem",
           "timezone":"Asia/Jerusalem"
        },
        {
           "id":1515,
           "country_id":376,
           "code":"",
           "name":"Yerushalayim",
           "standard_timezone":"(UTC+3.0) Jerusalem",
           "timezone":"Asia/Jerusalem"
        },
        {
           "id":1516,
           "country_id":380,
           "code":"",
           "name":"Abruzzo",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1517,
           "country_id":380,
           "code":"",
           "name":"Basilicate",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1518,
           "country_id":380,
           "code":"",
           "name":"Calabria",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1519,
           "country_id":380,
           "code":"",
           "name":"Campania",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1520,
           "country_id":380,
           "code":"",
           "name":"Emilia-Romagna",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1521,
           "country_id":380,
           "code":"",
           "name":"Friuli",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1522,
           "country_id":380,
           "code":"",
           "name":"Lazio",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1523,
           "country_id":380,
           "code":"",
           "name":"Liguria",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1524,
           "country_id":380,
           "code":"",
           "name":"Lombardy",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1525,
           "country_id":380,
           "code":"",
           "name":"The Marches",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1526,
           "country_id":380,
           "code":"",
           "name":"Molise",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1527,
           "country_id":380,
           "code":"",
           "name":"Piedmont",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1528,
           "country_id":380,
           "code":"",
           "name":"Apulia",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1529,
           "country_id":380,
           "code":"",
           "name":"Sardinia",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1530,
           "country_id":380,
           "code":"",
           "name":"Sicily",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1531,
           "country_id":380,
           "code":"",
           "name":"Tuscany",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1532,
           "country_id":380,
           "code":"",
           "name":"Trentino-Alto Adige",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1533,
           "country_id":380,
           "code":"",
           "name":"Umbria",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1534,
           "country_id":380,
           "code":"",
           "name":"Aosta Valley",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1535,
           "country_id":380,
           "code":"",
           "name":"Veneto",
           "standard_timezone":"(UTC+2.0) Rome",
           "timezone":"Europe/Rome"
        },
        {
           "id":1536,
           "country_id":384,
           "code":"",
           "name":"Valparaíso Region",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1537,
           "country_id":384,
           "code":"",
           "name":"Antofagasta Region",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1538,
           "country_id":384,
           "code":"",
           "name":"Araucanía Region",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1539,
           "country_id":384,
           "code":"",
           "name":"Atacama Region",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1540,
           "country_id":384,
           "code":"",
           "name":"Biobío Region",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1541,
           "country_id":384,
           "code":"",
           "name":"Coquimbo Region",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1542,
           "country_id":384,
           "code":"",
           "name":"Maule Region",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1543,
           "country_id":384,
           "code":"",
           "name":"Santiago Metropolitan Region",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1544,
           "country_id":384,
           "code":"",
           "name":"Danane",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1545,
           "country_id":384,
           "code":"",
           "name":"Divo",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1546,
           "country_id":384,
           "code":"",
           "name":"Ferkessedougou",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1547,
           "country_id":384,
           "code":"",
           "name":"Gagnoa",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1548,
           "country_id":384,
           "code":"",
           "name":"Katiola",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1549,
           "country_id":384,
           "code":"",
           "name":"Korhogo",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1550,
           "country_id":384,
           "code":"",
           "name":"Odienne",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1551,
           "country_id":384,
           "code":"",
           "name":"Seguela",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1552,
           "country_id":384,
           "code":"",
           "name":"Touba",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1553,
           "country_id":384,
           "code":"",
           "name":"Bongouanou",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1554,
           "country_id":384,
           "code":"",
           "name":"Issia",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1555,
           "country_id":384,
           "code":"",
           "name":"Lakota",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1556,
           "country_id":384,
           "code":"",
           "name":"Mankono",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1557,
           "country_id":384,
           "code":"",
           "name":"Oume",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1558,
           "country_id":384,
           "code":"",
           "name":"Soubre",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1559,
           "country_id":384,
           "code":"",
           "name":"Tingrela",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1560,
           "country_id":384,
           "code":"",
           "name":"Zuenoula",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1561,
           "country_id":384,
           "code":"",
           "name":"Bangolo",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1562,
           "country_id":384,
           "code":"",
           "name":"Beoumi",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1563,
           "country_id":384,
           "code":"",
           "name":"Bondoukou",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1564,
           "country_id":384,
           "code":"",
           "name":"Bouafle",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1565,
           "country_id":384,
           "code":"",
           "name":"Bouake",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1566,
           "country_id":384,
           "code":"",
           "name":"Daloa",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1567,
           "country_id":384,
           "code":"",
           "name":"Daoukro",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1568,
           "country_id":384,
           "code":"",
           "name":"Duekoue",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1569,
           "country_id":384,
           "code":"",
           "name":"Grand-Lahou",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1570,
           "country_id":384,
           "code":"",
           "name":"Man",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1571,
           "country_id":384,
           "code":"",
           "name":"Mbahiakro",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1572,
           "country_id":384,
           "code":"",
           "name":"Sakassou",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1573,
           "country_id":384,
           "code":"",
           "name":"San Pedro",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1574,
           "country_id":384,
           "code":"",
           "name":"Sassandra",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1575,
           "country_id":384,
           "code":"",
           "name":"Sinfra",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1576,
           "country_id":384,
           "code":"",
           "name":"Tabou",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1577,
           "country_id":384,
           "code":"",
           "name":"Tanda",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1578,
           "country_id":384,
           "code":"",
           "name":"Tiassale",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1579,
           "country_id":384,
           "code":"",
           "name":"Toumodi",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1580,
           "country_id":384,
           "code":"",
           "name":"Vavoua",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1581,
           "country_id":384,
           "code":"",
           "name":"Abidjan",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1582,
           "country_id":384,
           "code":"",
           "name":"Aboisso",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1583,
           "country_id":384,
           "code":"",
           "name":"Adiake",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1584,
           "country_id":384,
           "code":"",
           "name":"Alepe",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1585,
           "country_id":384,
           "code":"",
           "name":"Bocanda",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1586,
           "country_id":384,
           "code":"",
           "name":"Dabou",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1587,
           "country_id":384,
           "code":"",
           "name":"Dimbokro",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1588,
           "country_id":384,
           "code":"",
           "name":"Grand-Bassam",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1589,
           "country_id":384,
           "code":"",
           "name":"Guiglo",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1590,
           "country_id":384,
           "code":"",
           "name":"Jacqueville",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1591,
           "country_id":384,
           "code":"",
           "name":"Tiebissou",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1592,
           "country_id":384,
           "code":"",
           "name":"Toulepleu",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1593,
           "country_id":384,
           "code":"",
           "name":"Yamoussoukro",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1594,
           "country_id":384,
           "code":"",
           "name":"Agnéby",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1595,
           "country_id":384,
           "code":"",
           "name":"Bafing",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1596,
           "country_id":384,
           "code":"",
           "name":"Bas-Sassandra",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1597,
           "country_id":384,
           "code":"",
           "name":"Denguélé",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1598,
           "country_id":384,
           "code":"",
           "name":"Dix-Huit Montagnes",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1599,
           "country_id":384,
           "code":"",
           "name":"Fromager",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1600,
           "country_id":384,
           "code":"",
           "name":"Haut-Sassandra",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1601,
           "country_id":384,
           "code":"",
           "name":"Lacs",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1602,
           "country_id":384,
           "code":"",
           "name":"Lagunes",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1603,
           "country_id":384,
           "code":"",
           "name":"Marahoué",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1604,
           "country_id":384,
           "code":"",
           "name":"Moyen-Cavally",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1605,
           "country_id":384,
           "code":"",
           "name":"Moyen-Comoé",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1606,
           "country_id":384,
           "code":"",
           "name":"Nʼzi-Comoé",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1607,
           "country_id":384,
           "code":"",
           "name":"Savanes",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1608,
           "country_id":384,
           "code":"",
           "name":"Sud-Bandama",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1609,
           "country_id":384,
           "code":"",
           "name":"Sud-Comoé",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1610,
           "country_id":384,
           "code":"",
           "name":"Vallée du Bandama",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1611,
           "country_id":384,
           "code":"",
           "name":"Worodougou",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1612,
           "country_id":384,
           "code":"",
           "name":"Zanzan",
           "standard_timezone":"(UTC+0.0) Abidjan",
           "timezone":"Africa/Abidjan"
        },
        {
           "id":1613,
           "country_id":388,
           "code":"",
           "name":"Clarendon",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1614,
           "country_id":388,
           "code":"",
           "name":"Hanover Parish",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1615,
           "country_id":388,
           "code":"",
           "name":"Manchester",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1616,
           "country_id":388,
           "code":"",
           "name":"Portland",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1617,
           "country_id":388,
           "code":"",
           "name":"Saint Andrew",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1618,
           "country_id":388,
           "code":"",
           "name":"Saint Ann",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1619,
           "country_id":388,
           "code":"",
           "name":"Saint Catherine",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1620,
           "country_id":388,
           "code":"",
           "name":"St. Elizabeth",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1621,
           "country_id":388,
           "code":"",
           "name":"Saint James",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1622,
           "country_id":388,
           "code":"",
           "name":"Saint Mary",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1623,
           "country_id":388,
           "code":"",
           "name":"Saint Thomas",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1624,
           "country_id":388,
           "code":"",
           "name":"Trelawny",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1625,
           "country_id":388,
           "code":"",
           "name":"Westmoreland",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1626,
           "country_id":388,
           "code":"",
           "name":"Kingston",
           "standard_timezone":"(UTC-5.0) Jamaica",
           "timezone":"America/Jamaica"
        },
        {
           "id":1627,
           "country_id":392,
           "code":"",
           "name":"Aichi",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1628,
           "country_id":392,
           "code":"",
           "name":"Akita",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1629,
           "country_id":392,
           "code":"",
           "name":"Aomori",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1630,
           "country_id":392,
           "code":"",
           "name":"Chiba",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1631,
           "country_id":392,
           "code":"",
           "name":"Ehime",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1632,
           "country_id":392,
           "code":"",
           "name":"Fukui",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1633,
           "country_id":392,
           "code":"",
           "name":"Fukuoka",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1634,
           "country_id":392,
           "code":"",
           "name":"Fukushima",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1635,
           "country_id":392,
           "code":"",
           "name":"Gifu",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1636,
           "country_id":392,
           "code":"",
           "name":"Gumma",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1637,
           "country_id":392,
           "code":"",
           "name":"Hiroshima",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1638,
           "country_id":392,
           "code":"",
           "name":"Hokkaidō",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1639,
           "country_id":392,
           "code":"",
           "name":"Hyōgo",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1640,
           "country_id":392,
           "code":"",
           "name":"Ibaraki",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1641,
           "country_id":392,
           "code":"",
           "name":"Ishikawa",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1642,
           "country_id":392,
           "code":"",
           "name":"Iwate",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1643,
           "country_id":392,
           "code":"",
           "name":"Kagawa",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1644,
           "country_id":392,
           "code":"",
           "name":"Kagoshima",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1645,
           "country_id":392,
           "code":"",
           "name":"Kanagawa",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1646,
           "country_id":392,
           "code":"",
           "name":"Kōchi",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1647,
           "country_id":392,
           "code":"",
           "name":"Kumamoto",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1648,
           "country_id":392,
           "code":"",
           "name":"Kyōto",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1649,
           "country_id":392,
           "code":"",
           "name":"Mie",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1650,
           "country_id":392,
           "code":"",
           "name":"Miyagi",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1651,
           "country_id":392,
           "code":"",
           "name":"Miyazaki",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1652,
           "country_id":392,
           "code":"",
           "name":"Nagano",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1653,
           "country_id":392,
           "code":"",
           "name":"Nagasaki",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1654,
           "country_id":392,
           "code":"",
           "name":"Nara",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1655,
           "country_id":392,
           "code":"",
           "name":"Niigata",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1656,
           "country_id":392,
           "code":"",
           "name":"Ōita",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1657,
           "country_id":392,
           "code":"",
           "name":"Okayama",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1658,
           "country_id":392,
           "code":"",
           "name":"Ōsaka",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1659,
           "country_id":392,
           "code":"",
           "name":"Saga",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1660,
           "country_id":392,
           "code":"",
           "name":"Saitama",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1661,
           "country_id":392,
           "code":"",
           "name":"Shiga",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1662,
           "country_id":392,
           "code":"",
           "name":"Shimane",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1663,
           "country_id":392,
           "code":"",
           "name":"Shizuoka",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1664,
           "country_id":392,
           "code":"",
           "name":"Tochigi",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1665,
           "country_id":392,
           "code":"",
           "name":"Tokushima",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1666,
           "country_id":392,
           "code":"",
           "name":"Tōkyō",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1667,
           "country_id":392,
           "code":"",
           "name":"Tottori",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1668,
           "country_id":392,
           "code":"",
           "name":"Toyama",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1669,
           "country_id":392,
           "code":"",
           "name":"Wakayama",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1670,
           "country_id":392,
           "code":"",
           "name":"Yamagata",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1671,
           "country_id":392,
           "code":"",
           "name":"Yamaguchi",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1672,
           "country_id":392,
           "code":"",
           "name":"Yamanashi",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1673,
           "country_id":392,
           "code":"",
           "name":"Okinawa",
           "standard_timezone":"(UTC+9.0) Tokyo",
           "timezone":"Asia/Tokyo"
        },
        {
           "id":1674,
           "country_id":398,
           "code":"",
           "name":"Almaty",
           "standard_timezone":"(UTC+6.0) Almaty",
           "timezone":"Asia/Almaty"
        },
        {
           "id":1675,
           "country_id":398,
           "code":"",
           "name":"Almaty Qalasy",
           "standard_timezone":"(UTC+6.0) Almaty",
           "timezone":"Asia/Almaty"
        },
        {
           "id":1676,
           "country_id":398,
           "code":"",
           "name":"Aqmola",
           "standard_timezone":"(UTC+6.0) Almaty",
           "timezone":"Asia/Almaty"
        },
        {
           "id":1677,
           "country_id":398,
           "code":"",
           "name":"Aqtöbe",
           "standard_timezone":"(UTC+5.0) Aqtobe",
           "timezone":"Asia/Aqtobe"
        },
        {
           "id":1678,
           "country_id":398,
           "code":"",
           "name":"Astana Qalasy",
           "standard_timezone":"(UTC+6.0) Almaty",
           "timezone":"Asia/Almaty"
        },
        {
           "id":1679,
           "country_id":398,
           "code":"",
           "name":"Atyraū",
           "standard_timezone":"(UTC+6.0) Oral",
           "timezone":"Asia/Oral"
        },
        {
           "id":1680,
           "country_id":398,
           "code":"",
           "name":"Batys Qazaqstan",
           "standard_timezone":"(UTC+6.0) Oral",
           "timezone":"Asia/Oral"
        },
        {
           "id":1681,
           "country_id":398,
           "code":"",
           "name":"Bayqongyr Qalasy",
           "standard_timezone":"(UTC+6.0) Almaty",
           "timezone":"Asia/Almaty"
        },
        {
           "id":1682,
           "country_id":398,
           "code":"",
           "name":"Mangghystaū",
           "standard_timezone":"(UTC+5.0) Aqtau",
           "timezone":"Asia/Aqtau"
        },
        {
           "id":1683,
           "country_id":398,
           "code":"",
           "name":"Ongtüstik Qazaqstan",
           "standard_timezone":"(UTC+6.0) Almaty",
           "timezone":"Asia/Almaty"
        },
        {
           "id":1684,
           "country_id":398,
           "code":"",
           "name":"Pavlodar",
           "standard_timezone":"(UTC+6.0) Almaty",
           "timezone":"Asia/Almaty"
        },
        {
           "id":1685,
           "country_id":398,
           "code":"",
           "name":"Qaraghandy",
           "standard_timezone":"(UTC+6.0) Almaty",
           "timezone":"Asia/Almaty"
        },
        {
           "id":1686,
           "country_id":398,
           "code":"",
           "name":"Qostanay",
           "standard_timezone":"(UTC+6.0) Qyzylorda",
           "timezone":"Asia/Qyzylorda"
        },
        {
           "id":1687,
           "country_id":398,
           "code":"",
           "name":"Qyzylorda",
           "standard_timezone":"(UTC+6.0) Qyzylorda",
           "timezone":"Asia/Qyzylorda"
        },
        {
           "id":1688,
           "country_id":398,
           "code":"",
           "name":"East Kazakhstan",
           "standard_timezone":"(UTC+6.0) Almaty",
           "timezone":"Asia/Almaty"
        },
        {
           "id":1689,
           "country_id":398,
           "code":"",
           "name":"Soltüstik Qazaqstan",
           "standard_timezone":"(UTC+6.0) Almaty",
           "timezone":"Asia/Almaty"
        },
        {
           "id":1690,
           "country_id":398,
           "code":"",
           "name":"Zhambyl",
           "standard_timezone":"(UTC+6.0) Almaty",
           "timezone":"Asia/Almaty"
        },
        {
           "id":1691,
           "country_id":400,
           "code":"",
           "name":"Balqa",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1692,
           "country_id":400,
           "code":"",
           "name":"Ma’an",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1693,
           "country_id":400,
           "code":"",
           "name":"Karak",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1694,
           "country_id":400,
           "code":"",
           "name":"Al Mafraq",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1695,
           "country_id":400,
           "code":"",
           "name":"Tafielah",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1696,
           "country_id":400,
           "code":"",
           "name":"Az Zarqa",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1697,
           "country_id":400,
           "code":"",
           "name":"Irbid",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1698,
           "country_id":400,
           "code":"",
           "name":"Mafraq",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1699,
           "country_id":400,
           "code":"",
           "name":"Amman",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1700,
           "country_id":400,
           "code":"",
           "name":"Zarqa",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1701,
           "country_id":400,
           "code":"",
           "name":"Irbid",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1702,
           "country_id":400,
           "code":"",
           "name":"Ma’an",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1703,
           "country_id":400,
           "code":"",
           "name":"Ajlun",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1704,
           "country_id":400,
           "code":"",
           "name":"Aqaba",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1705,
           "country_id":400,
           "code":"",
           "name":"Jerash",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1706,
           "country_id":400,
           "code":"",
           "name":"Madaba",
           "standard_timezone":"(UTC+3.0) Amman",
           "timezone":"Asia/Amman"
        },
        {
           "id":1707,
           "country_id":404,
           "code":"",
           "name":"Central",
           "standard_timezone":"(UTC+3.0) Nairobi",
           "timezone":"Africa/Nairobi"
        },
        {
           "id":1708,
           "country_id":404,
           "code":"",
           "name":"Coast",
           "standard_timezone":"(UTC+3.0) Nairobi",
           "timezone":"Africa/Nairobi"
        },
        {
           "id":1709,
           "country_id":404,
           "code":"",
           "name":"Eastern",
           "standard_timezone":"(UTC+3.0) Nairobi",
           "timezone":"Africa/Nairobi"
        },
        {
           "id":1710,
           "country_id":404,
           "code":"",
           "name":"Nairobi Area",
           "standard_timezone":"(UTC+3.0) Nairobi",
           "timezone":"Africa/Nairobi"
        },
        {
           "id":1711,
           "country_id":404,
           "code":"",
           "name":"North-Eastern",
           "standard_timezone":"(UTC+3.0) Nairobi",
           "timezone":"Africa/Nairobi"
        },
        {
           "id":1712,
           "country_id":404,
           "code":"",
           "name":"Nyanza",
           "standard_timezone":"(UTC+3.0) Nairobi",
           "timezone":"Africa/Nairobi"
        },
        {
           "id":1713,
           "country_id":404,
           "code":"",
           "name":"Rift Valley",
           "standard_timezone":"(UTC+3.0) Nairobi",
           "timezone":"Africa/Nairobi"
        },
        {
           "id":1714,
           "country_id":404,
           "code":"",
           "name":"Western",
           "standard_timezone":"(UTC+3.0) Nairobi",
           "timezone":"Africa/Nairobi"
        },
        {
           "id":1715,
           "country_id":408,
           "code":"",
           "name":"Chagang-do",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1716,
           "country_id":408,
           "code":"",
           "name":"Hamgyŏng-namdo",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1717,
           "country_id":408,
           "code":"",
           "name":"Hwanghae-namdo",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1718,
           "country_id":408,
           "code":"",
           "name":"Hwanghae-bukto",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1719,
           "country_id":408,
           "code":"",
           "name":"Kaesŏng-si",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1720,
           "country_id":408,
           "code":"",
           "name":"Gangwon",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1721,
           "country_id":408,
           "code":"",
           "name":"Pʼyŏngan-bukto",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1722,
           "country_id":408,
           "code":"",
           "name":"Pʼyŏngyang-si",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1723,
           "country_id":408,
           "code":"",
           "name":"Yanggang-do",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1724,
           "country_id":408,
           "code":"",
           "name":"Nampʼo-si",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1725,
           "country_id":408,
           "code":"",
           "name":"Pʼyŏngan-namdo",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1726,
           "country_id":408,
           "code":"",
           "name":"Hamgyŏng-bukto",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1727,
           "country_id":408,
           "code":"",
           "name":"Najin Sŏnbong-si",
           "standard_timezone":"(UTC+9.0) Pyongyang",
           "timezone":"Asia/Pyongyang"
        },
        {
           "id":1728,
           "country_id":410,
           "code":"",
           "name":"Jeju",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1729,
           "country_id":410,
           "code":"",
           "name":"North Jeolla",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1730,
           "country_id":410,
           "code":"",
           "name":"North Chungcheong",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1731,
           "country_id":410,
           "code":"",
           "name":"Gangwon",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1732,
           "country_id":410,
           "code":"",
           "name":"Busan",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1733,
           "country_id":410,
           "code":"",
           "name":"Seoul",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1734,
           "country_id":410,
           "code":"",
           "name":"Incheon",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1735,
           "country_id":410,
           "code":"",
           "name":"Gyeonggi",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1736,
           "country_id":410,
           "code":"",
           "name":"North Gyeongsang",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1737,
           "country_id":410,
           "code":"",
           "name":"Daegu",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1738,
           "country_id":410,
           "code":"",
           "name":"South Jeolla",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1739,
           "country_id":410,
           "code":"",
           "name":"South Chungcheong",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1740,
           "country_id":410,
           "code":"",
           "name":"Gwangju",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1741,
           "country_id":410,
           "code":"",
           "name":"Daejeon",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1742,
           "country_id":410,
           "code":"",
           "name":"South Gyeongsang",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1743,
           "country_id":410,
           "code":"",
           "name":"Ulsan",
           "standard_timezone":"(UTC+9.0) Seoul",
           "timezone":"Asia/Seoul"
        },
        {
           "id":1744,
           "country_id":414,
           "code":"",
           "name":"Muḩāfaz̧atalWafrah",
           "standard_timezone":"(UTC+3.0) Kuwait",
           "timezone":"Asia/Kuwait"
        },
        {
           "id":1745,
           "country_id":414,
           "code":"",
           "name":"Al ‘Āşimah",
           "standard_timezone":"(UTC+3.0) Kuwait",
           "timezone":"Asia/Kuwait"
        },
        {
           "id":1746,
           "country_id":414,
           "code":"",
           "name":"Al Aḩmadī",
           "standard_timezone":"(UTC+3.0) Kuwait",
           "timezone":"Asia/Kuwait"
        },
        {
           "id":1747,
           "country_id":414,
           "code":"",
           "name":"Al Jahrāʼ",
           "standard_timezone":"(UTC+3.0) Kuwait",
           "timezone":"Asia/Kuwait"
        },
        {
           "id":1748,
           "country_id":414,
           "code":"",
           "name":"Al Farwaniyah",
           "standard_timezone":"(UTC+3.0) Kuwait",
           "timezone":"Asia/Kuwait"
        },
        {
           "id":1749,
           "country_id":414,
           "code":"",
           "name":"Ḩawallī",
           "standard_timezone":"(UTC+3.0) Kuwait",
           "timezone":"Asia/Kuwait"
        },
        {
           "id":1750,
           "country_id":417,
           "code":"",
           "name":"Bishkek",
           "standard_timezone":"(UTC+6.0) Bishkek",
           "timezone":"Asia/Bishkek"
        },
        {
           "id":1751,
           "country_id":417,
           "code":"",
           "name":"Chüy",
           "standard_timezone":"(UTC+6.0) Bishkek",
           "timezone":"Asia/Bishkek"
        },
        {
           "id":1752,
           "country_id":417,
           "code":"",
           "name":"Jalal-Abad",
           "standard_timezone":"(UTC+6.0) Bishkek",
           "timezone":"Asia/Bishkek"
        },
        {
           "id":1753,
           "country_id":417,
           "code":"",
           "name":"Naryn",
           "standard_timezone":"(UTC+6.0) Bishkek",
           "timezone":"Asia/Bishkek"
        },
        {
           "id":1754,
           "country_id":417,
           "code":"",
           "name":"Talas",
           "standard_timezone":"(UTC+6.0) Bishkek",
           "timezone":"Asia/Bishkek"
        },
        {
           "id":1755,
           "country_id":417,
           "code":"",
           "name":"Ysyk-Köl",
           "standard_timezone":"(UTC+6.0) Bishkek",
           "timezone":"Asia/Bishkek"
        },
        {
           "id":1756,
           "country_id":417,
           "code":"",
           "name":"Osh",
           "standard_timezone":"(UTC+6.0) Bishkek",
           "timezone":"Asia/Bishkek"
        },
        {
           "id":1757,
           "country_id":417,
           "code":"",
           "name":"Batken",
           "standard_timezone":"(UTC+6.0) Bishkek",
           "timezone":"Asia/Bishkek"
        },
        {
           "id":1758,
           "country_id":418,
           "code":"",
           "name":"Attapu",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1759,
           "country_id":418,
           "code":"",
           "name":"Champasak",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1760,
           "country_id":418,
           "code":"",
           "name":"Houaphan",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1761,
           "country_id":418,
           "code":"",
           "name":"Khammouan",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1762,
           "country_id":418,
           "code":"",
           "name":"Louang Namtha",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1763,
           "country_id":418,
           "code":"",
           "name":"Louangphrabang",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1764,
           "country_id":418,
           "code":"",
           "name":"Oudômxai",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1765,
           "country_id":418,
           "code":"",
           "name":"Phongsali",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1766,
           "country_id":418,
           "code":"",
           "name":"Saravan",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1767,
           "country_id":418,
           "code":"",
           "name":"Savannakhet",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1768,
           "country_id":418,
           "code":"",
           "name":"Vientiane",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1769,
           "country_id":418,
           "code":"",
           "name":"Xiagnabouli",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1770,
           "country_id":418,
           "code":"",
           "name":"Xiangkhoang",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1771,
           "country_id":418,
           "code":"",
           "name":"Khammouan",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1772,
           "country_id":418,
           "code":"",
           "name":"Loungnamtha",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1773,
           "country_id":418,
           "code":"",
           "name":"Louangphabang",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1774,
           "country_id":418,
           "code":"",
           "name":"Phôngsali",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1775,
           "country_id":418,
           "code":"",
           "name":"Salavan",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1776,
           "country_id":418,
           "code":"",
           "name":"Savannahkhét",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1777,
           "country_id":418,
           "code":"",
           "name":"Bokèo",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1778,
           "country_id":418,
           "code":"",
           "name":"Bolikhamxai",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1779,
           "country_id":418,
           "code":"",
           "name":"Viangchan",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1780,
           "country_id":418,
           "code":"",
           "name":"Xékong",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1781,
           "country_id":418,
           "code":"",
           "name":"Viangchan",
           "standard_timezone":"(UTC+7.0) Vientiane",
           "timezone":"Asia/Vientiane"
        },
        {
           "id":1782,
           "country_id":422,
           "code":"",
           "name":"Béqaa",
           "standard_timezone":"(UTC+3.0) Beirut",
           "timezone":"Asia/Beirut"
        },
        {
           "id":1783,
           "country_id":422,
           "code":"",
           "name":"Liban-Nord",
           "standard_timezone":"(UTC+3.0) Beirut",
           "timezone":"Asia/Beirut"
        },
        {
           "id":1784,
           "country_id":422,
           "code":"",
           "name":"Beyrouth",
           "standard_timezone":"(UTC+3.0) Beirut",
           "timezone":"Asia/Beirut"
        },
        {
           "id":1785,
           "country_id":422,
           "code":"",
           "name":"Mont-Liban",
           "standard_timezone":"(UTC+3.0) Beirut",
           "timezone":"Asia/Beirut"
        },
        {
           "id":1786,
           "country_id":422,
           "code":"",
           "name":"Liban-Sud",
           "standard_timezone":"(UTC+3.0) Beirut",
           "timezone":"Asia/Beirut"
        },
        {
           "id":1787,
           "country_id":422,
           "code":"",
           "name":"Nabatîyé",
           "standard_timezone":"(UTC+3.0) Beirut",
           "timezone":"Asia/Beirut"
        },
        {
           "id":1788,
           "country_id":422,
           "code":"",
           "name":"Béqaa",
           "standard_timezone":"(UTC+3.0) Beirut",
           "timezone":"Asia/Beirut"
        },
        {
           "id":1789,
           "country_id":422,
           "code":"",
           "name":"Liban-Nord",
           "standard_timezone":"(UTC+3.0) Beirut",
           "timezone":"Asia/Beirut"
        },
        {
           "id":1790,
           "country_id":422,
           "code":"",
           "name":"Aakkâr",
           "standard_timezone":"(UTC+3.0) Beirut",
           "timezone":"Asia/Beirut"
        },
        {
           "id":1791,
           "country_id":422,
           "code":"",
           "name":"Baalbek-Hermel",
           "standard_timezone":"(UTC+3.0) Beirut",
           "timezone":"Asia/Beirut"
        },
        {
           "id":1792,
           "country_id":426,
           "code":"",
           "name":"Balzers Commune",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1793,
           "country_id":426,
           "code":"",
           "name":"Eschen Commune",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1794,
           "country_id":426,
           "code":"",
           "name":"Gamprin Commune",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1795,
           "country_id":426,
           "code":"",
           "name":"Mauren Commune",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1796,
           "country_id":426,
           "code":"",
           "name":"Planken Commune",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1797,
           "country_id":426,
           "code":"",
           "name":"Ruggell Commune",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1798,
           "country_id":426,
           "code":"",
           "name":"Berea District",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1799,
           "country_id":426,
           "code":"",
           "name":"Butha-Buthe District",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1800,
           "country_id":426,
           "code":"",
           "name":"Leribe District",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1801,
           "country_id":426,
           "code":"",
           "name":"Mafeteng",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1802,
           "country_id":426,
           "code":"",
           "name":"Maseru",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1803,
           "country_id":426,
           "code":"",
           "name":"Mohaleʼs Hoek",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1804,
           "country_id":426,
           "code":"",
           "name":"Mokhotlong",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1805,
           "country_id":426,
           "code":"",
           "name":"Qachaʼs Nek",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1806,
           "country_id":426,
           "code":"",
           "name":"Quthing District",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1807,
           "country_id":426,
           "code":"",
           "name":"Thaba-Tseka District",
           "standard_timezone":"(UTC+2.0) Maseru",
           "timezone":"Africa/Maseru"
        },
        {
           "id":1808,
           "country_id":428,
           "code":"",
           "name":"Dobeles Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1809,
           "country_id":428,
           "code":"",
           "name":"Aizkraukles Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1810,
           "country_id":428,
           "code":"",
           "name":"Alūksnes Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1811,
           "country_id":428,
           "code":"",
           "name":"Balvu Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1812,
           "country_id":428,
           "code":"",
           "name":"Bauskas Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1813,
           "country_id":428,
           "code":"",
           "name":"Cēsu Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1814,
           "country_id":428,
           "code":"",
           "name":"Daugavpils",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1815,
           "country_id":428,
           "code":"",
           "name":"Daugavpils Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1816,
           "country_id":428,
           "code":"",
           "name":"Dobeles Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1817,
           "country_id":428,
           "code":"",
           "name":"Gulbenes Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1818,
           "country_id":428,
           "code":"",
           "name":"Jēkabpils Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1819,
           "country_id":428,
           "code":"",
           "name":"Jelgava",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1820,
           "country_id":428,
           "code":"",
           "name":"Jelgavas Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1821,
           "country_id":428,
           "code":"",
           "name":"Jūrmala",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1822,
           "country_id":428,
           "code":"",
           "name":"Krāslavas Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1823,
           "country_id":428,
           "code":"",
           "name":"Kuldīgas Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1824,
           "country_id":428,
           "code":"",
           "name":"Liepāja",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1825,
           "country_id":428,
           "code":"",
           "name":"Liepājas Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1826,
           "country_id":428,
           "code":"",
           "name":"Limbažu Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1827,
           "country_id":428,
           "code":"",
           "name":"Ludzas Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1828,
           "country_id":428,
           "code":"",
           "name":"Madonas Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1829,
           "country_id":428,
           "code":"",
           "name":"Ogres Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1830,
           "country_id":428,
           "code":"",
           "name":"Preiļu Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1831,
           "country_id":428,
           "code":"",
           "name":"Rēzekne",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1832,
           "country_id":428,
           "code":"",
           "name":"Rēzeknes Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1833,
           "country_id":428,
           "code":"",
           "name":"Rīga",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1834,
           "country_id":428,
           "code":"",
           "name":"Rīgas Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1835,
           "country_id":428,
           "code":"",
           "name":"Saldus Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1836,
           "country_id":428,
           "code":"",
           "name":"Talsu Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1837,
           "country_id":428,
           "code":"",
           "name":"Tukuma Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1838,
           "country_id":428,
           "code":"",
           "name":"Valkas Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1839,
           "country_id":428,
           "code":"",
           "name":"Valmieras Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1840,
           "country_id":428,
           "code":"",
           "name":"Ventspils",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1841,
           "country_id":428,
           "code":"",
           "name":"Ventspils Rajons",
           "standard_timezone":"(UTC+3.0) Riga",
           "timezone":"Europe/Riga"
        },
        {
           "id":1842,
           "country_id":430,
           "code":"",
           "name":"Bong",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1843,
           "country_id":430,
           "code":"",
           "name":"Grand Jide",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1844,
           "country_id":430,
           "code":"",
           "name":"Grand Cape Mount",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1845,
           "country_id":430,
           "code":"",
           "name":"Lofa",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1846,
           "country_id":430,
           "code":"",
           "name":"Nimba",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1847,
           "country_id":430,
           "code":"",
           "name":"Sinoe",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1848,
           "country_id":430,
           "code":"",
           "name":"Grand Bassa County",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1849,
           "country_id":430,
           "code":"",
           "name":"Grand Cape Mount",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1850,
           "country_id":430,
           "code":"",
           "name":"Maryland",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1851,
           "country_id":430,
           "code":"",
           "name":"Montserrado",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1852,
           "country_id":430,
           "code":"",
           "name":"Bomi",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1853,
           "country_id":430,
           "code":"",
           "name":"Grand Kru",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1854,
           "country_id":430,
           "code":"",
           "name":"Margibi",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1855,
           "country_id":430,
           "code":"",
           "name":"River Cess",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1856,
           "country_id":430,
           "code":"",
           "name":"Grand Gedeh",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1857,
           "country_id":430,
           "code":"",
           "name":"Lofa",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1858,
           "country_id":430,
           "code":"",
           "name":"Gbarpolu",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1859,
           "country_id":430,
           "code":"",
           "name":"River Gee",
           "standard_timezone":"(UTC+0.0) Monrovia",
           "timezone":"Africa/Monrovia"
        },
        {
           "id":1860,
           "country_id":434,
           "code":"",
           "name":"Al Abyār",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1861,
           "country_id":434,
           "code":"",
           "name":"Al ‘Azīzīyah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1862,
           "country_id":434,
           "code":"",
           "name":"Al Bayḑā’",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1863,
           "country_id":434,
           "code":"",
           "name":"Al Jufrah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1864,
           "country_id":434,
           "code":"",
           "name":"Al Jumayl",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1865,
           "country_id":434,
           "code":"",
           "name":"Al Kufrah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1866,
           "country_id":434,
           "code":"",
           "name":"Al Marj",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1867,
           "country_id":434,
           "code":"",
           "name":"Al Qarābūll",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1868,
           "country_id":434,
           "code":"",
           "name":"Al Qubbah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1869,
           "country_id":434,
           "code":"",
           "name":"Al Ajaylāt",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1870,
           "country_id":434,
           "code":"",
           "name":"Ash Shāţiʼ",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1871,
           "country_id":434,
           "code":"",
           "name":"Az Zahra’",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1872,
           "country_id":434,
           "code":"",
           "name":"Banī Walīd",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1873,
           "country_id":434,
           "code":"",
           "name":"Bin Jawwād",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1874,
           "country_id":434,
           "code":"",
           "name":"Ghāt",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1875,
           "country_id":434,
           "code":"",
           "name":"Jādū",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1876,
           "country_id":434,
           "code":"",
           "name":"Jālū",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1877,
           "country_id":434,
           "code":"",
           "name":"Janzūr",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1878,
           "country_id":434,
           "code":"",
           "name":"Masallatah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1879,
           "country_id":434,
           "code":"",
           "name":"Mizdah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1880,
           "country_id":434,
           "code":"",
           "name":"Murzuq",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1881,
           "country_id":434,
           "code":"",
           "name":"Nālūt",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1882,
           "country_id":434,
           "code":"",
           "name":"Qamīnis",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1883,
           "country_id":434,
           "code":"",
           "name":"Qaşr Bin Ghashīr",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1884,
           "country_id":434,
           "code":"",
           "name":"Sabhā",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1885,
           "country_id":434,
           "code":"",
           "name":"Şabrātah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1886,
           "country_id":434,
           "code":"",
           "name":"Shaḩḩāt",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1887,
           "country_id":434,
           "code":"",
           "name":"Şurmān",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1888,
           "country_id":434,
           "code":"",
           "name":"Tajura’ ",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1889,
           "country_id":434,
           "code":"",
           "name":"Tarhūnah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1890,
           "country_id":434,
           "code":"",
           "name":"Ţubruq",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1891,
           "country_id":434,
           "code":"",
           "name":"Tūkrah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1892,
           "country_id":434,
           "code":"",
           "name":"Zlīţan",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1893,
           "country_id":434,
           "code":"",
           "name":"Zuwārah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1894,
           "country_id":434,
           "code":"",
           "name":"Ajdābiyā",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1895,
           "country_id":434,
           "code":"",
           "name":"Al Fātiḩ",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1896,
           "country_id":434,
           "code":"",
           "name":"Al Jabal al Akhḑar",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1897,
           "country_id":434,
           "code":"",
           "name":"Al Khums",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1898,
           "country_id":434,
           "code":"",
           "name":"An Nuqāţ al Khams",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1899,
           "country_id":434,
           "code":"",
           "name":"Awbārī",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1900,
           "country_id":434,
           "code":"",
           "name":"Az Zāwiyah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1901,
           "country_id":434,
           "code":"",
           "name":"Banghāzī",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1902,
           "country_id":434,
           "code":"",
           "name":"Darnah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1903,
           "country_id":434,
           "code":"",
           "name":"Ghadāmis",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1904,
           "country_id":434,
           "code":"",
           "name":"Gharyān",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1905,
           "country_id":434,
           "code":"",
           "name":"Mişrātah",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1906,
           "country_id":434,
           "code":"",
           "name":"Sawfajjīn",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1907,
           "country_id":434,
           "code":"",
           "name":"Surt",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1908,
           "country_id":434,
           "code":"",
           "name":"Ţarābulus",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1909,
           "country_id":434,
           "code":"",
           "name":"Yafran",
           "standard_timezone":"(UTC+2.0) Tripoli",
           "timezone":"Africa/Tripoli"
        },
        {
           "id":1910,
           "country_id":438,
           "code":"",
           "name":"Balzers",
           "standard_timezone":"(UTC+2.0) Vaduz",
           "timezone":"Europe/Vaduz"
        },
        {
           "id":1911,
           "country_id":438,
           "code":"",
           "name":"Eschen",
           "standard_timezone":"(UTC+2.0) Vaduz",
           "timezone":"Europe/Vaduz"
        },
        {
           "id":1912,
           "country_id":438,
           "code":"",
           "name":"Gamprin",
           "standard_timezone":"(UTC+2.0) Vaduz",
           "timezone":"Europe/Vaduz"
        },
        {
           "id":1913,
           "country_id":438,
           "code":"",
           "name":"Mauren",
           "standard_timezone":"(UTC+2.0) Vaduz",
           "timezone":"Europe/Vaduz"
        },
        {
           "id":1914,
           "country_id":438,
           "code":"",
           "name":"Planken",
           "standard_timezone":"(UTC+2.0) Vaduz",
           "timezone":"Europe/Vaduz"
        },
        {
           "id":1915,
           "country_id":438,
           "code":"",
           "name":"Ruggell",
           "standard_timezone":"(UTC+2.0) Vaduz",
           "timezone":"Europe/Vaduz"
        },
        {
           "id":1916,
           "country_id":438,
           "code":"",
           "name":"Schaan",
           "standard_timezone":"(UTC+2.0) Vaduz",
           "timezone":"Europe/Vaduz"
        },
        {
           "id":1917,
           "country_id":438,
           "code":"",
           "name":"Schellenberg",
           "standard_timezone":"(UTC+2.0) Vaduz",
           "timezone":"Europe/Vaduz"
        },
        {
           "id":1918,
           "country_id":438,
           "code":"",
           "name":"Triesen",
           "standard_timezone":"(UTC+2.0) Vaduz",
           "timezone":"Europe/Vaduz"
        },
        {
           "id":1919,
           "country_id":438,
           "code":"",
           "name":"Triesenberg",
           "standard_timezone":"(UTC+2.0) Vaduz",
           "timezone":"Europe/Vaduz"
        },
        {
           "id":1920,
           "country_id":438,
           "code":"",
           "name":"Vaduz",
           "standard_timezone":"(UTC+2.0) Vaduz",
           "timezone":"Europe/Vaduz"
        },
        {
           "id":1921,
           "country_id":440,
           "code":"",
           "name":"Alytaus Apskritis",
           "standard_timezone":"(UTC+3.0) Vilnius",
           "timezone":"Europe/Vilnius"
        },
        {
           "id":1922,
           "country_id":440,
           "code":"",
           "name":"Kauno Apskritis",
           "standard_timezone":"(UTC+3.0) Vilnius",
           "timezone":"Europe/Vilnius"
        },
        {
           "id":1923,
           "country_id":440,
           "code":"",
           "name":"Klaipėdos Apskritis",
           "standard_timezone":"(UTC+3.0) Vilnius",
           "timezone":"Europe/Vilnius"
        },
        {
           "id":1924,
           "country_id":440,
           "code":"",
           "name":"Marijampolės Apskritis",
           "standard_timezone":"(UTC+3.0) Vilnius",
           "timezone":"Europe/Vilnius"
        },
        {
           "id":1925,
           "country_id":440,
           "code":"",
           "name":"Panevėžio Apskritis",
           "standard_timezone":"(UTC+3.0) Vilnius",
           "timezone":"Europe/Vilnius"
        },
        {
           "id":1926,
           "country_id":440,
           "code":"",
           "name":"Šiaulių Apskritis",
           "standard_timezone":"(UTC+3.0) Vilnius",
           "timezone":"Europe/Vilnius"
        },
        {
           "id":1927,
           "country_id":440,
           "code":"",
           "name":"Tauragės Apskritis",
           "standard_timezone":"(UTC+3.0) Vilnius",
           "timezone":"Europe/Vilnius"
        },
        {
           "id":1928,
           "country_id":440,
           "code":"",
           "name":"Telšių Apskritis",
           "standard_timezone":"(UTC+3.0) Vilnius",
           "timezone":"Europe/Vilnius"
        },
        {
           "id":1929,
           "country_id":440,
           "code":"",
           "name":"Utenos Apskritis",
           "standard_timezone":"(UTC+3.0) Vilnius",
           "timezone":"Europe/Vilnius"
        },
        {
           "id":1930,
           "country_id":440,
           "code":"",
           "name":"Vilniaus Apskritis",
           "standard_timezone":"(UTC+3.0) Vilnius",
           "timezone":"Europe/Vilnius"
        },
        {
           "id":1931,
           "country_id":442,
           "code":"",
           "name":"Diekirch",
           "standard_timezone":"(UTC+2.0) Luxembourg",
           "timezone":"Europe/Luxembourg"
        },
        {
           "id":1932,
           "country_id":442,
           "code":"",
           "name":"Grevenmacher",
           "standard_timezone":"(UTC+2.0) Luxembourg",
           "timezone":"Europe/Luxembourg"
        },
        {
           "id":1933,
           "country_id":442,
           "code":"",
           "name":"Luxembourg",
           "standard_timezone":"(UTC+2.0) Luxembourg",
           "timezone":"Europe/Luxembourg"
        },
        {
           "id":1934,
           "country_id":446,
           "code":"",
           "name":"Ilhas",
           "standard_timezone":"(UTC-3.0) Macau",
           "timezone":"Asia/Macau"
        },
        {
           "id":1935,
           "country_id":446,
           "code":"",
           "name":"Macau",
           "standard_timezone":"(UTC-3.0) Macau",
           "timezone":"Asia/Macau"
        },
        {
           "id":1936,
           "country_id":450,
           "code":"",
           "name":"Antsiranana",
           "standard_timezone":"(UTC+3.0) Antananarivo",
           "timezone":"Indian/Antananarivo"
        },
        {
           "id":1937,
           "country_id":450,
           "code":"",
           "name":"Fianarantsoa",
           "standard_timezone":"(UTC+3.0) Antananarivo",
           "timezone":"Indian/Antananarivo"
        },
        {
           "id":1938,
           "country_id":450,
           "code":"",
           "name":"Mahajanga",
           "standard_timezone":"(UTC+3.0) Antananarivo",
           "timezone":"Indian/Antananarivo"
        },
        {
           "id":1939,
           "country_id":450,
           "code":"",
           "name":"Toamasina",
           "standard_timezone":"(UTC+3.0) Antananarivo",
           "timezone":"Indian/Antananarivo"
        },
        {
           "id":1940,
           "country_id":450,
           "code":"",
           "name":"Antananarivo",
           "standard_timezone":"(UTC+3.0) Antananarivo",
           "timezone":"Indian/Antananarivo"
        },
        {
           "id":1941,
           "country_id":450,
           "code":"",
           "name":"Toliara",
           "standard_timezone":"(UTC+3.0) Antananarivo",
           "timezone":"Indian/Antananarivo"
        },
        {
           "id":1942,
           "country_id":454,
           "code":"",
           "name":"Chikwawa",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1943,
           "country_id":454,
           "code":"",
           "name":"Chiradzulu",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1944,
           "country_id":454,
           "code":"",
           "name":"Chitipa",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1945,
           "country_id":454,
           "code":"",
           "name":"Thyolo",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1946,
           "country_id":454,
           "code":"",
           "name":"Dedza",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1947,
           "country_id":454,
           "code":"",
           "name":"Dowa",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1948,
           "country_id":454,
           "code":"",
           "name":"Karonga",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1949,
           "country_id":454,
           "code":"",
           "name":"Kasungu",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1950,
           "country_id":454,
           "code":"",
           "name":"Lilongwe",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1951,
           "country_id":454,
           "code":"",
           "name":"Mangochi",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1952,
           "country_id":454,
           "code":"",
           "name":"Mchinji",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1953,
           "country_id":454,
           "code":"",
           "name":"Mzimba",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1954,
           "country_id":454,
           "code":"",
           "name":"Ntcheu",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1955,
           "country_id":454,
           "code":"",
           "name":"Nkhata Bay",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1956,
           "country_id":454,
           "code":"",
           "name":"Nkhotakota",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1957,
           "country_id":454,
           "code":"",
           "name":"Nsanje",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1958,
           "country_id":454,
           "code":"",
           "name":"Ntchisi",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1959,
           "country_id":454,
           "code":"",
           "name":"Rumphi",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1960,
           "country_id":454,
           "code":"",
           "name":"Salima",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1961,
           "country_id":454,
           "code":"",
           "name":"Zomba",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1962,
           "country_id":454,
           "code":"",
           "name":"Blantyre",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1963,
           "country_id":454,
           "code":"",
           "name":"Mwanza",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1964,
           "country_id":454,
           "code":"",
           "name":"Balaka",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1965,
           "country_id":454,
           "code":"",
           "name":"Likoma",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1966,
           "country_id":454,
           "code":"",
           "name":"Machinga",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1967,
           "country_id":454,
           "code":"",
           "name":"Mulanje",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1968,
           "country_id":454,
           "code":"",
           "name":"Phalombe",
           "standard_timezone":"(UTC+2.0) Blantyre",
           "timezone":"Africa/Blantyre"
        },
        {
           "id":1969,
           "country_id":458,
           "code":"",
           "name":"Johor",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1970,
           "country_id":458,
           "code":"",
           "name":"Kedah",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1971,
           "country_id":458,
           "code":"",
           "name":"Kelantan",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1972,
           "country_id":458,
           "code":"",
           "name":"Melaka",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1973,
           "country_id":458,
           "code":"",
           "name":"Negeri Sembilan",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1974,
           "country_id":458,
           "code":"",
           "name":"Pahang",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1975,
           "country_id":458,
           "code":"",
           "name":"Perak",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1976,
           "country_id":458,
           "code":"",
           "name":"Perlis",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1977,
           "country_id":458,
           "code":"",
           "name":"Pulau Pinang",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1978,
           "country_id":458,
           "code":"",
           "name":"Sarawak",
           "standard_timezone":"(UTC+8.0) Kuching",
           "timezone":"Asia/Kuching"
        },
        {
           "id":1979,
           "country_id":458,
           "code":"",
           "name":"Selangor",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1980,
           "country_id":458,
           "code":"",
           "name":"Terengganu",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1981,
           "country_id":458,
           "code":"",
           "name":"Kuala Lumpur",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1982,
           "country_id":458,
           "code":"",
           "name":"Federal Territory of Labuan",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1983,
           "country_id":458,
           "code":"",
           "name":"Sabah",
           "standard_timezone":"(UTC+8.0) Kuching",
           "timezone":"Asia/Kuching"
        },
        {
           "id":1984,
           "country_id":458,
           "code":"",
           "name":"Putrajaya",
           "standard_timezone":"(UTC+8:00) Kuala Lumpur, Singapore",
           "timezone":"Asia/Kuala_Lumpur"
        },
        {
           "id":1985,
           "country_id":462,
           "code":"",
           "name":"Maale",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1986,
           "country_id":462,
           "code":"",
           "name":"Seenu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1987,
           "country_id":462,
           "code":"",
           "name":"Alifu Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1988,
           "country_id":462,
           "code":"",
           "name":"Lhaviyani Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1989,
           "country_id":462,
           "code":"",
           "name":"Vaavu Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1990,
           "country_id":462,
           "code":"",
           "name":"Laamu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1991,
           "country_id":462,
           "code":"",
           "name":"Haa Alifu Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1992,
           "country_id":462,
           "code":"",
           "name":"Thaa Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1993,
           "country_id":462,
           "code":"",
           "name":"Meemu Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1994,
           "country_id":462,
           "code":"",
           "name":"Raa Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1995,
           "country_id":462,
           "code":"",
           "name":"Faafu Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1996,
           "country_id":462,
           "code":"",
           "name":"Dhaalu Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1997,
           "country_id":462,
           "code":"",
           "name":"Baa Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1998,
           "country_id":462,
           "code":"",
           "name":"Haa Dhaalu Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":1999,
           "country_id":462,
           "code":"",
           "name":"Shaviyani Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2000,
           "country_id":462,
           "code":"",
           "name":"Noonu Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2001,
           "country_id":462,
           "code":"",
           "name":"Kaafu Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2002,
           "country_id":462,
           "code":"",
           "name":"Gaafu Alifu Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2003,
           "country_id":462,
           "code":"",
           "name":"Gaafu Dhaalu Atholhu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2004,
           "country_id":462,
           "code":"",
           "name":"Gnyaviyani Atoll",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2005,
           "country_id":462,
           "code":"",
           "name":"Alifu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2006,
           "country_id":462,
           "code":"",
           "name":"Baa",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2007,
           "country_id":462,
           "code":"",
           "name":"Dhaalu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2008,
           "country_id":462,
           "code":"",
           "name":"Faafu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2009,
           "country_id":462,
           "code":"",
           "name":"Gaafu Alifu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2010,
           "country_id":462,
           "code":"",
           "name":"Gaafu Dhaalu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2011,
           "country_id":462,
           "code":"",
           "name":"Haa Alifu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2012,
           "country_id":462,
           "code":"",
           "name":"Haa Dhaalu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2013,
           "country_id":462,
           "code":"",
           "name":"Kaafu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2014,
           "country_id":462,
           "code":"",
           "name":"Lhaviyani",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2015,
           "country_id":462,
           "code":"",
           "name":"Maale",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2016,
           "country_id":462,
           "code":"",
           "name":"Meemu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2017,
           "country_id":462,
           "code":"",
           "name":"Gnaviyani",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2018,
           "country_id":462,
           "code":"",
           "name":"Noonu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2019,
           "country_id":462,
           "code":"",
           "name":"Raa",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2020,
           "country_id":462,
           "code":"",
           "name":"Shaviyani",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2021,
           "country_id":462,
           "code":"",
           "name":"Thaa",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2022,
           "country_id":462,
           "code":"",
           "name":"Vaavu",
           "standard_timezone":"(UTC+5.0) Maldives",
           "timezone":"Indian/Maldives"
        },
        {
           "id":2023,
           "country_id":466,
           "code":"",
           "name":"Bamako",
           "standard_timezone":"(UTC+0.0) Bamako",
           "timezone":"Africa/Bamako"
        },
        {
           "id":2024,
           "country_id":466,
           "code":"",
           "name":"Gao",
           "standard_timezone":"(UTC+0.0) Bamako",
           "timezone":"Africa/Bamako"
        },
        {
           "id":2025,
           "country_id":466,
           "code":"",
           "name":"Kayes",
           "standard_timezone":"(UTC+0.0) Bamako",
           "timezone":"Africa/Bamako"
        },
        {
           "id":2026,
           "country_id":466,
           "code":"",
           "name":"Mopti",
           "standard_timezone":"(UTC+0.0) Bamako",
           "timezone":"Africa/Bamako"
        },
        {
           "id":2027,
           "country_id":466,
           "code":"",
           "name":"Ségou",
           "standard_timezone":"(UTC+0.0) Bamako",
           "timezone":"Africa/Bamako"
        },
        {
           "id":2028,
           "country_id":466,
           "code":"",
           "name":"Sikasso",
           "standard_timezone":"(UTC+0.0) Bamako",
           "timezone":"Africa/Bamako"
        },
        {
           "id":2029,
           "country_id":466,
           "code":"",
           "name":"Koulikoro",
           "standard_timezone":"(UTC+0.0) Bamako",
           "timezone":"Africa/Bamako"
        },
        {
           "id":2030,
           "country_id":466,
           "code":"",
           "name":"Tombouctou",
           "standard_timezone":"(UTC+0.0) Bamako",
           "timezone":"Africa/Bamako"
        },
        {
           "id":2031,
           "country_id":466,
           "code":"",
           "name":"Gao",
           "standard_timezone":"(UTC+0.0) Bamako",
           "timezone":"Africa/Bamako"
        },
        {
           "id":2032,
           "country_id":466,
           "code":"",
           "name":"Kidal",
           "standard_timezone":"(UTC+0.0) Bamako",
           "timezone":"Africa/Bamako"
        },
        {
           "id":2033,
           "country_id":470,
           "code":"",
           "name":"Malta",
           "standard_timezone":"(UTC+2.0) Malta",
           "timezone":"Europe/Malta"
        },
        {
           "id":2034,
           "country_id":474,
           "code":"",
           "name":"Martinique",
           "standard_timezone":"(UTC-4.0) Martinique",
           "timezone":"America/Martinique"
        },
        {
           "id":2035,
           "country_id":478,
           "code":"",
           "name":"Nouakchott",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2036,
           "country_id":478,
           "code":"",
           "name":"Hodh Ech Chargui",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2037,
           "country_id":478,
           "code":"",
           "name":"Hodh El Gharbi",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2038,
           "country_id":478,
           "code":"",
           "name":"Assaba",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2039,
           "country_id":478,
           "code":"",
           "name":"Gorgol",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2040,
           "country_id":478,
           "code":"",
           "name":"Brakna",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2041,
           "country_id":478,
           "code":"",
           "name":"Trarza",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2042,
           "country_id":478,
           "code":"",
           "name":"Adrar",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2043,
           "country_id":478,
           "code":"",
           "name":"Dakhlet Nouadhibou",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2044,
           "country_id":478,
           "code":"",
           "name":"Tagant",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2045,
           "country_id":478,
           "code":"",
           "name":"Guidimaka",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2046,
           "country_id":478,
           "code":"",
           "name":"Tiris Zemmour",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2047,
           "country_id":478,
           "code":"",
           "name":"Inchiri",
           "standard_timezone":"(UTC+0.0) Nouakchott",
           "timezone":"Africa/Nouakchott"
        },
        {
           "id":2048,
           "country_id":480,
           "code":"",
           "name":"Black River",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2049,
           "country_id":480,
           "code":"",
           "name":"Flacq",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2050,
           "country_id":480,
           "code":"",
           "name":"Grand Port",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2051,
           "country_id":480,
           "code":"",
           "name":"Moka",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2052,
           "country_id":480,
           "code":"",
           "name":"Pamplemousses",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2053,
           "country_id":480,
           "code":"",
           "name":"Plaines Wilhems",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2054,
           "country_id":480,
           "code":"",
           "name":"Port Louis",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2055,
           "country_id":480,
           "code":"",
           "name":"Rivière du Rempart",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2056,
           "country_id":480,
           "code":"",
           "name":"Savanne",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2057,
           "country_id":480,
           "code":"",
           "name":"Agalega Islands",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2058,
           "country_id":480,
           "code":"",
           "name":"Cargados Carajos",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2059,
           "country_id":480,
           "code":"",
           "name":"Rodrigues",
           "standard_timezone":"(UTC+4.0) Mauritius",
           "timezone":"Indian/Mauritius"
        },
        {
           "id":2060,
           "country_id":484,
           "code":"",
           "name":"Aguascalientes",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2061,
           "country_id":484,
           "code":"",
           "name":"Baja California",
           "standard_timezone":"(UTC-7.0) Tijuana",
           "timezone":"America/Tijuana"
        },
        {
           "id":2062,
           "country_id":484,
           "code":"",
           "name":"Baja California Sur",
           "standard_timezone":"(UTC-6.0) Mazatlan",
           "timezone":"America/Mazatlan"
        },
        {
           "id":2063,
           "country_id":484,
           "code":"",
           "name":"Campeche",
           "standard_timezone":"(UTC-5.0) Merida",
           "timezone":"America/Merida"
        },
        {
           "id":2064,
           "country_id":484,
           "code":"",
           "name":"Chiapas",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2065,
           "country_id":484,
           "code":"",
           "name":"Chihuahua",
           "standard_timezone":"(UTC-6.0) Chihuahua",
           "timezone":"America/Chihuahua"
        },
        {
           "id":2066,
           "country_id":484,
           "code":"",
           "name":"Coahuila",
           "standard_timezone":"(UTC-5.0) Monterrey",
           "timezone":"America/Monterrey"
        },
        {
           "id":2067,
           "country_id":484,
           "code":"",
           "name":"Colima",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2068,
           "country_id":484,
           "code":"",
           "name":"The Federal District",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2069,
           "country_id":484,
           "code":"",
           "name":"Durango",
           "standard_timezone":"(UTC-5.0) Monterrey",
           "timezone":"America/Monterrey"
        },
        {
           "id":2070,
           "country_id":484,
           "code":"",
           "name":"Guanajuato",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2071,
           "country_id":484,
           "code":"",
           "name":"Guerrero",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2072,
           "country_id":484,
           "code":"",
           "name":"Hidalgo",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2073,
           "country_id":484,
           "code":"",
           "name":"Jalisco",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2074,
           "country_id":484,
           "code":"",
           "name":"México",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2075,
           "country_id":484,
           "code":"",
           "name":"Michoacán",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2076,
           "country_id":484,
           "code":"",
           "name":"Morelos",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2077,
           "country_id":484,
           "code":"",
           "name":"Nayarit",
           "standard_timezone":"(UTC-6.0) Mazatlan",
           "timezone":"America/Mazatlan"
        },
        {
           "id":2078,
           "country_id":484,
           "code":"",
           "name":"Nuevo León",
           "standard_timezone":"(UTC-5.0) Monterrey",
           "timezone":"America/Monterrey"
        },
        {
           "id":2079,
           "country_id":484,
           "code":"",
           "name":"Oaxaca",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2080,
           "country_id":484,
           "code":"",
           "name":"Puebla",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2081,
           "country_id":484,
           "code":"",
           "name":"Querétaro",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2082,
           "country_id":484,
           "code":"",
           "name":"Quintana Roo",
           "standard_timezone":"(UTC-5.0) Cancun",
           "timezone":"America/Cancun"
        },
        {
           "id":2083,
           "country_id":484,
           "code":"",
           "name":"San Luis Potosí",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2084,
           "country_id":484,
           "code":"",
           "name":"Sinaloa",
           "standard_timezone":"(UTC-6.0) Mazatlan",
           "timezone":"America/Mazatlan"
        },
        {
           "id":2085,
           "country_id":484,
           "code":"",
           "name":"Sonora",
           "standard_timezone":"(UTC-7.0) Hermosillo",
           "timezone":"America/Hermosillo"
        },
        {
           "id":2086,
           "country_id":484,
           "code":"",
           "name":"Tabasco",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2087,
           "country_id":484,
           "code":"",
           "name":"Tamaulipas",
           "standard_timezone":"(UTC-5.0) Monterrey",
           "timezone":"America/Monterrey"
        },
        {
           "id":2088,
           "country_id":484,
           "code":"",
           "name":"Tlaxcala",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2089,
           "country_id":484,
           "code":"",
           "name":"Veracruz-Llave",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2090,
           "country_id":484,
           "code":"",
           "name":"Yucatán",
           "standard_timezone":"(UTC-5.0) Merida",
           "timezone":"America/Merida"
        },
        {
           "id":2091,
           "country_id":484,
           "code":"",
           "name":"Zacatecas",
           "standard_timezone":"(UTC-5.0) Mexico_City",
           "timezone":"America/Mexico_City"
        },
        {
           "id":2092,
           "country_id":492,
           "code":"",
           "name":"Monaco",
           "standard_timezone":"(UTC+2.0) Monaco",
           "timezone":"Europe/Monaco"
        },
        {
           "id":2093,
           "country_id":496,
           "code":"",
           "name":"Arhangay",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2094,
           "country_id":496,
           "code":"",
           "name":"Bayanhongor",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2095,
           "country_id":496,
           "code":"",
           "name":"Bayan-Ölgiy",
           "standard_timezone":"(UTC+8.0) Hovd",
           "timezone":"Asia/Hovd"
        },
        {
           "id":2096,
           "country_id":496,
           "code":"",
           "name":"East Aimak",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2097,
           "country_id":496,
           "code":"",
           "name":"East Gobi Aymag",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2098,
           "country_id":496,
           "code":"",
           "name":"Middle Govĭ",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2099,
           "country_id":496,
           "code":"",
           "name":"Dzavhan",
           "standard_timezone":"(UTC+8.0) Hovd",
           "timezone":"Asia/Hovd"
        },
        {
           "id":2100,
           "country_id":496,
           "code":"",
           "name":"Govĭ-Altay",
           "standard_timezone":"(UTC+8.0) Hovd",
           "timezone":"Asia/Hovd"
        },
        {
           "id":2101,
           "country_id":496,
           "code":"",
           "name":"Hentiy",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2102,
           "country_id":496,
           "code":"",
           "name":"Hovd",
           "standard_timezone":"(UTC+8.0) Hovd",
           "timezone":"Asia/Hovd"
        },
        {
           "id":2103,
           "country_id":496,
           "code":"",
           "name":"Hövsgöl",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2104,
           "country_id":496,
           "code":"",
           "name":"South Gobi Aimak",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2105,
           "country_id":496,
           "code":"",
           "name":"South Hangay",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2106,
           "country_id":496,
           "code":"",
           "name":"Selenge",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2107,
           "country_id":496,
           "code":"",
           "name":"Sühbaatar",
           "standard_timezone":"(UTC+8.0) Choibalsan",
           "timezone":"Asia/Choibalsan"
        },
        {
           "id":2108,
           "country_id":496,
           "code":"",
           "name":"Central Aimak",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2109,
           "country_id":496,
           "code":"",
           "name":"Uvs",
           "standard_timezone":"(UTC+8.0) Hovd",
           "timezone":"Asia/Hovd"
        },
        {
           "id":2110,
           "country_id":496,
           "code":"",
           "name":"Ulaanbaatar",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2111,
           "country_id":496,
           "code":"",
           "name":"Bulgan",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2112,
           "country_id":496,
           "code":"",
           "name":"Darhan Uul",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2113,
           "country_id":496,
           "code":"",
           "name":"Govĭ-Sumber",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2114,
           "country_id":496,
           "code":"",
           "name":"Orhon",
           "standard_timezone":"(UTC+8.0) Ulaanbaatar",
           "timezone":"Asia/Ulaanbaatar"
        },
        {
           "id":2115,
           "country_id":498,
           "code":"",
           "name":"Ungheni Judetul",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2116,
           "country_id":498,
           "code":"",
           "name":"Balti",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2117,
           "country_id":498,
           "code":"",
           "name":"Cahul",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2118,
           "country_id":498,
           "code":"",
           "name":"Stinga Nistrului",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2119,
           "country_id":498,
           "code":"",
           "name":"Edinet",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2120,
           "country_id":498,
           "code":"",
           "name":"Găgăuzia",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2121,
           "country_id":498,
           "code":"",
           "name":"Lapusna",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2122,
           "country_id":498,
           "code":"",
           "name":"Orhei",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2123,
           "country_id":498,
           "code":"",
           "name":"Soroca",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2124,
           "country_id":498,
           "code":"",
           "name":"Tighina",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2125,
           "country_id":498,
           "code":"",
           "name":"Ungheni",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2126,
           "country_id":498,
           "code":"",
           "name":"Chişinău",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2127,
           "country_id":498,
           "code":"",
           "name":"Stînga Nistrului",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2128,
           "country_id":498,
           "code":"",
           "name":"Raionul Anenii Noi",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2129,
           "country_id":498,
           "code":"",
           "name":"Bălţi",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2130,
           "country_id":498,
           "code":"",
           "name":"Raionul Basarabeasca",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2131,
           "country_id":498,
           "code":"",
           "name":"Bender",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2132,
           "country_id":498,
           "code":"",
           "name":"Raionul Briceni",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2133,
           "country_id":498,
           "code":"",
           "name":"Raionul Cahul",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2134,
           "country_id":498,
           "code":"",
           "name":"Raionul Cantemir",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2135,
           "country_id":498,
           "code":"",
           "name":"Călăraşi",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2136,
           "country_id":498,
           "code":"",
           "name":"Căuşeni",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2137,
           "country_id":498,
           "code":"",
           "name":"Raionul Cimişlia",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2138,
           "country_id":498,
           "code":"",
           "name":"Raionul Criuleni",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2139,
           "country_id":498,
           "code":"",
           "name":"Raionul Donduşeni",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2140,
           "country_id":498,
           "code":"",
           "name":"Raionul Drochia",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2141,
           "country_id":498,
           "code":"",
           "name":"Dubăsari",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2142,
           "country_id":498,
           "code":"",
           "name":"Raionul Edineţ",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2143,
           "country_id":498,
           "code":"",
           "name":"Raionul Făleşti",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2144,
           "country_id":498,
           "code":"",
           "name":"Raionul Floreşti",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2145,
           "country_id":498,
           "code":"",
           "name":"Raionul Glodeni",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2146,
           "country_id":498,
           "code":"",
           "name":"Raionul Hînceşti",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2147,
           "country_id":498,
           "code":"",
           "name":"Raionul Ialoveni",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2148,
           "country_id":498,
           "code":"",
           "name":"Raionul Leova",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2149,
           "country_id":498,
           "code":"",
           "name":"Raionul Nisporeni",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2150,
           "country_id":498,
           "code":"",
           "name":"Raionul Ocniţa",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2151,
           "country_id":498,
           "code":"",
           "name":"Raionul Orhei",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2152,
           "country_id":498,
           "code":"",
           "name":"Raionul Rezina",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2153,
           "country_id":498,
           "code":"",
           "name":"Raionul Rîşcani",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2154,
           "country_id":498,
           "code":"",
           "name":"Raionul Sîngerei",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2155,
           "country_id":498,
           "code":"",
           "name":"Raionul Şoldăneşti",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2156,
           "country_id":498,
           "code":"",
           "name":"Raionul Soroca",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2157,
           "country_id":498,
           "code":"",
           "name":"Ştefan-Vodă",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2158,
           "country_id":498,
           "code":"",
           "name":"Raionul Străşeni",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2159,
           "country_id":498,
           "code":"",
           "name":"Raionul Taraclia",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2160,
           "country_id":498,
           "code":"",
           "name":"Raionul Teleneşti",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2161,
           "country_id":498,
           "code":"",
           "name":"Raionul Ungheni",
           "standard_timezone":"(UTC+3.0) Chisinau",
           "timezone":"Europe/Chisinau"
        },
        {
           "id":2162,
           "country_id":499,
           "code":"",
           "name":"Opština Andrijevica",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2163,
           "country_id":499,
           "code":"",
           "name":"Opština Bar",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2164,
           "country_id":499,
           "code":"",
           "name":"Opština Berane",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2165,
           "country_id":499,
           "code":"",
           "name":"Opština Bijelo Polje",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2166,
           "country_id":499,
           "code":"",
           "name":"Opština Budva",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2167,
           "country_id":499,
           "code":"",
           "name":"Opština Cetinje",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2168,
           "country_id":499,
           "code":"",
           "name":"Opština Danilovgrad",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2169,
           "country_id":499,
           "code":"",
           "name":"Opština Herceg Novi",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2170,
           "country_id":499,
           "code":"",
           "name":"Opština Kolašin",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2171,
           "country_id":499,
           "code":"",
           "name":"Opština Kotor",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2172,
           "country_id":499,
           "code":"",
           "name":"Opština Mojkovac",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2173,
           "country_id":499,
           "code":"",
           "name":"Opština Nikšić",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2174,
           "country_id":499,
           "code":"",
           "name":"Opština Plav",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2175,
           "country_id":499,
           "code":"",
           "name":"Opština Pljevlja",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2176,
           "country_id":499,
           "code":"",
           "name":"Opština Plužine",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2177,
           "country_id":499,
           "code":"",
           "name":"Opština Podgorica",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2178,
           "country_id":499,
           "code":"",
           "name":"Opština Rožaje",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2179,
           "country_id":499,
           "code":"",
           "name":"Opština Šavnik",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2180,
           "country_id":499,
           "code":"",
           "name":"Opština Tivat",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2181,
           "country_id":499,
           "code":"",
           "name":"Opština Ulcinj",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2182,
           "country_id":499,
           "code":"",
           "name":"Opština Žabljak",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":2183,
           "country_id":500,
           "code":"",
           "name":"Saint Anthony",
           "standard_timezone":"(UTC-4.0) Montserrat",
           "timezone":"America/Montserrat"
        },
        {
           "id":2184,
           "country_id":500,
           "code":"",
           "name":"Saint Georges",
           "standard_timezone":"(UTC-4.0) Montserrat",
           "timezone":"America/Montserrat"
        },
        {
           "id":2185,
           "country_id":500,
           "code":"",
           "name":"Saint Peter",
           "standard_timezone":"(UTC-4.0) Montserrat",
           "timezone":"America/Montserrat"
        },
        {
           "id":2186,
           "country_id":504,
           "code":"",
           "name":"Agadir",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2187,
           "country_id":504,
           "code":"",
           "name":"Al Hoceïma",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2188,
           "country_id":504,
           "code":"",
           "name":"Azizal",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2189,
           "country_id":504,
           "code":"",
           "name":"Ben Slimane",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2190,
           "country_id":504,
           "code":"",
           "name":"Beni Mellal",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2191,
           "country_id":504,
           "code":"",
           "name":"Boulemane",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2192,
           "country_id":504,
           "code":"",
           "name":"Casablanca",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2193,
           "country_id":504,
           "code":"",
           "name":"Chaouen",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2194,
           "country_id":504,
           "code":"",
           "name":"El Jadida",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2195,
           "country_id":504,
           "code":"",
           "name":"El Kelaa des Srarhna",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2196,
           "country_id":504,
           "code":"",
           "name":"Er Rachidia",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2197,
           "country_id":504,
           "code":"",
           "name":"Essaouira",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2198,
           "country_id":504,
           "code":"",
           "name":"Fes",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2199,
           "country_id":504,
           "code":"",
           "name":"Figuig",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2200,
           "country_id":504,
           "code":"",
           "name":"Kenitra",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2201,
           "country_id":504,
           "code":"",
           "name":"Khemisset",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2202,
           "country_id":504,
           "code":"",
           "name":"Khenifra",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2203,
           "country_id":504,
           "code":"",
           "name":"Khouribga",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2204,
           "country_id":504,
           "code":"",
           "name":"Marrakech",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2205,
           "country_id":504,
           "code":"",
           "name":"Meknes",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2206,
           "country_id":504,
           "code":"",
           "name":"Nador",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2207,
           "country_id":504,
           "code":"",
           "name":"Ouarzazate",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2208,
           "country_id":504,
           "code":"",
           "name":"Oujda",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2209,
           "country_id":504,
           "code":"",
           "name":"Rabat-Sale",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2210,
           "country_id":504,
           "code":"",
           "name":"Safi",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2211,
           "country_id":504,
           "code":"",
           "name":"Settat",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2212,
           "country_id":504,
           "code":"",
           "name":"Tanger",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2213,
           "country_id":504,
           "code":"",
           "name":"Tata",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2214,
           "country_id":504,
           "code":"",
           "name":"Taza",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2215,
           "country_id":504,
           "code":"",
           "name":"Tiznit",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2216,
           "country_id":504,
           "code":"",
           "name":"Guelmim",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2217,
           "country_id":504,
           "code":"",
           "name":"Ifrane",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2218,
           "country_id":504,
           "code":"",
           "name":"Laayoune",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2219,
           "country_id":504,
           "code":"",
           "name":"Tan-Tan",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2220,
           "country_id":504,
           "code":"",
           "name":"Taounate",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2221,
           "country_id":504,
           "code":"",
           "name":"Sidi Kacem",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2222,
           "country_id":504,
           "code":"",
           "name":"Taroudannt",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2223,
           "country_id":504,
           "code":"",
           "name":"Tetouan",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2224,
           "country_id":504,
           "code":"",
           "name":"Larache",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2225,
           "country_id":504,
           "code":"",
           "name":"Grand Casablanca",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2226,
           "country_id":504,
           "code":"",
           "name":"Fès-Boulemane",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2227,
           "country_id":504,
           "code":"",
           "name":"Marrakech-Tensift-Al Haouz",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2228,
           "country_id":504,
           "code":"",
           "name":"Meknès-Tafilalet",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2229,
           "country_id":504,
           "code":"",
           "name":"Rabat-Salé-Zemmour-Zaër",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2230,
           "country_id":504,
           "code":"",
           "name":"Chaouia-Ouardigha",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2231,
           "country_id":504,
           "code":"",
           "name":"Doukkala-Abda",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2232,
           "country_id":504,
           "code":"",
           "name":"Gharb-Chrarda-Beni Hssen",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2233,
           "country_id":504,
           "code":"",
           "name":"Guelmim-Es Smara",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2234,
           "country_id":504,
           "code":"",
           "name":"Oriental",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2235,
           "country_id":504,
           "code":"",
           "name":"Souss-Massa-Drâa",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2236,
           "country_id":504,
           "code":"",
           "name":"Tadla-Azilal",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2237,
           "country_id":504,
           "code":"",
           "name":"Tanger-Tétouan",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2238,
           "country_id":504,
           "code":"",
           "name":"Taza-Al Hoceima-Taounate",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2239,
           "country_id":504,
           "code":"",
           "name":"Laâyoune-Boujdour-Sakia El Hamra",
           "standard_timezone":"(UTC+0.0) Casablanca",
           "timezone":"Africa/Casablanca"
        },
        {
           "id":2240,
           "country_id":508,
           "code":"",
           "name":"Cabo Delgado",
           "standard_timezone":"(UTC+2.0) Maputo",
           "timezone":"Africa/Maputo"
        },
        {
           "id":2241,
           "country_id":508,
           "code":"",
           "name":"Gaza",
           "standard_timezone":"(UTC+2.0) Maputo",
           "timezone":"Africa/Maputo"
        },
        {
           "id":2242,
           "country_id":508,
           "code":"",
           "name":"Inhambane",
           "standard_timezone":"(UTC+2.0) Maputo",
           "timezone":"Africa/Maputo"
        },
        {
           "id":2243,
           "country_id":508,
           "code":"",
           "name":"Maputo Province",
           "standard_timezone":"(UTC+2.0) Maputo",
           "timezone":"Africa/Maputo"
        },
        {
           "id":2244,
           "country_id":508,
           "code":"",
           "name":"Sofala",
           "standard_timezone":"(UTC+2.0) Maputo",
           "timezone":"Africa/Maputo"
        },
        {
           "id":2245,
           "country_id":508,
           "code":"",
           "name":"Nampula",
           "standard_timezone":"(UTC+2.0) Maputo",
           "timezone":"Africa/Maputo"
        },
        {
           "id":2246,
           "country_id":508,
           "code":"",
           "name":"Niassa",
           "standard_timezone":"(UTC+2.0) Maputo",
           "timezone":"Africa/Maputo"
        },
        {
           "id":2247,
           "country_id":508,
           "code":"",
           "name":"Tete",
           "standard_timezone":"(UTC+2.0) Maputo",
           "timezone":"Africa/Maputo"
        },
        {
           "id":2248,
           "country_id":508,
           "code":"",
           "name":"Zambézia",
           "standard_timezone":"(UTC+2.0) Maputo",
           "timezone":"Africa/Maputo"
        },
        {
           "id":2249,
           "country_id":508,
           "code":"",
           "name":"Manica",
           "standard_timezone":"(UTC+2.0) Maputo",
           "timezone":"Africa/Maputo"
        },
        {
           "id":2250,
           "country_id":508,
           "code":"",
           "name":"Maputo",
           "standard_timezone":"(UTC+2.0) Maputo",
           "timezone":"Africa/Maputo"
        },
        {
           "id":2251,
           "country_id":512,
           "code":"",
           "name":"Ad Dākhilīyah",
           "standard_timezone":"(UTC+4.0) Muscat",
           "timezone":"Asia/Muscat"
        },
        {
           "id":2252,
           "country_id":512,
           "code":"",
           "name":"Al Bāţinah",
           "standard_timezone":"(UTC+4.0) Muscat",
           "timezone":"Asia/Muscat"
        },
        {
           "id":2253,
           "country_id":512,
           "code":"",
           "name":"Al Wusţá",
           "standard_timezone":"(UTC+4.0) Muscat",
           "timezone":"Asia/Muscat"
        },
        {
           "id":2254,
           "country_id":512,
           "code":"",
           "name":"Ash Sharqīyah",
           "standard_timezone":"(UTC+4.0) Muscat",
           "timezone":"Asia/Muscat"
        },
        {
           "id":2255,
           "country_id":512,
           "code":"",
           "name":"Masqaţ",
           "standard_timezone":"(UTC+4.0) Muscat",
           "timezone":"Asia/Muscat"
        },
        {
           "id":2256,
           "country_id":512,
           "code":"",
           "name":"Musandam",
           "standard_timezone":"(UTC+4.0) Muscat",
           "timezone":"Asia/Muscat"
        },
        {
           "id":2257,
           "country_id":512,
           "code":"",
           "name":"Z̧ufār",
           "standard_timezone":"(UTC+4.0) Muscat",
           "timezone":"Asia/Muscat"
        },
        {
           "id":2258,
           "country_id":512,
           "code":"",
           "name":"Az̧ Z̧āhirah",
           "standard_timezone":"(UTC+4.0) Muscat",
           "timezone":"Asia/Muscat"
        },
        {
           "id":2259,
           "country_id":512,
           "code":"",
           "name":"Muḩāfaz̧at al Buraymī",
           "standard_timezone":"(UTC+4.0) Muscat",
           "timezone":"Asia/Muscat"
        },
        {
           "id":2260,
           "country_id":516,
           "code":"",
           "name":"Bethanien",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2261,
           "country_id":516,
           "code":"",
           "name":"Caprivi Oos",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2262,
           "country_id":516,
           "code":"",
           "name":"Kaokoland",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2263,
           "country_id":516,
           "code":"",
           "name":"Otjiwarongo",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2264,
           "country_id":516,
           "code":"",
           "name":"Outjo",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2265,
           "country_id":516,
           "code":"",
           "name":"Owambo",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2266,
           "country_id":516,
           "code":"",
           "name":"Khomas",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2267,
           "country_id":516,
           "code":"",
           "name":"Kavango",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2268,
           "country_id":516,
           "code":"",
           "name":"Caprivi",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2269,
           "country_id":516,
           "code":"",
           "name":"Erongo",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2270,
           "country_id":516,
           "code":"",
           "name":"Hardap",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2271,
           "country_id":516,
           "code":"",
           "name":"Karas",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2272,
           "country_id":516,
           "code":"",
           "name":"Kunene",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2273,
           "country_id":516,
           "code":"",
           "name":"Ohangwena",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2274,
           "country_id":516,
           "code":"",
           "name":"Okavango",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2275,
           "country_id":516,
           "code":"",
           "name":"Omaheke",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2276,
           "country_id":516,
           "code":"",
           "name":"Omusati",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2277,
           "country_id":516,
           "code":"",
           "name":"Oshana",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2278,
           "country_id":516,
           "code":"",
           "name":"Oshikoto",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2279,
           "country_id":516,
           "code":"",
           "name":"Otjozondjupa",
           "standard_timezone":"(UTC+1.0) Windhoek",
           "timezone":"Africa/Windhoek"
        },
        {
           "id":2280,
           "country_id":520,
           "code":"",
           "name":"Aiwo",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2281,
           "country_id":520,
           "code":"",
           "name":"Anabar",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2282,
           "country_id":520,
           "code":"",
           "name":"Anetan",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2283,
           "country_id":520,
           "code":"",
           "name":"Anibare",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2284,
           "country_id":520,
           "code":"",
           "name":"Baiti",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2285,
           "country_id":520,
           "code":"",
           "name":"Boe",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2286,
           "country_id":520,
           "code":"",
           "name":"Buada",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2287,
           "country_id":520,
           "code":"",
           "name":"Denigomodu",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2288,
           "country_id":520,
           "code":"",
           "name":"Ewa",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2289,
           "country_id":520,
           "code":"",
           "name":"Ijuw",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2290,
           "country_id":520,
           "code":"",
           "name":"Meneng",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2291,
           "country_id":520,
           "code":"",
           "name":"Nibok",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2292,
           "country_id":520,
           "code":"",
           "name":"Uaboe",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2293,
           "country_id":520,
           "code":"",
           "name":"Yaren",
           "standard_timezone":"(UTC+3.0) Nauru",
           "timezone":"Pacific/Nauru"
        },
        {
           "id":2294,
           "country_id":524,
           "code":"",
           "name":"Bāgmatī",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2295,
           "country_id":524,
           "code":"",
           "name":"Bherī",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2296,
           "country_id":524,
           "code":"",
           "name":"Dhawalāgiri",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2297,
           "country_id":524,
           "code":"",
           "name":"Gandakī",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2298,
           "country_id":524,
           "code":"",
           "name":"Janakpur",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2299,
           "country_id":524,
           "code":"",
           "name":"Karnālī",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2300,
           "country_id":524,
           "code":"",
           "name":"Kosī",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2301,
           "country_id":524,
           "code":"",
           "name":"Lumbinī",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2302,
           "country_id":524,
           "code":"",
           "name":"Mahākālī",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2303,
           "country_id":524,
           "code":"",
           "name":"Mechī",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2304,
           "country_id":524,
           "code":"",
           "name":"Nārāyanī",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2305,
           "country_id":524,
           "code":"",
           "name":"Rāptī",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2306,
           "country_id":524,
           "code":"",
           "name":"Sagarmāthā",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2307,
           "country_id":524,
           "code":"",
           "name":"Setī",
           "standard_timezone":"(UTC+5.75) Kathmandu",
           "timezone":"Asia/Kathmandu"
        },
        {
           "id":2308,
           "country_id":528,
           "code":"",
           "name":"Provincie Drenthe",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2309,
           "country_id":528,
           "code":"",
           "name":"Provincie Friesland",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2310,
           "country_id":528,
           "code":"",
           "name":"Gelderland",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2311,
           "country_id":528,
           "code":"",
           "name":"Groningen",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2312,
           "country_id":528,
           "code":"",
           "name":"Limburg",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2313,
           "country_id":528,
           "code":"",
           "name":"North Brabant",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2314,
           "country_id":528,
           "code":"",
           "name":"North Holland",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2315,
           "country_id":528,
           "code":"",
           "name":"Utrecht",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2316,
           "country_id":528,
           "code":"",
           "name":"Zeeland",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2317,
           "country_id":528,
           "code":"",
           "name":"South Holland",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2318,
           "country_id":528,
           "code":"",
           "name":"Overijssel",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2319,
           "country_id":528,
           "code":"",
           "name":"Flevoland",
           "standard_timezone":"(UTC+2.0) Amsterdam",
           "timezone":"Europe/Amsterdam"
        },
        {
           "id":2320,
           "country_id":530,
           "code":"",
           "name":"Netherlands Antilles",
           "standard_timezone":"(UTC-5.0) Curacao",
           "timezone":"America/Curacao"
        },
        {
           "id":2321,
           "country_id":533,
           "code":"",
           "name":"Aruba",
           "standard_timezone":"(UTC-4.0) Aruba",
           "timezone":"America/Aruba"
        },
        {
           "id":2322,
           "country_id":548,
           "code":"",
           "name":"Ambrym",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2323,
           "country_id":548,
           "code":"",
           "name":"Aoba/Maéwo",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2324,
           "country_id":548,
           "code":"",
           "name":"Torba",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2325,
           "country_id":548,
           "code":"",
           "name":"Éfaté",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2326,
           "country_id":548,
           "code":"",
           "name":"Épi",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2327,
           "country_id":548,
           "code":"",
           "name":"Malakula",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2328,
           "country_id":548,
           "code":"",
           "name":"Paama",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2329,
           "country_id":548,
           "code":"",
           "name":"Pentecôte",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2330,
           "country_id":548,
           "code":"",
           "name":"Sanma",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2331,
           "country_id":548,
           "code":"",
           "name":"Shepherd",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2332,
           "country_id":548,
           "code":"",
           "name":"Tafea",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2333,
           "country_id":548,
           "code":"",
           "name":"Malampa",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2334,
           "country_id":548,
           "code":"",
           "name":"Penama",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2335,
           "country_id":548,
           "code":"",
           "name":"Shefa",
           "standard_timezone":"(UTC +11:00)Efate",
           "timezone":"Pacific/Efate"
        },
        {
           "id":2336,
           "country_id":554,
           "code":"",
           "name":"Tasman",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2337,
           "country_id":554,
           "code":"",
           "name":"Auckland",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2338,
           "country_id":554,
           "code":"",
           "name":"Bay of Plenty",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2339,
           "country_id":554,
           "code":"",
           "name":"Canterbury",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2340,
           "country_id":554,
           "code":"",
           "name":"Gisborne",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2341,
           "country_id":554,
           "code":"",
           "name":"Hawkeʼs Bay",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2342,
           "country_id":554,
           "code":"",
           "name":"Manawatu-Wanganui",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2343,
           "country_id":554,
           "code":"",
           "name":"Marlborough",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2344,
           "country_id":554,
           "code":"",
           "name":"Nelson",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2345,
           "country_id":554,
           "code":"",
           "name":"Northland",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2346,
           "country_id":554,
           "code":"",
           "name":"Otago",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2347,
           "country_id":554,
           "code":"",
           "name":"Southland",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2348,
           "country_id":554,
           "code":"",
           "name":"Taranaki",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2349,
           "country_id":554,
           "code":"",
           "name":"Waikato",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2350,
           "country_id":554,
           "code":"",
           "name":"Wellington",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2351,
           "country_id":554,
           "code":"",
           "name":"West Coast",
           "standard_timezone":"(UTC+12.0) Auckland",
           "timezone":"Pacific/Auckland"
        },
        {
           "id":2352,
           "country_id":558,
           "code":"",
           "name":"Boaco",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2353,
           "country_id":558,
           "code":"",
           "name":"Carazo",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2354,
           "country_id":558,
           "code":"",
           "name":"Chinandega",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2355,
           "country_id":558,
           "code":"",
           "name":"Chontales",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2356,
           "country_id":558,
           "code":"",
           "name":"Estelí",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2357,
           "country_id":558,
           "code":"",
           "name":"Granada",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2358,
           "country_id":558,
           "code":"",
           "name":"Jinotega",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2359,
           "country_id":558,
           "code":"",
           "name":"León",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2360,
           "country_id":558,
           "code":"",
           "name":"Madriz",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2361,
           "country_id":558,
           "code":"",
           "name":"Managua",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2362,
           "country_id":558,
           "code":"",
           "name":"Masaya",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2363,
           "country_id":558,
           "code":"",
           "name":"Matagalpa",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2364,
           "country_id":558,
           "code":"",
           "name":"Nueva Segovia",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2365,
           "country_id":558,
           "code":"",
           "name":"Río San Juan",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2366,
           "country_id":558,
           "code":"",
           "name":"Rivas",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2367,
           "country_id":558,
           "code":"",
           "name":"Ogun State",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2368,
           "country_id":558,
           "code":"",
           "name":"Atlántico Norte",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2369,
           "country_id":558,
           "code":"",
           "name":"Atlántico Sur",
           "standard_timezone":"(UTC-6.0) Managua",
           "timezone":"America/Managua"
        },
        {
           "id":2370,
           "country_id":562,
           "code":"",
           "name":"Agadez",
           "standard_timezone":"(UTC+1.0) Niamey",
           "timezone":"Africa/Niamey"
        },
        {
           "id":2371,
           "country_id":562,
           "code":"",
           "name":"Diffa",
           "standard_timezone":"(UTC+1.0) Niamey",
           "timezone":"Africa/Niamey"
        },
        {
           "id":2372,
           "country_id":562,
           "code":"",
           "name":"Dosso",
           "standard_timezone":"(UTC+1.0) Niamey",
           "timezone":"Africa/Niamey"
        },
        {
           "id":2373,
           "country_id":562,
           "code":"",
           "name":"Maradi",
           "standard_timezone":"(UTC+1.0) Niamey",
           "timezone":"Africa/Niamey"
        },
        {
           "id":2374,
           "country_id":562,
           "code":"",
           "name":"Tahoua",
           "standard_timezone":"(UTC+1.0) Niamey",
           "timezone":"Africa/Niamey"
        },
        {
           "id":2375,
           "country_id":562,
           "code":"",
           "name":"Zinder",
           "standard_timezone":"(UTC+1.0) Niamey",
           "timezone":"Africa/Niamey"
        },
        {
           "id":2376,
           "country_id":562,
           "code":"",
           "name":"Niamey",
           "standard_timezone":"(UTC+1.0) Niamey",
           "timezone":"Africa/Niamey"
        },
        {
           "id":2377,
           "country_id":562,
           "code":"",
           "name":"Tillabéri",
           "standard_timezone":"(UTC+1.0) Niamey",
           "timezone":"Africa/Niamey"
        },
        {
           "id":2378,
           "country_id":566,
           "code":"",
           "name":"Lagos",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2379,
           "country_id":566,
           "code":"",
           "name":"Abuja Federal Capital Territory",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2380,
           "country_id":566,
           "code":"",
           "name":"Ogun",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2381,
           "country_id":566,
           "code":"",
           "name":"Akwa Ibom",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2382,
           "country_id":566,
           "code":"",
           "name":"Cross River",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2383,
           "country_id":566,
           "code":"",
           "name":"Kaduna",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2384,
           "country_id":566,
           "code":"",
           "name":"Katsina",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2385,
           "country_id":566,
           "code":"",
           "name":"Anambra",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2386,
           "country_id":566,
           "code":"",
           "name":"Benue",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2387,
           "country_id":566,
           "code":"",
           "name":"Borno",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2388,
           "country_id":566,
           "code":"",
           "name":"Imo",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2389,
           "country_id":566,
           "code":"",
           "name":"Kano",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2390,
           "country_id":566,
           "code":"",
           "name":"Kwara",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2391,
           "country_id":566,
           "code":"",
           "name":"Niger",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2392,
           "country_id":566,
           "code":"",
           "name":"Oyo",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2393,
           "country_id":566,
           "code":"",
           "name":"Adamawa",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2394,
           "country_id":566,
           "code":"",
           "name":"Delta",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2395,
           "country_id":566,
           "code":"",
           "name":"Edo",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2396,
           "country_id":566,
           "code":"",
           "name":"Jigawa",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2397,
           "country_id":566,
           "code":"",
           "name":"Kebbi",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2398,
           "country_id":566,
           "code":"",
           "name":"Kogi",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2399,
           "country_id":566,
           "code":"",
           "name":"Osun",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2400,
           "country_id":566,
           "code":"",
           "name":"Taraba",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2401,
           "country_id":566,
           "code":"",
           "name":"Yobe",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2402,
           "country_id":566,
           "code":"",
           "name":"Abia",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2403,
           "country_id":566,
           "code":"",
           "name":"Bauchi",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2404,
           "country_id":566,
           "code":"",
           "name":"Enugu",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2405,
           "country_id":566,
           "code":"",
           "name":"Ondo",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2406,
           "country_id":566,
           "code":"",
           "name":"Plateau",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2407,
           "country_id":566,
           "code":"",
           "name":"Rivers",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2408,
           "country_id":566,
           "code":"",
           "name":"Sokoto",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2409,
           "country_id":566,
           "code":"",
           "name":"Bayelsa",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2410,
           "country_id":566,
           "code":"",
           "name":"Ebonyi",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2411,
           "country_id":566,
           "code":"",
           "name":"Ekiti",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2412,
           "country_id":566,
           "code":"",
           "name":"Gombe",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2413,
           "country_id":566,
           "code":"",
           "name":"Nassarawa",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2414,
           "country_id":566,
           "code":"",
           "name":"Zamfara",
           "standard_timezone":"(UTC+1.0) Lagos",
           "timezone":"Africa/Lagos"
        },
        {
           "id":2415,
           "country_id":570,
           "code":"",
           "name":"Niue",
           "standard_timezone":"(UTC-11.0) Niue",
           "timezone":"Pacific/Niue"
        },
        {
           "id":2416,
           "country_id":574,
           "code":"",
           "name":"Norfolk Island",
           "standard_timezone":"(UTC-4.0) Norfolk",
           "timezone":"Pacific/Norfolk"
        },
        {
           "id":2417,
           "country_id":578,
           "code":"",
           "name":"Svalbard",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2418,
           "country_id":578,
           "code":"",
           "name":"Akershus",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2419,
           "country_id":578,
           "code":"",
           "name":"Aust-Agder",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2420,
           "country_id":578,
           "code":"",
           "name":"Buskerud",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2421,
           "country_id":578,
           "code":"",
           "name":"Finnmark",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2422,
           "country_id":578,
           "code":"",
           "name":"Hedmark",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2423,
           "country_id":578,
           "code":"",
           "name":"Hordaland",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2424,
           "country_id":578,
           "code":"",
           "name":"Møre og Romsdal",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2425,
           "country_id":578,
           "code":"",
           "name":"Nordland",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2426,
           "country_id":578,
           "code":"",
           "name":"Nord-Trøndelag",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2427,
           "country_id":578,
           "code":"",
           "name":"Oppland",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2428,
           "country_id":578,
           "code":"",
           "name":"Oslo county",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2429,
           "country_id":578,
           "code":"",
           "name":"Østfold",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2430,
           "country_id":578,
           "code":"",
           "name":"Rogaland",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2431,
           "country_id":578,
           "code":"",
           "name":"Sogn og Fjordane",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2432,
           "country_id":578,
           "code":"",
           "name":"Sør-Trøndelag",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2433,
           "country_id":578,
           "code":"",
           "name":"Telemark",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2434,
           "country_id":578,
           "code":"",
           "name":"Troms",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2435,
           "country_id":578,
           "code":"",
           "name":"Vest-Agder",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2436,
           "country_id":578,
           "code":"",
           "name":"Vestfold",
           "standard_timezone":"(UTC+2.0) Oslo",
           "timezone":"Europe/Oslo"
        },
        {
           "id":2437,
           "country_id":583,
           "code":"",
           "name":"Kosrae",
           "standard_timezone":"(UTC +11:00 )Kosrae",
           "timezone":"Pacific/Kosrae"
        },
        {
           "id":2438,
           "country_id":583,
           "code":"",
           "name":"Pohnpei",
           "standard_timezone":"(UTC+11.0) Ponape",
           "timezone":"Pacific/Ponape"
        },
        {
           "id":2439,
           "country_id":583,
           "code":"",
           "name":"Chuuk",
           "standard_timezone":"(UTC+7.0) Truk",
           "timezone":"Pacific/Truk"
        },
        {
           "id":2440,
           "country_id":583,
           "code":"",
           "name":"Yap",
           "standard_timezone":"(UTC+7.0) Truk",
           "timezone":"Pacific/Truk"
        },
        {
           "id":2441,
           "country_id":584,
           "code":"",
           "name":"Marshall Islands",
           "standard_timezone":"(UTC+12.0) Majuro",
           "timezone":"Pacific/Majuro"
        },
        {
           "id":2442,
           "country_id":585,
           "code":"",
           "name":"State of Ngeremlengui",
           "standard_timezone":"(UTC-5.0) Palau",
           "timezone":"Pacific/Palau"
        },
        {
           "id":2443,
           "country_id":586,
           "code":"",
           "name":"Federally Administered Tribal Areas",
           "standard_timezone":"(UTC+5.0) Karachi",
           "timezone":"Asia/Karachi"
        },
        {
           "id":2444,
           "country_id":586,
           "code":"",
           "name":"Balochistān",
           "standard_timezone":"(UTC+5.0) Karachi",
           "timezone":"Asia/Karachi"
        },
        {
           "id":2445,
           "country_id":586,
           "code":"",
           "name":"North West Frontier Province",
           "standard_timezone":"(UTC+5.0) Karachi",
           "timezone":"Asia/Karachi"
        },
        {
           "id":2446,
           "country_id":586,
           "code":"",
           "name":"Punjab",
           "standard_timezone":"(UTC+5.0) Karachi",
           "timezone":"Asia/Karachi"
        },
        {
           "id":2447,
           "country_id":586,
           "code":"",
           "name":"Sindh",
           "standard_timezone":"(UTC+5.0) Karachi",
           "timezone":"Asia/Karachi"
        },
        {
           "id":2448,
           "country_id":586,
           "code":"",
           "name":"Azad Kashmir",
           "standard_timezone":"(UTC+5.0) Karachi",
           "timezone":"Asia/Karachi"
        },
        {
           "id":2449,
           "country_id":586,
           "code":"",
           "name":"Gilgit-Baltistan",
           "standard_timezone":"(UTC+5.0) Karachi",
           "timezone":"Asia/Karachi"
        },
        {
           "id":2450,
           "country_id":586,
           "code":"",
           "name":"Islāmābād",
           "standard_timezone":"(UTC+5.0) Karachi",
           "timezone":"Asia/Karachi"
        },
        {
           "id":2451,
           "country_id":591,
           "code":"",
           "name":"Bocas del Toro",
           "standard_timezone":"(UTC-5.0) Panama",
           "timezone":"America/Panama"
        },
        {
           "id":2452,
           "country_id":591,
           "code":"",
           "name":"Chiriquí",
           "standard_timezone":"(UTC-5.0) Panama",
           "timezone":"America/Panama"
        },
        {
           "id":2453,
           "country_id":591,
           "code":"",
           "name":"Coclé",
           "standard_timezone":"(UTC-5.0) Panama",
           "timezone":"America/Panama"
        },
        {
           "id":2454,
           "country_id":591,
           "code":"",
           "name":"Colón",
           "standard_timezone":"(UTC-5.0) Panama",
           "timezone":"America/Panama"
        },
        {
           "id":2455,
           "country_id":591,
           "code":"",
           "name":"Darién",
           "standard_timezone":"(UTC-5.0) Panama",
           "timezone":"America/Panama"
        },
        {
           "id":2456,
           "country_id":591,
           "code":"",
           "name":"Herrera",
           "standard_timezone":"(UTC-5.0) Panama",
           "timezone":"America/Panama"
        },
        {
           "id":2457,
           "country_id":591,
           "code":"",
           "name":"Los Santos",
           "standard_timezone":"(UTC-5.0) Panama",
           "timezone":"America/Panama"
        },
        {
           "id":2458,
           "country_id":591,
           "code":"",
           "name":"Panamá",
           "standard_timezone":"(UTC-5.0) Panama",
           "timezone":"America/Panama"
        },
        {
           "id":2459,
           "country_id":591,
           "code":"",
           "name":"San Blas",
           "standard_timezone":"(UTC-5.0) Panama",
           "timezone":"America/Panama"
        },
        {
           "id":2460,
           "country_id":591,
           "code":"",
           "name":"Veraguas",
           "standard_timezone":"(UTC-5.0) Panama",
           "timezone":"America/Panama"
        },
        {
           "id":2461,
           "country_id":598,
           "code":"",
           "name":"Central",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2462,
           "country_id":598,
           "code":"",
           "name":"Gulf",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2463,
           "country_id":598,
           "code":"",
           "name":"Milne Bay",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2464,
           "country_id":598,
           "code":"",
           "name":"Northern",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2465,
           "country_id":598,
           "code":"",
           "name":"Southern Highlands",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2466,
           "country_id":598,
           "code":"",
           "name":"Western",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2467,
           "country_id":598,
           "code":"",
           "name":"Bougainville",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2468,
           "country_id":598,
           "code":"",
           "name":"Chimbu",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2469,
           "country_id":598,
           "code":"",
           "name":"Eastern Highlands",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2470,
           "country_id":598,
           "code":"",
           "name":"East New Britain",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2471,
           "country_id":598,
           "code":"",
           "name":"East Sepik",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2472,
           "country_id":598,
           "code":"",
           "name":"Madang",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2473,
           "country_id":598,
           "code":"",
           "name":"Manus",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2474,
           "country_id":598,
           "code":"",
           "name":"Morobe",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2475,
           "country_id":598,
           "code":"",
           "name":"New Ireland",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2476,
           "country_id":598,
           "code":"",
           "name":"Western Highlands",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2477,
           "country_id":598,
           "code":"",
           "name":"West New Britain",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2478,
           "country_id":598,
           "code":"",
           "name":"Sandaun",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2479,
           "country_id":598,
           "code":"",
           "name":"Enga",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2480,
           "country_id":598,
           "code":"",
           "name":"National Capital",
           "standard_timezone":"(UTC+10.0) Port_Moresby",
           "timezone":"Pacific/Port_Moresby"
        },
        {
           "id":2481,
           "country_id":600,
           "code":"",
           "name":"Alto Paraná",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2482,
           "country_id":600,
           "code":"",
           "name":"Amambay",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2483,
           "country_id":600,
           "code":"",
           "name":"Caaguazú",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2484,
           "country_id":600,
           "code":"",
           "name":"Caazapá",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2485,
           "country_id":600,
           "code":"",
           "name":"Central",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2486,
           "country_id":600,
           "code":"",
           "name":"Concepción",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2487,
           "country_id":600,
           "code":"",
           "name":"Cordillera",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2488,
           "country_id":600,
           "code":"",
           "name":"Guairá",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2489,
           "country_id":600,
           "code":"",
           "name":"Itapúa",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2490,
           "country_id":600,
           "code":"",
           "name":"Misiones",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2491,
           "country_id":600,
           "code":"",
           "name":"Ñeembucú",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2492,
           "country_id":600,
           "code":"",
           "name":"Paraguarí",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2493,
           "country_id":600,
           "code":"",
           "name":"Presidente Hayes",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2494,
           "country_id":600,
           "code":"",
           "name":"San Pedro",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2495,
           "country_id":600,
           "code":"",
           "name":"Canindeyú",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2496,
           "country_id":600,
           "code":"",
           "name":"Asunción",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2497,
           "country_id":600,
           "code":"",
           "name":"Departamento de Alto Paraguay",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2498,
           "country_id":600,
           "code":"",
           "name":"Boquerón",
           "standard_timezone":"(UTC-4.0) Asuncion",
           "timezone":"America/Asuncion"
        },
        {
           "id":2499,
           "country_id":604,
           "code":"",
           "name":"Amazonas",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2500,
           "country_id":604,
           "code":"",
           "name":"Ancash",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2501,
           "country_id":604,
           "code":"",
           "name":"Apurímac",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2502,
           "country_id":604,
           "code":"",
           "name":"Arequipa",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2503,
           "country_id":604,
           "code":"",
           "name":"Ayacucho",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2504,
           "country_id":604,
           "code":"",
           "name":"Cajamarca",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2505,
           "country_id":604,
           "code":"",
           "name":"Callao",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2506,
           "country_id":604,
           "code":"",
           "name":"Cusco",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2507,
           "country_id":604,
           "code":"",
           "name":"Huancavelica",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2508,
           "country_id":604,
           "code":"",
           "name":"Huanuco",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2509,
           "country_id":604,
           "code":"",
           "name":"Ica",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2510,
           "country_id":604,
           "code":"",
           "name":"Junín",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2511,
           "country_id":604,
           "code":"",
           "name":"La Libertad",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2512,
           "country_id":604,
           "code":"",
           "name":"Lambayeque",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2513,
           "country_id":604,
           "code":"",
           "name":"Lima",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2514,
           "country_id":604,
           "code":"",
           "name":"Provincia de Lima",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2515,
           "country_id":604,
           "code":"",
           "name":"Loreto",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2516,
           "country_id":604,
           "code":"",
           "name":"Madre de Dios",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2517,
           "country_id":604,
           "code":"",
           "name":"Moquegua",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2518,
           "country_id":604,
           "code":"",
           "name":"Pasco",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2519,
           "country_id":604,
           "code":"",
           "name":"Piura",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2520,
           "country_id":604,
           "code":"",
           "name":"Puno",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2521,
           "country_id":604,
           "code":"",
           "name":"San Martín",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2522,
           "country_id":604,
           "code":"",
           "name":"Tacna",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2523,
           "country_id":604,
           "code":"",
           "name":"Tumbes",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2524,
           "country_id":604,
           "code":"",
           "name":"Ucayali",
           "standard_timezone":"(UTC-5.0) Lima",
           "timezone":"America/Lima"
        },
        {
           "id":2525,
           "country_id":608,
           "code":"",
           "name":"Abra",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2526,
           "country_id":608,
           "code":"",
           "name":"Agusan del Norte",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2527,
           "country_id":608,
           "code":"",
           "name":"Agusan del Sur",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2528,
           "country_id":608,
           "code":"",
           "name":"Aklan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2529,
           "country_id":608,
           "code":"",
           "name":"Albay",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2530,
           "country_id":608,
           "code":"",
           "name":"Antique",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2531,
           "country_id":608,
           "code":"",
           "name":"Bataan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2532,
           "country_id":608,
           "code":"",
           "name":"Batanes",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2533,
           "country_id":608,
           "code":"",
           "name":"Batangas",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2534,
           "country_id":608,
           "code":"",
           "name":"Benguet",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2535,
           "country_id":608,
           "code":"",
           "name":"Bohol",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2536,
           "country_id":608,
           "code":"",
           "name":"Bukidnon",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2537,
           "country_id":608,
           "code":"",
           "name":"Bulacan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2538,
           "country_id":608,
           "code":"",
           "name":"Cagayan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2539,
           "country_id":608,
           "code":"",
           "name":"Camarines Norte",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2540,
           "country_id":608,
           "code":"",
           "name":"Camarines Sur",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2541,
           "country_id":608,
           "code":"",
           "name":"Camiguin",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2542,
           "country_id":608,
           "code":"",
           "name":"Capiz",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2543,
           "country_id":608,
           "code":"",
           "name":"Catanduanes",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2544,
           "country_id":608,
           "code":"",
           "name":"Cebu",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2545,
           "country_id":608,
           "code":"",
           "name":"Basilan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2546,
           "country_id":608,
           "code":"",
           "name":"Eastern Samar",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2547,
           "country_id":608,
           "code":"",
           "name":"Davao del Sur",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2548,
           "country_id":608,
           "code":"",
           "name":"Davao Oriental",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2549,
           "country_id":608,
           "code":"",
           "name":"Ifugao",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2550,
           "country_id":608,
           "code":"",
           "name":"Ilocos Norte",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2551,
           "country_id":608,
           "code":"",
           "name":"Ilocos Sur",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2552,
           "country_id":608,
           "code":"",
           "name":"Iloilo",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2553,
           "country_id":608,
           "code":"",
           "name":"Isabela",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2554,
           "country_id":608,
           "code":"",
           "name":"Laguna",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2555,
           "country_id":608,
           "code":"",
           "name":"Lanao del Sur",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2556,
           "country_id":608,
           "code":"",
           "name":"La Union",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2557,
           "country_id":608,
           "code":"",
           "name":"Leyte",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2558,
           "country_id":608,
           "code":"",
           "name":"Marinduque",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2559,
           "country_id":608,
           "code":"",
           "name":"Masbate",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2560,
           "country_id":608,
           "code":"",
           "name":"Occidental Mindoro",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2561,
           "country_id":608,
           "code":"",
           "name":"Oriental Mindoro",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2562,
           "country_id":608,
           "code":"",
           "name":"Misamis Oriental",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2563,
           "country_id":608,
           "code":"",
           "name":"Mountain Province",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2564,
           "country_id":608,
           "code":"",
           "name":"Negros Oriental",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2565,
           "country_id":608,
           "code":"",
           "name":"Nueva Ecija",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2566,
           "country_id":608,
           "code":"",
           "name":"Nueva Vizcaya",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2567,
           "country_id":608,
           "code":"",
           "name":"Palawan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2568,
           "country_id":608,
           "code":"",
           "name":"Pampanga",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2569,
           "country_id":608,
           "code":"",
           "name":"Pangasinan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2570,
           "country_id":608,
           "code":"",
           "name":"Rizal",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2571,
           "country_id":608,
           "code":"",
           "name":"Romblon",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2572,
           "country_id":608,
           "code":"",
           "name":"Samar",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2573,
           "country_id":608,
           "code":"",
           "name":"Maguindanao",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2574,
           "country_id":608,
           "code":"",
           "name":"Cotabato City",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2575,
           "country_id":608,
           "code":"",
           "name":"Sorsogon",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2576,
           "country_id":608,
           "code":"",
           "name":"Southern Leyte",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2577,
           "country_id":608,
           "code":"",
           "name":"Sulu",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2578,
           "country_id":608,
           "code":"",
           "name":"Surigao del Norte",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2579,
           "country_id":608,
           "code":"",
           "name":"Surigao del Sur",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2580,
           "country_id":608,
           "code":"",
           "name":"Tarlac",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2581,
           "country_id":608,
           "code":"",
           "name":"Zambales",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2582,
           "country_id":608,
           "code":"",
           "name":"Zamboanga del Norte",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2583,
           "country_id":608,
           "code":"",
           "name":"Zamboanga del Sur",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2584,
           "country_id":608,
           "code":"",
           "name":"Northern Samar",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2585,
           "country_id":608,
           "code":"",
           "name":"Quirino",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2586,
           "country_id":608,
           "code":"",
           "name":"Siquijor",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2587,
           "country_id":608,
           "code":"",
           "name":"South Cotabato",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2588,
           "country_id":608,
           "code":"",
           "name":"Sultan Kudarat",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2589,
           "country_id":608,
           "code":"",
           "name":"Kalinga",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2590,
           "country_id":608,
           "code":"",
           "name":"Apayao",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2591,
           "country_id":608,
           "code":"",
           "name":"Tawi-Tawi",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2592,
           "country_id":608,
           "code":"",
           "name":"Angeles",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2593,
           "country_id":608,
           "code":"",
           "name":"Bacolod City",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2594,
           "country_id":608,
           "code":"",
           "name":"Compostela Valley",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2595,
           "country_id":608,
           "code":"",
           "name":"Baguio",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2596,
           "country_id":608,
           "code":"",
           "name":"Davao del Norte",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2597,
           "country_id":608,
           "code":"",
           "name":"Butuan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2598,
           "country_id":608,
           "code":"",
           "name":"Guimaras",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2599,
           "country_id":608,
           "code":"",
           "name":"Lanao del Norte",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2600,
           "country_id":608,
           "code":"",
           "name":"Misamis Occidental",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2601,
           "country_id":608,
           "code":"",
           "name":"Caloocan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2602,
           "country_id":608,
           "code":"",
           "name":"Cavite",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2603,
           "country_id":608,
           "code":"",
           "name":"Cebu City",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2604,
           "country_id":608,
           "code":"",
           "name":"Cotabato",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2605,
           "country_id":608,
           "code":"",
           "name":"Dagupan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2606,
           "country_id":608,
           "code":"",
           "name":"Cagayan de Oro",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2607,
           "country_id":608,
           "code":"",
           "name":"Iligan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2608,
           "country_id":608,
           "code":"",
           "name":"Davao",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2609,
           "country_id":608,
           "code":"",
           "name":"Las Piñas",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2610,
           "country_id":608,
           "code":"",
           "name":"Malabon",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2611,
           "country_id":608,
           "code":"",
           "name":"General Santos",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2612,
           "country_id":608,
           "code":"",
           "name":"Muntinlupa",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2613,
           "country_id":608,
           "code":"",
           "name":"Iloilo City",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2614,
           "country_id":608,
           "code":"",
           "name":"Navotas",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2615,
           "country_id":608,
           "code":"",
           "name":"Parañaque",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2616,
           "country_id":608,
           "code":"",
           "name":"Quezon City",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2617,
           "country_id":608,
           "code":"",
           "name":"Lapu-Lapu",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2618,
           "country_id":608,
           "code":"",
           "name":"Taguig",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2619,
           "country_id":608,
           "code":"",
           "name":"Valenzuela",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2620,
           "country_id":608,
           "code":"",
           "name":"Lucena",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2621,
           "country_id":608,
           "code":"",
           "name":"Mandaue",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2622,
           "country_id":608,
           "code":"",
           "name":"Manila",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2623,
           "country_id":608,
           "code":"",
           "name":"Zamboanga Sibugay",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2624,
           "country_id":608,
           "code":"",
           "name":"Naga",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2625,
           "country_id":608,
           "code":"",
           "name":"Olongapo",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2626,
           "country_id":608,
           "code":"",
           "name":"Ormoc",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2627,
           "country_id":608,
           "code":"",
           "name":"Santiago",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2628,
           "country_id":608,
           "code":"",
           "name":"Pateros",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2629,
           "country_id":608,
           "code":"",
           "name":"Pasay",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2630,
           "country_id":608,
           "code":"",
           "name":"Puerto Princesa",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2631,
           "country_id":608,
           "code":"",
           "name":"Quezon",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2632,
           "country_id":608,
           "code":"",
           "name":"Tacloban",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2633,
           "country_id":608,
           "code":"",
           "name":"Zamboanga City",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2634,
           "country_id":608,
           "code":"",
           "name":"Aurora",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2635,
           "country_id":608,
           "code":"",
           "name":"Negros Occidental",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2636,
           "country_id":608,
           "code":"",
           "name":"Biliran",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2637,
           "country_id":608,
           "code":"",
           "name":"Makati City",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2638,
           "country_id":608,
           "code":"",
           "name":"Sarangani",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2639,
           "country_id":608,
           "code":"",
           "name":"Mandaluyong City",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2640,
           "country_id":608,
           "code":"",
           "name":"Marikina",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2641,
           "country_id":608,
           "code":"",
           "name":"Pasig",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2642,
           "country_id":608,
           "code":"",
           "name":"San Juan",
           "standard_timezone":"(UTC+8.0) Manila",
           "timezone":"Asia/Manila"
        },
        {
           "id":2643,
           "country_id":612,
           "code":"",
           "name":"Pitcairn Islands",
           "standard_timezone":"(UTC-5.0) Pitcairn",
           "timezone":"Pacific/Pitcairn"
        },
        {
           "id":2644,
           "country_id":616,
           "code":"",
           "name":"Biala Podlaska",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2645,
           "country_id":616,
           "code":"",
           "name":"Bialystok",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2646,
           "country_id":616,
           "code":"",
           "name":"Bielsko",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2647,
           "country_id":616,
           "code":"",
           "name":"Bydgoszcz",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2648,
           "country_id":616,
           "code":"",
           "name":"Chelm",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2649,
           "country_id":616,
           "code":"",
           "name":"Ciechanow",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2650,
           "country_id":616,
           "code":"",
           "name":"Czestochowa",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2651,
           "country_id":616,
           "code":"",
           "name":"Elblag",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2652,
           "country_id":616,
           "code":"",
           "name":"Gdansk",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2653,
           "country_id":616,
           "code":"",
           "name":"Gorzow",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2654,
           "country_id":616,
           "code":"",
           "name":"Jelenia Gora",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2655,
           "country_id":616,
           "code":"",
           "name":"Kalisz",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2656,
           "country_id":616,
           "code":"",
           "name":"Katowice",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2657,
           "country_id":616,
           "code":"",
           "name":"Kielce",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2658,
           "country_id":616,
           "code":"",
           "name":"Konin",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2659,
           "country_id":616,
           "code":"",
           "name":"Koszalin",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2660,
           "country_id":616,
           "code":"",
           "name":"Krakow",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2661,
           "country_id":616,
           "code":"",
           "name":"Krosno",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2662,
           "country_id":616,
           "code":"",
           "name":"Legnica",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2663,
           "country_id":616,
           "code":"",
           "name":"Leszno",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2664,
           "country_id":616,
           "code":"",
           "name":"Lodz",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2665,
           "country_id":616,
           "code":"",
           "name":"Lomza",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2666,
           "country_id":616,
           "code":"",
           "name":"Lublin",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2667,
           "country_id":616,
           "code":"",
           "name":"Nowy Sacz",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2668,
           "country_id":616,
           "code":"",
           "name":"Olsztyn",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2669,
           "country_id":616,
           "code":"",
           "name":"Opole",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2670,
           "country_id":616,
           "code":"",
           "name":"Ostroleka",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2671,
           "country_id":616,
           "code":"",
           "name":"Pita",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2672,
           "country_id":616,
           "code":"",
           "name":"Piotrkow",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2673,
           "country_id":616,
           "code":"",
           "name":"Plock",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2674,
           "country_id":616,
           "code":"",
           "name":"Poznan",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2675,
           "country_id":616,
           "code":"",
           "name":"Przemysl",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2676,
           "country_id":616,
           "code":"",
           "name":"Radom",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2677,
           "country_id":616,
           "code":"",
           "name":"Rzeszow",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2678,
           "country_id":616,
           "code":"",
           "name":"Siedlce",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2679,
           "country_id":616,
           "code":"",
           "name":"Sieradz",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2680,
           "country_id":616,
           "code":"",
           "name":"Skierniewice",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2681,
           "country_id":616,
           "code":"",
           "name":"Slupsk",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2682,
           "country_id":616,
           "code":"",
           "name":"Suwalki",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2683,
           "country_id":616,
           "code":"",
           "name":"Szczecin",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2684,
           "country_id":616,
           "code":"",
           "name":"Tarnobrzeg",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2685,
           "country_id":616,
           "code":"",
           "name":"Tarnow",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2686,
           "country_id":616,
           "code":"",
           "name":"Torufi",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2687,
           "country_id":616,
           "code":"",
           "name":"Walbrzych",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2688,
           "country_id":616,
           "code":"",
           "name":"Warszawa",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2689,
           "country_id":616,
           "code":"",
           "name":"Wloclawek",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2690,
           "country_id":616,
           "code":"",
           "name":"Wroclaw",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2691,
           "country_id":616,
           "code":"",
           "name":"Zamosc",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2692,
           "country_id":616,
           "code":"",
           "name":"Zielona Gora",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2693,
           "country_id":616,
           "code":"",
           "name":"Lower Silesian Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2694,
           "country_id":616,
           "code":"",
           "name":"Kujawsko-Pomorskie Voivodship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2695,
           "country_id":616,
           "code":"",
           "name":"Łódź Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2696,
           "country_id":616,
           "code":"",
           "name":"Lublin Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2697,
           "country_id":616,
           "code":"",
           "name":"Lubusz Voivodship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2698,
           "country_id":616,
           "code":"",
           "name":"Lesser Poland Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2699,
           "country_id":616,
           "code":"",
           "name":"Masovian Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2700,
           "country_id":616,
           "code":"",
           "name":"Opole Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2701,
           "country_id":616,
           "code":"",
           "name":"Subcarpathian Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2702,
           "country_id":616,
           "code":"",
           "name":"Podlasie Voivodship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2703,
           "country_id":616,
           "code":"",
           "name":"Pomeranian Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2704,
           "country_id":616,
           "code":"",
           "name":"Silesian Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2705,
           "country_id":616,
           "code":"",
           "name":"Świętokrzyskie Voivodship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2706,
           "country_id":616,
           "code":"",
           "name":"Warmian-Masurian Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2707,
           "country_id":616,
           "code":"",
           "name":"Greater Poland Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2708,
           "country_id":616,
           "code":"",
           "name":"West Pomeranian Voivodeship",
           "standard_timezone":"(UTC+2.0) Warsaw",
           "timezone":"Europe/Warsaw"
        },
        {
           "id":2709,
           "country_id":620,
           "code":"",
           "name":"Aveiro",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2710,
           "country_id":620,
           "code":"",
           "name":"Beja",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2711,
           "country_id":620,
           "code":"",
           "name":"Braga",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2712,
           "country_id":620,
           "code":"",
           "name":"Bragança",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2713,
           "country_id":620,
           "code":"",
           "name":"Castelo Branco",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2714,
           "country_id":620,
           "code":"",
           "name":"Coimbra",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2715,
           "country_id":620,
           "code":"",
           "name":"Évora",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2716,
           "country_id":620,
           "code":"",
           "name":"Faro",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2717,
           "country_id":620,
           "code":"",
           "name":"Madeira",
           "standard_timezone":"(UTC-3.0) Madeira",
           "timezone":"Atlantic/Madeira"
        },
        {
           "id":2718,
           "country_id":620,
           "code":"",
           "name":"Guarda",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2719,
           "country_id":620,
           "code":"",
           "name":"Leiria",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2720,
           "country_id":620,
           "code":"",
           "name":"Lisbon",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2721,
           "country_id":620,
           "code":"",
           "name":"Portalegre",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2722,
           "country_id":620,
           "code":"",
           "name":"Porto",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2723,
           "country_id":620,
           "code":"",
           "name":"Santarém",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2724,
           "country_id":620,
           "code":"",
           "name":"Setúbal",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2725,
           "country_id":620,
           "code":"",
           "name":"Viana do Castelo",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2726,
           "country_id":620,
           "code":"",
           "name":"Vila Real",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2727,
           "country_id":620,
           "code":"",
           "name":"Viseu",
           "standard_timezone":"(UTC+1.0) Lisbon",
           "timezone":"Europe/Lisbon"
        },
        {
           "id":2728,
           "country_id":620,
           "code":"",
           "name":"Azores",
           "standard_timezone":"(UTC+2.0) Azores",
           "timezone":"Atlantic/Azores"
        },
        {
           "id":2729,
           "country_id":624,
           "code":"",
           "name":"Bafatá",
           "standard_timezone":"(UTC+0.0) Bissau",
           "timezone":"Africa/Bissau"
        },
        {
           "id":2730,
           "country_id":624,
           "code":"",
           "name":"Quinara",
           "standard_timezone":"(UTC+0.0) Bissau",
           "timezone":"Africa/Bissau"
        },
        {
           "id":2731,
           "country_id":624,
           "code":"",
           "name":"Oio",
           "standard_timezone":"(UTC+0.0) Bissau",
           "timezone":"Africa/Bissau"
        },
        {
           "id":2732,
           "country_id":624,
           "code":"",
           "name":"Bolama",
           "standard_timezone":"(UTC+0.0) Bissau",
           "timezone":"Africa/Bissau"
        },
        {
           "id":2733,
           "country_id":624,
           "code":"",
           "name":"Cacheu",
           "standard_timezone":"(UTC+0.0) Bissau",
           "timezone":"Africa/Bissau"
        },
        {
           "id":2734,
           "country_id":624,
           "code":"",
           "name":"Tombali",
           "standard_timezone":"(UTC+0.0) Bissau",
           "timezone":"Africa/Bissau"
        },
        {
           "id":2735,
           "country_id":624,
           "code":"",
           "name":"Gabú",
           "standard_timezone":"(UTC+0.0) Bissau",
           "timezone":"Africa/Bissau"
        },
        {
           "id":2736,
           "country_id":624,
           "code":"",
           "name":"Bissau",
           "standard_timezone":"(UTC+0.0) Bissau",
           "timezone":"Africa/Bissau"
        },
        {
           "id":2737,
           "country_id":624,
           "code":"",
           "name":"Biombo",
           "standard_timezone":"(UTC+0.0) Bissau",
           "timezone":"Africa/Bissau"
        },
        {
           "id":2738,
           "country_id":626,
           "code":"",
           "name":"Bobonaro",
           "standard_timezone":"(UTC+4.0) Dili",
           "timezone":"Asia/Dili"
        },
        {
           "id":2739,
           "country_id":630,
           "code":"",
           "name":"Puerto Rico",
           "standard_timezone":"(UTC-5.0) Puerto_Rico",
           "timezone":"America/Puerto_Rico"
        },
        {
           "id":2740,
           "country_id":634,
           "code":"",
           "name":"Ad Dawḩah",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2741,
           "country_id":634,
           "code":"",
           "name":"Al Ghuwayrīyah",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2742,
           "country_id":634,
           "code":"",
           "name":"Al Jumaylīyah",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2743,
           "country_id":634,
           "code":"",
           "name":"Al Khawr",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2744,
           "country_id":634,
           "code":"",
           "name":"Al Wakrah Municipality",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2745,
           "country_id":634,
           "code":"",
           "name":"Ar Rayyān",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2746,
           "country_id":634,
           "code":"",
           "name":"Jarayan al Batinah",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2747,
           "country_id":634,
           "code":"",
           "name":"Madīnat ash Shamāl",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2748,
           "country_id":634,
           "code":"",
           "name":"Umm Şalāl",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2749,
           "country_id":634,
           "code":"",
           "name":"Al Wakrah",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2750,
           "country_id":634,
           "code":"",
           "name":"Jarayān al Bāţinah",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2751,
           "country_id":634,
           "code":"",
           "name":"Umm Sa‘īd",
           "standard_timezone":"(UTC+3.0) Qatar",
           "timezone":"Asia/Qatar"
        },
        {
           "id":2752,
           "country_id":638,
           "code":"",
           "name":"Réunion",
           "standard_timezone":"(UTC-4.0) Reunion",
           "timezone":"Indian/Reunion"
        },
        {
           "id":2753,
           "country_id":642,
           "code":"",
           "name":"Alba",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2754,
           "country_id":642,
           "code":"",
           "name":"Arad",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2755,
           "country_id":642,
           "code":"",
           "name":"Argeş",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2756,
           "country_id":642,
           "code":"",
           "name":"Bacău",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2757,
           "country_id":642,
           "code":"",
           "name":"Bihor",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2758,
           "country_id":642,
           "code":"",
           "name":"Bistriţa-Năsăud",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2759,
           "country_id":642,
           "code":"",
           "name":"Botoşani",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2760,
           "country_id":642,
           "code":"",
           "name":"Brăila",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2761,
           "country_id":642,
           "code":"",
           "name":"Braşov",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2762,
           "country_id":642,
           "code":"",
           "name":"Bucureşti",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2763,
           "country_id":642,
           "code":"",
           "name":"Buzău",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2764,
           "country_id":642,
           "code":"",
           "name":"Caraş-Severin",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2765,
           "country_id":642,
           "code":"",
           "name":"Cluj",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2766,
           "country_id":642,
           "code":"",
           "name":"Constanţa",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2767,
           "country_id":642,
           "code":"",
           "name":"Covasna",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2768,
           "country_id":642,
           "code":"",
           "name":"Dâmboviţa",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2769,
           "country_id":642,
           "code":"",
           "name":"Dolj",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2770,
           "country_id":642,
           "code":"",
           "name":"Galaţi",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2771,
           "country_id":642,
           "code":"",
           "name":"Gorj",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2772,
           "country_id":642,
           "code":"",
           "name":"Harghita",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2773,
           "country_id":642,
           "code":"",
           "name":"Hunedoara",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2774,
           "country_id":642,
           "code":"",
           "name":"Ialomiţa",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2775,
           "country_id":642,
           "code":"",
           "name":"Iaşi",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2776,
           "country_id":642,
           "code":"",
           "name":"Maramureş",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2777,
           "country_id":642,
           "code":"",
           "name":"Mehedinţi",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2778,
           "country_id":642,
           "code":"",
           "name":"Mureş",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2779,
           "country_id":642,
           "code":"",
           "name":"Neamţ",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2780,
           "country_id":642,
           "code":"",
           "name":"Olt",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2781,
           "country_id":642,
           "code":"",
           "name":"Prahova",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2782,
           "country_id":642,
           "code":"",
           "name":"Sălaj",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2783,
           "country_id":642,
           "code":"",
           "name":"Satu Mare",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2784,
           "country_id":642,
           "code":"",
           "name":"Sibiu",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2785,
           "country_id":642,
           "code":"",
           "name":"Suceava",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2786,
           "country_id":642,
           "code":"",
           "name":"Teleorman",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2787,
           "country_id":642,
           "code":"",
           "name":"Timiş",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2788,
           "country_id":642,
           "code":"",
           "name":"Tulcea",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2789,
           "country_id":642,
           "code":"",
           "name":"Vaslui",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2790,
           "country_id":642,
           "code":"",
           "name":"Vâlcea",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2791,
           "country_id":642,
           "code":"",
           "name":"Judeţul Vrancea",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2792,
           "country_id":642,
           "code":"",
           "name":"Călăraşi",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2793,
           "country_id":642,
           "code":"",
           "name":"Giurgiu",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2794,
           "country_id":642,
           "code":"",
           "name":"Ilfov",
           "standard_timezone":"(UTC+3.0) Bucharest",
           "timezone":"Europe/Bucharest"
        },
        {
           "id":2795,
           "country_id":643,
           "code":"",
           "name":"Adygeya",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2796,
           "country_id":643,
           "code":"",
           "name":"Altay",
           "standard_timezone":"(UTC+8.0) Krasnoyarsk",
           "timezone":"Asia/Krasnoyarsk"
        },
        {
           "id":2797,
           "country_id":643,
           "code":"",
           "name":"Altayskiy Kray",
           "standard_timezone":"(UTC+8.0) Krasnoyarsk",
           "timezone":"Asia/Krasnoyarsk"
        },
        {
           "id":2798,
           "country_id":643,
           "code":"",
           "name":"Amur",
           "standard_timezone":"(UTC+10.0) Yakutsk",
           "timezone":"Asia/Yakutsk"
        },
        {
           "id":2799,
           "country_id":643,
           "code":"",
           "name":"Arkhangelskaya oblast",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2800,
           "country_id":643,
           "code":"",
           "name":"Astrakhan",
           "standard_timezone":"(UTC+4.0) Samara",
           "timezone":"Europe/Samara"
        },
        {
           "id":2801,
           "country_id":643,
           "code":"",
           "name":"Bashkortostan",
           "standard_timezone":"(UTC+6.0) Yekaterinburg",
           "timezone":"Asia/Yekaterinburg"
        },
        {
           "id":2802,
           "country_id":643,
           "code":"",
           "name":"Belgorod",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2803,
           "country_id":643,
           "code":"",
           "name":"Brjansk",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2804,
           "country_id":643,
           "code":"",
           "name":"Buryatiya",
           "standard_timezone":"(UTC+9.0) Irkutsk",
           "timezone":"Asia/Irkutsk"
        },
        {
           "id":2805,
           "country_id":643,
           "code":"",
           "name":"Chechnya",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2806,
           "country_id":643,
           "code":"",
           "name":"Tsjeljabinsk",
           "standard_timezone":"(UTC+6.0) Yekaterinburg",
           "timezone":"Asia/Yekaterinburg"
        },
        {
           "id":2807,
           "country_id":643,
           "code":"",
           "name":"Zabaïkalski Kray",
           "standard_timezone":"(UTC+10.0) Yakutsk",
           "timezone":"Asia/Yakutsk"
        },
        {
           "id":2808,
           "country_id":643,
           "code":"",
           "name":"Chukotskiy Avtonomnyy Okrug",
           "standard_timezone":"(UTC+9.0) Kamchatka",
           "timezone":"Asia/Kamchatka"
        },
        {
           "id":2809,
           "country_id":643,
           "code":"",
           "name":"Chuvashia",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2810,
           "country_id":643,
           "code":"",
           "name":"Dagestan",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2811,
           "country_id":643,
           "code":"",
           "name":"Ingushetiya",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2812,
           "country_id":643,
           "code":"",
           "name":"Irkutsk",
           "standard_timezone":"(UTC+9.0) Irkutsk",
           "timezone":"Asia/Irkutsk"
        },
        {
           "id":2813,
           "country_id":643,
           "code":"",
           "name":"Ivanovo",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2814,
           "country_id":643,
           "code":"",
           "name":"Kabardino-Balkariya",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2815,
           "country_id":643,
           "code":"",
           "name":"Kaliningrad",
           "standard_timezone":"(UTC+3.0) Kaliningrad",
           "timezone":"Europe/Kaliningrad"
        },
        {
           "id":2816,
           "country_id":643,
           "code":"",
           "name":"Kalmykiya",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2817,
           "country_id":643,
           "code":"",
           "name":"Kaluga",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2818,
           "country_id":643,
           "code":"",
           "name":"Karachayevo-Cherkesiya",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2819,
           "country_id":643,
           "code":"",
           "name":"Kareliya",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2820,
           "country_id":643,
           "code":"",
           "name":"Kemerovo",
           "standard_timezone":"(UTC+8.0) Krasnoyarsk",
           "timezone":"Asia/Krasnoyarsk"
        },
        {
           "id":2821,
           "country_id":643,
           "code":"",
           "name":"Khabarovsk Krai",
           "standard_timezone":"(UTC+11.0) Vladivostok",
           "timezone":"Asia/Vladivostok"
        },
        {
           "id":2822,
           "country_id":643,
           "code":"",
           "name":"Khakasiya",
           "standard_timezone":"(UTC+8.0) Krasnoyarsk",
           "timezone":"Asia/Krasnoyarsk"
        },
        {
           "id":2823,
           "country_id":643,
           "code":"",
           "name":"Khanty-Mansiyskiy Avtonomnyy Okrug",
           "standard_timezone":"(UTC+6.0) Yekaterinburg",
           "timezone":"Asia/Yekaterinburg"
        },
        {
           "id":2824,
           "country_id":643,
           "code":"",
           "name":"Kirov",
           "standard_timezone":"(UTC+4.0) Samara",
           "timezone":"Europe/Samara"
        },
        {
           "id":2825,
           "country_id":643,
           "code":"",
           "name":"Komi",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2826,
           "country_id":643,
           "code":"",
           "name":"Kostroma",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2827,
           "country_id":643,
           "code":"",
           "name":"Krasnodarskiy Kray",
           "standard_timezone":"(UTC+8.0) Krasnoyarsk",
           "timezone":"Asia/Krasnoyarsk"
        },
        {
           "id":2828,
           "country_id":643,
           "code":"",
           "name":"Kurgan",
           "standard_timezone":"(UTC+6.0) Yekaterinburg",
           "timezone":"Asia/Yekaterinburg"
        },
        {
           "id":2829,
           "country_id":643,
           "code":"",
           "name":"Kursk",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2830,
           "country_id":643,
           "code":"",
           "name":"Leningradskaya Oblastʼ",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2831,
           "country_id":643,
           "code":"",
           "name":"Lipetsk",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2832,
           "country_id":643,
           "code":"",
           "name":"Magadan",
           "standard_timezone":"(UTC+12.0) Magadan",
           "timezone":"Asia/Magadan"
        },
        {
           "id":2833,
           "country_id":643,
           "code":"",
           "name":"Mariy-El",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2834,
           "country_id":643,
           "code":"",
           "name":"Mordoviya",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2835,
           "country_id":643,
           "code":"",
           "name":"Moskovskaya Oblastʼ",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2836,
           "country_id":643,
           "code":"",
           "name":"Moscow",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2837,
           "country_id":643,
           "code":"",
           "name":"Murmansk Oblast",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2838,
           "country_id":643,
           "code":"",
           "name":"Nenetskiy Avtonomnyy Okrug",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2839,
           "country_id":643,
           "code":"",
           "name":"Nizjnij Novgorod",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2840,
           "country_id":643,
           "code":"",
           "name":"Novgorod",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2841,
           "country_id":643,
           "code":"",
           "name":"Novosibirsk",
           "standard_timezone":"(UTC+7.0) Novosibirsk",
           "timezone":"Asia/Novosibirsk"
        },
        {
           "id":2842,
           "country_id":643,
           "code":"",
           "name":"Omsk",
           "standard_timezone":"(UTC+7.0) Omsk",
           "timezone":"Asia/Omsk"
        },
        {
           "id":2843,
           "country_id":643,
           "code":"",
           "name":"Orenburg",
           "standard_timezone":"(UTC+6.0) Yekaterinburg",
           "timezone":"Asia/Yekaterinburg"
        },
        {
           "id":2844,
           "country_id":643,
           "code":"",
           "name":"Orjol",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2845,
           "country_id":643,
           "code":"",
           "name":"Penza",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2846,
           "country_id":643,
           "code":"",
           "name":"Primorskiy Kray",
           "standard_timezone":"(UTC+11.0) Vladivostok",
           "timezone":"Asia/Vladivostok"
        },
        {
           "id":2847,
           "country_id":643,
           "code":"",
           "name":"Pskov",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2848,
           "country_id":643,
           "code":"",
           "name":"Rostov",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2849,
           "country_id":643,
           "code":"",
           "name":"Rjazan",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2850,
           "country_id":643,
           "code":"",
           "name":"Sakha",
           "standard_timezone":"(UTC+11.0) Vladivostok",
           "timezone":"Asia/Vladivostok"
        },
        {
           "id":2851,
           "country_id":643,
           "code":"",
           "name":"Sakhalin",
           "standard_timezone":"(UTC+11.0) Vladivostok",
           "timezone":"Asia/Vladivostok"
        },
        {
           "id":2852,
           "country_id":643,
           "code":"",
           "name":"Samara",
           "standard_timezone":"(UTC+4.0) Samara",
           "timezone":"Europe/Samara"
        },
        {
           "id":2853,
           "country_id":643,
           "code":"",
           "name":"Sankt-Peterburg",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2854,
           "country_id":643,
           "code":"",
           "name":"Saratov",
           "standard_timezone":"(UTC+4.0) Samara",
           "timezone":"Europe/Samara"
        },
        {
           "id":2855,
           "country_id":643,
           "code":"",
           "name":"Severnaya Osetiya-Alaniya",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2856,
           "country_id":643,
           "code":"",
           "name":"Smolensk",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2857,
           "country_id":643,
           "code":"",
           "name":"Stavropolʼskiy Kray",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2858,
           "country_id":643,
           "code":"",
           "name":"Sverdlovsk",
           "standard_timezone":"(UTC+6.0) Yekaterinburg",
           "timezone":"Asia/Yekaterinburg"
        },
        {
           "id":2859,
           "country_id":643,
           "code":"",
           "name":"Tambov",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2860,
           "country_id":643,
           "code":"",
           "name":"Tatarstan",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2861,
           "country_id":643,
           "code":"",
           "name":"Tomsk",
           "standard_timezone":"(UTC+8.0) Krasnoyarsk",
           "timezone":"Asia/Krasnoyarsk"
        },
        {
           "id":2862,
           "country_id":643,
           "code":"",
           "name":"Tula",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2863,
           "country_id":643,
           "code":"",
           "name":"Tverskaya Oblast’",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2864,
           "country_id":643,
           "code":"",
           "name":"Tjumen",
           "standard_timezone":"(UTC+6.0) Yekaterinburg",
           "timezone":"Asia/Yekaterinburg"
        },
        {
           "id":2865,
           "country_id":643,
           "code":"",
           "name":"Tyva",
           "standard_timezone":"(UTC+8.0) Krasnoyarsk",
           "timezone":"Asia/Krasnoyarsk"
        },
        {
           "id":2866,
           "country_id":643,
           "code":"",
           "name":"Udmurtiya",
           "standard_timezone":"(UTC+4.0) Samara",
           "timezone":"Europe/Samara"
        },
        {
           "id":2867,
           "country_id":643,
           "code":"",
           "name":"Uljanovsk",
           "standard_timezone":"(UTC+4.0) Samara",
           "timezone":"Europe/Samara"
        },
        {
           "id":2868,
           "country_id":643,
           "code":"",
           "name":"Vladimir",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2869,
           "country_id":643,
           "code":"",
           "name":"Volgograd",
           "standard_timezone":"(UTC+4.0) Samara",
           "timezone":"Europe/Samara"
        },
        {
           "id":2870,
           "country_id":643,
           "code":"",
           "name":"Vologda",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2871,
           "country_id":643,
           "code":"",
           "name":"Voronezj",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2872,
           "country_id":643,
           "code":"",
           "name":"Yamalo-Nenetskiy Avtonomnyy Okrug",
           "standard_timezone":"(UTC+6.0) Yekaterinburg",
           "timezone":"Asia/Yekaterinburg"
        },
        {
           "id":2873,
           "country_id":643,
           "code":"",
           "name":"Jaroslavl",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2874,
           "country_id":643,
           "code":"",
           "name":"Jewish Autonomous Oblast",
           "standard_timezone":"(UTC+11.0) Vladivostok",
           "timezone":"Asia/Vladivostok"
        },
        {
           "id":2875,
           "country_id":643,
           "code":"",
           "name":"Perm",
           "standard_timezone":"(UTC+6.0) Yekaterinburg",
           "timezone":"Asia/Yekaterinburg"
        },
        {
           "id":2876,
           "country_id":643,
           "code":"",
           "name":"Krasnoyarskiy Kray",
           "standard_timezone":"(UTC+8.0) Krasnoyarsk",
           "timezone":"Asia/Krasnoyarsk"
        },
        {
           "id":2877,
           "country_id":643,
           "code":"",
           "name":"Kamtsjatka",
           "standard_timezone":"(UTC+9.0) Kamchatka",
           "timezone":"Asia/Kamchatka"
        },
        {
           "id":2878,
           "country_id":643,
           "code":"",
           "name":"RSJA",
           "standard_timezone":"(UTC+4.0) Moscow",
           "timezone":"Europe/Moscow"
        },
        {
           "id":2879,
           "country_id":646,
           "code":"",
           "name":"Eastern Province",
           "standard_timezone":"(UTC+2.0) Kigali",
           "timezone":"Africa/Kigali"
        },
        {
           "id":2880,
           "country_id":646,
           "code":"",
           "name":"Kigali City",
           "standard_timezone":"(UTC+2.0) Kigali",
           "timezone":"Africa/Kigali"
        },
        {
           "id":2881,
           "country_id":646,
           "code":"",
           "name":"Northern Province",
           "standard_timezone":"(UTC+2.0) Kigali",
           "timezone":"Africa/Kigali"
        },
        {
           "id":2882,
           "country_id":646,
           "code":"",
           "name":"Western Province",
           "standard_timezone":"(UTC+2.0) Kigali",
           "timezone":"Africa/Kigali"
        },
        {
           "id":2883,
           "country_id":646,
           "code":"",
           "name":"Southern Province",
           "standard_timezone":"(UTC+2.0) Kigali",
           "timezone":"Africa/Kigali"
        },
        {
           "id":2884,
           "country_id":654,
           "code":"",
           "name":"Ascension",
           "standard_timezone":"(UTC+1.0) St_Helena",
           "timezone":"Atlantic/St_Helena"
        },
        {
           "id":2885,
           "country_id":654,
           "code":"",
           "name":"Saint Helena",
           "standard_timezone":"(UTC+1.0) St_Helena",
           "timezone":"Atlantic/St_Helena"
        },
        {
           "id":2886,
           "country_id":654,
           "code":"",
           "name":"Tristan da Cunha",
           "standard_timezone":"(UTC+1.0) St_Helena",
           "timezone":"Atlantic/St_Helena"
        },
        {
           "id":2887,
           "country_id":659,
           "code":"",
           "name":"Christ Church Nichola Town",
           "standard_timezone":"(UTC-4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2888,
           "country_id":659,
           "code":"",
           "name":"Saint Anne Sandy Point",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2889,
           "country_id":659,
           "code":"",
           "name":"Saint George Basseterre",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2890,
           "country_id":659,
           "code":"",
           "name":"Saint George Gingerland",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2891,
           "country_id":659,
           "code":"",
           "name":"Saint James Windwa",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2892,
           "country_id":659,
           "code":"",
           "name":"Saint John Capesterre",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2893,
           "country_id":659,
           "code":"",
           "name":"Saint John Figtree",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2894,
           "country_id":659,
           "code":"",
           "name":"Saint Mary Cayon",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2895,
           "country_id":659,
           "code":"",
           "name":"Saint Paul Capesterre",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2896,
           "country_id":659,
           "code":"",
           "name":"Saint Paul Charlestown",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2897,
           "country_id":659,
           "code":"",
           "name":"Saint Peter Basseterre",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2898,
           "country_id":659,
           "code":"",
           "name":"Saint Thomas Lowland",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2899,
           "country_id":659,
           "code":"",
           "name":"Saint Thomas Middle Island",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2900,
           "country_id":659,
           "code":"",
           "name":"Trinity Palmetto Point",
           "standard_timezone":"(UTC -4:00)St_Kitts",
           "timezone":"America/St_Kitts"
        },
        {
           "id":2901,
           "country_id":660,
           "code":"",
           "name":"Anguilla",
           "standard_timezone":"(UTC-4.0) Anguilla",
           "timezone":"America/Anguilla"
        },
        {
           "id":2902,
           "country_id":662,
           "code":"",
           "name":"Anse-la-Raye",
           "standard_timezone":"(UTC -4:00)St_Lucia",
           "timezone":"America/St_Lucia"
        },
        {
           "id":2903,
           "country_id":662,
           "code":"",
           "name":"Dauphin",
           "standard_timezone":"(UTC -4:00)St_Lucia",
           "timezone":"America/St_Lucia"
        },
        {
           "id":2904,
           "country_id":662,
           "code":"",
           "name":"Castries",
           "standard_timezone":"(UTC -4:00)St_Lucia",
           "timezone":"America/St_Lucia"
        },
        {
           "id":2905,
           "country_id":662,
           "code":"",
           "name":"Choiseul",
           "standard_timezone":"(UTC -4:00)St_Lucia",
           "timezone":"America/St_Lucia"
        },
        {
           "id":2906,
           "country_id":662,
           "code":"",
           "name":"Dennery",
           "standard_timezone":"(UTC -4:00)St_Lucia",
           "timezone":"America/St_Lucia"
        },
        {
           "id":2907,
           "country_id":662,
           "code":"",
           "name":"Gros-Islet",
           "standard_timezone":"(UTC -4:00)St_Lucia",
           "timezone":"America/St_Lucia"
        },
        {
           "id":2908,
           "country_id":662,
           "code":"",
           "name":"Laborie",
           "standard_timezone":"(UTC -4:00)St_Lucia",
           "timezone":"America/St_Lucia"
        },
        {
           "id":2909,
           "country_id":662,
           "code":"",
           "name":"Micoud",
           "standard_timezone":"(UTC -4:00)St_Lucia",
           "timezone":"America/St_Lucia"
        },
        {
           "id":2910,
           "country_id":662,
           "code":"",
           "name":"Soufrière",
           "standard_timezone":"(UTC -4:00)St_Lucia",
           "timezone":"America/St_Lucia"
        },
        {
           "id":2911,
           "country_id":662,
           "code":"",
           "name":"Vieux-Fort",
           "standard_timezone":"(UTC -4:00)St_Lucia",
           "timezone":"America/St_Lucia"
        },
        {
           "id":2912,
           "country_id":662,
           "code":"",
           "name":"Praslin",
           "standard_timezone":"(UTC -4:00)St_Lucia",
           "timezone":"America/St_Lucia"
        },
        {
           "id":2913,
           "country_id":666,
           "code":"",
           "name":"Saint-Pierre-et-Miquelon",
           "standard_timezone":"(UTC-2.0) Miquelon",
           "timezone":"America/Miquelon"
        },
        {
           "id":2914,
           "country_id":670,
           "code":"",
           "name":"Charlotte",
           "standard_timezone":"(UTC+1.0) St_Vincent",
           "timezone":"America/St_Vincent"
        },
        {
           "id":2915,
           "country_id":670,
           "code":"",
           "name":"Saint Andrew",
           "standard_timezone":"(UTC+1.0) St_Vincent",
           "timezone":"America/St_Vincent"
        },
        {
           "id":2916,
           "country_id":670,
           "code":"",
           "name":"Saint David",
           "standard_timezone":"(UTC+1.0) St_Vincent",
           "timezone":"America/St_Vincent"
        },
        {
           "id":2917,
           "country_id":670,
           "code":"",
           "name":"Saint George",
           "standard_timezone":"(UTC+1.0) St_Vincent",
           "timezone":"America/St_Vincent"
        },
        {
           "id":2918,
           "country_id":670,
           "code":"",
           "name":"Saint Patrick",
           "standard_timezone":"(UTC+1.0) St_Vincent",
           "timezone":"America/St_Vincent"
        },
        {
           "id":2919,
           "country_id":670,
           "code":"",
           "name":"Grenadines",
           "standard_timezone":"(UTC+1.0) St_Vincent",
           "timezone":"America/St_Vincent"
        },
        {
           "id":2920,
           "country_id":674,
           "code":"",
           "name":"Acquaviva",
           "standard_timezone":"(UTC+2.0) San_Marino",
           "timezone":"Europe/San_Marino"
        },
        {
           "id":2921,
           "country_id":674,
           "code":"",
           "name":"Chiesanuova",
           "standard_timezone":"(UTC+2.0) San_Marino",
           "timezone":"Europe/San_Marino"
        },
        {
           "id":2922,
           "country_id":674,
           "code":"",
           "name":"Domagnano",
           "standard_timezone":"(UTC+2.0) San_Marino",
           "timezone":"Europe/San_Marino"
        },
        {
           "id":2923,
           "country_id":674,
           "code":"",
           "name":"Faetano",
           "standard_timezone":"(UTC+2.0) San_Marino",
           "timezone":"Europe/San_Marino"
        },
        {
           "id":2924,
           "country_id":674,
           "code":"",
           "name":"Fiorentino",
           "standard_timezone":"(UTC+2.0) San_Marino",
           "timezone":"Europe/San_Marino"
        },
        {
           "id":2925,
           "country_id":674,
           "code":"",
           "name":"Borgo Maggiore",
           "standard_timezone":"(UTC+2.0) San_Marino",
           "timezone":"Europe/San_Marino"
        },
        {
           "id":2926,
           "country_id":674,
           "code":"",
           "name":"San Marino",
           "standard_timezone":"(UTC+2.0) San_Marino",
           "timezone":"Europe/San_Marino"
        },
        {
           "id":2927,
           "country_id":674,
           "code":"",
           "name":"Montegiardino",
           "standard_timezone":"(UTC+2.0) San_Marino",
           "timezone":"Europe/San_Marino"
        },
        {
           "id":2928,
           "country_id":674,
           "code":"",
           "name":"Serravalle",
           "standard_timezone":"(UTC+2.0) San_Marino",
           "timezone":"Europe/San_Marino"
        },
        {
           "id":2929,
           "country_id":678,
           "code":"",
           "name":"Príncipe",
           "standard_timezone":"(UTC+0.0) Sao_Tome",
           "timezone":"Africa/Sao_Tome"
        },
        {
           "id":2930,
           "country_id":678,
           "code":"",
           "name":"Príncipe",
           "standard_timezone":"(UTC+0.0) Sao_Tome",
           "timezone":"Africa/Sao_Tome"
        },
        {
           "id":2931,
           "country_id":678,
           "code":"",
           "name":"São Tomé",
           "standard_timezone":"(UTC+0.0) Sao_Tome",
           "timezone":"Africa/Sao_Tome"
        },
        {
           "id":2932,
           "country_id":682,
           "code":"",
           "name":"Al Bāḩah",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2933,
           "country_id":682,
           "code":"",
           "name":"Al Madīnah",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2934,
           "country_id":682,
           "code":"",
           "name":"Ash Sharqīyah",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2935,
           "country_id":682,
           "code":"",
           "name":"Al Qaşīm",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2936,
           "country_id":682,
           "code":"",
           "name":"Ar Riyāḑ",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2937,
           "country_id":682,
           "code":"",
           "name":"‘Asīr",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2938,
           "country_id":682,
           "code":"",
           "name":"Ḩāʼil",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2939,
           "country_id":682,
           "code":"",
           "name":"Makkah",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2940,
           "country_id":682,
           "code":"",
           "name":"Northern Borders Region",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2941,
           "country_id":682,
           "code":"",
           "name":"Najrān",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2942,
           "country_id":682,
           "code":"",
           "name":"Jīzān",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2943,
           "country_id":682,
           "code":"",
           "name":"Tabūk",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2944,
           "country_id":682,
           "code":"",
           "name":"Al Jawf",
           "standard_timezone":"(UTC+3.0) Riyadh",
           "timezone":"Asia/Riyadh"
        },
        {
           "id":2945,
           "country_id":686,
           "code":"",
           "name":"Dakar",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2946,
           "country_id":686,
           "code":"",
           "name":"Diourbel",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2947,
           "country_id":686,
           "code":"",
           "name":"Saint-Louis",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2948,
           "country_id":686,
           "code":"",
           "name":"Tambacounda",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2949,
           "country_id":686,
           "code":"",
           "name":"Thiès",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2950,
           "country_id":686,
           "code":"",
           "name":"Louga",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2951,
           "country_id":686,
           "code":"",
           "name":"Fatick",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2952,
           "country_id":686,
           "code":"",
           "name":"Kaolack",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2953,
           "country_id":686,
           "code":"",
           "name":"Kolda Region",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2954,
           "country_id":686,
           "code":"",
           "name":"Ziguinchor",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2955,
           "country_id":686,
           "code":"",
           "name":"Louga",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2956,
           "country_id":686,
           "code":"",
           "name":"Saint-Louis",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2957,
           "country_id":686,
           "code":"",
           "name":"Matam",
           "standard_timezone":"(UTC+0.0) Dakar",
           "timezone":"Africa/Dakar"
        },
        {
           "id":2958,
           "country_id":688,
           "code":"",
           "name":"Autonomna Pokrajina Vojvodina",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":2959,
           "country_id":690,
           "code":"",
           "name":"Anse aux Pins",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2960,
           "country_id":690,
           "code":"",
           "name":"Anse Boileau",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2961,
           "country_id":690,
           "code":"",
           "name":"Anse Etoile",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2962,
           "country_id":690,
           "code":"",
           "name":"Anse Louis",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2963,
           "country_id":690,
           "code":"",
           "name":"Anse Royale",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2964,
           "country_id":690,
           "code":"",
           "name":"Baie Lazare",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2965,
           "country_id":690,
           "code":"",
           "name":"Baie Sainte Anne",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2966,
           "country_id":690,
           "code":"",
           "name":"Beau Vallon",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2967,
           "country_id":690,
           "code":"",
           "name":"Bel Air",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2968,
           "country_id":690,
           "code":"",
           "name":"Bel Ombre",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2969,
           "country_id":690,
           "code":"",
           "name":"Cascade",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2970,
           "country_id":690,
           "code":"",
           "name":"Glacis",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2971,
           "country_id":690,
           "code":"",
           "name":"Saint Thomas Middle Island Parish",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2972,
           "country_id":690,
           "code":"",
           "name":"Grand Anse Praslin",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2973,
           "country_id":690,
           "code":"",
           "name":"Trinity Palmetto Point Parish",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2974,
           "country_id":690,
           "code":"",
           "name":"La Riviere Anglaise",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2975,
           "country_id":690,
           "code":"",
           "name":"Mont Buxton",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2976,
           "country_id":690,
           "code":"",
           "name":"Mont Fleuri",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2977,
           "country_id":690,
           "code":"",
           "name":"Plaisance",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2978,
           "country_id":690,
           "code":"",
           "name":"Pointe Larue",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2979,
           "country_id":690,
           "code":"",
           "name":"Port Glaud",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2980,
           "country_id":690,
           "code":"",
           "name":"Saint Louis",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2981,
           "country_id":690,
           "code":"",
           "name":"Takamaka",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2982,
           "country_id":690,
           "code":"",
           "name":"Anse aux Pins",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2983,
           "country_id":690,
           "code":"",
           "name":"Inner Islands",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2984,
           "country_id":690,
           "code":"",
           "name":"English River",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2985,
           "country_id":690,
           "code":"",
           "name":"Port Glaud",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2986,
           "country_id":690,
           "code":"",
           "name":"Baie Lazare",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2987,
           "country_id":690,
           "code":"",
           "name":"Beau Vallon",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2988,
           "country_id":690,
           "code":"",
           "name":"Bel Ombre",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2989,
           "country_id":690,
           "code":"",
           "name":"Glacis",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2990,
           "country_id":690,
           "code":"",
           "name":"Grand Anse Mahe",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2991,
           "country_id":690,
           "code":"",
           "name":"Grand Anse Praslin",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2992,
           "country_id":690,
           "code":"",
           "name":"Inner Islands",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2993,
           "country_id":690,
           "code":"",
           "name":"English River",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2994,
           "country_id":690,
           "code":"",
           "name":"Mont Fleuri",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2995,
           "country_id":690,
           "code":"",
           "name":"Plaisance",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2996,
           "country_id":690,
           "code":"",
           "name":"Pointe Larue",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2997,
           "country_id":690,
           "code":"",
           "name":"Port Glaud",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2998,
           "country_id":690,
           "code":"",
           "name":"Takamaka",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":2999,
           "country_id":690,
           "code":"",
           "name":"Au Cap",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":3000,
           "country_id":690,
           "code":"",
           "name":"Les Mamelles",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":3001,
           "country_id":690,
           "code":"",
           "name":"Roche Caiman",
           "standard_timezone":"(UTC+5.50) Mahe",
           "timezone":"Indian/Mahe"
        },
        {
           "id":3002,
           "country_id":694,
           "code":"",
           "name":"Eastern Province",
           "standard_timezone":"(UTC+0.0) Freetown",
           "timezone":"Africa/Freetown"
        },
        {
           "id":3003,
           "country_id":694,
           "code":"",
           "name":"Northern Province",
           "standard_timezone":"(UTC+0.0) Freetown",
           "timezone":"Africa/Freetown"
        },
        {
           "id":3004,
           "country_id":694,
           "code":"",
           "name":"Southern Province",
           "standard_timezone":"(UTC+0.0) Freetown",
           "timezone":"Africa/Freetown"
        },
        {
           "id":3005,
           "country_id":694,
           "code":"",
           "name":"Western Area",
           "standard_timezone":"(UTC+0.0) Freetown",
           "timezone":"Africa/Freetown"
        },
        {
           "id":3006,
           "country_id":702,
           "code":"",
           "name":"Singapore",
           "standard_timezone":"(UTC+8.0) Singapore",
           "timezone":"Asia/Singapore"
        },
        {
           "id":3007,
           "country_id":703,
           "code":"",
           "name":"Banskobystrický",
           "standard_timezone":"(UTC+2.0) Bratislava",
           "timezone":"Europe/Bratislava"
        },
        {
           "id":3008,
           "country_id":703,
           "code":"",
           "name":"Bratislavský",
           "standard_timezone":"(UTC+2.0) Bratislava",
           "timezone":"Europe/Bratislava"
        },
        {
           "id":3009,
           "country_id":703,
           "code":"",
           "name":"Košický",
           "standard_timezone":"(UTC+2.0) Bratislava",
           "timezone":"Europe/Bratislava"
        },
        {
           "id":3010,
           "country_id":703,
           "code":"",
           "name":"Nitriansky",
           "standard_timezone":"(UTC+2.0) Bratislava",
           "timezone":"Europe/Bratislava"
        },
        {
           "id":3011,
           "country_id":703,
           "code":"",
           "name":"Prešovský",
           "standard_timezone":"(UTC+2.0) Bratislava",
           "timezone":"Europe/Bratislava"
        },
        {
           "id":3012,
           "country_id":703,
           "code":"",
           "name":"Trenčiansky",
           "standard_timezone":"(UTC+2.0) Bratislava",
           "timezone":"Europe/Bratislava"
        },
        {
           "id":3013,
           "country_id":703,
           "code":"",
           "name":"Trnavský",
           "standard_timezone":"(UTC+2.0) Bratislava",
           "timezone":"Europe/Bratislava"
        },
        {
           "id":3014,
           "country_id":703,
           "code":"",
           "name":"Žilinský",
           "standard_timezone":"(UTC+2.0) Bratislava",
           "timezone":"Europe/Bratislava"
        },
        {
           "id":3015,
           "country_id":704,
           "code":"",
           "name":"An Giang",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3016,
           "country_id":704,
           "code":"",
           "name":"Bắc Thái Tỉnh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3017,
           "country_id":704,
           "code":"",
           "name":"Bến Tre",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3018,
           "country_id":704,
           "code":"",
           "name":"Cao Bang",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3019,
           "country_id":704,
           "code":"",
           "name":"Cao Bằng",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3020,
           "country_id":704,
           "code":"",
           "name":"Ten Bai",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3021,
           "country_id":704,
           "code":"",
           "name":"Ðồng Tháp",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3022,
           "country_id":704,
           "code":"",
           "name":"Hà Bắc Tỉnh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3023,
           "country_id":704,
           "code":"",
           "name":"Hải Hưng Tỉnh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3024,
           "country_id":704,
           "code":"",
           "name":"Hải Phòng",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3025,
           "country_id":704,
           "code":"",
           "name":"Hoa Binh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3026,
           "country_id":704,
           "code":"",
           "name":"Ha Tay",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3027,
           "country_id":704,
           "code":"",
           "name":"Hồ Chí Minh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3028,
           "country_id":704,
           "code":"",
           "name":"Kiến Giang",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3029,
           "country_id":704,
           "code":"",
           "name":"Lâm Ðồng",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3030,
           "country_id":704,
           "code":"",
           "name":"Long An",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3031,
           "country_id":704,
           "code":"",
           "name":"Minh Hải Tỉnh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3032,
           "country_id":704,
           "code":"",
           "name":"Thua Thien-Hue",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3033,
           "country_id":704,
           "code":"",
           "name":"Quang Nam",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3034,
           "country_id":704,
           "code":"",
           "name":"Kon Tum",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3035,
           "country_id":704,
           "code":"",
           "name":"Quảng Nam-Ðà Nẵng Tỉnh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3036,
           "country_id":704,
           "code":"",
           "name":"Quảng Ninh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3037,
           "country_id":704,
           "code":"",
           "name":"Sông Bé Tỉnh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3038,
           "country_id":704,
           "code":"",
           "name":"Sơn La",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3039,
           "country_id":704,
           "code":"",
           "name":"Tây Ninh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3040,
           "country_id":704,
           "code":"",
           "name":"Thanh Hóa",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3041,
           "country_id":704,
           "code":"",
           "name":"Thái Bình",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3042,
           "country_id":704,
           "code":"",
           "name":"Nin Thuan",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3043,
           "country_id":704,
           "code":"",
           "name":"Tiền Giang",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3044,
           "country_id":704,
           "code":"",
           "name":"Vinh Phú Tỉnh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3045,
           "country_id":704,
           "code":"",
           "name":"Lạng Sơn",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3046,
           "country_id":704,
           "code":"",
           "name":"Binh Thuan",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3047,
           "country_id":704,
           "code":"",
           "name":"Long An",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3048,
           "country_id":704,
           "code":"",
           "name":"Ðồng Nai",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3049,
           "country_id":704,
           "code":"",
           "name":"Ha Nội",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3050,
           "country_id":704,
           "code":"",
           "name":"Bà Rịa-Vũng Tàu",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3051,
           "country_id":704,
           "code":"",
           "name":"Bình Ðịnh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3052,
           "country_id":704,
           "code":"",
           "name":"Bình Thuận",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3053,
           "country_id":704,
           "code":"",
           "name":"Gia Lai",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3054,
           "country_id":704,
           "code":"",
           "name":"Hà Giang",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3055,
           "country_id":704,
           "code":"",
           "name":"Hà Tây",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3056,
           "country_id":704,
           "code":"",
           "name":"Hà Tĩnh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3057,
           "country_id":704,
           "code":"",
           "name":"Hòa Bình",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3058,
           "country_id":704,
           "code":"",
           "name":"Khánh Hòa",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3059,
           "country_id":704,
           "code":"",
           "name":"Kon Tum",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3060,
           "country_id":704,
           "code":"",
           "name":"Nam Hà Tỉnh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3061,
           "country_id":704,
           "code":"",
           "name":"Nghệ An",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3062,
           "country_id":704,
           "code":"",
           "name":"Ninh Bình",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3063,
           "country_id":704,
           "code":"",
           "name":"Ninh Thuận",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3064,
           "country_id":704,
           "code":"",
           "name":"Phú Yên",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3065,
           "country_id":704,
           "code":"",
           "name":"Quảng Bình",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3066,
           "country_id":704,
           "code":"",
           "name":"Quảng Ngãi",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3067,
           "country_id":704,
           "code":"",
           "name":"Quảng Trị",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3068,
           "country_id":704,
           "code":"",
           "name":"Sóc Trăng",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3069,
           "country_id":704,
           "code":"",
           "name":"Thừa Thiên-Huế",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3070,
           "country_id":704,
           "code":"",
           "name":"Trà Vinh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3071,
           "country_id":704,
           "code":"",
           "name":"Tuyên Quang",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3072,
           "country_id":704,
           "code":"",
           "name":"Vĩnh Long",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3073,
           "country_id":704,
           "code":"",
           "name":"Yên Bái",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3074,
           "country_id":704,
           "code":"",
           "name":"Bắc Giang",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3075,
           "country_id":704,
           "code":"",
           "name":"Bắc Kạn",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3076,
           "country_id":704,
           "code":"",
           "name":"Bạc Liêu",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3077,
           "country_id":704,
           "code":"",
           "name":"Bắc Ninh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3078,
           "country_id":704,
           "code":"",
           "name":"Bình Dương",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3079,
           "country_id":704,
           "code":"",
           "name":"Bình Phước",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3080,
           "country_id":704,
           "code":"",
           "name":"Cà Mau",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3081,
           "country_id":704,
           "code":"",
           "name":"Ðà Nẵng",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3082,
           "country_id":704,
           "code":"",
           "name":"Hải Dương",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3083,
           "country_id":704,
           "code":"",
           "name":"Hà Nam",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3084,
           "country_id":704,
           "code":"",
           "name":"Hưng Yên",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3085,
           "country_id":704,
           "code":"",
           "name":"Nam Ðịnh",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3086,
           "country_id":704,
           "code":"",
           "name":"Phú Thọ",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3087,
           "country_id":704,
           "code":"",
           "name":"Quảng Nam",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3088,
           "country_id":704,
           "code":"",
           "name":"Thái Nguyên",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3089,
           "country_id":704,
           "code":"",
           "name":"Vĩnh Phúc",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3090,
           "country_id":704,
           "code":"",
           "name":"Cần Thơ",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3091,
           "country_id":704,
           "code":"",
           "name":"Ðắc Lắk",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3092,
           "country_id":704,
           "code":"",
           "name":"Lai Châu",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3093,
           "country_id":704,
           "code":"",
           "name":"Lào Cai",
           "standard_timezone":"(UTC+7.0) Ho_Chi_Minh",
           "timezone":"Asia/Ho_Chi_Minh"
        },
        {
           "id":3094,
           "country_id":705,
           "code":"",
           "name":"Notranjska",
           "standard_timezone":"(UTC+2.0) Ljubljana",
           "timezone":"Europe/Ljubljana"
        },
        {
           "id":3095,
           "country_id":705,
           "code":"",
           "name":"Koroška",
           "standard_timezone":"(UTC+2.0) Ljubljana",
           "timezone":"Europe/Ljubljana"
        },
        {
           "id":3096,
           "country_id":705,
           "code":"",
           "name":"Štajerska",
           "standard_timezone":"(UTC+2.0) Ljubljana",
           "timezone":"Europe/Ljubljana"
        },
        {
           "id":3097,
           "country_id":705,
           "code":"",
           "name":"Prekmurje",
           "standard_timezone":"(UTC+2.0) Ljubljana",
           "timezone":"Europe/Ljubljana"
        },
        {
           "id":3098,
           "country_id":705,
           "code":"",
           "name":"Primorska",
           "standard_timezone":"(UTC+2.0) Ljubljana",
           "timezone":"Europe/Ljubljana"
        },
        {
           "id":3099,
           "country_id":705,
           "code":"",
           "name":"Gorenjska",
           "standard_timezone":"(UTC+2.0) Ljubljana",
           "timezone":"Europe/Ljubljana"
        },
        {
           "id":3100,
           "country_id":705,
           "code":"",
           "name":"Dolenjska",
           "standard_timezone":"(UTC+2.0) Ljubljana",
           "timezone":"Europe/Ljubljana"
        },
        {
           "id":3101,
           "country_id":706,
           "code":"",
           "name":"Bakool",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3102,
           "country_id":706,
           "code":"",
           "name":"Banaadir",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3103,
           "country_id":706,
           "code":"",
           "name":"Bari",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3104,
           "country_id":706,
           "code":"",
           "name":"Bay",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3105,
           "country_id":706,
           "code":"",
           "name":"Galguduud",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3106,
           "country_id":706,
           "code":"",
           "name":"Gedo",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3107,
           "country_id":706,
           "code":"",
           "name":"Hiiraan",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3108,
           "country_id":706,
           "code":"",
           "name":"Middle Juba",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3109,
           "country_id":706,
           "code":"",
           "name":"Lower Juba",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3110,
           "country_id":706,
           "code":"",
           "name":"Mudug",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3111,
           "country_id":706,
           "code":"",
           "name":"Sanaag",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3112,
           "country_id":706,
           "code":"",
           "name":"Middle Shabele",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3113,
           "country_id":706,
           "code":"",
           "name":"Shabeellaha Hoose",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3114,
           "country_id":706,
           "code":"",
           "name":"Nugaal",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3115,
           "country_id":706,
           "code":"",
           "name":"Togdheer",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3116,
           "country_id":706,
           "code":"",
           "name":"Woqooyi Galbeed",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3117,
           "country_id":706,
           "code":"",
           "name":"Awdal",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3118,
           "country_id":706,
           "code":"",
           "name":"Sool",
           "standard_timezone":"(UTC+3.0) Mogadishu",
           "timezone":"Africa/Mogadishu"
        },
        {
           "id":3119,
           "country_id":710,
           "code":"",
           "name":"KwaZulu-Natal",
           "standard_timezone":"(UTC+2.0) Johannesburg",
           "timezone":"Africa/Johannesburg"
        },
        {
           "id":3120,
           "country_id":710,
           "code":"",
           "name":"Free State",
           "standard_timezone":"(UTC+2.0) Johannesburg",
           "timezone":"Africa/Johannesburg"
        },
        {
           "id":3121,
           "country_id":710,
           "code":"",
           "name":"Eastern Cape",
           "standard_timezone":"(UTC+2.0) Johannesburg",
           "timezone":"Africa/Johannesburg"
        },
        {
           "id":3122,
           "country_id":710,
           "code":"",
           "name":"Gauteng",
           "standard_timezone":"(UTC+2.0) Johannesburg",
           "timezone":"Africa/Johannesburg"
        },
        {
           "id":3123,
           "country_id":710,
           "code":"",
           "name":"Mpumalanga",
           "standard_timezone":"(UTC+2.0) Johannesburg",
           "timezone":"Africa/Johannesburg"
        },
        {
           "id":3124,
           "country_id":710,
           "code":"",
           "name":"Northern Cape",
           "standard_timezone":"(UTC+2.0) Johannesburg",
           "timezone":"Africa/Johannesburg"
        },
        {
           "id":3125,
           "country_id":710,
           "code":"",
           "name":"Limpopo",
           "standard_timezone":"(UTC+2.0) Johannesburg",
           "timezone":"Africa/Johannesburg"
        },
        {
           "id":3126,
           "country_id":710,
           "code":"",
           "name":"North-West",
           "standard_timezone":"(UTC+2.0) Johannesburg",
           "timezone":"Africa/Johannesburg"
        },
        {
           "id":3127,
           "country_id":710,
           "code":"",
           "name":"Western Cape",
           "standard_timezone":"(UTC+2.0) Johannesburg",
           "timezone":"Africa/Johannesburg"
        },
        {
           "id":3128,
           "country_id":716,
           "code":"",
           "name":"Manicaland",
           "standard_timezone":"(UTC+2.0) Harare",
           "timezone":"Africa/Harare"
        },
        {
           "id":3129,
           "country_id":716,
           "code":"",
           "name":"Midlands",
           "standard_timezone":"(UTC+2.0) Harare",
           "timezone":"Africa/Harare"
        },
        {
           "id":3130,
           "country_id":716,
           "code":"",
           "name":"Mashonaland Central",
           "standard_timezone":"(UTC+2.0) Harare",
           "timezone":"Africa/Harare"
        },
        {
           "id":3131,
           "country_id":716,
           "code":"",
           "name":"Mashonaland East",
           "standard_timezone":"(UTC+2.0) Harare",
           "timezone":"Africa/Harare"
        },
        {
           "id":3132,
           "country_id":716,
           "code":"",
           "name":"Mashonaland West",
           "standard_timezone":"(UTC+2.0) Harare",
           "timezone":"Africa/Harare"
        },
        {
           "id":3133,
           "country_id":716,
           "code":"",
           "name":"Matabeleland North",
           "standard_timezone":"(UTC+2.0) Harare",
           "timezone":"Africa/Harare"
        },
        {
           "id":3134,
           "country_id":716,
           "code":"",
           "name":"Matabeleland South",
           "standard_timezone":"(UTC+2.0) Harare",
           "timezone":"Africa/Harare"
        },
        {
           "id":3135,
           "country_id":716,
           "code":"",
           "name":"Masvingo",
           "standard_timezone":"(UTC+2.0) Harare",
           "timezone":"Africa/Harare"
        },
        {
           "id":3136,
           "country_id":716,
           "code":"",
           "name":"Bulawayo",
           "standard_timezone":"(UTC+2.0) Harare",
           "timezone":"Africa/Harare"
        },
        {
           "id":3137,
           "country_id":716,
           "code":"",
           "name":"Harare",
           "standard_timezone":"(UTC+2.0) Harare",
           "timezone":"Africa/Harare"
        },
        {
           "id":3138,
           "country_id":724,
           "code":"",
           "name":"Ceuta",
           "standard_timezone":"(UTC+2.0) Ceuta",
           "timezone":"Africa/Ceuta"
        },
        {
           "id":3139,
           "country_id":724,
           "code":"",
           "name":"Balearic Islands",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3140,
           "country_id":724,
           "code":"",
           "name":"La Rioja",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3141,
           "country_id":724,
           "code":"",
           "name":"Autonomous Region of Madrid",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3142,
           "country_id":724,
           "code":"",
           "name":"Murcia",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3143,
           "country_id":724,
           "code":"",
           "name":"Navarre",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3144,
           "country_id":724,
           "code":"",
           "name":"Asturias",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3145,
           "country_id":724,
           "code":"",
           "name":"Cantabria",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3146,
           "country_id":724,
           "code":"",
           "name":"Andalusia",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3147,
           "country_id":724,
           "code":"",
           "name":"Aragon",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3148,
           "country_id":724,
           "code":"",
           "name":"Canary Islands",
           "standard_timezone":"(UTC-4.0) Canary",
           "timezone":"Atlantic/Canary"
        },
        {
           "id":3149,
           "country_id":724,
           "code":"",
           "name":"Castille-La Mancha",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3150,
           "country_id":724,
           "code":"",
           "name":"Castille and León",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3151,
           "country_id":724,
           "code":"",
           "name":"Catalonia",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3152,
           "country_id":724,
           "code":"",
           "name":"Extremadura",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3153,
           "country_id":724,
           "code":"",
           "name":"Galicia",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3154,
           "country_id":724,
           "code":"",
           "name":"Basque Country",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3155,
           "country_id":724,
           "code":"",
           "name":"Valencia",
           "standard_timezone":"(UTC+2.0) Madrid",
           "timezone":"Europe/Madrid"
        },
        {
           "id":3156,
           "country_id":732,
           "code":"",
           "name":"Western Sahara",
           "standard_timezone":"(UTC+0.0) El_Aaiun",
           "timezone":"Africa/El_Aaiun"
        },
        {
           "id":3157,
           "country_id":736,
           "code":"",
           "name":"Al Wilāyah al Wusţá",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3158,
           "country_id":736,
           "code":"",
           "name":"Al Wilāyah al Istiwāʼīyah",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3159,
           "country_id":736,
           "code":"",
           "name":"Khartoum",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3160,
           "country_id":736,
           "code":"",
           "name":"Ash Shamaliyah",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3161,
           "country_id":736,
           "code":"",
           "name":"Al Wilāyah ash Sharqīyah",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3162,
           "country_id":736,
           "code":"",
           "name":"Ba?r al Ghazal Wilayat",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3163,
           "country_id":736,
           "code":"",
           "name":"Darfur Wilayat",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3164,
           "country_id":736,
           "code":"",
           "name":"Kurdufan Wilayat",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3165,
           "country_id":736,
           "code":"",
           "name":"Upper Nile",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3166,
           "country_id":736,
           "code":"",
           "name":"Red Sea",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3167,
           "country_id":736,
           "code":"",
           "name":"Lakes",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3168,
           "country_id":736,
           "code":"",
           "name":"Al Jazirah",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3169,
           "country_id":736,
           "code":"",
           "name":"Al Qadarif",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3170,
           "country_id":736,
           "code":"",
           "name":"Unity",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3171,
           "country_id":736,
           "code":"",
           "name":"White Nile",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3172,
           "country_id":736,
           "code":"",
           "name":"Blue Nile",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3173,
           "country_id":736,
           "code":"",
           "name":"Northern",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3174,
           "country_id":736,
           "code":"",
           "name":"Central Equatoria",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3175,
           "country_id":736,
           "code":"",
           "name":"Gharb al Istiwāʼīyah",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3176,
           "country_id":736,
           "code":"",
           "name":"Western Bahr al Ghazal",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3177,
           "country_id":736,
           "code":"",
           "name":"Gharb Dārfūr",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3178,
           "country_id":736,
           "code":"",
           "name":"Gharb Kurdufān",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3179,
           "country_id":736,
           "code":"",
           "name":"Janūb Dārfūr",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3180,
           "country_id":736,
           "code":"",
           "name":"Janūb Kurdufān",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3181,
           "country_id":736,
           "code":"",
           "name":"Junqalī",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3182,
           "country_id":736,
           "code":"",
           "name":"Kassalā",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3183,
           "country_id":736,
           "code":"",
           "name":"Nahr an Nīl",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3184,
           "country_id":736,
           "code":"",
           "name":"Shamāl Baḩr al Ghazāl",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3185,
           "country_id":736,
           "code":"",
           "name":"Shamāl Dārfūr",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3186,
           "country_id":736,
           "code":"",
           "name":"Shamāl Kurdufān",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3187,
           "country_id":736,
           "code":"",
           "name":"Eastern Equatoria",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3188,
           "country_id":736,
           "code":"",
           "name":"Sinnār",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3189,
           "country_id":736,
           "code":"",
           "name":"Warab",
           "standard_timezone":"(UTC+3.0) Khartoum",
           "timezone":"Africa/Khartoum"
        },
        {
           "id":3190,
           "country_id":740,
           "code":"",
           "name":"Brokopondo",
           "standard_timezone":"(UTC-3.0) Paramaribo",
           "timezone":"America/Paramaribo"
        },
        {
           "id":3191,
           "country_id":740,
           "code":"",
           "name":"Commewijne",
           "standard_timezone":"(UTC-3.0) Paramaribo",
           "timezone":"America/Paramaribo"
        },
        {
           "id":3192,
           "country_id":740,
           "code":"",
           "name":"Coronie",
           "standard_timezone":"(UTC-3.0) Paramaribo",
           "timezone":"America/Paramaribo"
        },
        {
           "id":3193,
           "country_id":740,
           "code":"",
           "name":"Marowijne",
           "standard_timezone":"(UTC-3.0) Paramaribo",
           "timezone":"America/Paramaribo"
        },
        {
           "id":3194,
           "country_id":740,
           "code":"",
           "name":"Nickerie",
           "standard_timezone":"(UTC-3.0) Paramaribo",
           "timezone":"America/Paramaribo"
        },
        {
           "id":3195,
           "country_id":740,
           "code":"",
           "name":"Para",
           "standard_timezone":"(UTC-3.0) Paramaribo",
           "timezone":"America/Paramaribo"
        },
        {
           "id":3196,
           "country_id":740,
           "code":"",
           "name":"Paramaribo",
           "standard_timezone":"(UTC-3.0) Paramaribo",
           "timezone":"America/Paramaribo"
        },
        {
           "id":3197,
           "country_id":740,
           "code":"",
           "name":"Saramacca",
           "standard_timezone":"(UTC-3.0) Paramaribo",
           "timezone":"America/Paramaribo"
        },
        {
           "id":3198,
           "country_id":740,
           "code":"",
           "name":"Sipaliwini",
           "standard_timezone":"(UTC-3.0) Paramaribo",
           "timezone":"America/Paramaribo"
        },
        {
           "id":3199,
           "country_id":740,
           "code":"",
           "name":"Wanica",
           "standard_timezone":"(UTC-3.0) Paramaribo",
           "timezone":"America/Paramaribo"
        },
        {
           "id":3200,
           "country_id":748,
           "code":"",
           "name":"Hhohho",
           "standard_timezone":"(UTC+2.0) Mbabane",
           "timezone":"Africa/Mbabane"
        },
        {
           "id":3201,
           "country_id":748,
           "code":"",
           "name":"Lubombo",
           "standard_timezone":"(UTC+2.0) Mbabane",
           "timezone":"Africa/Mbabane"
        },
        {
           "id":3202,
           "country_id":748,
           "code":"",
           "name":"Manzini",
           "standard_timezone":"(UTC+2.0) Mbabane",
           "timezone":"Africa/Mbabane"
        },
        {
           "id":3203,
           "country_id":748,
           "code":"",
           "name":"Shiselweni",
           "standard_timezone":"(UTC+2.0) Mbabane",
           "timezone":"Africa/Mbabane"
        },
        {
           "id":3204,
           "country_id":752,
           "code":"",
           "name":"Blekinge",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3205,
           "country_id":752,
           "code":"",
           "name":"Gävleborg",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3206,
           "country_id":752,
           "code":"",
           "name":"Gotland",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3207,
           "country_id":752,
           "code":"",
           "name":"Halland",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3208,
           "country_id":752,
           "code":"",
           "name":"Jämtland",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3209,
           "country_id":752,
           "code":"",
           "name":"Jönköping",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3210,
           "country_id":752,
           "code":"",
           "name":"Kalmar",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3211,
           "country_id":752,
           "code":"",
           "name":"Dalarna",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3212,
           "country_id":752,
           "code":"",
           "name":"Kronoberg",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3213,
           "country_id":752,
           "code":"",
           "name":"Norrbotten",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3214,
           "country_id":752,
           "code":"",
           "name":"Örebro",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3215,
           "country_id":752,
           "code":"",
           "name":"Östergötland",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3216,
           "country_id":752,
           "code":"",
           "name":"Södermanland",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3217,
           "country_id":752,
           "code":"",
           "name":"Uppsala",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3218,
           "country_id":752,
           "code":"",
           "name":"Värmland",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3219,
           "country_id":752,
           "code":"",
           "name":"Västerbotten",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3220,
           "country_id":752,
           "code":"",
           "name":"Västernorrland",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3221,
           "country_id":752,
           "code":"",
           "name":"Västmanland",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3222,
           "country_id":752,
           "code":"",
           "name":"Stockholm",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3223,
           "country_id":752,
           "code":"",
           "name":"Skåne",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3224,
           "country_id":752,
           "code":"",
           "name":"Västra Götaland",
           "standard_timezone":"(UTC+2.0) Stockholm",
           "timezone":"Europe/Stockholm"
        },
        {
           "id":3225,
           "country_id":756,
           "code":"",
           "name":"Aargau",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3226,
           "country_id":756,
           "code":"",
           "name":"Appenzell Innerrhoden",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3227,
           "country_id":756,
           "code":"",
           "name":"Appenzell Ausserrhoden",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3228,
           "country_id":756,
           "code":"",
           "name":"Bern",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3229,
           "country_id":756,
           "code":"",
           "name":"Basel-Landschaft",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3230,
           "country_id":756,
           "code":"",
           "name":"Kanton Basel-Stadt",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3231,
           "country_id":756,
           "code":"",
           "name":"Fribourg",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3232,
           "country_id":756,
           "code":"",
           "name":"Genève",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3233,
           "country_id":756,
           "code":"",
           "name":"Glarus",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3234,
           "country_id":756,
           "code":"",
           "name":"Graubünden",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3235,
           "country_id":756,
           "code":"",
           "name":"Jura",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3236,
           "country_id":756,
           "code":"",
           "name":"Luzern",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3237,
           "country_id":756,
           "code":"",
           "name":"Neuchâtel",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3238,
           "country_id":756,
           "code":"",
           "name":"Nidwalden",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3239,
           "country_id":756,
           "code":"",
           "name":"Obwalden",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3240,
           "country_id":756,
           "code":"",
           "name":"Kanton St. Gallen",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3241,
           "country_id":756,
           "code":"",
           "name":"Schaffhausen",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3242,
           "country_id":756,
           "code":"",
           "name":"Solothurn",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3243,
           "country_id":756,
           "code":"",
           "name":"Schwyz",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3244,
           "country_id":756,
           "code":"",
           "name":"Thurgau",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3245,
           "country_id":756,
           "code":"",
           "name":"Ticino",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3246,
           "country_id":756,
           "code":"",
           "name":"Uri",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3247,
           "country_id":756,
           "code":"",
           "name":"Vaud",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3248,
           "country_id":756,
           "code":"",
           "name":"Valais",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3249,
           "country_id":756,
           "code":"",
           "name":"Zug",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3250,
           "country_id":756,
           "code":"",
           "name":"Zürich",
           "standard_timezone":"(UTC+2.0) Zurich",
           "timezone":"Europe/Zurich"
        },
        {
           "id":3251,
           "country_id":760,
           "code":"",
           "name":"Al-Hasakah",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3252,
           "country_id":760,
           "code":"",
           "name":"Latakia",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3253,
           "country_id":760,
           "code":"",
           "name":"Quneitra",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3254,
           "country_id":760,
           "code":"",
           "name":"Ar-Raqqah",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3255,
           "country_id":760,
           "code":"",
           "name":"As-Suwayda",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3256,
           "country_id":760,
           "code":"",
           "name":"Daraa",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3257,
           "country_id":760,
           "code":"",
           "name":"Deir ez-Zor",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3258,
           "country_id":760,
           "code":"",
           "name":"Rif-dimashq",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3259,
           "country_id":760,
           "code":"",
           "name":"Aleppo",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3260,
           "country_id":760,
           "code":"",
           "name":"Hama Governorate",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3261,
           "country_id":760,
           "code":"",
           "name":"Homs",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3262,
           "country_id":760,
           "code":"",
           "name":"Idlib",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3263,
           "country_id":760,
           "code":"",
           "name":"Damascus City",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3264,
           "country_id":760,
           "code":"",
           "name":"Tartus",
           "standard_timezone":"(UTC+3.0) Damascus",
           "timezone":"Asia/Damascus"
        },
        {
           "id":3265,
           "country_id":762,
           "code":"",
           "name":"Gorno-Badakhshan",
           "standard_timezone":"(UTC+5.0) Dushanbe",
           "timezone":"Asia/Dushanbe"
        },
        {
           "id":3266,
           "country_id":762,
           "code":"",
           "name":"Khatlon",
           "standard_timezone":"(UTC+5.0) Dushanbe",
           "timezone":"Asia/Dushanbe"
        },
        {
           "id":3267,
           "country_id":762,
           "code":"",
           "name":"Sughd",
           "standard_timezone":"(UTC+5.0) Dushanbe",
           "timezone":"Asia/Dushanbe"
        },
        {
           "id":3268,
           "country_id":762,
           "code":"",
           "name":"Dushanbe",
           "standard_timezone":"(UTC+5.0) Dushanbe",
           "timezone":"Asia/Dushanbe"
        },
        {
           "id":3269,
           "country_id":762,
           "code":"",
           "name":"Region of Republican Subordination",
           "standard_timezone":"(UTC+5.0) Dushanbe",
           "timezone":"Asia/Dushanbe"
        },
        {
           "id":3270,
           "country_id":764,
           "code":"",
           "name":"Mae Hong Son",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3271,
           "country_id":764,
           "code":"",
           "name":"Chiang Mai",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3272,
           "country_id":764,
           "code":"",
           "name":"Chiang Rai",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3273,
           "country_id":764,
           "code":"",
           "name":"Nan",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3274,
           "country_id":764,
           "code":"",
           "name":"Lamphun",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3275,
           "country_id":764,
           "code":"",
           "name":"Lampang",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3276,
           "country_id":764,
           "code":"",
           "name":"Phrae",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3277,
           "country_id":764,
           "code":"",
           "name":"Tak",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3278,
           "country_id":764,
           "code":"",
           "name":"Sukhothai",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3279,
           "country_id":764,
           "code":"",
           "name":"Uttaradit",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3280,
           "country_id":764,
           "code":"",
           "name":"Kamphaeng Phet",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3281,
           "country_id":764,
           "code":"",
           "name":"Phitsanulok",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3282,
           "country_id":764,
           "code":"",
           "name":"Phichit",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3283,
           "country_id":764,
           "code":"",
           "name":"Phetchabun",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3284,
           "country_id":764,
           "code":"",
           "name":"Uthai Thani",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3285,
           "country_id":764,
           "code":"",
           "name":"Nakhon Sawan",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3286,
           "country_id":764,
           "code":"",
           "name":"Nong Khai",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3287,
           "country_id":764,
           "code":"",
           "name":"Loei",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3288,
           "country_id":764,
           "code":"",
           "name":"Sakon Nakhon",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3289,
           "country_id":764,
           "code":"",
           "name":"Nakhon Phanom",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3290,
           "country_id":764,
           "code":"",
           "name":"Khon Kaen",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3291,
           "country_id":764,
           "code":"",
           "name":"Kalasin",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3292,
           "country_id":764,
           "code":"",
           "name":"Maha Sarakham",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3293,
           "country_id":764,
           "code":"",
           "name":"Roi Et",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3294,
           "country_id":764,
           "code":"",
           "name":"Chaiyaphum",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3295,
           "country_id":764,
           "code":"",
           "name":"Nakhon Ratchasima",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3296,
           "country_id":764,
           "code":"",
           "name":"Buriram",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3297,
           "country_id":764,
           "code":"",
           "name":"Surin",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3298,
           "country_id":764,
           "code":"",
           "name":"Sisaket",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3299,
           "country_id":764,
           "code":"",
           "name":"Narathiwat",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3300,
           "country_id":764,
           "code":"",
           "name":"Chai Nat",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3301,
           "country_id":764,
           "code":"",
           "name":"Sing Buri",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3302,
           "country_id":764,
           "code":"",
           "name":"Lop Buri",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3303,
           "country_id":764,
           "code":"",
           "name":"Ang Thong",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3304,
           "country_id":764,
           "code":"",
           "name":"Phra Nakhon Si Ayutthaya",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3305,
           "country_id":764,
           "code":"",
           "name":"Sara Buri",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3306,
           "country_id":764,
           "code":"",
           "name":"Nonthaburi",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3307,
           "country_id":764,
           "code":"",
           "name":"Pathum Thani",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3308,
           "country_id":764,
           "code":"",
           "name":"Bangkok",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3309,
           "country_id":764,
           "code":"",
           "name":"Phayao",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3310,
           "country_id":764,
           "code":"",
           "name":"Samut Prakan",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3311,
           "country_id":764,
           "code":"",
           "name":"Nakhon Nayok",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3312,
           "country_id":764,
           "code":"",
           "name":"Chachoengsao",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3313,
           "country_id":764,
           "code":"",
           "name":"Chon Buri",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3314,
           "country_id":764,
           "code":"",
           "name":"Rayong",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3315,
           "country_id":764,
           "code":"",
           "name":"Chanthaburi",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3316,
           "country_id":764,
           "code":"",
           "name":"Trat",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3317,
           "country_id":764,
           "code":"",
           "name":"Kanchanaburi",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3318,
           "country_id":764,
           "code":"",
           "name":"Suphan Buri",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3319,
           "country_id":764,
           "code":"",
           "name":"Ratchaburi",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3320,
           "country_id":764,
           "code":"",
           "name":"Nakhon Pathom",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3321,
           "country_id":764,
           "code":"",
           "name":"Samut Songkhram",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3322,
           "country_id":764,
           "code":"",
           "name":"Samut Sakhon",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3323,
           "country_id":764,
           "code":"",
           "name":"Phetchaburi",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3324,
           "country_id":764,
           "code":"",
           "name":"Prachuap Khiri Khan",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3325,
           "country_id":764,
           "code":"",
           "name":"Chumphon",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3326,
           "country_id":764,
           "code":"",
           "name":"Ranong",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3327,
           "country_id":764,
           "code":"",
           "name":"Surat Thani",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3328,
           "country_id":764,
           "code":"",
           "name":"Phangnga",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3329,
           "country_id":764,
           "code":"",
           "name":"Phuket",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3330,
           "country_id":764,
           "code":"",
           "name":"Krabi",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3331,
           "country_id":764,
           "code":"",
           "name":"Nakhon Si Thammarat",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3332,
           "country_id":764,
           "code":"",
           "name":"Trang",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3333,
           "country_id":764,
           "code":"",
           "name":"Phatthalung",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3334,
           "country_id":764,
           "code":"",
           "name":"Satun",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3335,
           "country_id":764,
           "code":"",
           "name":"Songkhla",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3336,
           "country_id":764,
           "code":"",
           "name":"Pattani",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3337,
           "country_id":764,
           "code":"",
           "name":"Yala",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3338,
           "country_id":764,
           "code":"",
           "name":"Yasothon",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3339,
           "country_id":764,
           "code":"",
           "name":"Nakhon Phanom",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3340,
           "country_id":764,
           "code":"",
           "name":"Prachin Buri",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3341,
           "country_id":764,
           "code":"",
           "name":"Ubon Ratchathani",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3342,
           "country_id":764,
           "code":"",
           "name":"Udon Thani",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3343,
           "country_id":764,
           "code":"",
           "name":"Amnat Charoen",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3344,
           "country_id":764,
           "code":"",
           "name":"Mukdahan",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3345,
           "country_id":764,
           "code":"",
           "name":"Nong Bua Lamphu",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3346,
           "country_id":764,
           "code":"",
           "name":"Sa Kaeo",
           "standard_timezone":"(UTC+7.0) Bangkok",
           "timezone":"Asia/Bangkok"
        },
        {
           "id":3347,
           "country_id":768,
           "code":"",
           "name":"Amlame",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3348,
           "country_id":768,
           "code":"",
           "name":"Aneho",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3349,
           "country_id":768,
           "code":"",
           "name":"Atakpame",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3350,
           "country_id":768,
           "code":"",
           "name":"Bafilo",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3351,
           "country_id":768,
           "code":"",
           "name":"Bassar",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3352,
           "country_id":768,
           "code":"",
           "name":"Dapaong",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3353,
           "country_id":768,
           "code":"",
           "name":"Kante",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3354,
           "country_id":768,
           "code":"",
           "name":"Klouto",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3355,
           "country_id":768,
           "code":"",
           "name":"Lama-Kara",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3356,
           "country_id":768,
           "code":"",
           "name":"Lome",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3357,
           "country_id":768,
           "code":"",
           "name":"Mango",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3358,
           "country_id":768,
           "code":"",
           "name":"Niamtougou",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3359,
           "country_id":768,
           "code":"",
           "name":"Notse",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3360,
           "country_id":768,
           "code":"",
           "name":"Kpagouda",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3361,
           "country_id":768,
           "code":"",
           "name":"Badou",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3362,
           "country_id":768,
           "code":"",
           "name":"Sotouboua",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3363,
           "country_id":768,
           "code":"",
           "name":"Tabligbo",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3364,
           "country_id":768,
           "code":"",
           "name":"Tsevie",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3365,
           "country_id":768,
           "code":"",
           "name":"Tchamba",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3366,
           "country_id":768,
           "code":"",
           "name":"Tchaoudjo",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3367,
           "country_id":768,
           "code":"",
           "name":"Vogan",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3368,
           "country_id":768,
           "code":"",
           "name":"Centrale",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3369,
           "country_id":768,
           "code":"",
           "name":"Kara",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3370,
           "country_id":768,
           "code":"",
           "name":"Maritime",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3371,
           "country_id":768,
           "code":"",
           "name":"Plateaux",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3372,
           "country_id":768,
           "code":"",
           "name":"Savanes",
           "standard_timezone":"(UTC+0.0) Lome",
           "timezone":"Africa/Lome"
        },
        {
           "id":3373,
           "country_id":772,
           "code":"",
           "name":"Tokelau",
           "standard_timezone":"(UTC +13:00)Fakaofo",
           "timezone":"Pacific/Fakaofo"
        },
        {
           "id":3374,
           "country_id":776,
           "code":"",
           "name":"Ha‘apai",
           "standard_timezone":"(UTC +13:00)Tongatapu",
           "timezone":"Pacific/Tongatapu"
        },
        {
           "id":3375,
           "country_id":776,
           "code":"",
           "name":"Tongatapu",
           "standard_timezone":"(UTC+13:00)Tongatapu",
           "timezone":"Pacific/Tongatapu"
        },
        {
           "id":3376,
           "country_id":776,
           "code":"",
           "name":"Vava‘u",
           "standard_timezone":"(UTC +13:00)Tongatapu",
           "timezone":"Pacific/Tongatapu"
        },
        {
           "id":3377,
           "country_id":780,
           "code":"",
           "name":"Port of Spain",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3378,
           "country_id":780,
           "code":"",
           "name":"San Fernando",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3379,
           "country_id":780,
           "code":"",
           "name":"Chaguanas",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3380,
           "country_id":780,
           "code":"",
           "name":"Arima",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3381,
           "country_id":780,
           "code":"",
           "name":"Point Fortin Borough",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3382,
           "country_id":780,
           "code":"",
           "name":"Couva-Tabaquite-Talparo",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3383,
           "country_id":780,
           "code":"",
           "name":"Diego Martin",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3384,
           "country_id":780,
           "code":"",
           "name":"Penal-Debe",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3385,
           "country_id":780,
           "code":"",
           "name":"Princes Town",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3386,
           "country_id":780,
           "code":"",
           "name":"Rio Claro-Mayaro",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3387,
           "country_id":780,
           "code":"",
           "name":"San Juan-Laventille",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3388,
           "country_id":780,
           "code":"",
           "name":"Sangre Grande",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3389,
           "country_id":780,
           "code":"",
           "name":"Siparia",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3390,
           "country_id":780,
           "code":"",
           "name":"Tunapuna-Piarco",
           "standard_timezone":"(UTC-4.0) Port_of_Spain",
           "timezone":"America/Port_of_Spain"
        },
        {
           "id":3391,
           "country_id":784,
           "code":"",
           "name":"Abu Dhabi",
           "standard_timezone":"(UTC+4.0) Dubai",
           "timezone":"Asia/Dubai"
        },
        {
           "id":3392,
           "country_id":784,
           "code":"",
           "name":"Ajman",
           "standard_timezone":"(UTC+4.0) Dubai",
           "timezone":"Asia/Dubai"
        },
        {
           "id":3393,
           "country_id":784,
           "code":"",
           "name":"Dubai",
           "standard_timezone":"(UTC+4.0) Dubai",
           "timezone":"Asia/Dubai"
        },
        {
           "id":3394,
           "country_id":784,
           "code":"",
           "name":"Fujairah",
           "standard_timezone":"(UTC+4.0) Dubai",
           "timezone":"Asia/Dubai"
        },
        {
           "id":3395,
           "country_id":784,
           "code":"",
           "name":"Ras Al Khaimah",
           "standard_timezone":"(UTC+4.0) Dubai",
           "timezone":"Asia/Dubai"
        },
        {
           "id":3396,
           "country_id":784,
           "code":"",
           "name":"Sharjah",
           "standard_timezone":"(UTC+4.0) Dubai",
           "timezone":"Asia/Dubai"
        },
        {
           "id":3397,
           "country_id":784,
           "code":"",
           "name":"Umm Al Quwain",
           "standard_timezone":"(UTC+4.0) Dubai",
           "timezone":"Asia/Dubai"
        },
        {
           "id":3398,
           "country_id":788,
           "code":"",
           "name":"Tunis al Janubiyah Wilayat",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3399,
           "country_id":788,
           "code":"",
           "name":"Al Qaşrayn",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3400,
           "country_id":788,
           "code":"",
           "name":"Al Qayrawān",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3401,
           "country_id":788,
           "code":"",
           "name":"Jundūbah",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3402,
           "country_id":788,
           "code":"",
           "name":"Kef",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3403,
           "country_id":788,
           "code":"",
           "name":"Al Mahdīyah",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3404,
           "country_id":788,
           "code":"",
           "name":"Al Munastīr",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3405,
           "country_id":788,
           "code":"",
           "name":"Bājah",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3406,
           "country_id":788,
           "code":"",
           "name":"Banzart",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3407,
           "country_id":788,
           "code":"",
           "name":"Nābul",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3408,
           "country_id":788,
           "code":"",
           "name":"Silyānah",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3409,
           "country_id":788,
           "code":"",
           "name":"Sūsah",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3410,
           "country_id":788,
           "code":"",
           "name":"Bin ‘Arūs",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3411,
           "country_id":788,
           "code":"",
           "name":"Madanīn",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3412,
           "country_id":788,
           "code":"",
           "name":"Qābis",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3413,
           "country_id":788,
           "code":"",
           "name":"Qafşah",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3414,
           "country_id":788,
           "code":"",
           "name":"Qibilī",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3415,
           "country_id":788,
           "code":"",
           "name":"Şafāqis",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3416,
           "country_id":788,
           "code":"",
           "name":"Sīdī Bū Zayd",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3417,
           "country_id":788,
           "code":"",
           "name":"Taţāwīn",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3418,
           "country_id":788,
           "code":"",
           "name":"Tawzar",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3419,
           "country_id":788,
           "code":"",
           "name":"Tūnis",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3420,
           "country_id":788,
           "code":"",
           "name":"Zaghwān",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3421,
           "country_id":788,
           "code":"",
           "name":"Ariana",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3422,
           "country_id":788,
           "code":"",
           "name":"Manouba",
           "standard_timezone":"(UTC+1.0) Tunis",
           "timezone":"Africa/Tunis"
        },
        {
           "id":3423,
           "country_id":792,
           "code":"",
           "name":"Adıyaman",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3424,
           "country_id":792,
           "code":"",
           "name":"Afyonkarahisar",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3425,
           "country_id":792,
           "code":"",
           "name":"Ağrı Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3426,
           "country_id":792,
           "code":"",
           "name":"Amasya Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3427,
           "country_id":792,
           "code":"",
           "name":"Antalya Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3428,
           "country_id":792,
           "code":"",
           "name":"Artvin Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3429,
           "country_id":792,
           "code":"",
           "name":"Aydın Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3430,
           "country_id":792,
           "code":"",
           "name":"Balıkesir Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3431,
           "country_id":792,
           "code":"",
           "name":"Bilecik Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3432,
           "country_id":792,
           "code":"",
           "name":"Bingöl Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3433,
           "country_id":792,
           "code":"",
           "name":"Bitlis Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3434,
           "country_id":792,
           "code":"",
           "name":"Bolu Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3435,
           "country_id":792,
           "code":"",
           "name":"Burdur Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3436,
           "country_id":792,
           "code":"",
           "name":"Bursa",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3437,
           "country_id":792,
           "code":"",
           "name":"Çanakkale Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3438,
           "country_id":792,
           "code":"",
           "name":"Çorum Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3439,
           "country_id":792,
           "code":"",
           "name":"Denizli Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3440,
           "country_id":792,
           "code":"",
           "name":"Diyarbakır",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3441,
           "country_id":792,
           "code":"",
           "name":"Edirne Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3442,
           "country_id":792,
           "code":"",
           "name":"Elazığ",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3443,
           "country_id":792,
           "code":"",
           "name":"Erzincan Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3444,
           "country_id":792,
           "code":"",
           "name":"Erzurum Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3445,
           "country_id":792,
           "code":"",
           "name":"Eskişehir Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3446,
           "country_id":792,
           "code":"",
           "name":"Giresun Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3447,
           "country_id":792,
           "code":"",
           "name":"Hatay Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3448,
           "country_id":792,
           "code":"",
           "name":"Mersin Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3449,
           "country_id":792,
           "code":"",
           "name":"Isparta Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3450,
           "country_id":792,
           "code":"",
           "name":"Istanbul",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3451,
           "country_id":792,
           "code":"",
           "name":"İzmir",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3452,
           "country_id":792,
           "code":"",
           "name":"Kastamonu Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3453,
           "country_id":792,
           "code":"",
           "name":"Kayseri Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3454,
           "country_id":792,
           "code":"",
           "name":"Kırklareli Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3455,
           "country_id":792,
           "code":"",
           "name":"Kırşehir Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3456,
           "country_id":792,
           "code":"",
           "name":"Kocaeli Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3457,
           "country_id":792,
           "code":"",
           "name":"Kütahya Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3458,
           "country_id":792,
           "code":"",
           "name":"Malatya Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3459,
           "country_id":792,
           "code":"",
           "name":"Manisa Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3460,
           "country_id":792,
           "code":"",
           "name":"Kahramanmaraş Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3461,
           "country_id":792,
           "code":"",
           "name":"Muğla Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3462,
           "country_id":792,
           "code":"",
           "name":"Muş Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3463,
           "country_id":792,
           "code":"",
           "name":"Nevşehir",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3464,
           "country_id":792,
           "code":"",
           "name":"Ordu",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3465,
           "country_id":792,
           "code":"",
           "name":"Rize",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3466,
           "country_id":792,
           "code":"",
           "name":"Sakarya Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3467,
           "country_id":792,
           "code":"",
           "name":"Samsun Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3468,
           "country_id":792,
           "code":"",
           "name":"Sinop Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3469,
           "country_id":792,
           "code":"",
           "name":"Sivas Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3470,
           "country_id":792,
           "code":"",
           "name":"Tekirdağ Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3471,
           "country_id":792,
           "code":"",
           "name":"Tokat",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3472,
           "country_id":792,
           "code":"",
           "name":"Trabzon Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3473,
           "country_id":792,
           "code":"",
           "name":"Tunceli Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3474,
           "country_id":792,
           "code":"",
           "name":"Şanlıurfa Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3475,
           "country_id":792,
           "code":"",
           "name":"Uşak Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3476,
           "country_id":792,
           "code":"",
           "name":"Van Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3477,
           "country_id":792,
           "code":"",
           "name":"Yozgat Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3478,
           "country_id":792,
           "code":"",
           "name":"Ankara Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3479,
           "country_id":792,
           "code":"",
           "name":"Gümüşhane",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3480,
           "country_id":792,
           "code":"",
           "name":"Hakkâri Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3481,
           "country_id":792,
           "code":"",
           "name":"Konya Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3482,
           "country_id":792,
           "code":"",
           "name":"Mardin Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3483,
           "country_id":792,
           "code":"",
           "name":"Niğde",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3484,
           "country_id":792,
           "code":"",
           "name":"Siirt Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3485,
           "country_id":792,
           "code":"",
           "name":"Aksaray Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3486,
           "country_id":792,
           "code":"",
           "name":"Batman Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3487,
           "country_id":792,
           "code":"",
           "name":"Bayburt",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3488,
           "country_id":792,
           "code":"",
           "name":"Karaman Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3489,
           "country_id":792,
           "code":"",
           "name":"Kırıkkale Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3490,
           "country_id":792,
           "code":"",
           "name":"Şırnak Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3491,
           "country_id":792,
           "code":"",
           "name":"Adana Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3492,
           "country_id":792,
           "code":"",
           "name":"Çankırı Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3493,
           "country_id":792,
           "code":"",
           "name":"Gaziantep Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3494,
           "country_id":792,
           "code":"",
           "name":"Kars",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3495,
           "country_id":792,
           "code":"",
           "name":"Zonguldak",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3496,
           "country_id":792,
           "code":"",
           "name":"Ardahan Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3497,
           "country_id":792,
           "code":"",
           "name":"Bartın Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3498,
           "country_id":792,
           "code":"",
           "name":"Iğdır Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3499,
           "country_id":792,
           "code":"",
           "name":"Karabük",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3500,
           "country_id":792,
           "code":"",
           "name":"Kilis Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3501,
           "country_id":792,
           "code":"",
           "name":"Osmaniye Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3502,
           "country_id":792,
           "code":"",
           "name":"Yalova Province",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3503,
           "country_id":792,
           "code":"",
           "name":"Düzce",
           "standard_timezone":"(UTC+3.0) Istanbul",
           "timezone":"Europe/Istanbul"
        },
        {
           "id":3504,
           "country_id":795,
           "code":"",
           "name":"Ahal",
           "standard_timezone":"(UTC+5.0) Ashgabat",
           "timezone":"Asia/Ashgabat"
        },
        {
           "id":3505,
           "country_id":795,
           "code":"",
           "name":"Balkan",
           "standard_timezone":"(UTC+5.0) Ashgabat",
           "timezone":"Asia/Ashgabat"
        },
        {
           "id":3506,
           "country_id":795,
           "code":"",
           "name":"Daşoguz",
           "standard_timezone":"(UTC+5.0) Ashgabat",
           "timezone":"Asia/Ashgabat"
        },
        {
           "id":3507,
           "country_id":795,
           "code":"",
           "name":"Lebap",
           "standard_timezone":"(UTC+5.0) Ashgabat",
           "timezone":"Asia/Ashgabat"
        },
        {
           "id":3508,
           "country_id":795,
           "code":"",
           "name":"Mary",
           "standard_timezone":"(UTC+5.0) Ashgabat",
           "timezone":"Asia/Ashgabat"
        },
        {
           "id":3509,
           "country_id":796,
           "code":"",
           "name":"Turks and Caicos Islands",
           "standard_timezone":"(UTC-4.0) Grand_Turk",
           "timezone":"America/Grand_Turk"
        },
        {
           "id":3510,
           "country_id":798,
           "code":"",
           "name":"Tuvalu",
           "standard_timezone":"(UTC+12.0) Funafuti",
           "timezone":"Pacific/Funafuti"
        },
        {
           "id":3511,
           "country_id":800,
           "code":"",
           "name":"Eastern Region",
           "standard_timezone":"(UTC+3.0) Kampala",
           "timezone":"Africa/Kampala"
        },
        {
           "id":3512,
           "country_id":800,
           "code":"",
           "name":"Northern Region",
           "standard_timezone":"(UTC+3.0) Kampala",
           "timezone":"Africa/Kampala"
        },
        {
           "id":3513,
           "country_id":800,
           "code":"",
           "name":"Central Region",
           "standard_timezone":"(UTC+3.0) Kampala",
           "timezone":"Africa/Kampala"
        },
        {
           "id":3514,
           "country_id":800,
           "code":"",
           "name":"Western Region",
           "standard_timezone":"(UTC+3.0) Kampala",
           "timezone":"Africa/Kampala"
        },
        {
           "id":3515,
           "country_id":804,
           "code":"",
           "name":"Cherkasʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3516,
           "country_id":804,
           "code":"",
           "name":"Chernihivsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3517,
           "country_id":804,
           "code":"",
           "name":"Chernivetsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3518,
           "country_id":804,
           "code":"",
           "name":"Dnipropetrovsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3519,
           "country_id":804,
           "code":"",
           "name":"Donetsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3520,
           "country_id":804,
           "code":"",
           "name":"Ivano-Frankivsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3521,
           "country_id":804,
           "code":"",
           "name":"Kharkivsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3522,
           "country_id":804,
           "code":"",
           "name":"Kherson Oblast",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3523,
           "country_id":804,
           "code":"",
           "name":"Khmelʼnytsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3524,
           "country_id":804,
           "code":"",
           "name":"Kirovohradsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3525,
           "country_id":804,
           "code":"",
           "name":"Avtonomna Respublika Krym",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3526,
           "country_id":804,
           "code":"",
           "name":"Misto Kyyiv",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3527,
           "country_id":804,
           "code":"",
           "name":"Kiev Oblast",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3528,
           "country_id":804,
           "code":"",
           "name":"Luhansʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3529,
           "country_id":804,
           "code":"",
           "name":"Lʼvivsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3530,
           "country_id":804,
           "code":"",
           "name":"Mykolayivsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3531,
           "country_id":804,
           "code":"",
           "name":"Odessa Oblast",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3532,
           "country_id":804,
           "code":"",
           "name":"Poltava Oblast",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3533,
           "country_id":804,
           "code":"",
           "name":"Rivnensʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3534,
           "country_id":804,
           "code":"",
           "name":"Misto Sevastopol",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3535,
           "country_id":804,
           "code":"",
           "name":"Sumy Oblast",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3536,
           "country_id":804,
           "code":"",
           "name":"Ternopilʼsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3537,
           "country_id":804,
           "code":"",
           "name":"Vinnytsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3538,
           "country_id":804,
           "code":"",
           "name":"Volynsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3539,
           "country_id":804,
           "code":"",
           "name":"Zakarpatsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3540,
           "country_id":804,
           "code":"",
           "name":"Zaporizʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3541,
           "country_id":804,
           "code":"",
           "name":"Zhytomyrsʼka Oblastʼ",
           "standard_timezone":"(UTC+3.0) Kiev",
           "timezone":"Europe/Kiev"
        },
        {
           "id":3542,
           "country_id":807,
           "code":"",
           "name":"Macedonia, The Former Yugoslav Republic of",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3543,
           "country_id":807,
           "code":"",
           "name":"Aračinovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3544,
           "country_id":807,
           "code":"",
           "name":"Bač",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3545,
           "country_id":807,
           "code":"",
           "name":"Belčišta",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3546,
           "country_id":807,
           "code":"",
           "name":"Berovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3547,
           "country_id":807,
           "code":"",
           "name":"Bistrica",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3548,
           "country_id":807,
           "code":"",
           "name":"Bitola",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3549,
           "country_id":807,
           "code":"",
           "name":"Blatec",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3550,
           "country_id":807,
           "code":"",
           "name":"Bogdanci",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3551,
           "country_id":807,
           "code":"",
           "name":"Opstina Bogomila",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3552,
           "country_id":807,
           "code":"",
           "name":"Bogovinje",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3553,
           "country_id":807,
           "code":"",
           "name":"Bosilovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3554,
           "country_id":807,
           "code":"",
           "name":"Brvenica",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3555,
           "country_id":807,
           "code":"",
           "name":"Čair",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3556,
           "country_id":807,
           "code":"",
           "name":"Capari",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3557,
           "country_id":807,
           "code":"",
           "name":"Čaška",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3558,
           "country_id":807,
           "code":"",
           "name":"Čegrana",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3559,
           "country_id":807,
           "code":"",
           "name":"Centar",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3560,
           "country_id":807,
           "code":"",
           "name":"Centar Župa",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3561,
           "country_id":807,
           "code":"",
           "name":"Češinovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3562,
           "country_id":807,
           "code":"",
           "name":"Čučer-Sandevo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3563,
           "country_id":807,
           "code":"",
           "name":"Debar",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3564,
           "country_id":807,
           "code":"",
           "name":"Delčevo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3565,
           "country_id":807,
           "code":"",
           "name":"Delogoždi",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3566,
           "country_id":807,
           "code":"",
           "name":"Demir Hisar",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3567,
           "country_id":807,
           "code":"",
           "name":"Demir Kapija",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3568,
           "country_id":807,
           "code":"",
           "name":"Dobruševo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3569,
           "country_id":807,
           "code":"",
           "name":"Dolna Banjica",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3570,
           "country_id":807,
           "code":"",
           "name":"Dolneni",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3571,
           "country_id":807,
           "code":"",
           "name":"Opstina Gjorce Petrov",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3572,
           "country_id":807,
           "code":"",
           "name":"Drugovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3573,
           "country_id":807,
           "code":"",
           "name":"Džepčište",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3574,
           "country_id":807,
           "code":"",
           "name":"Gazi Baba",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3575,
           "country_id":807,
           "code":"",
           "name":"Gevgelija",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3576,
           "country_id":807,
           "code":"",
           "name":"Gostivar",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3577,
           "country_id":807,
           "code":"",
           "name":"Gradsko",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3578,
           "country_id":807,
           "code":"",
           "name":"Ilinden",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3579,
           "country_id":807,
           "code":"",
           "name":"Izvor",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3580,
           "country_id":807,
           "code":"",
           "name":"Jegunovce",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3581,
           "country_id":807,
           "code":"",
           "name":"Kamenjane",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3582,
           "country_id":807,
           "code":"",
           "name":"Karbinci",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3583,
           "country_id":807,
           "code":"",
           "name":"Karpoš",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3584,
           "country_id":807,
           "code":"",
           "name":"Kavadarci",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3585,
           "country_id":807,
           "code":"",
           "name":"Kičevo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3586,
           "country_id":807,
           "code":"",
           "name":"Kisela Voda",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3587,
           "country_id":807,
           "code":"",
           "name":"Klečevce",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3588,
           "country_id":807,
           "code":"",
           "name":"Kočani",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3589,
           "country_id":807,
           "code":"",
           "name":"Konče",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3590,
           "country_id":807,
           "code":"",
           "name":"Kondovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3591,
           "country_id":807,
           "code":"",
           "name":"Konopište",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3592,
           "country_id":807,
           "code":"",
           "name":"Kosel",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3593,
           "country_id":807,
           "code":"",
           "name":"Kratovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3594,
           "country_id":807,
           "code":"",
           "name":"Kriva Palanka",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3595,
           "country_id":807,
           "code":"",
           "name":"Krivogaštani",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3596,
           "country_id":807,
           "code":"",
           "name":"Kruševo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3597,
           "country_id":807,
           "code":"",
           "name":"Kukliš",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3598,
           "country_id":807,
           "code":"",
           "name":"Kukurečani",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3599,
           "country_id":807,
           "code":"",
           "name":"Kumanovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3600,
           "country_id":807,
           "code":"",
           "name":"Labuništa",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3601,
           "country_id":807,
           "code":"",
           "name":"Opstina Lipkovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3602,
           "country_id":807,
           "code":"",
           "name":"Lozovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3603,
           "country_id":807,
           "code":"",
           "name":"Lukovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3604,
           "country_id":807,
           "code":"",
           "name":"Makedonska Kamenica",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3605,
           "country_id":807,
           "code":"",
           "name":"Makedonski Brod",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3606,
           "country_id":807,
           "code":"",
           "name":"Mavrovi Anovi",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3607,
           "country_id":807,
           "code":"",
           "name":"Mešeišta",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3608,
           "country_id":807,
           "code":"",
           "name":"Miravci",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3609,
           "country_id":807,
           "code":"",
           "name":"Mogila",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3610,
           "country_id":807,
           "code":"",
           "name":"Murtino",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3611,
           "country_id":807,
           "code":"",
           "name":"Negotino",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3612,
           "country_id":807,
           "code":"",
           "name":"Negotino-Pološko",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3613,
           "country_id":807,
           "code":"",
           "name":"Novaci",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3614,
           "country_id":807,
           "code":"",
           "name":"Novo Selo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3615,
           "country_id":807,
           "code":"",
           "name":"Obleševo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3616,
           "country_id":807,
           "code":"",
           "name":"Ohrid",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3617,
           "country_id":807,
           "code":"",
           "name":"Orašac",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3618,
           "country_id":807,
           "code":"",
           "name":"Orizari",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3619,
           "country_id":807,
           "code":"",
           "name":"Oslomej",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3620,
           "country_id":807,
           "code":"",
           "name":"Pehčevo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3621,
           "country_id":807,
           "code":"",
           "name":"Petrovec",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3622,
           "country_id":807,
           "code":"",
           "name":"Plasnica",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3623,
           "country_id":807,
           "code":"",
           "name":"Podareš",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3624,
           "country_id":807,
           "code":"",
           "name":"Prilep",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3625,
           "country_id":807,
           "code":"",
           "name":"Probištip",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3626,
           "country_id":807,
           "code":"",
           "name":"Radoviš",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3627,
           "country_id":807,
           "code":"",
           "name":"Opstina Rankovce",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3628,
           "country_id":807,
           "code":"",
           "name":"Resen",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3629,
           "country_id":807,
           "code":"",
           "name":"Rosoman",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3630,
           "country_id":807,
           "code":"",
           "name":"Opština Rostuša",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3631,
           "country_id":807,
           "code":"",
           "name":"Samokov",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3632,
           "country_id":807,
           "code":"",
           "name":"Saraj",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3633,
           "country_id":807,
           "code":"",
           "name":"Šipkovica",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3634,
           "country_id":807,
           "code":"",
           "name":"Sopište",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3635,
           "country_id":807,
           "code":"",
           "name":"Sopotnica",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3636,
           "country_id":807,
           "code":"",
           "name":"Srbinovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3637,
           "country_id":807,
           "code":"",
           "name":"Staravina",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3638,
           "country_id":807,
           "code":"",
           "name":"Star Dojran",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3639,
           "country_id":807,
           "code":"",
           "name":"Staro Nagoričane",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3640,
           "country_id":807,
           "code":"",
           "name":"Štip",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3641,
           "country_id":807,
           "code":"",
           "name":"Struga",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3642,
           "country_id":807,
           "code":"",
           "name":"Strumica",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3643,
           "country_id":807,
           "code":"",
           "name":"Studeničani",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3644,
           "country_id":807,
           "code":"",
           "name":"Šuto Orizari",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3645,
           "country_id":807,
           "code":"",
           "name":"Sveti Nikole",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3646,
           "country_id":807,
           "code":"",
           "name":"Tearce",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3647,
           "country_id":807,
           "code":"",
           "name":"Tetovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3648,
           "country_id":807,
           "code":"",
           "name":"Topolčani",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3649,
           "country_id":807,
           "code":"",
           "name":"Valandovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3650,
           "country_id":807,
           "code":"",
           "name":"Vasilevo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3651,
           "country_id":807,
           "code":"",
           "name":"Veles",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3652,
           "country_id":807,
           "code":"",
           "name":"Velešta",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3653,
           "country_id":807,
           "code":"",
           "name":"Vevčani",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3654,
           "country_id":807,
           "code":"",
           "name":"Vinica",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3655,
           "country_id":807,
           "code":"",
           "name":"Vitolište",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3656,
           "country_id":807,
           "code":"",
           "name":"Vraneštica",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3657,
           "country_id":807,
           "code":"",
           "name":"Vrapčište",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3658,
           "country_id":807,
           "code":"",
           "name":"Vratnica",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3659,
           "country_id":807,
           "code":"",
           "name":"Vrutok",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3660,
           "country_id":807,
           "code":"",
           "name":"Zajas",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3661,
           "country_id":807,
           "code":"",
           "name":"Zelenikovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3662,
           "country_id":807,
           "code":"",
           "name":"Želino",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3663,
           "country_id":807,
           "code":"",
           "name":"Žitoše",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3664,
           "country_id":807,
           "code":"",
           "name":"Zletovo",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3665,
           "country_id":807,
           "code":"",
           "name":"Zrnovci",
           "standard_timezone":"(UTC+2.0) Skopje",
           "timezone":"Europe/Skopje"
        },
        {
           "id":3666,
           "country_id":818,
           "code":"",
           "name":"Ad Daqahlīyah",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3667,
           "country_id":818,
           "code":"",
           "name":"Al Baḩr al Aḩmar",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3668,
           "country_id":818,
           "code":"",
           "name":"Al Buḩayrah",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3669,
           "country_id":818,
           "code":"",
           "name":"Al Fayyūm",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3670,
           "country_id":818,
           "code":"",
           "name":"Al Gharbīyah",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3671,
           "country_id":818,
           "code":"",
           "name":"Alexandria",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3672,
           "country_id":818,
           "code":"",
           "name":"Al Ismā‘īlīyah",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3673,
           "country_id":818,
           "code":"",
           "name":"Al Jīzah",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3674,
           "country_id":818,
           "code":"",
           "name":"Al Minūfīyah",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3675,
           "country_id":818,
           "code":"",
           "name":"Al Minyā",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3676,
           "country_id":818,
           "code":"",
           "name":"Al Qāhirah",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3677,
           "country_id":818,
           "code":"",
           "name":"Al Qalyūbīyah",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3678,
           "country_id":818,
           "code":"",
           "name":"Al Wādī al Jadīd",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3679,
           "country_id":818,
           "code":"",
           "name":"Eastern Province",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3680,
           "country_id":818,
           "code":"",
           "name":"As Suways",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3681,
           "country_id":818,
           "code":"",
           "name":"Aswān",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3682,
           "country_id":818,
           "code":"",
           "name":"Asyūţ",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3683,
           "country_id":818,
           "code":"",
           "name":"Banī Suwayf",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3684,
           "country_id":818,
           "code":"",
           "name":"Būr Sa‘īd",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3685,
           "country_id":818,
           "code":"",
           "name":"Dumyāţ",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3686,
           "country_id":818,
           "code":"",
           "name":"Kafr ash Shaykh",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3687,
           "country_id":818,
           "code":"",
           "name":"Maţrūḩ",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3688,
           "country_id":818,
           "code":"",
           "name":"Qinā",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3689,
           "country_id":818,
           "code":"",
           "name":"Sūhāj",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3690,
           "country_id":818,
           "code":"",
           "name":"Janūb Sīnāʼ",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3691,
           "country_id":818,
           "code":"",
           "name":"Shamāl Sīnāʼ",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3692,
           "country_id":818,
           "code":"",
           "name":"Luxor",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3693,
           "country_id":818,
           "code":"",
           "name":"Helwan",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3694,
           "country_id":818,
           "code":"",
           "name":"6th of October",
           "standard_timezone":"(UTC+2.0) Cairo",
           "timezone":"Africa/Cairo"
        },
        {
           "id":3695,
           "country_id":826,
           "code":"",
           "name":"England",
           "standard_timezone":"(UTC+1.0) London",
           "timezone":"Europe/London"
        },
        {
           "id":3696,
           "country_id":826,
           "code":"",
           "name":"Northern Ireland",
           "standard_timezone":"(UTC+1.0) London",
           "timezone":"Europe/London"
        },
        {
           "id":3697,
           "country_id":826,
           "code":"",
           "name":"Scotland",
           "standard_timezone":"(UTC+1.0) London",
           "timezone":"Europe/London"
        },
        {
           "id":3698,
           "country_id":826,
           "code":"",
           "name":"Wales",
           "standard_timezone":"(UTC+1.0) London",
           "timezone":"Europe/London"
        },
        {
           "id":3699,
           "country_id":831,
           "code":"",
           "name":"Guernsey",
           "standard_timezone":"(UTC+1.0) Guernsey",
           "timezone":"Europe/Guernsey"
        },
        {
           "id":3700,
           "country_id":833,
           "code":"",
           "name":"Isle of Man",
           "standard_timezone":"(UTC+1.0) Isle_of_Man",
           "timezone":"Europe/Isle_of_Man"
        },
        {
           "id":3701,
           "country_id":834,
           "code":"",
           "name":"Arusha",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3702,
           "country_id":834,
           "code":"",
           "name":"Pwani",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3703,
           "country_id":834,
           "code":"",
           "name":"Dodoma",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3704,
           "country_id":834,
           "code":"",
           "name":"Iringa",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3705,
           "country_id":834,
           "code":"",
           "name":"Kigoma",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3706,
           "country_id":834,
           "code":"",
           "name":"Kilimanjaro",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3707,
           "country_id":834,
           "code":"",
           "name":"Lindi",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3708,
           "country_id":834,
           "code":"",
           "name":"Mara",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3709,
           "country_id":834,
           "code":"",
           "name":"Mbeya",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3710,
           "country_id":834,
           "code":"",
           "name":"Morogoro",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3711,
           "country_id":834,
           "code":"",
           "name":"Mtwara",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3712,
           "country_id":834,
           "code":"",
           "name":"Mwanza",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3713,
           "country_id":834,
           "code":"",
           "name":"Pemba North",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3714,
           "country_id":834,
           "code":"",
           "name":"Ruvuma",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3715,
           "country_id":834,
           "code":"",
           "name":"Shinyanga",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3716,
           "country_id":834,
           "code":"",
           "name":"Singida",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3717,
           "country_id":834,
           "code":"",
           "name":"Tabora",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3718,
           "country_id":834,
           "code":"",
           "name":"Tanga",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3719,
           "country_id":834,
           "code":"",
           "name":"Kagera",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3720,
           "country_id":834,
           "code":"",
           "name":"Pemba South",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3721,
           "country_id":834,
           "code":"",
           "name":"Zanzibar Central/South",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3722,
           "country_id":834,
           "code":"",
           "name":"Zanzibar North",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3723,
           "country_id":834,
           "code":"",
           "name":"Dar es Salaam",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3724,
           "country_id":834,
           "code":"",
           "name":"Rukwa",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3725,
           "country_id":834,
           "code":"",
           "name":"Zanzibar Urban/West",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3726,
           "country_id":834,
           "code":"",
           "name":"Arusha",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3727,
           "country_id":834,
           "code":"",
           "name":"Manyara",
           "standard_timezone":"(UTC+3.0) Dar_es_Salaam",
           "timezone":"Africa/Dar_es_Salaam"
        },
        {
           "id":3728,
           "country_id":850,
           "code":"",
           "name":"Virgin Islands",
           "standard_timezone":"(UTC+1.0) St_Thomas",
           "timezone":"America/St_Thomas"
        },
        {
           "id":3729,
           "country_id":854,
           "code":"",
           "name":"Boucle du Mouhoun",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3730,
           "country_id":854,
           "code":"",
           "name":"Cascades",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3731,
           "country_id":854,
           "code":"",
           "name":"Centre",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3732,
           "country_id":854,
           "code":"",
           "name":"Centre-Est",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3733,
           "country_id":854,
           "code":"",
           "name":"Centre-Nord",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3734,
           "country_id":854,
           "code":"",
           "name":"Centre-Ouest",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3735,
           "country_id":854,
           "code":"",
           "name":"Centre-Sud",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3736,
           "country_id":854,
           "code":"",
           "name":"Est",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3737,
           "country_id":854,
           "code":"",
           "name":"Hauts-Bassins",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3738,
           "country_id":854,
           "code":"",
           "name":"Nord",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3739,
           "country_id":854,
           "code":"",
           "name":"Plateau-Central",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3740,
           "country_id":854,
           "code":"",
           "name":"Sahel",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3741,
           "country_id":854,
           "code":"",
           "name":"Sud-Ouest",
           "standard_timezone":"(UTC+0.0) Ouagadougou",
           "timezone":"Africa/Ouagadougou"
        },
        {
           "id":3742,
           "country_id":855,
           "code":"",
           "name":"Komuna e Deçanit",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3743,
           "country_id":855,
           "code":"",
           "name":"Komuna e Dragashit",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3744,
           "country_id":855,
           "code":"",
           "name":"Komuna e Ferizajt",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3745,
           "country_id":855,
           "code":"",
           "name":"Komuna e Fushë Kosovës",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3746,
           "country_id":855,
           "code":"",
           "name":"Komuna e Gjakovës",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3747,
           "country_id":855,
           "code":"",
           "name":"Komuna e Gjilanit",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3748,
           "country_id":855,
           "code":"",
           "name":"Komuna e Drenasit",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3749,
           "country_id":855,
           "code":"",
           "name":"Komuna e Istogut",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3750,
           "country_id":855,
           "code":"",
           "name":"Komuna e Kaçanikut",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3751,
           "country_id":855,
           "code":"",
           "name":"Komuna e Kamenicës",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3752,
           "country_id":855,
           "code":"",
           "name":"Komuna e Klinës",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3753,
           "country_id":855,
           "code":"",
           "name":"Komuna e Leposaviqit",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3754,
           "country_id":855,
           "code":"",
           "name":"Komuna e Lipjanit",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3755,
           "country_id":855,
           "code":"",
           "name":"Komuna e Malisheves",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3756,
           "country_id":855,
           "code":"",
           "name":"Komuna e Mitrovicës",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3757,
           "country_id":855,
           "code":"",
           "name":"Komuna e Novobërdës",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3758,
           "country_id":855,
           "code":"",
           "name":"Komuna e Obiliqit",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3759,
           "country_id":855,
           "code":"",
           "name":"Komuna e Pejës",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3760,
           "country_id":855,
           "code":"",
           "name":"Komuna e Podujevës",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3761,
           "country_id":855,
           "code":"",
           "name":"Komuna e Prishtinës",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3762,
           "country_id":855,
           "code":"",
           "name":"Komuna e Prizrenit",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3763,
           "country_id":855,
           "code":"",
           "name":"Komuna e Rahovecit",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3764,
           "country_id":855,
           "code":"",
           "name":"Komuna e Shtërpcës",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3765,
           "country_id":855,
           "code":"",
           "name":"Komuna e Shtimes",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3766,
           "country_id":855,
           "code":"",
           "name":"Komuna e Skenderajt",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3767,
           "country_id":855,
           "code":"",
           "name":"Komuna e Thërandës",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3768,
           "country_id":855,
           "code":"",
           "name":"Komuna e Vitisë",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3769,
           "country_id":855,
           "code":"",
           "name":"Komuna e Vushtrrisë",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3770,
           "country_id":855,
           "code":"",
           "name":"Komuna e Zubin Potokut",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3771,
           "country_id":855,
           "code":"",
           "name":"Komuna e Zveçanit",
           "standard_timezone":"(UTC+2.0) Belgrade",
           "timezone":"Europe/Belgrade"
        },
        {
           "id":3772,
           "country_id":858,
           "code":"",
           "name":"Artigas Department",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3773,
           "country_id":858,
           "code":"",
           "name":"Canelones Department",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3774,
           "country_id":858,
           "code":"",
           "name":"Cerro Largo Department",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3775,
           "country_id":858,
           "code":"",
           "name":"Colonia Department",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3776,
           "country_id":858,
           "code":"",
           "name":"Durazno",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3777,
           "country_id":858,
           "code":"",
           "name":"Flores",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3778,
           "country_id":858,
           "code":"",
           "name":"Florida Department",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3779,
           "country_id":858,
           "code":"",
           "name":"Lavalleja Department",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3780,
           "country_id":858,
           "code":"",
           "name":"Maldonado Department",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3781,
           "country_id":858,
           "code":"",
           "name":"Montevideo",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3782,
           "country_id":858,
           "code":"",
           "name":"Paysandú",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3783,
           "country_id":858,
           "code":"",
           "name":"Río Negro",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3784,
           "country_id":858,
           "code":"",
           "name":"Rivera",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3785,
           "country_id":858,
           "code":"",
           "name":"Rocha",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3786,
           "country_id":858,
           "code":"",
           "name":"Salto",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3787,
           "country_id":858,
           "code":"",
           "name":"San José",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3788,
           "country_id":858,
           "code":"",
           "name":"Soriano Department",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3789,
           "country_id":858,
           "code":"",
           "name":"Tacuarembó",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3790,
           "country_id":858,
           "code":"",
           "name":"Treinta y Tres",
           "standard_timezone":"(UTC-3.0) Montevideo",
           "timezone":"America/Montevideo"
        },
        {
           "id":3791,
           "country_id":860,
           "code":"",
           "name":"Andijon",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3792,
           "country_id":860,
           "code":"",
           "name":"Buxoro",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3793,
           "country_id":860,
           "code":"",
           "name":"Farg ona",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3794,
           "country_id":860,
           "code":"",
           "name":"Xorazm",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3795,
           "country_id":860,
           "code":"",
           "name":"Namangan",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3796,
           "country_id":860,
           "code":"",
           "name":"Navoiy",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3797,
           "country_id":860,
           "code":"",
           "name":"Qashqadaryo",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3798,
           "country_id":860,
           "code":"",
           "name":"Karakalpakstan",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3799,
           "country_id":860,
           "code":"",
           "name":"Samarqand",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3800,
           "country_id":860,
           "code":"",
           "name":"Surxondaryo",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3801,
           "country_id":860,
           "code":"",
           "name":"Toshkent Shahri",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3802,
           "country_id":860,
           "code":"",
           "name":"Toshkent",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3803,
           "country_id":860,
           "code":"",
           "name":"Jizzax",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3804,
           "country_id":860,
           "code":"",
           "name":"Sirdaryo",
           "standard_timezone":"(UTC+5.0) Samarkand",
           "timezone":"Asia/Samarkand"
        },
        {
           "id":3805,
           "country_id":862,
           "code":"",
           "name":"Amazonas",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3806,
           "country_id":862,
           "code":"",
           "name":"Anzoátegui",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3807,
           "country_id":862,
           "code":"",
           "name":"Apure",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3808,
           "country_id":862,
           "code":"",
           "name":"Aragua",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3809,
           "country_id":862,
           "code":"",
           "name":"Barinas",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3810,
           "country_id":862,
           "code":"",
           "name":"Bolívar",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3811,
           "country_id":862,
           "code":"",
           "name":"Carabobo",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3812,
           "country_id":862,
           "code":"",
           "name":"Cojedes",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3813,
           "country_id":862,
           "code":"",
           "name":"Delta Amacuro",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3814,
           "country_id":862,
           "code":"",
           "name":"Distrito Federal",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3815,
           "country_id":862,
           "code":"",
           "name":"Falcón",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3816,
           "country_id":862,
           "code":"",
           "name":"Guárico",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3817,
           "country_id":862,
           "code":"",
           "name":"Lara",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3818,
           "country_id":862,
           "code":"",
           "name":"Mérida",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3819,
           "country_id":862,
           "code":"",
           "name":"Miranda",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3820,
           "country_id":862,
           "code":"",
           "name":"Monagas",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3821,
           "country_id":862,
           "code":"",
           "name":"Isla Margarita",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3822,
           "country_id":862,
           "code":"",
           "name":"Portuguesa",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3823,
           "country_id":862,
           "code":"",
           "name":"Sucre",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3824,
           "country_id":862,
           "code":"",
           "name":"Táchira",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3825,
           "country_id":862,
           "code":"",
           "name":"Trujillo",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3826,
           "country_id":862,
           "code":"",
           "name":"Yaracuy",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3827,
           "country_id":862,
           "code":"",
           "name":"Zulia",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3828,
           "country_id":862,
           "code":"",
           "name":"Dependencias Federales",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3829,
           "country_id":862,
           "code":"",
           "name":"Distrito Capital",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3830,
           "country_id":862,
           "code":"",
           "name":"Vargas",
           "standard_timezone":"(UTC-4.50) Caracas",
           "timezone":"America/Caracas"
        },
        {
           "id":3831,
           "country_id":882,
           "code":"",
           "name":"A‘ana",
           "standard_timezone":"(UTC+13.0) Apia",
           "timezone":"Pacific/Apia"
        },
        {
           "id":3832,
           "country_id":882,
           "code":"",
           "name":"Aiga-i-le-Tai",
           "standard_timezone":"(UTC+13.0) Apia",
           "timezone":"Pacific/Apia"
        },
        {
           "id":3833,
           "country_id":882,
           "code":"",
           "name":"Atua",
           "standard_timezone":"(UTC+13.0) Apia",
           "timezone":"Pacific/Apia"
        },
        {
           "id":3834,
           "country_id":882,
           "code":"",
           "name":"Fa‘asaleleaga",
           "standard_timezone":"(UTC+13.0) Apia",
           "timezone":"Pacific/Apia"
        },
        {
           "id":3835,
           "country_id":882,
           "code":"",
           "name":"Gaga‘emauga",
           "standard_timezone":"(UTC+13.0) Apia",
           "timezone":"Pacific/Apia"
        },
        {
           "id":3836,
           "country_id":882,
           "code":"",
           "name":"Va‘a-o-Fonoti",
           "standard_timezone":"(UTC+13.0) Apia",
           "timezone":"Pacific/Apia"
        },
        {
           "id":3837,
           "country_id":882,
           "code":"",
           "name":"Gagaifomauga",
           "standard_timezone":"(UTC+13.0) Apia",
           "timezone":"Pacific/Apia"
        },
        {
           "id":3838,
           "country_id":882,
           "code":"",
           "name":"Palauli",
           "standard_timezone":"(UTC+13.0) Apia",
           "timezone":"Pacific/Apia"
        },
        {
           "id":3839,
           "country_id":882,
           "code":"",
           "name":"Satupa‘itea",
           "standard_timezone":"(UTC+13.0) Apia",
           "timezone":"Pacific/Apia"
        },
        {
           "id":3840,
           "country_id":882,
           "code":"",
           "name":"Tuamasaga",
           "standard_timezone":"(UTC+13.0) Apia",
           "timezone":"Pacific/Apia"
        },
        {
           "id":3841,
           "country_id":882,
           "code":"",
           "name":"Vaisigano",
           "standard_timezone":"(UTC+13.0) Apia",
           "timezone":"Pacific/Apia"
        },
        {
           "id":3842,
           "country_id":887,
           "code":"",
           "name":"Abyan",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3843,
           "country_id":887,
           "code":"",
           "name":"‘Adan",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3844,
           "country_id":887,
           "code":"",
           "name":"Al Mahrah",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3845,
           "country_id":887,
           "code":"",
           "name":"Ḩaḑramawt",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3846,
           "country_id":887,
           "code":"",
           "name":"Shabwah",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3847,
           "country_id":887,
           "code":"",
           "name":"San’a’",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3848,
           "country_id":887,
           "code":"",
           "name":"Ta’izz",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3849,
           "country_id":887,
           "code":"",
           "name":"Al Ḩudaydah",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3850,
           "country_id":887,
           "code":"",
           "name":"Dhamar",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3851,
           "country_id":887,
           "code":"",
           "name":"Al Maḩwīt",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3852,
           "country_id":887,
           "code":"",
           "name":"Dhamār",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3853,
           "country_id":887,
           "code":"",
           "name":"Maʼrib",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3854,
           "country_id":887,
           "code":"",
           "name":"Şa‘dah",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3855,
           "country_id":887,
           "code":"",
           "name":"Şan‘āʼ",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3856,
           "country_id":887,
           "code":"",
           "name":"Aḑ Ḑāli‘",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3857,
           "country_id":887,
           "code":"",
           "name":"Omran",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3858,
           "country_id":887,
           "code":"",
           "name":"Al Bayḑāʼ",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3859,
           "country_id":887,
           "code":"",
           "name":"Al Jawf",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3860,
           "country_id":887,
           "code":"",
           "name":"Ḩajjah",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3861,
           "country_id":887,
           "code":"",
           "name":"Ibb",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3862,
           "country_id":887,
           "code":"",
           "name":"Laḩij",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3863,
           "country_id":887,
           "code":"",
           "name":"Ta‘izz",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3864,
           "country_id":887,
           "code":"",
           "name":"Amanat Al Asimah",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3865,
           "country_id":887,
           "code":"",
           "name":"Muḩāfaz̧at Raymah",
           "standard_timezone":"(UTC+3.0) Aden",
           "timezone":"Asia/Aden"
        },
        {
           "id":3866,
           "country_id":891,
           "code":"",
           "name":"Crna Gora (Montenegro)",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":3867,
           "country_id":891,
           "code":"",
           "name":"Srbija (Serbia)",
           "standard_timezone":"(UTC+2.0) Podgorica",
           "timezone":"Europe/Podgorica"
        },
        {
           "id":3868,
           "country_id":894,
           "code":"",
           "name":"North-Western",
           "standard_timezone":"(UTC+2.0) Lusaka",
           "timezone":"Africa/Lusaka"
        },
        {
           "id":3869,
           "country_id":894,
           "code":"",
           "name":"Copperbelt",
           "standard_timezone":"(UTC+2.0) Lusaka",
           "timezone":"Africa/Lusaka"
        },
        {
           "id":3870,
           "country_id":894,
           "code":"",
           "name":"Western",
           "standard_timezone":"(UTC+2.0) Lusaka",
           "timezone":"Africa/Lusaka"
        },
        {
           "id":3871,
           "country_id":894,
           "code":"",
           "name":"Southern",
           "standard_timezone":"(UTC+2.0) Lusaka",
           "timezone":"Africa/Lusaka"
        },
        {
           "id":3872,
           "country_id":894,
           "code":"",
           "name":"Central",
           "standard_timezone":"(UTC+2.0) Lusaka",
           "timezone":"Africa/Lusaka"
        },
        {
           "id":3873,
           "country_id":894,
           "code":"",
           "name":"Eastern",
           "standard_timezone":"(UTC+2.0) Lusaka",
           "timezone":"Africa/Lusaka"
        },
        {
           "id":3874,
           "country_id":894,
           "code":"",
           "name":"Northern",
           "standard_timezone":"(UTC+2.0) Lusaka",
           "timezone":"Africa/Lusaka"
        },
        {
           "id":3875,
           "country_id":894,
           "code":"",
           "name":"Luapula",
           "standard_timezone":"(UTC+2.0) Lusaka",
           "timezone":"Africa/Lusaka"
        },
        {
           "id":3876,
           "country_id":894,
           "code":"",
           "name":"Lusaka",
           "standard_timezone":"(UTC+2.0) Lusaka",
           "timezone":"Africa/Lusaka"
        }
     ];
    public countriesStates = {
        "0":[
     
        ],
        "1":[
           {
              "id":1,
              "name":"Alabama",
              "country_id":1
           },
           {
              "id":2,
              "name":"Alaska",
              "country_id":1
           },
           {
              "id":3,
              "name":"Arizona",
              "country_id":1
           },
           {
              "id":4,
              "name":"Arkansas",
              "country_id":1
           },
           {
              "id":5,
              "name":"California",
              "country_id":1
           },
           {
              "id":6,
              "name":"Colorado",
              "country_id":1
           },
           {
              "id":7,
              "name":"Connecticut",
              "country_id":1
           },
           {
              "id":8,
              "name":"Delaware",
              "country_id":1
           },
           {
              "id":9,
              "name":"Florida",
              "country_id":1
           },
           {
              "id":10,
              "name":"Georgia",
              "country_id":1
           },
           {
              "id":11,
              "name":"Hawaii",
              "country_id":1
           },
           {
              "id":12,
              "name":"Idaho",
              "country_id":1
           },
           {
              "id":13,
              "name":"Illinois",
              "country_id":1
           },
           {
              "id":14,
              "name":"Indiana",
              "country_id":1
           },
           {
              "id":15,
              "name":"Iowa",
              "country_id":1
           },
           {
              "id":16,
              "name":"Kansas",
              "country_id":1
           },
           {
              "id":17,
              "name":"Kentucky",
              "country_id":1
           },
           {
              "id":18,
              "name":"Louisiana",
              "country_id":1
           },
           {
              "id":19,
              "name":"Maine",
              "country_id":1
           },
           {
              "id":20,
              "name":"Maryland",
              "country_id":1
           },
           {
              "id":21,
              "name":"Massachusetts",
              "country_id":1
           },
           {
              "id":22,
              "name":"Michigan",
              "country_id":1
           },
           {
              "id":23,
              "name":"Minnesota",
              "country_id":1
           },
           {
              "id":24,
              "name":"Mississippi",
              "country_id":1
           },
           {
              "id":25,
              "name":"Missouri",
              "country_id":1
           },
           {
              "id":26,
              "name":"Montana",
              "country_id":1
           },
           {
              "id":27,
              "name":"Nebraska",
              "country_id":1
           },
           {
              "id":28,
              "name":"Nevada",
              "country_id":1
           },
           {
              "id":29,
              "name":"New Hampshire",
              "country_id":1
           },
           {
              "id":30,
              "name":"New Jersey",
              "country_id":1
           },
           {
              "id":31,
              "name":"New Mexico",
              "country_id":1
           },
           {
              "id":32,
              "name":"New York",
              "country_id":1
           },
           {
              "id":33,
              "name":"North Carolina",
              "country_id":1
           },
           {
              "id":34,
              "name":"North Dakota",
              "country_id":1
           },
           {
              "id":35,
              "name":"Ohio",
              "country_id":1
           },
           {
              "id":36,
              "name":"Oklahoma",
              "country_id":1
           },
           {
              "id":37,
              "name":"Oregon",
              "country_id":1
           },
           {
              "id":38,
              "name":"Pennsylvania",
              "country_id":1
           },
           {
              "id":39,
              "name":"Rhode Island",
              "country_id":1
           },
           {
              "id":40,
              "name":"South Carolina",
              "country_id":1
           },
           {
              "id":41,
              "name":"South Dakota",
              "country_id":1
           },
           {
              "id":42,
              "name":"Tennessee",
              "country_id":1
           },
           {
              "id":43,
              "name":"Texas",
              "country_id":1
           },
           {
              "id":44,
              "name":"Utah",
              "country_id":1
           },
           {
              "id":45,
              "name":"Vermont",
              "country_id":1
           },
           {
              "id":46,
              "name":"Virginia",
              "country_id":1
           },
           {
              "id":47,
              "name":"Washington",
              "country_id":1
           },
           {
              "id":3877,
              "name":"District of Columbia",
              "country_id":1
           },
           {
              "id":48,
              "name":"West Virginia",
              "country_id":1
           },
           {
              "id":49,
              "name":"Wisconsin",
              "country_id":1
           },
           {
              "id":50,
              "name":"Wyoming",
              "country_id":1
           }
        ],
        "2":[
           {
              "id":51,
              "name":"Andaman and Nicobar Islands",
              "country_id":2
           },
           {
              "id":52,
              "name":"Andhra Pradesh",
              "country_id":2
           },
           {
              "id":53,
              "name":"Arunachal Pradesh",
              "country_id":2
           },
           {
              "id":54,
              "name":"Assam",
              "country_id":2
           },
           {
              "id":55,
              "name":"Bihar",
              "country_id":2
           },
           {
              "id":56,
              "name":"Chandigarh",
              "country_id":2
           },
           {
              "id":57,
              "name":"Chhattisgarh",
              "country_id":2
           },
           {
              "id":58,
              "name":"Dadra and Nagar Haveli",
              "country_id":2
           },
           {
              "id":59,
              "name":"Daman and Diu",
              "country_id":2
           },
           {
              "id":60,
              "name":"Goa",
              "country_id":2
           },
           {
              "id":61,
              "name":"Gujarat",
              "country_id":2
           },
           {
              "id":62,
              "name":"Haryana",
              "country_id":2
           },
           {
              "id":63,
              "name":"Himachal Pradesh",
              "country_id":2
           },
           {
              "id":64,
              "name":"Jammu and Kashmir",
              "country_id":2
           },
           {
              "id":65,
              "name":"Jharkhand",
              "country_id":2
           },
           {
              "id":66,
              "name":"Karnataka",
              "country_id":2
           },
           {
              "id":67,
              "name":"Kerala",
              "country_id":2
           },
           {
              "id":68,
              "name":"Lakshadweep",
              "country_id":2
           },
           {
              "id":69,
              "name":"Madhya Pradesh",
              "country_id":2
           },
           {
              "id":70,
              "name":"Maharashtra",
              "country_id":2
           },
           {
              "id":71,
              "name":"Manipur",
              "country_id":2
           },
           {
              "id":72,
              "name":"Meghalaya",
              "country_id":2
           },
           {
              "id":73,
              "name":"Mizoram",
              "country_id":2
           },
           {
              "id":74,
              "name":"Nagaland",
              "country_id":2
           },
           {
              "id":75,
              "name":"National Capital Territory of Delhi",
              "country_id":2
           },
           {
              "id":76,
              "name":"Odisha",
              "country_id":2
           },
           {
              "id":77,
              "name":"Pondicherry",
              "country_id":2
           },
           {
              "id":78,
              "name":"Punjab",
              "country_id":2
           },
           {
              "id":79,
              "name":"Rajasthan",
              "country_id":2
           },
           {
              "id":80,
              "name":"Sikkim",
              "country_id":2
           },
           {
              "id":81,
              "name":"Tamil Nadu",
              "country_id":2
           },
           {
              "id":82,
              "name":"Telangana",
              "country_id":2
           },
           {
              "id":83,
              "name":"Tripura",
              "country_id":2
           },
           {
              "id":84,
              "name":"Uttar Pradesh",
              "country_id":2
           },
           {
              "id":85,
              "name":"Uttarakhand",
              "country_id":2
           },
           {
              "id":86,
              "name":"West Bengal",
              "country_id":2
           }
        ],
        "4":[
           {
              "id":87,
              "name":"Badakhshan",
              "country_id":4
           },
           {
              "id":88,
              "name":"Badghis Province",
              "country_id":4
           },
           {
              "id":89,
              "name":"Baghlān",
              "country_id":4
           },
           {
              "id":90,
              "name":"Bāmīān",
              "country_id":4
           },
           {
              "id":91,
              "name":"Farah",
              "country_id":4
           },
           {
              "id":92,
              "name":"Faryab Province",
              "country_id":4
           },
           {
              "id":93,
              "name":"Ghaznī",
              "country_id":4
           },
           {
              "id":94,
              "name":"Ghowr",
              "country_id":4
           },
           {
              "id":95,
              "name":"Helmand Province",
              "country_id":4
           },
           {
              "id":96,
              "name":"Herat Province",
              "country_id":4
           },
           {
              "id":97,
              "name":"Kabul",
              "country_id":4
           },
           {
              "id":98,
              "name":"Kāpīsā",
              "country_id":4
           },
           {
              "id":99,
              "name":"Lowgar",
              "country_id":4
           },
           {
              "id":100,
              "name":"Nangarhār",
              "country_id":4
           },
           {
              "id":101,
              "name":"Nīmrūz",
              "country_id":4
           },
           {
              "id":102,
              "name":"Orūzgān",
              "country_id":4
           },
           {
              "id":103,
              "name":"Kandahār",
              "country_id":4
           },
           {
              "id":104,
              "name":"Kunduz Province",
              "country_id":4
           },
           {
              "id":105,
              "name":"Takhār",
              "country_id":4
           },
           {
              "id":106,
              "name":"Vardak",
              "country_id":4
           },
           {
              "id":107,
              "name":"Zabul Province",
              "country_id":4
           },
           {
              "id":108,
              "name":"Paktīkā",
              "country_id":4
           },
           {
              "id":109,
              "name":"Balkh",
              "country_id":4
           },
           {
              "id":110,
              "name":"Jowzjān",
              "country_id":4
           },
           {
              "id":111,
              "name":"Samangān",
              "country_id":4
           },
           {
              "id":112,
              "name":"Sar-e Pol",
              "country_id":4
           },
           {
              "id":113,
              "name":"Konar",
              "country_id":4
           },
           {
              "id":114,
              "name":"Laghmān",
              "country_id":4
           },
           {
              "id":115,
              "name":"Paktia Province",
              "country_id":4
           },
           {
              "id":116,
              "name":"Khowst",
              "country_id":4
           },
           {
              "id":117,
              "name":"Nūrestān",
              "country_id":4
           },
           {
              "id":118,
              "name":"Parvān",
              "country_id":4
           },
           {
              "id":119,
              "name":"Dāykondī",
              "country_id":4
           },
           {
              "id":120,
              "name":"Panjshīr",
              "country_id":4
           }
        ],
        "8":[
           {
              "id":121,
              "name":"Berat",
              "country_id":8
           },
           {
              "id":122,
              "name":"Dibër",
              "country_id":8
           },
           {
              "id":123,
              "name":"Durrës",
              "country_id":8
           },
           {
              "id":124,
              "name":"Elbasan",
              "country_id":8
           },
           {
              "id":125,
              "name":"Fier",
              "country_id":8
           },
           {
              "id":126,
              "name":"Gjirokastër",
              "country_id":8
           },
           {
              "id":127,
              "name":"Korçë",
              "country_id":8
           },
           {
              "id":128,
              "name":"Kukës",
              "country_id":8
           },
           {
              "id":129,
              "name":"Lezhë",
              "country_id":8
           },
           {
              "id":130,
              "name":"Shkodër",
              "country_id":8
           },
           {
              "id":131,
              "name":"Tiranë",
              "country_id":8
           },
           {
              "id":132,
              "name":"Vlorë",
              "country_id":8
           }
        ],
        "12":[
           {
              "id":133,
              "name":"Alger",
              "country_id":12
           },
           {
              "id":134,
              "name":"Batna",
              "country_id":12
           },
           {
              "id":135,
              "name":"Constantine",
              "country_id":12
           },
           {
              "id":136,
              "name":"Médéa",
              "country_id":12
           },
           {
              "id":137,
              "name":"Mostaganem",
              "country_id":12
           },
           {
              "id":138,
              "name":"Oran",
              "country_id":12
           },
           {
              "id":139,
              "name":"Saïda",
              "country_id":12
           },
           {
              "id":140,
              "name":"Sétif",
              "country_id":12
           },
           {
              "id":141,
              "name":"Tiaret",
              "country_id":12
           },
           {
              "id":142,
              "name":"Tizi Ouzou",
              "country_id":12
           },
           {
              "id":143,
              "name":"Tlemcen",
              "country_id":12
           },
           {
              "id":144,
              "name":"Bejaïa",
              "country_id":12
           },
           {
              "id":145,
              "name":"Biskra",
              "country_id":12
           },
           {
              "id":146,
              "name":"Blida",
              "country_id":12
           },
           {
              "id":147,
              "name":"Bouira",
              "country_id":12
           },
           {
              "id":148,
              "name":"Djelfa",
              "country_id":12
           },
           {
              "id":149,
              "name":"Guelma",
              "country_id":12
           },
           {
              "id":150,
              "name":"Jijel",
              "country_id":12
           },
           {
              "id":151,
              "name":"Laghouat",
              "country_id":12
           },
           {
              "id":152,
              "name":"Mascara",
              "country_id":12
           },
           {
              "id":153,
              "name":"Mʼsila",
              "country_id":12
           },
           {
              "id":154,
              "name":"Oum el Bouaghi",
              "country_id":12
           },
           {
              "id":155,
              "name":"Sidi Bel Abbès",
              "country_id":12
           },
           {
              "id":156,
              "name":"Skikda",
              "country_id":12
           },
           {
              "id":157,
              "name":"Tébessa",
              "country_id":12
           },
           {
              "id":158,
              "name":"Adrar",
              "country_id":12
           },
           {
              "id":159,
              "name":"Aïn Defla",
              "country_id":12
           },
           {
              "id":160,
              "name":"Aïn Temouchent",
              "country_id":12
           },
           {
              "id":161,
              "name":"Annaba",
              "country_id":12
           },
           {
              "id":162,
              "name":"Béchar",
              "country_id":12
           },
           {
              "id":163,
              "name":"Bordj Bou Arréridj",
              "country_id":12
           },
           {
              "id":164,
              "name":"Boumerdes",
              "country_id":12
           },
           {
              "id":165,
              "name":"Chlef",
              "country_id":12
           },
           {
              "id":166,
              "name":"El Bayadh",
              "country_id":12
           },
           {
              "id":167,
              "name":"El Oued",
              "country_id":12
           },
           {
              "id":168,
              "name":"El Tarf",
              "country_id":12
           },
           {
              "id":169,
              "name":"Ghardaïa",
              "country_id":12
           },
           {
              "id":170,
              "name":"Illizi",
              "country_id":12
           },
           {
              "id":171,
              "name":"Khenchela",
              "country_id":12
           },
           {
              "id":172,
              "name":"Mila",
              "country_id":12
           },
           {
              "id":173,
              "name":"Naama النعامة",
              "country_id":12
           },
           {
              "id":174,
              "name":"Ouargla",
              "country_id":12
           },
           {
              "id":175,
              "name":"Relizane",
              "country_id":12
           },
           {
              "id":176,
              "name":"Souk Ahras",
              "country_id":12
           },
           {
              "id":177,
              "name":"Tamanghasset",
              "country_id":12
           },
           {
              "id":178,
              "name":"Tindouf",
              "country_id":12
           },
           {
              "id":179,
              "name":"Tipaza",
              "country_id":12
           },
           {
              "id":180,
              "name":"Tissemsilt",
              "country_id":12
           }
        ],
        "16":[
           {
              "id":181,
              "name":"American Samoa",
              "country_id":16
           }
        ],
        "20":[
           {
              "id":182,
              "name":"Parròquia de Canillo",
              "country_id":20
           },
           {
              "id":183,
              "name":"Parròquia dʼEncamp",
              "country_id":20
           },
           {
              "id":184,
              "name":"Parròquia de la Massana",
              "country_id":20
           },
           {
              "id":185,
              "name":"Parròquia dʼOrdino",
              "country_id":20
           },
           {
              "id":186,
              "name":"Parròquia de Sant Julià de Lòria",
              "country_id":20
           },
           {
              "id":187,
              "name":"Parròquia dʼAndorra la Vella",
              "country_id":20
           },
           {
              "id":188,
              "name":"Parròquia dʼEscaldes-Engordany",
              "country_id":20
           }
        ],
        "24":[
           {
              "id":189,
              "name":"Benguela",
              "country_id":24
           },
           {
              "id":190,
              "name":"Bié",
              "country_id":24
           },
           {
              "id":191,
              "name":"Cabinda",
              "country_id":24
           },
           {
              "id":192,
              "name":"Cuando Cubango",
              "country_id":24
           },
           {
              "id":193,
              "name":"Cuanza Norte",
              "country_id":24
           },
           {
              "id":194,
              "name":"Cuanza Sul",
              "country_id":24
           },
           {
              "id":195,
              "name":"Cunene",
              "country_id":24
           },
           {
              "id":196,
              "name":"Huambo",
              "country_id":24
           },
           {
              "id":197,
              "name":"Huíla",
              "country_id":24
           },
           {
              "id":198,
              "name":"Luanda",
              "country_id":24
           },
           {
              "id":199,
              "name":"Malanje",
              "country_id":24
           },
           {
              "id":200,
              "name":"Namibe",
              "country_id":24
           },
           {
              "id":201,
              "name":"Moxico",
              "country_id":24
           },
           {
              "id":202,
              "name":"Uíge",
              "country_id":24
           },
           {
              "id":203,
              "name":"Zaire",
              "country_id":24
           },
           {
              "id":204,
              "name":"Lunda Norte",
              "country_id":24
           },
           {
              "id":205,
              "name":"Lunda Sul",
              "country_id":24
           },
           {
              "id":206,
              "name":"Bengo",
              "country_id":24
           }
        ],
        "28":[
           {
              "id":207,
              "name":"Redonda",
              "country_id":28
           },
           {
              "id":208,
              "name":"Barbuda",
              "country_id":28
           },
           {
              "id":209,
              "name":"Saint George",
              "country_id":28
           },
           {
              "id":210,
              "name":"Saint John",
              "country_id":28
           },
           {
              "id":211,
              "name":"Saint Mary",
              "country_id":28
           },
           {
              "id":212,
              "name":"Saint Paul",
              "country_id":28
           },
           {
              "id":213,
              "name":"Saint Peter",
              "country_id":28
           },
           {
              "id":214,
              "name":"Saint Philip",
              "country_id":28
           }
        ],
        "31":[
           {
              "id":215,
              "name":"Abşeron",
              "country_id":31
           },
           {
              "id":216,
              "name":"Ağcabǝdi",
              "country_id":31
           },
           {
              "id":217,
              "name":"Ağdam",
              "country_id":31
           },
           {
              "id":218,
              "name":"Ağdaş",
              "country_id":31
           },
           {
              "id":219,
              "name":"Ağstafa",
              "country_id":31
           },
           {
              "id":220,
              "name":"Ağsu",
              "country_id":31
           },
           {
              "id":221,
              "name":"Əli Bayramli",
              "country_id":31
           },
           {
              "id":222,
              "name":"Astara",
              "country_id":31
           },
           {
              "id":223,
              "name":"Baki",
              "country_id":31
           },
           {
              "id":224,
              "name":"Balakǝn",
              "country_id":31
           },
           {
              "id":225,
              "name":"Bǝrdǝ",
              "country_id":31
           },
           {
              "id":226,
              "name":"Beylǝqan",
              "country_id":31
           },
           {
              "id":227,
              "name":"Bilǝsuvar",
              "country_id":31
           },
           {
              "id":228,
              "name":"Cǝbrayıl",
              "country_id":31
           },
           {
              "id":229,
              "name":"Cǝlilabad",
              "country_id":31
           },
           {
              "id":230,
              "name":"Daşkǝsǝn",
              "country_id":31
           },
           {
              "id":231,
              "name":"Dǝvǝçi",
              "country_id":31
           },
           {
              "id":232,
              "name":"Füzuli",
              "country_id":31
           },
           {
              "id":233,
              "name":"Gǝdǝbǝy",
              "country_id":31
           },
           {
              "id":234,
              "name":"Gǝncǝ",
              "country_id":31
           },
           {
              "id":235,
              "name":"Goranboy",
              "country_id":31
           },
           {
              "id":236,
              "name":"Göyçay",
              "country_id":31
           },
           {
              "id":237,
              "name":"Hacıqabul",
              "country_id":31
           },
           {
              "id":238,
              "name":"İmişli",
              "country_id":31
           },
           {
              "id":239,
              "name":"İsmayıllı",
              "country_id":31
           },
           {
              "id":240,
              "name":"Kǝlbǝcǝr",
              "country_id":31
           },
           {
              "id":241,
              "name":"Kürdǝmir",
              "country_id":31
           },
           {
              "id":242,
              "name":"Laçın",
              "country_id":31
           },
           {
              "id":243,
              "name":"Lǝnkǝran",
              "country_id":31
           },
           {
              "id":244,
              "name":"Lǝnkǝran Şǝhǝri",
              "country_id":31
           },
           {
              "id":245,
              "name":"Lerik",
              "country_id":31
           },
           {
              "id":246,
              "name":"Masallı",
              "country_id":31
           },
           {
              "id":247,
              "name":"Mingǝcevir",
              "country_id":31
           },
           {
              "id":248,
              "name":"Naftalan",
              "country_id":31
           },
           {
              "id":249,
              "name":"Nakhichevan",
              "country_id":31
           },
           {
              "id":250,
              "name":"Neftçala",
              "country_id":31
           },
           {
              "id":251,
              "name":"Oğuz",
              "country_id":31
           },
           {
              "id":252,
              "name":"Qǝbǝlǝ",
              "country_id":31
           },
           {
              "id":253,
              "name":"Qǝx",
              "country_id":31
           },
           {
              "id":254,
              "name":"Qazax",
              "country_id":31
           },
           {
              "id":255,
              "name":"Qobustan",
              "country_id":31
           },
           {
              "id":256,
              "name":"Quba",
              "country_id":31
           },
           {
              "id":257,
              "name":"Qubadlı",
              "country_id":31
           },
           {
              "id":258,
              "name":"Qusar",
              "country_id":31
           },
           {
              "id":259,
              "name":"Saatlı",
              "country_id":31
           },
           {
              "id":260,
              "name":"Sabirabad",
              "country_id":31
           },
           {
              "id":261,
              "name":"Şǝki",
              "country_id":31
           },
           {
              "id":262,
              "name":"Şǝki",
              "country_id":31
           },
           {
              "id":263,
              "name":"Salyan",
              "country_id":31
           },
           {
              "id":264,
              "name":"Şamaxı",
              "country_id":31
           },
           {
              "id":265,
              "name":"Şǝmkir",
              "country_id":31
           },
           {
              "id":266,
              "name":"Samux",
              "country_id":31
           },
           {
              "id":267,
              "name":"Siyǝzǝn",
              "country_id":31
           },
           {
              "id":268,
              "name":"Sumqayit",
              "country_id":31
           },
           {
              "id":269,
              "name":"Şuşa",
              "country_id":31
           },
           {
              "id":270,
              "name":"Şuşa Şəhəri",
              "country_id":31
           },
           {
              "id":271,
              "name":"Tǝrtǝr",
              "country_id":31
           },
           {
              "id":272,
              "name":"Tovuz",
              "country_id":31
           },
           {
              "id":273,
              "name":"Ucar",
              "country_id":31
           },
           {
              "id":274,
              "name":"Xaçmaz",
              "country_id":31
           },
           {
              "id":275,
              "name":"Xankǝndi",
              "country_id":31
           },
           {
              "id":276,
              "name":"Xanlar",
              "country_id":31
           },
           {
              "id":277,
              "name":"Xızı",
              "country_id":31
           },
           {
              "id":278,
              "name":"Xocalı",
              "country_id":31
           },
           {
              "id":279,
              "name":"Xocavǝnd",
              "country_id":31
           },
           {
              "id":280,
              "name":"Yardımlı",
              "country_id":31
           },
           {
              "id":281,
              "name":"Yevlax",
              "country_id":31
           },
           {
              "id":282,
              "name":"Yevlax",
              "country_id":31
           },
           {
              "id":283,
              "name":"Zǝngilan",
              "country_id":31
           },
           {
              "id":284,
              "name":"Zaqatala",
              "country_id":31
           },
           {
              "id":285,
              "name":"Zǝrdab",
              "country_id":31
           }
        ],
        "32":[
           {
              "id":286,
              "name":"Buenos Aires",
              "country_id":32
           },
           {
              "id":287,
              "name":"Catamarca",
              "country_id":32
           },
           {
              "id":288,
              "name":"Chaco",
              "country_id":32
           },
           {
              "id":289,
              "name":"Chubut",
              "country_id":32
           },
           {
              "id":290,
              "name":"Córdoba",
              "country_id":32
           },
           {
              "id":291,
              "name":"Corrientes",
              "country_id":32
           },
           {
              "id":292,
              "name":"Distrito Federal",
              "country_id":32
           },
           {
              "id":293,
              "name":"Entre Ríos",
              "country_id":32
           },
           {
              "id":294,
              "name":"Formosa",
              "country_id":32
           },
           {
              "id":295,
              "name":"Jujuy",
              "country_id":32
           },
           {
              "id":296,
              "name":"La Pampa",
              "country_id":32
           },
           {
              "id":297,
              "name":"La Rioja",
              "country_id":32
           },
           {
              "id":298,
              "name":"Mendoza",
              "country_id":32
           },
           {
              "id":299,
              "name":"Misiones",
              "country_id":32
           },
           {
              "id":300,
              "name":"Neuquén",
              "country_id":32
           },
           {
              "id":301,
              "name":"Río Negro",
              "country_id":32
           },
           {
              "id":302,
              "name":"Salta",
              "country_id":32
           },
           {
              "id":303,
              "name":"San Juan",
              "country_id":32
           },
           {
              "id":304,
              "name":"San Luis",
              "country_id":32
           },
           {
              "id":305,
              "name":"Santa Cruz",
              "country_id":32
           },
           {
              "id":306,
              "name":"Santa Fe",
              "country_id":32
           },
           {
              "id":307,
              "name":"Santiago del Estero",
              "country_id":32
           },
           {
              "id":308,
              "name":"Tierra del Fuego, Antártida e Islas del Atlán",
              "country_id":32
           },
           {
              "id":309,
              "name":"Tucumán",
              "country_id":32
           }
        ],
        "36":[
           {
              "id":310,
              "name":"Australian Capital Territory",
              "country_id":36
           },
           {
              "id":311,
              "name":"New South Wales",
              "country_id":36
           },
           {
              "id":312,
              "name":"Northern Territory",
              "country_id":36
           },
           {
              "id":313,
              "name":"Queensland",
              "country_id":36
           },
           {
              "id":314,
              "name":"South Australia",
              "country_id":36
           },
           {
              "id":315,
              "name":"Tasmania",
              "country_id":36
           },
           {
              "id":316,
              "name":"Victoria",
              "country_id":36
           },
           {
              "id":317,
              "name":"Western Australia",
              "country_id":36
           }
        ],
        "40":[
           {
              "id":318,
              "name":"Burgenland",
              "country_id":40
           },
           {
              "id":319,
              "name":"Carinthia",
              "country_id":40
           },
           {
              "id":320,
              "name":"Lower Austria",
              "country_id":40
           },
           {
              "id":321,
              "name":"Upper Austria",
              "country_id":40
           },
           {
              "id":322,
              "name":"Salzburg",
              "country_id":40
           },
           {
              "id":323,
              "name":"Styria",
              "country_id":40
           },
           {
              "id":324,
              "name":"Tyrol",
              "country_id":40
           },
           {
              "id":325,
              "name":"Vorarlberg",
              "country_id":40
           },
           {
              "id":326,
              "name":"Vienna",
              "country_id":40
           }
        ],
        "44":[
           {
              "id":327,
              "name":"Bimini",
              "country_id":44
           },
           {
              "id":328,
              "name":"Cat Island",
              "country_id":44
           },
           {
              "id":329,
              "name":"Inagua",
              "country_id":44
           },
           {
              "id":330,
              "name":"Long Island",
              "country_id":44
           },
           {
              "id":331,
              "name":"Mayaguana",
              "country_id":44
           },
           {
              "id":332,
              "name":"Ragged Island",
              "country_id":44
           },
           {
              "id":333,
              "name":"Harbour Island, Eleuthera",
              "country_id":44
           },
           {
              "id":334,
              "name":"North Abaco",
              "country_id":44
           },
           {
              "id":335,
              "name":"Acklins",
              "country_id":44
           },
           {
              "id":336,
              "name":"City of Freeport, Grand Bahama",
              "country_id":44
           },
           {
              "id":337,
              "name":"South Andros",
              "country_id":44
           },
           {
              "id":338,
              "name":"Hope Town, Abaco",
              "country_id":44
           },
           {
              "id":339,
              "name":"Mangrove Cay, Andros",
              "country_id":44
           },
           {
              "id":340,
              "name":"Mooreʼs Island, Abaco",
              "country_id":44
           },
           {
              "id":341,
              "name":"Rum Cay",
              "country_id":44
           },
           {
              "id":342,
              "name":"North Andros",
              "country_id":44
           },
           {
              "id":343,
              "name":"North Eleuthera",
              "country_id":44
           },
           {
              "id":344,
              "name":"South Eleuthera",
              "country_id":44
           },
           {
              "id":345,
              "name":"South Abaco",
              "country_id":44
           },
           {
              "id":346,
              "name":"San Salvador",
              "country_id":44
           },
           {
              "id":347,
              "name":"Berry Islands",
              "country_id":44
           },
           {
              "id":348,
              "name":"Black Point, Exuma",
              "country_id":44
           },
           {
              "id":349,
              "name":"Central Abaco",
              "country_id":44
           },
           {
              "id":350,
              "name":"Central Andros",
              "country_id":44
           },
           {
              "id":351,
              "name":"Central Eleuthera",
              "country_id":44
           },
           {
              "id":352,
              "name":"Crooked Island",
              "country_id":44
           },
           {
              "id":353,
              "name":"East Grand Bahama",
              "country_id":44
           },
           {
              "id":354,
              "name":"Exuma",
              "country_id":44
           },
           {
              "id":355,
              "name":"Grand Cay, Abaco",
              "country_id":44
           },
           {
              "id":356,
              "name":"Spanish Wells, Eleuthera",
              "country_id":44
           },
           {
              "id":357,
              "name":"West Grand Bahama",
              "country_id":44
           }
        ],
        "48":[
           {
              "id":358,
              "name":"Southern Governate",
              "country_id":48
           },
           {
              "id":359,
              "name":"Northern Governate",
              "country_id":48
           },
           {
              "id":360,
              "name":"Capital Governate",
              "country_id":48
           },
           {
              "id":361,
              "name":"Central Governate",
              "country_id":48
           },
           {
              "id":362,
              "name":"Muharraq Governate",
              "country_id":48
           }
        ],
        "50":[
           {
              "id":363,
              "name":"BG80",
              "country_id":50
           },
           {
              "id":364,
              "name":"Dhaka",
              "country_id":50
           },
           {
              "id":365,
              "name":"Khulna",
              "country_id":50
           },
           {
              "id":366,
              "name":"Rājshāhi",
              "country_id":50
           },
           {
              "id":367,
              "name":"Chittagong",
              "country_id":50
           },
           {
              "id":368,
              "name":"Barisāl",
              "country_id":50
           },
           {
              "id":369,
              "name":"Sylhet",
              "country_id":50
           }
        ],
        "51":[
           {
              "id":370,
              "name":"Aragatsotn",
              "country_id":51
           },
           {
              "id":371,
              "name":"Ararat",
              "country_id":51
           },
           {
              "id":372,
              "name":"Armavir",
              "country_id":51
           },
           {
              "id":373,
              "name":"Gegharkʼunikʼ",
              "country_id":51
           },
           {
              "id":374,
              "name":"Kotaykʼ",
              "country_id":51
           },
           {
              "id":375,
              "name":"Lorri",
              "country_id":51
           },
           {
              "id":376,
              "name":"Shirak",
              "country_id":51
           },
           {
              "id":377,
              "name":"Syunikʼ",
              "country_id":51
           },
           {
              "id":378,
              "name":"Tavush",
              "country_id":51
           },
           {
              "id":379,
              "name":"Vayotsʼ Dzor",
              "country_id":51
           },
           {
              "id":380,
              "name":"Yerevan",
              "country_id":51
           }
        ],
        "52":[
           {
              "id":381,
              "name":"Christ Church",
              "country_id":52
           },
           {
              "id":382,
              "name":"Saint Andrew",
              "country_id":52
           },
           {
              "id":383,
              "name":"Saint George",
              "country_id":52
           },
           {
              "id":384,
              "name":"Saint James",
              "country_id":52
           },
           {
              "id":385,
              "name":"Saint John",
              "country_id":52
           },
           {
              "id":386,
              "name":"Saint Joseph",
              "country_id":52
           },
           {
              "id":387,
              "name":"Saint Lucy",
              "country_id":52
           },
           {
              "id":388,
              "name":"Saint Michael",
              "country_id":52
           },
           {
              "id":389,
              "name":"Saint Peter",
              "country_id":52
           },
           {
              "id":390,
              "name":"Saint Philip",
              "country_id":52
           },
           {
              "id":391,
              "name":"Saint Thomas",
              "country_id":52
           }
        ],
        "56":[
           {
              "id":392,
              "name":"Bruxelles-Capitale",
              "country_id":56
           },
           {
              "id":393,
              "name":"Flanders",
              "country_id":56
           },
           {
              "id":394,
              "name":"Wallonia",
              "country_id":56
           }
        ],
        "60":[
           {
              "id":395,
              "name":"Devonshire",
              "country_id":60
           },
           {
              "id":396,
              "name":"Hamilton (parish)",
              "country_id":60
           },
           {
              "id":397,
              "name":"Hamilton (city)",
              "country_id":60
           },
           {
              "id":398,
              "name":"Paget",
              "country_id":60
           },
           {
              "id":399,
              "name":"Pembroke",
              "country_id":60
           },
           {
              "id":400,
              "name":"Saint Georgeʼs (parish)",
              "country_id":60
           },
           {
              "id":401,
              "name":"Saint Georgeʼs (city)",
              "country_id":60
           },
           {
              "id":402,
              "name":"Sandys",
              "country_id":60
           },
           {
              "id":403,
              "name":"Smithʼs",
              "country_id":60
           },
           {
              "id":404,
              "name":"Southampton",
              "country_id":60
           },
           {
              "id":405,
              "name":"Warwick",
              "country_id":60
           }
        ],
        "64":[
           {
              "id":406,
              "name":"Bumthang",
              "country_id":64
           },
           {
              "id":407,
              "name":"Chhukha",
              "country_id":64
           },
           {
              "id":408,
              "name":"Chirang",
              "country_id":64
           },
           {
              "id":409,
              "name":"Daga",
              "country_id":64
           },
           {
              "id":410,
              "name":"Geylegphug",
              "country_id":64
           },
           {
              "id":411,
              "name":"Ha",
              "country_id":64
           },
           {
              "id":412,
              "name":"Lhuntshi",
              "country_id":64
           },
           {
              "id":413,
              "name":"Mongar",
              "country_id":64
           },
           {
              "id":414,
              "name":"Paro District",
              "country_id":64
           },
           {
              "id":415,
              "name":"Pemagatsel",
              "country_id":64
           },
           {
              "id":416,
              "name":"Samchi",
              "country_id":64
           },
           {
              "id":417,
              "name":"Samdrup Jongkhar District",
              "country_id":64
           },
           {
              "id":418,
              "name":"Shemgang",
              "country_id":64
           },
           {
              "id":419,
              "name":"Tashigang",
              "country_id":64
           },
           {
              "id":420,
              "name":"Thimphu",
              "country_id":64
           },
           {
              "id":421,
              "name":"Tongsa",
              "country_id":64
           },
           {
              "id":422,
              "name":"Wangdi Phodrang",
              "country_id":64
           }
        ],
        "68":[
           {
              "id":423,
              "name":"Chuquisaca",
              "country_id":68
           },
           {
              "id":424,
              "name":"Cochabamba",
              "country_id":68
           },
           {
              "id":425,
              "name":"El Beni",
              "country_id":68
           },
           {
              "id":426,
              "name":"La Paz",
              "country_id":68
           },
           {
              "id":427,
              "name":"Oruro",
              "country_id":68
           },
           {
              "id":428,
              "name":"Pando",
              "country_id":68
           },
           {
              "id":429,
              "name":"Potosí",
              "country_id":68
           },
           {
              "id":430,
              "name":"Santa Cruz",
              "country_id":68
           },
           {
              "id":431,
              "name":"Tarija",
              "country_id":68
           }
        ],
        "70":[
           {
              "id":432,
              "name":"Federation of Bosnia and Herzegovina",
              "country_id":70
           },
           {
              "id":433,
              "name":"Republika Srpska",
              "country_id":70
           },
           {
              "id":434,
              "name":"Brčko",
              "country_id":70
           }
        ],
        "72":[
           {
              "id":435,
              "name":"Central",
              "country_id":72
           },
           {
              "id":436,
              "name":"Chobe",
              "country_id":72
           },
           {
              "id":437,
              "name":"Ghanzi",
              "country_id":72
           },
           {
              "id":438,
              "name":"Kgalagadi",
              "country_id":72
           },
           {
              "id":439,
              "name":"Kgatleng",
              "country_id":72
           },
           {
              "id":440,
              "name":"Kweneng",
              "country_id":72
           },
           {
              "id":441,
              "name":"Ngamiland",
              "country_id":72
           },
           {
              "id":442,
              "name":"North East",
              "country_id":72
           },
           {
              "id":443,
              "name":"South East",
              "country_id":72
           },
           {
              "id":444,
              "name":"Southern",
              "country_id":72
           },
           {
              "id":445,
              "name":"North West",
              "country_id":72
           }
        ],
        "74":[
     
        ],
        "76":[
           {
              "id":446,
              "name":"Acre",
              "country_id":76
           },
           {
              "id":447,
              "name":"Alagoas",
              "country_id":76
           },
           {
              "id":448,
              "name":"Amapá",
              "country_id":76
           },
           {
              "id":449,
              "name":"Estado do Amazonas",
              "country_id":76
           },
           {
              "id":450,
              "name":"Bahia",
              "country_id":76
           },
           {
              "id":451,
              "name":"Ceará",
              "country_id":76
           },
           {
              "id":452,
              "name":"Distrito Federal",
              "country_id":76
           },
           {
              "id":453,
              "name":"Espírito Santo",
              "country_id":76
           },
           {
              "id":454,
              "name":"Fernando de Noronha",
              "country_id":76
           },
           {
              "id":455,
              "name":"Goias",
              "country_id":76
           },
           {
              "id":456,
              "name":"Mato Grosso do Sul",
              "country_id":76
           },
           {
              "id":457,
              "name":"Maranhão",
              "country_id":76
           },
           {
              "id":458,
              "name":"Mato Grosso",
              "country_id":76
           },
           {
              "id":459,
              "name":"Minas Gerais",
              "country_id":76
           },
           {
              "id":460,
              "name":"Pará",
              "country_id":76
           },
           {
              "id":461,
              "name":"Paraíba",
              "country_id":76
           },
           {
              "id":462,
              "name":"Paraná",
              "country_id":76
           },
           {
              "id":463,
              "name":"Pernambuco",
              "country_id":76
           },
           {
              "id":464,
              "name":"Piauí",
              "country_id":76
           },
           {
              "id":465,
              "name":"State of Rio de Janeiro",
              "country_id":76
           },
           {
              "id":466,
              "name":"Rio Grande do Norte",
              "country_id":76
           },
           {
              "id":467,
              "name":"Rio Grande do Sul",
              "country_id":76
           },
           {
              "id":468,
              "name":"Rondônia",
              "country_id":76
           },
           {
              "id":469,
              "name":"Roraima",
              "country_id":76
           },
           {
              "id":470,
              "name":"Santa Catarina",
              "country_id":76
           },
           {
              "id":471,
              "name":"São Paulo",
              "country_id":76
           },
           {
              "id":472,
              "name":"Sergipe",
              "country_id":76
           },
           {
              "id":473,
              "name":"Estado de Goiás",
              "country_id":76
           },
           {
              "id":474,
              "name":"Pernambuco",
              "country_id":76
           },
           {
              "id":475,
              "name":"Tocantins",
              "country_id":76
           }
        ],
        "84":[
           {
              "id":476,
              "name":"Belize",
              "country_id":84
           },
           {
              "id":477,
              "name":"Cayo",
              "country_id":84
           },
           {
              "id":478,
              "name":"Corozal",
              "country_id":84
           },
           {
              "id":479,
              "name":"Orange Walk",
              "country_id":84
           },
           {
              "id":480,
              "name":"Stann Creek",
              "country_id":84
           },
           {
              "id":481,
              "name":"Toledo",
              "country_id":84
           }
        ],
        "86":[
           {
              "id":482,
              "name":"British Indian Ocean Territory",
              "country_id":86
           }
        ],
        "90":[
           {
              "id":483,
              "name":"Malaita",
              "country_id":90
           },
           {
              "id":484,
              "name":"Western",
              "country_id":90
           },
           {
              "id":485,
              "name":"Central",
              "country_id":90
           },
           {
              "id":486,
              "name":"Guadalcanal",
              "country_id":90
           },
           {
              "id":487,
              "name":"Isabel",
              "country_id":90
           },
           {
              "id":488,
              "name":"Makira",
              "country_id":90
           },
           {
              "id":489,
              "name":"Temotu",
              "country_id":90
           },
           {
              "id":490,
              "name":"Central Province",
              "country_id":90
           },
           {
              "id":491,
              "name":"Choiseul",
              "country_id":90
           },
           {
              "id":492,
              "name":"Rennell and Bellona",
              "country_id":90
           },
           {
              "id":493,
              "name":"Rennell and Bellona",
              "country_id":90
           }
        ],
        "92":[
           {
              "id":494,
              "name":"British Virgin Islands",
              "country_id":92
           }
        ],
        "96":[
           {
              "id":495,
              "name":"Belait",
              "country_id":96
           },
           {
              "id":496,
              "name":"Brunei and Muara",
              "country_id":96
           },
           {
              "id":497,
              "name":"Temburong",
              "country_id":96
           },
           {
              "id":498,
              "name":"Tutong",
              "country_id":96
           }
        ],
        "100":[
           {
              "id":499,
              "name":"Burgas",
              "country_id":100
           },
           {
              "id":500,
              "name":"Grad",
              "country_id":100
           },
           {
              "id":501,
              "name":"Khaskovo",
              "country_id":100
           },
           {
              "id":502,
              "name":"Lovech",
              "country_id":100
           },
           {
              "id":503,
              "name":"Mikhaylovgrad",
              "country_id":100
           },
           {
              "id":504,
              "name":"Plovdiv",
              "country_id":100
           },
           {
              "id":505,
              "name":"Razgrad",
              "country_id":100
           },
           {
              "id":506,
              "name":"Sofiya",
              "country_id":100
           },
           {
              "id":507,
              "name":"Varna",
              "country_id":100
           },
           {
              "id":508,
              "name":"Blagoevgrad",
              "country_id":100
           },
           {
              "id":509,
              "name":"Burgas",
              "country_id":100
           },
           {
              "id":510,
              "name":"Dobrich",
              "country_id":100
           },
           {
              "id":511,
              "name":"Gabrovo",
              "country_id":100
           },
           {
              "id":512,
              "name":"Oblast Sofiya-Grad",
              "country_id":100
           },
           {
              "id":513,
              "name":"Khaskovo",
              "country_id":100
           },
           {
              "id":514,
              "name":"Kŭrdzhali",
              "country_id":100
           },
           {
              "id":515,
              "name":"Kyustendil",
              "country_id":100
           },
           {
              "id":516,
              "name":"Lovech",
              "country_id":100
           },
           {
              "id":517,
              "name":"Montana",
              "country_id":100
           },
           {
              "id":518,
              "name":"Pazardzhit",
              "country_id":100
           },
           {
              "id":519,
              "name":"Pernik",
              "country_id":100
           },
           {
              "id":520,
              "name":"Pleven",
              "country_id":100
           },
           {
              "id":521,
              "name":"Plovdiv",
              "country_id":100
           },
           {
              "id":522,
              "name":"Razgrad",
              "country_id":100
           },
           {
              "id":523,
              "name":"Ruse",
              "country_id":100
           },
           {
              "id":524,
              "name":"Shumen",
              "country_id":100
           },
           {
              "id":525,
              "name":"Silistra",
              "country_id":100
           },
           {
              "id":526,
              "name":"Sliven",
              "country_id":100
           },
           {
              "id":527,
              "name":"Smolyan",
              "country_id":100
           },
           {
              "id":528,
              "name":"Sofiya",
              "country_id":100
           },
           {
              "id":529,
              "name":"Stara Zagora",
              "country_id":100
           },
           {
              "id":530,
              "name":"Tŭrgovishte",
              "country_id":100
           },
           {
              "id":531,
              "name":"Varna",
              "country_id":100
           },
           {
              "id":532,
              "name":"Veliko Tŭrnovo",
              "country_id":100
           },
           {
              "id":533,
              "name":"Vidin",
              "country_id":100
           },
           {
              "id":534,
              "name":"Vratsa",
              "country_id":100
           },
           {
              "id":535,
              "name":"Yambol",
              "country_id":100
           }
        ],
        "104":[
           {
              "id":536,
              "name":"Rakhine State",
              "country_id":104
           },
           {
              "id":537,
              "name":"Chin State",
              "country_id":104
           },
           {
              "id":538,
              "name":"Ayeyarwady",
              "country_id":104
           },
           {
              "id":539,
              "name":"Kachin State",
              "country_id":104
           },
           {
              "id":540,
              "name":"Kayin State",
              "country_id":104
           },
           {
              "id":541,
              "name":"Kayah State",
              "country_id":104
           },
           {
              "id":542,
              "name":"Magwe",
              "country_id":104
           },
           {
              "id":543,
              "name":"Mandalay",
              "country_id":104
           },
           {
              "id":544,
              "name":"Pegu",
              "country_id":104
           },
           {
              "id":545,
              "name":"Sagain",
              "country_id":104
           },
           {
              "id":546,
              "name":"Shan State",
              "country_id":104
           },
           {
              "id":547,
              "name":"Tanintharyi",
              "country_id":104
           },
           {
              "id":548,
              "name":"Mon State",
              "country_id":104
           },
           {
              "id":549,
              "name":"Rangoon",
              "country_id":104
           },
           {
              "id":550,
              "name":"Magway",
              "country_id":104
           },
           {
              "id":551,
              "name":"Bago",
              "country_id":104
           },
           {
              "id":552,
              "name":"Yangon",
              "country_id":104
           }
        ],
        "108":[
           {
              "id":553,
              "name":"Bujumbura",
              "country_id":108
           },
           {
              "id":554,
              "name":"Bubanza",
              "country_id":108
           },
           {
              "id":555,
              "name":"Bururi",
              "country_id":108
           },
           {
              "id":556,
              "name":"Cankuzo",
              "country_id":108
           },
           {
              "id":557,
              "name":"Cibitoke",
              "country_id":108
           },
           {
              "id":558,
              "name":"Gitega",
              "country_id":108
           },
           {
              "id":559,
              "name":"Karuzi",
              "country_id":108
           },
           {
              "id":560,
              "name":"Kayanza",
              "country_id":108
           },
           {
              "id":561,
              "name":"Kirundo",
              "country_id":108
           },
           {
              "id":562,
              "name":"Makamba",
              "country_id":108
           },
           {
              "id":563,
              "name":"Muyinga",
              "country_id":108
           },
           {
              "id":564,
              "name":"Ngozi",
              "country_id":108
           },
           {
              "id":565,
              "name":"Rutana",
              "country_id":108
           },
           {
              "id":566,
              "name":"Ruyigi",
              "country_id":108
           },
           {
              "id":567,
              "name":"Muramvya",
              "country_id":108
           },
           {
              "id":568,
              "name":"Mwaro",
              "country_id":108
           }
        ],
        "112":[
           {
              "id":569,
              "name":"Brestskaya Voblastsʼ",
              "country_id":112
           },
           {
              "id":570,
              "name":"Homyelʼskaya Voblastsʼ",
              "country_id":112
           },
           {
              "id":571,
              "name":"Hrodzyenskaya Voblastsʼ",
              "country_id":112
           },
           {
              "id":572,
              "name":"Mahilyowskaya Voblastsʼ",
              "country_id":112
           },
           {
              "id":573,
              "name":"Horad Minsk",
              "country_id":112
           },
           {
              "id":574,
              "name":"Minskaya Voblastsʼ",
              "country_id":112
           },
           {
              "id":575,
              "name":"Vitsyebskaya Voblastsʼ",
              "country_id":112
           }
        ],
        "116":[
           {
              "id":576,
              "name":"Krŏng Preăh Seihânŭ",
              "country_id":116
           },
           {
              "id":577,
              "name":"Kâmpóng Cham",
              "country_id":116
           },
           {
              "id":578,
              "name":"Kâmpóng Chhnăng",
              "country_id":116
           },
           {
              "id":579,
              "name":"Khétt Kâmpóng Spœ",
              "country_id":116
           },
           {
              "id":580,
              "name":"Kâmpóng Thum",
              "country_id":116
           },
           {
              "id":581,
              "name":"Kândal",
              "country_id":116
           },
           {
              "id":582,
              "name":"Kaôh Kŏng",
              "country_id":116
           },
           {
              "id":583,
              "name":"Krâchéh",
              "country_id":116
           },
           {
              "id":584,
              "name":"Môndól Kiri",
              "country_id":116
           },
           {
              "id":585,
              "name":"Phnum Penh",
              "country_id":116
           },
           {
              "id":586,
              "name":"Poŭthĭsăt",
              "country_id":116
           },
           {
              "id":587,
              "name":"Preăh Vihéar",
              "country_id":116
           },
           {
              "id":588,
              "name":"Prey Vêng",
              "country_id":116
           },
           {
              "id":589,
              "name":"Stœ̆ng Trêng",
              "country_id":116
           },
           {
              "id":590,
              "name":"Svay Riĕng",
              "country_id":116
           },
           {
              "id":591,
              "name":"Takêv",
              "country_id":116
           },
           {
              "id":592,
              "name":"Kâmpôt",
              "country_id":116
           },
           {
              "id":593,
              "name":"Phnum Pénh",
              "country_id":116
           },
           {
              "id":594,
              "name":"Rôtânăh Kiri",
              "country_id":116
           },
           {
              "id":595,
              "name":"Siĕm Réab",
              "country_id":116
           },
           {
              "id":596,
              "name":"Bantéay Méan Cheăy",
              "country_id":116
           },
           {
              "id":597,
              "name":"Kêb",
              "country_id":116
           },
           {
              "id":598,
              "name":"Ŏtdâr Méan Cheăy",
              "country_id":116
           },
           {
              "id":599,
              "name":"Preăh Seihânŭ",
              "country_id":116
           },
           {
              "id":600,
              "name":"Bătdâmbâng",
              "country_id":116
           },
           {
              "id":601,
              "name":"Palĭn",
              "country_id":116
           }
        ],
        "120":[
           {
              "id":602,
              "name":"Est",
              "country_id":120
           },
           {
              "id":603,
              "name":"Littoral",
              "country_id":120
           },
           {
              "id":604,
              "name":"North-West Province",
              "country_id":120
           },
           {
              "id":605,
              "name":"Ouest",
              "country_id":120
           },
           {
              "id":606,
              "name":"South-West Province",
              "country_id":120
           },
           {
              "id":607,
              "name":"Adamaoua",
              "country_id":120
           },
           {
              "id":608,
              "name":"Centre",
              "country_id":120
           },
           {
              "id":609,
              "name":"Extreme-Nord",
              "country_id":120
           },
           {
              "id":610,
              "name":"North Province",
              "country_id":120
           },
           {
              "id":611,
              "name":"South Province",
              "country_id":120
           }
        ],
        "124":[
           {
              "id":612,
              "name":"Alberta",
              "country_id":124
           },
           {
              "id":613,
              "name":"British Columbia",
              "country_id":124
           },
           {
              "id":614,
              "name":"Manitoba",
              "country_id":124
           },
           {
              "id":615,
              "name":"New Brunswick",
              "country_id":124
           },
           {
              "id":616,
              "name":"Newfoundland and Labrador",
              "country_id":124
           },
           {
              "id":617,
              "name":"Nova Scotia",
              "country_id":124
           },
           {
              "id":618,
              "name":"Ontario",
              "country_id":124
           },
           {
              "id":619,
              "name":"Prince Edward Island",
              "country_id":124
           },
           {
              "id":620,
              "name":"Quebec",
              "country_id":124
           },
           {
              "id":621,
              "name":"Saskatchewan",
              "country_id":124
           },
           {
              "id":622,
              "name":"Yukon",
              "country_id":124
           },
           {
              "id":623,
              "name":"Northwest Territories",
              "country_id":124
           },
           {
              "id":624,
              "name":"Nunavut",
              "country_id":124
           }
        ],
        "132":[
           {
              "id":625,
              "name":"Boa Vista",
              "country_id":132
           },
           {
              "id":626,
              "name":"Brava",
              "country_id":132
           },
           {
              "id":627,
              "name":"Maio",
              "country_id":132
           },
           {
              "id":628,
              "name":"Paul",
              "country_id":132
           },
           {
              "id":629,
              "name":"Praia",
              "country_id":132
           },
           {
              "id":630,
              "name":"Ribeira Grande",
              "country_id":132
           },
           {
              "id":631,
              "name":"Sal",
              "country_id":132
           },
           {
              "id":632,
              "name":"Santa Catarina   ",
              "country_id":132
           },
           {
              "id":633,
              "name":"São Nicolau",
              "country_id":132
           },
           {
              "id":634,
              "name":"São Vicente",
              "country_id":132
           },
           {
              "id":635,
              "name":"Tarrafal ",
              "country_id":132
           },
           {
              "id":636,
              "name":"Mosteiros",
              "country_id":132
           },
           {
              "id":637,
              "name":"Praia",
              "country_id":132
           },
           {
              "id":638,
              "name":"Santa Catarina",
              "country_id":132
           },
           {
              "id":639,
              "name":"Santa Cruz",
              "country_id":132
           },
           {
              "id":640,
              "name":"São Domingos",
              "country_id":132
           },
           {
              "id":641,
              "name":"São Filipe",
              "country_id":132
           },
           {
              "id":642,
              "name":"São Miguel",
              "country_id":132
           },
           {
              "id":643,
              "name":"Tarrafal",
              "country_id":132
           }
        ],
        "136":[
           {
              "id":644,
              "name":"Creek",
              "country_id":136
           },
           {
              "id":645,
              "name":"Eastern",
              "country_id":136
           },
           {
              "id":646,
              "name":"Midland",
              "country_id":136
           },
           {
              "id":647,
              "name":"South Town",
              "country_id":136
           },
           {
              "id":648,
              "name":"Spot Bay",
              "country_id":136
           },
           {
              "id":649,
              "name":"Stake Bay",
              "country_id":136
           },
           {
              "id":650,
              "name":"West End",
              "country_id":136
           },
           {
              "id":651,
              "name":"Western",
              "country_id":136
           }
        ],
        "140":[
           {
              "id":652,
              "name":"Bamingui-Bangoran",
              "country_id":140
           },
           {
              "id":653,
              "name":"Basse-Kotto",
              "country_id":140
           },
           {
              "id":654,
              "name":"Haute-Kotto",
              "country_id":140
           },
           {
              "id":655,
              "name":"Mambéré-Kadéï",
              "country_id":140
           },
           {
              "id":656,
              "name":"Haut-Mbomou",
              "country_id":140
           },
           {
              "id":657,
              "name":"Kémo",
              "country_id":140
           },
           {
              "id":658,
              "name":"Lobaye",
              "country_id":140
           },
           {
              "id":659,
              "name":"Mbomou",
              "country_id":140
           },
           {
              "id":660,
              "name":"Nana-Mambéré",
              "country_id":140
           },
           {
              "id":661,
              "name":"Ouaka",
              "country_id":140
           },
           {
              "id":662,
              "name":"Ouham",
              "country_id":140
           },
           {
              "id":663,
              "name":"Ouham-Pendé",
              "country_id":140
           },
           {
              "id":664,
              "name":"Vakaga",
              "country_id":140
           },
           {
              "id":665,
              "name":"Nana-Grébizi",
              "country_id":140
           },
           {
              "id":666,
              "name":"Sangha-Mbaéré",
              "country_id":140
           },
           {
              "id":667,
              "name":"Ombella-Mpoko",
              "country_id":140
           },
           {
              "id":668,
              "name":"Bangui",
              "country_id":140
           }
        ],
        "144":[
           {
              "id":669,
              "name":"Central",
              "country_id":144
           },
           {
              "id":670,
              "name":"North Central",
              "country_id":144
           },
           {
              "id":671,
              "name":"North Eastern",
              "country_id":144
           },
           {
              "id":672,
              "name":"North Western",
              "country_id":144
           },
           {
              "id":673,
              "name":"Sabaragamuwa",
              "country_id":144
           },
           {
              "id":674,
              "name":"Southern",
              "country_id":144
           },
           {
              "id":675,
              "name":"Uva",
              "country_id":144
           },
           {
              "id":676,
              "name":"Western",
              "country_id":144
           }
        ],
        "148":[
           {
              "id":677,
              "name":"Batha",
              "country_id":148
           },
           {
              "id":678,
              "name":"Biltine",
              "country_id":148
           },
           {
              "id":679,
              "name":"Borkou-Ennedi-Tibesti",
              "country_id":148
           },
           {
              "id":680,
              "name":"Chari-Baguirmi",
              "country_id":148
           },
           {
              "id":681,
              "name":"Guéra",
              "country_id":148
           },
           {
              "id":682,
              "name":"Kanem",
              "country_id":148
           },
           {
              "id":683,
              "name":"Lac",
              "country_id":148
           },
           {
              "id":684,
              "name":"Logone Occidental",
              "country_id":148
           },
           {
              "id":685,
              "name":"Logone Oriental",
              "country_id":148
           },
           {
              "id":686,
              "name":"Mayo-Kébbi",
              "country_id":148
           },
           {
              "id":687,
              "name":"Moyen-Chari",
              "country_id":148
           },
           {
              "id":688,
              "name":"Ouaddaï",
              "country_id":148
           },
           {
              "id":689,
              "name":"Salamat",
              "country_id":148
           },
           {
              "id":690,
              "name":"Tandjilé",
              "country_id":148
           }
        ],
        "152":[
           {
              "id":691,
              "name":"Valparaíso",
              "country_id":152
           },
           {
              "id":692,
              "name":"Aisén del General Carlos Ibáñez del Campo",
              "country_id":152
           },
           {
              "id":693,
              "name":"Antofagasta",
              "country_id":152
           },
           {
              "id":694,
              "name":"Araucanía",
              "country_id":152
           },
           {
              "id":695,
              "name":"Atacama",
              "country_id":152
           },
           {
              "id":696,
              "name":"Bío-Bío",
              "country_id":152
           },
           {
              "id":697,
              "name":"Coquimbo",
              "country_id":152
           },
           {
              "id":698,
              "name":"Libertador General Bernardo OʼHiggins",
              "country_id":152
           },
           {
              "id":699,
              "name":"Los Lagos",
              "country_id":152
           },
           {
              "id":700,
              "name":"Magallanes y Antártica Chilena",
              "country_id":152
           },
           {
              "id":701,
              "name":"Maule",
              "country_id":152
           },
           {
              "id":702,
              "name":"Región Metropolitana",
              "country_id":152
           },
           {
              "id":703,
              "name":"Tarapaca",
              "country_id":152
           },
           {
              "id":704,
              "name":"Los Lagos",
              "country_id":152
           },
           {
              "id":705,
              "name":"Tarapacá",
              "country_id":152
           },
           {
              "id":706,
              "name":"Región de Arica y Parinacota",
              "country_id":152
           },
           {
              "id":707,
              "name":"Región de Los Ríos",
              "country_id":152
           }
        ],
        "156":[
           {
              "id":708,
              "name":"Anhui",
              "country_id":156
           },
           {
              "id":709,
              "name":"Zhejiang",
              "country_id":156
           },
           {
              "id":710,
              "name":"Jiangxi",
              "country_id":156
           },
           {
              "id":711,
              "name":"Jiangsu",
              "country_id":156
           },
           {
              "id":712,
              "name":"Jilin",
              "country_id":156
           },
           {
              "id":713,
              "name":"Qinghai",
              "country_id":156
           },
           {
              "id":714,
              "name":"Fujian",
              "country_id":156
           },
           {
              "id":715,
              "name":"Heilongjiang",
              "country_id":156
           },
           {
              "id":716,
              "name":"Henan",
              "country_id":156
           },
           {
              "id":717,
              "name":"disputed",
              "country_id":156
           },
           {
              "id":718,
              "name":"Hebei",
              "country_id":156
           },
           {
              "id":719,
              "name":"Hunan Province",
              "country_id":156
           },
           {
              "id":720,
              "name":"Hubei",
              "country_id":156
           },
           {
              "id":721,
              "name":"Xinjiang",
              "country_id":156
           },
           {
              "id":722,
              "name":"Xizang",
              "country_id":156
           },
           {
              "id":723,
              "name":"Gansu",
              "country_id":156
           },
           {
              "id":724,
              "name":"Guangxi",
              "country_id":156
           },
           {
              "id":725,
              "name":"Guizhou",
              "country_id":156
           },
           {
              "id":726,
              "name":"Liaoning Province",
              "country_id":156
           },
           {
              "id":727,
              "name":"Inner Mongolia",
              "country_id":156
           },
           {
              "id":728,
              "name":"Ningxia",
              "country_id":156
           },
           {
              "id":729,
              "name":"Beijing",
              "country_id":156
           },
           {
              "id":730,
              "name":"Shanghai",
              "country_id":156
           },
           {
              "id":731,
              "name":"Shanxi",
              "country_id":156
           },
           {
              "id":732,
              "name":"Shandong",
              "country_id":156
           },
           {
              "id":733,
              "name":"Shaanxi",
              "country_id":156
           },
           {
              "id":734,
              "name":"Tianjin",
              "country_id":156
           },
           {
              "id":735,
              "name":"Yunnan Province",
              "country_id":156
           },
           {
              "id":736,
              "name":"Guangdong",
              "country_id":156
           },
           {
              "id":737,
              "name":"Hainan Province",
              "country_id":156
           },
           {
              "id":738,
              "name":"Sichuan",
              "country_id":156
           },
           {
              "id":739,
              "name":"Chongqing",
              "country_id":156
           },
           {
              "id":740,
              "name":"PF99",
              "country_id":156
           }
        ],
        "158":[
           {
              "id":741,
              "name":"Fukien",
              "country_id":158
           },
           {
              "id":742,
              "name":"Kaohsiung",
              "country_id":158
           },
           {
              "id":743,
              "name":"Taipei",
              "country_id":158
           },
           {
              "id":744,
              "name":"Taiwan",
              "country_id":158
           }
        ],
        "162":[
           {
              "id":745,
              "name":"Christmas Island",
              "country_id":162
           }
        ],
        "166":[
           {
              "id":746,
              "name":"Cocos (Keeling) Islands",
              "country_id":166
           }
        ],
        "170":[
           {
              "id":747,
              "name":"Amazonas",
              "country_id":170
           },
           {
              "id":748,
              "name":"Antioquia",
              "country_id":170
           },
           {
              "id":749,
              "name":"Arauca",
              "country_id":170
           },
           {
              "id":750,
              "name":"Atlántico",
              "country_id":170
           },
           {
              "id":751,
              "name":"Bolívar",
              "country_id":170
           },
           {
              "id":752,
              "name":"Boyacá",
              "country_id":170
           },
           {
              "id":753,
              "name":"Caldas",
              "country_id":170
           },
           {
              "id":754,
              "name":"Caquetá",
              "country_id":170
           },
           {
              "id":755,
              "name":"Cauca",
              "country_id":170
           },
           {
              "id":756,
              "name":"Cesar",
              "country_id":170
           },
           {
              "id":757,
              "name":"Chocó",
              "country_id":170
           },
           {
              "id":758,
              "name":"Córdoba",
              "country_id":170
           },
           {
              "id":759,
              "name":"Guaviare",
              "country_id":170
           },
           {
              "id":760,
              "name":"Guainía",
              "country_id":170
           },
           {
              "id":761,
              "name":"Huila",
              "country_id":170
           },
           {
              "id":762,
              "name":"La Guajira",
              "country_id":170
           },
           {
              "id":763,
              "name":"Magdalena",
              "country_id":170
           },
           {
              "id":764,
              "name":"Meta",
              "country_id":170
           },
           {
              "id":765,
              "name":"Nariño",
              "country_id":170
           },
           {
              "id":766,
              "name":"Norte de Santander",
              "country_id":170
           },
           {
              "id":767,
              "name":"Putumayo",
              "country_id":170
           },
           {
              "id":768,
              "name":"Quindío",
              "country_id":170
           },
           {
              "id":769,
              "name":"Risaralda",
              "country_id":170
           },
           {
              "id":770,
              "name":"Archipiélago de San Andrés, Providencia y San",
              "country_id":170
           },
           {
              "id":771,
              "name":"Santander",
              "country_id":170
           },
           {
              "id":772,
              "name":"Sucre",
              "country_id":170
           },
           {
              "id":773,
              "name":"Tolima",
              "country_id":170
           },
           {
              "id":774,
              "name":"Valle del Cauca",
              "country_id":170
           },
           {
              "id":775,
              "name":"Vaupés",
              "country_id":170
           },
           {
              "id":776,
              "name":"Vichada",
              "country_id":170
           },
           {
              "id":777,
              "name":"Casanare",
              "country_id":170
           },
           {
              "id":778,
              "name":"Cundinamarca",
              "country_id":170
           },
           {
              "id":779,
              "name":"Bogota D.C.",
              "country_id":170
           },
           {
              "id":780,
              "name":"Bolívar",
              "country_id":170
           },
           {
              "id":781,
              "name":"Boyacá",
              "country_id":170
           },
           {
              "id":782,
              "name":"Caldas",
              "country_id":170
           },
           {
              "id":783,
              "name":"Magdalena",
              "country_id":170
           }
        ],
        "174":[
           {
              "id":784,
              "name":"Anjouan",
              "country_id":174
           },
           {
              "id":785,
              "name":"Grande Comore",
              "country_id":174
           },
           {
              "id":786,
              "name":"Mohéli",
              "country_id":174
           }
        ],
        "175":[
           {
              "id":787,
              "name":"Mayotte",
              "country_id":175
           }
        ],
        "178":[
           {
              "id":788,
              "name":"Bouenza",
              "country_id":178
           },
           {
              "id":789,
              "name":"CF03",
              "country_id":178
           },
           {
              "id":790,
              "name":"Kouilou",
              "country_id":178
           },
           {
              "id":791,
              "name":"Lékoumou",
              "country_id":178
           },
           {
              "id":792,
              "name":"Likouala",
              "country_id":178
           },
           {
              "id":793,
              "name":"Niari",
              "country_id":178
           },
           {
              "id":794,
              "name":"Plateaux",
              "country_id":178
           },
           {
              "id":795,
              "name":"Sangha",
              "country_id":178
           },
           {
              "id":796,
              "name":"Pool",
              "country_id":178
           },
           {
              "id":797,
              "name":"Brazzaville",
              "country_id":178
           },
           {
              "id":798,
              "name":"Cuvette",
              "country_id":178
           },
           {
              "id":799,
              "name":"Cuvette-Ouest",
              "country_id":178
           },
           {
              "id":800,
              "name":"Pointe-Noire",
              "country_id":178
           }
        ],
        "180":[
           {
              "id":801,
              "name":"Bandundu",
              "country_id":180
           },
           {
              "id":802,
              "name":"Équateur",
              "country_id":180
           },
           {
              "id":803,
              "name":"Kasaï-Occidental",
              "country_id":180
           },
           {
              "id":804,
              "name":"Kasaï-Oriental",
              "country_id":180
           },
           {
              "id":805,
              "name":"Katanga",
              "country_id":180
           },
           {
              "id":806,
              "name":"Kinshasa",
              "country_id":180
           },
           {
              "id":807,
              "name":"Bas-Congo",
              "country_id":180
           },
           {
              "id":808,
              "name":"Orientale",
              "country_id":180
           },
           {
              "id":809,
              "name":"Maniema",
              "country_id":180
           },
           {
              "id":810,
              "name":"Nord-Kivu",
              "country_id":180
           },
           {
              "id":811,
              "name":"Sud-Kivu",
              "country_id":180
           }
        ],
        "184":[
           {
              "id":812,
              "name":"Cook Islands",
              "country_id":184
           }
        ],
        "188":[
           {
              "id":813,
              "name":"Alajuela",
              "country_id":188
           },
           {
              "id":814,
              "name":"Cartago",
              "country_id":188
           },
           {
              "id":815,
              "name":"Guanacaste",
              "country_id":188
           },
           {
              "id":816,
              "name":"Heredia",
              "country_id":188
           },
           {
              "id":817,
              "name":"Limón",
              "country_id":188
           },
           {
              "id":818,
              "name":"Puntarenas",
              "country_id":188
           },
           {
              "id":819,
              "name":"San José",
              "country_id":188
           }
        ],
        "191":[
           {
              "id":820,
              "name":"Bjelovarsko-Bilogorska",
              "country_id":191
           },
           {
              "id":821,
              "name":"Brodsko-Posavska",
              "country_id":191
           },
           {
              "id":822,
              "name":"Dubrovačko-Neretvanska",
              "country_id":191
           },
           {
              "id":823,
              "name":"Istarska",
              "country_id":191
           },
           {
              "id":824,
              "name":"Karlovačka",
              "country_id":191
           },
           {
              "id":825,
              "name":"Koprivničko-Križevačka",
              "country_id":191
           },
           {
              "id":826,
              "name":"Krapinsko-Zagorska",
              "country_id":191
           },
           {
              "id":827,
              "name":"Ličko-Senjska",
              "country_id":191
           },
           {
              "id":828,
              "name":"Međimurska",
              "country_id":191
           },
           {
              "id":829,
              "name":"Osječko-Baranjska",
              "country_id":191
           },
           {
              "id":830,
              "name":"Požeško-Slavonska",
              "country_id":191
           },
           {
              "id":831,
              "name":"Primorsko-Goranska",
              "country_id":191
           },
           {
              "id":832,
              "name":"Šibensko-Kniniska",
              "country_id":191
           },
           {
              "id":833,
              "name":"Sisačko-Moslavačka",
              "country_id":191
           },
           {
              "id":834,
              "name":"Splitsko-Dalmatinska",
              "country_id":191
           },
           {
              "id":835,
              "name":"Varaždinska",
              "country_id":191
           },
           {
              "id":836,
              "name":"Virovitičk-Podravska",
              "country_id":191
           },
           {
              "id":837,
              "name":"Vukovarsko-Srijemska",
              "country_id":191
           },
           {
              "id":838,
              "name":"Zadarska",
              "country_id":191
           },
           {
              "id":839,
              "name":"Zagrebačka",
              "country_id":191
           },
           {
              "id":840,
              "name":"Grad Zagreb",
              "country_id":191
           }
        ],
        "192":[
           {
              "id":841,
              "name":"Pinar del Río",
              "country_id":192
           },
           {
              "id":842,
              "name":"Ciudad de La Habana",
              "country_id":192
           },
           {
              "id":843,
              "name":"Matanzas",
              "country_id":192
           },
           {
              "id":844,
              "name":"Isla de la Juventud",
              "country_id":192
           },
           {
              "id":845,
              "name":"Camagüey",
              "country_id":192
           },
           {
              "id":846,
              "name":"Ciego de Ávila",
              "country_id":192
           },
           {
              "id":847,
              "name":"Cienfuegos",
              "country_id":192
           },
           {
              "id":848,
              "name":"Granma",
              "country_id":192
           },
           {
              "id":849,
              "name":"Guantánamo",
              "country_id":192
           },
           {
              "id":850,
              "name":"La Habana",
              "country_id":192
           },
           {
              "id":851,
              "name":"Holguín",
              "country_id":192
           },
           {
              "id":852,
              "name":"Las Tunas",
              "country_id":192
           },
           {
              "id":853,
              "name":"Sancti Spíritus",
              "country_id":192
           },
           {
              "id":854,
              "name":"Santiago de Cuba",
              "country_id":192
           },
           {
              "id":855,
              "name":"Villa Clara",
              "country_id":192
           }
        ],
        "196":[
           {
              "id":856,
              "name":"Famagusta",
              "country_id":196
           },
           {
              "id":857,
              "name":"Kyrenia",
              "country_id":196
           },
           {
              "id":858,
              "name":"Larnaca",
              "country_id":196
           },
           {
              "id":859,
              "name":"Nicosia",
              "country_id":196
           },
           {
              "id":860,
              "name":"Limassol",
              "country_id":196
           },
           {
              "id":861,
              "name":"Paphos",
              "country_id":196
           }
        ],
        "203":[
           {
              "id":862,
              "name":"Hradec Kralove",
              "country_id":203
           },
           {
              "id":863,
              "name":"Jablonec nad Nisou",
              "country_id":203
           },
           {
              "id":864,
              "name":"Jiein",
              "country_id":203
           },
           {
              "id":865,
              "name":"Jihlava",
              "country_id":203
           },
           {
              "id":866,
              "name":"Kolin",
              "country_id":203
           },
           {
              "id":867,
              "name":"Liberec",
              "country_id":203
           },
           {
              "id":868,
              "name":"Melnik",
              "country_id":203
           },
           {
              "id":869,
              "name":"Mlada Boleslav",
              "country_id":203
           },
           {
              "id":870,
              "name":"Nachod",
              "country_id":203
           },
           {
              "id":871,
              "name":"Nymburk",
              "country_id":203
           },
           {
              "id":872,
              "name":"Pardubice",
              "country_id":203
           },
           {
              "id":873,
              "name":"Hlavní Mesto Praha",
              "country_id":203
           },
           {
              "id":874,
              "name":"Semily",
              "country_id":203
           },
           {
              "id":875,
              "name":"Trutnov",
              "country_id":203
           },
           {
              "id":876,
              "name":"South Moravian Region",
              "country_id":203
           },
           {
              "id":877,
              "name":"Jihočeský Kraj",
              "country_id":203
           },
           {
              "id":878,
              "name":"Vysočina",
              "country_id":203
           },
           {
              "id":879,
              "name":"Karlovarský Kraj",
              "country_id":203
           },
           {
              "id":880,
              "name":"Královéhradecký Kraj",
              "country_id":203
           },
           {
              "id":881,
              "name":"Liberecký Kraj",
              "country_id":203
           },
           {
              "id":882,
              "name":"Olomoucký Kraj",
              "country_id":203
           },
           {
              "id":883,
              "name":"Moravskoslezský Kraj",
              "country_id":203
           },
           {
              "id":884,
              "name":"Pardubický Kraj",
              "country_id":203
           },
           {
              "id":885,
              "name":"Plzeňský Kraj",
              "country_id":203
           },
           {
              "id":886,
              "name":"Středočeský Kraj",
              "country_id":203
           },
           {
              "id":887,
              "name":"Ústecký Kraj",
              "country_id":203
           },
           {
              "id":888,
              "name":"Zlínský Kraj",
              "country_id":203
           }
        ],
        "204":[
           {
              "id":889,
              "name":"Atakora",
              "country_id":204
           },
           {
              "id":890,
              "name":"Atlantique",
              "country_id":204
           },
           {
              "id":891,
              "name":"Alibori",
              "country_id":204
           },
           {
              "id":892,
              "name":"Borgou",
              "country_id":204
           },
           {
              "id":893,
              "name":"Collines",
              "country_id":204
           },
           {
              "id":894,
              "name":"Kouffo",
              "country_id":204
           },
           {
              "id":895,
              "name":"Donga",
              "country_id":204
           },
           {
              "id":896,
              "name":"Littoral",
              "country_id":204
           },
           {
              "id":897,
              "name":"Mono",
              "country_id":204
           },
           {
              "id":898,
              "name":"Ouémé",
              "country_id":204
           },
           {
              "id":899,
              "name":"Plateau",
              "country_id":204
           },
           {
              "id":900,
              "name":"Zou",
              "country_id":204
           }
        ],
        "208":[
           {
              "id":901,
              "name":"Århus",
              "country_id":208
           },
           {
              "id":902,
              "name":"Bornholm",
              "country_id":208
           },
           {
              "id":903,
              "name":"Frederiksborg",
              "country_id":208
           },
           {
              "id":904,
              "name":"Fyn",
              "country_id":208
           },
           {
              "id":905,
              "name":"Copenhagen city",
              "country_id":208
           },
           {
              "id":906,
              "name":"København",
              "country_id":208
           },
           {
              "id":907,
              "name":"Nordjylland",
              "country_id":208
           },
           {
              "id":908,
              "name":"Ribe",
              "country_id":208
           },
           {
              "id":909,
              "name":"Ringkøbing",
              "country_id":208
           },
           {
              "id":910,
              "name":"Roskilde",
              "country_id":208
           },
           {
              "id":911,
              "name":"Sønderjylland",
              "country_id":208
           },
           {
              "id":912,
              "name":"Storstrøm",
              "country_id":208
           },
           {
              "id":913,
              "name":"Vejle",
              "country_id":208
           },
           {
              "id":914,
              "name":"Vestsjælland",
              "country_id":208
           },
           {
              "id":915,
              "name":"Viborg",
              "country_id":208
           },
           {
              "id":916,
              "name":"Fredriksberg",
              "country_id":208
           },
           {
              "id":917,
              "name":"Capital Region",
              "country_id":208
           },
           {
              "id":918,
              "name":"Central Jutland",
              "country_id":208
           },
           {
              "id":919,
              "name":"North Jutland",
              "country_id":208
           },
           {
              "id":920,
              "name":"Region Zealand",
              "country_id":208
           },
           {
              "id":921,
              "name":"Region South Denmark",
              "country_id":208
           }
        ],
        "212":[
           {
              "id":922,
              "name":"Saint Andrew",
              "country_id":212
           },
           {
              "id":923,
              "name":"Saint David",
              "country_id":212
           },
           {
              "id":924,
              "name":"Saint George",
              "country_id":212
           },
           {
              "id":925,
              "name":"Saint John",
              "country_id":212
           },
           {
              "id":926,
              "name":"Saint Joseph",
              "country_id":212
           },
           {
              "id":927,
              "name":"Saint Luke",
              "country_id":212
           },
           {
              "id":928,
              "name":"Saint Mark",
              "country_id":212
           },
           {
              "id":929,
              "name":"Saint Patrick",
              "country_id":212
           },
           {
              "id":930,
              "name":"Saint Paul",
              "country_id":212
           },
           {
              "id":931,
              "name":"Saint Peter",
              "country_id":212
           }
        ],
        "214":[
           {
              "id":932,
              "name":"Azua",
              "country_id":214
           },
           {
              "id":933,
              "name":"Baoruco",
              "country_id":214
           },
           {
              "id":934,
              "name":"Barahona",
              "country_id":214
           },
           {
              "id":935,
              "name":"Dajabón",
              "country_id":214
           },
           {
              "id":936,
              "name":"Duarte",
              "country_id":214
           },
           {
              "id":937,
              "name":"Espaillat",
              "country_id":214
           },
           {
              "id":938,
              "name":"Independencia",
              "country_id":214
           },
           {
              "id":939,
              "name":"La Altagracia",
              "country_id":214
           },
           {
              "id":940,
              "name":"Elías Piña",
              "country_id":214
           },
           {
              "id":941,
              "name":"La Romana",
              "country_id":214
           },
           {
              "id":942,
              "name":"María Trinidad Sánchez",
              "country_id":214
           },
           {
              "id":943,
              "name":"Monte Cristi",
              "country_id":214
           },
           {
              "id":944,
              "name":"Pedernales",
              "country_id":214
           },
           {
              "id":945,
              "name":"Puerto Plata",
              "country_id":214
           },
           {
              "id":946,
              "name":"Salcedo",
              "country_id":214
           },
           {
              "id":947,
              "name":"Samaná",
              "country_id":214
           },
           {
              "id":948,
              "name":"Sánchez Ramírez",
              "country_id":214
           },
           {
              "id":949,
              "name":"San Juan",
              "country_id":214
           },
           {
              "id":950,
              "name":"San Pedro de Macorís",
              "country_id":214
           },
           {
              "id":951,
              "name":"Santiago",
              "country_id":214
           },
           {
              "id":952,
              "name":"Santiago Rodríguez",
              "country_id":214
           },
           {
              "id":953,
              "name":"Valverde",
              "country_id":214
           },
           {
              "id":954,
              "name":"El Seíbo",
              "country_id":214
           },
           {
              "id":955,
              "name":"Hato Mayor",
              "country_id":214
           },
           {
              "id":956,
              "name":"La Vega",
              "country_id":214
           },
           {
              "id":957,
              "name":"Monseñor Nouel",
              "country_id":214
           },
           {
              "id":958,
              "name":"Monte Plata",
              "country_id":214
           },
           {
              "id":959,
              "name":"San Cristóbal",
              "country_id":214
           },
           {
              "id":960,
              "name":"Distrito Nacional",
              "country_id":214
           },
           {
              "id":961,
              "name":"Peravia",
              "country_id":214
           },
           {
              "id":962,
              "name":"San José de Ocoa",
              "country_id":214
           },
           {
              "id":963,
              "name":"Santo Domingo",
              "country_id":214
           }
        ],
        "218":[
           {
              "id":964,
              "name":"Galápagos",
              "country_id":218
           },
           {
              "id":965,
              "name":"Azuay",
              "country_id":218
           },
           {
              "id":966,
              "name":"Bolívar",
              "country_id":218
           },
           {
              "id":967,
              "name":"Cañar",
              "country_id":218
           },
           {
              "id":968,
              "name":"Carchi",
              "country_id":218
           },
           {
              "id":969,
              "name":"Chimborazo",
              "country_id":218
           },
           {
              "id":970,
              "name":"Cotopaxi",
              "country_id":218
           },
           {
              "id":971,
              "name":"El Oro",
              "country_id":218
           },
           {
              "id":972,
              "name":"Esmeraldas",
              "country_id":218
           },
           {
              "id":973,
              "name":"Guayas",
              "country_id":218
           },
           {
              "id":974,
              "name":"Imbabura",
              "country_id":218
           },
           {
              "id":975,
              "name":"Loja",
              "country_id":218
           },
           {
              "id":976,
              "name":"Los Ríos",
              "country_id":218
           },
           {
              "id":977,
              "name":"Manabí",
              "country_id":218
           },
           {
              "id":978,
              "name":"Morona-Santiago",
              "country_id":218
           },
           {
              "id":979,
              "name":"Napo",
              "country_id":218
           },
           {
              "id":980,
              "name":"Pastaza",
              "country_id":218
           },
           {
              "id":981,
              "name":"Pichincha",
              "country_id":218
           },
           {
              "id":982,
              "name":"Tungurahua",
              "country_id":218
           },
           {
              "id":983,
              "name":"Zamora-Chinchipe",
              "country_id":218
           },
           {
              "id":984,
              "name":"Sucumbios",
              "country_id":218
           },
           {
              "id":985,
              "name":"Napo",
              "country_id":218
           },
           {
              "id":986,
              "name":"Orellana",
              "country_id":218
           },
           {
              "id":987,
              "name":"Provincia de Santa Elena",
              "country_id":218
           },
           {
              "id":988,
              "name":"Provincia de Santo Domingo de los Tsáchilas",
              "country_id":218
           }
        ],
        "222":[
           {
              "id":989,
              "name":"Ahuachapán",
              "country_id":222
           },
           {
              "id":990,
              "name":"Cabañas",
              "country_id":222
           },
           {
              "id":991,
              "name":"Chalatenango",
              "country_id":222
           },
           {
              "id":992,
              "name":"Cuscatlán",
              "country_id":222
           },
           {
              "id":993,
              "name":"La Libertad",
              "country_id":222
           },
           {
              "id":994,
              "name":"La Paz",
              "country_id":222
           },
           {
              "id":995,
              "name":"La Unión",
              "country_id":222
           },
           {
              "id":996,
              "name":"Morazán",
              "country_id":222
           },
           {
              "id":997,
              "name":"San Miguel",
              "country_id":222
           },
           {
              "id":998,
              "name":"San Salvador",
              "country_id":222
           },
           {
              "id":999,
              "name":"Santa Ana",
              "country_id":222
           },
           {
              "id":1000,
              "name":"San Vicente",
              "country_id":222
           },
           {
              "id":1001,
              "name":"Sonsonate",
              "country_id":222
           },
           {
              "id":1002,
              "name":"Usulután",
              "country_id":222
           }
        ],
        "226":[
           {
              "id":1003,
              "name":"Annobón",
              "country_id":226
           },
           {
              "id":1004,
              "name":"Bioko Norte",
              "country_id":226
           },
           {
              "id":1005,
              "name":"Bioko Sur",
              "country_id":226
           },
           {
              "id":1006,
              "name":"Centro Sur",
              "country_id":226
           },
           {
              "id":1007,
              "name":"Kié-Ntem",
              "country_id":226
           },
           {
              "id":1008,
              "name":"Litoral",
              "country_id":226
           },
           {
              "id":1009,
              "name":"Wele-Nzas",
              "country_id":226
           }
        ],
        "231":[
           {
              "id":1010,
              "name":"Arsi",
              "country_id":231
           },
           {
              "id":1011,
              "name":"Gonder",
              "country_id":231
           },
           {
              "id":1012,
              "name":"Bale",
              "country_id":231
           },
           {
              "id":1013,
              "name":"Eritrea",
              "country_id":231
           },
           {
              "id":1014,
              "name":"Gamo Gofa",
              "country_id":231
           },
           {
              "id":1015,
              "name":"Gojam",
              "country_id":231
           },
           {
              "id":1016,
              "name":"Harerge",
              "country_id":231
           },
           {
              "id":1017,
              "name":"Ilubabor",
              "country_id":231
           },
           {
              "id":1018,
              "name":"Kefa",
              "country_id":231
           },
           {
              "id":1019,
              "name":"Addis Abeba",
              "country_id":231
           },
           {
              "id":1020,
              "name":"Sidamo",
              "country_id":231
           },
           {
              "id":1021,
              "name":"Tigray",
              "country_id":231
           },
           {
              "id":1022,
              "name":"Welega Kifle Hager",
              "country_id":231
           },
           {
              "id":1023,
              "name":"Welo",
              "country_id":231
           },
           {
              "id":1024,
              "name":"Adis Abeba",
              "country_id":231
           },
           {
              "id":1025,
              "name":"Asosa",
              "country_id":231
           },
           {
              "id":1026,
              "name":"Borena ",
              "country_id":231
           },
           {
              "id":1027,
              "name":"Debub Gonder",
              "country_id":231
           },
           {
              "id":1028,
              "name":"Debub Shewa",
              "country_id":231
           },
           {
              "id":1029,
              "name":"Debub Welo",
              "country_id":231
           },
           {
              "id":1030,
              "name":"Dire Dawa",
              "country_id":231
           },
           {
              "id":1031,
              "name":"Gambela",
              "country_id":231
           },
           {
              "id":1032,
              "name":"Metekel",
              "country_id":231
           },
           {
              "id":1033,
              "name":"Mirab Gojam",
              "country_id":231
           },
           {
              "id":1034,
              "name":"Mirab Harerge",
              "country_id":231
           },
           {
              "id":1035,
              "name":"Mirab Shewa",
              "country_id":231
           },
           {
              "id":1036,
              "name":"Misrak Gojam",
              "country_id":231
           },
           {
              "id":1037,
              "name":"Misrak Harerge",
              "country_id":231
           },
           {
              "id":1038,
              "name":"Nazret",
              "country_id":231
           },
           {
              "id":1039,
              "name":"Ogaden",
              "country_id":231
           },
           {
              "id":1040,
              "name":"Omo",
              "country_id":231
           },
           {
              "id":1041,
              "name":"Semen Gonder",
              "country_id":231
           },
           {
              "id":1042,
              "name":"Semen Shewa",
              "country_id":231
           },
           {
              "id":1043,
              "name":"Semen Welo",
              "country_id":231
           },
           {
              "id":1044,
              "name":"Tigray",
              "country_id":231
           },
           {
              "id":1045,
              "name":"Bale",
              "country_id":231
           },
           {
              "id":1046,
              "name":"Gamo Gofa",
              "country_id":231
           },
           {
              "id":1047,
              "name":"Ilubabor",
              "country_id":231
           },
           {
              "id":1048,
              "name":"Kefa",
              "country_id":231
           },
           {
              "id":1049,
              "name":"Sidamo",
              "country_id":231
           },
           {
              "id":1050,
              "name":"Welega",
              "country_id":231
           },
           {
              "id":1051,
              "name":"Ādīs Ābeba",
              "country_id":231
           },
           {
              "id":1052,
              "name":"Āfar",
              "country_id":231
           },
           {
              "id":1053,
              "name":"Āmara",
              "country_id":231
           },
           {
              "id":1054,
              "name":"Bīnshangul Gumuz",
              "country_id":231
           },
           {
              "id":1055,
              "name":"Dirē Dawa",
              "country_id":231
           },
           {
              "id":1056,
              "name":"Gambēla Hizboch",
              "country_id":231
           },
           {
              "id":1057,
              "name":"Hārerī Hizb",
              "country_id":231
           },
           {
              "id":1058,
              "name":"Oromīya",
              "country_id":231
           },
           {
              "id":1059,
              "name":"Sumalē",
              "country_id":231
           },
           {
              "id":1060,
              "name":"Tigray",
              "country_id":231
           },
           {
              "id":1061,
              "name":"YeDebub Bihēroch Bihēreseboch na Hizboch",
              "country_id":231
           }
        ],
        "232":[
           {
              "id":1062,
              "name":"Ānseba",
              "country_id":232
           },
           {
              "id":1063,
              "name":"Debub",
              "country_id":232
           },
           {
              "id":1064,
              "name":"Debubawī Kʼeyih Bahrī",
              "country_id":232
           },
           {
              "id":1065,
              "name":"Gash Barka",
              "country_id":232
           },
           {
              "id":1066,
              "name":"Maʼākel",
              "country_id":232
           },
           {
              "id":1067,
              "name":"Semēnawī Kʼeyih Bahrī",
              "country_id":232
           }
        ],
        "233":[
           {
              "id":1068,
              "name":"Harjumaa",
              "country_id":233
           },
           {
              "id":1069,
              "name":"Hiiumaa",
              "country_id":233
           },
           {
              "id":1070,
              "name":"Ida-Virumaa",
              "country_id":233
           },
           {
              "id":1071,
              "name":"Järvamaa",
              "country_id":233
           },
           {
              "id":1072,
              "name":"Jõgevamaa",
              "country_id":233
           },
           {
              "id":1073,
              "name":"Läänemaa",
              "country_id":233
           },
           {
              "id":1074,
              "name":"Lääne-Virumaa",
              "country_id":233
           },
           {
              "id":1075,
              "name":"Pärnumaa",
              "country_id":233
           },
           {
              "id":1076,
              "name":"Põlvamaa",
              "country_id":233
           },
           {
              "id":1077,
              "name":"Raplamaa",
              "country_id":233
           },
           {
              "id":1078,
              "name":"Saaremaa",
              "country_id":233
           },
           {
              "id":1079,
              "name":"Tartumaa",
              "country_id":233
           },
           {
              "id":1080,
              "name":"Valgamaa",
              "country_id":233
           },
           {
              "id":1081,
              "name":"Viljandimaa",
              "country_id":233
           },
           {
              "id":1082,
              "name":"Võrumaa",
              "country_id":233
           }
        ],
        "234":[
           {
              "id":1083,
              "name":"Norðoyar region",
              "country_id":234
           },
           {
              "id":1084,
              "name":"Eysturoy region",
              "country_id":234
           },
           {
              "id":1085,
              "name":"Sandoy region",
              "country_id":234
           },
           {
              "id":1086,
              "name":"Streymoy region",
              "country_id":234
           },
           {
              "id":1087,
              "name":"Suðuroy region",
              "country_id":234
           },
           {
              "id":1088,
              "name":"Vágar region",
              "country_id":234
           }
        ],
        "238":[
           {
              "id":1089,
              "name":"Falkland Islands (Islas Malvinas)",
              "country_id":238
           }
        ],
        "239":[
           {
              "id":1090,
              "name":"South Georgia and The South Sandwich Islands ",
              "country_id":239
           }
        ],
        "242":[
           {
              "id":1091,
              "name":"Central",
              "country_id":242
           },
           {
              "id":1092,
              "name":"Eastern",
              "country_id":242
           },
           {
              "id":1093,
              "name":"Northern",
              "country_id":242
           },
           {
              "id":1094,
              "name":"Rotuma",
              "country_id":242
           },
           {
              "id":1095,
              "name":"Western",
              "country_id":242
           }
        ],
        "246":[
           {
              "id":1096,
              "name":"Åland",
              "country_id":246
           },
           {
              "id":1097,
              "name":"Hame",
              "country_id":246
           },
           {
              "id":1098,
              "name":"Keski-Suomi",
              "country_id":246
           },
           {
              "id":1099,
              "name":"Kuopio",
              "country_id":246
           },
           {
              "id":1100,
              "name":"Kymi",
              "country_id":246
           },
           {
              "id":1101,
              "name":"Lapponia",
              "country_id":246
           },
           {
              "id":1102,
              "name":"Mikkeli",
              "country_id":246
           },
           {
              "id":1103,
              "name":"Oulu",
              "country_id":246
           },
           {
              "id":1104,
              "name":"Pohjois-Karjala",
              "country_id":246
           },
           {
              "id":1105,
              "name":"Turku ja Pori",
              "country_id":246
           },
           {
              "id":1106,
              "name":"Uusimaa",
              "country_id":246
           },
           {
              "id":1107,
              "name":"Vaasa",
              "country_id":246
           },
           {
              "id":1108,
              "name":"Southern Finland",
              "country_id":246
           },
           {
              "id":1109,
              "name":"Eastern Finland",
              "country_id":246
           },
           {
              "id":1110,
              "name":"Western Finland",
              "country_id":246
           }
        ],
        "248":[
     
        ],
        "250":[
           {
              "id":1111,
              "name":"Aquitaine",
              "country_id":250
           },
           {
              "id":1112,
              "name":"Auvergne",
              "country_id":250
           },
           {
              "id":1113,
              "name":"Basse-Normandie",
              "country_id":250
           },
           {
              "id":1114,
              "name":"Bourgogne",
              "country_id":250
           },
           {
              "id":1115,
              "name":"Brittany",
              "country_id":250
           },
           {
              "id":1116,
              "name":"Centre",
              "country_id":250
           },
           {
              "id":1117,
              "name":"Champagne-Ardenne",
              "country_id":250
           },
           {
              "id":1118,
              "name":"Corsica",
              "country_id":250
           },
           {
              "id":1119,
              "name":"Franche-Comté",
              "country_id":250
           },
           {
              "id":1120,
              "name":"Haute-Normandie",
              "country_id":250
           },
           {
              "id":1121,
              "name":"Île-de-France",
              "country_id":250
           },
           {
              "id":1122,
              "name":"Languedoc-Roussillon",
              "country_id":250
           },
           {
              "id":1123,
              "name":"Limousin",
              "country_id":250
           },
           {
              "id":1124,
              "name":"Lorraine",
              "country_id":250
           },
           {
              "id":1125,
              "name":"Midi-Pyrénées",
              "country_id":250
           },
           {
              "id":1126,
              "name":"Nord-Pas-de-Calais",
              "country_id":250
           },
           {
              "id":1127,
              "name":"Pays de la Loire",
              "country_id":250
           },
           {
              "id":1128,
              "name":"Picardie",
              "country_id":250
           },
           {
              "id":1129,
              "name":"Poitou-Charentes",
              "country_id":250
           },
           {
              "id":1130,
              "name":"Provence-Alpes-Côte dʼAzur",
              "country_id":250
           },
           {
              "id":1131,
              "name":"Rhône-Alpes",
              "country_id":250
           },
           {
              "id":1132,
              "name":"Alsace",
              "country_id":250
           }
        ],
        "254":[
           {
              "id":1133,
              "name":"Guyane",
              "country_id":254
           }
        ],
        "258":[
     
        ],
        "260":[
           {
              "id":1134,
              "name":"Saint-Paul-et-Amsterdam",
              "country_id":260
           },
           {
              "id":1135,
              "name":"Crozet",
              "country_id":260
           },
           {
              "id":1136,
              "name":"Kerguelen",
              "country_id":260
           },
           {
              "id":1137,
              "name":"Terre Adélie",
              "country_id":260
           },
           {
              "id":1138,
              "name":"Îles Éparses",
              "country_id":260
           }
        ],
        "262":[
           {
              "id":1139,
              "name":"Ali Sabieh",
              "country_id":262
           },
           {
              "id":1140,
              "name":"Dikhil   ",
              "country_id":262
           },
           {
              "id":1141,
              "name":"Djibouti  ",
              "country_id":262
           },
           {
              "id":1142,
              "name":"Obock",
              "country_id":262
           },
           {
              "id":1143,
              "name":"Tadjourah",
              "country_id":262
           },
           {
              "id":1144,
              "name":"Dikhil",
              "country_id":262
           },
           {
              "id":1145,
              "name":"Djibouti",
              "country_id":262
           },
           {
              "id":1146,
              "name":"Arta",
              "country_id":262
           }
        ],
        "266":[
           {
              "id":1147,
              "name":"Estuaire",
              "country_id":266
           },
           {
              "id":1148,
              "name":"Haut-Ogooué",
              "country_id":266
           },
           {
              "id":1149,
              "name":"Moyen-Ogooué",
              "country_id":266
           },
           {
              "id":1150,
              "name":"Ngounié",
              "country_id":266
           },
           {
              "id":1151,
              "name":"Nyanga",
              "country_id":266
           },
           {
              "id":1152,
              "name":"Ogooué-Ivindo",
              "country_id":266
           },
           {
              "id":1153,
              "name":"Ogooué-Lolo",
              "country_id":266
           },
           {
              "id":1154,
              "name":"Ogooué-Maritime",
              "country_id":266
           },
           {
              "id":1155,
              "name":"Woleu-Ntem",
              "country_id":266
           }
        ],
        "268":[
           {
              "id":1156,
              "name":"Ajaria",
              "country_id":268
           },
           {
              "id":1157,
              "name":"Tʼbilisi",
              "country_id":268
           },
           {
              "id":1158,
              "name":"Abkhazia",
              "country_id":268
           },
           {
              "id":1159,
              "name":"Kvemo Kartli",
              "country_id":268
           },
           {
              "id":1160,
              "name":"Kakheti",
              "country_id":268
           },
           {
              "id":1161,
              "name":"Guria",
              "country_id":268
           },
           {
              "id":1162,
              "name":"Imereti",
              "country_id":268
           },
           {
              "id":1163,
              "name":"Shida Kartli",
              "country_id":268
           },
           {
              "id":1164,
              "name":"Mtskheta-Mtianeti",
              "country_id":268
           },
           {
              "id":1165,
              "name":"Racha-Lechkhumi and Kvemo Svaneti",
              "country_id":268
           },
           {
              "id":1166,
              "name":"Samegrelo and Zemo Svaneti",
              "country_id":268
           },
           {
              "id":1167,
              "name":"Samtskhe-Javakheti",
              "country_id":268
           }
        ],
        "270":[
           {
              "id":1168,
              "name":"Banjul",
              "country_id":270
           },
           {
              "id":1169,
              "name":"Lower River",
              "country_id":270
           },
           {
              "id":1170,
              "name":"Central River",
              "country_id":270
           },
           {
              "id":1171,
              "name":"Upper River",
              "country_id":270
           },
           {
              "id":1172,
              "name":"Western",
              "country_id":270
           },
           {
              "id":1173,
              "name":"North Bank",
              "country_id":270
           }
        ],
        "275":[
           {
              "id":1174,
              "name":"Gaza Strip",
              "country_id":275
           },
           {
              "id":1175,
              "name":"West Bank",
              "country_id":275
           }
        ],
        "276":[
           {
              "id":1176,
              "name":"Baden-Württemberg",
              "country_id":276
           },
           {
              "id":1177,
              "name":"Bavaria",
              "country_id":276
           },
           {
              "id":1178,
              "name":"Bremen",
              "country_id":276
           },
           {
              "id":1179,
              "name":"Hamburg",
              "country_id":276
           },
           {
              "id":1180,
              "name":"Hesse",
              "country_id":276
           },
           {
              "id":1181,
              "name":"Lower Saxony",
              "country_id":276
           },
           {
              "id":1182,
              "name":"North Rhine-Westphalia",
              "country_id":276
           },
           {
              "id":1183,
              "name":"Rhineland-Palatinate",
              "country_id":276
           },
           {
              "id":1184,
              "name":"Saarland",
              "country_id":276
           },
           {
              "id":1185,
              "name":"Schleswig-Holstein",
              "country_id":276
           },
           {
              "id":1186,
              "name":"Brandenburg",
              "country_id":276
           },
           {
              "id":1187,
              "name":"Mecklenburg-Vorpommern",
              "country_id":276
           },
           {
              "id":1188,
              "name":"Saxony",
              "country_id":276
           },
           {
              "id":1189,
              "name":"Saxony-Anhalt",
              "country_id":276
           },
           {
              "id":1190,
              "name":"Thuringia",
              "country_id":276
           },
           {
              "id":1191,
              "name":"Berlin",
              "country_id":276
           }
        ],
        "288":[
           {
              "id":1192,
              "name":"Greater Accra",
              "country_id":288
           },
           {
              "id":1193,
              "name":"Ashanti",
              "country_id":288
           },
           {
              "id":1194,
              "name":"Brong-Ahafo Region",
              "country_id":288
           },
           {
              "id":1195,
              "name":"Central",
              "country_id":288
           },
           {
              "id":1196,
              "name":"Eastern",
              "country_id":288
           },
           {
              "id":1197,
              "name":"Northern",
              "country_id":288
           },
           {
              "id":1198,
              "name":"Volta",
              "country_id":288
           },
           {
              "id":1199,
              "name":"Western",
              "country_id":288
           },
           {
              "id":1200,
              "name":"Upper East",
              "country_id":288
           },
           {
              "id":1201,
              "name":"Upper West",
              "country_id":288
           }
        ],
        "292":[
           {
              "id":1202,
              "name":"Gibraltar",
              "country_id":292
           }
        ],
        "296":[
           {
              "id":1203,
              "name":"Line Islands",
              "country_id":296
           },
           {
              "id":1204,
              "name":"Gilbert Islands",
              "country_id":296
           },
           {
              "id":1205,
              "name":"Phoenix Islands",
              "country_id":296
           }
        ],
        "300":[
           {
              "id":1206,
              "name":"Mount Athos",
              "country_id":300
           },
           {
              "id":1207,
              "name":"East Macedonia and Thrace",
              "country_id":300
           },
           {
              "id":1208,
              "name":"Central Macedonia",
              "country_id":300
           },
           {
              "id":1209,
              "name":"West Macedonia",
              "country_id":300
           },
           {
              "id":1210,
              "name":"Thessaly",
              "country_id":300
           },
           {
              "id":1211,
              "name":"Epirus",
              "country_id":300
           },
           {
              "id":1212,
              "name":"Ionian Islands",
              "country_id":300
           },
           {
              "id":1213,
              "name":"West Greece",
              "country_id":300
           },
           {
              "id":1214,
              "name":"Central Greece",
              "country_id":300
           },
           {
              "id":1215,
              "name":"Peloponnese",
              "country_id":300
           },
           {
              "id":1216,
              "name":"Attica",
              "country_id":300
           },
           {
              "id":1217,
              "name":"North Aegean",
              "country_id":300
           },
           {
              "id":1218,
              "name":"South Aegean",
              "country_id":300
           },
           {
              "id":1219,
              "name":"Crete",
              "country_id":300
           }
        ],
        "304":[
           {
              "id":1220,
              "name":"Nordgrønland",
              "country_id":304
           },
           {
              "id":1221,
              "name":"Østgrønland",
              "country_id":304
           },
           {
              "id":1222,
              "name":"Vestgrønland",
              "country_id":304
           }
        ],
        "308":[
           {
              "id":1223,
              "name":"Saint Andrew",
              "country_id":308
           },
           {
              "id":1224,
              "name":"Saint David",
              "country_id":308
           },
           {
              "id":1225,
              "name":"Saint George",
              "country_id":308
           },
           {
              "id":1226,
              "name":"Saint John",
              "country_id":308
           },
           {
              "id":1227,
              "name":"Saint Mark",
              "country_id":308
           },
           {
              "id":1228,
              "name":"Saint Patrick",
              "country_id":308
           }
        ],
        "312":[
           {
              "id":1229,
              "name":"Guadeloupe",
              "country_id":312
           }
        ],
        "316":[
           {
              "id":1230,
              "name":"Guam",
              "country_id":316
           }
        ],
        "320":[
           {
              "id":1231,
              "name":"Alta Verapaz",
              "country_id":320
           },
           {
              "id":1232,
              "name":"Baja Verapaz",
              "country_id":320
           },
           {
              "id":1233,
              "name":"Chimaltenango",
              "country_id":320
           },
           {
              "id":1234,
              "name":"Chiquimula",
              "country_id":320
           },
           {
              "id":1235,
              "name":"El Progreso",
              "country_id":320
           },
           {
              "id":1236,
              "name":"Escuintla",
              "country_id":320
           },
           {
              "id":1237,
              "name":"Guatemala",
              "country_id":320
           },
           {
              "id":1238,
              "name":"Huehuetenango",
              "country_id":320
           },
           {
              "id":1239,
              "name":"Izabal",
              "country_id":320
           },
           {
              "id":1240,
              "name":"Jalapa",
              "country_id":320
           },
           {
              "id":1241,
              "name":"Jutiapa",
              "country_id":320
           },
           {
              "id":1242,
              "name":"Petén",
              "country_id":320
           },
           {
              "id":1243,
              "name":"Quetzaltenango",
              "country_id":320
           },
           {
              "id":1244,
              "name":"Quiché",
              "country_id":320
           },
           {
              "id":1245,
              "name":"Retalhuleu",
              "country_id":320
           },
           {
              "id":1246,
              "name":"Sacatepéquez",
              "country_id":320
           },
           {
              "id":1247,
              "name":"San Marcos",
              "country_id":320
           },
           {
              "id":1248,
              "name":"Santa Rosa",
              "country_id":320
           },
           {
              "id":1249,
              "name":"Sololá",
              "country_id":320
           },
           {
              "id":1250,
              "name":"Suchitepéquez",
              "country_id":320
           },
           {
              "id":1251,
              "name":"Totonicapán",
              "country_id":320
           },
           {
              "id":1252,
              "name":"Zacapa",
              "country_id":320
           }
        ],
        "324":[
           {
              "id":1253,
              "name":"Beyla",
              "country_id":324
           },
           {
              "id":1254,
              "name":"Boffa",
              "country_id":324
           },
           {
              "id":1255,
              "name":"Boké",
              "country_id":324
           },
           {
              "id":1256,
              "name":"Conakry",
              "country_id":324
           },
           {
              "id":1257,
              "name":"Dabola",
              "country_id":324
           },
           {
              "id":1258,
              "name":"Dalaba",
              "country_id":324
           },
           {
              "id":1259,
              "name":"Dinguiraye",
              "country_id":324
           },
           {
              "id":1260,
              "name":"Faranah",
              "country_id":324
           },
           {
              "id":1261,
              "name":"Forécariah",
              "country_id":324
           },
           {
              "id":1262,
              "name":"Fria",
              "country_id":324
           },
           {
              "id":1263,
              "name":"Gaoual",
              "country_id":324
           },
           {
              "id":1264,
              "name":"Guéckédou",
              "country_id":324
           },
           {
              "id":1265,
              "name":"Kankan",
              "country_id":324
           },
           {
              "id":1266,
              "name":"Kérouané",
              "country_id":324
           },
           {
              "id":1267,
              "name":"Kindia",
              "country_id":324
           },
           {
              "id":1268,
              "name":"Kissidougou",
              "country_id":324
           },
           {
              "id":1269,
              "name":"Koundara",
              "country_id":324
           },
           {
              "id":1270,
              "name":"Kouroussa",
              "country_id":324
           },
           {
              "id":1271,
              "name":"Macenta",
              "country_id":324
           },
           {
              "id":1272,
              "name":"Mali",
              "country_id":324
           },
           {
              "id":1273,
              "name":"Mamou",
              "country_id":324
           },
           {
              "id":1274,
              "name":"Pita",
              "country_id":324
           },
           {
              "id":1275,
              "name":"Siguiri",
              "country_id":324
           },
           {
              "id":1276,
              "name":"Télimélé",
              "country_id":324
           },
           {
              "id":1277,
              "name":"Tougué",
              "country_id":324
           },
           {
              "id":1278,
              "name":"Yomou",
              "country_id":324
           },
           {
              "id":1279,
              "name":"Coyah",
              "country_id":324
           },
           {
              "id":1280,
              "name":"Dubréka",
              "country_id":324
           },
           {
              "id":1281,
              "name":"Kankan",
              "country_id":324
           },
           {
              "id":1282,
              "name":"Koubia",
              "country_id":324
           },
           {
              "id":1283,
              "name":"Labé",
              "country_id":324
           },
           {
              "id":1284,
              "name":"Lélouma",
              "country_id":324
           },
           {
              "id":1285,
              "name":"Lola",
              "country_id":324
           },
           {
              "id":1286,
              "name":"Mandiana",
              "country_id":324
           },
           {
              "id":1287,
              "name":"Nzérékoré",
              "country_id":324
           },
           {
              "id":1288,
              "name":"Siguiri",
              "country_id":324
           }
        ],
        "328":[
           {
              "id":1289,
              "name":"Barima-Waini",
              "country_id":328
           },
           {
              "id":1290,
              "name":"Cuyuni-Mazaruni",
              "country_id":328
           },
           {
              "id":1291,
              "name":"Demerara-Mahaica",
              "country_id":328
           },
           {
              "id":1292,
              "name":"East Berbice-Corentyne",
              "country_id":328
           },
           {
              "id":1293,
              "name":"Essequibo Islands-West Demerara",
              "country_id":328
           },
           {
              "id":1294,
              "name":"Mahaica-Berbice",
              "country_id":328
           },
           {
              "id":1295,
              "name":"Pomeroon-Supenaam",
              "country_id":328
           },
           {
              "id":1296,
              "name":"Potaro-Siparuni",
              "country_id":328
           },
           {
              "id":1297,
              "name":"Upper Demerara-Berbice",
              "country_id":328
           },
           {
              "id":1298,
              "name":"Upper Takutu-Upper Essequibo",
              "country_id":328
           }
        ],
        "332":[
           {
              "id":1299,
              "name":"Nord-Ouest",
              "country_id":332
           },
           {
              "id":1300,
              "name":"Artibonite",
              "country_id":332
           },
           {
              "id":1301,
              "name":"Centre",
              "country_id":332
           },
           {
              "id":1302,
              "name":"Nord",
              "country_id":332
           },
           {
              "id":1303,
              "name":"Nord-Est",
              "country_id":332
           },
           {
              "id":1304,
              "name":"Ouest",
              "country_id":332
           },
           {
              "id":1305,
              "name":"Sud",
              "country_id":332
           },
           {
              "id":1306,
              "name":"Sud-Est",
              "country_id":332
           },
           {
              "id":1307,
              "name":"GrandʼAnse",
              "country_id":332
           },
           {
              "id":1308,
              "name":"Nippes",
              "country_id":332
           }
        ],
        "334":[
     
        ],
        "336":[
           {
              "id":1309,
              "name":"Vatican City",
              "country_id":336
           }
        ],
        "340":[
           {
              "id":1310,
              "name":"Atlántida",
              "country_id":340
           },
           {
              "id":1311,
              "name":"Choluteca",
              "country_id":340
           },
           {
              "id":1312,
              "name":"Colón",
              "country_id":340
           },
           {
              "id":1313,
              "name":"Comayagua",
              "country_id":340
           },
           {
              "id":1314,
              "name":"Copán",
              "country_id":340
           },
           {
              "id":1315,
              "name":"Cortés",
              "country_id":340
           },
           {
              "id":1316,
              "name":"El Paraíso",
              "country_id":340
           },
           {
              "id":1317,
              "name":"Francisco Morazán",
              "country_id":340
           },
           {
              "id":1318,
              "name":"Gracias a Dios",
              "country_id":340
           },
           {
              "id":1319,
              "name":"Intibucá",
              "country_id":340
           },
           {
              "id":1320,
              "name":"Islas de la Bahía",
              "country_id":340
           },
           {
              "id":1321,
              "name":"La Paz",
              "country_id":340
           },
           {
              "id":1322,
              "name":"Lempira",
              "country_id":340
           },
           {
              "id":1323,
              "name":"Ocotepeque",
              "country_id":340
           },
           {
              "id":1324,
              "name":"Olancho",
              "country_id":340
           },
           {
              "id":1325,
              "name":"Santa Bárbara",
              "country_id":340
           },
           {
              "id":1326,
              "name":"Valle",
              "country_id":340
           },
           {
              "id":1327,
              "name":"Yoro",
              "country_id":340
           }
        ],
        "344":[
           {
              "id":1328,
              "name":"Hong Kong",
              "country_id":344
           }
        ],
        "348":[
           {
              "id":1329,
              "name":"Bács-Kiskun",
              "country_id":348
           },
           {
              "id":1330,
              "name":"Baranya",
              "country_id":348
           },
           {
              "id":1331,
              "name":"Békés",
              "country_id":348
           },
           {
              "id":1332,
              "name":"Borsod-Abaúj-Zemplén",
              "country_id":348
           },
           {
              "id":1333,
              "name":"Budapest",
              "country_id":348
           },
           {
              "id":1334,
              "name":"Csongrád",
              "country_id":348
           },
           {
              "id":1335,
              "name":"Fejér",
              "country_id":348
           },
           {
              "id":1336,
              "name":"Győr-Moson-Sopron",
              "country_id":348
           },
           {
              "id":1337,
              "name":"Hajdú-Bihar",
              "country_id":348
           },
           {
              "id":1338,
              "name":"Heves",
              "country_id":348
           },
           {
              "id":1339,
              "name":"Komárom-Esztergom",
              "country_id":348
           },
           {
              "id":1340,
              "name":"Nógrád",
              "country_id":348
           },
           {
              "id":1341,
              "name":"Pest",
              "country_id":348
           },
           {
              "id":1342,
              "name":"Somogy",
              "country_id":348
           },
           {
              "id":1343,
              "name":"Szabolcs-Szatmár-Bereg",
              "country_id":348
           },
           {
              "id":1344,
              "name":"Jász-Nagykun-Szolnok",
              "country_id":348
           },
           {
              "id":1345,
              "name":"Tolna",
              "country_id":348
           },
           {
              "id":1346,
              "name":"Vas",
              "country_id":348
           },
           {
              "id":1347,
              "name":"Veszprém",
              "country_id":348
           },
           {
              "id":1348,
              "name":"Zala",
              "country_id":348
           }
        ],
        "352":[
           {
              "id":1349,
              "name":"Borgarfjardarsysla",
              "country_id":352
           },
           {
              "id":1350,
              "name":"Dalasysla",
              "country_id":352
           },
           {
              "id":1351,
              "name":"Eyjafjardarsysla",
              "country_id":352
           },
           {
              "id":1352,
              "name":"Gullbringusysla",
              "country_id":352
           },
           {
              "id":1353,
              "name":"Hafnarfjördur",
              "country_id":352
           },
           {
              "id":1354,
              "name":"Husavik",
              "country_id":352
           },
           {
              "id":1355,
              "name":"Isafjördur",
              "country_id":352
           },
           {
              "id":1356,
              "name":"Keflavik",
              "country_id":352
           },
           {
              "id":1357,
              "name":"Kjosarsysla",
              "country_id":352
           },
           {
              "id":1358,
              "name":"Kopavogur",
              "country_id":352
           },
           {
              "id":1359,
              "name":"Myrasysla",
              "country_id":352
           },
           {
              "id":1360,
              "name":"Neskaupstadur",
              "country_id":352
           },
           {
              "id":1361,
              "name":"Nordur-Isafjardarsysla",
              "country_id":352
           },
           {
              "id":1362,
              "name":"Nordur-Mulasysla",
              "country_id":352
           },
           {
              "id":1363,
              "name":"Nordur-Tingeyjarsysla",
              "country_id":352
           },
           {
              "id":1364,
              "name":"Olafsfjördur",
              "country_id":352
           },
           {
              "id":1365,
              "name":"Rangarvallasysla",
              "country_id":352
           },
           {
              "id":1366,
              "name":"Reykjavík",
              "country_id":352
           },
           {
              "id":1367,
              "name":"Saudarkrokur",
              "country_id":352
           },
           {
              "id":1368,
              "name":"Seydisfjordur",
              "country_id":352
           },
           {
              "id":1369,
              "name":"Siglufjordur",
              "country_id":352
           },
           {
              "id":1370,
              "name":"Skagafjardarsysla",
              "country_id":352
           },
           {
              "id":1371,
              "name":"Snafellsnes- og Hnappadalssysla",
              "country_id":352
           },
           {
              "id":1372,
              "name":"Strandasysla",
              "country_id":352
           },
           {
              "id":1373,
              "name":"Sudur-Mulasysla",
              "country_id":352
           },
           {
              "id":1374,
              "name":"Sudur-Tingeyjarsysla",
              "country_id":352
           },
           {
              "id":1375,
              "name":"Vestmannaeyjar",
              "country_id":352
           },
           {
              "id":1376,
              "name":"Vestur-Bardastrandarsysla",
              "country_id":352
           },
           {
              "id":1377,
              "name":"Vestur-Hunavatnssysla",
              "country_id":352
           },
           {
              "id":1378,
              "name":"Vestur-Isafjardarsysla",
              "country_id":352
           },
           {
              "id":1379,
              "name":"Vestur-Skaftafellssysla",
              "country_id":352
           },
           {
              "id":1380,
              "name":"East",
              "country_id":352
           },
           {
              "id":1381,
              "name":"Capital Region",
              "country_id":352
           },
           {
              "id":1382,
              "name":"Northeast",
              "country_id":352
           },
           {
              "id":1383,
              "name":"Northwest",
              "country_id":352
           },
           {
              "id":1384,
              "name":"South",
              "country_id":352
           },
           {
              "id":1385,
              "name":"Southern Peninsula",
              "country_id":352
           },
           {
              "id":1386,
              "name":"Westfjords",
              "country_id":352
           },
           {
              "id":1387,
              "name":"West",
              "country_id":352
           }
        ],
        "360":[
           {
              "id":1388,
              "name":"Aceh",
              "country_id":360
           },
           {
              "id":1389,
              "name":"Bali",
              "country_id":360
           },
           {
              "id":1390,
              "name":"Bengkulu",
              "country_id":360
           },
           {
              "id":1391,
              "name":"Jakarta Raya",
              "country_id":360
           },
           {
              "id":1392,
              "name":"Jambi",
              "country_id":360
           },
           {
              "id":1393,
              "name":"Jawa Barat",
              "country_id":360
           },
           {
              "id":1394,
              "name":"Central Java",
              "country_id":360
           },
           {
              "id":1395,
              "name":"East Java",
              "country_id":360
           },
           {
              "id":1396,
              "name":"Yogyakarta ",
              "country_id":360
           },
           {
              "id":1397,
              "name":"West Kalimantan",
              "country_id":360
           },
           {
              "id":1398,
              "name":"South Kalimantan",
              "country_id":360
           },
           {
              "id":1399,
              "name":"Kalimantan Tengah",
              "country_id":360
           },
           {
              "id":1400,
              "name":"East Kalimantan",
              "country_id":360
           },
           {
              "id":1401,
              "name":"Lampung",
              "country_id":360
           },
           {
              "id":1402,
              "name":"Nusa Tenggara Barat",
              "country_id":360
           },
           {
              "id":1403,
              "name":"East Nusa Tenggara",
              "country_id":360
           },
           {
              "id":1404,
              "name":"Central Sulawesi",
              "country_id":360
           },
           {
              "id":1405,
              "name":"Sulawesi Tenggara",
              "country_id":360
           },
           {
              "id":1406,
              "name":"Sulawesi Utara",
              "country_id":360
           },
           {
              "id":1407,
              "name":"West Sumatra",
              "country_id":360
           },
           {
              "id":1408,
              "name":"Sumatera Selatan",
              "country_id":360
           },
           {
              "id":1409,
              "name":"North Sumatra",
              "country_id":360
           },
           {
              "id":1410,
              "name":"Timor Timur",
              "country_id":360
           },
           {
              "id":1411,
              "name":"Maluku ",
              "country_id":360
           },
           {
              "id":1412,
              "name":"Maluku Utara",
              "country_id":360
           },
           {
              "id":1413,
              "name":"West Java",
              "country_id":360
           },
           {
              "id":1414,
              "name":"North Sulawesi",
              "country_id":360
           },
           {
              "id":1415,
              "name":"South Sumatra",
              "country_id":360
           },
           {
              "id":1416,
              "name":"Banten",
              "country_id":360
           },
           {
              "id":1417,
              "name":"Gorontalo",
              "country_id":360
           },
           {
              "id":1418,
              "name":"Bangka-Belitung",
              "country_id":360
           },
           {
              "id":1419,
              "name":"Papua",
              "country_id":360
           },
           {
              "id":1420,
              "name":"Riau",
              "country_id":360
           },
           {
              "id":1421,
              "name":"South Sulawesi",
              "country_id":360
           },
           {
              "id":1422,
              "name":"Irian Jaya Barat",
              "country_id":360
           },
           {
              "id":1423,
              "name":"Riau Islands",
              "country_id":360
           },
           {
              "id":1424,
              "name":"Sulawesi Barat",
              "country_id":360
           }
        ],
        "364":[
           {
              "id":1425,
              "name":"Āz̄ārbāyjān-e Gharbī",
              "country_id":364
           },
           {
              "id":1426,
              "name":"Chahār Maḩāll va Bakhtīārī",
              "country_id":364
           },
           {
              "id":1427,
              "name":"Sīstān va Balūchestān",
              "country_id":364
           },
           {
              "id":1428,
              "name":"Kohgīlūyeh va Būyer Aḩmad",
              "country_id":364
           },
           {
              "id":1429,
              "name":"Fārs Province",
              "country_id":364
           },
           {
              "id":1430,
              "name":"Gīlān",
              "country_id":364
           },
           {
              "id":1431,
              "name":"Hamadān",
              "country_id":364
           },
           {
              "id":1432,
              "name":"Īlām",
              "country_id":364
           },
           {
              "id":1433,
              "name":"Hormozgān Province",
              "country_id":364
           },
           {
              "id":1434,
              "name":"Kerman",
              "country_id":364
           },
           {
              "id":1435,
              "name":"Kermānshāh",
              "country_id":364
           },
           {
              "id":1436,
              "name":"Khūzestān",
              "country_id":364
           },
           {
              "id":1437,
              "name":"Kordestān",
              "country_id":364
           },
           {
              "id":1438,
              "name":"Mazandaran",
              "country_id":364
           },
           {
              "id":1439,
              "name":"Markazi",
              "country_id":364
           },
           {
              "id":1440,
              "name":"Zanjan",
              "country_id":364
           },
           {
              "id":1441,
              "name":"Bushehr Province",
              "country_id":364
           },
           {
              "id":1442,
              "name":"Lorestān",
              "country_id":364
           },
           {
              "id":1443,
              "name":"Markazi",
              "country_id":364
           },
           {
              "id":1444,
              "name":"Semnān Province",
              "country_id":364
           },
           {
              "id":1445,
              "name":"Tehrān",
              "country_id":364
           },
           {
              "id":1446,
              "name":"Zanjan",
              "country_id":364
           },
           {
              "id":1447,
              "name":"Eşfahān",
              "country_id":364
           },
           {
              "id":1448,
              "name":"Kermān",
              "country_id":364
           },
           {
              "id":1449,
              "name":"Ostan-e Khorasan-e Razavi",
              "country_id":364
           },
           {
              "id":1450,
              "name":"Yazd",
              "country_id":364
           },
           {
              "id":1451,
              "name":"Ardabīl",
              "country_id":364
           },
           {
              "id":1452,
              "name":"Āz̄ārbāyjān-e Sharqī",
              "country_id":364
           },
           {
              "id":1453,
              "name":"Markazi Province",
              "country_id":364
           },
           {
              "id":1454,
              "name":"Māzandarān Province",
              "country_id":364
           },
           {
              "id":1455,
              "name":"Zanjan Province",
              "country_id":364
           },
           {
              "id":1456,
              "name":"Golestān",
              "country_id":364
           },
           {
              "id":1457,
              "name":"Qazvīn",
              "country_id":364
           },
           {
              "id":1458,
              "name":"Qom",
              "country_id":364
           },
           {
              "id":1459,
              "name":"Yazd",
              "country_id":364
           },
           {
              "id":1460,
              "name":"Khorāsān-e Jonūbī",
              "country_id":364
           },
           {
              "id":1461,
              "name":"Razavi Khorasan Province",
              "country_id":364
           },
           {
              "id":1462,
              "name":"Khorāsān-e Shomālī",
              "country_id":364
           }
        ],
        "368":[
           {
              "id":1463,
              "name":"Anbar",
              "country_id":368
           },
           {
              "id":1464,
              "name":"Al Başrah",
              "country_id":368
           },
           {
              "id":1465,
              "name":"Al Muthanná",
              "country_id":368
           },
           {
              "id":1466,
              "name":"Al Qādisīyah",
              "country_id":368
           },
           {
              "id":1467,
              "name":"As Sulaymānīyah",
              "country_id":368
           },
           {
              "id":1468,
              "name":"Bābil",
              "country_id":368
           },
           {
              "id":1469,
              "name":"Baghdād",
              "country_id":368
           },
           {
              "id":1470,
              "name":"Dahūk",
              "country_id":368
           },
           {
              "id":1471,
              "name":"Dhi Qar",
              "country_id":368
           },
           {
              "id":1472,
              "name":"Diyala",
              "country_id":368
           },
           {
              "id":1473,
              "name":"Arbīl",
              "country_id":368
           },
           {
              "id":1474,
              "name":"Karbalāʼ",
              "country_id":368
           },
           {
              "id":1475,
              "name":"At Taʼmīm",
              "country_id":368
           },
           {
              "id":1476,
              "name":"Maysan",
              "country_id":368
           },
           {
              "id":1477,
              "name":"Nīnawá",
              "country_id":368
           },
           {
              "id":1478,
              "name":"Wāsiţ",
              "country_id":368
           },
           {
              "id":1479,
              "name":"An Najaf",
              "country_id":368
           },
           {
              "id":1480,
              "name":"Şalāḩ ad Dīn",
              "country_id":368
           }
        ],
        "372":[
           {
              "id":1481,
              "name":"Carlow",
              "country_id":372
           },
           {
              "id":1482,
              "name":"Cavan",
              "country_id":372
           },
           {
              "id":1483,
              "name":"County Clare",
              "country_id":372
           },
           {
              "id":1484,
              "name":"Cork",
              "country_id":372
           },
           {
              "id":1485,
              "name":"Donegal",
              "country_id":372
           },
           {
              "id":1486,
              "name":"Galway",
              "country_id":372
           },
           {
              "id":1487,
              "name":"County Kerry",
              "country_id":372
           },
           {
              "id":1488,
              "name":"County Kildare",
              "country_id":372
           },
           {
              "id":1489,
              "name":"County Kilkenny",
              "country_id":372
           },
           {
              "id":1490,
              "name":"Leitrim",
              "country_id":372
           },
           {
              "id":1491,
              "name":"Laois",
              "country_id":372
           },
           {
              "id":1492,
              "name":"Limerick",
              "country_id":372
           },
           {
              "id":1493,
              "name":"County Longford",
              "country_id":372
           },
           {
              "id":1494,
              "name":"County Louth",
              "country_id":372
           },
           {
              "id":1495,
              "name":"County Mayo",
              "country_id":372
           },
           {
              "id":1496,
              "name":"County Meath",
              "country_id":372
           },
           {
              "id":1497,
              "name":"Monaghan",
              "country_id":372
           },
           {
              "id":1498,
              "name":"County Offaly",
              "country_id":372
           },
           {
              "id":1499,
              "name":"County Roscommon",
              "country_id":372
           },
           {
              "id":1500,
              "name":"County Sligo",
              "country_id":372
           },
           {
              "id":1501,
              "name":"County Waterford",
              "country_id":372
           },
           {
              "id":1502,
              "name":"County Westmeath",
              "country_id":372
           },
           {
              "id":1503,
              "name":"County Wexford",
              "country_id":372
           },
           {
              "id":1504,
              "name":"County Wicklow",
              "country_id":372
           },
           {
              "id":1505,
              "name":"Dún Laoghaire-Rathdown",
              "country_id":372
           },
           {
              "id":1506,
              "name":"Fingal County",
              "country_id":372
           },
           {
              "id":1507,
              "name":"Tipperary North Riding",
              "country_id":372
           },
           {
              "id":1508,
              "name":"South Dublin",
              "country_id":372
           },
           {
              "id":1509,
              "name":"Tipperary South Riding",
              "country_id":372
           }
        ],
        "376":[
           {
              "id":1510,
              "name":"HaDarom",
              "country_id":376
           },
           {
              "id":1511,
              "name":"HaMerkaz",
              "country_id":376
           },
           {
              "id":1512,
              "name":"Northern District",
              "country_id":376
           },
           {
              "id":1513,
              "name":"H̱efa",
              "country_id":376
           },
           {
              "id":1514,
              "name":"Tel Aviv",
              "country_id":376
           },
           {
              "id":1515,
              "name":"Yerushalayim",
              "country_id":376
           }
        ],
        "380":[
           {
              "id":1516,
              "name":"Abruzzo",
              "country_id":380
           },
           {
              "id":1517,
              "name":"Basilicate",
              "country_id":380
           },
           {
              "id":1518,
              "name":"Calabria",
              "country_id":380
           },
           {
              "id":1519,
              "name":"Campania",
              "country_id":380
           },
           {
              "id":1520,
              "name":"Emilia-Romagna",
              "country_id":380
           },
           {
              "id":1521,
              "name":"Friuli",
              "country_id":380
           },
           {
              "id":1522,
              "name":"Lazio",
              "country_id":380
           },
           {
              "id":1523,
              "name":"Liguria",
              "country_id":380
           },
           {
              "id":1524,
              "name":"Lombardy",
              "country_id":380
           },
           {
              "id":1525,
              "name":"The Marches",
              "country_id":380
           },
           {
              "id":1526,
              "name":"Molise",
              "country_id":380
           },
           {
              "id":1527,
              "name":"Piedmont",
              "country_id":380
           },
           {
              "id":1528,
              "name":"Apulia",
              "country_id":380
           },
           {
              "id":1529,
              "name":"Sardinia",
              "country_id":380
           },
           {
              "id":1530,
              "name":"Sicily",
              "country_id":380
           },
           {
              "id":1531,
              "name":"Tuscany",
              "country_id":380
           },
           {
              "id":1532,
              "name":"Trentino-Alto Adige",
              "country_id":380
           },
           {
              "id":1533,
              "name":"Umbria",
              "country_id":380
           },
           {
              "id":1534,
              "name":"Aosta Valley",
              "country_id":380
           },
           {
              "id":1535,
              "name":"Veneto",
              "country_id":380
           }
        ],
        "384":[
           {
              "id":1536,
              "name":"Valparaíso Region",
              "country_id":384
           },
           {
              "id":1537,
              "name":"Antofagasta Region",
              "country_id":384
           },
           {
              "id":1538,
              "name":"Araucanía Region",
              "country_id":384
           },
           {
              "id":1539,
              "name":"Atacama Region",
              "country_id":384
           },
           {
              "id":1540,
              "name":"Biobío Region",
              "country_id":384
           },
           {
              "id":1541,
              "name":"Coquimbo Region",
              "country_id":384
           },
           {
              "id":1542,
              "name":"Maule Region",
              "country_id":384
           },
           {
              "id":1543,
              "name":"Santiago Metropolitan Region",
              "country_id":384
           },
           {
              "id":1544,
              "name":"Danane",
              "country_id":384
           },
           {
              "id":1545,
              "name":"Divo",
              "country_id":384
           },
           {
              "id":1546,
              "name":"Ferkessedougou",
              "country_id":384
           },
           {
              "id":1547,
              "name":"Gagnoa",
              "country_id":384
           },
           {
              "id":1548,
              "name":"Katiola",
              "country_id":384
           },
           {
              "id":1549,
              "name":"Korhogo",
              "country_id":384
           },
           {
              "id":1550,
              "name":"Odienne",
              "country_id":384
           },
           {
              "id":1551,
              "name":"Seguela",
              "country_id":384
           },
           {
              "id":1552,
              "name":"Touba",
              "country_id":384
           },
           {
              "id":1553,
              "name":"Bongouanou",
              "country_id":384
           },
           {
              "id":1554,
              "name":"Issia",
              "country_id":384
           },
           {
              "id":1555,
              "name":"Lakota",
              "country_id":384
           },
           {
              "id":1556,
              "name":"Mankono",
              "country_id":384
           },
           {
              "id":1557,
              "name":"Oume",
              "country_id":384
           },
           {
              "id":1558,
              "name":"Soubre",
              "country_id":384
           },
           {
              "id":1559,
              "name":"Tingrela",
              "country_id":384
           },
           {
              "id":1560,
              "name":"Zuenoula",
              "country_id":384
           },
           {
              "id":1561,
              "name":"Bangolo",
              "country_id":384
           },
           {
              "id":1562,
              "name":"Beoumi",
              "country_id":384
           },
           {
              "id":1563,
              "name":"Bondoukou",
              "country_id":384
           },
           {
              "id":1564,
              "name":"Bouafle",
              "country_id":384
           },
           {
              "id":1565,
              "name":"Bouake",
              "country_id":384
           },
           {
              "id":1566,
              "name":"Daloa",
              "country_id":384
           },
           {
              "id":1567,
              "name":"Daoukro",
              "country_id":384
           },
           {
              "id":1568,
              "name":"Duekoue",
              "country_id":384
           },
           {
              "id":1569,
              "name":"Grand-Lahou",
              "country_id":384
           },
           {
              "id":1570,
              "name":"Man",
              "country_id":384
           },
           {
              "id":1571,
              "name":"Mbahiakro",
              "country_id":384
           },
           {
              "id":1572,
              "name":"Sakassou",
              "country_id":384
           },
           {
              "id":1573,
              "name":"San Pedro",
              "country_id":384
           },
           {
              "id":1574,
              "name":"Sassandra",
              "country_id":384
           },
           {
              "id":1575,
              "name":"Sinfra",
              "country_id":384
           },
           {
              "id":1576,
              "name":"Tabou",
              "country_id":384
           },
           {
              "id":1577,
              "name":"Tanda",
              "country_id":384
           },
           {
              "id":1578,
              "name":"Tiassale",
              "country_id":384
           },
           {
              "id":1579,
              "name":"Toumodi",
              "country_id":384
           },
           {
              "id":1580,
              "name":"Vavoua",
              "country_id":384
           },
           {
              "id":1581,
              "name":"Abidjan",
              "country_id":384
           },
           {
              "id":1582,
              "name":"Aboisso",
              "country_id":384
           },
           {
              "id":1583,
              "name":"Adiake",
              "country_id":384
           },
           {
              "id":1584,
              "name":"Alepe",
              "country_id":384
           },
           {
              "id":1585,
              "name":"Bocanda",
              "country_id":384
           },
           {
              "id":1586,
              "name":"Dabou",
              "country_id":384
           },
           {
              "id":1587,
              "name":"Dimbokro",
              "country_id":384
           },
           {
              "id":1588,
              "name":"Grand-Bassam",
              "country_id":384
           },
           {
              "id":1589,
              "name":"Guiglo",
              "country_id":384
           },
           {
              "id":1590,
              "name":"Jacqueville",
              "country_id":384
           },
           {
              "id":1591,
              "name":"Tiebissou",
              "country_id":384
           },
           {
              "id":1592,
              "name":"Toulepleu",
              "country_id":384
           },
           {
              "id":1593,
              "name":"Yamoussoukro",
              "country_id":384
           },
           {
              "id":1594,
              "name":"Agnéby",
              "country_id":384
           },
           {
              "id":1595,
              "name":"Bafing",
              "country_id":384
           },
           {
              "id":1596,
              "name":"Bas-Sassandra",
              "country_id":384
           },
           {
              "id":1597,
              "name":"Denguélé",
              "country_id":384
           },
           {
              "id":1598,
              "name":"Dix-Huit Montagnes",
              "country_id":384
           },
           {
              "id":1599,
              "name":"Fromager",
              "country_id":384
           },
           {
              "id":1600,
              "name":"Haut-Sassandra",
              "country_id":384
           },
           {
              "id":1601,
              "name":"Lacs",
              "country_id":384
           },
           {
              "id":1602,
              "name":"Lagunes",
              "country_id":384
           },
           {
              "id":1603,
              "name":"Marahoué",
              "country_id":384
           },
           {
              "id":1604,
              "name":"Moyen-Cavally",
              "country_id":384
           },
           {
              "id":1605,
              "name":"Moyen-Comoé",
              "country_id":384
           },
           {
              "id":1606,
              "name":"Nʼzi-Comoé",
              "country_id":384
           },
           {
              "id":1607,
              "name":"Savanes",
              "country_id":384
           },
           {
              "id":1608,
              "name":"Sud-Bandama",
              "country_id":384
           },
           {
              "id":1609,
              "name":"Sud-Comoé",
              "country_id":384
           },
           {
              "id":1610,
              "name":"Vallée du Bandama",
              "country_id":384
           },
           {
              "id":1611,
              "name":"Worodougou",
              "country_id":384
           },
           {
              "id":1612,
              "name":"Zanzan",
              "country_id":384
           }
        ],
        "388":[
           {
              "id":1613,
              "name":"Clarendon",
              "country_id":388
           },
           {
              "id":1614,
              "name":"Hanover Parish",
              "country_id":388
           },
           {
              "id":1615,
              "name":"Manchester",
              "country_id":388
           },
           {
              "id":1616,
              "name":"Portland",
              "country_id":388
           },
           {
              "id":1617,
              "name":"Saint Andrew",
              "country_id":388
           },
           {
              "id":1618,
              "name":"Saint Ann",
              "country_id":388
           },
           {
              "id":1619,
              "name":"Saint Catherine",
              "country_id":388
           },
           {
              "id":1620,
              "name":"St. Elizabeth",
              "country_id":388
           },
           {
              "id":1621,
              "name":"Saint James",
              "country_id":388
           },
           {
              "id":1622,
              "name":"Saint Mary",
              "country_id":388
           },
           {
              "id":1623,
              "name":"Saint Thomas",
              "country_id":388
           },
           {
              "id":1624,
              "name":"Trelawny",
              "country_id":388
           },
           {
              "id":1625,
              "name":"Westmoreland",
              "country_id":388
           },
           {
              "id":1626,
              "name":"Kingston",
              "country_id":388
           }
        ],
        "392":[
           {
              "id":1627,
              "name":"Aichi",
              "country_id":392
           },
           {
              "id":1628,
              "name":"Akita",
              "country_id":392
           },
           {
              "id":1629,
              "name":"Aomori",
              "country_id":392
           },
           {
              "id":1630,
              "name":"Chiba",
              "country_id":392
           },
           {
              "id":1631,
              "name":"Ehime",
              "country_id":392
           },
           {
              "id":1632,
              "name":"Fukui",
              "country_id":392
           },
           {
              "id":1633,
              "name":"Fukuoka",
              "country_id":392
           },
           {
              "id":1634,
              "name":"Fukushima",
              "country_id":392
           },
           {
              "id":1635,
              "name":"Gifu",
              "country_id":392
           },
           {
              "id":1636,
              "name":"Gumma",
              "country_id":392
           },
           {
              "id":1637,
              "name":"Hiroshima",
              "country_id":392
           },
           {
              "id":1638,
              "name":"Hokkaidō",
              "country_id":392
           },
           {
              "id":1639,
              "name":"Hyōgo",
              "country_id":392
           },
           {
              "id":1640,
              "name":"Ibaraki",
              "country_id":392
           },
           {
              "id":1641,
              "name":"Ishikawa",
              "country_id":392
           },
           {
              "id":1642,
              "name":"Iwate",
              "country_id":392
           },
           {
              "id":1643,
              "name":"Kagawa",
              "country_id":392
           },
           {
              "id":1644,
              "name":"Kagoshima",
              "country_id":392
           },
           {
              "id":1645,
              "name":"Kanagawa",
              "country_id":392
           },
           {
              "id":1646,
              "name":"Kōchi",
              "country_id":392
           },
           {
              "id":1647,
              "name":"Kumamoto",
              "country_id":392
           },
           {
              "id":1648,
              "name":"Kyōto",
              "country_id":392
           },
           {
              "id":1649,
              "name":"Mie",
              "country_id":392
           },
           {
              "id":1650,
              "name":"Miyagi",
              "country_id":392
           },
           {
              "id":1651,
              "name":"Miyazaki",
              "country_id":392
           },
           {
              "id":1652,
              "name":"Nagano",
              "country_id":392
           },
           {
              "id":1653,
              "name":"Nagasaki",
              "country_id":392
           },
           {
              "id":1654,
              "name":"Nara",
              "country_id":392
           },
           {
              "id":1655,
              "name":"Niigata",
              "country_id":392
           },
           {
              "id":1656,
              "name":"Ōita",
              "country_id":392
           },
           {
              "id":1657,
              "name":"Okayama",
              "country_id":392
           },
           {
              "id":1658,
              "name":"Ōsaka",
              "country_id":392
           },
           {
              "id":1659,
              "name":"Saga",
              "country_id":392
           },
           {
              "id":1660,
              "name":"Saitama",
              "country_id":392
           },
           {
              "id":1661,
              "name":"Shiga",
              "country_id":392
           },
           {
              "id":1662,
              "name":"Shimane",
              "country_id":392
           },
           {
              "id":1663,
              "name":"Shizuoka",
              "country_id":392
           },
           {
              "id":1664,
              "name":"Tochigi",
              "country_id":392
           },
           {
              "id":1665,
              "name":"Tokushima",
              "country_id":392
           },
           {
              "id":1666,
              "name":"Tōkyō",
              "country_id":392
           },
           {
              "id":1667,
              "name":"Tottori",
              "country_id":392
           },
           {
              "id":1668,
              "name":"Toyama",
              "country_id":392
           },
           {
              "id":1669,
              "name":"Wakayama",
              "country_id":392
           },
           {
              "id":1670,
              "name":"Yamagata",
              "country_id":392
           },
           {
              "id":1671,
              "name":"Yamaguchi",
              "country_id":392
           },
           {
              "id":1672,
              "name":"Yamanashi",
              "country_id":392
           },
           {
              "id":1673,
              "name":"Okinawa",
              "country_id":392
           }
        ],
        "398":[
           {
              "id":1674,
              "name":"Almaty",
              "country_id":398
           },
           {
              "id":1675,
              "name":"Almaty Qalasy",
              "country_id":398
           },
           {
              "id":1676,
              "name":"Aqmola",
              "country_id":398
           },
           {
              "id":1677,
              "name":"Aqtöbe",
              "country_id":398
           },
           {
              "id":1678,
              "name":"Astana Qalasy",
              "country_id":398
           },
           {
              "id":1679,
              "name":"Atyraū",
              "country_id":398
           },
           {
              "id":1680,
              "name":"Batys Qazaqstan",
              "country_id":398
           },
           {
              "id":1681,
              "name":"Bayqongyr Qalasy",
              "country_id":398
           },
           {
              "id":1682,
              "name":"Mangghystaū",
              "country_id":398
           },
           {
              "id":1683,
              "name":"Ongtüstik Qazaqstan",
              "country_id":398
           },
           {
              "id":1684,
              "name":"Pavlodar",
              "country_id":398
           },
           {
              "id":1685,
              "name":"Qaraghandy",
              "country_id":398
           },
           {
              "id":1686,
              "name":"Qostanay",
              "country_id":398
           },
           {
              "id":1687,
              "name":"Qyzylorda",
              "country_id":398
           },
           {
              "id":1688,
              "name":"East Kazakhstan",
              "country_id":398
           },
           {
              "id":1689,
              "name":"Soltüstik Qazaqstan",
              "country_id":398
           },
           {
              "id":1690,
              "name":"Zhambyl",
              "country_id":398
           }
        ],
        "400":[
           {
              "id":1691,
              "name":"Balqa",
              "country_id":400
           },
           {
              "id":1692,
              "name":"Ma’an",
              "country_id":400
           },
           {
              "id":1693,
              "name":"Karak",
              "country_id":400
           },
           {
              "id":1694,
              "name":"Al Mafraq",
              "country_id":400
           },
           {
              "id":1695,
              "name":"Tafielah",
              "country_id":400
           },
           {
              "id":1696,
              "name":"Az Zarqa",
              "country_id":400
           },
           {
              "id":1697,
              "name":"Irbid",
              "country_id":400
           },
           {
              "id":1698,
              "name":"Mafraq",
              "country_id":400
           },
           {
              "id":1699,
              "name":"Amman",
              "country_id":400
           },
           {
              "id":1700,
              "name":"Zarqa",
              "country_id":400
           },
           {
              "id":1701,
              "name":"Irbid",
              "country_id":400
           },
           {
              "id":1702,
              "name":"Ma’an",
              "country_id":400
           },
           {
              "id":1703,
              "name":"Ajlun",
              "country_id":400
           },
           {
              "id":1704,
              "name":"Aqaba",
              "country_id":400
           },
           {
              "id":1705,
              "name":"Jerash",
              "country_id":400
           },
           {
              "id":1706,
              "name":"Madaba",
              "country_id":400
           }
        ],
        "404":[
           {
              "id":1707,
              "name":"Central",
              "country_id":404
           },
           {
              "id":1708,
              "name":"Coast",
              "country_id":404
           },
           {
              "id":1709,
              "name":"Eastern",
              "country_id":404
           },
           {
              "id":1710,
              "name":"Nairobi Area",
              "country_id":404
           },
           {
              "id":1711,
              "name":"North-Eastern",
              "country_id":404
           },
           {
              "id":1712,
              "name":"Nyanza",
              "country_id":404
           },
           {
              "id":1713,
              "name":"Rift Valley",
              "country_id":404
           },
           {
              "id":1714,
              "name":"Western",
              "country_id":404
           }
        ],
        "408":[
           {
              "id":1715,
              "name":"Chagang-do",
              "country_id":408
           },
           {
              "id":1716,
              "name":"Hamgyŏng-namdo",
              "country_id":408
           },
           {
              "id":1717,
              "name":"Hwanghae-namdo",
              "country_id":408
           },
           {
              "id":1718,
              "name":"Hwanghae-bukto",
              "country_id":408
           },
           {
              "id":1719,
              "name":"Kaesŏng-si",
              "country_id":408
           },
           {
              "id":1720,
              "name":"Gangwon",
              "country_id":408
           },
           {
              "id":1721,
              "name":"Pʼyŏngan-bukto",
              "country_id":408
           },
           {
              "id":1722,
              "name":"Pʼyŏngyang-si",
              "country_id":408
           },
           {
              "id":1723,
              "name":"Yanggang-do",
              "country_id":408
           },
           {
              "id":1724,
              "name":"Nampʼo-si",
              "country_id":408
           },
           {
              "id":1725,
              "name":"Pʼyŏngan-namdo",
              "country_id":408
           },
           {
              "id":1726,
              "name":"Hamgyŏng-bukto",
              "country_id":408
           },
           {
              "id":1727,
              "name":"Najin Sŏnbong-si",
              "country_id":408
           }
        ],
        "410":[
           {
              "id":1728,
              "name":"Jeju",
              "country_id":410
           },
           {
              "id":1729,
              "name":"North Jeolla",
              "country_id":410
           },
           {
              "id":1730,
              "name":"North Chungcheong",
              "country_id":410
           },
           {
              "id":1731,
              "name":"Gangwon",
              "country_id":410
           },
           {
              "id":1732,
              "name":"Busan",
              "country_id":410
           },
           {
              "id":1733,
              "name":"Seoul",
              "country_id":410
           },
           {
              "id":1734,
              "name":"Incheon",
              "country_id":410
           },
           {
              "id":1735,
              "name":"Gyeonggi",
              "country_id":410
           },
           {
              "id":1736,
              "name":"North Gyeongsang",
              "country_id":410
           },
           {
              "id":1737,
              "name":"Daegu",
              "country_id":410
           },
           {
              "id":1738,
              "name":"South Jeolla",
              "country_id":410
           },
           {
              "id":1739,
              "name":"South Chungcheong",
              "country_id":410
           },
           {
              "id":1740,
              "name":"Gwangju",
              "country_id":410
           },
           {
              "id":1741,
              "name":"Daejeon",
              "country_id":410
           },
           {
              "id":1742,
              "name":"South Gyeongsang",
              "country_id":410
           },
           {
              "id":1743,
              "name":"Ulsan",
              "country_id":410
           }
        ],
        "414":[
           {
              "id":1744,
              "name":"Muḩāfaz̧atalWafrah",
              "country_id":414
           },
           {
              "id":1745,
              "name":"Al ‘Āşimah",
              "country_id":414
           },
           {
              "id":1746,
              "name":"Al Aḩmadī",
              "country_id":414
           },
           {
              "id":1747,
              "name":"Al Jahrāʼ",
              "country_id":414
           },
           {
              "id":1748,
              "name":"Al Farwaniyah",
              "country_id":414
           },
           {
              "id":1749,
              "name":"Ḩawallī",
              "country_id":414
           }
        ],
        "417":[
           {
              "id":1750,
              "name":"Bishkek",
              "country_id":417
           },
           {
              "id":1751,
              "name":"Chüy",
              "country_id":417
           },
           {
              "id":1752,
              "name":"Jalal-Abad",
              "country_id":417
           },
           {
              "id":1753,
              "name":"Naryn",
              "country_id":417
           },
           {
              "id":1754,
              "name":"Talas",
              "country_id":417
           },
           {
              "id":1755,
              "name":"Ysyk-Köl",
              "country_id":417
           },
           {
              "id":1756,
              "name":"Osh",
              "country_id":417
           },
           {
              "id":1757,
              "name":"Batken",
              "country_id":417
           }
        ],
        "418":[
           {
              "id":1758,
              "name":"Attapu",
              "country_id":418
           },
           {
              "id":1759,
              "name":"Champasak",
              "country_id":418
           },
           {
              "id":1760,
              "name":"Houaphan",
              "country_id":418
           },
           {
              "id":1761,
              "name":"Khammouan",
              "country_id":418
           },
           {
              "id":1762,
              "name":"Louang Namtha",
              "country_id":418
           },
           {
              "id":1763,
              "name":"Louangphrabang",
              "country_id":418
           },
           {
              "id":1764,
              "name":"Oudômxai",
              "country_id":418
           },
           {
              "id":1765,
              "name":"Phongsali",
              "country_id":418
           },
           {
              "id":1766,
              "name":"Saravan",
              "country_id":418
           },
           {
              "id":1767,
              "name":"Savannakhet",
              "country_id":418
           },
           {
              "id":1768,
              "name":"Vientiane",
              "country_id":418
           },
           {
              "id":1769,
              "name":"Xiagnabouli",
              "country_id":418
           },
           {
              "id":1770,
              "name":"Xiangkhoang",
              "country_id":418
           },
           {
              "id":1771,
              "name":"Khammouan",
              "country_id":418
           },
           {
              "id":1772,
              "name":"Loungnamtha",
              "country_id":418
           },
           {
              "id":1773,
              "name":"Louangphabang",
              "country_id":418
           },
           {
              "id":1774,
              "name":"Phôngsali",
              "country_id":418
           },
           {
              "id":1775,
              "name":"Salavan",
              "country_id":418
           },
           {
              "id":1776,
              "name":"Savannahkhét",
              "country_id":418
           },
           {
              "id":1777,
              "name":"Bokèo",
              "country_id":418
           },
           {
              "id":1778,
              "name":"Bolikhamxai",
              "country_id":418
           },
           {
              "id":1779,
              "name":"Viangchan",
              "country_id":418
           },
           {
              "id":1780,
              "name":"Xékong",
              "country_id":418
           },
           {
              "id":1781,
              "name":"Viangchan",
              "country_id":418
           }
        ],
        "422":[
           {
              "id":1782,
              "name":"Béqaa",
              "country_id":422
           },
           {
              "id":1783,
              "name":"Liban-Nord",
              "country_id":422
           },
           {
              "id":1784,
              "name":"Beyrouth",
              "country_id":422
           },
           {
              "id":1785,
              "name":"Mont-Liban",
              "country_id":422
           },
           {
              "id":1786,
              "name":"Liban-Sud",
              "country_id":422
           },
           {
              "id":1787,
              "name":"Nabatîyé",
              "country_id":422
           },
           {
              "id":1788,
              "name":"Béqaa",
              "country_id":422
           },
           {
              "id":1789,
              "name":"Liban-Nord",
              "country_id":422
           },
           {
              "id":1790,
              "name":"Aakkâr",
              "country_id":422
           },
           {
              "id":1791,
              "name":"Baalbek-Hermel",
              "country_id":422
           }
        ],
        "426":[
           {
              "id":1792,
              "name":"Balzers Commune",
              "country_id":426
           },
           {
              "id":1793,
              "name":"Eschen Commune",
              "country_id":426
           },
           {
              "id":1794,
              "name":"Gamprin Commune",
              "country_id":426
           },
           {
              "id":1795,
              "name":"Mauren Commune",
              "country_id":426
           },
           {
              "id":1796,
              "name":"Planken Commune",
              "country_id":426
           },
           {
              "id":1797,
              "name":"Ruggell Commune",
              "country_id":426
           },
           {
              "id":1798,
              "name":"Berea District",
              "country_id":426
           },
           {
              "id":1799,
              "name":"Butha-Buthe District",
              "country_id":426
           },
           {
              "id":1800,
              "name":"Leribe District",
              "country_id":426
           },
           {
              "id":1801,
              "name":"Mafeteng",
              "country_id":426
           },
           {
              "id":1802,
              "name":"Maseru",
              "country_id":426
           },
           {
              "id":1803,
              "name":"Mohaleʼs Hoek",
              "country_id":426
           },
           {
              "id":1804,
              "name":"Mokhotlong",
              "country_id":426
           },
           {
              "id":1805,
              "name":"Qachaʼs Nek",
              "country_id":426
           },
           {
              "id":1806,
              "name":"Quthing District",
              "country_id":426
           },
           {
              "id":1807,
              "name":"Thaba-Tseka District",
              "country_id":426
           }
        ],
        "428":[
           {
              "id":1808,
              "name":"Dobeles Rajons",
              "country_id":428
           },
           {
              "id":1809,
              "name":"Aizkraukles Rajons",
              "country_id":428
           },
           {
              "id":1810,
              "name":"Alūksnes Rajons",
              "country_id":428
           },
           {
              "id":1811,
              "name":"Balvu Rajons",
              "country_id":428
           },
           {
              "id":1812,
              "name":"Bauskas Rajons",
              "country_id":428
           },
           {
              "id":1813,
              "name":"Cēsu Rajons",
              "country_id":428
           },
           {
              "id":1814,
              "name":"Daugavpils",
              "country_id":428
           },
           {
              "id":1815,
              "name":"Daugavpils Rajons",
              "country_id":428
           },
           {
              "id":1816,
              "name":"Dobeles Rajons",
              "country_id":428
           },
           {
              "id":1817,
              "name":"Gulbenes Rajons",
              "country_id":428
           },
           {
              "id":1818,
              "name":"Jēkabpils Rajons",
              "country_id":428
           },
           {
              "id":1819,
              "name":"Jelgava",
              "country_id":428
           },
           {
              "id":1820,
              "name":"Jelgavas Rajons",
              "country_id":428
           },
           {
              "id":1821,
              "name":"Jūrmala",
              "country_id":428
           },
           {
              "id":1822,
              "name":"Krāslavas Rajons",
              "country_id":428
           },
           {
              "id":1823,
              "name":"Kuldīgas Rajons",
              "country_id":428
           },
           {
              "id":1824,
              "name":"Liepāja",
              "country_id":428
           },
           {
              "id":1825,
              "name":"Liepājas Rajons",
              "country_id":428
           },
           {
              "id":1826,
              "name":"Limbažu Rajons",
              "country_id":428
           },
           {
              "id":1827,
              "name":"Ludzas Rajons",
              "country_id":428
           },
           {
              "id":1828,
              "name":"Madonas Rajons",
              "country_id":428
           },
           {
              "id":1829,
              "name":"Ogres Rajons",
              "country_id":428
           },
           {
              "id":1830,
              "name":"Preiļu Rajons",
              "country_id":428
           },
           {
              "id":1831,
              "name":"Rēzekne",
              "country_id":428
           },
           {
              "id":1832,
              "name":"Rēzeknes Rajons",
              "country_id":428
           },
           {
              "id":1833,
              "name":"Rīga",
              "country_id":428
           },
           {
              "id":1834,
              "name":"Rīgas Rajons",
              "country_id":428
           },
           {
              "id":1835,
              "name":"Saldus Rajons",
              "country_id":428
           },
           {
              "id":1836,
              "name":"Talsu Rajons",
              "country_id":428
           },
           {
              "id":1837,
              "name":"Tukuma Rajons",
              "country_id":428
           },
           {
              "id":1838,
              "name":"Valkas Rajons",
              "country_id":428
           },
           {
              "id":1839,
              "name":"Valmieras Rajons",
              "country_id":428
           },
           {
              "id":1840,
              "name":"Ventspils",
              "country_id":428
           },
           {
              "id":1841,
              "name":"Ventspils Rajons",
              "country_id":428
           }
        ],
        "430":[
           {
              "id":1842,
              "name":"Bong",
              "country_id":430
           },
           {
              "id":1843,
              "name":"Grand Jide",
              "country_id":430
           },
           {
              "id":1844,
              "name":"Grand Cape Mount",
              "country_id":430
           },
           {
              "id":1845,
              "name":"Lofa",
              "country_id":430
           },
           {
              "id":1846,
              "name":"Nimba",
              "country_id":430
           },
           {
              "id":1847,
              "name":"Sinoe",
              "country_id":430
           },
           {
              "id":1848,
              "name":"Grand Bassa County",
              "country_id":430
           },
           {
              "id":1849,
              "name":"Grand Cape Mount",
              "country_id":430
           },
           {
              "id":1850,
              "name":"Maryland",
              "country_id":430
           },
           {
              "id":1851,
              "name":"Montserrado",
              "country_id":430
           },
           {
              "id":1852,
              "name":"Bomi",
              "country_id":430
           },
           {
              "id":1853,
              "name":"Grand Kru",
              "country_id":430
           },
           {
              "id":1854,
              "name":"Margibi",
              "country_id":430
           },
           {
              "id":1855,
              "name":"River Cess",
              "country_id":430
           },
           {
              "id":1856,
              "name":"Grand Gedeh",
              "country_id":430
           },
           {
              "id":1857,
              "name":"Lofa",
              "country_id":430
           },
           {
              "id":1858,
              "name":"Gbarpolu",
              "country_id":430
           },
           {
              "id":1859,
              "name":"River Gee",
              "country_id":430
           }
        ],
        "434":[
           {
              "id":1860,
              "name":"Al Abyār",
              "country_id":434
           },
           {
              "id":1861,
              "name":"Al ‘Azīzīyah",
              "country_id":434
           },
           {
              "id":1862,
              "name":"Al Bayḑā’",
              "country_id":434
           },
           {
              "id":1863,
              "name":"Al Jufrah",
              "country_id":434
           },
           {
              "id":1864,
              "name":"Al Jumayl",
              "country_id":434
           },
           {
              "id":1865,
              "name":"Al Kufrah",
              "country_id":434
           },
           {
              "id":1866,
              "name":"Al Marj",
              "country_id":434
           },
           {
              "id":1867,
              "name":"Al Qarābūll",
              "country_id":434
           },
           {
              "id":1868,
              "name":"Al Qubbah",
              "country_id":434
           },
           {
              "id":1869,
              "name":"Al Ajaylāt",
              "country_id":434
           },
           {
              "id":1870,
              "name":"Ash Shāţiʼ",
              "country_id":434
           },
           {
              "id":1871,
              "name":"Az Zahra’",
              "country_id":434
           },
           {
              "id":1872,
              "name":"Banī Walīd",
              "country_id":434
           },
           {
              "id":1873,
              "name":"Bin Jawwād",
              "country_id":434
           },
           {
              "id":1874,
              "name":"Ghāt",
              "country_id":434
           },
           {
              "id":1875,
              "name":"Jādū",
              "country_id":434
           },
           {
              "id":1876,
              "name":"Jālū",
              "country_id":434
           },
           {
              "id":1877,
              "name":"Janzūr",
              "country_id":434
           },
           {
              "id":1878,
              "name":"Masallatah",
              "country_id":434
           },
           {
              "id":1879,
              "name":"Mizdah",
              "country_id":434
           },
           {
              "id":1880,
              "name":"Murzuq",
              "country_id":434
           },
           {
              "id":1881,
              "name":"Nālūt",
              "country_id":434
           },
           {
              "id":1882,
              "name":"Qamīnis",
              "country_id":434
           },
           {
              "id":1883,
              "name":"Qaşr Bin Ghashīr",
              "country_id":434
           },
           {
              "id":1884,
              "name":"Sabhā",
              "country_id":434
           },
           {
              "id":1885,
              "name":"Şabrātah",
              "country_id":434
           },
           {
              "id":1886,
              "name":"Shaḩḩāt",
              "country_id":434
           },
           {
              "id":1887,
              "name":"Şurmān",
              "country_id":434
           },
           {
              "id":1888,
              "name":"Tajura’ ",
              "country_id":434
           },
           {
              "id":1889,
              "name":"Tarhūnah",
              "country_id":434
           },
           {
              "id":1890,
              "name":"Ţubruq",
              "country_id":434
           },
           {
              "id":1891,
              "name":"Tūkrah",
              "country_id":434
           },
           {
              "id":1892,
              "name":"Zlīţan",
              "country_id":434
           },
           {
              "id":1893,
              "name":"Zuwārah",
              "country_id":434
           },
           {
              "id":1894,
              "name":"Ajdābiyā",
              "country_id":434
           },
           {
              "id":1895,
              "name":"Al Fātiḩ",
              "country_id":434
           },
           {
              "id":1896,
              "name":"Al Jabal al Akhḑar",
              "country_id":434
           },
           {
              "id":1897,
              "name":"Al Khums",
              "country_id":434
           },
           {
              "id":1898,
              "name":"An Nuqāţ al Khams",
              "country_id":434
           },
           {
              "id":1899,
              "name":"Awbārī",
              "country_id":434
           },
           {
              "id":1900,
              "name":"Az Zāwiyah",
              "country_id":434
           },
           {
              "id":1901,
              "name":"Banghāzī",
              "country_id":434
           },
           {
              "id":1902,
              "name":"Darnah",
              "country_id":434
           },
           {
              "id":1903,
              "name":"Ghadāmis",
              "country_id":434
           },
           {
              "id":1904,
              "name":"Gharyān",
              "country_id":434
           },
           {
              "id":1905,
              "name":"Mişrātah",
              "country_id":434
           },
           {
              "id":1906,
              "name":"Sawfajjīn",
              "country_id":434
           },
           {
              "id":1907,
              "name":"Surt",
              "country_id":434
           },
           {
              "id":1908,
              "name":"Ţarābulus",
              "country_id":434
           },
           {
              "id":1909,
              "name":"Yafran",
              "country_id":434
           }
        ],
        "438":[
           {
              "id":1910,
              "name":"Balzers",
              "country_id":438
           },
           {
              "id":1911,
              "name":"Eschen",
              "country_id":438
           },
           {
              "id":1912,
              "name":"Gamprin",
              "country_id":438
           },
           {
              "id":1913,
              "name":"Mauren",
              "country_id":438
           },
           {
              "id":1914,
              "name":"Planken",
              "country_id":438
           },
           {
              "id":1915,
              "name":"Ruggell",
              "country_id":438
           },
           {
              "id":1916,
              "name":"Schaan",
              "country_id":438
           },
           {
              "id":1917,
              "name":"Schellenberg",
              "country_id":438
           },
           {
              "id":1918,
              "name":"Triesen",
              "country_id":438
           },
           {
              "id":1919,
              "name":"Triesenberg",
              "country_id":438
           },
           {
              "id":1920,
              "name":"Vaduz",
              "country_id":438
           }
        ],
        "440":[
           {
              "id":1921,
              "name":"Alytaus Apskritis",
              "country_id":440
           },
           {
              "id":1922,
              "name":"Kauno Apskritis",
              "country_id":440
           },
           {
              "id":1923,
              "name":"Klaipėdos Apskritis",
              "country_id":440
           },
           {
              "id":1924,
              "name":"Marijampolės Apskritis",
              "country_id":440
           },
           {
              "id":1925,
              "name":"Panevėžio Apskritis",
              "country_id":440
           },
           {
              "id":1926,
              "name":"Šiaulių Apskritis",
              "country_id":440
           },
           {
              "id":1927,
              "name":"Tauragės Apskritis",
              "country_id":440
           },
           {
              "id":1928,
              "name":"Telšių Apskritis",
              "country_id":440
           },
           {
              "id":1929,
              "name":"Utenos Apskritis",
              "country_id":440
           },
           {
              "id":1930,
              "name":"Vilniaus Apskritis",
              "country_id":440
           }
        ],
        "442":[
           {
              "id":1931,
              "name":"Diekirch",
              "country_id":442
           },
           {
              "id":1932,
              "name":"Grevenmacher",
              "country_id":442
           },
           {
              "id":1933,
              "name":"Luxembourg",
              "country_id":442
           }
        ],
        "446":[
           {
              "id":1934,
              "name":"Ilhas",
              "country_id":446
           },
           {
              "id":1935,
              "name":"Macau",
              "country_id":446
           }
        ],
        "450":[
           {
              "id":1936,
              "name":"Antsiranana",
              "country_id":450
           },
           {
              "id":1937,
              "name":"Fianarantsoa",
              "country_id":450
           },
           {
              "id":1938,
              "name":"Mahajanga",
              "country_id":450
           },
           {
              "id":1939,
              "name":"Toamasina",
              "country_id":450
           },
           {
              "id":1940,
              "name":"Antananarivo",
              "country_id":450
           },
           {
              "id":1941,
              "name":"Toliara",
              "country_id":450
           }
        ],
        "454":[
           {
              "id":1942,
              "name":"Chikwawa",
              "country_id":454
           },
           {
              "id":1943,
              "name":"Chiradzulu",
              "country_id":454
           },
           {
              "id":1944,
              "name":"Chitipa",
              "country_id":454
           },
           {
              "id":1945,
              "name":"Thyolo",
              "country_id":454
           },
           {
              "id":1946,
              "name":"Dedza",
              "country_id":454
           },
           {
              "id":1947,
              "name":"Dowa",
              "country_id":454
           },
           {
              "id":1948,
              "name":"Karonga",
              "country_id":454
           },
           {
              "id":1949,
              "name":"Kasungu",
              "country_id":454
           },
           {
              "id":1950,
              "name":"Lilongwe",
              "country_id":454
           },
           {
              "id":1951,
              "name":"Mangochi",
              "country_id":454
           },
           {
              "id":1952,
              "name":"Mchinji",
              "country_id":454
           },
           {
              "id":1953,
              "name":"Mzimba",
              "country_id":454
           },
           {
              "id":1954,
              "name":"Ntcheu",
              "country_id":454
           },
           {
              "id":1955,
              "name":"Nkhata Bay",
              "country_id":454
           },
           {
              "id":1956,
              "name":"Nkhotakota",
              "country_id":454
           },
           {
              "id":1957,
              "name":"Nsanje",
              "country_id":454
           },
           {
              "id":1958,
              "name":"Ntchisi",
              "country_id":454
           },
           {
              "id":1959,
              "name":"Rumphi",
              "country_id":454
           },
           {
              "id":1960,
              "name":"Salima",
              "country_id":454
           },
           {
              "id":1961,
              "name":"Zomba",
              "country_id":454
           },
           {
              "id":1962,
              "name":"Blantyre",
              "country_id":454
           },
           {
              "id":1963,
              "name":"Mwanza",
              "country_id":454
           },
           {
              "id":1964,
              "name":"Balaka",
              "country_id":454
           },
           {
              "id":1965,
              "name":"Likoma",
              "country_id":454
           },
           {
              "id":1966,
              "name":"Machinga",
              "country_id":454
           },
           {
              "id":1967,
              "name":"Mulanje",
              "country_id":454
           },
           {
              "id":1968,
              "name":"Phalombe",
              "country_id":454
           }
        ],
        "458":[
           {
              "id":1969,
              "name":"Johor",
              "country_id":458
           },
           {
              "id":1970,
              "name":"Kedah",
              "country_id":458
           },
           {
              "id":1971,
              "name":"Kelantan",
              "country_id":458
           },
           {
              "id":1972,
              "name":"Melaka",
              "country_id":458
           },
           {
              "id":1973,
              "name":"Negeri Sembilan",
              "country_id":458
           },
           {
              "id":1974,
              "name":"Pahang",
              "country_id":458
           },
           {
              "id":1975,
              "name":"Perak",
              "country_id":458
           },
           {
              "id":1976,
              "name":"Perlis",
              "country_id":458
           },
           {
              "id":1977,
              "name":"Pulau Pinang",
              "country_id":458
           },
           {
              "id":1978,
              "name":"Sarawak",
              "country_id":458
           },
           {
              "id":1979,
              "name":"Selangor",
              "country_id":458
           },
           {
              "id":1980,
              "name":"Terengganu",
              "country_id":458
           },
           {
              "id":1981,
              "name":"Kuala Lumpur",
              "country_id":458
           },
           {
              "id":1982,
              "name":"Federal Territory of Labuan",
              "country_id":458
           },
           {
              "id":1983,
              "name":"Sabah",
              "country_id":458
           },
           {
              "id":1984,
              "name":"Putrajaya",
              "country_id":458
           }
        ],
        "462":[
           {
              "id":1985,
              "name":"Maale",
              "country_id":462
           },
           {
              "id":1986,
              "name":"Seenu",
              "country_id":462
           },
           {
              "id":1987,
              "name":"Alifu Atholhu",
              "country_id":462
           },
           {
              "id":1988,
              "name":"Lhaviyani Atholhu",
              "country_id":462
           },
           {
              "id":1989,
              "name":"Vaavu Atholhu",
              "country_id":462
           },
           {
              "id":1990,
              "name":"Laamu",
              "country_id":462
           },
           {
              "id":1991,
              "name":"Haa Alifu Atholhu",
              "country_id":462
           },
           {
              "id":1992,
              "name":"Thaa Atholhu",
              "country_id":462
           },
           {
              "id":1993,
              "name":"Meemu Atholhu",
              "country_id":462
           },
           {
              "id":1994,
              "name":"Raa Atholhu",
              "country_id":462
           },
           {
              "id":1995,
              "name":"Faafu Atholhu",
              "country_id":462
           },
           {
              "id":1996,
              "name":"Dhaalu Atholhu",
              "country_id":462
           },
           {
              "id":1997,
              "name":"Baa Atholhu",
              "country_id":462
           },
           {
              "id":1998,
              "name":"Haa Dhaalu Atholhu",
              "country_id":462
           },
           {
              "id":1999,
              "name":"Shaviyani Atholhu",
              "country_id":462
           },
           {
              "id":2000,
              "name":"Noonu Atholhu",
              "country_id":462
           },
           {
              "id":2001,
              "name":"Kaafu Atholhu",
              "country_id":462
           },
           {
              "id":2002,
              "name":"Gaafu Alifu Atholhu",
              "country_id":462
           },
           {
              "id":2003,
              "name":"Gaafu Dhaalu Atholhu",
              "country_id":462
           },
           {
              "id":2004,
              "name":"Gnyaviyani Atoll",
              "country_id":462
           },
           {
              "id":2005,
              "name":"Alifu",
              "country_id":462
           },
           {
              "id":2006,
              "name":"Baa",
              "country_id":462
           },
           {
              "id":2007,
              "name":"Dhaalu",
              "country_id":462
           },
           {
              "id":2008,
              "name":"Faafu",
              "country_id":462
           },
           {
              "id":2009,
              "name":"Gaafu Alifu",
              "country_id":462
           },
           {
              "id":2010,
              "name":"Gaafu Dhaalu",
              "country_id":462
           },
           {
              "id":2011,
              "name":"Haa Alifu",
              "country_id":462
           },
           {
              "id":2012,
              "name":"Haa Dhaalu",
              "country_id":462
           },
           {
              "id":2013,
              "name":"Kaafu",
              "country_id":462
           },
           {
              "id":2014,
              "name":"Lhaviyani",
              "country_id":462
           },
           {
              "id":2015,
              "name":"Maale",
              "country_id":462
           },
           {
              "id":2016,
              "name":"Meemu",
              "country_id":462
           },
           {
              "id":2017,
              "name":"Gnaviyani",
              "country_id":462
           },
           {
              "id":2018,
              "name":"Noonu",
              "country_id":462
           },
           {
              "id":2019,
              "name":"Raa",
              "country_id":462
           },
           {
              "id":2020,
              "name":"Shaviyani",
              "country_id":462
           },
           {
              "id":2021,
              "name":"Thaa",
              "country_id":462
           },
           {
              "id":2022,
              "name":"Vaavu",
              "country_id":462
           }
        ],
        "466":[
           {
              "id":2023,
              "name":"Bamako",
              "country_id":466
           },
           {
              "id":2024,
              "name":"Gao",
              "country_id":466
           },
           {
              "id":2025,
              "name":"Kayes",
              "country_id":466
           },
           {
              "id":2026,
              "name":"Mopti",
              "country_id":466
           },
           {
              "id":2027,
              "name":"Ségou",
              "country_id":466
           },
           {
              "id":2028,
              "name":"Sikasso",
              "country_id":466
           },
           {
              "id":2029,
              "name":"Koulikoro",
              "country_id":466
           },
           {
              "id":2030,
              "name":"Tombouctou",
              "country_id":466
           },
           {
              "id":2031,
              "name":"Gao",
              "country_id":466
           },
           {
              "id":2032,
              "name":"Kidal",
              "country_id":466
           }
        ],
        "470":[
           {
              "id":2033,
              "name":"Malta",
              "country_id":470
           }
        ],
        "474":[
           {
              "id":2034,
              "name":"Martinique",
              "country_id":474
           }
        ],
        "478":[
           {
              "id":2035,
              "name":"Nouakchott",
              "country_id":478
           },
           {
              "id":2036,
              "name":"Hodh Ech Chargui",
              "country_id":478
           },
           {
              "id":2037,
              "name":"Hodh El Gharbi",
              "country_id":478
           },
           {
              "id":2038,
              "name":"Assaba",
              "country_id":478
           },
           {
              "id":2039,
              "name":"Gorgol",
              "country_id":478
           },
           {
              "id":2040,
              "name":"Brakna",
              "country_id":478
           },
           {
              "id":2041,
              "name":"Trarza",
              "country_id":478
           },
           {
              "id":2042,
              "name":"Adrar",
              "country_id":478
           },
           {
              "id":2043,
              "name":"Dakhlet Nouadhibou",
              "country_id":478
           },
           {
              "id":2044,
              "name":"Tagant",
              "country_id":478
           },
           {
              "id":2045,
              "name":"Guidimaka",
              "country_id":478
           },
           {
              "id":2046,
              "name":"Tiris Zemmour",
              "country_id":478
           },
           {
              "id":2047,
              "name":"Inchiri",
              "country_id":478
           }
        ],
        "480":[
           {
              "id":2048,
              "name":"Black River",
              "country_id":480
           },
           {
              "id":2049,
              "name":"Flacq",
              "country_id":480
           },
           {
              "id":2050,
              "name":"Grand Port",
              "country_id":480
           },
           {
              "id":2051,
              "name":"Moka",
              "country_id":480
           },
           {
              "id":2052,
              "name":"Pamplemousses",
              "country_id":480
           },
           {
              "id":2053,
              "name":"Plaines Wilhems",
              "country_id":480
           },
           {
              "id":2054,
              "name":"Port Louis",
              "country_id":480
           },
           {
              "id":2055,
              "name":"Rivière du Rempart",
              "country_id":480
           },
           {
              "id":2056,
              "name":"Savanne",
              "country_id":480
           },
           {
              "id":2057,
              "name":"Agalega Islands",
              "country_id":480
           },
           {
              "id":2058,
              "name":"Cargados Carajos",
              "country_id":480
           },
           {
              "id":2059,
              "name":"Rodrigues",
              "country_id":480
           }
        ],
        "484":[
           {
              "id":2060,
              "name":"Aguascalientes",
              "country_id":484
           },
           {
              "id":2061,
              "name":"Baja California",
              "country_id":484
           },
           {
              "id":2062,
              "name":"Baja California Sur",
              "country_id":484
           },
           {
              "id":2063,
              "name":"Campeche",
              "country_id":484
           },
           {
              "id":2064,
              "name":"Chiapas",
              "country_id":484
           },
           {
              "id":2065,
              "name":"Chihuahua",
              "country_id":484
           },
           {
              "id":2066,
              "name":"Coahuila",
              "country_id":484
           },
           {
              "id":2067,
              "name":"Colima",
              "country_id":484
           },
           {
              "id":2068,
              "name":"The Federal District",
              "country_id":484
           },
           {
              "id":2069,
              "name":"Durango",
              "country_id":484
           },
           {
              "id":2070,
              "name":"Guanajuato",
              "country_id":484
           },
           {
              "id":2071,
              "name":"Guerrero",
              "country_id":484
           },
           {
              "id":2072,
              "name":"Hidalgo",
              "country_id":484
           },
           {
              "id":2073,
              "name":"Jalisco",
              "country_id":484
           },
           {
              "id":2074,
              "name":"México",
              "country_id":484
           },
           {
              "id":2075,
              "name":"Michoacán",
              "country_id":484
           },
           {
              "id":2076,
              "name":"Morelos",
              "country_id":484
           },
           {
              "id":2077,
              "name":"Nayarit",
              "country_id":484
           },
           {
              "id":2078,
              "name":"Nuevo León",
              "country_id":484
           },
           {
              "id":2079,
              "name":"Oaxaca",
              "country_id":484
           },
           {
              "id":2080,
              "name":"Puebla",
              "country_id":484
           },
           {
              "id":2081,
              "name":"Querétaro",
              "country_id":484
           },
           {
              "id":2082,
              "name":"Quintana Roo",
              "country_id":484
           },
           {
              "id":2083,
              "name":"San Luis Potosí",
              "country_id":484
           },
           {
              "id":2084,
              "name":"Sinaloa",
              "country_id":484
           },
           {
              "id":2085,
              "name":"Sonora",
              "country_id":484
           },
           {
              "id":2086,
              "name":"Tabasco",
              "country_id":484
           },
           {
              "id":2087,
              "name":"Tamaulipas",
              "country_id":484
           },
           {
              "id":2088,
              "name":"Tlaxcala",
              "country_id":484
           },
           {
              "id":2089,
              "name":"Veracruz-Llave",
              "country_id":484
           },
           {
              "id":2090,
              "name":"Yucatán",
              "country_id":484
           },
           {
              "id":2091,
              "name":"Zacatecas",
              "country_id":484
           }
        ],
        "492":[
           {
              "id":2092,
              "name":"Monaco",
              "country_id":492
           }
        ],
        "496":[
           {
              "id":2093,
              "name":"Arhangay",
              "country_id":496
           },
           {
              "id":2094,
              "name":"Bayanhongor",
              "country_id":496
           },
           {
              "id":2095,
              "name":"Bayan-Ölgiy",
              "country_id":496
           },
           {
              "id":2096,
              "name":"East Aimak",
              "country_id":496
           },
           {
              "id":2097,
              "name":"East Gobi Aymag",
              "country_id":496
           },
           {
              "id":2098,
              "name":"Middle Govĭ",
              "country_id":496
           },
           {
              "id":2099,
              "name":"Dzavhan",
              "country_id":496
           },
           {
              "id":2100,
              "name":"Govĭ-Altay",
              "country_id":496
           },
           {
              "id":2101,
              "name":"Hentiy",
              "country_id":496
           },
           {
              "id":2102,
              "name":"Hovd",
              "country_id":496
           },
           {
              "id":2103,
              "name":"Hövsgöl",
              "country_id":496
           },
           {
              "id":2104,
              "name":"South Gobi Aimak",
              "country_id":496
           },
           {
              "id":2105,
              "name":"South Hangay",
              "country_id":496
           },
           {
              "id":2106,
              "name":"Selenge",
              "country_id":496
           },
           {
              "id":2107,
              "name":"Sühbaatar",
              "country_id":496
           },
           {
              "id":2108,
              "name":"Central Aimak",
              "country_id":496
           },
           {
              "id":2109,
              "name":"Uvs",
              "country_id":496
           },
           {
              "id":2110,
              "name":"Ulaanbaatar",
              "country_id":496
           },
           {
              "id":2111,
              "name":"Bulgan",
              "country_id":496
           },
           {
              "id":2112,
              "name":"Darhan Uul",
              "country_id":496
           },
           {
              "id":2113,
              "name":"Govĭ-Sumber",
              "country_id":496
           },
           {
              "id":2114,
              "name":"Orhon",
              "country_id":496
           }
        ],
        "498":[
           {
              "id":2115,
              "name":"Ungheni Judetul",
              "country_id":498
           },
           {
              "id":2116,
              "name":"Balti",
              "country_id":498
           },
           {
              "id":2117,
              "name":"Cahul",
              "country_id":498
           },
           {
              "id":2118,
              "name":"Stinga Nistrului",
              "country_id":498
           },
           {
              "id":2119,
              "name":"Edinet",
              "country_id":498
           },
           {
              "id":2120,
              "name":"Găgăuzia",
              "country_id":498
           },
           {
              "id":2121,
              "name":"Lapusna",
              "country_id":498
           },
           {
              "id":2122,
              "name":"Orhei",
              "country_id":498
           },
           {
              "id":2123,
              "name":"Soroca",
              "country_id":498
           },
           {
              "id":2124,
              "name":"Tighina",
              "country_id":498
           },
           {
              "id":2125,
              "name":"Ungheni",
              "country_id":498
           },
           {
              "id":2126,
              "name":"Chişinău",
              "country_id":498
           },
           {
              "id":2127,
              "name":"Stînga Nistrului",
              "country_id":498
           },
           {
              "id":2128,
              "name":"Raionul Anenii Noi",
              "country_id":498
           },
           {
              "id":2129,
              "name":"Bălţi",
              "country_id":498
           },
           {
              "id":2130,
              "name":"Raionul Basarabeasca",
              "country_id":498
           },
           {
              "id":2131,
              "name":"Bender",
              "country_id":498
           },
           {
              "id":2132,
              "name":"Raionul Briceni",
              "country_id":498
           },
           {
              "id":2133,
              "name":"Raionul Cahul",
              "country_id":498
           },
           {
              "id":2134,
              "name":"Raionul Cantemir",
              "country_id":498
           },
           {
              "id":2135,
              "name":"Călăraşi",
              "country_id":498
           },
           {
              "id":2136,
              "name":"Căuşeni",
              "country_id":498
           },
           {
              "id":2137,
              "name":"Raionul Cimişlia",
              "country_id":498
           },
           {
              "id":2138,
              "name":"Raionul Criuleni",
              "country_id":498
           },
           {
              "id":2139,
              "name":"Raionul Donduşeni",
              "country_id":498
           },
           {
              "id":2140,
              "name":"Raionul Drochia",
              "country_id":498
           },
           {
              "id":2141,
              "name":"Dubăsari",
              "country_id":498
           },
           {
              "id":2142,
              "name":"Raionul Edineţ",
              "country_id":498
           },
           {
              "id":2143,
              "name":"Raionul Făleşti",
              "country_id":498
           },
           {
              "id":2144,
              "name":"Raionul Floreşti",
              "country_id":498
           },
           {
              "id":2145,
              "name":"Raionul Glodeni",
              "country_id":498
           },
           {
              "id":2146,
              "name":"Raionul Hînceşti",
              "country_id":498
           },
           {
              "id":2147,
              "name":"Raionul Ialoveni",
              "country_id":498
           },
           {
              "id":2148,
              "name":"Raionul Leova",
              "country_id":498
           },
           {
              "id":2149,
              "name":"Raionul Nisporeni",
              "country_id":498
           },
           {
              "id":2150,
              "name":"Raionul Ocniţa",
              "country_id":498
           },
           {
              "id":2151,
              "name":"Raionul Orhei",
              "country_id":498
           },
           {
              "id":2152,
              "name":"Raionul Rezina",
              "country_id":498
           },
           {
              "id":2153,
              "name":"Raionul Rîşcani",
              "country_id":498
           },
           {
              "id":2154,
              "name":"Raionul Sîngerei",
              "country_id":498
           },
           {
              "id":2155,
              "name":"Raionul Şoldăneşti",
              "country_id":498
           },
           {
              "id":2156,
              "name":"Raionul Soroca",
              "country_id":498
           },
           {
              "id":2157,
              "name":"Ştefan-Vodă",
              "country_id":498
           },
           {
              "id":2158,
              "name":"Raionul Străşeni",
              "country_id":498
           },
           {
              "id":2159,
              "name":"Raionul Taraclia",
              "country_id":498
           },
           {
              "id":2160,
              "name":"Raionul Teleneşti",
              "country_id":498
           },
           {
              "id":2161,
              "name":"Raionul Ungheni",
              "country_id":498
           }
        ],
        "499":[
           {
              "id":2162,
              "name":"Opština Andrijevica",
              "country_id":499
           },
           {
              "id":2163,
              "name":"Opština Bar",
              "country_id":499
           },
           {
              "id":2164,
              "name":"Opština Berane",
              "country_id":499
           },
           {
              "id":2165,
              "name":"Opština Bijelo Polje",
              "country_id":499
           },
           {
              "id":2166,
              "name":"Opština Budva",
              "country_id":499
           },
           {
              "id":2167,
              "name":"Opština Cetinje",
              "country_id":499
           },
           {
              "id":2168,
              "name":"Opština Danilovgrad",
              "country_id":499
           },
           {
              "id":2169,
              "name":"Opština Herceg Novi",
              "country_id":499
           },
           {
              "id":2170,
              "name":"Opština Kolašin",
              "country_id":499
           },
           {
              "id":2171,
              "name":"Opština Kotor",
              "country_id":499
           },
           {
              "id":2172,
              "name":"Opština Mojkovac",
              "country_id":499
           },
           {
              "id":2173,
              "name":"Opština Nikšić",
              "country_id":499
           },
           {
              "id":2174,
              "name":"Opština Plav",
              "country_id":499
           },
           {
              "id":2175,
              "name":"Opština Pljevlja",
              "country_id":499
           },
           {
              "id":2176,
              "name":"Opština Plužine",
              "country_id":499
           },
           {
              "id":2177,
              "name":"Opština Podgorica",
              "country_id":499
           },
           {
              "id":2178,
              "name":"Opština Rožaje",
              "country_id":499
           },
           {
              "id":2179,
              "name":"Opština Šavnik",
              "country_id":499
           },
           {
              "id":2180,
              "name":"Opština Tivat",
              "country_id":499
           },
           {
              "id":2181,
              "name":"Opština Ulcinj",
              "country_id":499
           },
           {
              "id":2182,
              "name":"Opština Žabljak",
              "country_id":499
           }
        ],
        "500":[
           {
              "id":2183,
              "name":"Saint Anthony",
              "country_id":500
           },
           {
              "id":2184,
              "name":"Saint Georges",
              "country_id":500
           },
           {
              "id":2185,
              "name":"Saint Peter",
              "country_id":500
           }
        ],
        "504":[
           {
              "id":2186,
              "name":"Agadir",
              "country_id":504
           },
           {
              "id":2187,
              "name":"Al Hoceïma",
              "country_id":504
           },
           {
              "id":2188,
              "name":"Azizal",
              "country_id":504
           },
           {
              "id":2189,
              "name":"Ben Slimane",
              "country_id":504
           },
           {
              "id":2190,
              "name":"Beni Mellal",
              "country_id":504
           },
           {
              "id":2191,
              "name":"Boulemane",
              "country_id":504
           },
           {
              "id":2192,
              "name":"Casablanca",
              "country_id":504
           },
           {
              "id":2193,
              "name":"Chaouen",
              "country_id":504
           },
           {
              "id":2194,
              "name":"El Jadida",
              "country_id":504
           },
           {
              "id":2195,
              "name":"El Kelaa des Srarhna",
              "country_id":504
           },
           {
              "id":2196,
              "name":"Er Rachidia",
              "country_id":504
           },
           {
              "id":2197,
              "name":"Essaouira",
              "country_id":504
           },
           {
              "id":2198,
              "name":"Fes",
              "country_id":504
           },
           {
              "id":2199,
              "name":"Figuig",
              "country_id":504
           },
           {
              "id":2200,
              "name":"Kenitra",
              "country_id":504
           },
           {
              "id":2201,
              "name":"Khemisset",
              "country_id":504
           },
           {
              "id":2202,
              "name":"Khenifra",
              "country_id":504
           },
           {
              "id":2203,
              "name":"Khouribga",
              "country_id":504
           },
           {
              "id":2204,
              "name":"Marrakech",
              "country_id":504
           },
           {
              "id":2205,
              "name":"Meknes",
              "country_id":504
           },
           {
              "id":2206,
              "name":"Nador",
              "country_id":504
           },
           {
              "id":2207,
              "name":"Ouarzazate",
              "country_id":504
           },
           {
              "id":2208,
              "name":"Oujda",
              "country_id":504
           },
           {
              "id":2209,
              "name":"Rabat-Sale",
              "country_id":504
           },
           {
              "id":2210,
              "name":"Safi",
              "country_id":504
           },
           {
              "id":2211,
              "name":"Settat",
              "country_id":504
           },
           {
              "id":2212,
              "name":"Tanger",
              "country_id":504
           },
           {
              "id":2213,
              "name":"Tata",
              "country_id":504
           },
           {
              "id":2214,
              "name":"Taza",
              "country_id":504
           },
           {
              "id":2215,
              "name":"Tiznit",
              "country_id":504
           },
           {
              "id":2216,
              "name":"Guelmim",
              "country_id":504
           },
           {
              "id":2217,
              "name":"Ifrane",
              "country_id":504
           },
           {
              "id":2218,
              "name":"Laayoune",
              "country_id":504
           },
           {
              "id":2219,
              "name":"Tan-Tan",
              "country_id":504
           },
           {
              "id":2220,
              "name":"Taounate",
              "country_id":504
           },
           {
              "id":2221,
              "name":"Sidi Kacem",
              "country_id":504
           },
           {
              "id":2222,
              "name":"Taroudannt",
              "country_id":504
           },
           {
              "id":2223,
              "name":"Tetouan",
              "country_id":504
           },
           {
              "id":2224,
              "name":"Larache",
              "country_id":504
           },
           {
              "id":2225,
              "name":"Grand Casablanca",
              "country_id":504
           },
           {
              "id":2226,
              "name":"Fès-Boulemane",
              "country_id":504
           },
           {
              "id":2227,
              "name":"Marrakech-Tensift-Al Haouz",
              "country_id":504
           },
           {
              "id":2228,
              "name":"Meknès-Tafilalet",
              "country_id":504
           },
           {
              "id":2229,
              "name":"Rabat-Salé-Zemmour-Zaër",
              "country_id":504
           },
           {
              "id":2230,
              "name":"Chaouia-Ouardigha",
              "country_id":504
           },
           {
              "id":2231,
              "name":"Doukkala-Abda",
              "country_id":504
           },
           {
              "id":2232,
              "name":"Gharb-Chrarda-Beni Hssen",
              "country_id":504
           },
           {
              "id":2233,
              "name":"Guelmim-Es Smara",
              "country_id":504
           },
           {
              "id":2234,
              "name":"Oriental",
              "country_id":504
           },
           {
              "id":2235,
              "name":"Souss-Massa-Drâa",
              "country_id":504
           },
           {
              "id":2236,
              "name":"Tadla-Azilal",
              "country_id":504
           },
           {
              "id":2237,
              "name":"Tanger-Tétouan",
              "country_id":504
           },
           {
              "id":2238,
              "name":"Taza-Al Hoceima-Taounate",
              "country_id":504
           },
           {
              "id":2239,
              "name":"Laâyoune-Boujdour-Sakia El Hamra",
              "country_id":504
           }
        ],
        "508":[
           {
              "id":2240,
              "name":"Cabo Delgado",
              "country_id":508
           },
           {
              "id":2241,
              "name":"Gaza",
              "country_id":508
           },
           {
              "id":2242,
              "name":"Inhambane",
              "country_id":508
           },
           {
              "id":2243,
              "name":"Maputo Province",
              "country_id":508
           },
           {
              "id":2244,
              "name":"Sofala",
              "country_id":508
           },
           {
              "id":2245,
              "name":"Nampula",
              "country_id":508
           },
           {
              "id":2246,
              "name":"Niassa",
              "country_id":508
           },
           {
              "id":2247,
              "name":"Tete",
              "country_id":508
           },
           {
              "id":2248,
              "name":"Zambézia",
              "country_id":508
           },
           {
              "id":2249,
              "name":"Manica",
              "country_id":508
           },
           {
              "id":2250,
              "name":"Maputo",
              "country_id":508
           }
        ],
        "512":[
           {
              "id":2251,
              "name":"Ad Dākhilīyah",
              "country_id":512
           },
           {
              "id":2252,
              "name":"Al Bāţinah",
              "country_id":512
           },
           {
              "id":2253,
              "name":"Al Wusţá",
              "country_id":512
           },
           {
              "id":2254,
              "name":"Ash Sharqīyah",
              "country_id":512
           },
           {
              "id":2255,
              "name":"Masqaţ",
              "country_id":512
           },
           {
              "id":2256,
              "name":"Musandam",
              "country_id":512
           },
           {
              "id":2257,
              "name":"Z̧ufār",
              "country_id":512
           },
           {
              "id":2258,
              "name":"Az̧ Z̧āhirah",
              "country_id":512
           },
           {
              "id":2259,
              "name":"Muḩāfaz̧at al Buraymī",
              "country_id":512
           }
        ],
        "516":[
           {
              "id":2260,
              "name":"Bethanien",
              "country_id":516
           },
           {
              "id":2261,
              "name":"Caprivi Oos",
              "country_id":516
           },
           {
              "id":2262,
              "name":"Kaokoland",
              "country_id":516
           },
           {
              "id":2263,
              "name":"Otjiwarongo",
              "country_id":516
           },
           {
              "id":2264,
              "name":"Outjo",
              "country_id":516
           },
           {
              "id":2265,
              "name":"Owambo",
              "country_id":516
           },
           {
              "id":2266,
              "name":"Khomas",
              "country_id":516
           },
           {
              "id":2267,
              "name":"Kavango",
              "country_id":516
           },
           {
              "id":2268,
              "name":"Caprivi",
              "country_id":516
           },
           {
              "id":2269,
              "name":"Erongo",
              "country_id":516
           },
           {
              "id":2270,
              "name":"Hardap",
              "country_id":516
           },
           {
              "id":2271,
              "name":"Karas",
              "country_id":516
           },
           {
              "id":2272,
              "name":"Kunene",
              "country_id":516
           },
           {
              "id":2273,
              "name":"Ohangwena",
              "country_id":516
           },
           {
              "id":2274,
              "name":"Okavango",
              "country_id":516
           },
           {
              "id":2275,
              "name":"Omaheke",
              "country_id":516
           },
           {
              "id":2276,
              "name":"Omusati",
              "country_id":516
           },
           {
              "id":2277,
              "name":"Oshana",
              "country_id":516
           },
           {
              "id":2278,
              "name":"Oshikoto",
              "country_id":516
           },
           {
              "id":2279,
              "name":"Otjozondjupa",
              "country_id":516
           }
        ],
        "520":[
           {
              "id":2280,
              "name":"Aiwo",
              "country_id":520
           },
           {
              "id":2281,
              "name":"Anabar",
              "country_id":520
           },
           {
              "id":2282,
              "name":"Anetan",
              "country_id":520
           },
           {
              "id":2283,
              "name":"Anibare",
              "country_id":520
           },
           {
              "id":2284,
              "name":"Baiti",
              "country_id":520
           },
           {
              "id":2285,
              "name":"Boe",
              "country_id":520
           },
           {
              "id":2286,
              "name":"Buada",
              "country_id":520
           },
           {
              "id":2287,
              "name":"Denigomodu",
              "country_id":520
           },
           {
              "id":2288,
              "name":"Ewa",
              "country_id":520
           },
           {
              "id":2289,
              "name":"Ijuw",
              "country_id":520
           },
           {
              "id":2290,
              "name":"Meneng",
              "country_id":520
           },
           {
              "id":2291,
              "name":"Nibok",
              "country_id":520
           },
           {
              "id":2292,
              "name":"Uaboe",
              "country_id":520
           },
           {
              "id":2293,
              "name":"Yaren",
              "country_id":520
           }
        ],
        "524":[
           {
              "id":2294,
              "name":"Bāgmatī",
              "country_id":524
           },
           {
              "id":2295,
              "name":"Bherī",
              "country_id":524
           },
           {
              "id":2296,
              "name":"Dhawalāgiri",
              "country_id":524
           },
           {
              "id":2297,
              "name":"Gandakī",
              "country_id":524
           },
           {
              "id":2298,
              "name":"Janakpur",
              "country_id":524
           },
           {
              "id":2299,
              "name":"Karnālī",
              "country_id":524
           },
           {
              "id":2300,
              "name":"Kosī",
              "country_id":524
           },
           {
              "id":2301,
              "name":"Lumbinī",
              "country_id":524
           },
           {
              "id":2302,
              "name":"Mahākālī",
              "country_id":524
           },
           {
              "id":2303,
              "name":"Mechī",
              "country_id":524
           },
           {
              "id":2304,
              "name":"Nārāyanī",
              "country_id":524
           },
           {
              "id":2305,
              "name":"Rāptī",
              "country_id":524
           },
           {
              "id":2306,
              "name":"Sagarmāthā",
              "country_id":524
           },
           {
              "id":2307,
              "name":"Setī",
              "country_id":524
           }
        ],
        "528":[
           {
              "id":2308,
              "name":"Provincie Drenthe",
              "country_id":528
           },
           {
              "id":2309,
              "name":"Provincie Friesland",
              "country_id":528
           },
           {
              "id":2310,
              "name":"Gelderland",
              "country_id":528
           },
           {
              "id":2311,
              "name":"Groningen",
              "country_id":528
           },
           {
              "id":2312,
              "name":"Limburg",
              "country_id":528
           },
           {
              "id":2313,
              "name":"North Brabant",
              "country_id":528
           },
           {
              "id":2314,
              "name":"North Holland",
              "country_id":528
           },
           {
              "id":2315,
              "name":"Utrecht",
              "country_id":528
           },
           {
              "id":2316,
              "name":"Zeeland",
              "country_id":528
           },
           {
              "id":2317,
              "name":"South Holland",
              "country_id":528
           },
           {
              "id":2318,
              "name":"Overijssel",
              "country_id":528
           },
           {
              "id":2319,
              "name":"Flevoland",
              "country_id":528
           }
        ],
        "530":[
           {
              "id":2320,
              "name":"Netherlands Antilles",
              "country_id":530
           }
        ],
        "533":[
           {
              "id":2321,
              "name":"Aruba",
              "country_id":533
           }
        ],
        "540":[
     
        ],
        "548":[
           {
              "id":2322,
              "name":"Ambrym",
              "country_id":548
           },
           {
              "id":2323,
              "name":"Aoba/Maéwo",
              "country_id":548
           },
           {
              "id":2324,
              "name":"Torba",
              "country_id":548
           },
           {
              "id":2325,
              "name":"Éfaté",
              "country_id":548
           },
           {
              "id":2326,
              "name":"Épi",
              "country_id":548
           },
           {
              "id":2327,
              "name":"Malakula",
              "country_id":548
           },
           {
              "id":2328,
              "name":"Paama",
              "country_id":548
           },
           {
              "id":2329,
              "name":"Pentecôte",
              "country_id":548
           },
           {
              "id":2330,
              "name":"Sanma",
              "country_id":548
           },
           {
              "id":2331,
              "name":"Shepherd",
              "country_id":548
           },
           {
              "id":2332,
              "name":"Tafea",
              "country_id":548
           },
           {
              "id":2333,
              "name":"Malampa",
              "country_id":548
           },
           {
              "id":2334,
              "name":"Penama",
              "country_id":548
           },
           {
              "id":2335,
              "name":"Shefa",
              "country_id":548
           }
        ],
        "554":[
           {
              "id":2336,
              "name":"Tasman",
              "country_id":554
           },
           {
              "id":2337,
              "name":"Auckland",
              "country_id":554
           },
           {
              "id":2338,
              "name":"Bay of Plenty",
              "country_id":554
           },
           {
              "id":2339,
              "name":"Canterbury",
              "country_id":554
           },
           {
              "id":2340,
              "name":"Gisborne",
              "country_id":554
           },
           {
              "id":2341,
              "name":"Hawkeʼs Bay",
              "country_id":554
           },
           {
              "id":2342,
              "name":"Manawatu-Wanganui",
              "country_id":554
           },
           {
              "id":2343,
              "name":"Marlborough",
              "country_id":554
           },
           {
              "id":2344,
              "name":"Nelson",
              "country_id":554
           },
           {
              "id":2345,
              "name":"Northland",
              "country_id":554
           },
           {
              "id":2346,
              "name":"Otago",
              "country_id":554
           },
           {
              "id":2347,
              "name":"Southland",
              "country_id":554
           },
           {
              "id":2348,
              "name":"Taranaki",
              "country_id":554
           },
           {
              "id":2349,
              "name":"Waikato",
              "country_id":554
           },
           {
              "id":2350,
              "name":"Wellington",
              "country_id":554
           },
           {
              "id":2351,
              "name":"West Coast",
              "country_id":554
           }
        ],
        "558":[
           {
              "id":2352,
              "name":"Boaco",
              "country_id":558
           },
           {
              "id":2353,
              "name":"Carazo",
              "country_id":558
           },
           {
              "id":2354,
              "name":"Chinandega",
              "country_id":558
           },
           {
              "id":2355,
              "name":"Chontales",
              "country_id":558
           },
           {
              "id":2356,
              "name":"Estelí",
              "country_id":558
           },
           {
              "id":2357,
              "name":"Granada",
              "country_id":558
           },
           {
              "id":2358,
              "name":"Jinotega",
              "country_id":558
           },
           {
              "id":2359,
              "name":"León",
              "country_id":558
           },
           {
              "id":2360,
              "name":"Madriz",
              "country_id":558
           },
           {
              "id":2361,
              "name":"Managua",
              "country_id":558
           },
           {
              "id":2362,
              "name":"Masaya",
              "country_id":558
           },
           {
              "id":2363,
              "name":"Matagalpa",
              "country_id":558
           },
           {
              "id":2364,
              "name":"Nueva Segovia",
              "country_id":558
           },
           {
              "id":2365,
              "name":"Río San Juan",
              "country_id":558
           },
           {
              "id":2366,
              "name":"Rivas",
              "country_id":558
           },
           {
              "id":2367,
              "name":"Ogun State",
              "country_id":558
           },
           {
              "id":2368,
              "name":"Atlántico Norte",
              "country_id":558
           },
           {
              "id":2369,
              "name":"Atlántico Sur",
              "country_id":558
           }
        ],
        "562":[
           {
              "id":2370,
              "name":"Agadez",
              "country_id":562
           },
           {
              "id":2371,
              "name":"Diffa",
              "country_id":562
           },
           {
              "id":2372,
              "name":"Dosso",
              "country_id":562
           },
           {
              "id":2373,
              "name":"Maradi",
              "country_id":562
           },
           {
              "id":2374,
              "name":"Tahoua",
              "country_id":562
           },
           {
              "id":2375,
              "name":"Zinder",
              "country_id":562
           },
           {
              "id":2376,
              "name":"Niamey",
              "country_id":562
           },
           {
              "id":2377,
              "name":"Tillabéri",
              "country_id":562
           }
        ],
        "566":[
           {
              "id":2378,
              "name":"Lagos",
              "country_id":566
           },
           {
              "id":2379,
              "name":"Abuja Federal Capital Territory",
              "country_id":566
           },
           {
              "id":2380,
              "name":"Ogun",
              "country_id":566
           },
           {
              "id":2381,
              "name":"Akwa Ibom",
              "country_id":566
           },
           {
              "id":2382,
              "name":"Cross River",
              "country_id":566
           },
           {
              "id":2383,
              "name":"Kaduna",
              "country_id":566
           },
           {
              "id":2384,
              "name":"Katsina",
              "country_id":566
           },
           {
              "id":2385,
              "name":"Anambra",
              "country_id":566
           },
           {
              "id":2386,
              "name":"Benue",
              "country_id":566
           },
           {
              "id":2387,
              "name":"Borno",
              "country_id":566
           },
           {
              "id":2388,
              "name":"Imo",
              "country_id":566
           },
           {
              "id":2389,
              "name":"Kano",
              "country_id":566
           },
           {
              "id":2390,
              "name":"Kwara",
              "country_id":566
           },
           {
              "id":2391,
              "name":"Niger",
              "country_id":566
           },
           {
              "id":2392,
              "name":"Oyo",
              "country_id":566
           },
           {
              "id":2393,
              "name":"Adamawa",
              "country_id":566
           },
           {
              "id":2394,
              "name":"Delta",
              "country_id":566
           },
           {
              "id":2395,
              "name":"Edo",
              "country_id":566
           },
           {
              "id":2396,
              "name":"Jigawa",
              "country_id":566
           },
           {
              "id":2397,
              "name":"Kebbi",
              "country_id":566
           },
           {
              "id":2398,
              "name":"Kogi",
              "country_id":566
           },
           {
              "id":2399,
              "name":"Osun",
              "country_id":566
           },
           {
              "id":2400,
              "name":"Taraba",
              "country_id":566
           },
           {
              "id":2401,
              "name":"Yobe",
              "country_id":566
           },
           {
              "id":2402,
              "name":"Abia",
              "country_id":566
           },
           {
              "id":2403,
              "name":"Bauchi",
              "country_id":566
           },
           {
              "id":2404,
              "name":"Enugu",
              "country_id":566
           },
           {
              "id":2405,
              "name":"Ondo",
              "country_id":566
           },
           {
              "id":2406,
              "name":"Plateau",
              "country_id":566
           },
           {
              "id":2407,
              "name":"Rivers",
              "country_id":566
           },
           {
              "id":2408,
              "name":"Sokoto",
              "country_id":566
           },
           {
              "id":2409,
              "name":"Bayelsa",
              "country_id":566
           },
           {
              "id":2410,
              "name":"Ebonyi",
              "country_id":566
           },
           {
              "id":2411,
              "name":"Ekiti",
              "country_id":566
           },
           {
              "id":2412,
              "name":"Gombe",
              "country_id":566
           },
           {
              "id":2413,
              "name":"Nassarawa",
              "country_id":566
           },
           {
              "id":2414,
              "name":"Zamfara",
              "country_id":566
           }
        ],
        "570":[
           {
              "id":2415,
              "name":"Niue",
              "country_id":570
           }
        ],
        "574":[
           {
              "id":2416,
              "name":"Norfolk Island",
              "country_id":574
           }
        ],
        "578":[
           {
              "id":2417,
              "name":"Svalbard",
              "country_id":578
           },
           {
              "id":2418,
              "name":"Akershus",
              "country_id":578
           },
           {
              "id":2419,
              "name":"Aust-Agder",
              "country_id":578
           },
           {
              "id":2420,
              "name":"Buskerud",
              "country_id":578
           },
           {
              "id":2421,
              "name":"Finnmark",
              "country_id":578
           },
           {
              "id":2422,
              "name":"Hedmark",
              "country_id":578
           },
           {
              "id":2423,
              "name":"Hordaland",
              "country_id":578
           },
           {
              "id":2424,
              "name":"Møre og Romsdal",
              "country_id":578
           },
           {
              "id":2425,
              "name":"Nordland",
              "country_id":578
           },
           {
              "id":2426,
              "name":"Nord-Trøndelag",
              "country_id":578
           },
           {
              "id":2427,
              "name":"Oppland",
              "country_id":578
           },
           {
              "id":2428,
              "name":"Oslo county",
              "country_id":578
           },
           {
              "id":2429,
              "name":"Østfold",
              "country_id":578
           },
           {
              "id":2430,
              "name":"Rogaland",
              "country_id":578
           },
           {
              "id":2431,
              "name":"Sogn og Fjordane",
              "country_id":578
           },
           {
              "id":2432,
              "name":"Sør-Trøndelag",
              "country_id":578
           },
           {
              "id":2433,
              "name":"Telemark",
              "country_id":578
           },
           {
              "id":2434,
              "name":"Troms",
              "country_id":578
           },
           {
              "id":2435,
              "name":"Vest-Agder",
              "country_id":578
           },
           {
              "id":2436,
              "name":"Vestfold",
              "country_id":578
           }
        ],
        "580":[
     
        ],
        "581":[
     
        ],
        "583":[
           {
              "id":2437,
              "name":"Kosrae",
              "country_id":583
           },
           {
              "id":2438,
              "name":"Pohnpei",
              "country_id":583
           },
           {
              "id":2439,
              "name":"Chuuk",
              "country_id":583
           },
           {
              "id":2440,
              "name":"Yap",
              "country_id":583
           }
        ],
        "584":[
           {
              "id":2441,
              "name":"Marshall Islands",
              "country_id":584
           }
        ],
        "585":[
           {
              "id":2442,
              "name":"State of Ngeremlengui",
              "country_id":585
           }
        ],
        "586":[
           {
              "id":2443,
              "name":"Federally Administered Tribal Areas",
              "country_id":586
           },
           {
              "id":2444,
              "name":"Balochistān",
              "country_id":586
           },
           {
              "id":2445,
              "name":"North West Frontier Province",
              "country_id":586
           },
           {
              "id":2446,
              "name":"Punjab",
              "country_id":586
           },
           {
              "id":2447,
              "name":"Sindh",
              "country_id":586
           },
           {
              "id":2448,
              "name":"Azad Kashmir",
              "country_id":586
           },
           {
              "id":2449,
              "name":"Gilgit-Baltistan",
              "country_id":586
           },
           {
              "id":2450,
              "name":"Islāmābād",
              "country_id":586
           }
        ],
        "591":[
           {
              "id":2451,
              "name":"Bocas del Toro",
              "country_id":591
           },
           {
              "id":2452,
              "name":"Chiriquí",
              "country_id":591
           },
           {
              "id":2453,
              "name":"Coclé",
              "country_id":591
           },
           {
              "id":2454,
              "name":"Colón",
              "country_id":591
           },
           {
              "id":2455,
              "name":"Darién",
              "country_id":591
           },
           {
              "id":2456,
              "name":"Herrera",
              "country_id":591
           },
           {
              "id":2457,
              "name":"Los Santos",
              "country_id":591
           },
           {
              "id":2458,
              "name":"Panamá",
              "country_id":591
           },
           {
              "id":2459,
              "name":"San Blas",
              "country_id":591
           },
           {
              "id":2460,
              "name":"Veraguas",
              "country_id":591
           }
        ],
        "598":[
           {
              "id":2461,
              "name":"Central",
              "country_id":598
           },
           {
              "id":2462,
              "name":"Gulf",
              "country_id":598
           },
           {
              "id":2463,
              "name":"Milne Bay",
              "country_id":598
           },
           {
              "id":2464,
              "name":"Northern",
              "country_id":598
           },
           {
              "id":2465,
              "name":"Southern Highlands",
              "country_id":598
           },
           {
              "id":2466,
              "name":"Western",
              "country_id":598
           },
           {
              "id":2467,
              "name":"Bougainville",
              "country_id":598
           },
           {
              "id":2468,
              "name":"Chimbu",
              "country_id":598
           },
           {
              "id":2469,
              "name":"Eastern Highlands",
              "country_id":598
           },
           {
              "id":2470,
              "name":"East New Britain",
              "country_id":598
           },
           {
              "id":2471,
              "name":"East Sepik",
              "country_id":598
           },
           {
              "id":2472,
              "name":"Madang",
              "country_id":598
           },
           {
              "id":2473,
              "name":"Manus",
              "country_id":598
           },
           {
              "id":2474,
              "name":"Morobe",
              "country_id":598
           },
           {
              "id":2475,
              "name":"New Ireland",
              "country_id":598
           },
           {
              "id":2476,
              "name":"Western Highlands",
              "country_id":598
           },
           {
              "id":2477,
              "name":"West New Britain",
              "country_id":598
           },
           {
              "id":2478,
              "name":"Sandaun",
              "country_id":598
           },
           {
              "id":2479,
              "name":"Enga",
              "country_id":598
           },
           {
              "id":2480,
              "name":"National Capital",
              "country_id":598
           }
        ],
        "600":[
           {
              "id":2481,
              "name":"Alto Paraná",
              "country_id":600
           },
           {
              "id":2482,
              "name":"Amambay",
              "country_id":600
           },
           {
              "id":2483,
              "name":"Caaguazú",
              "country_id":600
           },
           {
              "id":2484,
              "name":"Caazapá",
              "country_id":600
           },
           {
              "id":2485,
              "name":"Central",
              "country_id":600
           },
           {
              "id":2486,
              "name":"Concepción",
              "country_id":600
           },
           {
              "id":2487,
              "name":"Cordillera",
              "country_id":600
           },
           {
              "id":2488,
              "name":"Guairá",
              "country_id":600
           },
           {
              "id":2489,
              "name":"Itapúa",
              "country_id":600
           },
           {
              "id":2490,
              "name":"Misiones",
              "country_id":600
           },
           {
              "id":2491,
              "name":"Ñeembucú",
              "country_id":600
           },
           {
              "id":2492,
              "name":"Paraguarí",
              "country_id":600
           },
           {
              "id":2493,
              "name":"Presidente Hayes",
              "country_id":600
           },
           {
              "id":2494,
              "name":"San Pedro",
              "country_id":600
           },
           {
              "id":2495,
              "name":"Canindeyú",
              "country_id":600
           },
           {
              "id":2496,
              "name":"Asunción",
              "country_id":600
           },
           {
              "id":2497,
              "name":"Departamento de Alto Paraguay",
              "country_id":600
           },
           {
              "id":2498,
              "name":"Boquerón",
              "country_id":600
           }
        ],
        "604":[
           {
              "id":2499,
              "name":"Amazonas",
              "country_id":604
           },
           {
              "id":2500,
              "name":"Ancash",
              "country_id":604
           },
           {
              "id":2501,
              "name":"Apurímac",
              "country_id":604
           },
           {
              "id":2502,
              "name":"Arequipa",
              "country_id":604
           },
           {
              "id":2503,
              "name":"Ayacucho",
              "country_id":604
           },
           {
              "id":2504,
              "name":"Cajamarca",
              "country_id":604
           },
           {
              "id":2505,
              "name":"Callao",
              "country_id":604
           },
           {
              "id":2506,
              "name":"Cusco",
              "country_id":604
           },
           {
              "id":2507,
              "name":"Huancavelica",
              "country_id":604
           },
           {
              "id":2508,
              "name":"Huanuco",
              "country_id":604
           },
           {
              "id":2509,
              "name":"Ica",
              "country_id":604
           },
           {
              "id":2510,
              "name":"Junín",
              "country_id":604
           },
           {
              "id":2511,
              "name":"La Libertad",
              "country_id":604
           },
           {
              "id":2512,
              "name":"Lambayeque",
              "country_id":604
           },
           {
              "id":2513,
              "name":"Lima",
              "country_id":604
           },
           {
              "id":2514,
              "name":"Provincia de Lima",
              "country_id":604
           },
           {
              "id":2515,
              "name":"Loreto",
              "country_id":604
           },
           {
              "id":2516,
              "name":"Madre de Dios",
              "country_id":604
           },
           {
              "id":2517,
              "name":"Moquegua",
              "country_id":604
           },
           {
              "id":2518,
              "name":"Pasco",
              "country_id":604
           },
           {
              "id":2519,
              "name":"Piura",
              "country_id":604
           },
           {
              "id":2520,
              "name":"Puno",
              "country_id":604
           },
           {
              "id":2521,
              "name":"San Martín",
              "country_id":604
           },
           {
              "id":2522,
              "name":"Tacna",
              "country_id":604
           },
           {
              "id":2523,
              "name":"Tumbes",
              "country_id":604
           },
           {
              "id":2524,
              "name":"Ucayali",
              "country_id":604
           }
        ],
        "608":[
           {
              "id":2525,
              "name":"Abra",
              "country_id":608
           },
           {
              "id":2526,
              "name":"Agusan del Norte",
              "country_id":608
           },
           {
              "id":2527,
              "name":"Agusan del Sur",
              "country_id":608
           },
           {
              "id":2528,
              "name":"Aklan",
              "country_id":608
           },
           {
              "id":2529,
              "name":"Albay",
              "country_id":608
           },
           {
              "id":2530,
              "name":"Antique",
              "country_id":608
           },
           {
              "id":2531,
              "name":"Bataan",
              "country_id":608
           },
           {
              "id":2532,
              "name":"Batanes",
              "country_id":608
           },
           {
              "id":2533,
              "name":"Batangas",
              "country_id":608
           },
           {
              "id":2534,
              "name":"Benguet",
              "country_id":608
           },
           {
              "id":2535,
              "name":"Bohol",
              "country_id":608
           },
           {
              "id":2536,
              "name":"Bukidnon",
              "country_id":608
           },
           {
              "id":2537,
              "name":"Bulacan",
              "country_id":608
           },
           {
              "id":2538,
              "name":"Cagayan",
              "country_id":608
           },
           {
              "id":2539,
              "name":"Camarines Norte",
              "country_id":608
           },
           {
              "id":2540,
              "name":"Camarines Sur",
              "country_id":608
           },
           {
              "id":2541,
              "name":"Camiguin",
              "country_id":608
           },
           {
              "id":2542,
              "name":"Capiz",
              "country_id":608
           },
           {
              "id":2543,
              "name":"Catanduanes",
              "country_id":608
           },
           {
              "id":2544,
              "name":"Cebu",
              "country_id":608
           },
           {
              "id":2545,
              "name":"Basilan",
              "country_id":608
           },
           {
              "id":2546,
              "name":"Eastern Samar",
              "country_id":608
           },
           {
              "id":2547,
              "name":"Davao del Sur",
              "country_id":608
           },
           {
              "id":2548,
              "name":"Davao Oriental",
              "country_id":608
           },
           {
              "id":2549,
              "name":"Ifugao",
              "country_id":608
           },
           {
              "id":2550,
              "name":"Ilocos Norte",
              "country_id":608
           },
           {
              "id":2551,
              "name":"Ilocos Sur",
              "country_id":608
           },
           {
              "id":2552,
              "name":"Iloilo",
              "country_id":608
           },
           {
              "id":2553,
              "name":"Isabela",
              "country_id":608
           },
           {
              "id":2554,
              "name":"Laguna",
              "country_id":608
           },
           {
              "id":2555,
              "name":"Lanao del Sur",
              "country_id":608
           },
           {
              "id":2556,
              "name":"La Union",
              "country_id":608
           },
           {
              "id":2557,
              "name":"Leyte",
              "country_id":608
           },
           {
              "id":2558,
              "name":"Marinduque",
              "country_id":608
           },
           {
              "id":2559,
              "name":"Masbate",
              "country_id":608
           },
           {
              "id":2560,
              "name":"Occidental Mindoro",
              "country_id":608
           },
           {
              "id":2561,
              "name":"Oriental Mindoro",
              "country_id":608
           },
           {
              "id":2562,
              "name":"Misamis Oriental",
              "country_id":608
           },
           {
              "id":2563,
              "name":"Mountain Province",
              "country_id":608
           },
           {
              "id":2564,
              "name":"Negros Oriental",
              "country_id":608
           },
           {
              "id":2565,
              "name":"Nueva Ecija",
              "country_id":608
           },
           {
              "id":2566,
              "name":"Nueva Vizcaya",
              "country_id":608
           },
           {
              "id":2567,
              "name":"Palawan",
              "country_id":608
           },
           {
              "id":2568,
              "name":"Pampanga",
              "country_id":608
           },
           {
              "id":2569,
              "name":"Pangasinan",
              "country_id":608
           },
           {
              "id":2570,
              "name":"Rizal",
              "country_id":608
           },
           {
              "id":2571,
              "name":"Romblon",
              "country_id":608
           },
           {
              "id":2572,
              "name":"Samar",
              "country_id":608
           },
           {
              "id":2573,
              "name":"Maguindanao",
              "country_id":608
           },
           {
              "id":2574,
              "name":"Cotabato City",
              "country_id":608
           },
           {
              "id":2575,
              "name":"Sorsogon",
              "country_id":608
           },
           {
              "id":2576,
              "name":"Southern Leyte",
              "country_id":608
           },
           {
              "id":2577,
              "name":"Sulu",
              "country_id":608
           },
           {
              "id":2578,
              "name":"Surigao del Norte",
              "country_id":608
           },
           {
              "id":2579,
              "name":"Surigao del Sur",
              "country_id":608
           },
           {
              "id":2580,
              "name":"Tarlac",
              "country_id":608
           },
           {
              "id":2581,
              "name":"Zambales",
              "country_id":608
           },
           {
              "id":2582,
              "name":"Zamboanga del Norte",
              "country_id":608
           },
           {
              "id":2583,
              "name":"Zamboanga del Sur",
              "country_id":608
           },
           {
              "id":2584,
              "name":"Northern Samar",
              "country_id":608
           },
           {
              "id":2585,
              "name":"Quirino",
              "country_id":608
           },
           {
              "id":2586,
              "name":"Siquijor",
              "country_id":608
           },
           {
              "id":2587,
              "name":"South Cotabato",
              "country_id":608
           },
           {
              "id":2588,
              "name":"Sultan Kudarat",
              "country_id":608
           },
           {
              "id":2589,
              "name":"Kalinga",
              "country_id":608
           },
           {
              "id":2590,
              "name":"Apayao",
              "country_id":608
           },
           {
              "id":2591,
              "name":"Tawi-Tawi",
              "country_id":608
           },
           {
              "id":2592,
              "name":"Angeles",
              "country_id":608
           },
           {
              "id":2593,
              "name":"Bacolod City",
              "country_id":608
           },
           {
              "id":2594,
              "name":"Compostela Valley",
              "country_id":608
           },
           {
              "id":2595,
              "name":"Baguio",
              "country_id":608
           },
           {
              "id":2596,
              "name":"Davao del Norte",
              "country_id":608
           },
           {
              "id":2597,
              "name":"Butuan",
              "country_id":608
           },
           {
              "id":2598,
              "name":"Guimaras",
              "country_id":608
           },
           {
              "id":2599,
              "name":"Lanao del Norte",
              "country_id":608
           },
           {
              "id":2600,
              "name":"Misamis Occidental",
              "country_id":608
           },
           {
              "id":2601,
              "name":"Caloocan",
              "country_id":608
           },
           {
              "id":2602,
              "name":"Cavite",
              "country_id":608
           },
           {
              "id":2603,
              "name":"Cebu City",
              "country_id":608
           },
           {
              "id":2604,
              "name":"Cotabato",
              "country_id":608
           },
           {
              "id":2605,
              "name":"Dagupan",
              "country_id":608
           },
           {
              "id":2606,
              "name":"Cagayan de Oro",
              "country_id":608
           },
           {
              "id":2607,
              "name":"Iligan",
              "country_id":608
           },
           {
              "id":2608,
              "name":"Davao",
              "country_id":608
           },
           {
              "id":2609,
              "name":"Las Piñas",
              "country_id":608
           },
           {
              "id":2610,
              "name":"Malabon",
              "country_id":608
           },
           {
              "id":2611,
              "name":"General Santos",
              "country_id":608
           },
           {
              "id":2612,
              "name":"Muntinlupa",
              "country_id":608
           },
           {
              "id":2613,
              "name":"Iloilo City",
              "country_id":608
           },
           {
              "id":2614,
              "name":"Navotas",
              "country_id":608
           },
           {
              "id":2615,
              "name":"Parañaque",
              "country_id":608
           },
           {
              "id":2616,
              "name":"Quezon City",
              "country_id":608
           },
           {
              "id":2617,
              "name":"Lapu-Lapu",
              "country_id":608
           },
           {
              "id":2618,
              "name":"Taguig",
              "country_id":608
           },
           {
              "id":2619,
              "name":"Valenzuela",
              "country_id":608
           },
           {
              "id":2620,
              "name":"Lucena",
              "country_id":608
           },
           {
              "id":2621,
              "name":"Mandaue",
              "country_id":608
           },
           {
              "id":2622,
              "name":"Manila",
              "country_id":608
           },
           {
              "id":2623,
              "name":"Zamboanga Sibugay",
              "country_id":608
           },
           {
              "id":2624,
              "name":"Naga",
              "country_id":608
           },
           {
              "id":2625,
              "name":"Olongapo",
              "country_id":608
           },
           {
              "id":2626,
              "name":"Ormoc",
              "country_id":608
           },
           {
              "id":2627,
              "name":"Santiago",
              "country_id":608
           },
           {
              "id":2628,
              "name":"Pateros",
              "country_id":608
           },
           {
              "id":2629,
              "name":"Pasay",
              "country_id":608
           },
           {
              "id":2630,
              "name":"Puerto Princesa",
              "country_id":608
           },
           {
              "id":2631,
              "name":"Quezon",
              "country_id":608
           },
           {
              "id":2632,
              "name":"Tacloban",
              "country_id":608
           },
           {
              "id":2633,
              "name":"Zamboanga City",
              "country_id":608
           },
           {
              "id":2634,
              "name":"Aurora",
              "country_id":608
           },
           {
              "id":2635,
              "name":"Negros Occidental",
              "country_id":608
           },
           {
              "id":2636,
              "name":"Biliran",
              "country_id":608
           },
           {
              "id":2637,
              "name":"Makati City",
              "country_id":608
           },
           {
              "id":2638,
              "name":"Sarangani",
              "country_id":608
           },
           {
              "id":2639,
              "name":"Mandaluyong City",
              "country_id":608
           },
           {
              "id":2640,
              "name":"Marikina",
              "country_id":608
           },
           {
              "id":2641,
              "name":"Pasig",
              "country_id":608
           },
           {
              "id":2642,
              "name":"San Juan",
              "country_id":608
           }
        ],
        "612":[
           {
              "id":2643,
              "name":"Pitcairn Islands",
              "country_id":612
           }
        ],
        "616":[
           {
              "id":2644,
              "name":"Biala Podlaska",
              "country_id":616
           },
           {
              "id":2645,
              "name":"Bialystok",
              "country_id":616
           },
           {
              "id":2646,
              "name":"Bielsko",
              "country_id":616
           },
           {
              "id":2647,
              "name":"Bydgoszcz",
              "country_id":616
           },
           {
              "id":2648,
              "name":"Chelm",
              "country_id":616
           },
           {
              "id":2649,
              "name":"Ciechanow",
              "country_id":616
           },
           {
              "id":2650,
              "name":"Czestochowa",
              "country_id":616
           },
           {
              "id":2651,
              "name":"Elblag",
              "country_id":616
           },
           {
              "id":2652,
              "name":"Gdansk",
              "country_id":616
           },
           {
              "id":2653,
              "name":"Gorzow",
              "country_id":616
           },
           {
              "id":2654,
              "name":"Jelenia Gora",
              "country_id":616
           },
           {
              "id":2655,
              "name":"Kalisz",
              "country_id":616
           },
           {
              "id":2656,
              "name":"Katowice",
              "country_id":616
           },
           {
              "id":2657,
              "name":"Kielce",
              "country_id":616
           },
           {
              "id":2658,
              "name":"Konin",
              "country_id":616
           },
           {
              "id":2659,
              "name":"Koszalin",
              "country_id":616
           },
           {
              "id":2660,
              "name":"Krakow",
              "country_id":616
           },
           {
              "id":2661,
              "name":"Krosno",
              "country_id":616
           },
           {
              "id":2662,
              "name":"Legnica",
              "country_id":616
           },
           {
              "id":2663,
              "name":"Leszno",
              "country_id":616
           },
           {
              "id":2664,
              "name":"Lodz",
              "country_id":616
           },
           {
              "id":2665,
              "name":"Lomza",
              "country_id":616
           },
           {
              "id":2666,
              "name":"Lublin",
              "country_id":616
           },
           {
              "id":2667,
              "name":"Nowy Sacz",
              "country_id":616
           },
           {
              "id":2668,
              "name":"Olsztyn",
              "country_id":616
           },
           {
              "id":2669,
              "name":"Opole",
              "country_id":616
           },
           {
              "id":2670,
              "name":"Ostroleka",
              "country_id":616
           },
           {
              "id":2671,
              "name":"Pita",
              "country_id":616
           },
           {
              "id":2672,
              "name":"Piotrkow",
              "country_id":616
           },
           {
              "id":2673,
              "name":"Plock",
              "country_id":616
           },
           {
              "id":2674,
              "name":"Poznan",
              "country_id":616
           },
           {
              "id":2675,
              "name":"Przemysl",
              "country_id":616
           },
           {
              "id":2676,
              "name":"Radom",
              "country_id":616
           },
           {
              "id":2677,
              "name":"Rzeszow",
              "country_id":616
           },
           {
              "id":2678,
              "name":"Siedlce",
              "country_id":616
           },
           {
              "id":2679,
              "name":"Sieradz",
              "country_id":616
           },
           {
              "id":2680,
              "name":"Skierniewice",
              "country_id":616
           },
           {
              "id":2681,
              "name":"Slupsk",
              "country_id":616
           },
           {
              "id":2682,
              "name":"Suwalki",
              "country_id":616
           },
           {
              "id":2683,
              "name":"Szczecin",
              "country_id":616
           },
           {
              "id":2684,
              "name":"Tarnobrzeg",
              "country_id":616
           },
           {
              "id":2685,
              "name":"Tarnow",
              "country_id":616
           },
           {
              "id":2686,
              "name":"Torufi",
              "country_id":616
           },
           {
              "id":2687,
              "name":"Walbrzych",
              "country_id":616
           },
           {
              "id":2688,
              "name":"Warszawa",
              "country_id":616
           },
           {
              "id":2689,
              "name":"Wloclawek",
              "country_id":616
           },
           {
              "id":2690,
              "name":"Wroclaw",
              "country_id":616
           },
           {
              "id":2691,
              "name":"Zamosc",
              "country_id":616
           },
           {
              "id":2692,
              "name":"Zielona Gora",
              "country_id":616
           },
           {
              "id":2693,
              "name":"Lower Silesian Voivodeship",
              "country_id":616
           },
           {
              "id":2694,
              "name":"Kujawsko-Pomorskie Voivodship",
              "country_id":616
           },
           {
              "id":2695,
              "name":"Łódź Voivodeship",
              "country_id":616
           },
           {
              "id":2696,
              "name":"Lublin Voivodeship",
              "country_id":616
           },
           {
              "id":2697,
              "name":"Lubusz Voivodship",
              "country_id":616
           },
           {
              "id":2698,
              "name":"Lesser Poland Voivodeship",
              "country_id":616
           },
           {
              "id":2699,
              "name":"Masovian Voivodeship",
              "country_id":616
           },
           {
              "id":2700,
              "name":"Opole Voivodeship",
              "country_id":616
           },
           {
              "id":2701,
              "name":"Subcarpathian Voivodeship",
              "country_id":616
           },
           {
              "id":2702,
              "name":"Podlasie Voivodship",
              "country_id":616
           },
           {
              "id":2703,
              "name":"Pomeranian Voivodeship",
              "country_id":616
           },
           {
              "id":2704,
              "name":"Silesian Voivodeship",
              "country_id":616
           },
           {
              "id":2705,
              "name":"Świętokrzyskie Voivodship",
              "country_id":616
           },
           {
              "id":2706,
              "name":"Warmian-Masurian Voivodeship",
              "country_id":616
           },
           {
              "id":2707,
              "name":"Greater Poland Voivodeship",
              "country_id":616
           },
           {
              "id":2708,
              "name":"West Pomeranian Voivodeship",
              "country_id":616
           }
        ],
        "620":[
           {
              "id":2709,
              "name":"Aveiro",
              "country_id":620
           },
           {
              "id":2710,
              "name":"Beja",
              "country_id":620
           },
           {
              "id":2711,
              "name":"Braga",
              "country_id":620
           },
           {
              "id":2712,
              "name":"Bragança",
              "country_id":620
           },
           {
              "id":2713,
              "name":"Castelo Branco",
              "country_id":620
           },
           {
              "id":2714,
              "name":"Coimbra",
              "country_id":620
           },
           {
              "id":2715,
              "name":"Évora",
              "country_id":620
           },
           {
              "id":2716,
              "name":"Faro",
              "country_id":620
           },
           {
              "id":2717,
              "name":"Madeira",
              "country_id":620
           },
           {
              "id":2718,
              "name":"Guarda",
              "country_id":620
           },
           {
              "id":2719,
              "name":"Leiria",
              "country_id":620
           },
           {
              "id":2720,
              "name":"Lisbon",
              "country_id":620
           },
           {
              "id":2721,
              "name":"Portalegre",
              "country_id":620
           },
           {
              "id":2722,
              "name":"Porto",
              "country_id":620
           },
           {
              "id":2723,
              "name":"Santarém",
              "country_id":620
           },
           {
              "id":2724,
              "name":"Setúbal",
              "country_id":620
           },
           {
              "id":2725,
              "name":"Viana do Castelo",
              "country_id":620
           },
           {
              "id":2726,
              "name":"Vila Real",
              "country_id":620
           },
           {
              "id":2727,
              "name":"Viseu",
              "country_id":620
           },
           {
              "id":2728,
              "name":"Azores",
              "country_id":620
           }
        ],
        "624":[
           {
              "id":2729,
              "name":"Bafatá",
              "country_id":624
           },
           {
              "id":2730,
              "name":"Quinara",
              "country_id":624
           },
           {
              "id":2731,
              "name":"Oio",
              "country_id":624
           },
           {
              "id":2732,
              "name":"Bolama",
              "country_id":624
           },
           {
              "id":2733,
              "name":"Cacheu",
              "country_id":624
           },
           {
              "id":2734,
              "name":"Tombali",
              "country_id":624
           },
           {
              "id":2735,
              "name":"Gabú",
              "country_id":624
           },
           {
              "id":2736,
              "name":"Bissau",
              "country_id":624
           },
           {
              "id":2737,
              "name":"Biombo",
              "country_id":624
           }
        ],
        "626":[
           {
              "id":2738,
              "name":"Bobonaro",
              "country_id":626
           }
        ],
        "630":[
           {
              "id":2739,
              "name":"Puerto Rico",
              "country_id":630
           }
        ],
        "634":[
           {
              "id":2740,
              "name":"Ad Dawḩah",
              "country_id":634
           },
           {
              "id":2741,
              "name":"Al Ghuwayrīyah",
              "country_id":634
           },
           {
              "id":2742,
              "name":"Al Jumaylīyah",
              "country_id":634
           },
           {
              "id":2743,
              "name":"Al Khawr",
              "country_id":634
           },
           {
              "id":2744,
              "name":"Al Wakrah Municipality",
              "country_id":634
           },
           {
              "id":2745,
              "name":"Ar Rayyān",
              "country_id":634
           },
           {
              "id":2746,
              "name":"Jarayan al Batinah",
              "country_id":634
           },
           {
              "id":2747,
              "name":"Madīnat ash Shamāl",
              "country_id":634
           },
           {
              "id":2748,
              "name":"Umm Şalāl",
              "country_id":634
           },
           {
              "id":2749,
              "name":"Al Wakrah",
              "country_id":634
           },
           {
              "id":2750,
              "name":"Jarayān al Bāţinah",
              "country_id":634
           },
           {
              "id":2751,
              "name":"Umm Sa‘īd",
              "country_id":634
           }
        ],
        "638":[
           {
              "id":2752,
              "name":"Réunion",
              "country_id":638
           }
        ],
        "642":[
           {
              "id":2753,
              "name":"Alba",
              "country_id":642
           },
           {
              "id":2754,
              "name":"Arad",
              "country_id":642
           },
           {
              "id":2755,
              "name":"Argeş",
              "country_id":642
           },
           {
              "id":2756,
              "name":"Bacău",
              "country_id":642
           },
           {
              "id":2757,
              "name":"Bihor",
              "country_id":642
           },
           {
              "id":2758,
              "name":"Bistriţa-Năsăud",
              "country_id":642
           },
           {
              "id":2759,
              "name":"Botoşani",
              "country_id":642
           },
           {
              "id":2760,
              "name":"Brăila",
              "country_id":642
           },
           {
              "id":2761,
              "name":"Braşov",
              "country_id":642
           },
           {
              "id":2762,
              "name":"Bucureşti",
              "country_id":642
           },
           {
              "id":2763,
              "name":"Buzău",
              "country_id":642
           },
           {
              "id":2764,
              "name":"Caraş-Severin",
              "country_id":642
           },
           {
              "id":2765,
              "name":"Cluj",
              "country_id":642
           },
           {
              "id":2766,
              "name":"Constanţa",
              "country_id":642
           },
           {
              "id":2767,
              "name":"Covasna",
              "country_id":642
           },
           {
              "id":2768,
              "name":"Dâmboviţa",
              "country_id":642
           },
           {
              "id":2769,
              "name":"Dolj",
              "country_id":642
           },
           {
              "id":2770,
              "name":"Galaţi",
              "country_id":642
           },
           {
              "id":2771,
              "name":"Gorj",
              "country_id":642
           },
           {
              "id":2772,
              "name":"Harghita",
              "country_id":642
           },
           {
              "id":2773,
              "name":"Hunedoara",
              "country_id":642
           },
           {
              "id":2774,
              "name":"Ialomiţa",
              "country_id":642
           },
           {
              "id":2775,
              "name":"Iaşi",
              "country_id":642
           },
           {
              "id":2776,
              "name":"Maramureş",
              "country_id":642
           },
           {
              "id":2777,
              "name":"Mehedinţi",
              "country_id":642
           },
           {
              "id":2778,
              "name":"Mureş",
              "country_id":642
           },
           {
              "id":2779,
              "name":"Neamţ",
              "country_id":642
           },
           {
              "id":2780,
              "name":"Olt",
              "country_id":642
           },
           {
              "id":2781,
              "name":"Prahova",
              "country_id":642
           },
           {
              "id":2782,
              "name":"Sălaj",
              "country_id":642
           },
           {
              "id":2783,
              "name":"Satu Mare",
              "country_id":642
           },
           {
              "id":2784,
              "name":"Sibiu",
              "country_id":642
           },
           {
              "id":2785,
              "name":"Suceava",
              "country_id":642
           },
           {
              "id":2786,
              "name":"Teleorman",
              "country_id":642
           },
           {
              "id":2787,
              "name":"Timiş",
              "country_id":642
           },
           {
              "id":2788,
              "name":"Tulcea",
              "country_id":642
           },
           {
              "id":2789,
              "name":"Vaslui",
              "country_id":642
           },
           {
              "id":2790,
              "name":"Vâlcea",
              "country_id":642
           },
           {
              "id":2791,
              "name":"Judeţul Vrancea",
              "country_id":642
           },
           {
              "id":2792,
              "name":"Călăraşi",
              "country_id":642
           },
           {
              "id":2793,
              "name":"Giurgiu",
              "country_id":642
           },
           {
              "id":2794,
              "name":"Ilfov",
              "country_id":642
           }
        ],
        "643":[
           {
              "id":2795,
              "name":"Adygeya",
              "country_id":643
           },
           {
              "id":2796,
              "name":"Altay",
              "country_id":643
           },
           {
              "id":2797,
              "name":"Altayskiy Kray",
              "country_id":643
           },
           {
              "id":2798,
              "name":"Amur",
              "country_id":643
           },
           {
              "id":2799,
              "name":"Arkhangelskaya oblast",
              "country_id":643
           },
           {
              "id":2800,
              "name":"Astrakhan",
              "country_id":643
           },
           {
              "id":2801,
              "name":"Bashkortostan",
              "country_id":643
           },
           {
              "id":2802,
              "name":"Belgorod",
              "country_id":643
           },
           {
              "id":2803,
              "name":"Brjansk",
              "country_id":643
           },
           {
              "id":2804,
              "name":"Buryatiya",
              "country_id":643
           },
           {
              "id":2805,
              "name":"Chechnya",
              "country_id":643
           },
           {
              "id":2806,
              "name":"Tsjeljabinsk",
              "country_id":643
           },
           {
              "id":2807,
              "name":"Zabaïkalski Kray",
              "country_id":643
           },
           {
              "id":2808,
              "name":"Chukotskiy Avtonomnyy Okrug",
              "country_id":643
           },
           {
              "id":2809,
              "name":"Chuvashia",
              "country_id":643
           },
           {
              "id":2810,
              "name":"Dagestan",
              "country_id":643
           },
           {
              "id":2811,
              "name":"Ingushetiya",
              "country_id":643
           },
           {
              "id":2812,
              "name":"Irkutsk",
              "country_id":643
           },
           {
              "id":2813,
              "name":"Ivanovo",
              "country_id":643
           },
           {
              "id":2814,
              "name":"Kabardino-Balkariya",
              "country_id":643
           },
           {
              "id":2815,
              "name":"Kaliningrad",
              "country_id":643
           },
           {
              "id":2816,
              "name":"Kalmykiya",
              "country_id":643
           },
           {
              "id":2817,
              "name":"Kaluga",
              "country_id":643
           },
           {
              "id":2818,
              "name":"Karachayevo-Cherkesiya",
              "country_id":643
           },
           {
              "id":2819,
              "name":"Kareliya",
              "country_id":643
           },
           {
              "id":2820,
              "name":"Kemerovo",
              "country_id":643
           },
           {
              "id":2821,
              "name":"Khabarovsk Krai",
              "country_id":643
           },
           {
              "id":2822,
              "name":"Khakasiya",
              "country_id":643
           },
           {
              "id":2823,
              "name":"Khanty-Mansiyskiy Avtonomnyy Okrug",
              "country_id":643
           },
           {
              "id":2824,
              "name":"Kirov",
              "country_id":643
           },
           {
              "id":2825,
              "name":"Komi",
              "country_id":643
           },
           {
              "id":2826,
              "name":"Kostroma",
              "country_id":643
           },
           {
              "id":2827,
              "name":"Krasnodarskiy Kray",
              "country_id":643
           },
           {
              "id":2828,
              "name":"Kurgan",
              "country_id":643
           },
           {
              "id":2829,
              "name":"Kursk",
              "country_id":643
           },
           {
              "id":2830,
              "name":"Leningradskaya Oblastʼ",
              "country_id":643
           },
           {
              "id":2831,
              "name":"Lipetsk",
              "country_id":643
           },
           {
              "id":2832,
              "name":"Magadan",
              "country_id":643
           },
           {
              "id":2833,
              "name":"Mariy-El",
              "country_id":643
           },
           {
              "id":2834,
              "name":"Mordoviya",
              "country_id":643
           },
           {
              "id":2835,
              "name":"Moskovskaya Oblastʼ",
              "country_id":643
           },
           {
              "id":2836,
              "name":"Moscow",
              "country_id":643
           },
           {
              "id":2837,
              "name":"Murmansk Oblast",
              "country_id":643
           },
           {
              "id":2838,
              "name":"Nenetskiy Avtonomnyy Okrug",
              "country_id":643
           },
           {
              "id":2839,
              "name":"Nizjnij Novgorod",
              "country_id":643
           },
           {
              "id":2840,
              "name":"Novgorod",
              "country_id":643
           },
           {
              "id":2841,
              "name":"Novosibirsk",
              "country_id":643
           },
           {
              "id":2842,
              "name":"Omsk",
              "country_id":643
           },
           {
              "id":2843,
              "name":"Orenburg",
              "country_id":643
           },
           {
              "id":2844,
              "name":"Orjol",
              "country_id":643
           },
           {
              "id":2845,
              "name":"Penza",
              "country_id":643
           },
           {
              "id":2846,
              "name":"Primorskiy Kray",
              "country_id":643
           },
           {
              "id":2847,
              "name":"Pskov",
              "country_id":643
           },
           {
              "id":2848,
              "name":"Rostov",
              "country_id":643
           },
           {
              "id":2849,
              "name":"Rjazan",
              "country_id":643
           },
           {
              "id":2850,
              "name":"Sakha",
              "country_id":643
           },
           {
              "id":2851,
              "name":"Sakhalin",
              "country_id":643
           },
           {
              "id":2852,
              "name":"Samara",
              "country_id":643
           },
           {
              "id":2853,
              "name":"Sankt-Peterburg",
              "country_id":643
           },
           {
              "id":2854,
              "name":"Saratov",
              "country_id":643
           },
           {
              "id":2855,
              "name":"Severnaya Osetiya-Alaniya",
              "country_id":643
           },
           {
              "id":2856,
              "name":"Smolensk",
              "country_id":643
           },
           {
              "id":2857,
              "name":"Stavropolʼskiy Kray",
              "country_id":643
           },
           {
              "id":2858,
              "name":"Sverdlovsk",
              "country_id":643
           },
           {
              "id":2859,
              "name":"Tambov",
              "country_id":643
           },
           {
              "id":2860,
              "name":"Tatarstan",
              "country_id":643
           },
           {
              "id":2861,
              "name":"Tomsk",
              "country_id":643
           },
           {
              "id":2862,
              "name":"Tula",
              "country_id":643
           },
           {
              "id":2863,
              "name":"Tverskaya Oblast’",
              "country_id":643
           },
           {
              "id":2864,
              "name":"Tjumen",
              "country_id":643
           },
           {
              "id":2865,
              "name":"Tyva",
              "country_id":643
           },
           {
              "id":2866,
              "name":"Udmurtiya",
              "country_id":643
           },
           {
              "id":2867,
              "name":"Uljanovsk",
              "country_id":643
           },
           {
              "id":2868,
              "name":"Vladimir",
              "country_id":643
           },
           {
              "id":2869,
              "name":"Volgograd",
              "country_id":643
           },
           {
              "id":2870,
              "name":"Vologda",
              "country_id":643
           },
           {
              "id":2871,
              "name":"Voronezj",
              "country_id":643
           },
           {
              "id":2872,
              "name":"Yamalo-Nenetskiy Avtonomnyy Okrug",
              "country_id":643
           },
           {
              "id":2873,
              "name":"Jaroslavl",
              "country_id":643
           },
           {
              "id":2874,
              "name":"Jewish Autonomous Oblast",
              "country_id":643
           },
           {
              "id":2875,
              "name":"Perm",
              "country_id":643
           },
           {
              "id":2876,
              "name":"Krasnoyarskiy Kray",
              "country_id":643
           },
           {
              "id":2877,
              "name":"Kamtsjatka",
              "country_id":643
           },
           {
              "id":2878,
              "name":"RSJA",
              "country_id":643
           }
        ],
        "646":[
           {
              "id":2879,
              "name":"Eastern Province",
              "country_id":646
           },
           {
              "id":2880,
              "name":"Kigali City",
              "country_id":646
           },
           {
              "id":2881,
              "name":"Northern Province",
              "country_id":646
           },
           {
              "id":2882,
              "name":"Western Province",
              "country_id":646
           },
           {
              "id":2883,
              "name":"Southern Province",
              "country_id":646
           }
        ],
        "652":[
     
        ],
        "654":[
           {
              "id":2884,
              "name":"Ascension",
              "country_id":654
           },
           {
              "id":2885,
              "name":"Saint Helena",
              "country_id":654
           },
           {
              "id":2886,
              "name":"Tristan da Cunha",
              "country_id":654
           }
        ],
        "659":[
           {
              "id":2887,
              "name":"Christ Church Nichola Town",
              "country_id":659
           },
           {
              "id":2888,
              "name":"Saint Anne Sandy Point",
              "country_id":659
           },
           {
              "id":2889,
              "name":"Saint George Basseterre",
              "country_id":659
           },
           {
              "id":2890,
              "name":"Saint George Gingerland",
              "country_id":659
           },
           {
              "id":2891,
              "name":"Saint James Windwa",
              "country_id":659
           },
           {
              "id":2892,
              "name":"Saint John Capesterre",
              "country_id":659
           },
           {
              "id":2893,
              "name":"Saint John Figtree",
              "country_id":659
           },
           {
              "id":2894,
              "name":"Saint Mary Cayon",
              "country_id":659
           },
           {
              "id":2895,
              "name":"Saint Paul Capesterre",
              "country_id":659
           },
           {
              "id":2896,
              "name":"Saint Paul Charlestown",
              "country_id":659
           },
           {
              "id":2897,
              "name":"Saint Peter Basseterre",
              "country_id":659
           },
           {
              "id":2898,
              "name":"Saint Thomas Lowland",
              "country_id":659
           },
           {
              "id":2899,
              "name":"Saint Thomas Middle Island",
              "country_id":659
           },
           {
              "id":2900,
              "name":"Trinity Palmetto Point",
              "country_id":659
           }
        ],
        "660":[
           {
              "id":2901,
              "name":"Anguilla",
              "country_id":660
           }
        ],
        "662":[
           {
              "id":2902,
              "name":"Anse-la-Raye",
              "country_id":662
           },
           {
              "id":2903,
              "name":"Dauphin",
              "country_id":662
           },
           {
              "id":2904,
              "name":"Castries",
              "country_id":662
           },
           {
              "id":2905,
              "name":"Choiseul",
              "country_id":662
           },
           {
              "id":2906,
              "name":"Dennery",
              "country_id":662
           },
           {
              "id":2907,
              "name":"Gros-Islet",
              "country_id":662
           },
           {
              "id":2908,
              "name":"Laborie",
              "country_id":662
           },
           {
              "id":2909,
              "name":"Micoud",
              "country_id":662
           },
           {
              "id":2910,
              "name":"Soufrière",
              "country_id":662
           },
           {
              "id":2911,
              "name":"Vieux-Fort",
              "country_id":662
           },
           {
              "id":2912,
              "name":"Praslin",
              "country_id":662
           }
        ],
        "663":[
     
        ],
        "666":[
           {
              "id":2913,
              "name":"Saint-Pierre-et-Miquelon",
              "country_id":666
           }
        ],
        "670":[
           {
              "id":2914,
              "name":"Charlotte",
              "country_id":670
           },
           {
              "id":2915,
              "name":"Saint Andrew",
              "country_id":670
           },
           {
              "id":2916,
              "name":"Saint David",
              "country_id":670
           },
           {
              "id":2917,
              "name":"Saint George",
              "country_id":670
           },
           {
              "id":2918,
              "name":"Saint Patrick",
              "country_id":670
           },
           {
              "id":2919,
              "name":"Grenadines",
              "country_id":670
           }
        ],
        "674":[
           {
              "id":2920,
              "name":"Acquaviva",
              "country_id":674
           },
           {
              "id":2921,
              "name":"Chiesanuova",
              "country_id":674
           },
           {
              "id":2922,
              "name":"Domagnano",
              "country_id":674
           },
           {
              "id":2923,
              "name":"Faetano",
              "country_id":674
           },
           {
              "id":2924,
              "name":"Fiorentino",
              "country_id":674
           },
           {
              "id":2925,
              "name":"Borgo Maggiore",
              "country_id":674
           },
           {
              "id":2926,
              "name":"San Marino",
              "country_id":674
           },
           {
              "id":2927,
              "name":"Montegiardino",
              "country_id":674
           },
           {
              "id":2928,
              "name":"Serravalle",
              "country_id":674
           }
        ],
        "678":[
           {
              "id":2929,
              "name":"Príncipe",
              "country_id":678
           },
           {
              "id":2930,
              "name":"Príncipe",
              "country_id":678
           },
           {
              "id":2931,
              "name":"São Tomé",
              "country_id":678
           }
        ],
        "682":[
           {
              "id":2932,
              "name":"Al Bāḩah",
              "country_id":682
           },
           {
              "id":2933,
              "name":"Al Madīnah",
              "country_id":682
           },
           {
              "id":2934,
              "name":"Ash Sharqīyah",
              "country_id":682
           },
           {
              "id":2935,
              "name":"Al Qaşīm",
              "country_id":682
           },
           {
              "id":2936,
              "name":"Ar Riyāḑ",
              "country_id":682
           },
           {
              "id":2937,
              "name":"‘Asīr",
              "country_id":682
           },
           {
              "id":2938,
              "name":"Ḩāʼil",
              "country_id":682
           },
           {
              "id":2939,
              "name":"Makkah",
              "country_id":682
           },
           {
              "id":2940,
              "name":"Northern Borders Region",
              "country_id":682
           },
           {
              "id":2941,
              "name":"Najrān",
              "country_id":682
           },
           {
              "id":2942,
              "name":"Jīzān",
              "country_id":682
           },
           {
              "id":2943,
              "name":"Tabūk",
              "country_id":682
           },
           {
              "id":2944,
              "name":"Al Jawf",
              "country_id":682
           }
        ],
        "686":[
           {
              "id":2945,
              "name":"Dakar",
              "country_id":686
           },
           {
              "id":2946,
              "name":"Diourbel",
              "country_id":686
           },
           {
              "id":2947,
              "name":"Saint-Louis",
              "country_id":686
           },
           {
              "id":2948,
              "name":"Tambacounda",
              "country_id":686
           },
           {
              "id":2949,
              "name":"Thiès",
              "country_id":686
           },
           {
              "id":2950,
              "name":"Louga",
              "country_id":686
           },
           {
              "id":2951,
              "name":"Fatick",
              "country_id":686
           },
           {
              "id":2952,
              "name":"Kaolack",
              "country_id":686
           },
           {
              "id":2953,
              "name":"Kolda Region",
              "country_id":686
           },
           {
              "id":2954,
              "name":"Ziguinchor",
              "country_id":686
           },
           {
              "id":2955,
              "name":"Louga",
              "country_id":686
           },
           {
              "id":2956,
              "name":"Saint-Louis",
              "country_id":686
           },
           {
              "id":2957,
              "name":"Matam",
              "country_id":686
           }
        ],
        "688":[
           {
              "id":2958,
              "name":"Autonomna Pokrajina Vojvodina",
              "country_id":688
           }
        ],
        "690":[
           {
              "id":2959,
              "name":"Anse aux Pins",
              "country_id":690
           },
           {
              "id":2960,
              "name":"Anse Boileau",
              "country_id":690
           },
           {
              "id":2961,
              "name":"Anse Etoile",
              "country_id":690
           },
           {
              "id":2962,
              "name":"Anse Louis",
              "country_id":690
           },
           {
              "id":2963,
              "name":"Anse Royale",
              "country_id":690
           },
           {
              "id":2964,
              "name":"Baie Lazare",
              "country_id":690
           },
           {
              "id":2965,
              "name":"Baie Sainte Anne",
              "country_id":690
           },
           {
              "id":2966,
              "name":"Beau Vallon",
              "country_id":690
           },
           {
              "id":2967,
              "name":"Bel Air",
              "country_id":690
           },
           {
              "id":2968,
              "name":"Bel Ombre",
              "country_id":690
           },
           {
              "id":2969,
              "name":"Cascade",
              "country_id":690
           },
           {
              "id":2970,
              "name":"Glacis",
              "country_id":690
           },
           {
              "id":2971,
              "name":"Saint Thomas Middle Island Parish",
              "country_id":690
           },
           {
              "id":2972,
              "name":"Grand Anse Praslin",
              "country_id":690
           },
           {
              "id":2973,
              "name":"Trinity Palmetto Point Parish",
              "country_id":690
           },
           {
              "id":2974,
              "name":"La Riviere Anglaise",
              "country_id":690
           },
           {
              "id":2975,
              "name":"Mont Buxton",
              "country_id":690
           },
           {
              "id":2976,
              "name":"Mont Fleuri",
              "country_id":690
           },
           {
              "id":2977,
              "name":"Plaisance",
              "country_id":690
           },
           {
              "id":2978,
              "name":"Pointe Larue",
              "country_id":690
           },
           {
              "id":2979,
              "name":"Port Glaud",
              "country_id":690
           },
           {
              "id":2980,
              "name":"Saint Louis",
              "country_id":690
           },
           {
              "id":2981,
              "name":"Takamaka",
              "country_id":690
           },
           {
              "id":2982,
              "name":"Anse aux Pins",
              "country_id":690
           },
           {
              "id":2983,
              "name":"Inner Islands",
              "country_id":690
           },
           {
              "id":2984,
              "name":"English River",
              "country_id":690
           },
           {
              "id":2985,
              "name":"Port Glaud",
              "country_id":690
           },
           {
              "id":2986,
              "name":"Baie Lazare",
              "country_id":690
           },
           {
              "id":2987,
              "name":"Beau Vallon",
              "country_id":690
           },
           {
              "id":2988,
              "name":"Bel Ombre",
              "country_id":690
           },
           {
              "id":2989,
              "name":"Glacis",
              "country_id":690
           },
           {
              "id":2990,
              "name":"Grand Anse Mahe",
              "country_id":690
           },
           {
              "id":2991,
              "name":"Grand Anse Praslin",
              "country_id":690
           },
           {
              "id":2992,
              "name":"Inner Islands",
              "country_id":690
           },
           {
              "id":2993,
              "name":"English River",
              "country_id":690
           },
           {
              "id":2994,
              "name":"Mont Fleuri",
              "country_id":690
           },
           {
              "id":2995,
              "name":"Plaisance",
              "country_id":690
           },
           {
              "id":2996,
              "name":"Pointe Larue",
              "country_id":690
           },
           {
              "id":2997,
              "name":"Port Glaud",
              "country_id":690
           },
           {
              "id":2998,
              "name":"Takamaka",
              "country_id":690
           },
           {
              "id":2999,
              "name":"Au Cap",
              "country_id":690
           },
           {
              "id":3000,
              "name":"Les Mamelles",
              "country_id":690
           },
           {
              "id":3001,
              "name":"Roche Caiman",
              "country_id":690
           }
        ],
        "694":[
           {
              "id":3002,
              "name":"Eastern Province",
              "country_id":694
           },
           {
              "id":3003,
              "name":"Northern Province",
              "country_id":694
           },
           {
              "id":3004,
              "name":"Southern Province",
              "country_id":694
           },
           {
              "id":3005,
              "name":"Western Area",
              "country_id":694
           }
        ],
        "702":[
           {
              "id":3006,
              "name":"Singapore",
              "country_id":702
           }
        ],
        "703":[
           {
              "id":3007,
              "name":"Banskobystrický",
              "country_id":703
           },
           {
              "id":3008,
              "name":"Bratislavský",
              "country_id":703
           },
           {
              "id":3009,
              "name":"Košický",
              "country_id":703
           },
           {
              "id":3010,
              "name":"Nitriansky",
              "country_id":703
           },
           {
              "id":3011,
              "name":"Prešovský",
              "country_id":703
           },
           {
              "id":3012,
              "name":"Trenčiansky",
              "country_id":703
           },
           {
              "id":3013,
              "name":"Trnavský",
              "country_id":703
           },
           {
              "id":3014,
              "name":"Žilinský",
              "country_id":703
           }
        ],
        "704":[
           {
              "id":3015,
              "name":"An Giang",
              "country_id":704
           },
           {
              "id":3016,
              "name":"Bắc Thái Tỉnh",
              "country_id":704
           },
           {
              "id":3017,
              "name":"Bến Tre",
              "country_id":704
           },
           {
              "id":3018,
              "name":"Cao Bang",
              "country_id":704
           },
           {
              "id":3019,
              "name":"Cao Bằng",
              "country_id":704
           },
           {
              "id":3020,
              "name":"Ten Bai",
              "country_id":704
           },
           {
              "id":3021,
              "name":"Ðồng Tháp",
              "country_id":704
           },
           {
              "id":3022,
              "name":"Hà Bắc Tỉnh",
              "country_id":704
           },
           {
              "id":3023,
              "name":"Hải Hưng Tỉnh",
              "country_id":704
           },
           {
              "id":3024,
              "name":"Hải Phòng",
              "country_id":704
           },
           {
              "id":3025,
              "name":"Hoa Binh",
              "country_id":704
           },
           {
              "id":3026,
              "name":"Ha Tay",
              "country_id":704
           },
           {
              "id":3027,
              "name":"Hồ Chí Minh",
              "country_id":704
           },
           {
              "id":3028,
              "name":"Kiến Giang",
              "country_id":704
           },
           {
              "id":3029,
              "name":"Lâm Ðồng",
              "country_id":704
           },
           {
              "id":3030,
              "name":"Long An",
              "country_id":704
           },
           {
              "id":3031,
              "name":"Minh Hải Tỉnh",
              "country_id":704
           },
           {
              "id":3032,
              "name":"Thua Thien-Hue",
              "country_id":704
           },
           {
              "id":3033,
              "name":"Quang Nam",
              "country_id":704
           },
           {
              "id":3034,
              "name":"Kon Tum",
              "country_id":704
           },
           {
              "id":3035,
              "name":"Quảng Nam-Ðà Nẵng Tỉnh",
              "country_id":704
           },
           {
              "id":3036,
              "name":"Quảng Ninh",
              "country_id":704
           },
           {
              "id":3037,
              "name":"Sông Bé Tỉnh",
              "country_id":704
           },
           {
              "id":3038,
              "name":"Sơn La",
              "country_id":704
           },
           {
              "id":3039,
              "name":"Tây Ninh",
              "country_id":704
           },
           {
              "id":3040,
              "name":"Thanh Hóa",
              "country_id":704
           },
           {
              "id":3041,
              "name":"Thái Bình",
              "country_id":704
           },
           {
              "id":3042,
              "name":"Nin Thuan",
              "country_id":704
           },
           {
              "id":3043,
              "name":"Tiền Giang",
              "country_id":704
           },
           {
              "id":3044,
              "name":"Vinh Phú Tỉnh",
              "country_id":704
           },
           {
              "id":3045,
              "name":"Lạng Sơn",
              "country_id":704
           },
           {
              "id":3046,
              "name":"Binh Thuan",
              "country_id":704
           },
           {
              "id":3047,
              "name":"Long An",
              "country_id":704
           },
           {
              "id":3048,
              "name":"Ðồng Nai",
              "country_id":704
           },
           {
              "id":3049,
              "name":"Ha Nội",
              "country_id":704
           },
           {
              "id":3050,
              "name":"Bà Rịa-Vũng Tàu",
              "country_id":704
           },
           {
              "id":3051,
              "name":"Bình Ðịnh",
              "country_id":704
           },
           {
              "id":3052,
              "name":"Bình Thuận",
              "country_id":704
           },
           {
              "id":3053,
              "name":"Gia Lai",
              "country_id":704
           },
           {
              "id":3054,
              "name":"Hà Giang",
              "country_id":704
           },
           {
              "id":3055,
              "name":"Hà Tây",
              "country_id":704
           },
           {
              "id":3056,
              "name":"Hà Tĩnh",
              "country_id":704
           },
           {
              "id":3057,
              "name":"Hòa Bình",
              "country_id":704
           },
           {
              "id":3058,
              "name":"Khánh Hòa",
              "country_id":704
           },
           {
              "id":3059,
              "name":"Kon Tum",
              "country_id":704
           },
           {
              "id":3060,
              "name":"Nam Hà Tỉnh",
              "country_id":704
           },
           {
              "id":3061,
              "name":"Nghệ An",
              "country_id":704
           },
           {
              "id":3062,
              "name":"Ninh Bình",
              "country_id":704
           },
           {
              "id":3063,
              "name":"Ninh Thuận",
              "country_id":704
           },
           {
              "id":3064,
              "name":"Phú Yên",
              "country_id":704
           },
           {
              "id":3065,
              "name":"Quảng Bình",
              "country_id":704
           },
           {
              "id":3066,
              "name":"Quảng Ngãi",
              "country_id":704
           },
           {
              "id":3067,
              "name":"Quảng Trị",
              "country_id":704
           },
           {
              "id":3068,
              "name":"Sóc Trăng",
              "country_id":704
           },
           {
              "id":3069,
              "name":"Thừa Thiên-Huế",
              "country_id":704
           },
           {
              "id":3070,
              "name":"Trà Vinh",
              "country_id":704
           },
           {
              "id":3071,
              "name":"Tuyên Quang",
              "country_id":704
           },
           {
              "id":3072,
              "name":"Vĩnh Long",
              "country_id":704
           },
           {
              "id":3073,
              "name":"Yên Bái",
              "country_id":704
           },
           {
              "id":3074,
              "name":"Bắc Giang",
              "country_id":704
           },
           {
              "id":3075,
              "name":"Bắc Kạn",
              "country_id":704
           },
           {
              "id":3076,
              "name":"Bạc Liêu",
              "country_id":704
           },
           {
              "id":3077,
              "name":"Bắc Ninh",
              "country_id":704
           },
           {
              "id":3078,
              "name":"Bình Dương",
              "country_id":704
           },
           {
              "id":3079,
              "name":"Bình Phước",
              "country_id":704
           },
           {
              "id":3080,
              "name":"Cà Mau",
              "country_id":704
           },
           {
              "id":3081,
              "name":"Ðà Nẵng",
              "country_id":704
           },
           {
              "id":3082,
              "name":"Hải Dương",
              "country_id":704
           },
           {
              "id":3083,
              "name":"Hà Nam",
              "country_id":704
           },
           {
              "id":3084,
              "name":"Hưng Yên",
              "country_id":704
           },
           {
              "id":3085,
              "name":"Nam Ðịnh",
              "country_id":704
           },
           {
              "id":3086,
              "name":"Phú Thọ",
              "country_id":704
           },
           {
              "id":3087,
              "name":"Quảng Nam",
              "country_id":704
           },
           {
              "id":3088,
              "name":"Thái Nguyên",
              "country_id":704
           },
           {
              "id":3089,
              "name":"Vĩnh Phúc",
              "country_id":704
           },
           {
              "id":3090,
              "name":"Cần Thơ",
              "country_id":704
           },
           {
              "id":3091,
              "name":"Ðắc Lắk",
              "country_id":704
           },
           {
              "id":3092,
              "name":"Lai Châu",
              "country_id":704
           },
           {
              "id":3093,
              "name":"Lào Cai",
              "country_id":704
           }
        ],
        "705":[
           {
              "id":3094,
              "name":"Notranjska",
              "country_id":705
           },
           {
              "id":3095,
              "name":"Koroška",
              "country_id":705
           },
           {
              "id":3096,
              "name":"Štajerska",
              "country_id":705
           },
           {
              "id":3097,
              "name":"Prekmurje",
              "country_id":705
           },
           {
              "id":3098,
              "name":"Primorska",
              "country_id":705
           },
           {
              "id":3099,
              "name":"Gorenjska",
              "country_id":705
           },
           {
              "id":3100,
              "name":"Dolenjska",
              "country_id":705
           }
        ],
        "706":[
           {
              "id":3101,
              "name":"Bakool",
              "country_id":706
           },
           {
              "id":3102,
              "name":"Banaadir",
              "country_id":706
           },
           {
              "id":3103,
              "name":"Bari",
              "country_id":706
           },
           {
              "id":3104,
              "name":"Bay",
              "country_id":706
           },
           {
              "id":3105,
              "name":"Galguduud",
              "country_id":706
           },
           {
              "id":3106,
              "name":"Gedo",
              "country_id":706
           },
           {
              "id":3107,
              "name":"Hiiraan",
              "country_id":706
           },
           {
              "id":3108,
              "name":"Middle Juba",
              "country_id":706
           },
           {
              "id":3109,
              "name":"Lower Juba",
              "country_id":706
           },
           {
              "id":3110,
              "name":"Mudug",
              "country_id":706
           },
           {
              "id":3111,
              "name":"Sanaag",
              "country_id":706
           },
           {
              "id":3112,
              "name":"Middle Shabele",
              "country_id":706
           },
           {
              "id":3113,
              "name":"Shabeellaha Hoose",
              "country_id":706
           },
           {
              "id":3114,
              "name":"Nugaal",
              "country_id":706
           },
           {
              "id":3115,
              "name":"Togdheer",
              "country_id":706
           },
           {
              "id":3116,
              "name":"Woqooyi Galbeed",
              "country_id":706
           },
           {
              "id":3117,
              "name":"Awdal",
              "country_id":706
           },
           {
              "id":3118,
              "name":"Sool",
              "country_id":706
           }
        ],
        "710":[
           {
              "id":3119,
              "name":"KwaZulu-Natal",
              "country_id":710
           },
           {
              "id":3120,
              "name":"Free State",
              "country_id":710
           },
           {
              "id":3121,
              "name":"Eastern Cape",
              "country_id":710
           },
           {
              "id":3122,
              "name":"Gauteng",
              "country_id":710
           },
           {
              "id":3123,
              "name":"Mpumalanga",
              "country_id":710
           },
           {
              "id":3124,
              "name":"Northern Cape",
              "country_id":710
           },
           {
              "id":3125,
              "name":"Limpopo",
              "country_id":710
           },
           {
              "id":3126,
              "name":"North-West",
              "country_id":710
           },
           {
              "id":3127,
              "name":"Western Cape",
              "country_id":710
           }
        ],
        "716":[
           {
              "id":3128,
              "name":"Manicaland",
              "country_id":716
           },
           {
              "id":3129,
              "name":"Midlands",
              "country_id":716
           },
           {
              "id":3130,
              "name":"Mashonaland Central",
              "country_id":716
           },
           {
              "id":3131,
              "name":"Mashonaland East",
              "country_id":716
           },
           {
              "id":3132,
              "name":"Mashonaland West",
              "country_id":716
           },
           {
              "id":3133,
              "name":"Matabeleland North",
              "country_id":716
           },
           {
              "id":3134,
              "name":"Matabeleland South",
              "country_id":716
           },
           {
              "id":3135,
              "name":"Masvingo",
              "country_id":716
           },
           {
              "id":3136,
              "name":"Bulawayo",
              "country_id":716
           },
           {
              "id":3137,
              "name":"Harare",
              "country_id":716
           }
        ],
        "724":[
           {
              "id":3138,
              "name":"Ceuta",
              "country_id":724
           },
           {
              "id":3139,
              "name":"Balearic Islands",
              "country_id":724
           },
           {
              "id":3140,
              "name":"La Rioja",
              "country_id":724
           },
           {
              "id":3141,
              "name":"Autonomous Region of Madrid",
              "country_id":724
           },
           {
              "id":3142,
              "name":"Murcia",
              "country_id":724
           },
           {
              "id":3143,
              "name":"Navarre",
              "country_id":724
           },
           {
              "id":3144,
              "name":"Asturias",
              "country_id":724
           },
           {
              "id":3145,
              "name":"Cantabria",
              "country_id":724
           },
           {
              "id":3146,
              "name":"Andalusia",
              "country_id":724
           },
           {
              "id":3147,
              "name":"Aragon",
              "country_id":724
           },
           {
              "id":3148,
              "name":"Canary Islands",
              "country_id":724
           },
           {
              "id":3149,
              "name":"Castille-La Mancha",
              "country_id":724
           },
           {
              "id":3150,
              "name":"Castille and León",
              "country_id":724
           },
           {
              "id":3151,
              "name":"Catalonia",
              "country_id":724
           },
           {
              "id":3152,
              "name":"Extremadura",
              "country_id":724
           },
           {
              "id":3153,
              "name":"Galicia",
              "country_id":724
           },
           {
              "id":3154,
              "name":"Basque Country",
              "country_id":724
           },
           {
              "id":3155,
              "name":"Valencia",
              "country_id":724
           }
        ],
        "732":[
           {
              "id":3156,
              "name":"Western Sahara",
              "country_id":732
           }
        ],
        "736":[
           {
              "id":3157,
              "name":"Al Wilāyah al Wusţá",
              "country_id":736
           },
           {
              "id":3158,
              "name":"Al Wilāyah al Istiwāʼīyah",
              "country_id":736
           },
           {
              "id":3159,
              "name":"Khartoum",
              "country_id":736
           },
           {
              "id":3160,
              "name":"Ash Shamaliyah",
              "country_id":736
           },
           {
              "id":3161,
              "name":"Al Wilāyah ash Sharqīyah",
              "country_id":736
           },
           {
              "id":3162,
              "name":"Ba?r al Ghazal Wilayat",
              "country_id":736
           },
           {
              "id":3163,
              "name":"Darfur Wilayat",
              "country_id":736
           },
           {
              "id":3164,
              "name":"Kurdufan Wilayat",
              "country_id":736
           },
           {
              "id":3165,
              "name":"Upper Nile",
              "country_id":736
           },
           {
              "id":3166,
              "name":"Red Sea",
              "country_id":736
           },
           {
              "id":3167,
              "name":"Lakes",
              "country_id":736
           },
           {
              "id":3168,
              "name":"Al Jazirah",
              "country_id":736
           },
           {
              "id":3169,
              "name":"Al Qadarif",
              "country_id":736
           },
           {
              "id":3170,
              "name":"Unity",
              "country_id":736
           },
           {
              "id":3171,
              "name":"White Nile",
              "country_id":736
           },
           {
              "id":3172,
              "name":"Blue Nile",
              "country_id":736
           },
           {
              "id":3173,
              "name":"Northern",
              "country_id":736
           },
           {
              "id":3174,
              "name":"Central Equatoria",
              "country_id":736
           },
           {
              "id":3175,
              "name":"Gharb al Istiwāʼīyah",
              "country_id":736
           },
           {
              "id":3176,
              "name":"Western Bahr al Ghazal",
              "country_id":736
           },
           {
              "id":3177,
              "name":"Gharb Dārfūr",
              "country_id":736
           },
           {
              "id":3178,
              "name":"Gharb Kurdufān",
              "country_id":736
           },
           {
              "id":3179,
              "name":"Janūb Dārfūr",
              "country_id":736
           },
           {
              "id":3180,
              "name":"Janūb Kurdufān",
              "country_id":736
           },
           {
              "id":3181,
              "name":"Junqalī",
              "country_id":736
           },
           {
              "id":3182,
              "name":"Kassalā",
              "country_id":736
           },
           {
              "id":3183,
              "name":"Nahr an Nīl",
              "country_id":736
           },
           {
              "id":3184,
              "name":"Shamāl Baḩr al Ghazāl",
              "country_id":736
           },
           {
              "id":3185,
              "name":"Shamāl Dārfūr",
              "country_id":736
           },
           {
              "id":3186,
              "name":"Shamāl Kurdufān",
              "country_id":736
           },
           {
              "id":3187,
              "name":"Eastern Equatoria",
              "country_id":736
           },
           {
              "id":3188,
              "name":"Sinnār",
              "country_id":736
           },
           {
              "id":3189,
              "name":"Warab",
              "country_id":736
           }
        ],
        "740":[
           {
              "id":3190,
              "name":"Brokopondo",
              "country_id":740
           },
           {
              "id":3191,
              "name":"Commewijne",
              "country_id":740
           },
           {
              "id":3192,
              "name":"Coronie",
              "country_id":740
           },
           {
              "id":3193,
              "name":"Marowijne",
              "country_id":740
           },
           {
              "id":3194,
              "name":"Nickerie",
              "country_id":740
           },
           {
              "id":3195,
              "name":"Para",
              "country_id":740
           },
           {
              "id":3196,
              "name":"Paramaribo",
              "country_id":740
           },
           {
              "id":3197,
              "name":"Saramacca",
              "country_id":740
           },
           {
              "id":3198,
              "name":"Sipaliwini",
              "country_id":740
           },
           {
              "id":3199,
              "name":"Wanica",
              "country_id":740
           }
        ],
        "744":[
     
        ],
        "748":[
           {
              "id":3200,
              "name":"Hhohho",
              "country_id":748
           },
           {
              "id":3201,
              "name":"Lubombo",
              "country_id":748
           },
           {
              "id":3202,
              "name":"Manzini",
              "country_id":748
           },
           {
              "id":3203,
              "name":"Shiselweni",
              "country_id":748
           }
        ],
        "752":[
           {
              "id":3204,
              "name":"Blekinge",
              "country_id":752
           },
           {
              "id":3205,
              "name":"Gävleborg",
              "country_id":752
           },
           {
              "id":3206,
              "name":"Gotland",
              "country_id":752
           },
           {
              "id":3207,
              "name":"Halland",
              "country_id":752
           },
           {
              "id":3208,
              "name":"Jämtland",
              "country_id":752
           },
           {
              "id":3209,
              "name":"Jönköping",
              "country_id":752
           },
           {
              "id":3210,
              "name":"Kalmar",
              "country_id":752
           },
           {
              "id":3211,
              "name":"Dalarna",
              "country_id":752
           },
           {
              "id":3212,
              "name":"Kronoberg",
              "country_id":752
           },
           {
              "id":3213,
              "name":"Norrbotten",
              "country_id":752
           },
           {
              "id":3214,
              "name":"Örebro",
              "country_id":752
           },
           {
              "id":3215,
              "name":"Östergötland",
              "country_id":752
           },
           {
              "id":3216,
              "name":"Södermanland",
              "country_id":752
           },
           {
              "id":3217,
              "name":"Uppsala",
              "country_id":752
           },
           {
              "id":3218,
              "name":"Värmland",
              "country_id":752
           },
           {
              "id":3219,
              "name":"Västerbotten",
              "country_id":752
           },
           {
              "id":3220,
              "name":"Västernorrland",
              "country_id":752
           },
           {
              "id":3221,
              "name":"Västmanland",
              "country_id":752
           },
           {
              "id":3222,
              "name":"Stockholm",
              "country_id":752
           },
           {
              "id":3223,
              "name":"Skåne",
              "country_id":752
           },
           {
              "id":3224,
              "name":"Västra Götaland",
              "country_id":752
           }
        ],
        "756":[
           {
              "id":3225,
              "name":"Aargau",
              "country_id":756
           },
           {
              "id":3226,
              "name":"Appenzell Innerrhoden",
              "country_id":756
           },
           {
              "id":3227,
              "name":"Appenzell Ausserrhoden",
              "country_id":756
           },
           {
              "id":3228,
              "name":"Bern",
              "country_id":756
           },
           {
              "id":3229,
              "name":"Basel-Landschaft",
              "country_id":756
           },
           {
              "id":3230,
              "name":"Kanton Basel-Stadt",
              "country_id":756
           },
           {
              "id":3231,
              "name":"Fribourg",
              "country_id":756
           },
           {
              "id":3232,
              "name":"Genève",
              "country_id":756
           },
           {
              "id":3233,
              "name":"Glarus",
              "country_id":756
           },
           {
              "id":3234,
              "name":"Graubünden",
              "country_id":756
           },
           {
              "id":3235,
              "name":"Jura",
              "country_id":756
           },
           {
              "id":3236,
              "name":"Luzern",
              "country_id":756
           },
           {
              "id":3237,
              "name":"Neuchâtel",
              "country_id":756
           },
           {
              "id":3238,
              "name":"Nidwalden",
              "country_id":756
           },
           {
              "id":3239,
              "name":"Obwalden",
              "country_id":756
           },
           {
              "id":3240,
              "name":"Kanton St. Gallen",
              "country_id":756
           },
           {
              "id":3241,
              "name":"Schaffhausen",
              "country_id":756
           },
           {
              "id":3242,
              "name":"Solothurn",
              "country_id":756
           },
           {
              "id":3243,
              "name":"Schwyz",
              "country_id":756
           },
           {
              "id":3244,
              "name":"Thurgau",
              "country_id":756
           },
           {
              "id":3245,
              "name":"Ticino",
              "country_id":756
           },
           {
              "id":3246,
              "name":"Uri",
              "country_id":756
           },
           {
              "id":3247,
              "name":"Vaud",
              "country_id":756
           },
           {
              "id":3248,
              "name":"Valais",
              "country_id":756
           },
           {
              "id":3249,
              "name":"Zug",
              "country_id":756
           },
           {
              "id":3250,
              "name":"Zürich",
              "country_id":756
           }
        ],
        "760":[
           {
              "id":3251,
              "name":"Al-Hasakah",
              "country_id":760
           },
           {
              "id":3252,
              "name":"Latakia",
              "country_id":760
           },
           {
              "id":3253,
              "name":"Quneitra",
              "country_id":760
           },
           {
              "id":3254,
              "name":"Ar-Raqqah",
              "country_id":760
           },
           {
              "id":3255,
              "name":"As-Suwayda",
              "country_id":760
           },
           {
              "id":3256,
              "name":"Daraa",
              "country_id":760
           },
           {
              "id":3257,
              "name":"Deir ez-Zor",
              "country_id":760
           },
           {
              "id":3258,
              "name":"Rif-dimashq",
              "country_id":760
           },
           {
              "id":3259,
              "name":"Aleppo",
              "country_id":760
           },
           {
              "id":3260,
              "name":"Hama Governorate",
              "country_id":760
           },
           {
              "id":3261,
              "name":"Homs",
              "country_id":760
           },
           {
              "id":3262,
              "name":"Idlib",
              "country_id":760
           },
           {
              "id":3263,
              "name":"Damascus City",
              "country_id":760
           },
           {
              "id":3264,
              "name":"Tartus",
              "country_id":760
           }
        ],
        "762":[
           {
              "id":3265,
              "name":"Gorno-Badakhshan",
              "country_id":762
           },
           {
              "id":3266,
              "name":"Khatlon",
              "country_id":762
           },
           {
              "id":3267,
              "name":"Sughd",
              "country_id":762
           },
           {
              "id":3268,
              "name":"Dushanbe",
              "country_id":762
           },
           {
              "id":3269,
              "name":"Region of Republican Subordination",
              "country_id":762
           }
        ],
        "764":[
           {
              "id":3270,
              "name":"Mae Hong Son",
              "country_id":764
           },
           {
              "id":3271,
              "name":"Chiang Mai",
              "country_id":764
           },
           {
              "id":3272,
              "name":"Chiang Rai",
              "country_id":764
           },
           {
              "id":3273,
              "name":"Nan",
              "country_id":764
           },
           {
              "id":3274,
              "name":"Lamphun",
              "country_id":764
           },
           {
              "id":3275,
              "name":"Lampang",
              "country_id":764
           },
           {
              "id":3276,
              "name":"Phrae",
              "country_id":764
           },
           {
              "id":3277,
              "name":"Tak",
              "country_id":764
           },
           {
              "id":3278,
              "name":"Sukhothai",
              "country_id":764
           },
           {
              "id":3279,
              "name":"Uttaradit",
              "country_id":764
           },
           {
              "id":3280,
              "name":"Kamphaeng Phet",
              "country_id":764
           },
           {
              "id":3281,
              "name":"Phitsanulok",
              "country_id":764
           },
           {
              "id":3282,
              "name":"Phichit",
              "country_id":764
           },
           {
              "id":3283,
              "name":"Phetchabun",
              "country_id":764
           },
           {
              "id":3284,
              "name":"Uthai Thani",
              "country_id":764
           },
           {
              "id":3285,
              "name":"Nakhon Sawan",
              "country_id":764
           },
           {
              "id":3286,
              "name":"Nong Khai",
              "country_id":764
           },
           {
              "id":3287,
              "name":"Loei",
              "country_id":764
           },
           {
              "id":3288,
              "name":"Sakon Nakhon",
              "country_id":764
           },
           {
              "id":3289,
              "name":"Nakhon Phanom",
              "country_id":764
           },
           {
              "id":3290,
              "name":"Khon Kaen",
              "country_id":764
           },
           {
              "id":3291,
              "name":"Kalasin",
              "country_id":764
           },
           {
              "id":3292,
              "name":"Maha Sarakham",
              "country_id":764
           },
           {
              "id":3293,
              "name":"Roi Et",
              "country_id":764
           },
           {
              "id":3294,
              "name":"Chaiyaphum",
              "country_id":764
           },
           {
              "id":3295,
              "name":"Nakhon Ratchasima",
              "country_id":764
           },
           {
              "id":3296,
              "name":"Buriram",
              "country_id":764
           },
           {
              "id":3297,
              "name":"Surin",
              "country_id":764
           },
           {
              "id":3298,
              "name":"Sisaket",
              "country_id":764
           },
           {
              "id":3299,
              "name":"Narathiwat",
              "country_id":764
           },
           {
              "id":3300,
              "name":"Chai Nat",
              "country_id":764
           },
           {
              "id":3301,
              "name":"Sing Buri",
              "country_id":764
           },
           {
              "id":3302,
              "name":"Lop Buri",
              "country_id":764
           },
           {
              "id":3303,
              "name":"Ang Thong",
              "country_id":764
           },
           {
              "id":3304,
              "name":"Phra Nakhon Si Ayutthaya",
              "country_id":764
           },
           {
              "id":3305,
              "name":"Sara Buri",
              "country_id":764
           },
           {
              "id":3306,
              "name":"Nonthaburi",
              "country_id":764
           },
           {
              "id":3307,
              "name":"Pathum Thani",
              "country_id":764
           },
           {
              "id":3308,
              "name":"Bangkok",
              "country_id":764
           },
           {
              "id":3309,
              "name":"Phayao",
              "country_id":764
           },
           {
              "id":3310,
              "name":"Samut Prakan",
              "country_id":764
           },
           {
              "id":3311,
              "name":"Nakhon Nayok",
              "country_id":764
           },
           {
              "id":3312,
              "name":"Chachoengsao",
              "country_id":764
           },
           {
              "id":3313,
              "name":"Chon Buri",
              "country_id":764
           },
           {
              "id":3314,
              "name":"Rayong",
              "country_id":764
           },
           {
              "id":3315,
              "name":"Chanthaburi",
              "country_id":764
           },
           {
              "id":3316,
              "name":"Trat",
              "country_id":764
           },
           {
              "id":3317,
              "name":"Kanchanaburi",
              "country_id":764
           },
           {
              "id":3318,
              "name":"Suphan Buri",
              "country_id":764
           },
           {
              "id":3319,
              "name":"Ratchaburi",
              "country_id":764
           },
           {
              "id":3320,
              "name":"Nakhon Pathom",
              "country_id":764
           },
           {
              "id":3321,
              "name":"Samut Songkhram",
              "country_id":764
           },
           {
              "id":3322,
              "name":"Samut Sakhon",
              "country_id":764
           },
           {
              "id":3323,
              "name":"Phetchaburi",
              "country_id":764
           },
           {
              "id":3324,
              "name":"Prachuap Khiri Khan",
              "country_id":764
           },
           {
              "id":3325,
              "name":"Chumphon",
              "country_id":764
           },
           {
              "id":3326,
              "name":"Ranong",
              "country_id":764
           },
           {
              "id":3327,
              "name":"Surat Thani",
              "country_id":764
           },
           {
              "id":3328,
              "name":"Phangnga",
              "country_id":764
           },
           {
              "id":3329,
              "name":"Phuket",
              "country_id":764
           },
           {
              "id":3330,
              "name":"Krabi",
              "country_id":764
           },
           {
              "id":3331,
              "name":"Nakhon Si Thammarat",
              "country_id":764
           },
           {
              "id":3332,
              "name":"Trang",
              "country_id":764
           },
           {
              "id":3333,
              "name":"Phatthalung",
              "country_id":764
           },
           {
              "id":3334,
              "name":"Satun",
              "country_id":764
           },
           {
              "id":3335,
              "name":"Songkhla",
              "country_id":764
           },
           {
              "id":3336,
              "name":"Pattani",
              "country_id":764
           },
           {
              "id":3337,
              "name":"Yala",
              "country_id":764
           },
           {
              "id":3338,
              "name":"Yasothon",
              "country_id":764
           },
           {
              "id":3339,
              "name":"Nakhon Phanom",
              "country_id":764
           },
           {
              "id":3340,
              "name":"Prachin Buri",
              "country_id":764
           },
           {
              "id":3341,
              "name":"Ubon Ratchathani",
              "country_id":764
           },
           {
              "id":3342,
              "name":"Udon Thani",
              "country_id":764
           },
           {
              "id":3343,
              "name":"Amnat Charoen",
              "country_id":764
           },
           {
              "id":3344,
              "name":"Mukdahan",
              "country_id":764
           },
           {
              "id":3345,
              "name":"Nong Bua Lamphu",
              "country_id":764
           },
           {
              "id":3346,
              "name":"Sa Kaeo",
              "country_id":764
           }
        ],
        "768":[
           {
              "id":3347,
              "name":"Amlame",
              "country_id":768
           },
           {
              "id":3348,
              "name":"Aneho",
              "country_id":768
           },
           {
              "id":3349,
              "name":"Atakpame",
              "country_id":768
           },
           {
              "id":3350,
              "name":"Bafilo",
              "country_id":768
           },
           {
              "id":3351,
              "name":"Bassar",
              "country_id":768
           },
           {
              "id":3352,
              "name":"Dapaong",
              "country_id":768
           },
           {
              "id":3353,
              "name":"Kante",
              "country_id":768
           },
           {
              "id":3354,
              "name":"Klouto",
              "country_id":768
           },
           {
              "id":3355,
              "name":"Lama-Kara",
              "country_id":768
           },
           {
              "id":3356,
              "name":"Lome",
              "country_id":768
           },
           {
              "id":3357,
              "name":"Mango",
              "country_id":768
           },
           {
              "id":3358,
              "name":"Niamtougou",
              "country_id":768
           },
           {
              "id":3359,
              "name":"Notse",
              "country_id":768
           },
           {
              "id":3360,
              "name":"Kpagouda",
              "country_id":768
           },
           {
              "id":3361,
              "name":"Badou",
              "country_id":768
           },
           {
              "id":3362,
              "name":"Sotouboua",
              "country_id":768
           },
           {
              "id":3363,
              "name":"Tabligbo",
              "country_id":768
           },
           {
              "id":3364,
              "name":"Tsevie",
              "country_id":768
           },
           {
              "id":3365,
              "name":"Tchamba",
              "country_id":768
           },
           {
              "id":3366,
              "name":"Tchaoudjo",
              "country_id":768
           },
           {
              "id":3367,
              "name":"Vogan",
              "country_id":768
           },
           {
              "id":3368,
              "name":"Centrale",
              "country_id":768
           },
           {
              "id":3369,
              "name":"Kara",
              "country_id":768
           },
           {
              "id":3370,
              "name":"Maritime",
              "country_id":768
           },
           {
              "id":3371,
              "name":"Plateaux",
              "country_id":768
           },
           {
              "id":3372,
              "name":"Savanes",
              "country_id":768
           }
        ],
        "772":[
           {
              "id":3373,
              "name":"Tokelau",
              "country_id":772
           }
        ],
        "776":[
           {
              "id":3374,
              "name":"Ha‘apai",
              "country_id":776
           },
           {
              "id":3375,
              "name":"Tongatapu",
              "country_id":776
           },
           {
              "id":3376,
              "name":"Vava‘u",
              "country_id":776
           }
        ],
        "780":[
           {
              "id":3377,
              "name":"Port of Spain",
              "country_id":780
           },
           {
              "id":3378,
              "name":"San Fernando",
              "country_id":780
           },
           {
              "id":3379,
              "name":"Chaguanas",
              "country_id":780
           },
           {
              "id":3380,
              "name":"Arima",
              "country_id":780
           },
           {
              "id":3381,
              "name":"Point Fortin Borough",
              "country_id":780
           },
           {
              "id":3382,
              "name":"Couva-Tabaquite-Talparo",
              "country_id":780
           },
           {
              "id":3383,
              "name":"Diego Martin",
              "country_id":780
           },
           {
              "id":3384,
              "name":"Penal-Debe",
              "country_id":780
           },
           {
              "id":3385,
              "name":"Princes Town",
              "country_id":780
           },
           {
              "id":3386,
              "name":"Rio Claro-Mayaro",
              "country_id":780
           },
           {
              "id":3387,
              "name":"San Juan-Laventille",
              "country_id":780
           },
           {
              "id":3388,
              "name":"Sangre Grande",
              "country_id":780
           },
           {
              "id":3389,
              "name":"Siparia",
              "country_id":780
           },
           {
              "id":3390,
              "name":"Tunapuna-Piarco",
              "country_id":780
           }
        ],
        "784":[
           {
              "id":3391,
              "name":"Abu Dhabi",
              "country_id":784
           },
           {
              "id":3392,
              "name":"Ajman",
              "country_id":784
           },
           {
              "id":3393,
              "name":"Dubai",
              "country_id":784
           },
           {
              "id":3394,
              "name":"Fujairah",
              "country_id":784
           },
           {
              "id":3395,
              "name":"Ras Al Khaimah",
              "country_id":784
           },
           {
              "id":3396,
              "name":"Sharjah",
              "country_id":784
           },
           {
              "id":3397,
              "name":"Umm Al Quwain",
              "country_id":784
           }
        ],
        "788":[
           {
              "id":3398,
              "name":"Tunis al Janubiyah Wilayat",
              "country_id":788
           },
           {
              "id":3399,
              "name":"Al Qaşrayn",
              "country_id":788
           },
           {
              "id":3400,
              "name":"Al Qayrawān",
              "country_id":788
           },
           {
              "id":3401,
              "name":"Jundūbah",
              "country_id":788
           },
           {
              "id":3402,
              "name":"Kef",
              "country_id":788
           },
           {
              "id":3403,
              "name":"Al Mahdīyah",
              "country_id":788
           },
           {
              "id":3404,
              "name":"Al Munastīr",
              "country_id":788
           },
           {
              "id":3405,
              "name":"Bājah",
              "country_id":788
           },
           {
              "id":3406,
              "name":"Banzart",
              "country_id":788
           },
           {
              "id":3407,
              "name":"Nābul",
              "country_id":788
           },
           {
              "id":3408,
              "name":"Silyānah",
              "country_id":788
           },
           {
              "id":3409,
              "name":"Sūsah",
              "country_id":788
           },
           {
              "id":3410,
              "name":"Bin ‘Arūs",
              "country_id":788
           },
           {
              "id":3411,
              "name":"Madanīn",
              "country_id":788
           },
           {
              "id":3412,
              "name":"Qābis",
              "country_id":788
           },
           {
              "id":3413,
              "name":"Qafşah",
              "country_id":788
           },
           {
              "id":3414,
              "name":"Qibilī",
              "country_id":788
           },
           {
              "id":3415,
              "name":"Şafāqis",
              "country_id":788
           },
           {
              "id":3416,
              "name":"Sīdī Bū Zayd",
              "country_id":788
           },
           {
              "id":3417,
              "name":"Taţāwīn",
              "country_id":788
           },
           {
              "id":3418,
              "name":"Tawzar",
              "country_id":788
           },
           {
              "id":3419,
              "name":"Tūnis",
              "country_id":788
           },
           {
              "id":3420,
              "name":"Zaghwān",
              "country_id":788
           },
           {
              "id":3421,
              "name":"Ariana",
              "country_id":788
           },
           {
              "id":3422,
              "name":"Manouba",
              "country_id":788
           }
        ],
        "792":[
           {
              "id":3423,
              "name":"Adıyaman",
              "country_id":792
           },
           {
              "id":3424,
              "name":"Afyonkarahisar",
              "country_id":792
           },
           {
              "id":3425,
              "name":"Ağrı Province",
              "country_id":792
           },
           {
              "id":3426,
              "name":"Amasya Province",
              "country_id":792
           },
           {
              "id":3427,
              "name":"Antalya Province",
              "country_id":792
           },
           {
              "id":3428,
              "name":"Artvin Province",
              "country_id":792
           },
           {
              "id":3429,
              "name":"Aydın Province",
              "country_id":792
           },
           {
              "id":3430,
              "name":"Balıkesir Province",
              "country_id":792
           },
           {
              "id":3431,
              "name":"Bilecik Province",
              "country_id":792
           },
           {
              "id":3432,
              "name":"Bingöl Province",
              "country_id":792
           },
           {
              "id":3433,
              "name":"Bitlis Province",
              "country_id":792
           },
           {
              "id":3434,
              "name":"Bolu Province",
              "country_id":792
           },
           {
              "id":3435,
              "name":"Burdur Province",
              "country_id":792
           },
           {
              "id":3436,
              "name":"Bursa",
              "country_id":792
           },
           {
              "id":3437,
              "name":"Çanakkale Province",
              "country_id":792
           },
           {
              "id":3438,
              "name":"Çorum Province",
              "country_id":792
           },
           {
              "id":3439,
              "name":"Denizli Province",
              "country_id":792
           },
           {
              "id":3440,
              "name":"Diyarbakır",
              "country_id":792
           },
           {
              "id":3441,
              "name":"Edirne Province",
              "country_id":792
           },
           {
              "id":3442,
              "name":"Elazığ",
              "country_id":792
           },
           {
              "id":3443,
              "name":"Erzincan Province",
              "country_id":792
           },
           {
              "id":3444,
              "name":"Erzurum Province",
              "country_id":792
           },
           {
              "id":3445,
              "name":"Eskişehir Province",
              "country_id":792
           },
           {
              "id":3446,
              "name":"Giresun Province",
              "country_id":792
           },
           {
              "id":3447,
              "name":"Hatay Province",
              "country_id":792
           },
           {
              "id":3448,
              "name":"Mersin Province",
              "country_id":792
           },
           {
              "id":3449,
              "name":"Isparta Province",
              "country_id":792
           },
           {
              "id":3450,
              "name":"Istanbul",
              "country_id":792
           },
           {
              "id":3451,
              "name":"İzmir",
              "country_id":792
           },
           {
              "id":3452,
              "name":"Kastamonu Province",
              "country_id":792
           },
           {
              "id":3453,
              "name":"Kayseri Province",
              "country_id":792
           },
           {
              "id":3454,
              "name":"Kırklareli Province",
              "country_id":792
           },
           {
              "id":3455,
              "name":"Kırşehir Province",
              "country_id":792
           },
           {
              "id":3456,
              "name":"Kocaeli Province",
              "country_id":792
           },
           {
              "id":3457,
              "name":"Kütahya Province",
              "country_id":792
           },
           {
              "id":3458,
              "name":"Malatya Province",
              "country_id":792
           },
           {
              "id":3459,
              "name":"Manisa Province",
              "country_id":792
           },
           {
              "id":3460,
              "name":"Kahramanmaraş Province",
              "country_id":792
           },
           {
              "id":3461,
              "name":"Muğla Province",
              "country_id":792
           },
           {
              "id":3462,
              "name":"Muş Province",
              "country_id":792
           },
           {
              "id":3463,
              "name":"Nevşehir",
              "country_id":792
           },
           {
              "id":3464,
              "name":"Ordu",
              "country_id":792
           },
           {
              "id":3465,
              "name":"Rize",
              "country_id":792
           },
           {
              "id":3466,
              "name":"Sakarya Province",
              "country_id":792
           },
           {
              "id":3467,
              "name":"Samsun Province",
              "country_id":792
           },
           {
              "id":3468,
              "name":"Sinop Province",
              "country_id":792
           },
           {
              "id":3469,
              "name":"Sivas Province",
              "country_id":792
           },
           {
              "id":3470,
              "name":"Tekirdağ Province",
              "country_id":792
           },
           {
              "id":3471,
              "name":"Tokat",
              "country_id":792
           },
           {
              "id":3472,
              "name":"Trabzon Province",
              "country_id":792
           },
           {
              "id":3473,
              "name":"Tunceli Province",
              "country_id":792
           },
           {
              "id":3474,
              "name":"Şanlıurfa Province",
              "country_id":792
           },
           {
              "id":3475,
              "name":"Uşak Province",
              "country_id":792
           },
           {
              "id":3476,
              "name":"Van Province",
              "country_id":792
           },
           {
              "id":3477,
              "name":"Yozgat Province",
              "country_id":792
           },
           {
              "id":3478,
              "name":"Ankara Province",
              "country_id":792
           },
           {
              "id":3479,
              "name":"Gümüşhane",
              "country_id":792
           },
           {
              "id":3480,
              "name":"Hakkâri Province",
              "country_id":792
           },
           {
              "id":3481,
              "name":"Konya Province",
              "country_id":792
           },
           {
              "id":3482,
              "name":"Mardin Province",
              "country_id":792
           },
           {
              "id":3483,
              "name":"Niğde",
              "country_id":792
           },
           {
              "id":3484,
              "name":"Siirt Province",
              "country_id":792
           },
           {
              "id":3485,
              "name":"Aksaray Province",
              "country_id":792
           },
           {
              "id":3486,
              "name":"Batman Province",
              "country_id":792
           },
           {
              "id":3487,
              "name":"Bayburt",
              "country_id":792
           },
           {
              "id":3488,
              "name":"Karaman Province",
              "country_id":792
           },
           {
              "id":3489,
              "name":"Kırıkkale Province",
              "country_id":792
           },
           {
              "id":3490,
              "name":"Şırnak Province",
              "country_id":792
           },
           {
              "id":3491,
              "name":"Adana Province",
              "country_id":792
           },
           {
              "id":3492,
              "name":"Çankırı Province",
              "country_id":792
           },
           {
              "id":3493,
              "name":"Gaziantep Province",
              "country_id":792
           },
           {
              "id":3494,
              "name":"Kars",
              "country_id":792
           },
           {
              "id":3495,
              "name":"Zonguldak",
              "country_id":792
           },
           {
              "id":3496,
              "name":"Ardahan Province",
              "country_id":792
           },
           {
              "id":3497,
              "name":"Bartın Province",
              "country_id":792
           },
           {
              "id":3498,
              "name":"Iğdır Province",
              "country_id":792
           },
           {
              "id":3499,
              "name":"Karabük",
              "country_id":792
           },
           {
              "id":3500,
              "name":"Kilis Province",
              "country_id":792
           },
           {
              "id":3501,
              "name":"Osmaniye Province",
              "country_id":792
           },
           {
              "id":3502,
              "name":"Yalova Province",
              "country_id":792
           },
           {
              "id":3503,
              "name":"Düzce",
              "country_id":792
           }
        ],
        "795":[
           {
              "id":3504,
              "name":"Ahal",
              "country_id":795
           },
           {
              "id":3505,
              "name":"Balkan",
              "country_id":795
           },
           {
              "id":3506,
              "name":"Daşoguz",
              "country_id":795
           },
           {
              "id":3507,
              "name":"Lebap",
              "country_id":795
           },
           {
              "id":3508,
              "name":"Mary",
              "country_id":795
           }
        ],
        "796":[
           {
              "id":3509,
              "name":"Turks and Caicos Islands",
              "country_id":796
           }
        ],
        "798":[
           {
              "id":3510,
              "name":"Tuvalu",
              "country_id":798
           }
        ],
        "800":[
           {
              "id":3511,
              "name":"Eastern Region",
              "country_id":800
           },
           {
              "id":3512,
              "name":"Northern Region",
              "country_id":800
           },
           {
              "id":3513,
              "name":"Central Region",
              "country_id":800
           },
           {
              "id":3514,
              "name":"Western Region",
              "country_id":800
           }
        ],
        "804":[
           {
              "id":3515,
              "name":"Cherkasʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3516,
              "name":"Chernihivsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3517,
              "name":"Chernivetsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3518,
              "name":"Dnipropetrovsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3519,
              "name":"Donetsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3520,
              "name":"Ivano-Frankivsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3521,
              "name":"Kharkivsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3522,
              "name":"Kherson Oblast",
              "country_id":804
           },
           {
              "id":3523,
              "name":"Khmelʼnytsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3524,
              "name":"Kirovohradsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3525,
              "name":"Avtonomna Respublika Krym",
              "country_id":804
           },
           {
              "id":3526,
              "name":"Misto Kyyiv",
              "country_id":804
           },
           {
              "id":3527,
              "name":"Kiev Oblast",
              "country_id":804
           },
           {
              "id":3528,
              "name":"Luhansʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3529,
              "name":"Lʼvivsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3530,
              "name":"Mykolayivsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3531,
              "name":"Odessa Oblast",
              "country_id":804
           },
           {
              "id":3532,
              "name":"Poltava Oblast",
              "country_id":804
           },
           {
              "id":3533,
              "name":"Rivnensʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3534,
              "name":"Misto Sevastopol",
              "country_id":804
           },
           {
              "id":3535,
              "name":"Sumy Oblast",
              "country_id":804
           },
           {
              "id":3536,
              "name":"Ternopilʼsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3537,
              "name":"Vinnytsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3538,
              "name":"Volynsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3539,
              "name":"Zakarpatsʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3540,
              "name":"Zaporizʼka Oblastʼ",
              "country_id":804
           },
           {
              "id":3541,
              "name":"Zhytomyrsʼka Oblastʼ",
              "country_id":804
           }
        ],
        "807":[
           {
              "id":3542,
              "name":"Macedonia, The Former Yugoslav Republic of",
              "country_id":807
           },
           {
              "id":3543,
              "name":"Aračinovo",
              "country_id":807
           },
           {
              "id":3544,
              "name":"Bač",
              "country_id":807
           },
           {
              "id":3545,
              "name":"Belčišta",
              "country_id":807
           },
           {
              "id":3546,
              "name":"Berovo",
              "country_id":807
           },
           {
              "id":3547,
              "name":"Bistrica",
              "country_id":807
           },
           {
              "id":3548,
              "name":"Bitola",
              "country_id":807
           },
           {
              "id":3549,
              "name":"Blatec",
              "country_id":807
           },
           {
              "id":3550,
              "name":"Bogdanci",
              "country_id":807
           },
           {
              "id":3551,
              "name":"Opstina Bogomila",
              "country_id":807
           },
           {
              "id":3552,
              "name":"Bogovinje",
              "country_id":807
           },
           {
              "id":3553,
              "name":"Bosilovo",
              "country_id":807
           },
           {
              "id":3554,
              "name":"Brvenica",
              "country_id":807
           },
           {
              "id":3555,
              "name":"Čair",
              "country_id":807
           },
           {
              "id":3556,
              "name":"Capari",
              "country_id":807
           },
           {
              "id":3557,
              "name":"Čaška",
              "country_id":807
           },
           {
              "id":3558,
              "name":"Čegrana",
              "country_id":807
           },
           {
              "id":3559,
              "name":"Centar",
              "country_id":807
           },
           {
              "id":3560,
              "name":"Centar Župa",
              "country_id":807
           },
           {
              "id":3561,
              "name":"Češinovo",
              "country_id":807
           },
           {
              "id":3562,
              "name":"Čučer-Sandevo",
              "country_id":807
           },
           {
              "id":3563,
              "name":"Debar",
              "country_id":807
           },
           {
              "id":3564,
              "name":"Delčevo",
              "country_id":807
           },
           {
              "id":3565,
              "name":"Delogoždi",
              "country_id":807
           },
           {
              "id":3566,
              "name":"Demir Hisar",
              "country_id":807
           },
           {
              "id":3567,
              "name":"Demir Kapija",
              "country_id":807
           },
           {
              "id":3568,
              "name":"Dobruševo",
              "country_id":807
           },
           {
              "id":3569,
              "name":"Dolna Banjica",
              "country_id":807
           },
           {
              "id":3570,
              "name":"Dolneni",
              "country_id":807
           },
           {
              "id":3571,
              "name":"Opstina Gjorce Petrov",
              "country_id":807
           },
           {
              "id":3572,
              "name":"Drugovo",
              "country_id":807
           },
           {
              "id":3573,
              "name":"Džepčište",
              "country_id":807
           },
           {
              "id":3574,
              "name":"Gazi Baba",
              "country_id":807
           },
           {
              "id":3575,
              "name":"Gevgelija",
              "country_id":807
           },
           {
              "id":3576,
              "name":"Gostivar",
              "country_id":807
           },
           {
              "id":3577,
              "name":"Gradsko",
              "country_id":807
           },
           {
              "id":3578,
              "name":"Ilinden",
              "country_id":807
           },
           {
              "id":3579,
              "name":"Izvor",
              "country_id":807
           },
           {
              "id":3580,
              "name":"Jegunovce",
              "country_id":807
           },
           {
              "id":3581,
              "name":"Kamenjane",
              "country_id":807
           },
           {
              "id":3582,
              "name":"Karbinci",
              "country_id":807
           },
           {
              "id":3583,
              "name":"Karpoš",
              "country_id":807
           },
           {
              "id":3584,
              "name":"Kavadarci",
              "country_id":807
           },
           {
              "id":3585,
              "name":"Kičevo",
              "country_id":807
           },
           {
              "id":3586,
              "name":"Kisela Voda",
              "country_id":807
           },
           {
              "id":3587,
              "name":"Klečevce",
              "country_id":807
           },
           {
              "id":3588,
              "name":"Kočani",
              "country_id":807
           },
           {
              "id":3589,
              "name":"Konče",
              "country_id":807
           },
           {
              "id":3590,
              "name":"Kondovo",
              "country_id":807
           },
           {
              "id":3591,
              "name":"Konopište",
              "country_id":807
           },
           {
              "id":3592,
              "name":"Kosel",
              "country_id":807
           },
           {
              "id":3593,
              "name":"Kratovo",
              "country_id":807
           },
           {
              "id":3594,
              "name":"Kriva Palanka",
              "country_id":807
           },
           {
              "id":3595,
              "name":"Krivogaštani",
              "country_id":807
           },
           {
              "id":3596,
              "name":"Kruševo",
              "country_id":807
           },
           {
              "id":3597,
              "name":"Kukliš",
              "country_id":807
           },
           {
              "id":3598,
              "name":"Kukurečani",
              "country_id":807
           },
           {
              "id":3599,
              "name":"Kumanovo",
              "country_id":807
           },
           {
              "id":3600,
              "name":"Labuništa",
              "country_id":807
           },
           {
              "id":3601,
              "name":"Opstina Lipkovo",
              "country_id":807
           },
           {
              "id":3602,
              "name":"Lozovo",
              "country_id":807
           },
           {
              "id":3603,
              "name":"Lukovo",
              "country_id":807
           },
           {
              "id":3604,
              "name":"Makedonska Kamenica",
              "country_id":807
           },
           {
              "id":3605,
              "name":"Makedonski Brod",
              "country_id":807
           },
           {
              "id":3606,
              "name":"Mavrovi Anovi",
              "country_id":807
           },
           {
              "id":3607,
              "name":"Mešeišta",
              "country_id":807
           },
           {
              "id":3608,
              "name":"Miravci",
              "country_id":807
           },
           {
              "id":3609,
              "name":"Mogila",
              "country_id":807
           },
           {
              "id":3610,
              "name":"Murtino",
              "country_id":807
           },
           {
              "id":3611,
              "name":"Negotino",
              "country_id":807
           },
           {
              "id":3612,
              "name":"Negotino-Pološko",
              "country_id":807
           },
           {
              "id":3613,
              "name":"Novaci",
              "country_id":807
           },
           {
              "id":3614,
              "name":"Novo Selo",
              "country_id":807
           },
           {
              "id":3615,
              "name":"Obleševo",
              "country_id":807
           },
           {
              "id":3616,
              "name":"Ohrid",
              "country_id":807
           },
           {
              "id":3617,
              "name":"Orašac",
              "country_id":807
           },
           {
              "id":3618,
              "name":"Orizari",
              "country_id":807
           },
           {
              "id":3619,
              "name":"Oslomej",
              "country_id":807
           },
           {
              "id":3620,
              "name":"Pehčevo",
              "country_id":807
           },
           {
              "id":3621,
              "name":"Petrovec",
              "country_id":807
           },
           {
              "id":3622,
              "name":"Plasnica",
              "country_id":807
           },
           {
              "id":3623,
              "name":"Podareš",
              "country_id":807
           },
           {
              "id":3624,
              "name":"Prilep",
              "country_id":807
           },
           {
              "id":3625,
              "name":"Probištip",
              "country_id":807
           },
           {
              "id":3626,
              "name":"Radoviš",
              "country_id":807
           },
           {
              "id":3627,
              "name":"Opstina Rankovce",
              "country_id":807
           },
           {
              "id":3628,
              "name":"Resen",
              "country_id":807
           },
           {
              "id":3629,
              "name":"Rosoman",
              "country_id":807
           },
           {
              "id":3630,
              "name":"Opština Rostuša",
              "country_id":807
           },
           {
              "id":3631,
              "name":"Samokov",
              "country_id":807
           },
           {
              "id":3632,
              "name":"Saraj",
              "country_id":807
           },
           {
              "id":3633,
              "name":"Šipkovica",
              "country_id":807
           },
           {
              "id":3634,
              "name":"Sopište",
              "country_id":807
           },
           {
              "id":3635,
              "name":"Sopotnica",
              "country_id":807
           },
           {
              "id":3636,
              "name":"Srbinovo",
              "country_id":807
           },
           {
              "id":3637,
              "name":"Staravina",
              "country_id":807
           },
           {
              "id":3638,
              "name":"Star Dojran",
              "country_id":807
           },
           {
              "id":3639,
              "name":"Staro Nagoričane",
              "country_id":807
           },
           {
              "id":3640,
              "name":"Štip",
              "country_id":807
           },
           {
              "id":3641,
              "name":"Struga",
              "country_id":807
           },
           {
              "id":3642,
              "name":"Strumica",
              "country_id":807
           },
           {
              "id":3643,
              "name":"Studeničani",
              "country_id":807
           },
           {
              "id":3644,
              "name":"Šuto Orizari",
              "country_id":807
           },
           {
              "id":3645,
              "name":"Sveti Nikole",
              "country_id":807
           },
           {
              "id":3646,
              "name":"Tearce",
              "country_id":807
           },
           {
              "id":3647,
              "name":"Tetovo",
              "country_id":807
           },
           {
              "id":3648,
              "name":"Topolčani",
              "country_id":807
           },
           {
              "id":3649,
              "name":"Valandovo",
              "country_id":807
           },
           {
              "id":3650,
              "name":"Vasilevo",
              "country_id":807
           },
           {
              "id":3651,
              "name":"Veles",
              "country_id":807
           },
           {
              "id":3652,
              "name":"Velešta",
              "country_id":807
           },
           {
              "id":3653,
              "name":"Vevčani",
              "country_id":807
           },
           {
              "id":3654,
              "name":"Vinica",
              "country_id":807
           },
           {
              "id":3655,
              "name":"Vitolište",
              "country_id":807
           },
           {
              "id":3656,
              "name":"Vraneštica",
              "country_id":807
           },
           {
              "id":3657,
              "name":"Vrapčište",
              "country_id":807
           },
           {
              "id":3658,
              "name":"Vratnica",
              "country_id":807
           },
           {
              "id":3659,
              "name":"Vrutok",
              "country_id":807
           },
           {
              "id":3660,
              "name":"Zajas",
              "country_id":807
           },
           {
              "id":3661,
              "name":"Zelenikovo",
              "country_id":807
           },
           {
              "id":3662,
              "name":"Želino",
              "country_id":807
           },
           {
              "id":3663,
              "name":"Žitoše",
              "country_id":807
           },
           {
              "id":3664,
              "name":"Zletovo",
              "country_id":807
           },
           {
              "id":3665,
              "name":"Zrnovci",
              "country_id":807
           }
        ],
        "818":[
           {
              "id":3666,
              "name":"Ad Daqahlīyah",
              "country_id":818
           },
           {
              "id":3667,
              "name":"Al Baḩr al Aḩmar",
              "country_id":818
           },
           {
              "id":3668,
              "name":"Al Buḩayrah",
              "country_id":818
           },
           {
              "id":3669,
              "name":"Al Fayyūm",
              "country_id":818
           },
           {
              "id":3670,
              "name":"Al Gharbīyah",
              "country_id":818
           },
           {
              "id":3671,
              "name":"Alexandria",
              "country_id":818
           },
           {
              "id":3672,
              "name":"Al Ismā‘īlīyah",
              "country_id":818
           },
           {
              "id":3673,
              "name":"Al Jīzah",
              "country_id":818
           },
           {
              "id":3674,
              "name":"Al Minūfīyah",
              "country_id":818
           },
           {
              "id":3675,
              "name":"Al Minyā",
              "country_id":818
           },
           {
              "id":3676,
              "name":"Al Qāhirah",
              "country_id":818
           },
           {
              "id":3677,
              "name":"Al Qalyūbīyah",
              "country_id":818
           },
           {
              "id":3678,
              "name":"Al Wādī al Jadīd",
              "country_id":818
           },
           {
              "id":3679,
              "name":"Eastern Province",
              "country_id":818
           },
           {
              "id":3680,
              "name":"As Suways",
              "country_id":818
           },
           {
              "id":3681,
              "name":"Aswān",
              "country_id":818
           },
           {
              "id":3682,
              "name":"Asyūţ",
              "country_id":818
           },
           {
              "id":3683,
              "name":"Banī Suwayf",
              "country_id":818
           },
           {
              "id":3684,
              "name":"Būr Sa‘īd",
              "country_id":818
           },
           {
              "id":3685,
              "name":"Dumyāţ",
              "country_id":818
           },
           {
              "id":3686,
              "name":"Kafr ash Shaykh",
              "country_id":818
           },
           {
              "id":3687,
              "name":"Maţrūḩ",
              "country_id":818
           },
           {
              "id":3688,
              "name":"Qinā",
              "country_id":818
           },
           {
              "id":3689,
              "name":"Sūhāj",
              "country_id":818
           },
           {
              "id":3690,
              "name":"Janūb Sīnāʼ",
              "country_id":818
           },
           {
              "id":3691,
              "name":"Shamāl Sīnāʼ",
              "country_id":818
           },
           {
              "id":3692,
              "name":"Luxor",
              "country_id":818
           },
           {
              "id":3693,
              "name":"Helwan",
              "country_id":818
           },
           {
              "id":3694,
              "name":"6th of October",
              "country_id":818
           }
        ],
        "826":[
           {
              "id":3695,
              "name":"England",
              "country_id":826
           },
           {
              "id":3696,
              "name":"Northern Ireland",
              "country_id":826
           },
           {
              "id":3697,
              "name":"Scotland",
              "country_id":826
           },
           {
              "id":3698,
              "name":"Wales",
              "country_id":826
           }
        ],
        "831":[
           {
              "id":3699,
              "name":"Guernsey",
              "country_id":831
           }
        ],
        "832":[
     
        ],
        "833":[
           {
              "id":3700,
              "name":"Isle of Man",
              "country_id":833
           }
        ],
        "834":[
           {
              "id":3701,
              "name":"Arusha",
              "country_id":834
           },
           {
              "id":3702,
              "name":"Pwani",
              "country_id":834
           },
           {
              "id":3703,
              "name":"Dodoma",
              "country_id":834
           },
           {
              "id":3704,
              "name":"Iringa",
              "country_id":834
           },
           {
              "id":3705,
              "name":"Kigoma",
              "country_id":834
           },
           {
              "id":3706,
              "name":"Kilimanjaro",
              "country_id":834
           },
           {
              "id":3707,
              "name":"Lindi",
              "country_id":834
           },
           {
              "id":3708,
              "name":"Mara",
              "country_id":834
           },
           {
              "id":3709,
              "name":"Mbeya",
              "country_id":834
           },
           {
              "id":3710,
              "name":"Morogoro",
              "country_id":834
           },
           {
              "id":3711,
              "name":"Mtwara",
              "country_id":834
           },
           {
              "id":3712,
              "name":"Mwanza",
              "country_id":834
           },
           {
              "id":3713,
              "name":"Pemba North",
              "country_id":834
           },
           {
              "id":3714,
              "name":"Ruvuma",
              "country_id":834
           },
           {
              "id":3715,
              "name":"Shinyanga",
              "country_id":834
           },
           {
              "id":3716,
              "name":"Singida",
              "country_id":834
           },
           {
              "id":3717,
              "name":"Tabora",
              "country_id":834
           },
           {
              "id":3718,
              "name":"Tanga",
              "country_id":834
           },
           {
              "id":3719,
              "name":"Kagera",
              "country_id":834
           },
           {
              "id":3720,
              "name":"Pemba South",
              "country_id":834
           },
           {
              "id":3721,
              "name":"Zanzibar Central/South",
              "country_id":834
           },
           {
              "id":3722,
              "name":"Zanzibar North",
              "country_id":834
           },
           {
              "id":3723,
              "name":"Dar es Salaam",
              "country_id":834
           },
           {
              "id":3724,
              "name":"Rukwa",
              "country_id":834
           },
           {
              "id":3725,
              "name":"Zanzibar Urban/West",
              "country_id":834
           },
           {
              "id":3726,
              "name":"Arusha",
              "country_id":834
           },
           {
              "id":3727,
              "name":"Manyara",
              "country_id":834
           }
        ],
        "850":[
           {
              "id":3728,
              "name":"Virgin Islands",
              "country_id":850
           }
        ],
        "854":[
           {
              "id":3729,
              "name":"Boucle du Mouhoun",
              "country_id":854
           },
           {
              "id":3730,
              "name":"Cascades",
              "country_id":854
           },
           {
              "id":3731,
              "name":"Centre",
              "country_id":854
           },
           {
              "id":3732,
              "name":"Centre-Est",
              "country_id":854
           },
           {
              "id":3733,
              "name":"Centre-Nord",
              "country_id":854
           },
           {
              "id":3734,
              "name":"Centre-Ouest",
              "country_id":854
           },
           {
              "id":3735,
              "name":"Centre-Sud",
              "country_id":854
           },
           {
              "id":3736,
              "name":"Est",
              "country_id":854
           },
           {
              "id":3737,
              "name":"Hauts-Bassins",
              "country_id":854
           },
           {
              "id":3738,
              "name":"Nord",
              "country_id":854
           },
           {
              "id":3739,
              "name":"Plateau-Central",
              "country_id":854
           },
           {
              "id":3740,
              "name":"Sahel",
              "country_id":854
           },
           {
              "id":3741,
              "name":"Sud-Ouest",
              "country_id":854
           }
        ],
        "855":[
           {
              "id":3742,
              "name":"Komuna e Deçanit",
              "country_id":855
           },
           {
              "id":3743,
              "name":"Komuna e Dragashit",
              "country_id":855
           },
           {
              "id":3744,
              "name":"Komuna e Ferizajt",
              "country_id":855
           },
           {
              "id":3745,
              "name":"Komuna e Fushë Kosovës",
              "country_id":855
           },
           {
              "id":3746,
              "name":"Komuna e Gjakovës",
              "country_id":855
           },
           {
              "id":3747,
              "name":"Komuna e Gjilanit",
              "country_id":855
           },
           {
              "id":3748,
              "name":"Komuna e Drenasit",
              "country_id":855
           },
           {
              "id":3749,
              "name":"Komuna e Istogut",
              "country_id":855
           },
           {
              "id":3750,
              "name":"Komuna e Kaçanikut",
              "country_id":855
           },
           {
              "id":3751,
              "name":"Komuna e Kamenicës",
              "country_id":855
           },
           {
              "id":3752,
              "name":"Komuna e Klinës",
              "country_id":855
           },
           {
              "id":3753,
              "name":"Komuna e Leposaviqit",
              "country_id":855
           },
           {
              "id":3754,
              "name":"Komuna e Lipjanit",
              "country_id":855
           },
           {
              "id":3755,
              "name":"Komuna e Malisheves",
              "country_id":855
           },
           {
              "id":3756,
              "name":"Komuna e Mitrovicës",
              "country_id":855
           },
           {
              "id":3757,
              "name":"Komuna e Novobërdës",
              "country_id":855
           },
           {
              "id":3758,
              "name":"Komuna e Obiliqit",
              "country_id":855
           },
           {
              "id":3759,
              "name":"Komuna e Pejës",
              "country_id":855
           },
           {
              "id":3760,
              "name":"Komuna e Podujevës",
              "country_id":855
           },
           {
              "id":3761,
              "name":"Komuna e Prishtinës",
              "country_id":855
           },
           {
              "id":3762,
              "name":"Komuna e Prizrenit",
              "country_id":855
           },
           {
              "id":3763,
              "name":"Komuna e Rahovecit",
              "country_id":855
           },
           {
              "id":3764,
              "name":"Komuna e Shtërpcës",
              "country_id":855
           },
           {
              "id":3765,
              "name":"Komuna e Shtimes",
              "country_id":855
           },
           {
              "id":3766,
              "name":"Komuna e Skenderajt",
              "country_id":855
           },
           {
              "id":3767,
              "name":"Komuna e Thërandës",
              "country_id":855
           },
           {
              "id":3768,
              "name":"Komuna e Vitisë",
              "country_id":855
           },
           {
              "id":3769,
              "name":"Komuna e Vushtrrisë",
              "country_id":855
           },
           {
              "id":3770,
              "name":"Komuna e Zubin Potokut",
              "country_id":855
           },
           {
              "id":3771,
              "name":"Komuna e Zveçanit",
              "country_id":855
           }
        ],
        "858":[
           {
              "id":3772,
              "name":"Artigas Department",
              "country_id":858
           },
           {
              "id":3773,
              "name":"Canelones Department",
              "country_id":858
           },
           {
              "id":3774,
              "name":"Cerro Largo Department",
              "country_id":858
           },
           {
              "id":3775,
              "name":"Colonia Department",
              "country_id":858
           },
           {
              "id":3776,
              "name":"Durazno",
              "country_id":858
           },
           {
              "id":3777,
              "name":"Flores",
              "country_id":858
           },
           {
              "id":3778,
              "name":"Florida Department",
              "country_id":858
           },
           {
              "id":3779,
              "name":"Lavalleja Department",
              "country_id":858
           },
           {
              "id":3780,
              "name":"Maldonado Department",
              "country_id":858
           },
           {
              "id":3781,
              "name":"Montevideo",
              "country_id":858
           },
           {
              "id":3782,
              "name":"Paysandú",
              "country_id":858
           },
           {
              "id":3783,
              "name":"Río Negro",
              "country_id":858
           },
           {
              "id":3784,
              "name":"Rivera",
              "country_id":858
           },
           {
              "id":3785,
              "name":"Rocha",
              "country_id":858
           },
           {
              "id":3786,
              "name":"Salto",
              "country_id":858
           },
           {
              "id":3787,
              "name":"San José",
              "country_id":858
           },
           {
              "id":3788,
              "name":"Soriano Department",
              "country_id":858
           },
           {
              "id":3789,
              "name":"Tacuarembó",
              "country_id":858
           },
           {
              "id":3790,
              "name":"Treinta y Tres",
              "country_id":858
           }
        ],
        "860":[
           {
              "id":3791,
              "name":"Andijon",
              "country_id":860
           },
           {
              "id":3792,
              "name":"Buxoro",
              "country_id":860
           },
           {
              "id":3793,
              "name":"Farg ona",
              "country_id":860
           },
           {
              "id":3794,
              "name":"Xorazm",
              "country_id":860
           },
           {
              "id":3795,
              "name":"Namangan",
              "country_id":860
           },
           {
              "id":3796,
              "name":"Navoiy",
              "country_id":860
           },
           {
              "id":3797,
              "name":"Qashqadaryo",
              "country_id":860
           },
           {
              "id":3798,
              "name":"Karakalpakstan",
              "country_id":860
           },
           {
              "id":3799,
              "name":"Samarqand",
              "country_id":860
           },
           {
              "id":3800,
              "name":"Surxondaryo",
              "country_id":860
           },
           {
              "id":3801,
              "name":"Toshkent Shahri",
              "country_id":860
           },
           {
              "id":3802,
              "name":"Toshkent",
              "country_id":860
           },
           {
              "id":3803,
              "name":"Jizzax",
              "country_id":860
           },
           {
              "id":3804,
              "name":"Sirdaryo",
              "country_id":860
           }
        ],
        "862":[
           {
              "id":3805,
              "name":"Amazonas",
              "country_id":862
           },
           {
              "id":3806,
              "name":"Anzoátegui",
              "country_id":862
           },
           {
              "id":3807,
              "name":"Apure",
              "country_id":862
           },
           {
              "id":3808,
              "name":"Aragua",
              "country_id":862
           },
           {
              "id":3809,
              "name":"Barinas",
              "country_id":862
           },
           {
              "id":3810,
              "name":"Bolívar",
              "country_id":862
           },
           {
              "id":3811,
              "name":"Carabobo",
              "country_id":862
           },
           {
              "id":3812,
              "name":"Cojedes",
              "country_id":862
           },
           {
              "id":3813,
              "name":"Delta Amacuro",
              "country_id":862
           },
           {
              "id":3814,
              "name":"Distrito Federal",
              "country_id":862
           },
           {
              "id":3815,
              "name":"Falcón",
              "country_id":862
           },
           {
              "id":3816,
              "name":"Guárico",
              "country_id":862
           },
           {
              "id":3817,
              "name":"Lara",
              "country_id":862
           },
           {
              "id":3818,
              "name":"Mérida",
              "country_id":862
           },
           {
              "id":3819,
              "name":"Miranda",
              "country_id":862
           },
           {
              "id":3820,
              "name":"Monagas",
              "country_id":862
           },
           {
              "id":3821,
              "name":"Isla Margarita",
              "country_id":862
           },
           {
              "id":3822,
              "name":"Portuguesa",
              "country_id":862
           },
           {
              "id":3823,
              "name":"Sucre",
              "country_id":862
           },
           {
              "id":3824,
              "name":"Táchira",
              "country_id":862
           },
           {
              "id":3825,
              "name":"Trujillo",
              "country_id":862
           },
           {
              "id":3826,
              "name":"Yaracuy",
              "country_id":862
           },
           {
              "id":3827,
              "name":"Zulia",
              "country_id":862
           },
           {
              "id":3828,
              "name":"Dependencias Federales",
              "country_id":862
           },
           {
              "id":3829,
              "name":"Distrito Capital",
              "country_id":862
           },
           {
              "id":3830,
              "name":"Vargas",
              "country_id":862
           }
        ],
        "876":[
     
        ],
        "882":[
           {
              "id":3831,
              "name":"A‘ana",
              "country_id":882
           },
           {
              "id":3832,
              "name":"Aiga-i-le-Tai",
              "country_id":882
           },
           {
              "id":3833,
              "name":"Atua",
              "country_id":882
           },
           {
              "id":3834,
              "name":"Fa‘asaleleaga",
              "country_id":882
           },
           {
              "id":3835,
              "name":"Gaga‘emauga",
              "country_id":882
           },
           {
              "id":3836,
              "name":"Va‘a-o-Fonoti",
              "country_id":882
           },
           {
              "id":3837,
              "name":"Gagaifomauga",
              "country_id":882
           },
           {
              "id":3838,
              "name":"Palauli",
              "country_id":882
           },
           {
              "id":3839,
              "name":"Satupa‘itea",
              "country_id":882
           },
           {
              "id":3840,
              "name":"Tuamasaga",
              "country_id":882
           },
           {
              "id":3841,
              "name":"Vaisigano",
              "country_id":882
           }
        ],
        "887":[
           {
              "id":3842,
              "name":"Abyan",
              "country_id":887
           },
           {
              "id":3843,
              "name":"‘Adan",
              "country_id":887
           },
           {
              "id":3844,
              "name":"Al Mahrah",
              "country_id":887
           },
           {
              "id":3845,
              "name":"Ḩaḑramawt",
              "country_id":887
           },
           {
              "id":3846,
              "name":"Shabwah",
              "country_id":887
           },
           {
              "id":3847,
              "name":"San’a’",
              "country_id":887
           },
           {
              "id":3848,
              "name":"Ta’izz",
              "country_id":887
           },
           {
              "id":3849,
              "name":"Al Ḩudaydah",
              "country_id":887
           },
           {
              "id":3850,
              "name":"Dhamar",
              "country_id":887
           },
           {
              "id":3851,
              "name":"Al Maḩwīt",
              "country_id":887
           },
           {
              "id":3852,
              "name":"Dhamār",
              "country_id":887
           },
           {
              "id":3853,
              "name":"Maʼrib",
              "country_id":887
           },
           {
              "id":3854,
              "name":"Şa‘dah",
              "country_id":887
           },
           {
              "id":3855,
              "name":"Şan‘āʼ",
              "country_id":887
           },
           {
              "id":3856,
              "name":"Aḑ Ḑāli‘",
              "country_id":887
           },
           {
              "id":3857,
              "name":"Omran",
              "country_id":887
           },
           {
              "id":3858,
              "name":"Al Bayḑāʼ",
              "country_id":887
           },
           {
              "id":3859,
              "name":"Al Jawf",
              "country_id":887
           },
           {
              "id":3860,
              "name":"Ḩajjah",
              "country_id":887
           },
           {
              "id":3861,
              "name":"Ibb",
              "country_id":887
           },
           {
              "id":3862,
              "name":"Laḩij",
              "country_id":887
           },
           {
              "id":3863,
              "name":"Ta‘izz",
              "country_id":887
           },
           {
              "id":3864,
              "name":"Amanat Al Asimah",
              "country_id":887
           },
           {
              "id":3865,
              "name":"Muḩāfaz̧at Raymah",
              "country_id":887
           }
        ],
        "891":[
           {
              "id":3866,
              "name":"Crna Gora (Montenegro)",
              "country_id":891
           },
           {
              "id":3867,
              "name":"Srbija (Serbia)",
              "country_id":891
           }
        ],
        "894":[
           {
              "id":3868,
              "name":"North-Western",
              "country_id":894
           },
           {
              "id":3869,
              "name":"Copperbelt",
              "country_id":894
           },
           {
              "id":3870,
              "name":"Western",
              "country_id":894
           },
           {
              "id":3871,
              "name":"Southern",
              "country_id":894
           },
           {
              "id":3872,
              "name":"Central",
              "country_id":894
           },
           {
              "id":3873,
              "name":"Eastern",
              "country_id":894
           },
           {
              "id":3874,
              "name":"Northern",
              "country_id":894
           },
           {
              "id":3875,
              "name":"Luapula",
              "country_id":894
           },
           {
              "id":3876,
              "name":"Lusaka",
              "country_id":894
           }
        ]
     };

    public timezonesCountryList = {
        "1":[
           /*      {
              "id":"America/Anchorage",
              "name":"(UTC-8.0) Anchorage",
              "country_id":1
           },
           */      {
              "id":"America/Chicago",
              "name":"(UTC-06:00) Central Time (US & Canada)",
              "country_id":1
           },
           {
              "id":"America/Denver",
              "name":"(UTC-07:00) Mountain Time (US & Canada)",
              "country_id":1
           },
           {
              "id":"America/Los_Angeles",
              "name":"(UTC-08:00) Pacific Time (US & Canada)",
              "country_id":1
           },
           {
              "id":"America/New_York",
              "name":"(UTC-05:00) Eastern Time (US & Canada)",
              "country_id":1
           },
           {
              "id":"America/Phoenix",
              "name":"(UTC-08:00) Pacific Time (US & Canada)",
              "country_id":1
           }      /*,
           {
              "id":"Pacific/Honolulu",
              "name":"(UTC-10.0) Honolulu",
              "country_id":1
           }      */
        ],
        "2":[
           {
              "id":"Asia/Kolkata",
              "name":"(UTC+05:30) Chennai, Koltaka, Mumbai, New Del",
              "country_id":2
           }
        ],
        "4":[
           {
              "id":"Asia/Kabul",
              "name":"(UTC+4.30) Kabul",
              "country_id":4
           }
        ],
        "8":[
           {
              "id":"Europe/Tirane",
              "name":"(UTC+2.0) Tirane",
              "country_id":8
           }
        ],
        "12":[
           {
              "id":"Africa/Algiers",
              "name":"(UTC+1.0) Algiers",
              "country_id":12
           }
        ],
        "16":[
           {
              "id":"Pacific/Pago_Pago",
              "name":"(UTC-11.0) Pago_Pago",
              "country_id":16
           }
        ],
        "20":[
           {
              "id":"Europe/Andorra",
              "name":"(UTC+2.0) Andorra",
              "country_id":20
           }
        ],
        "24":[
           {
              "id":"Africa/Luanda",
              "name":"(UTC+1.0) Luanda",
              "country_id":24
           }
        ],
        "28":[
           {
              "id":"America/Antigua",
              "name":"(UTC+10.0) Antigua",
              "country_id":28
           }
        ],
        "31":[
           {
              "id":"Asia/Baku",
              "name":"(UTC+5.0) Baku",
              "country_id":31
           }
        ],
        "32":[
           {
              "id":"America/Argentina/Buenos_Aires",
              "name":"(UTC-3.0) Argentina",
              "country_id":32
           },
           {
              "id":"America/Argentina/Catamarca",
              "name":"(UTC-3.0) Argentina",
              "country_id":32
           },
           {
              "id":"America/Argentina/Cordoba",
              "name":"(UTC-3.0) Argentina",
              "country_id":32
           },
           {
              "id":"America/Argentina/Jujuy",
              "name":"(UTC-3.0) Argentina",
              "country_id":32
           },
           {
              "id":"America/Argentina/La_Rioja",
              "name":"(UTC-3.0) Argentina",
              "country_id":32
           },
           {
              "id":"America/Argentina/Mendoza",
              "name":"(UTC-3.0) Argentina",
              "country_id":32
           },
           {
              "id":"America/Argentina/Rio_Gallegos",
              "name":"(UTC-3.0) Argentina",
              "country_id":32
           },
           {
              "id":"America/Argentina/San_Juan",
              "name":"(UTC-3.0) Argentina",
              "country_id":32
           },
           {
              "id":"America/Argentina/Tucuman",
              "name":"(UTC-3.0) Argentina",
              "country_id":32
           },
           {
              "id":"America/Argentina/Ushuaia",
              "name":"(UTC-3.0) Argentina",
              "country_id":32
           }
        ],
        "36":[
           {
              "id":"Australia/Adelaide",
              "name":"(UTC+9.30) Adelaide",
              "country_id":36
           },
           {
              "id":"Australia/Brisbane",
              "name":"(UTC+10.0) Brisbane",
              "country_id":36
           },
           {
              "id":"Australia/Darwin",
              "name":"(UTC+9.30) Darwin",
              "country_id":36
           },
           {
              "id":"Australia/Hobart",
              "name":"(UTC+10.0) Hobart",
              "country_id":36
           },
           {
              "id":"Australia/Melbourne",
              "name":"(UTC+10.0) Melbourne",
              "country_id":36
           },
           {
              "id":"Australia/Perth",
              "name":"(UTC+8.0) Perth",
              "country_id":36
           },
           {
              "id":"Australia/Sydney",
              "name":"(UTC+10.0) Sydney",
              "country_id":36
           }
        ],
        "40":[
           {
              "id":"Europe/Vienna",
              "name":"(UTC+2.0) Vienna",
              "country_id":40
           }
        ],
        "44":[
           {
              "id":"America/Nassau",
              "name":"(UTC-4.0) Nassau",
              "country_id":44
           }
        ],
        "48":[
           {
              "id":"Asia/Bahrain",
              "name":"(UTC+3.0) Bahrain",
              "country_id":48
           }
        ],
        "50":[
           {
              "id":"Asia/Dhaka",
              "name":"(UTC+6.0) Dhaka",
              "country_id":50
           }
        ],
        "51":[
           {
              "id":"Asia/Yerevan",
              "name":"(UTC+4.0) Yerevan",
              "country_id":51
           }
        ],
        "52":[
           {
              "id":"America/Barbados",
              "name":"(UTC-4.0) Barbados",
              "country_id":52
           }
        ],
        "56":[
           {
              "id":"Europe/Brussels",
              "name":"(UTC+2.0) Brussels",
              "country_id":56
           }
        ],
        "60":[
           {
              "id":"Atlantic/Bermuda",
              "name":"(UTC-3.0) Bermuda",
              "country_id":60
           }
        ],
        "64":[
           {
              "id":"Asia/Thimphu",
              "name":"(UTC+6.0) Thimphu",
              "country_id":64
           }
        ],
        "68":[
           {
              "id":"America/La_Paz",
              "name":"(UTC-4.0) La_Paz",
              "country_id":68
           }
        ],
        "70":[
           {
              "id":"Europe/Sarajevo",
              "name":"(UTC+2.0) Sarajevo",
              "country_id":70
           }
        ],
        "72":[
           {
              "id":"Africa/Gaborone",
              "name":"(UTC+2.0) Gaborone",
              "country_id":72
           }
        ],
        "76":[
           {
              "id":"America/Araguaina",
              "name":"(UTC-3.0) Araguaina",
              "country_id":76
           },
           {
              "id":"America/Bahia",
              "name":"(UTC-4.0) Bahia",
              "country_id":76
           },
           {
              "id":"America/Belem",
              "name":"(UTC-3.0) Belem",
              "country_id":76
           },
           {
              "id":"America/Boa_Vista",
              "name":"(UTC-4.0) Boa_Vista",
              "country_id":76
           },
           {
              "id":"America/Campo_Grande",
              "name":"(UTC-4.0) Campo_Grande",
              "country_id":76
           },
           {
              "id":"America/Cuiaba",
              "name":"(UTC-4.0) Cuiaba",
              "country_id":76
           },
           {
              "id":"America/Fortaleza",
              "name":"(UTC-3.0) Fortaleza",
              "country_id":76
           },
           {
              "id":"America/Maceio",
              "name":"(UTC-3.0) Maceio",
              "country_id":76
           },
           {
              "id":"America/Manaus",
              "name":"(UTC-4.0) Manaus",
              "country_id":76
           },
           {
              "id":"America/Noronha",
              "name":"(UTC -2:00 )Noronha",
              "country_id":76
           },
           {
              "id":"America/Porto_Velho",
              "name":"(UTC-4.0) Porto_Velho",
              "country_id":76
           },
           {
              "id":"America/Recife",
              "name":"(UTC-3.0) Recife",
              "country_id":76
           },
           {
              "id":"America/Rio_Branco",
              "name":"(UTC-5.0) Rio_Branco",
              "country_id":76
           },
           {
              "id":"America/Sao_Paulo",
              "name":"(UTC-3.0) Sao_Paulo",
              "country_id":76
           }
        ],
        "84":[
           {
              "id":"America/Belize",
              "name":"(UTC-6.0) Belize",
              "country_id":84
           }
        ],
        "86":[
           {
              "id":"Indian/Chagos",
              "name":"(UTC +6:00)/Chagos",
              "country_id":86
           }
        ],
        "90":[
           {
              "id":"Pacific/Guadalcanal",
              "name":"(UTC+2.0) Guadalcanal",
              "country_id":90
           }
        ],
        "92":[
           {
              "id":"America/Tortola",
              "name":"(UTC+2.0) Tortola",
              "country_id":92
           }
        ],
        "96":[
           {
              "id":"Asia/Brunei",
              "name":"(UTC+8.0) Brunei",
              "country_id":96
           }
        ],
        "100":[
           {
              "id":"Europe/Sofia",
              "name":"(UTC+3.0) Sofia",
              "country_id":100
           }
        ],
        "104":[
           {
              "id":"Asia/Rangoon",
              "name":"(UTC+6.50) Rangoon",
              "country_id":104
           }
        ],
        "108":[
           {
              "id":"Africa/Bujumbura",
              "name":"(UTC+2.0) Bujumbura",
              "country_id":108
           }
        ],
        "112":[
           {
              "id":"Europe/Minsk",
              "name":"(UTC+3.0) Minsk",
              "country_id":112
           }
        ],
        "116":[
           {
              "id":"Asia/Phnom_Penh",
              "name":"(UTC+7.0) Phnom_Penh",
              "country_id":116
           }
        ],
        "120":[
           {
              "id":"Africa/Douala",
              "name":"(UTC+1.0) Douala",
              "country_id":120
           }
        ],
        "124":[
           {
              "id":"America/Edmonton",
              "name":"(UTC-6.0) Edmonton",
              "country_id":124
           },
           {
              "id":"America/Goose_Bay",
              "name":"(UTC-3.0) Goose_Bay",
              "country_id":124
           },
           {
              "id":"America/Halifax",
              "name":"(UTC-3.0) Halifax",
              "country_id":124
           },
           {
              "id":"America/Montreal",
              "name":"(UTC-4.0) Montreal",
              "country_id":124
           },
           {
              "id":"America/Rankin_Inlet",
              "name":"(UTC-5.0) Rankin_Inlet",
              "country_id":124
           },
           {
              "id":"America/Toronto",
              "name":"(UTC-4.0) Toronto",
              "country_id":124
           },
           {
              "id":"America/Vancouver",
              "name":"(UTC-7.0) Vancouver",
              "country_id":124
           },
           {
              "id":"America/Whitehorse",
              "name":"(UTC-7.0) Whitehorse",
              "country_id":124
           },
           {
              "id":"America/Winnipeg",
              "name":"(UTC-5.0) Winnipeg",
              "country_id":124
           },
           {
              "id":"America/Yellowknife",
              "name":"(UTC-6.0) Yellowknife",
              "country_id":124
           }
        ],
        "132":[
           {
              "id":"Atlantic/Cape_Verde",
              "name":"(UTC+2.0) Cape_Verde",
              "country_id":132
           }
        ],
        "136":[
           {
              "id":"America/Cayman",
              "name":"(UTC-5.0) Cayman",
              "country_id":136
           }
        ],
        "140":[
           {
              "id":"Africa/Bangui",
              "name":"(UTC+1.0) Bangui",
              "country_id":140
           }
        ],
        "144":[
           {
              "id":"Asia/Colombo",
              "name":"(UTC+5.50) Colombo",
              "country_id":144
           }
        ],
        "148":[
           {
              "id":"Africa/Ndjamena",
              "name":"(UTC +1:00)Ndjamena",
              "country_id":148
           }
        ],
        "152":[
           {
              "id":"America/Santiago",
              "name":"(UTC-4.0) Santiago",
              "country_id":152
           }
        ],
        "156":[
           {
              "id":"Asia/Ho_Chi_Minh",
              "name":"(UTC+7.0) Ho_Chi_Minh",
              "country_id":156
           }
        ],
        "158":[
           {
              "id":"Asia/Taipei",
              "name":"(UTC+8.0) Taipei",
              "country_id":158
           }
        ],
        "162":[
           {
              "id":"Indian/Christmas",
              "name":"(UTC-7.0) Christmas",
              "country_id":162
           }
        ],
        "166":[
           {
              "id":"Indian/Cocos",
              "name":"(UTC-3.0) Cocos",
              "country_id":166
           }
        ],
        "170":[
           {
              "id":"America/Bogota",
              "name":"(UTC-5.0) Bogota",
              "country_id":170
           }
        ],
        "174":[
           {
              "id":"Indian/Comoro",
              "name":"(UTC+1.0) Comoro",
              "country_id":174
           }
        ],
        "175":[
           {
              "id":"Indian/Mayotte",
              "name":"(UTC+3.0) Mayotte",
              "country_id":175
           }
        ],
        "178":[
           {
              "id":"Africa/Brazzaville",
              "name":"(UTC+1.0) Brazzaville",
              "country_id":178
           }
        ],
        "180":[
           {
              "id":"Africa/Kinshasa",
              "name":"(UTC+1.0) Kinshasa",
              "country_id":180
           },
           {
              "id":"Africa/Lubumbashi",
              "name":"(UTC+2.0) Lubumbashi",
              "country_id":180
           }
        ],
        "184":[
           {
              "id":"Pacific/Rarotonga",
              "name":"(UTC -10:00)Rarotonga",
              "country_id":184
           }
        ],
        "188":[
           {
              "id":"America/Costa_Rica",
              "name":"(UTC-6.0) Costa_Rica",
              "country_id":188
           }
        ],
        "191":[
           {
              "id":"Europe/Zagreb",
              "name":"(UTC+2.0) Zagreb",
              "country_id":191
           }
        ],
        "192":[
           {
              "id":"America/Havana",
              "name":"(UTC-4.0) Havana",
              "country_id":192
           }
        ],
        "196":[
           {
              "id":"Asia/Nicosia",
              "name":"(UTC+3.0) Nicosia",
              "country_id":196
           }
        ],
        "203":[
           {
              "id":"Europe/Prague",
              "name":"(UTC+2.0) Prague",
              "country_id":203
           }
        ],
        "204":[
           {
              "id":"Africa/Porto-Novo",
              "name":"(UTC+1.0) Porto-Novo",
              "country_id":204
           }
        ],
        "208":[
           {
              "id":"Europe/Copenhagen",
              "name":"(UTC+2.0) Copenhagen",
              "country_id":208
           }
        ],
        "212":[
           {
              "id":"America/Dominica",
              "name":"(UTC-4.0) Dominica",
              "country_id":212
           }
        ],
        "214":[
           {
              "id":"America/Santo_Domingo",
              "name":"(UTC-4.0) Santo_Domingo",
              "country_id":214
           }
        ],
        "218":[
           {
              "id":"America/Guayaquil",
              "name":"(UTC-5.0) Guayaquil",
              "country_id":218
           },
           {
              "id":"Pacific/Galapagos",
              "name":"(UTC-5.0) Galapagos",
              "country_id":218
           }
        ],
        "222":[
           {
              "id":"America/El_Salvador",
              "name":"(UTC-6.0) El_Salvador",
              "country_id":222
           }
        ],
        "226":[
           {
              "id":"Africa/Malabo",
              "name":"(UTC+1.0) Malabo",
              "country_id":226
           }
        ],
        "231":[
           {
              "id":"Africa/Addis_Ababa",
              "name":"(UTC+3.0) Addis_Ababa",
              "country_id":231
           }
        ],
        "232":[
           {
              "id":"Africa/Asmara",
              "name":"(UTC+3.0) Asmara",
              "country_id":232
           }
        ],
        "233":[
           {
              "id":"Europe/Tallinn",
              "name":"(UTC+3.0) Tallinn",
              "country_id":233
           }
        ],
        "234":[
           {
              "id":"Atlantic/Faroe",
              "name":"(UTC +1:00)Faroe",
              "country_id":234
           }
        ],
        "238":[
           {
              "id":"Atlantic/Stanley",
              "name":"(UTC+1.0) Stanley",
              "country_id":238
           }
        ],
        "239":[
           {
              "id":"Atlantic/South_Georgia",
              "name":"(UTC-4.0) South_Georgia",
              "country_id":239
           }
        ],
        "242":[
           {
              "id":"Pacific/Fiji",
              "name":"(UTC+3.0) Fiji",
              "country_id":242
           }
        ],
        "246":[
           {
              "id":"Europe/Helsinki",
              "name":"(UTC+3.0) Helsinki",
              "country_id":246
           }
        ],
        "250":[
           {
              "id":"Europe/Paris",
              "name":"(UTC+2.0) Paris",
              "country_id":250
           }
        ],
        "254":[
           {
              "id":"America/Guyana",
              "name":"(UTC-4.0) Guyana",
              "country_id":254
           }
        ],
        "260":[
           {
              "id":"Indian/Kerguelen",
              "name":"(UTC+2.0) Kerguelen",
              "country_id":260
           }
        ],
        "262":[
           {
              "id":"Africa/Djibouti",
              "name":"(UTC+3.0) Djibouti",
              "country_id":262
           }
        ],
        "266":[
           {
              "id":"Africa/Libreville",
              "name":"(UTC+1.0) Libreville",
              "country_id":266
           }
        ],
        "268":[
           {
              "id":"Asia/Tbilisi",
              "name":"(UTC+4.0) Tbilisi",
              "country_id":268
           }
        ],
        "270":[
           {
              "id":"Africa/Banjul",
              "name":"(UTC+0.0) Banjul",
              "country_id":270
           }
        ],
        "275":[
           {
              "id":"Asia/Gaza",
              "name":"(UTC+4.50) Gaza",
              "country_id":275
           }
        ],
        "276":[
           {
              "id":"Europe/Berlin",
              "name":"(UTC+2.0) Berlin",
              "country_id":276
           }
        ],
        "288":[
           {
              "id":"Africa/Accra",
              "name":"(UTC+0.0) Accra",
              "country_id":288
           }
        ],
        "292":[
           {
              "id":"Europe/Gibraltar",
              "name":"(UTC+2.0) Gibraltar",
              "country_id":292
           }
        ],
        "296":[
           {
              "id":"Pacific/Enderbury",
              "name":"(UTC +13:00)Enderbury",
              "country_id":296
           },
           {
              "id":"Pacific/Kiritimati",
              "name":"(UTC +14:00)Kiritimati",
              "country_id":296
           },
           {
              "id":"Pacific/Tarawa",
              "name":"(UTC+12.0) Tarawa",
              "country_id":296
           }
        ],
        "300":[
           {
              "id":"Europe/Athens",
              "name":"(UTC+3.0) Athens",
              "country_id":300
           }
        ],
        "304":[
           {
              "id":"America/Godthab",
              "name":"(UTC+2.0) Godthab",
              "country_id":304
           }
        ],
        "308":[
           {
              "id":"America/Grenada",
              "name":"(UTC-4.0) Grenada",
              "country_id":308
           }
        ],
        "312":[
           {
              "id":"America/Guadeloupe",
              "name":"(UTC-3.0) Guadeloupe",
              "country_id":312
           }
        ],
        "316":[
           {
              "id":"Pacific/Guam",
              "name":"(UTC-6.0) Guam",
              "country_id":316
           }
        ],
        "320":[
           {
              "id":"America/Guatemala",
              "name":"(UTC-6.0) Guatemala",
              "country_id":320
           }
        ],
        "324":[
           {
              "id":"Africa/Conakry",
              "name":"(UTC+0.0) Conakry",
              "country_id":324
           }
        ],
        "332":[
           {
              "id":"America/Port-au-Prince",
              "name":"(UTC-4.0) Port-au-Prince",
              "country_id":332
           }
        ],
        "336":[
           {
              "id":"Europe/Vatican",
              "name":"(UTC-5.0) Vatican",
              "country_id":336
           }
        ],
        "340":[
           {
              "id":"America/Tegucigalpa",
              "name":"(UTC-6.0) Tegucigalpa",
              "country_id":340
           }
        ],
        "344":[
           {
              "id":"Asia/Hong_Kong",
              "name":"(UTC+8.0) Hong_Kong",
              "country_id":344
           }
        ],
        "348":[
           {
              "id":"Europe/Budapest",
              "name":"(UTC+2.0) Budapest",
              "country_id":348
           }
        ],
        "352":[
           {
              "id":"Atlantic/Reykjavik",
              "name":"(UTC+0.0) Reykjavik",
              "country_id":352
           }
        ],
        "360":[
           {
              "id":"Asia/Jakarta",
              "name":"(UTC+7.0) Jakarta",
              "country_id":360
           },
           {
              "id":"Asia/Jayapura",
              "name":"(UTC+9.0) Jayapura",
              "country_id":360
           },
           {
              "id":"Asia/Ujung_Pandang",
              "name":"(UTC+7.0) Ujung_Pandang",
              "country_id":360
           }
        ],
        "364":[
           {
              "id":"Asia/Tehran",
              "name":"(UTC+4.50) Tehran",
              "country_id":364
           }
        ],
        "368":[
           {
              "id":"Asia/Baghdad",
              "name":"(UTC+3.0) Baghdad",
              "country_id":368
           }
        ],
        "372":[
           {
              "id":"Europe/Dublin",
              "name":"(UTC+1.0) Dublin",
              "country_id":372
           }
        ],
        "376":[
           {
              "id":"Asia/Jerusalem",
              "name":"(UTC+3.0) Jerusalem",
              "country_id":376
           }
        ],
        "380":[
           {
              "id":"Europe/Rome",
              "name":"(UTC+2.0) Rome",
              "country_id":380
           }
        ],
        "384":[
           {
              "id":"Africa/Abidjan",
              "name":"(UTC+0.0) Abidjan",
              "country_id":384
           }
        ],
        "388":[
           {
              "id":"America/Jamaica",
              "name":"(UTC-5.0) Jamaica",
              "country_id":388
           }
        ],
        "392":[
           {
              "id":"Asia/Tokyo",
              "name":"(UTC+9.0) Tokyo",
              "country_id":392
           }
        ],
        "398":[
           {
              "id":"Asia/Almaty",
              "name":"(UTC+6.0) Almaty",
              "country_id":398
           },
           {
              "id":"Asia/Aqtau",
              "name":"(UTC+5.0) Aqtau",
              "country_id":398
           },
           {
              "id":"Asia/Aqtobe",
              "name":"(UTC+5.0) Aqtobe",
              "country_id":398
           },
           {
              "id":"Asia/Oral",
              "name":"(UTC+6.0) Oral",
              "country_id":398
           },
           {
              "id":"Asia/Qyzylorda",
              "name":"(UTC+6.0) Qyzylorda",
              "country_id":398
           }
        ],
        "400":[
           {
              "id":"Asia/Amman",
              "name":"(UTC+3.0) Amman",
              "country_id":400
           }
        ],
        "404":[
           {
              "id":"Africa/Nairobi",
              "name":"(UTC+3.0) Nairobi",
              "country_id":404
           }
        ],
        "408":[
           {
              "id":"Asia/Pyongyang",
              "name":"(UTC+9.0) Pyongyang",
              "country_id":408
           }
        ],
        "410":[
           {
              "id":"Asia/Seoul",
              "name":"(UTC+9.0) Seoul",
              "country_id":410
           }
        ],
        "414":[
           {
              "id":"Asia/Kuwait",
              "name":"(UTC+3.0) Kuwait",
              "country_id":414
           }
        ],
        "417":[
           {
              "id":"Asia/Bishkek",
              "name":"(UTC+6.0) Bishkek",
              "country_id":417
           }
        ],
        "418":[
           {
              "id":"Asia/Vientiane",
              "name":"(UTC+7.0) Vientiane",
              "country_id":418
           }
        ],
        "422":[
           {
              "id":"Asia/Beirut",
              "name":"(UTC+3.0) Beirut",
              "country_id":422
           }
        ],
        "426":[
           {
              "id":"Africa/Maseru",
              "name":"(UTC+2.0) Maseru",
              "country_id":426
           }
        ],
        "428":[
           {
              "id":"Europe/Riga",
              "name":"(UTC+3.0) Riga",
              "country_id":428
           }
        ],
        "430":[
           {
              "id":"Africa/Monrovia",
              "name":"(UTC+0.0) Monrovia",
              "country_id":430
           }
        ],
        "434":[
           {
              "id":"Africa/Tripoli",
              "name":"(UTC+2.0) Tripoli",
              "country_id":434
           }
        ],
        "438":[
           {
              "id":"Europe/Vaduz",
              "name":"(UTC+2.0) Vaduz",
              "country_id":438
           }
        ],
        "440":[
           {
              "id":"Europe/Vilnius",
              "name":"(UTC+3.0) Vilnius",
              "country_id":440
           }
        ],
        "442":[
           {
              "id":"Europe/Luxembourg",
              "name":"(UTC+2.0) Luxembourg",
              "country_id":442
           }
        ],
        "446":[
           {
              "id":"Asia/Macau",
              "name":"(UTC-3.0) Macau",
              "country_id":446
           }
        ],
        "450":[
           {
              "id":"Indian/Antananarivo",
              "name":"(UTC+3.0) Antananarivo",
              "country_id":450
           }
        ],
        "454":[
           {
              "id":"Africa/Blantyre",
              "name":"(UTC+2.0) Blantyre",
              "country_id":454
           }
        ],
        "458":[
           {
              "id":"Asia/Kuala_Lumpur",
              "name":"(UTC+8:00) Kuala Lumpur, Singapore",
              "country_id":458
           },
           {
              "id":"Asia/Kuching",
              "name":"(UTC+8.0) Kuching",
              "country_id":458
           }
        ],
        "462":[
           {
              "id":"Indian/Maldives",
              "name":"(UTC+5.0) Maldives",
              "country_id":462
           }
        ],
        "466":[
           {
              "id":"Africa/Bamako",
              "name":"(UTC+0.0) Bamako",
              "country_id":466
           }
        ],
        "470":[
           {
              "id":"Europe/Malta",
              "name":"(UTC+2.0) Malta",
              "country_id":470
           }
        ],
        "474":[
           {
              "id":"America/Martinique",
              "name":"(UTC-4.0) Martinique",
              "country_id":474
           }
        ],
        "478":[
           {
              "id":"Africa/Nouakchott",
              "name":"(UTC+0.0) Nouakchott",
              "country_id":478
           }
        ],
        "480":[
           {
              "id":"Indian/Mauritius",
              "name":"(UTC+4.0) Mauritius",
              "country_id":480
           }
        ],
        "484":[
           {
              "id":"America/Cancun",
              "name":"(UTC-5.0) Cancun",
              "country_id":484
           },
           {
              "id":"America/Chihuahua",
              "name":"(UTC-6.0) Chihuahua",
              "country_id":484
           },
           {
              "id":"America/Hermosillo",
              "name":"(UTC-7.0) Hermosillo",
              "country_id":484
           },
           {
              "id":"America/Mazatlan",
              "name":"(UTC-6.0) Mazatlan",
              "country_id":484
           },
           {
              "id":"America/Merida",
              "name":"(UTC-5.0) Merida",
              "country_id":484
           },
           {
              "id":"America/Mexico_City",
              "name":"(UTC-5.0) Mexico_City",
              "country_id":484
           },
           {
              "id":"America/Monterrey",
              "name":"(UTC-5.0) Monterrey",
              "country_id":484
           },
           {
              "id":"America/Tijuana",
              "name":"(UTC-7.0) Tijuana",
              "country_id":484
           }
        ],
        "492":[
           {
              "id":"Europe/Monaco",
              "name":"(UTC+2.0) Monaco",
              "country_id":492
           }
        ],
        "496":[
           {
              "id":"Asia/Choibalsan",
              "name":"(UTC+8.0) Choibalsan",
              "country_id":496
           },
           {
              "id":"Asia/Hovd",
              "name":"(UTC+8.0) Hovd",
              "country_id":496
           },
           {
              "id":"Asia/Ulaanbaatar",
              "name":"(UTC+8.0) Ulaanbaatar",
              "country_id":496
           }
        ],
        "498":[
           {
              "id":"Europe/Chisinau",
              "name":"(UTC+3.0) Chisinau",
              "country_id":498
           }
        ],
        "499":[
           {
              "id":"Europe/Podgorica",
              "name":"(UTC+2.0) Podgorica",
              "country_id":499
           }
        ],
        "500":[
           {
              "id":"America/Montserrat",
              "name":"(UTC-4.0) Montserrat",
              "country_id":500
           }
        ],
        "504":[
           {
              "id":"Africa/Casablanca",
              "name":"(UTC+0.0) Casablanca",
              "country_id":504
           }
        ],
        "508":[
           {
              "id":"Africa/Maputo",
              "name":"(UTC+2.0) Maputo",
              "country_id":508
           }
        ],
        "512":[
           {
              "id":"Asia/Muscat",
              "name":"(UTC+4.0) Muscat",
              "country_id":512
           }
        ],
        "516":[
           {
              "id":"Africa/Windhoek",
              "name":"(UTC+1.0) Windhoek",
              "country_id":516
           }
        ],
        "520":[
           {
              "id":"Pacific/Nauru",
              "name":"(UTC+3.0) Nauru",
              "country_id":520
           }
        ],
        "524":[
           {
              "id":"Asia/Kathmandu",
              "name":"(UTC+5.75) Kathmandu",
              "country_id":524
           }
        ],
        "528":[
           {
              "id":"Europe/Amsterdam",
              "name":"(UTC+2.0) Amsterdam",
              "country_id":528
           }
        ],
        "530":[
           {
              "id":"America/Curacao",
              "name":"(UTC-5.0) Curacao",
              "country_id":530
           }
        ],
        "533":[
           {
              "id":"America/Aruba",
              "name":"(UTC-4.0) Aruba",
              "country_id":533
           }
        ],
        "548":[
           {
              "id":"Pacific/Efate",
              "name":"(UTC +11:00)Efate",
              "country_id":548
           }
        ],
        "554":[
           {
              "id":"Pacific/Auckland",
              "name":"(UTC+12.0) Auckland",
              "country_id":554
           }
        ],
        "558":[
           {
              "id":"America/Managua",
              "name":"(UTC-6.0) Managua",
              "country_id":558
           }
        ],
        "562":[
           {
              "id":"Africa/Niamey",
              "name":"(UTC+1.0) Niamey",
              "country_id":562
           }
        ],
        "566":[
           {
              "id":"Africa/Lagos",
              "name":"(UTC+1.0) Lagos",
              "country_id":566
           }
        ],
        "570":[
           {
              "id":"Pacific/Niue",
              "name":"(UTC-11.0) Niue",
              "country_id":570
           }
        ],
        "574":[
           {
              "id":"Pacific/Norfolk",
              "name":"(UTC-4.0) Norfolk",
              "country_id":574
           }
        ],
        "578":[
           {
              "id":"Europe/Oslo",
              "name":"(UTC+2.0) Oslo",
              "country_id":578
           }
        ],
        "583":[
           {
              "id":"Pacific/Kosrae",
              "name":"(UTC +11:00 )Kosrae",
              "country_id":583
           },
           {
              "id":"Pacific/Ponape",
              "name":"(UTC+11.0) Ponape",
              "country_id":583
           },
           {
              "id":"Pacific/Truk",
              "name":"(UTC+7.0) Truk",
              "country_id":583
           }
        ],
        "584":[
           {
              "id":"Pacific/Majuro",
              "name":"(UTC+12.0) Majuro",
              "country_id":584
           }
        ],
        "585":[
           {
              "id":"Pacific/Palau",
              "name":"(UTC-5.0) Palau",
              "country_id":585
           }
        ],
        "586":[
           {
              "id":"Asia/Karachi",
              "name":"(UTC+5.0) Karachi",
              "country_id":586
           }
        ],
        "591":[
           {
              "id":"America/Panama",
              "name":"(UTC-5.0) Panama",
              "country_id":591
           }
        ],
        "598":[
           {
              "id":"Pacific/Port_Moresby",
              "name":"(UTC+10.0) Port_Moresby",
              "country_id":598
           }
        ],
        "600":[
           {
              "id":"America/Asuncion",
              "name":"(UTC-4.0) Asuncion",
              "country_id":600
           }
        ],
        "604":[
           {
              "id":"America/Lima",
              "name":"(UTC-5.0) Lima",
              "country_id":604
           }
        ],
        "608":[
           {
              "id":"Asia/Manila",
              "name":"(UTC+8.0) Manila",
              "country_id":608
           }
        ],
        "612":[
           {
              "id":"Pacific/Pitcairn",
              "name":"(UTC-5.0) Pitcairn",
              "country_id":612
           }
        ],
        "616":[
           {
              "id":"Europe/Warsaw",
              "name":"(UTC+2.0) Warsaw",
              "country_id":616
           }
        ],
        "620":[
           {
              "id":"Atlantic/Azores",
              "name":"(UTC+2.0) Azores",
              "country_id":620
           },
           {
              "id":"Atlantic/Madeira",
              "name":"(UTC-3.0) Madeira",
              "country_id":620
           },
           {
              "id":"Europe/Lisbon",
              "name":"(UTC+1.0) Lisbon",
              "country_id":620
           }
        ],
        "624":[
           {
              "id":"Africa/Bissau",
              "name":"(UTC+0.0) Bissau",
              "country_id":624
           }
        ],
        "626":[
           {
              "id":"Asia/Dili",
              "name":"(UTC+4.0) Dili",
              "country_id":626
           }
        ],
        "630":[
           {
              "id":"America/Puerto_Rico",
              "name":"(UTC-5.0) Puerto_Rico",
              "country_id":630
           }
        ],
        "634":[
           {
              "id":"Asia/Qatar",
              "name":"(UTC+3.0) Qatar",
              "country_id":634
           }
        ],
        "638":[
           {
              "id":"Indian/Reunion",
              "name":"(UTC-4.0) Reunion",
              "country_id":638
           }
        ],
        "642":[
           {
              "id":"Europe/Bucharest",
              "name":"(UTC+3.0) Bucharest",
              "country_id":642
           }
        ],
        "643":[
           {
              "id":"Asia/Irkutsk",
              "name":"(UTC+9.0) Irkutsk",
              "country_id":643
           },
           {
              "id":"Asia/Kamchatka",
              "name":"(UTC+9.0) Kamchatka",
              "country_id":643
           },
           {
              "id":"Asia/Krasnoyarsk",
              "name":"(UTC+8.0) Krasnoyarsk",
              "country_id":643
           },
           {
              "id":"Asia/Magadan",
              "name":"(UTC+12.0) Magadan",
              "country_id":643
           },
           {
              "id":"Asia/Novosibirsk",
              "name":"(UTC+7.0) Novosibirsk",
              "country_id":643
           },
           {
              "id":"Asia/Omsk",
              "name":"(UTC+7.0) Omsk",
              "country_id":643
           },
           {
              "id":"Asia/Vladivostok",
              "name":"(UTC+11.0) Vladivostok",
              "country_id":643
           },
           {
              "id":"Asia/Yakutsk",
              "name":"(UTC+10.0) Yakutsk",
              "country_id":643
           },
           {
              "id":"Asia/Yekaterinburg",
              "name":"(UTC+6.0) Yekaterinburg",
              "country_id":643
           },
           {
              "id":"Europe/Kaliningrad",
              "name":"(UTC+3.0) Kaliningrad",
              "country_id":643
           },
           {
              "id":"Europe/Moscow",
              "name":"(UTC+4.0) Moscow",
              "country_id":643
           },
           {
              "id":"Europe/Samara",
              "name":"(UTC+4.0) Samara",
              "country_id":643
           }
        ],
        "646":[
           {
              "id":"Africa/Kigali",
              "name":"(UTC+2.0) Kigali",
              "country_id":646
           }
        ],
        "654":[
           {
              "id":"Atlantic/St_Helena",
              "name":"(UTC+1.0) St_Helena",
              "country_id":654
           }
        ],
        "659":[
           {
              "id":"America/St_Kitts",
              "name":"(UTC-4:00)St_Kitts",
              "country_id":659
           }
        ],
        "660":[
           {
              "id":"America/Anguilla",
              "name":"(UTC-4.0) Anguilla",
              "country_id":660
           }
        ],
        "662":[
           {
              "id":"America/St_Lucia",
              "name":"(UTC -4:00)St_Lucia",
              "country_id":662
           }
        ],
        "666":[
           {
              "id":"America/Miquelon",
              "name":"(UTC-2.0) Miquelon",
              "country_id":666
           }
        ],
        "670":[
           {
              "id":"America/St_Vincent",
              "name":"(UTC+1.0) St_Vincent",
              "country_id":670
           }
        ],
        "674":[
           {
              "id":"Europe/San_Marino",
              "name":"(UTC+2.0) San_Marino",
              "country_id":674
           }
        ],
        "678":[
           {
              "id":"Africa/Sao_Tome",
              "name":"(UTC+0.0) Sao_Tome",
              "country_id":678
           }
        ],
        "682":[
           {
              "id":"Asia/Riyadh",
              "name":"(UTC+3.0) Riyadh",
              "country_id":682
           }
        ],
        "686":[
           {
              "id":"Africa/Dakar",
              "name":"(UTC+0.0) Dakar",
              "country_id":686
           }
        ],
        "688":[
           {
              "id":"Europe/Belgrade",
              "name":"(UTC+2.0) Belgrade",
              "country_id":688
           }
        ],
        "690":[
           {
              "id":"Indian/Mahe",
              "name":"(UTC+5.50) Mahe",
              "country_id":690
           }
        ],
        "694":[
           {
              "id":"Africa/Freetown",
              "name":"(UTC+0.0) Freetown",
              "country_id":694
           }
        ],
        "702":[
           {
              "id":"Asia/Singapore",
              "name":"(UTC+8.0) Singapore",
              "country_id":702
           }
        ],
        "703":[
           {
              "id":"Europe/Bratislava",
              "name":"(UTC+2.0) Bratislava",
              "country_id":703
           }
        ],
        "705":[
           {
              "id":"Europe/Ljubljana",
              "name":"(UTC+2.0) Ljubljana",
              "country_id":705
           }
        ],
        "706":[
           {
              "id":"Africa/Mogadishu",
              "name":"(UTC+3.0) Mogadishu",
              "country_id":706
           }
        ],
        "710":[
           {
              "id":"Africa/Johannesburg",
              "name":"(UTC+2.0) Johannesburg",
              "country_id":710
           }
        ],
        "716":[
           {
              "id":"Africa/Harare",
              "name":"(UTC+2.0) Harare",
              "country_id":716
           }
        ],
        "724":[
           {
              "id":"Africa/Ceuta",
              "name":"(UTC+2.0) Ceuta",
              "country_id":724
           },
           {
              "id":"Atlantic/Canary",
              "name":"(UTC-4.0) Canary",
              "country_id":724
           },
           {
              "id":"Europe/Madrid",
              "name":"(UTC+2.0) Madrid",
              "country_id":724
           }
        ],
        "732":[
           {
              "id":"Africa/El_Aaiun",
              "name":"(UTC+0.0) El_Aaiun",
              "country_id":732
           }
        ],
        "736":[
           {
              "id":"Africa/Khartoum",
              "name":"(UTC+3.0) Khartoum",
              "country_id":736
           }
        ],
        "740":[
           {
              "id":"America/Paramaribo",
              "name":"(UTC-3.0) Paramaribo",
              "country_id":740
           }
        ],
        "748":[
           {
              "id":"Africa/Mbabane",
              "name":"(UTC+2.0) Mbabane",
              "country_id":748
           }
        ],
        "752":[
           {
              "id":"Europe/Stockholm",
              "name":"(UTC+2.0) Stockholm",
              "country_id":752
           }
        ],
        "756":[
           {
              "id":"Europe/Zurich",
              "name":"(UTC+2.0) Zurich",
              "country_id":756
           }
        ],
        "760":[
           {
              "id":"Asia/Damascus",
              "name":"(UTC+3.0) Damascus",
              "country_id":760
           }
        ],
        "762":[
           {
              "id":"Asia/Dushanbe",
              "name":"(UTC+5.0) Dushanbe",
              "country_id":762
           }
        ],
        "764":[
           {
              "id":"Asia/Bangkok",
              "name":"(UTC+7.0) Bangkok",
              "country_id":764
           }
        ],
        "768":[
           {
              "id":"Africa/Lome",
              "name":"(UTC+0.0) Lome",
              "country_id":768
           }
        ],
        "772":[
           {
              "id":"Pacific/Fakaofo",
              "name":"(UTC +13:00)Fakaofo",
              "country_id":772
           }
        ],
        "776":[
           {
              "id":"Pacific/Tongatapu",
              "name":"(UTC +13:00)Tongatapu",
              "country_id":776
           }
        ],
        "780":[
           {
              "id":"America/Port_of_Spain",
              "name":"(UTC-4.0) Port_of_Spain",
              "country_id":780
           }
        ],
        "784":[
           {
              "id":"Asia/Dubai",
              "name":"(UTC+4.0) Dubai",
              "country_id":784
           }
        ],
        "788":[
           {
              "id":"Africa/Tunis",
              "name":"(UTC+1.0) Tunis",
              "country_id":788
           }
        ],
        "792":[
           {
              "id":"Europe/Istanbul",
              "name":"(UTC+3.0) Istanbul",
              "country_id":792
           }
        ],
        "795":[
           {
              "id":"Asia/Ashgabat",
              "name":"(UTC+5.0) Ashgabat",
              "country_id":795
           }
        ],
        "796":[
           {
              "id":"America/Grand_Turk",
              "name":"(UTC-4.0) Grand_Turk",
              "country_id":796
           }
        ],
        "798":[
           {
              "id":"Pacific/Funafuti",
              "name":"(UTC+12.0) Funafuti",
              "country_id":798
           }
        ],
        "800":[
           {
              "id":"Africa/Kampala",
              "name":"(UTC+3.0) Kampala",
              "country_id":800
           }
        ],
        "804":[
           {
              "id":"Europe/Kiev",
              "name":"(UTC+3.0) Kiev",
              "country_id":804
           }
        ],
        "807":[
           {
              "id":"Europe/Skopje",
              "name":"(UTC+2.0) Skopje",
              "country_id":807
           }
        ],
        "818":[
           {
              "id":"Africa/Cairo",
              "name":"(UTC+2.0) Cairo",
              "country_id":818
           }
        ],
        "826":[
           {
              "id":"Europe/London",
              "name":"(UTC+1.0) London",
              "country_id":826
           }
        ],
        "831":[
           {
              "id":"Europe/Guernsey",
              "name":"(UTC+1.0) Guernsey",
              "country_id":831
           }
        ],
        "833":[
           {
              "id":"Europe/Isle_of_Man",
              "name":"(UTC+1.0) Isle_of_Man",
              "country_id":833
           }
        ],
        "834":[
           {
              "id":"Africa/Dar_es_Salaam",
              "name":"(UTC+3.0) Dar_es_Salaam",
              "country_id":834
           }
        ],
        "850":[
           {
              "id":"America/St_Thomas",
              "name":"(UTC+1.0) St_Thomas",
              "country_id":850
           }
        ],
        "854":[
           {
              "id":"Africa/Ouagadougou",
              "name":"(UTC+0.0) Ouagadougou",
              "country_id":854
           }
        ],
        "858":[
           {
              "id":"America/Montevideo",
              "name":"(UTC-3.0) Montevideo",
              "country_id":858
           }
        ],
        "860":[
           {
              "id":"Asia/Samarkand",
              "name":"(UTC+5.0) Samarkand",
              "country_id":860
           }
        ],
        "862":[
           {
              "id":"America/Caracas",
              "name":"(UTC-4.50) Caracas",
              "country_id":862
           }
        ],
        "882":[
           {
              "id":"Pacific/Apia",
              "name":"(UTC+13.0) Apia",
              "country_id":882
           }
        ],
        "887":[
           {
              "id":"Asia/Aden",
              "name":"(UTC+3.0) Aden",
              "country_id":887
           }
        ],
        "894":[
           {
              "id":"Africa/Lusaka",
              "name":"(UTC+2.0) Lusaka",
              "country_id":894
           }
        ]
     };

    public usaStatesList = [
        {
           "id":1,
           "country_id":1,
           "code":"AL",
           "name":"Alabama"
        },
        {
           "id":2,
           "country_id":1,
           "code":"AK",
           "name":"Alaska"
        },
        {
           "id":3,
           "country_id":1,
           "code":"AZ",
           "name":"Arizona"
        },
        {
           "id":4,
           "country_id":1,
           "code":"AR",
           "name":"Arkansas"
        },
        {
           "id":5,
           "country_id":1,
           "code":"CA",
           "name":"California"
        },
        {
           "id":6,
           "country_id":1,
           "code":"CO",
           "name":"Colorado"
        },
        {
           "id":7,
           "country_id":1,
           "code":"CT",
           "name":"Connecticut"
        },
        {
           "id":8,
           "country_id":1,
           "code":"DE",
           "name":"Delaware"
        },
        {
           "id":9,
           "country_id":1,
           "code":"FL",
           "name":"Florida"
        },
        {
           "id":10,
           "country_id":1,
           "code":"GA",
           "name":"Georgia"
        },
        {
           "id":11,
           "country_id":1,
           "code":"HI",
           "name":"Hawaii"
        },
        {
           "id":12,
           "country_id":1,
           "code":"ID",
           "name":"Idaho"
        },
        {
           "id":13,
           "country_id":1,
           "code":"IL",
           "name":"Illinois"
        },
        {
           "id":14,
           "country_id":1,
           "code":"IN",
           "name":"Indiana"
        },
        {
           "id":15,
           "country_id":1,
           "code":"IA",
           "name":"Iowa"
        },
        {
           "id":16,
           "country_id":1,
           "code":"KS",
           "name":"Kansas"
        },
        {
           "id":17,
           "country_id":1,
           "code":"KY",
           "name":"Kentucky"
        },
        {
           "id":18,
           "country_id":1,
           "code":"LA",
           "name":"Louisiana"
        },
        {
           "id":19,
           "country_id":1,
           "code":"ME",
           "name":"Maine"
        },
        {
           "id":20,
           "country_id":1,
           "code":"MD",
           "name":"Maryland"
        },
        {
           "id":21,
           "country_id":1,
           "code":"MA",
           "name":"Massachusetts"
        },
        {
           "id":22,
           "country_id":1,
           "code":"MI",
           "name":"Michigan"
        },
        {
           "id":23,
           "country_id":1,
           "code":"MN",
           "name":"Minnesota"
        },
        {
           "id":24,
           "country_id":1,
           "code":"MS",
           "name":"Mississippi"
        },
        {
           "id":25,
           "country_id":1,
           "code":"MO",
           "name":"Missouri"
        },
        {
           "id":26,
           "country_id":1,
           "code":"MT",
           "name":"Montana"
        },
        {
           "id":27,
           "country_id":1,
           "code":"NE",
           "name":"Nebraska"
        },
        {
           "id":28,
           "country_id":1,
           "code":"NV",
           "name":"Nevada"
        },
        {
           "id":29,
           "country_id":1,
           "code":"NH",
           "name":"New Hampshire"
        },
        {
           "id":30,
           "country_id":1,
           "code":"NJ",
           "name":"New Jersey"
        },
        {
           "id":31,
           "country_id":1,
           "code":"NM",
           "name":"New Mexico"
        },
        {
           "id":32,
           "country_id":1,
           "code":"NY",
           "name":"New York"
        },
        {
           "id":33,
           "country_id":1,
           "code":"NC",
           "name":"North Carolina"
        },
        {
           "id":34,
           "country_id":1,
           "code":"ND",
           "name":"North Dakota"
        },
        {
           "id":35,
           "country_id":1,
           "code":"OH",
           "name":"Ohio"
        },
        {
           "id":36,
           "country_id":1,
           "code":"OK",
           "name":"Oklahoma"
        },
        {
           "id":37,
           "country_id":1,
           "code":"OR",
           "name":"Oregon"
        },
        {
           "id":38,
           "country_id":1,
           "code":"PA",
           "name":"Pennsylvania"
        },
        {
           "id":39,
           "country_id":1,
           "code":"RI",
           "name":"Rhode Island"
        },
        {
           "id":40,
           "country_id":1,
           "code":"SC",
           "name":"South Carolina"
        },
        {
           "id":41,
           "country_id":1,
           "code":"SD",
           "name":"South Dakota"
        },
        {
           "id":42,
           "country_id":1,
           "code":"TN",
           "name":"Tennessee"
        },
        {
           "id":43,
           "country_id":1,
           "code":"TX",
           "name":"Texas"
        },
        {
           "id":44,
           "country_id":1,
           "code":"UT",
           "name":"Utah"
        },
        {
           "id":45,
           "country_id":1,
           "code":"VT",
           "name":"Vermont"
        },
        {
           "id":46,
           "country_id":1,
           "code":"VA",
           "name":"Virginia"
        },
        {
           "id":47,
           "country_id":1,
           "code":"WA",
           "name":"Washington"
        },
        {
           "id":3877,
           "country_id":1,
           "code":"DC",
           "name":"District of Columbia"
        },
        {
           "id":48,
           "country_id":1,
           "code":"WV",
           "name":"West Virginia"
        },
        {
           "id":49,
           "country_id":1,
           "code":"WI",
           "name":"Wisconsin"
        },
        {
           "id":50,
           "country_id":1,
           "code":"WY",
           "name":"Wyoming"
        }
     ];

    public timezoneList = {
        "Etc/GMT+12":"( GMT -12:00 ) (Etc/GMT+12)",
        "Etc/GMT+11":"( GMT -11:00 ) (Etc/GMT+11)",
        "Pacific/Midway":"( GMT -11:00 ) Samoa Standard Time(Pacific/Midway)",
        "Pacific/Niue":"( GMT -11:00 ) Niue Time(Pacific/Niue)",
        "Pacific/Pago_Pago":"( GMT -11:00 ) Samoa Standard Time(Pacific/Pago_Pago)",
        "Pacific/Samoa":"( GMT -11:00 ) Samoa Standard Time(Pacific/Samoa)",
        "US/Samoa":"( GMT -11:00 ) Samoa Standard Time(US/Samoa)",
        "Etc/GMT+10":"( GMT -10:00 ) (Etc/GMT+10)",
        "HST":"( GMT -10:00 ) Hawaii Standard Time(HST)",
        "Pacific/Honolulu":"( GMT -10:00 ) Hawaii Standard Time(Pacific/Honolulu)",
        "Pacific/Johnston":"( GMT -10:00 ) Hawaii Standard Time(Pacific/Johnston)",
        "Pacific/Rarotonga":"( GMT -10:00 ) Cook Is. Time(Pacific/Rarotonga)",
        "Pacific/Tahiti":"( GMT -10:00 ) Tahiti Time(Pacific/Tahiti)",
        "US/Hawaii":"( GMT -10:00 ) Hawaii Standard Time(US/Hawaii)",
        "Pacific/Marquesas":"( GMT -9:-30 ) Marquesas Time(Pacific/Marquesas)",
        "America/Adak":"( GMT -9:00 ) Hawaii-Aleutian Daylight Time(America/Adak)",
        "America/Atka":"( GMT -9:00 ) Hawaii-Aleutian Daylight Time(America/Atka)",
        "US/Aleutian":"( GMT -9:00 ) Hawaii-Aleutian Daylight Time(US/Aleutian)",
        "Etc/GMT+9":"( GMT -9:00 ) (Etc/GMT+9)",
        "Pacific/Gambier":"( GMT -9:00 ) Gambier Time(Pacific/Gambier)",
        "AST":"( GMT -8:00 ) Alaska Daylight Time(AST)",
        "America/Anchorage":"( GMT -8:00 ) Alaska Daylight Time(America/Anchorage)",
        "America/Juneau":"( GMT -8:00 ) Alaska Daylight Time(America/Juneau)",
        "America/Nome":"( GMT -8:00 ) Alaska Daylight Time(America/Nome)",
        "America/Yakutat":"( GMT -8:00 ) Alaska Daylight Time(America/Yakutat)",
        "US/Alaska":"( GMT -8:00 ) Alaska Daylight Time(US/Alaska)",
        "Etc/GMT+8":"( GMT -8:00 ) (Etc/GMT+8)",
        "Pacific/Pitcairn":"( GMT -8:00 ) Pitcairn Standard Time(Pacific/Pitcairn)",
        "America/Dawson":"( GMT -7:00 ) Pacific Daylight Time(America/Dawson)",
        "America/Ensenada":"( GMT -7:00 ) Pacific Daylight Time(America/Ensenada)",
        "America/Los_Angeles":"( GMT -7:00 ) Pacific Daylight Time(America/Los_Angeles)",
        "America/Tijuana":"( GMT -7:00 ) Pacific Daylight Time(America/Tijuana)",
        "America/Vancouver":"( GMT -7:00 ) Pacific Daylight Time(America/Vancouver)",
        "America/Whitehorse":"( GMT -7:00 ) Pacific Daylight Time(America/Whitehorse)",
        "Canada/Pacific":"( GMT -7:00 ) Pacific Daylight Time(Canada/Pacific)",
        "Canada/Yukon":"( GMT -7:00 ) Pacific Daylight Time(Canada/Yukon)",
        "Mexico/BajaNorte":"( GMT -7:00 ) Pacific Daylight Time(Mexico/BajaNorte)",
        "PST":"( GMT -7:00 ) Pacific Daylight Time(PST)",
        "PST8PDT":"( GMT -7:00 ) Pacific Daylight Time(PST8PDT)",
        "US/Pacific":"( GMT -7:00 ) Pacific Daylight Time(US/Pacific)",
        "US/Pacific-New":"( GMT -7:00 ) Pacific Daylight Time(US/Pacific-New)",
        "America/Dawson_Creek":"( GMT -7:00 ) Mountain Standard Time(America/Dawson_Creek)",
        "America/Hermosillo":"( GMT -7:00 ) Mountain Standard Time(America/Hermosillo)",
        "America/Phoenix":"( GMT -7:00 ) Mountain Standard Time(America/Phoenix)",
        "Etc/GMT+7":"( GMT -7:00 ) (Etc/GMT+7)",
        "MST":"( GMT -7:00 ) Mountain Standard Time(MST)",
        "PNT":"( GMT -7:00 ) Mountain Standard Time(PNT)",
        "US/Arizona":"( GMT -7:00 ) Mountain Standard Time(US/Arizona)",
        "America/Boise":"( GMT -6:00 ) Mountain Daylight Time(America/Boise)",
        "America/Cambridge_Bay":"( GMT -6:00 ) Mountain Daylight Time(America/Cambridge_Bay)",
        "America/Chihuahua":"( GMT -6:00 ) Mountain Daylight Time(America/Chihuahua)",
        "America/Denver":"( GMT -6:00 ) Mountain Daylight Time(America/Denver)",
        "America/Edmonton":"( GMT -6:00 ) Mountain Daylight Time(America/Edmonton)",
        "America/Inuvik":"( GMT -6:00 ) Mountain Daylight Time(America/Inuvik)",
        "America/Mazatlan":"( GMT -6:00 ) Mountain Daylight Time(America/Mazatlan)",
        "America/Shiprock":"( GMT -6:00 ) Mountain Daylight Time(America/Shiprock)",
        "America/Yellowknife":"( GMT -6:00 ) Mountain Daylight Time(America/Yellowknife)",
        "Canada/Mountain":"( GMT -6:00 ) Mountain Daylight Time(Canada/Mountain)",
        "MST7MDT":"( GMT -6:00 ) Mountain Daylight Time(MST7MDT)",
        "Mexico/BajaSur":"( GMT -6:00 ) Mountain Daylight Time(Mexico/BajaSur)",
        "Navajo":"( GMT -6:00 ) Mountain Daylight Time(Navajo)",
        "US/Mountain":"( GMT -6:00 ) Mountain Daylight Time(US/Mountain)",
        "America/Belize":"( GMT -6:00 ) Central Standard Time(America/Belize)",
        "America/Costa_Rica":"( GMT -6:00 ) Central Standard Time(America/Costa_Rica)",
        "America/El_Salvador":"( GMT -6:00 ) Central Standard Time(America/El_Salvador)",
        "America/Guatemala":"( GMT -6:00 ) Central Standard Time(America/Guatemala)",
        "America/Managua":"( GMT -6:00 ) Central Standard Time(America/Managua)",
        "America/Regina":"( GMT -6:00 ) Central Standard Time(America/Regina)",
        "America/Swift_Current":"( GMT -6:00 ) Central Standard Time(America/Swift_Current)",
        "America/Tegucigalpa":"( GMT -6:00 ) Central Standard Time(America/Tegucigalpa)",
        "Canada/East-Saskatchewan":"( GMT -6:00 ) Central Standard Time(Canada/East-Saskatchewan)",
        "Canada/Saskatchewan":"( GMT -6:00 ) Central Standard Time(Canada/Saskatchewan)",
        "Etc/GMT+6":"( GMT -6:00 ) (Etc/GMT+6)",
        "Pacific/Galapagos":"( GMT -6:00 ) Galapagos Time(Pacific/Galapagos)",
        "America/Cancun":"( GMT -5:00 ) Central Daylight Time(America/Cancun)",
        "America/Chicago":"( GMT -5:00 ) Central Daylight Time(America/Chicago)",
        "America/Indiana/Knox":"( GMT -5:00 ) Central Daylight Time(America/Indiana/Knox)",
        "America/Knox_IN":"( GMT -5:00 ) Eastern Daylight Time(America/Knox_IN)",
        "America/Menominee":"( GMT -5:00 ) Central Daylight Time(America/Menominee)",
        "America/Merida":"( GMT -5:00 ) Central Daylight Time(America/Merida)",
        "America/Mexico_City":"( GMT -5:00 ) Central Daylight Time(America/Mexico_City)",
        "America/Monterrey":"( GMT -5:00 ) Central Daylight Time(America/Monterrey)",
        "America/North_Dakota/Center":"( GMT -5:00 ) Central Daylight Time(America/North_Dakota/Center)",
        "America/North_Dakota/New_Salem":"( GMT -5:00 ) Central Daylight Time(America/North_Dakota/New_Salem)",
        "America/Rainy_River":"( GMT -5:00 ) Central Daylight Time(America/Rainy_River)",
        "America/Rankin_Inlet":"( GMT -5:00 ) Central Daylight Time(America/Rankin_Inlet)",
        "America/Winnipeg":"( GMT -5:00 ) Central Daylight Time(America/Winnipeg)",
        "CST":"( GMT -5:00 ) Central Daylight Time(CST)",
        "CST6CDT":"( GMT -5:00 ) Central Daylight Time(CST6CDT)",
        "Canada/Central":"( GMT -5:00 ) Central Daylight Time(Canada/Central)",
        "Chile/EasterIsland":"( GMT -5:00 ) Easter Is. Time(Chile/EasterIsland)",
        "Mexico/General":"( GMT -5:00 ) Central Daylight Time(Mexico/General)",
        "Pacific/Easter":"( GMT -5:00 ) Easter Is. Time(Pacific/Easter)",
        "US/Central":"( GMT -5:00 ) Central Daylight Time(US/Central)",
        "US/Indiana-Starke":"( GMT -5:00 ) Eastern Daylight Time(US/Indiana-Starke)",
        "America/Atikokan":"( GMT -5:00 ) Eastern Standard Time(America/Atikokan)",
        "America/Bogota":"( GMT -5:00 ) Colombia Time(America/Bogota)",
        "America/Cayman":"( GMT -5:00 ) Eastern Standard Time(America/Cayman)",
        "America/Coral_Harbour":"( GMT -5:00 ) Eastern Standard Time(America/Coral_Harbour)",
        "America/Eirunepe":"( GMT -5:00 ) Acre Time(America/Eirunepe)",
        "America/Guayaquil":"( GMT -5:00 ) Ecuador Time(America/Guayaquil)",
        "America/Jamaica":"( GMT -5:00 ) Eastern Standard Time(America/Jamaica)",
        "America/Lima":"( GMT -5:00 ) Peru Time(America/Lima)",
        "America/Panama":"( GMT -5:00 ) Eastern Standard Time(America/Panama)",
        "America/Port-au-Prince":"( GMT -5:00 ) Eastern Daylight Time(America/Port-au-Prince)",
        "America/Porto_Acre":"( GMT -5:00 ) Acre Time(America/Porto_Acre)",
        "America/Rio_Branco":"( GMT -5:00 ) Acre Time(America/Rio_Branco)",
        "Brazil/Acre":"( GMT -5:00 ) Acre Time(Brazil/Acre)",
        "EST":"( GMT -5:00 ) Eastern Standard Time(EST)",
        "Etc/GMT+5":"( GMT -5:00 ) (Etc/GMT+5)",
        "Jamaica":"( GMT -5:00 ) Eastern Standard Time(Jamaica)",
        "America/Indiana/Petersburg":"( GMT -4:00 ) Central Daylight Time(America/Indiana/Petersburg)",
        "America/Indiana/Vincennes":"( GMT -4:00 ) Central Daylight Time(America/Indiana/Vincennes)",
        "America/Detroit":"( GMT -4:00 ) Eastern Daylight Time(America/Detroit)",
        "America/Fort_Wayne":"( GMT -4:00 ) Eastern Daylight Time(America/Fort_Wayne)",
        "America/Grand_Turk":"( GMT -4:00 ) Eastern Daylight Time(America/Grand_Turk)",
        "America/Havana":"( GMT -4:00 ) Central Daylight Time(America/Havana)",
        "America/Indiana/Indianapolis":"( GMT -4:00 ) Eastern Daylight Time(America/Indiana/Indianapolis)",
        "America/Indiana/Marengo":"( GMT -4:00 ) Eastern Daylight Time(America/Indiana/Marengo)",
        "America/Indiana/Vevay":"( GMT -4:00 ) Eastern Daylight Time(America/Indiana/Vevay)",
        "America/Indianapolis":"( GMT -4:00 ) Eastern Daylight Time(America/Indianapolis)",
        "America/Iqaluit":"( GMT -4:00 ) Eastern Daylight Time(America/Iqaluit)",
        "America/Kentucky/Louisville":"( GMT -4:00 ) Eastern Daylight Time(America/Kentucky/Louisville)",
        "America/Kentucky/Monticello":"( GMT -4:00 ) Eastern Daylight Time(America/Kentucky/Monticello)",
        "America/Louisville":"( GMT -4:00 ) Eastern Daylight Time(America/Louisville)",
        "America/Montreal":"( GMT -4:00 ) Eastern Daylight Time(America/Montreal)",
        "America/Nassau":"( GMT -4:00 ) Eastern Daylight Time(America/Nassau)",
        "America/New_York":"( GMT -4:00 ) Eastern Daylight Time(America/New_York)",
        "America/Nipigon":"( GMT -4:00 ) Eastern Daylight Time(America/Nipigon)",
        "America/Pangnirtung":"( GMT -4:00 ) Eastern Daylight Time(America/Pangnirtung)",
        "America/Thunder_Bay":"( GMT -4:00 ) Eastern Daylight Time(America/Thunder_Bay)",
        "America/Toronto":"( GMT -4:00 ) Eastern Daylight Time(America/Toronto)",
        "Canada/Eastern":"( GMT -4:00 ) Eastern Daylight Time(Canada/Eastern)",
        "Cuba":"( GMT -4:00 ) Central Daylight Time(Cuba)",
        "EST5EDT":"( GMT -4:00 ) Eastern Daylight Time(EST5EDT)",
        "US/East-Indiana":"( GMT -4:00 ) Eastern Daylight Time(US/East-Indiana)",
        "US/Eastern":"( GMT -4:00 ) Eastern Daylight Time(US/Eastern)",
        "US/Michigan":"( GMT -4:00 ) Eastern Daylight Time(US/Michigan)",
        "America/Caracas":"( GMT -4:00 ) Venezuela Time(America/Caracas)",
        "America/Anguilla":"( GMT -4:00 ) Atlantic Standard Time(America/Anguilla)",
        "America/Antigua":"( GMT -4:00 ) Atlantic Standard Time(America/Antigua)",
        "America/Aruba":"( GMT -4:00 ) Atlantic Standard Time(America/Aruba)",
        "America/Barbados":"( GMT -4:00 ) Atlantic Standard Time(America/Barbados)",
        "America/Blanc-Sablon":"( GMT -4:00 ) Atlantic Standard Time(America/Blanc-Sablon)",
        "America/Boa_Vista":"( GMT -4:00 ) Amazon Time(America/Boa_Vista)",
        "America/Campo_Grande":"( GMT -4:00 ) Amazon Time(America/Campo_Grande)",
        "America/Cuiaba":"( GMT -4:00 ) Amazon Time(America/Cuiaba)",
        "America/Curacao":"( GMT -4:00 ) Atlantic Standard Time(America/Curacao)",
        "America/Dominica":"( GMT -4:00 ) Atlantic Standard Time(America/Dominica)",
        "America/Grenada":"( GMT -4:00 ) Atlantic Standard Time(America/Grenada)",
        "America/Guadeloupe":"( GMT -4:00 ) Atlantic Standard Time(America/Guadeloupe)",
        "America/Guyana":"( GMT -4:00 ) Guyana Time(America/Guyana)",
        "America/La_Paz":"( GMT -4:00 ) Bolivia Time(America/La_Paz)",
        "America/Manaus":"( GMT -4:00 ) Amazon Time(America/Manaus)",
        "America/Martinique":"( GMT -4:00 ) Atlantic Standard Time(America/Martinique)",
        "America/Montserrat":"( GMT -4:00 ) Atlantic Standard Time(America/Montserrat)",
        "America/Port_of_Spain":"( GMT -4:00 ) Atlantic Standard Time(America/Port_of_Spain)",
        "America/Porto_Velho":"( GMT -4:00 ) Amazon Time(America/Porto_Velho)",
        "America/Puerto_Rico":"( GMT -4:00 ) Atlantic Standard Time(America/Puerto_Rico)",
        "America/Santo_Domingo":"( GMT -4:00 ) Atlantic Standard Time(America/Santo_Domingo)",
        "America/St_Kitts":"( GMT -4:00 ) Atlantic Standard Time(America/St_Kitts)",
        "America/St_Lucia":"( GMT -4:00 ) Atlantic Standard Time(America/St_Lucia)",
        "America/St_Thomas":"( GMT -4:00 ) Atlantic Standard Time(America/St_Thomas)",
        "America/St_Vincent":"( GMT -4:00 ) Atlantic Standard Time(America/St_Vincent)",
        "America/Tortola":"( GMT -4:00 ) Atlantic Standard Time(America/Tortola)",
        "America/Virgin":"( GMT -4:00 ) Atlantic Standard Time(America/Virgin)",
        "Brazil/West":"( GMT -4:00 ) Amazon Time(Brazil/West)",
        "Etc/GMT+4":"( GMT -4:00 ) (Etc/GMT+4)",
        "America/Asuncion":"( GMT -3:00 ) Paraguay Time(America/Asuncion)",
        "America/Glace_Bay":"( GMT -3:00 ) Atlantic Daylight Time(America/Glace_Bay)",
        "America/Goose_Bay":"( GMT -3:00 ) Atlantic Daylight Time(America/Goose_Bay)",
        "America/Halifax":"( GMT -3:00 ) Atlantic Daylight Time(America/Halifax)",
        "America/Moncton":"( GMT -3:00 ) Atlantic Daylight Time(America/Moncton)",
        "America/Santiago":"( GMT -3:00 ) Chile Time(America/Santiago)",
        "America/Thule":"( GMT -3:00 ) Atlantic Daylight Time(America/Thule)",
        "Antarctica/Palmer":"( GMT -3:00 ) Chile Time(Antarctica/Palmer)",
        "Atlantic/Bermuda":"( GMT -3:00 ) Atlantic Daylight Time(Atlantic/Bermuda)",
        "Atlantic/Stanley":"( GMT -3:00 ) Falkland Is. Summer Time(Atlantic/Stanley)",
        "Canada/Atlantic":"( GMT -3:00 ) Atlantic Daylight Time(Canada/Atlantic)",
        "Chile/Continental":"( GMT -3:00 ) Chile Time(Chile/Continental)",
        "America/Araguaina":"( GMT -3:00 ) Brasilia Time(America/Araguaina)",
        "America/Argentina/Buenos_Aires":"( GMT -3:00 ) Argentine Time(America/Argentina/Buenos_Aires)",
        "America/Argentina/Catamarca":"( GMT -3:00 ) Argentine Time(America/Argentina/Catamarca)",
        "America/Argentina/ComodRivadavia":"( GMT -3:00 ) Argentine Time(America/Argentina/ComodRivadavia)",
        "America/Argentina/Cordoba":"( GMT -3:00 ) Argentine Time(America/Argentina/Cordoba)",
        "America/Argentina/Jujuy":"( GMT -3:00 ) Argentine Time(America/Argentina/Jujuy)",
        "America/Argentina/La_Rioja":"( GMT -3:00 ) Argentine Time(America/Argentina/La_Rioja)",
        "America/Argentina/Mendoza":"( GMT -3:00 ) Argentine Time(America/Argentina/Mendoza)",
        "America/Argentina/Rio_Gallegos":"( GMT -3:00 ) Argentine Time(America/Argentina/Rio_Gallegos)",
        "America/Argentina/San_Juan":"( GMT -3:00 ) Argentine Time(America/Argentina/San_Juan)",
        "America/Argentina/Tucuman":"( GMT -3:00 ) Argentine Time(America/Argentina/Tucuman)",
        "America/Argentina/Ushuaia":"( GMT -3:00 ) Argentine Time(America/Argentina/Ushuaia)",
        "America/Bahia":"( GMT -3:00 ) Brasilia Time(America/Bahia)",
        "America/Belem":"( GMT -3:00 ) Brasilia Time(America/Belem)",
        "America/Buenos_Aires":"( GMT -3:00 ) Argentine Time(America/Buenos_Aires)",
        "America/Catamarca":"( GMT -3:00 ) Argentine Time(America/Catamarca)",
        "America/Cayenne":"( GMT -3:00 ) French Guiana Time(America/Cayenne)",
        "America/Cordoba":"( GMT -3:00 ) Argentine Time(America/Cordoba)",
        "America/Fortaleza":"( GMT -3:00 ) Brasilia Time(America/Fortaleza)",
        "America/Jujuy":"( GMT -3:00 ) Argentine Time(America/Jujuy)",
        "America/Maceio":"( GMT -3:00 ) Brasilia Time(America/Maceio)",
        "America/Mendoza":"( GMT -3:00 ) Argentine Time(America/Mendoza)",
        "America/Montevideo":"( GMT -3:00 ) Uruguay Time(America/Montevideo)",
        "America/Paramaribo":"( GMT -3:00 ) Suriname Time(America/Paramaribo)",
        "America/Recife":"( GMT -3:00 ) Brasilia Time(America/Recife)",
        "America/Rosario":"( GMT -3:00 ) Argentine Time(America/Rosario)",
        "America/Sao_Paulo":"( GMT -3:00 ) Brasilia Time(America/Sao_Paulo)",
        "Antarctica/Rothera":"( GMT -3:00 ) Rothera Time(Antarctica/Rothera)",
        "Brazil/East":"( GMT -3:00 ) Brasilia Time(Brazil/East)",
        "Etc/GMT+3":"( GMT -3:00 ) (Etc/GMT+3)",
        "America/St_Johns":"( GMT -2:-30 ) Newfoundland Daylight Time(America/St_Johns)",
        "Canada/Newfoundland":"( GMT -2:-30 ) Newfoundland Daylight Time(Canada/Newfoundland)",
        "America/Godthab":"( GMT -2:00 ) Western Greenland Summer Time(America/Godthab)",
        "America/Miquelon":"( GMT -2:00 ) Pierre &amp; Miquelon Daylight Time(America/Miquelon)",
        "America/Noronha":"( GMT -2:00 ) Fernando de Noronha Time(America/Noronha)",
        "Atlantic/South_Georgia":"( GMT -2:00 ) South Georgia Standard Time(Atlantic/South_Georgia)",
        "Brazil/DeNoronha":"( GMT -2:00 ) Fernando de Noronha Time(Brazil/DeNoronha)",
        "Etc/GMT+2":"( GMT -2:00 ) (Etc/GMT+2)",
        "Atlantic/Cape_Verde":"( GMT -1:00 ) Cape Verde Time(Atlantic/Cape_Verde)",
        "Etc/GMT+1":"( GMT -1:00 ) (Etc/GMT+1)",
        "America/Scoresbysund":"( GMT +0:00 ) Eastern Greenland Summer Time(America/Scoresbysund)",
        "Atlantic/Azores":"( GMT +0:00 ) Azores Summer Time(Atlantic/Azores)",
        "Africa/Abidjan":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Abidjan)",
        "Africa/Accra":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Accra)",
        "Africa/Bamako":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Bamako)",
        "Africa/Banjul":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Banjul)",
        "Africa/Bissau":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Bissau)",
        "Africa/Conakry":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Conakry)",
        "Africa/Dakar":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Dakar)",
        "Africa/Freetown":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Freetown)",
        "Africa/Lome":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Lome)",
        "Africa/Monrovia":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Monrovia)",
        "Africa/Nouakchott":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Nouakchott)",
        "Africa/Ouagadougou":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Ouagadougou)",
        "Africa/Sao_Tome":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Sao_Tome)",
        "Africa/Timbuktu":"( GMT + 0:00 ) Greenwich Mean Time(Africa/Timbuktu)",
        "America/Danmarkshavn":"( GMT + 0:00 ) Greenwich Mean Time(America/Danmarkshavn)",
        "Atlantic/Reykjavik":"( GMT + 0:00 ) Greenwich Mean Time(Atlantic/Reykjavik)",
        "Atlantic/St_Helena":"( GMT + 0:00 ) Greenwich Mean Time(Atlantic/St_Helena)",
        "Etc/GMT":"( GMT + 0:00 ) (Etc/GMT)",
        "Etc/GMT+0":"( GMT + 0:00 ) (Etc/GMT+0)",
        "Etc/GMT-0":"( GMT + 0:00 ) (Etc/GMT-0)",
        "Etc/GMT0":"( GMT + 0:00 ) (Etc/GMT0)",
        "Etc/Greenwich":"( GMT + 0:00 ) Greenwich Mean Time(Etc/Greenwich)",
        "Etc/UCT":"( GMT + 0:00 ) Coordinated Universal Time(Etc/UCT)",
        "Etc/UTC":"( GMT + 0:00 ) Coordinated Universal Time(Etc/UTC)",
        "Etc/Universal":"( GMT + 0:00 ) Coordinated Universal Time(Etc/Universal)",
        "Etc/Zulu":"( GMT + 0:00 ) Coordinated Universal Time(Etc/Zulu)",
        "GMT":"( GMT + 0:00 ) Greenwich Mean Time(GMT)",
        "GMT0":"( GMT + 0:00 ) (GMT0)",
        "Greenwich":"( GMT + 0:00 ) Greenwich Mean Time(Greenwich)",
        "Iceland":"( GMT + 0:00 ) Greenwich Mean Time(Iceland)",
        "UCT":"( GMT + 0:00 ) Coordinated Universal Time(UCT)",
        "UTC":"( GMT + 0:00 ) Coordinated Universal Time(UTC)",
        "Universal":"( GMT + 0:00 ) Coordinated Universal Time(Universal)",
        "Zulu":"( GMT + 0:00 ) Coordinated Universal Time(Zulu)",
        "Africa/Casablanca":"( GMT + 0:00 ) Western European Time(Africa/Casablanca)",
        "Africa/El_Aaiun":"( GMT + 0:00 ) Western European Time(Africa/El_Aaiun)",
        "Atlantic/Canary":"( GMT + 0:00 ) Western European Summer Time(Atlantic/Canary)",
        "Atlantic/Faeroe":"( GMT + 0:00 ) Western European Summer Time(Atlantic/Faeroe)",
        "Atlantic/Madeira":"( GMT + 0:00 ) Western European Summer Time(Atlantic/Madeira)",
        "Eire":"( GMT + 0:00 ) Irish Summer Time(Eire)",
        "Europe/Belfast":"( GMT + 0:00 ) British Summer Time(Europe/Belfast)",
        "Europe/Dublin":"( GMT + 0:00 ) Irish Summer Time(Europe/Dublin)",
        "Europe/Guernsey":"( GMT + 0:00 ) British Summer Time(Europe/Guernsey)",
        "Europe/Isle_of_Man":"( GMT + 0:00 ) British Summer Time(Europe/Isle_of_Man)",
        "Europe/Jersey":"( GMT + 0:00 ) British Summer Time(Europe/Jersey)",
        "Europe/Lisbon":"( GMT + 0:00 ) Western European Summer Time(Europe/Lisbon)",
        "Europe/London":"( GMT + 0:00 ) British Summer Time(Europe/London)",
        "GB":"( GMT + 0:00 ) British Summer Time(GB)",
        "GB-Eire":"( GMT + 0:00 ) British Summer Time(GB-Eire)",
        "Portugal":"( GMT + 0:00 ) Western European Summer Time(Portugal)",
        "WET":"( GMT + 0:00 ) Western European Summer Time(WET)",
        "Africa/Algiers":"( GMT + 1:00 ) Central European Time(Africa/Algiers)",
        "Africa/Bangui":"( GMT + 1:00 ) Western African Time(Africa/Bangui)",
        "Africa/Brazzaville":"( GMT + 1:00 ) Western African Time(Africa/Brazzaville)",
        "Africa/Douala":"( GMT + 1:00 ) Western African Time(Africa/Douala)",
        "Africa/Kinshasa":"( GMT + 1:00 ) Western African Time(Africa/Kinshasa)",
        "Africa/Lagos":"( GMT + 1:00 ) Western African Time(Africa/Lagos)",
        "Africa/Libreville":"( GMT + 1:00 ) Western African Time(Africa/Libreville)",
        "Africa/Luanda":"( GMT + 1:00 ) Western African Time(Africa/Luanda)",
        "Africa/Malabo":"( GMT + 1:00 ) Western African Time(Africa/Malabo)",
        "Africa/Ndjamena":"( GMT + 1:00 ) Western African Time(Africa/Ndjamena)",
        "Africa/Niamey":"( GMT + 1:00 ) Western African Time(Africa/Niamey)",
        "Africa/Porto-Novo":"( GMT + 1:00 ) Western African Time(Africa/Porto-Novo)",
        "Africa/Tunis":"( GMT + 1:00 ) Central European Summer Time(Africa/Tunis)",
        "Etc/GMT-1":"( GMT + 1:00 ) (Etc/GMT-1)",
        "Africa/Ceuta":"( GMT + 1:00 ) Central European Summer Time(Africa/Ceuta)",
        "Africa/Windhoek":"( GMT + 1:00 ) Western African Summer Time(Africa/Windhoek)",
        "Arctic/Longyearbyen":"( GMT + 1:00 ) Central European Summer Time(Arctic/Longyearbyen)",
        "Atlantic/Jan_Mayen":"( GMT + 1:00 ) Central European Summer Time(Atlantic/Jan_Mayen)",
        "CET":"( GMT + 1:00 ) Central European Summer Time(CET)",
        "ECT":"( GMT + 1:00 ) Central European Summer Time(ECT)",
        "Europe/Amsterdam":"( GMT + 1:00 ) Central European Summer Time(Europe/Amsterdam)",
        "Europe/Andorra":"( GMT + 1:00 ) Central European Summer Time(Europe/Andorra)",
        "Europe/Belgrade":"( GMT + 1:00 ) Central European Summer Time(Europe/Belgrade)",
        "Europe/Berlin":"( GMT + 1:00 ) Central European Summer Time(Europe/Berlin)",
        "Europe/Bratislava":"( GMT + 1:00 ) Central European Summer Time(Europe/Bratislava)",
        "Europe/Brussels":"( GMT + 1:00 ) Central European Summer Time(Europe/Brussels)",
        "Europe/Budapest":"( GMT + 1:00 ) Central European Summer Time(Europe/Budapest)",
        "Europe/Copenhagen":"( GMT + 1:00 ) Central European Summer Time(Europe/Copenhagen)",
        "Europe/Gibraltar":"( GMT + 1:00 ) Central European Summer Time(Europe/Gibraltar)",
        "Europe/Ljubljana":"( GMT + 1:00 ) Central European Summer Time(Europe/Ljubljana)",
        "Europe/Luxembourg":"( GMT + 1:00 ) Central European Summer Time(Europe/Luxembourg)",
        "Europe/Madrid":"( GMT + 1:00 ) Central European Summer Time(Europe/Madrid)",
        "Europe/Malta":"( GMT + 1:00 ) Central European Summer Time(Europe/Malta)",
        "Europe/Monaco":"( GMT + 1:00 ) Central European Summer Time(Europe/Monaco)",
        "Europe/Oslo":"( GMT + 1:00 ) Central European Summer Time(Europe/Oslo)",
        "Europe/Paris":"( GMT + 1:00 ) Central European Summer Time(Europe/Paris)",
        "Europe/Podgorica":"( GMT + 1:00 ) Central European Summer Time(Europe/Podgorica)",
        "Europe/Prague":"( GMT + 1:00 ) Central European Summer Time(Europe/Prague)",
        "Europe/Rome":"( GMT + 1:00 ) Central European Summer Time(Europe/Rome)",
        "Europe/San_Marino":"( GMT + 1:00 ) Central European Summer Time(Europe/San_Marino)",
        "Europe/Sarajevo":"( GMT + 1:00 ) Central European Summer Time(Europe/Sarajevo)",
        "Europe/Skopje":"( GMT + 1:00 ) Central European Summer Time(Europe/Skopje)",
        "Europe/Stockholm":"( GMT + 1:00 ) Central European Summer Time(Europe/Stockholm)",
        "Europe/Tirane":"( GMT + 1:00 ) Central European Summer Time(Europe/Tirane)",
        "Europe/Vaduz":"( GMT + 1:00 ) Central European Summer Time(Europe/Vaduz)",
        "Europe/Vatican":"( GMT + 1:00 ) Central European Summer Time(Europe/Vatican)",
        "Europe/Vienna":"( GMT + 1:00 ) Central European Summer Time(Europe/Vienna)",
        "Europe/Warsaw":"( GMT + 1:00 ) Central European Summer Time(Europe/Warsaw)",
        "Europe/Zagreb":"( GMT + 1:00 ) Central European Summer Time(Europe/Zagreb)",
        "Europe/Zurich":"( GMT + 1:00 ) Central European Summer Time(Europe/Zurich)",
        "MET":"( GMT + 1:00 ) Middle Europe Summer Time(MET)",
        "Poland":"( GMT + 1:00 ) Central European Summer Time(Poland)",
        "ART":"( GMT + 2:00 ) Eastern European Summer Time(ART)",
        "Africa/Blantyre":"( GMT + 2:00 ) Central African Time(Africa/Blantyre)",
        "Africa/Bujumbura":"( GMT + 2:00 ) Central African Time(Africa/Bujumbura)",
        "Africa/Cairo":"( GMT + 2:00 ) Eastern European Summer Time(Africa/Cairo)",
        "Africa/Gaborone":"( GMT + 2:00 ) Central African Time(Africa/Gaborone)",
        "Africa/Harare":"( GMT + 2:00 ) Central African Time(Africa/Harare)",
        "Africa/Johannesburg":"( GMT + 2:00 ) South Africa Standard Time(Africa/Johannesburg)",
        "Africa/Kigali":"( GMT + 2:00 ) Central African Time(Africa/Kigali)",
        "Africa/Lubumbashi":"( GMT + 2:00 ) Central African Time(Africa/Lubumbashi)",
        "Africa/Lusaka":"( GMT + 2:00 ) Central African Time(Africa/Lusaka)",
        "Africa/Maputo":"( GMT + 2:00 ) Central African Time(Africa/Maputo)",
        "Africa/Maseru":"( GMT + 2:00 ) South Africa Standard Time(Africa/Maseru)",
        "Africa/Mbabane":"( GMT + 2:00 ) South Africa Standard Time(Africa/Mbabane)",
        "Africa/Tripoli":"( GMT + 2:00 ) Eastern European Time(Africa/Tripoli)",
        "CAT":"( GMT + 2:00 ) Central African Time(CAT)",
        "Egypt":"( GMT + 2:00 ) Eastern European Summer Time(Egypt)",
        "Etc/GMT-2":"( GMT + 2:00 ) (Etc/GMT-2)",
        "Europe/Kaliningrad":"( GMT + 3:00 ) Eastern European Summer Time(Europe/Kaliningrad)",
        "Libya":"( GMT + 2:00 ) Eastern European Time(Libya)",
        "Asia/Amman":"( GMT + 2:00 ) Eastern European Summer Time(Asia/Amman)",
        "Asia/Beirut":"( GMT + 2:00 ) Eastern European Summer Time(Asia/Beirut)",
        "Asia/Damascus":"( GMT + 2:00 ) Eastern European Summer Time(Asia/Damascus)",
        "Asia/Gaza":"( GMT + 2:00 ) Eastern European Summer Time(Asia/Gaza)",
        "Asia/Istanbul":"( GMT + 2:00 ) Eastern European Summer Time(Asia/Istanbul)",
        "Asia/Jerusalem":"( GMT + 2:00 ) Israel Standard Time(Asia/Jerusalem)",
        "Asia/Nicosia":"( GMT + 2:00 ) Eastern European Summer Time(Asia/Nicosia)",
        "Asia/Tel_Aviv":"( GMT + 2:00 ) Israel Standard Time(Asia/Tel_Aviv)",
        "EET":"( GMT + 2:00 ) Eastern European Summer Time(EET)",
        "Europe/Athens":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Athens)",
        "Europe/Bucharest":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Bucharest)",
        "Europe/Chisinau":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Chisinau)",
        "Europe/Helsinki":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Helsinki)",
        "Europe/Istanbul":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Istanbul)",
        "Europe/Kiev":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Kiev)",
        "Europe/Mariehamn":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Mariehamn)",
        "Europe/Minsk":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Minsk)",
        "Europe/Nicosia":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Nicosia)",
        "Europe/Riga":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Riga)",
        "Europe/Simferopol":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Simferopol)",
        "Europe/Sofia":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Sofia)",
        "Europe/Tallinn":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Tallinn)",
        "Europe/Tiraspol":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Tiraspol)",
        "Europe/Uzhgorod":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Uzhgorod)",
        "Europe/Vilnius":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Vilnius)",
        "Europe/Zaporozhye":"( GMT + 2:00 ) Eastern European Summer Time(Europe/Zaporozhye)",
        "Israel":"( GMT + 2:00 ) Israel Standard Time(Israel)",
        "Turkey":"( GMT + 2:00 ) Eastern European Summer Time(Turkey)",
        "Africa/Addis_Ababa":"( GMT + 3:00 ) Eastern African Time(Africa/Addis_Ababa)",
        "Africa/Asmera":"( GMT + 3:00 ) Eastern African Time(Africa/Asmera)",
        "Africa/Dar_es_Salaam":"( GMT + 3:00 ) Eastern African Time(Africa/Dar_es_Salaam)",
        "Africa/Djibouti":"( GMT + 3:00 ) Eastern African Time(Africa/Djibouti)",
        "Africa/Kampala":"( GMT + 3:00 ) Eastern African Time(Africa/Kampala)",
        "Africa/Khartoum":"( GMT + 3:00 ) Eastern African Time(Africa/Khartoum)",
        "Africa/Mogadishu":"( GMT + 3:00 ) Eastern African Time(Africa/Mogadishu)",
        "Africa/Nairobi":"( GMT + 3:00 ) Eastern African Time(Africa/Nairobi)",
        "Antarctica/Syowa":"( GMT + 3:00 ) Syowa Time(Antarctica/Syowa)",
        "Asia/Aden":"( GMT + 3:00 ) Arabia Standard Time(Asia/Aden)",
        "Asia/Baghdad":"( GMT + 3:00 ) Arabia Daylight Time(Asia/Baghdad)",
        "Asia/Bahrain":"( GMT + 3:00 ) Arabia Standard Time(Asia/Bahrain)",
        "Asia/Kuwait":"( GMT + 3:00 ) Arabia Standard Time(Asia/Kuwait)",
        "Asia/Qatar":"( GMT + 3:00 ) Arabia Standard Time(Asia/Qatar)",
        "Asia/Riyadh":"( GMT + 3:00 ) Arabia Standard Time(Asia/Riyadh)",
        "EAT":"( GMT + 3:00 ) Eastern African Time(EAT)",
        "Etc/GMT-3":"( GMT + 3:00 ) (Etc/GMT-3)",
        "Europe/Moscow":"( GMT + 4:00 ) Moscow Daylight Time(Europe/Moscow)",
        "Europe/Volgograd":"( GMT + 3:00 ) Volgograd Summer Time(Europe/Volgograd)",
        "Indian/Antananarivo":"( GMT + 3:00 ) Eastern African Time(Indian/Antananarivo)",
        "Indian/Comoro":"( GMT + 3:00 ) Eastern African Time(Indian/Comoro)",
        "Indian/Mayotte":"( GMT + 3:00 ) Eastern African Time(Indian/Mayotte)",
        "W-SU":"( GMT + 3:00 ) Moscow Daylight Time(W-SU)",
        "Asia/Tehran":"( GMT + 3:30 ) Iran Standard Time(Asia/Tehran)",
        "Iran":"( GMT + 3:30 ) Iran Standard Time(Iran)",
        "Asia/Baku":"( GMT + 4:00 ) Azerbaijan Summer Time(Asia/Baku)",
        "Asia/Dubai":"( GMT + 4:00 ) Gulf Standard Time(Asia/Dubai)",
        "Asia/Muscat":"( GMT + 4:00 ) Gulf Standard Time(Asia/Muscat)",
        "Asia/Tbilisi":"( GMT + 4:00 ) Georgia Time(Asia/Tbilisi)",
        "Asia/Yerevan":"( GMT + 4:00 ) Armenia Summer Time(Asia/Yerevan)",
        "Etc/GMT-4":"( GMT + 4:00 ) (Etc/GMT-4)",
        "Europe/Samara":"( GMT + 4:00 ) Samara Summer Time(Europe/Samara)",
        "Indian/Mahe":"( GMT + 4:00 ) Seychelles Time(Indian/Mahe)",
        "Indian/Mauritius":"( GMT + 4:00 ) Mauritius Time(Indian/Mauritius)",
        "Indian/Reunion":"( GMT + 4:00 ) Reunion Time(Indian/Reunion)",
        "NET":"( GMT + 4:00 ) Armenia Summer Time(NET)",
        "Asia/Kabul":"( GMT + 4:30 ) Afghanistan Time(Asia/Kabul)",
        "Asia/Aqtau":"( GMT + 5:00 ) Aqtau Time(Asia/Aqtau)",
        "Asia/Aqtobe":"( GMT + 5:00 ) Aqtobe Time(Asia/Aqtobe)",
        "Asia/Ashgabat":"( GMT + 5:00 ) Turkmenistan Time(Asia/Ashgabat)",
        "Asia/Ashkhabad":"( GMT + 5:00 ) Turkmenistan Time(Asia/Ashkhabad)",
        "Asia/Dushanbe":"( GMT + 5:00 ) Tajikistan Time(Asia/Dushanbe)",
        "Asia/Karachi":"( GMT + 5:00 ) Pakistan Time(Asia/Karachi)",
        "Asia/Oral":"( GMT + 5:00 ) Oral Time(Asia/Oral)",
        "Asia/Samarkand":"( GMT + 5:00 ) Uzbekistan Time(Asia/Samarkand)",
        "Asia/Tashkent":"( GMT + 5:00 ) Uzbekistan Time(Asia/Tashkent)",
        "Asia/Yekaterinburg":"( GMT + 6:00 ) Yekaterinburg Summer Time(Asia/Yekaterinburg)",
        "Etc/GMT-5":"( GMT + 5:00 ) (Etc/GMT-5)",
        "Indian/Kerguelen":"( GMT + 5:00 ) French Southern &amp; Antarctic Lands Time(Indian/Kerguelen)",
        "Indian/Maldives":"( GMT + 5:00 ) Maldives Time(Indian/Maldives)",
        "Antarctica/Mawson":"( GMT + 6:00 ) Mawson Time(Antarctica/Mawson)",
        "Asia/Kolkata":"( GMT + 5:30 ) India Standard Time(Asia/Calcutta)",
        "Asia/Colombo":"( GMT + 5:30 ) Sri Lanka Time(Asia/Colombo)",
        "Asia/Katmandu":"( GMT + 5:45 ) Nepal Time(Asia/Katmandu)",
        "Antarctica/Vostok":"( GMT + 6:00 ) Vostok Time(Antarctica/Vostok)",
        "Asia/Almaty":"( GMT + 6:00 ) Alma-Ata Time(Asia/Almaty)",
        "Asia/Bishkek":"( GMT + 6:00 ) Kirgizstan Time(Asia/Bishkek)",
        "Asia/Dacca":"( GMT + 6:00 ) Bangladesh Time(Asia/Dacca)",
        "Asia/Dhaka":"( GMT + 6:00 ) Bangladesh Time(Asia/Dhaka)",
        "Asia/Omsk":"( GMT + 7:00 ) Omsk Summer Time(Asia/Omsk)",
        "Asia/Qyzylorda":"( GMT + 6:00 ) Qyzylorda Time(Asia/Qyzylorda)",
        "Asia/Thimbu":"( GMT + 6:00 ) Bhutan Time(Asia/Thimbu)",
        "Asia/Thimphu":"( GMT + 6:00 ) Bhutan Time(Asia/Thimphu)",
        "BST":"( GMT + 6:00 ) Bangladesh Time(BST)",
        "Etc/GMT-6":"( GMT + 6:00 ) (Etc/GMT-6)",
        "Indian/Chagos":"( GMT + 6:00 ) Indian Ocean Territory Time(Indian/Chagos)",
        "Asia/Kashgar":"( GMT + 8:00 ) China Standard Time(Asia/Kashgar)",
        "Asia/Urumqi":"( GMT + 8:00 ) China Standard Time(Asia/Urumqi)",
        "Asia/Rangoon":"( GMT + 6:30 ) Myanmar Time(Asia/Rangoon)",
        "Indian/Cocos":"( GMT + 6:30 ) Cocos Islands Time(Indian/Cocos)",
        "Asia/Novosibirsk":"( GMT + 7:00 ) Novosibirsk Summer Time(Asia/Novosibirsk)",
        "Antarctica/Davis":"( GMT + 7:00 ) Davis Time(Antarctica/Davis)",
        "Asia/Bangkok":"( GMT + 7:00 ) Indochina Time(Asia/Bangkok)",
        "Asia/Hovd":"( GMT + 7:00 ) Hovd Summer Time(Asia/Hovd)",
        "Asia/Jakarta":"( GMT + 7:00 ) West Indonesia Time(Asia/Jakarta)",
        "Asia/Krasnoyarsk":"( GMT + 8:00 ) Krasnoyarsk Summer Time(Asia/Krasnoyarsk)",
        "Asia/Phnom_Penh":"( GMT + 7:00 ) Indochina Time(Asia/Phnom_Penh)",
        "Asia/Pontianak":"( GMT + 7:00 ) West Indonesia Time(Asia/Pontianak)",
        "Asia/Saigon":"( GMT + 7:00 ) Indochina Time(Asia/Saigon)",
        "Asia/Vientiane":"( GMT + 7:00 ) Indochina Time(Asia/Vientiane)",
        "Etc/GMT-7":"( GMT + 7:00 ) (Etc/GMT-7)",
        "Indian/Christmas":"( GMT + 7:00 ) Christmas Island Time(Indian/Christmas)",
        "Asia/Brunei":"( GMT + 8:00 ) Brunei Time(Asia/Brunei)",
        "Asia/Chongqing":"( GMT + 8:00 ) China Standard Time(Asia/Chongqing)",
        "Asia/Harbin":"( GMT + 8:00 ) China Standard Time(Asia/Harbin)",
        "Asia/Hong_Kong":"( GMT + 8:00 ) Hong Kong Time(Asia/Hong_Kong)",
        "Asia/Irkutsk":"( GMT + 9:00 ) Irkutsk Summer Time(Asia/Irkutsk)",
        "Asia/Kuala_Lumpur":"( GMT + 8:00 ) Malaysia Time(Asia/Kuala_Lumpur)",
        "Asia/Kuching":"( GMT + 8:00 ) Malaysia Time(Asia/Kuching)",
        "Asia/Macao":"( GMT + 8:00 ) China Standard Time(Asia/Macao)",
        "Asia/Makassar":"( GMT + 8:00 ) Central Indonesia Time(Asia/Makassar)",
        "Asia/Manila":"( GMT + 8:00 ) Philippines Time(Asia/Manila)",
        "Asia/Shanghai":"( GMT + 8:00 ) China Standard Time(Asia/Shanghai)",
        "Asia/Singapore":"( GMT + 8:00 ) Singapore Time(Asia/Singapore)",
        "Asia/Taipei":"( GMT + 8:00 ) China Standard Time(Asia/Taipei)",
        "Asia/Ujung_Pandang":"( GMT + 8:00 ) Central Indonesia Time(Asia/Ujung_Pandang)",
        "Asia/Ulaanbaatar":"( GMT + 8:00 ) Ulaanbaatar Summer Time(Asia/Ulaanbaatar)",
        "Asia/Ulan_Bator":"( GMT + 8:00 ) Ulaanbaatar Summer Time(Asia/Ulan_Bator)",
        "Australia/Perth":"( GMT + 8:00 ) Western Standard Time (Australia)(Australia/Perth)",
        "Australia/West":"( GMT + 8:00 ) Western Standard Time (Australia)(Australia/West)",
        "Etc/GMT-8":"( GMT + 8:00 ) (Etc/GMT-8)",
        "PRC":"( GMT + 8:00 ) China Standard Time(PRC)",
        "Singapore":"( GMT + 8:00 ) Singapore Time(Singapore)",
        "Asia/Choibalsan":"( GMT + 9:00 ) Choibalsan Summer Time(Asia/Choibalsan)",
        "Asia/Pyongyang":"( GMT + 9:00 ) Korea Standard Time(Asia/Pyongyang)",
        "Asia/Dili":"( GMT + 9:00 ) East Timor Time(Asia/Dili)",
        "Asia/Jayapura":"( GMT + 9:00 ) East Indonesia Time(Asia/Jayapura)",
        "Asia/Seoul":"( GMT + 9:00 ) Korea Standard Time(Asia/Seoul)",
        "Asia/Tokyo":"( GMT + 9:00 ) Japan Standard Time(Asia/Tokyo)",
        "Asia/Yakutsk":"( GMT + 10:00 ) Yaktsk Summer Time(Asia/Yakutsk)",
        "Etc/GMT-9":"( GMT + 9:00 ) (Etc/GMT-9)",
        "JST":"( GMT + 9:00 ) Japan Standard Time(JST)",
        "Japan":"( GMT + 9:00 ) Japan Standard Time(Japan)",
        "Pacific/Palau":"( GMT + 9:00 ) Palau Time(Pacific/Palau)",
        "ROK":"( GMT + 9:00 ) Korea Standard Time(ROK)",
        "ACT":"( GMT + 9:30 ) Central Standard Time (Northern Territory)(ACT)",
        "Australia/Darwin":"( GMT + 9:30 ) Central Standard Time (Northern Territory)(Australia/Darwin)",
        "Australia/North":"( GMT + 9:30 ) Central Standard Time (Northern Territory)(Australia/North)",
        "Antarctica/DumontDUrville":"( GMT + 10:00 ) Dumont-d Urville Time(Antarctica/DumontDUrville)",
        "Asia/Vladivostok":"( GMT + 10:00 ) Vladivostok Summer Time(Asia/Vladivostok)",
        "Australia/Brisbane":"( GMT + 10:00 ) Eastern Standard Time (Queensland)(Australia/Brisbane)",
        "Australia/Lindeman":"( GMT + 10:00 ) Eastern Standard Time (Queensland)(Australia/Lindeman)",
        "Australia/Queensland":"( GMT + 10:00 ) Eastern Standard Time (Queensland)(Australia/Queensland)",
        "Etc/GMT-10":"( GMT + 10:00 ) (Etc/GMT-10)(Etc/GMT-10)",
        "Pacific/Guam":"( GMT + 10:00 ) Chamorro Standard Time(Pacific/Guam)",
        "Pacific/Port_Moresby":"( GMT + 10:00 ) Papua New Guinea Time(Pacific/Port_Moresby)",
        "Pacific/Saipan":"( GMT + 10:00 ) Chamorro Standard Time(Pacific/Saipan)",
        "Pacific/Truk":"( GMT + 10:00 ) Truk Time(Pacific/Truk)",
        "Pacific/Yap":"( GMT + 10:00 ) Yap Time(Pacific/Yap)",
        "Australia/Adelaide":"( GMT + 9:30 ) Central Standard Time (South Australia)(Australia/Adelaide)",
        "Australia/Broken_Hill":"( GMT + 9:30 ) Central Standard Time (South Australia/New South Wales)(Australia/Broken_Hill)",
        "Australia/South":"( GMT + 9:30 ) Central Standard Time (South Australia)(Australia/South)",
        "Australia/Yancowinna":"( GMT + 9:30 ) Central Standard Time (South Australia/New South Wales)(Australia/Yancowinna)",
        "Antarctica/Casey":"( GMT + 8:00 ) Western Standard Time (Australia)(Antarctica/Casey)",
        "Asia/Sakhalin":"( GMT + 11:00 ) Sakhalin Summer Time(Asia/Sakhalin)",
        "Australia/ACT":"( GMT + 10:00 ) Eastern Standard Time (New South Wales)(Australia/ACT)",
        "Australia/Canberra":"( GMT + 10:00 ) Eastern Standard Time (New South Wales)(Australia/Canberra)",
        "Australia/Currie":"( GMT + 10:00 ) Eastern Standard Time (New South Wales)(Australia/Currie)",
        "Australia/Hobart":"( GMT + 10:00 ) Eastern Standard Time (Tasmania)(Australia/Hobart)",
        "Australia/Melbourne":"( GMT + 10:00 ) Eastern Standard Time (Victoria)(Australia/Melbourne)",
        "Australia/NSW":"( GMT + 10:00 ) Eastern Standard Time (New South Wales)(Australia/NSW)",
        "Australia/Sydney":"( GMT + 10:00 ) Eastern Standard Time (New South Wales)(Australia/Sydney)",
        "Australia/Tasmania":"( GMT + 10:00 ) Eastern Standard Time (Tasmania)(Australia/Tasmania)",
        "Australia/Victoria":"( GMT + 10:00 ) Eastern Standard Time (Victoria)(Australia/Victoria)",
        "Australia/LHI":"( GMT + 10:30 ) Load Howe Standard Time(Australia/LHI)",
        "Australia/Lord_Howe":"( GMT + 10:30 ) Load Howe Standard Time(Australia/Lord_Howe)",
        "Asia/Magadan":"( GMT + 12:00 ) Magadan Summer Time(Asia/Magadan)",
        "Etc/GMT-11":"( GMT + 11:00 ) (Etc/GMT-11)",
        "Pacific/Efate":"( GMT + 11:00 ) Vanuatu Time(Pacific/Efate)",
        "Pacific/Guadalcanal":"( GMT + 11:00 ) Solomon Is. Time(Pacific/Guadalcanal)",
        "Pacific/Kosrae":"( GMT + 11:00 ) Kosrae Time(Pacific/Kosrae)",
        "Pacific/Noumea":"( GMT + 11:00 ) New Caledonia Time(Pacific/Noumea)",
        "Pacific/Ponape":"( GMT + 11:00 ) Ponape Time(Pacific/Ponape)",
        "SST":"( GMT + 11:00 ) Solomon Is. Time(SST)",
        "Pacific/Norfolk":"( GMT + 11:30 ) Norfolk Time(Pacific/Norfolk)",
        "Asia/Anadyr":"( GMT + 12:00 ) Anadyr Summer Time(Asia/Anadyr)",
        "Asia/Kamchatka":"( GMT + 12:00 ) Petropavlovsk-Kamchatski Summer Time(Asia/Kamchatka)",
        "Etc/GMT-12":"( GMT + 12:00 ) (Etc/GMT-12)",
        "Kwajalein":"( GMT + 12:00 ) Marshall Islands Time(Kwajalein)",
        "Pacific/Fiji":"( GMT + 12:00 ) Fiji Time(Pacific/Fiji)",
        "Pacific/Funafuti":"( GMT + 12:00 ) Tuvalu Time(Pacific/Funafuti)",
        "Pacific/Kwajalein":"( GMT + 12:00 ) Marshall Islands Time(Pacific/Kwajalein)",
        "Pacific/Majuro":"( GMT + 12:00 ) Marshall Islands Time(Pacific/Majuro)",
        "Pacific/Nauru":"( GMT + 12:00 ) Nauru Time(Pacific/Nauru)",
        "Pacific/Tarawa":"( GMT + 12:00 ) Gilbert Is. Time(Pacific/Tarawa)",
        "Pacific/Wake":"( GMT + 12:00 ) Wake Time(Pacific/Wake)",
        "Pacific/Wallis":"( GMT + 12:00 ) Wallis &amp; Futuna Time(Pacific/Wallis)",
        "Pacific/Fakaofo":"( GMT +13:00 ) Tokelau Time(Pacific/Fakaofo)",
        "Antarctica/McMurdo":"( GMT + 12:00 ) New Zealand Standard Time(Antarctica/McMurdo)",
        "Antarctica/South_Pole":"( GMT + 12:00 ) New Zealand Standard Time(Antarctica/South_Pole)",
        "NST":"( GMT + 12:00 ) New Zealand Standard Time(NST)",
        "NZ":"( GMT + 12:00 ) New Zealand Standard Time(NZ)",
        "Pacific/Auckland":"( GMT + 12:00 ) New Zealand Standard Time(Pacific/Auckland)",
        "Etc/GMT-13":"( GMT + 13:00 ) (Etc/GMT-13)",
        "Pacific/Enderbury":"( GMT + 13:00 ) Phoenix Is. Time(Pacific/Enderbury)",
        "Pacific/Tongatapu":"( GMT + 13:00 ) Tonga Time(Pacific/Tongatapu)",
        "NZ-CHAT":"( GMT + 12:45 ) Chatham Standard Time(NZ-CHAT)",
        "Pacific/Chatham":"( GMT + 12:45 ) Chatham Standard Time(Pacific/Chatham)",
        "Pacific/Apia":"( GMT +14:00 ) West Samoa Time(Pacific/Apia)",
        "Etc/GMT-14":"( GMT + 14:00 ) (Etc/GMT-14)",
        "Pacific/Kiritimati":"( GMT + 14:00 ) Line Is. Time(Pacific/Kiritimati)"
     };
}
export default new MasterData; 