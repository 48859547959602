import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormArray, Validators, FormArrayName, FormControl } from '@angular/forms';
import { ValidateService } from 'app/services/validate.service';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-performa-invoice',
  templateUrl: './performa-invoice.component.html',
  styleUrls: ['./performa-invoice.component.scss']
})
export class PerformaInvoiceComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  PeformaForm: FormGroup;
  showAll_options: boolean = false;
  cc: boolean;
  bcc: boolean;
  companies: any;
  user_type: any;
  shotochips: boolean = false;
  customer_type: any;
  showusertype: boolean = true;
  sendtomails: string;
  ckeditor: any;

  constructor(
    private commonService: CommonService,
    private configService: ConfigurationService,
    public dialog: MatDialog, 
    private validateservice: ValidateService,
    private fb: FormBuilder, ) {
    
  }


  ngOnInit() {
    
  this.PeformaForm = this.fb.group({
    billed_by: ['', Validators.required],
    bank_details: ['', Validators.required]
  })
    this.getFormDetails();
  }
  
  ngAfterViewInit(): void {
  }
  
  onReady(e) {
    this.ckeditor  = e.editor;
  }

  
  getFormDetails() {
    this.configService.getProformaConfig({}).subscribe(res => {
      if (res.status === 1) {
        this.PeformaForm.patchValue({
          bank_details: res.data.bank_details,
          billed_by :  res.data.billed_by     
        });
        // this.companies = res.data;
      }
    })
  }

  save() {
    
    if (this.PeformaForm.valid) {

      let params = {
        bank_details: this.PeformaForm.controls.bank_details.value,
        billed_by: this.PeformaForm.controls.billed_by.value,
      }
      this.configService.saveProforma(params).subscribe(res => {
        if(res.status) {
          this.commonService.openSnackBar('primary', res.message);
        } else {
          this.commonService.openSnackBar('warn', res.message);
        }
      })
    } else {
      this.validateservice.validateAllFormFields(this.PeformaForm);
    }
  }

}
