<div class="page-layout carded fullwidth inner-scroll">
    <mat-toolbar-row class="secondary-headers w-100-p px-20">
        <h2 class="sub-heading">{{displayedList == 'list'?'Reveneu Forecast':'Due Customers'}}</h2>
    </mat-toolbar-row>
    <div class="center px-20">
        <div class="content-card mt-12 mb-52">
            <div class="content" fusePerfectScrollbar>
                <div fxLayout='row' fxLayoutAlign="start center" class="fuse-white-bg px-12 py-4">
                    
                   

                    <div fxLayout='row wrap' fxLayoutAlign="start center">
                        <ng-container *ngIf="showList">
                            <mat-button-toggle-group name="list_type" (change)="getSelectedDisplayList('past_dues')" [value]="displayedList">
                                <mat-button-toggle value="past_dues">Past Dues</mat-button-toggle>
                            </mat-button-toggle-group>
                        </ng-container>
                    </div>
                    <div fxFlex="70">
                        <ng-container *ngIf="monthsResponseData && showList">
                        <mat-button-toggle-group name="forecast_month"  [value]="listSelectedMonth">
                            <ng-container *ngFor="let monthData of foreCastMonths;let i = index">
                                <mat-button-toggle [value]="monthData.month_number" (click)="changeForeCastMonth(i);getSelectedDisplayList('list')">{{monthData.month_name}}</mat-button-toggle>
                            </ng-container>
                        </mat-button-toggle-group>
                    </ng-container>
                    </div>

                    <div fxFlex="20">
                        <ng-container *ngIf="displayedList == 'list'">
                            <button mat-icon-button [ngClass]="{'primary': showList}" (click)="showList = !showList">
                                <mat-icon matTooltip="List">list</mat-icon>
                            </button>
                            <button mat-icon-button [ngClass]="{'primary': !showList}" (click)="showList = !showList;showChartData(1)">
                                <mat-icon matTooltip="chart">insert_chart_outlined
                                </mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </div>

                <div fxLayout='row' fxLayoutAlign="start center" class="fuse-white-bg p-8 border m-8 border-radius-8" *ngIf="(revenewSummary && showList) || displayedList == 'past_dues'">
                    <div fxFlex="60" class="border-right pr-16" >
                        <ng-container *ngIf="displayedList == 'past_dues'">
                            <div fxLayout='row wrap' fxLayoutAlign="space-between center" class="h-40">
                                <ng-container *ngIf="dueCustomemrsrevenewSummary">
                                <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                                    <div fxFlex="65" class="sub-heading">
                                        Due Amount(USA) :
                                    </div>
                                    <div fxFlex="35" class="sub-heading ">
                                        <ng-container>
                                            ${{dueCustomemrsrevenewSummary.usa_due_amount | number : '1.2-2'}}
                                        </ng-container>
                                    </div>
                                </div>
                                <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                                    <div fxFlex="65" class="sub-heading">
                                        Due Amount(India) :
                                    </div>
                                    <div fxFlex="35" class="sub-heading ">
                                        ₹{{dueCustomemrsrevenewSummary.india_due_amount | number : '1.2-2'}}
                                    </div>
                                </div>
                                <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="space-between baseline" class="pt-12">
                                    <div fxFlex="65" class="sub-heading">
                                        Due Amount(Singapore) :
                                    </div>
                                    <div fxFlex="35" class="sub-heading ">
                                        ${{dueCustomemrsrevenewSummary.singapore_due_amount | number : '1.2-2'}}
                                    </div>
                                </div>
                            </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="displayedList == 'list'">
                            <div fxLayout='row' fxLayoutAlign="space-between center" class="h-40">
                                <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                                    <div fxFlex="65" class="sub-heading">
                                        Total Forecasted :
                                    </div>
                                    <div fxFlex="35" class="sub-heading ">
                                        {{currencySymbol}}{{revenewSummary.total_forcasted | number : '1.2-2'}}
                                    </div>
                                </div>
                                <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                                    <div fxFlex="65" class="sub-heading">
                                        Due :
                                    </div>
                                    <div fxFlex="35" class="sub-heading ">
                                        {{currencySymbol}}{{revenewSummary.due_amount | number : '1.2-2'}}
                                    </div>
                                </div>
                                <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                                    <div fxFlex="65" class="sub-heading">
                                        Paid :
                                    </div>
                                    <div fxFlex="35" class="sub-heading green-200-bg">
                                        {{currencySymbol}}{{revenewSummary.paid_amount | number : '1.2-2'}}
                                    </div>
                                </div>
                            </div>
                            <div fxLayout='row' fxLayoutAlign="space-between center" class="h-40">
                                <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                                    <div fxFlex="65" class="sub-heading">
                                        Amount Credited (Stripe) :
                                    </div>
                                    <div fxFlex="35" class="sub-heading ">
                                        {{currencySymbol}}{{revenewSummary.stripe_credit | number : '1.2-2'}}
                                    </div>
                                </div>
                                <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                                    <div fxFlex="60" class="sub-heading">
                                        Overdue :
                                    </div>
                                    <div fxFlex="40" class="sub-heading red-200-bg">
                                        {{currencySymbol}}{{revenewSummary.over_due_amount | number : '1.2-2'}}
                                    </div>
                                </div>
                                <div fxFlex="33" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
                                    <div fxFlex="65" class="sub-heading">
                                        Lost :
                                    </div>
                                    <div fxFlex="35" class="sub-heading grey-200-bg">
                                        {{currencySymbol}}{{revenewSummary.lost_customer_amount | number : '1.2-2'}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div fxFlex="40" class="pl-16">
                        <h2 class="sub-heading m-0">Filters By</h2>
                        <div fxLayout='row wrap' fxLayoutAlign="space-between center" class="mb-12">
                            <div fxFlex="35">
                                <mat-form-field class="w-100-p" appearance="outline">
                                    <mat-label>Status</mat-label>
                                    <mat-select appMatSearchSelector class="w-100-p"
                                        (selectionChange)="onStatusChange($event)" [(ngModel)]="status_id">
                                        <app-mat-search-selector placeholder='Search..'></app-mat-search-selector>
                                        <ng-container *ngFor="let status of statusList">
                                            <mat-option *appMatSearchSelectorItems [value]="status.status">
                                                {{status.name}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="60">
                                <!-- <mat-form-field appearance='outline' class="w-100-p" matTooltipPosition="above">
                                    <mat-label>Date within oct</mat-label>
                                    <input matInput [matDatepicker]="picker" (click)="picker.open()">
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field> -->
    
                                <form [formGroup]="dateRangeForm">
                                    <mat-form-field appearance='outline' class="w-100-p" matTooltipPosition="above">
                                        <mat-label>Select</mat-label>
                                        <mat-date-range-input [rangePicker]="picker">
                                            <input matStartDate placeholder="Start date" formControlName="start_date">
                                            <input matEndDate placeholder="End date" formControlName="end_date">
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-date-range-picker #picker></mat-date-range-picker>
                                    </mat-form-field>
                                </form>
                            </div>
                        </div>

                        <div fxLayout='row wrap' fxLayoutAlign="start center" *ngIf="displayedList == 'past_dues'">
                            <mat-button-toggle-group name="country" (change)="changeDueCustomersCounry($event)" [value]="selectedDueCustomerCountry">
                                <mat-button-toggle value="1">USA</mat-button-toggle>
                                <mat-button-toggle value="2">India</mat-button-toggle>
                                <mat-button-toggle value="702">Singapore</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        
                        <div fxLayout='row wrap' fxLayoutAlign="start center" *ngIf="showList && displayedList == 'list'">
                            <mat-button-toggle-group name="country" (change)="changeCounry($event)" [value]="listSelectedCountry">
                                <mat-button-toggle value="1">USA</mat-button-toggle>
                                <mat-button-toggle value="2">India</mat-button-toggle>
                                <mat-button-toggle value="702">Singapore</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
    
                        <div fxLayout='row wrap' fxLayoutAlign="start center" *ngIf="!showList && displayedList == 'list'">
                            <mat-button-toggle-group name="chart_country" (change)="chartChangeCounry($event)" value="1">
                                <mat-button-toggle value="1">USA</mat-button-toggle>
                                <mat-button-toggle value="2">India</mat-button-toggle>
                                <mat-button-toggle value="702">Singapore</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        
                        
                    </div>
                </div>

                <ng-container *ngIf="dataSource && showList && displayedList == 'list'">
                    <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12 py-12">
                        <div class="search-wrapper" fxFlex="30">
                            <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
                                [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue"
                                placeholder="Search by Customer">
                            <mat-icon class="search_icon">search</mat-icon>
                            <mat-icon class="close s-20 danger-fg" *ngIf="searchValue"
                                (click)="searchValue=''; applyFilter('');">
                                close
                            </mat-icon>
                        </div>
                        <div *ngIf="totalRecords>0">
                            <button mat-flat-button class="primary" (click)="exportExcel()">Export</button>
                        </div>
                    </div>
                    <div class="">
                        <div class="px-12 pb-12">
                            <mat-table [dataSource]="dataSource">

                                <ng-container matColumnDef="sno">
                                    <mat-header-cell *matHeaderCellDef fxFlex="60px"> S.No </mat-header-cell>
                                    <mat-cell *matCellDef="let element;let i = index" fxFlex="60px"> {{i+1}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="customer_name">
                                    <mat-header-cell *matHeaderCellDef fxFlex="200px"> customer name </mat-header-cell>
                                    <mat-cell *matCellDef="let element" fxFlex="200px"> 
                                        <a (click)="navigate(element)" class="cursor-pointer">{{element.name}}</a>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="bill_amount">
                                    <mat-header-cell *matHeaderCellDef> bill amount </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.billing_amount}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="billing_cycle">
                                    <mat-header-cell *matHeaderCellDef> billing cycle </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.billing_cycle}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="billing_date">
                                    <mat-header-cell *matHeaderCellDef> billing date </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.payment_date}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="status">
                                    <mat-header-cell *matHeaderCellDef> status </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.subscription_status}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="license_cost">
                                    <mat-header-cell *matHeaderCellDef> license cost </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.licenses_amount}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="addon_cost">
                                    <mat-header-cell *matHeaderCellDef> addon cost </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.addons_amount}} </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayedColumns;"
                                    [ngClass]="{'green-200-bg': row.subscription_status == 'Paid', 'grey-200-bg': row.subscription_status == 'Lost', 'red-200-bg': row.subscription_status == 'Over Due', 'yellow-200-bg': row.subscription_status == 'Upcoming'}">
                                </mat-row>
                            </mat-table>

                            <div *ngIf="totalRecords == 0" class="text-center">
                                <h2 class="sub-heading">No Records Found</h2>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- Chart-1 -->
                <div *ngIf="!showList && dataChartSource && displayedList == 'list'" fxLayout='row wrap' fxLayoutAlign="start center"
                    class="m-8 p-20 border border-radius-8">
                    <div fxFlex="40">
                        <fusioncharts width="100%" height="400" type="stackedcolumn2dline" dataFormat="json"
                            [dataSource]="monthlySubscriptionChartDataSource">
                        </fusioncharts>
                    </div>
                    <div fxFlex="60">

                        <mat-table [dataSource]="dataChartSource">

                            <ng-container matColumnDef="month">
                                <mat-header-cell *matHeaderCellDef> Month </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase"> {{element.month_name}}
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="projected">
                                <mat-header-cell *matHeaderCellDef> Projected ({{currencySymbol}}) </mat-header-cell>
                                <mat-cell *matCellDef="let element" class="lowercase"> {{getForeCastAmount(element.year_month) | number:'1.2-2'}}
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedChartColumns;sticky:true"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedChartColumns;"></mat-row>
                        </mat-table>
                    </div>
                </div>

                <ng-container *ngIf="dueCustomemrsdataSource && displayedList == 'past_dues'">
                    <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12 py-12">
                        <div class="search-wrapper" fxFlex="30">
                            <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
                                [readOnly]="dueCustomemrsdataSource.length === 0" [(ngModel)]="dueCustomemrssearchValue"
                                placeholder="Search by Customer">
                            <mat-icon class="search_icon">search</mat-icon>
                            <mat-icon class="close s-20 danger-fg" *ngIf="dueCustomemrssearchValue"
                                (click)="dueCustomemrssearchValue=''; applyFilter('');">
                                close
                            </mat-icon>
                        </div>
                        <div *ngIf="totalRecords>0">
                            <button mat-flat-button class="primary" (click)="exportExcel()">Export</button>
                        </div>
                    </div>
                    <div class="">
                        <div class="px-12 pb-12">
                            <mat-table [dataSource]="dueCustomemrsdataSource">

                                <ng-container matColumnDef="sno">
                                    <mat-header-cell *matHeaderCellDef fxFlex="60px"> S.No </mat-header-cell>
                                    <mat-cell *matCellDef="let element" fxFlex="60px" > {{element.s_no}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="customer_name">
                                    <mat-header-cell *matHeaderCellDef fxFlex="200px"> customer name </mat-header-cell>
                                    <mat-cell *matCellDef="let element" fxFlex="200px" >
                                        <a (click)="navigate(element)" class="cursor-pointer">{{element.name}}</a>
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="region">
                                    <mat-header-cell *matHeaderCellDef> region </mat-header-cell>
                                    <mat-cell *matCellDef="let element" > {{element.region}}
                                    </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="bill_amount">
                                    <mat-header-cell *matHeaderCellDef> bill amount </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.billing_amount}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="billing_cycle">
                                    <mat-header-cell *matHeaderCellDef> billing cycle </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.billing_cycle}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="billing_date">
                                    <mat-header-cell *matHeaderCellDef> billing date </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.billing_date}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="license_cost">
                                    <mat-header-cell *matHeaderCellDef> license cost </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.licenses_amount}} </mat-cell>
                                </ng-container>

                                <ng-container matColumnDef="addon_cost">
                                    <mat-header-cell *matHeaderCellDef> addon cost </mat-header-cell>
                                    <mat-cell *matCellDef="let element"> {{element.addons_amount}} </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="dueCustomemrsdisplayedColumns;sticky:true"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: dueCustomemrsdisplayedColumns">
                                </mat-row>
                            </mat-table>

                            <div *ngIf="dueCustomemrstotalRecords == 0" class="text-center">
                                <h2 class="sub-heading">No Records Found</h2>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>