import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from './apiurls';
import { SharedServiceService } from './shared-service.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyDetailsService {

  constructor(
    private sharedService: SharedServiceService
  ) { }

  addCustomerAccountBalance(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.addCustomerAccountBalance, params);
  }

  addTextRecruiter(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.addTextRecruiter, params);
  }

  editTextRecruiter(id): Observable<any> {
    const url = ApiUrls.companyInfo.editTextRecruiter + '/' + id;
    return this.sharedService.get(url);
  }

  saveGst(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.savegst, params);
  }

  getBillingData(company_id): Observable<any> {
    const url = ApiUrls.companyInfo.billingDetails + '/' + company_id;
    return this.sharedService.get(url, {});
  }

  getSubscriptionDetails(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getSubscriptionDetails, params);
  }

  enableFreeAppAccess(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.enableFreeAppAccess, params);
  }

  saveAddonsAsFree(params: any): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveAddonsAsFree, params);
  }

  getAddonList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getAddon, params)
  }

  discardCompanyReports(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.DiscardCompanyReports, params);
  }

  discardCompanyPayments(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.DiscardCompanyPayments, params);
  }

  companyType(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.setCompanyType, params);
  }

  companyAutoRenewal(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.updateCompanyAutoRenewal, params);
  }

  bulkImportAccess(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.bulkImport, params);
  }

  get_pro_rate(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.get_pro_rate, params);
  }

  convertLicensesToPaid(params): Observable<any> {
    if (params.payment_gateway == 2) {
      return this.sharedService.post(ApiUrls.companyInfo.stripeConvertFreeLicenses, params);
    }
    // return this.sharedService.post(ApiUrls.companyInfo.convertLicenesToPaid, params);
  }

  cronEmails(params) {
    return this.sharedService.post(ApiUrls.companyInfo.cronEmails, params);
  }

  cronEmailClean(params) {
    return this.sharedService.post(ApiUrls.companyInfo.cronEmailClean, params);
  }

  updateIntegrationCtrls(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.updateIntegrationCtrls, params);
  }

  getCurrentStage(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getCurrentStage, params);
  }

  getCpanelRoleUsers(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.getCpanelRoleUsers, params);
  }

  getStageInfo(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getStageInfo, params);
  }

  saveDataMigrationStageInfo(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveDataMigration, params);
  }

  getEmailTemplateData(params): Observable<any> {
    return this.sharedService.get(ApiUrls.commonUrls.emailTemplateData, params);
  }

  getUserlist(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getUserList, params);
  }

  getUserActivities(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.usersActivities, params);
  }

  updateMfaInfo(params): Observable<any> {
    return this.sharedService.post(ApiUrls.users.updateMfaInfo, params);
  }

  revokeUser(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.revokeUser, params);
  }

  deleteUser(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.deleteUser, params);
  }

  convertAsEmpolyee(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.employeeAccess, params);
  }

  UserActivationEmail(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.userActivationEmail, params);
  }

  checkUserInPartnerCompany(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.checkUserInPartnerCompanies, params);
  }

  storeCompanyUser(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveCompanyUser, params);
  }

  getImplementationActivities(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.implemetationActivities, params);
  }

  saveImplementationActivities(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveimplemetationActivities, params);
  }

  integrationControlsData(company_id) {
    const url = ApiUrls.companyInfo.integrationControls + '/' + company_id;
    return this.sharedService.get(url, {});
  }

  updatePaymentGateway(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.paymentGatewayUpdate, params);
  }

  getInvoiceList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getInvoiceList, params);
  }

  
  downloadProforma(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.downloadProforma, params);
  }
  uploadInvoicePdf(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.uploadInvoicePdf, params);
  }

  uploadTdsDocument(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.upload_tds_document, params);
  }

  
  doMassMailsSplit(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.splitMassMails, params);
  }

  getImportMigrateStatusList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.getImportMigrateStatusList, params);
  }

  getExportFtpFailedData(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.exportFtpFailedData, params);
  }

  downloadFtpFailedData(zip_id) {
    const url = ApiUrls.companyInfo.downloadFailedFTPData + '/' + zip_id;
    return this.sharedService.get<any>(url, null, null, 'arraybuffer');
  }

  getDomainDetails(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getDomainDetails, params);
  }

  addDomain(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.addDomain, params);
  }

  verifyDomain(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.verifyDomain, params);
  }

  deleteDomain(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.deleteDomain, params);
  }

  mailDomainForceAdd(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.forceAddDomain, params);
  }

  partnerSearch(company_id): Observable<any> {
    const url = ApiUrls.companyInfo.partnerSearch + '/' + company_id;
    return this.sharedService.get(url, {});
  }

  enablepartnerSearch(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.enablePartnerSearch, params);
  }

  getRefundRequests(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getRefundData, params);
  }

  processRefund(company_id, refund_id) {
    const url = ApiUrls.companyInfo.processRefund + '/' + company_id + '/' + refund_id;
    return this.sharedService.get(url, {});
  }

  saveRefund(params): Observable<any> {
    if (params.payment_gateway == 1 || params.refund_type == 0) {
      return this.sharedService.post(ApiUrls.companyInfo.addRefund, params);
    } else {
      return this.sharedService.post(ApiUrls.companyInfo.stripePaymentRefund, params);
    }
  }

  removeFreeLicenses(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.freeLicnesesRemove, params);
  }

  getRenewSubscription(company_id): Observable<any> {
    const url = ApiUrls.companyInfo.getRenewSubscription + '/' + company_id;
    return this.sharedService.get(url, {});
  }

  updateRenewalRemainderEmail(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.updateRenewalRemainderEmail, params);
  }

  sendDialogueEmail(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.sendDialogueEmail, params);
  }

  saveTrainingInfo(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveTrainingInfo, params);
  }

  getTrainingData(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getTrainingInfo, params);
  }

  saveCompanyInfo(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveCompanyInfo, params);
  }

  add_addons(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.add_addons, params);
  }

  invoiceURLCheckout(params): Observable<any> {
    var url = ApiUrls.companyInfo.invoiceURLcheckout;
    return this.sharedService.post(url, params);
  }

  addonPaymentCheckout(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.addon_payment_checkout, params);
  }

  renew(params): Observable<any> {
    var url = ApiUrls.companyInfo.renew;
    return this.sharedService.get(url, params);
  }

  upgrade(params): Observable<any> {
    var url = ApiUrls.companyInfo.upgrade;
    return this.sharedService.get(url, params);
  }

  renewCheckout(params): Observable<any> {
    var url = ApiUrls.companyInfo.renewcheckout;
    return this.sharedService.post(url, params);
  }

  upgradeCheckout(params): Observable<any> {
    var url = ApiUrls.companyInfo.upgradecheckout;
    return this.sharedService.post(url, params);
  }

  zeroAmountInvoiceCheckout(params): Observable<any> {
    var url = ApiUrls.companyInfo.zeroAmountInvoiceCheckoutUrl;
    return this.sharedService.post(url, params);
  }

  upgradeSubscription(params): Observable<any> {
    var url = ApiUrls.companyInfo.upgradeSubscriptionUrl;
    return this.sharedService.post(url, params);
  }

  getPlanDetailsUpgrade(params): Observable<any> {
    return this.sharedService.get(ApiUrls.commonUrls.getPlanDetailsUpgrade, params);
  }

  getTopupPlanDetails(params): Observable<any> {
    return this.sharedService.get(ApiUrls.commonUrls.getTopupPlanDetails, params);
  }

  getRenewPlanDetails(params): Observable<any> {
    return this.sharedService.get(ApiUrls.commonUrls.getRenewPlanDetails, params);
  }
  getUpgradeSubscriptionDetails(params): Observable<any> {
    return this.sharedService.get(ApiUrls.commonUrls.getUpgradeSubscriptionDetails, params);
  }

  getAccountDetails(company_id): Observable<any> {
    const url = ApiUrls.companyInfo.getAccountDetails + '/' + company_id;
    return this.sharedService.get(url, {});
  }

  getCompnaySubscriptionDetails(company_id): Observable<any> {
    const url = ApiUrls.companyInfo.getCompanySubscriptionDetails + '/' + company_id;
    return this.sharedService.get(url, {});
  }

  saveCompanySubscription(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveCompanySubscription, params);
  }

  addNewStartDate(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.updateNewSubscriptionStartDate, params);
  }

  saveInvoiceLineItem(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveInvoiceLineItem, params);
  }

  getInvoiceLineItem(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getInvoiceLineItem, params);
  }
}
