<div class="page-layout carded fullwidth inner-scroll" *ngIf="MrrUnlimitedChartDataSource">
  <div class="center px-20">
    <div class="content-card mt-20 mb-4">
      <div class="content" fusePerfectScrollbar>
        <!-- MRR -->
        <section class="m-12 mat-elevation-z5 border-radius-4">
          <div class="px-12 py-4 grey-300-bg" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <h2 class="muli-bold font-size-16 m-0">MRR(Unlimited Subscription )</h2>
            <div fxFlex="20">
              <form [formGroup]="MrrUnlimitedYearForm">
                <mat-form-field appearance="outline" class="w-100-p">
                  <mat-label></mat-label>
                  <mat-select formControlName="country_id">
                    <mat-option *ngFor="let cust of countries" [value]="cust.value">
                      {{cust.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
          </div>
          <div>
            <div class="mt-12">
              <form [formGroup]="MrrUnlimitedYearForm">
                <mat-form-field appearance="outline" class="w-252 ml-12">
                  <mat-label>Select Year</mat-label>
                  <mat-select formControlName="year">
                    <mat-option *ngFor="let year of years" [value]="year.value">
                      {{year.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
            </div>
            <fusioncharts width="100%" height="400" type="stackedcolumn2dline" dataFormat="json"
              [dataSource]="MrrUnlimitedChartDataSource">
            </fusioncharts>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>