import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MailTemplatesViewComponent } from '../mail-templates-view/mail-templates-view.component';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-mail-templates-list',
  templateUrl: './mail-templates-list.component.html',
  styleUrls: ['./mail-templates-list.component.scss']
})
export class MailTemplatesListComponent implements OnInit {
  displayedColumns: string[] = ['template', 'subject', 'actions'];
  dataSource;
  params: { limit: number; page: number; searchKey?: string; };
  length: any;
  pageSize: any;
  index: any;
  constructor(private CommonService: CommonService,
    private configService: ConfigurationService,
    public router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    this.params = this.params = { limit: 15, page: 1 };
    this.getTemplateList(this.params);
  }
  applyFilter(filterValue: string) {
    this.params['searchKey'] = filterValue;
    this.params['page'] = 1;
    this.getTemplateList(this.params);
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getTemplateList(this.params);
  }
  getTemplateList(params) {
    this.configService.getTemplates(params).subscribe(res => {
      if (res.status == 1) {
        this.length = res.data.total;
        this.pageSize = res.data.per_page;
        this.index = res.data.current_page;
        this.dataSource = new MatTableDataSource(res.data.data);
      }
    });
  }
  edit(element) {
    this.router.navigate(['mail_templates/edit', element.id]);
  }

  view(element) {
    const dialogRef = this.dialog.open(MailTemplatesViewComponent, {
      width: '75%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
