<mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">Map Fields</h2>
</mat-toolbar-row>
<div class="page-layout carded fullwidth inner-scroll right-sidebar inner-sidebar mt-52 mb-48 px-40">
    <fuse-sidebar class="sidebar w-520 p-20 mt-12 fuse-white-bg mb-8" position="right" lockedOpen="gt-xs">
        <div class="content" fusePerfectScrollbar *ngIf="userFieldGroup && clientusers">
            <div class="sub-heading my-12"> User Mapping</div>
            <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12"
                *ngFor="let user of clientusers | keyvalue" [formGroup]="userFieldGroup">
                <div fxFlex='50'>
                    {{user.key}}
                </div>
                <div fxFlex='50'>
                    <mat-form-field class="w-100-p" appearance='outline'>
                        <mat-label>Select</mat-label>
                        <mat-select [formControlName]="user.value" appMatSearchSelector>
                            <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                            <mat-option>Select</mat-option>
                            <ng-container *ngFor="let item of arrUsersList">
                                <mat-option *appMatSearchSelectorItems [value]='item.id'>{{item.email}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </fuse-sidebar>
    <div class="center pl-0 ml-0">
        <div class="content-card mt-12 mb-8">

            <div class="content" fusePerfectScrollbar>
                <div class="p-12 pt-32">

                    <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlexFill fxLayoutGap="12px grid"
                        [formGroup]="formFieldsGroup" *ngIf="dbHeaders && formFieldsGroup">
                        <ng-container *ngFor="let field of dbHeaders">
                            <div fxFlex='25'>

                                <mat-form-field class="w-100-p" appearance='outline'>
                                    <mat-label>{{field.label_name}} </mat-label>
                                    <mat-select [formControlName]="field.field_name">
                                        <mat-option>Select</mat-option>
                                        <mat-option *ngFor="let item of excelHeaderslist" [value]='item'>{{item}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>
                        <div fxFlex='25'>
                            <mat-form-field class="w-100-p" appearance='outline'>
                                <mat-label>s3 Resume Folder Path</mat-label>
                                <input type="text" formControlName="s3_folder_path" matInput>
                            </mat-form-field>
                        </div>

                    </div>
                </div>
            </div>



        </div>
    </div>
</div>
<div class="footer">
    <button mat-button (click)="back()">Back</button>
    <button mat-button class="primary" (click)="save()">Save</button>
</div>