import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from './apiurls';
import { SharedServiceService } from './shared-service.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(
    private sharedService: SharedServiceService
  ) { }

  saveUsers(params): Observable<any> {
    return this.sharedService.post(ApiUrls.users.save, params);
  }

  getCompanyListData(): Observable<any> {
    return this.sharedService.get(ApiUrls.bouncedSearch.getlist);
  }

  getbouncedsearchList(params): Observable<any> {
    return this.sharedService.get(ApiUrls.bouncedSearch.getbouncedsearchList, params);
  }

  cronjobslist(params): Observable<any> {
    return this.sharedService.post(ApiUrls.cronjobs.getlist, params);
  }

  cronjobstatusupdate(params): Observable<any> {
    return this.sharedService.post(ApiUrls.cronjobs.cronjobstatus, params);
  }

  getCompanyList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.getListData, params);
  }

  getCronEmail(params): Observable<any> {
    return this.sharedService.post(ApiUrls.cronjobs.getCronEmails, params);
  }

  getlogList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.cronjobs.logList, params);
  }

  getDataMigrationStagePoc(): Observable<any> {
    return this.sharedService.get(ApiUrls.commonUrls.dataMigrationStagePoc, {});
  }

  getCpanelRoleUsers(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.getCpanelRoleUsers, params);
  }

  savevDataMigrationStagePoc(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.saveDataMigrationStagePoc, params);
  }

  sendCommunication(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.sendCommunication, params);
  }

  saveProforma(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.saveProforma, params);
  }


  getProformaConfig(params): Observable<any> {
    return this.sharedService.get(ApiUrls.clients.getProformaConfig, params);
  }

  getTemplates(params): Observable<any> {
    return this.sharedService.get(ApiUrls.mailTemplates.getTemplates, params);
  }

  editTemplate(id): Observable<any> {
    return this.sharedService.get(ApiUrls.mailTemplates.editTemplate + '/' + id);
  }

  saveTemplate(params): Observable<any> {
    return this.sharedService.post(ApiUrls.mailTemplates.saveTemplate, params);
  }

  getQueueLogs(params): Observable<any> {
    return this.sharedService.get(ApiUrls.queues.getQueuesList, params);
  }

  runQueue(id): Observable<any> {
    return this.sharedService.get(ApiUrls.queues.runQueue + '/' + id);
  }

  deleteQueue(id): Observable<any> {
    return this.sharedService.get(ApiUrls.queues.deleteQueue + '/' + id);
  }

  bulkdeleteQueue(data): Observable<any> {
    let params = { 'ids': data };
    return this.sharedService.post(ApiUrls.queues.bulkdeleteQueue, params);
  }

  getAddonsList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getAddonsList, params);
  }

  saveAddon(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveAddon, params);
  }

  updateTrialData(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.updateTrialData, params);
  }

  getTrialPlanAddons(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getTrialPlanAddons, params);
  }

  getCurrentUser(): Observable<any> {
    return this.sharedService.get(ApiUrls.commonUrls.currentUserData, {});
  }

  getUsersList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.users.getListData, params);
  }

  updateStatus(params): Observable<any> {
    return this.sharedService.post(ApiUrls.users.updateStatus, params);
  }

  deleteCpanelUser(request_id): Observable<any> {
    const url = ApiUrls.users.deleteUser + '/' + request_id;
    return this.sharedService.get(url, {});
  }

  getUnsubscribeList(params): Observable<any> {
    return this.sharedService.get(ApiUrls.unsubsribeList.getUnsubscribeList, params);
  }

  saveUnsubscribeEmail(params): Observable<any> {
    return this.sharedService.post(ApiUrls.unsubsribeList.saveUnsubscribeEmail, params);
  }

  deleteUnsubscribeEmail(params): Observable<any> {
    return this.sharedService.post(ApiUrls.unsubsribeList.deleteUnsubscribeEmail, params);
  }

  getFromMailList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.from_email.getListData, params);
  }

  saveFromMailList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.from_email.save, params);
  }

  deleteFromMailList(params): Observable<any> {
    return this.sharedService.post( ApiUrls.from_email.delete, params);
  }

}
