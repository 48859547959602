<div class="px-52">
  <ng-container *ngIf="!payment">
    <mat-horizontal-stepper #stepper>
      <mat-step>
        <ng-template matStepLabel>Choose Plan</ng-template>
        <ng-container>
          <form #subscribeForm="ngForm" (ngSubmit)="saveData()" novalidate>
            <div>
              <div>
                <div class="billing_plans my-12" *ngIf="subscription_type == 'upgrade' || subscription_type == 'renew'">
                  <div class="heading">Billing Cycle</div>
                  <mat-radio-group aria-label="Select an option" class="w-50-p" fxLayout="row wrap"
                    fxLayoutAlign="space-between center" [(ngModel)]="subscription_cycle" name="opList">
                    <mat-radio-button *ngFor="let op of billingCycles; let i = index"
                      (change)="changeBundleCartBillingCycle($event)" [value]="op.cycle" name="opList"
                      [checked]="op.cycle == subscription_cycle">{{op.title}}</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div fxLayout="row wrap" fxLayoutAlign="space-between start">

                  <div fxFlex="64">
                    <!-- pack start -->
                    <div class="" *ngIf="isPackageEnabled['hire']">
                      <h2 class="heading">ATS</h2>
                      <div class="subscription_plans">
                        <ul class="nav nav-tabs pl-0">
                          <ng-container *ngFor="let item of plans['hire'] | keyvalue: unsorted; let i = index">
                            <li class="nav-item w-25-p mr-12 mt-12" *ngIf="item.value.status == 1">
                              <a class="nav-link border-radius-4 border p-16"
                                [ngClass]="plansActiveClassArr['hire'][item.key] == true ? 'primary' : '' "
                                (click)="getSelectedBundlePlanItems('hire', item.key, 1)">
                                <div fxLayout="column wrap" fxLayoutAlign="center center">
                                  <span class="font-medium">{{ getPlanTitle(item.key) }}</span>
                                  <small>
                                    {{getCurrencySymbol(country_id)}} {{
                                    licensesCostArr[this.subscription_cycle][item.key] }} /User/Month<br />
                                  </small>
                                </div>
                              </a>
                            </li>
                          </ng-container>

                        </ul>
                      </div>
                      <!-- addon start -->
                      <div class="py-12">
                        <ng-container *ngIf="atsPlanPaidItems">
                          <div>
                            <div *ngIf="bundleCartAddons[atsPlanPaidItems.user_license?.sb_plan_slug]" class="pt-8"
                              fxLayout="row wrap" fxLayoutAlign="space-between center">
                              <div class="">
                                <h2 class="heading mb-0">{{ atsPlanPaidItems.user_license.addon.name }}</h2>
                                <small>Choose how manu users you can access <span
                                    class="text-success">Oorwin</span></small>
                              </div>
                              <div class="">
                                <mat-form-field appearance="outline">
                                  <input matInput type="number"
                                    customMin="minLicenses[atsPlanPaidItems.user_license?.sb_plan_slug][atsPlanPaidItems.user_license?.addon?.slug]"
                                    name="ats_{{i}}"
                                    (keyup)="addLicenesToCart(atsPlanPaidItems.user_license?.sb_plan_slug, atsPlanPaidItems?.user_license)"
                                    [(ngModel)]="bundleCartAddons[atsPlanPaidItems.user_license?.sb_plan_slug][atsPlanPaidItems.user_license?.addon?.slug].quantity"
                                    [disabled]="checkIsEmpty(atsPlanPaidItems.user_license?.sb_plan_slug) || disablePaidLicenses">
                                </mat-form-field>
                                <div style="color:red"
                                  *ngIf="minLicenses[atsPlanPaidItems.user_license?.sb_plan_slug][atsPlanPaidItems.user_license?.addon?.slug] > bundleCartAddons[atsPlanPaidItems.user_license.sb_plan_slug][atsPlanPaidItems.user_license?.addon?.slug].quantity && (!disablePaidLicenses)">
                                  Min Licenses should be
                                  {{minLicenses[atsPlanPaidItems.user_license?.sb_plan_slug][atsPlanPaidItems.user_license?.addon?.slug]}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="atsPlanPaidItems['paid_addons']">
                          <div>
                            <div class="pb-12" fxLayout="row wrap" fxLayoutAlign="space-between center">
                              <div class="">
                                <h2 class="heading mb-0">Add-ons</h2>
                                <small>Customize and enhance your plan with additional features.</small>
                              </div>
                              <!-- <a href="javascript:;" class="form-text small text-success">Hide</a> -->
                            </div>

                            <div *ngFor="let addonItem of atsPlanPaidItems['paid_addons']">
                              <div class="border border-radius-8 p-12 mb-8" fxLayout="row wrap"
                                fxLayoutAlign="space-between start" *ngIf="bundleCartAddons[addonItem.sb_plan_slug]">
                                <div fxFlex="55">
                                  <div fxLayout="row wrap" fxLayoutAlign="start start">

                                    <div fxFlex="30px">
                                      <mat-checkbox color="primary"
                                        name="addon_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                        [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added"
                                        (change)="addItemtoCartTotalAmount(addonItem, addonItem.price_type)"
                                        [disabled]="checkIsEmpty(bundleCartAddons[addonItem.sb_plan_slug])">
                                      </mat-checkbox>
                                    </div>

                                    <div fxFlex fxLayout="column wrap" fxLayoutAlign="stat start">
                                      <h2 class="heading m-0 mb-8">{{addonItem.addon?.name}}</h2>
                                      <h3 class="sub-heading m-0 mb-8">{{addonItem.addon?.description}}</h3>
                                      <p class="text-muted font-size-12 m-0">{{addonItem.addon?.other_information}}</p>
                                    </div>
                                  </div>
                                </div>

                                <div fxFlex="40">
                                  <ng-container
                                    *ngIf="addonItem.price_type == 'slab' || addonItem.price_type == 'slab_flat_fee'">
                                    <div>
                                      <mat-form-field appearance="outline" class="w-100-p">
                                        <mat-label>Quantity</mat-label>
                                        <mat-select name="quantity_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                          [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].slab_index"
                                          [disabled]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added == 0"
                                          (selectionChange)="addItemtoCartTotalAmount(addonItem)">
                                          <mat-option value="">Select</mat-option>
                                          <mat-option
                                            *ngFor="let slabInfo of addonItem.other_details.slab_data | keyvalue: unsorted;"
                                            [value]="slabInfo.value.slab_index"> {{slabInfo.value.name}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </ng-container>

                                  <ng-container *ngIf="addonItem.price_type == 'slab' ">
                                    <mat-form-field appearance="outline">
                                      <mat-label>Units</mat-label>
                                      <input matInput type="number"
                                        name="addon_units_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                        [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].units"
                                        (keyup)="addItemtoCartTotalAmount(addonItem)"
                                        [disabled]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added == 0">
                                    </mat-form-field>
                                  </ng-container>

                                  <ng-container
                                    *ngIf="addonItem.price_type != 'slab' && addonItem.price_type != 'slab_flat_fee'">
                                    <div class="form-group mb-2">
                                      <small class="form-text text-muted">{{getCurrencySymbol(country_id)}}
                                        {{addonItem.price}} / month</small>
                                    </div>
                                  </ng-container>

                                  <br>

                                  <mat-checkbox color="primary"
                                    [disabled]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added == 0"
                                    name="auto_renew_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                    [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_auto_renew">
                                    Auto Renew</mat-checkbox>
                                </div>
                              </div>
                            </div>

                          </div>
                        </ng-container>
                      </div><!-- addon end -->
                    </div>
                    <!-- pack end -->

                    <!-- pack start -->
                    <div class="" *ngIf="isPackageEnabled['hr']">
                      <h2 class="heading">HR</h2>
                      <div class="subscription_plans">
                        <ul class="nav nav-tabs pl-0">
                          <ng-container *ngFor="let item of plans['hr'] | keyvalue: unsorted; let i = index">
                            <li class="nav-item w-25-p mr-12 mt-12" *ngIf="item.value.status == 1">
                              <a class="nav-link border-radius-4 border p-16"
                                [ngClass]=" plansActiveClassArr['hr'][item.key] == true ? 'primary' : '' "
                                (click)="getSelectedBundlePlanItems('hr', item.key, 1)">
                                <div fxLayout="column wrap" fxLayoutAlign="center center">
                                  <span class="font-medium">{{ getPlanTitle(item.key) }}</span>
                                  <small>
                                    {{getCurrencySymbol(country_id)}} {{
                                    licensesCostArr[this.subscription_cycle][item.key] }} /User/Month<br />
                                  </small>
                                </div>
                              </a>
                            </li>
                          </ng-container>

                        </ul>
                      </div>

                      <!-- addon start -->
                      <div class="py-12">
                        <ng-container *ngIf="hrPlanPaidItems">
                          <div>
                            <div *ngIf="bundleCartAddons[hrPlanPaidItems.user_license?.sb_plan_slug]" class="pt-8"
                              fxLayout="row wrap" fxLayoutAlign="space-between center">
                              <div class="">
                                <h2 class="heading mb-0">{{ hrPlanPaidItems.user_license?.addon?.name }}</h2>
                                <small>Choose how manu users you can access <span
                                    class="text-success">Oorwin</span></small>
                              </div>
                              <div class="">
                                <mat-form-field appearance="outline">
                                  <input matInput type="number"
                                    customMin="minLicenses[hrPlanPaidItems.user_license?.sb_plan_slug][hrPlanPaidItems.user_license?.addon?.slug]"
                                    [name]="hrPlanPaidItems.user_license?.addon?.slug+'_'+i"
                                    (keyup)="addLicenesToCart(hrPlanPaidItems.user_license?.sb_plan_slug, hrPlanPaidItems?.user_license)"
                                    [(ngModel)]="bundleCartAddons[hrPlanPaidItems.user_license?.sb_plan_slug][hrPlanPaidItems.user_license?.addon?.slug].quantity"
                                    [disabled]="checkIsEmpty(hrPlanPaidItems.user_license?.sb_plan_slug) || disablePaidLicenses">
                                </mat-form-field>
                                <div style="color:red"
                                  *ngIf="minLicenses[hrPlanPaidItems.user_license?.sb_plan_slug][hrPlanPaidItems.user_license?.addon?.slug] > bundleCartAddons[hrPlanPaidItems.user_license?.sb_plan_slug][hrPlanPaidItems.user_license?.addon?.slug].quantity && (!disablePaidLicenses)">
                                  Min Licenses should be
                                  {{minLicenses[hrPlanPaidItems.user_license?.sb_plan_slug][hrPlanPaidItems.user_license?.addon?.slug]}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="hrPlanPaidItems && hrPlanPaidItems['paid_addons']">
                          <div>
                            <div class="pb-12" fxLayout="row wrap" fxLayoutAlign="space-between center">
                              <div class="">
                                <h2 class="heading mb-0">Add-ons</h2>
                                <small>Customize and enhance your plan with additional features.</small>
                              </div>
                              <!-- <a href="javascript:;" class="form-text small text-success">Hide</a> -->
                            </div>

                            <div *ngFor="let addonItem of hrPlanPaidItems['paid_addons']">
                              <div class="border border-radius-8 p-12 mb-8"
                                *ngIf="bundleCartAddons[addonItem.sb_plan_slug]" fxLayout="row wrap"
                                fxLayoutAlign="space-between start">
                                <div fxFlex="55">
                                  <div fxLayout="row wrap" fxLayoutAlign="start start">

                                    <div fxFlex="30px">
                                      <mat-checkbox color="primary"
                                        name="addon_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                        [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added"
                                        (change)="addItemtoCartTotalAmount(addonItem, addonItem.price_type)"
                                        [disabled]="checkIsEmpty(bundleCartAddons[addonItem.sb_plan_slug])">
                                      </mat-checkbox>
                                    </div>

                                    <div fxFlex fxLayout="column wrap" fxLayoutAlign="stat start">
                                      <h2 class="heading m-0 mb-8">{{addonItem.addon?.name}}</h2>
                                      <h3 class="sub-heading m-0 mb-8">{{addonItem.addon?.description}}</h3>
                                      <p class="text-muted font-size-12 m-0">{{addonItem.addon?.other_information}}</p>
                                    </div>
                                  </div>
                                </div>

                                <div fxFlex="40">
                                  <ng-container
                                    *ngIf="addonItem.price_type == 'slab' || addonItem.price_type == 'slab_flat_fee'">
                                    <div>
                                      <mat-form-field appearance="outline" class="w-100-p">
                                        <mat-label>Quantity</mat-label>
                                        <mat-select name="quantity_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                          [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].slab_index"
                                          [disabled]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added == 0"
                                          (selectionChange)="addItemtoCartTotalAmount(addonItem)">
                                          <mat-option value="">Select</mat-option>
                                          <mat-option
                                            *ngFor="let slabInfo of addonItem.other_details.slab_data | keyvalue: unsorted"
                                            [value]="slabInfo.value.slab_index"> {{slabInfo.value.name}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </ng-container>

                                  <ng-container *ngIf="addonItem.price_type == 'slab' ">
                                    <mat-form-field appearance="outline">
                                      <mat-label>Units</mat-label>
                                      <input matInput type="number"
                                        name="addon_units_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                        [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].units"
                                        (keyup)="addItemtoCartTotalAmount(addonItem)"
                                        [disabled]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added == 0">
                                    </mat-form-field>
                                  </ng-container>

                                  <ng-container
                                    *ngIf="addonItem.price_type != 'slab' && addonItem.price_type != 'slab_flat_fee'">
                                    <div class="form-group mb-2">
                                      <small class="form-text text-muted">{{getCurrencySymbol(country_id)}}
                                        {{addonItem.price}} / month</small>
                                    </div>
                                  </ng-container>

                                  <br>

                                  <mat-checkbox color="primary"
                                    [disabled]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added == 0"
                                    name="auto_renew_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                    [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_auto_renew">
                                    Auto Renew</mat-checkbox>
                                </div>
                              </div>
                            </div>

                          </div>
                        </ng-container>
                      </div><!-- addon end -->
                    </div><!-- pack end -->

                    <!-- pack start -->
                    <div class="" *ngIf="isPackageEnabled['crm']">
                      <h2 class="heading">CRM</h2>
                      <div class="subscription_plans">
                        <ul class="nav nav-tabs pl-0">
                          <ng-container *ngFor="let item of plans['crm'] | keyvalue: unsorted; let i = index">
                            <li class="nav-item w-25-p mr-12 mt-12" *ngIf="item.value.status == 1">
                              <a class="nav-link border-radius-4 border p-16"
                                [ngClass]=" plansActiveClassArr['crm'][item.key] == true ? 'primary' : '' "
                                (click)="getSelectedBundlePlanItems('crm', item.key, 1)">
                                <div fxLayout="column wrap" fxLayoutAlign="center center">
                                  <span class="font-medium">{{ getPlanTitle(item.key) }}</span>
                                  <small>
                                    {{getCurrencySymbol(country_id)}} {{
                                    licensesCostArr[this.subscription_cycle][item.key] }} /User/Month<br />
                                  </small>
                                </div>
                              </a>
                            </li>
                          </ng-container>

                        </ul>
                      </div>
                      <!-- addon start -->
                      <div class="py-12">
                        <ng-container *ngIf="crmPlanPaidItems">
                          <div>
                            <div *ngIf="bundleCartAddons[crmPlanPaidItems.user_license?.sb_plan_slug]" class="pt-8"
                              fxLayout="row wrap" fxLayoutAlign="space-between center">
                              <div class="">
                                <h2 class="heading mb-0">{{ crmPlanPaidItems.user_license?.addon?.name }}</h2>
                                <small>Choose how manu users you can access <span
                                    class="text-success">Oorwin</span></small>
                              </div>
                              <div class="">
                                <mat-form-field appearance="outline">
                                  <input matInput type="number"
                                    customMin="minLicenses[crmPlanPaidItems.user_license?.sb_plan_slug][crmPlanPaidItems.user_license?.addon?.slug]"
                                    [name]="crmPlanPaidItems.user_license?.addon?.slug+'_'+i"
                                    (keyup)="addLicenesToCart(crmPlanPaidItems.user_license?.sb_plan_slug, crmPlanPaidItems?.user_license)"
                                    [(ngModel)]="bundleCartAddons[crmPlanPaidItems.user_license?.sb_plan_slug][crmPlanPaidItems.user_license?.addon?.slug].quantity"
                                    [disabled]="checkIsEmpty(crmPlanPaidItems.user_license?.sb_plan_slug) || disablePaidLicenses">
                                </mat-form-field>
                                <div style="color:red"
                                  *ngIf="minLicenses[crmPlanPaidItems.user_license?.sb_plan_slug][crmPlanPaidItems.user_license?.addon?.slug] > bundleCartAddons[crmPlanPaidItems.user_license?.sb_plan_slug][crmPlanPaidItems.user_license?.addon?.slug].quantity && (!disablePaidLicenses)">
                                  Min Licenses should be
                                  {{minLicenses[crmPlanPaidItems.user_license?.sb_plan_slug][crmPlanPaidItems.user_license?.addon?.slug]}}
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="crmPlanPaidItems && crmPlanPaidItems['paid_addons']">
                          <div>
                            <div class="pb-12" fxLayout="row wrap" fxLayoutAlign="space-between center">
                              <div class="">
                                <h2 class="heading mb-0">Add-ons</h2>
                                <small>Customize and enhance your plan with additional features.</small>
                              </div>
                              <!-- <a href="javascript:;" class="form-text small text-success">Hide</a> -->
                            </div>

                            <div *ngFor="let addonItem of crmPlanPaidItems['paid_addons']">
                              <div class="border border-radius-8 p-12 mb-8"
                                *ngIf="bundleCartAddons[addonItem.sb_plan_slug]" fxLayout="row wrap"
                                fxLayoutAlign="space-between start">
                                <div fxFlex="55">
                                  <div fxLayout="row wrap" fxLayoutAlign="start start">

                                    <div fxFlex="30px">
                                      <mat-checkbox color="primary"
                                        name="addon_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                        [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added"
                                        (change)="addItemtoCartTotalAmount(addonItem, addonItem.price_type)"
                                        [disabled]="checkIsEmpty(bundleCartAddons[addonItem.sb_plan_slug])">
                                      </mat-checkbox>
                                    </div>

                                    <div fxFlex fxLayout="column wrap" fxLayoutAlign="stat start">
                                      <h2 class="heading m-0 mb-8">{{addonItem.addon?.name}}</h2>
                                      <h3 class="sub-heading m-0 mb-8">{{addonItem.addon?.description}}</h3>
                                      <p class="text-muted font-size-12 m-0">{{addonItem.addon?.other_information}}</p>
                                    </div>
                                  </div>
                                </div>

                                <div fxFlex="40">
                                  <ng-container
                                    *ngIf="addonItem.price_type == 'slab' || addonItem.price_type == 'slab_flat_fee'">
                                    <div>
                                      <mat-form-field appearance="outline" class="w-100-p">
                                        <mat-label>Quantity</mat-label>
                                        <mat-select name="quantity_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                          [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].slab_index"
                                          [disabled]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added == 0"
                                          (selectionChange)="addItemtoCartTotalAmount(addonItem)">
                                          <mat-option value="">Select</mat-option>
                                          <mat-option
                                            *ngFor="let slabInfo of addonItem.other_details.slab_data | keyvalue: unsorted"
                                            [value]="slabInfo.value.slab_index"> {{slabInfo.value.name}}
                                          </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                    </div>
                                  </ng-container>

                                  <ng-container *ngIf="addonItem.price_type == 'slab' ">
                                    <mat-form-field appearance="outline">
                                      <mat-label>Units</mat-label>
                                      <input matInput type="number"
                                        name="addon_units_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                        [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].units"
                                        (keyup)="addItemtoCartTotalAmount(addonItem)"
                                        [disabled]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added == 0">
                                    </mat-form-field>
                                  </ng-container>

                                  <ng-container
                                    *ngIf="addonItem.price_type != 'slab' && addonItem.price_type != 'slab_flat_fee'">
                                    <div class="form-group mb-2">
                                      <small class="form-text text-muted">{{getCurrencySymbol(country_id)}}
                                        {{addonItem.price}} / month</small>
                                    </div>
                                  </ng-container>

                                  <br>

                                  <mat-checkbox color="primary"
                                    [disabled]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_added == 0"
                                    name="auto_renew_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                    [(ngModel)]="bundleCartAddons[addonItem.sb_plan_slug][addonItem.addon?.slug].is_auto_renew">
                                    Auto Renew</mat-checkbox>
                                </div>
                              </div>
                            </div>

                          </div>
                        </ng-container>
                      </div><!-- addon end -->
                    </div><!-- pack end -->


                  </div>

                  <div fxFlex="33">
                    <div class="sticky-top">
                      <mat-card>
                        <div class="card-body">
                          <div class="text-center">
                            <p class="checkout-items px-8 mt-0">Total Items - <span>{{checkoutItems.length}}</span></p>
                            <p class="font-medium">Total Cost</p>
                            <h2 class="text-bold font-size-30 mb-0">
                              {{getCurrencySymbol(country_id)}}{{total_amount_before_discount | number : '1.2-2' }}
                            </h2>
                            <small class="form-text text-muted m-0">Billed
                              <span *ngIf="subscription_cycle == 1">Monthly</span>
                              <span *ngIf="subscription_cycle == 3">Quarterly</span>
                              <span *ngIf="subscription_cycle == 6">Half-Yearly</span>
                              <span *ngIf="subscription_cycle == 12">Yearly</span>
                            </small>
                            <hr />
                          </div>
                          <ul class="list-group p-0">
                            <ng-container *ngFor="let checkoutItem of checkoutItems">
                              <li class="bg-light-success p-12 mb-12 border-radius-8" fxLayout="row wrap"
                                fxLayoutAlign="center center">
                                <div class="text-dark" fxFlex="90">
                                  <span class="font-size-12 font-medium">{{checkoutItem.name}}</span>
                                  <div class="" fxLayout="row wrap">
                                    <span class="font-size-26 text-bold line-height-28">{{checkoutItem.sub_total |
                                      number : '1.2-2' }}</span>
                                    <small class="d-block text-muted pl-2">{{
                                      getCurrencySymbol(companyDetails?.country_id)}}
                                      <span
                                        *ngIf="checkoutItem.price_type != 'slab' && checkoutItem.price_type != 'slab_flat_fee' ">
                                        {{checkoutItem.price | number : '1.2-2'}}
                                      </span>
                                      <span
                                        *ngIf="checkoutItem.price_type == 'slab' || checkoutItem.price_type == 'slab_flat_fee'">
                                        {{checkoutItem.slab_price | number : '1.2-2' }}
                                      </span>
                                      per <span *ngIf="checkoutItem.price_type == 'per_unit'">/ user</span>
                                          <span *ngIf="checkoutItem.price_type != 'per_unit'">/ month</span>
                                          <span *ngIf="checkoutItem.price_type == 'per_unit' && subscription_type != 'top_up'">/ month</span> 
                                      |
                                      <span
                                        *ngIf="checkoutItem.price_type != 'slab' && checkoutItem.price_type != 'slab_flat_fee'">
                                        <span *ngIf="checkoutItem.credits_type == 'un_limited'">Unlimited</span>
                                        <span *ngIf="checkoutItem.credits_type == 'limited'">{{checkoutItem.quantity}}
                                          Credits</span>
                                      </span>
                                      <span
                                        *ngIf="checkoutItem.price_type == 'slab_flat_fee'">{{checkoutItem.slab_title}}</span>
                                      <span *ngIf="checkoutItem.price_type == 'slab'">
                                        {{checkoutItem.units * checkoutItem.slab_index}} Credits</span>
                                    </small>
                                  </div>
                                </div>
                                <mat-icon class="cursor-pointer" matTooltip="Reove from cart"
                                  (click)="removeItemFromCart(checkoutItem)">close</mat-icon>
                              </li>
                            </ng-container>
                          </ul>

                          <mat-card-actions class="text-center" *ngIf="showCheckoutOpt()">
                            <ng-container *ngIf="checkoutError == 1">
                              <button mat-button (click)="applyCouponOnCheckout()" class="primary w-100-p"> Checkout
                              </button>
                            </ng-container>
                            <ng-container *ngIf="checkoutError == 0">
                              <button mat-button matStepperNext (click)="applyCouponOnCheckout()"
                                class="primary w-100-p"> Checkout </button>
                            </ng-container>
                          </mat-card-actions>
                        </div>
                      </mat-card>
                      <!-- <p class="font-size-12 text-center p-3">Not sure which plan is right for you?
                      <a href="javascript:;" class="d-block text-success font-italic">Contact Us</a>
                    </p> -->
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </form>
        </ng-container>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Checkout</ng-template>
        <form novalidate>
          <div>
            <mat-card class="card mt-12">
              <div>
                <div class="">
                  <p class="font-size-16 font-medium">Order Summary</p>

                  <ng-container *ngFor="let checkoutItem of discountedCheckoutItems; let i = index">
                    <div class="bg-light border-radius-8 p-12 px-20 mb-12">
                      <div class="" fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <div fxLayout="column wrap">
                          <h2 class="heading">{{checkoutItem.name}}</h2>
                          <small class="d-block text-muted font-size-12">{{getCurrencySymbol(country_id)}}
                            <!-- <span *ngIf = "checkoutItem.price_type != 'slab' && checkoutItem.price_type != 'slab_flat_fee' ">
                              {{checkoutItem.price}}
                            </span> 
                            <span *ngIf = "checkoutItem.price_type == 'slab' || checkoutItem.price_type == 'slab_flat_fee'">
                              {{checkoutItem.slab_price}}
                            </span> 
                            per <span *ngIf = "checkoutItem.price_type == 'per_unit'"> user</span>/ month | 
                            <span *ngIf = "checkoutItem.price_type != 'slab' && checkoutItem.price_type != 'slab_flat_fee'">
                                <span *ngIf = "checkoutItem.credits_type == 'un_limited'">Unlimited</span>
                                <span *ngIf = "checkoutItem.credits_type == 'limited'">{{checkoutItem.quantity}} Credit(s)</span>
                            </span> 
                            <span *ngIf = "checkoutItem.price_type == 'slab' || checkoutItem.price_type == 'slab_flat_fee'">
                                {{checkoutItem.slab_index * checkoutItem.units }} Credit(s)
                            </span> -->

                            <span
                              *ngIf="checkoutItem.price_type == 'slab' || checkoutItem.price_type == 'slab_flat_fee'">
                              {{checkoutItem.slab_price | number : '1.2-2'}}
                            </span>
                            <span *ngIf="checkoutItem.price_type != 'slab' || checkoutItem.price_type != 'slab_flat_fee'">
                              {{checkoutItem.price | number : '1.2-2'}}
                            </span>
                            per 
                            <span *ngIf="checkoutItem.price_type == 'per_unit'"> / user | </span>
                            <span *ngIf="checkoutItem.price_type != 'per_unit' && subscription_type == 'top_up'"> / month

                              |</span>
                            <span *ngIf="subscription_type != 'top_up'"> / month |</span>

                            <span
                              *ngIf="checkoutItem.price_type != 'slab' && checkoutItem.price_type != 'slab_flat_fee'">
                              <span *ngIf="checkoutItem.credits_type == 'un_limited'">Unlimited</span>
                              <span *ngIf="checkoutItem.credits_type == 'limited'">{{checkoutItem.quantity}}
                                Credits</span>
                            </span>
                            <span *ngIf="checkoutItem.price_type == 'slab_flat_fee'">{{checkoutItem.slab_title}}</span>
                            <span *ngIf="checkoutItem.price_type == 'slab'">
                              {{checkoutItem.units * checkoutItem.slab_index}} Credits</span>
                          </small>
                        </div>

                        <div class="col" *ngIf="itemCouponsList">
                          <div class="form-group mb-0"
                            *ngIf="itemCouponsList[checkoutItem.slug] && !isZeroPaymentInvoice">
                            <mat-form-field appearance="outline" class="w-400-p">
                              <mat-label>Apply Coupon </mat-label>
                              <mat-select class="form-control w-xl-50 mb-1" name="apply_coupon_id_{{i}}"
                                [(ngModel)]="itemCouponsList[checkoutItem.slug]['coupon_id']"
                                (selectionChange)="applyCouponToLineItem(checkoutItem.slug, i)">
                                <mat-option> Select </mat-option>
                                <mat-option *ngFor="let couponInfo of itemCouponsList[checkoutItem.slug]"
                                  [value]="couponInfo['id']">
                                  {{couponInfo['title']}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <span *ngIf="checkoutItem.is_discount_applied == 1"
                            class="font-size-12 form-text text-warning mt-0">
                            <span>{{checkoutItem.discount_title}}</span>
                          </span>
                        </div>
                        <div class="col text-right" *ngIf="checkoutItem.is_discount_applied == 1">
                          <span class="font-size-20 font-medium">{{checkoutItem.sub_total }}</span>
                          <small class="d-block text-muted">
                            <span>(-) {{checkoutItem.discount_amount | number : '1.2-2' }}</span>
                          </small>
                        </div>
                        <div class="col text-right">
                          <span class="font-size-20 text-bold"
                            *ngIf="checkoutItem.after_discount">{{getCurrencySymbol(country_id)}}{{checkoutItem.after_discount
                            | number : '1.2-2' }}</span>
                          <span class="font-size-20 text-bold"
                            *ngIf="!checkoutItem.after_discount">{{getCurrencySymbol(country_id)}}{{checkoutItem.sub_total
                            | number : '1.2-2' }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="bg-light border-radius-8 p-12 px-20 mb-12">
                    <div fxLayout="row wrap" fxLayoutAlign="end center">

                      <div class="col form-group mb-0">
                        <div *ngIf="invoiceCoupns.length>1 && !isZeroPaymentInvoice">
                          <mat-form-field appearance="outline" class="w-400-p">
                            <mat-label>Apply Coupon(On Sub Total)</mat-label>
                            <mat-select class="form-control w-xl-50" name="invoice_coupons"
                              [(ngModel)]="invoice_coupon_obj.coupon_id"
                              (selectionChange)="cartSubTotalAmount('discount_items')">
                              <mat-option value="">Select</mat-option>
                              <mat-option *ngFor="let couponInfo of invoiceCoupns" [value]="couponInfo['id']">
                                {{couponInfo['title']}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                      <div *ngIf="cart_total_amount>0" class="font-size-16 text-semibold text-right text-uppercase"
                        fxFlex="70">Sub Total</div>
                      <div *ngIf="cart_total_amount>0" class="font-size-20 text-semibold text-right" fxFlex="30">
                        <span
                          *ngIf="invoiceCouponItem.is_discount_applied == 1">{{getCurrencySymbol(country_id)}}{{sumTwoAmounts(total_amount_after_discount,
                          invoiceCouponItem.discount_amount) | number : '1.2-2'}}</span>
                        <span *ngIf="invoiceCouponItem.is_discount_applied == 0">
                          {{getCurrencySymbol(country_id)}}
                          <span
                            *ngIf="used_wallet_amount > 0 && used_wallet_amount >=cart_total_amount">{{total_amount_before_discount|
                            number : '1.2-2'}}</span>
                          <span *ngIf="total_amount_before_discount">{{total_amount_before_discount| number :
                            '1.2-2'}}</span>
                        </span>
                      </div>
                    </div>

                    <div fxLayout="row wrap" fxLayoutAlign="end center"
                      *ngIf="invoiceCouponItem.is_discount_applied == 1">
                      <div class="font-size-16 text-semibold text-right text-uppercase" fxFlex="70">Discount</div>
                      <div class="font-size-20 text-semibold text-right" fxFlex="30">(-)
                        {{getCurrencySymbol(country_id)}}{{invoiceCouponItem.discount_amount | number : '1.2-2'}}</div>
                    </div>

                    <div fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="used_wallet_amount > 0">
                      <div class="font-size-16 text-semibold text-right text-uppercase" fxFlex="70">Wallet</div>
                      <div class="font-size-20 text-semibold text-right" fxFlex="30">(-)
                        {{getCurrencySymbol(country_id)}}{{used_wallet_amount | number : '1.2-2' }}</div>
                    </div>

                    <!-- <div fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="used_wallet_amount > 0" >
                    <div class="font-size-16 text-semibold text-right text-uppercase" fxFlex="70"></div>
                    <div class="font-size-20 text-semibold text-right" fxFlex="30">
                        {{calculateAfterDiscount(calculateAfterDiscount(total_amount_before_discount, total_discount_price), used_wallet_amount)}}
                    </div>
                  </div> -->

                    <div fxLayout="row wrap" fxLayoutAlign="end center"
                      *ngIf="companyDetails?.country_id == 2 && tax_amount > 0">
                      <div class="font-size-16 text-semibold text-right text-uppercase" fxFlex="70">{{tax_name}}</div>
                      <div class="font-size-20 text-semibold text-right" fxFlex="30">(+)
                        {{getCurrencySymbol(country_id)}}{{tax_amount | number : '1.2-2'}}</div>
                    </div>

                    <div fxLayout="row wrap" fxLayoutAlign="end center"
                      *ngIf="companyDetails?.country_id == 2 && tds_amount > 0">
                      <div class="font-size-16 text-semibold text-right text-uppercase" fxFlex="70">{{tds_title}}</div>
                      <div class="font-size-20 text-semibold text-right" fxFlex="30">(-)
                        {{getCurrencySymbol(country_id)}}{{tds_amount | number : '1.2-2' }}</div>
                    </div>

                    <div fxLayout="row wrap" fxLayoutAlign="end center">
                      <div class="font-size-24 font-bold text-right text-uppercase mt-8" fxFlex="70">Total</div>
                      <div class="font-size-24 font-bold text-right mt-2" fxFlex="30">
                        {{getCurrencySymbol(country_id)}}{{cart_total_amount | number : '1.2-2' }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>


            <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-16">
              <button mat-button matStepperPrevious (click)="changePaymentMode()">Back</button>
              <button mat-button (click)="checkoutInvoice()" class="primary ml-12">Checkout </button>
            </div>

          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </ng-container>

  <form [formGroup]="subscriptionForm" *ngIf="subscriptionForm">
    <ng-container *ngIf="payment">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill fxLayoutGap="40px" class="mb-12">
        <div fxFlex='330px' class="text-center font-size-32 text-bold">Payment Method</div>
        <div fxFlex class="text-right font-size-32 text-bold">
          Total Amount: <span class="accent-fg"> {{getCurrencySymbol(country_id)}}{{sub_total | number : '1.2-2' }}
          </span>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="40px" class="mb-12">
        <div fxFlex='330px' *ngIf="cart_total_amount>0">
          <div *ngIf="!turnOffPaymentMode">
            <ng-container *ngFor="let item of paymentmodes">
              <ng-container
                *ngIf="(type == 'renew' || (type != 'renew' && item.value != 'card')) && item.isActive == 1">
                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill class="pay_modes cursor-pointer"
                  (click)="paymentChange(item)" [ngClass]="{active: isActive(item), 'disableDiv': item.isDisable}">
                  <div fxFlex='75px'>
                    <ng-container *ngIf="item.value == 'bank'">
                      <mat-icon class="s-40 ml-4 pay_icon">account_balance</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="item.slug == 'stripe_balance'">
                      <mat-icon class="s-40 ml-4 pay_icon">account_balance_wallet</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="item.value == 'cheque'">
                      <mat-icon class="s-40 ml-4 pay_icon">monetization_on</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="item.value == 'card'">
                      <mat-icon class="s-40 ml-4 pay_icon">credit_card</mat-icon>
                    </ng-container>
                    <ng-container *ngIf="item.slug == 'invoice_url'">
                      <mat-icon class="s-40 ml-4 pay_icon">mail</mat-icon>
                    </ng-container>
                  </div>
                  <div fxFlex class="font-size-16 text-bold"> {{item.name}} </div>
                  <div fxFlexAlign="center" class="select" *ngIf="item.isSelected">
                    <mat-icon class="s-24 primary-fg ml-4">check_circle_outline</mat-icon>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
          <div class="ml-32 p-8 warn-fg font-size-16 border" *ngIf="turnOffPaymentMode">
            <ng-container>
              Please use exact stripe credit balance to processed for payment
            </ng-container>
          </div>
        </div>
        <div fxFlex class="pl-32 mt-20">
          <ng-container *ngIf="showtxn && showTXNID">
            <div class="text-bold font-size-16">
              Transaction ID<span class="red-fg">*</span>
            </div>
            <div>
              <mat-form-field class="w-40-p my-8" appearance="outline">
                <input type="text" matInput formControlName="txn_id">
              </mat-form-field>
            </div>
          </ng-container>

          <ng-container *ngIf="showtxn && showTXNID">
            <div class="text-bold font-size-16 mb-10">
              Transaction Proof<span class="red-fg">*</span>
            </div>
            <div>
              <input type="file" accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls" id="file1"
                formControlName="bank_cheque_attchment" (change)="onSelectFile1($event)">
              <div class="text-center w-160 mx-auto mb-20" fxLayout="row wrap" fxLayoutAlign="center center"
                *ngIf="url1">
                <img [src]="url1" style="max-width:100%;height: auto;">
              </div>
            </div>
          </ng-container>

          <!-- <ng-container *ngIf="showtxn"> -->
          <ng-container>
            <div class="text-bold font-size-16 mt-20">
              Billing Details<span class="red-fg">*</span>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
              <div fxFlex='30'>
                <mat-form-field class="w-100-p my-8" appearance="outline">
                  <mat-label>Street </mat-label>
                  <input type="text" matInput formControlName="billing_street">
                </mat-form-field>
              </div>
              <div fxFlex='30'>
                <mat-form-field class="w-100-p my-8 ml-12" appearance="outline">
                  <mat-label>City</mat-label>
                  <input type="text" matInput formControlName="billing_city">
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
              <div fxFlex='30'>
                <mat-form-field class="w-100-p" appearance='outline'>
                  <mat-label>Country</mat-label>
                  <mat-select formControlName="billing_country" (selectionChange)="changeCountry($event.value)">
                    <mat-option *ngFor="let item of countries" [value]="item.id">
                      {{item.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex='30'>
                <mat-form-field class="w-100-p ml-12" appearance='outline'>
                  <mat-label>State</mat-label>
                  <mat-select formControlName="billing_state">
                    <mat-option *ngFor="let item of states" [value]="item.id">
                      {{item.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
              <div fxFlex='30'>
                <mat-form-field class="w-100-p my-8" appearance="outline">
                  <mat-label>Zip </mat-label>
                  <input type="text" matInput formControlName="billing_zip">
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </form>

  <div class='footer' *ngIf="!turnOffPaymentMode">
    <button mat-button (click)="changePaymentMode()" *ngIf="payment">Back</button>
    <button mat-button (click)="save()" class="primary" *ngIf="payment && cart_total_amount<=0">Pay</button>
    <button mat-button (click)="save()" class="primary"
      *ngIf="payment && payment_mode == 'invoice_url' &&  cart_total_amount > 0">{{isAccountBalPayment?'Pay':'Send
      Invoice Payment Link'}}</button>
  </div>

</div>