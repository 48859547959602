
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">

        <button mat-flat-button class="primary" style="margin-right: 10px;" *ngIf="!initiateBackup || isLostAccount" (click)="onChange('enable_data_backup')" >Intiate Backup</button>
        <p *ngIf="initiateBackup" class="text-bold" style="margin-right: 10px;color: green;"><mat-icon class="s-18 primary-fg">check_circle_outline</mat-icon> Data Backup Request Initiated</p>

        <p class="p-4 border-radius-4 grey-300-bg text-bold">Note: This feature is available once per every 6 months</p>

        <div fxFlex class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>

      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="customer_name">
                <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> <a class='cursor-pointer'
                  (click)='navigate(element)'>{{element.company_name}} </a></mat-cell>
              </ng-container>

              <ng-container matColumnDef="s3_status">
                <mat-header-cell *matHeaderCellDef> S3 Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.s3_status}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="data_status">
                <mat-header-cell *matHeaderCellDef> Data Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.data_status}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef> Created On </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="updated_at">
                <mat-header-cell *matHeaderCellDef> Updated On </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.updated_at}} </mat-cell>
              </ng-container>
          
              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>

    <div class='footer'>
      <button mat-button (click)="showActivities()">Activities</button>
    </div>
