import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/services/common.service';
import { ExcelService } from 'app/services/excel.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ClientsService } from 'app/services/clients.service';
@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  // ejs datepicker
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  // public lastEnd: Date = this.today;
  public lastEnd: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  // public yearStart = moment().startOf('year');
  public yearEnd: Date = this.today;
  public value: Date[] = [this.yearStart, this.yearEnd];
  // ejs datepicker completed
  // public value: Date[] = [this.monthStart, this.monthEnd];
  // displayedColumns: string[] = ['customerName', '#userLicences', '#massMailCredits', '#accountContacts', '$userLicences', '$massMailCredits', '$accountContacts', '$amount'];
  dataSource;
  searchValue = '';
  getlist: any;
  getTableData: any;
  length: any;
  pageSize: any;
  index: any;
  searchForm: FormGroup;
  getDateRange = {};
  selection = [{ value: '0', viewValue: 'All' },
  { value: '1', viewValue: 'Mass Mails' },
  { value: '2', viewValue: 'User Licences' },
  { value: '3', viewValue: 'Account Contacts' }]
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  // params: { addon_type: any[]; dateRange: {}; limit: number; page: number; search_filter?: string };
  companyList: any;
  paymentList: any;
  addon: { id: any; name: string; }[] = [];
  dataId: number = 0;
  dateActive = '';
  //monthly revenue
  // revenue
  revenuedisplayedColumns: string[] = ['customerName', 'app_type', 'monthPaid', 'subscriptionCycle', 'hire_licenses_cost', 'hr_licenses_cost', 'crm_licenses_cost', 'massmails', 'accountcontacts', 'total_cost'];
  revenueDataSource: any;
  revenueLength: number;
  revenuepageSize: any;
  revenueindex: any;
  revenueSearchValue = '';
  revenuegetDateRange = {};
  revenueTableData: any;
  revenueForm: FormGroup;
  toppingList: any;
  params: { limit: number; page: number; paid_on?: any; onboarded_on?: any; subscription_cycle?: any; app_type?: any; date_range?: any; client_ids?: any; search_key?: any };
  app_type = [
    { value: '1', viewValue: 'Hire' },
    { value: '5', viewValue: 'HR' },
    { value: '6', viewValue: 'CRM' },
    { value: '2', viewValue: 'Mass Mails' },
    { value: '4', viewValue: 'Account Cotact Search' },
  ];
  subscriptionCycle = [
    { value: '', viewValue: 'All' },
    { value: '1', viewValue: 'Monthly' },
    { value: '3', viewValue: 'Quarterly' },
    { value: '6', viewValue: 'Half Yearly' },
    { value: '12', viewValue: 'Yearly' },
  ]
  constructor(private commonservice: CommonService,
    private clientService: ClientsService,
    private excelService: ExcelService,
    public dialog: MatDialog,
    private cp: CurrencyPipe,
    private fb: FormBuilder,
    public datePipe: DatePipe) {
    // this.searchForm = this.fb.group({
    //   select: ['']
    // })
    this.revenueForm = this.fb.group({
      subscription_cycle: [''],
      app_type: [''],
      customer: [''],
      start_date: [''],
      end_date: ['']
    })
    this.revenueForm.valueChanges.pipe(debounceTime(0), distinctUntilChanged()).subscribe(res => {
      if (res) {
        this.params.subscription_cycle = res.subscription_cycle ? res.subscription_cycle : '';
        this.params.app_type = res.app_type ? res.app_type.map(x => x).join(",") : '';
        this.params.client_ids = res.customer ? res.customer.map(x => x).join(",") : '';
        if (res.start_date && res.end_date) {
          this.revenuegetDateRange['startDate'] = moment(res.start_date).format("YYYY-MM-DD");
          this.revenuegetDateRange['endDate'] = moment(res.end_date).format("YYYY-MM-DD");
          this.params.date_range = this.revenuegetDateRange ? JSON.stringify(this.revenuegetDateRange) : '';
        }
        else {
          this.params.date_range = '';
        }
        this.getmonthlyRevenueReport();
      }
    })


  }

  ngOnInit() {
    // this.getDateRange['startDate'] = moment(this.monthStart).format("YYYY-MM-DD");
    // this.getDateRange['endDate'] = moment(this.monthEnd).format("YYYY-MM-DD");
    // this.params = { addon_type: this.addon, dateRange: this.getDateRange, limit: 15, page: 1 };
    // this.getPaymentsData();
    // this.searchForm.patchValue({
    //   select: this.selection[0].value
    // })

    // getCompanyList
    this.commonservice.getCompanyList({ all_users: 2, comp_id: 1 }).subscribe(res => {
      if (res.status === 1) {
        this.toppingList = res.data;
      }
    })
    this.params = { limit: 15, page: 1 };
    this.getmonthlyRevenueReport();
  }
  // applyFilter(filterValue: string) {
  //   this.params.search_filter = filterValue;
  //   this.getPaymentsData();
  // }
  // pageEvent(event) {
  //   this.params.limit = event.pageSize;
  //   this.params.page = event.pageIndex + 1;
  //   this.getPaymentsData();
  // }
  // getPaymentsData() {
  //   this.commonservice.payments(this.params).subscribe(res => {
  //     if (res['status'] === 1) {
  //       this.companyList = res['companies_list'];
  //       this.paymentList = res['payments_list'];
  //       this.length = this.companyList.total;
  //       this.pageSize = this.companyList.per_page;
  //       this.index = this.companyList.current_page;
  //       this.dataSource = new MatTableDataSource(this.companyList.data);
  //     }
  //   })
  // }

  // exportExcel() {
  //   let prepared_data = this.prepareExcelData(this.companyList.data);
  //   this.excelService.exportAsExcelFile(prepared_data, 'new_report');
  // }
  // prepareExcelData(data) {
  //   let ArrData = [];
  //   data.forEach(element => {
  //     let eachRow = {};
  //     eachRow['Customer Name'] = element.display_name;
  //     eachRow['#User Licences'] = this.paymentList[element.id]['1']['0'];
  //     eachRow['#Mass Mail Credits'] = this.paymentList[element.id]['2']['0'];
  //     eachRow['#Account Contacts'] = this.paymentList[element.id]['4']['0'];
  //     eachRow['$User Licences'] = this.cp.transform(this.paymentList[element.id]['1']['1'], 'USD');
  //     eachRow['$Mass Mail Credits'] = this.cp.transform(this.paymentList[element.id]['2']['1'], 'USD');
  //     eachRow['$Account Contacts'] = this.cp.transform(this.paymentList[element.id]['4']['1'], 'USD');
  //     eachRow['$Amount'] = this.cp.transform(this.paymentList[element.id]['1']['1'] + this.paymentList[element.id]['2']['1'] + this.paymentList[element.id]['4']['1'], 'USD');
  //     ArrData.push(eachRow);
  //   });
  //   return ArrData;
  // }
  // selectChange(val) {
  //   switch (val) {
  //     case '0':
  //       this.addon = [];
  //       this.dataId = 0;
  //       break;
  //     case '1':
  //       this.addon = [{ id: '2', name: 'Mass Mails' }];
  //       this.dataId = 2;
  //       break;
  //     case '2':
  //       this.addon = [{ id: "1", name: " User Licenses" }];
  //       this.dataId = 1;
  //       break;
  //     case '3':
  //       this.addon = [{ id: "4", name: "Account Contacts" }];
  //       this.dataId = 4;
  //       break;
  //   }
  //   this.params['addon_type'] = this.addon;
  //   this.getPaymentsData();
  // }
  // date(e) {
  //   // console.log(moment(this.monthStart).format("YYYY-MM-DD"));
  //   if (e.startDate) {
  //     this.getDateRange['startDate'] = moment(e.startDate).format("YYYY-MM-DD");
  //     this.getDateRange['endDate'] = moment(e.endDate).format("YYYY-MM-DD");
  //     console.log(this.getDateRange);
  //     this.params['dateRange'] = this.getDateRange;
  //     this.getPaymentsData();
  //   }
  // }
  // tableReturnData(element, type) {
  //   // return this.paymentList[element.id]['1']['0'];

  //   if (type == '#user') {
  //     const promise = new Promise(function (resolve, reject) {
  //       if (this.paymentList[element.id]['1']['0']) {
  //         resolve();
  //       } else {
  //         reject();
  //       }
  //     });

  //     promise.then(function () {
  //       console.log('Success, You are a GEEK');
  //       return this.paymentList[element.id]['1']['0'];
  //     }).
  //       catch(function () {
  //         console.log('Some error has occured');
  //         return '-';
  //       });
  //   }
  //   else {
  //     const promise = new Promise(function (resolve, reject) {
  //       if (this.paymentList[element.id]['1']['1']) {
  //         resolve();
  //       } else {
  //         reject();
  //       }
  //     });

  //     promise.then(function () {
  //       console.log('Success, You are a GEEK');
  //       return this.paymentList[element.id]['1']['1'];
  //     }).
  //       catch(function () {
  //         console.log('Some error has occured');
  //         return '-';
  //       });
  //   }

  // }

  // Dashboard monthly revenue report
  applyFilter(val) {
    this.params['search_key'] = val;
    this.getmonthlyRevenueReport();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getmonthlyRevenueReport();
  }
  getmonthlyRevenueReport() {
    this.clientService.revenueReport(this.params).subscribe(
      res => {
        if (res.status === 1) {
          this.revenueLength = res.data ? res.data.total : 0;
          this.revenuepageSize = res.data ? res.data.per_page : '15';
          this.revenueindex = res.data ? res.data.current_page : 1;
          this.revenueTableData = res.data ? res.data.data : '';
          this.revenueDataSource = new MatTableDataSource(this.revenueTableData);
        }
      })
    // () => this.commonService.getLoader())
  }

  // export as excel data
  exportExcel() {
    if (this.revenueLength) {
      this.params['limit'] = this.revenueLength;
      this.clientService.revenueReport(this.params).subscribe(res => {
        if (res.status === 1) {
          let prepared_data = this.prepareExcelData(res.data.data);
          this.excelService.exportAsExcelFile(prepared_data, 'Monthly_Revenue_Report');
        }
      })
    }
    else {
      this.commonservice.openSnackBar('warn', 'There are no records Found');
    }
  }
  subCycle(element) {
    if (element === 1) {
      return 'Monthly';
    }
    else if (element === 3) {
      return 'quarterly';
    }
    else if (element === 6) {
      return 'Half yearly';
    }
    else if (element === 12) {
      return 'Yearly';
    }
    else {
      return '-'
    }
  }
  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name'] = element.company_name;
      eachRow['app type'] = element.app_type;
      eachRow['Month Paid'] = moment(element.paid_on).format("YYYY-MM-DD");
      eachRow['Subscription Cycle'] = this.subCycle(element.subscription_cycle);
      eachRow['Onboarded on'] = moment(element.onboarded_date).format("YYYY-MM-DD");
      eachRow['Number of HIRE Licences'] = element.hire_licenses_cnt ? element.hire_licenses_cnt : '-';
      eachRow['$ Hire Cost Per Licence'] = element.hire_cost_per_license ? element.hire_cost_per_license : '-';
      eachRow['$ Hire Licence Total Cost'] = element.hire_licenses_cost ? element.hire_licenses_cost : '-';
      eachRow['Number of HR Licences'] = element.hr_licenses_cnt ? element.hr_licenses_cnt : '-';
      eachRow['$ HR Cost Per Licence'] = element.hr_cost_per_license ? element.hr_cost_per_license : '-';
      eachRow['$ HR Licence Total Cost'] = element.hr_licenses_cost ? element.hr_licenses_cost : '';
      eachRow['Number of CRM Licences'] = element.crm_licenses_cnt ? element.crm_licenses_cnt : '-';
      eachRow['$ CRM Cost Per Licence'] = element.crm_cost_per_license ? element.crm_cost_per_license : '-';
      eachRow['$ CRM Licence Total Cost'] = element.crm_licenses_cost ? element.crm_licenses_cost : '-';
      eachRow['# Mass Mails'] = element.mass_mails_licenses_cnt ? element.mass_mails_licenses_cnt : '-';
      eachRow['$ Mass Mails'] = element.mass_mails_licenses_cost ? element.mass_mails_licenses_cost : '-';
      eachRow['# Account Countacts'] = element.account_contacts_licenses_cnt ? element.account_contacts_licenses_cnt : '';
      eachRow['$ Account Countacts'] = element.account_contacts_licenses_cost ? element.account_contacts_licenses_cost : '';
      ArrData.push(eachRow);
    });
    return ArrData;
  }

  setDate(date_type: string) {
    this.dateActive = date_type;
    switch (date_type) {
      case 'this_week':
        this.revenueForm.patchValue({
          start_date: this.datePipe.transform(this.weekStart, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(this.weekEnd, 'yyyy-MM-dd')
        });
        break;
      case 'this_month':
        this.revenueForm.patchValue({
          start_date: this.datePipe.transform(this.monthStart, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(this.monthEnd, 'yyyy-MM-dd')
        });
        break;
      case 'last_month':
        this.revenueForm.patchValue({
          start_date: this.datePipe.transform(this.lastStart, 'yyyy-MM-dd'),
          end_date: this.datePipe.transform(this.lastEnd, 'yyyy-MM-dd')
        });
        break;
      default:
        this.revenueForm.patchValue({
          start_date: '',
          end_date: ''
        });
        break;
    }
  }
}
