<h1 mat-dialog-title class='text-uppercase'>Edit Doccument</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Name</mat-label>
          <input type="text" formControlName="title" matInput>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Description</mat-label>
          <input type="text" formControlName="description" matInput>
        </mat-form-field>
      </div>
      <!-- <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Signdate</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="signdate" >
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div> -->
      <div fxFlex="48">
        <div class="text-bold font-size-16 mb-10">
          Doccument<span class="red-fg">*</span>
        </div>
        <div>
          <input type="file" accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls" id="file1"
            formControlName="doc_attchment" (change)="onSelectFile1($event)">
          <div class="text-center w-160 mx-auto mb-20" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="url1">
            <!-- <img [src]="url1" style="max-width:100%;height: auto;"> -->
          </div>
        </div>
      </div>
      
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-flat-button class='primary' (click)="save()">Save</button>
</mat-dialog-actions>