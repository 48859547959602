import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { ApiUrls } from 'app/services/apiurls';
import { CommonService } from 'app/services/common.service';
import { CompanyDetailsService } from 'app/services/company-details.service';
import { environment } from 'environments/environment';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
// import { ModuleActivityDialogueComponent } from '../module-activity-dialogue/module-activity-dialogue.component';
import { SendMailDialogueComponent } from '../send-mail-dialogue/send-mail-dialogue.component';


@Component({
  selector: 'app-implementation-activities',
  templateUrl: './implementation-activities.component.html',
  styleUrls: ['./implementation-activities.component.scss']
})

export class ImplementationActivitiesComponent implements OnInit {

  statusList = [
    { value: '', viewValue: 'Select' }, { value: 'in_progress', viewValue: 'In Progress' }, { value: 'completed', viewValue: 'Completed' }
  ];

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol', 'actions'];
  dataSource: any;
  implementationFromData: any = {};
  company_id: any;

  constructor(private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    private route: ActivatedRoute,
    public dialog: MatDialog) {
    this.company_id = this.route.parent.snapshot.params['id'];
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.companyDetailService.getImplementationActivities({ company_id: this.company_id, module: 'implementation' }).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = res.data.data;
        if (isEmpty(res.data.activities)) {
          this.dataSource.forEach(element => {
            this.implementationFromData[element.slug] = { is_applicable: false, completion_date: '', status: '', title: element.title, sub_title: element.sub_title };
          });
        } else {
          let activities = res.data.activities;
          for (let k in activities) {
            this.implementationFromData[k] = { is_applicable: activities[k].is_applicable, completion_date: activities[k].completion_date, status: activities[k].status, title: activities[k].title, sub_title: activities[k].sub_title };
          }
        }
      }
    })
  }

  saveData() {

    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? You want to update data?';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        let payLoadData = {
          company_id: this.company_id,
          module: 'implementation',
          data: JSON.stringify(this.implementationFromData)
        }
        this.companyDetailService.saveImplementationActivities(payLoadData).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      }
    })
  }

  downloadPdfData()
  {
    var DOC_URL = environment.apiUrl;
    window.open(DOC_URL+ApiUrls.companyInfo.downloadImplementation+'/'+this.company_id );
  }
  onDateChange(event, slug) {
    this.implementationFromData[slug].completion_date = moment(this.implementationFromData[slug].completion_date).format("YYYY-MM-DD");
  }

  getIsApplicable(slug) {

    if (this.implementationFromData[slug].is_applicable == 'false') {
      this.implementationFromData[slug].status = '';
    }
  }

  showComments(slug, title) {
    let activityData = {
      'company_id': this.company_id,
      'module_slug': 'implementation',
      'show_company_name': false,
      'module_name': title,
      'isComments': true,
      'record_id': slug
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  sendImplementationUpdate() {
    let dialogueData = {};
    this.companyDetailService.getEmailTemplateData({ slug: 'IMPLEMENTATION_UPDATE', company_id:this.company_id }).subscribe(res => {
      if (res.status == 1) {
        dialogueData['emailData'] = res.data.emailData;
        dialogueData['users'] = res.data.users;
        dialogueData['emails'] = res.data.emails;
        dialogueData['company_id'] = this.company_id;
        dialogueData['module'] = 'implementation';

        const dialogRef = this.dialog.open(SendMailDialogueComponent, {
          width: '65%',
          data: dialogueData
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
      else {
        this.commonService.openSnackBar('warn', res.message);
      }
    })
  }

}
