import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { FusionChartsModule } from 'angular-fusioncharts';
import { MatSearchSelectorModule } from 'app/mat-search-selector/mat-search-selector.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonAllModule } from 'app/common-all/common-all.module';


@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FuseSidebarModule,
    FuseSharedModule,
    FusionChartsModule,
    MatSearchSelectorModule,
    ScrollingModule,
    CommonAllModule
  ]
})
export class DashboardModule { }
