<h1 mat-dialog-title class='text-uppercase'> Add Emails </h1>
<mat-divider></mat-divider>
<mat-dialog-content>
    <div [formGroup]="CommunicationForm" class="w-100-p">
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-12" >
        <div fxFlex='80'>
          <div>
            <mat-form-field class="w-100-p mt-12" appearance="outline">
              <span matPrefix class="font-size-12">TO &nbsp;</span>
              <mat-chip-list #TOEmailchipList >
                <mat-chip [color]="(email.invalid)?'warn':''" selected
                  *ngFor="let email of to; let i=index;"
                  [selectable]="selectable" [removable]="removable" (removed)="removeToEmail(email)"
                  class="text-lowercase">
                    {{email}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input [matChipInputFor]="TOEmailchipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addToEmail($event)">
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div >
            <mat-form-field class="w-100-p mt-12" appearance="outline">
              <span matPrefix class="font-size-12">CC &nbsp;</span>
              <mat-chip-list #CCEmailchipList >
                <mat-chip [color]="(email.invalid)?'warn':''" selected
                  *ngFor="let email of cc; let i=index;"
                  [selectable]="selectable" [removable]="removable" (removed)="removeCcEmail(email)" name="chips"
                  class="text-lowercase">
                  {{email}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input [matChipInputFor]="CCEmailchipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="addCcEmail($event)">
              </mat-chip-list>
            </mat-form-field>
          </div>
        </div>
    </div>
    </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button class='primary' (click)="update()" >Update</button>
</mat-dialog-actions> 