import { NgModule } from '@angular/core';
import { SignupRequestRoutingModule } from './signup-request-routing.module';
import { SignupRequestComponent } from './signup-request/signup-request.component';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { RequestDetailsComponent } from './request-details/request-details.component';
import { CommonAllModule } from 'app/common-all/common-all.module';
import { AddSignupDialogComponent } from './add-signup-dialog/add-signup-dialog.component';
import { TrailCustomersComponent } from './trail-customers/trail-customers.component';
import { PassDueDateComponent } from './pass-due-date/pass-due-date.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ImportComponent } from './import/import.component';

import { CardsComponent } from './cards/cards.component';
import { MatSearchSelectorModule } from 'app/mat-search-selector/mat-search-selector.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { MigrationRequestComponent } from './migration-request/migration-request.component';
import { DataBackupRequestComponent } from './data-backup-request/data-backup-request.component';
import { FTPDetailsComponent } from './ftpdetails/ftpdetails.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ReleaseMaintenanceComponent } from './release-maintenance/release-maintenance.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ExitRequestComponent } from './exit-request/exit-request.component';
import { ApproveExitRequestComponent } from './approve-exit-request/approve-exit-request.component';
import { ConfirmExitRequestApproveComponent } from './confirm-exit-request-approve/confirm-exit-request-approve.component';
import { LeadOwnersComponent } from './lead-owners/lead-owners.component';
import { AddLeadownerComponent } from './lead-owners/add-leadowner/add-leadowner.component';
import { DemoRequestComponent } from './demo-request/demo-request.component';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { SubscriptionInvoiceComponent } from './subscription-invoice/subscription-invoice.component';
import { CpanelActivitiesComponent } from './cpanel-activities/cpanel-activities.component';
import { FtpFilesComponent } from './ftp-files/ftp-files.component';
import { ImplementationDataListingComponent } from './implementation-data-listing/implementation-data-listing.component';
import { DataMigrationListingComponent } from './data-migration-listing/data-migration-listing.component';
import { CreditAccountBalanceTransactionsComponent } from './credit-account-balance-transactions/credit-account-balance-transactions.component';
import { EditDoccumentlistingDialogComponent } from './edit-doccumentlisting-dialog/edit-doccumentlisting-dialog.component';
import { CouponComponent } from './coupon/coupon.component';
import { AddCouponlistingDialogComponent } from './add-couponlisting-dialog/add-couponlisting-dialog.component';
import { CustomMinDirective } from './custom-min-validator.directive';
import { UsageMetricsComponent } from './usage-metrics/usage-metrics.component';
import { FusionChartsModule } from 'angular-fusioncharts';


@NgModule({
    declarations: [
        SignupRequestComponent,
        RequestDetailsComponent,
        AddSignupDialogComponent,
        TrailCustomersComponent,
        PassDueDateComponent,
        ChangePasswordComponent,
        ImportComponent,
        CardsComponent,
        MigrationRequestComponent,
        DataBackupRequestComponent,
        FTPDetailsComponent,
        ReleaseMaintenanceComponent,
        ExitRequestComponent,
        ApproveExitRequestComponent,
        ConfirmExitRequestApproveComponent,
        LeadOwnersComponent,
        AddLeadownerComponent,
        DemoRequestComponent,
        SubscriptionInvoiceComponent,
        CpanelActivitiesComponent,
        FtpFilesComponent,
        ImplementationDataListingComponent,
        DataMigrationListingComponent,
        CreditAccountBalanceTransactionsComponent,
        EditDoccumentlistingDialogComponent,
        CouponComponent,
        AddCouponlistingDialogComponent,
        CustomMinDirective,
        UsageMetricsComponent
    ],
    imports: [
        SignupRequestRoutingModule,
        FuseSidebarModule,
        FuseSharedModule,
        CommonAllModule,
        MatSearchSelectorModule,
        ScrollingModule,
        IntlInputPhoneModule,
        CKEditorModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgxPowerBiModule,
        FusionChartsModule,
    ]
})
export class SignupRequestModule { }
