import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-data-migration-listing',
  templateUrl: './data-migration-listing.component.html',
  styleUrls: ['./data-migration-listing.component.scss']
})
export class DataMigrationListingComponent implements OnInit {

  displayedColumns: string[] = ['customer_name', 'intiated_by', 'intiated_on','poc' ,'current_stage', 'est'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  currentUser = {};
  params: { page: number; limit?: number, searchKey?: any, stageSlug?:any, listType?:any };
  stagesData:any;
  viewList:any='in_progress';

  constructor(
    private commonService: CommonService,
    public router: Router, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    this.getList();
  }

  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.commonService.getDataMigrationListing(this.params).subscribe(res => {
      if (res.status == 1) {
        let responseData = res.data.data;
        this.stagesData = res.data.stagesData;
        this.dataSource = new MatTableDataSource(responseData.data);
        this.length = responseData.total;
        this.pageSize = responseData.per_page;
        this.index = responseData.current_page;
      }
    })
  }

  navigate(element, blank) {
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.id, '_blank'); });
  }

  getColumnDate(info, column) {
    let objData = JSON.parse(info);
    if (isEmpty(objData[column])) {
      return '-';
    }
    return objData[column];
  }

  selectChange(val) {
    this.params.stageSlug = val;
    this.params.page = 1;
    this.getList();
  }

  getViewList(listType) {
    this.viewList = listType;
    this.params.listType = listType;
    this.params.page = 1;
    this.getList();
  }

}
