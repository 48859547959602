<div class="px-52">
    <ng-container *ngIf="!payment && plans">
        <mat-horizontal-stepper #stepper [linear]="isLinear">
            <mat-step [stepControl]="subscribeForm">
                <ng-template matStepLabel>Choose Plan</ng-template>
                <ng-container>
                    <form #subscribeForm="ngForm" (ngSubmit)="nextStage(subscribeForm.form)" novalidate>
                        <div fxLayout="row wrap" fxLayoutAlign="space-between start">
                            <div fxFlex="64">
                                <div class="billing_plans my-12">
                                    <div class="heading">Billing Cycle</div>
                                    <mat-radio-group aria-label="Select an option" class="w-80-p" fxLayout="row wrap"
                                        fxLayoutAlign="space-between center" [(ngModel)]="subscription_cycle"
                                        name="subscription_cycle" [disabled]="subscription_type == 'top_up'">
                                        <mat-radio-button *ngFor="let billingCycle of billingCycles;"
                                            (change)="changeBundleCartBillingCycle($event.value)"
                                            [value]="billingCycle.cycle">{{billingCycle.title}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>

                                <div class="billing_plans my-12">
                                    <div class="heading">Apps</div>
                                    <ng-container *ngFor="let app of defaultApps|keyvalue:unsorted">
                                        <mat-checkbox [(ngModel)]="selectedApps[app.key]"
                                            (change)="changeAppSelection(app.key)" [name]="app.key" class="w-100"
                                            [disabled]="subscription_type == 'top_up' && enabledApps[app.key] == 1">
                                            {{app.value}}
                                        </mat-checkbox>
                                    </ng-container>
                                </div>

                                <div class=" subscription_plans">
                                    <div class="heading">Plans</div>
                                    <ul class="nav nav-tabs pl-0 mt-0">
                                        <ng-container *ngFor="let plan_slug of enabledPlans; let i = index">
                                            <li class="nav-item w-25-p mr-12 mt-12"
                                                *ngIf="plans[plan_slug]['status'] == 1">
                                                <a class="nav-link border-radius-4 border p-16"
                                                    [ngClass]="(selectedPlan == plan_slug) ? 'primary' : '' "
                                                    (click)="choosePlan(plan_slug)">
                                                    <div fxLayout="column wrap" fxLayoutAlign="center center">
                                                        <span class="font-medium">{{ plans[plan_slug]['title'] |
                                                            titlecase}}</span>
                                                    </div>
                                                </a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>

                                <ng-container *ngIf="selectedPlan">
                                    <mat-accordion>
                                        <mat-expansion-panel expanded class="mb-16">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title class="font-weight-900">
                                                    User Licences
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div *ngFor="let app_slug of selectedApps | keyvalue:unsorted;">
                                                <div *ngIf="app_slug.value && plans[selectedPlan][app_slug.key]['user_license']"
                                                    class="pt-8" fxLayout="row wrap"
                                                    fxLayoutAlign="space-between center">
                                                    <div class="">
                                                        <h2 class="heading mb-0">
                                                            {{plans[selectedPlan][app_slug.key]['user_license'].addon.name}}
                                                            <small>
                                                                {{getCurrencySymbol(country_id)}}({{licensesCostArr[subscription_cycle][selectedPlan][appLicenseSlugs[app_slug.key]]
                                                                }} /User/Month)<br />
                                                            </small>
                                                        </h2>
                                                        <small>Choose how manu users you can access <span
                                                                class="text-success">Oorwin</span></small>
                                                    </div>
                                                    <div class="">
                                                        <mat-form-field appearance="outline">
                                                            <input matInput type="number"
                                                                [name]="app_slug.key+'_quantity'"
                                                                [(ngModel)]="cart[selectedPlan][appLicenseSlugs[app_slug.key]].quantity"
                                                                [min]="minLicenses[selectedPlan][appLicenseSlugs[app_slug.key]]"
                                                                (input)="addLicenesToCart(plans[selectedPlan][app_slug.key]['user_license'], app_slug.key)"
                                                                required>
                                                        </mat-form-field>
                                                        <div class="red-fg"
                                                            *ngIf="!subscribeForm.controls[app_slug.key+'_quantity']?.valid">
                                                            Min Licenses should be
                                                            {{minLicenses[selectedPlan][appLicenseSlugs[app_slug.key]]}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                    <!-- <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                                        <div class="">
                                            <h2 class="heading mb-0">Licences</h2>
                                        </div>
                                    </div> -->

                                    <mat-accordion>
                                        <mat-expansion-panel expanded>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title class="font-weight-900">
                                                    Add-ons
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    Customize and enhance your plan with additional features.
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <div>
                                                <ng-container *ngIf="checkEnableApp()">
                                                    <div class="border-bottom p-12 mb-8" fxLayout="row wrap"
                                                        fxLayoutAlign="space-between start"
                                                        *ngFor="let addonItem of plans[selectedPlan]['global']['paid_addons']">
                                                        <div fxFlex="55">
                                                            <div fxLayout="row wrap" fxLayoutAlign="start start">
                                                                <div fxFlex="30px">
                                                                    <mat-checkbox color="primary"
                                                                        name="addon_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                                                        [(ngModel)]="cart[addonItem.sb_plan_slug][addonItem.addon.slug].is_added"
                                                                        (change)="addItemToCart(addonItem, 'global', addonItem.price_type)">
                                                                    </mat-checkbox>
                                                                </div>

                                                                <div fxFlex fxLayout="column wrap"
                                                                    fxLayoutAlign="stat start">
                                                                    <h2 class="heading m-0 mb-8">
                                                                        {{addonItem.addon?.name}}
                                                                    </h2>
                                                                    <h3 class="sub-heading m-0 mb-8">
                                                                        {{addonItem.addon?.description}}</h3>
                                                                    <p class="text-muted font-size-12 m-0">
                                                                        {{addonItem.addon?.other_information}}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div fxFlex="40">
                                                            <ng-container
                                                                *ngIf="addonItem.price_type == 'slab' || addonItem.price_type == 'slab_flat_fee'">
                                                                <div>
                                                                    <mat-form-field appearance="outline"
                                                                        class="w-100-p">
                                                                        <mat-label>Quantity</mat-label>
                                                                        <mat-select
                                                                            name="quantity_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                                                            [(ngModel)]="cart[addonItem.sb_plan_slug][addonItem.addon.slug].slab_index"
                                                                            (selectionChange)="addItemToCart(addonItem, 'global')"
                                                                            [disabled]="!cart[addonItem.sb_plan_slug][addonItem.addon.slug].is_added">
                                                                            <mat-option value="">Select</mat-option>
                                                                            <mat-option
                                                                                *ngFor="let slabInfo of addonItem.other_details.slab_data | keyvalue: unsorted;"
                                                                                [value]="slabInfo.value.slab_index">
                                                                                {{slabInfo.value.name}}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>
                                                            </ng-container>

                                                            <ng-container *ngIf="addonItem.price_type == 'slab'  || addonItem.price_type=='flat_fee_per_unit'">
                                                                <mat-form-field appearance="outline">
                                                                    <mat-label>Units</mat-label>
                                                                    <input matInput type="number"
                                                                        name="addon_units_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                                                        [(ngModel)]="cart[addonItem.sb_plan_slug][addonItem.addon.slug].units"
                                                                        (input)="addItemToCart(addonItem, 'global')"
                                                                        [disabled]="!cart[addonItem.sb_plan_slug][addonItem.addon.slug].is_added"
                                                                        min="1">
                                                                </mat-form-field>
                                                                <div class="red-fg"
                                                                    *ngIf="subscribeForm.controls['addon_units_'+addonItem.sb_plan_slug+'_'+addonItem.addon?.slug]?.invalid">
                                                                    Min units should be 1
                                                                </div>
                                                            </ng-container>

                                                            <ng-container
                                                                *ngIf="addonItem.price_type != 'slab' && addonItem.price_type != 'slab_flat_fee'">
                                                                <div class="form-group mb-2">
                                                                    <small
                                                                        class="form-text text-muted">{{getCurrencySymbol(country_id)}}
                                                                        {{addonItem.price}} / month</small>
                                                                </div>
                                                            </ng-container>
                                                            <br>

                                                            <mat-checkbox color="primary"
                                                                name="auto_renew_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                                                [(ngModel)]="cart[addonItem.sb_plan_slug][addonItem.addon.slug].is_auto_renew"
                                                                [disabled]="!cart[addonItem.sb_plan_slug][addonItem.addon.slug].is_added">
                                                                Auto Renew</mat-checkbox>
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <div *ngFor="let app_slug of selectedApps | keyvalue:unsorted;">
                                                    <ng-container
                                                        *ngIf="app_slug.value && plans[selectedPlan][app_slug.key]['paid_addons']">
                                                        <div class="border-bottom p-12 mb-8" fxLayout="row wrap"
                                                            fxLayoutAlign="space-between start"
                                                            *ngFor="let addonItem of plans[selectedPlan][app_slug.key]['paid_addons'];">

                                                            <div fxFlex="55">
                                                                <div fxLayout="row wrap" fxLayoutAlign="start start">
                                                                    <div fxFlex="30px">
                                                                        <mat-checkbox color="primary"
                                                                            name="addon_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                                                            [(ngModel)]="cart[addonItem.sb_plan_slug][addonItem.addon.slug].is_added"
                                                                            (change)="addItemToCart(addonItem, app_slug.key, addonItem.price_type)">
                                                                        </mat-checkbox>
                                                                    </div>

                                                                    <div fxFlex fxLayout="column wrap"
                                                                        fxLayoutAlign="stat start">
                                                                        <h2 class="heading m-0 mb-8">
                                                                            {{addonItem.addon?.name}}
                                                                        </h2>
                                                                        <h3 class="sub-heading m-0 mb-8">
                                                                            {{addonItem.addon?.description}}</h3>
                                                                        <p class="text-muted font-size-12 m-0">
                                                                            {{addonItem.addon?.other_information}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div fxFlex="40">
                                                                <ng-container
                                                                    *ngIf="addonItem.price_type == 'slab' || addonItem.price_type == 'slab_flat_fee'">
                                                                    <div>
                                                                        <mat-form-field appearance="outline"
                                                                            class="w-100-p">
                                                                            <mat-label>Quantity</mat-label>
                                                                            <mat-select
                                                                                name="quantity_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                                                                [(ngModel)]="cart[addonItem.sb_plan_slug][addonItem.addon.slug].slab_index"
                                                                                (selectionChange)="addItemToCart(addonItem, app_slug.key)"
                                                                                [disabled]="!cart[addonItem.sb_plan_slug][addonItem.addon.slug].is_added">
                                                                                <mat-option value="">Select</mat-option>
                                                                                <mat-option
                                                                                    *ngFor="let slabInfo of addonItem.other_details.slab_data | keyvalue: unsorted;"
                                                                                    [value]="slabInfo.value.slab_index">
                                                                                    {{slabInfo.value.name}}
                                                                                </mat-option>
                                                                            </mat-select>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </ng-container>

                                                                <ng-container *ngIf="addonItem.price_type == 'slab'  || addonItem.price_type=='flat_fee_per_unit' ">
                                                                    <mat-form-field appearance="outline">
                                                                        <mat-label>Units</mat-label>
                                                                        <input matInput type="number"
                                                                            name="addon_units_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                                                            [(ngModel)]="cart[addonItem.sb_plan_slug][addonItem.addon.slug].units"
                                                                            (input)="addItemToCart(addonItem, app_slug.key)"
                                                                            [disabled]="!cart[addonItem.sb_plan_slug][addonItem.addon.slug].is_added"
                                                                            min="1">
                                                                    </mat-form-field>
                                                                    <div class="red-fg"
                                                                        *ngIf="subscribeForm.controls['addon_units_'+addonItem.sb_plan_slug+'_'+addonItem.addon?.slug]?.invalid">
                                                                        Min units should be 1
                                                                    </div>
                                                                </ng-container>

                                                                <ng-container
                                                                    *ngIf="addonItem.price_type != 'slab' && addonItem.price_type != 'slab_flat_fee'">
                                                                    <div class="form-group mb-2">
                                                                        <small
                                                                            class="form-text text-muted">{{getCurrencySymbol(country_id)}}
                                                                            {{addonItem.price}} / month</small>
                                                                    </div>
                                                                </ng-container>
                                                                <br>

                                                                <mat-checkbox color="primary"
                                                                    name="auto_renew_{{addonItem.sb_plan_slug}}_{{addonItem.addon?.slug}}"
                                                                    [(ngModel)]="cart[addonItem.sb_plan_slug][addonItem.addon.slug].is_auto_renew"
                                                                    [disabled]="!cart[addonItem.sb_plan_slug][addonItem.addon.slug].is_added">
                                                                    Auto Renew</mat-checkbox>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>

                                </ng-container>
                            </div>

                            <div fxFlex="33">
                                <div class="sticky-top">
                                    <mat-card>
                                        <div class="card-body">
                                            <div class="text-center">
                                                <p class="checkout-items px-8 mt-0">Total Items -
                                                    <span>{{checkoutItems.length}}</span>
                                                </p>
                                                <p class="font-medium">Total Cost</p>
                                                <h2 class="text-bold font-size-30 mb-0">
                                                    {{getCurrencySymbol(country_id)}}
                                                    <span *ngIf="!isEmpty(lineItemsData)">
                                                        {{(total_amount_before_discount - lineItemsData.amount)|number : '1.2-2'}}
                                                    </span>
                                                    <span *ngIf="isEmpty(lineItemsData)">
                                                       {{total_amount_before_discount|number : '1.2-2'}}
                                                    </span>
                                                </h2>
                                                <small class="form-text text-muted m-0">Billed
                                                    <span *ngIf="subscription_cycle == 1">Monthly</span>
                                                    <span *ngIf="subscription_cycle == 3">Quarterly</span>
                                                    <span *ngIf="subscription_cycle == 6">Half-Yearly</span>
                                                    <span *ngIf="subscription_cycle == 12">Yearly</span>
                                                </small>
                                                <hr />
                                            </div>
                                            <ul class="list-group p-0">
                                                <ng-container *ngFor="let checkoutItem of checkoutItems">
                                                    <li class="bg-light-success p-12 mb-12 border-radius-8"
                                                        fxLayout="row wrap" fxLayoutAlign="center center">
                                                        <div class="text-dark" fxFlex="90">
                                                            <span
                                                                class="font-size-12 font-medium">{{checkoutItem.name}}</span>
                                                            <div class="" fxLayout="row wrap">
                                                                <span
                                                                    class="font-size-26 text-bold line-height-28">{{checkoutItem.sub_total
                                                                    | number : '1.2-2' }}</span>
                                                                <small class="d-block text-muted pl-2">{{
                                                                    getCurrencySymbol(companyDetails?.country_id)}}
                                                                    <span
                                                                        *ngIf="checkoutItem.price_type != 'slab' && checkoutItem.price_type != 'slab_flat_fee' ">
                                                                        {{checkoutItem.price | number : '1.2-2'}}
                                                                    </span>
                                                                    <span
                                                                        *ngIf="checkoutItem.price_type == 'slab' || checkoutItem.price_type == 'slab_flat_fee'">
                                                                        {{checkoutItem.slab_price | number : '1.2-2' }}
                                                                    </span>
                                                                    per <span
                                                                        *ngIf="checkoutItem.price_type == 'per_unit'">/
                                                                        user</span>
                                                                    <span
                                                                        *ngIf="checkoutItem.price_type != 'per_unit'">/
                                                                        month</span>
                                                                    <span
                                                                        *ngIf="checkoutItem.price_type == 'per_unit' && subscription_type != 'top_up'">/
                                                                        month</span>
                                                                    |
                                                                    <span
                                                                        *ngIf="checkoutItem.price_type != 'slab' && checkoutItem.price_type != 'slab_flat_fee'">
                                                                        <span
                                                                            *ngIf="checkoutItem.credits_type == 'un_limited'">Unlimited</span>
                                                                        <span
                                                                            *ngIf="checkoutItem.credits_type == 'limited'">{{checkoutItem.quantity}}
                                                                            Credits</span>
                                                                    </span>
                                                                    <span
                                                                        *ngIf="checkoutItem.price_type == 'slab_flat_fee'">{{checkoutItem.slab_title}}</span>
                                                                    <span *ngIf="checkoutItem.price_type == 'slab'">
                                                                        {{checkoutItem.units * checkoutItem.slab_index}}
                                                                        Credits</span>
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <mat-icon class="cursor-pointer" matTooltip="Reove from cart"
                                                            (click)="removeItemFromCart(checkoutItem)">close</mat-icon>
                                                    </li>
                                                </ng-container>
                                            </ul>

                                            <mat-card-actions class="text-center">
                                                <button mat-button *ngIf="!isEmpty(checkoutItems)" type="submit"
                                                    matStepperNext class="primary w-100-p">
                                                    Checkout </button>

                                                <button mat-button type="submit" *ngIf="isEmpty(checkoutItems)"
                                                    class="primary w-100-p">
                                                    Checkout </button>
                                            </mat-card-actions>
                                        </div>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Checkout</ng-template>
                <form novalidate>
                    <div>
                        <mat-card class="card mt-12">
                            <div>
                                <div class="">
                                    <p class="font-size-16 font-medium">Order Summary</p>

                                    <ng-container *ngFor="let checkoutItem of checkoutItems; let i = index">
                                        <div class="bg-light border-radius-8 p-12 px-20 mb-12">
                                            <div class="" fxLayout="row wrap" fxLayoutAlign="space-between center">
                                                <div fxLayout="column wrap">
                                                    <h2 class="heading">{{checkoutItem.name}}</h2>
                                                    <small
                                                        class="d-block text-muted font-size-12">{{getCurrencySymbol(country_id)}}
                                                        <span
                                                            *ngIf="checkoutItem.price_type=='slab' || checkoutItem.price_type=='slab_flat_fee'">
                                                            {{checkoutItem.slab_price | number : '1.2-2'}}
                                                        </span>
                                                        <span *ngIf=" checkoutItem.price_type !='slab' ||
                                                            checkoutItem.price_type !='slab_flat_fee'">
                                                            {{checkoutItem.price | number : '1.2-2'}}
                                                        </span>
                                                        per
                                                        <span *ngIf=" checkoutItem.price_type=='per_unit'"> / user |
                                                        </span>
                                                        <span *ngIf=" checkoutItem.price_type !='per_unit' &&
                                                            subscription_type=='top_up'">
                                                            / month

                                                            |</span>
                                                        <span *ngIf=" subscription_type !='top_up'"> / month |</span>

                                                        <span *ngIf=" checkoutItem.price_type !='slab' &&
                                                            checkoutItem.price_type !='slab_flat_fee'">
                                                            <span
                                                                *ngIf=" checkoutItem.credits_type=='un_limited'">Unlimited</span>
                                                            <span
                                                                *ngIf=" checkoutItem.credits_type=='limited'">{{checkoutItem.quantity}}
                                                                Credits</span>
                                                        </span>
                                                        <span
                                                            *ngIf=" checkoutItem.price_type=='slab_flat_fee'">{{checkoutItem.slab_title}}</span>
                                                        <span *ngIf=" checkoutItem.price_type=='slab'">
                                                            {{checkoutItem.units * checkoutItem.slab_index}}
                                                            Credits</span>
                                                    </small>
                                                </div>

                                                <div class="col text-right"
                                                    *ngIf="checkoutItem.is_discount_applied == 1">
                                                    <span class="font-size-20 font-medium">{{checkoutItem.sub_total
                                                        }}</span>
                                                    <small class="d-block text-muted">
                                                        <span>(-) {{checkoutItem.discount_amount | number : '1.2-2'
                                                            }}</span>
                                                    </small>
                                                </div>
                                                <div class="col text-right">
                                                    <span class="font-size-20 text-bold"
                                                        *ngIf="checkoutItem.after_discount">{{getCurrencySymbol(country_id)}}{{checkoutItem.after_discount
                                                        | number : '1.2-2' }}</span>
                                                    <span class="font-size-20 text-bold"
                                                        *ngIf="!checkoutItem.after_discount">{{getCurrencySymbol(country_id)}}{{checkoutItem.sub_total
                                                        | number : '1.2-2' }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngFor="let lineItem of lineItemsData?.lineItems; let i = index">
                                        <div class="bg-light border-radius-8 p-12 px-20 mb-12">
                                            <div class="" fxLayout="row wrap" fxLayoutAlign="space-between center">
                                                <div fxLayout="column wrap">
                                                    <h2 class="heading">{{lineItem.title}}</h2>
                                                    <small class="d-block text-muted font-size-12">
                                                        {{getCurrencySymbol(country_id)}} {{lineItem.price}}
                                                    </small>
                                                </div>

                                               
                                                <div class="col text-right">
                                                    <span class="font-size-20 text-bold" >
                                                        {{getCurrencySymbol(country_id)}}{{lineItem.price
                                                        | number : '1.2-2' }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <div class="bg-light border-radius-8 p-12 px-20 mb-12">
                                        <div fxLayout="row wrap" fxLayoutAlign="end center">
                                            <div *ngIf="cart_total_amount>0"
                                                class="font-size-16 text-semibold text-right text-uppercase"
                                                fxFlex="70">Sub Total
                                            </div>
                                            <div *ngIf="cart_total_amount>0"
                                                class="font-size-20 text-semibold text-right" fxFlex="30">
                                                <span>
                                                    {{getCurrencySymbol(country_id)}}
                                                    <span
                                                        *ngIf="total_amount_before_discount">{{total_amount_before_discount|
                                                        number : '1.2-2'}}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div fxLayout="row wrap" fxLayoutAlign="end center"
                                            *ngIf="used_wallet_amount > 0">
                                            <div class="font-size-16 text-semibold text-right text-uppercase"
                                                fxFlex="70">Wallet
                                            </div>
                                            <div class="font-size-20 text-semibold text-right" fxFlex="30">(-)
                                                {{getCurrencySymbol(country_id)}}{{used_wallet_amount | number : '1.2-2'
                                                }}</div>
                                        </div>

                                        <div fxLayout="row wrap" fxLayoutAlign="end center"
                                            *ngIf="companyDetails?.country_id == 2 && tax_amount > 0">
                                            <div class="font-size-16 text-semibold text-right text-uppercase"
                                                fxFlex="70">
                                                {{tax_name}}</div>
                                            <div class="font-size-20 text-semibold text-right" fxFlex="30">(+)
                                                {{getCurrencySymbol(country_id)}}{{tax_amount | number : '1.2-2'}}</div>
                                        </div>

                                        <div fxLayout="row wrap" fxLayoutAlign="end center"
                                            *ngIf="companyDetails?.country_id == 2 && tds_amount > 0">
                                            <div class="font-size-16 text-semibold text-right text-uppercase"
                                                fxFlex="70">
                                                {{tds_title}}</div>
                                            <div class="font-size-20 text-semibold text-right" fxFlex="30">(-)
                                                {{getCurrencySymbol(country_id)}}{{tds_amount | number : '1.2-2' }}
                                            </div>
                                        </div>

                                        <div fxLayout="row wrap" fxLayoutAlign="end center">
                                            <div class="font-size-24 font-bold text-right text-uppercase mt-8"
                                                fxFlex="70">Total
                                            </div>
                                            <div class="font-size-24 font-bold text-right mt-2" fxFlex="30">
                                                {{getCurrencySymbol(country_id)}}{{cart_total_amount | number : '1.2-2'
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div *ngIf="companyDetails?.country_id == 2" fxLayout="row wrap" fxLayoutAlign="start start">
                                <div fxFlex="200px">
                                    <mat-checkbox color="primary"
                                        name="chk_tds_amount"
                                        [(ngModel)]="chk_tds_amount"
                                        (change)="addTdsAmountToCart(chk_tds_amount)">
                                    </mat-checkbox>
                                    Apply TDS 
                                    <span class="accent-fg mr-4" matTooltip="When you apply for the TDS, you must provide us with a digitally signed TDS certificate." 
                                    >
                                        <mat-icon class="s-18">info</mat-icon>
                                    </span>
                                </div>

                                
                            </div>

                        </mat-card>


                        <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-16">
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button class="primary ml-12" (click)="checkoutInvoice()">Checkout </button>
                        </div>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </ng-container>

    <form [formGroup]="subscriptionForm" *ngIf="subscriptionForm">
        <ng-container *ngIf="payment">
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill fxLayoutGap="40px" class="mb-12">
                <div fxFlex='330px' class="text-center font-size-32 text-bold">Payment Method</div>
                <div fxFlex class="text-right font-size-32 text-bold">
                    Total Amount: <span class="accent-fg"> {{getCurrencySymbol(country_id)}}{{cart_total_amount | number
                        :
                        '1.2-2' }}
                    </span>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="40px" class="mb-12">
                <div fxFlex='50' *ngIf="cart_total_amount>0">
                    <div *ngIf="!turnOffPaymentMode">
                        <ng-container *ngFor="let item of paymentmodes">
                            <ng-container
                                *ngIf="(subscription_type == 'renew' || (subscription_type != 'renew' && item.value != 'card')) && item.isActive == 1">
                                <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlexFill
                                    class="pay_modes cursor-pointer" (click)="paymentChange(item)"
                                    [ngClass]="{active: item.isSelected, 'disableDiv': item.isDisable}">
                                    <div fxFlex='75px'>
                                        <ng-container *ngIf="item.value == 'bank'">
                                            <mat-icon class="s-40 ml-4 pay_icon">account_balance</mat-icon>
                                        </ng-container>
                                        <ng-container *ngIf="item.slug == 'stripe_balance'">
                                            <mat-icon class="s-40 ml-4 pay_icon">account_balance_wallet</mat-icon>
                                        </ng-container>
                                        <ng-container *ngIf="item.value == 'cheque'">
                                            <mat-icon class="s-40 ml-4 pay_icon">monetization_on</mat-icon>
                                        </ng-container>
                                        <ng-container *ngIf="item.value == 'card'">
                                            <mat-icon class="s-40 ml-4 pay_icon">credit_card</mat-icon>
                                        </ng-container>
                                        <ng-container *ngIf="item.slug == 'invoice_url'">
                                            <mat-icon class="s-40 ml-4 pay_icon">mail</mat-icon>
                                        </ng-container>
                                    </div>
                                    <div fxFlex class="font-size-16 text-bold"> {{item.name}} </div>
                                    <div fxFlexAlign="center" class="select" *ngIf="item.isSelected">
                                        <mat-icon class="s-24 primary-fg ml-4">check_circle_outline</mat-icon>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <mat-radio-group
                            aria-labelledby="example-radio-group-label"
                            class="example-radio-group"
                            formControlName="selected_card">
                        <ng-container *ngFor="let card_detail of card_details | keyvalue:unsorted">
                            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="p-16 border border-radius-8 line-height-28 mt-12">
                                <div fxLayout="row wrap">
                                    <div class="mr-8">
                                        <mat-radio-button [value]="card_detail.key" [name]="card_detail.key"></mat-radio-button>
                                    </div>
                                    <div fxLayout="column" class="font-size-16">
                                        <div fxLayout="row wrap" fxLayoutAlign="center center">
                                            <ng-container [ngSwitch]="card_detail.value.cc_type">
                                                <img *ngSwitchCase="'visa'" src="assets/images/visa.svg" alt="" class="h-20 pr-8">
                                                <img *ngSwitchCase="'master'" src="assets/images/visa.svg" alt="" class="h-20 pr-8">
                                                <img *ngSwitchCase="'mastercard'" src="assets/images/master.svg" alt="" class="h-20 pr-8">
                                                <img *ngSwitchCase="'amex'" src="assets/images/amex.svg" alt="" class="h-20 pr-8">
                                                <img *ngSwitchDefault src="assets/images/payment-method.svg" alt="" class="h-20 pr-8">
                                            </ng-container>
                                            <span>* * * *</span>
                                            <span>{{card_detail.value.cc_number}}</span>
                                        </div>
                                        <div>
                                            {{card_detail.value.name}}
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="column" class="w-25-p font-weight-600">
                                    <span>Card Expiry</span>
                                    <span>{{card_detail.value.exp_month}}/{{card_detail.value.exp_year}}</span>
                                </div>
                            </div>
                        </ng-container>
                        </mat-radio-group>
                    </div>
                    <div class="ml-32 p-8 warn-fg font-size-16 border" *ngIf="turnOffPaymentMode">
                        <ng-container>
                            Please use exact stripe credit balance to processed for payment
                        </ng-container>
                    </div>
                </div>
                <div fxFlex class="pl-32 mt-20">
                    <!-- <ng-container *ngIf="showtxn && showTXNID">
                        <div class="text-bold font-size-16">
                            Transaction ID<span class="red-fg">*</span>
                        </div>
                        <div>
                            <mat-form-field class="w-40-p my-8" appearance="outline">
                                <input type="text" matInput formControlName="txn_id">
                            </mat-form-field>
                        </div>
                    </ng-container> -->

                    <!-- <ng-container *ngIf="showtxn && showTXNID">
                        <div class="text-bold font-size-16 mb-10">
                            Transaction Proof<span class="red-fg">*</span>
                        </div>
                        <div>
                            <input type="file" accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls" id="file1"
                                formControlName="bank_cheque_attchment" (change)="onSelectFile1($event)">
                            <div class="text-center w-160 mx-auto mb-20" fxLayout="row wrap"
                                fxLayoutAlign="center center" *ngIf="url1">
                                <img [src]="url1" style="max-width:100%;height: auto;">
                            </div>
                        </div>
                    </ng-container> -->

                    <ng-container>
                        <div class="text-bold font-size-16 mt-20">
                            Billing Details<span class="red-fg">*</span>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
                            <div fxFlex='40'>
                                <mat-form-field class="w-100-p my-8" appearance="outline">
                                    <mat-label>Street </mat-label>
                                    <input type="text" matInput formControlName="billing_street">
                                </mat-form-field>
                            </div>
                            <div fxFlex='40'>
                                <mat-form-field class="w-100-p my-8 ml-12" appearance="outline">
                                    <mat-label>City</mat-label>
                                    <input type="text" matInput formControlName="billing_city">
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
                            <div fxFlex='40'>
                                <mat-form-field class="w-100-p" appearance='outline'>
                                    <mat-label>Country</mat-label>
                                    <mat-select formControlName="billing_country"
                                        (selectionChange)="changeCountry($event.value)">
                                        <mat-option *ngFor="let item of countries" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex='40'>
                                <mat-form-field class="w-100-p ml-12" appearance='outline'>
                                    <mat-label>State</mat-label>
                                    <mat-select formControlName="billing_state">
                                        <mat-option *ngFor="let item of states" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
                            <div fxFlex='40'>
                                <mat-form-field class="w-100-p my-8" appearance="outline">
                                    <mat-label>Zip </mat-label>
                                    <input type="text" matInput formControlName="billing_zip">
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </form>

    <div class='footer' *ngIf="!turnOffPaymentMode">
        <button mat-button (click)="changePaymentMode()" *ngIf="payment">Back</button>
        <ng-container *ngIf="subscription_type != 'upgrade_subscription'">
            <button mat-button (click)="save()" class="primary" *ngIf="payment && cart_total_amount<=0">Pay</button>
            <button mat-button (click)="save()" class="primary"
            *ngIf="payment && payment_mode == 'invoice_url' &&  cart_total_amount > 0">{{isAccountBalPayment?'Pay':'Send
            Invoice Payment Link'}}
            </button>
        </ng-container>
        <button mat-button (click)="save()" class="primary" *ngIf="payment && subscription_type == 'upgrade_subscription'">Pay</button>
        <button mat-button (click)="downloadProforma()" class="primary" *ngIf="payment&&payment_mode == 'invoice_url'">
            Download Proforma</button>
        
    </div>
</div>