<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">Demo Requests</h2>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">

        <div class="search-wrapper" fxFlex="30" fxFlex.lt-xl="20">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [(ngModel)]="searchValue" placeholder="Name, Email, Region">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>

        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.first_name}} {{element.last_name}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.email}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="region">
                <mat-header-cell *matHeaderCellDef> Region </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.country_id == 1">USA</span>
                  <span *ngIf="element.country_id == 2">India</span>
                  <span *ngIf="element.country_id == 702">Singapore</span>
                </mat-cell>
              </ng-container>

              <!-- <ng-container matColumnDef="lead_owner">
                <mat-header-cell *matHeaderCellDef> Lead Owner </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.lfirst_name}} {{element.llast_name}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="lead_owner_email">
                <mat-header-cell *matHeaderCellDef> Lead Owner Email </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.lemail}} </mat-cell>
              </ng-container> -->

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>