<div class="page-layout carded fullwidth inner-scroll">
    <mat-toolbar-row class="secondary-headers w-100-p px-20">
      <h2 class="sub-heading">Missing TDS Transactions</h2>
    </mat-toolbar-row>
    <div class="center px-20">
      <div class="content-card mt-12 mb-52">
        <div fxLayout='row' fxLayoutGap="10px" fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
          <div class="search-wrapper" fxFlex="20">
            <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
              [(ngModel)]="searchValue" placeholder="Payment Info..">
            <mat-icon class="search_icon">search</mat-icon>
            <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
              close
            </mat-icon>
          </div>
  
          <div fxFlex="70" class="actions_list mt-12" fxLayout="row wrap" fxLayoutAlign="end center">
            
  
            <!-- <mat-form-field appearance="outline" class="w-160 mr-8">
              <mat-label>Country</mat-label>
              <mat-select [(ngModel)]="country_id" (selectionChange)="countryChanged()">
                  <mat-option *ngFor="let item of countries" [value]="item.value">
                      {{item.viewValue}}
                  </mat-option>
              </mat-select>
            </mat-form-field> -->
  
            <!-- <mat-form-field appearance="outline" class="w-160 mr-8">
              <mat-label>Payment Mode</mat-label>
              <mat-select [(ngModel)]="paymentType" (selectionChange)="paymentTypeChanged()">
                  <mat-option *ngFor="let item of paymentTypes" [value]="item.value">
                      {{item.viewValue}}
                  </mat-option>
              </mat-select>
            </mat-form-field> -->
  
            <form [formGroup]="paymentReportForm" fxFlex>
              <div fxLayout="row wrap" fxLayoutAlign="start center">
                <!-- <mat-form-field appearance="outline" class=" mr-8">
                  <mat-select formControlName="status">
                    <mat-option *ngFor="let item of status_list" [value]='item.value'>
                      {{item.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
  
                <mat-form-field class="w-162" appearance="outline" [matMenuTriggerFor]="menu">
                  <mat-label>Select date</mat-label>
                  <mat-date-range-input [rangePicker]="rangePicker">
                    <input matStartDate placeholder="Start date" readonly formControlName="start_date">
                    <input matEndDate placeholder="End date" readonly formControlName="end_date">
                  </mat-date-range-input>
                </mat-form-field>
    
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="setDate('this_week')"
                    [ngClass]="{'primary': (dateActive == 'this_week')}">This Week</button>
                  <button mat-menu-item (click)="setDate('this_month')"
                    [ngClass]="{'primary': (dateActive == 'this_month')}">This Month</button>
                  <button mat-menu-item (click)="setDate('last_month')"
                    [ngClass]="{'primary': (dateActive == 'last_month')}">Last Month</button>
                  <button mat-menu-item (click)="rangePicker.open();dateActive = 'custom';"
                    [ngClass]="{'primary': dateActive == 'custom'}">Custom Range</button>
                  <button mat-menu-item (click)="setDate('clear')"
                    [ngClass]="{'primary': dateActive == 'clear'}">Clear</button>
                  <!-- <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle> -->
                  <mat-date-range-picker #rangePicker>
                    <mat-date-range-picker-actions>
                      <button mat-button matDateRangePickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                    </mat-date-range-picker-actions>
                  </mat-date-range-picker>
                </mat-menu>

                <div fxFlex="6">
                <button mat-icon-button (click)="clearFilters()" class="mr-4">
                  <mat-icon>refresh</mat-icon>
                </button>
              </div>
              </div>
            </form>

            <div>
                <button mat-stroked-button class="default" (click)="exportExcel()">Export</button>
              </div>
              

       
            <div fxLayout="row" fxLayoutAlign="end center">
              <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
                [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
            </div>
  
          </div>
        </div>
        <div class="content" fusePerfectScrollbar>
          <ng-container *ngIf="dataSource">
            <div class="px-12 pb-12">
              <mat-table [dataSource]="dataSource">
  
                <ng-container matColumnDef="customer_name">
                  <mat-header-cell *matHeaderCellDef fxFlex> Customer</mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex>
                    <span *ngIf="element.company_name"> <a class='cursor-pointer'
                        (click)='navigate(element)'>{{element.company_name}} </a></span>
                    <span *ngIf="!element.company_name">Company Deleted</span>
                  </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="sales_rep">
                  <mat-header-cell *matHeaderCellDef fxFlex="145px"> Sales Rep </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="145px"> {{element.sales_executive_name}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="invocie_ref">
                  <mat-header-cell *matHeaderCellDef fxFlex="145px"> invoice Ref# </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="145px"> {{element.ref_id}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="transaction_id">
                  <mat-header-cell *matHeaderCellDef fxFlex="194px"> Trasaction ID </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="194px"> {{element.trasaction_id?element.trasaction_id:'-'}}
                  </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="invoice_type">
                  <mat-header-cell *matHeaderCellDef fxFlex> Payment Info </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex> {{element.item_details}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="amount">
                  <mat-header-cell *matHeaderCellDef fxFlex="100px"> Amount </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="100px"> {{getCurrencySymbol()}}{{trimAmount(element.amount)}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="payment_mode">
                  <mat-header-cell *matHeaderCellDef fxFlex="100px"> Payment Mode </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="100px">
                    {{element.payment_mode}}
                   </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="paid_on">
                  <mat-header-cell *matHeaderCellDef fxFlex="100px"> Paid On </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="100px"> {{element.updated_at}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef fxFlex="100px"> Actions </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="100px">

                    <button mat-icon-button class="accent-fg" matTooltip="TDS Certificate Remainder"
                      (click)="sendTdsRemainder(element.id, element.company_id)">
                      <mat-icon class="s-18">email</mat-icon> 
                    </button>
                    <span *ngIf="element.tds_remainder_cnt" matTooltip="{{element.tds_remainder_cnt}} Remainder(s) Sent">| {{element.tds_remainder_cnt}} </span>
                  </mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
  
              <div *ngIf="dataSource.data.length == 0" class="text-center">
                <h2 class="sub-heading">No Records Found</h2>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>