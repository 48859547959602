import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { FormGroup, FormBuilder, Validators, FormArray, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import masterdata from 'app/services/masterdata';
import { CustomConfirmDialogComponent } from '../../custom-confirm-dialog/custom-confirm-dialog.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-plan-info',
  templateUrl: './plan-info.component.html',
  styleUrls: ['./plan-info.component.scss']
})

export class PlanInfoComponent implements OnInit {

  billingCycles:any;
  plan_slug: any;
  // plansPaidData: any;
  company_id: any;
  companyDetails: any;
  params: { company_id: number; } 
  isPackageEnabled = { 'hire': false, 'hr': false, 'crm': false };
  enabledApps: any;
  currentPackage: any;
  licensesCostArr: any;
  addonsProrateArr: any;
  plansActiveClassArr: any;
  itemsarr: any;
  atsPlanPaidItems: any = [];
  hrPlanPaidItems: any;
  crmPlanPaidItems: any;
  minLicenses: any;
  bundleCartAddons:any = {};
  cartAddons:any = [];
  subscription_cycle:any = 1;
  singlePlanPreLicenses: any;

  // licensesErrorMsgObj = {};
  licensesErrorMsgObj: any [][] = [];

  used_wallet_amount: any;
  isZeroPaymentInvoice: any;
  total_amount_before_discount: any = 0;
  total: any;
  sub_total: any;
  cart_total_amount: any;
  is_gstin: any;
  wallet_amount: any;
  taxData: any;
  tax_amount: any;
  tds_amount: any;
  license_type_items:any;

  checkoutItems:any[] = [];
  discountedCheckoutItems: any[] = [];

  duplicateLineItemCoupon:any[] = [];
  duplicateOptItemCoupon:any[] = [];

  invoice_coupon_obj = {'coupon_id':''};
  invoiceCouponItem:any = [];
  total_amount_after_discount: any;

  planConfig = {
    'hire':['ats_classic','ats_starter', 'ats_professional', 'ats_enterprise',], 
    'hr':['hr_classic','hr_starter', 'hr_professional', 'hr_enterprise'], 
    'crm':['crm_classic','crm_starter', 'crm_professional', 'crm_enterprise'] 
  };

  tax_name: any;
  tds_title: any;
  // coupons: any;
  couponsIdasIndex: any;
  lineItemRemainRedemptions: any;
  // lineItemSelectedCouponsSpa: any;
  invoiceCoupns:any[] = [];
  lineItemCoupns:any[][] = [];
  invoiceCoupnsIdasIndex: any;
  lineItemCoupnsIdasIndex: any;
  lineItemCouponIndex: any;

  couponInfo: any;
  cart_total: any;
  total_price: any;
  total_discount_price: any;
  discount_amount: any;
  pertage_discount_amount: any;
  max_discount: any;
  percentageVal: any;
  checkoutItemArr: any;
  checkoutError: any = 0;
  otherData = {'selected_card' : 0};
  billing = {};
  billing_address: any;
  selected_country_id: any;
  state: any;  
  itemCouponsList:any;
  disablePaidLicenses: boolean = false;

  @ViewChild('subscribeForm') subscribeForm: FormGroupDirective;
  // @ViewChild('checkoutForm') checkoutForm: FormGroupDirective;
  
  subscription_type: string;
  payment: boolean = false;
  isAccountBalPayment:boolean = false;
  accountBalance:any;
  turnOffPaymentMode: boolean = false;
  masterData = masterdata;
  countries: { id: number; name: string; }[];
  states: { id: number; country_id: number; code: string; name: string; standard_timezone: string; timezone: string; }[];
  paymentmodes = [
    { name: 'Generate Invoice', value: 'invoice_url', isActive: 1, slug: 'invoice_url', isDisable: false, isSelected: 0 },
    { name: 'Stripe Credit Balance', value: 'invoice_url', isActive: 1, slug: 'stripe_balance', isDisable: false, isSelected: 0 },
  ];
  selected: any;
  payment_mode: any = '';
  showTXNID: boolean = true;
  showcc: boolean = false;
  showtxn: boolean;
  subscriptionForm: FormGroup;
  user_email: any;
  updateCheckout: Promise<any>;
  subscribedPlansPackageArr: any = {};

  planSubscribedItems: any;
  apply_coupon:any;
  subscriptionType: any;
  currency: string;
  currency_symbol: string;
  country_id: any;
  plans: any;
  licensesProrateArr: any;

  constructor(
    public commonservice: CommonService,
    public companyDetailService: CompanyDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private location: Location,
  ) {
    this.subscriptionForm = this.fb.group({
      billing_street: [''],
      billing_city: [''],
      billing_country: [''],
      billing_state: [''],
      billing_zip: [''],
      txn_id: [''],
      bank_cheque_attchment:['']
    })
    this.company_id = this.route.parent.snapshot.params['id'];
    this.subscription_type = this.route.snapshot.params['subscriptionType'];
    // if (this.commonservice.subscriptionType != '') {
    //   this.subscription_type = this.commonservice.subscriptionType;
    // } else {
    //   this.location.back();
    // }
    
  }

  ngOnInit() {

    this.params = { company_id: Number(this.company_id) }
    this.countries = this.masterData.countriesList;

    if (this.subscription_type == 'upgrade'){
      this.getPlanDetails();
    } else if (this.subscription_type == 'top_up'){
      this.getTopupPlanDetails();
    } else if (this.subscription_type == 'renew'){
      this.getRenewPlanDetails();
    } else {
      this.getPlanDetails();
    }
    this.checkCurrency();
  }

  changeCountry(country: number) {
    this.states = this.masterData.statesList.filter(state => state.country_id === country);
  }

  getPlanDetails() {

    this.companyDetailService.getPlanDetailsUpgrade(this.params).subscribe(res => {
      if (res.status == 1) {
        // this.plansPaidData = res.data.plansPaidData;
        this.plans = res.data.plans;
        this.enabledApps = res.data.enabledApps;
        this.currentPackage = res.data.enabledApps[0];
        this.licensesCostArr = res.data.licensesCostArr;
        this.companyDetails = res.data.companyRecord;
        this.country_id = this.companyDetails.country_id;
        this.plansActiveClassArr = res.data.plansActiveClassArr;
        this.minLicenses = res.data.minLicenses;
        this.singlePlanPreLicenses = res.data.singlePlanPreLicenses;
        this.is_gstin = res.data.is_gstin;
        this.license_type_items = res.data.license_type_items;
        this.billing_address = res.data.billing_address;
        this.tax_name = this.getTAXname(this.companyDetails.country_id);
        this.tds_title = this.getTAXname(this.companyDetails.country_id, 'tds');
        // this.coupons = res.data.coupons.applicableCoupons;
        // this.couponsIdasIndex = res.data.coupons.couponsIdasIndex;
        // this.lineItemRemainRedemptions = res.data.coupons.lineItemRemainRedemptions;
        // this.lineItemSelectedCouponsSpa = res.data.coupons.lineItemSelectedCouponsSpa;
        // this.invoiceCoupnsIdasIndex = this.couponsIdasIndex.invoice;
        // this.lineItemCoupnsIdasIndex = this.couponsIdasIndex.line_item;
        this.lineItemCouponIndex = {};
        this.billingCycles = res.data.billingCycles;

        this.enabledApps.forEach(element => {
          this.isPackageEnabled[element] = true;
        });
        this.resetMinLicenseValidation();
        // this.showFirstPlanDetails();
      }
      else {
        this.commonservice.openSnackBar('warn', res.data.message);
        this.router.navigate(['company_details/' + this.company_id + '/billingdetails']);
      }
    });
  }

  showFirstPlanDetails() {
    this.enabledApps.forEach(element => {
      var firstPlan = Object.keys(this.plans[element])[0];
      // this.getSelectedBundlePlanItems(element, firstPlan);
    });
  }

  showCheckoutOpt = function () {

    if (this.checkoutItems.length && (this.total_amount_before_discount == 0 || this.total_amount_before_discount >0) 
        ) {
        return true;
    }

    return false;
}

  getTopupPlanDetails() {

    this.companyDetailService.getTopupPlanDetails(this.params).subscribe(res => {
      if (res.status == 1) {
        // this.plansPaidData = res.data.plansPaidData;
        this.plans = res.data.plans;
        this.enabledApps = res.data.enabledApps;
        this.currentPackage = res.data.enabledApps[0];
        this.licensesCostArr = res.data.licensesCostArr;
        this.licensesProrateArr = res.data.licensesProrateArr;
        this.addonsProrateArr = res.data.addonsProrateArr;
        this.companyDetails = res.data.companyRecord;
        this.country_id = this.companyDetails.country_id;
        this.plansActiveClassArr = res.data.plansActiveClassArr;
        this.minLicenses = res.data.minLicenses;
        this.singlePlanPreLicenses = res.data.singlePlanPreLicenses;
        this.is_gstin = res.data.is_gstin;
        this.license_type_items = res.data.license_type_items;
        this.billing_address = res.data.billing_address;
        this.tax_name = this.getTAXname(this.companyDetails.country_id);
        this.tds_title = this.getTAXname(this.companyDetails.country_id, 'tds');

        // this.coupons = res.data.coupons.applicableCoupons;
        // this.couponsIdasIndex = res.data.coupons.couponsIdasIndex;
        // this.lineItemRemainRedemptions = res.data.coupons.lineItemRemainRedemptions;
        // this.lineItemSelectedCouponsSpa = res.data.coupons.lineItemSelectedCouponsSpa;

        this.subscription_cycle = res.data.subscriptionCycle;
        this.subscribedPlansPackageArr = res.data.subscribedPlansPackageArr;

        // this.invoiceCoupnsIdasIndex = this.couponsIdasIndex.invoice;
        // this.lineItemCoupnsIdasIndex = this.couponsIdasIndex.line_item;
        this.lineItemCouponIndex = {};
        this.billingCycles = res.data.billingCycles;

        this.enabledApps.forEach(element => {
          this.isPackageEnabled[element] = true;
        });

        this.onloadSelectedSubscribedPlan();
        this.resetMinLicenseValidation();

      }
    });
  }

  onloadSelectedSubscribedPlan() {
    Object.entries(this.subscribedPlansPackageArr).forEach( plan_slug => {
      this.getSelectedBundlePlanItems(plan_slug[0],plan_slug[1], 1);
      this.enableActiveClassForPlan(plan_slug[0], plan_slug[1]);
    });
  }

  getRenewPlanDetails() {
    this.companyDetailService.getRenewPlanDetails(this.params).subscribe(res => {
      if (res.status == 1) {
        // this.plansPaidData = res.data.plansPaidData;
        this.plans = res.data.plans;
        this.enabledApps = res.data.enabledApps;
        this.currentPackage = res.data.enabledApps[0];
        this.licensesCostArr = res.data.licensesCostArr;
        this.companyDetails = res.data.companyRecord;
        this.country_id = this.companyDetails.country_id;
        this.plansActiveClassArr = res.data.plansActiveClassArr;
        this.minLicenses = res.data.minLicenses;
        this.singlePlanPreLicenses = res.data.singlePlanPreLicenses;
        this.is_gstin = res.data.is_gstin;
        this.license_type_items = res.data.license_type_items;
        this.billing_address = res.data.billing_address;
        this.tax_name = this.getTAXname(this.companyDetails.country_id);
        this.tds_title = this.getTAXname(this.companyDetails.country_id, 'tds');

        // this.coupons = res.data.coupons.applicableCoupons;
        // this.couponsIdasIndex = res.data.coupons.couponsIdasIndex;
        // this.lineItemRemainRedemptions = res.data.coupons.lineItemRemainRedemptions;
        // this.lineItemSelectedCouponsSpa = res.data.coupons.lineItemSelectedCouponsSpa;

        this.subscription_cycle = res.data.subscriptionCycle;
        this.subscribedPlansPackageArr = res.data.subscribedPlansPackageArr;

        // this.invoiceCoupnsIdasIndex = this.couponsIdasIndex.invoice;
        // this.lineItemCoupnsIdasIndex = this.couponsIdasIndex.line_item;
        this.lineItemCouponIndex = {};
        this.billingCycles = res.data.billingCycles;

        this.planSubscribedItems = res.data.planSubscribedItems;

        this.enabledApps.forEach(element => {
          this.isPackageEnabled[element] = true;
        });

        this.purchasedPlanItemsBinding();
        this.resetMinLicenseValidation();

      }
    });
  }

  purchasedPlanItemsBinding(){
    //for renew
    Object.entries(this.subscribedPlansPackageArr).forEach( (pack:any) => {
        
        this.getSelectedBundlePlanItems(pack[0], pack[1], 1);

        Object.values(this.planSubscribedItems[pack[1]]).forEach( itemInfo => {
            
            if (itemInfo['price_type'] == 'slab' || itemInfo['price_type'] == 'flat_fee'|| itemInfo['price_type'] == 'flat_fee_pro_rate' || itemInfo['price_type'] == 'slab_flat_fee') {
                this.addItemtoCartTotalAmount(itemInfo);
            }
        });
    });  
  }

  resetMinLicenseValidation = function() {
    this.licensesErrorMsgObj = {};

    Object.entries(this.minLicenses).forEach( (licenseArr, plan_slug) => {
        this.licensesErrorMsgObj[licenseArr[0]] = {};
        Object.entries(licenseArr[1]).forEach( (license_slug, k)=> {
            this.licensesErrorMsgObj[licenseArr[0]][license_slug[0]] = {};
            this.licensesErrorMsgObj[licenseArr[0]][license_slug[0]]['error'] = 0;
            this.checkoutError = 0;
        });
    });
  }
  resetSelectedCoupons = function () {

    // Object.entries(this.lineItemSelectedCouponsSpa).forEach((dataArr, slug) => {
    //   Object.keys(dataArr[1]).forEach((c_id) => {
    //     this.lineItemSelectedCouponsSpa[dataArr[0]][c_id] = 0;
    //   });
    // });

    this.apply_coupon = 0;
    this.invoice_coupon_obj = {'coupon_id':''};
    this.cartSubTotalAmount();
  }

  unsorted() { }

  saveData(){
    // this.resetSelectedCoupons();
    this.checkoutError = 0;
    Object.values(this.licensesErrorMsgObj).forEach(licenseArr => {
        Object.values(licenseArr).forEach( edata => {
          if (edata.error == 1) {
              this.checkoutError = 1;
              this.commonservice.openSnackBar('warn', 'validation errors');
              return false;
          }
        });
    });
  }

  getTAXname(country_id, type = 'gst') {

    if (country_id == 2) {

      if (type == 'gst') {
        return 'GST of 18%';
      } 
      else if(type == 'tds') {
        return 'TDS of 10%';
      }
    }
  }

  getPlanTitle(plan_slug) {
    var planTitleArr = {
      'ats_starter': 'Starter', 'ats_professional': 'Professional', 'ats_enterprise': 'Enterprise', 'ats_classic': 'Classic',
      'hr_starter': 'Starter', 'hr_professional': 'Professional', 'hr_enterprise': 'Enterprise', 'hr_classic': 'Classic',
      'crm_starter': 'Starter', 'crm_professional': 'Professional', 'crm_enterprise': 'Enterprise', 'crm_classic': 'Classic',
    }
    return planTitleArr[plan_slug];
  }

  getConfigLicense(plan_slug) {
    var get_config_license = {
      'ats_starter':'hire_users', 'ats_professional':'hire_users','ats_enterprise':'hire_users','ats_classic':'hire_users',
      'hr_starter':'hr_users', 'hr_professional':'hr_users','hr_enterprise':'hr_users','hr_classic':'hr_users', 
      'crm_starter':'crm_users', 'crm_professional':'crm_users','crm_enterprise':'crm_users','crm_classic':'crm_users'
    };
    return get_config_license[plan_slug];
  }

  otherLicenseType(plan_slug) {
    var other_license_type_addons = {
      'ats_starter': [], 'ats_professional':[], 'ats_enterprise':[],'ats_classic': [],
      'hr_starter': [], 'hr_professional':[], 'hr_enterprise':[],'hr_classic': [],
      'crm_starter': [], 'crm_professional':[], 'crm_enterprise':[], 'crm_classic':[],
    };
    return other_license_type_addons[plan_slug];
  }

  getCurrencySymbol(country_id) {
    if (country_id == 2) {
      return '₹';
    } else {
      return '$';
    }
  }

  checkCurrency() {
    if (this.country_id == 2) {
      this.currency = 'INR';
      this.currency_symbol = '₹';
    } else {
      this.currency = 'USD';
      this.currency_symbol = '$';
    }
  }

  enableActiveClassForPlan(pack, plan, removeCls = false) {

    Object.keys(this.plansActiveClassArr).forEach(key => {

      this.itemsarr = this.plansActiveClassArr[key];

      if (!isEmpty(this.itemsarr)) {
        Object.keys(this.itemsarr).forEach(planSlug => {

          if (planSlug == plan) {
            this.plansActiveClassArr[pack][planSlug] = true;
            if (removeCls)
              this.plansActiveClassArr[pack][planSlug] = false;
          } else {
            this.plansActiveClassArr[pack][planSlug] = false;
          }
        });
      }
    });
  }

  checkIsEmpty(plan_slug) {

    if( this.bundleCartAddons[plan_slug])
      return false;
    else
      return false;
  }

  getSelectedBundlePlanItems(pack, plan_slug, isSelected = 0) {
    // if(this.checkSelectedPlanInDiffModules(pack, plan_slug)) {
    //   this.commonservice.openSnackBar('warn', 'You cannot select different plans in different modules');
    //   return false;
    // }
    this.resetMinLicenseValidation();

    /* if ($scope.purchaseType == 'single_plan' && isSelected == 1) {

      if (!$scope.validateSinglePlanUserLicenses(plan_slug)) {
          showAlertMessage({ status : 0, message : 'Please fill the required fields' });
          return;
      }
      // console.log('min',$scope.singlePlanPreLicenses);
     
      $scope.singleSelectedPack = pack;
      $scope.singleSelectedPlan = plan_slug;
      $scope.bundleCartAddons = {};
      // console.log('here');
      $scope.showDetailsForm('single_plan_addons');
    } */

    this.plan_slug = plan_slug;

    if (isSelected == 1) {
      this.enableActiveClassForPlan(pack, plan_slug);
    }

    if (pack == 'hire') {
      this.atsPlanPaidItems = this.plans[pack][plan_slug];
      if (isSelected == 1) {
        this.prepareCartItems(this.atsPlanPaidItems, plan_slug);
      }
    }
    else if (pack == 'hr') {
      this.hrPlanPaidItems = this.plans[pack][plan_slug];
      if (isSelected == 1) {
        this.prepareCartItems(this.hrPlanPaidItems, plan_slug);
      }

    }
    else if (pack == 'crm') {
      this.crmPlanPaidItems = this.plans[pack][plan_slug];
      if (isSelected == 1) {
        this.prepareCartItems(this.crmPlanPaidItems, plan_slug);
      }
    }

    if (isSelected == 1) {
        this.addOnlyOnePackagetoBundleCart(pack, plan_slug);
        this.cartSubTotalAmount();
    }
    this.disablePaidLicenses = false;

  }

  // checkSelectedPlanInDiffModules (pack, plan_slug) {
  //   let slug = plan_slug.split('_');
  //   for (const key in this.plansActiveClassArr) {
  //     if (Object.prototype.hasOwnProperty.call(this.plansActiveClassArr, key) && key != pack) {
  //       for (const sub_plan in this.plansActiveClassArr[key]) {
  //         if (Object.prototype.hasOwnProperty.call(this.plansActiveClassArr[key], sub_plan) && !sub_plan.includes(slug[1]) && this.plansActiveClassArr[key][sub_plan]) {
  //             return true;
  //         }
  //       }   
  //     }
  //   }
  //   return false;
  // }

  addOnlyOnePackagetoBundleCart = function(pack, plan_slug) {
    var packagePlans = this.planConfig[pack];
    packagePlans.forEach(plan => {

      if (plan != plan_slug && (this.bundleCartAddons[plan]) ) {
          delete this.bundleCartAddons[plan];
      }
    });
  }

  prepareCartItems(planData, plan_slug) {
    this.bundleCartAddons[plan_slug] = {};

    planData?.paid_addons.forEach(itemInfo => {
      this.setPrepareCart(itemInfo, plan_slug);
    });
    
    this.setPrepareCart(planData.user_license, plan_slug);

    // Object.values(planData).forEach((paidDataArr:any) => {
    //   console.log(paidDataArr);
    //   paidDataArr.forEach(itemInfo => {
    //     console.log(itemInfo);
    //     this.setPrepareCart(itemInfo, plan_slug);
    //   });
    // });
    // console.log('bundle', this.bundleCartAddons);
  }

  setPrepareCart(itemInfo, plan_slug) {
    // for renew
    let addonSlug = itemInfo.slug ?? itemInfo.addon?.slug;
    if (this.subscription_type == 'renew' && (this.planSubscribedItems[plan_slug]) && (this.planSubscribedItems[plan_slug][addonSlug])) {
      var subscribedItem = this.planSubscribedItems[plan_slug][addonSlug];
      subscribedItem.price = itemInfo.price;
      if (subscribedItem.price_type == 'per_unit') {
        subscribedItem.price = this.licensesCostArr[this.subscription_cycle][plan_slug];
        this.planSubscribedItems[plan_slug][addonSlug]['sub_total'] = (parseFloat(subscribedItem.quantity) * parseFloat(subscribedItem.price)) * parseFloat(this.subscription_cycle);
      } else if (subscribedItem.price_type == 'flat_fee' || subscribedItem.price_type == 'flat_fee_pro_rate') {
        this.planSubscribedItems[plan_slug][addonSlug]['sub_total'] = parseFloat(subscribedItem.price) * parseFloat(this.subscription_cycle);
      }
      this.bundleCartAddons[plan_slug][addonSlug] = this.planSubscribedItems[plan_slug][addonSlug];
    } else {

      var tempItem = {};
      tempItem['price_type'] = itemInfo.price_type;
      tempItem['slug'] = addonSlug;
      if (itemInfo.price_type == 'per_unit') {
        tempItem['price'] = this.licensesCostArr[this.subscription_cycle][plan_slug];
      } else {
        tempItem['price'] = itemInfo.price;
      }
      tempItem['name'] = itemInfo.addon?.name;
      tempItem['is_auto_renew'] = 0;
      tempItem['is_added'] = 0;
      tempItem['slab_index'] = "";
      tempItem['slab_price'] = "";
      tempItem['slab_title'] = "";
      tempItem['sub_total'] = 0;
      tempItem['credits_type'] = itemInfo.credits_type;
      tempItem['sb_plan_slug'] = itemInfo.sb_plan_slug;
      tempItem['package'] = itemInfo.package;
      tempItem['billing_type'] = itemInfo.billing_type;
      tempItem['units'] = 1;

      if (addonSlug == this.getConfigLicense(plan_slug)) {

        if (this.minLicenses[plan_slug][addonSlug]) {

          if (this.singlePlanPreLicenses[plan_slug] > this.minLicenses[plan_slug][addonSlug])
            tempItem['quantity'] = this.singlePlanPreLicenses[plan_slug];
          else
            tempItem['quantity'] = this.minLicenses[plan_slug][addonSlug];


          tempItem['is_added'] = 1;
          if (this.subscription_type == 'top_up') {
            tempItem['sub_total'] = (parseFloat(tempItem['quantity']) * parseFloat(tempItem['price']));
          } else {
            tempItem['sub_total'] = (parseFloat(tempItem['quantity']) * parseFloat(tempItem['price'])) * parseFloat(this.subscription_cycle);
          }
        }
      }
      else if (this.isOtherLicenseAddon(plan_slug, addonSlug)) {
        tempItem['quantity'] = 0;
      }
      else {
        tempItem['quantity'] = itemInfo.quantity;
      }

      this.bundleCartAddons[plan_slug][addonSlug] = tempItem;
    }
  }

  isOtherLicenseAddon = function(plan_slug, slug) {

    if (isEmpty(this.otherLicenseType(plan_slug)))
        return false;

    var itemIndex = this.otherLicenseType(plan_slug).indexOf(slug);

    if (itemIndex >= 0)
        return true;

    return false;
  }

  addLicenesToCart(plan_slug, licenseItem) {
    if (this.bundleCartAddons[plan_slug][licenseItem.addon?.slug].quantity > 0) {

      if (this.minLicenses[plan_slug][licenseItem.addon?.slug] > this.bundleCartAddons[plan_slug][licenseItem.addon?.slug].quantity) {
        this.licensesErrorMsgObj[plan_slug][licenseItem.addon?.slug]['error'] = 1;
        this.checkoutError = 1;
      } else {
        this.licensesErrorMsgObj[plan_slug][licenseItem.addon?.slug]['error'] = 0;
        this.checkoutError = 0;
      }

      if (this.subscription_type == 'top_up'){ 
        let licensePrice = this.licensesProrateArr[this.subscription_cycle][plan_slug];
        this.bundleCartAddons[plan_slug][licenseItem.addon?.slug]['price'] = licensePrice;
      } else {
        let licensePrice = this.licensesCostArr[this.subscription_cycle][plan_slug];
        this.bundleCartAddons[plan_slug][licenseItem.addon?.slug]['price'] = licensePrice;
      }

      this.bundleCartAddons[plan_slug][licenseItem.addon?.slug].is_added = 1;
      var cartItem = this.bundleCartAddons[plan_slug][licenseItem.addon?.slug];

      if(this.subscription_type == 'top_up') {
        this.bundleCartAddons[plan_slug][licenseItem.addon?.slug]['sub_total'] =
        (parseFloat(cartItem.quantity) * parseFloat(cartItem.price));
      } else {
        this.bundleCartAddons[plan_slug][licenseItem.addon?.slug]['sub_total'] =
        (parseFloat(cartItem.quantity) * parseFloat(cartItem.price)) * parseFloat(this.subscription_cycle);
      }
    }
    else if (isEmpty(this.bundleCartAddons[plan_slug][licenseItem.addon?.slug].quantity)) {

      this.bundleCartAddons[plan_slug][licenseItem.addon?.slug].is_added = 0;

      if (this.minLicenses[plan_slug][licenseItem.addon?.slug] > 0) {
        this.licensesErrorMsgObj[plan_slug][licenseItem.addon?.slug]['error'] = 1;
        this.checkoutError = 1;
      }
    }
    else {
      this.licensesErrorMsgObj[plan_slug][licenseItem.addon?.slug]['error'] = 0;
      this.checkoutError = 0;
    }
    this.cartSubTotalAmount();
  }

  calculateTax(sub_total, is_gstin = false) {
    let arrResult = { 'sub_total': parseFloat(sub_total), 'tax_amount': 0, 'tds_amount': 0 };
    if (this.companyDetails.country_id == 2 && sub_total > 0) {
      let tax_amount = parseFloat(sub_total) * 0.18;
      let final_amunt = tax_amount + parseFloat(sub_total);

      if (is_gstin) {
        let tds_amount = parseFloat(sub_total) * 0.10;
        let total_with_tds = parseFloat(sub_total) - tds_amount;
        final_amunt = total_with_tds + tax_amount;
        arrResult['tds_amount'] = tds_amount;
      }

      arrResult['sub_total'] = final_amunt;
      arrResult['tax_amount'] = tax_amount;
      return arrResult;
    }
    return arrResult;
  }

  calculateCheckedOutItemsCost() {

    this.cart_total = 0;
    this.total_price = 0;
    this.total_discount_price = 0;

    Object.values(this.discountedCheckoutItems).forEach ( itemInfo => {

        if (itemInfo.is_added == 1 && (itemInfo.sub_total) && itemInfo.sub_total > 0) {
            this.cart_total += parseFloat(itemInfo.after_discount);
            this.total_price += parseFloat(itemInfo.sub_total);
            this.total_discount_price += parseFloat(itemInfo.discount_amount);
        }
    });

    if (this.cart_total > 0) {

        this.invoiceCouponItem['sub_total'] = parseFloat(this.cart_total); 
        this.invoiceCouponItem['after_discount'] = parseFloat(this.cart_total); 
        this.invoiceCouponItem = this.applyCouponToInvoice(this.invoiceCouponItem); 
     
        if (this.invoiceCouponItem['discount_amount'] > 0) {
            this.total_discount_price += parseFloat(this.invoiceCouponItem['discount_amount']);
        }

        return parseFloat(this.invoiceCouponItem['after_discount']).toFixed(2);
    }

    return parseFloat(this.cart_total).toFixed(2);
  }

  cartSubTotalAmount(itemsFor='bundle') {
        
    this.used_wallet_amount = 0;
    this.isZeroPaymentInvoice = false;

    if (itemsFor == 'bundle') {
        this.total_amount_before_discount = this.total = this.getBundleCartTotalAmount();
    }
    else if (itemsFor == 'discount_items') {

        if (isEmpty(this.invoice_coupon_obj.coupon_id)) 
            this.invoiceCouponItem = {'name':'Invoice', 'is_discount_applied':0, 'coupon_id':0, 'discount_amount':0};

        this.total = this.total_amount_after_discount =  this.calculateCheckedOutItemsCost();
    }
    
    this.sub_total = this.cart_total_amount = this.total;

    if (this.companyDetails.wallet_amount)
      this.wallet_amount = parseFloat(this.companyDetails.wallet_amount);

    if (this.total > 0 && this.wallet_amount > 0) {
        this.used_wallet_amount = Math.min(this.total, this.wallet_amount);
        this.sub_total =  this.cart_total_amount =  parseFloat(this.total) - parseFloat(this.used_wallet_amount);
    }

    this.taxData = this.calculateTax( this.sub_total, this.is_gstin);
    this.tax_amount = this.taxData['tax_amount'];
    this.tds_amount = this.taxData['tds_amount'];

    if (this.taxData['sub_total'] <= 1) 
        this.isZeroPaymentInvoice = true;

    this.sub_total = this.cart_total_amount = this.taxData['sub_total'];
    
    return this.sub_total;
  }

  getBundleCartTotalAmount() {  
  
      this.checkoutItems = [];
      this.discountedCheckoutItems = [];
      var cart_total = 0;

      Object.values(this.bundleCartAddons).forEach(paidDataArr => {
        Object.values(paidDataArr).forEach(itemInfo => {
          if (itemInfo.is_added == 1 && itemInfo.billing_type == 'paid') {
            
            if (itemInfo.price_type == 'slab' || itemInfo.price_type == 'slab_flat_fee') {
                if ( (itemInfo.units != '') && (itemInfo.slab_index != '') )
                this.checkoutItems.push(itemInfo);
            } else {
              this.checkoutItems.push(itemInfo);
            }
            cart_total += parseFloat(itemInfo.sub_total);
          }
        });
      });

    this.discountedCheckoutItems = this.checkoutItems;
    
    return cart_total.toFixed(2);
  }

  removeItemFromCart (addedItem) {

    if (this.subscription_type == 'top_up'){
      if (this.getConfigLicense(addedItem.sb_plan_slug) == addedItem.slug && isEmpty(this.subscribedPlansPackageArr[addedItem.package])) {
        this.enableActiveClassForPlan(addedItem.package, addedItem.sb_plan_slug, true);
        delete this.bundleCartAddons[addedItem.sb_plan_slug];

      } else {

          if (this.license_type_items[addedItem.slug]==1) {
              this.bundleCartAddons[addedItem.sb_plan_slug][addedItem.slug]['quantity'] = 0;
          }
          this.bundleCartAddons[addedItem.sb_plan_slug][addedItem.slug]['is_added'] = 0;
          this.bundleCartAddons[addedItem.sb_plan_slug][addedItem.slug]['is_auto_renew'] = 0;
          this.bundleCartAddons[addedItem.sb_plan_slug][addedItem.slug]['slab_index'] = '';
          this.bundleCartAddons[addedItem.sb_plan_slug][addedItem.slug]['units'] = 1;
      }
    } else {
      
      if (this.getConfigLicense(addedItem.sb_plan_slug) == addedItem.slug ) {
        
        this.enableActiveClassForPlan(addedItem.package, addedItem.sb_plan_slug, true);
        delete this.bundleCartAddons[addedItem.sb_plan_slug];

        // Object.values(this.bundleCartAddons).forEach(paidDataArr => {
          
        //   Object.values(paidDataArr).forEach(itemInfo => {
            
        //     if ( addedItem.sb_plan_slug == itemInfo.sb_plan_slug) {
              
        //       if (this.license_type_items[itemInfo.slug]==1) {
        //           this.bundleCartAddons[itemInfo.sb_plan_slug][itemInfo.slug]['quantity'] = 0;
        //       }
        //       this.bundleCartAddons[itemInfo.sb_plan_slug][itemInfo.slug]['is_added'] = 0;
        //       this.bundleCartAddons[itemInfo.sb_plan_slug][itemInfo.slug]['is_auto_renew'] = 0;
        //       this.bundleCartAddons[itemInfo.sb_plan_slug][itemInfo.slug]['slab_index'] = '';
        //       this.bundleCartAddons[itemInfo.sb_plan_slug][itemInfo.slug]['units'] = 1;
        //     }
        //   });
        // });

        this.disablePaidLicenses = true;

      } else {

          if (this.license_type_items[addedItem.slug]==1) {
              this.bundleCartAddons[addedItem.sb_plan_slug][addedItem.slug]['quantity'] = 0;
          }
          this.bundleCartAddons[addedItem.sb_plan_slug][addedItem.slug]['is_added'] = 0;
          this.bundleCartAddons[addedItem.sb_plan_slug][addedItem.slug]['is_auto_renew'] = 0;
          this.bundleCartAddons[addedItem.sb_plan_slug][addedItem.slug]['slab_index'] = '';
          this.bundleCartAddons[addedItem.sb_plan_slug][addedItem.slug]['units'] = 1;
      }
    }     
    this.cartSubTotalAmount();
  }

  checkOorwinBrandingAddonInCart = function() {
    var cunt = 0;
    this.checkoutItems.forEach(item => {
      if (item.slug == 'oorwin_branding')
        cunt = cunt + 1;
    });

    if (cunt == 1)
      return true;

    return false;
  }

  addItemtoCartTotalAmount(item, priceType = null) {
    let planSlug = item.slug ?? item.addon?.slug;
    
    if (this.bundleCartAddons[item.sb_plan_slug][planSlug]['is_added'] == 0) {
      this.bundleCartAddons[item.sb_plan_slug][planSlug]['is_auto_renew'] = 0;
      this.bundleCartAddons[item.sb_plan_slug][planSlug]['slab_index'] = '';
      this.cartSubTotalAmount();
      return false;
    }

    /* if (item.slug == 'oorwin_branding' && this.checkOorwinBrandingAddonInCart() && priceType) {
        this.bundleCartAddons[item.sb_plan_slug][item.slug]['is_added'] = 0;
        this.commonservice.openSnackBar('warn', 'Remove oorwin branding addon is already added to cart');
        return false;
    } */

    if (priceType == 'slab' || priceType == 'slab_flat_fee')
      return false;

    if ((item.price_type == 'slab' || item.price_type == 'slab_flat_fee') && (this.bundleCartAddons[item.sb_plan_slug][planSlug]['slab_index'] == '')) {

      if (this.bundleCartAddons[item.sb_plan_slug][planSlug]['is_added'] == 1) {
        this.bundleCartAddons[item.sb_plan_slug][planSlug]['is_added'] = 0;
        this.bundleCartAddons[item.sb_plan_slug][planSlug]['is_auto_renew'] = 0;
        this.bundleCartAddons[item.sb_plan_slug][planSlug]['slab_index'] = '';
        this.cartSubTotalAmount();
      }

      return false;

    } else if ((item.price_type == 'slab' || item.price_type == 'slab_flat_fee') && (this.bundleCartAddons[item.sb_plan_slug][planSlug]['slab_index'] != '')) {
      var slabInfo = item['other_details']['slab_data'][this.bundleCartAddons[item.sb_plan_slug][planSlug]['slab_index']];
      this.bundleCartAddons[item.sb_plan_slug][planSlug]['slab_price'] = slabInfo.price;
      this.bundleCartAddons[item.sb_plan_slug][planSlug]['slab_title'] = slabInfo.name;
    }

    /* if ( item.price_type == 'slab' || item.price_type == 'slab_flat_fee' ){
        
      if (this.bundleCartAddons[item.sb_plan_slug][item.slug]['slab_index'])  {

        var slabInfo = item['other_details']['slab_data'][this.bundleCartAddons[item.sb_plan_slug][item.slug]['slab_index']];
        this.bundleCartAddons[item.sb_plan_slug][item.slug]['slab_price'] = slabInfo.price;
        this.bundleCartAddons[item.sb_plan_slug][item.slug]['slab_title'] = slabInfo.name;

      } else {
        return false;
      }
    } */

    if (item.price_type == 'slab') {

      var slabItem = this.bundleCartAddons[item.sb_plan_slug][planSlug];

      if (slabItem.units < 1)
        slabItem.units = 0;

      this.bundleCartAddons[item.sb_plan_slug][planSlug]['sub_total'] = parseFloat(slabItem.slab_price) * parseFloat(slabItem.units);
    }
    else if (item.price_type == 'per_unit') {

      this.bundleCartAddons[item.sb_plan_slug][planSlug]['sub_total'] =
        (parseFloat(this.bundleCartAddons[item.sb_plan_slug][planSlug].quantity) * parseFloat(item.price)) * parseFloat(this.subscription_cycle);
    }
    else if (item.price_type == 'flat_fee') {
      this.bundleCartAddons[item.sb_plan_slug][planSlug]['sub_total'] =
        parseFloat(item.price) * parseFloat(this.subscription_cycle);
    }
    else if (item.price_type == 'flat_fee_pro_rate') {

      if (this.subscription_type == 'top_up') {
        this.bundleCartAddons[item.sb_plan_slug][item.addon?.slug]['sub_total'] = this.addonsProrateArr[item.sb_plan_slug][item.addon?.slug];
      } else {
        this.bundleCartAddons[item.sb_plan_slug][item.addon?.slug]['sub_total'] =
          parseFloat(item.price) * parseFloat(this.subscription_cycle);
      }
    }
    else if (item.price_type == 'slab_flat_fee') {

      var selected_type = this.bundleCartAddons[item.sb_plan_slug][planSlug].slab_index;

      if (selected_type == 2)
        this.bundleCartAddons[item.sb_plan_slug][planSlug]['sub_total'] = parseFloat(this.bundleCartAddons[item.sb_plan_slug][planSlug]['slab_price']);
      else if (selected_type == 1)
        this.bundleCartAddons[item.sb_plan_slug][planSlug]['sub_total'] = parseFloat(this.bundleCartAddons[item.sb_plan_slug][planSlug]['slab_price']) * parseFloat(this.subscription_cycle);
    }
    
    this.cartSubTotalAmount();
  }

  changeBundleCartBillingCycle (event) {

    this.subscription_cycle = event.value;

    Object.entries(this.bundleCartAddons).forEach( paidDataArr => {
    
      Object.values(paidDataArr[1]).forEach(itemInfo => {
          // console.log(itemInfo);
          if (itemInfo.is_added == 1 && (itemInfo.sub_total) && itemInfo.sub_total > 0) {

              if (itemInfo.price_type == 'slab') {
                  this.bundleCartAddons[paidDataArr[0]][itemInfo.slug]['sub_total'] = 
                  parseFloat(this.bundleCartAddons[paidDataArr[0]][itemInfo.slug]['slab_price']);
              }   
              else if (itemInfo.price_type == 'per_unit') {
                  itemInfo.price = this.licensesCostArr[this.subscription_cycle][itemInfo.sb_plan_slug];
                  this.bundleCartAddons[paidDataArr[0]][itemInfo.slug]['price'] = itemInfo.price;
                  this.bundleCartAddons[paidDataArr[0]][itemInfo.slug]['sub_total'] = ((parseFloat(itemInfo.quantity) * parseFloat(itemInfo.price)) * this.subscription_cycle);
              }
              else if (itemInfo.price_type == 'flat_fee' || itemInfo.price_type == 'flat_fee_pro_rate') {
                  this.bundleCartAddons[paidDataArr[0]][itemInfo.slug]['sub_total'] = parseFloat(itemInfo.price) * parseFloat(this.subscription_cycle);
              }
              else if (itemInfo.price_type == 'slab_flat_fee') {

                var selected_type = this.bundleCartAddons[paidDataArr[0]][itemInfo.slug]['slab_index'];

                if (selected_type == 2)
                    this.bundleCartAddons[paidDataArr[0]][itemInfo.slug]['sub_total'] =  parseFloat(this.bundleCartAddons[paidDataArr[0]][itemInfo.slug]['slab_price']);
                else if (selected_type == 1)
                    this.bundleCartAddons[paidDataArr[0]][itemInfo.slug]['sub_total'] =  parseFloat(this.bundleCartAddons[paidDataArr[0]][itemInfo.slug]['slab_price']) * parseFloat(this.subscription_cycle);
            }
          }
        });
    });

    this.cartSubTotalAmount();
  }

  calculateAfterDiscount (total, discount) {
    
    var after_discount = parseFloat(total) - parseFloat(discount);
    
    if (after_discount <= 0) 
        return 0;

    return after_discount;
  }

  sumTwoAmounts (total, discount) {
    var totalAmunt = parseFloat(total) + parseFloat(discount);

    if (totalAmunt <= 0)
        return 0;
    return totalAmunt;
  }

  // getBillingCycleApplicableCoupons() {

  //   if ( this.coupons.invoice ) {
  //     Object.values(this.coupons.invoice).forEach( (couponInfo, k) => {
  //       if (couponInfo['billing_cycle'] == this.subscription_cycle)
  //         this.invoiceCoupns[k] = couponInfo;
  //     });
  //   }

  //   if ( this.coupons.line_item ) {
  //     Object.keys(this.coupons.line_item).forEach( (couponInfo, k) => {
  //       this.lineItemCoupns[couponInfo] = [];
  //     });
  //       Object.values(this.coupons.line_item).forEach( (couponInfo, k) => {

  //           Object.values(couponInfo).forEach( (couponData, ik) => {

  //               if (couponData['billing_cycle'] == this.subscription_cycle)
  //                 this.lineItemCoupns[couponData['item']][ik] = couponData;
  //           });
  //       });
  //   }
  // }

  applyCouponOnCheckout = function() {
    
    this.saveData();
    
    if (this.checkoutError == 1) {
      this.commonservice.openSnackBar('warn', 'validation errors');
      return false;
    }

    if (this.isZeroPaymentInvoice)
        return true;

    // this.getBillingCycleApplicableCoupons();

    this.itemCouponsList = {};
    
    Object.values(this.discountedCheckoutItems).forEach(checkoutItem => {
      
      checkoutItem['is_discount_applied'] = 0; checkoutItem['discount_amount'] = 0; 
      checkoutItem['after_discount'] = checkoutItem['sub_total']; checkoutItem['discount_title'] ='';

      if ( (this.lineItemCoupns) && (this.lineItemCoupns[checkoutItem['slug']])) {

          var size = Object.keys(this.lineItemCoupns[checkoutItem['slug']]).length;
          
          if (size == 1) {
              
              if(this.duplicateLineItemCoupon.hasOwnProperty(checkoutItem['slug']))
                  this.duplicateLineItemCoupon[checkoutItem['slug']] = parseInt(this.duplicateLineItemCoupon[checkoutItem['slug']]) + 1;
              else
                  this.duplicateLineItemCoupon[checkoutItem['slug']] = 1;
              
              this.couponInfo = this.lineItemCoupns[checkoutItem['slug']][0];

              if (this.duplicateLineItemCoupon[checkoutItem['slug']] <= this.couponInfo['remain_redemptions'])
                  checkoutItem = this.calculateDiscountAmount(checkoutItem, this.couponInfo);
          } 
          else if (size > 1) {
              this.itemCouponsList[checkoutItem['slug']] = [];
              this.itemCouponsList[checkoutItem['slug']] = this.lineItemCoupns[checkoutItem['slug']];
              this.itemCouponsList[checkoutItem['slug']]['coupon_id'] = '';
          }
      }

    });
    this.cartSubTotalAmount('discount_items');

    // console.log('itemcoupnslist', this.itemCouponsList);
    // console.log('checkout', this.discountedCheckoutItems);
  }

  applyCouponToLineItem (item_slug, itemIndex) {
    var coupon_id = this.itemCouponsList[item_slug]['coupon_id'];
    this.checkoutItemArr = this.discountedCheckoutItems[itemIndex];
    this.checkoutItemArr['is_discount_applied'] = 0; 
    this.checkoutItemArr['discount_amount'] = 0; this.checkoutItemArr['after_discount'] = this.checkoutItemArr.sub_total; 
    this.checkoutItemArr['discount_title'] =''; this.checkoutItemArr['coupon_id'] = 0;

    if (isEmpty(coupon_id)) {

        var removedCouponId = this.lineItemCouponIndex[itemIndex];

        if(this.duplicateOptItemCoupon.hasOwnProperty(this.checkoutItemArr.slug)) {
            if (this.duplicateOptItemCoupon[this.checkoutItemArr.slug].hasOwnProperty(removedCouponId)) {
                if (this.duplicateOptItemCoupon[this.checkoutItemArr.slug][removedCouponId] > 0) {
                    this.duplicateOptItemCoupon[this.checkoutItemArr.slug][removedCouponId] = parseInt(this.duplicateOptItemCoupon[this.checkoutItemArr.slug][removedCouponId]) - 1;
                }
            } 
        }
    }

    if (isEmpty(this.lineItemCoupnsIdasIndex[item_slug][coupon_id])) {
        this.discountedCheckoutItems[itemIndex] = this.checkoutItemArr;
        this.cartSubTotalAmount('discount_items');
        return;
    }

    var couponInfo = this.lineItemCoupnsIdasIndex[item_slug][coupon_id];
    this.lineItemCouponIndex[itemIndex] = couponInfo['id'];

    if(this.duplicateOptItemCoupon.hasOwnProperty(this.checkoutItemArr.slug)) {

        if (this.duplicateOptItemCoupon[this.checkoutItemArr.slug].hasOwnProperty(couponInfo['id'])) {
          this.duplicateOptItemCoupon[this.checkoutItemArr.slug][couponInfo['id']] = parseInt(this.duplicateOptItemCoupon[this.checkoutItemArr.slug][couponInfo['id']]) + 1;
        } else {
          this.duplicateOptItemCoupon[this.checkoutItemArr.slug][couponInfo['id']] = 1;
        }
    }
    else {
        this.duplicateOptItemCoupon[this.checkoutItemArr.slug] = {};
        this.duplicateOptItemCoupon[this.checkoutItemArr.slug][couponInfo['id']] = 1;
    }

    if (this.duplicateOptItemCoupon[this.checkoutItemArr.slug][couponInfo['id']] > couponInfo['remain_redemptions']) {

        this.commonservice.openSnackBar('warn', "You can't apply this coupon, max redemptions reached");

        this.duplicateOptItemCoupon[this.checkoutItemArr.slug][couponInfo['id']] = this.duplicateOptItemCoupon[this.checkoutItemArr.slug][couponInfo['id']] - 1;
        this.discountedCheckoutItems[itemIndex] = this.checkoutItemArr;

      // Object.keys(this.lineItemSelectedCouponsSpa[item_slug]).forEach(c_id => {
      //   if (coupon_id != c_id)
      //     this.lineItemSelectedCouponsSpa[item_slug][c_id] = 0;
      // });
        this.cartSubTotalAmount('discount_items');
        return;
    }

    if (this.duplicateOptItemCoupon[this.checkoutItemArr.slug][couponInfo['id']] <= couponInfo['remain_redemptions']){
        this.checkoutItemArr = this.calculateDiscountAmount(this.checkoutItemArr, couponInfo);
    }

    this.discountedCheckoutItems[itemIndex] = this.checkoutItemArr;
    // this.lineItemSelectedCouponsSpa[item_slug][coupon_id] = 1;
    this.cartSubTotalAmount('discount_items');
  }

  applyCouponToInvoice = function(checkoutItem) {

    if (isEmpty(this.invoiceCoupns))
        return checkoutItem;

    var size = Object.keys(this.invoiceCoupns).length;

    if (isEmpty(this.invoice_coupon_obj.coupon_id) && size == 1) {
        checkoutItem = this.calculateDiscountAmount(checkoutItem, this.invoiceCoupns[0]);
    }
    else if ( (this.invoice_coupon_obj.coupon_id) && size > 1) {
        checkoutItem = this.calculateDiscountAmount(checkoutItem, this.invoiceCoupnsIdasIndex[this.invoice_coupon_obj.coupon_id]);
    }

    return checkoutItem;
  }

  calculateDiscountAmount (checkoutItem, couponInfo) {
    
    checkoutItem['is_discount_applied'] = 1;

    if (couponInfo['type'] == 'fixed_value') {
        
        this.discount_amount  = Math.min(checkoutItem.sub_total, couponInfo['discount']);
        this.discount_amount = parseFloat(this.discount_amount).toFixed(2);
        checkoutItem['discount_amount'] = this.discount_amount;
        checkoutItem['after_discount'] = parseFloat(checkoutItem.sub_total) - parseFloat(this.discount_amount);
        // checkoutItem['discount_title'] = 'Total Amount '+this.getCurrencySymbol(this.companyDetails.country_id)+checkoutItem.sub_total+'- Flat discount '+this.getCurrencySymbol(this.companyDetails.country_id)+this.discount_amount+' on '+checkoutItem.name; 
        checkoutItem['discount_title'] = 'Discount ' +this.getCurrencySymbol(this.companyDetails.country_id)+this.discount_amount+' applied'; 

    }
    else if (couponInfo['type'] == 'percentage') {

        this.percentageVal = couponInfo['discount'] / 100;
        this.pertage_discount_amount = parseFloat(this.percentageVal) * parseFloat(checkoutItem.sub_total);
        this.pertage_discount_amount = parseFloat(this.pertage_discount_amount).toFixed(2);
        
        this.max_discount = 0;
        this.max_discount = Math.min(this.pertage_discount_amount, checkoutItem.sub_total);
        checkoutItem['discount_amount'] = parseFloat(this.max_discount);
        checkoutItem['after_discount'] = parseFloat(checkoutItem.sub_total) - parseFloat(this.max_discount);
        // checkoutItem['discount_title'] = 'Total Amount '+this.getCurrencySymbol(this.companyDetails.country_id)+checkoutItem.sub_total+'-'+couponInfo['discount']+'% amount '+this.getCurrencySymbol(this.companyDetails.country_id)+this.max_discount+' on '+checkoutItem.name;
        checkoutItem['discount_title'] = 'Discount ' +this.getCurrencySymbol(this.companyDetails.country_id)+this.max_discount+' applied'; 

    }

    checkoutItem['discount_amount'] = parseFloat(checkoutItem.discount_amount).toFixed(2);
    checkoutItem['after_discount'] = parseFloat(checkoutItem.after_discount).toFixed(2);
    checkoutItem['coupon_id'] = couponInfo['id'];

    return checkoutItem;
  }

  checkoutInvoice() {
    this.payment = true;
    this.companyDetailService.getAccountDetails(this.company_id).subscribe(res => {

      if (res.status == 1) {
        this.accountBalance = res.data.accountBalance;
        // this.accountBalance = 19000;
        this.user_email = res.data.address.email;
        this.showtxn = false;
        this.showInvoiceUrlPayment();
        this.appendBillingAddress(res.data?.address);
      }
    });    
  }

  isActive(item) {
    return this.selected === item;
  };

  changePaymentMode() {
    this.turnOffPaymentMode = false;
    this.payment = false;

    this.resetSelectedCoupons();    
    this.duplicateLineItemCoupon = [];
    this.duplicateOptItemCoupon = [];
    this.lineItemCouponIndex = {};
    this.resetMinLicenseValidation();
  }

  appendBillingAddress(addressInfo: any) {
    if (!isEmpty(addressInfo)) {
      this.changeCountry(parseInt(addressInfo.country));
      this.subscriptionForm.get('billing_street').setValue(addressInfo.street);
      this.subscriptionForm.get('billing_city').setValue(addressInfo.city);
      this.subscriptionForm.get('billing_country').setValue(parseInt(addressInfo.country));
      this.subscriptionForm.get('billing_state').setValue(parseInt(addressInfo.state));
      this.subscriptionForm.get('billing_zip').setValue(addressInfo.zip);
    }
  }
  
  showInvoiceUrlPayment() {
    let isActive = 1;
    this.isAccountBalPayment = false;
    this.turnOffPaymentMode = false;
    let totalAmount = this.sub_total.toFixed(2);
    let allowCreditBalance = false;
    console.log('bal', this.accountBalance, totalAmount);
    if (this.accountBalance > 0 && (parseFloat(this.accountBalance) >= parseFloat(totalAmount))) {
      isActive = 1;
      this.isAccountBalPayment = true;
    }
    else if (this.accountBalance > 0 && (parseFloat(this.accountBalance) < parseFloat(totalAmount))) {
      allowCreditBalance = true;
    }
    
    this.paymentmodes.forEach(ele => {

      if (this.isAccountBalPayment && ele.slug == 'stripe_balance') {
        ele.isDisable = false;
        ele.isActive = 1;
        ele.name = 'Stripe Credit Balance ' + this.getCurrencySymbol(this.companyDetails.country_id) + this.accountBalance;
      }
      
      if (this.isAccountBalPayment && ele.slug != 'stripe_balance') {
        ele.isDisable = true;
      }

      if (allowCreditBalance && ele.slug != 'stripe_balance') {
        ele.isDisable = false;
      }

      if (!this.isAccountBalPayment && ele.slug == 'stripe_balance') {
        ele.isActive = 0;
      }
      ele.isSelected = 0;
    })
  }

  paymentChange(item) {

    this.selected = item;
    this.payment_mode = item.value;

    this.paymentmodes.forEach(ele => {
      if(item.slug == ele.slug){
        ele.isSelected = 1;
      } else {
        ele.isSelected = 0;
      }
    });

    if(item.slug == 'invoice_url')
      this.isAccountBalPayment = false;
    else 
      this.isAccountBalPayment = true;



    if (this.payment_mode == 'invoice_url') {
      this.showTXNID = false;
    } else {
      this.showTXNID = true;
    }

    if (this.payment_mode != '') {

      if (this.payment_mode != 'card') {

        this.showtxn = true;
        this.showcc = false;
        
        if (this.payment_mode == 'invoice_url') {
          this.subscriptionForm.get('txn_id').clearValidators();
          this.subscriptionForm.get('bank_cheque_attchment').clearValidators();
        } else {
          this.subscriptionForm.get('txn_id').setValidators([Validators.required]);
          this.subscriptionForm.get('bank_cheque_attchment').setValidators([Validators.required]);
        }
        this.subscriptionForm.get('txn_id').updateValueAndValidity();
        this.subscriptionForm.get('bank_cheque_attchment').updateValueAndValidity();
        
        this.subscriptionForm.get('billing_street').setValidators([Validators.required]);
        this.subscriptionForm.get('billing_city').setValidators([Validators.required]);
        this.subscriptionForm.get('billing_country').setValidators([Validators.required]);
        this.subscriptionForm.get('billing_state').setValidators([Validators.required]);
        this.subscriptionForm.get('billing_zip').setValidators([Validators.required]);
      } else {
        this.showcc = true;
        this.showtxn = false;
      }
    } else {
      this.showtxn = false;
      this.showcc = false;
    }
  }

  save() {
    
    if ((!this.subscriptionForm.valid || !this.payment_mode) && this.sub_total > 0) {
      this.commonservice.openSnackBar('warn', 'Please submit required details');
      this.subscriptionForm.get('billing_street').markAsTouched();
      this.subscriptionForm.get('billing_city').markAsTouched();
      this.subscriptionForm.get('billing_country').markAsTouched();
      this.subscriptionForm.get('billing_state').markAsTouched();
      this.subscriptionForm.get('billing_zip').markAsTouched();
      this.subscriptionForm.get('txn_id').markAsTouched();
      return false;
    }
    
    if (this.cart_total_amount<=0)
      this.payment_mode = 'zeroInvoicePayment';
    
    let msg; let emailInfo;
    if (this.payment_mode == 'zeroInvoicePayment') {
      msg = 'Are you sure, Do you want to process payment with zero amount';
      emailInfo = this.user_email;
    }
    else if (this.payment_mode == 'invoice_url' && this.isAccountBalPayment) {
      msg = 'Are you sure, Do you want to process payment using stripe credit balance?';
      emailInfo = this.user_email;
    }
    else if (this.payment_mode == 'invoice_url' && !this.isAccountBalPayment) {
      msg = 'Are you sure, Do you want to send invoice payment link to customer?';
      emailInfo = this.user_email;
    }

    // if (this.billing_address != null) {
    //   this.selected_country_id = this.billing_address['country'];
    //   this.state = this.billing_address['state']; 
    //   this.billing = this.billing_address;
      
    //   this.billing['selected_country_id'] = this.billing['country'];
    //   this.billing['selected_state_id'] = this.billing['state'];
    // } else { 
      this.billing['street'] = this.subscriptionForm.value['billing_street'];
      this.billing['city'] = this.subscriptionForm.value['billing_city'];
      this.billing['country'] = this.subscriptionForm.value['billing_country'];
      this.billing['selected_country_id'] = this.subscriptionForm.value['billing_country'];
      this.billing['state'] = this.subscriptionForm.value['billing_state'];
      this.billing['zip'] = this.subscriptionForm.value['billing_zip'];
    // }
    
    const data = [];
    data['title'] = 'Alert';
    data['message'] = msg;
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    data['check_data1'] = 'Send invoice email to super admin';
    data['email'] = emailInfo;
    const dialogRef = this.dialog.open(CustomConfirmDialogComponent, {
      width: '35%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result.status) {

        let params:any = {};
        params['cartData'] = this.bundleCartAddons;
        params['billing'] = this.billing;
        params['subscription_cycle'] = this.subscription_cycle;
        params['selected_plan_slugs'] = Object.keys(this.bundleCartAddons);
        params['checkout_items'] = this.discountedCheckoutItems;
        params['invoice_coupon_id'] = this.invoiceCouponItem['coupon_id'];
        // params['otherData'] = this.otherData;
        params['master'] = '';
        params['txn_id'] = this.subscriptionForm.value['txn_id'];
        params['is_cpanel'] = 1;
        params['isAccountBalancePayment'] = this.isAccountBalPayment;
        params['payment_mode'] = this.payment_mode;
        params['company_id'] = this.company_id;

        params['comments'] = isEmpty(result.comments)?'':result.comments;

        if (result.check_data1) {
          params['mail_toadmin'] = result.check_data1;
          params['customer_email'] = result.customer_email;
        } else {
          params['customer_email'] = this.user_email;
        }

        (this.sub_total == 0) ? params['is_zero_payment'] = '1' : params['is_zero_payment'] = '0' ;
        
        if (this.payment_mode == 'invoice_url') {
          params['payment_type'] = this.subscription_type;
          this.updateCheckout = this.companyDetailService.invoiceURLCheckout(params).toPromise();
        }
        else if (this.payment_mode == 'zeroInvoicePayment') {
          params['payment_type'] = this.subscription_type;
          this.updateCheckout = this.companyDetailService.zeroAmountInvoiceCheckout(params).toPromise();
        }   

        this.updateCheckout.then(res => {
          if (res.status === 1) {
            if (this.isAccountBalPayment) {
              this.commonservice.getLoader();
              setTimeout(() => {
                this.commonservice.openSnackBar('primary', res.data.message);
                this.commonservice.getLoader();
                this.router.navigate(['company_details/' + this.company_id + '/billingdetails']);
              }, 8000);
            } else {
              this.commonservice.openSnackBar('primary', res.data.message);
              this.router.navigate(['company_details/' + this.company_id + '/billingdetails']);
            }
          } else {
            this.commonservice.openSnackBar('warn', res.data.message);
          }
        });
      }
    });
  }

}

