import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { AddCouponlistingDialogComponent } from '../add-couponlisting-dialog/add-couponlisting-dialog.component';
import { environment } from 'environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isEmpty } from 'lodash';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'] 
})
export class CouponComponent implements OnInit {

  MxRecordsFrom: FormGroup;
  displayedColumns: string[] = ['title','discountType','value','validity','validityExpiry','maxRedemptions', 'billingCycle', 'clients', 'restrictToAddon', 'actions'];
  dataSource;
  searchValue = '';
  save_type = '';
  getlist: any;
  getTableData: any;
  length: any;
  company_id: any;
  params: { company_id: number; limit: number; page: number, search: string; }
  currentPage: number;
  limit: number;
  currentUser: any;
  companydomain: any;
  
  constructor(private commonService: CommonService, public dialog: MatDialog , private activatedroute: ActivatedRoute) {
    this.company_id = this.activatedroute.parent.snapshot.params['id']; }

  ngOnInit() {
    this.params = { company_id: Number(this.company_id), limit: 15, page: 1, search: '',  }
    this.getCouponData();
  }

  applyFilter(val) {
    this.params['search'] = val;
    this.params.page = 1;
    this.getCouponData();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getCouponData();
  }

  getCouponData() {
    this.commonService.getCouponsList(this.params).subscribe(result => { 
      if (result.status === 1) {
        this.currentPage = result.couponsList.current_page;
        this.limit = result.couponsList.per_page;
        this.length = result.couponsList.total;
        this.dataSource = new MatTableDataSource(result.couponsList.data);
      }
    })
  }

  trimString(str) {
    var i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  addCoupon(){
    const dialogRef = this.dialog.open(AddCouponlistingDialogComponent, {
      width: '70%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCouponData();
      }
    });
  }

  importCoupon(){
    const dialogRef = this.dialog.open(AddCouponlistingDialogComponent, {
      width: '70%',
      data: 'import'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCouponData();
      }
    });
  }

  restrictToClients (element): void {

    this.commonService.getCouponsCompanyList( { id : element } ).subscribe(result => {

      if (result.status == 1) {
        var arrData = result.data;
        arrData = arrData.join('#');
        arrData = arrData.replaceAll("#", "<br>");
        arrData = this.trimString(arrData);

        const data = []; 
        data['title'] = 'Total Clients';
        data['message'] = arrData;
        data['successText'] = 'Close';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { 
          minWidth: '30%',
          minHeight: '40%',
          data: data
        });
      }
    })
    
  }

  restrictTo (element): void {
    
    if ( !isEmpty(element) ) {
      // var arrData = JSON.parse(element);
      var arrData = element;
      // arrData = arrData.join('#');
      // arrData = arrData.replaceAll("#", "<br>");
      arrData = this.trimString(arrData);
    } else {
      arrData = 'No Addons Selected';
    }

    const data = []; 
      data['title'] = 'Restricted To Addons';
      data['message'] = arrData;
      data['successText'] = 'Close';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, { 
        minWidth: '30%',
        minHeight: '40%',
        data: data
      });
  }

  edit(element): void {
    const dialogRef = this.dialog.open(AddCouponlistingDialogComponent, {
      width: '70%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getCouponData();
      }
    });
  }

  deleteDoccumentsData(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to Delete Coupon Data';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonService.deleteCoupon({ id: element.id }).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.getCouponData();
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      }
    })
  }

}
