import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FuseSharedModule } from '@fuse/shared.module';

import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';
import { FuseSidebarModule } from '@fuse/components';
import { MaterialModule } from 'app/material.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        QuickPanelComponent
    ],
    imports: [
        MatDividerModule,
        MatListModule,
        MatSlideToggleModule,
        FuseSharedModule,
        FuseSidebarModule,
        MaterialModule,
        CommonModule,
        RouterModule
    ],
    exports: [
        QuickPanelComponent
    ]
})
export class QuickPanelModule {
}
