<div class="loader content" *ngIf="showLoader">
  <mat-spinner *ngIf="showLoader" [diameter]="50"></mat-spinner>
</div>
<h1 mat-dialog-title class='text-uppercase'>Subscription ENd date</h1>
<mat-divider></mat-divider>
<form [formGroup]="extendTrail" (ngSubmit) ="extendDate()">
<mat-dialog-content>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Subscription end date</mat-label>
      <input matInput [matDatepicker]="picker" (click)="picker.open()" formControlName="subscr_end_date">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button class='primary mr-4'>Save</button>
  <button mat-flat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
</form>