import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../../../services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidateService } from 'app/services/validate.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import common from 'app/services/common';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-mail-templates-edit',
  templateUrl: './mail-templates-edit.component.html',
  styleUrls: ['./mail-templates-edit.component.scss']
})
export class MailTemplatesEditComponent implements OnInit {
  ckeditor: any;
  form: FormGroup;
  id:number;
  config = common.editorconfig;
  constructor(private CommonService: CommonService,
    private configService: ConfigurationService,
    private fb: FormBuilder,
    private validateservice: ValidateService,
    private route: ActivatedRoute,
    private location:Location) {
    this.id = this.route.snapshot.params['id'];
    this.form = this.fb.group({
      subject: ['', Validators.required],
      mail_body: ['', Validators.required]
    });
  }

  ngOnInit() { 
    this.configService.editTemplate(this.id).subscribe(res => {
      if(res.status == 1){
        this.form.get('subject').setValue(res.data.subject);
        this.form.get('mail_body').setValue(res.data.body);
      }
      else{
        this.CommonService.openSnackBar('warn', res.message);
      }
    });
  }
  onReady(e) {
    this.ckeditor = e.editor;
  }
  save() {
    console.log(this.form.valid, this.form.value)
    if (this.form.valid) {
      let params = {};
      params['id'] = this.id;
      params['body'] = this.form.value.mail_body;
      params['subject'] = this.form.value.subject;
      this.configService.saveTemplate(params).subscribe(res => {
          if(res.status == 1){
            this.CommonService.openSnackBar('primary',res.message);
          }
      })
    }
    else {
      this.validateservice.validateAllFormFields(this.form);
    }
  }

  back() {
    this.location.back();
  }

}
