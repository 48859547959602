import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { EditAddonsDialogComponent } from '../edit-addons-dialog/edit-addons-dialog.component';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-addon',
  templateUrl: './addon.component.html',
  styleUrls: ['./addon.component.scss']
})
export class AddonComponent implements OnInit {

  MxRecordsFrom: FormGroup;
  displayedColumns: string[] = ['name', 'description', 'other_information', 'actions'];
  dataSource;
  searchValue = '';
  getlist: any;
  getTableData: any;
  length: any;
  company_id: any;
  params: { limit: number; page: number, search: string; }
  currentPage: number;
  limit: number;
  more = [];
  more1 = [];


  constructor(
    private configService: ConfigurationService,
    public dialog: MatDialog, private activatedroute: ActivatedRoute) {
    this.company_id = this.activatedroute.parent.snapshot.params['id'];
  }

  ngOnInit() {
    this.params = { limit: 100, page: 1, search: '', }
    this.addonsList();
  }

  applyFilter(val) {
    this.params['search'] = val;
    this.params.page = 1;
    this.addonsList();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.addonsList();
  }

  addonsList() {
    this.configService.getAddonsList(this.params).subscribe(result => {
      if (result.status === 1) {
        this.currentPage = result.addonsList.current_page;
        this.limit = result.addonsList.per_page;
        this.length = result.addonsList.total;
        this.dataSource = new MatTableDataSource(result.addonsList.data);
      }
    })
  }

  trimString(str) {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  edit(element): void {
    const dialogRef = this.dialog.open(EditAddonsDialogComponent, {
      width: '50%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addonsList();
      }
    });
  }

  showActivities() {
    let activityData = {
      'company_id': 0,
      'module_slug': 'addons',
      'show_company_name': false,
      'module_name': 'Addons Information'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
