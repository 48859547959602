import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { AddRefundComponent } from '../refunds/add-refund/add-refund.component';
import * as moment from 'moment';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { CompanyDetailsService } from 'app/services/company-details.service';
import { UploadInvoicePdfComponent } from './upload-invoice-pdf/upload-invoice-pdf.component';
import { element } from 'protractor';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  displayedColumns: string[] = ['invoiceRef', 'transactionId', 'status', 'amount', 'refund_amount','incentive_amount', 'paidon', 'payment_mode', 'description', 'actions'];
  dataSource;
  id: any;
  searchValue = '';
  getTableData: any;
  length: any;
  pageSize: any;
  index: any;
  params: { company_id: any; page: number; limit?: number, searchKey?: any, itemsByStatus?: any, status?: any };
  active_subscription_id: any;
  permissions: any = {};
  subscription_end_date: any;
  payment_gateway: any;
  country_id: any;
  listType: any = 'listing';
  isLostAccount:any;

  selection = [
    { value: 'all', viewValue: 'All' },
    { value: '0', viewValue: 'Due' },
    { value: '1', viewValue: 'Paid' },
    { value: '2', viewValue: 'Failed' },
  ];
  form: FormGroup;
  constructor(private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    private route: ActivatedRoute,
    private fb: FormBuilder, public dialog: MatDialog) {
    this.id = this.route.parent.snapshot.params['id'];
    this.form = this.fb.group({
      selectVal: [this.selection[0].value]
    })
  }

  ngOnInit() {
    this.params = { company_id: this.id, limit: 15, itemsByStatus: 'all', page: 1 };
    this.getList(this.listType);
  }

  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList(this.listType);
  }

  pageEvent(event) {
    // console.log(event, this.getTableData.data[0].status);
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList(this.listType);
  }

  selectChange(val) {
    this.params.itemsByStatus = val;
    this.params.status = val;
    this.params.page = 1;
    this.getList(this.listType);
  }

  getList(list_type, loaded = false) {

    this.listType = list_type;

    if (loaded && list_type == 'listing') {
      this.selection = [
        { value: 'all', viewValue: 'All' },
        { value: '0', viewValue: 'Due' },
        { value: '1', viewValue: 'Paid' },
        { value: '2', viewValue: 'Failed' },
      ];
      this.params = { company_id: this.id, limit: 15, itemsByStatus: 'all', page: 1 };
      this.form.controls['selectVal'].setValue('all');
      this.displayedColumns = ['invoiceRef', 'transactionId', 'status', 'amount', 'refund_amount', 'paidon', 'payment_mode', 'actions'];
      
      
      
    }
    else if (loaded && list_type == 'invoice_url') {
      this.selection = [
        { value: '0', viewValue: 'Open' },
        { value: '1', viewValue: 'Paid' },
        { value: '2', viewValue: 'Deleted' },
      ];
      this.params = { company_id: this.id, limit: 15, status: '0', page: 1 };
      this.form.controls['selectVal'].setValue('0');
      this.displayedColumns = ['invoice_id', 'invoice_url', 'payment', 'status', 'created_on','due_on', 'actions'];
    }

    if (this.listType == 'listing') {
      this.companyDetailService.getInvoiceList(this.params).subscribe(result => {
        if (result.status === 1) {
          this.getTableData = result.data;
          this.isLostAccount = result.isAccountLost;
          this.length = this.getTableData.total;
          this.pageSize = result.data.per_page;
          this.index = result.data.current_page;

          this.permissions = result.permissions;
          this.active_subscription_id = result.active_subscription_id;
          this.subscription_end_date = result.subscription_end_date;
          this.payment_gateway = result.payment_gateway;
          this.country_id = result.country_id;

          if(this.country_id == 2)
          {
            this.displayedColumns = ['invoiceRef', 'transactionId', 'status', 'amount', 'refund_amount', 'paidon', 'payment_mode', 'actions','tds_certificate'];
          }

          this.dataSource = new MatTableDataSource(result.data.data);
        }
      })
    } 
    else if(this.listType == 'invoice_url') {
      this.commonService.getSubscriptionInvoices(this.params).subscribe(res => {
        if (res.status == 1) {
          this.length = res.data.total;
          this.pageSize = res.data.per_page;
          this.index = res.data.current_page;
          this.dataSource = new MatTableDataSource(res.data.data);
        }
      })
    }
  }

  downloadInvocieDoc(invoice_id, fileName, columnName) {
    this.commonService.downloadInvoice(this.id, invoice_id, columnName).subscribe(res => {
      if (columnName == 'invoice_attachment') {
        this.showPDFFile(res, fileName);
      } else {
        this.downloadFile(res, fileName);
      }
    });
  }

  showPDFFile(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const dataURL = URL.createObjectURL(blob);
    window.open(dataURL, '_blank');
  }

  downloadFile(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const dataURL = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = dataURL;
    a.download = fileName;
    a.click();
  }

  refundInvoice(invoice_record) {
    let prepareData: any;
    let refund_amount = invoice_record.total;
    if (invoice_record.refund_amount) {
      refund_amount = (invoice_record.total - invoice_record.refund_amount).toFixed(2);
    }
    prepareData = {
      'company_id': invoice_record.company_id,
      'ref_id': invoice_record.ref_id,
      'invoice_amount': refund_amount,
      'invoice_id': invoice_record.id,
      'payment_gateway': this.payment_gateway,
      'currency': this.getCurrencySymbol(),
      'record':invoice_record
    }

    const dialogRef = this.dialog.open(AddRefundComponent, {
      width: '50%',
      data: prepareData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getList(this.listType);
      }
    });
  }

  availableRefundAmount(record) {
    let amount = parseFloat(record.total);
    let refund_amount = parseFloat(record.refund_amount);

    if (refund_amount) {
      if (amount > refund_amount) {
        return true;
      } else if (amount < refund_amount) {
        return false;
      } else if (amount == refund_amount) {
        return false;
      }
    } else {
      return true;
    }
  }

  checkSubscriptionActive() {
    let to_day = moment(new Date()).format("YYYY-MM-DD");
    if (this.subscription_end_date == to_day) {
      return true;
    }
    let diffDays = moment(this.subscription_end_date).diff(to_day, 'days');
    if (diffDays > 0) {
      return true;
    }
    return false;
  }

  isActiveSubscriptionId(subscriptionId) {
    if(typeof(subscriptionId) == 'number') {
      return this.active_subscription_id.includes(subscriptionId);
    }

    if(subscriptionId) {
      return this.active_subscription_id.some(activeSubId => subscriptionId?.split(',')?.map(recordSubId => parseInt(recordSubId))?.includes(parseInt(activeSubId)));
    }
    return false;
    // if (subscriptionId) {
    //   let subscriptionIDArr = subscriptionId.split(',');
    //   subscriptionIDArr.forEach(element => {
    //     this.active_subscription_id.forEach(v => {
    //       if (element == v) {
    //         return true;
    //       }
    //     });
    //   });
    //   return false;
    // }
    // else {
    //   return false;
    // }
  }

  convertToPositive(amount) {
    return Math.abs(amount);
  }

  getCurrencySymbol() {
    if (this.payment_gateway == 1) {
      return '$';
    }
    if (this.country_id == 2) {
      return '₹';
    } else {
      return '$';
    }
  }

  getPaymentStatus(status) {
    if (status == 1) {
      return 'Paid';
    } else if (status == 0) {
      return 'Open';
    } else if (status == 2) {
      return 'Deleted';
    }
  }

  deleteRecord(record) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? You want to delete invoice';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = {};
        params['company_id'] = record.company_id;
        params['id'] = record.id;
        this.commonService.deleteSubscriptionInvoice(params).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
          this.getList('invoice_url', true)
        });
      } else { }
    })
  }

  showActivities() {
    let activityData ={
      'company_id':this.id,
      'module_slug':'invoices',
      'show_company_name':false,
      'module_name':'Invocies'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  uploadInvoice(element) {
    const dialogRef = this.dialog.open(UploadInvoicePdfComponent, {
      width: '50%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.getList(this.listType);
      }
    });
  }

  uploadTDSDocument(event: any, invoice_id:any): void {

    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0]['name'];
      var FormTDSAttach = new FormData();
      var extension = file.split('.').pop().toLowerCase();
      var valid_extensions = [];
      valid_extensions = [
            'pdf'
        ];

    if(!(valid_extensions.indexOf(extension) > -1))
    {
      this.commonService.openSnackBar('warn', 'Please upload valid file.');
    }
    else{
      FormTDSAttach.append('document_file', event.target.files[0]);

    let payLoadData = {};
    payLoadData['company_id'] = this.id;
    payLoadData['invoice_id'] = invoice_id;
    FormTDSAttach.append('requestData', JSON.stringify(payLoadData));
    this.companyDetailService.uploadTdsDocument(FormTDSAttach).subscribe(res => {
      if (res.status == 1) {
        this.commonService.openSnackBar('primary', res.message);
        this.getList(this.listType);
      } else {
        this.commonService.openSnackBar('warn', res.message);
      }
    })
    }

      
      // observable = this.CandidatesService.create(FormParsingAttach);

    }

    // if (this.is_uploaded) {
    
    // } else {
    //   this.commonService.openSnackBar('warn', 'Please upload pdf format');
    // }
  }
  
}
