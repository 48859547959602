<h1 mat-dialog-title class='text-uppercase'>{{show_title}} {{ data.is_unlimited == 1 ? ' (Unlimited)' : ''}} </h1>
<mat-divider></mat-divider>
<form [formGroup] = "AddLicense" (ngSubmit) ="updateLicenses()">
<mat-dialog-content>
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill fxLayoutGap="12px grid">
    <div fxFlex='48' fxLayout="row wrap" fxLayoutAlign="start center">
      <mat-label class="w-100-p sub-heading">Licences</mat-label>
      <mat-form-field class="w-50-p" appearance='outline'>
        <!-- (keyup) = "calCost()" -->
        <input numeric type="number" matInput formControlName="total_licenses" min="0">
      </mat-form-field>
      <!-- <mat-form-field class="w-50-p" appearance='outline'>
        <input type="text" matInput disabled value="({{per_user_license_cost}} / Per User)" >
      </mat-form-field> -->
    </div>
    <!-- <div fxFlex='48'  [hidden]="subscription_type == 'trial'">
      <mat-label class="w-100-p sub-heading">Cost</mat-label>
      <div fxLayout="row wrap" fxLayoutAlign="start center">
      <div fxFlex >
        <mat-form-field class="w-100-p" appearance='outline'>
          <input type="text" matInput  value="{{calTotalAmount}}" readonly style="cursor:no-drop;">
        </mat-form-field>
      </div>
      <div fxFlex='80px' class="ml-4">
        / month
      </div>
    </div>
    </div> -->
    <!-- <div fxFlex='48' *ngIf="subscription_type == 'paid'">
      <mat-label class="sub-heading">Usage</mat-label> <br />
      <mat-radio-group aria-label="Usage" formControlName="charge_type">
        <mat-radio-button value="0" class="font-size-14 mr-8" color="primary" [checked]= "true">Free</mat-radio-button> -->
        <!-- <mat-radio-button value="1" class="font-size-14" color="primary" >Charge</mat-radio-button> -->
      <!-- </mat-radio-group>
    </div> -->

    <!-- <div fxFlex='48' *ngIf="subscription_type == 'trial'">
      <mat-label class="sub-heading">Usage</mat-label> <br />
      <mat-radio-group aria-label="Usage" formControlName="charge_type">
        <mat-radio-button value="0" class="font-size-14 mr-8" color="primary" [checked]= "true">Free</mat-radio-button>
      </mat-radio-group>
    </div> -->

  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button class='primary'>Add</button>
</mat-dialog-actions>
</form>