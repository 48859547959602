import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { CommonHelpersService } from 'app/services/common-helpers.service';


@Component({
  selector: 'app-credit-account-balance-transactions',
  templateUrl: './credit-account-balance-transactions.component.html',
  styleUrls: ['./credit-account-balance-transactions.component.scss']
})
export class CreditAccountBalanceTransactionsComponent implements OnInit {

  displayedColumns: string[] = ['customer', 'balance', 'ref', 'status', 'created_by', 'created_on', 'proof'];
  displayedColumns1: string[] = ['customer', 'balance', 'invoice_ref', 'ref', 'debit_for', 'created_by', 'created_on', 'proof'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  currentUser = {};
  params: { page: number; limit?: number, status: any; searchKey?: any };
  stagesData: any;
  viewList: any = 0;

  constructor(
    private commonService: CommonService,
    private common_hepler: CommonHelpersService,
    public router: Router, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1, status: this.viewList };
    this.getList();
  }

  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.commonService.getStripeBalanceTransactions(this.params).subscribe(res => {
      if (res.status == 1) {
        let responseData = res.data;
        this.dataSource = new MatTableDataSource(responseData.data);
        this.length = responseData.total;
        this.pageSize = responseData.per_page;
        this.index = responseData.current_page;
      }
    })
  }

  navigate(element, blank) {
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }

  getViewList(listType) {
    if (listType == 0) {
      this.displayedColumns = ['customer', 'balance', 'ref', 'status', 'created_by', 'created_on', 'proof'];
    } else {
      this.displayedColumns = ['customer', 'balance', 'invoice_ref', 'ref', 'status', 'created_by', 'created_on', 'proof'];
    }
    this.viewList = listType;
    this.params.page = 1;
    this.params.status = listType;
    this.getList();
  }

  downloadInvocieDoc(element, fileName, columnName) {
    this.commonService.downloadInvoice(element.company_id, element.payment_id, columnName).subscribe(res => {
      this.showPDFFile(res, fileName);
    });
  }

  downloadOfflineDoc(element, fileName) {
    this.commonService.downloadOfflinePayment(element.company_id, element.id).subscribe(res => {
      this.downloadFile(res, fileName);
    });
  }

  showPDFFile(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const dataURL = URL.createObjectURL(blob);
    window.open(dataURL, '_blank');
  }

  downloadFile(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const dataURL = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = dataURL;
    a.download = fileName;
    a.click();
  }

}
