import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CompanyDetailsComponent } from './company-details.component';
import { BillingDetailsComponent } from './billing-details/billing-details.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { TextRecruitComponent } from './text-recruit/text-recruit.component';
import { MxRecordsComponent } from './mx-records/mx-records.component';
import { IntegrationControlsComponent } from './integration-controls/integration-controls.component';
import { CronEmailCleanupComponent } from './cron-email-cleanup/cron-email-cleanup.component';
import { DetailUserComponent } from './detail-user/detail-user.component';
import { PartnerSearchComponent } from './partner-search/partner-search.component';
import { RefundsComponent } from './refunds/refunds.component';
import { DataBackupComponent } from './data-backup/data-backup.component';
import { MigrationsComponent } from './migrations/migrations.component';
import { PlanInfoComponent } from './subscription/plan-info/plan-info.component';
import { MigrationFtpFilesComponent } from './migration-ftp-files/migration-ftp-files.component';
import { ImplementationActivitiesComponent } from './implementation-activities/implementation-activities.component';
import { TrainingsComponent } from './trainings/trainings.component';
import { DataMigrationComponent } from './data-migration/data-migration.component';
import { AddAddonsComponent } from './subscription/add-addons/add-addons.component';

import { CompanyPricePlanConfigurationComponent } from 'app/common-all/company-price-plan-configuration/company-price-plan-configuration.component';
import { DoccumentsListingComponent } from 'app/common-all/doccuments-listing/doccuments-listing.component';
import { SubscriptionPlanComponent } from './subscription/subscription-plan/subscription-plan.component';
import { FromEmailsComponent } from './from-emails/from-emails.component';

const routes: Routes = [
  {
    path: '',
    component: CompanyDetailsComponent,
    children: [
      {
        path: 'billingdetails',
        component: BillingDetailsComponent
      },
      {
        path: 'subscription',
        // component: CompanySubscriptionPlanDetailsComponent
        // component: PaidModeComponent
        component: CompanyPricePlanConfigurationComponent
      },
      {
        path: 'invoices',
        component: InvoicesComponent
      },
      {
        path: 'users',
        component: DetailUserComponent
      },
      {
        path: 'doccuments',
        component: DoccumentsListingComponent
      },
      {
        path: 'textrecruit',
        component: TextRecruitComponent
      },
      {
        path: 'mxrecords',
        component: MxRecordsComponent
      },
      {
        path: 'integrationControls',
        component: IntegrationControlsComponent
      },
      {
        path: 'cronemailcleanup',
        component: CronEmailCleanupComponent
      },
      {
        path: 'partner_search',
        component: PartnerSearchComponent
      },
      {
        path: 'refunds',
        component: RefundsComponent
      },
      {
        path: 'data_backup',
        component: DataBackupComponent
      },
      {
        path: 'candidatesmigration/:migrationType',
        component: MigrationsComponent,
      },
      {
        path: 'manageSubscription/:subscriptionType',
        // component: PlanInfoComponent,
        component: SubscriptionPlanComponent
        // component: ChangePlanComponent,
      },
      {
        path: 'bulkStatus',
        component: MigrationFtpFilesComponent,
      },
      {
        path: 'implementationActivities',
        component: ImplementationActivitiesComponent,
      },
      {
        path: 'trainings',
        component: TrainingsComponent,
      },
      {
        path: 'data-migration',
        component: DataMigrationComponent,
      },
      {
        path: 'from-emails',
        component: FromEmailsComponent,
      },
      
      {
        path: 'addAddons',
        component: AddAddonsComponent,
      },
      {
        path: '',
        redirectTo: 'billingdetails',
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyDetailsRoutingModule { }
