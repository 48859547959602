<div fxLayout="row" fxLayoutAlign="space-between" id="login">
  <div fxLayout="column" fxFlex="60" fxLayoutAlign="space-between start" style="height:100vh;overflow:hidden;">
    <div class="logo py-4 px-40">
      <img src="assets/images/logo/oorwin.png">
    </div>
    <div>
      <img src="assets/images/login.png" alt="image">
    </div>

  </div>

  <div id="login-form-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
    <div id="login-form">
      <div [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">
        <div *ngIf="!resetPassword">
          <div class="font-size-20 text-bold">Forgot Password</div>
          <p class="my-12">Go back to <span class="accent-fg cursor-pointer" (click)='backToLogin()'> Sign In </span> </p>
          <form [formGroup]="emailForm">
            <mat-form-field appearance="outline" class="mt-12 w-100-p">
              <mat-label>Email</mat-label>
              <mat-icon matPrefix class="s-18 mr-8">email</mat-icon>
              <input matInput formControlName="email">
            </mat-form-field>
            <button mat-raised-button color="primary" class="submit-button btn-rounded my-12" aria-label="Reset Password" 
              (click)='isValidEmail()'>
                Reset Password
            </button>
          </form>
        </div>
      </div>


      <div *ngIf="resetPassword">
        <div class="font-size-20 text-bold">Enter New Password</div>

        <form [formGroup]="resetForm">
          
          <mat-form-field class="mt-12" appearance="outline" >
            <mat-label>Enter New password </mat-label>
            <mat-icon matPrefix class="s-18 mr-8">vpn_key</mat-icon>
            <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
            <button type='button' mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="mt-12" appearance="outline" >
            <mat-label>Re Enter password </mat-label>
            <mat-icon matPrefix class="s-18 mr-8">vpn_key</mat-icon>
            <input matInput [type]="reHide ? 'password' : 'text'" formControlName="repassword">
            <button type='button' mat-icon-button matSuffix (click)="reHide = !reHide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{reHide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
          
          <button mat-raised-button color="primary" class="submit-button btn-rounded my-12" aria-label="Reset Password"
            (click)="savePassword()" >Update Password</button>
        </form>

      </div>

    </div>
  </div>
</div>