<h1 mat-dialog-title class='text-uppercase'>Upload Invoice Pdf</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
    <div class="border py-12">
        <h2 class="sub-heading text-center red-fg">Note: If you upload the invoice, the original invoice  will be replaced with uploaded invoice.</h2>
        <div fxLayout="row wrap" fxLayoutAlign="start center" class="pl-32 mt-20">
            <div fxFlex="30">
                <div class="text-bold font-size-16 mb-10">
                    Invoice Pdf<span class="red-fg">*</span>
                </div>
                <div>
                    <input type="file" accept=".pdf" id="file1" (change)="onSelectFile1($event)">
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
    <button mat-button class="primary" (click)="save()">Save</button>
</mat-dialog-actions>