<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">Client Refunds</h2>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">

        <div class="search-wrapper" fxFlex="30" fxFlex.lt-xl="20">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [(ngModel)]="searchValue" placeholder="Company Name">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>

        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          
          <button mat-flat-button class="primary mr-4" (click)="exportExcel()">Export</button>

          <mat-form-field appearance="outline" class="w-200 mr-8">
            <mat-label>Country</mat-label>
            <mat-select [(ngModel)]="country_id" (selectionChange)="countryChanged()">
                <mat-option *ngFor="let item of countries" [value]="item.value">
                    {{item.viewValue}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          
          <div>
            <form [formGroup]="refundForm">
              <mat-form-field class="w-200" appearance="outline" >
                <input matInput [matDatepicker]="dp" placeholder="Month and Year" formControlName="Year_Month">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                  (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                </mat-datepicker>
              </mat-form-field>
            </form>
          </div>

          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="customer_name">
                <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> <a class='cursor-pointer'
                  (click)='navigate(element)'>{{element.company_name}}</a> </mat-cell>
              </ng-container>

              <ng-container matColumnDef="invoiceRef">
                <mat-header-cell *matHeaderCellDef>Invoice Ref# </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.ref_id}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{getCurrencySymbol(element.payment_gateway, element.country_id)}} {{element.amount}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="rufund_to">
                <mat-header-cell *matHeaderCellDef> Refund To </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.refund_type}}
                </mat-cell>
              </ng-container>

              <!-- <ng-container matColumnDef="reason">
                <mat-header-cell *matHeaderCellDef> Reason </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.reason">{{element.reason}}</span> 
                  <span *ngIf="!element.reason">-</span> 
                </mat-cell>
              </ng-container> -->

                <!-- description Column -->
            <ng-container matColumnDef="reason">
              <mat-header-cell *matHeaderCellDef fxFlex="280px"> Reason </mat-header-cell>
              <mat-cell *matCellDef="let element;let i = index" fxFlex="280px">
                <span *ngIf="(element.reason + '').length > 50; else elseBlock">
                  <span *ngIf="!more[i]">{{element.reason | slice:0:50}} <a class="cursor-pointer"
                      (click)="more[i] = !more[i]">More</a></span>
                  <span *ngIf="more[i]" >{{element.reason}} <a class="cursor-pointer"
                      (click)="more[i] = !more[i]">Less</a></span>
                </span>
                <ng-template #elseBlock >{{element.reason}}</ng-template>
              </mat-cell>
            </ng-container>

              <ng-container matColumnDef="refund_date">
                <mat-header-cell *matHeaderCellDef> Refund Date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at | date:"MM/dd/yyyy"}}
                </mat-cell>
              </ng-container>

               <!-- action Column -->
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
              <mat-cell *matCellDef="let element">
                  <button mat-icon-button class="accent-fg" matTooltip="Download" *ngIf="element.is_pdf_generated ==1" (click)="downloadInvocieDoc(element.company_id, element.refund_id, element.invoice_attachment)">
                      <mat-icon class="s-18">insert_drive_file</mat-icon>
                  </button>
                  <span *ngIf="element.is_pdf_generated == 0">-</span>
              </mat-cell>
          </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>