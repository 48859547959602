import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-price-plan-configuration',
  templateUrl: './company-price-plan-configuration.component.html',
  styleUrls: ['./company-price-plan-configuration.component.scss']
})
export class CompanyPricePlanConfigurationComponent implements OnInit {

  hideCountry: boolean = false;
  constructor(private router: Router) {
    this.hideCountry = this.router.url.includes('addons/paid') ? false : true;
  }

  ngOnInit(): void {
  }

}
