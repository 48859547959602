

class Common {
    public editorconfig = {
        // Define the toolbar groups as it is a more accessible solution.
        toolbarGroups: [{
            "name": "basicstyles",
            "groups": ["basicstyles"]
        },
        {
            "name": "links",
            "groups": ["links"]
        },
        {
            "name": "paragraph",
            "groups": ["list", "blocks"]
        },
        {
            "name": "document",
            "groups": ["mode"]
        },
        {
            "name": "tools",
            "groups": ["tools"]
        },
        {
            "name": "insert",
            "groups": ["insert"]
        },
        {
            "name": "styles",
            "groups": ["styles"]
        },
        {
            "name": "colors",
            "groups": ["colors"]
        }
        ],
        fontSize_style: {
            element: 'span',
            styles: { 'font-size': '#(size)' },
            overrides: [{ element: 'font', attributes: { 'size': null } }]
        },
        // Remove the redundant buttons from toolbar groups defined above.
        removeButtons: 'Underline,Strike,Subscript,Superscript,Anchor,Styles,Specialchar,Source',
        removePlugins: 'elementspath,resize,cloudservices,easyimage,magicline',
        removeDialogTabs: 'image:advanced;link:advanced',
        allowedContent: true,
        disableNativeSpellChecker: true,
        scayt_autoStartup: true,
        fontSize_defaultLabel: '12',
        format_tags: 'p;h1;h2;h3;pre',
        extraPlugins: 'uploadimage,font,contextmenu,colorbutton,justify,copyformatting,maximize,showborders',
        height: '45vh',
        startupShowBorders: false,
        placeholder: 'Enter Conter here..'

    };
}

export default new Common;