import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SharedServiceService } from './services/shared-service.service';
import { AuthModule } from './auth/auth.module';
import { MaterialModule } from './material.module';
import { ExcelService } from './services/excel.service';
import { SignupRequestModule } from './main/signup-request/signup-request.module';
import { CommonAllModule } from './common-all/common-all.module';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { HttpTokenInterceptor } from './services/httptoken.interceptor';
import { HttpErrorInterceptor } from './services/httperror.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { ConfigurationModule } from './main/configuration/configuration.module';
import { ReportsModule } from './main/reports/reports.module';

// Import angular-fusioncharts
import { FusionChartsModule } from "angular-fusioncharts";

// Import FusionCharts library and chart modules
import * as FusionCharts from "fusioncharts";
import * as Charts from "fusioncharts/fusioncharts.charts";

import * as FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

// Pass the fusioncharts library and chart modules 
FusionChartsModule.fcRoot(FusionCharts, Charts, FusionTheme);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MaterialModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        SignupRequestModule,
        AppRoutingModule,
        AuthModule,
        CommonAllModule,
        ConfigurationModule,
        ReportsModule,

        FusionChartsModule
    ],
    providers: [SharedServiceService, ExcelService, DatePipe, CurrencyPipe,
        { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    ],
    bootstrap: [
        AppComponent
    ],
    exports: [
        CommonAllModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class AppModule {
}
