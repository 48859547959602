<div class="dialog-wrap">
    <div mat-dialog-title>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>{{planTitleArr[plan_id]}} Features</div>
        <button mat-icon-button mat-dialog-close class="btn-transparent">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  
    <mat-dialog-content class="mat-typography">
      <h4 class="">All Features:</h4>
      <div class="row">
      <div style="width: 33%;display: inline-grid;" *ngFor="let appSlug of selectedApps | keys">
          <h3 class="mb-0">{{defaultApps[appSlug.key]}}</h3>
          <mat-list *ngFor="let item of plans[appSlug.key]['features']" >
            <mat-list-item style="font-size: 12px; height: 16px;">{{item.name}}
              <span *ngIf="item.quantity>1">({{item.quantity}} Credits)</span>
              <span *ngIf="item.quantity=='Unlimited'">(Unlimited)</span>
            </mat-list-item>
          </mat-list>
        </div>
          <!-- <div class="" *ngFor="let item of plans[appSlug.key]['features']">
              <span class="text-success line-height-14 mr-2">
                  <i class="oorwin-tick-mark font-size-8"></i>
              </span>
              <span class="font-size-12">{{item.name}}</span>
              <span *ngIf="item.quantity>1" class="font-size-12 font-medium pl-1">({{item.quantity}} Credits)</span>
              <span *ngIf="item.quantity=='Unlimited'" class="font-size-12 font-medium pl-1">(Unlimited)</span>
          </div> -->
    </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-flat-button mat-dialog-close class="mat-secondary">Cancel</button>
    </mat-dialog-actions>
  </div>
  