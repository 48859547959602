import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { isEmpty } from 'lodash';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-edit-addons-dialog',
  templateUrl: './edit-addons-dialog.component.html',
  styleUrls: ['./edit-addons-dialog.component.scss']
})
export class EditAddonsDialogComponent implements OnInit {

  form: FormGroup; 
  addData:any = {};
  formData: FormData = new FormData();
  IsRequired: boolean = false;
  

  constructor(public dialogRef: MatDialogRef<EditAddonsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private commonService: CommonService,
    private configService: ConfigurationService
    ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      description: [],
      other_information: [],
    })

  }

  ngOnInit() {
    
    if (!isEmpty(this.data)) {

      this.IsRequired = true;

      this.form.patchValue({
        name: this.data.name,
        description: this.data.description,
        other_information: this.data.other_information,
      });
      
    }

  }

  save() {

    if (this.form.valid) {
      
      if (!isEmpty(this.data)) {
        this.addData['id'] = this.data.id;
      }

      this.addData['name'] = this.form.value.name;
      this.addData['description'] = this.form.value.description;
      this.addData['other_information'] = this.form.value.other_information;
      
      this.configService.saveAddon(this.addData).subscribe(res => {
        
        if (res.status === 1) {
          this.dialogRef.close(true);
          this.commonService.openSnackBar('primary', res.message);
        } else {
          if (isEmpty(res.message))
            this.commonService.openSnackBar('warn', res.validations.name);
          else 
            this.commonService.openSnackBar('warn', res.message);
        }
      });
    }

  }

}
