<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p">
    <ul class="nav m-0 p-0">
      <li><a class="text-bold" (click)="getExitRequests('pending')"
          [ngClass]="{'active': getlist == 'pending'}">Pending</a></li>
      <li><a class="text-bold" (click)="getExitRequests('approved')"
          [ngClass]="{'active': getlist == 'approved'}">Approved</a></li>
      <li><a class="text-bold" (click)="getExitRequests('rejected')"
          [ngClass]="{'active': getlist == 'rejected'}">Rejected</a></li>
    </ul>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
        <div class="search-wrapper" fxFlex="30">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Company Name...">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>
        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">

          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="client">
                <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.company_info"> 
                    <a class='cursor-pointer' (click)="navigate(element)">{{element.company_info.name}}</a>
                  </span>
                  <span *ngIf="!element.company_info">-</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef fxFlex="200px"> email </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="200px" class="lowercase"> {{element.client_info.email}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef>
                  <span *ngIf="getlist == 'pending' ">Contact Name</span>
                  <span *ngIf="getlist != 'pending' ">Requested On</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="getlist == 'pending' "> {{element.client_info.first_name}}
                    {{element.client_info.last_name}} </span>
                  <span *ngIf="getlist != 'pending' "> {{element.created_at|date:"MM/dd/yyyy"}} </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="contactnumber">
                <mat-header-cell *matHeaderCellDef>
                  <span *ngIf="getlist == 'pending' "> contact number </span>
                  <span *ngIf="getlist != 'pending' ">subscription end date</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="getlist == 'pending' ">
                    {{element.client_info.contact_number?element.client_info.contact_number:'-'}} </span>
                  <span *ngIf="getlist != 'pending' "> {{element.subscription_data.subscr_end_date |date:"MM/dd/yyyy"}}
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef>
                  <span *ngIf="getlist == 'pending' "> requested on </span>
                  <span *ngIf="getlist == 'approved' ">approved on</span>
                  <span *ngIf="getlist == 'rejected' ">rejected on</span>

                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="getlist == 'pending' "> {{element.created_at|date:"MM/dd/yyyy"}} </span>
                  <span *ngIf="getlist != 'pending' "> {{element.setteld_on |date:"MM/dd/yyyy"}} </span>

                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="subscr_end_date">
                <mat-header-cell *matHeaderCellDef>
                  <span *ngIf="getlist == 'pending' ">subscription end date </span>
                  <span *ngIf="getlist == 'approved' ">approved by</span>
                  <span *ngIf="getlist == 'rejected' ">rejected by</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="getlist == 'pending' "> {{element.subscription_data.subscr_end_date |date:"MM/dd/yyyy"}}
                  </span>
                  <span *ngIf="getlist != 'pending' ">
                    <span *ngIf="element.user_info"> {{element.user_info.first_name}} {{element.user_info.last_name}}
                    </span>
                    <span *ngIf="!element.user_info"> - </span>
                  </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="closing_type">
                <mat-header-cell *matHeaderCellDef>
                  <span *ngIf="getlist == 'pending' "> payment type </span>
                  <span *ngIf="getlist != 'pending' ">Amount($)</span>

                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="getlist == 'pending' "> 
                    <span *ngIf="element.closing_type == 1">Amount Paid</span>
                    <span *ngIf="element.closing_type == 2">Refund To Customer</span>
                    <span *ngIf="element.closing_type == 3">No Due</span>
                  </span>
                  <span *ngIf="getlist != 'pending' ">
                    <span *ngIf="element.amount">{{element.amount}}</span>
                    <span *ngIf="!element.amount">-</span>
                     </span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>
                  <span *ngIf="getlist == 'pending' ">Actions</span>
                  <span *ngIf="getlist != 'pending' ">Comments</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let element;let i = index">

                  <span *ngIf="getlist != 'pending'">
                    <span *ngIf="(element.comments + '').length > 30; else elseBlock">
                      <span *ngIf="!more[i]">{{element.comments | slice:0:30}} <a class="cursor-pointer"
                          (click)="more[i] = !more[i]">More</a></span>
                      <span *ngIf="more[i]">{{element.comments}} <a class="cursor-pointer"
                          (click)="more[i] = !more[i]">Less</a></span>
                    </span>
                    <ng-template #elseBlock>{{element.comments}}</ng-template>
                  </span>

                  <button mat-icon-button class="accent-fg" matTooltip="Approve"
                    *ngIf="getlist == 'pending' && exit_request_permissions['exit_requests.edit']"
                    (click)="approveExitRequest(element.company_id, element.id)">
                    <mat-icon class="s-18">check circle icon</mat-icon>
                  </button>

                  <button mat-icon-button class="red-fg" matTooltip="Reject" (click)="rejectExitRequest(element)"
                    *ngIf="getlist == 'pending' && exit_request_permissions['exit_requests.reject'] ">
                    <mat-icon class="s-18">clear</mat-icon>
                  </button>

                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>