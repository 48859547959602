import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from 'app/services/excel.service';
import { CommonService } from 'app/services/common.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { isEmpty } from 'lodash';
import { EditFieldsComponent } from '../client-all/edit-fields/edit-fields.component';
import { MxrecordsValidationRemainderComponent } from '../mxrecords-validation-remainder/mxrecords-validation-remainder.component';
import { ClientsService } from 'app/services/clients.service';
import { ConfirmExitRequestApproveComponent } from 'app/main/company-details/confirm-exit-request-approve/confirm-exit-request-approve.component';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
@Component({
  selector: 'app-active-customers',
  templateUrl: './active-customers.component.html',
  styleUrls: ['./active-customers.component.scss']
})
export class ActiveCustomersComponent implements OnInit {
  displayedColumns: string[] = ['customername', 'contactname', 'contactemail', 'region', 'onboarded_date','hire', 'hr','crm','monthly_pay','due_payments','subscription_plan','subscription_cycle','sales_user_name','support_user_name','actions'];
  dataSource;
  searchValue = '';
  getlist: any;
  getTableData: any;
  length: any;
  pageSize: any;
  index: any;
  logginUser: any;
  customerType:any = 'active';

  countries = [
    {value:'all', viewValue: 'All'}, { value: '1', viewValue: 'USA ($)' },{ value: '2', viewValue: 'India (₹)' }, { value: '702', viewValue: 'Singapore (S$)'}
  ];
  country_id: any = 'all';

  // ejs datepicker
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  // public lastEnd: Date = this.today;
  public lastEnd: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  // public yearStart = moment().startOf('year');
  public yearEnd: Date = this.today;
  // public value: Date[] = [this.yearStart, this.yearEnd];
  // ejs datepicker completed

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  params: { all_users: number; limit: number; page: number; searchKey?: string; search_filter?: string; type: string; dateRange?: any; status?:string, country_id?:any; sort_column?:any; sort_type?:any };
  getDateRange = {};
  dateRangeForm: FormGroup;
  permissions: any;
  subscr_end: any = {};
  constructor(private commonservice: CommonService,
    private clientService: ClientsService,
    private excelService: ExcelService, private datepipe: DatePipe,
    public dialog: MatDialog,
    public router: Router, private fb: FormBuilder) {

    this.commonservice.currentUser.subscribe(res => {
        if(res) {
          this.logginUser = res;
        }
    });
    
    this.dateRangeForm = this.fb.group({
      daterange: ['']
    });
    
    
    this.dateRangeForm.valueChanges.subscribe(res => {
      if (res) {
        if (res.daterange) {
          this.getDateRange['startDate'] = this.datepipe.transform(res.daterange[0], 'yyyy-MM-dd');
          this.getDateRange['endDate'] = this.datepipe.transform(res.daterange[1], 'yyyy-MM-dd');
          this.params.dateRange = JSON.stringify(this.getDateRange);
        }
        else {
          this.params.dateRange = '';
        }
        this.getActiveUsers('');
      }
    });
  }

  ngOnInit() {
    console.log(this.router.url);
    if (this.router.url.includes('/due_customers')) {
      this.customerType = 'due';
    }
    this.getActiveUsers('all');
    this.commonservice.permissions.subscribe(res => {
      this.permissions = res;
    })
    
  }
  applyFilter(filterValue: string) {
    this.params.searchKey = filterValue;
    this.getActiveUsers('');
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getActiveUsers('');
  }
  navigate(element, blank) {
    if (blank) {
      // this.router.navigate(['company_details', element.company_id]);
      this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
    }
    else {
      this.router.navigate(['company_details', element.company_id]);
    }
  }
  navigatetoImport(element) {
    this.router.navigate(['import', element.company_id])
  }
  getActiveUsers(getnav) {
    switch (getnav) {
      case 'all':
        this.getlist = getnav;
        this.params = { all_users: 2, limit: 15, page: 1, type: 'paid',status:'active'}
        break;
      case 'today':
        this.getlist = getnav;
        this.params = { all_users: 2, limit: 15, page: 1, searchKey: '', search_filter: 'search_filter_today', type: 'paid' }
        break;
      case 'week':
        this.getlist = getnav;
        this.params = { all_users: 2, limit: 15, page: 1, searchKey: '', search_filter: 'search_filter_week', type: 'paid' }
        break;
      case '2days':
        this.getlist = getnav;
        this.params = { all_users: 2, limit: 15, page: 1, searchKey: '', search_filter: 'search_filter_intwodys', type: 'paid' }
        break;
      default:
        // console.log(this.params);
        break;
    }
    // console.log(this.getlist, 'getlist');
    this.params.country_id = this.country_id;
    this.params.status = this.customerType;
    this.clientService.getActiveCustomers(this.params).subscribe(result => {
      if (result.status === 1) {
        this.getTableData = result.data;
        this.length = this.getTableData.total;
        this.pageSize = result.data.per_page;
        this.index = result.data.current_page;
        this.dataSource = new MatTableDataSource(result.data.data);
        if (this.getTableData.total) {
          this.isSubscriptionEnd(this.getTableData.data);
        }
      }
    })
  }

  sortColumnData(column_name, order_type) {
    this.params.sort_column = column_name;
    this.params.sort_type = order_type;
    this.getActiveUsers('');
  }

  exportExcel() {
    this.params.limit = this.length;
    this.clientService.getActiveCustomers(this.params).subscribe(result => {
      if (result.status === 1) {
        let prepared_data = this.prepareExcelData(result.data.data);
        let sheet_name = (this.customerType == 'active')?'Active Customers':'Due Customers';
        this.excelService.exportAsExcelFile(prepared_data, sheet_name);
      }
    })
  }

  isSubscriptionEnd(data) {
    var today = new Date().getTime();
    data.forEach(e => {
      // console.log(e.subscr_end_date);
      var enddate = new Date(e.subscr_end_date);
      var sub_end_date = enddate.getTime();
      var Difference_In_Time = sub_end_date - today;
      var diffInDays = (Difference_In_Time / (1000 * 3600 * 24));
      if(diffInDays < 0) {
        this.subscr_end[e.company_id] = true;
      } else {
        this.subscr_end[e.company_id] = false;
      }
    });
  }
  
  renewRoute(id, type) {
    this.commonservice.subscriptionType = 'renew';
    this.router.navigate(['company_details/'+id+'/'+type]);
  }
  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name'] = element.company_name;
      eachRow['Contact Name'] = element.client_info.first_name+' '+element.client_info.last_name;
      eachRow['Contact Email'] = element.client_info.email;
      eachRow['Region'] = element.region;
      eachRow['Onboarded On'] = '';
      if(!isEmpty(element.onboarded_date) && element.onboarded_date !='-')
      {
        eachRow['Onboarded On'] = this.datepipe.transform(element.onboarded_date, 'MM/dd/yyyy');
      }
      // 
      // eachRow['Hire License Cost ($)'] = element.hire_license_cost;
      eachRow['Hire Users'] = element.hire_users;
      eachRow['Hire License Cost ($)'] = element.hire_users_cost ? element.hire_users_cost : '0';
      eachRow['HR Users'] =  element.hr_users;
      eachRow['HR License Cost ($)'] = element.hr_users_cost ? element.hr_users_cost : '0';
      eachRow['CRM Users'] =  element.crm_users;
      eachRow['CRM License Cost ($)'] = element.crm_users_cost ? element.crm_users_cost : '0';
      eachRow['Mass Mails'] =  element.mass_mails;
      eachRow['Mass Mails Cost ($)'] = element.mass_mails_cost ? element.mass_mails_cost : '0';
      eachRow['Contact Search'] =  element.contact_search;
      eachRow['Contact Search Cost ($)'] = element.contact_search_cost ? element.contact_search_cost : '0';
      eachRow['Other Addons Cost ($)'] = element.other_addons_cost ? element.other_addons_cost : '0';
      // eachRow['CRM License Cost ($)'] = element.crm_license_cost;
      // eachRow['HR License Cost ($)'] = element.hr_license_cost;
      eachRow['Total Paid'] = parseFloat(element.total_cost);
      eachRow['Subscription End On'] = this.datepipe.transform(element.subscr_end_date, 'MM/dd/yyyy');
      // eachRow['Due Payments'] = element.is_due;
      eachRow['Subscription Plan'] = this.subPlan(element.subscription_plan);
      eachRow['Subscription Cycle'] = this.subCycle(element.subscription_cycle);
      eachRow['Sales Rep'] = element.sales_user_name ? element.sales_user_name : '-';
      eachRow['Support Executive'] = element.support_user_name ? element.support_user_name : '-';
      ArrData.push(eachRow);
    });
    // console.log('prepared', ArrData);
    return ArrData;
  }
  refresh() {
    this.searchValue = '';
    this.params = { all_users: 2, limit: 15, page: 1, type: 'paid' }
    this.dateRangeForm.reset();
  }

  subCycle(element) {
    if (element === 1) {
      return 'Monthly';
    }
    else if (element === 3) {
      return 'Quarterly';
    }
    else if (element === 6) {
      return 'Half yearly';
    }
    else if (element === 12) {
      return 'Yearly';
    }
    else {
      return '-'
    }
  }

  subPlan(element) {
    if (element == 'limited') {
      return 'Limited';
    }
    else if (element == 'unlimited_inc_addons') {
      return 'Unlimited (Incl. Add-ons)';
    }
    else if (element == 'unlimited_excl_addons') {
      return 'Unlimited (Excl. Add-ons)';
    }
    else {
      return '-'
    }
  }

  edit(element) {
    const dialogRef = this.dialog.open(EditFieldsComponent, {
      minWidth: '40%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      if (result) {
        this.getActiveUsers('all');
      }
    });
  }

  sendMxRecordsValidationRemainder() {
    let payload = {};
    const dialogRef = this.dialog.open(MxrecordsValidationRemainderComponent, {
      width: '65%',
      data: payload
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  countryChanged() {
    this.params = { all_users: 2, limit: 15, page: 1, type: 'paid', country_id: this.country_id };
    this.getActiveUsers('');
  }


  

viewComment(items)
{
  let record_id = items.company_id;
  let cnt = items.comments_cnt;
  if(cnt)
  {



    let activityData ={
      'company_id':record_id,
      'show_company_name':true,
      'addon_slug':'client_notes',
      'module_slug':'client_note',
      'module_name':'client_note',
}
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });
    

    dialogRef.afterClosed().subscribe(result => {
    });
    

  }

    // alert(record_id);

}

addComment(record_id) {
  
 const data = [];
 data['title'] = 'Comment';
 data['message'] = 'Comment';
 data['successText'] = 'Yes';
 data['closeText'] = 'No';
 const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
   minWidth: '30%',
   minHeight: '40%',
   data: data
 });
 let saveData: any;
 
 dialogRef.afterClosed().subscribe(result => {
   console.log(result);
   if (result.approved) {

     saveData = {
       'id': record_id,
       'status': 'comment',
       'comments' : result.commets
     };
     this.commonservice.saveClientNotes(saveData).subscribe(res => {
       if (res.status == 1) {
         this.commonservice.openSnackBar('primary', res.message);
       } else {
         this.commonservice.openSnackBar('warn', res.message);
       }
       this.getActiveUsers('all');

     });
   } else {

   }
 })
 
}


}

