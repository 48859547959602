import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { NgxPowerBiService } from 'ngx-powerbi';
import { Page } from 'powerbi-client';
import { Report } from 'report';

@Component({
  selector: 'app-powerbi-reports',
  templateUrl: './powerbi-reports.component.html',
  styleUrls: ['./powerbi-reports.component.scss']
})
export class PowerbiReportsComponent implements OnInit {

  private powerBiService: NgxPowerBiService;
  private pbiContainerElement: HTMLElement;
  report_slug: any;

  constructor(private commonservice: CommonService, private route: ActivatedRoute,) {
    this.powerBiService = new NgxPowerBiService();
    this.report_slug = this.route.snapshot.params['report_slug'];
  }

  ngOnInit() {
    this.getBiReport();
  }

  getBiReport() {
    this.commonservice.getPowerBiReport({'report_name':this.report_slug}).subscribe(res => {
      if (res.status == 1) {
        const config = {
          type: 'report',
          tokenType: 1,
          embedUrl: res.data.report.embedUrl,
          accessToken: res.data.report.accessToken
        };
        this.pbiContainerElement = <HTMLElement>(document.getElementById('pbi-container'));
        var report = this.powerBiService.embed(this.pbiContainerElement, config);
      }
    })

    //  report.on('loaded', function(event) {
    //   report.getPages().then(function(pages) {
    //     console.log('report loaded, number of pages: ', pages.length);
    //     // We only want to handle the 'loaded' event once
    //     report.off('loaded');
    //   });
    // });

    // report.off('pageChanged');
    // report.on<{ newPage: Page }>('pageChanged', event => {
    //   console.log('Page changed:', event.detail.newPage.displayName);
    // });

  }

}
