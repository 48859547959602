<h1 mat-dialog-title class='text-uppercase'>{{data ? 'Edit' :'Add'}} User</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>First Name</mat-label>
          <input type="text" formControlName="firstName" matInput>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Last Name</mat-label>
          <input type="text" formControlName="lastName" matInput>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Email</mat-label>
          <input type="email" formControlName="email" matInput>
        </mat-form-field>
      </div>
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Role</mat-label>
          <mat-select formControlName="role">
            <mat-option *ngFor="let item of role" [value]="item.value">
              {{item.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex='48' *ngIf="!data">
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="password">
        </mat-form-field>
      </div>
      <div fxFlex='48' *ngIf="!data">
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Confirm Password</mat-label>
          <input type="password" matInput formControlName="confirmPassword">
        </mat-form-field>
      </div>

      <!-- <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Country Code</mat-label>
          <input type="text" matInput formControlName="extension">
        </mat-form-field>
      </div> -->

      <!-- <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Mobile Number</mat-label>
          <input type="number" matInput formControlName="mobile_number">
        </mat-form-field>
      </div> -->

      <div fxFlex='48'>
        <!-- <label class="text-bold pl-12">Mobile Number</label> -->
                <intl-input-phone placeholder="Mobile Number" appearance="outline" class="mt-12" formControlName="mobile_number"
                [ConfigurationOption]="configOption3" (OnIsRequiredChange)="requiredFlagChange($event)"
                [NumberTextValue]="NumberModel2" [SelectedCountryISOCode]="SelectedCountryISOCode"></intl-input-phone>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Incentive Percentage</mat-label>
          <input type="text" formControlName="incentive_percentage" matInput>
        </mat-form-field>
      </div>


      <div fxFlex='48'>
         <!-- <label class="text-bold pl-12">Incentive Target</label> -->
        <mat-form-field class="w-40-p" appearance='outline'>
          <mat-label>Incentive Target</mat-label>
          <mat-select formControlName="incentive_target_type">
            <mat-option *ngFor="let item of target_types" [value]="item.value">
              {{item.viewValue}}
            </mat-option>
          </mat-select>
          </mat-form-field>
          <mat-form-field class="w-60-p" appearance='outline'>
            <mat-label>Incentive Target $</mat-label>
          <input type="text" formControlName="incentive_target_amount" matInput>


        </mat-form-field>
      </div>

    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-flat-button class='primary' (click)="save()">Save</button>
</mat-dialog-actions>