import { NgModule } from '@angular/core';

import { ConfigurationRoutingModule } from './configuration-routing.module';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { TrialPlanComponent } from './subscriptions/trial-plan/trial-plan.component';
import { AddonComponent } from './subscriptions/addon/addon.component';
import { EditAddonsDialogComponent } from './subscriptions/edit-addons-dialog/edit-addons-dialog.component';
import { CronJobsComponent } from './cron-jobs/cron-jobs.component';
import { CronJobLogActivitiesComponent } from './cron-jobs/cron-job-log-activities/cron-job-log-activities.component';
import { UsersComponent } from './users/users.component';
import { AddUserDialogComponent } from './add-user-dialog/add-user-dialog.component';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { EmailCommunicationComponent } from './email-communication/email-communication.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { BouncedSearchComponent } from './bounced-search/bounced-search.component';
import { MatSearchSelectorModule } from 'app/mat-search-selector/mat-search-selector.module';
import { QueueLogsComponent } from './queue-logs/queue-logs.component';
import { MailTemplatesComponent } from './mail-templates/mail-templates.component';
import { MailTemplatesListComponent } from './mail-templates/mail-templates-list/mail-templates-list.component';
import { MailTemplatesViewComponent } from './mail-templates/mail-templates-view/mail-templates-view.component';
import { MailTemplatesEditComponent } from './mail-templates/mail-templates-edit/mail-templates-edit.component';
import { DefaultSettingsComponent } from './default-settings/default-settings.component';
import { UnsubscribeListComponent } from './unsubscribe-list/unsubscribe-list.component';
import { AddUnsubscribeEmailComponent } from './add-unsubscribe-email/add-unsubscribe-email.component';
import { PerformaInvoiceComponent } from './performa-invoice/performa-invoice.component';


@NgModule({
  declarations: [
    SubscriptionsComponent,
    TrialPlanComponent,
    AddonComponent,
    EditAddonsDialogComponent,
    CronJobsComponent,
    CronJobLogActivitiesComponent,
    UsersComponent,
    AddUserDialogComponent,
    EmailCommunicationComponent,
    BouncedSearchComponent,
    QueueLogsComponent,
    MailTemplatesComponent,
    MailTemplatesListComponent,
    MailTemplatesEditComponent,
    MailTemplatesViewComponent,
    DefaultSettingsComponent,
    UnsubscribeListComponent,
    AddUnsubscribeEmailComponent,
    PerformaInvoiceComponent
  ],
  imports: [
    ConfigurationRoutingModule,
    FuseSharedModule,
    IntlInputPhoneModule,
    CKEditorModule,
    MatSearchSelectorModule
  ]
})
export class ConfigurationModule { }
