<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-header">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold text-capitalize" [routerLink]="['/dashboard']"
          routerLinkActive="router-link-active">Dashboard</a></li>
      <li><a class="text-bold text-capitalize active">Clients - Pass Due Date</a></li>
    </ul>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-20 mb-52">
      <div class="content" fusePerfectScrollbar>
        <app-top-cards></app-top-cards>
        <ng-container *ngIf="dataSource">
          <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12">
            <div class="search-wrapper" fxFlex="30">
              <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
                [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Company Name, Contact Name">
              <mat-icon class="search_icon">search</mat-icon>
              <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
                close
              </mat-icon>
            </div>
            <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
              <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
                [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
            </div>
          </div>
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">
              <!-- customername Column -->
              <ng-container matColumnDef="customername">
                <mat-header-cell *matHeaderCellDef> customer name </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.name}}
                </mat-cell>
              </ng-container>

              <!-- subdomainname Column -->
              <ng-container matColumnDef="subdomainname">
                <mat-header-cell *matHeaderCellDef> subdomain name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.sub_domain_name}} </mat-cell>
              </ng-container>

              <!-- 	region Column -->
              <ng-container matColumnDef="region">
                <mat-header-cell *matHeaderCellDef>region </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.region }}
                </mat-cell>
              </ng-container>
              <!-- 	status Column -->
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.status === 1 ? 'Active' : 'Inactive'}}
                </mat-cell>
              </ng-container>
              <!-- 	subsscriptionenddate Column -->
              <ng-container matColumnDef="subscriptionenddate">
                <mat-header-cell *matHeaderCellDef>subscription end date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.subscr_end_date |date:"MM/dd/yyyy"}}
                </mat-cell>
              </ng-container>

              <!-- 	actions Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>actions</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button mat-icon-button class="accent-fg" matTooltip="View" (click)="navigate(element,'')">
                    <mat-icon class="s-18">remove_red_eye</mat-icon>
                  </button>
                  <button mat-icon-button class="red-fg" matTooltip="" (click)="delete(element.id)">
                    <mat-icon class="s-18">delete</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>