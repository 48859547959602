import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import masterdata from 'app/services/masterdata';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-detail-user-edit-dialog',
  templateUrl: './detail-user-edit-dialog.component.html',
  styleUrls: ['./detail-user-edit-dialog.component.scss']
})
export class DetailUserEditDialogComponent implements OnInit {
  checked: boolean = false;
  addUserForm: FormGroup;
  masterData = masterdata;
  countries: { "id": number; "name": string; }[];
  states: { "id": number; "country_id": number; "code": string; "name": string; "standard_timezone": string; "timezone": string; }[];
  hire_users_list: any;
  hr_users_list: any;
  crm_users_list: any;
  company_id: any;

  constructor(private fb: FormBuilder,
    private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    public dialogRef: MatDialogRef<DetailUserEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {
    this.addUserForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      contact_number: ['', Validators.required],
      country_id: ['', Validators.required],
      state_id: ['', Validators.required],
      city: [''],
      office_number: [''],
      is_super_admin: [''],
      hire_role_id: [''],
      hr_role_id: [''],
      crm_role_id: [''],

    })
  }

  ngOnInit() {
    // console.log(this.masterData);
    this.countries = this.masterData.countriesList;
    this.hire_users_list = this.data.hire_roles;
    this.hr_users_list = this.data.hr_roles;
    this.crm_users_list = this.data.crm_roles;
    this.company_id = this.data.company_id;
  }
  changeCountry(country) {
    this.states = this.masterData.statesList.filter(state => state.country_id === country);
  }
  check(e) {
    this.checked = e.checked;
    console.log(e.checked);
    if (e.checked) {
      this.addUserForm.patchValue({ hr_role_id: '', hire_role_id: '', crm_role_id: '' });
    }
  }

  saveUser() {

    if (this.addUserForm.valid) {
      if (!this.addUserForm.value.is_super_admin) {
        if (!this.addUserForm.value.hire_role_id && !this.addUserForm.value.hr_role_id && !this.addUserForm.value.crm_role_id) {
          this.commonService.openSnackBar('warn', 'Atleast Select Role');
        }
      }
      let sendData = {};
      sendData['email'] = this.addUserForm.value.email;
      sendData['company_id'] = this.company_id;
      this.companyDetailService.checkUserInPartnerCompany(sendData).subscribe(res => {
        if (res.status == 1 && !res.is_account_exist) {
          this.saveUserData();
        } else if (res.status == 1 && res.is_account_exist) {
          this.createNewUser();
        }
      });

    }
  }

  saveUserData() {
    let saveData = {};
    let roles = {};
    if (this.addUserForm.value.hire_role_id) {
      roles['hire'] = this.addUserForm.value.hire_role_id;
    }
    if (this.addUserForm.value.hr_role_id) {
      roles['hr'] = this.addUserForm.value.hr_role_id;
    }
    if (this.addUserForm.value.crm_role_id) {
      roles['crm'] = this.addUserForm.value.crm_role_id;
    }

    saveData = this.addUserForm.value;
    saveData['company_id'] = this.company_id;
    saveData['save_action'] = 'register';
    saveData['user_hiring_type'] = 0;
    saveData['roles'] = roles;
    if (this.addUserForm.value.is_super_admin) {
      roles['is_super_admin'] = 1;
    }
    console.log('send', saveData);
    this.companyDetailService.storeCompanyUser(saveData).subscribe(res => {
      if (res.status == 1) {

      }

    });

  }

  createNewUser() {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to create user';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });
    let saveData: any;
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.commonService.getLoader();

      } else {

      }
    })
  }
}
