<div class="page-layout carded fullwidth inner-scroll" *ngIf="dataSource">
  <mat-toolbar-row class="secondary-headers w-100-p">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold active">Users</a></li>
    </ul>
  </mat-toolbar-row>

  <div class="center px-20">
    <div class="content-card mt-12 mb-56">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
        <div class="search-wrapper" fxFlex="30">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Search...">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>
        <div fxFlex="100" class="actions_list">
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50, 100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="limit" [pageIndex]="currentPage-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">
              
              <!-- <ng-container matColumnDef="sno">
                <mat-header-cell *matHeaderCellDef fxFlex="100px"> S.no </mat-header-cell>
                <mat-cell *matCellDef="let element;index as i" fxFlex="100px">
                  {{i + 1}}
                </mat-cell>
              </ng-container> -->
             
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.first_name}} {{element.last_name}} </mat-cell>
              </ng-container>

              <!-- email Column -->
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef > Email </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.email}} </mat-cell>
              </ng-container>

               <!-- email Column -->
               <ng-container matColumnDef="mobile_number">
                <mat-header-cell *matHeaderCellDef > Mobile Number </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.mobile_ext}} {{element.mobile}} </mat-cell>
              </ng-container>

              <!-- role Column -->
              <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.cpanle_role.name}} </mat-cell>
              </ng-container>

              <!-- Incentive Percentage Column -->
              <ng-container matColumnDef="incentive_percentage">
                <mat-header-cell *matHeaderCellDef> Incentive Percentage </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.incentive_percentage}} </mat-cell>
              </ng-container>

              <!-- Incentive Target Column -->
              <ng-container matColumnDef="incentive_target_amount">
                <mat-header-cell *matHeaderCellDef> Incentive Target ($) </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.incentive_target_amount}}  {{target_types[element.incentive_target_type] ? '/'+target_types[element.incentive_target_type]: '' }} </mat-cell>
              </ng-container>


              

              <!-- action Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef fxFlex='150px'> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="164px">
                  <button mat-icon-button class="accent-fg" matTooltip="Edit" (click)="edit(element)">
                    <mat-icon class="s-18">edit</mat-icon>
                  </button>
                  <button mat-icon-button
                    [ngClass]="{'orange-fg': element.status === 1 , 'primary-fg':element.status === 0}"
                    [matTooltip] = "element.status === 1 ? 'Inactive' : 'Active'" (click)="updateStatus(element)">
                    <mat-icon class="s-18">{{element.status === 1 ? 'voice_over_off' : 'person'}}</mat-icon>
                  </button>
                  <button mat-icon-button class="red-fg" matTooltip="Delete" (click)="deleteUser(element.id)" *ngIf="currentUser.id != element.id" >
                    <mat-icon class="s-18">delete</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>
        <div class="footer">
          <button mat-button (click)="addUser()">Add</button>
          <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
          <button mat-button (click)="showActivities()">Activities</button>
        </div>
      </div>
    </div>
  </div>
</div>