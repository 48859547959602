import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { ConfirmExitRequestApproveComponent } from '../confirm-exit-request-approve/confirm-exit-request-approve.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-user-licences-dialog',
  templateUrl: './user-licences-dialog.component.html',
  styleUrls: ['./user-licences-dialog.component.scss']
})
export class UserLicencesDialogComponent implements OnInit {

  per_user_license_cost: any;
  show_title: any;
  AddLicense: FormGroup;
  calTotalAmount: any;
  saveData: any;
  subscription_type: any;
  savepromise: Promise<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    public companyDetailService: CompanyDetailsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UserLicencesDialogComponent>,
    public dialog: MatDialog
  ) {
    this.AddLicense = this.fb.group({
      'total_licenses': ['', Validators.required],
      // 'charge_type': []
    });
    this.subscription_type = this.data.company.subscription_type;
  }

  ngOnInit() {
    // this.per_user_license_cost = this.data.per_license_cost;
    this.show_title = this.data.license_title;
  }

  // calCost() {
  //   this.calTotalAmount = (this.AddLicense.value.total_licenses * this.per_user_license_cost).toFixed(2);
  // }

  updateLicenses() {
    if (this.AddLicense.valid) {
      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? you want to give free licenses';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
        width: '35%',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.approved) {
          let defaultCharge: any = 0;
          // if (this.AddLicense.value.charge_type) {
          //   defaultCharge = this.AddLicense.value.charge_type;
          // }

          let licenses_data: any;
            licenses_data = {
              'available': this.AddLicense.value.total_licenses,
              // 'charge_type': defaultCharge,
              // 'cost': this.calTotalAmount,
              'is_subscribed': 1
            };
          this.saveData = {
            'company_id': this.data.company_id,
            'subscription_id': this.data.subscription_id,
            'company': this.data.company,
            'data': licenses_data,
            'addon_id': this.data.addon_id,
            'addon_slug': this.data.addonSlug,
            'addon_operation_id': 0,
            'is_unlimited': 0,
            'company_name': this.data.company_name,
            'comments': result.commets,
            'plan_slug': this.data.planSlug,
            'price_type': this.data.price_type
          };
          // console.log(this.saveData);return;
          this.savepromise = this.companyDetailService.saveAddonsAsFree(this.saveData).toPromise();
          this.savepromise.then(res => {
            if (res.status == 1) {
              this.commonService.openSnackBar('primary', res.message);
            } else {
              this.commonService.openSnackBar('warn', res.message);
            }
            this.dialogRef.close(1);
          });
        } else { }
      })
    }
  }

}
