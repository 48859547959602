import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-trail-suscriptionend-date-dialog',
  templateUrl: './trail-suscriptionend-date-dialog.component.html',
  styleUrls: ['./trail-suscriptionend-date-dialog.component.scss']
})
export class TrailSuscriptionendDateDialogComponent implements OnInit {

  constructor(
    public fb: FormBuilder,
    private commonservice: CommonService,
    public dialogRef: MatDialogRef<TrailSuscriptionendDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any  
  ) { 
    this.extendTrail = this.fb.group({
      'subscr_end_date' : ['', Validators.required]
    });
  }

  extendTrail: FormGroup;
  showLoader: boolean;

  ngOnInit() {
    console.log('enddate',this.data.subscr_end_date);
    this.extendTrail.patchValue({
      subscr_end_date:moment(this.data.subscr_end_date)
    });
  }

  extendDate(){
    if(this.extendTrail.valid){
      let extended_date:any;
      let saveData:any;
      extended_date =  moment(this.extendTrail.value.subscr_end_date).format("YYYY-MM-DD");
      saveData = {
        'company' : this.data,
        'column' : 'end_date',
        'extended_date':extended_date,
        'comments': ''
      };
      this.showLoader = true;
      this.commonservice.udpateCompanyInfo(saveData).subscribe(res =>{
        if(res.status == 1) {
          this.commonservice.openSnackBar('primary', res.message);
        }else{
          this.commonservice.openSnackBar('warn', res.message);
        }
        this.showLoader = false;
        this.dialogRef.close(1);
      });

    }
  }

}
