<h1 mat-dialog-title class='text-uppercase'>Add Refund</h1>
<mat-divider></mat-divider>
<form [formGroup]="addRefund" (ngSubmit)="saveRefund()">
<mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
      <div fxFlex='100'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Amount({{data.currency}})</mat-label>
          <input type="number" formControlName="amount" matInput (change)="amountChange()">
          <mat-error *ngIf="addRefund.controls.amount.invalid && moreAmount">Entered amount is not greater than invoice amount</mat-error>
          <mat-error *ngIf="addRefund.controls.amount.invalid && !moreAmount">Please enter valid amount</mat-error>
        </mat-form-field>
      </div>

      <div fxFlex='100' *ngIf="data.record.used_credit_balance_amount>0 && data.record.payment_mode =='card'">
        <div class="text-bold font-size-14">Total: {{data.currency}} {{data.record.amount}}</div>
        <div class="text-bold font-size-14">Stripe Credit Balance: (-){{data.currency}} {{data.record.used_credit_balance_amount}}</div>
        <div class="text-bold font-size-14">Paid Amount: {{data.currency}} {{(data.record.amount - data.record.used_credit_balance_amount).toFixed(2)}}</div>
        <div class="warn-fg">Note: Refund amount to customer account is {{data.currency}} {{(data.record.amount - data.record.used_credit_balance_amount).toFixed(2)}}</div>
      </div>

      <div fxFlex='100'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Reason</mat-label>
          <textarea matInput rows="6" formControlName="reason"></textarea>
          <mat-error *ngIf="addRefund.controls.reason.invalid">Add reason for refund</mat-error>
        </mat-form-field>
      </div>
      
      <div fxFlex='100'>
        <mat-label>Deposit Amount : </mat-label>
        <mat-radio-group aria-label="Select an option" formControlName="refund_type" (change)="refundAccountChange()">
          <mat-radio-button color="primary" class="mr-8 sub-heading" value="1" *ngIf="data.record.payment_gateway_id == '2' && data.record.payment_mode =='card'">To Customer Account</mat-radio-button>
          <mat-radio-button color="primary" value="0" class="sub-heading" [checked]="true">To Customer Wallet</mat-radio-button>
          <!-- <mat-error *ngIf="addRefund.controls.refund_type.invalid">Please select deposit amount</mat-error> -->
        </mat-radio-group>
      </div>

    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
    <button mat-flat-button class='primary'>Save</button>
  </mat-dialog-actions>
</form>