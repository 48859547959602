<div class="mat-tab-header">
  <input matInput autocomplete="off" class="mat-input-element" [formControl]="_searchBox" [placeholder]="placeholder"
    #searchBox />
  <button mat-button *ngIf="!!(changes$ | async)" mat-icon-button class="button-clear" aria-label="Clear"
    (click)="clear()">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<mat-checkbox color="primary" #allSelected class="mat-option" *ngIf="isMultiple && !noRecords"
  (change)="selectAllOption($event)">
  {{allSelected.checked ? 'UnSelect' :'Select'}} All</mat-checkbox>
<div class="p-8" *ngIf="noRecords">
  <span class="m-12">No Entries Found...</span>
</div>