<h1 mat-dialog-title class='text-uppercase text-center' *ngIf="data.title"> EDIT {{data.title}} </h1>
<mat-divider></mat-divider>
<form [formGroup]="updateInfoForm">
  <mat-dialog-content class="text-center line-height-32">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
        <div fxFlex>
            {{data.title}}
        </div>
        <div fxFlex='70'>
            <mat-form-field appearance="outline" class="w-100-p">
                <input type="text" matInput formControlName="fieldData" >
                <!-- <mat-error *ngIf="gstform.get('gstnumber').hasError('pattern')">Not a valid GST Number</mat-error> -->
            </mat-form-field>
        </div> 
    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider> 
  <mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button class='primary' (click)="saveInfo()">{{data.successText}}</button>
  </mat-dialog-actions>
</form>