import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { CompanyDetailsService } from 'app/services/company-details.service';
import masterdata from 'app/services/masterdata';
import { isEmpty, partialRight } from 'lodash';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";

import { CustomConfirmDialogComponent } from '../../custom-confirm-dialog/custom-confirm-dialog.component';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent implements OnInit {
  defaultApps = { "hire": "ATS", "hr": "HR", "crm": "CRM" };
  appLicenseSlugs = { "hire": "hire_users", "hr": "hr_users", "crm": "crm_users" };
  licenseAppSlugs = { "hire_users": "hire", "hr_users": "hr", "crm_users": "crm" };
  paymentmodes = [
    { name: 'Generate Invoice', value: 'invoice_url', isActive: 1, slug: 'invoice_url', isDisable: false, isSelected: 0 },
    { name: 'Stripe Credit Balance', value: 'invoice_url', isActive: 1, slug: 'stripe_balance', isDisable: false, isSelected: 0 },
  ];

  subscription_cycle: any = 1;
  enabledApps: any;
  selectedPlan: string;

  isEmpty = isEmpty;
  subscriptionForm: FormGroup;
  @ViewChild('subscribeForm') subscribeForm: FormGroupDirective;

  cart: any = {};

  company_id: any;
  subscription_type: string;
  masterData = masterdata;
  countries: { id: number; name: string; }[];
  params: { company_id: number; }
  plans: any;
  currentPackage: any;
  licensesCostArr: any;
  companyDetails: any;
  country_id: any;
  minLicenses: any;
  singlePlanPreLicenses: any;
  is_gstin: any;
  license_type_items: any;
  billing_address: any;
  tax_name: string;
  tds_title: string;
  payment: boolean = false;
  billingCycles: any;
  enabledPlans: any;
  isZeroPaymentInvoice: boolean;
  total_amount_before_discount: any = 0;
  wallet_amount: any;
  checkoutItems: any[] = [];
  cart_total_amount: number = 0;
  taxData: any;
  tax_amount: any;
  tds_amount: any;
  is_future_configuration: boolean = false;
  isLinear = true;
  turnOffPaymentMode: boolean = false;
  states: { id: number; country_id: number; code: string; name: string; standard_timezone: string; timezone: string; }[];
  accountBalance: any;
  user_email: any;
  isAccountBalPayment: boolean;
  payment_mode: any;
  selected: any;
  billing: any = {};
  subscribedPlan: any;
  licensesProrateArr: any;
  addonsProrateArr: any;
  planSubscribedItems: any;
  selectedApps: any;
  used_wallet_amount: any;
  card_details: any;
  default_card: any;
  cardBillingAddressArr:any;
  lineItemsData: any;
  chk_tds_amount:any;

  constructor(
    public commonservice: CommonService,
    public companyDetailService: CompanyDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private HttpClient: HttpClient
  ) {
    this.company_id = this.route.parent.snapshot.params['id'];
    this.subscription_type = this.route.snapshot.params['subscriptionType'];
    this.chk_tds_amount=false;

    this.subscriptionForm = this.fb.group({
      billing_street: ['', Validators.required],
      billing_city: ['', Validators.required],
      billing_country: ['', Validators.required],
      billing_state: ['', Validators.required],
      billing_zip: ['', Validators.required],
      selected_card: [''],
    });

    this.subscriptionForm.get('selected_card')?.valueChanges.subscribe(res => {
      if (res) {
        this.showInvoiceUrlPayment();
        if(this.cardBillingAddressArr.hasOwnProperty(res)) {
          this.appendBillingAddress(this.cardBillingAddressArr[res]);
        } else {
          this.appendBillingAddress(this.billing_address);
        }
            
      }
    })
  }

  ngOnInit(): void {
    this.params = { company_id: Number(this.company_id) }
    this.countries = this.masterData.countriesList;
    this.setPlanUrl();
  }

  setPlanUrl() {
    let planUrl;
    switch (this.subscription_type) {
      case 'upgrade':
        planUrl = this.companyDetailService.getPlanDetailsUpgrade(this.params);
        break;
      case 'top_up':
        planUrl = this.companyDetailService.getTopupPlanDetails(this.params);
        break;
      case 'renew':
        planUrl = this.companyDetailService.getRenewPlanDetails(this.params);
        break;
      case 'upgrade_subscription':
        planUrl = this.companyDetailService.getUpgradeSubscriptionDetails(this.params);
        break;
      default:
        this.commonservice.openSnackBar('warn', 'Something went Wrong');
        this.router.navigate(['company_details/' + this.company_id + '/billingdetails']);
        break;
    }

    if (planUrl) {
      this.getPlanDetails(planUrl);
    }
  }

  getPlanDetails(planUrl: Observable<any>) {
    planUrl.subscribe(res => {
      if (res.status == 1) {
        this.plans = res.data.plans;
        this.enabledApps = res.data.enabledApps;
        this.selectedApps = { ...this.enabledApps };
        this.enabledPlans = res.data.enabledPlans;
        this.currentPackage = res.data.enabledApps[0];
        this.licensesCostArr = res.data.licensesCostArr;
        this.companyDetails = res.data.companyRecord;
        this.wallet_amount = this.companyDetails.wallet_amount;
        this.country_id = this.companyDetails.country_id;
        this.minLicenses = res.data.minLicenses;
        this.singlePlanPreLicenses = res.data.singlePlanPreLicenses;
        this.is_gstin = res.data.is_gstin;
        this.license_type_items = res.data.license_type_items;
        this.billing_address = res.data.billing_address;
        this.billingCycles = res.data.billingCycles;
        this.tax_name = this.getTAXname(this.country_id);
        this.tds_title = this.getTAXname(this.country_id, 'tds');

        this.subscription_cycle = res.data.subscriptionCycle ?? 1;
        //for topup
        this.subscribedPlan = res.data?.subscribedPlan;
        this.licensesProrateArr = res.data?.licensesProrateArr;
        this.addonsProrateArr = res.data?.addonsProrateArr;
        //for renewal
        this.planSubscribedItems = res.data?.planSubscribedItems;
        if (!isEmpty(res.data?.futureConfigPlan)) {
          this.subscribedPlan = res.data.futureConfigPlan;
        }

        this.lineItemsData = res.data?.lineItemsData;

        if ((this.subscription_type == 'top_up' || this.subscription_type == 'renew' || this.subscription_type == 'upgrade_subscription') && this.subscribedPlan) {
          this.choosePlan(this.subscribedPlan);
        }

        if (this.subscription_type == 'upgrade_subscription') {
          this.card_details = res.data?.cc_details;
          this.cardBillingAddressArr = res.data?.cardBillingAddressArr;
          this.default_card = res.data?.default_card?.toString();
          this.subscriptionForm.get('selected_card')?.setValue(this.default_card);
          if(this.cardBillingAddressArr.hasOwnProperty(res.data?.default_card))
            this.appendBillingAddress(this.cardBillingAddressArr[res.data.default_card]);
        }
      } else {
        this.commonservice.openSnackBar('warn', res.data.message);
        this.router.navigate(['company_details/' + this.company_id + '/billingdetails']);
      }
    });
  }

  getTAXname(country_id, type = 'gst') {
    if (country_id == 2) {
      if (type == 'gst') {
        return 'GST of 18%';
      }

      if (type == 'tds') {
        return 'TDS of 10%';
      }
    }
  }

  unsorted() { }

  getCurrencySymbol(country_id) {
    return country_id == 2 ? '₹' : '$';
  }

  changeCountry(country: number) {
    this.states = this.masterData.statesList.filter(state => state.country_id === country);
  }

  changeBundleCartBillingCycle(billing_cycle) {
    this.subscription_cycle = billing_cycle;
    this.updateCart();
  }

  changeAppSelection(app) {
    if (isEmpty(this.selectedPlan)) {
      return;
    }

    if (!this.selectedApps[app]) {
      delete this.cart[this.selectedPlan][this.appLicenseSlugs[app]];

      this.removePackageAddonsFromCart(this.selectedPlan, app);
      // for (const addon in this.cart[this.selectedPlan]) {
      //   if (Object.prototype.hasOwnProperty.call(this.cart[this.selectedPlan], addon)) {
      //     console.log(this.cart[this.selectedPlan][addon]);
      //     let addon_package = this.cart[this.selectedPlan][addon]?.['package'];
      //     //removing addons from cart when unchecked package 
      //     if (addon_package == app || !this.checkEnableApp()) {
      //       this.cart[this.selectedPlan][addon] = {};
      //     }
      //   }
      // }
      this.cartSubTotalAmount();
    }

    if (this.selectedApps[app]) {
      let license_slug = this.plans[this.selectedPlan][app]['user_license_slug'];
      this.cart[this.selectedPlan][license_slug] = { quantity: this.singlePlanPreLicenses[this.selectedPlan][license_slug] };
      this.addLicenesToCart(this.plans[this.selectedPlan][app]['user_license'], app);
    }

    if (!(Object.values(this.selectedApps).some(val => val))) {
      this.commonservice.openSnackBar('warn', 'Please select at least one App');
      return;
    }
  }

  checkEnableApp() {
    return Object.values(this.selectedApps).some(val => val);
  }

  choosePlan(plan_slug) {
    if (this.selectedPlan == plan_slug) {
      return;
    }

    this.selectedPlan = plan_slug;
    this.cart = {};
    this.cart[plan_slug] = {};
    for (const app in this.selectedApps) {
      if (Object.prototype.hasOwnProperty.call(this.selectedApps, app)) {
        const isEnabledApp = this.selectedApps[app];
        if (isEnabledApp) {
          let license_slug = this.plans[plan_slug][app]['user_license_slug'];
          this.cart[plan_slug][license_slug] = { quantity: this.singlePlanPreLicenses[plan_slug][license_slug] };
          this.addLicenesToCart(this.plans[plan_slug][app]['user_license'], app);
        }
      }
    }
  }

  addLicenesToCart(user_license, app) {
    let plan_slug = user_license.sb_plan_slug;
    let addon_slug = user_license.addon.slug;

    if (this.minLicenses[plan_slug][addon_slug] > this.cart[plan_slug][addon_slug].quantity) {
      console.log(this.minLicenses[plan_slug][addon_slug]);
      console.log('@@@@@@@@@@@@@@@@@@@@@@@');
      console.log(this.cart[plan_slug][addon_slug].quantity);
      console.log(addon_slug);
      // return;
    }

    if (this.singlePlanPreLicenses[plan_slug][addon_slug] != this.cart[plan_slug][addon_slug].quantity) {
      this.singlePlanPreLicenses[plan_slug][addon_slug] = this.cart[plan_slug][addon_slug].quantity;
    }

    if (this.cart[plan_slug][addon_slug].quantity > 0) {
      this.cart[plan_slug][addon_slug].is_added = 1;
      this.cart[plan_slug][addon_slug].name = user_license.addon.name;
      this.cart[plan_slug][addon_slug].slug = addon_slug;
      this.cart[plan_slug][addon_slug].package = app;
      this.cart[plan_slug][addon_slug].sb_plan_slug = plan_slug;
      this.cart[plan_slug][addon_slug].credits_type = user_license.credits_type;
      this.cart[plan_slug][addon_slug].price_type = user_license.price_type;
      this.cart[plan_slug][addon_slug].slab_title = '';
      this.cart[plan_slug][addon_slug].slab_index = '';
      this.cart[plan_slug][addon_slug].slab_price = '';
      this.cart[plan_slug][addon_slug].billing_type = user_license.billing_type;
      this.cart[plan_slug][addon_slug].is_auto_renew = 0;

      if (this.subscription_type == 'top_up') {
        this.cart[plan_slug][addon_slug].price = parseFloat(this.licensesProrateArr[this.subscription_cycle][plan_slug][addon_slug]);
      } else {
        this.cart[plan_slug][addon_slug].price = parseFloat(this.licensesCostArr[this.subscription_cycle][plan_slug][addon_slug]);
      }

      var cartItem = this.cart[plan_slug][addon_slug];

      if (this.subscription_type == 'top_up') {
        this.cart[plan_slug][addon_slug]['sub_total'] = (parseFloat(cartItem.quantity) * parseFloat(this.licensesProrateArr[this.subscription_cycle][plan_slug][addon_slug]));
      } else {
        this.cart[plan_slug][addon_slug]['sub_total'] = (parseFloat(cartItem.quantity) * parseFloat(this.licensesCostArr[this.subscription_cycle][plan_slug][addon_slug])) * parseFloat(this.subscription_cycle);
      }
    }

    if (this.checkEmptyNumbers(this.cart[plan_slug][addon_slug].quantity)) {
      this.cart[plan_slug][addon_slug].is_added = 0;
    }

    //add addons to ng model for something...
    this.plans[plan_slug][app]['paid_addons']?.forEach(addonItem => {
      if (this.cart[addonItem.sb_plan_slug][addonItem.addon.slug] == undefined) {
        this.cart[addonItem.sb_plan_slug][addonItem.addon.slug] = {};
        if ((this.subscription_type == 'renew' || this.subscription_type == 'upgrade_subscription') && Object.prototype.hasOwnProperty.call(this.planSubscribedItems[addonItem.sb_plan_slug] ?? {}, addonItem.addon.slug)) {
          this.cart[addonItem.sb_plan_slug][addonItem.addon.slug] = this.planSubscribedItems[addonItem.sb_plan_slug][addonItem.addon.slug];
        }
      }
    });

    this.plans[plan_slug]['global']['paid_addons']?.forEach(addonItem => {
      if (this.cart[addonItem.sb_plan_slug][addonItem.addon.slug] == undefined) {
        this.cart[addonItem.sb_plan_slug][addonItem.addon.slug] = {};
        if ((this.subscription_type == 'renew' || this.subscription_type == 'upgrade_subscription') && Object.prototype.hasOwnProperty.call(this.planSubscribedItems[addonItem.sb_plan_slug] ?? {}, addonItem.addon.slug)) {
          this.cart[addonItem.sb_plan_slug][addonItem.addon.slug] = this.planSubscribedItems[addonItem.sb_plan_slug][addonItem.addon.slug];
        }
      }
    });
    //calling for cart amount
    this.cartSubTotalAmount();
  }


  addItemToCart(item, addon_package, priceType = null) {
    let plan_slug = item.sb_plan_slug;
    let addon_slug = item.addon.slug;

    if (this.cart[plan_slug][addon_slug]['is_added'] == 0) {
      // delete this.cart[plan_slug][addon_slug];
      this.cart[plan_slug][addon_slug] = {};
      this.cartSubTotalAmount();
      return false;
    }

    if (priceType == 'slab' || priceType == 'slab_flat_fee') {
      this.cart[plan_slug][addon_slug]['price_type'] = item.price_type;
      return false;
    }

    if ((item.price_type == 'slab' || item.price_type == 'slab_flat_fee') && this.checkEmptyNumbers(this.cart[plan_slug][addon_slug]['slab_index'])) {
      if (this.cart[plan_slug][addon_slug]['is_added']) {
        // delete this.cart[plan_slug][addon_slug];
        this.cart[plan_slug][addon_slug] = {};
        this.cartSubTotalAmount();
      }
      return false;
    }

    if ((item.price_type == 'slab' || item.price_type == 'slab_flat_fee') && !this.checkEmptyNumbers(this.cart[plan_slug][addon_slug]['slab_index'])) {
      var slabInfo = item['other_details']['slab_data'][this.cart[plan_slug][addon_slug]['slab_index']];
      this.cart[plan_slug][addon_slug]['slab_price'] = slabInfo.price;
      this.cart[plan_slug][addon_slug]['slab_title'] = slabInfo.name;
    }

    this.cart[plan_slug][addon_slug]['name'] = item.addon.name;
    this.cart[plan_slug][addon_slug]['slug'] = addon_slug;
    this.cart[plan_slug][addon_slug]['package'] = addon_package;

    this.cart[plan_slug][addon_slug]['credits_type'] = item.credits_type;
    this.cart[plan_slug][addon_slug]['price_type'] = item.price_type;
    // this.cart[plan_slug][addon_slug]['slab_title'] = item.slab_title;
    this.cart[plan_slug][addon_slug]['quantity'] = item.quantity;
    this.cart[plan_slug][addon_slug]['sb_plan_slug'] = plan_slug;
    this.cart[plan_slug][addon_slug]['billing_type'] = item.billing_type;
    this.cart[plan_slug][addon_slug]['is_auto_renew'] = this.cart[plan_slug][addon_slug]['is_auto_renew'] ?? 0;
    this.cart[plan_slug][addon_slug]['price'] = parseFloat(item.price);

    switch (item.price_type) {
      case 'slab':
        var slabItem = { ...this.cart[plan_slug][addon_slug] };
        if (this.checkEmptyNumbers(slabItem.units) || slabItem.units < 1)
          slabItem.units = 1;

        this.cart[plan_slug][addon_slug]['units'] = slabItem.units;
        this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(slabItem.slab_price) * parseFloat(slabItem.units);
        break;
      case 'per_unit':
        this.cart[plan_slug][addon_slug]['sub_total'] = (parseFloat(this.cart[plan_slug][addon_slug].quantity) * parseFloat(item.price)) * parseFloat(this.subscription_cycle);
        break;
      case 'flat_fee_per_unit':
        var slabItem = { ...this.cart[plan_slug][addon_slug] };
        if (this.checkEmptyNumbers(slabItem.units) || slabItem.units < 1)
          slabItem.units = 1;

        this.cart[plan_slug][addon_slug].quantity = slabItem.units;
        this.cart[plan_slug][addon_slug]['sub_total'] = (parseFloat(slabItem.units) * parseFloat(item.price)) * parseFloat(this.subscription_cycle);
        break;
      case 'flat_fee':
        this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(item.price) * parseFloat(this.subscription_cycle);
        break;
      case 'slab_flat_fee':
        var selected_type = this.cart[plan_slug][addon_slug].slab_index;
        this.cart[plan_slug][addon_slug]['units'] = 1;

        if (selected_type == 1)
          this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(this.cart[plan_slug][addon_slug]['slab_price']) * parseFloat(this.subscription_cycle);
        else if (selected_type == 2)
          this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(this.cart[plan_slug][addon_slug]['slab_price']);
        break;
      case 'flat_fee_pro_rate':
        if (this.subscription_type == 'top_up') {
          this.cart[item.sb_plan_slug][addon_slug].price = parseFloat(this.addonsProrateArr[plan_slug][addon_slug]);
          this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(this.addonsProrateArr[plan_slug][addon_slug]);
        } else {
          this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(item.price) * parseFloat(this.subscription_cycle);
        }
        break;

      default:
        break;
    }

    this.cartSubTotalAmount();
  }

  checkEmptyNumbers(numberV) {
    if (numberV == '' || numberV == undefined || numberV == null) {
      return true;
    }
    return false;
  }

  updateCart() {
    if (isEmpty(this.cart)) {
      return;
    }

    let plan_slug = this.selectedPlan;
    for (const addon_slug in this.cart[plan_slug]) {
      if (this.cart[plan_slug][addon_slug]?.['is_added'] == 0) {
        continue;
      }

      switch (this.cart[plan_slug][addon_slug]?.['price_type']) {
        case 'slab':
          let slabItem = this.cart[plan_slug][addon_slug];

          if (this.checkEmptyNumbers(slabItem.units) || slabItem.units < 1)
            slabItem.units = 1;

          this.cart[plan_slug][addon_slug]['units'] = slabItem.units;
          this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(slabItem.slab_price) * parseFloat(slabItem.units);
          break;
        case 'per_unit':
          if (this.subscription_type == 'top_up') {
            this.cart[plan_slug][addon_slug]['price'] = parseFloat(this.licensesProrateArr[this.subscription_cycle][plan_slug][addon_slug]);
          } else {
            this.cart[plan_slug][addon_slug]['price'] = parseFloat(this.licensesCostArr[this.subscription_cycle][plan_slug][addon_slug]);
          }
          this.cart[plan_slug][addon_slug]['sub_total'] = (parseFloat(this.cart[plan_slug][addon_slug].quantity) * parseFloat(this.cart[plan_slug][addon_slug]['price'])) * parseFloat(this.subscription_cycle);
          break;
        case 'flat_fee':
          this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(this.cart[plan_slug][addon_slug]['price']) * parseFloat(this.subscription_cycle);
          break;
        case 'slab_flat_fee':
          let selected_type = this.cart[plan_slug][addon_slug]?.slab_index;
          this.cart[plan_slug][addon_slug]['units'] = 1;

          if (selected_type == 1)
            this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(this.cart[plan_slug][addon_slug]['slab_price']) * parseFloat(this.subscription_cycle);
          if (selected_type == 2)
            this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(this.cart[plan_slug][addon_slug]['slab_price']);
          break;
        case 'flat_fee_pro_rate':
          if (this.subscription_type == 'top_up') {
            this.cart[plan_slug][addon_slug].price = parseFloat(this.addonsProrateArr[plan_slug][addon_slug]);
            this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(this.addonsProrateArr[plan_slug][addon_slug]);
          } else {
            this.cart[plan_slug][addon_slug]['sub_total'] = parseFloat(this.cart[plan_slug][addon_slug]['price']) * parseFloat(this.subscription_cycle);
          }
          break;
        default:
          break;
      }
    }

    this.cartSubTotalAmount();
  }

  addTdsAmountToCart(chk_tds_amount:any){
    this.cartSubTotalAmount();
  }
  cartSubTotalAmount() {
    this.used_wallet_amount = 0;
    this.isZeroPaymentInvoice = false;
    this.total_amount_before_discount = this.getTotalAmount();
    this.cart_total_amount = this.total_amount_before_discount;
    if (this.total_amount_before_discount > 0 && this.wallet_amount > 0) {
      this.used_wallet_amount = Math.min(this.total_amount_before_discount, this.wallet_amount);
      this.cart_total_amount = parseFloat(this.total_amount_before_discount) - parseFloat(this.used_wallet_amount);
    }

    this.taxData = this.calculateTAX(this.cart_total_amount, this.is_gstin, this.chk_tds_amount);
    this.tax_amount = this.taxData['tax_amount'];
    this.tds_amount = this.taxData['tds_amount'];

    let max_amount = 0.5;
    if (this.country_id == 2) {
      max_amount = 1
    }

    if (this.taxData['sub_total'] < max_amount)
      this.isZeroPaymentInvoice = true;

    this.cart_total_amount = this.taxData['sub_total'];
    return this.cart_total_amount;
  }

  calculateTAX(sub_total, is_gstin = false,chk_tds_amount=false) {
    let arrResult = { 'sub_total': parseFloat(sub_total), 'tax_amount': 0, 'tds_amount': 0 };
    if (this.country_id == 2 && sub_total > 0) {
      let tax_amount = parseFloat(sub_total) * 0.18;
      let final_amunt = tax_amount + parseFloat(sub_total);

      if (chk_tds_amount) {
        let tds_amount = parseFloat(sub_total) * 0.10;
        let total_with_tds = parseFloat(sub_total) - tds_amount;
        final_amunt = total_with_tds + tax_amount;
        arrResult['tds_amount'] = tds_amount;
      }

      arrResult['sub_total'] = final_amunt;
      arrResult['tax_amount'] = tax_amount;
      return arrResult;
    }
    return arrResult;
  }

  getTotalAmount() {
    this.checkoutItems = [];
    let cart_total = 0;
    let plan_slug = this.selectedPlan;

    for (const addon_slug in this.cart[plan_slug]) {
      if (this.cart[plan_slug][addon_slug]?.['is_added'] == 1) {
        if (this.cart[plan_slug][addon_slug]?.price_type == 'slab' || this.cart[plan_slug][addon_slug]?.price_type == 'slab_flat_fee') {
          if (!this.checkEmptyNumbers(this.cart[plan_slug][addon_slug]?.units) && !this.checkEmptyNumbers(this.cart[plan_slug][addon_slug]?.slab_index))
            this.checkoutItems.push(this.cart[plan_slug][addon_slug]);
        } else {
          this.checkoutItems.push(this.cart[plan_slug][addon_slug]);
        }

        if (this.cart[plan_slug][addon_slug]?.sub_total > 0) {
          cart_total += parseFloat(this.cart[plan_slug][addon_slug]?.sub_total);
        }
      }
    }

    if (this.lineItemsData?.amount)
      cart_total += parseFloat(this.lineItemsData.amount);

    return cart_total.toFixed(2);
  }

  removeItemFromCart(cartItem) {
    let license_slug = this.getUserLicenseSlug(cartItem.package, cartItem.sb_plan_slug);
    if (cartItem.slug == license_slug) {
      if (this.subscription_type == 'top_up' && this.isSubscribedPackage(cartItem.sb_plan_slug)) {
        if (this.enabledApps[this.licenseAppSlugs[license_slug]]) {
          this.cart[cartItem.sb_plan_slug][cartItem.slug].quantity = 0;
          this.addLicenesToCart(this.plans[cartItem.sb_plan_slug][this.licenseAppSlugs[cartItem.slug]]['user_license'], cartItem.package);
          return;
        } else {
          delete this.cart[cartItem.sb_plan_slug][cartItem.slug];
          this.selectedApps[this.licenseAppSlugs[license_slug]] = 0;
          this.removePackageAddonsFromCart(cartItem.sb_plan_slug, cartItem.package);
        }
      } else {
        delete this.cart[cartItem.sb_plan_slug][cartItem.slug];
        this.selectedApps[this.licenseAppSlugs[license_slug]] = 0;
        this.removePackageAddonsFromCart(cartItem.sb_plan_slug, cartItem.package);
      }
    } else {
      this.cart[cartItem.sb_plan_slug][cartItem.slug] = {};
      // delete this.cart[cartItem.sb_plan_slug][cartItem.slug];
    }

    this.cartSubTotalAmount();
  }

  removePackageAddonsFromCart(selectedPlan: any, a_package: any) {
    for (const addon in this.cart[selectedPlan]) {
      if (Object.prototype.hasOwnProperty.call(this.cart[selectedPlan], addon)) {
        let addon_package = this.cart[selectedPlan][addon]?.['package'];
        //removing addons from cart when unchecked package 
        if (addon_package == a_package || !this.checkEnableApp()) {
          this.cart[selectedPlan][addon] = {};
        }
      }
    }
  }

  isSubscribedPackage(plan_slug) {
    if (this.subscription_type == 'upgrade') {
      return false;
    }

    if (this.subscribedPlan == plan_slug)
      return true;

    return false;
  }

  getUserLicenseSlug(app, plan_slug) {
    return this.plans[plan_slug][app]['user_license_slug'];
  }

  nextStage(form) {
    console.log(this.cart);
    if (isEmpty(this.checkoutItems)) {
      this.commonservice.openSnackBar('warn', 'Oops! Your cart is empty');
      return false;
    }

    if (!form.valid) {
      this.commonservice.openSnackBar('warn', 'Please select min licenses');
      return false;
    }
    return true;
  }

  changePaymentMode() {
    this.turnOffPaymentMode = false;
    this.payment = false;
  }

  checkoutInvoice() {
    this.payment = true;
    this.companyDetailService.getAccountDetails(this.company_id).subscribe(res => {
      if (res.status == 1) {
        this.accountBalance = res.data.accountBalance;
        this.billing_address = res.data?.address;
        this.user_email = res.data.address.email;
        // this.showtxn = false;
        this.showInvoiceUrlPayment();
        if(isEmpty(this.default_card)) {
          this.appendBillingAddress(this.billing_address);
        }
      }
    });
  }

  showInvoiceUrlPayment() {
    let isActive = 1;
    let totalAmount = this.cart_total_amount.toFixed(2);
    let allowCreditBalance = false;

    this.turnOffPaymentMode = false;
    this.isAccountBalPayment = false;

    if (this.accountBalance > 0 && (parseFloat(this.accountBalance) >= parseFloat(totalAmount))) {
      isActive = 1;
      this.isAccountBalPayment = true;
    } else if (this.accountBalance > 0 && (parseFloat(this.accountBalance) < parseFloat(totalAmount))) {
      allowCreditBalance = true;
    }

    this.paymentmodes.forEach(ele => {
      if (this.isAccountBalPayment && ele.slug == 'stripe_balance') {
        ele.isDisable = false;
        ele.isActive = 1;
        ele.name = 'Stripe Credit Balance ' + this.getCurrencySymbol(this.companyDetails.country_id) + this.accountBalance;
      }

      if (this.isAccountBalPayment && ele.slug != 'stripe_balance') {
        ele.isDisable = true;
      }

      if (allowCreditBalance && ele.slug != 'stripe_balance') {
        ele.isDisable = false;
      }

      if (!this.isAccountBalPayment && ele.slug == 'stripe_balance') {
        ele.isActive = 0;
      }

      if (this.subscription_type == 'upgrade_subscription' && ele.slug == 'invoice_url') {
        ele.isActive = 0;
      }
      ele.isSelected = 0;
    });
  }

  appendBillingAddress(addressInfo: any) {
    if (!isEmpty(addressInfo)) {
      this.changeCountry(parseInt(addressInfo.country));
      this.subscriptionForm.get('billing_street').setValue(addressInfo.street);
      this.subscriptionForm.get('billing_city').setValue(addressInfo.city);
      this.subscriptionForm.get('billing_country').setValue(addressInfo.country ? parseInt(addressInfo.country) : '');
      this.subscriptionForm.get('billing_state').setValue(addressInfo.state ? parseInt(addressInfo.state) : '');
      this.subscriptionForm.get('billing_zip').setValue(addressInfo.zip);
    }
  }


  paymentChange(item) {
    this.selected = item;
    this.payment_mode = item.value;
    this.subscriptionForm.get('selected_card')?.setValue('');

    this.paymentmodes.forEach(ele => {
      if (item.slug == ele.slug) {
        ele.isSelected = 1;
      } else {
        ele.isSelected = 0;
      }
    });

    this.appendBillingAddress(this.billing_address);
    this.isAccountBalPayment = (item.slug == 'invoice_url') ? false : true;
  }


  
  downloadProforma()
  {

    let postData = {};
    postData['check_data1'] = '';
    postData['customer_email'] = '';
    postData['comments'] = '';
    postData['status'] = true;
    this.billing['street'] = this.subscriptionForm.value['billing_street'];
    this.billing['city'] = this.subscriptionForm.value['billing_city'];
    this.billing['country'] = this.subscriptionForm.value['billing_country'];
    this.billing['selected_country_id'] = this.subscriptionForm.value['billing_country'];
    this.billing['state'] = this.subscriptionForm.value['billing_state'];
    this.billing['zip'] = this.subscriptionForm.value['billing_zip'];
    var params:any={};
  
    this.setPaymentInfoParam(postData, params);
    console.log(params);

    

    this.companyDetailService.downloadProforma(params).subscribe(res => {
      if (res.status == 1) {
        // console.log
        // let file_data = window.atob(res.data.file_data);
        // console.log(file_data);
      //  this.downloadBrochure(file_data,res.data.original_name);

      let link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.setAttribute('download', 'true');
        
        link.href = res.data.file_path;
        link.target = '_blank';
        link.download = res.data.original_name;
        document.body.appendChild(link);
        link.click();
        link.remove();

       

      }
    });
    
  }

  async downloadBrochure(url: string,file_name:string) {
    try {
      const res = await this.HttpClient.get(url, { responseType: 'blob' }).toPromise();
      this.downloadFile(res,file_name);
    } catch (e) {
      console.log(e.body.message);
    }
  }


  downloadFile(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const dataURL = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = dataURL;
    a.download = fileName;
    a.click();
  }

  setPaymentInfoParam(result:any,params:any)
  {
    let cartData = {};
    cartData[this.selectedPlan] = {};

    for (const key in this.cart[this.selectedPlan]) {
      if (this.cart[this.selectedPlan][key]['is_added']) {
        cartData[this.selectedPlan][key] = this.cart[this.selectedPlan][key];
      }
    }

    
    params['cartData'] = cartData;
    params['billing'] = this.billing;
    params['subscription_cycle'] = this.subscription_cycle;
    params['selected_plan_slugs'] = Object.keys(this.cart);
    params['selectedApps'] = this.selectedApps;
    params['is_cpanel'] = 1;
    params['isAccountBalancePayment'] = this.isAccountBalPayment;
    params['payment_mode'] = this.payment_mode;
    params['company_id'] = this.company_id;
    params['comments'] = isEmpty(result.comments) ? '' : result.comments;

    params['chk_tds_amount'] = this.chk_tds_amount;


    if (result.check_data1) {
      params['mail_toadmin'] = result.check_data1;
      params['customer_email'] = result.customer_email;
    } else {
      params['customer_email'] = this.user_email;
    }

    (this.cart_total_amount == 0) ? params['is_zero_payment'] = '1' : params['is_zero_payment'] = '0';


  }
  
  save() {

    let selectedPaymentCard = this.subscriptionForm.value['selected_card'];
    
    if (this.subscription_type == 'upgrade_subscription' && selectedPaymentCard) {
      this.payment_mode = 'card';
    }

    if ((!this.subscriptionForm.valid || !this.payment_mode) && this.cart_total_amount > 0) {
      this.commonservice.openSnackBar('warn', 'Please submit required details');
      this.subscriptionForm.markAllAsTouched();
      return false;
    }

    if (this.cart_total_amount <= 0)
      this.payment_mode = 'zeroInvoicePayment';

    let msg;
    let emailInfo = this.user_email;;
    if (this.payment_mode == 'zeroInvoicePayment') {
      msg = 'Are you sure, Do you want to process payment with zero amount';
    } else if (this.payment_mode == 'invoice_url' && this.isAccountBalPayment) {
      msg = 'Are you sure, Do you want to process payment using stripe credit balance?';
    } else if (this.payment_mode == 'invoice_url' && !this.isAccountBalPayment) {
      msg = 'Are you sure, Do you want to send invoice payment link to customer?';
    } else if (this.payment_mode == 'card' && this.subscription_type == 'upgrade_subscription') {
      msg = 'Are you sure, Do you want to upgrade subscription plan?';
    }

    this.billing['street'] = this.subscriptionForm.value['billing_street'];
    this.billing['city'] = this.subscriptionForm.value['billing_city'];
    this.billing['country'] = this.subscriptionForm.value['billing_country'];
    this.billing['selected_country_id'] = this.subscriptionForm.value['billing_country'];
    this.billing['state'] = this.subscriptionForm.value['billing_state'];
    this.billing['zip'] = this.subscriptionForm.value['billing_zip'];

    const data = [];
    data['title'] = 'Alert';
    data['message'] = msg;
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    data['check_data1'] = 'Send invoice email to super admin';
    data['email'] = emailInfo;

    const dialogRef = this.dialog.open(CustomConfirmDialogComponent, {
      width: '35%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.status) {
        
        var params: any = {};
        this.setPaymentInfoParam(result,params);
        let updateCheckoutUrl;
        params['payment_type'] = this.subscription_type;
    

        if (this.payment_mode == 'invoice_url') {
          if (this.subscription_type == 'upgrade_subscription') {
            params['isWebhookUpgradeSubscription'] = true;
          }
          updateCheckoutUrl = this.companyDetailService.invoiceURLCheckout(params);
        } else if (this.payment_mode == 'zeroInvoicePayment') {
          if (this.subscription_type == 'upgrade_subscription') {
            params['isUpgradeSubscription'] = true;
          }
          updateCheckoutUrl = this.companyDetailService.zeroAmountInvoiceCheckout(params);
        }
        else if (this.payment_mode == 'card' && selectedPaymentCard && this.subscription_type == 'upgrade_subscription') {
          params['isUpgradeSubscription'] = true;
          params['otherData'] = {'selected_card':selectedPaymentCard};
          updateCheckoutUrl = this.companyDetailService.upgradeSubscription(params);
        }

        // console.log(params);
        // return;
        updateCheckoutUrl.subscribe(res => {
          if (res.status === 1) {
            if (this.isAccountBalPayment) {
              this.commonservice.getLoader();
              setTimeout(() => {
                this.commonservice.openSnackBar('primary', res.data.message);
                this.commonservice.getLoader();
                this.router.navigate(['company_details/' + this.company_id + '/billingdetails']);
              }, 8000);
            } else {
              this.commonservice.openSnackBar('primary', res.data.message);
              this.router.navigate(['company_details/' + this.company_id + '/billingdetails']);
            }
          } else {
            this.commonservice.openSnackBar('warn', res.data.message);
          }
        });
      }
    });
  }
}
