import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { ExcelService } from 'app/services/excel.service';
import * as moment from 'moment';
import { ClientsService } from 'app/services/clients.service';
import { ActiveUserChangePasswordComponent } from 'app/common-all/active-user-change-password/active-user-change-password.component';

@Component({
  selector: 'app-total-active-users',
  templateUrl: './total-active-users.component.html',
  styleUrls: ['./total-active-users.component.scss']
})
export class TotalActiveUsersComponent implements OnInit {
  dataSource: any;
  company: FormGroup;
  displayedColumns: string[] = ['user_name', 'role', 'access_to', 'email', 'number', 'added_on','actions'];
  params: { limit: number; page: number; searchKey?: string;};
  users_params: { limit: number; page: number; searchKey?: string; company_id?:number };
  getTableData: any;
  length: any;
  pageSize: any;
  index: any;
  searchValue = '';
  companyData = [];
  toppingList: any;
  uniqueArray: any[];

  customersData:any;
  customersResult:any;
  customers_data_length: any;
  customers_data_pageSize: any;
  customers_data_index: any;
  customer_searchValue = '';
  viewed_id:any;

  constructor(private commonservice: CommonService,
    private clientService: ClientsService,
    private router: Router, private fb: FormBuilder,
    public dialog: MatDialog,
    private excelService: ExcelService,) {
    this.company = this.fb.group({
      toppings: ['']
    })
    this.company.valueChanges.subscribe(res => {
      if (res) {
        if (res.toppings.length > 0) {
          this.companyData = [];
          this.toppingList.filter(filt_data => {
            res.toppings.filter(val => {
              if (filt_data.id === val) {
                this.companyData.push({ id: filt_data.id, name: filt_data.display_name });
              }
            })
          })
        }
        else {
          this.companyData = [];
        }
        this.params['companies_data'] = this.companyData;
        this.getlist();
      }
    })
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1}
    this.users_params = { limit: 15, page: 1}
    this.getlist();

  }

  getlist() {
    this.clientService.totalActiveUsers(this.params).subscribe(res => {
      if(res['status'] == 1) {
        this.customersResult = res['data'];
        this.customersData = res['data'].data;
        this.customers_data_length = this.customersResult.total;
        this.customers_data_pageSize = this.customersResult.per_page;
        this.customers_data_index = this.customersResult.current_page;
      }
    });
  }
  getCompany(company_id) {
    if(this.viewed_id && this.viewed_id != company_id){
      this.searchValue = '';
    }
    this.viewed_id = company_id;
    this.users_params['company_id'] = company_id;
    this.clientService.getCustomerActiveUsers(this.users_params).subscribe(result => {
      if (result['status'] === 1) {
        this.getTableData = result['data'];
        this.length = this.getTableData.total;
        this.pageSize = this.getTableData.per_page;
        this.index = this.getTableData.current_page;
        this.dataSource = new MatTableDataSource(this.getTableData.data);
        this.reset_params();
      }
    });
  }

  applyFilter(filterValue: string, type) {
    this.params.page = 1;
    if(type == 'customer') {
      this.params['searchKey'] = filterValue;
      this.getlist();
    }else if(type == 'user') {
      this.users_params['searchKey'] = filterValue;
      this.getCompany(this.viewed_id);
    }
  }
  pageEvent(event, type) {
    if(type == 'customer') {
      this.params['limit'] = event.pageSize;
      this.params['page'] = event.pageIndex + 1;
      this.getlist();
    }else if(type == 'user') {
      this.users_params['limit'] = event.pageSize;
      this.users_params['page'] = event.pageIndex + 1;
      this.getCompany(this.viewed_id);
    }
  }

  reset_params() {
    this.users_params = { limit: 15, page: 1, searchKey:''};
  }

  getUserRole(active_roles, type) {
    if(active_roles.length > 0 ) {
      let roleArr = [];
      let accessArr = [];
      active_roles.forEach(element => {
        roleArr.push(element.roles.name);
        accessArr.push(element.app);
      });
      if(type == 'role') {
        return roleArr.join();
      }else if(type == 'access_to') {
        return accessArr.join();
      }
    }else{
      return '-';
    }
  }

  navigate(element) {
    this.router.navigate(['company_details', element.id]);
    // this.router.navigate([]).then(result => { window.open("company_details/" + element.id, '_blank'); });
  }

  changePassword(user_id) {
    console.log('user', user_id);
    let data = {};
    data['id'] = user_id;
    data['company_id'] = this.viewed_id;
    const dialogRef = this.dialog.open(ActiveUserChangePasswordComponent, {
      width: '65%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  revokeUser(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = element.status == 1 ? 'Are you sure? # You want to In-Active user' : 'Are you sure? # You want to Active user';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.clientService.revokeUser({ status: element.status == 1 ? 0 : 1, id: element.id, company_id:this.viewed_id }).subscribe(res => {
          if (res.status === 1) {
            this.commonservice.openSnackBar('primary', res.message);
            this.getCompany(this.viewed_id);
          }
          else {
            this.commonservice.openSnackBar('warn', res.message);
          }
        })
      } else {

      }
    })
  }

  exportExcel(company_name) {
    // console.log(Params);
    this.users_params['company_id'] = this.viewed_id;
    this.users_params['page'] = 1;
    this.users_params['limit'] = this.length;
    this.clientService.getCustomerActiveUsers(this.users_params).subscribe(result => {
      if (result.status === 1) {
        let prepared_data = this.prepareExcelData(result.data.data);
        let sheet_name = company_name +' Active Users List';
        this.excelService.exportAsExcelFile(prepared_data, sheet_name);
      }
    })
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['User Name'] = element.name;
      if(element.is_super_admin == 1){
        eachRow['Role'] = 'Super Administrator';
      }else{
        eachRow['Role'] = this.getUserRole(element.active_roles, 'role');
      }
      eachRow['Access to'] = this.getUserRole(element.active_roles, 'access_to');
      eachRow['Email'] = element.email;
      eachRow['Contact Number'] = element.contact_number;
      if(element.created_at) {
        eachRow['Addon On'] = moment(element.created_at).format('MM/DD/YYYY');
      }else {
        eachRow['Addon On'] = '';
      }
      ArrData.push(eachRow);
    });
    // console.log('prepared', ArrData);
    return ArrData;
  }

}
