<form [formGroup]="MxRecordsFrom" class="px-32 py-8">
  <div fxLayout="row wrap" fxLayoutAlign="start center">
    <div fxFlex="50" class="mr-12">
      <mat-form-field appearance="outline" class="w-100-p">
        <mat-label>Domain Name</mat-label>
        <input matInput formControlName="domain" (input)="domainChange()">
      </mat-form-field>
    </div>
    <div fxFlex="250px" fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="subsidaryList.length>1">
      <mat-form-field class="w-100-p country-p" appearance='outline'>
        <mat-label>Subsidary List</mat-label>
        <mat-select formControlName="subsidary_id" (selectionChange)="getDomainDetails()">
          <mat-option *ngFor="let item of subsidaryList" [value]="item.id">
            {{item.subsidary_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="50" class="mr-12">
      <mat-form-field appearance="outline" class="w-100-p">
        <mat-label>From Email</mat-label>
        <input matInput type="email" formControlName="from_email" placeholder="All the communication emails will be send from the following email address">
      </mat-form-field>
    </div>
    &nbsp;&nbsp;
    <div>
      <span *ngIf="domainData && domainData.state == 'active' && !showUpdate " style="margin-right: 5px;"><mat-icon class="s-18 primary-fg">check_circle_outline</mat-icon>Verified</span>
      <button mat-flat-button class="primary" *ngIf="!domainData" (click)="save()" style="margin-right: 5px;" [disabled]="isLostAccount">Add Domain</button>
      <button mat-flat-button class="accent" *ngIf="showUpdate" (click)="udpateDoamin()" style="margin-right: 5px;" [disabled]="isLostAccount">Update</button>
      <button mat-flat-button class="primary" *ngIf="(domainData && domainData.state != 'active') && (!showUpdate && !DNS_status)"
        (click)="verifyDoamin()" style="margin-right: 5px;" [disabled]="isLostAccount">Verify Now</button>
      <button mat-flat-button class="primary" *ngIf="domainData && domainData.state != 'active' && DNS_status"
        (click)="verifyDoamin()" style="margin-right: 5px;" [disabled]="isLostAccount">Complete</button>
        <button mat-flat-button class="warn" (click)="forceAddDomain()" [disabled]="isLostAccount">Force Update</button>

    </div>
  </div>
</form>

<p *ngIf="dataSource" class="text-bold px-32">Add DNS records for sending</p>
<div class="center px-20">
  <div class="content-card mt-12 mb-52">

    <div class="content" fusePerfectScrollbar>
      <ng-container *ngIf="dataSource">
        <div class="px-12 pb-12">
          <mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef fxFlex="15"> Type </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="15">
                <span *ngIf="element.valid == 'valid'" class="pr-4">
                  <mat-icon class="s-18 primary-fg">check_circle_outline</mat-icon>
                </span>
                <span *ngIf="element.valid != 'valid'" class="pr-4">
                  <mat-icon class="s-18 warn-fg">cancel</mat-icon>
                </span>
                {{element.type}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="host_name">
              <mat-header-cell *matHeaderCellDef  fxFlex="30"> Host Name </mat-header-cell>
              <mat-cell *matCellDef="let element" class="lowercase"  fxFlex="30"> {{element.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="enter_value">
              <mat-header-cell *matHeaderCellDef fxFlex> Enter This Value </mat-header-cell>
              <mat-cell *matCellDef="let element" class="lowercase" fxFlex> {{element.value}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </ng-container>
      <ng-container *ngIf="dataSource1">
        <div class="px-12 pb-12">
          <mat-table [dataSource]="dataSource1">

            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef fxFlex="15"> Type </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex="15">
                <span *ngIf="element.valid == 'valid'" class="pr-4">
                  <mat-icon class="s-18 primary-fg">check_circle_outline</mat-icon>
                </span>
                <span *ngIf="element.valid != 'valid'" class="pr-4">
                  <mat-icon class="s-18 warn-fg">cancel</mat-icon>
                </span>
                {{element.type}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="priority">
              <mat-header-cell *matHeaderCellDef fxFlex="30"> Priority </mat-header-cell>
              <mat-cell *matCellDef="let element" class="lowercase" fxFlex="30"> {{element.priority}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="enter_value">
              <mat-header-cell *matHeaderCellDef fxFlex> Enter This Value </mat-header-cell>
              <mat-cell *matCellDef="let element" fxFlex class="lowercase"> {{element.value}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns1;sticky:true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns1;"></mat-row>
          </mat-table>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class='footer'>
  <button mat-button (click)="showActivities()">Activities</button>
</div>