<div class="page-layout carded fullwidth inner-scroll" *ngIf="dataSource">
  <!-- <mat-toolbar-row class="secondary-headers w-100-p" >
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li>
        <a class="text-bold active" >Coupons</a>
      </li>
    </ul>
  </mat-toolbar-row> -->

  <div class="center px-20">
    <div class="content-card mt-12 mb-56">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
        <div class="search-wrapper" fxFlex="30">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Search...">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>

        <div fxFlex="100" class="actions_list">
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50, 100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="limit" [pageIndex]="currentPage-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">
              
              <ng-container matColumnDef="sno">
                <mat-header-cell *matHeaderCellDef fxFlex="100px"> S.no </mat-header-cell>
                <mat-cell *matCellDef="let element;index as i" fxFlex="100px">
                  {{i + 1}}
                </mat-cell>
              </ng-container>
             
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                
                <mat-cell *matCellDef="let element;let i = index" >
                    <div *ngIf="element.description">
                      <span [innerHTML]="element.description | slice:0:more[i] ? element.description.length : 100"> </span>
                      <a *ngIf="element.description.length > 100" style="cursor:pointer;" (click)="more[i] = !more[i];">{{!more[i] ? ' More ' : ' Less '}}</a>
                    </div>
                    <div *ngIf="!element.description">N/A</div>
                </mat-cell> 
                

              </ng-container>

               <ng-container matColumnDef="other_information">
                <mat-header-cell *matHeaderCellDef > Other Information </mat-header-cell>
                
                <mat-cell *matCellDef="let element;let i = index" >
                    <div *ngIf="element.other_information">
                      <span [innerHTML]="element.other_information | slice:0:more1[i] ? element.other_information.length : 100"> </span>
                      <a *ngIf="element.other_information.length > 100" style="cursor:pointer;" (click)="more1[i] = !more1[i];">{{!more1[i] ? ' More ' : ' Less '}}</a>
                    </div>
                    <div *ngIf="!element.other_information">N/A</div>
                </mat-cell>
              </ng-container>             

              <!-- action Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef fxFlex='100px'> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="100px">
                  
                  <button mat-icon-button class="accent-fg" matTooltip="Edit"  (click)="edit(element)">
                    <mat-icon class="s-18">edit</mat-icon>
                  </button>

                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>
        <div class="footer">
          <!-- <button mat-button (click)="addUser()">Add</button> -->
          <!-- <mat-divider style="width:1px;background-color:#fff;"></mat-divider> -->
          <button mat-button (click)="showActivities()">Activities</button>
        </div>
      </div>
    </div>
  </div>
</div>