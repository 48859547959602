import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { ExcelService } from 'app/services/excel.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { ClientsService } from 'app/services/clients.service';

@Component({
  selector: 'app-discard-reports',
  templateUrl: './discard-reports.component.html',
  styleUrls: ['./discard-reports.component.scss']
})

export class DiscardReportsComponent implements OnInit {

  displayedColumns: string[] = ['customerName', 'contactName', 'email', 'subdomain', 'region', 'status', 'subscriptionenddate', 'actions'];
  dataSource;
  searchValue = '';
  getlist: any;
  getTableData: any;
  length: any;
  pageSize: any;
  index: any;
  paginator: MatPaginator;
  params: { limit: number; page: number; searchKey?: string; };
  constructor(
    private commonservice: CommonService,
    private clientService: ClientsService,
    private excelService: ExcelService,
    public router: Router,
    private datepipe: DatePipe,
   ) { }

  ngOnInit() {
    this.params = { limit: 15, page: 1}
    this.getDiscardReportCustomers();
  }

  getDiscardReportCustomers() {
    this.clientService.discardReportCustomers(this.params).subscribe(result => {
      if (result.status === 1) {
        // console.log(result);
        this.getTableData = result.data;
        this.length = this.getTableData.total;
        this.pageSize = result.data.per_page;
        this.index = result.data.current_page;
        this.dataSource = new MatTableDataSource(result.data.data);
      }
    })
  }
  applyFilter(filterValue: string) {
    this.params.searchKey = filterValue;
    this.params.page = 1;
    this.getDiscardReportCustomers();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getDiscardReportCustomers();
  }
  navigate(element, blank) {
    // this.router.navigate(['company_details', element.company_id]);
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }

  exportExcel() {
    let Params = { limit: this.length, page: 1 };
    this.clientService.discardReportCustomers(Params).subscribe(result => {
      if (result.status === 1) {
        let prepared_data = this.prepareExcelData(result.data.data);
        this.excelService.exportAsExcelFile(prepared_data, 'Discard Report Clients');
      }
    })
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer'] = element.name;
      eachRow['Customer Name'] = (element.client_info ? element.client_info.first_name : '') + ' ' + (element.client_info ? element.client_info.last_name : '');
      eachRow['Customer Email'] = (element.client_info ? element.client_info.email : '');
      eachRow['Subdomain Name'] = element.sub_domain_name;
      eachRow['Region'] = element.region;
      eachRow['Status'] = element.status == 1 ? 'Active' : 'In-Active';
      eachRow['Subscription End Date'] = moment(element.subscr_end_date).format('MM/DD/YYYY');
      ArrData.push(eachRow);
    });
    // console.log('prepared', ArrData);
    return ArrData;
  }

}
