import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-remove-free-licenses',
  templateUrl: './remove-free-licenses.component.html',
  styleUrls: ['./remove-free-licenses.component.scss']
})

export class RemoveFreeLicensesComponent implements OnInit {
  show_title: any;
  AddLicense: FormGroup;
  free_licenses: any;
  available_licenses: any;
  max_licenses_to_exit: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RemoveFreeLicensesComponent>,
    public dialog: MatDialog
  ) {
    this.AddLicense = this.fb.group({
      'total_licenses': ['', Validators.required],
    });
  }

  ngOnInit() {
    this.show_title = this.data.license_title;
    this.free_licenses = this.data.free_licenses;
    this.available_licenses = this.data.available_licenses;
    this.max_licenses_to_exit = Math.min(this.available_licenses, this.free_licenses);
  }

  convertLinceses() {
    if (this.AddLicense.valid) {

      if (this.AddLicense.value.total_licenses <= 0) {
        this.commonService.openSnackBar('warn', 'Enter at least one license');
        return;
      }

      if (this.AddLicense.value.total_licenses > this.max_licenses_to_exit) {
        this.commonService.openSnackBar('warn', 'Please enter valid license(s)');
        return;
      }

      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? remove free licenses';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35%',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let params = {};
          params['company_id'] = this.data.company_id;
          params['convert_licenses'] = this.AddLicense.value.total_licenses;
          params['addon_slug'] = this.data.addonSlug;
          params['free_licenses'] = this.free_licenses;
          params['available_licenses'] = this.available_licenses;
          params['subscription_id'] = this.data.subscription_id;
          params['is_unlimited'] = this.data.is_unlimited;
          params['is_cpanel'] = 1;
          params['company_name'] = this.data.company_name;

          this.companyDetailService.removeFreeLicenses(params).subscribe(res => {
            if (res.status == 1) {
              this.commonService.openSnackBar('primary', res.data.message);
            } else {
              this.commonService.openSnackBar('warn', res.data.message);
            }
            this.dialogRef.close(1);
          });

        } else { }
      })
    }
  }
}
