import { Component, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { DOWN_ARROW, UP_ARROW, LEFT_ARROW, RIGHT_ARROW, HOME, END, ENTER, SPACE, A } from '@angular/cdk/keycodes';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSelect, MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-mat-search-selector',
  templateUrl: './mat-search-selector.component.html',
  styleUrls: ['./mat-search-selector.component.scss']
})
export class MatSearchSelectorComponent {

  /** Holds the reference to the input DOM element. */
  @ViewChild('searchBox', { read: ElementRef, static: false })
  private _searchBoxInputElement: ElementRef;
  private _destroys$ = new Subject<null>();
  /** Label of the input placeholder.  */
  @Input()
  placeholder = 'Search..';

  private isCheck: Boolean = false;
  isMultiple: Boolean;
  noRecords: Boolean = false;

  @ViewChild('allSelected', { read: MatCheckbox , static: false})
  _selectAllOptionElement?: MatCheckbox;

  /** Holds the reference to the form control instance of the input DOM element. */
  _searchBox = new FormControl();

  /**
   * Gets the observable stream responsible for tracking the input value changes.
   *
   * @returns Observable<string>
   */
  get changes$(): Observable<string> {
    return this._searchBox.valueChanges;
  }

  /**
   * Handles the key down event and decides when to propagate the event to `MatSelect`.
   *
   * @param event KeyboardEvent
   */
  @HostListener('keydown', ['$event'])
  _handleKeyDown(event: KeyboardEvent) {
    if (
      event.keyCode !== DOWN_ARROW &&
      event.keyCode !== UP_ARROW &&
      event.keyCode !== LEFT_ARROW &&
      event.keyCode !== RIGHT_ARROW &&
      event.keyCode !== HOME &&
      event.keyCode !== END &&
      event.keyCode !== ENTER &&
      // event.keyCode !== SPACE &&
      (event.keyCode !== A || !event.ctrlKey)
    ) {
      event.stopPropagation();
    }
  }
  constructor(private _matSelect: MatSelect) {
  }
  /** Places the focus on the input element. */
  focus() {
    if (this._searchBoxInputElement.nativeElement) {
      this._searchBoxInputElement.nativeElement.focus();
    }
  }

  /** Resets the value of the input element. */
  clear(silent = false) {
    if (!silent) {
      this._searchBox.setValue('');
      this.focus();
    } else {
      this._searchBoxInputElement.nativeElement.value = '';
    }
  }

  selectAllOption(event) {
    this.isCheck = event.checked;
    const selectvalues = this._matSelect.options.map(opt => opt.value);
    const matvalue = this._matSelect.value ? this._matSelect.value : [];
    this._matSelect._selectionModel.clear();
    if (this.isCheck) {
      this._matSelect.options.forEach(opt => {
        this._matSelect._selectionModel.select(opt);
      });
      const values = matvalue.concat(selectvalues);
      this._matSelect.valueChange.emit(Array.from(new Set(values)));
      this._matSelect._onChange(Array.from(new Set(values)));
      this._matSelect.selectionChange.emit(new MatSelectChange(this._matSelect, Array.from(new Set(values))));
    }
    else {
      const values = matvalue.filter(val => val && !selectvalues.includes(val));
      this._matSelect.valueChange.emit(Array.from(new Set(values)));
      this._matSelect._onChange(Array.from(new Set(values)));
      this._matSelect.selectionChange.emit(new MatSelectChange(this._matSelect, Array.from(new Set(values))));
    }
  }

  ngDestroy() {
    if (this._destroys$) {
      this._destroys$.next(null);
      this._destroys$.complete();

    }
  }

}
