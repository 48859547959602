import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'app/services/common.service';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {


  client_info_permissions: any = {};
  id: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getModulePermissions();
    this.activatedRoute.params.subscribe(result => {
      if (result['id']) {
        this.id = result['id'];
        // console.log(this.id);
      }
    })
  }

  getModulePermissions() {
    let permission_params = {};
    permission_params['module'] = 'clients';
    this.commonService.getModulePermissions(permission_params).subscribe(res => {
      if (res.status == 1) {
        this.client_info_permissions = res.data.permissions;
      }
      console.log(this.client_info_permissions);

    })
  }

}
