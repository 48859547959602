<div class="page-layout carded fullwidth inner-scroll">
    <mat-toolbar-row class="secondary-headers w-100-p">
        <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
            <li><a class="text-bold active">Release Maintenance</a></li>
        </ul>
    </mat-toolbar-row>

    <div class="center px-20">
        <div class="content-card mt-12 mb-56">
            <div class="content p-20" fusePerfectScrollbar>
                <div [formGroup]="MaintenanceForm" class="w-5100-p">
                    <div fxLayout='row' fxLayoutAlign="space-between start">
                        <div fxFlex='48'>
                            
                            <div class="mt-12">
                              <mat-form-field appearance="outline" class="w-70-p">
                                <mat-label>Start Date</mat-label>

                                <!-- <input matInput formControlName="start_date" [owlDateTimeTrigger]="start_date" [owlDateTime]="start_date"> -->
                                <input matInput formControlName="start_date">
                              </mat-form-field>
                              <owl-date-time #start_date></owl-date-time>
                            </div>
                            <div class="mt-12">
                              <mat-form-field appearance="outline" class="w-70-p">
                                <mat-label>End Date</mat-label>

                                <!-- <input matInput formControlName="end_date" [owlDateTimeTrigger]="end_date" [owlDateTime]="end_date"> -->
                                <input matInput formControlName="end_date">
                              </mat-form-field>
                              <owl-date-time #end_date></owl-date-time>
                            </div>
                            <div class="mt-12">
                              <mat-form-field class="w-70-p" appearance="outline">
                                  <mat-label>Allowable Companies</mat-label>
                                  <mat-select appMatSearchSelector class="w-100-p" formControlName="to" multiple>
                                      <app-mat-search-selector placeholder='Search..'></app-mat-search-selector>
                                      <ng-container *ngFor="let company of companies">
                                          <mat-option *appMatSearchSelectorItems [value]="company.id">
                                              {{company.display_name}}
                                          </mat-option>
                                      </ng-container>
                                  </mat-select>
                              </mat-form-field>
                          </div>
                           
                        </div>
                        <div fxFlex='48'>
                            <div class="mt-12">
                                <div class="font-size-12 text-bold my-4">Message Body</div>
                                <ckeditor formControlName="mail_body"></ckeditor>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <button mat-button class="primary" (click)="send()">Send</button>
</div>