import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trail-customers',
  templateUrl: './trail-customers.component.html',
  styleUrls: ['./trail-customers.component.scss']
})
export class TrailCustomersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
