import { Component, NgZone, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { ExcelService } from 'app/services/excel.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { ReportsService } from 'app/services/reports.service';

const MRRUnlimited = {
  chart: {
    showvalues: "1",
    caption: "MRR(Unlimited Subscription)",
    subcaption: "",
    numberprefix: "",
    numbersuffix: "",
    plottooltext: "$seriesName : <b>$dataValue</b>",
    showhovereffect: "1",
    yaxisname: "Amount",
    showsum: "1",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        { label: "Jan" }, { label: "Feb" }, { label: "Mar" }, { label: "Apr" }, { label: "May" }, { label: "Jun" },
        { label: "Jul" }, { label: "Aug" }, { label: "Sep" }, { label: "Oct" }, { label: "Nov" }, { label: "Dec" }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    }
  ]
};

@Component({
  selector: 'app-unlimited-mrr',
  templateUrl: './unlimited-mrr.component.html',
  styleUrls: ['./unlimited-mrr.component.scss']
})
export class UnlimitedMrrComponent implements OnInit {

  countries = [
    { value: '1', viewValue: 'USA' }, { value: '2', viewValue: 'India' }, { value: '702', viewValue: 'Singapore' }
  ]

  years = [
    { value: 2022, viewValue: 2022 }, { value: 2021, viewValue: 2021 }, { value: 2020, viewValue: 2020 },
  ];

  // ejs datepicker
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  // public lastEnd: Date = this.today;
  public lastEnd: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  // public yearStart = moment().startOf('year');
  public yearEnd: Date = this.today;
  // public value: Date[] = [this.yearStart, this.yearEnd];
  // value: Date[];
  // ejs datepicker completed

  //Refund chart report
  MrrUnlimitedChartDataSource: any;
  MrrUnlimitedYearForm: FormGroup;
  MrrUnlimitedYearRange = {};
  params: { date_range?: any, country_id?: any };
  //Refund chart report

  constructor(private commonService: CommonService,
    private reportService: ReportsService,
    private zone: NgZone,
    private fb: FormBuilder, private excelService: ExcelService, private datepipe: DatePipe, private router: Router) {

    let currentYear = this.years.filter(val => val.value == new Date().getFullYear());

    this.MrrUnlimitedYearForm = this.fb.group({
      year: [currentYear[0].value],
      country_id: ['1']
    })

    this.MrrUnlimitedYearForm.valueChanges.subscribe(res => {
      if (res) {
        let yearStart = new Date(res.year, 0, 1);
        let yearEnd = new Date(res.year, 11, 31);
        this.MrrUnlimitedYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
        this.MrrUnlimitedYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
        this.params.date_range = JSON.stringify(this.MrrUnlimitedYearRange);
        this.params.country_id = res.country_id;
        this.getUnlimitedMRRChartInfo();
      }
    })
  }

  ngOnInit() {
    this.params = { country_id: 1 };

    let yearStart = new Date(new Date().getFullYear(), 0, 1);
    let yearEnd = new Date(new Date().getFullYear(), 11, 31);

    this.MrrUnlimitedYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
    this.MrrUnlimitedYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
    this.params.date_range = JSON.stringify(this.MrrUnlimitedYearRange);
    this.getUnlimitedMRRChartInfo();

  }

  getUnlimitedMRRChartInfo() {
    this.reportService.getUnlimitedMRRChartInfo(this.params).subscribe(res => {
      if (res) {
        MRRUnlimited.dataset[0].data.filter((data, index) => {
          MRRUnlimited.dataset[0].data[index].value = '';
          MRRUnlimited.dataset[0].data[index].fullDate = '';

        })
        Object.entries(res.mrr_data).forEach(val => {
          MRRUnlimited.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              MRRUnlimited.dataset[0].data[index].value = val[1].toString();
              MRRUnlimited.dataset[0].data[index].fullDate = val[0];
            }
          })
        });
        this.MrrUnlimitedChartDataSource = MRRUnlimited;
      }
    })
  }
}
