import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonHelpersService } from 'app/services/common-helpers.service';
import { CommonService } from 'app/services/common.service';
import { ExcelService } from 'app/services/excel.service';
import { ReportsService } from 'app/services/reports.service';

const paymentModeWisePaymentsData = {
  chart: {
    showvalues: "1",
    caption: "Jobs",
    subcaption: "",
    numberprefix: "",
    numbersuffix: "",
    plottooltext: "$seriesName : <b>$dataValue</b>",
    showhovereffect: "1",
    yaxisname: "Records",
    showsum: "1",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        { label: "Jan" }, { label: "Feb" }, { label: "Mar" }, { label: "Apr" }, { label: "May" }, { label: "Jun" },
        { label: "Jul" }, { label: "Aug" }, { label: "Sep" }, { label: "Oct" }, { label: "Nov" }, { label: "Dec" }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    },
  ]
};

@Component({
  selector: 'app-usage-metrics',
  templateUrl: './usage-metrics.component.html',
  styleUrls: ['./usage-metrics.component.scss']
})
export class UsageMetricsComponent implements OnInit {

  monthlySubscriptionChartDataSource: any = paymentModeWisePaymentsData;
  displayedChartColumns: string[] = ['month', 'projected'];
  dataSource:any;
  dataChartSource:any;
  id: any;
  currentUser = {};
  params: { company_id?: any, module_slug?: any, date_range?:any};

  statusList:any = [{name:"Today", status:'today'}, {name:"This Week", status:'week'}, {name:"This Month", status:'month'}, {name:"This Quater", status:'quarter'}, {name:"This Semester", status:'semester'}, {name:"This Year", status:'year'}];
  modulesList:any = [{name:"Jobs", status:'jobs'}, {name:"Candidates", status:'candidates'}, {name:"Resource Pool", status:'resource_pool'}];
  currentMonth = new Date();
  responseData:any;
  getDateRange = {};
  foreCastMonths:any;
  monthsResponseData:any;
  chartResponse:any;
  currencySymbol:any = '$';
  toppingList: any;
  companyId:any;
  date_range:any='month';
  module_slug:any = 'jobs';
  totalRecords:any = 0;

  constructor(
    private reportService: ReportsService,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private helperService:CommonHelpersService,
    private excelService: ExcelService,
    private commonservice:CommonService, 
  ) {
    
  }

  ngOnInit() {
    this.params = { module_slug: this.module_slug, date_range: this.date_range };
    this.getNextSixMonths();
    // this.showChartData(1);
    this.commonservice.companyList().subscribe(res => {
      if (res.status === 1) {this.toppingList = res.data;}
    });
  }

  getNextSixMonths() {
    this.reportService.getNextSixMonths().subscribe(res => {
      this.monthsResponseData = res.data;
      this.foreCastMonths = this.monthsResponseData.months;
      this.dataChartSource = this.monthsResponseData.months;
      this.foreCastMonths.forEach((val,key) => {
        paymentModeWisePaymentsData.categories[0]['category'][key]['label'] = val.month_short_name;
      });
    })
  }

  showChartData(countr_id?:any) {
   
    this.reportService.getCustomerUsageMetrics(this.params).subscribe(res => {
      this.totalRecords = 0;
      if (res) {
        this.chartResponse = res.data;
        paymentModeWisePaymentsData.dataset[0].data.filter((data, index) => {
          paymentModeWisePaymentsData.dataset[0].data[index].value = '';
          paymentModeWisePaymentsData.dataset[0].data[index].fullDate = '';
        })
        if (res.data) {
          Object.entries(res.data.jobsData).forEach(val => {
            paymentModeWisePaymentsData.categories[0].category.filter((labelData, index) => {
              if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
                paymentModeWisePaymentsData.dataset[0].data[index].value = val[1].toString();
                paymentModeWisePaymentsData.dataset[0].data[index].fullDate = val[0];
                this.totalRecords += val[1];
              }
            })
          });
        }
        this.monthlySubscriptionChartDataSource = paymentModeWisePaymentsData;
      }
    })
  }

  clientChange() {
    this.params['company_id'] = this.companyId;
    this.getCustomerUsageMetricsData();
  }

  onStatusChange(e: any) {
    this.params['date_range'] = e.value;
    this.getCustomerUsageMetricsData();
  }

  onModuleChange(e: any) {
    
    this.params['module_slug'] = e.value;
    this.getCustomerUsageMetricsData();
  }

  getCustomerUsageMetricsData() {
    if (this.params['company_id']) {
      paymentModeWisePaymentsData.chart.caption = this.params.module_slug;
      this.showChartData();
      }
    }
}
