<h1 mat-dialog-title class=text-uppercase>Customer Details</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <div fxLayout="row wrap" fxLayoutAlign="start baseline" class='line-height-32' *ngIf="data">
    <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
        Company Name:
      </div>
      <div fxFlex="45" class=accent-fg>
        {{data.company_name}}
      </div>
    </div>
    <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
        Sub Domain:
      </div>
      <div fxFlex="45" class=accent-fg>
        {{data.sub_domain_name}}
      </div>
    </div>
    <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
        Contact Name:
      </div>
      <div fxFlex="45" class=accent-fg>
        {{data.first_name}} {{data.last_name}}
      </div>
    </div>


    <!-- <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
        Functional Role:
      </div>
      <div fxFlex="45" class=accent-fg>
        {{data.role}}
      </div>
    </div> -->

    <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
        Email:
      </div>
      <div fxFlex="45" class=accent-fg>
        {{data.email}}
      </div>
    </div>

    <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
        Contact Number:
      </div>
      <div fxFlex="45" class=accent-fg>
        {{data.contact_number}}
      </div>
    </div>

    <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
        Website:
      </div>
      <div fxFlex="45" class=accent-fg>
        {{data.website}}
      </div>
    </div>

    <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
       Company Location:
      </div>
      <div fxFlex="45" class='accent-fg text-uppercase'>
        {{data.region}}
      </div>
    </div>

    <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
        Interested In:
      </div>
      <div fxFlex="45" class=accent-fg>
        {{data.interested_app}}
      </div>
    </div>

    <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
        Location:
      </div>
      <div fxFlex="45" class=accent-fg>
        {{data.location}}
      </div>
    </div>

    <!-- <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
       Team Size:
      </div>
      <div fxFlex="45" class='accent-fg'>
        {{data.team_size}}
      </div>
    </div> -->


    <!-- <div fxFlex="50" fxLayout="row" fxLayoutAlign="space-between baseline">
      <div fxFlex="45" class='text-right  sub-heading'>
       Request For Demo:
      </div>
      <div fxFlex="45" class='accent-fg'>
        {{data.demo_date_time}} ({{data.time_zone}})
      </div>
    </div> -->

  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <!-- <button mat-flat-button class='primary mr-4'>Accept</button>
  <button mat-flat-button class='warn mr-4'>Reject</button>
  <button mat-flat-button class='red mr-4'>Delete</button> -->
  <button mat-flat-button mat-dialog-close>Ok</button>
</mat-dialog-actions>