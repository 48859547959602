import { Component, ViewEncapsulation } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent {
    date: Date;
    events: any[];
    notes: any[];
    settings: any;
    sidebarItems = [
        { name: 'Dashboard', icon: 'home', slug: 'dashboard' },
        { name: 'Signup Requests', icon: 'person_add', slug: 'signup_requests' },
        { name: 'Clients', icon: 'people', slug: 'clients' },
        { name: 'Trial Companies', icon: 'business', slug: 'trial' },
        { name: 'Subscriptions', icon: 'scatter_plot', slug: 'addons' },
        { name: 'Cron Jobs', icon: 'watch', slug: 'cronjobs' },
        { name: 'Users', icon: 'people', slug: 'cpanel_users' },
        { name: 'Bounced Search', icon: 'search', slug:'bounced_search' },
        { name: 'Queue Logs', icon: 'search', slug:'queue_logs' }
    ]
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
    ) {
        // Set the defaults
        this.date = new Date();
        this.settings = {
            notify: true,
            cloud: false,
            retro: true
        };
    }
    close() {
        this._fuseSidebarService.getSidebar('quickPanel').close();
    }
}
