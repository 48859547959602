import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

    constructor(public auth: CommonService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.auth.getToken();
        request = request.clone({ headers: request.headers.set('Content-Encoding', 'gzip, deflate') });
        request = request.clone({ headers: request.headers.set('X-Requested-With', 'XMLHttpRequest') });
        request = request.clone({
            url: environment.apiUrl + request.url,
            // headers: request.headers.set('Content-Type', 'application/json multipart/form-data')
            //     .set('Accept', 'application/json multipart/form-data')
            //     .set('Access-Control-Allow-Origin', '*')
        })
        if (token) {
            request = request.clone({
                headers: request.headers.set(
                    'Authorization', `Bearer ${token}`
                )
            });
        }
        return next.handle(request);
    }
}