import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-convert-free-licenses-to-paid',
  templateUrl: './convert-free-licenses-to-paid.component.html',
  styleUrls: ['./convert-free-licenses-to-paid.component.scss']
})

export class ConvertFreeLicensesToPaidComponent implements OnInit {
  show_title: any;
  AddLicense: FormGroup;
  available_licenses: any;
  pro_rate_amount: any = 0;
  licensesProrateArr: any;
  licence_cost_per_month: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    public companyDetailService: CompanyDetailsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ConvertFreeLicensesToPaidComponent>,
    public dialog: MatDialog
  ) {
    this.AddLicense = this.fb.group({
      'total_licenses': [0, Validators.required],
      'amount': [this.pro_rate_amount, Validators.required],
    });
  }

  ngOnInit() {
    this.show_title = this.data.license_title;
    this.available_licenses = this.data.free_licenses;
    this.AddLicense.get('total_licenses').setValidators(Validators.max(this.available_licenses));
    this.AddLicense.updateValueAndValidity();
    this.getProRate();
  }

  getProRate() {
    this.companyDetailService.get_pro_rate({ company_id: this.data.company_id, sb_plan_slug: this.data.sb_plan_slug }).subscribe(res => {
      if (res.status == 1) {
        this.licensesProrateArr = res.data.licensesProrateArr;
        console.log(this.licensesProrateArr);
        this.licence_cost_per_month = this.licensesProrateArr[this.data.billing_cycle][this.data.sb_plan_slug][this.data.addonSlug];
      }
    });
  }

  convertLinceses() {
    if (this.AddLicense.valid) {

      if (this.AddLicense.value.total_licenses <= 0) {
        this.commonService.openSnackBar('warn', 'Enter at least one license');
        return;
      }

      if (this.AddLicense.value.total_licenses > this.available_licenses) {
        this.commonService.openSnackBar('warn', 'Entered licenses are not more than available free licenses');
        return;
      }

      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? you want to convert free licenses to paid licenses';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35%',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let params = {};
          params['company_id'] = this.data.company_id;
          params['convert_licenses'] = this.AddLicense.value.total_licenses;
          // params['amount'] = this.AddLicense.value.amount;
          params['addon_slug'] = this.data.addonSlug;
          params['free_licenses'] = this.available_licenses;
          // params['per_license_cost'] = this.data.per_license_cost;
          params['payment_gateway'] = this.data.payment_gateway;
          params['package'] = this.data.package;

            this.companyDetailService.convertLicensesToPaid(params).subscribe(res => {
              if (res.status == 1) {
                this.commonService.openSnackBar('primary', res.message);
              } else {
                this.commonService.openSnackBar('warn', res.message);
              }
              this.dialogRef.close(1);
            });
        } else { }
      })
    }
  }

  LicensesAdded() {
    let licenses = this.AddLicense.value.total_licenses;
    if (licenses > this.available_licenses) {
      this.AddLicense.patchValue({ 'amount': '' });
      return false;
    }
    this.pro_rate_amount = (licenses * this.licence_cost_per_month).toFixed(2);
    console.log(this.pro_rate_amount);
    this.AddLicense.patchValue({ 'amount': this.pro_rate_amount });
  }

}
