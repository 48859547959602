<h1 mat-dialog-title class='text-uppercase'>Reset Password</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <div class="py-16 line-height-32" fxLayout="row wrap" fxLayoutAlign="center start">
    <div fxFlex="70" class='pb-12'>
      <mat-form-field class="w-100-p" appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password">
      </mat-form-field>
    </div>
    <div fxFlex="70">
      <mat-form-field class="w-100-p" appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input matInput type="password">
      </mat-form-field>
    </div>
    <div fxFlex="70">
      <mat-checkbox color="primary" class="sub-heading">Enforce user to change password when login</mat-checkbox>
    </div>
    <div fxFlex="70">
      <ul class="list-style-none sub-heading">
        <li>
          <mat-icon class="s-16 border-radius-100 primary mr-8">done</mat-icon> Atleast 8 characters long
        </li>
        <li>
          <mat-icon class="s-16 border-radius-100 primary mr-8">done</mat-icon> One lowercase character
        </li>
        <li>
          <mat-icon class="s-16 border-radius-100 primary mr-8">done</mat-icon> One uppercase character
        </li>
        <li>
          <mat-icon class="s-16 border-radius-100 primary mr-8">done</mat-icon> Atleast one Number and Special character
        </li>
      </ul>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-flat-button class='primary'>Save</button>
</mat-dialog-actions>