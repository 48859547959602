import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActiveSubscriptionPaymentReportComponent } from './active-subscription-payment-report/active-subscription-payment-report.component';
import { ClientRefundsComponent } from './client-refunds/client-refunds.component';
import { ClientSubscriptionsComponent } from './client-subscriptions/client-subscriptions.component';
import { PaymentReportsComponent } from './payment-reports/payment-reports.component';
import { PowerbiReportsComponent } from './powerbi-reports/powerbi-reports.component';
import { ReportsComponent } from './reports.component';
import { RevenueForecostComponent } from './revenue-forecost/revenue-forecost.component';
import { RevenueForecastingComponent } from './revenue-forecasting/revenue-forecasting.component';
import { SalesExecutivePaymentsReportComponent } from './sales-executive-payments-report/sales-executive-payments-report.component';
import { UnlimitedMrrComponent } from './unlimited-mrr/unlimited-mrr.component';
import { DueCustomersComponent } from './due-customers/due-customers.component';
import { UsersReportComponent } from './users-report/users-report.component';
import { TdsReportComponent } from './tds-report/tds-report.component';

const routes: Routes = [
  {
    path: '',
    component: ReportsComponent
  },
  {
    path: ':report_slug',
    component: PowerbiReportsComponent
  },
  {
    path: 'listing/clientSubscriptions',
    component: ClientSubscriptionsComponent
  },
  {
    path: 'listing/payments',
    component: PaymentReportsComponent
  },
  {
    path: 'listing/subscriptionRevenue',
    component: ActiveSubscriptionPaymentReportComponent
  },
  {
    path: 'listing/clientRefunds',
    component: ClientRefundsComponent
  },
  {
    path: 'listing/unlimitedSubscriptionMRR',
    component: UnlimitedMrrComponent
  },
  {
    path: 'listing/salesExecutivePayments',
    component: SalesExecutivePaymentsReportComponent
  },
  {
    path: 'listing/revenueForecast',
    // component: RevenueForecostComponent
    component: RevenueForecastingComponent
  },
  {
    path: 'listing/dueCustomers',
    component: DueCustomersComponent
  },
  {
    path: 'listing/usersReport',
    component: UsersReportComponent
  },
  {
    path: 'listing/tdsReport',
    component: TdsReportComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
