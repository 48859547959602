<h1 mat-dialog-title class='text-uppercase'>Update Payment Info</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
    <form [formGroup]="updateform">
        <div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill class="mb-12">
                <div fxFlex='150px'>
                    Payment Mode
                </div>
                <div fxFlex>
                    <mat-form-field class="w-100-p" appearance='outline'>
                        <mat-select formControlName="payment_mode" (selectionChange)='paymentChange()'>
                            <mat-option value=''>Select</mat-option>
                            <ng-container *ngFor="let item of payment">
                                <mat-option [value]="item.value">
                                    {{item.name}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill class="mb-12">
                <div fxFlex='150px'>
                   Total Amount
                </div>
                <div fxFlex class="font-size-18 text-bold accent-fg">
                    {{amount}}
                </div>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill class="mb-12" *ngIf="showtxn">
                <div fxFlex='150px'>
                    Transaction ID
                </div>
                <div fxFlex>
                    <mat-form-field class="w-100-p mb-8" appearance="outline">
                        <mat-label> Transaction ID</mat-label>
                        <input type="text" matInput formControlName="txn_id">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
    <button mat-flat-button class='primary' (click)="update()" [disabled]='amount == "undefined"' >Update</button>
</mat-dialog-actions>