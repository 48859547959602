import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { CompanyDetailsService } from 'app/services/company-details.service';


@Component({
  selector: 'app-data-backup',
  templateUrl: './data-backup.component.html',
  styleUrls: ['./data-backup.component.scss']
})
export class DataBackupComponent implements OnInit {

  displayedColumns: string[] = ['customer_name', 's3_status', 'data_status', 'created_at', 'updated_at'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  company_id: any;
  params: { page: number; limit?: number, searchKey?: any, company_id?: any };
  DataBackupFrom: FormGroup;
  initiateBackup: boolean = true;
  isLostAccount:any;

  constructor(private fb: FormBuilder,
    private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    public router: Router, private route: ActivatedRoute,public dialog: MatDialog) {
    this.company_id = this.route.parent.snapshot.params['id'];
    // this.DataBackupFrom = this.fb.group({
    //   enable_data_backup: []
    // })
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1, company_id: this.company_id };
    this.getList();
  }
  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.commonService.dataBackupRequests(this.params).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data.data);
        this.initiateBackup = res.initiate_backup;
        this.isLostAccount = res.isAccountLost;
        this.length = res.data.total;
        this.pageSize = res.data.per_page;
        this.index = res.data.current_page;
      }
    })
  }
  navigate(element, blank) {
    // this.router.navigate(['company_details', element.company_id]);
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }

  onChange(column_name) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? You want to Initiate Data Backup request';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = {};
        params['column_name'] = column_name;
        params['company_id'] = this.company_id;
        params['ctrl_value'] = 1;
        this.companyDetailService.updateIntegrationCtrls(params).subscribe(res => {
          if (res.data.status == 1) {
            this.commonService.openSnackBar('primary', res.data.message);
            this.getList();
          } else {
            this.commonService.openSnackBar('warn', res.data.message);
          }
        });
      } else { }
    })
  }

  showActivities() {
    let activityData ={
      'company_id':this.company_id,
      'module_slug':'dataBackup',
      'show_company_name':false,
      'module_name':'Data Backup'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
