<mat-toolbar class="p-0 border">

    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center" class="px-20">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg">
                </div>
            </div>

            <!-- <button mat-icon-button class="" (click)="toggleSidebarOpen('quickPanel')" aria-label="Toggle quick panel"
                class="mr-12">
                <mat-icon class="secondary-text">sort</mat-icon>
            </button> -->
            <button mat-icon-button [matMenuTriggerFor]="navigationMenu" class="mr-12">
                <mat-icon>sort</mat-icon>
            </button>
            <mat-menu #navigationMenu="matMenu" [overlapTrigger]="false" class="w-532">
                <div class="px-12 pb-8">
                    <div class="top-nav-menu">
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="2%" class="pb-12"
                            *ngIf="home_permissions">

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px"
                                *ngIf="home_permissions['dashboard.view']">
                                <button mat-menu-item routerLink="dashboard" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Dashboard">
                                    <mat-icon class="s-20">home</mat-icon>
                                    <span class="font-size-14">Dashboard</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px"
                                *ngIf="home_permissions['signup_request.view']">
                                <button mat-menu-item routerLink="signup_requests" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Signup Requests">
                                    <mat-icon class="s-20">person_add</mat-icon>
                                    <span class="font-size-14">Signup Requests</span>
                                </button>
                            </div>

                            <!-- <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="demoRequests" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Demo Requests">
                                    <mat-icon class="s-20">person_add</mat-icon>
                                    <span class="font-size-14">Demo Requests</span>
                                </button>
                            </div> -->

                            <!-- <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="12px">
                                <button mat-menu-item routerLink="leadOwners" [routerLinkActive]="['primary-fg']"
                                    class="px-4">
                                    <mat-icon class="s-20">people</mat-icon>
                                    <span class="font-size-14">Lead Owners</span>
                                </button>
                            </div> -->

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px"
                                *ngIf="home_permissions['clients.view']">
                                <button mat-menu-item routerLink="clients" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Clients">
                                    <mat-icon class="s-20">people</mat-icon>
                                    <span class="font-size-14">Clients</span>
                                </button>
                            </div>

                            <!-- <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="home_permissions['clients.refunds']">
                                <button mat-menu-item routerLink="clientRefunds" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Client Refunds">
                                    <mat-icon class="s-20">attach_money</mat-icon>
                                    <span class="font-size-14">Client Refunds</span>
                                </button>
                            </div> -->

                            <!-- <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="home_permissions['clients.subsriptions']">
                                <button mat-menu-item routerLink="client_subscriptions" [routerLinkActive]="['primary-fg']"
                                    class="px-4"  matTooltip="Client Subscriptions">
                                    <mat-icon class="s-20">people</mat-icon>
                                    <span class="font-size-14">Client Subscriptions</span>
                                </button>
                            </div> -->

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="migrationRequests" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Migration Requests">
                                    <mat-icon class="s-20">compare_arrows</mat-icon>
                                    <span class="font-size-14">Migration Requests</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="data_backup_requests"
                                    [routerLinkActive]="['primary-fg']" class="px-4" matTooltip="Backup Requests">
                                    <mat-icon class="s-20">backup</mat-icon>
                                    <span class="font-size-14">Backup Requests</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="file_storage_details"
                                    [routerLinkActive]="['primary-fg']" class="px-4" matTooltip="FTP Details">
                                    <mat-icon class="s-20">details</mat-icon>
                                    <span class="font-size-14">FTP Details</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="company_ftp_files" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Company FTP Files">
                                    <mat-icon class="s-20">details</mat-icon>
                                    <span class="font-size-14">FTP Files</span>
                                </button>
                            </div>

                            <!-- <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="payment_reports" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="FTP Details">
                                    <mat-icon class="s-20">format_list_bulleted</mat-icon>
                                    <span class="font-size-14">Payment Reports</span>
                                </button>
                            </div> -->

                            <!-- <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="reports/listing/subscriptionRevenue" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Subscription Revenue">
                                    <mat-icon class="s-20">format_list_bulleted</mat-icon>
                                    <span class="font-size-14">Subscription Revenue</span>
                                </button>
                            </div> -->

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="subscriptionInvoices"
                                    [routerLinkActive]="['primary-fg']" class="px-4" matTooltip="Subscription Invoices">
                                    <mat-icon class="s-20">format_list_bulleted</mat-icon>
                                    <span class="font-size-14">Subscription Invoices</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="cpanelActivities" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Cpanel Activities">
                                    <mat-icon class="s-20">format_list_bulleted</mat-icon>
                                    <span class="font-size-14">Activities</span>
                                </button>
                            </div>

                            <div fxFlex="31" *ngIf="home_permissions['users']" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="reports" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Reports">
                                    <mat-icon class="s-20">format_list_bulleted</mat-icon>
                                    <span class="font-size-14">Reports</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="implemenationListing" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Implementations">
                                    <mat-icon class="s-20">format_list_bulleted</mat-icon>
                                    <span class="font-size-14">Implementation</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="migrationsListing" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Implementations">
                                    <mat-icon class="s-20">format_list_bulleted</mat-icon>
                                    <span class="font-size-14">Data Migration</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="creditBalanceTransactions" [routerLinkActive]="['primary-fg']"
                                    class="px-4">
                                    <mat-icon class="s-20">format_list_bulleted</mat-icon>
                                    <span class="font-size-14">Offline Payments</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="usage-metrics" [routerLinkActive]="['primary-fg']"
                                    class="px-4">
                                    <mat-icon class="s-20">format_list_bulleted</mat-icon>
                                    <span class="font-size-14">Usage Metrics</span>
                                </button>
                            </div>

                            <!-- <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="home_permissions['exit_requests.view']">
                                <button mat-menu-item routerLink="exit_requests" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Exit Requests">
                                    <mat-icon class="s-20">exit_to_app</mat-icon>
                                    <span class="font-size-14">Exit Requests</span>
                                </button>
                            </div> -->
                        </div>
                    </div>
                </div>

            </mat-menu>
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="logo">
                    <img class="logo-icon" src="assets/images/logo/logo.svg">
                </div>
            </div>
            <!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->

        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <div class="mr-8" *ngIf="home_permissions['dashboard.view']">
                <button mat-icon-button matTooltip="Dashboard" [routerLink]="['/dashboard']">
                    <mat-icon>home</mat-icon>
                </button>
            </div>
            <div class="mr-8" *ngIf="home_permissions['users']">
                <button mat-icon-button matTooltip="Configurations" [matMenuTriggerFor]="settings">
                    <mat-icon>settings</mat-icon>
                </button>
            </div>
            <mat-menu #settings="matMenu" [overlapTrigger]="false" class="w-532">
                <div class="px-12 pb-8">
                    <div class="top-nav-menu">
                        <div fxLayout="row" fxLayoutAlign="start center" class="top-nav-title">
                            <h3 class="text-bold font-size-16 my-8">Configurations</h3>
                        </div>
                        <mat-divider></mat-divider>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="2%" *ngIf="home_permissions">

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="12px"
                                *ngIf="home_permissions['subscription']">
                                <button mat-menu-item routerLink="configuration/addons" [routerLinkActive]="['primary-fg']"
                                    class="px-4">
                                    <mat-icon class="s-20">scatter_plot</mat-icon>
                                    <span class="font-size-14">Subscriptions</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="12px"
                                *ngIf="home_permissions['cron_jobs']">
                                <button mat-menu-item routerLink="configuration/cronjobs" [routerLinkActive]="['primary-fg']"
                                    class="px-4">
                                    <mat-icon class="s-20">watch</mat-icon>
                                    <span class="font-size-14">Cron Jobs</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="12px"
                                *ngIf="home_permissions['users']">
                                <button mat-menu-item routerLink="configuration/cpanel_users" [routerLinkActive]="['primary-fg']"
                                    class="px-4">
                                    <mat-icon class="s-20">people</mat-icon>
                                    <span class="font-size-14">Users</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="12px"
                                *ngIf="home_permissions['users']">
                                <button mat-menu-item routerLink="configuration/email_communication"
                                    [routerLinkActive]="['primary-fg']" class="px-4">
                                    <mat-icon class="s-20">email</mat-icon>
                                    <span class="font-size-14">Send Mail</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="12px">
                                <button mat-menu-item routerLink="configuration/bounced_search" [routerLinkActive]="['primary-fg']"
                                    class="px-4">
                                    <mat-icon class="s-20">search</mat-icon>
                                    <span class="font-size-14">Bounced Search</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="12px">
                                <button mat-menu-item routerLink="configuration/queue_logs" [routerLinkActive]="['primary-fg']"
                                    class="px-4">
                                    <mat-icon class="s-20">search</mat-icon>
                                    <span class="font-size-14">Queue Logs</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="12px">
                                <button mat-menu-item routerLink="configuration/mail_templates" [routerLinkActive]="['primary-fg']"
                                    class="px-4">
                                    <mat-icon class="s-20">mail</mat-icon>
                                    <span class="font-size-14">Mail Templates</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="12px">
                                <button mat-menu-item routerLink="configuration/dataMigrationSettings" [routerLinkActive]="['primary-fg']"
                                    class="px-4">
                                    <mat-icon class="s-20">settings_applications</mat-icon>
                                    <span class="font-size-14">Data Migration Settings</span>
                                </button>
                            </div>
                            
                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="configuration/doccumentsListing" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Documents">
                                    <mat-icon class="s-20">details</mat-icon>
                                    <span class="font-size-14">Documents</span>
                                </button>
                            </div>
                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="configuration/unsubscribeListing" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="UnSubscribe List">
                                    <mat-icon class="s-20">unsubscribe</mat-icon>
                                    <span class="font-size-14">UnSubscribe List</span>
                                </button>
                            </div>

                            <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="configuration/proformaInvoice" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Proforma Invoice">
                                    <mat-icon class="s-20">format_list_bulleted</mat-icon>
                                    <span class="font-size-14">Proforma Invoice</span>
                                </button>
                            </div>

                            <!-- <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="20px">
                                <button mat-menu-item routerLink="couponsListing" [routerLinkActive]="['primary-fg']"
                                    class="px-4" matTooltip="Coupons">
                                    <mat-icon class="s-20">details</mat-icon>
                                    <span class="font-size-14">Coupons</span>
                                </button>
                            </div> -->
                            <!-- <div fxFlex="31" fxLayoutAlign="start center" fxLayoutGap="12px" *ngIf="home_permissions['users']">
                                    <button mat-menu-item routerLink="maintenance" [routerLinkActive]="['primary-fg']"
                                        class="px-4">
                                        <mat-icon class="s-20">new_releases</mat-icon>
                                        <span class="font-size-14">Release Maintenance</span>
                                    </button>
                                </div> -->

                        </div>
                    </div>
                </div>
            </mat-menu>
            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" src="assets/images/profile-image.png">
                    <span class="username mr-12" fxHide fxShow.gt-sm
                        *ngIf="currentUser">{{currentUser.user_name}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

                <button mat-menu-item class="" (click)="navigate()">
                    <mat-icon class="s-18">vpn_key</mat-icon>
                    <span>Change Password</span>
                </button>

                <button mat-menu-item class="" (click)="doLogout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>

            </mat-menu>
        </div>

    </div>

</mat-toolbar>