<ul fxLayout="row" class='nav'>
  <li><a (click)="getList('active')" [ngClass]="{'active': getlist == 'active'}">Active</a></li>
  <li><a (click)="getList('no_profile')" [ngClass]="{'active': getlist == 'no_profile'}">No Profile</a></li>
  <li><a (click)="getList('inactive')" [ngClass]="{'active': getlist == 'inactive'}">Inactive</a></li>
  <li><a (click)="getList('blocked')" [ngClass]="{'active': getlist == 'blocked'}">Blocked</a></li>
  <li><a (click)="getList('exit')" [ngClass]="{'active': getlist == 'exit'}">Exit</a></li>
  <li><a (click)="getList('pending')" [ngClass]="{'active': getlist == 'pending'}">Pending</a></li>
  <li><a (click)="getList('deleted')" [ngClass]="{'active': getlist == 'deleted'}">Deleted</a></li>
  <li><a (click)="params.module = 'user_activities';getList('activity');" [ngClass]="{'active': getlist == 'activity'}">User Activity</a></li>
</ul>
<ng-container *ngIf="dataSource && getlist != 'activity'">
  <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12">
    <div class="search-wrapper" fxFlex="30">
      <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
        [(ngModel)]="searchValue" placeholder="Search Name..">
      <mat-icon class="search_icon">search</mat-icon>
      <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
        close
      </mat-icon>
    </div>
    <div fxFlex='20' class="ml-80">
      <mat-form-field class="w-100-p" appearance="outline">
        <mat-label>Select</mat-label>
        <mat-select appMatSearchSelector class="w-100-p" [(ngModel)]="params.user_type" name="user_type" (selectionChange)="applyUsertTypeFilter(params.user_type)">
          <app-mat-search-selector placeholder='Search..'></app-mat-search-selector>
          <ng-container *ngFor="let topping of userTypeList">
            <mat-option *appMatSearchSelectorItems [value]="topping.id">
              {{topping.name}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
      <button class="warn mr-12" *ngIf="params.status == 1 && logginUser?.role_name == 'admin'" mat-stroked-button (click)="forceLogout({ company_id : company_id }, 'company_id')">Force Logout</button>
      <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
        [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
    </div>
  </div>
  <div class="px-12 pb-12">
    <mat-table [dataSource]="dataSource" *ngIf="dataSource.data.length > 0">
      <!-- name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.full_name}}
        </mat-cell>
      </ng-container>
      <!-- email Column -->
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let element" class="lowercase"> {{element.email}} </mat-cell>
      </ng-container>

      <!-- 	Status Column -->
      <ng-container matColumnDef="contactnumber">
        <mat-header-cell *matHeaderCellDef> Contact Number </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.contact_number?element.contact_number:'-'}}
        </mat-cell>
      </ng-container>

      <!-- role Column -->
      <ng-container matColumnDef="role">
        <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="element.is_super_admin == 1"> Super Administrator </span>
          <span *ngIf="element.is_super_admin == 0"> {{element.role_names?element.role_names:'-'}} </span>
        </mat-cell>
      </ng-container>

      <!-- 	createdbyColumn -->
      <ng-container matColumnDef="createdby">
        <mat-header-cell *matHeaderCellDef>Created By </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.created_by_name?element.created_by_name:'-'}}</mat-cell>
      </ng-container>

      <!-- createdby Column -->
      <ng-container matColumnDef="createdon">
        <mat-header-cell *matHeaderCellDef>Created on </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.created_at ? (element.created_at | date:"MM/dd/yyyy, h:mm a") : '-'}}
        </mat-cell>
      </ng-container>

      <!-- action Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="200px"> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="200px">

          <span *ngIf=" getlist == 'no_profile' ">-</span>

          <button mat-icon-button class="orange-fg" matTooltip="Revoke Access" *ngIf="element.status == 1 
           && getlist != 'no_profile' " (click)="revokeUser(element)" [disabled]="isLostAccount">
            <mat-icon class="s-18">voice_over_off</mat-icon>
          </button>

          <button mat-icon-button class="primary-fg" matTooltip="Active"
            *ngIf="getlist != 'no_profile' && (element.status == 0 || element.status == 4 || element.status == 2 )"
            (click)="revokeUser(element)" [disabled]="isLostAccount">
            <mat-icon class="s-18">how_to_reg</mat-icon>
          </button>

          <!-- <button mat-icon-button class="primary-fg" matTooltip="Convert as employee" *ngIf="element.is_employee != 1 &&  getlist != 'exit' && getlist != 'no_profile' " (click) = "convertAsEmployee(element)">
            <mat-icon class="s-18">autorenew</mat-icon>
          </button> -->

          <button mat-icon-button class="warn-fg" matTooltip="Delete"
            *ngIf="element.status != 4 && element.is_super_admin != 1 && getlist != 'no_profile' && (permissions && permissions['clients.users.delete']) "
            (click)="deleteUser(element)" [disabled]="isLostAccount">
            <mat-icon class="s-18">clear</mat-icon>
          </button>

          <button mat-icon-button class="primary-fg" matTooltip="Send activation email"
            *ngIf="element.status == -1 && getlist != 'no_profile'" (click)="sendActivationEmail(element)" [disabled]="isLostAccount">
            <mat-icon class="s-18">contact_mail</mat-icon>
          </button>

          <button mat-icon-button class="primary-fg" matTooltip="Change Password" (click)="changePassword(element.id)"
            *ngIf="element.status != -1 && element.status != 3 && element.status != 4  && getlist != 'no_profile' " [disabled]="isLostAccount">
            <mat-icon class="s-18">vpn_key</mat-icon>
          </button>
          <!--  -->
          <button mat-icon-button class="primary-fg" matTooltip="Disable Multi Factor Authentication" (click)="showMfaPopup(element.email)"  *ngIf="checkHavemfa(element.email)" [disabled]="isLostAccount">
            <mat-icon class="s-18">sync_disabled</mat-icon>
          </button>

          <button mat-icon-button class="warn-fg" matTooltip="Logout user" *ngIf="params.status == 1 && logginUser?.role_name == 'admin'" (click)="forceLogout({ global_user_id : element.global_user_id }, 'global_user_id')">
            <mat-icon class="s-18">power_settings_new</mat-icon>
          </button>

        </mat-cell>
      </ng-container>

      <!-- 	partnercompaniesColumn -->
      <!-- <ng-container matColumnDef="partnerCompanies">
        <mat-header-cell *matHeaderCellDef>Partner Companies </mat-header-cell>
        <mat-cell *matCellDef="let element"> -
        </mat-cell>
      </ng-container> -->

      <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div *ngIf="dataSource.data.length == 0" class="text-center">
      <h2 class="heading m-0 py-16 border">No Records Found</h2>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="dataSource && getlist == 'activity'">
  <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12">
    <div class="search-wrapper" fxFlex="30">
      <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
        [(ngModel)]="searchValue" placeholder="Activity type, Description" [disabled]="isLostAccount">
      <mat-icon class="search_icon">search</mat-icon>
      <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
        close
      </mat-icon>
    </div>
    <div fxFlex='20' class="ml-80">
      <mat-form-field class="w-100-p" appearance="outline">
        <mat-label>Select Modules</mat-label>
        <mat-select appMatSearchSelector class="w-100-p" [(ngModel)]="params.module" name="module_id" (selectionChange)="getList('activity')">
          <app-mat-search-selector placeholder='Search..'></app-mat-search-selector>
          <ng-container *ngFor="let topping of modulesList">
            <mat-option *appMatSearchSelectorItems [value]="topping.id">
              {{topping.name}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
      <button mat-icon-button  (click)="refresh()" class="mr-4" [disabled]="isLostAccount">
        <mat-icon>refresh</mat-icon>
      </button>
      <button mat-icon-button (click)="filter()" matTooltip="Filter" class="grey-800-border" style="border:1px solid" [disabled]="isLostAccount">
        <mat-icon class="s-18">filter_list</mat-icon>
      </button>
      <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
        [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
    </div>
  </div>
  <div class="px-12 pb-12">
    <mat-table [dataSource]="dataSource">
      <!-- module Column -->
      <ng-container matColumnDef="module">
        <mat-header-cell *matHeaderCellDef> module </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.module_name}}
        </mat-cell>
      </ng-container>
      <!-- record Column -->
      <ng-container matColumnDef="record">
        <mat-header-cell *matHeaderCellDef> record </mat-header-cell>
        <mat-cell *matCellDef="let element" class="lowercase"> {{element.table_field}} </mat-cell>
      </ng-container>

      <!-- 	user Column -->
      <ng-container matColumnDef="user">
        <mat-header-cell *matHeaderCellDef> user </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.created}}
        </mat-cell>
      </ng-container>

      <!-- activitytype Column -->
      <ng-container matColumnDef="activitytype">
        <mat-header-cell *matHeaderCellDef> activitytype </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.subject}} </mat-cell>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef fxFlex="280px"> description </mat-header-cell>
        <mat-cell *matCellDef="let element;let i = index" fxFlex="280px">
          <span *ngIf="(element.body + '').length > 50; else elseBlock">
            <span *ngIf="!more[i]">{{element.body | slice:0:50}} <a class="cursor-pointer"
                (click)="more[i] = !more[i]">More</a></span>
            <span *ngIf="more[i]" >{{element.body}} <a class="cursor-pointer"
                (click)="more[i] = !more[i]">Less</a></span>
          </span>
          <ng-template #elseBlock >{{element.body}}</ng-template>
        </mat-cell>
      </ng-container>

      <!-- updatedon Column -->
      <ng-container matColumnDef="updatedon">
        <mat-header-cell *matHeaderCellDef> updatedon </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.created_at | date:"MM/dd/yyyy, h:mm a" }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns1;sticky:true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns1;"></mat-row>
    </mat-table>
    <div *ngIf="dataSource.data.length == 0" class="text-center">
      <h2 class="heading m-0 py-16 border">No Records Found</h2>
    </div>
  </div>
</ng-container>
<div class="footer">
  <!-- <button mat-button (click)="addUser()">Add User</button> -->
  <button mat-button (click)="showActivities()">Activities</button>

</div>