<h1 mat-dialog-title class='text-uppercase'>Edit Addon</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <form [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">

      <div fxFlex='100'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Name</mat-label>
          <input type="text" formControlName="name" matInput>
        </mat-form-field>
      </div>

      <div fxFlex='100'>
          <mat-form-field class="w-100-p" appearance='outline'>
            <mat-label>Description</mat-label>
            <textarea matInput rows="6" formControlName="description"></textarea>
          </mat-form-field>
        <!-- <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Description</mat-label>
          <input type="text" formControlName="description" matInput>
        </mat-form-field> -->
      </div>

      <div fxFlex='100'>
        <mat-form-field class="w-100-p" appearance='outline'>
            
          <mat-label>Other Information</mat-label>
          <textarea matInput rows="6" formControlName="other_information"></textarea>
          
        </mat-form-field>
      </div>
      
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-flat-button class='primary' (click)="save()">Save</button>
</mat-dialog-actions>