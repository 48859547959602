import { Component, OnInit, Inject, Injectable, ViewChild, ElementRef, ViewChildren } from '@angular/core';
import { FormArray,FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import common from 'app/services/common';
import { ValidateService } from 'app/services/validate.service';
import { CommonService } from 'app/services/common.service';
import { isEmpty } from 'lodash';
import { CompanyDetailsService } from 'app/services/company-details.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-send-mail-dialogue',
  templateUrl: './send-mail-dialogue.component.html',
  styleUrls: ['./send-mail-dialogue.component.scss']
})
export class SendMailDialogueComponent implements OnInit {

  filteredccEmails: any;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emailData:any;
  users:any;
  ckeditor: any;
  form: FormGroup;
  config = common.editorconfig;
  sales_emails:any;
  @ViewChild('cc_emails') cc_emails: ElementRef<HTMLInputElement>;


  constructor( public dialogRef: MatDialogRef<SendMailDialogueComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private CommonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    private fb: FormBuilder,
    private validateservice: ValidateService,
    ) {
      this.form = this.fb.group({
        check_data1: ['true'],
        subject: ['', Validators.required],
        mail_body: ['', Validators.required],
        
        sales_emails:[''],
        cc_emails:this.fb.array([])
      });
     }
    
  ngOnInit() {
    this.emailData = this.data.emailData;
    this.users = this.data.users;
    this.form.get('subject').setValue(this.emailData.subject);
    this.form.get('mail_body').setValue(this.emailData.body);
    if(this.data?.cc_emails)
    {
      
      var formArray = this.form.get('cc_emails') as FormArray;
      formArray.push(this.fb.control(this.data?.cc_emails));

    }
      
  }

  salesUserChange() {
    this.sales_emails = this.form.value.sales_emails ? this.form.value.sales_emails.map(x => x).join(",") : '';
  }

  sendEmail() {
    if (this.form.valid) {
      let params = {};
      params['body'] = this.form.value.mail_body;
      params['subject'] = this.form.value.subject;
      params['sales_emails'] = this.sales_emails;
      params['cc_emails'] = this.form.value.cc_emails;
      params['emails'] = this.data.emails;
      params['company_id'] = this.data.company_id;
      params['invoice_id'] = this.data?.invoice_id;
      
      params['module'] = this.data.module;
      params['sendToCustomer'] = this.form.value['check_data1'];
      if (!isEmpty(this.data.stage)) {
        params['stage'] = this.data.stage;
        params['stageName'] = this.data.stageName;
      }
      this.companyDetailService.sendDialogueEmail(params).subscribe(res => {
          if(res.status == 1){
            this.CommonService.openSnackBar('primary',res.message);
          } else {
            this.CommonService.openSnackBar('warn', res.message);
          }
          this.dialogRef.close(1);
      })
    }
    else {
      this.validateservice.validateAllFormFields(this.form);
    }
  }

  onReady(e) {
    this.ckeditor = e.editor;
  }

  add(event: MatChipInputEvent, formArrName?: any): void {
    const value = (event.value || '').trim();

    if (value) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let validEmail = re.test(String(value).toLowerCase());
      if (validEmail) {
        const formArray = this.form.get(formArrName) as FormArray;
        formArray.push(this.fb.control(value));
        // this.cc_emails.nativeElement.value = '';
      } else {
        event.chipInput!.clear();
        // delete this.filteredccEmails;
      }
    }
    // Clear the input value
    event.chipInput!.clear();
  }

  selected(event: MatAutocompleteSelectedEvent, formArrName: any): void {
    const formArray = this.form.get(formArrName) as FormArray;
    formArray.push(this.fb.control(event.option.value));
    // this.toEmail.nativeElement.value = '';
    // this.bccEmail.nativeElement.value = '';
    this.cc_emails.nativeElement.value = '';
  }
  remove(chipValue: string, field: string): void {
    const control = <FormArray>this.form?.get(field);
    const index = control.value.indexOf(chipValue);

    if (index >= 0) {
      control.removeAt(index);
    }
  }

}
