<div class="page-layout carded fullwidth inner-scroll" *ngIf="responseData">
  <mat-toolbar-row class="secondary-header">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold text-capitalize active">Billing Details</a>
      </li>
    </ul>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-20 mb-52">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="px-12 py-8">
        <a>{{CompanyDetails.name}} ({{CompanyDetails.subscription_type}})</a>
        <a>Frequency : {{responseData.frequency}}</a>
      </div>
      <mat-divider></mat-divider>
      <div class="content" fusePerfectScrollbar *ngIf="!next">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" class='p-16'>
          <div fxFlex='72' fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="16px grid">
            <div fxFlex='50'>
              <mat-card class="p-0">
                <mat-card-header class="px-16 border-top-radius-4 grey-200" fxLayout="row wrap"
                  fxLayoutAlign="space-between center">
                  <mat-card-title class="m-0 muli-bold">Hire User Licenses</mat-card-title>
                  <button mat-icon-button (click)="UserLicences('hire')">
                    <mat-icon class="s-18">add</mat-icon>
                  </button>
                </mat-card-header>
                <mat-card-content class="px-16 py-8">
                  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class='line-height-28'>
                    <div fxFlex='30' class='text-right sub-heading'>Availbale</div>
                    <div fxFlex='65'>
                      <a>{{responseData.available_users}}/{{responseData.existing_users_count}} User Licences</a>
                    </div>
                    <div fxFlex='30' class='text-right sub-heading'>Used</div>
                    <div fxFlex='65'>
                      <a>{{responseData.used_user_credits + responseData.addon_user_used_credits}}</a>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div fxFlex='50'>
              <mat-card class="p-0">
                <mat-card-header class="px-16 border-top-radius-4 grey-200" fxLayout="row wrap"
                  fxLayoutAlign="space-between center">
                  <mat-card-title class="m-0 muli-bold">HR User Licenses</mat-card-title>
                  <button mat-icon-button (click)="UserLicences('hr')">
                    <mat-icon class="s-18">add</mat-icon>
                  </button>
                </mat-card-header>
                <mat-card-content class="px-16 py-8">
                  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class='line-height-28'>
                    <div fxFlex='30' class='text-right sub-heading'>Availbale</div>
                    <div fxFlex='65'>
                      <a>{{responseData.available_users}}/{{responseData.existing_users_count}} User Licences</a>
                    </div>
                    <div fxFlex='30' class='text-right sub-heading'>Used</div>
                    <div fxFlex='65'>
                      <a>{{responseData.used_user_credits + responseData.addon_user_used_credits}}</a>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>


            <div fxFlex='50'>
              <mat-card class="p-0">
                <mat-card-header class="px-16 border-top-radius-4 grey-200" fxLayout="row wrap"
                  fxLayoutAlign="space-between center">
                  <mat-card-title class="m-0 muli-bold">CRM User Licenses</mat-card-title>
                  <button mat-icon-button (click)="UserLicences('crm')">
                    <mat-icon class="s-18">add</mat-icon>
                  </button>
                </mat-card-header>
                <mat-card-content class="px-16 py-8">
                  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class='line-height-28'>
                    <div fxFlex='30' class='text-right sub-heading'>Availbale</div>
                    <div fxFlex='65'>
                      <a>{{responseData.available_users}}/{{responseData.existing_users_count}} User Licences</a>
                    </div>
                    <div fxFlex='30' class='text-right sub-heading'>Used</div>
                    <div fxFlex='65'>
                      <a>{{responseData.used_user_credits + responseData.addon_user_used_credits}}</a>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

            <div fxFlex='50'>
              <mat-card class="p-0">
                <mat-card-header class="px-16 border-top-radius-4 grey-200" fxLayout="row wrap"
                  fxLayoutAlign="space-between center">
                  <mat-card-title class="m-0 muli-bold">Mass Mail Credits</mat-card-title>
                  <button mat-icon-button (click)="AddCredits('mass_mails')">
                    <mat-icon class="s-18">add</mat-icon>
                  </button>
                </mat-card-header>
                <mat-card-content class="px-16 py-8">
                  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class='line-height-28'>
                    <div fxFlex='30' class='text-right sub-heading'>Availbale</div>
                    <div fxFlex='65'>
                      <a>{{responseData.available_massmail_credits}}</a>
                    </div>
                    <div fxFlex='30' class='text-right sub-heading'>Used</div>
                    <div fxFlex='65'>
                      <a>{{ responseData.used_massmail_credits + responseData.addon_massmail_used_credits }}</a>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div fxFlex='50'>
              <mat-card class="p-0">
                <mat-card-header class="px-16 border-top-radius-4 grey-200" fxLayout="row wrap"
                  fxLayoutAlign="space-between center">
                  <mat-card-title class="m-0 muli-bold">Accounts Contact Credits</mat-card-title>
                  <button mat-icon-button (click)="AddCredits('contacts')">
                    <mat-icon class="s-18">add</mat-icon>
                  </button>
                </mat-card-header>
                <mat-card-content class="px-16 py-8">
                  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class='line-height-28'>
                    <div fxFlex='30' class='text-right sub-heading'>Availbale</div>
                    <div fxFlex='65'>
                      <a>{{responseData.available_account_contact_credits}}</a>
                    </div>
                    <div fxFlex='30' class='text-right sub-heading'>Used</div>
                    <div fxFlex='65'>
                      <a>{{ responseData.used_account_contacts_credits + responseData.addon_account_used_contact_credits }}</a>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>

          </div>
          <div fxFlex='26'>
            <mat-card class="p-0">
              <mat-card-header class="px-16 py-8 border-top-radius-4 primary">
                <mat-card-title class="m-0">Billing Details</mat-card-title>
              </mat-card-header>
              <mat-card-content class="px-16 py-8 line-height-28">
                <div fxLayout="row wrap" fxLayoutAlign="space-between center" class='line-height-28'>
                  <div fxFlex='70' class='sub-heading text-capitalize'>User Licences cost</div>
                  <div fxFlex='30' class="text-right">
                    <a>{{ responseData.user_licenses_cost | number : '1.2-2'}} $</a>
                  </div>
                  <div fxFlex='70' class='sub-heading text-capitalize'>Mass mail Licences cost</div>
                  <div fxFlex='30' class="text-right">
                    <a>{{ responseData.massmail_licenses_cost | number : '1.2-2'}} $</a>
                  </div>
                  <div fxFlex='70' class='sub-heading text-capitalize'>Account contact licences</div>
                  <div fxFlex='30' class="text-right">
                    <a>{{ responseData.account_contacts_licenses_cost | number : '1.2-2'}} $</a>
                  </div>

                  <div fxFlex='70' class='sub-heading text-capitalize' *ngIf="responseData.addon_cost">Pending Addons
                  </div>
                  <div fxFlex='30' class="text-right" *ngIf="responseData.addon_cost">
                    <a>{{ responseData.addon_cost | number : '1.2-2'}} $</a>
                  </div>

                  <div fxFlex='70' class='sub-heading text-capitalize' *ngIf="responseData.cart_items_cost">Pending
                    Addons</div>
                  <div fxFlex='30' class="text-right" *ngIf="responseData.cart_items_cost">
                    <a>{{ responseData.cart_items_cost | number : '1.2-2'}} $</a>
                  </div>

                  <div fxFlex='70' class='sub-heading text-capitalize' *ngIf="responseData.future_sub_addon_cost">
                    Pending Addons</div>
                  <div fxFlex='30' class="text-right" *ngIf="responseData.future_sub_addon_cost">
                    <a>{{ responseData.future_sub_addon_cost | number : '1.2-2'}} $</a>
                  </div>

                  <div fxFlex='70' class='sub-heading text-capitalize grey-200-bg mt-4'>Sub Total</div>
                  <div fxFlex='30' class="text-right grey-200-bg mt-4">
                    <a>{{subTotal | number : '1.2-2'}} $</a>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <div fxFlex='100'>
            <ul fxLayout="row" class='nav'>
              <li><a class='active'>Pending Addons</a></li>
            </ul>
            <mat-divider></mat-divider>
            <div class="mt-12">
              <mat-table [dataSource]="dataSource">
                <!-- customername Column -->
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef> name </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <a>{{addons_arr[element.addon_id]}}</a>
                  </mat-cell>
                </ng-container>
                <!-- quantity Column -->
                <ng-container matColumnDef="quantity">
                  <mat-header-cell *matHeaderCellDef> quantity</mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.available}}</mat-cell>
                </ng-container>

                <!-- cost Column -->
                <ng-container matColumnDef="cost">
                  <mat-header-cell *matHeaderCellDef> cost</mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.sub_total}} </mat-cell>
                </ng-container>

                <!-- startdate Column -->
                <ng-container matColumnDef="startdate">
                  <mat-header-cell *matHeaderCellDef> startdate</mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.start_date |date:"MM/dd/yyyy"}} </mat-cell>
                </ng-container>

                <!-- enddate Column -->
                <ng-container matColumnDef="enddate">
                  <mat-header-cell *matHeaderCellDef> enddate </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.end_date |date:"MM/dd/yyyy"}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
              <div *ngIf="dataSource.data.length == 0" class="text-center">
                <h2 class="heading m-0 py-16 border">No Records Found</h2>
              </div>
            </div>
          </div>
        </div>
        <div class='footer'>
          <button mat-button (click)="next = true;">Next</button>
          <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
          <button mat-button (click)="back()">Back</button>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar *ngIf="next">
        <div fxLayout="row wrap" fxLayoutAlign="start start" class='p-16' fxFlexFill fxLayoutGap="16px grid">
          <div fxFlex='40'>
            <mat-card class="p-0">
              <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
                fxLayoutAlign="space-between center">
                <mat-card-title class="m-0 muli-bold">Billing Details</mat-card-title>
              </mat-card-header>
              <mat-card-content class="px-16 py-8">
                <div>
                  <mat-form-field appearance="outline" class="w-100-p py-8">
                    <mat-label>Street</mat-label>
                    <input type="text" matInput>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-100-p py-8">
                    <mat-label>City</mat-label>
                    <input type="text" matInput>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-100-p py-8">
                    <mat-label>Country</mat-label>
                    <mat-select>
                      <mat-option *ngFor="let food of foods" [value]="food.value">
                        {{food.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-100-p py-8">
                    <mat-label>State</mat-label>
                    <mat-select>
                      <mat-option *ngFor="let food of foods" [value]="food.value">
                        {{food.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-100-p py-8">
                    <mat-label>Zip</mat-label>
                    <input type="text" matInput>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          <div fxFlex='40'>
            <mat-card class="p-0">
              <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
                fxLayoutAlign="space-between center">
                <mat-card-title class="m-0 muli-bold text-capitalize">Credit Card Information</mat-card-title>
                <span class="sub-heading">Total Amount : 949.28 $</span>
              </mat-card-header>
              <mat-card-content class="px-16 py-8">
                <div>
                  <mat-form-field appearance="outline" class="w-100-p py-8">
                    <mat-label>Card Number</mat-label>
                    <input type="text" matInput>
                  </mat-form-field>
                  <mat-label class="sub-heading w-100-p">Expiry Date</mat-label> <br />
                  <mat-form-field appearance="outline" class="w-50-p py-8">
                    <mat-label>Select Month</mat-label>
                    <mat-select>
                      <mat-option *ngFor="let food of foods" [value]="food.value">
                        {{food.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-50-p py-8">
                    <mat-label>Select Year</mat-label>
                    <mat-select>
                      <mat-option *ngFor="let food of foods" [value]="food.value">
                        {{food.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="w-100-p py-8">
                    <mat-label>Security Code</mat-label>
                    <input type="password" matInput>
                  </mat-form-field>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class='footer'>
          <button mat-button>Proceed</button>
          <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
          <button mat-button (click)="next = false;">Back</button>
        </div>
      </div>
    </div>
  </div>
</div>