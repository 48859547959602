<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold text-capitalize" [routerLink]="['trail_mode']" routerLinkActive="router-link-active"
          routerLinkActive="active">Trial Mode</a>
      </li>
      <li><a class="text-bold text-capitalize" [routerLink]="['paid']" routerLinkActive="router-link-active"
          routerLinkActive="active">Subscription Plans</a>
      </li>
      <li><a class="text-bold text-capitalize" [routerLink]="['edit_addons']" routerLinkActive="router-link-active"
          routerLinkActive="active">Addons</a>
      </li>
      <!-- <li><a class="text-bold text-capitalize" [routerLink]="['activities/trialMode']"
          routerLinkActive="router-link-active" routerLinkActive="active">Activities</a>
      </li> -->
    </ul>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-52">
      <div class="content" fusePerfectScrollbar>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>