<div class="page-layout carded fullwidth inner-scroll">
    <mat-toolbar-row class="secondary-headers w-100-p px-20">
        <h2 class="sub-heading">Usage Metrics(Client wise)</h2>
    </mat-toolbar-row>
    <div class="center px-20">
        <div class="content-card mt-12 mb-52">
            <div class="content" fusePerfectScrollbar>
                <!-- Chart-1 -->
                <div *ngIf="dataChartSource" fxLayout='row wrap' fxLayoutAlign="start center"
                    class="m-8 p-20 border border-radius-8">
                    <div *ngIf="chartResponse" fxFlex="100" fxLayout='row wrap' fxLayoutAlign="space-between center" class="mb-16">
                        <div>No of active users : <span class="primary-fg">{{chartResponse.activeUsers}}</span></div>
                        <div>No of users with activity : <span class="primary-fg">{{chartResponse.totalActiveUsers}}</span></div>
                        <div>No of users without activity : <span class="primary-fg">{{chartResponse.totalInactiveUsers}}</span></div>
                        <div>Total {{module_slug}} added: <span class="primary-fg">{{totalRecords}}</span></div>
                    </div>
                    <div fxLayout='row wrap' fxFlex="100" fxLayoutAlign="space-between center">
                        <div fxFlex="30">
                            <mat-form-field class="w-100-p" appearance="outline">
                                <mat-label>Select Company</mat-label>
                                <mat-select appMatSearchSelector [(ngModel)]="companyId" (selectionChange)="clientChange()">
                                  <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                                  <ng-container *ngFor="let topping of toppingList">
                                    <mat-option *appMatSearchSelectorItems [value]="topping.id">{{topping.display_name}}
                                    </mat-option>
                                  </ng-container>
                                </mat-select>
                              </mat-form-field>
                        </div>
                        <div fxFlex="30">
                            <mat-form-field class="w-100-p" appearance="outline">
                              <mat-label>Date Range</mat-label>
                              <mat-select appMatSearchSelector class="w-100-p"
                                  (selectionChange)="onStatusChange($event)" [(ngModel)]="date_range">
                                  <app-mat-search-selector placeholder='Search..'></app-mat-search-selector>
                                  <ng-container *ngFor="let status of statusList">
                                      <mat-option *appMatSearchSelectorItems [value]="status.status">
                                          {{status.name}}
                                      </mat-option>
                                  </ng-container>
                              </mat-select>
                          </mat-form-field>
                        </div>
                        <div fxFlex="30">
                            <mat-form-field class="w-100-p" appearance="outline">
                                <mat-label>Module</mat-label>
                                <mat-select appMatSearchSelector class="w-100-p"
                                    (selectionChange)="onModuleChange($event)" [(ngModel)]="module_slug">
                                    <app-mat-search-selector placeholder='Search..'></app-mat-search-selector>
                                    <ng-container *ngFor="let status of modulesList">
                                        <mat-option *appMatSearchSelectorItems [value]="status.status">
                                            {{status.name}}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxFlex="100">
                        <fusioncharts width="100%" height="400" type="stackedcolumn2dline" dataFormat="json"
                            [dataSource]="monthlySubscriptionChartDataSource">
                        </fusioncharts>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>