import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DetailUserEditDialogComponent } from '../detail-user-edit-dialog/detail-user-edit-dialog.component';
import { ResetPasswordDialogComponent } from '../reset-password-dialog/reset-password-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import * as moment from 'moment';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { ActiveUserChangePasswordComponent } from 'app/common-all/active-user-change-password/active-user-change-password.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-detail-user',
  templateUrl: './detail-user.component.html',
  styleUrls: ['./detail-user.component.scss']
})
export class DetailUserComponent implements OnInit {
  dataSource: any;
  displayedColumns = ['name', 'email', 'contactnumber', 'role', 'createdby', 'createdon', 'actions'];
  displayedColumns1 = ['module', 'record', 'user', 'activitytype', 'description', 'updatedon'];
  searchValue = '';
  index: any;
  pageSize: any;
  length: any;
  getlist: any;
  params: { addition_data: {}; company_id: number; limit: number; order_field: string; page: number; search: string; sort: string; status: any;module?: any;user_type?:any };
  company_id: any;
  getTableData: any;
  hire_roles_list: any;
  hr_roles_list: any;
  crm_roles_list: any;
  more = {};
  activeUsersList:any;
  modulesList:any;
  permissions:any = {};
  isLostAccount:any;
  mfaInfo:any;
  logginUser: any;
  userTypeList:any;
  constructor(private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    public dialog: MatDialog, private activatedroute: ActivatedRoute) {
    this.company_id = this.activatedroute.parent.snapshot.params['id'];
    this.commonService.currentUser.subscribe(res => {
      if(res) {
        this.logginUser = res;
      }
    });
  }

  ngOnInit() {
    this.params = { addition_data: {}, company_id: Number(this.company_id), limit: 15, order_field: "id", page: 1, search: "", sort: "desc", status: 1,'user_type':'all_users' };
    this.userTypeList=[{'id':'all_users','name':'All Users'},{id:'is_super_admin',name:'Super Admins'},{id:'crm',name:'CRM'},{id:'hire',name:'ATS'},{id:'hr',name:'HRMS'}];

    this.getList('active');
  }
  applyFilter(filterValue: string) {
    this.params['search'] = filterValue;
    this.params.page = 1;
    this.getList('');
  }

  applyUsertTypeFilter(filterValue: string) {
    // this.params['search'] = filterValue;
    this.params.page = 1;
    this.getList('');
  }

  pageEvent(event) {
    // console.log(event, this.getTableData.data[0].status);
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList('');
  }
  getList(getnav) {
    switch (getnav) {
      case 'active':
        this.getlist = getnav;
        this.params['status'] = 1;
        break;
      case 'inactive':
        this.getlist = getnav;
        this.params['status'] = 0;
        break;
      case 'blocked':
        this.getlist = getnav;
        this.params['status'] = 2;
        break;
      case 'exit':
        this.getlist = getnav;
        this.params['status'] = 3;
        break;
      case 'pending':
        this.getlist = getnav;
        this.params['status'] = -1;
        break;
      case 'deleted':
        this.getlist = getnav;
        this.params['status'] = 4;
        break;
      case 'no_profile':
        this.getlist = getnav;
        this.params['status'] = 'no_profile';
        break;
      case 'activity':
        this.getlist = getnav;
        this.params['status'] = "user_activity";
        this.params['addition_data'] = '';
        break;
      default:
        break;
    }
    // console.log('list_params',this.params);
    if (this.getlist != 'activity') {
      this.companyDetailService.getUserlist(this.params).subscribe(result => {
        if (result.status == 1) {
          this.permissions = result.data.permissions;
          this.hire_roles_list = result.data.app_roles[2].app_users;
          this.hr_roles_list = result.data.app_roles[1].app_users;
          this.crm_roles_list = result.data.app_roles[0].app_users;
          this.getTableData = result.data;
          this.length = this.getTableData.total;
          this.pageSize = this.getTableData.per_page;
          this.index = this.getTableData.current_page;
          this.dataSource = new MatTableDataSource(this.getTableData.users_list);
          this.isLostAccount = result.data.isAccountLost;
          this.getTableData.users_list.filter((val, index) => {
            this.more[index] = false;
          })
          this.mfaInfo = result.data.mfa_data;
        }

      })
    } else if (this.getlist == 'activity') {
      this.companyDetailService.getUserActivities(this.params).subscribe(result => {
        if (result.status == 1) {
          this.getTableData = result.data;
          this.length = result.total;
          // this.pageSize = this.getTableData.per_page;
          // this.index = this.getTableData.current_page;
          this.dataSource = new MatTableDataSource(this.getTableData);
          this.activeUsersList = result.users_list;
          this.modulesList = result.modules_list;
        }
      })
    }

  }

  addUser() {
    const passedData = {};
    passedData['hire_roles'] = this.hire_roles_list;
    passedData['hr_roles'] = this.hr_roles_list;
    passedData['crm_roles'] = this.crm_roles_list;
    passedData['company_id'] = this.company_id;
    const dialogRef = this.dialog.open(DetailUserEditDialogComponent, {
      width: '50%',
      data: passedData
      // data: res
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  editUser(element): void {
    // this.commonService.editUser({ company_id: this.company_id, is_super_admin: element.is_super_admin, user_id: element.id }).subscribe(res => {
    //   if (res) {

    //   }
    // })
    const dialogRef = this.dialog.open(DetailUserEditDialogComponent, {
      width: '50%',
      // data: res
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  changePassword(user_id) {
    console.log('user', user_id);
    let data = {};
    data['id'] = user_id;
    data['company_id'] = this.company_id;
    const dialogRef = this.dialog.open(ActiveUserChangePasswordComponent, {
      width: '65%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }

  checkHavemfa(email) {
    if (this.mfaInfo[email]) {
      return true;
    }
    return false;
  }

  showMfaPopup(email) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to Remove Multi Factor Authentication?';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let prepared_data = {};
        prepared_data['email'] = email;
        this.companyDetailService.updateMfaInfo(prepared_data).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.getList('');    
          }
        })
      }
    })
  }


  revokeUser(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = element.status == 1 ? 'Are you sure? # You want to In-Active user' : 'Are you sure? # You want to Active user';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let prepared_status: any;
        if (element.status == 1) {
          prepared_status = 0;
        } else if (element.status == 0) {
          prepared_status = 1;
        } else if (element.status == 4) {
          prepared_status = 1;
        } else if (element.status == 2) {
          prepared_status = 1;
        }

        this.companyDetailService.revokeUser({
          status: prepared_status,
          id: element.id,
          company_id: this.company_id
        }).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.getList('');
          }
          else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      } else {

      }
    })
  }

  deleteUser(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to Delete user';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let prepared_data = {};
        prepared_data['id'] = element.id;
        prepared_data['company_id'] = this.company_id;
        this.companyDetailService.deleteUser(prepared_data).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.getList('');
          }
          else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      } else {

      }
    })
  }

  convertAsEmployee(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to give Employee access';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let prepared_data = {};
        prepared_data['id'] = element.id;
        prepared_data['company_id'] = this.company_id;
        this.companyDetailService.convertAsEmpolyee(prepared_data).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.getList('');
          }
          else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      } else {

      }
    })
  }

  sendActivationEmail(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to send activation email again?';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let prepared_data = {};
        prepared_data['id'] = element.id;
        prepared_data['company_id'] = this.company_id;
        this.companyDetailService.UserActivationEmail(prepared_data).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.getList('');
          }
          else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      } else {

      }
    })
  }
  filter() {
    let data = {};
    let addition_data = {};
    data['users_list'] = this.activeUsersList;
    // data['modules_list'] = this.modulesList;
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '50%',
      data:data
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('result', result);
      if(result) {
        let from_date:any;
        if(result.from_date){
          from_date = moment(result.from_date).format('MM/DD/YYYY');
        }
        let to_date:any;
        if(result.to_date){
          to_date = moment(result.to_date).format('MM/DD/YYYY');
        }
        addition_data['users'] = result.users;
        // addition_data['modules'] = result.modules;
        addition_data['from_date'] = from_date;
        addition_data['to_date'] = to_date;
        this.params.addition_data = addition_data;
        this.params.limit = 15;
        this.params.page = 1;
        this.companyDetailService.getUserActivities(this.params).subscribe(result => {
          if (result.status == 1) {
            this.getTableData = result.data;
            this.length = result.total;
            this.dataSource = new MatTableDataSource(this.getTableData);
          }
        })
      }
    });
  }
  refresh() {
    this.params.page = 1;
    this.params.limit = 15;
    this.params.addition_data = {};
    this.params.search = '';
    this.params.module = 'user_activities';
    this.getList('activity');
  }

  showActivities() {
    let activityData ={
      'company_id':this.company_id,
      'module_slug':'users',
      'show_company_name':false,
      'module_name':'Users'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  forceLogout(param, key) {
    // console.log(param[key], !param[key]);
    if(!param[key]) {
      this.commonService.openSnackBar('warn', 'Something Went Wrong');
      return false;
    }
    let data = [];
    data['title'] = 'Are you sure?';
    data['message'] = 'Do you want to logout users ?';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: data,
      minWidth: '30%',
      minHeight: '40%'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonService.cpanelEndSessions(param).subscribe(res => {
          if (res.status) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })
      }
    });
  }
}
