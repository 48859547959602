<div class="page-layout carded fullwidth inner-scroll" *ngIf="dataSource">
  <mat-toolbar-row class="secondary-headers w-100-p" >
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li>
        <a class="text-bold active" >Coupons</a>
      </li>
    </ul>
  </mat-toolbar-row>

  <div class="center px-20">
    <div class="content-card mt-12 mb-56">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
        <div class="search-wrapper" fxFlex="30">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Search...">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>

        <div fxFlex="100" class="actions_list">
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50, 100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="limit" [pageIndex]="currentPage-1"></mat-paginator>
          </div>
        </div>

        <!-- <div fxFlex="8" class="actions_list">
          <div fxLayout='row' fxLayoutAlign="space-between center" >
            <button class="primary" mat-stroked-button (click)="importCoupon()" >Import</button>
          </div>
        </div> -->

        <div fxFlex="8" class="actions_list">
          <div fxLayout='row' fxLayoutAlign="space-between center" >
            <button class="primary" mat-stroked-button (click)="addCoupon()" >Add Coupon</button>
          </div>
        </div>

      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">
              
              <!-- <ng-container matColumnDef="sno">
                <mat-header-cell *matHeaderCellDef fxFlex="100px"> S.no </mat-header-cell>
                <mat-cell *matCellDef="let element;index as i" fxFlex="100px">
                  {{i + 1}}
                </mat-cell>
              </ng-container> -->
             
              <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.title}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="discountType">
                <mat-header-cell *matHeaderCellDef> Discount Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{trimString(element.type)}} </mat-cell>
              </ng-container>

               <ng-container matColumnDef="value">
                <mat-header-cell *matHeaderCellDef > Value </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.discount}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="validity">
                <mat-header-cell *matHeaderCellDef > Validity </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{trimString(element.validity_type)}} </mat-cell>
              </ng-container>
               
              <ng-container matColumnDef="validityExpiry">
                <mat-header-cell *matHeaderCellDef> Validity Expiry </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.expiry_date ? element.expiry_date : 'N/A'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="maxRedemptions">
                <mat-header-cell *matHeaderCellDef> Max Redemptions </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.max_redemptions}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="billingCycle">
                <mat-header-cell *matHeaderCellDef> Billing Cycle </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ (element.billing_cycle == 1) ? 'Monthly' : (element.billing_cycle == 3) ? 'Quarterly' : (element.billing_cycle == 6) ? 'Half yearly' : 'Yearly' }} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="clients" >
                <mat-header-cell *matHeaderCellDef > Total Clients </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button mat-icon-button class="accent-fg" matTooltip="Total Clients"  (click)="restrictToClients(element.id)"> 
                    <mat-icon class="s-18">info</mat-icon>
                  </button> 
                    <!-- {{element.items ? element.items : 'N/A'}}   -->
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="restrictToAddon" >
                <mat-header-cell *matHeaderCellDef > Applicable For </mat-header-cell>
                <mat-cell *matCellDef="let element" >
                  <!-- <button mat-icon-button class="accent-fg" matTooltip="Addons" *ngIf="element.items"  (click)="restrictTo(element.items)"> 
                    <mat-icon class="s-18">info</mat-icon>
                  </button>  -->
                    {{element.items ? trimString(element.items) : 'Total Invoice'}}
                </mat-cell>
              </ng-container>

             

              <!-- action Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef fxFlex='100px'> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="100px">
                  <ng-container *ngIf="element.redemptions > 0">
                    This Coupon is Redemptioned by Customer(s). 
                  </ng-container>
                  <ng-container *ngIf="element.redemptions == 0">
                    <button mat-icon-button class="accent-fg" matTooltip="Edit"  (click)="edit(element)">
                      <mat-icon class="s-18">edit</mat-icon>
                    </button>
                    
                    <button mat-icon-button class="red-fg" matTooltip="Delete" (click)="deleteDoccumentsData(element)" >
                      <mat-icon class="s-18">delete</mat-icon>
                    </button>
                  </ng-container>
                  

                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>
        <!-- <div class="footer">
          <button mat-button (click)="addUser()">Add</button>
          <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
          <button mat-button (click)="showActivities()">Activities</button>
        </div> -->
      </div>
    </div>
  </div>
</div>