<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">Mail Templates</h2>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-32">
      <div class="content" fusePerfectScrollbar>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>