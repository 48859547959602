import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrls } from './apiurls';
import { SharedServiceService } from './shared-service.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private sharedService: SharedServiceService
  ) { }

  clientPayments(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.client_payments, params);
  }

  currentMonthRevenueInfo(params): Observable<any> {
    return this.sharedService.get(ApiUrls.powerbi.currentMonthRevenueInfo, params);
  }

  getClientRefunds(params): Observable<any> {
    return this.sharedService.get(ApiUrls.commonUrls.clientRefund, params);
  }

  subscriptionHistory(params): Observable<any> {
    return this.sharedService.get(ApiUrls.clients.subscriptionHistory, params);
  }

  getPaymentReportData(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.paymentReport, params);
  }

  getRevenueForeCost(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.revenueForecost, params);
  }

  getSalesUsersList(): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.getSalesUsers, {});
  }

  salesExecutivePaymentsReport(params): Observable<any> {
    return this.sharedService.post(ApiUrls.dashboard.get_sales_executive_payments, params);
  }

  getUnlimitedMRRChartInfo(params): Observable<any> {
    return this.sharedService.post(ApiUrls.dashboard.unlimitedSubscriptionMrr, params);
  }

  getNextSixMonths(): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.getNextSixMonths, {});
  }
  getRevenueForeCostChartData(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.revenueForecostChartData,params);
  }

  getDueCustomers(params): Observable<any> {
    return this.sharedService.get(ApiUrls.reports.allDueCustomers,params);
  }

  getCustomerUsers(params): Observable<any> {
    return this.sharedService.get(ApiUrls.reports.allCustomerUsers,params);
  }
  
  getCustomerUsageMetrics(params): Observable<any> {
    return this.sharedService.get(ApiUrls.reports.getCustomerUsageMetrics,params);
  }
}
