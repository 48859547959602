<div class="page-layout carded fullwidth inner-scroll" *ngIf="dashboard_permissions">

    <div class="center px-20">
        <div class="content-card mt-20 mb-4">
            <div class="content" fusePerfectScrollbar>
                <!-- Monthly Subscription Revenue Report first Report -->
                <section class="m-12 mat-elevation-z5 border-radius-4"
                    *ngIf="dashboard_permissions['dashboard.monthly_subscription.view']">
                    <div class="px-12 py-4 grey-300-bg" fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <h2 class="muli-bold font-size-16 m-0" fxFlex="auto"> Monthly Realized Revenue Report
                        </h2>

                        <div fxFlex="10" class="text-right mr-12">
                            <button mat-icon-button *ngIf="monthlySubscriptionChart"
                                (click)="refresh('monthlySubscription')" class="mr-4">
                                <mat-icon>refresh</mat-icon>
                            </button>
                            <button mat-icon-button (click)="monthlySubscriptionChart = !monthlySubscriptionChart">
                                <mat-icon *ngIf="!monthlySubscriptionChart" matTooltip="List">list</mat-icon>
                                <mat-icon *ngIf="monthlySubscriptionChart" matTooltip="chart">insert_chart_outlined
                                </mat-icon>
                            </button>
                        </div>

                        <div fxFlex="20" *ngIf="!monthlySubscriptionChart">
                            <form [formGroup]="monthlySubscriptionYearForm">
                                <mat-form-field appearance="outline" class="w-100-p">
                                    <mat-label></mat-label>
                                    <mat-select formControlName="country_id">
                                        <mat-option *ngFor="let cust of countries" [value]="cust.value">
                                            {{cust.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>

                        <div fxFlex="20" *ngIf="monthlySubscriptionChart">
                            <form [formGroup]="monthlySubscriptionForm">
                                <mat-form-field appearance="outline" class="w-100-p">
                                    <mat-label></mat-label>
                                    <mat-select formControlName="country_id">
                                        <mat-option *ngFor="let cust of countries" [value]="cust.value">
                                            {{cust.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>

                    </div>

                    <div *ngIf="!monthlySubscriptionChart && monthlySubscriptionChartDataSource ">
                        <div class="mt-12">
                            <form [formGroup]="monthlySubscriptionYearForm">
                                <mat-form-field appearance="outline" class="w-252 ml-12">
                                    <mat-label>Select Year</mat-label>
                                    <mat-select formControlName="year">
                                        <mat-option *ngFor="let year of years" [value]="year.value">
                                            {{year.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        <fusioncharts width="100%" height="400" type="stackedcolumn2dline" dataFormat="json"
                            [dataSource]="monthlySubscriptionChartDataSource" (dataplotClick)=monthlyAddons($event)>
                        </fusioncharts>
                    </div>

                    <!-- Table Listing -->
                    <ng-container *ngIf="monthlySubscriptionDataSource && monthlySubscriptionChart">
                        <form [formGroup]="monthlySubscriptionForm">
                            <div fxLayout='row wrap' fxLayoutAlign="space-between center"
                                class="fuse-white-bg px-12 pt-12">
                                <div fxFlex="20">
                                    <mat-form-field class="w-100-p ml-8" appearance="outline">
                                        <mat-label>Select</mat-label>
                                        <mat-select appMatSearchSelector class="w-100-p" formControlName="customer"
                                            multiple>
                                            <app-mat-search-selector placeholder='Search..'></app-mat-search-selector>
                                            <ng-container *ngFor="let topping of toppingList">
                                                <mat-option *appMatSearchSelectorItems [value]="topping.id">
                                                    {{topping.display_name}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div fxFlex="20">
                                    <mat-form-field appearance="outline" class="w-100-p">
                                        <mat-label>App Type</mat-label>
                                        <mat-select formControlName="app_type" multiple>
                                            <mat-option *ngFor="let cust of app_type" [value]="cust.value">
                                                {{cust.viewValue}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="20">
                                    <mat-form-field appearance="outline" class="w-100-p">
                                        <mat-label>Subscription Cycle</mat-label>
                                        <mat-select formControlName="subscription_cycle">
                                            <mat-option *ngFor="let cust of subscriptionCycle" [value]="cust.value">
                                                {{cust.viewValue}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div fxFlex="15">
                                    <mat-form-field class="w-100-p" appearance="outline" [matMenuTriggerFor]="menu1">
                                        <mat-label>Select date</mat-label>
                                        <mat-date-range-input [rangePicker]="rangePicker">
                                            <input matStartDate placeholder="Start date" readonly
                                                formControlName="start_date">
                                            <input matEndDate placeholder="End date" readonly
                                                formControlName="end_date">
                                        </mat-date-range-input>
                                    </mat-form-field>

                                    <mat-menu #menu1="matMenu">
                                        <button mat-menu-item (click)="setDate('monthlySubscriptionForm', 'this_week')"
                                            [ngClass]="{'primary': (dateActive == 'this_week')}">This Week</button>
                                        <button mat-menu-item (click)="setDate('monthlySubscriptionForm', 'this_month')"
                                            [ngClass]="{'primary': (dateActive == 'this_month')}">This Month</button>
                                        <button mat-menu-item (click)="setDate('monthlySubscriptionForm','last_month')"
                                            [ngClass]="{'primary': (dateActive == 'last_month')}">Last Month</button>
                                        <button mat-menu-item (click)="rangePicker.open();dateActive = 'custom';"
                                            [ngClass]="{'primary': dateActive == 'custom'}">Custom Range</button>
                                        <button mat-menu-item (click)="setDate('monthlySubscriptionForm', 'clear')"
                                            [ngClass]="{'primary': dateActive == 'clear'}">Clear</button>
                                        <!-- <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle> -->
                                        <mat-date-range-picker #rangePicker>
                                            <mat-date-range-picker-actions>
                                                <button mat-button matDateRangePickerCancel>Cancel</button>
                                                <button mat-raised-button color="primary"
                                                    matDateRangePickerApply>Apply</button>
                                            </mat-date-range-picker-actions>
                                        </mat-date-range-picker>
                                    </mat-menu>
                                </div>

                            </div>
                        </form>
                        <div class="px-12 pb-12">
                            <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg">
                                <div class="search-wrapper" fxFlex="30">
                                    <input matInput class="search1"
                                        (keyup.enter)="applyFilter($event.target.value,'monthlySubscription')"
                                        type="text" [readOnly]="monthlySubscriptionDataSource.length === 0"
                                        [(ngModel)]="monthlySubscriptionSearchValue" placeholder="Search ..">
                                    <mat-icon class="search_icon">search</mat-icon>
                                    <mat-icon class="close s-20 danger-fg" *ngIf="monthlySubscriptionSearchValue"
                                        (click)="monthlySubscriptionSearchValue=''; applyFilter('','monthlySubscription');">
                                        close
                                    </mat-icon>
                                </div>
                                <div fxFlex="100" class="actions_list" fxLayout="row wrap" fxLayoutAlign="end center">
                                    <div>
                                        <button mat-stroked-button (click)="exportExcel('monthlySubscription')">Export
                                            as excel</button>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="end center">
                                        <mat-paginator [pageSizeOptions]="[15, 25, 50,100]"
                                            [length]="monthlySubscriptionLength"
                                            (page)="pageEvent($event,'monthlySubscription')"
                                            [pageSize]="monthlySubscriptionpageSize"
                                            [pageIndex]="monthlySubscriptionindex-1">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                            <div class="height-300" fusePerfectScrollbar>
                                <mat-table [dataSource]="monthlySubscriptionDataSource" class="table">
                                    <!-- customerName Column -->
                                    <ng-container matColumnDef="customerName">
                                        <mat-header-cell *matHeaderCellDef> customer Name </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <a (click)="navigate(element.company_id)"
                                                class="cursor-pointer">{{element.company_name}}</a>
                                        </mat-cell>
                                    </ng-container>

                                    <!-- monthPaid Column -->
                                    <ng-container matColumnDef="monthPaid">
                                        <mat-header-cell *matHeaderCellDef> last paid date </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            {{element.paid_on | date:"MM/dd/yyyy"}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- subscriptionCycle Column -->
                                    <ng-container matColumnDef="subscriptionCycle">
                                        <mat-header-cell *matHeaderCellDef> subscription Cycle </mat-header-cell>
                                        <mat-cell *matCellDef="let element"
                                            [innerHtml]="subCycle(element.subscription_cycle)">

                                        </mat-cell>
                                    </ng-container>

                                    <!-- hire_licenses_cost Column -->
                                    <ng-container matColumnDef="hire_licenses_cost">
                                        <mat-header-cell *matHeaderCellDef fxFlex="400px"> Hire </mat-header-cell>
                                        <mat-cell *matCellDef="let element" fxFlex="400px">

                                            U {{ element.hire_licenses_cnt ? element.hire_licenses_cnt : 0}} -
                                            {{element.hire_licenses_cost ? (isNumber(element.hire_licenses_cost) ?
                                            (element.hire_licenses_cost|currency:'USD':$:'1.2-2') :
                                            element.hire_licenses_cost) : '$0'}}
                                            | M
                                            {{element.mass_mails_licenses_cnt ? (element.mass_mails_licenses_cnt |
                                            shortNumber) : 0}} -
                                            {{element.mass_mails_licenses_cost ?
                                            (isNumber(element.mass_mails_licenses_cost) ?
                                            (element.mass_mails_licenses_cost|currency:'USD':$:'1.2-2') :
                                            element.mass_mails_licenses_cost) :
                                            '$0'}}
                                            | C
                                            {{element.account_contacts_licenses_cnt ?
                                            (element.account_contacts_licenses_cnt | shortNumber) :
                                            0}}
                                            -
                                            {{element.account_contacts_licenses_cost ?
                                            (isNumber(element.account_contacts_licenses_cost) ?
                                            (element.account_contacts_licenses_cost|currency:'USD':$:'1.2-2') :
                                            element.account_contacts_licenses_cost) : '$0' }}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- crm_licenses_cost Column -->
                                    <ng-container matColumnDef="crm_licenses_cost">
                                        <mat-header-cell *matHeaderCellDef> crm </mat-header-cell>
                                        <mat-cell *matCellDef="let element">

                                            <span *ngIf="element.crm_licenses_cnt">
                                                U {{element.crm_licenses_cnt}} -
                                                {{ (isNumber(element.crm_licenses_cost) ?
                                                (element.crm_licenses_cost|currency:'USD':$:'1.2-2') :
                                                element.crm_licenses_cost) }}
                                            </span>
                                            <span *ngIf="!element.crm_licenses_cnt">-</span>

                                        </mat-cell>
                                    </ng-container>

                                    <!-- hr_licenses_cost Column -->
                                    <ng-container matColumnDef="hr_licenses_cost">
                                        <mat-header-cell *matHeaderCellDef> hr </mat-header-cell>
                                        <mat-cell *matCellDef="let element">

                                            <span *ngIf="element.hr_licenses_cnt">
                                                U {{element.hr_licenses_cnt}} -
                                                {{ (isNumber(element.hr_licenses_cost) ?
                                                (element.hr_licenses_cost|currency:'USD':$:'1.2-2') :
                                                element.hr_licenses_cost) }}
                                            </span>
                                            <span *ngIf="!element.hr_licenses_cnt">-</span>

                                        </mat-cell>
                                    </ng-container>
                                    <!-- massmails Column -->
                                    <ng-container matColumnDef="massmails">
                                        <mat-header-cell *matHeaderCellDef> mass mails</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            {{element.mass_mails_licenses_cnt ? element.mass_mails_licenses_cnt : '-'}}
                                            <span *ngIf="element.mass_mails_licenses_cost">
                                                (
                                                {{element.mass_mails_licenses_cost ? ((element.mass_mails_licenses_cost
                                                | number) | currency) :
                                                '-'}}
                                                )
                                            </span>
                                        </mat-cell>
                                    </ng-container>

                                    <!-- accountcontacts Column -->
                                    <ng-container matColumnDef="accountcontacts">
                                        <mat-header-cell *matHeaderCellDef> account contacts</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            {{element.account_contacts_licenses_cnt ?
                                            element.account_contacts_licenses_cnt : '-'}}
                                            <span *ngIf="element.account_contacts_licenses_cost">
                                                (
                                                {{element.account_contacts_licenses_cost ?
                                                ((element.account_contacts_licenses_cost | number) |
                                                currency) : '-'}}
                                                )
                                            </span>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="total_cost">
                                        <mat-header-cell *matHeaderCellDef> Total Cost</mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            {{ isNumber(element.total_cost) ?
                                            (element.total_cost|currency:'USD':$:'1.2-2') :
                                            element.total_cost }}
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="monthlySubscriptionTableCols;"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: monthlySubscriptionTableCols;"></mat-row>
                                </mat-table>
                            </div>
                            <div *ngIf="monthlySubscriptionDataSource.data.length == 0" class="text-center">
                                <h2 class="heading m-0 py-16 border">No Records Found</h2>
                            </div>
                        </div>
                    </ng-container>
                </section>

                <!-- Payment Mode wise monthly rerport -->
                <section class="m-12 mat-elevation-z5 border-radius-4"
                    *ngIf="dashboard_permissions['dashboard.mrr.view']">
                    <div class="px-12 py-4 grey-300-bg" fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <h2 class="muli-bold font-size-16 m-0"> Monthly Payments
                        </h2>
                        <div>
                            <button mat-stroked-button (click)="exportMonthlyPaymentsData()">Export</button>
                        </div>
                        <div fxFlex="20" *ngIf="paymentModeWisePaymentsChart && paymentModeWisePaymentsChartDataSource">
                            <form [formGroup]="paymentModeWisePaymentsYearForm">
                                <mat-form-field appearance="outline" class="w-100-p">
                                    <mat-label></mat-label>
                                    <mat-select formControlName="country_id">
                                        <mat-option *ngFor="let cust of countries" [value]="cust.value">
                                            {{cust.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                    <div *ngIf="paymentModeWisePaymentsChart && paymentModeWisePaymentsChartDataSource">
                        <div class="mt-12">
                            <form [formGroup]="paymentModeWisePaymentsYearForm">
                                <mat-form-field appearance="outline" class="w-252 ml-12">
                                    <mat-label>Select Year</mat-label>
                                    <mat-select formControlName="year">
                                        <mat-option *ngFor="let year of years" [value]="year.value">
                                            {{year.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        <fusioncharts width="100%" height="400" type="stackedcolumn2dline" dataFormat="json"
                            [dataSource]="paymentModeWisePaymentsChartDataSource">
                        </fusioncharts>
                    </div>
                </section>

                <!-- Monthly Revenue Report first Report -->
                <!-- <section class="m-12 mat-elevation-z5 border-radius-4" *ngIf="dashboard_permissions['dashboard.monthly_revenew.view']">
                <div class="px-12 py-4 grey-300-bg" fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <h2 class="muli-bold font-size-16 m-0"> Monthly Revenue Report
                  </h2>
                  <div fxFlex="10" class="text-right">
                    <button mat-icon-button *ngIf="revenueChart" (click)="refresh('monthlyRevenueReport')" class="mr-4">
                      <mat-icon>refresh</mat-icon>
                    </button>
                    <button mat-icon-button (click)="revenueChart = !revenueChart">
                      <mat-icon *ngIf="!revenueChart" matTooltip="List">list</mat-icon>
                      <mat-icon *ngIf="revenueChart" matTooltip="chart">insert_chart_outlined</mat-icon>
                    </button>
                  </div>
                </div>
                <div *ngIf="!revenueChart">
                  <div class="mt-12">
                    <form [formGroup]="chartYearForm">
                      <mat-form-field appearance="outline" class="w-252 ml-12">
                        <mat-label>Select Year</mat-label>
                        <mat-select formControlName="year">
                          <mat-option *ngFor="let year of years" [value]="year.value">
                            {{year.viewValue}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                     
                    </form>
                  </div>
                  <fusioncharts width="100%" height="400" type="column2d" dataFormat="json"
                    [dataSource]="monthRevnueReportData" (dataplotClick)="showeRvenueTableData($event)">
                  </fusioncharts>
                </div>
                <ng-container *ngIf="revenueDataSource && revenueChart">
                  <form [formGroup]="revenueForm">
                    <div fxLayout='row wrap' fxLayoutAlign="space-between center" class="fuse-white-bg px-12 pt-12">
                      <div fxFlex="20">
                        <mat-form-field class="w-100-p ml-8" appearance="outline">
                          <mat-label>Select</mat-label>
                          <mat-select appMatSearchSelector class="w-100-p" formControlName="customer" multiple>
                            <app-mat-search-selector placeholder='Search..'></app-mat-search-selector>
                            <ng-container *ngFor="let topping of toppingList">
                              <mat-option *appMatSearchSelectorItems [value]="topping.id">
                                {{topping.display_name}}
                              </mat-option>
                            </ng-container>
                          </mat-select>
                        </mat-form-field>
                      </div>
                     
                      <div fxFlex="20">
                        <mat-form-field appearance="outline" class="w-100-p">
                          <mat-label>App Type</mat-label>
                          <mat-select formControlName="app_type" multiple>
                            <mat-option *ngFor="let cust of app_type" [value]="cust.value">
                              {{cust.viewValue}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div fxFlex="20">
                        <mat-form-field appearance="outline" class="w-100-p">
                          <mat-label>Subscription Cycle</mat-label>
                          <mat-select formControlName="subscription_cycle">
                            <mat-option *ngFor="let cust of subscriptionCycle" [value]="cust.value">
                              {{cust.viewValue}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                     
                      <div fxFlex="15">
                        <ejs-daterangepicker placeholder='Select Date' class="w-100-p" formControlName="dateRange">
                          <e-presets>
                            <e-preset label="This Week" [start]='weekStart' [end]='weekEnd'></e-preset>
                            <e-preset label="This Month" [start]='monthStart' [end]='monthEnd'></e-preset>
                            <e-preset label="Last Month" [start]='lastStart' [end]='lastEnd'></e-preset>
                          </e-presets>
                        </ejs-daterangepicker>
                      </div>
                     
                    </div>
                  </form>
                  <div class="px-12 pb-12">
                    <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg">
                      <div class="search-wrapper" fxFlex="30">
                        <input matInput class="search1"
                          (keyup.enter)="applyFilter($event.target.value,'monthlyRevenueReport')" type="text"
                          [readOnly]="revenueDataSource.length === 0" [(ngModel)]="revenueSearchValue"
                          placeholder="Search ..">
                        <mat-icon class="search_icon">search</mat-icon>
                        <mat-icon class="close s-20 danger-fg" *ngIf="revenueSearchValue"
                          (click)="revenueSearchValue=''; applyFilter('','monthlyRevenueReport');">
                          close
                        </mat-icon>
                      </div>
                      <div fxFlex="100" class="actions_list" fxLayout="row wrap" fxLayoutAlign="end center">
                        <div>
                          <button mat-stroked-button (click)="exportExcel('monthlyRevenueReport')">Export as excel</button>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center">
                          <mat-paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="revenueLength"
                            (page)="pageEvent($event,'monthlyRevenueReport')" [pageSize]="revenuepageSize"
                            [pageIndex]="revenueindex-1">
                          </mat-paginator>
                        </div>
                      </div>
                    </div>
                    <div class="height-300" fusePerfectScrollbar>
                      <mat-table [dataSource]="revenueDataSource" class="table">
                        <ng-container matColumnDef="customerName">
                          <mat-header-cell *matHeaderCellDef> customer Name </mat-header-cell>
                          <mat-cell *matCellDef="let element">
                            <a (click)="navigate(element.company_id)" class="cursor-pointer">{{element.company_name}}</a>
                          </mat-cell>
      
                        <ng-container matColumnDef="monthPaid">
                          <mat-header-cell *matHeaderCellDef> paid date </mat-header-cell>
                          <mat-cell *matCellDef="let element">
                            {{element.paid_on | date:"MM/dd/yyyy"}}
                          </mat-cell>
                        </ng-container>
      
                        <ng-container matColumnDef="subscriptionCycle">
                          <mat-header-cell *matHeaderCellDef> subscription Cycle </mat-header-cell>
                          <mat-cell *matCellDef="let element" [innerHtml]="subCycle(element.subscription_cycle)">
      
                          </mat-cell>
                        </ng-container>
      
                        <ng-container matColumnDef="hire_licenses_cost">
                          <mat-header-cell *matHeaderCellDef fxFlex="400px"> Hire </mat-header-cell>
                          <mat-cell *matCellDef="let element" fxFlex="400px">
      
                            U {{ element.hire_licenses_cnt ? element.hire_licenses_cnt : 0}} -
                            {{element.hire_licenses_cost ? (isNumber(element.hire_licenses_cost) ? (element.hire_licenses_cost|currency:'USD':$:'1.2-2') : element.hire_licenses_cost) : '$0'}} | M
                            {{element.mass_mails_licenses_cnt ? (element.mass_mails_licenses_cnt | shortNumber) : 0}} -
                            {{element.mass_mails_licenses_cost ? (isNumber(element.mass_mails_licenses_cost) ? (element.mass_mails_licenses_cost|currency:'USD':$:'1.2-2') : element.mass_mails_licenses_cost) : '$0'}}
                            | C
                            {{element.account_contacts_licenses_cnt ? (element.account_contacts_licenses_cnt | shortNumber) : 0}}
                            -
                            {{element.account_contacts_licenses_cost ? (isNumber(element.account_contacts_licenses_cost) ? (element.account_contacts_licenses_cost|currency:'USD':$:'1.2-2') : element.account_contacts_licenses_cost) : '$0' }}
                          </mat-cell>
                        </ng-container>
      
                        <ng-container matColumnDef="crm_licenses_cost">
                          <mat-header-cell *matHeaderCellDef> crm </mat-header-cell>
                          <mat-cell *matCellDef="let element">
      
                            <span *ngIf="element.crm_licenses_cnt">
                              U {{element.crm_licenses_cnt}} - {{ (isNumber(element.crm_licenses_cost) ? (element.crm_licenses_cost|currency:'USD':$:'1.2-2') : element.crm_licenses_cost) }} </span>
                            <span *ngIf="!element.crm_licenses_cnt">-</span>
      
                          </mat-cell>
                        </ng-container>
      
                        <ng-container matColumnDef="hr_licenses_cost">
                          <mat-header-cell *matHeaderCellDef> hr </mat-header-cell>
                          <mat-cell *matCellDef="let element">
      
                            <span *ngIf="element.hr_licenses_cnt">
                              U {{element.hr_licenses_cnt}} - {{ (isNumber(element.hr_licenses_cost) ? (element.hr_licenses_cost|currency:'USD':$:'1.2-2') : element.hr_licenses_cost) }}
                            </span>
                            <span *ngIf="!element.hr_licenses_cnt">-</span>
      
                          </mat-cell>
                        </ng-container>
  
                        <ng-container matColumnDef="massmails">
                          <mat-header-cell *matHeaderCellDef> mass mails</mat-header-cell>
                          <mat-cell *matCellDef="let element">
                            {{element.mass_mails_licenses_cnt ? element.mass_mails_licenses_cnt : '-'}}
                            <span *ngIf="element.mass_mails_licenses_cost">
                              (
                              {{element.mass_mails_licenses_cost ? ((element.mass_mails_licenses_cost | number) | currency) : '-'}}
                              )
                            </span>
                          </mat-cell>
                        </ng-container>
      
                        <ng-container matColumnDef="accountcontacts">
                          <mat-header-cell *matHeaderCellDef> account contacts</mat-header-cell>
                          <mat-cell *matCellDef="let element">
                            {{element.account_contacts_licenses_cnt ? element.account_contacts_licenses_cnt : '-'}}
                            <span *ngIf="element.account_contacts_licenses_cost">
                              (
                              {{element.account_contacts_licenses_cost ? ((element.account_contacts_licenses_cost | number) | currency) : '-'}}
                              )
                            </span>
                          </mat-cell>
                        </ng-container>
      
                        <ng-container matColumnDef="total_cost">
                          <mat-header-cell *matHeaderCellDef> Total Cost</mat-header-cell>
                          <mat-cell *matCellDef="let element">
                            {{ isNumber(element.total_cost) ? (element.total_cost|currency:'USD':$:'1.2-2') : element.total_cost }}
                          </mat-cell>
                        </ng-container>
      
                        <mat-header-row *matHeaderRowDef="revenuedisplayedColumns;"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: revenuedisplayedColumns;"></mat-row>
                      </mat-table>
                    </div>
                    <div *ngIf="revenueDataSource.data.length == 0" class="text-center">
                      <h2 class="heading m-0 py-16 border">No Records Found</h2>
                    </div>
                  </div>
                </ng-container>
              </section> -->

                <!-- Signup vs Closures Report -->
                <section class="m-12 mt-24 mat-elevation-z5 border-radius-4"
                    *ngIf="dashboard_permissions['dashboard.signup_closures.view']">

                    <div class="px-12 py-4 grey-300-bg" fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <h2 class="muli-bold font-size-16 m-0" fxFlex="65">Signup vs Closures </h2>
                        <div fxFlex="20" *ngIf="signupClosureChart">
                            <form [formGroup]="signVsClose">
                                <mat-form-field appearance="outline" class="w-100-p">
                                    <mat-label></mat-label>
                                    <mat-select formControlName="signupvsclosure">
                                        <mat-option *ngFor="let cust of signvsclose" [value]="cust.value">
                                            {{cust.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        <div fxFlex="10" class="text-right">
                            <button mat-icon-button (click)="refresh('signup')" class="mr-4">
                                <mat-icon>refresh</mat-icon>
                            </button>
                            <button mat-icon-button (click)="signupClosureChart = !signupClosureChart">
                                <mat-icon *ngIf="!signupClosureChart" matTooltip="List">list</mat-icon>
                                <mat-icon *ngIf="signupClosureChart" matTooltip="chart">insert_chart_outlined</mat-icon>
                            </button>
                        </div>

                        <div fxFlex="20" *ngIf="signupClosureChart">
                            <mat-form-field appearance="outline" class="w-100-p">
                                <mat-label></mat-label>
                                <mat-select [(ngModel)]="signupClosureCountryId"
                                    (ngModelChange)="signupCloseListingCountryChnage()">
                                    <mat-option *ngFor="let cust of countries" [value]="cust.value">
                                        {{cust.viewValue}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxFlex="20" *ngIf="!signupClosureChart && signupClosureChartDataSource">
                            <form [formGroup]="signupClosureYearForm">
                                <mat-form-field appearance="outline" class="w-100-p">
                                    <mat-label></mat-label>
                                    <mat-select formControlName="country_id">
                                        <mat-option *ngFor="let cust of countries" [value]="cust.value">
                                            {{cust.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>

                    <div *ngIf="!signupClosureChart && signupClosureChartDataSource ">
                        <div class="mt-12">
                            <form [formGroup]="signupClosureYearForm">
                                <mat-form-field appearance="outline" class="w-252 ml-12">
                                    <mat-label>Select Year</mat-label>
                                    <mat-select formControlName="year">
                                        <mat-option *ngFor="let year of years" [value]="year.value">
                                            {{year.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        <fusioncharts width="100%" height="400" type="stackedcolumn2dline" dataFormat="json"
                            [dataSource]="signupClosureChartDataSource" (dataplotClick)=signupclosure($event)>
                        </fusioncharts>
                    </div>

                    <!-- Table Listing -->
                    <div *ngIf="signupClosureChart">
                        <ng-container *ngIf="signupDatasource && (selectSign)">
                            <form [formGroup]="signupForm">
                                <div fxLayout='row wrap' fxLayoutAlign="space-between center"
                                    class="fuse-white-bg px-12 pt-12">

                                    <div fxFlex="15">
                                        <mat-form-field class="w-100-p" appearance="outline"
                                            [matMenuTriggerFor]="menu3">
                                            <mat-label>Select date</mat-label>
                                            <mat-date-range-input [rangePicker]="rangePicker">
                                                <input matStartDate placeholder="Start date" readonly
                                                    formControlName="start_date">
                                                <input matEndDate placeholder="End date" readonly
                                                    formControlName="end_date">
                                            </mat-date-range-input>
                                        </mat-form-field>

                                        <mat-menu #menu3="matMenu">
                                            <button mat-menu-item (click)="setDate('signupForm','this_week')"
                                                [ngClass]="{'primary': (dateActive == 'this_week')}">This Week</button>
                                            <button mat-menu-item (click)="setDate('signupForm','this_month')"
                                                [ngClass]="{'primary': (dateActive == 'this_month')}">This
                                                Month</button>
                                            <button mat-menu-item (click)="setDate('signupForm','last_month')"
                                                [ngClass]="{'primary': (dateActive == 'last_month')}">Last
                                                Month</button>
                                            <button mat-menu-item (click)="rangePicker.open();dateActive = 'custom';"
                                                [ngClass]="{'primary': dateActive == 'custom'}">Custom Range</button>
                                            <button mat-menu-item (click)="setDate('signupForm','clear')"
                                                [ngClass]="{'primary': dateActive == 'clear'}">Clear</button>
                                            <!-- <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle> -->
                                            <mat-date-range-picker #rangePicker>
                                                <mat-date-range-picker-actions>
                                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                                    <button mat-raised-button color="primary"
                                                        matDateRangePickerApply>Apply</button>
                                                </mat-date-range-picker-actions>
                                            </mat-date-range-picker>
                                        </mat-menu>
                                    </div>
                                </div>
                            </form>
                            <div class="px-12 pb-12">
                                <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg">
                                    <div class="search-wrapper" fxFlex="30">
                                        <input matInput class="search1"
                                            (keyup.enter)="applyFilter($event.target.value,'signup')" type="text"
                                            [readOnly]="signupDatasource.length === 0" [(ngModel)]="signupsSearchValue"
                                            placeholder="Search ..">
                                        <mat-icon class="search_icon">search</mat-icon>
                                        <mat-icon class="close s-20 danger-fg" *ngIf="signupsSearchValue"
                                            (click)="signupsSearchValue=''; applyFilter('','signup');">
                                            close
                                        </mat-icon>
                                    </div>
                                    <div fxFlex="100" class="actions_list" fxLayout="row wrap"
                                        fxLayoutAlign="end center">
                                        <div>
                                            <button mat-stroked-button (click)="exportExcel('signup')">Export as
                                                excel</button>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="end center">
                                            <mat-paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="signupsLength"
                                                (page)="pageEvent($event,'signup')" [pageSize]="signupspageSize"
                                                [pageIndex]="signupsindex-1">
                                            </mat-paginator>
                                        </div>
                                    </div>
                                </div>
                                <div class="height-300" fusePerfectScrollbar>
                                    <mat-table [dataSource]="signupDatasource" class="table">

                                        <ng-container matColumnDef="customerName">
                                            <mat-header-cell *matHeaderCellDef> customer Name </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                <a (click)="navigate(element.company_id)"
                                                    class="cursor-pointer">{{element.company_name}}</a>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="contact_name">
                                            <mat-header-cell *matHeaderCellDef> Contact Name </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                <span *ngIf="element.client_info">{{element.client_info.first_name}}
                                                    {{element.client_info.last_name}}</span>
                                                <span *ngIf="!element.client_info">-</span>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="email">
                                            <mat-header-cell *matHeaderCellDef fxFlex="200px"> Email </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                <span *ngIf="element.client_info"
                                                    fxFlex="200px">{{element.client_info.email | lowercase
                                                    }}</span>
                                                <span *ngIf="!element.client_info">-</span>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="sub_domain">
                                            <mat-header-cell *matHeaderCellDef>Sub Domain </mat-header-cell>
                                            <mat-cell *matCellDef="let element">{{element.sub_domain_name}}</mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="contact_number">
                                            <mat-header-cell *matHeaderCellDef> Contact Number </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                <span
                                                    *ngIf="element.client_info">{{element.client_info.contact_number}}</span>
                                                <span *ngIf="!element.client_info">-</span>
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="region">
                                            <mat-header-cell *matHeaderCellDef> Region </mat-header-cell>
                                            <mat-cell *matCellDef="let element">{{element.region | uppercase }}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="onboarded_date">
                                            <mat-header-cell *matHeaderCellDef> Signup on </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                {{element.onboarded_date | date:"MM/dd/yyyy"}}
                                            </mat-cell>
                                        </ng-container>

                                        <mat-header-row *matHeaderRowDef="signupsdisplayedColumns;"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: signupsdisplayedColumns;"></mat-row>
                                    </mat-table>
                                </div>
                                <div *ngIf="signupDatasource.data.length == 0" class="text-center">
                                    <h2 class="heading m-0 py-16 border">No Records Found</h2>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="closuresDatasource && (!selectSign)">
                            <form [formGroup]="closuresForm">
                                <div fxLayout='row wrap' fxLayoutAlign="space-between center"
                                    class="fuse-white-bg px-12 pt-12">

                                    <div fxFlex="15">
                                        <mat-form-field appearance="outline" class="w-100-p">
                                            <mat-label>App Type</mat-label>
                                            <mat-select formControlName="app_type" multiple>
                                                <mat-option *ngFor="let cust of app_type" [value]="cust.value">
                                                    {{cust.viewValue}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="15">
                                        <mat-form-field appearance="outline" class="w-100-p">
                                            <mat-label>Subscription Cycle</mat-label>
                                            <mat-select formControlName="subscription_cycle">
                                                <mat-option *ngFor="let cust of subscriptionCycle" [value]="cust.value">
                                                    {{cust.viewValue}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="15">
                                        <mat-form-field class="w-90-p m-20" appearance="outline"
                                            [matMenuTriggerFor]="menu4">
                                            <mat-label>Select date</mat-label>
                                            <mat-date-range-input [rangePicker]="rangePicker">
                                                <input matStartDate placeholder="Start date" readonly
                                                    formControlName="start_date">
                                                <input matEndDate placeholder="End date" readonly
                                                    formControlName="end_date">
                                            </mat-date-range-input>
                                        </mat-form-field>

                                        <mat-menu #menu4="matMenu">
                                            <button mat-menu-item (click)="setDate('closuresForm','this_week')"
                                                [ngClass]="{'primary': (dateActive == 'this_week')}">This Week</button>
                                            <button mat-menu-item (click)="setDate('closuresForm','this_month')"
                                                [ngClass]="{'primary': (dateActive == 'this_month')}">This
                                                Month</button>
                                            <button mat-menu-item (click)="setDate('closuresForm','last_month')"
                                                [ngClass]="{'primary': (dateActive == 'last_month')}">Last
                                                Month</button>
                                            <button mat-menu-item (click)="rangePicker.open();dateActive = 'custom';"
                                                [ngClass]="{'primary': dateActive == 'custom'}">Custom Range</button>
                                            <button mat-menu-item (click)="setDate('closuresForm','clear')"
                                                [ngClass]="{'primary': dateActive == 'clear'}">Clear</button>
                                            <!-- <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle> -->
                                            <mat-date-range-picker #rangePicker>
                                                <mat-date-range-picker-actions>
                                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                                    <button mat-raised-button color="primary"
                                                        matDateRangePickerApply>Apply</button>
                                                </mat-date-range-picker-actions>
                                            </mat-date-range-picker>
                                        </mat-menu>
                                    </div>
                                </div>
                            </form>
                            <div class="px-12 pb-12">
                                <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg">
                                    <div class="search-wrapper" fxFlex="30">
                                        <input matInput class="search1"
                                            (keyup.enter)="applyFilter($event.target.value,'closures')" type="text"
                                            [readOnly]="closuresDatasource.length === 0"
                                            [(ngModel)]="closuresSearchValue" placeholder="Search ..">
                                        <mat-icon class="search_icon">search</mat-icon>
                                        <mat-icon class="close s-20 danger-fg" *ngIf="closuresSearchValue"
                                            (click)="closuresSearchValue=''; applyFilter('','closures');">
                                            close
                                        </mat-icon>
                                    </div>
                                    <div fxFlex="100" class="actions_list" fxLayout="row wrap"
                                        fxLayoutAlign="end center">
                                        <div>
                                            <button mat-stroked-button (click)="exportExcel('closures')">Export as
                                                excel</button>
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="end center">
                                            <mat-paginator [pageSizeOptions]="[15, 25, 50,100]"
                                                [length]="closuresLength" (page)="pageEvent($event,'closures')"
                                                [pageSize]="closurespageSize" [pageIndex]="closuresindex-1">
                                            </mat-paginator>
                                        </div>
                                    </div>
                                </div>
                                <div class="height-300" fusePerfectScrollbar>
                                    <mat-table [dataSource]="closuresDatasource" class="table">
                                        <!-- customerName Column -->
                                        <ng-container matColumnDef="customerName">
                                            <mat-header-cell *matHeaderCellDef> customer Name </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                <a (click)="navigate(element.company_id)"
                                                    class="cursor-pointer">{{element.company_name}}</a>
                                            </mat-cell>
                                        </ng-container>
                                        <!-- app_type Column -->
                                        <ng-container matColumnDef="app_type">
                                            <mat-header-cell *matHeaderCellDef> app type </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                {{element.app_type}}
                                            </mat-cell>
                                        </ng-container>
                                        <!-- subscriptionCycle Column -->
                                        <ng-container matColumnDef="subscriptionCycle">
                                            <mat-header-cell *matHeaderCellDef> subscription Cycle </mat-header-cell>
                                            <mat-cell *matCellDef="let element"
                                                [innerHtml]="subCycle(element.subscription_cycle)">

                                            </mat-cell>
                                        </ng-container>
                                        <!-- onboarded_date Column -->
                                        <ng-container matColumnDef="onboarded_date">
                                            <mat-header-cell *matHeaderCellDef> Signup on </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                {{element.onboarded_date | date:"MM/dd/yyyy"}}
                                            </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="onboarded_on">
                                            <mat-header-cell *matHeaderCellDef> Onboarded on </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                {{element.paid_on | date:"MM/dd/yyyy"}}
                                            </mat-cell>
                                        </ng-container>

                                        <!-- hire_licenses_cost Column -->
                                        <ng-container matColumnDef="hire_licenses_cost">
                                            <mat-header-cell *matHeaderCellDef fxFlex="400px"> Hire </mat-header-cell>
                                            <mat-cell *matCellDef="let element" fxFlex="400px">

                                                U {{ element.hire_licenses_cnt ? element.hire_licenses_cnt : 0}} -
                                                {{element.hire_licenses_cost ? (isNumber(element.hire_licenses_cost) ?
                                                (element.hire_licenses_cost|currency:'USD':$:'1.2-2') :
                                                element.hire_licenses_cost) : '$0'}}
                                                | M
                                                {{element.mass_mails_licenses_cnt ? (element.mass_mails_licenses_cnt |
                                                shortNumber) : 0}} -
                                                {{element.mass_mails_licenses_cost ?
                                                (isNumber(element.mass_mails_licenses_cost) ?
                                                (element.mass_mails_licenses_cost|currency:'USD':$:'1.2-2') :
                                                element.mass_mails_licenses_cost)
                                                : '$0'}}
                                                | C
                                                {{element.account_contacts_licenses_cnt ?
                                                (element.account_contacts_licenses_cnt | shortNumber)
                                                : 0}}
                                                -
                                                {{element.account_contacts_licenses_cost ?
                                                (isNumber(element.account_contacts_licenses_cost) ?
                                                (element.account_contacts_licenses_cost|currency:'USD':$:'1.2-2') :
                                                element.account_contacts_licenses_cost) : '$0' }}
                                            </mat-cell>
                                        </ng-container>

                                        <!-- crm_licenses_cost Column -->
                                        <ng-container matColumnDef="crm_licenses_cost">
                                            <mat-header-cell *matHeaderCellDef> crm </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                <span *ngIf="element.crm_licenses_cnt">
                                                    U {{element.crm_licenses_cnt}} -
                                                    {{ (isNumber(element.crm_licenses_cost) ?
                                                    (element.crm_licenses_cost|currency:'USD':$:'1.2-2')
                                                    : element.crm_licenses_cost) }}
                                                </span>
                                                <span *ngIf="!element.crm_licenses_cnt">-</span>
                                            </mat-cell>
                                        </ng-container>

                                        <!-- hr_licenses_cost Column -->
                                        <ng-container matColumnDef="hr_licenses_cost">
                                            <mat-header-cell *matHeaderCellDef> hr </mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                <span *ngIf="element.hr_licenses_cnt">
                                                    U {{element.hr_licenses_cnt}} -
                                                    {{ (isNumber(element.hr_licenses_cost) ?
                                                    (element.hr_licenses_cost|currency:'USD':$:'1.2-2') :
                                                    element.hr_licenses_cost) }}
                                                </span>
                                                <span *ngIf="!element.hr_licenses_cnt">-</span>
                                            </mat-cell>
                                        </ng-container>
                                        <!-- massmails Column -->
                                        <ng-container matColumnDef="massmails">
                                            <mat-header-cell *matHeaderCellDef> mass mails</mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                {{element.mass_mails_licenses_cnt ? element.mass_mails_licenses_cnt :
                                                '-'}}
                                                <span *ngIf="element.mass_mails_licenses_cost">
                                                    (
                                                    {{element.mass_mails_licenses_cost ?
                                                    ((element.mass_mails_licenses_cost | number) | currency)
                                                    : '-'}}
                                                    )
                                                </span>
                                            </mat-cell>
                                        </ng-container>

                                        <!-- accountcontacts Column -->
                                        <ng-container matColumnDef="accountcontacts">
                                            <mat-header-cell *matHeaderCellDef> account contacts</mat-header-cell>
                                            <mat-cell *matCellDef="let element">
                                                {{element.account_contacts_licenses_cnt ?
                                                element.account_contacts_licenses_cnt : '-'}}
                                                <span *ngIf="element.account_contacts_licenses_cost">
                                                    (
                                                    {{element.account_contacts_licenses_cost ?
                                                    ((element.account_contacts_licenses_cost | number)
                                                    | currency) : '-'}}
                                                    )
                                                </span>
                                            </mat-cell>
                                        </ng-container>


                                        <mat-header-row *matHeaderRowDef="closuresdisplayedColumns;"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: closuresdisplayedColumns;"></mat-row>
                                    </mat-table>
                                </div>
                                <div *ngIf="closuresDatasource.data.length == 0" class="text-center">
                                    <h2 class="heading m-0 py-16 border">No Records Found</h2>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </section>

                <!-- Refunds Report -->
                <section class="m-12 mat-elevation-z5 border-radius-4">
                    <div class="px-12 py-4 grey-300-bg" fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <h2 class="muli-bold font-size-16 m-0" fxFlex="auto"> Refunds
                        </h2>
                        <div fxFlex="10" class="text-right mr-12">
                            <button mat-icon-button *ngIf="refundsChart" (click)="refresh('refunds')" class="mr-4">
                                <mat-icon> refresh </mat-icon>
                            </button>
                            <button mat-icon-button (click)="refundsChart = !refundsChart">
                                <mat-icon *ngIf="!refundsChart" matTooltip="List">list</mat-icon>
                                <mat-icon *ngIf="refundsChart" matTooltip="chart">insert_chart_outlined</mat-icon>
                            </button>
                        </div>

                        <div fxFlex="20" *ngIf="refundsChart">
                            <form [formGroup]="refundsForm">
                                <mat-form-field appearance="outline" class="w-100-p">
                                    <mat-label></mat-label>
                                    <mat-select formControlName="country_id">
                                        <mat-option *ngFor="let cust of countries" [value]="cust.value">
                                            {{cust.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>

                        <div fxFlex="20" *ngIf="!refundsChart && refundsChartDataSource">
                            <form [formGroup]="refundsYearForm">
                                <mat-form-field appearance="outline" class="w-100-p">
                                    <mat-label></mat-label>
                                    <mat-select formControlName="country_id">
                                        <mat-option *ngFor="let cust of countries" [value]="cust.value">
                                            {{cust.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>

                    </div>

                    <div *ngIf="!refundsChart && refundsChartDataSource">
                        <div class="mt-12">
                            <form [formGroup]="refundsYearForm">
                                <mat-form-field appearance="outline" class="w-252 ml-12">
                                    <mat-label>Select Year</mat-label>
                                    <mat-select formControlName="year">
                                        <mat-option *ngFor="let year of years" [value]="year.value">
                                            {{year.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        <fusioncharts width="100%" height="400" type="stackedcolumn2dline" dataFormat="json"
                            [dataSource]="refundsChartDataSource" (dataplotClick)=refundsMothlyList($event)>
                        </fusioncharts>
                    </div>

                    <!-- Table Listing -->
                    <ng-container *ngIf="refundsDataSource && refundsChart">
                        <form [formGroup]="refundsForm">
                            <div fxLayout='row wrap' fxLayoutAlign="space-between center"
                                class="fuse-white-bg px-12 pt-12">
                                <div fxFlex="20">
                                    <mat-form-field class="w-100-p ml-8" appearance="outline">
                                        <mat-label>Select</mat-label>
                                        <mat-select appMatSearchSelector class="w-100-p" formControlName="customer"
                                            multiple>
                                            <app-mat-search-selector placeholder='Search..'></app-mat-search-selector>
                                            <ng-container *ngFor="let topping of toppingList">
                                                <mat-option *appMatSearchSelectorItems [value]="topping.id">
                                                    {{topping.display_name}}
                                                </mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div fxFlex="20">
                                    <mat-form-field class="w-100-p ml-8" appearance="outline">
                                        <mat-label>Refund To</mat-label>
                                        <mat-select formControlName="refund_type">
                                            <mat-option *ngFor="let rftype of refund_types" [value]="rftype.value">
                                                {{rftype.viewValue}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div fxFlex="15">
                                    <mat-form-field class="w-90-p m-20" appearance="outline"
                                        [matMenuTriggerFor]="menu5">
                                        <mat-label>Select date</mat-label>
                                        <mat-date-range-input [rangePicker]="rangePicker">
                                            <input matStartDate placeholder="Start date" readonly
                                                formControlName="start_date">
                                            <input matEndDate placeholder="End date" readonly
                                                formControlName="end_date">
                                        </mat-date-range-input>
                                    </mat-form-field>

                                    <mat-menu #menu5="matMenu">
                                        <button mat-menu-item (click)="setDate('refundsForm','this_week')"
                                            [ngClass]="{'primary': (dateActive == 'this_week')}">This Week</button>
                                        <button mat-menu-item (click)="setDate('refundsForm','this_month')"
                                            [ngClass]="{'primary': (dateActive == 'this_month')}">This Month</button>
                                        <button mat-menu-item (click)="setDate('refundsForm','last_month')"
                                            [ngClass]="{'primary': (dateActive == 'last_month')}">Last Month</button>
                                        <button mat-menu-item (click)="rangePicker.open();dateActive = 'custom';"
                                            [ngClass]="{'primary': dateActive == 'custom'}">Custom Range</button>
                                        <button mat-menu-item (click)="setDate('refundsForm','clear')"
                                            [ngClass]="{'primary': dateActive == 'clear'}">Clear</button>
                                        <!-- <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle> -->
                                        <mat-date-range-picker #rangePicker>
                                            <mat-date-range-picker-actions>
                                                <button mat-button matDateRangePickerCancel>Cancel</button>
                                                <button mat-raised-button color="primary"
                                                    matDateRangePickerApply>Apply</button>
                                            </mat-date-range-picker-actions>
                                        </mat-date-range-picker>
                                    </mat-menu>
                                </div>

                            </div>
                        </form>
                        <div class="px-12 pb-12">
                            <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg">
                                <div class="search-wrapper" fxFlex="30">
                                    <input matInput class="search1"
                                        (keyup.enter)="applyFilter($event.target.value,'refunds')" type="text"
                                        [readOnly]="refundsDataSource.length === 0" [(ngModel)]="refundsSearchValue"
                                        placeholder="Invoice Ref..">
                                    <mat-icon class="search_icon">search</mat-icon>
                                    <mat-icon class="close s-20 danger-fg" *ngIf="refundsSearchValue"
                                        (click)="refundsSearchValue=''; applyFilter('','refunds');">
                                        close
                                    </mat-icon>
                                </div>
                                <div fxFlex="100" class="actions_list" fxLayout="row wrap" fxLayoutAlign="end center">
                                    <div>
                                        <button mat-stroked-button (click)="exportExcel('refunds')">Export as
                                            excel</button>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="end center">
                                        <mat-paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="refundsLength"
                                            (page)="pageEvent($event,'refunds')" [pageSize]="refundspageSize"
                                            [pageIndex]="refundsindex-1">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                            <div class="height-300" fusePerfectScrollbar>
                                <mat-table [dataSource]="refundsDataSource" class="table">

                                    <ng-container matColumnDef="customer_name">
                                        <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> <a class='cursor-pointer'
                                                (click)='navigate(element.company_id)'>{{element.company_name}}</a>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="invoiceRef">
                                        <mat-header-cell *matHeaderCellDef>Invoice Ref# </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="lowercase"> {{element.ref_id}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="amount">
                                        <mat-header-cell *matHeaderCellDef> Amount </mat-header-cell>
                                        <mat-cell *matCellDef="let element" class="lowercase"> ${{element.amount}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="rufund_to">
                                        <mat-header-cell *matHeaderCellDef> Refund To </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.refund_type}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- <ng-container matColumnDef="reason">
                      <mat-header-cell *matHeaderCellDef> Reason </mat-header-cell>
                      <mat-cell *matCellDef="let element">
                        <span *ngIf="element.reason">{{element.reason}}</span> 
                        <span *ngIf="!element.reason">-</span> 
                      </mat-cell>
                    </ng-container> -->

                                    <!-- description Column -->
                                    <ng-container matColumnDef="reason">
                                        <mat-header-cell *matHeaderCellDef fxFlex="330px"> Reason </mat-header-cell>
                                        <mat-cell *matCellDef="let element;let i = index" fxFlex="330px">
                                            <span *ngIf="(element.reason + '').length > 30; else elseBlock">
                                                <span *ngIf="!more[i]">{{element.reason | slice:0:30}} <a
                                                        class="cursor-pointer"
                                                        (click)="more[i] = !more[i]">More</a></span>
                                                <span *ngIf="more[i]" style="white-space: pre-wrap;">{{element.reason}}
                                                    <a class="cursor-pointer"
                                                        (click)="more[i] = !more[i]">Less</a></span>
                                            </span>
                                            <ng-template #elseBlock>{{element.reason}}</ng-template>
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="refund_date">
                                        <mat-header-cell *matHeaderCellDef> Refund Date </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.created_at | date:"MM/dd/yyyy"}}
                                        </mat-cell>
                                    </ng-container>

                                    <!-- action Column -->
                                    <ng-container matColumnDef="actions">
                                        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            <button mat-icon-button class="accent-fg" matTooltip="Download"
                                                *ngIf="element.is_pdf_generated ==1"
                                                (click)="downloadInvocieDoc(element.company_id, element.refund_id, element.invoice_attachment)">
                                                <mat-icon class="s-18">insert_drive_file</mat-icon>
                                            </button>
                                            <span *ngIf="element.is_pdf_generated == 0">-</span>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="refundsTableCols;"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: refundsTableCols;"></mat-row>
                                </mat-table>
                            </div>
                            <div *ngIf="refundsDataSource.data.length == 0" class="text-center">
                                <h2 class="heading m-0 py-16 border">No Records Found</h2>
                            </div>
                        </div>
                    </ng-container>
                </section>

                <!-- ExitCustomers Report -->
                <section class="m-12 mat-elevation-z5 border-radius-4">
                    <div class="px-12 py-4 grey-300-bg" fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <h2 class="muli-bold font-size-16 m-0" fxFlex="auto"> Exit Customers
                        </h2>
                        <div fxFlex="10" class="text-right mr-12">
                            <button mat-icon-button *ngIf="exitCustomersChart" (click)="refresh('exitCustomers')"
                                class="mr-4">
                                <mat-icon> refresh </mat-icon>
                            </button>
                            <button mat-icon-button (click)="exitCustomersChart = !exitCustomersChart">
                                <mat-icon *ngIf="!exitCustomersChart" matTooltip="List">list</mat-icon>
                                <mat-icon *ngIf="exitCustomersChart" matTooltip="chart">insert_chart_outlined</mat-icon>
                            </button>
                        </div>

                        <div fxFlex="20" *ngIf="exitCustomersChart">
                            <form [formGroup]="exitCustomersForm">
                                <mat-form-field appearance="outline" class="w-100-p">
                                    <mat-label></mat-label>
                                    <mat-select formControlName="country_id">
                                        <mat-option *ngFor="let cust of countries" [value]="cust.value">
                                            {{cust.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>

                        <div fxFlex="20" *ngIf="!exitCustomersChart && exitCustomersChartDataSource">
                            <form [formGroup]="exitCustomersYearForm">
                                <mat-form-field appearance="outline" class="w-100-p">
                                    <mat-label></mat-label>
                                    <mat-select formControlName="country_id">
                                        <mat-option *ngFor="let cust of countries" [value]="cust.value">
                                            {{cust.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>

                    </div>

                    <div *ngIf="!exitCustomersChart && exitCustomersChartDataSource">
                        <div class="mt-12">
                            <form [formGroup]="exitCustomersYearForm">
                                <mat-form-field appearance="outline" class="w-252 ml-12">
                                    <mat-label>Select Year</mat-label>
                                    <mat-select formControlName="year">
                                        <mat-option *ngFor="let year of years" [value]="year.value">
                                            {{year.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        <fusioncharts width="100%" height="400" type="stackedcolumn2dline" dataFormat="json"
                            [dataSource]="exitCustomersChartDataSource" (dataplotClick)=exitCustomersMothlyList($event)>
                        </fusioncharts>
                    </div>

                    <!-- Table Listing -->
                    <ng-container *ngIf="exitCustomersDataSource && exitCustomersChart">
                        <form [formGroup]="exitCustomersForm">
                            <div fxLayout='row wrap' fxLayoutAlign="space-between center"
                                class="fuse-white-bg px-12 pt-12">
                            </div>
                        </form>
                        <div class="px-12 pb-12">
                            <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg">
                                <div class="search-wrapper" fxFlex="30">
                                    <input matInput class="search1"
                                        (keyup.enter)="applyFilter($event.target.value,'exitCustomers')" type="text"
                                        [readOnly]="exitCustomersDataSource.length === 0"
                                        [(ngModel)]="exitCustomersSearchValue" placeholder="Customer..">
                                    <mat-icon class="search_icon">search</mat-icon>
                                    <mat-icon class="close s-20 danger-fg" *ngIf="exitCustomersSearchValue"
                                        (click)="exitCustomersSearchValue=''; applyFilter('','exitCustomers');">
                                        close
                                    </mat-icon>
                                </div>
                                <div fxFlex="100" class="actions_list" fxLayout="row wrap" fxLayoutAlign="end center">
                                    <div>
                                        <button mat-stroked-button (click)="exportExcel('exitCustomers')">Export as
                                            excel</button>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="end center">
                                        <mat-paginator [pageSizeOptions]="[15, 25, 50,100]"
                                            [length]="exitCustomersLength" (page)="pageEvent($event,'exitCustomers')"
                                            [pageSize]="exitCustomerspageSize" [pageIndex]="exitCustomersindex-1">
                                        </mat-paginator>
                                    </div>
                                </div>
                            </div>
                            <div class="height-300" fusePerfectScrollbar>
                                <mat-table [dataSource]="exitCustomersDataSource" class="table">

                                    <ng-container matColumnDef="customer_name">
                                        <mat-header-cell *matHeaderCellDef> Customer </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> <a class='cursor-pointer'
                                                (click)='navigate(element.id)'>{{element.company_name}}</a> </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="contact_name">
                                        <mat-header-cell *matHeaderCellDef>Contact Name </mat-header-cell>
                                        <mat-cell *matCellDef="let element">
                                            {{(element.client_info)?element.client_info.first_name:'-'}}
                                            {{(element.client_info)?element.client_info.last_name:''}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="contact_email">
                                        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="text-transform: none;">
                                            {{element.client_info.email?element.client_info.email:''}} </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="onboarded_on">
                                        <mat-header-cell *matHeaderCellDef> Onboarded On </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.onboarded_on |
                                            date:"MM/dd/yyyy"}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="exit_date">
                                        <mat-header-cell *matHeaderCellDef> Exit Date </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.exit_date | date:"MM/dd/yyyy"}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="reason">
                                        <mat-header-cell *matHeaderCellDef fxFlex="330px"> Reason </mat-header-cell>
                                        <mat-cell *matCellDef="let element;let i = index" fxFlex="330px">
                                            <span *ngIf="(element.exit_comments + '').length > 30; else elseBlock">
                                                <span *ngIf="!more[i]">{{element.exit_comments | slice:0:30}} <a
                                                        class="cursor-pointer"
                                                        (click)="more[i] = !more[i]">More</a></span>
                                                <span *ngIf="more[i]"
                                                    style="white-space: pre-wrap;">{{element.exit_comments}} <a
                                                        class="cursor-pointer"
                                                        (click)="more[i] = !more[i]">Less</a></span>
                                            </span>
                                            <ng-template #elseBlock>{{element.exit_comments}}</ng-template>
                                        </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="exitCustomersTableCols;"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: exitCustomersTableCols;"></mat-row>
                                </mat-table>
                            </div>
                            <div *ngIf="exitCustomersDataSource.data.length == 0" class="text-center">
                                <h2 class="heading m-0 py-16 border">No Records Found</h2>
                            </div>
                        </div>
                    </ng-container>
                </section>

                <!-- MRR -->
                <section class="m-12 mat-elevation-z5 border-radius-4"
                    *ngIf="dashboard_permissions['dashboard.mrr.view']">
                    <div class="px-12 py-4 grey-300-bg" fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <h2 class="muli-bold font-size-16 m-0"> MRR
                        </h2>

                        <div fxFlex="10" class="text-right mr-12">
                            <button mat-stroked-button (click)="exportMrrData()">Export</button>
                        </div>

                        <div fxFlex="20" *ngIf="MRRChart && MRRChartDataSource">
                            <form [formGroup]="MRRYearForm">
                                <mat-form-field appearance="outline" class="w-100-p">
                                    <mat-label></mat-label>
                                    <mat-select formControlName="country_id">
                                        <mat-option *ngFor="let cust of countries" [value]="cust.value">
                                            {{cust.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>

                    </div>

                    <div *ngIf="MRRChart && MRRChartDataSource">
                        <div class="mt-12">
                            <form [formGroup]="MRRYearForm">
                                <mat-form-field appearance="outline" class="w-252 ml-12">
                                    <mat-label>Select Year</mat-label>
                                    <mat-select formControlName="year">
                                        <mat-option *ngFor="let year of years" [value]="year.value">
                                            {{year.viewValue}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                        <fusioncharts width="100%" height="400" type="stackedcolumn2dline" dataFormat="json"
                            [dataSource]="MRRChartDataSource">
                        </fusioncharts>
                    </div>

                </section>
            </div>
        </div>
    </div>