import { NgModule } from '@angular/core';

import { ClientsRoutingModule } from './clients-routing.module';
import { ClientsComponent } from './clients.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatSearchSelectorModule } from 'app/mat-search-selector/mat-search-selector.module';
import { CommonAllModule } from 'app/common-all/common-all.module';
import { DiscardReportsComponent } from './discard-reports/discard-reports.component';
import { ClientAllComponent } from './client-all/client-all.component';
import { EditFieldsComponent } from './client-all/edit-fields/edit-fields.component';
import { TrailComapniesComponent } from './trail-comapnies/trail-comapnies.component';
import { TrailSuscriptionendDateDialogComponent } from './trail-suscriptionend-date-dialog/trail-suscriptionend-date-dialog.component';
import { ActiveCustomersComponent } from './active-customers/active-customers.component';
import { MxrecordsValidationRemainderComponent } from './mxrecords-validation-remainder/mxrecords-validation-remainder.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TotalActiveUsersComponent } from './total-active-users/total-active-users.component';
import { PaymentsComponent } from './payments/payments.component';
import { ExitCustomersComponent } from './exit-customers/exit-customers.component';


@NgModule({
  declarations: [
    ClientsComponent,
    ClientAllComponent,
    DiscardReportsComponent,
    EditFieldsComponent,
    TrailComapniesComponent,
    TrailSuscriptionendDateDialogComponent,
    ActiveCustomersComponent,
    MxrecordsValidationRemainderComponent,
    TotalActiveUsersComponent,
    PaymentsComponent,
    ExitCustomersComponent
  ],
  imports: [
    ClientsRoutingModule,
    FuseSharedModule,
    MatSearchSelectorModule,
    CommonAllModule,
    ScrollingModule
  ]
})
export class ClientsModule { }
