import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CommonHelpersService } from 'app/services/common-helpers.service';
import { ExcelService } from 'app/services/excel.service';
import { ReportsService } from 'app/services/reports.service';

@Component({
  selector: 'app-due-customers',
  templateUrl: './due-customers.component.html',
  styleUrls: ['./due-customers.component.scss']
})
export class DueCustomersComponent implements OnInit {

  displayedColumns: string[] = ['sno', 'customer_name','region', 'bill_amount', 'billing_cycle', 'billing_date','end_date','license_cost', 'addon_cost'];
  dataSource:any;
  searchValue = '';
  params: { page: number; limit?: number, search_key?: any, date_range?:any, country_id?:any };
  totalRecords:any;
  revenewSummary:any;
  responseData:any;
  listSelectedCountry:any;
  currencySymbol:any;

  constructor(
    private reportService: ReportsService,
    public router: Router,
    public dialog: MatDialog,
    private helperService:CommonHelpersService,
    private excelService: ExcelService,
  ) {
    
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    this.getList();
  }

  applyFilter(value) {
    this.params['search_key'] = value;
    this.getList();
  }

  getList() {
    this.reportService.getDueCustomers(this.params).subscribe(res => {
      this.dataSource = new MatTableDataSource(res.data.data);
      this.totalRecords = res.data.data.length;
      this.responseData = res.data;
      this.revenewSummary = this.responseData.revenew_summary;
    })
  }

  changeCounry(e: any) {
    this.searchValue = '';
    this.params['search_key']= '';
    this.params['country_id'] = e.value;
    this.listSelectedCountry = e.value;
    this.getList();
    this.currencySymbol = this.helperService.getCurrencySymbol(e.value);
  }

  exportExcel() {
    let prepared_data = this.prepareExcelData(this.responseData.data);
    let sheet_name = 'Due Customers';
    this.excelService.exportAsExcelFile(prepared_data, sheet_name);
    
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name'] = element.name;
      eachRow['Region'] = element.region;
      eachRow['Bill Amount'] = element.billing_amount;
      eachRow['Billing Cycle'] = element.billing_cycle;
      eachRow['Billing Date'] = element.billing_date;
      eachRow['Subscription End Date'] = element.subscription_end_date;
      eachRow['Licenses Cost'] = element.licenses_amount;
      eachRow['Add-ons Cost'] = element.addons_amount;
      
      ArrData.push(eachRow);
    });
    return ArrData;
  }
}
