import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {

  topcardsCount: any;
  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.getTopCardsCount();
  }
  getTopCardsCount() {
    this.commonService.labelsCount().subscribe(result => {
      if (result.status === 1) {
        this.topcardsCount = result.dash_board_arr;
      }
    })
  }
}
