<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-header">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold text-capitalize active">sudhakarmaxo - Candidate Migrations</a></li>
    </ul>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-20 mb-52">
      <div class="content" fusePerfectScrollbar>
        <form>
          <div fxLayout="row wrap" fxLayoutAlign="space-around center" class="mt-32">
            <div fxFlex="30" class="text-center file-upload mat-elevation-z5 border-radius-8 py-24">
              <label for="ceipal">
                <mat-icon class="s-32 mr-12">get_app</mat-icon>
                <span class="muli-bold">Ceipal</span>
              </label>
              <input type="file" id="ceipal">
            </div>
            <div fxFlex="30" class="text-center file-upload mat-elevation-z5 border-radius-8 py-24">
              <label for="zoniac">
                <mat-icon class="s-32 mr-12">get_app</mat-icon>
                <span class="muli-bold">Zoniac</span>
              </label>
              <input type="file" id="zoniac">
            </div>
          </div>
        </form>
      </div>
      <div class="footer">
        <button mat-button (click)="back()">Back</button>
      </div>
    </div>
  </div>
</div>