import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormArray, Validators, FormArrayName, FormControl } from '@angular/forms';
import { ValidateService } from 'app/services/validate.service'; 
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-renewal-remainder-dialog',
  templateUrl: './renewal-remainder-dialog.component.html',
  styleUrls: ['./renewal-remainder-dialog.component.scss']
})
export class RenewalRemainderDialogComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  company_id: any;
  CommunicationForm: FormGroup;
  shotochips: boolean = false;
  to: string[] = this.data.to_email;
  cc: string[] = this.data.cc_email;
  toEmail: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private fb: FormBuilder,
  private commonService: CommonService,
  private companyDetailService: CompanyDetailsService,
  private validateservice: ValidateService,
  public dialogRef: MatDialogRef<RenewalRemainderDialogComponent>,) {
      this.CommunicationForm = this.fb.group({
      })
  }

  ngOnInit() {
  }

  addToEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.to.push(value);
    }
    
    if (input)
      input.value = '';
  }
  removeToEmail(toEmail: string): void {
    const index = this.to.indexOf(toEmail);

    if (index >= 0) {
      this.to.splice(index, 1);
    }
  }

  addCcEmail(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.cc.push(value);
    }
    
    if (input)
      input.value = '';
  }
  removeCcEmail(ccEmail: string): void {
    const index = this.cc.indexOf(ccEmail);

    if (index >= 0) {
      this.cc.splice(index, 1);
    }
  }

  update() {
    if (this.CommunicationForm.valid) {
      let params = {
        company_id: this.data.company_id,
        to: this.to,
        cc: this.cc,
      }
      this.companyDetailService.updateRenewalRemainderEmail(params).subscribe(res => {
        if(res.status) {
          this.commonService.openSnackBar('primary', res.message);
          this.dialogRef.close(1);
        } else {
          this.commonService.openSnackBar('warn', res.message);
        }
      })
    } else {
      this.validateservice.validateAllFormFields(this.CommunicationForm);
    }
  }
  
}
