import { Injectable } from '@angular/core';
import { SharedServiceService } from './shared-service.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiUrls } from './apiurls';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private showLoader: boolean;
  public showLoaderObservable = new BehaviorSubject<boolean>(false);
  public masterhomepermissions = new BehaviorSubject<any>('');
  permissions = this.masterhomepermissions.asObservable();
  public currentUserdata = new BehaviorSubject<any>('');

  private fileSource = new BehaviorSubject({});
  currentFile = this.fileSource.asObservable();
  public fileupload;
  public folderpath;

  _subscriptionType: string;

  constructor(
    private sharedService: SharedServiceService,
    private snackBar: MatSnackBar
  ) {
  }

  set subscriptionType(value: string) {
    this._subscriptionType = value;
  }

  get subscriptionType(): string {
    return this._subscriptionType;
  }

  setpermissions(permissions: any) {
    this.masterhomepermissions.next(permissions);
  }

  setCurrentUser(user: any) {
    this.currentUserdata.next(user);
  }

  get currentUser() {
    return this.currentUserdata.asObservable();
  }

  openSnackBar(className: string, _message: string, duration?: number): void {
    this.snackBar.open(_message, '', {
      duration: duration ? duration : 3000,
      panelClass: [className],
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  getLoader() {
    this.showLoader = this.showLoader === true ? false : true;
    this.showLoaderObservable.next(this.showLoader);
  }

  getToken() {
    return sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null;
  }

  signupRequestTableData(params): Observable<any> {
    return this.sharedService.get<any>(ApiUrls.signUpRequest.getTableData, params);
  }

  exportAsExcel(params): Observable<any> {
    return this.sharedService.get(ApiUrls.signUpRequest.exportAsExcel, params);
  }

  labelsCount(): Observable<any> {
    return this.sharedService.get(ApiUrls.topcards.labelsCount);
  }

  getList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.getListData, params);
  }

  saveSignupRequest(params): Observable<any> {
    if (typeof params.id != 'undefined') {
      return this.sharedService.post(ApiUrls.signUpRequest.updateSignup, params);
    }
    return this.sharedService.post(ApiUrls.signUpRequest.saveSignup, params);
  }

  deleteSignupRequest(params): Observable<any> {
    return this.sharedService.post(ApiUrls.signUpRequest.deleteSignup, params);
  }

  saveClientNotes(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.saveClientNotes, params);
  }


  CreateCompanySignup(request_id): Observable<any> {
    const url = ApiUrls.signUpRequest.createCompany + '/' + request_id;
    return this.sharedService.get(url, {});
  }

  SetCompanySignup(request_id): Observable<any> {
    const url = ApiUrls.signUpRequest.setCompany + '/' + request_id;
    return this.sharedService.post(url, {});
  }

  udpateCompanyInfo(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.updateTrialCompanyInfo, params);
  }

  textRecruitList(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.textRecruitList, params);
  }

  deleteTextRecruiterList(id): Observable<any> {
    const url = ApiUrls.companyInfo.deleteTextRecruiterList + '/' + id;
    return this.sharedService.get(url);
  }

  updateResumeParser(params) {
    return this.sharedService.post(ApiUrls.companyInfo.updateResumeParser, params);
  }

  updateSolorIndex(params) {
    return this.sharedService.post(ApiUrls.companyInfo.updateSoloarIndex, params);
  }

  payments(params) {
    return this.sharedService.post(ApiUrls.topcards.payments, params);
  }

  saveUserLicenses(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveUserLicense, params);
  }

  updateUserLicenses(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.updateUserLicense, params);
  }

  saveMassMailandContacts(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveCredits, params);
  }

  getDefaultAddons(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getDefaultAddons, params);
  }

  saveTrialData(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveTrialMode, params);
  }

  getPaidModeData(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getPaidMode, params);
  }

  getPaidModeDataSubscription(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getPaidModeSubscription, params);
  }

  getPaidmodeCompanySubscription(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getPaidmodeCompanySubscription, params);
  }

  saveDefaultAddonsSubscription(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveDefaultAddonsSubscription, params);
  }

  getAddonList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getAddon, params)
  }

  getLogin(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.login, params);
  }

  getLoginWithUsername(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.loginWithUsername, params);
  }

  mxRecords(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.mxRecords, params);
  }

  doLogout(): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.logout, {});
  }

  changePassword(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.changePassword, params);
  }
  editUser(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.editUser, params);
  }

  getCompanyList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.getListData, params);
  }

  getCurrentUser(): Observable<any> {
    return this.sharedService.get(ApiUrls.commonUrls.currentUserData, {});
  }

  getAddonsList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getAddonsList, params);
  }

  getUsersList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.users.getListData, params);
  }

  saveAddon(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveAddon, params);
  }

  revenueReport(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.revenueReport, params);
  }

  signups(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.signups, params);
  }

  closures(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.closures, params);
  }

  exit_customers(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.exit_customers, params);
  }

  payments_decliened(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.payments_decliened, params);
  }

  downloadInvoice(company_id, invoice_id, fileName) {
    const url = ApiUrls.companyInfo.downloadInvoice + '/' + company_id + '/' + invoice_id + '/' + fileName;
    return this.sharedService.get<any>(url, null, null, 'arraybuffer');
  }

  downloadOfflinePayment(company_id, invoice_id) {
    const url = ApiUrls.companyInfo.downloadOfflineAttchment + '/' + company_id + '/' + invoice_id;
    return this.sharedService.get<any>(url, null, null, 'arraybuffer');
  }

  total_monthly_revenue(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.total_monthly_revenue, params);
  }

  total_signups_closures(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.total_signups_closures, params);
  }

  activateMail(record_id) {
    const url = ApiUrls.signUpRequest.activateMail + '/' + record_id;
    return this.sharedService.get(url);
  }

  resetUserPassword(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.resetUserPassword, params);
  }

  MonthlySubscriptionReport(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.monthlySubscriptionRevenue, params);
  }

  MonthlySubscriptionChartReport(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.monthlySubscriptionChartData, params);
  }

  MRRChartReport(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.MRRCharData, params);
  }

  paymentModeWisePaymentsChartReport(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.paymentModeWisePaymentsCharData, params);
  }

  downloadRefundInvoice(company_id, invoice_id) {
    const url = ApiUrls.companyInfo.downloadRefundInvoice + '/' + company_id + '/' + invoice_id;
    return this.sharedService.get<any>(url, null, null, 'arraybuffer');
  }

  getModulePermissions(params): Observable<any> {
    return this.sharedService.get(ApiUrls.commonUrls.modulePermissions, params);
  }

  verifyUserMobileNumber(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.verifyMobileNumber, params);
  }

  sendResetLinkEmail(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.sendResetLinkEmail, params);
  }

  showResetForm(token): Observable<any> {
    const url = ApiUrls.commonUrls.showResetForm + '/' + token;
    return this.sharedService.get(url, {});
  }

  resetPassword(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.resetPassword, params);
  }

  loginWithFireBase(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.FireBaseLogin, params);
  }

  updatCandidateSearch(params) {
    return this.sharedService.post(ApiUrls.companyInfo.updateCandidateSearchbyPermission, params);
  }

  verifySignupEmail(params): Observable<any> {
    return this.sharedService.post(ApiUrls.signUpRequest.verifyEmail, params);
  }

  sendLoginEmail(params) {
    return this.sharedService.post(ApiUrls.signUpRequest.loginEmail, params);
  }

  clientMigrationsRequests(params) {
    return this.sharedService.post(ApiUrls.commonUrls.migrationRequests, params);
  }

  clientMigrationManagement(params) {
    return this.sharedService.post(ApiUrls.commonUrls.migrationManagement, params);
  }

  dataBackupRequests(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.dataBackupRequests, params);
  }

  getFTPdetails(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.ftpDetails, params);
  }

  getFTPfiles(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.ftp_files, params);
  }

  deleteFTPdetails(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.deleteFTPrecord, params);
  }

  deleteMigrationRequest(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.deleteMigrationRequest, params);
  }

  importExcelFile(comp_id, client_name, params): Observable<any> {
    // console.log(params);
    const url = ApiUrls.clients.importExcelFile + '/' + client_name + '/' + comp_id;
    if (params instanceof File) {
      const formdata = new FormData();
      formdata.set('imported_file[imported_file]', params);
      return this.sharedService.post(url, formdata)
    } else {
      return this.sharedService.post(url, params);
    }
  }

  changeFile(file: any, id: any, clientname: any) {
    this.fileSource.next({ file, id, clientname })
  }

  uploadExcelData(comp_id, client_name, params): Observable<any> {
    const url = ApiUrls.clients.uploadExcelData + '/' + client_name + '/' + comp_id;
    return this.sharedService.post(url, params);
  }

  refundsChartData(params): Observable<any> {
    return this.sharedService.post(ApiUrls.dashboard.refundsChartData, params);
  }

  exitCustomersChartData(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.exitCustomersChartData, params);
  }

  exitCustomersListData(params): Observable<any> {
    return this.sharedService.post(ApiUrls.dashboard.exitCustomersListData, params);
  }

  refundsReportData(params): Observable<any> {
    return this.sharedService.post(ApiUrls.dashboard.refundsMonthReport, params);
  }

  upgradePaymentLinkToCustomer(params): Observable<any> {
    return this.sharedService.get(ApiUrls.clients.upgradeLinkToCustomer, params);
  }

  enableMaintenanceMode(params): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.enableMaintenanceMode, params);
  }

  getExitRequests(params): Observable<any> {
    return this.sharedService.get(ApiUrls.commonUrls.exitRequests, params);
  }

  getExitRequestsAddons(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.exit_request_addons_data, params);
  }

  approveExitRequest(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.approveExitRequest, params);
  }

  rejectExitRequest(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.rejectExitRequest, params);
  }

  getLeadOwnersList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.lead_owners.getListData, params);
  }

  saveLeadOwner(params): Observable<any> {
    return this.sharedService.post(ApiUrls.lead_owners.save, params);
  }

  deleteLeadOwner(id): Observable<any> {
    return this.sharedService.get(ApiUrls.lead_owners.deleteRecord + '/' + id);
  }

  getDemoRequestList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.demo_requests, params);
  }

  getLeadAssignmentsList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.lead_owners.lead_assignments, params);
  }

  getPowerBiReport(params): Observable<any> {
    return this.sharedService.get(ApiUrls.powerbi.getBiReport, params);
  }

  getReportsListing(params): Observable<any> {
    return this.sharedService.get(ApiUrls.powerbi.reportsListing, params);
  }

  getSubscriptionInvoices(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.subscription_invoices, params);
  }

  deleteSubscriptionInvoice(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.delete_invoice, params);
  }

  getCpanelActivities(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.cpanel_activities, params);
  }

  candidateMigrationRecordSave(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.candidateMigrationRecord, params);
  }

  getCandidateMigrationRecords(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getCandidateMigrationRecords, params);
  }

  deleteCandidateMigrationRecord(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.deleteCandidateMigrationRecords, params);
  }

  getCpanelAllRoleUsers(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getCpanelAllRoleUsers, params);
  }

  downloadMrrData(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.downloadMrrData, params);
  }

  downloadMonthlyPaymentsData(params): Observable<any> {
    return this.sharedService.get(ApiUrls.dashboard.downloadMonthlyPaymentsData, params);
  }

  getAccountLostInfo(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.isAccountLost, params);
  }

  getModuleCommentsData(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getModuleComments, params);
  }

  saveModuleComments(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveModuleComments, params);
  }

  deleteModuleComments(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.deleteModuleComments, params);
  }

  getImplementationListing(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.implementationListing, params);
  }

  getDataMigrationListing(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.dataMigrtionListing, params);
  }

  getStripeBalanceTransactions(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.stripeBalTransactions, params);
  }

  getDoccumentsList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.getDoccumentsList, params);
  }

  saveDoccumentsList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.saveDoccumentsList, params);
  }

  revokeDoccument(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.revokeDoccument, params);
  }

  deleteDoccument(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.deleteDoccument, params);
  }

  getCompanyData(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.getCompanyData, params);
  }

  sendDoccument(params): Observable<any> {
    return this.sharedService.post(ApiUrls.commonUrls.sendDoccument, params);
  }

  getCompanyubscriptionPlanDetails(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getCompanyubscriptionPlanDetails, params);
  }

  getCountrySubscriptionPlanDetails(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getCountrySubscriptionPlanDetails, params);
  }

  saveCountrySubscriptionPlanDetails(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveCountrySubscriptionPlanDetails, params);
  }

  saveCompanySubscriptionPlanDetails(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveCompanySubscriptionPlanDetails, params);
  }

  getsubscriptionPlanDetails(params): Observable<any> {
    return this.sharedService.get(ApiUrls.companyInfo.getsubscriptionPlanDetails, params)
  }

  saveSubscriptionDetails(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveSubscriptionDetails, params)
  }

  getCountryDetails(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getCountryDetails, params);
  }

  getPlanDetails(): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getPlanDetails);
  }

  saveCoupon(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.saveCoupon, params);
  }

  couponsImport(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.couponsImport, params);
  }

  getCouponsList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getCouponsList, params);
  }

  getCouponsCompanyList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getCouponsCompanyList, params);
  }

  getCouponCompanyId(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getCouponCompanyId, params);
  }

  deleteCoupon(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.deleteCoupon, params);
  }

  getFreeSubscriptionPlanDetails(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getFreeSubscriptionPlanDetails, params);
  }

  getPaidAddonTypeList(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.getPaidAddonTypeList, params);
  }

  updateAddonTypeData(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.updateAddonTypeData, params);
  }

  updateAddonSubscription(params): Observable<any> {
    return this.sharedService.post(ApiUrls.companyInfo.updateAddonSubscription, params);
  }

  companyList(): Observable<any> {
    return this.sharedService.get(ApiUrls.clients.companyList, {});
  }


  cpanelEndSessions(params: any): Observable<any> {
    return this.sharedService.post(ApiUrls.clients.endSessions, params);
  }
}
