import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { AddLeadownerComponent } from './add-leadowner/add-leadowner.component';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-lead-owners',
  templateUrl: './lead-owners.component.html',
  styleUrls: ['./lead-owners.component.scss']
})
export class LeadOwnersComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email','contact_number', 'region','sort_order' ,'status', 'actions'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  currentUser = {};
  activeLeadIds = {};
  getlist: any = 'lead_owners';
  params: { page: number; limit?: number, searchKey?: any, list_type?:any;};

  constructor(
    private commonService: CommonService,
    public router: Router, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    this.getList();
    this.getCurrentUser();
  }
  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    if (this.getlist == 'lead_owners') {
      this.getList();
    } else {
      this.getLeadAssignments(this.getlist);
    }
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    if (this.getlist == 'lead_owners') {
      this.getList();
    } else {
      this.getLeadAssignments(this.getlist);
    }
  }

  getDataList(type) {
    this.searchValue = '';
    this.params = { limit: 15, page: 1, searchKey:'' };
    if (type == 'lead_owners') {
      this.getList();
    } else {
      this.getLeadAssignments(type);
    }
  }

  getLeadAssignments(type) {
    this.displayedColumns = ['name', 'email', 'region','lead_owner' ,'lead_owner_email'];
    this.getlist = type;
    this.params.list_type = type;
    this.commonService.getLeadAssignmentsList(this.params).subscribe(res => {
      if (res.status == 0) {
        this.commonService.openSnackBar('warn', res.message);
      } 
      let responseTableData = res.data.lead_assignments;
      this.dataSource = new MatTableDataSource(responseTableData.data);
      this.length = responseTableData.total;
      this.pageSize = responseTableData.per_page;
      this.index = responseTableData.current_page;
    })
  }

  getList() {
    this.displayedColumns = ['name', 'email','contact_number', 'region','sort_order' ,'status', 'actions'];
    this.getlist = 'lead_owners';
    this.commonService.getLeadOwnersList(this.params).subscribe(res => {
      if (res.status == 0) {
        this.commonService.openSnackBar('warn', res.message);
      } 
      let responseTableData = res.data.lead_owners;
      this.dataSource = new MatTableDataSource(responseTableData.data);
      this.length = responseTableData.total;
      this.pageSize = responseTableData.per_page;
      this.index = responseTableData.current_page;
      this.activeLeadIds = res.data.activeLeadIds;
    })
  }
  
  addLeadOwner(record = {}) {
    if (isEmpty(record)) {
      record['id'] = '';
    } 
    const dialogRef = this.dialog.open(AddLeadownerComponent, {
      width: '65%',
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getList();
      }
    });
  }

  deleteRecord(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? You want to delete record';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = {};
        this.commonService.deleteLeadOwner(record_id).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
          this.getList();
        });
      } else { }
    })
  }

  getCurrentUser() {
    this.commonService.getCurrentUser().subscribe(res => {
      if (res.status == 1) {
        this.currentUser = res.data;
      }
    })
  }

  checkLastActivity(record_id) {
    
    if (this.activeLeadIds[record_id]) {
      return true;
    }
    return false;
  }
  
}
