<div fxLayout="row wrap" fxLayoutAlign="center start">
  <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between stretch">
    <div fxFlex="60" class="m-12 mat-elevation-z5 border-radius-4">
      <form #subscribeForm="ngForm" (ngSubmit)="saveData()" novalidate>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start" class="p-16">
          <div fxFlex="30">
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>Training POC</mat-label>
              <mat-select required [(ngModel)]="trainingPoc" name="trainingPoc">
                <mat-option *ngFor="let user of usersList" [value]='user.name'>{{user.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="30">
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>Estimated Completion Date</mat-label>
              <input matInput required [matDatepicker]="picker1" (click)="picker1.open()"
                [(ngModel)]="estCompletioinDate" name="estCompletioinDate"
                (dateChange)="onDateChange($event, 'estCompletioinDate')">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div fxFlex="30">
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>Completion Date</mat-label>
              <input matInput required [matDatepicker]="picker2" (click)="picker2.open()" [(ngModel)]="completionDate"
                name="completionDate" (dateChange)="onDateChange($event, 'completionDate')">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div fxFlex="30" class="pt-16">
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>Training Status</mat-label>
              <mat-select required [(ngModel)]="trainingStatus" name="trainingStatus">
                <mat-option *ngFor="let status of statusList" [value]="status.value">
                  {{status.viewValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <div fxFlex="35" class="m-12 mat-elevation-z5 border-radius-4">
      <app-comments moduleSlug='training' [companyId]='company_id' [recordId]="0"></app-comments>
    </div>
  </div>
</div>

<div class='footer'>
  <button mat-button (click)="subscribeForm.ngSubmit.emit()">Save</button>
</div>