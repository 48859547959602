import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { UserLicencesDialogComponent } from '../user-licences-dialog/user-licences-dialog.component';
import { SearchCreditDialogComponent } from '../search-credit-dialog/search-credit-dialog.component';
import { CompanyDetailsService } from 'app/services/company-details.service';

export interface Food {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-renew-subscription',
  templateUrl: './renew-subscription.component.html',
  styleUrls: ['./renew-subscription.component.scss']
})
export class RenewSubscriptionComponent implements OnInit {
  next: boolean = false;
  dataSource: any;
  displayedColumns = ['name', 'quantity', 'cost', 'startdate', 'enddate'];
  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' }
  ];
  company_id: any;
  responseData: any;
  CompanyDetails: any;
  subtotal: any;
  CompanyAddons: any;
  addons_arr: any;
  CurrentSubscription: any;
  licenseType: any;
  per_any_license_cost: any;
  license_title: string;
  addonId: number;
  mass_mail_opts: any;
  contact_search_opts: any;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    private _location: Location
  ) {
    this.company_id = this.route.snapshot.params['id'];
  }


  ngOnInit() {
    this.renewDetails();
  }

  renewDetails() {
    this.companyDetailService.getRenewSubscription(this.company_id).subscribe(res => {
      console.log(res.data);
      if (res.status == 1) {
        this.responseData = res.data;
        this.CompanyDetails = this.responseData.CompDetails;
        this.CompanyAddons = this.responseData.CompanyAddons;
        this.addons_arr = this.responseData.addons;
        this.CurrentSubscription = this.responseData.company_subscription_details;
        this.mass_mail_opts = this.responseData.arrMassMail;
        this.contact_search_opts = this.responseData.arrAccouCnts;
        this.dataSource = new MatTableDataSource(this.CompanyAddons);
        // console.log('source',this.dataSource);
        this.subTotal();
      }
    });
  }

  subTotal() {
    this.subTotal = this.responseData.user_licenses_cost + this.responseData.massmail_licenses_cost + this.responseData.social_licenses_cost + this.responseData.account_contacts_licenses_cost + this.responseData.addon_cost + this.responseData.cart_items_cost + this.responseData.future_sub_addon_cost;
  }

  getLicenseData() {
    if (this.licenseType == 'hire') {
      this.per_any_license_cost = this.responseData.hire_license_addon.cost;
      this.license_title = 'HIRE User Licenses';
      this.addonId = 1;
    }
    if (this.licenseType == 'hr') {
      this.per_any_license_cost = this.responseData.hr_license_addon.cost;
      this.license_title = 'HR User Licenses';
      this.addonId = 5;
    }
    if (this.licenseType == 'crm') {
      this.per_any_license_cost = this.responseData.crm_license_addon.cost;
      this.license_title = 'CRM User Licenses';
      this.addonId = 6;
    }
  }

  UserLicences(license_type) {
    this.licenseType = license_type;
    this.getLicenseData();
    let prepareData: any;
    let otherData: any;
    otherData = {
      'subscription_type': this.CompanyDetails.subscription_type
    }
    prepareData = {
      'per_license_cost': this.per_any_license_cost,
      'license_title': this.license_title,
      'company_id': this.company_id,
      'subscription_id': this.CurrentSubscription.id,
      'company': otherData,
      'addonId': this.addonId
    }
    console.log('dialog', prepareData);
    const dialogRef = this.dialog.open(UserLicencesDialogComponent, {
      width: '50%',
      data: prepareData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.renewDetails();
      }
    });
  }


  AddCredits(credit_type) {
    let prepareData: any;
    let otherData: any;
    otherData = {
      'subscription_type': this.CompanyDetails.subscription_type
    }
    prepareData = {
      'company_id': this.company_id,
      'subscription_id': this.CurrentSubscription.id,
      'company': otherData,
    }
    if (credit_type == 'mass_mails') {
      prepareData['options_list'] = this.mass_mail_opts;
      prepareData['addonId'] = 2;
      prepareData['credit_title'] = 'MassMail Credits';
    }
    if (credit_type == 'contacts') {
      prepareData['options_list'] = this.contact_search_opts;
      prepareData['addonId'] = 4;
      prepareData['credit_title'] = 'Contact Search Credits';
    }

    const dialogRef = this.dialog.open(SearchCreditDialogComponent, {
      width: '50%',
      data: prepareData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.renewDetails();
      }
    });
  }

  back() {
    this._location.back();
  }
}
