<h1 mat-dialog-title class='text-uppercase'>Sales & Support rep</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
    <form [formGroup]="editform">
      <div>
        
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill class="mb-12">
          <div fxFlex='150px'>
            Sales Rep
          </div>
            <div fxFlex>
                <mat-form-field class="w-100-p" appearance='outline'>
                  <mat-select appMatSearchSelector formControlName="sales" >
                    <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                    <mat-option value=''>Select</mat-option>
                    <ng-container *ngFor="let item of userslist">
                      <mat-option *appMatSearchSelectorItems [value]="item.id"> {{item.first_name | titlecase}} {{item.last_name | titlecase}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill class="mb-12">
          <div fxFlex='150px'>
           Support Executive
          </div>
            <div fxFlex>
                <mat-form-field class="w-100-p" appearance='outline'>
                  <mat-select appMatSearchSelector formControlName="support" >
                    <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                    <mat-option value=''>Select</mat-option>
                    <ng-container *ngFor="let item of userslist">
                      <mat-option *appMatSearchSelectorItems [value]="item.id"> {{item.first_name | titlecase}} {{item.last_name | titlecase}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
            </div>
        </div>
        
      </div>
    </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
    <button mat-flat-button class='primary' (click)="save()">Save</button>
</mat-dialog-actions>