import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddUserDialogComponent } from '../add-user-dialog/add-user-dialog.component';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  target_types = {
     1 : 'Monthly' ,
    3 :  'Quarterly' ,
    6 :  'Half-Yearly' ,
    12 : 'Yearly'
};

  displayedColumns: string[] = ['name', 'email', 'mobile_number', 'role','incentive_percentage','incentive_target_amount', 'actions'];
  dataSource;
  searchValue = '';
  getlist: any;
  getTableData: any;
  length: any;
  params = { limit: 15, page: 1, search: '' }
  currentPage: number;
  limit: number;
  currentUser: any;

  constructor(private commonService: CommonService,
    private configService: ConfigurationService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.getCurrentUser();
    this.getUserData();
  }

  getCurrentUser() {
    this.configService.getCurrentUser().subscribe(res => {
      if (res.status == 1) {
        this.currentUser = res.data;
      }
    })
  }

  applyFilter(val) {
    this.params['search'] = val;
    this.params.page = 1;
    this.getUserData();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getUserData();
  }
  getUserData() {
    this.configService.getUsersList(this.params).subscribe(result => {
      if (result.status === 1) {
        this.currentPage = result.cpanel_users.current_page;
        this.limit = result.cpanel_users.per_page;
        this.length = result.cpanel_users.total
        this.dataSource = new MatTableDataSource(result.cpanel_users.data);
      }
    })
  }
  addUser(): void {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '50%',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.getUserData();
      }
    });
  }
  edit(element): void {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '50%',
      data: element
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.getUserData();
      }
    });
  }
  updateStatus(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = element.status == 1 ? 'Are you sure? # You want to In-Active user' : 'Are you sure? # You want to Active user';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.configService.updateStatus({ status: element.status == 1 ? 0 : 1, id: element.id }).subscribe(res => {
          if (res.status === 1) {
            this.getUserData();
            this.commonService.openSnackBar('primary', res.message);
          }
          else {
            this.commonService.openSnackBar('primary', res.message);
          }
        })
      } else {

      }
    })
  }

  deleteUser(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to delete user';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.configService.deleteCpanelUser(record_id).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
          this.getUserData();
        });
      } else {

      }
    })
  }

  showActivities() {
    let activityData = {
      'company_id': 0,
      'module_slug': 'users',
      'show_company_name': false,
      'module_name': 'Users'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
