import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'app/services/common.service';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-add-unsubscribe-email',
  templateUrl: './add-unsubscribe-email.component.html',
  styleUrls: ['./add-unsubscribe-email.component.scss']
})
export class AddUnsubscribeEmailComponent implements OnInit {
  addUnsubscribeForm: FormGroup;

  constructor(
    private configServ: ConfigurationService,
    private commonServ: CommonService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddUnsubscribeEmailComponent>,
  ) {
    this.addUnsubscribeForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      comments: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  addUnsubscribeEmail() {
    if (this.addUnsubscribeForm.valid) {
      this.configServ.saveUnsubscribeEmail(this.addUnsubscribeForm.value).subscribe(res => {
        if (res.status == 1) {
          this.commonServ.openSnackBar('primary', res.message);
          this.dialogRef.close(1);
        } else {
          this.commonServ.openSnackBar('warn', res.message);
        }
      });
    }
  }
}
