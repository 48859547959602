
import { Directive, OnInit, AfterContentInit, OnDestroy, ContentChildren, QueryList, ContentChild, Input, ChangeDetectorRef } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Subject, Observable } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, withLatestFrom, startWith } from 'rxjs/operators';
import { MatSearchSelectorComponent } from './mat-search-selector/mat-search-selector.component';
import { MatSearchSelectorItemsDirective } from './mat-search-selector-items.directive';

/** Directive for marking a `MatSelect` component searchable. */
@Directive({
  selector: '[appMatSearchSelector]'
})
export class MatSearchSelectorDirective implements OnInit, AfterContentInit, OnDestroy {
  /** Holds the list of `MatSearchSelectorItemDirective` references. */
  @ContentChildren(MatSearchSelectorItemsDirective)
  private _matOptionDirectives: QueryList<MatSearchSelectorItemsDirective>;

  /** Holds the reference to the search input component. */
  @ContentChild(MatSearchSelectorComponent, { static: false })
  private _searchableBox: MatSearchSelectorComponent;

  /** Whether the input box should be cleared on opening the dropdown. */
  @Input()
  clearSearchInput = true;

  private previousSelectedValues: any[];

  /** Subject for signalling component destruction. */
  private _destroys$ = new Subject<null>();
  _value: string;

  constructor(
    private _matSelect: MatSelect,
    public cdf: ChangeDetectorRef
  ) { }

  ngOnInit() {
    /** Subscribe to dropdown opening / closing. */
    this._matSelect.openedChange
      .pipe(
        takeUntil(this._destroys$)
      )
      .subscribe(
        opened => {
          if (opened) {
            this._searchableBox.focus();
            this._searchableBox.isMultiple = this._matSelect.multiple;
          } else if (this.clearSearchInput) {
            this._searchableBox.clear();
          }
        }
      );
  }

  ngAfterContentInit() {
    /** Subscribe to filtering input changes. */
    this._searchableBox.changes$
      .pipe(
        debounceTime(50),
        distinctUntilChanged(),
        withLatestFrom(this._getDirectiveChanges()),
        takeUntil(this._destroys$)
      )
      .subscribe(
        ([searchValue, optionDirectives]) => this._filterOptionDirectives(optionDirectives, searchValue)
      );
    this._matSelect.options.changes.subscribe(value => {
      if (value.length === 0) {
        this._searchableBox.noRecords = true;
      }
      else {
        this._searchableBox.noRecords = false;

        let values = value ? value.filter(opt => opt.selected).map(opt => opt.value) : [];
        if (this._matSelect.multiple) {
          let restoreSelectedValues = false;
          if (this.previousSelectedValues && Array.isArray(this.previousSelectedValues)) {
            this.previousSelectedValues.forEach(previousValue => {
              if (values.indexOf(previousValue) === -1) {
                // if a value that was selected before is deselected and not found in the options, it was deselected due to the filtering, so we restore it.
                values.push(previousValue);
                restoreSelectedValues = true;
              }
            });
          }
          if (restoreSelectedValues) {
            this._matSelect._onChange(values);
            this.previousSelectedValues = values;
          }

          if (values.length > 0 && value.map(opt => opt.value).length === value.filter(opt => opt.selected).map(opt => opt.value).length) {
            if (this._searchableBox._selectAllOptionElement && !this._searchableBox._selectAllOptionElement.checked) {
              this._searchableBox._selectAllOptionElement.toggle();
            }
          }
          else {
            if (this._searchableBox._selectAllOptionElement && this._searchableBox._selectAllOptionElement.checked) {
              this._searchableBox._selectAllOptionElement.toggle();
            }
          }
        }

      }
    });
    this._matSelect.valueChange
      .pipe(takeUntil(this._destroys$))
      .subscribe(async (values) => {
        if (this._matSelect.multiple) {
          let restoreSelectedValues = false;
          const optionValues = this._matSelect.options.map(option => option && option.value);
          if (this._searchableBox._searchBox.value && this._searchableBox._searchBox.value.length
            && this.previousSelectedValues && Array.isArray(this.previousSelectedValues)) {
            if (!values || !Array.isArray(values)) {
              values = [];
            }
            await this.previousSelectedValues.forEach(previousValue => {
              if (values.indexOf(previousValue) === -1 && optionValues.indexOf(previousValue) === -1) {
                // if a value that was selected before is deselected and not found in the options, it was deselected due to the filtering, so we restore it.
                values.push(previousValue);
                restoreSelectedValues = true;
              }
            });
          }

          if (restoreSelectedValues) {
            this._matSelect._onChange(values);
          }
          this.previousSelectedValues = values;

          if (values.length === optionValues.length) {
            if (!this._searchableBox._selectAllOptionElement.checked) {
              this._searchableBox._selectAllOptionElement.toggle();
            }
          }
          else {
            if (this._searchableBox._selectAllOptionElement.checked) {
              this._searchableBox._selectAllOptionElement.toggle();
            }
          }
        }
      });
  }

  ngOnDestroy() {
    if (this._matSelect) {
      this._matSelect._onChange(this.previousSelectedValues);
    }
    this._destroys$.next(null);
    this._destroys$.complete();
  }

  /** Filters the available options according to the search value. */
  private _filterOptionDirectives(optionDirectives: QueryList<MatSearchSelectorItemsDirective>, searchValue: string) {

    optionDirectives.forEach(item => {
      const value = searchValue + '';
      const contains = item.text.includes(value.trim().toLowerCase());
      if (contains) {
        if (item.detached) {
          item.attach();
        }
      } else {
        if (!item.detached) {
          item.detach();
        }
      }
      this.cdf.markForCheck();
    });

    const valueChanged = searchValue !== this._value;
    if (valueChanged) {
      this.initMultiSelectedValues();
      this._value = searchValue;
    }
  }

  initMultiSelectedValues() {
    if (this._matSelect.multiple && !this._value) {
      this.previousSelectedValues = this._matSelect.options
        .filter(option => option.selected)
        .map(option => option.value);
    }
  }

  /** Returns the stream of searchable items changes. */
  private _getDirectiveChanges(): Observable<QueryList<MatSearchSelectorItemsDirective>> {
    return this._matOptionDirectives.changes
      .pipe(
        startWith(this._matOptionDirectives)
      );
  }
}
