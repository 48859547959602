import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActiveCustomersComponent } from './active-customers/active-customers.component';
import { ClientAllComponent } from './client-all/client-all.component';
import { ClientsComponent } from './clients.component';
import { DiscardReportsComponent } from './discard-reports/discard-reports.component';
import { ExitCustomersComponent } from './exit-customers/exit-customers.component';
import { TotalActiveUsersComponent } from './total-active-users/total-active-users.component';
import { TrailComapniesComponent } from './trail-comapnies/trail-comapnies.component';

const routes: Routes = [
  {
    path: '',
    component: ClientsComponent,
    children: [
      {
        path: '',
        component: ClientAllComponent
      },
      {
        path: 'discard_reports',
        component: DiscardReportsComponent
      },
      {
        path: 'active_customers',
        component: ActiveCustomersComponent
      },
      {
        path: 'due_customers',
        component: ActiveCustomersComponent
      },
      {
        path: 'trial_active_users',
        // component: TrailCustomersComponent
        component: TrailComapniesComponent
      },
      {
        path: 'exit_users',
        component: ExitCustomersComponent
      },
      {
        path: 'total_active_users',
        component: TotalActiveUsersComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientsRoutingModule { }
