import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  hide1: boolean = true;
  hide: boolean = true;
  hidden: boolean = true;
  changePassword: FormGroup;
  validation_msg: any;

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router
  ) {
    this.changePassword = this.fb.group({
      current_password: ['', Validators.required],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required]
    });
  }

  ngOnInit() {

  }

  savePassword() {
    if (this.changePassword.valid) {
      const saveData = {};
      saveData['current_password'] = this.changePassword.value.current_password;
      saveData['password'] = this.changePassword.value.password;
      saveData['password_confirmation'] = this.changePassword.value.password_confirmation;

      // console.log(saveData);return;
      this.commonService.changePassword(saveData).subscribe(res => {

        if (res.status == 1) {
          this.commonService.openSnackBar('primary', res.message);
          this.router.navigate(['dashboard']);
        } else {
          this.validation_msg = res.message;
          if (typeof res.validations != 'undefined') {
            for (let field_name in res.validations) {
              this.validation_msg += res.validations[field_name];
            }
          }
          this.commonService.openSnackBar('warn', this.validation_msg);
        }
      });
    }
    else {
      this.changePassword.get('current_password').markAsTouched();
      this.changePassword.get('password').markAsTouched();
      this.changePassword.get('password_confirmation').markAsTouched();
    }
  }

}
