import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/services/common.service';
import { CommonModuleDialogActivityComponent } from '../common-module-dialog-activity/common-module-dialog-activity.component';
import { isEmpty } from 'lodash';
import { PlansDialogComponent } from '../plans-dialog/plans-dialog.component';

@Component({
  selector: 'app-common-subscription-plan',
  templateUrl: './common-subscription-plan.component.html',
  styleUrls: ['./common-subscription-plan.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonSubscriptionPlanComponent implements OnInit {
  @Input() hideCountry:boolean = false;
  coutries = [
    { value: 1, viewValue: 'USA ($)' },
    { value: 2, viewValue: 'India (₹)' },
    { value: 702, viewValue: 'Singapore (S$)' },
  ];

  packages = [
    { value: 'hire', viewValue: 'ATS' },
    { value: 'hr', viewValue: 'HR' },
    { value: 'crm', viewValue: 'CRM' },
    { value: 'global', viewValue: 'Global' },
  ];

  params: { country_id?: any; company_id: any; package?: any; plan?: any };
  addondetails: any;
  plan_details:any;
  enabledApps : any;
  activePlanData: any;
  company_id: any;
  selected_country: number = 1;
  // selected_package: any = 'hire';
  planTitleArr = {  "0": "Classic", "1": "Starter", "2": "Professional", "3": "Enterprise" };
  allPlans = { "0": "classic", "1": "starter", "2": "professional", "3": "enterprise" };
  // allPlans = {
  //   'hire': { 0: 'ats_classic', 1: 'ats_starter', 2: 'ats_professional', 3: 'ats_enterprise' },
  //   'hr': { 0: 'hr_classic', 1: 'hr_starter', 2: 'hr_professional', 3: 'hr_enterprise' },
  //   'crm': { 0: 'crm_classic', 1: 'crm_starter', 2: 'crm_professional', 3: 'crm_enterprise' }
  // };

  billingCycleDiscounts = {3:20, 6:20, 12:20};
  billingCycles = {1:1,3:0,6:0, 12:1};
  current_billng_cycle:any = 12;

  @ViewChild('subscribeForm') subscribeForm: FormGroupDirective;
  planData: any;
  addonDetailLength: number = 0;

  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.company_id = this.route.parent.snapshot.params['id'] != undefined ? this.route.parent.snapshot.params['id'] : 0;
    this.params = { company_id: this.company_id };
    if(!this.hideCountry) {
      this.params.country_id = this.selected_country;
    }
    this.getSubscriptionPlans();
  }

  countryChanged() {
    this.params.country_id = this.selected_country;
    this.getSubscriptionPlans();
  }

  productChanged() {
    // this.params.package = this.selected_package;
    this.getSubscriptionPlans();
  }

  getSubscriptionPlans() {
    this.commonService.getsubscriptionPlanDetails(this.params).subscribe(res => {
      if (res.status == 1) {
        // console.log(res);
        this.addondetails = res.data.plan_data;
        this.plan_details = res.data.plans;
        this.enabledApps = res.data.enabledApps;
        this.addonDetailLength = Object.keys(this.addondetails)?.length;
        this.activePlanData = res.data.activePlanData;
        this.billingCycleDiscounts = res.data.discountData;
        this.billingCycles = res.data.billingCyclesData;
        this.current_billng_cycle = res.data.current_billng_cycle;
      }
    });
  }

  checkPlanEnable(event: any, key) {
    if (event.checked) {
      this.activePlanData[this.allPlans[key]] = 1;
    } else {
      this.activePlanData[this.allPlans[key]] = 0;
    }
  }

  unsorted() { }

  trimString(str) {
    var i, frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  restrictTo(element): void {

    let params:any = {
      company_id: this.company_id, 
      // plan: this.allPlans[this.selected_package][element]
    }

    if(!this.hideCountry) {
      params.country_id = this.selected_country;
    }
    
    this.commonService.getFreeSubscriptionPlanDetails(params).subscribe(res => {
      if (res.status == 1) {
        this.planData = res.data.plan_data;

        let list: string[] = [];
        var arrData: any = [];

        this.planData.forEach(element => {
          list.push(element.name);
        });

        arrData = list.join('#');
        arrData = arrData.replaceAll("#", "<br>");
        arrData = this.trimString(arrData);

        const data = [];
        data['title'] = 'Free Addons';
        data['message'] = arrData;
        data['successText'] = 'Close';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          minWidth: '30%',
          minHeight: '40%',
          data: data
        });

      }
    });
  }

  saveData() {
    let planRequired: boolean = false;
    // console.log('billingCycles',this.billingCycles);
    Object.keys(this.activePlanData).forEach((key) => {
      if (this.activePlanData[key] == 1) {
        planRequired = true;
      }
    });

    if (planRequired == false) {
      this.commonService.openSnackBar('warn', 'Please select at least one plan');
      return;
    }

    if (this.subscribeForm.valid) {
      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? You want to update data?';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35%',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          let saveparams:any = {
            // package: this.selected_package,
            company_id: this.company_id,
            data: this.addondetails,
            activePlanData: this.activePlanData,
            billingCycleDiscounts:this.billingCycleDiscounts,
            billingCycles:this.billingCycles
          }

          if(!this.hideCountry) {
            saveparams.country_id = this.selected_country;
          }
          
          this.commonService.saveSubscriptionDetails(saveparams).subscribe(res => {
            if (res.status == 1) {
              this.commonService.openSnackBar('primary', res.message);
              this.getSubscriptionPlans()
            } else {
              this.commonService.openSnackBar('warn', res.message);
            }
          });
        }
      })

    } else {
      this.commonService.openSnackBar('warn', 'Validation Errors, Each Input Must Be Greater Than 0');
      return false;
    }
  }

  showActivities() {
    let activityData = {
      'company_id': this.company_id,
      'module_slug': 'subscriptionPlans',
      'show_company_name': false,
      'module_name': 'Subscription Plans'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }



  showPlanDetails(plain_id:any,plan_slug:any) {
    let dialogueData = {};
    console.log(plan_slug);

    dialogueData['plans'] = this.plan_details[plan_slug];
    dialogueData['enabledApps'] = this.enabledApps;
    dialogueData['plain_id'] = plain_id;
    dialogueData['plan_slug'] = plan_slug;
    dialogueData['planTitleArr'] = this.planTitleArr;
    
   
      // dialogueData['emailData'] = res.data.emailData;
      // dialogueData['users'] = res.data.users;
      // dialogueData['emails'] = res.data.emails;
      // dialogueData['company_id'] = this.company_id;
      // dialogueData['module'] = 'implementation';

      const dialogRef = this.dialog.open(PlansDialogComponent, {
       // width: '100%',
       // position: { "right": "0" },
        panelClass: "fixed-right-dialog",
        minWidth: '90%',
        maxWidth: '90%',
        data: dialogueData
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    
  }

}
