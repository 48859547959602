<div class="page-layout carded fullwidth inner-scroll">
    <mat-toolbar-row class="secondary-headers w-100-p">
        <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
            <li><a class="text-bold active">Reports</a></li>
        </ul>
    </mat-toolbar-row>
    <div class="center px-20">
        <div class="content-card mt-12 mb-52">
            <div class="content" fusePerfectScrollbar>
                <div fxLayout='row' fxLayoutAlign="start start" class="p-12">
                    <div fxFlex="33" *ngIf="getTableData">
                        <div class="report-list cursor-pointer" fxLayout='row' fxLayoutAlign="start center"
                            *ngFor="let item of getTableData.data;" (click)="navigate(item)">
                            <span class="material-icons mr-20">
                                insert_chart_outlined
                            </span>
                            <div class="">{{item.name}}</div>
                        </div>

                        <div class="report-list cursor-pointer" fxLayout='row' fxLayoutAlign="start center" 
                        (click)="navigate({action_url:'dueCustomers', 'enable_powerbi':0})">
                            <span class="material-icons mr-20">
                                insert_chart_outlined
                            </span>
                            <div class="">Due Customers</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>