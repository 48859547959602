<h1 mat-dialog-title class='text-uppercase'>Convert Free {{show_title}} To Paid</h1>
<mat-divider></mat-divider>
<form [formGroup] = "AddLicense">
<mat-dialog-content>
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="grey-100-bg border-radius-4 p-8 mb-8">
    <p class="text-bold m-0" fxFlex="50">Total free {{show_title}} : <span class="accent-fg">{{available_licenses}}</span></p>
    <p class="text-bold m-0" fxFlex>Pro-rate per license : <span class="accent-fg">{{licence_cost_per_month}}</span></p>
  </div>
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlexFill fxLayoutGap="12px grid">
    <div fxFlex='48'>
      <mat-form-field class="w-100-p" appearance='outline'>
        <mat-label>Add Licenses</mat-label>
        <input numeric type="number" min="1" matInput  formControlName="total_licenses" (input)="LicensesAdded()">
      </mat-form-field>
    </div>

    <div fxFlex='48'>
      <mat-form-field class="w-100-p" appearance='outline'>
        <mat-label>Enter Amount ($)</mat-label>
        <input type="number" matInput  formControlName="amount" readonly>
      </mat-form-field>
    </div>

  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button class='primary' (click)="convertLinceses()"  >Convert</button>
</mat-dialog-actions>
</form>