import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/services/common.service';
import { CompanyDetailsService } from 'app/services/company-details.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-invoice-line-items',
  templateUrl: './invoice-line-items.component.html',
  styleUrls: ['./invoice-line-items.component.scss']
})
export class InvoiceLineItemsComponent implements OnInit {

  lineItemForm: FormGroup;
  submitted = false;
  savedLineItems:any;
  itemForms:any;
  responseLoaded:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<InvoiceLineItemsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private companyDetailService: CompanyDetailsService,
    private commonService: CommonService,
    ) { }

  ngOnInit(): void {
    this.getInvoiceLineItem();
  }

  getInvoiceLineItem () {
    this.companyDetailService.getInvoiceLineItem({company_id:this.data.company_id}).subscribe(res => {
      if (res.status == 1) {
        this.savedLineItems = res.data.lineItems;
        if (!isEmpty(this.savedLineItems)) {
          this.lineItemForm = this.formBuilder.group({
            itemArr: this.formBuilder.array([])
          });
          const lineItemCtrl = <FormArray>this.lineItemForm.controls['itemArr'];
          this.savedLineItems.forEach(addedItem => {
              let newItemArr = this.formBuilder.group({
                title: [addedItem.title, Validators.required],
                price: [addedItem.price, Validators.required]
              });
              lineItemCtrl.push(newItemArr);
            })
        } else {
          this.lineItemForm = this.formBuilder.group({
            itemArr: this.formBuilder.array([
              this.formBuilder.group({
                title: ['', Validators.required],
                price: ['', Validators.required],
              })
            ])
          });
        }
      }
      this.itemForms = this.lineItemForm.get('itemArr') as FormArray;
      this.responseLoaded = true;
    });
  }
 
  addItem() {
    const control = <FormArray>this.lineItemForm.controls['itemArr'];
    const newItemArr = this.formBuilder.group({
      title: ['', Validators.required],
      price: ['', Validators.required]
    });

    control.push(newItemArr);
  }

  removeItem(i) {
    this.itemForms.removeAt(i)
  }

  async save() {
    this.submitted = true;
    if (this.lineItemForm.invalid)
      return;

    let payload ={};
    payload['lineItems'] = this.lineItemForm.value.itemArr;
    payload['company_id'] = this.data.company_id;

    this.companyDetailService.saveInvoiceLineItem(payload).subscribe(res => {
      if (res.status == 1) {
        this.commonService.openSnackBar('primary', res.message);
        this.dialogRef.close(1);
      } else {
        this.commonService.openSnackBar('warn', res.message);
      }
    });
  }
}
