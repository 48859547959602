import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-common-module-activity',
  templateUrl: './common-module-activity.component.html',
  styleUrls: ['./common-module-activity.component.scss']
})
export class CommonModuleActivityComponent implements OnInit {

  show_company_name:boolean = false;
  module_slug:any;
  company_id:any;
  signup_request_id:any;
  displayedColumns: string[] = ['activity', 'details', 'created_by' ,'created_on'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  more = [];
  params: { page: number; limit?: number, searchKey?: any, status?: any, module_slug?:any, is_module?:any, company_id?:any, signup_request_id?:any};
  @Input()
  moduleSlug:any;
  @Input()
  companyId:any;
  @Input()
  showCompanyName:boolean = false;
  @Input()
  isModel:boolean=false;
  @Input()
  signupRequestId:any;


  constructor(
    private commonService: CommonService,
    public router: Router, public dialog: MatDialog,
    private route: ActivatedRoute,) {
     
  }

  ngOnInit() {
    if (this.showCompanyName) {
      this.displayedColumns = ['module_name','activity', 'details', 'created_by' ,'created_on'];
    }
    this.module_slug = this.route.snapshot.params['module_slug']?this.route.snapshot.params['module_slug']:this.moduleSlug;
    this.company_id = this.route.snapshot.params['company_id']?this.route.snapshot.params['company_id']:this.companyId;
    this.signup_request_id = this.route.snapshot.params['signupRequestId']?this.route.snapshot.params['signupRequestId']:this.signupRequestId;
    if(this.signup_request_id)
    {
      this.displayedColumns = ['details', 'created_by' ,'created_on'];
    }
    
    this.show_company_name = this.showCompanyName;
    this.params = {limit:15, page:1, status:0, module_slug:this.module_slug, is_module:1, company_id:this.company_id,signup_request_id:this.signup_request_id};
    this.getList();
  }

  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {

    if (!this.company_id) {
      this.params.company_id = 0;
    }
    
    this.commonService.getCpanelActivities(this.params).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data.data);
        this.length = res.data.total;
        this.pageSize = res.data.per_page;
        this.index = res.data.current_page;
      }
    })
  }
}
