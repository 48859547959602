import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ExcelService } from 'app/services/excel.service';
import { CommonService } from 'app/services/common.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { isEmpty } from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ReportsService } from 'app/services/reports.service';

@Component({
  selector: 'app-active-subscription-payment-report',
  templateUrl: './active-subscription-payment-report.component.html',
  styleUrls: ['./active-subscription-payment-report.component.scss']
})

export class ActiveSubscriptionPaymentReportComponent implements OnInit {

  displayedColumns: string[] = ['customer', 'subscription', 'cycle', 'start_date', 'end_date', 'hire_data', 'hr_data', 'crm_data', 'cost', 'refund', 'sub_total'];
  dataSource;
  searchValue = '';
  getlist: any;
  getTableData: any;
  revenueInfo: any;
  length: any;
  pageSize: any;
  index: any;
  countries = [
    { value: '1', viewValue: 'USA' }, { value: '2', viewValue: 'India' }, { value: '702', viewValue: 'Singapore' }
  ];

  invoiceStatus = [
    { value: 'paid', viewValue: 'Paid' }, { value: 'due', viewValue: 'Due' }
  ];

  country_id: any = '1';
  invoice_status: any = 'paid';

  // ejs datepicker
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  // public lastEnd: Date = this.today;
  public lastEnd: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  // public yearStart = moment().startOf('year');
  public yearEnd: Date = this.today;
  // public value: Date[] = [this.yearStart, this.yearEnd];
  // ejs datepicker completed

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  params: { limit: number; page: number; searchKey?: string; dateRange?: any; status?: any, country_id: any; };
  getDateRange = {};
  dateRangeForm: FormGroup;
  permissions: any;
  subscr_end: any = {};
  constructor(
    private reportService: ReportsService,
    private excelService: ExcelService, private datepipe: DatePipe,
    public dialog: MatDialog,
    public router: Router, private fb: FormBuilder,
    public datePipe: DatePipe) {
    this.dateRangeForm = this.fb.group({
      start_date: [''],
      end_date: ['']
    });
    this.dateRangeForm.valueChanges.pipe(debounceTime(0), distinctUntilChanged()).subscribe(res => {
      if (res) {
        if (res) {
          this.getDateRange['startDate'] = this.datepipe.transform(res.start_date, 'yyyy-MM-dd');
          this.getDateRange['endDate'] = this.datepipe.transform(res.end_date, 'yyyy-MM-dd');
          this.params.dateRange = JSON.stringify(this.getDateRange);
        }
        else {
          this.params.dateRange = '';
          this.getDateRange = {};
        }
        this.getPaymentsReports();
      }
    });
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1, country_id: this.country_id, status: this.invoice_status };
    this.dateRangeForm.setValue({
      start_date: this.datePipe.transform(this.monthStart, 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.monthEnd, 'yyyy-MM-dd')
    });
    this.currentMonthInfo();
  }

  applyFilter(filterValue: string) {
    this.params.searchKey = filterValue;
    this.getPaymentsReports();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getPaymentsReports();
  }

  getPaymentsReports() {
    this.reportService.clientPayments(this.params).subscribe(result => {
      if (result.status === 1) {
        this.getTableData = result.data;
        this.length = this.getTableData.total;
        this.pageSize = result.data.per_page;
        this.index = result.data.current_page;
        this.dataSource = new MatTableDataSource(result.data.data);
      }
    })
  }

  currentMonthInfo() {
    this.reportService.currentMonthRevenueInfo({ 'country_id': this.country_id }).subscribe(result => {
      if (result.status === 1) {
        this.revenueInfo = result.data;
      }
    })
  }

  companyData(element) {
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_info.company_id, '_blank'); });
  }

  exportExcel() {
    this.params.limit = this.length;
    this.reportService.clientPayments(this.params).subscribe(result => {
      if (result.status === 1) {
        let prepared_data = this.prepareExcelData(result.data.data);
        let sheet_name = 'Customer Payments';
        this.excelService.exportAsExcelFile(prepared_data, sheet_name);
      }
    })
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer'] = element.company_info.name;
      eachRow['Country'] = this.getCountryName();
      eachRow['Subscription'] = this.planType(element.company_info.subscription_plan);
      eachRow['Cycle'] = this.subCycle(element.company_info.subscription_cycle);
      eachRow['Start Date'] = this.datepipe.transform(element.company_info.subscr_start_date, 'MM/dd/yyyy');
      eachRow['End Date'] = this.datepipe.transform(element.company_info.end_date, 'MM/dd/yyyy');
      eachRow['Hire Licenses'] = element.hire_users.paid;
      eachRow['Hire Licenses Amount'] = element.hire_users.amount;
      eachRow['Mass Mails'] = element.mass_mails_credits.paid;
      eachRow['Mass Mails Amount'] = element.mass_mails_credits.amount;
      eachRow['Contact Search(Accounts)'] = element.contacts_search_credits.paid;
      eachRow['Contact Search Amount'] = element.contacts_search_credits.amount;
      eachRow['Contact Search(Linkedin)'] = element.linkedin_credits.paid;
      eachRow['Contact Search(Linkedin) Amount'] = element.linkedin_credits.amount;
      if (this.country_id == 2) {
        eachRow['SMS Credits'] = element.sms_credits.paid;
        eachRow['SMS Credits Amount'] = element.sms_credits.amount;
      }
      eachRow['HR Licenses'] = element.hr_users.paid;
      eachRow['HR Licenses Amount'] = element.hr_users.amount;
      if (this.country_id == 1) {
        eachRow['I-9 Credits'] = element.i9_forms.paid;
        eachRow['I-9 Credits Amount'] = element.i9_forms.amount;
      }
      eachRow['CRM Licenses'] = element.crm_users.paid;
      eachRow['CRM Licenses Amount'] = element.crm_users.amount;
      eachRow['Oorwin Branding'] = element.oorwin_branding.amount;
      eachRow['Cost'] = Number(element.payment_info.total_paid);
      if (this.country_id == 2) {
        eachRow['TDS(-) of 7.5%'] = Number(element.payment_info.tax);
        eachRow['GST(+) of 18%'] = Number(element.payment_info.tds);
      }
      eachRow['Refund'] = Number(element.payment_info.refund);
      eachRow['Sub total'] = !isEmpty(element.payment_info.refund) ? Number(element.payment_info.total_paid - element.payment_info.refund) : Number(element.payment_info.total_paid);
      // eachRow['Status'] =  this.getPaymentStatus(element.company_info.subscr_end_date);
      ArrData.push(eachRow);
    });
    // console.log('prepared', ArrData);
    return ArrData;
  }

  refresh() {
    this.searchValue = '';
    this.params = { limit: 15, page: 1, country_id: this.country_id }
    this.dateRangeForm.reset();
  }

  subCycle(element) {
    if (element == 1) {
      return 'Monthly';
    }
    else if (element == 3) {
      return 'Quarterly';
    }
    else if (element == 6) {
      return 'Half yearly';
    }
    else if (element == 12) {
      return 'Yearly';
    }
    else {
      return '-'
    }
  }

  planType(element) {
    if (element == 'limited') {
      return 'Limited';
    }
    else if (element == 'unlimited_inc_addons') {
      return 'Unlimited Incl';
    }
    else if (element == 'unlimited_excl_addons') {
      return 'Unlimited Excl';
    }
    else {
      return 'Limited'
    }
  }

  getCurrencySymbol() {
    if (this.country_id == 2) {
      return '₹';
    }
    return '$';
  }

  getCountryName() {
    if (this.country_id == 2) {
      return 'India';
    } else if (this.country_id == 702) {
      return 'Singapore';
    } else {
      return 'USA';
    }
  }

  countryChanged() {
    this.searchValue = '';
    this.params = { limit: 15, page: 1, country_id: this.country_id, status: this.invoice_status, dateRange: JSON.stringify(this.getDateRange) };
    this.getPaymentsReports();
    this.currentMonthInfo();
  }

  invoiceStatusChanged() {
    this.searchValue = '';
    this.params = { limit: 15, page: 1, country_id: this.country_id, status: this.invoice_status, dateRange: JSON.stringify(this.getDateRange) };
    this.getPaymentsReports();
  }

  getPaymentStatus(end_date) {

    var today = new Date();
    var sub_end_date = new Date(end_date);

    if (sub_end_date >= today) {
      return 'Paid';
    }
    return 'Due';
  }

}

