import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/services/common.service';
import { ExcelService } from 'app/services/excel.service';
import { RequestDetailsComponent } from '../request-details/request-details.component';
import { AddSignupDialogComponent } from '../add-signup-dialog/add-signup-dialog.component';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { ConfirmExitRequestApproveComponent } from '../confirm-exit-request-approve/confirm-exit-request-approve.component';

@Component({
  selector: 'app-signup-request',
  templateUrl: './signup-request.component.html',
  styleUrls: ['./signup-request.component.scss']
})

export class SignupRequestComponent implements OnInit {
  // displayedColumns: string[] = ['client', 'name', 'email', 'subdomain', 'contactnumber', 'date', 'role', 'buying_stage', 'status', 'actions'];
  displayedColumns: string[] = ['client', 'name', 'email', 'subdomain', 'contactnumber', 'date', 'region', 'location', 'status', 'actions'];
  dataSource;
  searchValue = '';
  getlist: any;
  getTableData: any;
  params: { limit: number; page: number; searchKey?: string; status: string; dateRange?: any; country_id?: any; year?:any };
  length: any;
  pageSize: any;
  index: any;
  getDateRange = {};
  selectedDateRange;

  countries = [
    { value: 'all', viewValue: 'All' }, { value: '1', viewValue: 'USA ($)' }, { value: '2', viewValue: 'India (₹)' }, { value: '702', viewValue: 'Singapore (S$)' }
  ];
  years = [];
  country_id: any = 'all';
  year_id: any;


  // ejs datepicker
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  // public lastEnd: Date = this.today;
  public lastEnd: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  // public yearStart = moment().startOf('year');
  public yearEnd: Date = this.today;
  // public value: Date[] = [this.yearStart, this.yearEnd];
  // ejs datepicker completed

  dateRangeForm: FormGroup;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  logginUser: any;
  constructor(private commonservice: CommonService,
    private excelService: ExcelService,
    public dialog: MatDialog,
    private datepipe: DatePipe, private fb: FormBuilder) {

      this.commonservice.currentUser.subscribe(res => {
        if(res) {
          this.logginUser = res;
        }
    });

    let startYear = new Date('2018').getFullYear();
    this.year_id = new Date().getFullYear()
    for (let year = startYear; year <= this.year_id; year++) {
      this.years.push({ value: year, viewValue: year });
    }

    this.dateRangeForm = this.fb.group({
      daterange: ['']
    });
    this.dateRangeForm.valueChanges.subscribe(res => {
      if (res) {
        if (res.daterange) {
          this.getDateRange['startDate'] = this.datepipe.transform(res.daterange[0], 'yyyy-MM-dd');
          this.getDateRange['endDate'] = this.datepipe.transform(res.daterange[1], 'yyyy-MM-dd');
          this.selectedDateRange = JSON.stringify(this.getDateRange);
        }
        else {
          this.selectedDateRange = '';
        }
        this.getSignUpRequestData(this.getlist);
      }
    });
  }

  ngOnInit() {
    this.getSignUpRequestData('inprogress');
  }
  applyFilter(filterValue: string) {
    this.params['searchKey'] = filterValue;
    this.params['page'] = 1;
    this.getSignUpRequestData('');
  }
  pageEvent(event) {
    // console.log(event, this.getTableData.data[0].status);
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.params.status = this.getTableData.data[0].status;
    this.getSignUpRequestData('');
  }
  getSignUpRequestData(getnav) {
    switch (getnav) {
      case 'inprogress':
        this.getlist = getnav;
        this.params = { limit: 15, page: 1, status: 'inprogress' }
        break;
      case 'pending':
        this.getlist = getnav;
        this.params = { limit: 15, page: 1, status: 'pending' }
        break;
      case 'approved':
        this.getlist = getnav;
        this.params = { limit: 15, page: 1, status: 'approved' }
        break;
      case 'completed':
        this.getlist = getnav;
        this.params = { limit: 15, page: 1, status: 'completed' }
        break;
      case 'rejected':
        this.getlist = getnav;
        this.params = { limit: 15, page: 1, status: 'rejected' }
        break;
      default:
        break;
    }
    if (this.selectedDateRange) {
      this.params.dateRange = this.selectedDateRange;
    }
    this.params.country_id = this.country_id;
    this.params.year = this.year_id;
    this.commonservice.signupRequestTableData(this.params).subscribe(result => {
      if (result) {
        this.getTableData = result.data;
        this.length = this.getTableData.total;
        this.pageSize = result.data.per_page;
        this.index = result.data.current_page;
        this.dataSource = new MatTableDataSource(result.data.data);

      }
    })
  }

  exportExcel() {
    let Params = { limit: this.length, page: 1, status: this.getlist, country_id:this.country_id };
    // console.log(Params);
    this.commonservice.exportAsExcel(Params).subscribe(result => {
      if (result.status === 1) {
        let prepared_data = this.prepareExcelData(result.data.data);
        let sheet_name = 'Singup Request ' + this.getlist + ' List';
        this.excelService.exportAsExcelFile(prepared_data, sheet_name);
      }
    })
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Client'] = element.company_name;
      eachRow['Contact Name'] = element.first_name + ' ' + element.last_name;
      eachRow['Contact Email'] = element.email;
      eachRow['Subdomain Name'] = element.sub_domain_name;
      eachRow['Contact Number'] = element.contact_number;
      eachRow['Signup On'] = moment(element.created_at).format('MM/DD/YYYY');
      // eachRow['Role'] = element.role;
      // eachRow['Buying Stage'] = element.buying_stage;
      eachRow['Status'] = element.status;
      ArrData.push(eachRow);
    });
    // console.log('prepared', ArrData);
    return ArrData;
  }

  requestDetails(elementData) {
    // console.log(elementData);
    const dialogRef = this.dialog.open(RequestDetailsComponent, {
      width: '65%',
      data: elementData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  addSignUp() {

    let data = {};
    data['id'] = '';
    const dialogRef = this.dialog.open(AddSignupDialogComponent, {
      width: '65%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSignUpRequestData('pending');
      }
    });
  }

  editSignup(record_data) {
    const dialogRef = this.dialog.open(AddSignupDialogComponent, {
      width: '65%',
      data: record_data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getSignUpRequestData(this.getTableData.data[0].status);
      }
    });
  }

  viewComment(items)
  {
    let record_id = items.id;
    let cnt = items.comments_cnt;
    if(cnt)
    {


      let activityData ={
        'company_id':0,
        'signup_request_id': record_id,
        'module_slug':'signupRequest',
        'show_company_name':true,
        'module_name':'Signup',
        // 'record_id' : record_id,
        // 'isComments' : true
      }
      const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
        width: '80%',
        data: activityData
      });
  
      dialogRef.afterClosed().subscribe(result => {
      });
      

    }

      // alert(record_id);

  }
  deleteSignup(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to delete this Data';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });
    let saveData: any;
    
    dialogRef.afterClosed().subscribe(result => {
      if (result.approved) {
        saveData = {
          'id': record_id,
          'status': 'delete',
          'comments' : result.commets
        };
        this.commonservice.deleteSignupRequest(saveData).subscribe(res => {
          if (res.status == 1) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
          this.getSignUpRequestData(this.getTableData.data[0].status);
        });
      } else {

      }
    })
  }

  


  addComment(record_id) {
    const data = [];
    data['title'] = 'Comment';
    data['message'] = 'Comment';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });
    let saveData: any;
    
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.approved) {

        saveData = {
          'id': record_id,
          'status': 'comment',
          'comments' : result.commets
        };
        this.commonservice.deleteSignupRequest(saveData).subscribe(res => {
          if (res.status == 1) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
          this.getSignUpRequestData(this.getTableData.data[0].status);
        });
      } else {

      }
    })
  }

  rejectPending(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to reject this request';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmExitRequestApproveComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });
    let saveData: any;
    
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.approved) {

        saveData = {
          'id': record_id,
          'status': 'rejected',
          'comments' : result.commets
        };
        this.commonservice.deleteSignupRequest(saveData).subscribe(res => {
          if (res.status == 1) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
          this.getSignUpRequestData(this.getTableData.data[0].status);
        });
      } else {

      }
    })
  }

  createCompany(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to accept this request';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonservice.CreateCompanySignup(record_id).subscribe(res => {
          if (res.status == 1) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
          this.getSignUpRequestData(this.getTableData.data[0].status);
        });
      } else {

      }
    })
  }

  setCompany(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to accept this request';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonservice.SetCompanySignup(record_id).subscribe(res => {
          if (res.status == 1) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
          this.getSignUpRequestData(this.getTableData.data[0].status);
        });
      } else {

      }
    })
  }
  completedEmail(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to send activation mail';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonservice.activateMail(record_id).subscribe(res => {
          if (res['status'] == 1) {
            this.commonservice.openSnackBar('primary', res['message'] ? res['message'] : '');
          } else {
            this.commonservice.openSnackBar('warn', res['message'] ? res['message'] : 'Something Went Wrong');
          }
          this.getSignUpRequestData(this.getTableData.data[0].status);
        });
      } else {

      }
    })
  }

  verifyEmail(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to send verify mail';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = {};
        params['req_id'] = record_id;
        this.commonservice.verifySignupEmail(params).subscribe(res => {
          if (res.data.status) {
            this.commonservice.openSnackBar('primary', res.data.message);
          } else {
            this.commonservice.openSnackBar('warn', res.data.message);
          }
          this.getSignUpRequestData(this.getTableData.data[0].status);
        });
      } else {

      }
    })
  }

  sendLoginEmail(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to send login mail';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = {};
        params['signup_id'] = record_id;
        this.commonservice.sendLoginEmail(params).subscribe(res => {
          if (res['status'] == 1) {
            this.commonservice.openSnackBar('primary', res['message'] ? res['message'] : '');
          } else {
            this.commonservice.openSnackBar('warn', res['message'] ? res['message'] : 'Something Went Wrong');
          }
          this.getSignUpRequestData(this.getTableData.data[0].status);
        });
      } else {

      }
    })
  }

  locationInfo(record) {
    if (!record.location) {
      return '-';
    }
    let request_location = record.location;
    request_location = request_location.replace(/[0-9]/g, '');
    let result = request_location.split(",");
    if (!Array.isArray(result)) {
      return '-';
    }
    result.shift();
    return result.join()
  }

  countryChanged() {
    this.params.limit = 15;
    this.params.page = 1;
    this.params.country_id = this.country_id;
    this.getSignUpRequestData('');
  }
  yearChanged() {
    this.params.limit = 15;
    this.params.page = 1;
    this.params.country_id = this.country_id;
    this.params.year = this.year_id;
    this.getSignUpRequestData('');
  }

  showActivities() {
    let activityData ={
      'company_id':0,
      'module_slug':'signupRequest',
      'show_company_name':true,
      'module_name':'Signup'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
