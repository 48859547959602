import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { CommonService } from 'app/services/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ExcelService } from 'app/services/excel.service';
import { DatePipe } from '@angular/common';
import { parse } from 'querystring';
import { ReportsService } from 'app/services/reports.service';

@Component({
  selector: 'app-sales-executive-payments-report',
  templateUrl: './sales-executive-payments-report.component.html',
  styleUrls: ['./sales-executive-payments-report.component.scss'],
})
export class SalesExecutivePaymentsReportComponent implements OnInit {

  displayedColumns: string[] = ['company_name', 'sales_executive', 'total', 'refund', 'grand_total'];
  dataSource:any;
  searchValue = '';
  length: number = 10;
  pageSize: number = 15;
  index: number = 1;
  toppingList: any;
  salesUsers: any;
  getTableData: any;
  check_upgrade_payment:boolean=false;
 
  countries = [
    { value: '0', viewValue: 'All' },{ value: '1', viewValue: 'USA' },{ value: '2', viewValue: 'India' }, { value: '702', viewValue: 'Singapore'}
  ];

  country_id: any = '0';

  // ejs datepicker
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  // public lastEnd: Date = this.today;
  public lastEnd: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  // public yearStart = moment().startOf('year');
  public yearEnd: Date = this.today;
  // public value: Date[] = [this.yearStart, this.yearEnd];
  // ejs datepicker completed

  params: { limit: number; page: number; searchKey?: any; dateRange?: any; country_id?: any; client_ids?: any; sales_ids?:any, check_upgrade_payment?:any};
  dateRangeForm: FormGroup;
  filtersFrom: FormGroup;
  getDateRange = {};
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  constructor(private fb: FormBuilder,
    private commonservice:CommonService, 
    private reportService: ReportsService,
    public router: Router,private excelService: ExcelService,
    private datepipe: DatePipe) {
    this.dateRangeForm = this.fb.group({
      start_date: [''],
      end_date: ['']
    });
    this.filtersFrom = this.fb.group({client_ids:[''], sales_ids:['']});
    this.dateRangeForm.valueChanges.subscribe(res => {
      if (res) {
        if (res.start_date && res.end_date) {
          this.getDateRange['startDate'] = this.datepipe.transform(res.start_date, 'yyyy-MM-dd');
          this.getDateRange['endDate'] = this.datepipe.transform(res.end_date, 'yyyy-MM-dd');
          this.params.dateRange = JSON.stringify(this.getDateRange);
        }
        else {
          this.params.dateRange = '';
          this.params.page = 1;
          this.params.limit = 15;
          this.getDateRange = {};
        }
        this.getSubscriptionHistory();
      }
    });
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    // this.dateRangeForm.get('daterange').setValue([this.monthStart, this.monthEnd]);
    this.dateRangeForm.setValue({
      start_date: this.datepipe.transform(this.monthStart, 'yyyy-MM-dd'),
      end_date: this.datepipe.transform(this.monthEnd, 'yyyy-MM-dd')
    });
    this.commonservice.companyList().subscribe(res => {
      if (res.status === 1) {this.toppingList = res.data;}
    });
    this.reportService.getSalesUsersList().subscribe(res => {
      if (res.status === 1) {this.salesUsers = res.data.users;}
    });
    this.getSubscriptionHistory();
  }

  getSubscriptionHistory() {
    this.reportService.salesExecutivePaymentsReport(this.params).subscribe(result => {
      if(result.status == 1) {
        this.getTableData = result.data;
        this.length = this.getTableData.total;
        this.pageSize = result.data.per_page;
        this.index = result.data.current_page;
        this.dataSource = new MatTableDataSource(result.data.data);
      }
    }); 
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getSubscriptionHistory();
  }
  
  applyFilter(filterValue: string) {
    this.params.searchKey = filterValue;
    this.params.page=1;
    this.params.limit = 15;
    this.getSubscriptionHistory();
  }

  clientChange() {
    this.params.client_ids = this.filtersFrom.value.client_ids ? this.filtersFrom.value.client_ids.map(x => x).join(",") : '';
    this.params.page = 1; 
    this.params.limit = 15;
    this.getSubscriptionHistory();
  }

  salesUserChange() {
    this.params.sales_ids = this.filtersFrom.value.sales_ids ? this.filtersFrom.value.sales_ids.map(x => x).join(",") : '';
    this.params.page = 1; 
    this.params.limit = 15;
    this.getSubscriptionHistory();
  }

  countryChanged() {
    this.params.limit = 15;
    this.params.page = 1;
    this.params.country_id = this.country_id;
    this.params.dateRange = JSON.stringify(this.getDateRange);
    this.getSubscriptionHistory();
  }

  navigate(element) {
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }

  getCurrencySymbol(country_id) {
    if (country_id == 2) {
      return '₹';
    } else {
      return '$';
    }
  }

  getGrandTotal(record) {
    let amunt = 0;
    if (record.wallet_amount > 0) {
      amunt += parseFloat(record.wallet_amount);
    }
    if (record.refund_amount > 0) {
      amunt += parseFloat(record.refund_amount);
    }
    amunt = parseFloat(record.invoice_amount) - amunt;
    return amunt.toFixed(2);
  }

  exportExcel() {
    this.params.limit = this.length;
    this.reportService.salesExecutivePaymentsReport(this.params).subscribe(result => {
      if (result.status === 1) {
        let prepared_data = this.prepareExcelData(result.data.data);
        let sheet_name = 'Sales Executive Payments';
        this.excelService.exportAsExcelFile(prepared_data, sheet_name);
      }
    })
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Company Name'] = element.company_name;
      eachRow['Region'] = this.getCountryName(element.country_id);
      eachRow['Sales Executive'] = element.sales_executive_name;
      eachRow['Invoice Amount'] = (element.invoice_amount > 0)?parseFloat(element.invoice_amount):0;
      // eachRow['Used Wallet Amount'] = (element.wallet_amount > 0)?parseFloat(element.wallet_amount):0;
      eachRow['Total Paid'] = parseFloat(this.getGrandTotal(element));
      ArrData.push(eachRow);
    });
    // console.log('prepared', ArrData);
    return ArrData;
  }

  getCountryName(country_id) {
    if (country_id == 2) {
      return 'India';
    } else if (country_id == 702) {
      return 'Singapore';
    } else {
      return 'USA';
    }
  }

  checkUpgradePayment() {
      this.params.check_upgrade_payment = this.check_upgrade_payment;
      this.getSubscriptionHistory();
  }

}
