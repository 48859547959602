import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-migration-ftp-files',
  templateUrl: './migration-ftp-files.component.html',
  styleUrls: ['./migration-ftp-files.component.scss']
})
export class MigrationFtpFilesComponent implements OnInit {

  displayedColumns: string[] = ['zip_name', 'uploaded_date', 'status','percentage', 'migrated', 'failed'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  currentUser = {};
  params: { page: number; limit?: number, searchKey?: any, company_id?:any };

  constructor(
    private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    public router: Router, public dialog: MatDialog, private route: ActivatedRoute,) {
      this.id = this.route.parent.snapshot.params['id'];
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    this.getList();
  }

  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.params.company_id = this.id;
    this.companyDetailService.getImportMigrateStatusList(this.params).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data.data);
        this.length = res.data.total;
        this.pageSize = res.data.per_page;
        this.index = res.data.current_page;
      }
    })
  }
  
  exportFailedData(zip_id) {
    this.companyDetailService.getExportFtpFailedData({id:zip_id, company_id:this.id}).subscribe(res => {
      if (res.status == 1) {
        this.commonService.openSnackBar('primary', res.message);
      }
      else {
        this.commonService.openSnackBar('warn', res.message);
      }
    })
  }

  downloadFailedData(zip_id, fileName) {
    this.companyDetailService.downloadFtpFailedData(zip_id).subscribe(res => {
      this.downloadFile(res, fileName);
    });
  }

  downloadFile(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const dataURL = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.href = dataURL;
    a.download = fileName;
    a.click();
  }
  
}
