<div class="page-layout carded fullwidth inner-scroll">
    <mat-toolbar-row class="secondary-headers w-100-p px-20">
      <h2 class="sub-heading">Users Report</h2>
    </mat-toolbar-row>
    <div class="center px-20">
      <div class="content-card mt-12 mb-52">
        <div fxLayout='row wrap' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
  
          <div class="search-wrapper" fxFlex="30">
            <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
              [(ngModel)]="searchValue" placeholder="Customer Name">
            <mat-icon class="search_icon">search</mat-icon>
            <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
              close
            </mat-icon>
          </div>
  
          <div fxFlex="70" class="actions_list" fxLayout="row wrap" fxLayoutAlign="end center">
            <div fxLayout="row wrap" fxLayoutAlign="start center" class="mt-12">
  
              <button mat-flat-button class="primary mr-4" (click)="exportExcel()">Export</button>
              
              <!-- <mat-form-field appearance="outline" class="w-150 mr-8">
                <mat-label>Country</mat-label>
                <mat-select [(ngModel)]="country_id" (selectionChange)="countryChanged()">
                    <mat-option *ngFor="let item of countries" [value]="item.value">
                        {{item.viewValue}}
                    </mat-option>
                </mat-select>
              </mat-form-field> -->
              
              <form [formGroup]="clientSubscriptionForm">
                
                <mat-form-field class="w-200 mr-8" appearance="outline">
                  <mat-label>Select Company</mat-label>
                  <mat-select appMatSearchSelector formControlName="client_ids" multiple (selectionChange)="clientChange()">
                    <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                    <ng-container *ngFor="let topping of toppingList">
                      <mat-option *appMatSearchSelectorItems [value]="topping.id">{{topping.display_name}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>


                <!-- <mat-form-field class="w-100 mr-8" appearance="outline">
                    <mat-label>User Type</mat-label>
                    <mat-select appMatSearchSelector formControlName="app_type" multiple (selectionChange)="appTypeChange()">
                      <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                      <ng-container *ngFor="let topping of app_list">
                        <mat-option *appMatSearchSelectorItems [value]="topping.value">{{topping.viewValue}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field> -->

                  <mat-form-field appearance="outline" class="w-100 mr-8">
                    <mat-label>User Type</mat-label>
                      <mat-select formControlName="app_type" (selectionChange)="appTypeChange()">
                        <mat-option *ngFor="let cust of app_list" [value]="cust.value">
                          {{cust.viewValue}}
                        </mat-option>
                      </mat-select>
                  </mat-form-field>

                  <mat-form-field class="w-100 mr-8" appearance="outline">
                    <mat-label>Status</mat-label>
                    <mat-select appMatSearchSelector formControlName="status_type" multiple (selectionChange)="statusTypeChange()">
                      <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                      <ng-container *ngFor="let topping of status_list">
                        <mat-option *appMatSearchSelectorItems [value]="topping.value">{{topping.viewValue}}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>


                <!-- <mat-form-field appearance="outline" class="w-150 mr-8">
                    <mat-label>App Type</mat-label>
                      <mat-select formControlName="app_type" (selectionChange)="appTypeChange()">
                        <mat-option *ngFor="let cust of app_list" [value]="cust.value">
                          {{cust.viewValue}}
                        </mat-option>
                      </mat-select>
                  </mat-form-field>
                
                <mat-form-field appearance="outline" class="w-150 mr-8">
                    <mat-label>Renewal Status</mat-label>
                      <mat-select formControlName="status_type" (selectionChange)="statusTypeChange()">
                        <mat-option *ngFor="let cust of status_list" [value]="cust.value">
                          {{cust.viewValue}}
                        </mat-option>
                      </mat-select>
                  </mat-form-field> -->
  
<!--             
                <mat-form-field class="w-200" appearance="outline" >
                  <input matInput [matDatepicker]="dp" placeholder="Month and Year" formControlName="Year_Month">
                  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                  <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
                    (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                  </mat-datepicker>
                </mat-form-field> -->
  
              </form>
  
            </div>
            <div fxLayout="row" fxLayoutAlign="end center">
              <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
                [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
            </div>
          </div>
        </div>
        <div class="content" fusePerfectScrollbar>
          <ng-container *ngIf="dataSource">
            <div class="px-12 pb-12">
              <mat-table [dataSource]="dataSource">
  
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
                  <mat-cell *matCellDef="let element"> <a class='cursor-pointer'
                    (click)='navigate(element)'>{{element.company_name}} </a></mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="first_name">
                  <mat-header-cell *matHeaderCellDef> User Name </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="lowercase"> {{element.first_name + element.last_name}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="email">
                  <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="lowercase"> {{element.email}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="contact_number">
                  <mat-header-cell *matHeaderCellDef>Mobile </mat-header-cell>
                  <mat-cell *matCellDef="let element" class="lowercase"> {{element.contact_number}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{subStatus(element.status)}}
                  </mat-cell>
                </ng-container>
  
              
            
                <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
  
              <div *ngIf="dataSource.data.length == 0" class="text-center">
                <h2 class="sub-heading">No Records Found</h2>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>