import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { isEmpty } from 'lodash';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ConfigurationOptions, ContentOptionsEnum, SortOrderEnum, OutputOptionsEnum } from 'intl-input-phone';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-add-from-mail',
  templateUrl: './add-from-mail.component.html',
  styleUrls: ['./add-from-mail.component.scss']
})
export class AddFromMailComponent implements OnInit {

  form: FormGroup;
  addData = {};

  IsRequired: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AddFromMailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private commonService: CommonService,
    private configService: ConfigurationService
  ) {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    })

    // this.configOption3 = new ConfigurationOptions();
    // this.configOption3.SelectorClass = "OptionType3";
    // this.configOption3.OptionTextTypes = [];
    // this.configOption3.OptionTextTypes.push(ContentOptionsEnum.Flag);
    // this.configOption3.OptionTextTypes.push(ContentOptionsEnum.CountryName);
    // this.configOption3.OptionTextTypes.push(ContentOptionsEnum.CountryPhoneCode);
    // this.configOption3.SortBy = SortOrderEnum.CountryPhoneCode;
    // this.configOption3.OutputFormat = OutputOptionsEnum.Number;

  }

  ngOnInit() {
    this.setData();
  }
  setData() {
    if (!isEmpty(this.data)) {
      this.form.patchValue({
        firstName: this.data.first_name,
        lastName: this.data.last_name,
        email: this.data.email,
      })
    }
    else {
    }
  }

  save() {
    if (this.form.valid) {

      if(!isEmpty(this.data))
      {
        this.addData['id'] = this.data?.id;
        this.addData['company_id'] = this.data.company_id;
      }
      
      this.addData['first_name'] = this.form.value.firstName;
      this.addData['last_name'] = this.form.value.lastName;
      this.addData['email'] = this.form.value.email;


      this.configService.saveFromMailList(this.addData).subscribe(res => {
        if (res.status === 1) {
          this.dialogRef.close(true);
          this.commonService.openSnackBar('primary', res.message);
        }
        else {
          let msg = res.message;
          if (typeof res.validations != 'undefined') {
            for (let field_name in res.validations) {
              msg += res.validations[field_name];
            }
          }
          this.commonService.openSnackBar('warn', msg);
        }
      })
    }
    else {
      this.form.get('firstName').markAsTouched();
      this.form.get('lastName').markAsTouched();
      this.form.get('email').markAsTouched();
    }
  }

  requiredFlagChange(isRequire: boolean) {
    this.IsRequired = isRequire;
  }

}
