import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-add-refund',
  templateUrl: './add-refund.component.html',
  styleUrls: ['./add-refund.component.scss']
})
export class AddRefundComponent implements OnInit {

  addRefund: FormGroup;
  moreAmount = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddRefundComponent>
  ) {
    this.addRefund = this.fb.group({
      reason : ['', Validators.required],
      amount :[this.data.invoice_amount, Validators.required],
      refund_type :['']
    });
  }

  ngOnInit() {
  }

  amountChange() {
    let enetred_amount = this.addRefund.value.amount;
    if(enetred_amount > this.data.invoice_amount) {
      this.moreAmount = true;
      this.addRefund.controls.amount.setErrors(Validators.required);
    }else if(enetred_amount <= 0) {
      this.moreAmount = false;
      this.addRefund.controls.amount.setErrors(Validators.required);
    }    
  }

  saveRefund() {
    if (this.addRefund.valid) {
      this.moreAmount = false;
      let saveData: any;
      saveData = {
        'amount' : this.addRefund.value.amount,
        'reason' : this.addRefund.value.reason,
        'refund_type' : this.addRefund.value.refund_type,
        'company_id' : this.data.company_id,
        'invoice_id' : this.data.invoice_id,
        'payment_gateway' : this.data.payment_gateway
      };
      
      this.companyDetailService.saveRefund(saveData).subscribe(res => {
        if (res.status == 1) {
          this.commonService.openSnackBar('primary', res.message);
        } else {
          this.commonService.openSnackBar('warn', res.message);
        }
        this.dialogRef.close(1);
      })
    } 
  }

  refundAccountChange() {
    let reufundAccount = this.addRefund.value.refund_type;
    if (reufundAccount ==1 && this.data.record.used_credit_balance_amount>0 && this.data.record.payment_mode =='card') {
        let amount = (parseFloat(this.data.record.amount) - parseFloat(this.data.record.used_credit_balance_amount)).toFixed(2);
        this.addRefund.controls['amount'].setValue(amount);
    } else {
      this.addRefund.controls['amount'].setValue(this.data.invoice_amount);
    }
  }

}
