import { NgModule } from '@angular/core';

import { ReportsRoutingModule } from './reports-routing.module';
import { ReportsComponent } from './reports.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { ActiveSubscriptionPaymentReportComponent } from './active-subscription-payment-report/active-subscription-payment-report.component';
import { ClientSubscriptionsComponent } from './client-subscriptions/client-subscriptions.component';
import { PaymentReportsComponent } from './payment-reports/payment-reports.component';
import { RevenueForecostComponent } from './revenue-forecost/revenue-forecost.component';
import { SalesExecutivePaymentsReportComponent } from './sales-executive-payments-report/sales-executive-payments-report.component';
import { UnlimitedMrrComponent } from './unlimited-mrr/unlimited-mrr.component';
import { ClientRefundsComponent } from './client-refunds/client-refunds.component';
import { MatSearchSelectorModule } from 'app/mat-search-selector/mat-search-selector.module';
import { RevenueForecastingComponent } from './revenue-forecasting/revenue-forecasting.component';
import { FusionChartsModule } from 'angular-fusioncharts';
import { DueCustomersComponent } from './due-customers/due-customers.component';
import { UsersReportComponent } from './users-report/users-report.component';
import { TdsReportComponent } from './tds-report/tds-report.component';

@NgModule({
  declarations: [
    ReportsComponent,
    ClientSubscriptionsComponent,
    ClientRefundsComponent,
    PaymentReportsComponent,
    ActiveSubscriptionPaymentReportComponent,
    UnlimitedMrrComponent,
    SalesExecutivePaymentsReportComponent,
    RevenueForecostComponent,
    RevenueForecastingComponent,
    DueCustomersComponent,
    UsersReportComponent,
    TdsReportComponent
  ],
  imports: [
    ReportsRoutingModule,
    FuseSharedModule,
    MatSearchSelectorModule,
    FusionChartsModule
  ]
})
export class ReportsModule { }
