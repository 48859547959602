import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-demo-request',
  templateUrl: './demo-request.component.html',
  styleUrls: ['./demo-request.component.scss']
})
export class DemoRequestComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email','region'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  params: { page: number; limit?: number, searchKey?: any};

  constructor(
    private commonService: CommonService,
    public router: Router, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1 };
    this.getList();
  }
  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.commonService.getDemoRequestList(this.params).subscribe(res => {
      if (res.status == 0) {
        this.commonService.openSnackBar('warn', res.message);
      } 
      let responseTableData = res.data.demo_requests;
      this.dataSource = new MatTableDataSource(responseTableData.data);
      this.length = responseTableData.total;
      this.pageSize = responseTableData.per_page;
      this.index = responseTableData.current_page;
    })
  }
}
