import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-leadowner',
  templateUrl: './add-leadowner.component.html',
  styleUrls: ['./add-leadowner.component.scss']
})

export class AddLeadownerComponent implements OnInit {

  saveData: any = {};
  showLoader: boolean;
  
  comapany_locations = [
    { country_table_id: 1, country_name: 'USA'},
    { country_table_id: 2, country_name: 'India'},
    { country_table_id: 702, country_name: 'Singapore'},
  ];

  user_status = [
    { status_code: 1, status_name: 'Active'},
    { status_code: 0, status_name: 'Inactive'},
  ];

  leadOwnerForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private commonservice: CommonService,
    public dialogRef: MatDialogRef<AddLeadownerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient

  ) {
    this.leadOwnerForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      country_id: [1, Validators.required],
      sort_order: [''],
      contact_number: [''],
    });

    if (this.data.id) {
      this.leadOwnerForm.addControl('status', new FormControl(1));
    }
  }

  ngOnInit() {
    if (this.data.id) {
      this.leadOwnerForm.patchValue(this.data);
    }
  }

  saveLeadOwner() {

    if (!this.leadOwnerForm.valid) {
      return;
    }

    this.saveData = this.leadOwnerForm.value;
    if (this.data.id) {
      this.saveData['id'] = this.data.id;
    }

    this.showLoader = true;
    this.commonservice.saveLeadOwner(this.saveData).subscribe(res => {
      if (res.status == 1) {
        this.commonservice.openSnackBar('primary', res.message);
        this.dialogRef.close(1);
      } 
      else {
        this.commonservice.openSnackBar('warn', res.message);
      }
      this.showLoader = false;
    });
  }

}
