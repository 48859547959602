import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  // apiurl = environment.apiurl;
  // apiurl = '/api';
  // apiurl = 'http://localhost/pranay_oorwinAPI/cpanel';


  constructor(private http: HttpClient, ) { }

  get<T>(url: string, params?: HttpParams | any, headers?: HttpHeaders | any, type?: any): Observable<T> {
    // console.log(this.apiurl, url, params, headers, type);
    if (params & headers) {
      return this.http.get<T>(url, { params: params, headers: headers });
    }
    else {
      if (params) {
        return this.http.get<T>(url, { params: params });
      }
      else {
        if (headers && type) {
          return this.http.get<T>(url, { headers: headers, responseType: type });
        }
        else {
          if (headers) {
            return this.http.get<T>(url, { headers: headers });
          }
          if (type) {
            return this.http.get<T>(url, { responseType: type });
          }
        }
      }
    }
    return this.http.get<T>(url);
  }

  post<T>(url: string, params?: HttpParams | any, headers?: HttpHeaders | any, type?: any): Observable<T> {
    // console.log('params', params);
    // url = this.apiurl + '/' + url;
    if (params & headers) {
      return this.http.post<T>(url, params, { headers: headers });
    }
    else {
      if (params) {
        if (headers === null) {
          return this.http.post<T>(url, null, { params: params });
        }
        else {
          return this.http.post<T>(url, params);
        }
      }
      else {
        if (headers && type) {
          return this.http.post<T>(url, '', { headers: headers, responseType: type });
        }
        else {
          if (headers) {
            return this.http.post<T>(url, '', { headers: headers });
          }
          if (type) {
            return this.http.post<T>(url, '', { responseType: type });
          }
        }

      }
    }
    return this.http.post<T>(url, '');
  }

  put<T>(url: string, params: HttpParams | any): Observable<T> {
    // url = this.apiurl + '/' + url;
    return this.http.put<T>(url, params);
  }

  delete<T>(url: string): Observable<T> {
    // url = this.apiurl + '/' + url;
    return this.http.delete<T>(url);
  }

  getToken(): string {
    return sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '';
  }
}
