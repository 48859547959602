<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold active">Cron Jobs</a></li>
      <!-- <li><a class="text-bold" (click)="showActivities('activities')"
        [ngClass]="{'active': getlist == 'activities'}">Activities</a>
    </li> -->
    </ul>
  </mat-toolbar-row>

  <div class="center px-20" >
    <div class="content-card mt-12 mb-52">
      <div *ngIf="dataSource">
        <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12">
          <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">
             
              <!-- <ng-container matColumnDef="sno">
                <mat-header-cell *matHeaderCellDef fxFlex="100px"> S.No </mat-header-cell>
                <mat-cell *matCellDef="let element;index as i" fxFlex="100px">{{i+1}}
                </mat-cell>
              </ng-container> -->
              
              <ng-container matColumnDef="cronjobname">
                <mat-header-cell *matHeaderCellDef> cron job name </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a class="cursor-pointer" (click)="navigate(element)">{{element.cron_job_name}}</a>
                </mat-cell>
              </ng-container>

              <!-- email Column -->
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef fxFlex="200px"> Email </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="200px"> {{element.email}} </mat-cell>
              </ng-container>

              <!-- time Column -->
              <ng-container matColumnDef="time">
                <mat-header-cell *matHeaderCellDef> time </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.cron_job_time}} </mat-cell>
              </ng-container>

              <!-- status Column -->
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.status === 1 ? 'Active' : 'Inactive'}} </mat-cell>
              </ng-container>

              <!-- actions Column -->
              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef> actions </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <button mat-icon-button [ngClass]="{'primary-fg': element.status == 0,'warn-fg':element.status == 1}"  matTooltip="{{element.status == 0?'Switch On Cron Job':'Switch Off Cron Job'}}"
                    (click)="updateStatus(element)">
                    <mat-icon>power_settings_new</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class='footer'>
  <button mat-button (click)="showActivities()">Activities</button>
</div>