import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _moment from 'moment';
import { ValidateService } from 'app/services/validate.service';

@Component({
  selector: 'app-release-maintenance',
  templateUrl: './release-maintenance.component.html',
  styleUrls: ['./release-maintenance.component.scss']
})
export class ReleaseMaintenanceComponent implements OnInit {
  companies: any;
  MaintenanceForm: FormGroup;

  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private validateservice: ValidateService
  ) {
    this.MaintenanceForm = this.fb.group({
      to: [],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      mail_body: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.getCompanies();
  }
  getCompanies() {
    this.commonService.getCompanyList({ comp_id: 1 }).subscribe(res => {
      if (res.status === 1) {
        this.companies = res.data;
      }
    })
  }
  send() {
    if (this.MaintenanceForm.valid) {
      console.log(this.MaintenanceForm.value);
      let params = this.MaintenanceForm.value;
      this.commonService.enableMaintenanceMode(params).subscribe(res => {
        if (res.status) {
          this.commonService.openSnackBar('primary', res.message);
          this.MaintenanceForm.reset();
        } else {
          this.commonService.openSnackBar('warn', res.message);

        }
      })

    } else {
      console.log("invalid form");
      this.validateservice.validateAllFormFields(this.MaintenanceForm);
    }

  }
}
