<mat-toolbar-row class="secondary-headers w-100-p px-20">
  <ul class="nav m-0 p-0" fxFlex="auto">
    <li><a class="text-bold" (click)="getTabData('dataMigration')"
        [ngClass]="{'active': mainTab == 'dataMigration'}">Status Tracking</a>
    </li>
    <li><a class="text-bold" (click)="getTabData('ftp')" [ngClass]="{'active': mainTab == 'ftp'}">FTP Details & Resume
        Migrations</a>
    </li>
  </ul>
</mat-toolbar-row>

<mat-toolbar-row class="secondary-headers w-100-p px-20 py-12" *ngIf="mainTab == 'dataMigration'">
  <ul class="stages m-0 p-0" fxFlex="auto">
    <li *ngFor="let record of stagesData; let i = index">
      <a class="text-bold" (click)="getStage(record.stage_slug, i)"
        [ngClass]="record.is_active == '1' ? 'active' : 'inactive'">{{record.stage_name}}
        <span *ngIf="currentStage == record.stage_slug">
          <mat-icon class="s-18" [ngClass]="record.is_active == '1' ? 'fuse-white-fg' : 'green-fg'">edit</mat-icon>
        </span>
      </a>
    </li>
  </ul>
</mat-toolbar-row>

<ng-container *ngIf="mainTab == 'dataMigration'">
  <div>
    <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between stretch">
      <!-- <form #subscribeForm="ngForm" (ngSubmit)="saveData()" novalidate> -->
      <div fxFlex="60" class="m-12 mt-20" fxLayout="row wrap" fxLayoutAlign="space-between start">
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
          <div fxFlex="30">
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>Stage POC<span class="red-fg font-size-14">*</span></mat-label>
              <mat-select [(ngModel)]="trainingPoc" name="trainingPoc">
                <mat-option  *ngFor="let user of usersList" [value]='user.name'>{{user.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="30">
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>Estimated Completion Date</mat-label>
              <input matInput [matDatepicker]="picker1" (click)="picker1.open()" [(ngModel)]="estCompletioinDate"
                name="estCompletioinDate" (dateChange)="onDateChange($event, 'estCompletioinDate')">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div fxFlex="30">
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>Completion Date*</mat-label>
              <input matInput  [matDatepicker]="picker2" (click)="picker2.open()" [(ngModel)]="completionDate"
                name="completionDate" (dateChange)="onDateChange($event, 'completionDate')">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div fxFlex="60" *ngIf="currentStage == 'migration_inprogress'" fxLayout="row wrap" fxLayoutAlign="space-between center">
          <div fxFlex="50">
            <mat-form-field appearance="outline" class="w-100-p">
              <mat-label>Migration Type</mat-label>
              <mat-select [(ngModel)]="clientMigration" name="clientMigration">
                <mat-option *ngFor="let migration of clientMigrationTypes" [value]='migration.value'>{{migration.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="50" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="clientMigration == 'ceipal' ||clientMigration == 'zoniac' ">
            <button mat-button class="primary" (click)="processedMigration()">Click to Processed</button>
          </div>
        </div>

        <h2 fxFlex="100" class="muli-bold font-size-16" *ngIf="currentStage == 'data_backup_responsibility'">Migration
          Type</h2>
        <div fxFlex="30" *ngIf="currentStage == 'data_backup_responsibility'">
          <mat-card class="p-0" fxFlex="100">
            <mat-card-header class="px-12 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
              fxLayoutAlign="space-between center">
              <mat-card-title class="m-0 muli-bold font-size-14">Standard</mat-card-title>
            </mat-card-header>
            <mat-card-content class="px-16 py-12">
              <div fxLayout="column wrap" fxLayoutGap="12px">
                <mat-checkbox color="primary" *ngFor="let optRecord of standardOpts; let j = index"
                  [(ngModel)]="standardOpts[j]['isDone']" name="standardOpts">{{optRecord.name}}</mat-checkbox>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxFlex="65" *ngIf="currentStage == 'data_backup_responsibility'">
          <mat-card class="p-0" fxFlex="100">
            <mat-card-header class="px-12 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
              fxLayoutAlign="space-between center">
              <mat-card-title class="m-0 muli-bold font-size-14">Comprehnsive</mat-card-title>
            </mat-card-header>
            <mat-card-content class="px-16 py-12">
              <div fxLayout="row wrap" fxLayoutAlign="start center" class="mb-12">
                <div fxFlex="65">
                  <mat-radio-group fxLayout="row" fxLayoutAlign="start start" [(ngModel)]="migrationType"
                    name="migrationType">
                    <mat-radio-button  color="primary"  class="mr-12" value="free">Free Migration</mat-radio-button>
                    <mat-radio-button  color="primary" value="paid">Paid Migration</mat-radio-button>
                  </mat-radio-group>
                </div>
                <div fxFlex="35">
                  <mat-form-field class="w-100-p" appearance='outline'>
                    <mat-label>Cost</mat-label>
                    <input matInput type="number" [(ngModel)]="cost" name="cost">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="column wrap" fxLayoutGap="12px">
                <mat-checkbox color="primary" *ngFor="let optRecord of comprehnsiveOpts; let j = index"
                  [(ngModel)]="comprehnsiveOpts[j]['isDone']" name="standardOpts">{{optRecord.name}}</mat-checkbox>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <h2 fxFlex="100" class="muli-bold font-size-16" *ngIf="currentStage == 'data_backup_responsibility' || currentStage == 'data_downloading'">Data Location Details</h2>
        <div fxFlex="100" *ngIf="currentStage == 'data_backup_responsibility' || currentStage == 'data_downloading'">
          <mat-form-field appearance="outline" class="w-100-p">
            <mat-label>Type here</mat-label>
            <textarea matInput rows="4" class="textresize" [(ngModel)]="dataLocationInfo"
              name="dataLocationInfo"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="35" class="m-12 mt-20 border-radius-4">
        <app-comments #child [moduleSlug]="currentStage" [companyId]="company_id" [recordId]="0"></app-comments>
      </div>
      <!-- </form> -->
    </div>
  </div>
  <div class="footer">
    <button mat-button (click)="sendUpdateEmailToCustomer()" *ngIf="currentActiveStage == currentStage">Update Customer</button>
    <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
    <button mat-button (click)="saveData(true)">Save</button>
    <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
    <button mat-button (click)="getPreviousStage(currentStage, true)"
      *ngIf="currentStage !='data_backup_responsibility' && currentActiveStage == currentStage">Save
      & Push to previous stage</button>
    <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
    <button mat-button (click)="getNextStage(currentStage, true)" *ngIf="currentStage !='migration_completed' && currentActiveStage == currentStage ">Complete
      & Push to next stage</button>
    <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
    <button mat-button (click)="getNextStage(currentStage, true);isFlowComplete = 'true';"
      *ngIf="currentStage =='migration_completed' && currentActiveStage == currentStage ">Complete</button>
  </div>
</ng-container>

<ng-container *ngIf="mainTab == 'ftp'">
  <div class="center px-20">
    <div class="content-card mt-12 mb-12">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource1">
        <div class="search-wrapper" fxFlex="30" fxFlex.lt-xl="20">
        </div>
        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="end center">
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource1">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource1">
              <ng-container matColumnDef="user_name">
                <mat-header-cell *matHeaderCellDef> User Name </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.username}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="password">
                <mat-header-cell *matHeaderCellDef> Password </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.password}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="host">
                <mat-header-cell *matHeaderCellDef> Host </mat-header-cell>
                <mat-cell *matCellDef="let element" > 183.82.101.170 </mat-cell>
              </ng-container>

              <ng-container matColumnDef="port">
                <mat-header-cell *matHeaderCellDef> Port </mat-header-cell>
                <mat-cell *matCellDef="let element" > 21 </mat-cell>
              </ng-container>

              <ng-container matColumnDef="created_on">
                <mat-header-cell *matHeaderCellDef> Created On </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at}} </mat-cell>
              </ng-container>
          
              <mat-header-row *matHeaderRowDef="displayedColumns1;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns1;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource1.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <app-migration-ftp-files></app-migration-ftp-files>
</ng-container>