import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CommonHelpersService } from 'app/services/common-helpers.service';
import { ExcelService } from 'app/services/excel.service';
import { ReportsService } from 'app/services/reports.service';

const paymentModeWisePaymentsData = {
  chart: {
    showvalues: "1",
    caption: "Monthly Payments",
    subcaption: "",
    numberprefix: "",
    numbersuffix: "",
    plottooltext: "$seriesName : <b>$dataValue</b>",
    showhovereffect: "1",
    yaxisname: "Amount",
    showsum: "1",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        { label: "" }, { label: "" }, { label: "" }, { label: "" }, { label: "" }, { label: "" },
      ]
    }
  ],
  dataset: [
    {
      seriesname: "",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
      { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    },
  ]
};

@Component({
  selector: 'app-revenue-forecasting',
  templateUrl: './revenue-forecasting.component.html',
  styleUrls: ['./revenue-forecasting.component.scss']
})
export class RevenueForecastingComponent implements OnInit {

  monthlySubscriptionChartDataSource: any = paymentModeWisePaymentsData;
  displayedColumns: string[] = ['sno', 'customer_name', 'bill_amount', 'billing_cycle', 'billing_date','status', 'license_cost', 'addon_cost'];
  displayedChartColumns: string[] = ['month', 'projected'];
  // dataSource = ELEMENT_DATA1;
  dataSource:any;
  dataChartSource:any;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  currentUser = {};
  params: { page: number; limit?: number, search_key?: any, date_range?:any, country_id?:any };
  showList:boolean = true;
  status_id:any="all";

  statusList:any = [{name:"All", status:'all'}, {name:"Paid", status:'paid'}, {name:"Due", status:'due'}, {name:"Over Due", status:'over_due'}, {name:"Lost", status:'lost'}, {name:"Upcoming", status:'upcoming'}];
  currentMonth = new Date();
  responseData:any;
  dateRangeForm: FormGroup;
  getDateRange = {};
  public today: Date = new Date(new Date().toDateString());
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = new Date(this.today.getFullYear(), this.today.getMonth()+1, 0);
  lastDayOfMonth = new Date(this.today.getFullYear(), this.today.getMonth()+1, 0);
  totalRecords:any;
  foreCastMonths:any;
  monthsResponseData:any;
  revenewSummary:any;
  chartResponse:any;
  currencySymbol:any = '$';
  listSelectedCountry:any = '1';
  listSelectedMonth:any;
  listSelectedMonthIndex:any = 0;

  displayedList:any = 'list';
  selectedDueCustomerCountry:any;
  dueCustomemrsdisplayedColumns: string[] = ['sno', 'customer_name','region', 'bill_amount', 'billing_cycle', 'billing_date','license_cost', 'addon_cost'];
  dueCustomemrsdataSource:any;
  dueCustomemrssearchValue = '';
  dueCustomemrsparams: { page: number; limit?: number, search_key?: any, country_id?:any };
  dueCustomemrstotalRecords:any;
  dueCustomemrsrevenewSummary:any;
  dueCustomemrsresponseData:any;
  dueCustomemrscurrencySymbol:any;

  constructor(
    private reportService: ReportsService,
    public router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private helperService:CommonHelpersService,
    private excelService: ExcelService,
  ) {
    this.dateRangeForm = this.fb.group({
      start_date: [this.datepipe.transform(this.monthStart, 'yyyy-MM-dd')],
      end_date: [this.datepipe.transform(this.monthEnd, 'yyyy-MM-dd')]
    });

    this.dateRangeForm.valueChanges.subscribe(res => {
      if (res) {
        if (res.start_date && res.end_date) {
          this.getDateRange['start_date'] = this.datepipe.transform(res.start_date, 'yyyy-MM-dd');
          this.getDateRange['end_date'] = this.datepipe.transform(res.end_date, 'yyyy-MM-dd');
          this.params.date_range = JSON.stringify(this.getDateRange);
        }
        else {
          this.params.date_range = '';
          this.getDateRange = {};
        }
        this.getList();
      }
    });
  }

  ngOnInit() {
    
    this.params = { limit: 15, page: 1 };
    this.dueCustomemrsparams = { limit: 15, page: 1 };
    this.getList();
    this.getNextSixMonths();
  }

  getNextSixMonths() {
    this.reportService.getNextSixMonths().subscribe(res => {
      this.monthsResponseData = res.data;
      this.foreCastMonths = this.monthsResponseData.months;
      this.dataChartSource = this.monthsResponseData.months;
      this.foreCastMonths.forEach((val,key) => {
        paymentModeWisePaymentsData.categories[0]['category'][key]['label'] = val.month_short_name;
      });
      this.listSelectedMonth = this.monthsResponseData.current_month;
    })
  }

  showChartData(countr_id?:any) {
    this.reportService.getRevenueForeCostChartData({'country_id':countr_id}).subscribe(res => {
      if (res) {
        this.chartResponse = res.data;
        paymentModeWisePaymentsData.dataset[0].data.filter((data, index) => {
          paymentModeWisePaymentsData.dataset[0].data[index].value = '';
          paymentModeWisePaymentsData.dataset[0].data[index].fullDate = '';
        })
        if (res.data) {
          Object.entries(res.data).forEach(val => {
            paymentModeWisePaymentsData.categories[0].category.filter((labelData, index) => {
              if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
                paymentModeWisePaymentsData.dataset[0].data[index].value = val[1].toString();
                paymentModeWisePaymentsData.dataset[0].data[index].fullDate = val[0];
              }
            })
          });
        }
        this.monthlySubscriptionChartDataSource = paymentModeWisePaymentsData;
      }
    })
  }

  getForeCastAmount(year_month) {
    if (this.chartResponse && this.chartResponse[year_month]) {
      return this.chartResponse[year_month];
    }else {
      return 0;
    }
  }
  
  applyFilter(value) {
    if (this.displayedList == 'list') {
      this.params['search_key'] = value;
      this.getList();
    }
    else if (this.displayedList == 'past_dues') {
      this.dueCustomemrsparams['search_key'] = value;
      this.getDueCustomersList();
    }
  }
  
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    
    

    this.reportService.getRevenueForeCost(this.params).subscribe(res => {
      this.dataSource = new MatTableDataSource(res.data.data);
      this.totalRecords = res.data.data.length;
      this.responseData = res.data;
      this.revenewSummary = this.responseData.revenew_summary;
    })
  }

  changeCounry(e: any) {
    this.searchValue = '';
    this.status_id = 'all';
    this.params['search_key']= '';
    this.params['status']= 'all';
    this.params['country_id'] = e.value;
    this.listSelectedCountry = e.value;
    this.getList();
    this.currencySymbol = this.helperService.getCurrencySymbol(e.value);
  }

  getSelectedDisplayList(listType) {
    this.displayedList = listType;

    if (this.displayedList == 'past_dues') {
      this.getDueCustomersList();
      this.listSelectedMonth = '';
    }
    else if (this.displayedList == 'list'){
      this.dueCustomemrssearchValue = '';
      this.selectedDueCustomerCountry ='';
      this.dueCustomemrsparams['search_key'] = '';
      this.dueCustomemrsparams['country_id'] = '';
    }
  }

  getDueCustomersList() {
    this.reportService.getDueCustomers(this.dueCustomemrsparams).subscribe(res => {
      this.dueCustomemrsdataSource = new MatTableDataSource(res.data.data);
      this.dueCustomemrstotalRecords = res.data.data.length;
      this.dueCustomemrsresponseData = res.data;
      this.dueCustomemrsrevenewSummary = this.dueCustomemrsresponseData.revenew_summary;
    })
  }

  changeDueCustomersCounry(e: any) {
    this.dueCustomemrssearchValue = '';
    this.dueCustomemrsparams['search_key']= '';
    this.dueCustomemrsparams['country_id'] = e.value;
    this.selectedDueCustomerCountry = e.value;
    this.getDueCustomersList();
    this.dueCustomemrscurrencySymbol = this.helperService.getCurrencySymbol(e.value);
  }

  chartChangeCounry(e:any) {
    this.showChartData(e.value);
    this.currencySymbol = this.helperService.getCurrencySymbol(e.value);
  }

  onStatusChange(e: any) {
    this.params['status'] = e.value;
    this.getList();
  }

  changeForeCastMonth(index:any){
    this.searchValue = '';
    this.status_id = 'all';
    this.params['search_key']= '';
    this.params['status']= 'all';
    this.params.date_range = JSON.stringify(this.foreCastMonths[index]['month_dates']);
    this.listSelectedMonth = this.foreCastMonths[index]['month_number'];
    this.listSelectedMonthIndex = index;
    this.getList();
  }

  exportExcel() {
    let prepared_data;
    let sheet_name;
    if (this.displayedList == 'past_dues') {
      prepared_data = this.prepareExcelData(this.dueCustomemrsresponseData.data);
      sheet_name = 'Due Customers';
    } 
    else if (this.displayedList == 'list') {
      prepared_data = this.prepareExcelData(this.responseData.data);
      sheet_name = this.foreCastMonths[this.listSelectedMonthIndex]['month_name']+'-Reveneu Forecast';
    }
    this.excelService.exportAsExcelFile(prepared_data, sheet_name);
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name'] = element.name;

      if (this.displayedList == 'past_dues') {
        eachRow['Region'] = element.region;
      }
      eachRow['Bill Amount'] = element.billing_amount;
      eachRow['Billing Cycle'] = element.billing_cycle;
      eachRow['Billing Date'] = this.displayedList == 'past_dues'?element.billing_date:element.payment_date;

      if (this.displayedList == 'list') {
        eachRow['Status'] = element.subscription_status;
      }
      eachRow['Licenses Cost'] = element.licenses_amount;
      eachRow['Add-ons Cost'] = element.addons_amount;
      
      ArrData.push(eachRow);
    });
    return ArrData;
  }
  
  navigate(element) {
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }
}
