<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p py-4">
    <ul class="nav m-0 p-0" fxFlex="auto">
      <li class="text-bold active">Sales Executive Payments</li>
    </ul>
    Upgrade Payment <mat-slide-toggle color="primary" [(ngModel)]="check_upgrade_payment" class="toggle-right" style="margin-right:10px" (change)="checkUpgradePayment()"></mat-slide-toggle>
    <div fxFlex="150px" fxLayout="row wrap" fxLayoutAlign="end center" class="mr-12">
      <mat-form-field class="w-100-p country-p" appearance='outline'>
          <mat-label>Country</mat-label>
          <mat-select [(ngModel)]="country_id" (selectionChange)="countryChanged()">
              <mat-option *ngFor="let item of countries" [value]="item.value">
                  {{item.viewValue}}
              </mat-option>
          </mat-select>
      </mat-form-field>
    </div>
    <form [formGroup]="filtersFrom">
      <div fxFlex="150px" fxLayout="row wrap" fxLayoutAlign="end center" class="mr-12">
        <mat-form-field class="w-100-p country-p" appearance='outline'>
            <mat-label>Select Company</mat-label>
            <mat-select appMatSearchSelector formControlName="client_ids" multiple (selectionChange)="clientChange()">
              <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
              <ng-container *ngFor="let topping of toppingList">
                <mat-option *appMatSearchSelectorItems [value]="topping.id">{{topping.display_name}}
                </mat-option>
              </ng-container>
            </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="150px" fxLayout="row wrap" fxLayoutAlign="end center" class="mr-12">
        <mat-form-field class="w-100-p country-p" appearance='outline'>
            <mat-label>Sales Executive</mat-label>
            <mat-select appMatSearchSelector formControlName="sales_ids" multiple (selectionChange)="salesUserChange()">
              <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
              <ng-container *ngFor="let saleUser of salesUsers">
                <mat-option *appMatSearchSelectorItems [value]="saleUser.id">{{saleUser.name}}
                </mat-option>
              </ng-container>
            </mat-select>
        </mat-form-field>
      </div>
    </form>
    <form [formGroup]="dateRangeForm">
      <div fxFlex="300px" fxLayout="row wrap" fxLayoutAlign="end center">
        <mat-form-field appearance="outline" class="w-252 mr-20">
          <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date" formControlName="start_date">
              <input matEndDate placeholder="End date" formControlName="end_date">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      </div>
    </form>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
        <div class="search-wrapper" fxFlex="30" fxFlex.lt-xl="20">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [(ngModel)]="searchValue" placeholder="Company Name">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>
        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="start center" class="mt-4">
            <button mat-icon-button class="accent-fg" matTooltip="Export Data" (click)="exportExcel()">
                <mat-icon class="s-24">screen_share</mat-icon>
            </button>
         </div>
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>

      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="company_name">
                <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> <a class='cursor-pointer'
                  (click)='navigate(element)'>{{element.company_name}} </a></mat-cell>
              </ng-container>

              <ng-container matColumnDef="sales_executive">
                <mat-header-cell *matHeaderCellDef>Sales Executive </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.sales_executive_name}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="total">
                <mat-header-cell *matHeaderCellDef> Invoice Amount </mat-header-cell>
                <mat-cell *matCellDef="let element">{{getCurrencySymbol(element.country_id)}} {{(element.invoice_amount > 0)?element.invoice_amount:0.00}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="refund">
                <mat-header-cell *matHeaderCellDef> Refunded Amount </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{getCurrencySymbol(element.country_id)}} {{(element.refund_amount > 0)?element.refund_amount:0.00}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="grand_total">
                <mat-header-cell *matHeaderCellDef> Total Paid </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{getCurrencySymbol(element.country_id)}} {{element.total_paid}} </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>