import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { Router } from '@angular/router';
import { ReportsService } from 'app/services/reports.service';
import { ExcelService } from 'app/services/excel.service';
import { DatePipe } from '@angular/common';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-client-refunds',
  templateUrl: './client-refunds.component.html',
  styleUrls: ['./client-refunds.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ClientRefundsComponent implements OnInit {

  displayedColumns: string[] = ['customer_name', 'invoiceRef', 'amount', 'reason', 'refund_date', 'rufund_to', 'actions'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  params: {page: number; limit?: number, searchKey?: any, country_id?:any };
  active_subscription_id:any;
  refundForm: FormGroup;
  more = {};
  countries = [
    { value: '1', viewValue: 'USA' },{ value: '2', viewValue: 'India' }, { value: '702', viewValue: 'Singapore'}
  ];
  country_id:any = '1';

  
  constructor(
    private excelService: ExcelService,
    private datePipe: DatePipe,
    private commonService: CommonService,
    private reportService: ReportsService,
    private fb: FormBuilder,public dialog: MatDialog, public router: Router,) {
      this.refundForm = this.fb.group({
        Year_Month: [moment()],
      });
    
  }

  ngOnInit() {
    this.params = { limit: 15, page: 1, country_id:this.country_id };
    this.getList();
  }
  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }
  
  getList() {
    this.reportService.getClientRefunds(this.params).subscribe(result => {
      if (result.status === 1) {
        this.length = result.data.total;
        this.pageSize = result.data.per_page;
        this.index = result.data.current_page;
        this.active_subscription_id = result.active_subscription_id;
        this.dataSource = new MatTableDataSource(result.data.data);
        result.data.data.filter((val, index) => {
          this.more[index] = false;
        })

      }
    })
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.refundForm.get('Year_Month').value;
    ctrlValue.year(normalizedYear.year());
    this.refundForm.get('Year_Month').setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.refundForm.get('Year_Month').value;
    ctrlValue.month(normalizedMonth.month());
    this.refundForm.get('Year_Month').setValue(ctrlValue);
    let val = moment(ctrlValue).format('YYYY-MM');
    this.params['year_month'] = val;
    this.params['page'] = 1;
   
    datepicker.close();
    this.getList();
    // console.log(this.params);
  }

  downloadInvocieDoc(company_id, invoice_id, fileName) {
    this.commonService.downloadRefundInvoice(company_id, invoice_id).subscribe(res => {
      this.showPDFFile(res, fileName);
    });
  }

  showPDFFile(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const dataURL = URL.createObjectURL(blob);
    window.open(dataURL, '_blank');
  }

  navigate(element, blank) {
    // this.router.navigate(['company_details', element.company_id]);
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }

  getCurrencySymbol(payment_gateway, country_id) {
    
    if (payment_gateway == 1) {
      return '$';
    }
    if (country_id == 2) {
      return '₹';
    } else {
      return '$';
    }
  }

  countryChanged() {
    this.searchValue = '';
    this.params = { limit: 15, page: 1, country_id:this.country_id};
    this.getList();
  }

  exportExcel() {
    this.params.limit = this.length;
    this.reportService.getClientRefunds(this.params).subscribe(result => {
      if (result.status === 1) {
        let prepared_data = this.prepareExcelData(result.data.data);
        let sheet_name = 'Customers Refunds List ';
        this.excelService.exportAsExcelFile(prepared_data, sheet_name);
      }
    })
  }

  prepareExcelData(data) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name'] = element.company_name;
      eachRow['Invoice Ref#'] = element.ref_id;
      eachRow['Amount'] = this.getCurrencySymbol(element.payment_gateway, element.country_id) + ' ' +parseFloat(element.amount);
      eachRow['Reason'] = element.reason;
      eachRow['Refund Date'] = this.datePipe.transform(element.created_at, 'MM/dd/yyyy');
      eachRow['Refund To'] = element.refund_type;
      
      ArrData.push(eachRow);
    });
    return ArrData;
  }
}
