<mat-card class="p-0" fxFlex="100">
  <mat-card-header class="px-12 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
    fxLayoutAlign="space-between center">
    <mat-card-title class="m-0 muli-bold font-size-14">Comments</mat-card-title>
  </mat-card-header>
  <mat-card-content class="px-16 py-12">
    <div *ngIf="commentsData && currentUser" fusePerfectScrollbar fxLayout='column' class="comments">
      <div fxLayout='column' class="pb-16" *ngFor="let record of commentsData">
        <div class="px-8 font-size-12 text-bold color-grey">
          <span>{{record.created}}</span> - <span>{{record.updated_at | date:'medium'}}</span>
        </div>
        <p class="p-8 m-4 mat-elevation-z3 border-radius-8 font-size-12 text-bold color-grey line-height-1.75">{{record.body}}</p>
        <div class="px-8" fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center">
          <button mat-button color="primary" *ngIf="currentUser.id == record.created_by" (click)="editComment(record.id, record.body)">Edit</button>
          <button mat-button color="accent" *ngIf="currentUser.id == record.created_by" (click)="deleteComment(record.id)">Delete</button>
        </div>
      </div>
    </div>
    <div fxLayout='column'>
      <mat-form-field appearance="outline" class="w-100-p">
        <mat-label>Enter Comments</mat-label>
        <textarea matInput rows="4" class="textresize" [(ngModel)]="userComments"></textarea>
      </mat-form-field>
      <div class="px-8" fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start center">
        <button mat-button color="primary" (click)="saveComment()">Save</button>
        <button mat-button color="accent" (click)="cancelComments()">Cancel</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>