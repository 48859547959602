import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormArray, Validators, FormArrayName, FormControl } from '@angular/forms';
import { ValidateService } from 'app/services/validate.service';
import { ConfigurationService } from 'app/services/configuration.service';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-email-communication',
  templateUrl: './email-communication.component.html',
  styleUrls: ['./email-communication.component.scss']
})

export class EmailCommunicationComponent implements OnInit, AfterViewInit {
  @ViewChild('ToEmailchipList', { static: false }) ToEmailchipList: MatChipList;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fruits: Fruit[] = [];

  CommunicationForm: FormGroup;
  showAll_options: boolean = false;
  cc: boolean;
  bcc: boolean;
  companies: any;
  user_type: any;
  shotochips: boolean = false;
  customer_type: any;
  showusertype: boolean = true;
  sendtomails: string;
  ckeditor: any;

  constructor(
    private commonService: CommonService,
    private configService: ConfigurationService,
    public dialog: MatDialog, 
    private validateservice: ValidateService,
    private fb: FormBuilder, ) {
    
  }

  ngOnInit() {
    this.getCompanies();
    this.createForm();
    this.CommunicationForm.controls.to.statusChanges.subscribe(status => this.ToEmailchipList ? this.ToEmailchipList.errorState = status === 'INVALID' : '');
  }
  ngAfterViewInit(): void {
    this.CommunicationForm.controls.to.statusChanges.subscribe(status => this.ToEmailchipList ? this.ToEmailchipList.errorState = status === 'INVALID' : '');
  }
  validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }
  onReady(e) {
    this.ckeditor  = e.editor;
  }

  createForm() {
    this.CommunicationForm = this.fb.group({
      customer_type: ['1'],
      user_type: ['admin'],
      toids: new FormControl(''),
      to: new FormArray([]),
      cc: new FormArray([]),
      bcc: new FormArray([]),
      subject: ['', Validators.required],
      mail_body: ['', Validators.required]
    })
  }
  onCtrlChange() {

    this.customer_type = this.CommunicationForm.value.customer_type;
    if (this.customer_type == '1') {
      // this.CommunicationForm.controls.toids.patchValue([]);
      this.CommunicationForm.patchValue({ toids: [] });
      setTimeout(() => {
        this.showAll_options = false;
        this.shotochips = false;
        this.showusertype = true;
      }, 500);

    } else {
      this.CommunicationForm.patchValue({ user_type: 'admin' });
      let tomails = <FormArray>this.CommunicationForm.get('to');
      while (tomails.length > 0) {
        tomails.removeAt(0);
      }

      // this.CommunicationForm.addControl('toids', new FormControl(''));
      this.showAll_options = true;
      this.shotochips = false;
      this.showusertype = false;
      // tomails.controls = [];
      // this.CommunicationForm.value.to = '';
      // console.log(this.CommunicationForm.get('to'));

    }
  }

  onTypeChange() {
    this.user_type = this.CommunicationForm.value.user_type;
    if (this.user_type == 'admin') {
      this.shotochips = false;
      this.showAll_options = false;


    } else {
      this.shotochips = false;
      this.showAll_options = false;
      let tomails = <FormArray>this.CommunicationForm.get('to');
      while (tomails.length > 0) {
        tomails.removeAt(0);
      }
    }
  }

  getCompanies() {
    this.configService.getCompanyList({ comp_id: 1 }).subscribe(res => {
      if (res.status === 1) {
        this.companies = res.data;
      }
    })
  }

  showCc(e) {
    if(this.cc) {
      this.cc = false;
      let ccmails = <FormArray>this.CommunicationForm.get('cc');
      while (ccmails.length > 0) {
        ccmails.removeAt(0);
      }

    } else {
      this.cc = true;
    }
  }
  showBcc(e) {
    if(this.bcc) {
      this.bcc = false;
      let bccmails = <FormArray>this.CommunicationForm.get('bcc');
      while (bccmails.length > 0) {
        bccmails.removeAt(0);
      }

    } else {
      this.bcc = true;
    }
  }

  addEmail(event: MatChipInputEvent, field): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      const control = <FormArray>this.CommunicationForm.get(field);
      if (!this.checkDuplicateEmail(value.trim(), control)) {
        control.push(this.fb.control(value.trim(), [Validators.email]));
      }
    }

    if (input) {
      input.value = '';
    }
  }

  removeEmail(index: any, field: any): void {
    const control = <FormArray>this.CommunicationForm.get(field);

    if (index >= 0) {
      control.controls.splice(index, 1);
    }
  }

  checkDuplicateEmail(value: any, control: any) {
    let checkEmail = control.controls.filter(item => item.value === value);
    if (checkEmail.length === 0) {
      return false
    }
    return true;
  }

  send() {
    
    if (this.CommunicationForm.controls.customer_type.value == '1') {
      if (this.CommunicationForm.controls.user_type.value == 'admin') {
        // this.sendtomails = this.CommunicationForm.controls.to.value;
        // if(this.sendtomails == '' ||this.sendtomails == null) {
        //   this.commonService.openSnackBar('warn', 'Please select atleast one mail');
        //   return false;
        // }
        this.sendtomails = 'all_admin';
      }
      else {
        this.sendtomails = 'all';
      }
    } else {
      this.sendtomails = this.CommunicationForm.controls.toids.value;
      if(this.sendtomails == '' ||this.sendtomails == null ) {
        this.commonService.openSnackBar('warn', 'Please select atleast one mail');
        return false;
      }
    }
    
    if (this.CommunicationForm.valid) {

      let params = {
        customer_type: this.CommunicationForm.controls.customer_type.value,
        user_type: this.CommunicationForm.controls.user_type.value,
        to_mails: this.sendtomails,
        cc: this.CommunicationForm.controls.cc.value,
        bcc: this.CommunicationForm.controls.bcc.value,
        mail_body: this.CommunicationForm.controls.mail_body.value,
        subject: this.CommunicationForm.controls.subject.value,
      }
      // console.log(params);return;
      this.configService.sendCommunication(params).subscribe(res => {
        if(res.status) {
          this.commonService.openSnackBar('primary', res.message);
          this.createForm();
        } else {
          this.commonService.openSnackBar('warn', res.message);
        }
      })
    } else {
      this.validateservice.validateAllFormFields(this.CommunicationForm);
    }
  }

}
