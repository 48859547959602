<ng-container *ngIf="dataSource">
  <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12">
    <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
      <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
        [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
    </div>
  </div>
  <div class="px-12 pb-12">
    <mat-table [dataSource]="dataSource">
      <!-- sno Column -->
      <ng-container matColumnDef="sno">
        <mat-header-cell *matHeaderCellDef> S.no</mat-header-cell>
        <mat-cell *matCellDef="let element;index as i">{{i + 1}}
        </mat-cell>
      </ng-container>

      <!-- 	company Column -->
      <ng-container matColumnDef="company">
        <mat-header-cell *matHeaderCellDef fxFlex="200px"> Company </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="200px"> {{element.display_name}}
        </mat-cell>
      </ng-container>

      <!-- listname Column -->
      <ng-container matColumnDef="listname">
        <mat-header-cell *matHeaderCellDef> List name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.list_name}} </mat-cell>
      </ng-container>

      <!-- description Column -->
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> description </mat-header-cell>
        <mat-cell *matCellDef="let element">{{element.status === 1 ? 'queue' : ''}} </mat-cell>
      </ng-container>

      <!-- 	createdon  Column -->
      <ng-container matColumnDef="createdon">
        <mat-header-cell *matHeaderCellDef>created on </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.createdat | date:"MM/dd/yyyy"}}
        </mat-cell>
      </ng-container>


      <!-- action Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-flat-button class="accent" (click)="cleanMail(element)">
            clean
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <div *ngIf="dataSource.data.length == 0" class="text-center">
      <h2 class="heading m-0 py-16 border">No Records Found</h2>
    </div>
  </div>
</ng-container>