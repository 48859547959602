import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mail-templates',
  templateUrl: './mail-templates.component.html',
  styleUrls: ['./mail-templates.component.scss']
})
export class MailTemplatesComponent implements OnInit {
  dataSource = false;
  constructor() { }

  ngOnInit() {
  }

}
