<form [formGroup]="SubscriptionForm" *ngIf="SubscriptionForm">
  <div class="p-16" *ngIf="arrAddonResponse">
    <mat-card class="p-0">

      <mat-card-header class="px-16 border-top-radius-4 grey-200-bg" fxLayout="row wrap"
        fxLayoutAlign="space-between center">
        <mat-card-title class="m-0 muli-bold">Subscription Cost Details</mat-card-title>
        <mat-form-field appearance="outline">
          <mat-label>Hire {{hire_liceses.name}} ({{currency_symbol}})</mat-label>
          <input type="text" matInput formControlName="hire_user_cost">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{hr_liceses.name}} ({{currency_symbol}})</mat-label>
          <input type="text" matInput formControlName="hr_user_cost">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{crm_liceses.name}} ({{currency_symbol}})</mat-label>
          <input type="text" matInput formControlName="crm_user_cost">
        </mat-form-field>
      </mat-card-header>

      <mat-card-content class="px-16 py-8">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="mt-12">
          <!-- Mass Mails -->
          <div fxFlex="30">
            <ng-container formArrayName="mass_mails">
              <mat-form-field appearance="outline" class="w-100-p"
                *ngFor="let MassMail of SubscriptionForm.get('mass_mails')['controls']; index as i" [formGroupName]="i">
                <mat-label>{{MassMail.value.name}} ({{currency_symbol}})</mat-label>
                <input type="text" matInput [value]="MassMail.value.cost" formControlName="cost">
              </mat-form-field>
            </ng-container>

          </div>

          <!-- Contact Search -->
          <div fxFlex="30">
            <ng-container formArrayName="contact_search">
              <mat-form-field appearance="outline" class="w-100-p"
                *ngFor="let ContactSearch of SubscriptionForm.get('contact_search')['controls']; index as i"
                [formGroupName]="i">
                <mat-label>{{ContactSearch.value.name}} ({{currency_symbol}})</mat-label>
                <input type="text" matInput [value]="ContactSearch.value.cost" formControlName="cost">
              </mat-form-field>
            </ng-container>
          </div>

          <div fxFlex="30" >
            <mat-card class="p-0" *ngIf="oorwin_branding">
              <mat-card-header class="px-16 border-top-radius-4 grey-200" fxLayout="row wrap"
                fxLayoutAlign="space-between center">
                <mat-card-title class="m-0 py-12 muli-bold">Remove Oorwin Branding</mat-card-title>
              </mat-card-header>
              <mat-card-content class="p-16">
                <mat-radio-group formControlName="branding_type" aria-label="Select an option"
                  (change)="ChangeBrandingType(oorwin_branding.name)" [disabled]="add_branding_addon">
                  <mat-radio-button value="remove_monthly" color="primary" class="sub-heading mr-20">Monthly
                  </mat-radio-button>
                  <mat-radio-button value="remove_life_time" color="primary" class="sub-heading">Life Time
                  </mat-radio-button>
                </mat-radio-group>

                <mat-form-field appearance="outline" class="w-100-p mt-12">
                  <mat-label>Cost ({{currency_symbol}})</mat-label>
                  <input type="number" matInput formControlName="branding_cost" min="1" [readonly]="add_branding_addon">
                </mat-form-field>

              </mat-card-content>
            </mat-card>

            <mat-card class="p-0" style="margin-top: 10px;">
              <mat-card-header class="px-16 border-top-radius-4 grey-200" fxLayout="row wrap"
                fxLayoutAlign="space-between center">
                <mat-card-title class="m-0 py-12 muli-bold">Next Subscription Start Date</mat-card-title>
              </mat-card-header>
              <mat-card-content class="p-16">
                <mat-form-field appearance="outline" class="w-100-p mt-12">
                  <mat-label>Start Date</mat-label>
                  <input matInput [matDatepicker]="picker" (click)="picker.open()" (dateChange)="onDateChange($event)"
                    formControlName="next_subscription_start_date">
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </mat-card-content>
            </mat-card>
          </div>

          <!-- <div fxFlex="30">
          <mat-form-field appearance="outline" class="w-100-p">
            <mat-label>5000 mass mail credits</mat-label>
            <input type="text" matInput>
          </mat-form-field>
        </div> -->

        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class='footer' *ngIf="permissions">
    <button mat-button (click)="updateSubscription()" *ngIf="permissions['clients.subsriptions.update']">Update</button>
    <!-- <button mat-button *ngIf="permissions['clients.subsriptions.update'] && subscription_type == 'trial'" [routerLink]= "['upgrade']" >Upgrade</button>
    <button mat-button *ngIf="permissions['clients.subsriptions.update'] && subscription_type != 'trial'" [routerLink]= "['renew']">Renew</button> -->
  </div>
</form>