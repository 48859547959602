<div fxFlex="100">
  <form  #subscribeForm="ngForm" (ngSubmit)="saveTrailMode()" *ngIf="addons">

    <div fxLayout="row wrap" fxLayoutAlign="space-evenly start" class="my-20 px-32">
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="93" class="pb-16">
        <div fxFlex='24'>
          <mat-form-field class="w-100-p" appearance='outline'>
            <mat-label>Country</mat-label>
            <mat-select [(value)]="selected_country" (selectionChange)="countryChanged()">
              <mat-option *ngFor="let item of coutries" [value]="item.value">
                {{item.viewValue}}
              </mat-option>
            </mat-select> 
          </mat-form-field>
        </div>
        <div fxFlex='24' class="pl-20" >
            <mat-form-field class="w-100-p" appearance='outline'> 
                <mat-label>product</mat-label>
                <mat-select (selectionChange)="productChanged()" [(value)]="selected_package">
                    <mat-option *ngFor="let item of packages" [value]="item.value">
                        {{item.viewValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
      </div>
      <div fxFlex='45' fxLayoutAlign="start">
        <mat-card class="p-0">
          <mat-card-header class="px-16 border-top-radius-4 grey-200 py-8">
            <mat-card-title class="m-0 muli-bold">TRIAL ADDONS CREDITS</mat-card-title>
          </mat-card-header>
          <mat-card-content class="p-16">

            <!-- <div *ngIf="addons.planData" fxLayout="row wrap">
              <ng-container *ngFor="let field of addons.planData; let i = index">
                  <mat-checkbox color="primary" class="font-size-14 w-100-p white-space-wrap" [(ngModel)]="field.plan_id" [name]="field.name+'_'+i" >{{field.name}}</mat-checkbox>
              </ng-container>
            </div> -->

            <div fxLayout="row wrap" class="my-20 " fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
              
              <div *ngFor="let field of addons; let i = index">
                <div fxFlex="48">
                  <mat-form-field class="w-100-p " appearance="outline" >
                      <mat-label>{{field.name}} </mat-label>
                      <input type="number" matInput required customMin="1" [(ngModel)]="field.quantity" [name]="field.slug+'_'+i">
                  </mat-form-field>
                </div>
              </div>

            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div fxFlex='45'>
        <!-- <mat-card class="p-0">
          <mat-card-header class="px-16 border-top-radius-4 grey-200 py-8">
            <mat-card-title class="m-0 muli-bold">TRIAL PERIOD DURATION</mat-card-title>
          </mat-card-header>
          <mat-card-content class="p-16">
            <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
              <div fxFlex="48" fxLayout="row">
                <mat-form-field class="w-100-p br-none" appearance="outline">
                  <mat-label>Trail Period</mat-label>
                  <input type="text" matInput formControlName="trial_days">
                </mat-form-field>
                <span class="attch">in Days</span>
              </div>
              <div fxFlex="48" fxLayout="row">
                <mat-form-field class="w-100-p br-none" appearance="outline">
                  <mat-label>Grace Period</mat-label>
                  <input type="text" matInput formControlName="grace_period">
                </mat-form-field>
                <span class="attch">in Days</span>
              </div>
            </div>
          </mat-card-content>
        </mat-card> -->
      </div>
    </div>
  </form>
</div>
<div class='footer'>
  <button mat-button (click)="saveTrailMode()">Save</button>
  <mat-divider style="width:1px;background-color:#fff;"></mat-divider>
  <button mat-button (click)="showActivities()">Activities</button>
</div>