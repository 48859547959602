import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/services/common.service';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-pass-due-date',
  templateUrl: './pass-due-date.component.html',
  styleUrls: ['./pass-due-date.component.scss']
})
export class PassDueDateComponent implements OnInit {

  params: { all_users: number; limit: number; page: number; type: string; searchKey?: string; };
  displayedColumns = ['customername', 'subdomainname', 'region', 'status', 'subscriptionenddate', 'actions']
  getTableData: any;
  length: any;
  pageSize: any;
  dataSource;
  index: any;
  searchValue = '';
  constructor(private commonService: CommonService, private router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    this.params = { all_users: 2, limit: 15, page: 1, type: 'pass_due_date' };
    this.getList();
  }
  applyFilter(value) {
    this.params['searchKey'] = value;
    this.getList();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }
  getList() {
    this.commonService.getList(this.params).subscribe(result => {
      if (result.status === 1) {
        this.getTableData = result.data;
        this.length = this.getTableData.total;
        this.pageSize = result.data.per_page;
        this.index = result.data.current_page;
        this.dataSource = new MatTableDataSource(result.data.data);
      }
    })
  }
  navigate(element) {
    this.router.navigate(['company_details', element.company_id]);
  }
  delete(record_id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to delete this Data';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });
    let saveData: any;
    saveData = {
      'id': record_id,
      'status': 'delete'
    };
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(saveData);
        this.commonService.deleteSignupRequest(saveData).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
          this.getList();
        });
      } else {

      }
    })
  }

}
