<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">FTP Details</h2>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">

        <div class="search-wrapper" fxFlex="30" fxFlex.lt-xl="20">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [(ngModel)]="searchValue" placeholder="Company Name">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>

        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="customer_name">
                <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <span *ngIf="element.company_name"> <a class='cursor-pointer'
                  (click)='navigate(element)'>{{element.company_name}} </a></span>
                  <span *ngIf="!element.company_name">Company Deleted</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="user_name">
                <mat-header-cell *matHeaderCellDef> User Name </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.username}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="password">
                <mat-header-cell *matHeaderCellDef> Password </mat-header-cell>
                <mat-cell *matCellDef="let element" class="lowercase"> {{element.password}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="host">
                <mat-header-cell *matHeaderCellDef> Host </mat-header-cell>
                <mat-cell *matCellDef="let element" > 183.82.101.170 </mat-cell>
              </ng-container>

              <ng-container matColumnDef="port">
                <mat-header-cell *matHeaderCellDef> Port </mat-header-cell>
                <mat-cell *matCellDef="let element" > 21 </mat-cell>
              </ng-container>

              <ng-container matColumnDef="created_on">
                <mat-header-cell *matHeaderCellDef> Created On </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.created_at}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef fxFlex='150px'> Actions </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="164px">
                  <button mat-icon-button class="red-fg" matTooltip="Delete" (click)="deleteRecord(element)" *ngIf="!element.company_name && (currentUser && currentUser.role_id == 1)">
                    <mat-icon class="s-18">delete</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
          
              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>