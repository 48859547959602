import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { FormBuilder, FormGroup, FormArray, AbstractControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { isEmpty } from 'lodash';
import { CompanyDetailsService } from 'app/services/company-details.service';

@Component({
  selector: 'app-migrations',
  templateUrl: './migrations.component.html',
  styleUrls: ['./migrations.component.scss']
})
export class MigrationsComponent implements OnInit {
  fileToUpload: File = null;
  sizes: string[];
  company_id: any;
  folderpath: any = '';
  showAddForm : number = 1;
  userForm: FormGroup;
  editRecordId:any;
  companies = [
    { name: 'Ceipal', value: 'ceipal' },
    { name: 'Zoniac', value: 'zoniac' }
  ];
  usersList:any;
  statusList:any;
  displayedColumns: string[] = ['initiated_on', 'user' ,'migration_type', 'folders','total','migrated','failed','remaining','percentage', 'status',  'actions'];
  dataSource;

  client_name: any = 'ceipal';
  client: { name: string; value: string; };
  @ViewChild('myFile', { static: false })
  myFileVariable: ElementRef;
  more = [];
  isLostAccount:any;
  clientMigration:any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private commonserv: CommonService,
    private companyDetailService: CompanyDetailsService,
    private fb:FormBuilder,
    public dialog: MatDialog,
  ) {
    this.company_id = this.route.parent.snapshot.params['id'];
    this.clientMigration = this.route.snapshot.params['migrationType'];
  }

  ngOnInit() {
    this.createRecordForm();
    this.getCPanelUsers();
    this.getAccountLostInfo();
    console.log('hai', this.clientMigration);
    
    if (this.clientMigration) {
      this.client_name = this.clientMigration;
    }
  }

  getAccountLostInfo() {
    this.commonserv.getAccountLostInfo({company_id:this.company_id}).subscribe(res => {
      if (res.status == 1) {
        this.isLostAccount = res.data.isAccountLost;
      }
    })
  }

  createRecordForm() {
    this.userForm = this.fb.group({
      input_array: this.fb.array([
        this.fb.control('')
      ]),
      initiatedOn:['', Validators.required],
      cpanelUser:['', Validators.required],
      status:['', Validators.required],
      migrationType:['ftp', Validators.required],
      total_files:['', Validators.required],
      migrated_files:['', Validators.required],
      failed_files:['', Validators.required]
    })
  }

  handleFileInput(files: FileList) {
    this.folderpath = '';
    this.fileToUpload = files.item(0);
  }

  resetuploadfile() {
    this.myFileVariable.nativeElement.value = '';
    this.fileToUpload = null;
  }

  uploadFile() {
    if (this.fileToUpload || this.folderpath != '') {

      if (this.fileToUpload) {
        if (this.fileToUpload.type === 'application/vnd.ms-excel' || this.fileToUpload.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          this.commonserv.fileupload = this.fileToUpload;
          this.commonserv.changeFile(this.fileToUpload, this.company_id, this.client_name);
          this.router.navigate(['company_details/' + this.company_id + '/mapfields']);
        } else {
          this.commonserv.openSnackBar('warn', 'Please upload an xls, xlsx file format to proceed');
        }
      } else if (this.folderpath) {
        this.commonserv.folderpath = this.folderpath;
        this.commonserv.changeFile(this.folderpath, this.company_id, this.client_name);
        this.router.navigate(['company_details/' + this.company_id + '/mapfields']);

      } else {
        this.commonserv.openSnackBar('warn', 'Please upload a file to proceed');
      }
    } else {
      this.commonserv.openSnackBar('warn', 'Please Select a file to proceed');
    }
  }

  add() {
    if(this.userForm.value.input_array.length >9){
      return;
    }
    (this.userForm.get('input_array') as FormArray).push(
      this.fb.control(null)
    );
  }

  remove(index) {
    (this.userForm.get('input_array') as FormArray).removeAt(index);
  }

  getFormControls(): AbstractControl[] {
    return (<FormArray> this.userForm.get('input_array')).controls
  }

  onSubmit() {
    if(this.userForm.valid) {
      let saveData = {};
      saveData['initiated_on'] = moment(this.userForm.value.initiatedOn).format("YYYY-MM-DD");
      saveData['cpanel_user'] = this.userForm.value.cpanelUser;
      saveData['status'] = this.userForm.value.status;
      saveData['migration_type'] = this.userForm.value.migrationType;
      saveData['folders'] = this.userForm.value.input_array;
      saveData['total_files'] = this.userForm.value.total_files;
      saveData['migrated_files'] = this.userForm.value.migrated_files;
      saveData['failed_files'] = this.userForm.value.failed_files;
      saveData['company_id'] = this.company_id;
      saveData['record_id'] = this.editRecordId?this.editRecordId:'';
      
      this.commonserv.candidateMigrationRecordSave(saveData).subscribe(res => {
        if (res.data.status == 1) {
          this.commonserv.openSnackBar('primary', res.data.message);
          this.createRecordForm();
          this.getMigrationRecords();
          this.showAddForm = 3;
        } else {
          this.commonserv.openSnackBar('warn', res.data.message);
        }
      })
    }
  }

  getCPanelUsers() {
    let RoleIdArr = {};
    RoleIdArr[3] = 3;
    RoleIdArr[2] = 2;
    this.companyDetailService.getCpanelRoleUsers(RoleIdArr).subscribe(res => {
      if (res.status == 1) {
        this.usersList = res.data.users;
        this.statusList = res.data.statusList;
      }
    })
  }

  getMigrationRecords() {
    this.commonserv.getCandidateMigrationRecords({'company_id':this.company_id}).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data.data);
        this.showAddForm = 3;
      }
    })
  }

  deleteMigrationRecord(id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to delete this Data';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonserv.deleteCandidateMigrationRecord({'migration_id':id}).subscribe(res => {
          if (res.status == 1) {
            this.commonserv.openSnackBar('primary', res.message);
            this.getMigrationRecords();
            this.showAddForm = 3;
          } else {
            this.commonserv.openSnackBar('warn', res.message);
          }
        });
      } else {

      }
    })
  }

  editRecord(record) {
    this.createRecordForm();
    this.userForm.patchValue({
      initiatedOn:record.initiated_on,
      cpanelUser:record.cpanel_user,
      migrationType:record.migration_type,
      status:record.status,
      total_files:record.total_files,
      migrated_files:record.migrated_files,
      failed_files:record.failed_files,
    });

    if (!isEmpty(record.folders) && record.migration_type == 'ftp') {
      let folders = JSON.parse(record.folders);
      const folderArr = this.userForm.get('input_array') as FormArray;
      folderArr.removeAt(0);
      folders.forEach((v,k) => {
        folderArr.push(
          this.fb.control(v)
        )
      })
    }
    this.editRecordId = record.id; 
    this.showAddForm = 2;
  }

  getFolderNames(record) {

    if (isEmpty(record.folders)) {
      return '-';
    }
    let folders = JSON.parse(record.folders);
    return folders.toString();
  }
 
}
