import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'app/services/common.service';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import * as moment from 'moment';
import { CompanyDetailsService } from 'app/services/company-details.service';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  company_id: any;
  arrAddonResponse: any;
  hire_liceses: any;
  hr_liceses: any;
  crm_liceses: any;
  mass_mails_list: any;
  oorwin_branding: any;
  contact_search_list: any;
  oorwin_branding_list: any;
  SubscriptionForm: FormGroup;
  brandingList: any;
  permissions: any = {};
  add_branding_addon:boolean = false;
  next_start_date:any;
  subscription_type: any;
  currency_symbol:any;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private companyDetailService: CompanyDetailsService,
    private fb: FormBuilder
  ) {
    this.company_id = this.route.parent.snapshot.params['id'];
  }

  ngOnInit() {
    this.getSubscriptionDetails();

  }
  getSubscriptionDetails() {
    this.companyDetailService.getCompnaySubscriptionDetails(this.company_id).subscribe(res => {
      if (res.status == 1) {
        this.permissions = res.permissions;
        console.log('res', res);
        this.arrAddonResponse = res.data;
        
        this.hire_liceses = this.arrAddonResponse['hire_users'][0];
        this.hr_liceses = this.arrAddonResponse['hr_users'][0];
        this.crm_liceses = this.arrAddonResponse['crm_users'][0];
        this.mass_mails_list = this.arrAddonResponse['mass_mails_credits'];
        this.contact_search_list = this.arrAddonResponse['contacts_search_credits'];
        this.oorwin_branding = this.arrAddonResponse['oorwin_branding'][0];
        this.brandingList = res.branding_data;
        this.add_branding_addon = res.branding_addon_access;
        this.next_start_date = res.next_start_date;
        this.subscription_type = res.active_subscription.subscription_type;
        this.currency_symbol = res.currency_symbol;

        this.createForm();

        const arrMassMail = this.SubscriptionForm.get('mass_mails') as FormArray;
        const arrContactSearch = this.SubscriptionForm.get('contact_search') as FormArray;
        this.mass_mails_list.forEach(element => {
          arrMassMail.push(
            this.fb.group({
              addon_id: [element.addon_id],
              addonref: [element.addonref],
              company_id: [element.company_id],
              cost: [element.cost],
              name: [element.name],
              quantity: [element.quantity]
            })
          );
        });

        this.contact_search_list.forEach(element => {
          arrContactSearch.push(
            this.fb.group({
              addon_id: [element.addon_id],
              addonref: [element.addonref],
              company_id: [element.company_id],
              cost: [element.cost],
              name: [element.name],
              quantity: [element.quantity]
            })
          );
        });

      }
      else {
        this.commonService.openSnackBar('warn', res.message);
      }
    });
  }

  createForm() {
    this.SubscriptionForm = this.fb.group({
      hire_user_cost: [this.hire_liceses.cost ? this.hire_liceses.cost : ''],
      hr_user_cost: [this.hr_liceses.cost ? this.hr_liceses.cost : ''],
      crm_user_cost: [this.crm_liceses.cost ? this.crm_liceses.cost : ''],
      mass_mails: this.fb.array([]),
      contact_search: this.fb.array([]),
      branding_type: [this.oorwin_branding.name],
      branding_cost: [this.oorwin_branding.cost, Validators.required],
      next_subscription_start_date:[this.next_start_date]
    });
  }

  updateSubscription() {
    if (this.SubscriptionForm.valid) {

      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? You want to update';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35%',
        data: data
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {

          let UserCost = [];
          let BrandingData = {};
          let saveData: any;
          this.hire_liceses['cost'] = this.SubscriptionForm.value.hire_user_cost;
          this.hr_liceses['cost'] = this.SubscriptionForm.value.hr_user_cost;
          this.crm_liceses['cost'] = this.SubscriptionForm.value.crm_user_cost;

          UserCost.push(this.hire_liceses);
          UserCost.push(this.hr_liceses);
          UserCost.push(this.crm_liceses);

          BrandingData['name'] = this.SubscriptionForm.value.branding_type;
          BrandingData['cost'] = this.SubscriptionForm.value.branding_cost;
          BrandingData['id'] = this.oorwin_branding.addonref;
          BrandingData['company_id'] = this.company_id;
          // console.log('name', BrandingData);return;
          saveData = {
            'AccountContacts': this.SubscriptionForm.value.contact_search,
            'mailData': this.SubscriptionForm.value.mass_mails,
            'UserCost': UserCost,
            'BrandingData': BrandingData,
            'socialData': ''
          }
          this.companyDetailService.saveCompanySubscription(saveData).subscribe(res => {
            if (res.status == 1) {
              this.commonService.openSnackBar('primary', res.message);
              this.getSubscriptionDetails();
            } else {
              this.commonService.openSnackBar('warn', res.message);
            }
          });

        } else { }
      })
    }
  }

  ChangeBrandingType(current_type) {
    let selected_type;
    selected_type = this.SubscriptionForm.value.branding_type;
    if (selected_type != current_type) {
      this.SubscriptionForm.patchValue({ 'branding_cost': this.brandingList[selected_type] });
    } else {
      this.SubscriptionForm.patchValue({ 'branding_cost': this.oorwin_branding.cost });
    }
  }

  onDateChange(event) {

    // let selected_date = moment(this.SubscriptionForm.value.next_subscription_start_date).format("YYYY-MM-DD");
    // let to_day = moment().format("YYYY-MM-DD");

    // if (selected_date > to_day) {
    //   this.commonService.openSnackBar('warn', "Future date not allowed");
    //   return
    // }
    
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to add start date';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let extended_date: any;
        let saveData: any;
        extended_date = moment(this.SubscriptionForm.value.next_subscription_start_date).format("YYYY-MM-DD");
        saveData = {
          'start_date':extended_date,
          'company_id' : this.company_id
        };
        this.companyDetailService.addNewStartDate(saveData).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        });

      } else {}
    })
  }

  upgrade() {
    this.router.navigate(['upgrade']);
  }

}
