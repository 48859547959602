<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-header">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold" [routerLink]="['/dashboard']" routerLinkActive="router-link-active">Dashboard</a></li>
      <li><a class="text-bold active">Current Month Payments</a></li>
    </ul>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-20 mb-52">
      <div class="content border-1" fusePerfectScrollbar>
        <app-top-cards></app-top-cards>

        <!-- <ng-container *ngIf="dataSource && paymentList">
          <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12">
            <div class="search-wrapper" fxFlex="30">
              <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
                [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Customer Name...">
              <mat-icon class="search_icon">search</mat-icon>
              <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
                close
              </mat-icon>
            </div>
            <form [formGroup]="searchForm" fxFlex="30">
              <div fxFlex="35" class="ml-4">
                <mat-form-field appearance="outline" class="w-100-p">
                  <mat-select formControlName="select" (selectionChange)='selectChange($event.value)'>
                    <mat-option *ngFor="let item of selection" [value]='item.value'>
                      {{item.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="65" class="pl-12">
                <ejs-daterangepicker placeholder='Select Date' class="w-100-p" [value]='value' (change)="date($event)">
                  <e-presets>
                    <e-preset label="This Week" [start]='weekStart' [end]='weekEnd'></e-preset>
                    <e-preset label="This Month" [start]='monthStart' [end]='monthEnd'></e-preset>
                    <e-preset label="Last Month" [start]='lastStart' [end]='lastEnd'></e-preset>
                    <e-preset label="Last Year" [start]='yearStart' [end]='yearEnd'></e-preset>
                  </e-presets>
                </ejs-daterangepicker>
              </div>
            </form>
            <div fxFlex="40" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
              <div>
                <button mat-flat-button class="primary" (click)="exportExcel()">Export as Excel</button>
              </div>
              <div fxLayout="row" fxLayoutAlign="end center">
                <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50]" [length]="length" (page)="pageEvent($event)"
                  [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
              </div>
            </div>
          </div>
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">
            
              <ng-container matColumnDef="customerName">
                <mat-header-cell *matHeaderCellDef> customer Name </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.display_name}}</mat-cell>
              </ng-container>
             
              <ng-container matColumnDef="#userLicences">
                <mat-header-cell *matHeaderCellDef> #user Licences </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{(dataId === 0 || dataId === 1) ? (paymentList[element.id]['1'] ? paymentList[element.id]['1']['0'] : '') : ''}}
                </mat-cell>
              </ng-container>

            
              <ng-container matColumnDef="#massMailCredits">
                <mat-header-cell *matHeaderCellDef fxFlex="200px"> #mass Mail Credits </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="200px">
                  {{(dataId === 0 || dataId === 2) ? paymentList[element.id]['2']['0'] : ''}}
                </mat-cell>
              </ng-container>

             
              <ng-container matColumnDef="#accountContacts">
                <mat-header-cell *matHeaderCellDef> account Contacts </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{(dataId === 0 || dataId === 4) ? paymentList[element.id]['4']['0'] : ''}}
                </mat-cell>
              </ng-container>

             
              <ng-container matColumnDef="$userLicences">
                <mat-header-cell *matHeaderCellDef> $user Licences </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{(dataId === 0 || dataId === 1) ? (paymentList[element.id]['1']['1'] | currency) : ''}}
                </mat-cell>
              </ng-container>

            
              <ng-container matColumnDef="$massMailCredits">
                <mat-header-cell *matHeaderCellDef> $mass Mail Credits </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{(dataId === 0 || dataId === 2) ? (paymentList[element.id]['2']['1'] | currency) : ''}}
                </mat-cell>
              </ng-container>

              
              <ng-container matColumnDef="$accountContacts">
                <mat-header-cell *matHeaderCellDef> $account Contacts </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{(dataId === 0 || dataId === 4) ? (paymentList[element.id]['4']['1'] | currency) : ''}}
                </mat-cell>
              </ng-container>

             
              <ng-container matColumnDef="$amount">
                <mat-header-cell *matHeaderCellDef> $amount </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{dataId === 0 ? (paymentList[element.id]['1']['1'] + paymentList[element.id]['2']['1'] + paymentList[element.id]['4']['1'] | currency) : (dataId === 1 ? (paymentList[element.id]['1']['1'] | currency) : (dataId === 2 ? (paymentList[element.id]['2']['1'] | currency) : (dataId === 4 ? ( paymentList[element.id]['4']['1'] | currency) : '') ))}}
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container> -->

        <!-- Dashboard monthly revenue report -->



        <ng-container *ngIf="revenueDataSource">
          <form [formGroup]="revenueForm">
            <div fxLayout='row wrap' fxLayoutAlign="space-between center" class="fuse-white-bg px-12 pt-12">
              <div fxFlex="20">
                <mat-form-field class="w-100-p ml-8" appearance="outline">
                  <mat-label>Select</mat-label>
                  <mat-select class="w-100-p" formControlName="customer" multiple>
                    <mat-option *ngFor="let topping of toppingList" [value]="topping.id">{{topping.display_name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="20">
                <mat-form-field appearance="outline" class="w-100-p">
                  <mat-label>App Type</mat-label>
                  <mat-select formControlName="app_type" multiple>
                    <mat-option *ngFor="let cust of app_type" [value]="cust.value">
                      {{cust.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="20">
                <mat-form-field appearance="outline" class="w-100-p">
                  <mat-label>Subscription Cycle</mat-label>
                  <mat-select formControlName="subscription_cycle">
                    <mat-option *ngFor="let cust of subscriptionCycle" [value]="cust.value">
                      {{cust.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- <div fxFlex="20">
                  <mat-form-field class="w-100-p" appearance='outline'>
                    <mat-label>paid On</mat-label>
                    <input matInput [matDatepicker]="picker" (click)="picker.open()" formControlName="paid_on">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div fxFlex="20">
                  <mat-form-field class="w-100-p" appearance='outline'>
                    <mat-label>Onboarded On</mat-label>
                    <input matInput [matDatepicker]="picker1" (click)="picker1.open()" formControlName="onboarded_on">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                </div> -->
              <div fxFlex="15">
                
                <mat-form-field class="w-100-p" appearance="outline" [matMenuTriggerFor]="menu">
                  <mat-label>Select date</mat-label>
                  <mat-date-range-input [rangePicker]="rangePicker">
                    <input matStartDate placeholder="Start date" readonly formControlName="start_date">
                    <input matEndDate placeholder="End date" readonly formControlName="end_date">
                  </mat-date-range-input>
                </mat-form-field>
    
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="setDate('this_week')"
                    [ngClass]="{'primary': (dateActive == 'this_week')}">This Week</button>
                  <button mat-menu-item (click)="setDate('this_month')"
                    [ngClass]="{'primary': (dateActive == 'this_month')}">This Month</button>
                  <button mat-menu-item (click)="setDate('last_month')"
                    [ngClass]="{'primary': (dateActive == 'last_month')}">Last Month</button>
                  <button mat-menu-item (click)="rangePicker.open();dateActive = 'custom';"
                    [ngClass]="{'primary': dateActive == 'custom'}">Custom Range</button>
                  <button mat-menu-item (click)="setDate('clear')"
                    [ngClass]="{'primary': dateActive == 'clear'}">Clear</button>
                  <!-- <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle> -->
                  <mat-date-range-picker #rangePicker>
                    <mat-date-range-picker-actions>
                      <button mat-button matDateRangePickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                    </mat-date-range-picker-actions>
                  </mat-date-range-picker>
                </mat-menu>
              </div>
              <!-- <div fxFlex="20">
                  <mat-form-field class="w-100-p" appearance='outline'>
                    <mat-label>Onboarded On</mat-label>
                    <input matInput type="month" formControlName="month">
                  </mat-form-field>
                </div> -->
            </div>
          </form>
          <div class="px-12 pb-12">
            <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg">
              <div class="search-wrapper" fxFlex="30">
                <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value,'monthlyRevenueReport')"
                  type="text" [readOnly]="revenueDataSource.length === 0" [(ngModel)]="revenueSearchValue"
                  placeholder="Search ..">
                <mat-icon class="search_icon">search</mat-icon>
                <mat-icon class="close s-20 danger-fg" *ngIf="revenueSearchValue"
                  (click)="revenueSearchValue=''; applyFilter('');">
                  close
                </mat-icon>
              </div>
              <div fxFlex="100" class="actions_list" fxLayout="row wrap" fxLayoutAlign="end center">
                <div>
                  <button mat-stroked-button (click)="exportExcel()">Export as excel</button>
                </div>
                <div fxLayout="row" fxLayoutAlign="end center">
                  <mat-paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="revenueLength"
                    (page)="pageEvent($event,'monthlyRevenueReport')" [pageSize]="revenuepageSize"
                    [pageIndex]="revenueindex-1">
                  </mat-paginator>
                </div>
              </div>
            </div>
            <div fusePerfectScrollbar>
              <mat-table [dataSource]="revenueDataSource" class="table">
                <!-- customerName Column -->
                <ng-container matColumnDef="customerName">
                  <mat-header-cell *matHeaderCellDef> customer Name </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{element.company_name}}
                  </mat-cell>
                </ng-container>

                <!-- app_type Column -->
                <ng-container matColumnDef="app_type">
                  <mat-header-cell *matHeaderCellDef> app type </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{element.app_type}}
                  </mat-cell>
                </ng-container>

                <!-- monthPaid Column -->
                <ng-container matColumnDef="monthPaid">
                  <mat-header-cell *matHeaderCellDef> month Paid </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{element.paid_on | date:"yyyy-MM-dd"}}
                  </mat-cell>
                </ng-container>

                <!-- subscriptionCycle Column -->
                <ng-container matColumnDef="subscriptionCycle">
                  <mat-header-cell *matHeaderCellDef> subscription Cycle </mat-header-cell>
                  <mat-cell *matCellDef="let element" [innerHtml]="subCycle(element.subscription_cycle)">

                  </mat-cell>
                </ng-container>

                <!-- hire_licenses_cost Column -->
                <ng-container matColumnDef="hire_licenses_cost">
                  <mat-header-cell *matHeaderCellDef> Hire licence Total cost </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{element.hire_licenses_cost ? ((element.hire_licenses_cost | number) | currency) : '-'}}
                    <span *ngIf="element.hire_licenses_cost">
                      ( {{ element.hire_licenses_cnt ? element.hire_licenses_cnt : ''}} *
                      {{ element.hire_cost_per_license ?( (element.hire_cost_per_license | number) | currency) : ''}}
                      )
                    </span>
                  </mat-cell>
                </ng-container>

                <!-- crm_licenses_cost Column -->
                <ng-container matColumnDef="crm_licenses_cost">
                  <mat-header-cell *matHeaderCellDef> crm licence Total cost </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{element.crm_licenses_cost ? ((element.crm_licenses_cost | number) | currency) : '-'}}
                    <span *ngIf="element.crm_licenses_cost">
                      (
                      {{element.crm_licenses_cnt ? element.crm_licenses_cnt : '-'}} *
                      {{element.crm_cost_per_license ? element.crm_cost_per_license :'-'}}
                      )
                    </span>
                  </mat-cell>
                </ng-container>

                <!-- hr_licenses_cost Column -->
                <ng-container matColumnDef="hr_licenses_cost">
                  <mat-header-cell *matHeaderCellDef> hr licence Total cost </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{element.hr_licenses_cost ? ((element.hr_licenses_cost | number) | currency) : '-'}}
                    <span *ngIf="element.hr_licenses_cost">
                      ( {{element.hr_licenses_cnt ? element.hr_licenses_cnt : '-'}} *
                      {{element.hr_cost_per_license ? ((element.hr_cost_per_license | number) | currency) : '-'}} )
                    </span>
                  </mat-cell>
                </ng-container>
                <!-- massmails Column -->
                <ng-container matColumnDef="massmails">
                  <mat-header-cell *matHeaderCellDef> mass mails</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{element.mass_mails_licenses_cnt ? element.mass_mails_licenses_cnt : '-'}}
                    <span *ngIf="element.mass_mails_licenses_cost">
                      (
                      {{element.mass_mails_licenses_cost ? ((element.mass_mails_licenses_cost | number) | currency) : '-'}}
                      )
                    </span>
                  </mat-cell>
                </ng-container>

                <!-- accountcontacts Column -->
                <ng-container matColumnDef="accountcontacts">
                  <mat-header-cell *matHeaderCellDef> account contacts</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{element.account_contacts_licenses_cnt ? element.account_contacts_licenses_cnt : '-'}}
                    <span *ngIf="element.account_contacts_licenses_cost">
                      (
                      {{element.account_contacts_licenses_cost ? ((element.account_contacts_licenses_cost | number) | currency) : '-'}}
                      )
                    </span>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="total_cost">
                  <mat-header-cell *matHeaderCellDef> Total Cost</mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    ${{ element.total_cost }}
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="revenuedisplayedColumns;"></mat-header-row>
                <mat-row *matRowDef="let row; columns: revenuedisplayedColumns;"></mat-row>
              </mat-table>
            </div>
            <div *ngIf="revenueDataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>