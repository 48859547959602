<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold active">Log Activities</a></li>
    </ul>
  </mat-toolbar-row>
  <div class="center px-20">
    <div class="content-card mt-12 mb-56">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
        <div fxFlex="20">
          <form [formGroup]="company">
            <mat-form-field class="w-100-p" appearance="outline">
              <mat-label>Select Company</mat-label>
              <mat-select appMatSearchSelector class="w-100-p" formControlName="toppings" multiple>
                <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                <ng-container *ngFor="let topping of toppingList">
                  <mat-option *appMatSearchSelectorItems [value]="topping.id">{{topping.display_name}}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
        <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
            [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">
              <!-- sno Column -->
              <ng-container matColumnDef="sno">
                <mat-header-cell *matHeaderCellDef fxFlex="100px"> Sno </mat-header-cell>
                <mat-cell *matCellDef="let element;index as i" fxFlex="100px">{{i+1}}
                </mat-cell>
              </ng-container>
              <!-- company Column -->
              <ng-container matColumnDef="company">
                <mat-header-cell *matHeaderCellDef fxFlex="200px"> company </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="200px">
                  {{cronjob_id == 1 ? element.display_name : (cronjob_id == 10?getCompayName(element.company_id):element.company_name)}}
                </mat-cell>
              </ng-container>

              <!-- description Column -->
              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef fxFlex> description </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex>
                  <span class="text-truncate">
                    {{cronjob_id == 1 ? 'Clean up completed' : element.description}}</span>
                </mat-cell>
              </ng-container>

              <!-- createdon Column -->
              <ng-container matColumnDef="createdon">
                <mat-header-cell *matHeaderCellDef fxFlex="200px"> created on </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="200px">
                  {{cronjob_id == 1 ? (element.createdat | date:"MM/dd/yyyy, h:mm a") : (element.created_at | date:"MM/dd/yyyy, h:mm a")}}
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="heading m-0 py-16 border">No Records Found</h2>
            </div>
          </div>
        </ng-container>
        <div class="footer">
          <button mat-button (click)="back();">Back</button>
        </div>
      </div>
    </div>
  </div>
</div>