import { NgModule } from '@angular/core';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { CommonAllModule } from 'app/common-all/common-all.module';
import { MatSearchSelectorModule } from 'app/mat-search-selector/mat-search-selector.module';
import { CKEditorModule } from 'ckeditor4-angular';
// import { CustomMinDirective } from '../signup-request/custom-min-validator.directive';
import { AddCustomerBalanceComponent } from './add-customer-balance/add-customer-balance.component';
import { AddTextRecruitDialogComponent } from './add-text-recruit-dialog/add-text-recruit-dialog.component';
import { AddGstDialogComponent } from './billing-details/add-gst-dialog/add-gst-dialog.component';
import { BillingDetailsComponent } from './billing-details/billing-details.component';

import { CompanyDetailsRoutingModule } from './company-details-routing.module';
import { CompanyDetailsComponent } from './company-details.component';
import { ConfirmExitRequestApproveComponent } from './confirm-exit-request-approve/confirm-exit-request-approve.component';
import { ConvertFreeLicensesToPaidComponent } from './convert-free-licenses-to-paid/convert-free-licenses-to-paid.component';
import { CronEmailCleanupComponent } from './cron-email-cleanup/cron-email-cleanup.component';
import { CustomConfirmDialogComponent } from './custom-confirm-dialog/custom-confirm-dialog.component';
import { DataBackupComponent } from './data-backup/data-backup.component';
import { DataMigrationComponent } from './data-migration/data-migration.component';
import { DetailUserEditDialogComponent } from './detail-user-edit-dialog/detail-user-edit-dialog.component';
import { DetailUserComponent } from './detail-user/detail-user.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { ImplementationActivitiesComponent } from './implementation-activities/implementation-activities.component';
import { IntegrationControlsComponent } from './integration-controls/integration-controls.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { MapFieldsComponent } from './map-fields/map-fields.component';
import { MassMailsSplitComponent } from './mass-mails-split/mass-mails-split.component';
import { MigrationFtpFilesComponent } from './migration-ftp-files/migration-ftp-files.component';
import { MigrationsComponent } from './migrations/migrations.component';
import { MxRecordsComponent } from './mx-records/mx-records.component';
import { NumericDirective } from './numeric.directive';
import { PartnerSearchComponent } from './partner-search/partner-search.component';
import { PurchasedAddonTypeListComponent } from './purchased-addon-type-list/purchased-addon-type-list.component';
import { AddRefundComponent } from './refunds/add-refund/add-refund.component';
import { RefundsComponent } from './refunds/refunds.component';
import { RemoveFreeLicensesComponent } from './remove-free-licenses/remove-free-licenses.component';
import { RenewSubscriptionComponent } from './renew-subscription/renew-subscription.component';
import { RenewalRemainderDialogComponent } from './renewal-remainder-dialog/renewal-remainder-dialog.component';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';
import { SearchCreditDialogComponent } from './search-credit-dialog/search-credit-dialog.component';
import { SendMailDialogueComponent } from './send-mail-dialogue/send-mail-dialogue.component';
import { AddAddonsComponent } from './subscription/add-addons/add-addons.component';
import { ChangePlanComponent } from './subscription/change-plan/change-plan.component';
import { CustomMinDirective } from './subscription/custom-min-validator.directive';
import { PlanInfoComponent } from './subscription/plan-info/plan-info.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TextRecruitComponent } from './text-recruit/text-recruit.component';
import { TrainingsComponent } from './trainings/trainings.component';
import { UpdateCompanyinfoDialogComponent } from './update-companyinfo-dialog/update-companyinfo-dialog.component';
import { UpdatePaymentDetailsComponent } from './update-payment-details/update-payment-details.component';
import { UserLicencesDialogComponent } from './user-licences-dialog/user-licences-dialog.component';
import { UploadInvoicePdfComponent } from './invoices/upload-invoice-pdf/upload-invoice-pdf.component';
import { SubscriptionPlanComponent } from './subscription/subscription-plan/subscription-plan.component';
import { InvoiceLineItemsComponent } from './invoice-line-items/invoice-line-items.component';
import { AddFreeCreditsDialogComponent } from './add-free-credits-dialog/add-free-credits-dialog.component';
import { FromEmailsComponent } from './from-emails/from-emails.component';
import { AddFromMailComponent } from './from-emails/add-from-mail/add-from-mail.component';

@NgModule({
  declarations: [
    CompanyDetailsComponent,
    BillingDetailsComponent,
    SubscriptionComponent,
    InvoicesComponent,
    DetailUserComponent,
    TextRecruitComponent,
    MxRecordsComponent,
    RenewSubscriptionComponent,
    IntegrationControlsComponent,
    CronEmailCleanupComponent,
    PartnerSearchComponent,
    RefundsComponent,
    DataBackupComponent,
    MigrationsComponent,
    PlanInfoComponent,
    MigrationFtpFilesComponent,
    ImplementationActivitiesComponent,
    ConfirmExitRequestApproveComponent,
    UserLicencesDialogComponent,
    SearchCreditDialogComponent,
    ResetPasswordDialogComponent,
    RemoveFreeLicensesComponent,
    AddRefundComponent,
    FilterDialogComponent,
    DetailUserEditDialogComponent,
    ConvertFreeLicensesToPaidComponent,
    AddGstDialogComponent,
    AddTextRecruitDialogComponent,
    MassMailsSplitComponent,
    AddCustomerBalanceComponent,
    RenewalRemainderDialogComponent,
    UpdateCompanyinfoDialogComponent,
    AddAddonsComponent,
    DataMigrationComponent,
    SendMailDialogueComponent,
    TrainingsComponent,
    MapFieldsComponent,
    ChangePlanComponent,
    UpdatePaymentDetailsComponent,
    CustomConfirmDialogComponent,
    CustomMinDirective,
    NumericDirective,
    PurchasedAddonTypeListComponent,
    UploadInvoicePdfComponent,
    SubscriptionPlanComponent,
    InvoiceLineItemsComponent,
    AddFreeCreditsDialogComponent,
    FromEmailsComponent,
    AddFromMailComponent
  ],
  imports: [
    CompanyDetailsRoutingModule,
    FuseSharedModule,
    MatSearchSelectorModule,
    CommonAllModule,
    CKEditorModule,
    FuseSidebarModule
  ]
})
export class CompanyDetailsModule { }
