import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { isEmpty } from 'lodash';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';
import { ConfigurationOptions, ContentOptionsEnum, SortOrderEnum, OutputOptionsEnum } from 'intl-input-phone';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {
  role = [
    { value: 1, viewValue: 'Admin' },
    { value: 2, viewValue: 'Support' },
    { value: 3, viewValue: 'Sales' },

  ];
  target_types = [
    { value: 1, viewValue: 'Monthly' },
    { value: 3, viewValue: 'Quarterly' },
    { value: 6, viewValue: 'Half-Yearly' },
    { value: 12, viewValue: 'Yearly' },
  ];
  form: FormGroup;
  addData = {};

  configOption3: ConfigurationOptions;
  NumberModel2: string = "+91";
  SelectedCountryISOCode: string = "IN";
  IsRequired: boolean = false;
  phoneNumber: any;
  phoneNumberExt: any;
  CountryISOCode: any;

  constructor(
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private commonService: CommonService,
    private configService: ConfigurationService
  ) {
    this.form = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      incentive_percentage:[''],
      incentive_target_type:[''],
      incentive_target_amount:[''],
      // extension: ['', Validators.required],
      mobile_number: [''],
    })

    if (isEmpty(this.data)) {
      this.form.addControl('password', new FormControl('', Validators.required));
      this.form.addControl('confirmPassword', new FormControl('', Validators.required));
    }

    this.configOption3 = new ConfigurationOptions();
    this.configOption3.SelectorClass = "OptionType3";
    this.configOption3.OptionTextTypes = [];
    this.configOption3.OptionTextTypes.push(ContentOptionsEnum.Flag);
    this.configOption3.OptionTextTypes.push(ContentOptionsEnum.CountryName);
    this.configOption3.OptionTextTypes.push(ContentOptionsEnum.CountryPhoneCode);
    this.configOption3.SortBy = SortOrderEnum.CountryPhoneCode;
    this.configOption3.OutputFormat = OutputOptionsEnum.Number;

  }

  ngOnInit() {
    this.setData();
  }
  setData() {
    if (!isEmpty(this.data)) {
      this.IsRequired = true;
      this.form.patchValue({
        firstName: this.data.first_name,
        lastName: this.data.last_name,
        email: this.data.email,
        role: this.data.cpanle_role.id,
        incentive_percentage: this.data.incentive_percentage,
        incentive_target_type: this.data.incentive_target_type,
        incentive_target_amount: this.data.incentive_target_amount,
        // extension: this.data.mobile_ext,
        // mobile_number: this.data.mobile,
      })
      this.SelectedCountryISOCode = this.data.country_iso_code;
      this.NumberModel2 = this.data.mobile_ext + ' ' + this.data.mobile;

    }
    else {
    }
  }

  save() {
    // console.log(this.form.value.mobile_number,this.IsRequired);return;
    if (this.form.valid && !isEmpty(this.data)) {

      if (this.IsRequired && !this.form.value.mobile_number) {
        this.phoneNumber = this.data.mobile;
        this.phoneNumberExt = this.data.mobile_ext;
        this.CountryISOCode = this.data.country_iso_code;

      } else {
        if (!this.IsRequired) {
          this.commonService.openSnackBar('warn', 'Please enter valid mobile number');
          return;
        }
        this.phoneNumber = this.form.value.mobile_number.Number;
        this.phoneNumberExt = this.form.value.mobile_number.CountryModel.CountryPhoneCode;
        this.CountryISOCode = this.form.value.mobile_number.CountryModel.ISOCode;
      }

      this.addData['id'] = this.data.id;
      this.addData['first_name'] = this.form.value.firstName;
      this.addData['last_name'] = this.form.value.lastName;
      this.addData['email'] = this.form.value.email;
      this.addData['cpanel_role_id'] = this.form.value.role;
      this.addData['mobile_ext'] = this.phoneNumberExt;
      this.addData['mobile'] = this.phoneNumber;
      this.addData['country_iso_code'] = this.CountryISOCode;

      this.addData['incentive_percentage'] = this.form.value.incentive_percentage;
      this.addData['incentive_target_type'] = this.form.value.incentive_target_type;
      this.addData['incentive_target_amount'] = this.form.value.incentive_target_amount;


      if (this.form.value.role === 1) {
        this.addData['cpanle_role'] = { id: 1, name: "admin" };
      }
      else if (this.form.value.role === 2) {
        this.addData['cpanle_role'] = { id: 2, name: "support" };
      }
      this.configService.saveUsers(this.addData).subscribe(res => {
        if (res.status === 1) {
          this.dialogRef.close(true);
          this.commonService.openSnackBar('primary', res.message);
        }
        else {
          let msg = res.message;
          if (typeof res.validations != 'undefined') {
            for (let field_name in res.validations) {
              msg += res.validations[field_name];
            }
          }
          this.commonService.openSnackBar('warn', msg);
        }
      })
    }
    else if (this.form.valid && isEmpty(this.data)) {

      if (!this.IsRequired) {
        this.commonService.openSnackBar('warn', 'Please enter valid mobile number');
        return;
      }

      this.addData['confirm_password'] = this.form.value.confirmPassword;
      this.addData['cpanel_role_id'] = this.form.value.role;
      this.addData['first_name'] = this.form.value.firstName;
      this.addData['last_name'] = this.form.value.lastName;
      this.addData['email'] = this.form.value.email;
      this.addData['password'] = this.form.value.password;
      this.addData['mobile_ext'] = this.form.value.mobile_number.CountryModel.CountryPhoneCode;
      this.addData['mobile'] = this.form.value.mobile_number.Number;
      this.addData['country_iso_code'] = this.form.value.mobile_number.CountryModel.ISOCode;

      this.addData['incentive_percentage'] = this.form.value.incentive_percentage;
      this.addData['incentive_target_type'] = this.form.value.incentive_target_type;
      this.addData['incentive_target_amount'] = this.form.value.incentive_target_amount;



      if (this.addData['password'] === this.addData['confirm_password']) {
        this.configService.saveUsers(this.addData).subscribe(res => {
          if (res.status === 1) {
            this.dialogRef.close(true);
            this.commonService.openSnackBar('primary', res.message);
          }
          else {
            let msg = res.message;
            if (typeof res.validations != 'undefined') {
              for (let field_name in res.validations) {
                msg += '<P>' + res.validations[field_name] + '</P>';
              }
            }
            this.commonService.openSnackBar('warn', msg);
          }
        })
      }
      else {
        this.commonService.openSnackBar('warn', 'Confirm Password and Password must be match');
      }
    }
    else {
      this.form.get('firstName').markAsTouched();
      this.form.get('lastName').markAsTouched();
      this.form.get('role').markAsTouched();
      this.form.get('email').markAsTouched();
      // this.form.get('extension').markAsTouched();
      // this.form.get('mobile_number').markAsTouched();
      if (isEmpty(this.data)) {
        this.form.get('password').markAsTouched();
        this.form.get('confirmPassword').markAsTouched();
      }
    }
  }

  requiredFlagChange(isRequire: boolean) {
    this.IsRequired = isRequire;
  }
}
