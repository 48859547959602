import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CommonModuleDialogActivityComponent } from 'app/common-all/common-module-dialog-activity/common-module-dialog-activity.component';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-trial-plan',
  templateUrl: './trial-plan.component.html',
  styleUrls: ['./trial-plan.component.scss']
})

export class TrialPlanComponent implements OnInit {

  coutries = [
    { value: 1, viewValue: 'USA ($)' },
    { value: 2, viewValue: 'India (₹)' },
    { value: 702, viewValue: 'Singapore (S$)' },
  ];

  packages = [
    { value: 'hire', viewValue: 'ATS' },
    { value: 'hr', viewValue: 'HR' },
    { value: 'crm', viewValue: 'CRM' },
  ];

  selected_package_val: any = 'trial';
  selected_package: any = 'hire';

  @ViewChild('subscribeForm') subscribeForm: FormGroupDirective;

  trialMode: FormGroup;
  addons: any;
  grace_period: any;
  trial_days: any;
  selected_country: number = 1;
  params = { country_id: this.selected_country, package: this.selected_package, plan: this.selected_package_val };

  constructor(
    private commonService: CommonService,
    private configService: ConfigurationService,
    private fb: FormBuilder, public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.getAddons();
  }

  getAddons() {
    this.configService.getTrialPlanAddons(this.params).subscribe(res => {

      if (res.status == 1) {
        this.addons = res.data.addonData;

        this.addons.map(item => {

          if (item.quantity == '' || item.quantity == null) {

            if (item.slug == 'hire_users' || item.slug == 'hr_users' || item.slug == 'crm_users')
              item.quantity = '2';
            else if (item.slug == 'partner_login')
              item.quantity = '1';
            else
              item.quantity = '100';

          } else {
            item.quantity = item.quantity;
          }

        });
      }
    });
  }

  countryChanged() {
    this.params.country_id = this.selected_country;
    this.getAddons();
  }

  productChanged() {
    // if (this.selected_package_val == 'hr_trial') {
    //   this.selected_package = 'hr';
    // } else if (this.selected_package_val == 'crm_trial') {
    //   this.selected_package = 'crm';
    // } else {
    //   this.selected_package = 'hire';
    // }
    this.params.package = this.selected_package;
    this.params.plan = this.selected_package_val;
    this.getAddons();
  }

  saveTrailMode() {
    // console.log(this.addons);
    // return false; 

    if (this.subscribeForm.valid) {
      const data = [];
      data['title'] = 'Alert';
      data['message'] = 'Are you sure? You want to update';
      data['successText'] = 'Yes';
      data['closeText'] = 'No';
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '35%',
        data: data
      });

      dialogRef.afterClosed().subscribe(result => {

        if (result) {
          const preparedData = {};
          preparedData['package'] = this.selected_package;
          preparedData['plan'] = this.selected_package_val;
          preparedData['addons'] = this.addons;
          preparedData['country_id'] = this.selected_country;

          this.configService.updateTrialData(preparedData).subscribe(res => {
            if (res.status == 1) {
              this.commonService.openSnackBar('primary', res.message);
              this.getAddons();
            } else {
              this.commonService.openSnackBar('warn', res.message);
            }
          });
        } else { }
      })
    } else {
      this.commonService.openSnackBar('warn', 'Please fill the required fields');
      return false;
    }
  }

  showActivities() {
    let activityData = {
      'company_id': 0,
      'module_slug': 'trialMode',
      'show_company_name': false,
      'module_name': 'Trial Credits'
    }
    const dialogRef = this.dialog.open(CommonModuleDialogActivityComponent, {
      width: '80%',
      data: activityData
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
