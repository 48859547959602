import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ConfigurationService } from 'app/services/configuration.service';

@Component({
  selector: 'app-cron-job-log-activities',
  templateUrl: './cron-job-log-activities.component.html',
  styleUrls: ['./cron-job-log-activities.component.scss']
})
export class CronJobLogActivitiesComponent implements OnInit {
  displayedColumns: string[] = ['sno', 'company', 'description', 'createdon'];
  dataSource;
  length: any;
  pageSize: any;
  getTableData: any;
  index: any;
  cronjob_id: number;
  toppingList: any;
  company: FormGroup;
  companyData = [];
  uniqueArray: any[];
  params: { cronjob_id: number; limit: number; page: number; companies_data: any[]; };
  mailParams: { companies_data: any[]; company_id: string; limit: number; page: number; view_status: string; };
  constructor(
    private commonservice: CommonService,
    private configService: ConfigurationService,
    private route: ActivatedRoute,
    private _location: Location,
    private fb: FormBuilder
  ) {
    this.route.params.subscribe(res => {
      if (res['id']) {
        this.cronjob_id = res['id'];
      }
    })
    this.company = this.fb.group({
      toppings: ['']
    })
    this.company.valueChanges.subscribe(res => {
      if (res) {
        if (res.toppings.length > 0) {
          this.companyData = [];
          this.toppingList.filter(filt_data => {
            res.toppings.filter(val => {
              if (filt_data.id === val) {
                this.companyData.push({ id: filt_data.id, name: filt_data.display_name });
              }
            })
          })
        }
        else {
          this.companyData = [];
        }
        if (this.cronjob_id == 1) {
          this.mailParams['companies_data'] = this.companyData;
        }
        else {
          this.params['companies_data'] = this.companyData;
        }
        this.getData();
      }
    })
  }

  ngOnInit() {
    this.params = { cronjob_id: this.cronjob_id, limit: 15, page: 1, companies_data: this.companyData };
    this.mailParams = { companies_data: [], company_id: '', limit: 1, page: 1, view_status: 'completed' };
    this.getData();
    this.commonservice.companyList().subscribe(res => {
      if (res.status === 1) {this.toppingList = res.data;}
    });
  }
  pageEvent(event) {
    if (this.cronjob_id == 1) {
      this.mailParams.limit = event.pageSize;
      this.mailParams.page = event.pageIndex + 1;
    }
    else {
      this.params.limit = event.pageSize;
      this.params.page = event.pageIndex + 1;
    }
    this.getData();
  }
  getData() {
    if (this.cronjob_id == 1) {

      this.configService.getCronEmail(this.mailParams).subscribe(result => {
        if (result.status === 1) {
          this.getTableData = result.cron_emails;
          this.length = this.getTableData.total;
          this.pageSize = this.getTableData.per_page;
          this.index = this.getTableData.current_page;
          this.dataSource = new MatTableDataSource(this.getTableData.data);
        }
      })
    }
    else {
      this.configService.getlogList(this.params).subscribe(result => {
        if (result.status === 1) {
          this.getTableData = result.cron_job_logs;
          this.length = this.getTableData.total;
          this.pageSize = this.getTableData.per_page;
          this.index = this.getTableData.current_page;
          this.dataSource = new MatTableDataSource(this.getTableData.data);
        }
      })
    }
  }
  back() {
    this._location.back();
  }

  getCompayName(company_id) {
    let compannyName = '';
    this.toppingList.filter(filt_data => {
      if (filt_data.id == company_id) {
        compannyName = filt_data.display_name;
      }
    })
    return compannyName;
  }
}
