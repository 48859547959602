import { Component, OnInit, NgZone } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from 'app/services/common.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { ExcelService } from 'app/services/excel.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash';
import { environment } from 'environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

//revenuechartdata
const RevenueChartData = {
  chart: {
    caption: "Total Revenue Report",
    subcaption: "",
    xaxisname: "Month(s)",
    yaxisname: "Amount ( In USD )",
    numbersuffix: "",
    numberprefix: "$",
    showvalues: '1',
    theme: "fusion"
  },
  data: [
    { label: 'Jan', value: '', fullDate: '' }, { label: 'Feb', value: '', fullDate: '' }, { label: 'Mar', value: '', fullDate: '' },
    { label: 'Apr', value: '', fullDate: '' }, { label: 'May', value: '', fullDate: '' }, { label: 'Jun', value: '', fullDate: '' },
    { label: 'Jul', value: '', fullDate: '' }, { label: 'Aug', value: '', fullDate: '' }, { label: 'Sep', value: '', fullDate: '' },
    { label: 'Oct', value: '', fullDate: '' }, { label: 'Nov', value: '', fullDate: '' }, { label: 'Dec', value: '', fullDate: '' }
  ]
};

// signupvsclosureData
const signupclosureData = {
  chart: {
    showvalues: "1",
    caption: "Signup vs Closures",
    subcaption: "",
    numberprefix: "",
    numbersuffix: "",
    plottooltext: "$seriesName : <b>$dataValue</b>",
    showhovereffect: "1",
    yaxisname: "Count",
    showsum: "1",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        { label: "Jan" }, { label: "Feb" }, { label: "Mar" }, { label: "Apr" }, { label: "May" }, { label: "Jun" },
        { label: "Jul" }, { label: "Aug" }, { label: "Sep" }, { label: "Oct" }, { label: "Nov" }, { label: "Dec" }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "Signups",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    },
    {
      seriesname: "Closures",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    }
  ]
};


// Monthly susbscription chart
const monthlySubscriptionData = {
  chart: {
    showvalues: "1",
    caption: "Monthly Realized Revenue",
    subcaption: "",
    numberprefix: "",
    numbersuffix: "",
    plottooltext: "$seriesName : <b>$dataValue</b>",
    showhovereffect: "1",
    yaxisname: "($) Amount",
    showsum: "1",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        { label: "Jan" }, { label: "Feb" }, { label: "Mar" }, { label: "Apr" }, { label: "May" }, { label: "Jun" },
        { label: "Jul" }, { label: "Aug" }, { label: "Sep" }, { label: "Oct" }, { label: "Nov" }, { label: "Dec" }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "Licenses",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    },
    {
      seriesname: "Addons",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    }
  ]
};

// Refunds chart

const refundsData = {
  chart: {
    showvalues: "1",
    caption: "Refunds",
    subcaption: "",
    numberprefix: "",
    numbersuffix: "",
    plottooltext: "$seriesName : <b>$dataValue</b>",
    showhovereffect: "1",
    yaxisname: "($) Amount",
    showsum: "1",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        { label: "Jan" }, { label: "Feb" }, { label: "Mar" }, { label: "Apr" }, { label: "May" }, { label: "Jun" },
        { label: "Jul" }, { label: "Aug" }, { label: "Sep" }, { label: "Oct" }, { label: "Nov" }, { label: "Dec" }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "Refunds",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    }
  ]
};

// Refunds chart

const exitCustomersData = {
  chart: {
    showvalues: "1",
    caption: "Exit Customers",
    subcaption: "",
    numberprefix: "",
    numbersuffix: "",
    plottooltext: "$seriesName : <b>$dataValue</b>",
    showhovereffect: "1",
    yaxisname: "Customers",
    showsum: "1",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        { label: "Jan" }, { label: "Feb" }, { label: "Mar" }, { label: "Apr" }, { label: "May" }, { label: "Jun" },
        { label: "Jul" }, { label: "Aug" }, { label: "Sep" }, { label: "Oct" }, { label: "Nov" }, { label: "Dec" }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "ExitCustomers",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    }
  ]
};


// MRR
const MRRData = {
  chart: {
    showvalues: "1",
    caption: "MRR",
    subcaption: "",
    numberprefix: "",
    numbersuffix: "",
    plottooltext: "$seriesName : <b>$dataValue</b>",
    showhovereffect: "1",
    yaxisname: "Amount",
    showsum: "1",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        { label: "Jan" }, { label: "Feb" }, { label: "Mar" }, { label: "Apr" }, { label: "May" }, { label: "Jun" },
        { label: "Jul" }, { label: "Aug" }, { label: "Sep" }, { label: "Oct" }, { label: "Nov" }, { label: "Dec" }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "Licenses",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    },
    {
      seriesname: "Add-ons",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    },
    // {
    //   seriesname: "Account Contact Search",
    //   data: [
    //     { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
    //     { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
    //     { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
    //   ]
    // }
  ]
};

// MRR
const paymentModeWisePaymentsData = {
  chart: {
    showvalues: "1",
    caption: "Monthly Payments",
    subcaption: "",
    numberprefix: "",
    numbersuffix: "",
    plottooltext: "$seriesName : <b>$dataValue</b>",
    showhovereffect: "1",
    yaxisname: "Amount",
    showsum: "1",
    theme: "fusion"
  },
  categories: [
    {
      category: [
        { label: "Jan" }, { label: "Feb" }, { label: "Mar" }, { label: "Apr" }, { label: "May" }, { label: "Jun" },
        { label: "Jul" }, { label: "Aug" }, { label: "Sep" }, { label: "Oct" }, { label: "Nov" }, { label: "Dec" }
      ]
    }
  ],
  dataset: [
    {
      seriesname: "Card",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    },
    {
      seriesname: "Bank",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    },
    {
      seriesname: "Cheque",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    },
    {
      seriesname: "Offline",
      data: [
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' },
        { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }, { value: "", fullDate: '' }
      ]
    }
  ]
};


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  app_type = [
    { value: 'hire_users', viewValue: 'Hire' }, { value: 'hr_users', viewValue: 'HR' }, { value: 'crm_users', viewValue: 'CRM' },
    { value: 'mass_mails_credits', viewValue: 'Mass Mails' }, { value: 'contacts_search_credits', viewValue: 'Account Contact Search' },
  ];
  signvsclose = [
    { value: 'signup', viewValue: 'Signup' }, { value: 'closure', viewValue: 'Closures' }
  ]

  countries = [
    { value: '1', viewValue: 'USA' }, { value: '2', viewValue: 'India' }, { value: '702', viewValue: 'Singapore' }
  ]
  years = [];
  // years = [
  //   { value: 2022, viewValue: 2022 }, { value: 2021, viewValue: 2021 }, { value: 2020, viewValue: 2020 }, { value: 2019, viewValue: 2019 }, { value: 2018, viewValue: 2018 },
  // ];

  refund_types = [
    { value: 'wallet', viewValue: 'Wallet' }, { value: 'card', viewValue: 'Card' }
  ];

  subscriptionCycle = [
    { value: '', viewValue: 'All' }, { value: '1', viewValue: 'Monthly' }, { value: '3', viewValue: 'Quarterly' },
    { value: '6', viewValue: 'Half Yearly' }, { value: '12', viewValue: 'Yearly' },
  ]

  // ejs datepicker
  public today: Date = new Date(new Date().toDateString());
  public weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - (new Date().getDay() + 7) % 7)).toDateString());
  public weekEnd: Date = new Date(new Date(new Date().setDate(new Date(new Date().setDate((new Date().getDate()
    - (new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString())
    ;
  public monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
  public monthEnd: Date = this.today;
  public lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
  // public lastEnd: Date = this.today;
  public lastEnd: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
  public yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
  // public yearStart = moment().startOf('year');
  public yearEnd: Date = this.today;
  // public value: Date[] = [this.yearStart, this.yearEnd];
  // value: Date[];
  // ejs datepicker completed

  revenueChart: boolean = true;
  signupClosureChart: boolean = false;
  exitCustChart: boolean = true;
  paymentsDeclinedChart: boolean = true;
  toppingList: any;
  monthlySubscriptionChart: boolean = false;
  refundsChart: boolean = false;
  exitCustomersChart: boolean = false;
  MRRChart: boolean = true;
  paymentModeWisePaymentsChart: boolean = true;
  dashboard_permissions: any = {};
  more = {};
  signupClosureCountryId = '1';
  downloadMothlyPaymentURL: any;
  downloadMRRURL: any;

  // Refunds
  refundsTableCols: string[] = ['customer_name', 'invoiceRef', 'amount', 'reason', 'refund_date', 'rufund_to', 'actions'];
  refundsDataSource: any;
  refundsLength: number;
  refundspageSize: any;
  refundsindex: any;
  refundsSearchValue = '';
  refundsgetDateRange = {};
  refundsTableData: any;
  refundsForm: FormGroup;
  refundsParams: { limit: number; page: number; paid_on?: any; onboarded_on?: any; subscription_cycle?: any; app_type?: any; date_range?: any; client_ids?: any; search_key?: any; export_all?: any; refund_type?: any; country_id?: any; };

  // Exit Customers
  exitCustomersTableCols: string[] = ['customer_name', 'contact_name', 'contact_email', 'onboarded_on', 'exit_date', 'reason'];
  exitCustomersDataSource: any;
  exitCustomersLength: number;
  exitCustomerspageSize: any;
  exitCustomersindex: any;
  exitCustomersSearchValue = '';
  exitCustomersgetDateRange = {};
  exitCustomersTableData: any;
  exitCustomersForm: FormGroup;
  exitCustomersParams: { limit: number; page: number; search_key?: any; export_all?: any; country_id?: any; date_range?: any; };

  // revenue
  monthlySubscriptionTableCols: string[] = ['customerName', 'monthPaid', 'subscriptionCycle', 'hire_licenses_cost', 'hr_licenses_cost', 'crm_licenses_cost', 'total_cost'];
  monthlySubscriptionDataSource: any;
  monthlySubscriptionLength: number;
  monthlySubscriptionpageSize: any;
  monthlySubscriptionindex: any;
  monthlySubscriptionSearchValue = '';
  monthlySubscriptiongetDateRange = {};
  monthlySubscriptionTableData: any;
  monthlySubscriptionForm: FormGroup;

  monthlySubscriptionParams: { limit: number; page: number; paid_on?: any; onboarded_on?: any; subscription_cycle?: any; app_type?: any; date_range?: any; client_ids?: any; search_key?: any; export_all?: any; country_id?: any };

  // revenue
  revenuedisplayedColumns: string[] = ['customerName', 'monthPaid', 'subscriptionCycle', 'hire_licenses_cost', 'hr_licenses_cost', 'crm_licenses_cost', 'total_cost'];
  revenueDataSource: any;
  revenueLength: number;
  revenuepageSize: any;
  revenueindex: any;
  revenueSearchValue = '';
  revenuegetDateRange = {};
  revenueTableData: any;
  revenueForm: FormGroup;

  params: { limit: number; page: number; paid_on?: any; onboarded_on?: any; subscription_cycle?: any; app_type?: any; date_range?: any; client_ids?: any; search_key?: any; export_all?: any };

  exitCustparams: { limit: number; page: number; paid_on?: any; onboarded_on?: any; subscription_cycle?: any; app_type?: any; date_range?: any; client_ids?: any; search_key?: any };

  signupsparams: { limit: number; page: number; paid_on?: any; onboarded_on?: any; subscription_cycle?: any; app_type?: any; date_range?: any; client_ids?: any; search_key?: any; country_id?: any };

  closuresparams: { limit: number; page: number; paid_on?: any; onboarded_on?: any; subscription_cycle?: any; app_type?: any; date_range?: any; client_ids?: any; search_key?: any; country_id?: any };

  paymentsDeclinedparams: { limit: number; page: number; paid_on?: any; onboarded_on?: any; subscription_cycle?: any; app_type?: any; date_range?: any; client_ids?: any; search_key?: any };
  //revenue
  //signups
  signupsdisplayedColumns: string[] = ['customerName', 'contact_name', 'email', 'sub_domain', 'contact_number', 'region', 'onboarded_date'];
  signupDatasource: any;
  signupsLength: any;
  signupspageSize: any;
  signupsindex: any;
  signupsSearchValue = '';
  signupsDateRange = {};
  signupsTableData: any;
  signupForm: FormGroup;
  //signups
  //closures
  closuresdisplayedColumns: string[] = ['customerName', 'onboarded_date', 'onboarded_on', 'subscriptionCycle', 'hire_licenses_cost', 'hr_licenses_cost', 'crm_licenses_cost'];
  closuresDatasource: any;
  closuresLength: any;
  closurespageSize: any;
  closuresindex: any;
  closuresSearchValue = '';
  closuresDateRange = {};
  closuresTableData: any;
  closuresForm: FormGroup;
  signVsClose: FormGroup;
  selectSign: boolean = true;
  //closures
  //exit customers
  exitCustdisplayedColumns: string[] = ['customerName', 'onboarded_date', 'hire_licenses_cost', 'hr_licenses_cost', 'crm_licenses_cost'];
  exitCustDataSource: any;
  exitCustLength: any;
  exitCustpageSize: any;
  exitCustindex: any;
  exitCustSearchValue = '';
  exitCustTableData: any;
  exitCustgetDateRange = {};
  exitCustForm: FormGroup;
  //exit customers
  //paymentsDeclined
  paymentsDeclineddisplayedColumns: string[] = ['customerName', 'onboarded_date', 'hire_licenses_cost', 'hr_licenses_cost', 'crm_licenses_cost'];
  paymentsDeclinedDataSource: any;
  paymentsDeclinedLength: any;
  paymentDeclinedpageSize: any;
  paymentDeclinedindex: any;
  paymentsDeclinedSearchValue = '';
  paymentsDeclinedgetDateRange = {};
  paymentsDeclinedTableData: any;
  paymentsDeclinedForm: FormGroup;
  //paymentsDeclined
  //chart monthly revenue report
  monthRevnueReportData: any;
  chartRevnueYearRange = {};
  chartRevenueparams: { date_range?: any, app_type?: any };
  chartTable: boolean = false;
  chartYearForm: FormGroup;
  //chart monthly revenue report
  //signupvs closure report
  signupClosureChartDataSource: any;
  signupClosureYearForm: FormGroup;
  signupClosureYearRange = {};
  signupClosureyearParams: { date_range?: any, country_id?: any };
  signupClosurechartTable: boolean = false;
  showSectionMonth: any;
  //signupvs closure report

  //monthly subscription chart report
  monthlySubscriptionChartDataSource: any;
  monthlySubscriptionYearForm: FormGroup;
  monthlySubscriptionYearRange = {};
  monthlySubscriptionyearParams: { date_range?: any, country_id?: any };
  monthlySubscriptionchartTable: boolean = false;
  //monthly subscription chart report


  //Refund chart report
  refundsChartDataSource: any;
  refundsYearForm: FormGroup;
  refundsYearRange = {};
  refundsyearParams: { date_range?: any, country_id?: any };
  refundschartTable: boolean = false;
  //Refund chart report

  //exitCustomers chart report
  exitCustomersChartDataSource: any;
  exitCustomersYearForm: FormGroup;
  exitCustomersYearRange = {};
  exitCustomersyearParams: { date_range?: any, country_id?: any };
  exitCustomerschartTable: boolean = false;
  //exitCustomers chart report

  //MRR chart report
  MRRChartDataSource: any;
  MRRYearForm: FormGroup;
  MRRYearRange = {};
  MRRyearParams: { date_range?: any, country_id?: any };
  MRRchartTable: boolean = false;
  //MRR chart report

  // chart report
  paymentModeWisePaymentsChartDataSource: any;
  paymentModeWisePaymentsYearForm: FormGroup;
  paymentModeWisePaymentsYearRange = {};
  paymentModeWisePaymentsyearParams: { date_range?: any, country_id?: any };
  paymentModeWisePaymentschartTable: boolean = false;
  //MRR chart report

  constructor(private commonService: CommonService, private zone: NgZone,
    private fb: FormBuilder, private excelService: ExcelService, private datepipe: DatePipe, private router: Router) {

    let startYear = new Date('2018').getFullYear();
    let currentYear = new Date().getFullYear()
    for (let year = startYear; year <= currentYear; year++) {
      this.years.push({ value: year, viewValue: year });
    }
    // console.log(this.years);
    // Refunds revenue report
    this.refundsForm = this.fb.group({
      // subscription_cycle: [''],
      // app_type: [''],
      refund_type: [''],
      customer: [''],
      // dateRange: [''],
      start_date: [''],
      end_date: [''],
      country_id: ['1']
    });
    this.refundsForm.valueChanges.pipe(debounceTime(0), distinctUntilChanged()).subscribe(res => {

      if (res && res.customer != undefined) {
        this.prepareRefundsParams(res);
      }
      if (res && res.customer == null) {
        this.prepareRefundsParams(res);
      }
    })
    // Refunds revenue report

    // exit customers report
    this.exitCustomersForm = this.fb.group({
      country_id: ['1']
    });
    this.exitCustomersForm.valueChanges.subscribe(res => {
      this.prepareExitCustomersParams(res);
    })
    // exit customers report

    // Monthly subscription revenue report
    this.monthlySubscriptionForm = this.fb.group({
      subscription_cycle: [''],
      app_type: [''],
      customer: [''],
      // dateRange: [''],
      start_date: [''],
      end_date: [''],
      country_id: ['1']
    });
    this.monthlySubscriptionForm.valueChanges.pipe(debounceTime(0), distinctUntilChanged()).subscribe(res => {

      if (res && res.customer != 'undefined') {
        this.prepareMonthlySubscriptionParams(res);
      }
    })

    // Monthly Subscription revenue report

    // Monthly revenue report
    this.revenueForm = this.fb.group({
      subscription_cycle: [''],
      app_type: [''],
      customer: [''],
      dateRange: ['']
    })
    this.revenueForm.valueChanges.subscribe(res => {
      if (res) {

        this.params.subscription_cycle = res.subscription_cycle ? res.subscription_cycle : '';
        this.params.app_type = res.app_type ? res.app_type.map(x => x).join(",") : '';
        this.params.client_ids = res.customer ? res.customer.map(x => x).join(",") : '';
        if (res.dateRange) {
          this.revenuegetDateRange['startDate'] = moment(res.dateRange[0]).format("YYYY-MM-DD");
          this.revenuegetDateRange['endDate'] = moment(res.dateRange[1]).format("YYYY-MM-DD");
          this.params.date_range = this.revenuegetDateRange ? JSON.stringify(this.revenuegetDateRange) : '';
        }
        else {
          this.params.date_range = '';
        }
        this.params.page = 1;
        this.getmonthlyRevenueReport();
      }
    })
    // Monthly revenue report

    // signup
    this.signupForm = this.fb.group({
      // paid_on: [''],
      // onboarded_on: [''],
      // subscription_cycle: [''],
      // app_type: [''],
      // customer: [''],
      // dateRange: ['']
      start_date: [''],
      end_date: ['']
    })
    this.signupForm.valueChanges.pipe(debounceTime(0), distinctUntilChanged()).subscribe(res => {
      if (res) {
        // this.signupsparams.paid_on = res.paid_on ? moment(res.paid_on).format("YYYY-MM-DD") : '';
        this.signupsparams.onboarded_on = res.onboarded_on ? moment(res.onboarded_on).format("YYYY-MM-DD") : '';
        // this.signupsparams.subscription_cycle = res.subscription_cycle ? res.subscription_cycle : '';
        this.signupsparams.app_type = res.app_type ? res.app_type.map(x => x).join(",") : '';
        this.signupsparams.client_ids = res.customer ? res.customer.map(x => x).join(",") : '';
        if (res.start_date && res.end_date) {
          this.signupsDateRange['startDate'] = moment(res.start_date).format("YYYY-MM-DD");
          this.signupsDateRange['endDate'] = moment(res.end_date).format("YYYY-MM-DD");
          this.signupsparams.date_range = this.signupsDateRange ? JSON.stringify(this.signupsDateRange) : '';
        }
        else {
          this.signupsparams.date_range = '';
        }
        this.signupsparams.page = 1;
        this.signupsparams.country_id = this.signupClosureCountryId;
        this.getSignupData();
      }
    })
    // signup
    // closures
    this.closuresForm = this.fb.group({
      // paid_on: [''],
      // onboarded_on: [''],
      subscription_cycle: [''],
      app_type: [''],
      // customer: [''],
      // dateRange: ['']
      start_date: [''],
      end_date: ['']
    })
    this.closuresForm.valueChanges.pipe(debounceTime(0), distinctUntilChanged()).subscribe(res => {
      if (res) {
        this.closuresparams.paid_on = res.paid_on ? moment(res.paid_on).format("YYYY-MM-DD") : '';
        this.closuresparams.onboarded_on = res.onboarded_on ? moment(res.onboarded_on).format("YYYY-MM-DD") : '';
        this.closuresparams.subscription_cycle = res.subscription_cycle ? res.subscription_cycle : '';
        this.closuresparams.app_type = res.app_type ? res.app_type.map(x => x).join(",") : '';
        this.closuresparams.client_ids = res.customer ? res.customer.map(x => x).join(",") : '';
        if (res.start_date && res.end_date) {
          this.closuresDateRange['startDate'] = moment(res.start_date).format("YYYY-MM-DD");
          this.closuresDateRange['endDate'] = moment(res.end_date).format("YYYY-MM-DD");
          this.closuresparams.date_range = this.closuresDateRange ? JSON.stringify(this.closuresDateRange) : '';
        }
        else {
          this.closuresparams.date_range = '';
        }
        this.closuresparams.page = 1;
        this.closuresparams.country_id = this.signupClosureCountryId;
        this.getClosuresData();
      }
    })

    this.signVsClose = this.fb.group({
      signupvsclosure: [this.signvsclose[0].value]
    })

    this.signVsClose.valueChanges.subscribe(res => {
      if (res) {
        res.signupvsclosure == 'signup' ? this.selectSign = true : this.selectSign = false;

        if (this.selectSign) {
          this.getSignupData();
        } else {
          this.getClosuresData();
        }
      }
    })
    // closures

    //exit Cusomers
    this.exitCustForm = this.fb.group({
      // paid_on: [''],
      onboarded_on: [''],
      // subscription_cycle: [''],
      app_type: [''],
      // customer: [''],
      dateRange: ['']
    })
    this.exitCustForm.valueChanges.subscribe(res => {
      if (res) {
        // this.exitCustparams.paid_on = res.paid_on ? moment(res.paid_on).format("YYYY-MM-DD") : '';
        this.exitCustparams.onboarded_on = res.onboarded_on ? moment(res.onboarded_on).format("YYYY-MM-DD") : '';
        // this.exitCustparams.subscription_cycle = res.subscription_cycle ? res.subscription_cycle : '';
        this.exitCustparams.app_type = res.app_type ? res.app_type.map(x => x).join(",") : '';
        // this.exitCustparams.client_ids = res.customer ? res.customer.map(x => x).join(",") : '';
        if (res.dateRange) {
          this.exitCustgetDateRange['startDate'] = moment(res.dateRange[0]).format("YYYY-MM-DD");
          this.exitCustgetDateRange['endDate'] = moment(res.dateRange[1]).format("YYYY-MM-DD");
          this.exitCustparams.date_range = this.exitCustgetDateRange ? JSON.stringify(this.exitCustgetDateRange) : '';
        }
        else {
          this.exitCustparams.date_range = '';
        }
        this.exitCustparams.page = 1;
        this.getExitCustomers();
      }
    })
    //exit Cusomers
    //paymentsDeclined
    this.paymentsDeclinedForm = this.fb.group({
      // paid_on: [''],
      onboarded_on: [''],
      // subscription_cycle: [''],
      app_type: [''],
      customer: [''],
      dateRange: ['']
    })
    this.paymentsDeclinedForm.valueChanges.subscribe(res => {
      if (res) {
        // this.paymentsDeclinedparams.paid_on = res.paid_on ? moment(res.paid_on).format("YYYY-MM-DD") : '';
        this.paymentsDeclinedparams.onboarded_on = res.onboarded_on ? moment(res.onboarded_on).format("YYYY-MM-DD") : '';
        // this.paymentsDeclinedparams.subscription_cycle = res.subscription_cycle ? res.subscription_cycle : '';
        this.paymentsDeclinedparams.app_type = res.app_type ? res.app_type.map(x => x).join(",") : '';
        this.paymentsDeclinedparams.client_ids = res.customer ? res.customer.map(x => x).join(",") : '';
        if (res.dateRange) {
          this.paymentsDeclinedgetDateRange['startDate'] = moment(res.dateRange[0]).format("YYYY-MM-DD");
          this.paymentsDeclinedgetDateRange['endDate'] = moment(res.dateRange[1]).format("YYYY-MM-DD");
          this.paymentsDeclinedparams.date_range = this.paymentsDeclinedgetDateRange ? JSON.stringify(this.paymentsDeclinedgetDateRange) : '';
        }
        else {
          this.paymentsDeclinedparams.date_range = '';
        }
        this.paymentsDeclinedparams.page = 1;
        this.getPaymentDecliend();
      }
    })
    //paymentsDeclined
    // chart Year
    // let currentYear = this.years.filter(val => val.value == new Date().getFullYear());
    this.chartYearForm = this.fb.group({
      year: [currentYear],
      app_type: ['']
    })
    this.signupClosureYearForm = this.fb.group({
      year: [currentYear],
      country_id: ['1']
    })
    this.monthlySubscriptionYearForm = this.fb.group({
      year: [currentYear],
      country_id: ['1']
    })

    this.refundsYearForm = this.fb.group({
      year: [currentYear],
      country_id: ['1']
    })

    this.exitCustomersYearForm = this.fb.group({
      year: [currentYear],
      country_id: ['1']
    })

    this.MRRYearForm = this.fb.group({
      year: [currentYear],
      country_id: ['1']
    })

    this.paymentModeWisePaymentsYearForm = this.fb.group({
      year: [currentYear],
      country_id: ['1']
    })

    this.chartYearForm.valueChanges.subscribe(res => {
      if (res) {

        let yearStart = new Date(res.year, 0, 1);
        let yearEnd = new Date(res.year, 11, 31);
        this.chartRevnueYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
        this.chartRevnueYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
        this.chartRevenueparams.date_range = JSON.stringify(this.chartRevnueYearRange);
        this.chartRevenueparams.app_type = res.app_type ? res.app_type.map(x => x).join(",") : '';
        this.total_monthly_revenue();
      }
    })
    this.signupClosureYearForm.valueChanges.subscribe(res => {
      if (res) {

        let yearStart = new Date(res.year, 0, 1);
        let yearEnd = new Date(res.year, 11, 31);
        this.signupClosureYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
        this.signupClosureYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
        this.signupClosureyearParams.date_range = JSON.stringify(this.signupClosureYearRange);
        this.signupClosureyearParams.country_id = res.country_id;

        this.signupClosureReport();
      }
    })

    this.monthlySubscriptionYearForm.valueChanges.subscribe(res => {
      if (res) {

        let yearStart = new Date(res.year, 0, 1);
        let yearEnd = new Date(res.year, 11, 31);
        this.monthlySubscriptionYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
        this.monthlySubscriptionYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
        this.monthlySubscriptionyearParams.date_range = JSON.stringify(this.monthlySubscriptionYearRange);
        this.monthlySubscriptionyearParams.country_id = res.country_id;
        this.monthlySubscriptionChartData();
      }
    })

    this.refundsYearForm.valueChanges.subscribe(res => {
      if (res) {

        let yearStart = new Date(res.year, 0, 1);
        let yearEnd = new Date(res.year, 11, 31);
        this.refundsYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
        this.refundsYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
        this.refundsyearParams.date_range = JSON.stringify(this.refundsYearRange);
        this.refundsyearParams.country_id = res.country_id;
        this.refundsChartData();
      }
    })

    this.exitCustomersYearForm.valueChanges.subscribe(res => {
      if (res) {

        let yearStart = new Date(res.year, 0, 1);
        let yearEnd = new Date(res.year, 11, 31);
        this.exitCustomersYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
        this.exitCustomersYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
        this.exitCustomersyearParams.date_range = JSON.stringify(this.exitCustomersYearRange);
        this.exitCustomersyearParams.country_id = res.country_id;
        this.exitCustomersChartData();
      }
    })

    this.MRRYearForm.valueChanges.subscribe(res => {
      if (res) {

        let yearStart = new Date(res.year, 0, 1);
        let yearEnd = new Date(res.year, 11, 31);
        this.MRRYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
        this.MRRYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
        this.MRRyearParams.date_range = JSON.stringify(this.MRRYearRange);
        this.MRRyearParams.country_id = this.MRRYearForm.value.country_id;
        this.MRRChartData();
      }
    })

    this.paymentModeWisePaymentsYearForm.valueChanges.subscribe(res => {
      if (res) {

        let yearStart = new Date(res.year, 0, 1);
        let yearEnd = new Date(res.year, 11, 31);
        this.paymentModeWisePaymentsYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
        this.paymentModeWisePaymentsYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
        this.paymentModeWisePaymentsyearParams.date_range = JSON.stringify(this.paymentModeWisePaymentsYearRange);
        this.paymentModeWisePaymentsyearParams.country_id = this.paymentModeWisePaymentsYearForm.value.country_id;
        this.paymentModeWisePaymentsChartData();
      }
    })

  }

  ngOnInit() {

    this.getDashboardPermissions();
    this.monthlySubscriptionParams = { limit: 15, page: 1, country_id: 1 };
    this.refundsParams = { limit: 15, page: 1, country_id: 1 };
    this.exitCustomersParams = { limit: 15, page: 1, country_id: 1 };
    this.params = { limit: 15, page: 1 };
    this.signupsparams = { limit: 15, page: 1, country_id: 1 };
    this.closuresparams = { limit: 15, page: 1, country_id: 1 };
    this.exitCustparams = { limit: 15, page: 1 };
    this.paymentsDeclinedparams = { limit: 15, page: 1 };
    this.chartRevenueparams = {};
    this.signupClosureyearParams = {};
    this.monthlySubscriptionyearParams = { country_id: 1 };
    this.refundsyearParams = { country_id: 1 };
    this.exitCustomersyearParams = { country_id: 1 };
    this.MRRyearParams = {};
    this.paymentModeWisePaymentsyearParams = {};
    this.downloadMothlyPaymentURL = environment.apiUrl + 'downloadMonthlyPaymentsData';
    this.downloadMRRURL = environment.apiUrl + 'downloadMrr';

    // this.monthlySubscriptionRevenueReport();
    // this.refundsReport();
    // this.exitCustomersReport();
    // this.getmonthlyRevenueReport();
    // this.getSignupData();

    //charts
    let yearStart = new Date(new Date().getFullYear(), 0, 1);
    let yearEnd = new Date(new Date().getFullYear(), 11, 31);

    this.chartRevnueYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
    this.chartRevnueYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
    this.chartRevenueparams.date_range = JSON.stringify(this.chartRevnueYearRange);
    this.total_monthly_revenue();

    this.signupClosureYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
    this.signupClosureYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
    this.signupClosureyearParams.date_range = JSON.stringify(this.signupClosureYearRange);
    this.signupClosureReport();

    this.monthlySubscriptionYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
    this.monthlySubscriptionYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
    this.monthlySubscriptionyearParams.date_range = JSON.stringify(this.monthlySubscriptionYearRange);
    this.monthlySubscriptionChartData();

    this.refundsYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
    this.refundsYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
    this.refundsyearParams.date_range = JSON.stringify(this.refundsYearRange);
    this.refundsChartData();

    this.exitCustomersYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
    this.exitCustomersYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
    this.exitCustomersyearParams.date_range = JSON.stringify(this.exitCustomersYearRange);
    this.exitCustomersChartData();

    this.MRRYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
    this.MRRYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
    this.MRRyearParams.date_range = JSON.stringify(this.MRRYearRange);
    this.MRRChartData();

    this.paymentModeWisePaymentsYearRange['startDate'] = this.datepipe.transform(yearStart, 'yyyy-MM-dd');
    this.paymentModeWisePaymentsYearRange['endDate'] = this.datepipe.transform(yearEnd, 'yyyy-MM-dd');
    this.paymentModeWisePaymentsyearParams.date_range = JSON.stringify(this.paymentModeWisePaymentsYearRange);
    this.paymentModeWisePaymentsChartData();

    // getCompanyList
    this.commonService.getCompanyList({ comp_id: 1 }).subscribe(res => {
      if (res.status === 1) {
        this.toppingList = res.data;
      }
    })
  }

  getDashboardPermissions() {
    let permission_params = {};
    permission_params['module'] = 'dashboard';
    this.commonService.getModulePermissions(permission_params).subscribe(res => {

      if (res.status == 1) {
        this.dashboard_permissions = res.data.permissions;
      }
    })
  }


  open(event) {

  }
  applyFilter(val, section) {

    switch (section) {
      case 'monthlySubscription':
        this.monthlySubscriptionParams['search_key'] = val;
        this.monthlySubscriptionParams.page = 1;
        this.monthlySubscriptionRevenueReport();
        break;
      case 'refunds':
        this.refundsParams['search_key'] = val;
        this.refundsParams.page = 1;
        this.refundsReport();
        break;
      case 'exitCustomers':
        this.exitCustomersParams['search_key'] = val;
        this.exitCustomersParams.page = 1;
        this.exitCustomersReport();
        break;
      case 'monthlyRevenueReport':
        this.params['search_key'] = val;
        this.params['page'] = 1;
        this.getmonthlyRevenueReport();
        break;
      case 'signup':
        this.signupsparams['search_key'] = val;
        this.signupsparams['page'] = 1;
        this.getSignupData();
        break;
      case 'closures':
        this.closuresparams['search_key'] = val;
        this.closuresparams['page'] = 1;
        this.getClosuresData();
        break;
      case 'exitCustomers':
        this.exitCustparams['search_key'] = val;
        this.exitCustparams['page'] = 1;
        this.getExitCustomers();
        break;
      case 'paymentsDeclined':
        this.paymentsDeclinedparams['search_key'] = val;
        this.paymentsDeclinedparams['page'] = 1;
        this.getPaymentDecliend();
        break;
    }
  }
  pageEvent(event, section) {
    switch (section) {
      case 'monthlySubscription':
        this.monthlySubscriptionParams.limit = event.pageSize;
        this.monthlySubscriptionParams.page = event.pageIndex + 1;
        this.monthlySubscriptionRevenueReport();
        break;
      case 'refunds':
        this.refundsParams.limit = event.pageSize;
        this.refundsParams.page = event.pageIndex + 1;
        this.refundsReport();
        break;
      case 'exitCustomers':
        this.exitCustomersParams.limit = event.pageSize;
        this.exitCustomersParams.page = event.pageIndex + 1;
        this.exitCustomersReport();
        break;
      case 'monthlyRevenueReport':
        this.params.limit = event.pageSize;
        this.params.page = event.pageIndex + 1;
        this.getmonthlyRevenueReport();
        break;
      case 'signup':
        this.signupsparams.limit = event.pageSize;
        this.signupsparams.page = event.pageIndex + 1;
        this.getSignupData();
        break;
      case 'closures':
        this.closuresparams.limit = event.pageSize;
        this.closuresparams.page = event.pageIndex + 1;
        this.getClosuresData();
        break;
      // case 'exitCustomers':
      //   this.exitCustparams.limit = event.pageSize;
      //   this.exitCustparams.page = event.pageIndex + 1;
      //   this.getExitCustomers();
      //   break;
      case 'paymentsDeclined':
        this.paymentsDeclinedparams.limit = event.pageSize;
        this.paymentsDeclinedparams.page = event.pageIndex + 1;
        this.getPaymentDecliend();
        break;
    }
  }
  getmonthlyRevenueReport() {
    // this.commonService.revenueReport(this.params).subscribe(
    //   res => {
    //     if (res.status === 1) {
    //       this.revenueLength = res.data ? res.data.total : 0;
    //       this.revenuepageSize = res.data ? res.data.per_page : '15';
    //       this.revenueindex = res.data ? res.data.current_page : 1;
    //       this.revenueTableData = res.data ? res.data.data : '';
    //       this.revenueDataSource = new MatTableDataSource(this.revenueTableData);
    //     }
    //   })
  }

  monthlySubscriptionRevenueReport() {

    this.commonService.MonthlySubscriptionReport(this.monthlySubscriptionParams).subscribe(
      res => {
        if (res.status === 1) {
          this.monthlySubscriptionLength = res.data ? res.data.total : 0;
          this.monthlySubscriptionpageSize = res.data ? res.data.per_page : '15';
          this.monthlySubscriptionindex = res.data ? res.data.current_page : 1;
          this.monthlySubscriptionTableData = res.data ? res.data.data : '';
          this.monthlySubscriptionDataSource = new MatTableDataSource(this.monthlySubscriptionTableData);
        }
      })
    // () => this.commonService.getLoader())
  }

  refundsReport() {
    this.commonService.refundsReportData(this.refundsParams).subscribe(
      res => {
        if (res.status === 1) {

          this.refundsLength = res.data ? res.data.total : 0;
          this.refundspageSize = res.data ? res.data.per_page : '15';
          this.refundsindex = res.data ? res.data.current_page : 1;
          this.refundsTableData = res.data ? res.data.data : '';
          this.refundsDataSource = new MatTableDataSource(this.refundsTableData);
          res.data.data.filter((val, index) => {
            this.more[index] = false;
          })
        }
      })
    // () => this.commonService.getLoader())
  }

  exitCustomersReport() {
    this.commonService.exitCustomersListData(this.exitCustomersParams).subscribe(
      res => {
        if (res.status === 1) {
          this.exitCustomersLength = res.data ? res.data.total : 0;
          this.exitCustomerspageSize = res.data ? res.data.per_page : '15';
          this.exitCustomersindex = res.data ? res.data.current_page : 1;
          this.exitCustomersTableData = res.data ? res.data.data : '';
          this.exitCustomersDataSource = new MatTableDataSource(this.exitCustomersTableData);
          res.data.data.filter((val, index) => {
            this.more[index] = false;
          })
        }
      })
    // () => this.commonService.getLoader())
  }

  refresh(section) {
    switch (section) {
      case 'monthlySubscription':
        this.monthlySubscriptionSearchValue = '';
        this.monthlySubscriptionParams = { limit: 15, page: 1, country_id: 1 };
        this.monthlySubscriptionForm.reset();
        this.monthlySubscriptionForm.patchValue({ country_id: '1' });
        break;
      case 'refunds':
        this.refundsSearchValue = '';
        this.refundsParams = { limit: 15, page: 1, country_id: 1 };
        this.refundsForm.reset();
        this.refundsForm.patchValue({ country_id: '1' });
        // this.refundsReport();
        break;
      case 'exitCustomers':
        this.exitCustomersSearchValue = '';
        this.exitCustomersParams = { limit: 15, page: 1, country_id: 1 };
        this.exitCustomersForm.reset();
        this.exitCustomersForm.patchValue({ country_id: '1' });
        break;
      case 'monthlyRevenueReport':
        this.revenueSearchValue = '';
        this.params = { limit: 15, page: 1 };
        this.revenueForm.reset();
        break;
      // case 'exitCustomers':
      //   this.exitCustForm.reset();
      //   this.exitCustparams = { limit: 15, page: 1 };
      //   this.getExitCustomers();
      //   break;
      case 'signup':
        this.signupsSearchValue = '';
        this.signupsparams = { limit: 15, page: 1, country_id: 1 };
        this.signupForm.reset();
        this.closuresSearchValue = '';
        this.closuresparams = { limit: 15, page: 1, country_id: 1 };
        this.closuresForm.reset();
        break;
      case 'paymentsDeclined':
        this.paymentsDeclinedSearchValue = '';
        this.paymentsDeclinedparams = { limit: 15, page: 1 };
        this.paymentsDeclinedForm.reset();
    }
  }

  subCycle(element) {
    if (element === 1) {
      return 'Monthly';
    }
    else if (element === 3) {
      return 'Quarterly';
    }
    else if (element === 6) {
      return 'Half yearly';
    }
    else if (element === 12) {
      return 'Yearly';
    }
    else {
      return '-'
    }
  }
  getSignupData() {
    this.commonService.signups(this.signupsparams).subscribe(res => {
      if (res.status === 1) {
        this.signupsLength = res.data ? res.data.total : 0;
        this.signupspageSize = res.data ? res.data.per_page : '15';
        this.signupsindex = res.data ? res.data.current_page : 1;
        this.signupsTableData = res.data ? res.data.data : '';
        this.signupDatasource = new MatTableDataSource(this.signupsTableData);
      }
    })
  }
  getClosuresData() {
    this.commonService.closures(this.closuresparams).toPromise().then(res => {
      if (res.status === 1) {
        this.closuresLength = res.data ? res.data.total : 0;
        this.closurespageSize = res.data ? res.data.per_page : '15';
        this.closuresindex = res.data ? res.data.current_page : 1;
        this.closuresTableData = res.data ? res.data.data : '';
        this.closuresDatasource = new MatTableDataSource(this.closuresTableData);
      }
    })
  }
  // Exit customers
  getExitCustomers() {
    this.commonService.exit_customers(this.exitCustparams).subscribe(res => {
      if (res.status === 1) {
        this.exitCustLength = res.data ? res.data.total : 0;
        this.exitCustpageSize = res.data ? res.data.per_page : '15';
        this.exitCustindex = res.data ? res.data.current_page : 1;
        this.exitCustTableData = res.data ? res.data.data : '';
        this.exitCustDataSource = new MatTableDataSource(this.exitCustTableData);
      }
    })
  }
  //payments_decliened
  getPaymentDecliend() {
    this.commonService.payments_decliened(this.paymentsDeclinedparams).subscribe(res => {

      if (res.status === 1) {
        this.paymentsDeclinedLength = res.data ? res.data.total : 0;
        this.paymentDeclinedpageSize = res.data ? res.data.per_page : '15';
        this.paymentDeclinedindex = res.data ? res.data.current_page : 1;
        this.paymentsDeclinedTableData = res.data ? res.data.data : '';
        this.paymentsDeclinedDataSource = new MatTableDataSource(this.paymentsDeclinedTableData);
      }
    })
  }

  // export as excel data
  exportExcel(section) {
    switch (section) {

      case 'monthlySubscription':
        if (this.monthlySubscriptionLength) {
          this.commonService.MonthlySubscriptionReport(Object.assign({}, this.monthlySubscriptionParams, { export_all: 1 })).subscribe(res => {
            if (res) {
              let prepared_data = this.prepareExcelData(res.data, 'monthlySubscription');
              this.excelService.exportAsExcelFile(prepared_data, 'MonthlySubscription');
            }
          })
        }
        else {
          this.commonService.openSnackBar('warn', 'There are no records Found');
        }
        break;

      case 'refunds':
        if (this.refundsLength) {
          this.commonService.refundsReportData(Object.assign({}, this.refundsParams, { export_all: 1, limit: this.refundsLength })).subscribe(res => {
            if (res) {
              let prepared_data = this.prepareRefundsExcelData(res.data.data, 'refunds');
              this.excelService.exportAsExcelFile(prepared_data, 'refunds');
            }
          })
        }
        else {
          this.commonService.openSnackBar('warn', 'There are no records Found');
        }
        break;
      case 'exitCustomers':
        if (this.exitCustomersLength) {
          this.commonService.exitCustomersListData(Object.assign({}, this.exitCustomersParams, { export_all: 1, limit: this.exitCustomersLength })).subscribe(res => {
            if (res) {
              let prepared_data = this.prepareExitCustomersExcelData(res.data.data, 'exitCustomers');
              this.excelService.exportAsExcelFile(prepared_data, 'Exit Customers');
            }
          })
        }
        else {
          this.commonService.openSnackBar('warn', 'There are no records Found');
        }
        break;

      case 'monthlyRevenueReport':
        if (this.revenueLength) {
          this.commonService.revenueReport(Object.assign({}, this.params, { export_all: 1 })).subscribe(res => {
            if (res) {
              let prepared_data = this.prepareExcelData(res.data, 'monthlyRevenueReport');
              this.excelService.exportAsExcelFile(prepared_data, 'Monthly_Revenue_Report');
            }
          })
        }
        else {
          this.commonService.openSnackBar('warn', 'There are no records Found');
        }
        break;
      case 'signup':
        if (this.signupsLength) {
          this.commonService.signups(Object.assign({}, this.signupsparams, { export_all: 1 })).subscribe(res => {
            if (res.status === 1) {
              let prepared_data = this.prepareSignupExcelData(res.data, 'signup');
              this.excelService.exportAsExcelFile(prepared_data, 'signups');
            }
          })
        }
        else {
          this.commonService.openSnackBar('warn', 'There are no records Found');
        }
        break;
      case 'closures':
        if (this.closuresLength) {
          this.commonService.closures(Object.assign({}, this.closuresparams, { export_all: 1 })).subscribe(res => {
            if (res.status === 1) {
              let prepared_data = this.prepareExcelData(res.data, 'closures');
              this.excelService.exportAsExcelFile(prepared_data, 'closures');
            }
          })
        }
        else {
          this.commonService.openSnackBar('warn', 'There are no records Found');
        }
        break;
      case 'paymentsDeclined':
        if (this.paymentsDeclinedLength) {
          this.commonService.payments_decliened(Object.assign({}, this.paymentsDeclinedparams, { export_all: 1 })).subscribe(res => {
            if (res.status === 1) {
              let prepared_data = this.prepareExcelData(res.data.data, 'payment_declined');
              this.excelService.exportAsExcelFile(prepared_data, 'paymentsDeclined');
            }
          })
        }
        else {
          this.commonService.openSnackBar('warn', 'There are no records Found');
        }
        break;
      // case 'exitCustomers':
      //   if (this.exitCustLength) {
      //     this.commonService.exit_customers(Object.assign({}, this.exitCustparams, { export_all: 1 })).subscribe(res => {
      //       if (res.status === 1) {
      //         let prepared_data = this.prepareExcelData(res.data.data, 'exit_customers');
      //         this.excelService.exportAsExcelFile(prepared_data, 'exitCustomers');
      //       }
      //     })
      //   }
      //   else {
      //     this.commonService.openSnackBar('warn', 'There are no records Found');
      //   }
      //   break;
    }
  }

  prepareRefundsExcelData(data, section) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name'] = element.company_name;
      eachRow['Invoice Ref#'] = element.ref_id;
      eachRow['Amount ($)'] = element.amount ? element.amount : '-';
      eachRow['Refund To'] = element.refund_type ? element.refund_type : '-';
      eachRow['Reason'] = element.reason ? element.reason : '-';
      eachRow['Refund Date'] = moment(element.created_at).format("MM/DD/YYYY");
      ArrData.push(eachRow);
    });
    return ArrData;
  }

  prepareExitCustomersExcelData(data, section) {
    let ArrData = [];
    data.forEach(element => {
      let firstName = !isEmpty(element.client_info.first_name) ? element.client_info.first_name : '';
      let lastName = !isEmpty(element.client_info.last_name) ? element.client_info.last_name : '';
      let eachRow = {};
      eachRow['Customer'] = element.company_name;
      eachRow['Contact Name'] = firstName + ' ' + lastName;
      eachRow['Email'] = element.client_info.email ? element.client_info.email : '-';
      eachRow['Onboarded On'] = moment(element.onboarded_on).format("MM/DD/YYYY");
      eachRow['Exit Date'] = moment(element.exit_date).format("MM/DD/YYYY");
      eachRow['Reason'] = element.exit_comments
      ArrData.push(eachRow);
    });
    return ArrData;
  }

  prepareSignupExcelData(data, section) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name'] = element.company_name;
      eachRow['Signup on'] = moment(element.onboarded_date).format("MM/DD/YYYY");
      eachRow['HIRE Licences'] = element.hire_licenses_cnt ? element.hire_licenses_cnt : '-';
      eachRow['HR Licences'] = element.hr_licenses_cnt ? element.hr_licenses_cnt : '-';
      eachRow['CRM Licences'] = element.crm_licenses_cnt ? element.crm_licenses_cnt : '-';
      eachRow['Mass Mails'] = element.mass_mails_licenses_cnt ? element.mass_mails_licenses_cnt : '-';
      eachRow['Account Countacts'] = element.account_contacts_licenses_cnt ? element.account_contacts_licenses_cnt : '';
      eachRow['Subscription Type'] = element.subscription_type ? element.subscription_type.charAt(0).toUpperCase() + element.subscription_type.slice(1) : '';
      eachRow['Website'] = element.website ? element.website : '';
      ArrData.push(eachRow);
    });
    return ArrData;
  }

  prepareExcelData(data, section) {
    let ArrData = [];
    data.forEach(element => {
      let eachRow = {};
      eachRow['Customer Name'] = element.company_name;
      eachRow['App Type'] = element.app_type;
      if (section == 'monthlyRevenueReport' || section == 'monthlySubscription') {
        eachRow['Month Paid'] = moment(element.paid_on).format("MM/DD/YYYY");
      }
      if (section == 'monthlyRevenueReport' || section == 'closures' || section == 'monthlySubscription') {
        eachRow['Subscription Cycle'] = this.subCycle(element.subscription_cycle);
      }
      if (section == 'closures') {
        eachRow['Signup on'] = moment(element.onboarded_date).format("MM/DD/YYYY");
      }
      if (section == 'closures') {
        eachRow['Onboarded on'] = moment(element.paid_on).format("MM/DD/YYYY");
      }

      eachRow['HIRE Licences'] = element.hire_licenses_cnt ? element.hire_licenses_cnt : '-';
      // eachRow['$ Hire Cost Per Licence'] = element.hire_cost_per_license ? element.hire_cost_per_license : '-';
      eachRow['$ Hire Licence Cost'] = element.hire_licenses_cost ? element.hire_licenses_cost : '-';
      eachRow['HR Licences'] = element.hr_licenses_cnt ? element.hr_licenses_cnt : '-';
      // eachRow['$ HR Cost Per Licence'] = element.hr_cost_per_license ? element.hr_cost_per_license : '-';
      eachRow['$ HR Licence Cost'] = element.hr_licenses_cost ? element.hr_licenses_cost : '-';
      eachRow['CRM Licences'] = element.crm_licenses_cnt ? element.crm_licenses_cnt : '-';
      // eachRow['$ CRM Cost Per Licence'] = element.crm_cost_per_license ? element.crm_cost_per_license : '-';
      eachRow['$ CRM Licence Cost'] = element.crm_licenses_cost ? element.crm_licenses_cost : '-';
      eachRow['Mass Mails'] = element.mass_mails_licenses_cnt ? element.mass_mails_licenses_cnt : '-';
      eachRow['$ Mass Mails Cost'] = element.mass_mails_licenses_cost ? element.mass_mails_licenses_cost : '-';
      eachRow['Account Countacts'] = element.account_contacts_licenses_cnt ? element.account_contacts_licenses_cnt : '-';
      eachRow['$ Account Countacts Cost'] = element.account_contacts_licenses_cost ? element.account_contacts_licenses_cost : '-';
      eachRow['$ Total Cost'] = element.total_cost;
      ArrData.push(eachRow);
    });
    return ArrData;
  }
  total_monthly_revenue() {
    this.commonService.total_monthly_revenue(this.chartRevenueparams).subscribe(res => {
      if (res.status === 1) {
        RevenueChartData.data.forEach(data => {
          data.value = '';
          data.fullDate = '';
        });
        Object.entries(res.data).forEach(val => {
          const data = RevenueChartData.data.find(data => data.label === this.datepipe.transform(val[0], 'MMM'));
          data ? data.value = val[1].toString() : '';
          data ? data.fullDate = val[0] : '';
        });
        this.monthRevnueReportData = RevenueChartData;

      }
    })
  }
  // public ejsValue: Date[];
  showeRvenueTableData(e) {

    this.zone.run(() => {
      this.showSectionMonth = e.dataObj.categoryLabel;
      const getMonth = RevenueChartData.data.find(data => data.label === e.dataObj.categoryLabel);
      if (getMonth) {
        let dateSplit = getMonth.fullDate.split('-');
        let monthStart = new Date(Number(dateSplit[0]), Number(dateSplit[1]) - 1, 1);
        let monthEnd = new Date(Number(dateSplit[0]), Number(dateSplit[1]), 0);
        this.revenuegetDateRange['startDate'] = this.datepipe.transform(monthStart, 'yyyy-MM-dd');
        this.revenuegetDateRange['endDate'] = this.datepipe.transform(monthEnd, 'yyyy-MM-dd');
        // this.ejsValue = [this.revenuegetDateRange['startDate'], this.revenuegetDateRange['endDate']];
        this.revenueForm.get('dateRange').setValue([this.datepipe.transform(monthStart, 'MM/dd/yyyy'), this.datepipe.transform(monthEnd, 'MM/dd/yyyy')]);

        this.params.date_range = JSON.stringify(this.revenuegetDateRange);
        this.params.page = 1;
        this.params.limit = 15;
        // this.chartTable = true;
        this.revenueChart = true;
        this.getmonthlyRevenueReport();
      }
    });
  }


  refundsChartData() {
    this.commonService.refundsChartData(this.refundsyearParams).subscribe(res => {
      if (res) {

        refundsData.dataset[0].data.filter((data, index) => {
          refundsData.dataset[0].data[index].value = '';
          refundsData.dataset[0].data[index].fullDate = '';

        })
        Object.entries(res.refunds).forEach(val => {
          refundsData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              refundsData.dataset[0].data[index].value = val[1].toString();
              refundsData.dataset[0].data[index].fullDate = val[0];
            }
          })
        });
        this.refundsChartDataSource = refundsData;

      }
    })
  }

  exitCustomersChartData() {
    this.commonService.exitCustomersChartData(this.exitCustomersyearParams).subscribe(res => {
      if (res) {

        exitCustomersData.dataset[0].data.filter((data, index) => {
          exitCustomersData.dataset[0].data[index].value = '';
          exitCustomersData.dataset[0].data[index].fullDate = '';

        })
        Object.entries(res.exitCustomers).forEach(val => {
          exitCustomersData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              exitCustomersData.dataset[0].data[index].value = val[1].toString();
              exitCustomersData.dataset[0].data[index].fullDate = val[0];
            }
          })
        });
        this.exitCustomersChartDataSource = exitCustomersData;
      }
    })
  }

  monthlySubscriptionChartData() {

    this.commonService.MonthlySubscriptionChartReport(this.monthlySubscriptionyearParams).subscribe(res => {
      if (res) {

        monthlySubscriptionData.dataset[0].data.filter((data, index) => {
          monthlySubscriptionData.dataset[0].data[index].value = '';
          monthlySubscriptionData.dataset[0].data[index].fullDate = '';
          monthlySubscriptionData.dataset[1].data[index].value = '';
          monthlySubscriptionData.dataset[1].data[index].fullDate = '';

        })
        Object.entries(res.licenses).forEach(val => {
          monthlySubscriptionData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              monthlySubscriptionData.dataset[0].data[index].value = val[1].toString();
              monthlySubscriptionData.dataset[0].data[index].fullDate = val[0];
            }
          })
        });
        Object.entries(res.addons).forEach(val => {
          monthlySubscriptionData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              monthlySubscriptionData.dataset[1].data[index].value = val[1].toString();
              monthlySubscriptionData.dataset[1].data[index].fullDate = val[0];
            }
          })
        });
        this.monthlySubscriptionChartDataSource = monthlySubscriptionData;
      }
    })
  }

  MRRChartData() {

    this.commonService.MRRChartReport(this.MRRyearParams).subscribe(res => {
      if (res) {

        MRRData.dataset[0].data.filter((data, index) => {
          MRRData.dataset[0].data[index].value = '';
          MRRData.dataset[0].data[index].fullDate = '';
          MRRData.dataset[1].data[index].value = '';
          MRRData.dataset[1].data[index].fullDate = '';
          // MRRData.dataset[2].data[index].value = '';
          // MRRData.dataset[2].data[index].fullDate = '';

        })
        Object.entries(res.licenses).forEach(val => {
          MRRData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              MRRData.dataset[0].data[index].value = val[1].toString();
              MRRData.dataset[0].data[index].fullDate = val[0];
            }
          })
        });
        Object.entries(res.addons).forEach(val => {
          MRRData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              MRRData.dataset[1].data[index].value = val[1].toString();
              MRRData.dataset[1].data[index].fullDate = val[0];
            }
          })
        });
        // Object.entries(res.account_search).forEach(val => {
        //   MRRData.categories[0].category.filter((labelData, index) => {
        //     if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
        //       MRRData.dataset[2].data[index].value = val[1].toString();
        //       MRRData.dataset[2].data[index].fullDate = val[0];
        //     }
        //   })
        // });
        this.MRRChartDataSource = MRRData;


      }
    })
  }

  paymentModeWisePaymentsChartData() {

    this.commonService.paymentModeWisePaymentsChartReport(this.paymentModeWisePaymentsyearParams).subscribe(res => {
      if (res) {

        paymentModeWisePaymentsData.dataset[0].data.filter((data, index) => {
          paymentModeWisePaymentsData.dataset[0].data[index].value = '';
          paymentModeWisePaymentsData.dataset[0].data[index].fullDate = '';
          paymentModeWisePaymentsData.dataset[1].data[index].value = '';
          paymentModeWisePaymentsData.dataset[1].data[index].fullDate = '';
          paymentModeWisePaymentsData.dataset[2].data[index].value = '';
          paymentModeWisePaymentsData.dataset[2].data[index].fullDate = '';
          paymentModeWisePaymentsData.dataset[3].data[index].value = '';
          paymentModeWisePaymentsData.dataset[3].data[index].fullDate = '';

        })
        Object.entries(res.card).forEach(val => {
          paymentModeWisePaymentsData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              paymentModeWisePaymentsData.dataset[0].data[index].value = val[1].toString();
              paymentModeWisePaymentsData.dataset[0].data[index].fullDate = val[0];
            }
          })
        });
        Object.entries(res.bank).forEach(val => {
          paymentModeWisePaymentsData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              paymentModeWisePaymentsData.dataset[1].data[index].value = val[1].toString();
              paymentModeWisePaymentsData.dataset[1].data[index].fullDate = val[0];
            }
          })
        });
        Object.entries(res.cheque).forEach(val => {
          paymentModeWisePaymentsData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              paymentModeWisePaymentsData.dataset[2].data[index].value = val[1].toString();
              paymentModeWisePaymentsData.dataset[2].data[index].fullDate = val[0];
            }
          })
        });
        Object.entries(res.stripe_offline).forEach(val => {
          paymentModeWisePaymentsData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              paymentModeWisePaymentsData.dataset[3].data[index].value = val[1].toString();
              paymentModeWisePaymentsData.dataset[3].data[index].fullDate = val[0];
            }
          })
        });
        this.paymentModeWisePaymentsChartDataSource = paymentModeWisePaymentsData;
      }
    })
  }

  signupClosureReport() {
    this.commonService.total_signups_closures(this.signupClosureyearParams).subscribe(res => {
      if (res) {

        signupclosureData.dataset[0].data.filter((data, index) => {
          signupclosureData.dataset[0].data[index].value = '';
          signupclosureData.dataset[0].data[index].fullDate = '';
          signupclosureData.dataset[1].data[index].value = '';
          signupclosureData.dataset[1].data[index].fullDate = '';
        })
        Object.entries(res.signups).forEach(val => {
          signupclosureData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              signupclosureData.dataset[0].data[index].value = val[1].toString();
              signupclosureData.dataset[0].data[index].fullDate = val[0];
            }
          })
        });
        Object.entries(res.closures).forEach(val => {
          signupclosureData.categories[0].category.filter((labelData, index) => {
            if (labelData.label === this.datepipe.transform(val[0], 'MMM')) {
              signupclosureData.dataset[1].data[index].value = val[1].toString();
              signupclosureData.dataset[1].data[index].fullDate = val[0];
            }
          })
        });
        this.signupClosureChartDataSource = signupclosureData;
      }
    })
  }


  refundsMothlyList(e) {
    this.zone.run(() => {
      if (e.dataObj.datasetName == 'Refunds') {
        refundsData.categories[0].category.filter((labelData, index) => {
          if (labelData.label === e.dataObj.categoryLabel) {
            let dateSplit = refundsData.dataset[0].data[index].fullDate.split('-');
            let monthStart = new Date(Number(dateSplit[0]), Number(dateSplit[1]) - 1, 1);
            let monthEnd = new Date(Number(dateSplit[0]), Number(dateSplit[1]), 0);
            this.refundsgetDateRange['startDate'] = this.datepipe.transform(monthStart, 'yyyy-MM-dd');
            this.refundsgetDateRange['endDate'] = this.datepipe.transform(monthEnd, 'yyyy-MM-dd');
            this.refundsParams.date_range = JSON.stringify(this.refundsgetDateRange);
            if (this.refundsYearForm.value.country_id) {
              this.refundsParams.country_id = this.refundsYearForm.value.country_id;
            }

            this.refundsReport();
            this.refundsChart = true;
          }
        });
      }
    });
  }

  exitCustomersMothlyList(e) {
    this.zone.run(() => {
      if (e.dataObj.datasetName == 'ExitCustomers') {
        exitCustomersData.categories[0].category.filter((labelData, index) => {
          if (labelData.label === e.dataObj.categoryLabel) {
            let dateSplit = exitCustomersData.dataset[0].data[index].fullDate.split('-');
            let monthStart = new Date(Number(dateSplit[0]), Number(dateSplit[1]) - 1, 1);
            let monthEnd = new Date(Number(dateSplit[0]), Number(dateSplit[1]), 0);
            this.exitCustomersgetDateRange['startDate'] = this.datepipe.transform(monthStart, 'yyyy-MM-dd');
            this.exitCustomersgetDateRange['endDate'] = this.datepipe.transform(monthEnd, 'yyyy-MM-dd');
            this.exitCustomersParams.date_range = JSON.stringify(this.exitCustomersgetDateRange);
            if (this.exitCustomersYearForm.value.country_id) {
              this.exitCustomersParams.country_id = this.exitCustomersYearForm.value.country_id;
            }
            this.exitCustomersReport();
            this.exitCustomersChart = true;
          }
        });
      }
    });
  }

  monthlyAddons(e) {
    this.zone.run(() => {
      if (e.dataObj.datasetName == 'Licenses') {
        monthlySubscriptionData.categories[0].category.filter((labelData, index) => {
          if (labelData.label === e.dataObj.categoryLabel) {
            let dateSplit = monthlySubscriptionData.dataset[1].data[index].fullDate.split('-');
            let monthStart = new Date(Number(dateSplit[0]), Number(dateSplit[1]) - 1, 1);
            let monthEnd = new Date(Number(dateSplit[0]), Number(dateSplit[1]), 0);
            this.monthlySubscriptiongetDateRange['startDate'] = this.datepipe.transform(monthStart, 'yyyy-MM-dd');
            this.monthlySubscriptiongetDateRange['endDate'] = this.datepipe.transform(monthEnd, 'yyyy-MM-dd');
            // this.monthlySubscriptionForm.get('dateRange').setValue([this.datepipe.transform(monthStart, 'MM/dd/yyyy'), this.datepipe.transform(monthEnd, 'MM/dd/yyyy')]);
            this.monthlySubscriptionParams.date_range = JSON.stringify(this.monthlySubscriptiongetDateRange);
            this.monthlySubscriptionParams.app_type = "hire_users,hr_users,crm_users";

            if (this.monthlySubscriptionYearForm.value.country_id) {
              this.monthlySubscriptionParams.country_id = this.monthlySubscriptionYearForm.value.country_id;
            }

            this.monthlySubscriptionRevenueReport();
            // this.monthlySubscriptionchartTable = true;
            this.monthlySubscriptionChart = true;
          }
        });
      }
      else if (e.dataObj.datasetName == 'Addons') {
        monthlySubscriptionData.categories[0].category.filter((labelData, index) => {
          if (labelData.label === e.dataObj.categoryLabel) {
            let dateSplit = monthlySubscriptionData.dataset[0].data[index].fullDate.split('-');
            let monthStart = new Date(Number(dateSplit[0]), Number(dateSplit[1]) - 1, 1);
            let monthEnd = new Date(Number(dateSplit[0]), Number(dateSplit[1]), 0);
            this.monthlySubscriptiongetDateRange['startDate'] = this.datepipe.transform(monthStart, 'yyyy-MM-dd');
            this.monthlySubscriptiongetDateRange['endDate'] = this.datepipe.transform(monthEnd, 'yyyy-MM-dd');
            this.signupsparams.date_range = JSON.stringify(this.monthlySubscriptiongetDateRange);
            // this.monthlySubscriptionForm.get('dateRange').setValue([this.datepipe.transform(monthStart, 'MM/dd/yyyy'), this.datepipe.transform(monthEnd, 'MM/dd/yyyy')]);
            this.monthlySubscriptionParams.date_range = JSON.stringify(this.monthlySubscriptiongetDateRange);
            this.monthlySubscriptionParams.app_type = "mass_mails_credits,contacts_search_credits";

            if (this.monthlySubscriptionYearForm.value.country_id) {
              this.monthlySubscriptionParams.country_id = this.monthlySubscriptionYearForm.value.country_id;
            }

            this.monthlySubscriptionRevenueReport();
            // this.monthlySubscriptionchartTable = true;
            this.monthlySubscriptionChart = true;

          }
        });
      }
    });
  }


  signupclosure(e) {
    this.zone.run(() => {
      if (e.dataObj.datasetName == 'Closures') {
        signupclosureData.categories[0].category.filter((labelData, index) => {
          if (labelData.label === e.dataObj.categoryLabel) {
            let dateSplit = signupclosureData.dataset[1].data[index].fullDate.split('-');
            let monthStart = new Date(Number(dateSplit[0]), Number(dateSplit[1]) - 1, 1);
            let monthEnd = new Date(Number(dateSplit[0]), Number(dateSplit[1]), 0);
            this.closuresDateRange['startDate'] = this.datepipe.transform(monthStart, 'yyyy-MM-dd');
            this.closuresDateRange['endDate'] = this.datepipe.transform(monthEnd, 'yyyy-MM-dd');
            // this.closuresForm.get('dateRange').setValue([this.datepipe.transform(monthStart, 'MM/dd/yyyy'), this.datepipe.transform(monthEnd, 'MM/dd/yyyy')]);
            this.closuresForm.setValue({
              'start_date': this.datepipe.transform(monthStart, 'MM/dd/yyyy'),
              'end_date': this.datepipe.transform(monthEnd, 'MM/dd/yyyy')
            });
            this.closuresparams.date_range = JSON.stringify(this.closuresDateRange);
            this.closuresparams.country_id = this.signupClosureYearForm.value.country_id;
            this.getClosuresData();
            // this.signupClosurechartTable = true;
            this.signupClosureChart = true;
            e.dataObj.datasetName == 'signup' ? this.selectSign = true : this.selectSign = false;
            this.signVsClose.get('signupvsclosure').setValue(this.signvsclose[1].value);
          }
        });
      }
      else if (e.dataObj.datasetName == 'Signups') {
        signupclosureData.categories[0].category.filter((labelData, index) => {
          if (labelData.label === e.dataObj.categoryLabel) {
            let dateSplit = signupclosureData.dataset[0].data[index].fullDate.split('-');
            let monthStart = new Date(Number(dateSplit[0]), Number(dateSplit[1]) - 1, 1);
            let monthEnd = new Date(Number(dateSplit[0]), Number(dateSplit[1]), 0);
            this.signupsDateRange['startDate'] = this.datepipe.transform(monthStart, 'yyyy-MM-dd');
            this.signupsDateRange['endDate'] = this.datepipe.transform(monthEnd, 'yyyy-MM-dd');
            this.signupsparams.date_range = JSON.stringify(this.signupsDateRange);
            // this.signupForm.get('dateRange').setValue([this.datepipe.transform(monthStart, 'MM/dd/yyyy'), this.datepipe.transform(monthEnd, 'MM/dd/yyyy')]);
            this.signupForm.patchValue({
              stat_date: this.datepipe.transform(monthStart, 'MM/dd/yyyy'),
              end_date: this.datepipe.transform(monthEnd, 'MM/dd/yyyy')
            });
            this.signupsparams.country_id = this.signupClosureYearForm.value.country_id;
            this.getSignupData();
            // this.signupClosurechartTable = true;
            this.signupClosureChart = true;
            e.dataObj.datasetName == 'signup' ? this.selectSign = true : this.selectSign = false;
            this.signVsClose.get('signupvsclosure').setValue(this.signvsclose[0].value);
          }
        });
      }
    });
  }
  navigate(id) {
    // this.router.navigate(['company_details', id]);
    this.router.navigate([]).then(result => { window.open("#/company_details/" + id, '_blank'); });
  }

  isNumber(e) { return typeof e === 'number' }

  prepareMonthlySubscriptionParams(res) {
    this.monthlySubscriptionParams.subscription_cycle = res.subscription_cycle ? res.subscription_cycle : '';
    if (res.app_type) {
      this.monthlySubscriptionParams.app_type = res.app_type ? res.app_type.map(x => x).join(",") : '';
    }
    this.monthlySubscriptionParams.client_ids = res.customer ? res.customer.map(x => x).join(",") : '';
    if (res.start_date && res.end_date) {
      this.monthlySubscriptiongetDateRange['startDate'] = moment(res.start_date).format("YYYY-MM-DD");
      this.monthlySubscriptiongetDateRange['endDate'] = moment(res.end_date).format("YYYY-MM-DD");
      this.monthlySubscriptionParams.date_range = this.monthlySubscriptiongetDateRange ? JSON.stringify(this.monthlySubscriptiongetDateRange) : '';
    }
    else if (!this.monthlySubscriptionParams.date_range) {
      this.monthlySubscriptionParams.date_range = '';
    }
    this.monthlySubscriptionParams.page = 1;
    this.monthlySubscriptionParams.country_id = res.country_id;
    this.monthlySubscriptionRevenueReport();
  }

  prepareRefundsParams(res) {
    this.refundsParams.client_ids = res.customer ? res.customer.map(x => x).join(",") : '';

    if (res.refund_type) {
      this.refundsParams.refund_type = res.refund_type ? res.refund_type : '';
    }

    if (res.start_date && res.end_date) {
      this.refundsgetDateRange['startDate'] = moment(res.start_date).format("YYYY-MM-DD");
      this.refundsgetDateRange['endDate'] = moment(res.end_date).format("YYYY-MM-DD");
      this.refundsParams.date_range = this.refundsgetDateRange ? JSON.stringify(this.refundsgetDateRange) : '';
    }
    else if (!this.refundsParams.date_range) {
      this.refundsParams.date_range = '';
    }
    this.refundsParams.page = 1;
    this.refundsParams.country_id = res.country_id;
    this.refundsReport();
  }

  prepareExitCustomersParams(res) {
    this.exitCustomersParams.page = 1;
    this.exitCustomersParams.country_id = res.country_id;
    this.exitCustomersReport();
  }

  downloadInvocieDoc(company_id, invoice_id, fileName) {
    this.commonService.downloadRefundInvoice(company_id, invoice_id).subscribe(res => {
      this.showPDFFile(res, fileName);
    });
  }

  showPDFFile(data: any, fileName: string) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const dataURL = URL.createObjectURL(blob);
    window.open(dataURL, '_blank');
  }

  signupCloseListingCountryChnage() {
    this.signupsparams.country_id = this.signupClosureCountryId;
    this.closuresparams.country_id = this.signupClosureCountryId;
    if (this.selectSign) {
      this.getSignupData();
    } else {
      this.getClosuresData();
    }
  }

  exportMrrData() {
    let fileURL = this.downloadMRRURL + '/' + this.MRRYearForm.value.year + '/' + this.MRRYearForm.value.country_id;
    window.open(fileURL, '_blank');
  }

  exportMonthlyPaymentsData() {
    let fileURL = this.downloadMothlyPaymentURL + '/' + this.paymentModeWisePaymentsYearForm.value.year + '/' + this.paymentModeWisePaymentsYearForm.value.country_id;
    window.open(fileURL, '_blank');
  }

  setDate(fg: string, date_type: string) {
    let a = this.monthlySubscriptionForm;
    switch (fg) {
      case 'monthlySubscriptionForm':
        a = this.monthlySubscriptionForm;
        break;
      case 'signupForm':
        a = this.signupForm;
        break;
      case 'closuresForm':
        a = this.closuresForm;
        break;
      case 'refundsForm':
        a = this.refundsForm;
        break;
    }

    switch (date_type) {
      case 'this_week':
        a?.patchValue({
          start_date: this.datepipe.transform(this.weekStart, 'yyyy-MM-dd'),
          end_date: this.datepipe.transform(this.weekEnd, 'yyyy-MM-dd')
        });
        break;
      case 'this_month':
        a.patchValue({
          start_date: this.datepipe.transform(this.monthStart, 'yyyy-MM-dd'),
          end_date: this.datepipe.transform(this.monthEnd, 'yyyy-MM-dd')
        });
        break;
      case 'last_month':
        a.patchValue({
          start_date: this.datepipe.transform(this.lastStart, 'yyyy-MM-dd'),
          end_date: this.datepipe.transform(this.lastEnd, 'yyyy-MM-dd')
        });
        break;
      default:
        a.patchValue({
          start_date: '',
          end_date: ''
        });
        break;
    }
  }
}