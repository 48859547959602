<h1 mat-dialog-title class='text-uppercase'>Exit Request Info</h1>
<mat-divider></mat-divider>

<ng-container *ngIf="payment_type == 'no_due'">
  <mat-dialog-content  >
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="grey-100-bg border-radius-4 p-8 mb-8">
      <p class="text-bold m-0" fxFlex="50">Request Raised On: <span
          class="accent-fg">{{requested_on |date:"MM/dd/yyyy"}}</span></p>
      <p class="text-bold m-0" fxFlex>Subsciption End Date: <span
          class="accent-fg">{{subscription_end_date |date:"MM/dd/yyyy"}}</span></p>
    </div>
    <h2 class="text-bold text-center py-20">
      Customer don't have any due amount
    </h2>

    <div *ngIf="show_wallet_amount">
      <mat-checkbox [(ngModel)]="add_wallet" (change)="refundWalletAmount()">Refund Wallet Amount (${{wallet_amount}})</mat-checkbox>
    </div>

  </mat-dialog-content>

  <mat-divider></mat-divider>
  <mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button class='primary mr-4' (click)="approveNoDueRequest()">Approve</button>
  </mat-dialog-actions>
</ng-container>

<form [formGroup]="approveExitRequest" (ngSubmit)="refundToCustomer()" *ngIf="payment_type != 'no_due' ">
  <mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="grey-100-bg border-radius-4 p-8 mb-16">
      <p class="text-bold m-0" fxFlex="50">Request Raised On: <span
          class="accent-fg">{{requested_on |date:"MM/dd/yyyy"}}</span></p>
      <p class="text-bold m-0" fxFlex>Subsciption End Date: <span
          class="accent-fg">{{subscription_end_date |date:"MM/dd/yyyy"}}</span></p>
    </div>
    <p *ngIf="payment_type == 'customer_payment' "> <span class="text-bold">Note :</span> Due Amount <b>$ {{total_amount}}</b> is already paid by customer</p>
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
      <table class="table mb-16" *ngIf="addonsData">
        <thead>
          <th>Subscription Item</th>
          <th>Per Pack</th>
          <th>Credits</th>
          <th>Unit Price ($)</th>
          <th>Price ($)</th>
        </thead>
        <tbody>
          <tr *ngFor="let record of addonsData | keyvalue">
            <td>{{record.value.license_title}}
              <span *ngIf="record.value.addon_id == 7">(Subscription Based)</span>
              <span *ngIf="record.value.addon_id != 7">(Pro Rate)</span>
            </td>
            <td>1</td>
            <td>{{record.value.credits}}</td>
            <td>{{record.value.per_license_cost}}</td>
            <td>{{record.value.cost}}</td>
          </tr>
          <tr>
            <td colspan="4" class="text-right">Sub Total ($)</td>
            <td class="text-right">
              <span *ngIf="payment_type == 'customer_payment' ">{{total_amount}}</span>
              <mat-form-field class="w-100-p" appearance='outline' *ngIf="payment_type == 'refund_to_customer' ">
                <input type="text" formControlName="total_amount" matInput  >
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="show_wallet_amount">
      <mat-checkbox formControlName="refund_wallet_amount" (change)="addWalletAmount()">Refund Wallet Amount (${{wallet_amount}})</mat-checkbox>
    </div>
    <p *ngIf="payment_type == 'refund_to_customer'" class="mb-0 text-left"><b>Note:</b>The refund amount will be credited to customer account</p>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align='end'>
    <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
    <!-- <button mat-flat-button class='primary' class="mr-4" (click)="no_refund = 2"> Approve</button> -->
    <button mat-flat-button class='primary' (click)="no_refund = 1">Approve with no refund</button>
  </mat-dialog-actions>
</form>