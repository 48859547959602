import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { ConfigurationService } from 'app/services/configuration.service';


@Component({
  selector: 'app-default-settings',
  templateUrl: './default-settings.component.html',
  styleUrls: ['./default-settings.component.scss']
})

export class DefaultSettingsComponent implements OnInit {

  usersList: any;
  stageData: any;
  allocatedPocData: any;

  constructor(private commonService: CommonService,
    private configService: ConfigurationService,
    public router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    this.DataMigrationStagePoc();
    this.getCPanelUsers();
  }

  DataMigrationStagePoc() {
    this.configService.getDataMigrationStagePoc().subscribe(res => {
      if (res.status == 1) {
        this.stageData = res.data;
        this.allocatedPocData = this.stageData.details;
      }
    })
  }

  getCPanelUsers() {
    let RoleIdArr = {};
    RoleIdArr[3] = 3;
    RoleIdArr[2] = 2;
    this.configService.getCpanelRoleUsers(RoleIdArr).subscribe(res => {
      if (res.status == 1) {
        this.usersList = res.data.users;
      }
    })
  }

  saveData() {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? You want to update data?';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = {};
        params['details'] = JSON.stringify(this.allocatedPocData);
        params['module_slug'] = this.stageData.module_slug;
        this.configService.savevDataMigrationStagePoc(params).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
        })        
      }
    })
  }

}
