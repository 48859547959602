<div class="loader content" *ngIf="showLoader">
  <mat-spinner *ngIf="showLoader" [diameter]="50"></mat-spinner>
</div>
<h1 mat-dialog-title class='text-uppercase'>Add Lead Owner</h1>
<mat-divider></mat-divider>
<form [formGroup]="leadOwnerForm" (ngSubmit)="saveLeadOwner()">
  <mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>First Name</mat-label>
          <input type="text" formControlName="first_name" matInput>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Last Name</mat-label>
          <input type="text" formControlName="last_name" matInput>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Email</mat-label>
          <input type="email" formControlName="email" matInput>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Region</mat-label>
          <mat-select formControlName="country_id">
            <mat-option *ngFor="let item of comapany_locations" [value]="item.country_table_id">
              {{item.country_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Sort Order</mat-label>
          <input type="text" formControlName="sort_order" matInput>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Contact Number</mat-label>
          <input type="text" formControlName="contact_number" matInput>
        </mat-form-field>
      </div>

      <div fxFlex='48' *ngIf="data && data.id">
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Status</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let item of user_status" [value]="item.status_code">
              {{item.status_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>
  </mat-dialog-content>

  <mat-divider></mat-divider>

  <mat-dialog-actions align='end'>
    <button mat-flat-button class='primary mr-4'>Save</button>
    <button mat-flat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>

</form>