import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-subscription-invoice',
  templateUrl: './subscription-invoice.component.html',
  styleUrls: ['./subscription-invoice.component.scss']
})
export class SubscriptionInvoiceComponent implements OnInit {

  displayedColumns: string[] = ['customer_name','sales_rep', 'invoice_id', 'invoice_url','payment_for' ,'status','created_on', 'actions'];
  dataSource;
  id: any;
  searchValue = '';
  length: any;
  pageSize: any;
  index: any;
  currentUser = {};
  params: { page: number; limit?: number, searchKey?: any, status?: any};
  selection = [
    { value: '0', viewValue: 'Open' },
    { value: '1', viewValue: 'Paid' },
    { value: '2', viewValue: 'Deleted' },
  ];
  invoiceStatus:any = '0';
  usersList:any;

  constructor(
    private commonService: CommonService,
    public router: Router, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.getCPanelUsers();
    this.params = { limit:15, page:1, status:0 };
    this.getList();
  }

  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }

  getList() {
    this.commonService.getSubscriptionInvoices(this.params).subscribe(res => {
      if (res.status == 1) {
        this.dataSource = new MatTableDataSource(res.data.data);
        this.length = res.data.total;
        this.pageSize = res.data.per_page;
        this.index = res.data.current_page;
      }
    })
  }

  navigate(element, blank) {
    // this.router.navigate(['company_details', element.company_id]);
    this.router.navigate([]).then(result => { window.open("#/company_details/" + element.company_id, '_blank'); });
  }

  getPaymentStatus(status) {
    if (status == 1) {
      return 'Paid';
    } else if (status == 0) {
      return 'Open';
    } else if (status == 2) {
      return 'Deleted';
    }
  }

  deleteRecord(record) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? You want to delete invoice';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '35%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let params = {};
        params['company_id'] = record.company_id;
        params['id'] = record.id;
        this.commonService.deleteSubscriptionInvoice(params).subscribe(res => {
          if (res.status == 1) {
            this.commonService.openSnackBar('primary', res.message);
          } else {
            this.commonService.openSnackBar('warn', res.message);
          }
          this.getList();
        });
      } else { }
    })
  }

  selectChange(val) {
    this.params.status = val;
    this.params.page = 1;
    this.getList();
  }

  getCPanelUsers() {
    let RoleIdArr = {};
    RoleIdArr[3] = 3;
    RoleIdArr[2] = 2;
    RoleIdArr[1] = 1;
    this.commonService.getCpanelAllRoleUsers(RoleIdArr).subscribe(res => {
      if (res.status == 1) {
        this.usersList = res.data;
      }
    })
  }

}
