<div>
  <div fxLayout="row wrap" class="border-b-1">
    <ul class="top-nav m-0 p-0" fxFlex>
      <li><a class="text-bold" (click)="getTrailCompaniesData('all')" [ngClass]="{'active': getlist == 'all'}">Trial
          Customers</a>
      </li>

      <li><a class="text-bold" (click)="getTrailCompaniesData('free_customers')"
        [ngClass]="{'active': getlist == 'free_customers'}">Free Customers</a>
      </li>

      <!-- <li><a class="text-bold" (click)="getTrailCompaniesData('search_filter_today')"
                [ngClass]="{'active': getlist == 'search_filter_today'}">Expiring today</a>
              </li>
              <li><a class="text-bold" (click)="getTrailCompaniesData('search_filter_week')"
                [ngClass]="{'active': getlist == 'search_filter_week'}">Expiring this week</a></li>
                <li><a class="text-bold" (click)="getTrailCompaniesData('search_filter_intwodys')"
                  [ngClass]="{'active': getlist == 'search_filter_intwodys'}">Expiring in next 2 days</a>
                </li> -->
    </ul>

    <div fxFlex="280px" fxLayout="row wrap" fxLayoutAlign="end center">
      <mat-form-field class="w-100-p country-p" appearance='outline'>
        <mat-select [(ngModel)]="country_id" (selectionChange)="countryChanged()">
          <mat-option *ngFor="let item of countries" [value]="item.value">
            {{item.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div fxFlex="372px" fxLayout="row wrap" fxLayoutAlign="end center">
      <mat-form-field class="w-252 mr-20" appearance="outline" [matMenuTriggerFor]="menu">
        <mat-label>Select subscription end date</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker" [formGroup]="dateRangeForm">
          <input matStartDate placeholder="Start date" readonly formControlName="start_date">
          <input matEndDate placeholder="End date" readonly formControlName="end_date">
        </mat-date-range-input>
      </mat-form-field>

      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="setDate('this_week')" [ngClass]="{'primary': (dateActive == 'this_week')}">This
          Week</button>
        <button mat-menu-item (click)="setDate('this_month')" [ngClass]="{'primary': (dateActive == 'this_month')}">This
          Month</button>
        <button mat-menu-item (click)="setDate('last_month')" [ngClass]="{'primary': (dateActive == 'last_month')}">Last
          Month</button>
        <button mat-menu-item (click)="rangePicker.open();dateActive = 'custom';"
          [ngClass]="{'primary': dateActive == 'custom'}">Custom Range</button>
        <button mat-menu-item (click)="setDate('clear')" [ngClass]="{'primary': dateActive == 'clear'}">Clear</button>
        <!-- <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle> -->
        <mat-date-range-picker #rangePicker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-menu>
      <button mat-icon-button class="ml-4 mr-20" matTooltip="Refresh" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
    <div class="search-wrapper" fxFlex="30">
      <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
        [readOnly]="dataSource.length === 0" [(ngModel)]="searchValue" placeholder="Company Name">
      <mat-icon class="search_icon">search</mat-icon>
      <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
        close
      </mat-icon>
    </div>
    <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
      <div>
        <button mat-flat-button class="primary" (click)="exportExcel()" *ngIf="logginUser?.role_name == 'admin'">Export as Excel</button>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
          [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
      </div>
    </div>
  </div>
</div>
<cdk-virtual-scroll-viewport itemSize="50" class="scroll-content-height">

<div class="mx-12 mb-12">
  <ng-container *ngIf="dataSource">
    <div class="px-12 pb-12">
      <mat-table [dataSource]="dataSource" class="table">
        <!-- client Column -->
        <ng-container matColumnDef="customername">
          <mat-header-cell *matHeaderCellDef> Customer Name
            <span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="sortColumnData('companies.name','asc')">
                  <mat-icon>sort</mat-icon>
                  <span>Sort Ascending</span>
                </button>
                <button mat-menu-item (click)="sortColumnData('companies.name','desc')">
                  <mat-icon>sort</mat-icon>
                  <span>Sort Descending</span>
                </button>
              </mat-menu>
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> 
            <a *ngIf="element.is_db_deleted == 0" (click)="navigate(element)" class="cursor-pointer">{{element.name}}</a>
            <span *ngIf="element.is_db_deleted == 1">{{element.name}}</span>
          </mat-cell>
        </ng-container>
        <!-- name Column -->
        <ng-container matColumnDef="contactname">
          <mat-header-cell *matHeaderCellDef> Contact Name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.client_info.first_name}}
            {{element.client_info.last_name}} </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef fxFlex="200px"> Email </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="200px"> {{element.client_info.email}} </mat-cell>
              </ng-container> -->

        <!-- <ng-container matColumnDef="subdomain">
                <mat-header-cell *matHeaderCellDef> Sub Domain </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.sub_domain_name}} </mat-cell>
              </ng-container> -->

        <!-- contactnumber Column -->
        <ng-container matColumnDef="contactnumber">
          <mat-header-cell *matHeaderCellDef> Contact Number </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.client_info.contact_number}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef fxFlex="200px">Email </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="200px" class="lowercase"> {{element.client_info.email}}
          </mat-cell>
        </ng-container>

        <!-- date Column -->
        <ng-container matColumnDef="signupdate">
          <mat-header-cell *matHeaderCellDef> Signup On
            <span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="sortColumnData('company_subscription_details.subscr_start_date','asc')">
                  <mat-icon>sort</mat-icon>
                  <span>Sort Ascending</span>
                </button>
                <button mat-menu-item (click)="sortColumnData('company_subscription_details.subscr_start_date','desc')">
                  <mat-icon>sort</mat-icon>
                  <span>Sort Descending</span>
                </button>
              </mat-menu>
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{ element.subscr_start_date | date:"MM/dd/yyyy" }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="region">
          <mat-header-cell *matHeaderCellDef> region
            <span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="sortColumnData('companies.region','asc')">
                  <mat-icon>sort</mat-icon>
                  <span>Sort Ascending</span>
                </button>
                <button mat-menu-item (click)="sortColumnData('companies.region','desc')">
                  <mat-icon>sort</mat-icon>
                  <span>Sort Descending</span>
                </button>
              </mat-menu>
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="element.region != 'usa'">{{element.region | titlecase}}</span>
            <span *ngIf="element.region == 'usa'">{{element.region | uppercase }}</span>
          </mat-cell>
        </ng-container>

        <!-- status Column -->
        <ng-container matColumnDef="remainingdays">
          <mat-header-cell *matHeaderCellDef> Remainig Days </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{differDays(element.subscr_end_date, element.grace_period)}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="trial_endon">
          <mat-header-cell *matHeaderCellDef> Trial End Date
            <span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>arrow_drop_down</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="sortColumnData('company_subscription_details.subscr_end_date','asc')">
                  <mat-icon>sort</mat-icon>
                  <span>Sort Ascending</span>
                </button>
                <button mat-menu-item (click)="sortColumnData('company_subscription_details.subscr_end_date','desc')">
                  <mat-icon>sort</mat-icon>
                  <span>Sort Descending</span>
                </button>
              </mat-menu>
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{showExtendedDate(element.subscr_end_date, element.grace_period)}}
          </mat-cell>
        </ng-container>

        <!-- Sales rep Column -->
        <ng-container matColumnDef="sales_user">
          <mat-header-cell *matHeaderCellDef> Sales Rep </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="userslist">{{ (element.sales_user_id) ? (userslist | getById:element.sales_user_id:'first_name'
              ) +" "+ (userslist | getById:element.sales_user_id:'last_name' ) : '-'}}
            </span>
          </mat-cell>
        </ng-container>

        <!-- Support executive Column -->
        <ng-container matColumnDef="support_user">
          <mat-header-cell *matHeaderCellDef fxFlex="200px"> Support Executive </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="200px">
            <span *ngIf="userslist">{{ (element.support_user_id) ? (userslist |
              getById:element.support_user_id:'first_name' ) +" "+ (userslist |
              getById:element.support_user_id:'last_name' ) : '-'}}</span>
          </mat-cell>
        </ng-container>
        <!-- action Column -->

        <!-- Company Status Column -->
        <ng-container matColumnDef="lost_account">
          <mat-header-cell *matHeaderCellDef fxFlex="200px"> Status </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="200px">
            <span >{{ (element.lost_account==1) ?'Lost': 'Active'}}</span>
          </mat-cell>
        </ng-container>
        <!-- Company Status Column -->

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-icon-button class="red-fg" matTooltip="Send Activation Mail" *ngIf="element.status == 0"
              (click)="SendActivationMail(element.id)">
              <mat-icon class="s-18">email</mat-icon>
            </button>

            <button matBadge="{{element?.comments_cnt}}" matBadgeColor="warn" 
              (click)="viewComment(element)" mat-icon-button  class="teal-fg" matTooltip="Comments"
                    >
                    <mat-icon   (click)="$event.stopPropagation();addComment(element.id)"  class="s-18">comments</mat-icon>
             </button>

            <ng-container *ngIf="element.status == 1">
              <button mat-icon-button class="accent-fg" matTooltip="Extend Trial" (click)="extendTrial(element)">
                <mat-icon class="s-18">add</mat-icon>
              </button>
              <button mat-icon-button class="accent-fg" matTooltip="Update Sales and Support Exucutive"
                (click)="edit(element)">
                <mat-icon class="s-18">group_add</mat-icon>
              </button>
              <button mat-icon-button class="teal-fg" matTooltip="End Trial" (click)="endTrial(element)">
                <mat-icon class="s-18">remove</mat-icon>
              </button>
              <!-- <button mat-icon-button class="accent-fg" matTooltip="Upgrade" (click)="upgradeRoute(element.company_id)">
                <mat-icon class="s-18">monetization_on</mat-icon>
              </button> -->
              <button mat-icon-button class="warn-fg" matTooltip="Candidate Data Migrations"
                (click)="navigatetoImport(element)">
                <mat-icon class="s-18">arrow_right_alt</mat-icon>
              </button>
              <button mat-icon-button class="red-fg" matTooltip="Delete" (click)="deleteTrial(element.id)">
                <mat-icon class="s-18">close</mat-icon>
              </button>

              

              

            </ng-container>

            

          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <div *ngIf="dataSource.data.length == 0" class="text-center">
        <h2 class="heading m-0 py-16 border">No Records Found</h2>
      </div>
    </div>
  </ng-container>
</div>
</cdk-virtual-scroll-viewport>