<h1 mat-dialog-title class='text-uppercase'>Template View</h1>
<mat-divider></mat-divider>
<mat-dialog-content>
  <div *ngIf="data">
    <table cellspacing="0" cellpadding="0" border="0" align="center" width="100%" bg-color='#F7F7F7'>
      <tr>
        <td style="background-color: #F7F7F7; padding: 50px 0; font-family:calibri, Arial, Helvetica, sans-serif;">
          <table cellspacing="0" cellpadding="0" border="0" align="center" width="620" bgcolor="#ffffff"
            style="width: 620px; border: 1px solid #E3E3E3;">
            <tr>
              <td
                style="width: 100%; text-align:center; padding: 15px 30px; border-top: 5px solid #00B257; border-bottom: 1px solid #E3E3E3;">
                <table cellspacing="0" cellpadding="0" border="0" width='100%'>
                  <tr>
                    <td width='30%' style="width: 30%;">
                      <img src="https://oorwin.com/assets/images/oorwin-original.svg" width="90"
                        style="width:90px; max-width:90px;" alt="Oorwin" />
                    </td>
                    <td width='70%'
                      style="width: 70%; font-family:calibri, Arial, Helvetica, sans-serif; text-align: right; font-size: 14px; color: #999999; font-weight: bold;">
                      AI powered staffing solution - CRM | ATS | HRMS
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td [innerHtml]="data.body | safeHtml">
              </td>
            </tr>
            <tr *ngIf="data.slug == 'SIGNED_UP_FREE_TRAIL'">
              <td width='100%' style="width: 100%; padding: 10px 30px 30px 30px;">
                <a href="javascript:void(0)" style="text-decoration: none; display: inline-block;">
                  <span
                    style="padding:7px 15px; font-family:calibri, Arial, Helvetica, sans-serif; color: #ffffff; font-size: 14px; text-transform: uppercase; background: #00B257; display: inline-block; border-radius: 2px;">Book
                    a demo</span>
                </a>
              </td>
            </tr>

            <tr *ngIf="data.slug == 'IMPLEMENTATION_UPDATE'">
              <td width='100%' style="width: 100%; padding: 10px 30px 30px 30px;">
                  <table width="100%" cellspacing="0" cellpadding="8" border="1">
                      <tr>
                          <th>Status</th>
                          <th>Activity</th>
                          <th>Completed Date</th>
                      </tr>
                      <tr>
                          <td>XXXXXXXX</td>
                          <td>XXXXXXXX</td>
                          <td>XXXXXXXX</td>
                      </tr>
                      <tr>
                          <td>XXXXXXXX</td>
                          <td>XXXXXXXX</td>
                          <td>XXXXXXXX</td>
                      </tr>
                  </table>
              </td>
          </tr>

            <tr *ngIf="data.slug == 'SIGNED_UP_FREE_TRAIL' ||data.slug == 'PAID_CUSTOMER' ">
              <td width='100%'
                style="width: 100%; background-color: #F7F7F7; padding: 30px 20px; border: 1px solid #E3E3E3;">
                <table celpadding="0" cellspacing="0" border="0" width="100%">
                  <td width='50px' style="width: 50px; vertical-align: top;">
                    <img src="../../../../../assets/images/venkat.png" width="48"
                      style="width:48px;display: inline-block; max-width: 48;" />
                  </td>
                  <td
                    style="font-family:calibri, Arial, Helvetica, sans-serif; color: #000000; font-size: 14px; vertical-align: top; padding-left: 5px;">
                    <span style="display: block; font-weight: bold; font-size: 14px; line-height: 18px;">Venkat
                      Kolli</span>
                    <span
                      style="display: block; font-weight: 500; font-size: 14px; line-height: 18px; color: #707070;">CEO
                      | Oorwin</span>
                    <a href="javascript:void(0)"
                      style="display: block; font-weight: 500; font-size: 14px; line-height: 22px; color: #707070;text-decoration: none;">
                      <img src="../../../../../assets/images/Icon-email.png" width="12"
                        style="width:12px; display:inline-block; max-width:12px; vertical-align:middle;" />
                      <span
                        style="display: inline-block; font-weight: 500; font-size: 14px; line-height: 18px; color: #707070; padding-left: 3px;">venkat.kolli@oorwin.com</span>
                    </a>
                    <a href="javascript:void(0)" target="_blank"
                      style="display: block; font-weight: 500; font-size: 14px; line-height: 22px; color: #707070; text-decoration: none;">
                      <img src="../../../../../assets/images/Icon-linkedin.png" width="12"
                        style="width:12px; display:inline-block; max-width:12px; vertical-align:middle;" />
                      <span
                        style="display: inline-block; font-weight: 500; font-size: 14px; line-height: 18px; color: #707070; padding-left: 3px;">https://www.linkedin.com/in/venkat-kolli-9a88567/</span>
                    </a>
                  </td>
                </table>
              </td>
            </tr>
            <tr>
              <td width="100%" style="width: 100%; background: #484848; padding: 10px 30px;">
                <table celpadding="0" cellspacing="0" border="0" width="100%">
                  <tr>
                    <td width="50%"
                      style="width: 50%; color:#ffffff; font-size:12px;  font-family:calibri, Arial, Helvetica, sans-serif;">
                      All rights reserved | oorwin Labs LLC.
                    </td>
                    <td width="50%"
                      style="width: 50%; color:#ffffff; font-size:14px;  font-family:calibri, Arial, Helvetica, sans-serif; text-align: right;">
                      <img src="https://oorwin.com/assets/images/oorwin-original.svg"
                        style="width:70px; display: inline-block; max-width:70px;" width="70" alt="oorwin" />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
</mat-dialog-actions>