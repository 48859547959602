import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfigurationService } from 'app/services/configuration.service';
const moment = _rollupMoment || _moment;
export interface PeriodicElement {
  type: string;
}
@Component({
  selector: 'app-queue-logs',
  templateUrl: './queue-logs.component.html',
  styleUrls: ['./queue-logs.component.scss']
})
export class QueueLogsComponent implements OnInit {
  displayedColumns: string[] = ['select', 'id', 'queue', 'failed_at', 'actions'];
  exception_msg = 'No Data';
  selected = -1;
  selection = new SelectionModel<any>(true, []);
  show_filter_values = [
    { value: 'all', viewValue: 'All' }, { value: 'bulk', viewValue: 'Bulk' }, { value: 'medium', viewValue: 'Medium' },
    { value: 'default', viewValue: 'Default' }
  ];
  date_filter = "any";
  companies = "any";
  response_data: any;
  length: number = 10;
  pageSize: number = 15;
  index: number = 1;
  queueFilterForm: FormGroup;
  params = { page: 1, limit: 15 };

  dataSource: any;
  constructor(private fb: FormBuilder,
    private commonservice: CommonService,
    private configService: ConfigurationService,
    public dialog: MatDialog,
  ) {
    this.queueFilterForm = this.fb.group({
      date_filter: [''],
      queue_filter: ['all']
    });
  }

  ngOnInit() {
    this.params['date_filter'] = '';
    this.getQueue(1);
  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.response_data.queues.per_page;
    return numSelected == numRows;
  }
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'}all`;

    }
  }
  appTypeChange() {
    this.params['queue_filter'] = this.queueFilterForm.value.queue_filter;
    this.params['page'] = 1;
  }
  searchFilter() {
    this.params['queue_filter'] = this.queueFilterForm.value.queue_filter;
    this.getQueue(1);
  }
  pageEvent(event) {
    this.params['limit'] = event.pageSize;
    this.params['page'] = event.pageIndex + 1;
    this.getQueue(this.params['page']);
  }
  onDateChange() {
    this.params['date_filter'] = moment(this.queueFilterForm.value.date_filter).format("YYYY-MM-DD");
    this.params['page'] = 1;
  }
  showException(log) {
    this.exception_msg = log;
  }
  getQueue(page) {
    this.params['queue_filter'] = this.queueFilterForm.value.queue_filter ? this.queueFilterForm.value.queue_filter : '';
    this.params['date_filter'] = this.params['date_filter'] ? this.params['date_filter'] : '';
    this.params['page'] = page;
    this.configService.getQueueLogs(this.params).subscribe(result => {
      if (result.status == 1) {
        this.response_data = result.data;
        this.length = this.response_data.queues.total;
        this.pageSize = result.data.queues.per_page;
        this.index = result.data.queues.current_page;
        this.dataSource = new MatTableDataSource(result.data.queues.data);
      } else {
        this.commonservice.openSnackBar('warn', result.message);
      }
    });

  }
  DeleteQueue(id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to delete this Record';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.configService.deleteQueue(id).subscribe(res => {
          if (res.status == 1) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
          this.getQueue(1);
        });
      } else {

      }
    })
  }
  runJob(id) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to delete this Record';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.configService.runQueue(id).subscribe(res => {
          if (res.status == 1) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
          this.getQueue(1);
        });
      } else {

      }
    })


  }
  bulkDeleteQueues() {
    let paramArr = [];
    this.selection.selected.forEach(item => {
      paramArr.push(item.id);
    });
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to delete this Record';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.configService.bulkdeleteQueue(paramArr).subscribe(res => {
          if (res.status == 1) {
            this.commonservice.openSnackBar('primary', res.message);
          } else {
            this.commonservice.openSnackBar('warn', res.message);
          }
          this.getQueue(1);
        });
      } else {

      }
    })

  }

}