<form [formGroup]="form">
  <div fxLayout="row wrap" fxLayoutAlign="center start">
    <div fxLayout="row wrap" fxFlex="80" fxLayoutAlign="space-between start">

      <mat-card class="p-0 mt-40" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Resume Parser</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="resume_parser" aria-label="Select an option" (change)="onCtrlChange('resume_parser')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">rChilli Parser</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Oorwin Parser</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>

      </mat-card>

      <mat-card class="p-0 mt-40" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Resume Search</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="solr_indexing_type" aria-label="Select an option" (change)="onCtrlChange('solr_indexing_type')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Solr Search</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Oorwin Search</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>

      <mat-card class="p-0 mt-40" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Candidate Search</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="candidate_search_by_permission" aria-label="Select an option"
          (change)="onCtrlChange('candidate_search_by_permission')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">No Permisssion</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading mr-20">Without Visibility</mat-radio-button>
            <mat-radio-button value="2" color="primary" class="sub-heading" [disabled]="true">Permission
            </mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>

      <mat-card class="p-0 mt-40" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">IMAP Sync</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          Enable IMAP Auto Sync Cron <mat-slide-toggle color="primary" formControlName="enable_email_sync" (change)="onCtrlChange('enable_email_sync')" class="toggle-right" [disabled]="isLostAccount"></mat-slide-toggle>
        </mat-card-content>
      </mat-card>

      <mat-card class="p-0 mt-40" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Subsidary Enable</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="is_subsidary_enabled" aria-label="Select an option" (change)="onCtrlChange('is_subsidary_enabled')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>

      <mat-card class="p-0 mt-40" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Job Campaign Enable</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="is_job_campaign_enabled" aria-label="Select an option" (change)="onCtrlChange('is_job_campaign_enabled')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>


      <mat-card class="p-0 mt-40" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">jobGrabber Enable</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="is_job_campaign_enabled" aria-label="Select an option" (change)="onCtrlChange('is_job_grabber_enabled')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>

      <!-- <mat-card class="p-0 mt-40 mb-20" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">JobGrabber Suggest Email</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="is_jobgrabber_suggest_email" aria-label="Select an option" (change)="onCtrlChange('is_jobgrabber_suggest_email')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card> -->

      <mat-card class="p-0 mt-40 mb-20" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">DB Solr Search</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="is_db_solr_search" aria-label="Select an option" (change)="onCtrlChange('is_db_solr_search')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>

      <mat-card class="p-0 mt-40 mb-20" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Account Auto Inactive</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="can_account_auto_inactive" aria-label="Select an option" (change)="onCtrlChange('can_account_auto_inactive')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>

      <!-- <mat-card class="p-0 mt-40 mb-20" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Enable Partner Login</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="enable_partner_login" aria-label="Select an option" (change)="onCtrlChange('enable_partner_login')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card> -->


      <mat-card class="p-0 mt-40 mb-20" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Enable Integrated Candidate Search</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="is_integrate_candidate_search" aria-label="Select an option" (change)="onCtrlChange('is_integrate_candidate_search')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>

      <mat-card class="p-0 mt-40 mb-20" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Enable Resume Builder</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="has_resume_builder" aria-label="Select an option" (change)="onCtrlChange('has_resume_builder')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card>

      <mat-card class="p-0 mt-40 mb-20" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">NPAworldwide Marketplace</mat-card-title>
        </mat-card-header>
        
        <mat-card-content class="px-16 py-12">
          
          <mat-radio-group formControlName="npa_worldwide_marketplace" aria-label="Select an option" (change)="onCtrlChange('npa_worldwide_marketplace')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
          &nbsp; &nbsp;
          <ng-container *ngIf="showOpt">
            <mat-form-field class="w-200 mr-8" appearance="outline">
              <mat-label>Free the Member</mat-label>
              <mat-select appMatSearchSelector formControlName="member_id" (selectionChange)="onCtrlChange('npa_worldwide_marketplace')"> 
                <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                <ng-container *ngFor="let member of members">
                  <mat-option *appMatSearchSelectorItems [value]="member.id">{{member.name}}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </ng-container>

        </mat-card-content>
      </mat-card>


      <mat-card class="p-0 mt-40 mb-20" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Marketplace</mat-card-title>
        </mat-card-header>
        
        <mat-card-content class="px-16 py-12">
          
          <mat-radio-group formControlName="oorwin_marketplace" aria-label="Select an option" (change)="onCtrlChange('oorwin_marketplace')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
          &nbsp; &nbsp;
          <ng-container *ngIf="showOptMarketPlace">

                <mat-form-field class="w-200 mr-8" appearance="outline">
                  <input type="number" matInput (change)="onCtrlChange('oorwin_marketplace')" formControlName="free_job_post_cnt">

              <!-- <mat-label>Select the Member</mat-label>
              <mat-select appMatSearchSelector formControlName="member_id" (selectionChange)="onCtrlChange('oorwin_marketplace')"> 
                <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
                <ng-container *ngFor="let member of members">
                  <mat-option *appMatSearchSelectorItems [value]="member.id">{{member.name}}
                  </mat-option>
                </ng-container>
              </mat-select> -->
            </mat-form-field>
          </ng-container>

        </mat-card-content>
      </mat-card>


      <mat-card class="p-0 mt-40 mb-20" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Job Grabber Limit</mat-card-title>
        </mat-card-header>
        
        <mat-card-content class="px-16 py-12">
          
          <mat-radio-group formControlName="job_grabber_limit_enable" aria-label="Select an option" (change)="onCtrlChange('job_grabber_limit_enable')" [disabled]="isLostAccount">
            <mat-radio-button value="0" color="primary" class="sub-heading mr-20">Disable</mat-radio-button>
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable</mat-radio-button>
          </mat-radio-group>
          &nbsp; &nbsp;
          <ng-container *ngIf="showOptjob_grabber_limit">

                <mat-form-field class="w-200 mr-8" appearance="outline">
                  <input type="number" matInput (change)="onCtrlChange('job_grabber_limit_enable')" formControlName="job_grabber_limit">

            </mat-form-field>
          </ng-container>

        </mat-card-content>
      </mat-card>

      <!-- <mat-card class="p-0 mt-40" fxFlex="45" *ngIf="country_id != 2 && currentUser && currentUser.role_id == 1">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Payment Gateway</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="payment_gateway" aria-label="Select an option" (change)="onGatewayChange($event)">
            <mat-radio-button value="1" color="primary" class="sub-heading mr-20">Authorize.Net</mat-radio-button>
            <mat-radio-button value="2" color="primary" class="sub-heading">Stripe</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card> -->

      <!-- <mat-card class="p-0 mt-40" fxFlex="45">
        <mat-card-header class="px-16 border-top-radius-4 grey-200 py-12" fxLayout="row wrap"
          fxLayoutAlign="space-between center">
          <mat-card-title class="m-0 muli-bold">Data Backup</mat-card-title>
        </mat-card-header>
        <mat-card-content class="px-16 py-12">
          <mat-radio-group formControlName="enable_data_backup" aria-label="Select an option" (change)="onCtrlChange('enable_data_backup')">
            <mat-radio-button value="1" color="primary" class="sub-heading">Enable Data Backup</mat-radio-button>
          </mat-radio-group>
        </mat-card-content>
      </mat-card> -->

    </div>
  </div>

  <div class='footer'>
    <button mat-button (click)="showActivities()">Activities</button>
  </div>