import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ValidateService } from 'app/services/validate.service';

@Component({
  selector: 'app-map-fields',
  templateUrl: './map-fields.component.html',
  styleUrls: ['./map-fields.component.scss']
})
export class MapFieldsComponent implements OnInit {

  company_id: any;
  file = [];
  data: any;
  excelHeaders: any;
  dbHeaders: any;
  clientname: any;
  formFieldsGroup: FormGroup;
  userFieldGroup: FormGroup;
  clientusers: any;
  arrUsersList: any;
  selectedfield: unknown[];
  basicmapfields: any;
  excelHeaderslist: any;
  link: any;
  fileformat: any;
  attachment: { filename: any; format: any; };
  folderpath: any;

  constructor(private commonserv: CommonService,
    private location: Location,
    private validateservice: ValidateService,
    private fb: FormBuilder) {



  }

  ngOnInit() {
    // console.log(this.company_id);
    this.commonserv.currentFile.subscribe(result => {
      // console.log(result);
      this.company_id = result['id'];
      this.clientname = result['clientname'];
      this.folderpath = result['folderpath'];
    })
    this.importExcelFile();

  }
  back() {
    this.commonserv.folderpath = '';
    this.commonserv.fileupload = null;
    this.location.back();
  }

  importExcelFile() {
    if (this.company_id == undefined || this.clientname == undefined  ) {
      this.back();
    } else {
      let params = this.commonserv.fileupload ? this.commonserv.fileupload : { 's3_folder_path': this.commonserv.folderpath }
      this.commonserv.importExcelFile(this.company_id, this.clientname, params).subscribe(res => {
        if (res.type === 'success') {
          this.data = res;

          this.excelHeaderslist = res.excelHeaders;
          this.basicmapfields = res.basicMapFields;
          this.dbHeaders = res.dbHeaders;
          this.clientusers = res.arrDiffUserList;
          this.arrUsersList = res.arrUsersList;
          this.link = res.filePath;

          this.formFieldsGroup = this.fb.group([]);
          this.dbHeaders.forEach(field => {
            this.formFieldsGroup.addControl(field.field_name, this.fb.control('', field.is_required ? Validators.required : null));

          });
          this.formFieldsGroup.addControl('s3_folder_path', this.fb.control('', Validators.required));
          // console.log(this.formFieldsGroup);

          this.formFieldsGroup.patchValue(this.basicmapfields);
          this.userFieldGroup = this.fb.group([]);
          var userval = [];
          userval = Object.values(this.clientusers);
          // console.log(userval);
          userval.forEach(user => {    
            this.userFieldGroup.addControl(user, this.fb.control('', Validators.required));

            if (this.arrUsersList.length === 1) {
              this.userFieldGroup.controls[user].setValue(this.arrUsersList[0].id);
            } else {
              const email = Object.keys(this.clientusers).find(key => this.clientusers[key] === user);
              const isPresent = this.arrUsersList.find(el => el.email.trim().toLowerCase() === email.trim().toLowerCase());
              if(isPresent != undefined) {
                this.userFieldGroup.controls[user].setValue(isPresent.id);

              }

            }

          })

          // console.log(this.userFieldGroup.value);

        } else {
          this.commonserv.openSnackBar('warn', res.message);
          setTimeout(() => {
            this.back();
          }, 1500);
        }

      })
    }
  }

  save() {
    if (this.formFieldsGroup.valid && this.userFieldGroup.valid) {
      let params = {
        mapedOrder: this.formFieldsGroup.value,
        mapedUserList: this.userFieldGroup.value,
        link: this.link,
        s3_folder_path: this.formFieldsGroup.controls['s3_folder_path'].value
      }
      // console.log(params);
      this.commonserv.uploadExcelData(this.company_id, this.clientname, params).subscribe(res => {
        if (res.type == 'success') {
          this.commonserv.openSnackBar('primary', res.message)
          this.back();
        } else {
          this.commonserv.openSnackBar('amber', res.message);
        }
      }, () => {
        this.commonserv.openSnackBar('danger', 'Something Went Wrong!');
        this.back();
      })
    } else {
      this.validateservice.validateAllFormFields(this.formFieldsGroup);
      this.validateservice.validateAllFormFields(this.userFieldGroup);
    }


  }
}
