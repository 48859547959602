<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers">
    <ul fxLayout="row" fxLayoutAlign="start center" class="top-nav m-0 p-0">
      <li><a class="text-bold active">Change Password</a></li>
    </ul>
  </mat-toolbar-row>
  <div class="center px-20" [formGroup]="changePassword">
    <div class="content-card mt-20 mb-52">
      <div class="content border-1" fusePerfectScrollbar>
        <div fxLayout="row wrap" fxLayoutAlign="center start">
          <div fxFlex="40" fxLayoutGap="12px grid" class="mt-40">
            <div>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>Enter Current Password</mat-label>
                <input matInput type="password" [type]="hide1 ? 'password' : 'text'" formControlName="current_password">
                <button mat-icon-button matSuffix type="button" (click)="hide1 = !hide1">
                  <mat-icon class="font-size-14">
                    {{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>Enter New Password</mat-label>
                <input matInput type="password" [type]="hide ? 'password' : 'text'" formControlName="password">
                <button mat-icon-button matSuffix type="button" (click)="hide = !hide">
                  <mat-icon class="font-size-14">
                    {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>

              </mat-form-field>

            </div>
            <div>
              <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>Confirm New Password</mat-label>
                <input matInput type="password" [type]="hidden ? 'password' : 'text'"
                  formControlName="password_confirmation">
                <button mat-icon-button matSuffix type="button" (click)="hidden = !hidden">
                  <mat-icon class="font-size-14">
                    {{hidden ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="footer">
          <button mat-button (click)="savePassword()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>