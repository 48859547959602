<h1 mat-dialog-title class='text-uppercase' *ngIf="!isImport"> Add/Edit Coupon </h1>
<h1 mat-dialog-title class='text-uppercase' *ngIf="isImport"> Import Coupons </h1>
<mat-divider></mat-divider>
<mat-dialog-content>

    <form [formGroup]="importForm" *ngIf="isImport">
      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
        <div fxFlex='48'>
            Select File : 
            <input type="file" accept=".xlsx" formControlName="couponsFile" (change)="onFileChange($event.target.files)" required />
        </div>
      </div>
    </form>

  <form [formGroup]="form" *ngIf="!isImport">
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Name</mat-label>
          <input type="text" formControlName="name" matInput>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Discount Type</mat-label>
          <mat-select formControlName="discountType" (selectionChange)='selectChange($event.value)' >
            <mat-option *ngFor="let item of discountType" [value]="item.value">
              {{item.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Value</mat-label>
          <input type="number" formControlName="value" matInput>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Validity</mat-label>
          <mat-select formControlName="validity" (selectionChange)='validitySelectChange($event.value)'>
            <mat-option *ngFor="let item of validity" [value]="item.value">
              {{item.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex='48' *ngIf="expiry">
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Validity Expiry</mat-label>
          <input matInput [matDatepicker]="picker" [min]="todayDate" required placeholder="Choose a date" formControlName="validityExpiry" >
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Max Redemptions</mat-label>
          <input type="text" formControlName="maxRedemptions" matInput>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Billing Cycle</mat-label>
          <mat-select formControlName="billingCycle" >
            <mat-option *ngFor="let item of billingCycle" [value]="item.value">
              {{item.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance="outline">
          <mat-label>All Clients/ Client</mat-label>
          <mat-select appMatSearchSelector formControlName="restrictToClients" multiple >
            <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
            <ng-container *ngFor="let topping of restrictToClients">
              <mat-option *appMatSearchSelectorItems [value]="topping.id">{{topping.display_name}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      
      <div fxFlex='48'>
        <mat-form-field class="w-100-p" appearance="outline">
          <mat-label> All /  Add On(s), Features and Licenses</mat-label>
          <mat-select  formControlName="restrictToAddon"  >
            <app-mat-search-selector placeholder='search..'></app-mat-search-selector>
            <mat-option value="">select</mat-option>
            <ng-container *ngFor="let topping of restricttoAddon">
              <mat-option [value]="topping.slug">{{topping.title}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align='end'>
  <button mat-flat-button mat-dialog-close class="mr-4">Cancel</button>
  <button mat-flat-button class='primary' (click)="save()">Save</button>
</mat-dialog-actions>