import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-common-module-dialog-activity',
  templateUrl: './common-module-dialog-activity.component.html',
  styleUrls: ['./common-module-dialog-activity.component.scss']
})
export class CommonModuleDialogActivityComponent implements OnInit {

  module_slug: any;
  company_id: any;
  show_company_name: any;
  isModuleComments: any = false;
  record_id: any = 0;
  signup_request_id: any =0;

  constructor(public dialogRef: MatDialogRef<CommonModuleDialogActivityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.company_id = this.data.company_id;
    this.module_slug = this.data.module_slug;
    this.show_company_name = this.data.show_company_name;
    if (this.data.isComments) {
      this.isModuleComments = this.data.isComments;
    }
    if (this.data.record_id) {
      this.record_id = this.data.record_id;
    }
    if (this.data.signup_request_id) {
      this.signup_request_id = this.data.signup_request_id;
    }
    
  }

}
