import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';
import { CommonService } from 'app/services/common.service';
import { ConfigurationService } from 'app/services/configuration.service';
import { AddUnsubscribeEmailComponent } from '../add-unsubscribe-email/add-unsubscribe-email.component';

@Component({
  selector: 'app-unsubscribe-list',
  templateUrl: './unsubscribe-list.component.html',
  styleUrls: ['./unsubscribe-list.component.scss']
})
export class UnsubscribeListComponent implements OnInit {
  displayedColumns: string[] = ['email_id', 'comment', 'created_on', 'actions'];
  dataSource;
  currentPage: any;
  limit: any;
  length: any;
  params: { limit: number; page: number, search: string; }

  constructor(
    private CommonService: CommonService,
    private ConfigService: ConfigurationService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.params = { limit: 15, page: 1, search: '', }
    this.getUnsubscribeList();
  }

  getUnsubscribeList() {
    this.ConfigService.getUnsubscribeList(this.params).subscribe(res => {
      if (res.status === 1) {
        this.currentPage = res.data.current_page;
        this.limit = res.data.per_page;
        this.length = res.data.total;
        this.dataSource = new MatTableDataSource(res.data.data);
      }
    })
  }

  applyFilter(val) {
    this.params['search'] = val;
    this.params.page = 1;
    this.getUnsubscribeList();
  }

  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getUnsubscribeList();
  }

  addUnsubscribeEmail() {
    let data = {};
    data['id'] = '';
    const dialogRef = this.dialog.open(AddUnsubscribeEmailComponent, {
      width: '35%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getUnsubscribeList();
      }
    });
  }

  deleteUnsubscribeEmail(element: any) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to unblock Email';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ConfigService.deleteUnsubscribeEmail({ id: element.id }).subscribe(res => {
          if (res.status === 1) {
            this.CommonService.openSnackBar('primary', res.message);
            this.getUnsubscribeList();
          } else {
            this.CommonService.openSnackBar('warn', res.message);
          }
        })
      }
    })
  }
}
