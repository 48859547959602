import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'app/services/common.service';

@Component({
  selector: 'app-send-docto-client-dialog',
  templateUrl: './send-docto-client-dialog.component.html',
  styleUrls: ['./send-docto-client-dialog.component.scss']
})
export class SendDoctoClientDialogComponent implements OnInit {

  form: FormGroup;
  addData:any = {};
  IsRequired: boolean = false;

  constructor(public dialogRef: MatDialogRef<SendDoctoClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private commonService: CommonService) {
    this.form = this.fb.group({
      email: ['', Validators.required]
    })

  }

  ngOnInit() {
  }

  save() {

    this.addData['doc_id'] = this.data;
    this.addData['email'] = this.form.value.email;

    this.commonService.sendDoccument(this.addData).subscribe(res => {
      if (res.status === 1) {
        this.dialogRef.close(true);
        this.commonService.openSnackBar('primary', res.message);
      }
      else {
        let msg = res.message;
        if (typeof res.validations != 'undefined') {
          for (let field_name in res.validations) {
            msg += res.validations[field_name];
          }
        }
        this.commonService.openSnackBar('warn', msg);
      }
    })
    
  }

}
