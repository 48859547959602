<div class="page-layout carded fullwidth inner-scroll">
  <mat-toolbar-row class="secondary-headers w-100-p px-20">
    <h2 class="sub-heading">Migrations</h2>
  </mat-toolbar-row>
  <div class="center px-20">
    <mat-toolbar-row class="secondary-headers w-100-p px-20">
      <ul class="nav m-0 p-0" fxFlex="auto">
        <li><a class="text-bold" (click)="getViewList('in_progress')"
            [ngClass]="{'active': viewList == 'in_progress'}">In Progress</a>
        </li>
        <li><a class="text-bold" (click)="getViewList('completed')"
            [ngClass]="{'active': viewList == 'completed'}">Completed</a>
        </li>
        <li><a class="text-bold" (click)="getViewList('washed_away')"
            [ngClass]="{'active': viewList == 'washed_away'}">Washed Away</a>
        </li>
      </ul>
    </mat-toolbar-row>
    <div class="content-card mt-12 mb-52">
      <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">

        <div class="search-wrapper" fxFlex="25">
          <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
            [(ngModel)]="searchValue" placeholder="Customer">
          <mat-icon class="search_icon">search</mat-icon>
          <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
            close
          </mat-icon>
        </div>

        <div fxFlex="25" *ngIf="viewList != 'completed'">
          <mat-form-field appearance="outline" class="w-100-p ml-60">
            <mat-label>Stage</mat-label>
            <mat-select (selectionChange)='selectChange($event.value)'>
              <mat-option value="">All</mat-option>
              <mat-option *ngFor="let item of stagesData" [value]='item.stage_slug'>
                {{item.stage_name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxFlex="50" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
          <div fxLayout="row" fxLayoutAlign="end center">

            <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
              [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
          </div>
        </div>
      </div>
      <div class="content" fusePerfectScrollbar>
        <ng-container *ngIf="dataSource">
          <div class="px-12 pb-12">
            <mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="customer_name">
                <mat-header-cell *matHeaderCellDef> Customer </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a class='cursor-pointer' (click)='navigate(element)'>{{element.name}} </a>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="intiated_by">
                <mat-header-cell *matHeaderCellDef> Initiated By </mat-header-cell>
                <mat-cell *matCellDef="let element">{{getColumnDate(element.info, 'intiated_by')}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="intiated_on">
                <mat-header-cell *matHeaderCellDef> Initiated On </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{getColumnDate(element.info, 'intiated_on')}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="poc">
                <mat-header-cell *matHeaderCellDef> Support POC </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.first_name?element.first_name:'-'}}
                  {{element.last_name?element.last_name:'-'}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="current_stage">
                <mat-header-cell *matHeaderCellDef> Current Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.stage_name}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="est">
                <mat-header-cell *matHeaderCellDef> Estimated Completion Date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{getColumnDate(element.info, 'estCompletioinDate') |
                  date:'MM/dd/yyyy'}} </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.data.length == 0" class="text-center">
              <h2 class="sub-heading">No Records Found</h2>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>