import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddTextRecruitDialogComponent } from '../add-text-recruit-dialog/add-text-recruit-dialog.component';
import { ConfirmDialogComponent } from 'app/common-all/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-text-recruit',
  templateUrl: './text-recruit.component.html',
  styleUrls: ['./text-recruit.component.scss']
})
export class TextRecruitComponent implements OnInit {

  displayedColumns: string[] = ['phone', 'AccountSID', 'AuthToken', 'TotalCredits', 'CreditsExpiry', 'Available', 'actions'];
  dataSource;
  id: any;
  searchValue = '';
  getTableData: any;
  length: any;
  pageSize: any;
  index: any;
  params: { company_id: any; page: number; limit?: number, searchKey?: any };
  isLostAccount:any;

  constructor(private commonService: CommonService,
    public dialog: MatDialog,
    private route: ActivatedRoute) {
    this.id = this.route.parent.snapshot.params['id'];
  }

  ngOnInit() {
    this.params = { company_id: this.id, page: 1 };
    this.getList();
  }
  applyFilter(value) {
    this.params['searchKey'] = value;
    this.params.page = 1;
    this.getList();
  }
  pageEvent(event) {
    this.params.limit = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getList();
  }
  getList() {
    this.commonService.textRecruitList(this.params).subscribe(result => {
      if (result.status === 1) {
        this.isLostAccount = result.isAccountLost;
        this.getTableData = result.data;
        this.length = this.getTableData.total;
        this.pageSize = result.data.per_page;
        this.index = result.data.current_page;
        this.dataSource = new MatTableDataSource(result.data.data);
      }
    })
  }
  add() {
    const dialogRef = this.dialog.open(AddTextRecruitDialogComponent, {
      width: '50%',
      data: { id: this.id, perform: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.params = { company_id: this.id, page: this.index };
        this.getList();
      }
    });
  }
  edit(element) {
    const dialogRef = this.dialog.open(AddTextRecruitDialogComponent, {
      width: '50%',
      data: { id: element.id, perform: 'edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.params = { company_id: this.id, page: this.index };
        this.getList();
      }
    });
  }
  delete(element) {
    const data = [];
    data['title'] = 'Alert';
    data['message'] = 'Are you sure? # You want to delete phone number';
    data['successText'] = 'Yes';
    data['closeText'] = 'No';
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      minWidth: '30%',
      minHeight: '40%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.commonService.deleteTextRecruiterList(element.id).subscribe(res => {
          if (res.status === 1) {
            this.commonService.openSnackBar('primary', res.message);
            this.params = { company_id: this.id, page: this.index };
            this.getList();
          }
        })
      }
    })
  }
}
