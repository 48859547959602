<h1 mat-dialog-title class='text-uppercase text-center' *ngIf="title">{{title[0]}} <br/> {{title[1]}}
</h1>
<mat-divider></mat-divider>
<form [formGroup]="addRefund">
  <mat-dialog-content class="text-center line-height-32">

    <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlexFill fxLayoutGap="12px grid" *ngIf="data.price_type == 'flat_fee'">
      <label id="example-radio-group-label">Free Access :</label>
      <mat-radio-group  aria-labelledby="example-radio-group-label" formControlName="free_access_type">
          <mat-radio-button value='current_subscription' color="primary" class="sub-heading mr-12">Current Subscription</mat-radio-button>
          <mat-radio-button value='life_time' color="primary" class="sub-heading"> Life Time</mat-radio-button>
      </mat-radio-group>
  </div>
    
    <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlexFill fxLayoutGap="12px grid">
      <div fxFlex='100'>
        <mat-form-field class="w-100-p" appearance='outline'>
          <mat-label>Comments</mat-label>
          <textarea matInput rows="6" formControlName="reason"></textarea>
        </mat-form-field>
      </div>
    </div>
    <p *ngIf="showOtherText" class="text-left m-0"><b>Note: </b> {{otherText}}</p>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions align='end'>
    
    <button mat-flat-button class='mr-16' (click)="closeDialog()">{{data.closeText}}</button>
    <button mat-flat-button class='primary' (click)="closeWithComments()">{{data.successText}}</button>
  </mat-dialog-actions>
</form>