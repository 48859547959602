<div class="page-layout carded fullwidth inner-scroll">
    <div class="center px-20">
      <div class="" [ngClass]="{'content-card mt-12 mb-52':!isModel}">
        <div fxLayout='row' fxLayoutAlign="space-between center" class="fuse-white-bg px-12" *ngIf="dataSource">
  
          <div class="search-wrapper" fxFlex="30" fxFlex.lt-xl="30">
            <input matInput class="search1" (keyup.enter)="applyFilter($event.target.value)" type="text"
              [(ngModel)]="searchValue" placeholder="Activity,User...">
            <mat-icon class="search_icon">search</mat-icon>
            <mat-icon class="close s-20 danger-fg" *ngIf="searchValue" (click)="searchValue=''; applyFilter('');">
              close
            </mat-icon>
          </div>
  
          <div fxFlex="100" class="actions_list" fxLayout="row" fxLayoutAlign="end center">
            <div fxLayout="row" fxLayoutAlign="end center">
              <mat-paginator #paginator [pageSizeOptions]="[15, 25, 50,100]" [length]="length" (page)="pageEvent($event)"
                [pageSize]="pageSize" [pageIndex]="index-1"></mat-paginator>
            </div>
          </div>
        </div>
        <div class="content" fusePerfectScrollbar>
          <ng-container *ngIf="dataSource">
            <div class="px-12 pb-12">
              <mat-table [dataSource]="dataSource">
  
                <ng-container matColumnDef="module_name" *ngIf="showCompanyName">
                  <mat-header-cell *matHeaderCellDef> {{show_company_name == true?'Customer':'Module'}} </mat-header-cell>
                  <mat-cell *matCellDef="let element">{{show_company_name == true?element.company_name:element.module_name}}</mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="activity">
                  <mat-header-cell *matHeaderCellDef> Activity </mat-header-cell>
                  <mat-cell *matCellDef="let element" [innerHTML]="element.subject"></mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="details">
                  <mat-header-cell *matHeaderCellDef > {{signup_request_id?'Comments':'Description'}}  </mat-header-cell>
                  <mat-cell *matCellDef="let element;let i = index" >
                    <div>
                      <span [innerHTML]="element.body | slice:0:more[i] ? element.body.length : 100"> </span>
                    <a *ngIf="element.body.length > 100" style="cursor:pointer;" (click)="more[i] = !more[i];">{{!more[i] ? ' More ' : ' Less '}}</a>
                    </div>
                  </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="created_by">
                  <mat-header-cell *matHeaderCellDef fxFlex="130px"> User  </mat-header-cell>
                  <mat-cell *matCellDef="let element" fxFlex="130px"> {{element.created}} </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="created_on">
                  <mat-header-cell *matHeaderCellDef fxFlex="170px">{{signup_request_id?'Created On':'Updated On'}} </mat-header-cell>
                  
                  <mat-cell *matCellDef="let element" fxFlex="170px"> {{element.created_at | date:"MM/dd/yyyy, h:mm a" }}
                  </mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
  
              <div *ngIf="dataSource.data.length == 0" class="text-center">
                <h2 class="sub-heading">No Records Found</h2>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>